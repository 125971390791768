

























































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import {
    CandidatConcour,
    CandidatInterface,
    EtatDossierCandidat,
    getCandidatConcour,
    getEtatDossier,
    getLibelleCivilite,
    getMentionBac,
    MentionBAC
} from '@/types/Candidat'
import TableSelection from '@/components/TableSelection.vue'
import { Ability } from '@/types/Ability'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import PhoneInput from '@/components/Tools/PhoneInput.vue'
import { formatDate, formatDateDocument, formatDateSinTime, formatDateYYYYMMDD } from '@/utils/helpers'
import { isEmpty, isNull, isUndefined } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { TypePassation } from '@/types/Epreuve'

@Component({
    methods: {
        getEtatDossier,
        getCandidatConcour,
        isNull,
        isEmpty,
        getMentionBac,
        formatDateSinTime,
        formatDateDocument,
        formatDateYYYYMMDD,
        formatDate,
        getLibelleCivilite
    },
    computed: {
        Ability() {
            return Ability
        },
        mentionBac() {
            const middle = Math.floor(Object.keys(MentionBAC).length / 2)
            return Object.keys(MentionBAC).slice(0, middle)
        },
        etatCandidatConcour() {
            const middle = Math.floor(Object.keys(CandidatConcour).length / 2)
            return Object.keys(CandidatConcour).slice(0, middle)
        },
        etatDossier() {
            const middle = Math.floor(Object.keys(EtatDossierCandidat).length / 2)
            return Object.keys(EtatDossierCandidat).slice(0, middle)
        }
    },
    components: {
        PhoneInput,
        ErrorDisplay,
        TableSelection,
        FontAwesomeIcon
    }
})
export default class PopupEditCandidatInscriptions extends Vue {
    can = this.$store.getters['auth/can']
    candidat: CandidatInterface = {} as CandidatInterface
    edit = false
    loading = false
    phases: any[] = []

    /**
     * onEditChanged
     * Actions à entreprendre lorsque l'état d'édition change
     * @param {boolean} value - Valeur de l'état d'édition
     * @returns {void}
     */
    @Watch('edit', { immediate: true })
    onEditChanged(value: boolean): void {
        this.$emit('changeEditing', value)
        if (value) {
            if (!this.can(Ability.CAND_MANAGE)) {
                this.edit = false
                return
            }
        } else {
            this.loadCandidat()
        }
    }

    /** Vérifie si l'épreuve fournie à déjà une séance planifiée */
    hasSeancePlanified(epreuve_id: number): boolean {
        if (this.candidat.seances) {
            const seanceMatch = this.candidat.seances.find((seance: any) => parseInt(seance.epreuve_id) === epreuve_id)
            if (seanceMatch && seanceMatch.creneau && seanceMatch.creneau.jour) {
                return true
            }
        }
        return false
    }

    /**
     * Sauvegarde les informations du candidat
     * @returns {void}
     */
    saveInfos(): void {
        if (this.edit && !this.loading && this.can(Ability.CAND_MANAGE) && !isEmpty(this.candidat)) {
            this.loading = true

            const idInfo = 't_info_' + Math.random()
            this.$bvToast.toast('Enregistrement en cours ...', {
                id: idInfo,
                toaster: 'b-toaster-top-right',
                variant: 'primary',
                noCloseButton: true,
                fade: true,
                noAutoHide: true
            })

            if (!isEmpty(this.candidat.concours) && !isNull(this.candidat.concours)) {
                for (let i = 0; i < this.candidat.concours.length; i++) {
                    this.candidat.concours[i].concour_id = this.candidat.concours[i].id
                }
            }

            for (const phase of this.phases) {
                if (phase.epreuves && phase.epreuves.length !== 0) {
                    for (const epreuve of phase.epreuves) {
                        if (epreuve.childSelected === -1) {
                            this.$store.commit('candidat/REMOVE_EDITED_CANDIDAT_EPREUVE', epreuve.oldChildSelected)
                            epreuve.oldChildSelected = -1
                            epreuve.childSelectedName =
                                epreuve.tirage_aleatoire === 1 ? 'Tirage non effectué' : 'Aucune'
                        } else {
                            if (epreuve.childSelected !== epreuve.oldChildSelected) {
                                this.$store.commit('candidat/REMOVE_EDITED_CANDIDAT_EPREUVE', epreuve.oldChildSelected)
                                this.$store.commit('candidat/ADD_EDITED_CANDIDAT_EPREUVE', epreuve.childSelected)
                                const epreuveChildAdded = this.$store.getters['epreuve/getEpreuvesDelibCandidat'](
                                    this.$store.getters['candidat/editedCandidat'].concours
                                ).find((e: any) => e.id === epreuve.childSelected)
                                if (epreuveChildAdded) {
                                    epreuve.childSelectedName = epreuveChildAdded.name
                                }
                            }
                        }
                    }
                }
            }

            const payload: any = {
                id: this.candidat.id,
                first_name: this.candidat.first_name,
                name: this.candidat.name,
                concour_id: this.candidat.concour_id,
                demission: this.candidat.demission,
                etat_dossier: this.candidat.etat_dossier,
                nationalite_fr: this.candidat.nationalite_fr,
                serie_id: this.candidat.serie_id,
                code: this.candidat.code,
                concours: this.candidat.concours,
                inscriptions: this.$store.getters['candidat/editedCandidat'].inscriptions,
                autorisation_passation: this.candidat.autorisation_passation,
                autorisation_resultats: this.candidat.autorisation_resultats
            }

            if (payload.demission) {
                payload.demission_at = this.candidat.demission_at
            }

            this.$store.dispatch('candidat/updateCandidat', payload)
                .then((response) => {
                    if (response.data && response.data.data && response.data.data.id) {
                        this.$store.commit('candidat/SET_CANDIDAT', response.data.data)
                        this.$store.commit('candidat/SET_SELECTED_CANDIDAT', response.data.data.id)
                        this.$store.commit(
                            'candidat/SET_EDITED_CANDIDAT',
                            this.$store.getters['candidat/selectedCandidat'].id
                        )
                    }

                    this.$bvToast.toast('Enregistrement terminé.', {
                        id: 't_succes_' + Math.random(),
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    })

                    this.edit = false
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                    this.loading = false
                })
        }
    }

    /**
     * getPhase
     * Récupère la phase en cours du concours
     * @param {any} concours - Concours
     * @param {number} id - Identifiant de la phase
     * @returns {object} Phase en cours
     */
    getPhase(concours: any, id: number): object {
        if (!concours || !concours.concours_data || !concours.concours_data.phases) {
            return {}
        }
        return concours.concours_data.phases.find((p: any) => p.phase_id === id)
    }

    /**
     * getAutorisation
     * Affiche la valeur de l'autorisation
     * @param {boolean} value - Valeur de l'autorisation
     * @returns {string} Valeur de l'autorisation
     */
    getAutorisation(value: boolean | null): string {
        if (isUndefined(value) || isNull(value)) {
            return '-'
        } else {
            return value ? 'Oui' : 'Non'
        }
    }

    /**
     * loadCandidat
     * Charge les informations nécessaires à l'affichage des informations du candidat
     * @returns {Promise<void>}
     */
    async loadCandidat(): Promise<void> {
        if (isEmpty(this.$store.getters['epreuve/epreuves'])) {
            await this.$store.dispatch('epreuve/getEpreuves', {
                perPage: 0
            })
        }

        if (isEmpty(this.$store.getters['banque/banques'])) {
            await this.$store.dispatch('banque/getBanques', {
                perPage: 0
            })
        }

        if (isEmpty(this.$store.getters['concour/concours'])) {
            await this.$store.dispatch('concour/getConcours', {
                perPage: 0
            })
        }

        const candidat = JSON.parse(JSON.stringify(this.$store.getters['candidat/editedCandidat']))
        const all_epreuves = this.$store.getters['epreuve/epreuves']
        const all_banques = this.$store.getters['banque/banques']

        if (!isEmpty(candidat.concours)) {
            // Récupération des concours, épreuves et banques
            const all_concours = this.$store.getters['concour/concours']

            // Ajout des données des concours et des épreuves
            for (let i = 0; i < candidat.concours.length; i++) {
                candidat.concours[i].concours_data = all_concours.find((c: any) => c.id === candidat.concours[i].id)

                if (!isEmpty(candidat.concours[i].epreuves_deliberation)) {
                    for (let j = 0; j < candidat.concours[i].epreuves_deliberation.length; j++) {
                        candidat.concours[i].epreuves_deliberation[j].epreuve = all_epreuves.find(
                            (e: any) => e.id === candidat.concours[i].epreuves_deliberation[j].epreuve_id
                        )
                    }
                }
            }
        }

        let phases = []
        const banque = all_banques.find((b: any) => b.id === candidat.concour_id)
        if (!isEmpty(banque)) {
            phases = JSON.parse(JSON.stringify(banque.phases))
        }

        const epreuvesIdInscriptions = []
        if (candidat.inscriptions) {
            for (const inscription of candidat.inscriptions) {
                if (inscription && inscription.epreuve_id) {
                    epreuvesIdInscriptions.push(inscription.epreuve_id)
                }
            }
        }

        const epreuves = this.$store.getters['epreuve/getEpreuvesDelibCandidat'](candidat.concours)
        for (let i = 0; i < epreuves.length; i++) {
            const index = phases.findIndex((p: any) => p.phase_id === epreuves[i].phase_id)
            if (index !== -1) {
                if (epreuves[i].id_epreuve_maitresse && epreuves[i].id_epreuve_maitresse !== 0) {
                    // Pas d'épreuves filles, car pas épreuves maîtresses
                    const indexMaitre = epreuves.findIndex((e: any) => e.id === epreuves[i].id_epreuve_maitresse)
                    if (indexMaitre !== -1) {
                        if (!epreuves[indexMaitre].childsItems) {
                            epreuves[indexMaitre].oldChildSelected = -1
                            epreuves[indexMaitre].childSelected = -1
                            epreuves[indexMaitre].childSelectedName = epreuves[i].tirage_aleatoire === 1 ? 'Tirage non effectué' : 'Aucune'
                            epreuves[indexMaitre].childsItems = [{ id: -1, name: 'Aucune'}]
                        }

                        const indexEpreuveCandidat = epreuvesIdInscriptions.indexOf(epreuves[i].id)
                        if (indexEpreuveCandidat !== -1) {
                            epreuves[indexMaitre].oldChildSelected = epreuves[i].id
                            epreuves[indexMaitre].childSelected = epreuves[i].id
                            epreuves[indexMaitre].childSelectedName = epreuves[i].name
                        }
                        epreuves[indexMaitre].childsItems.push(epreuves[i])
                    }
                } else if (epreuves[i].type_passation === -1) {
                    // Récupére les épreuves filles liées à la maîtresse
                    const indexMaitre = epreuves.findIndex((e: any) => e.id === epreuves[i].id)
                    const epreuves_filles = all_epreuves.filter((ep: any) => ep.id_epreuve_maitresse === epreuves[i].id)
                    if (indexMaitre !== -1) {
                        epreuves[indexMaitre].childsItems = []
                    }
                    for (const f in epreuves_filles) {
                        if (indexMaitre !== -1) {
                            const indexEpreuveCandidat = epreuvesIdInscriptions.indexOf(epreuves_filles[f].id)
                            if (indexEpreuveCandidat !== -1) {
                                epreuves[indexMaitre].oldChildSelected = epreuves_filles[f].id
                                epreuves[indexMaitre].childSelected = epreuves_filles[f].id
                                epreuves[indexMaitre].childSelectedName = epreuves_filles[f].name
                            }
                            epreuves[indexMaitre].childsItems.push(epreuves_filles[f])
                        }
                    }
                    epreuves[i].typeinput = 'select'
                    if (!epreuves[i].childSelected) {
                        epreuves[i].oldChildSelected = -1
                        epreuves[i].childSelected = -1
                        epreuves[i].childSelectedName = epreuves[i].tirage_aleatoire === 1 ? 'Tirage non effectué' : 'Aucune'
                    }
                    if (!epreuves[i].childsItems || epreuves[i].childsItems.length === 0) {
                        epreuves[i].childsItems = [{ id: -1, name: 'Aucune' }]
                    }
                    if (isUndefined(phases[index].epreuves)) {
                        phases[index].epreuves = []
                    }

                    /** Vérifie si l'épreuve fournie est une épreuve de type TYPE_PASSATION_TP */
                    if (epreuves[i].childsItems) {
                        const childMatch = epreuves[i].childsItems.find(
                            (child: any) => child.type_passation === TypePassation.TYPE_PASSATION_TP
                        )
                        if (!childMatch) {
                            phases[index].epreuves.push(epreuves[i])
                        }
                    } else if (epreuves[i].type_passation !== TypePassation.TYPE_PASSATION_TP) {
                        phases[index].epreuves.push(epreuves[i])
                    }
                } else {
                    if(!phases[index].epreuves) {
                        phases[index].epreuves = []
                    }
                    const indexEpreuveCandidat = epreuvesIdInscriptions.indexOf(epreuves[i].id)
                    if( indexEpreuveCandidat > -1 && (epreuves[i].obligatoire === 0 || epreuves[i].option > 0)) {
                        epreuves[i].childSelected =  epreuves[i].id
                        epreuves[i].childSelectedName =  epreuves[i].name
                        phases[index].epreuves.push(epreuves[i])
                    }

                }
            }
        }
        this.phases = phases
        this.candidat = candidat
    }
}
