


















import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'

@Component({
    components: {
    },
    computed: {
        ...mapGetters('centre', ['centreSelect', 'centre_users_select', 'loading', 'conventions']),
        ...mapState('centre', ['users_count', 'error'])
    }
})
export default class ModuleConventions extends Vue {
    @Watch('conventions')
    initCard () {
        this.getDescription()
    }

    // Retourne une description selon l'état de la convention et de l'annexe.
    getDescription () {
        let description = 'text-secondary'
        let count_success = 0
        let count_info = 0
        let count_secondary = 0
        let count_rien = 0
        for (const c in this.$store.state.centre.conventions) {
            // count_success
            if (this.$store.state.centre.conventions[c].convention_published_at && this.$store.state.centre.conventions[c].convention_signed_at && this.$store.state.centre.conventions[c].convention_validated_at) {
                count_success++
            }
            if (this.$store.state.centre.conventions[c].annexe_published_at && this.$store.state.centre.conventions[c].annexe_signed_at && this.$store.state.centre.conventions[c].annexe_validated_at) {
                count_success++
            }

            // count_info
            if (this.$store.state.centre.conventions[c].convention_published_at && this.$store.state.centre.conventions[c].convention_signed_at && !this.$store.state.centre.conventions[c].convention_validated_at) {
                count_info++
            }
            if (this.$store.state.centre.conventions[c].annexe_published_at && this.$store.state.centre.conventions[c].annexe_signed_at && !this.$store.state.centre.conventions[c].annexe_validated_at) {
                count_info++
            }

            // count_secondary
            if (this.$store.state.centre.conventions[c].convention_published_at && !this.$store.state.centre.conventions[c].convention_signed_at && !this.$store.state.centre.conventions[c].convention_validated_at) {
                count_secondary++
            }
            if (this.$store.state.centre.conventions[c].annexe_published_at && !this.$store.state.centre.conventions[c].annexe_signed_at && !this.$store.state.centre.conventions[c].annexe_validated_at) {
                count_secondary++
            }

            // count_rien
            if (!this.$store.state.centre.conventions[c].convention_published_at && !this.$store.state.centre.conventions[c].convention_signed_at && !this.$store.state.centre.conventions[c].convention_validated_at) {
                count_rien++
            }
            if (!this.$store.state.centre.conventions[c].annexe_published_at && !this.$store.state.centre.conventions[c].annexe_signed_at && !this.$store.state.centre.conventions[c].annexe_validated_at) {
                if (count_rien !== 0) {
                    // on incrémente uniquement si on sait qu'on à aussi AUCUNE convention en cours.
                    count_rien++
                }
            }
        }

        if (count_success !== 0) {
            description = 'La convention est signée.'
        }
        if (count_info !== 0) {
            description = "La convention et/ou l'annexe de convention est en attente de signature par la direction."
        }
        if (count_secondary !== 0) {
            description = "La convention et/ou l'annexe de convention est disponible pour signature."
        }
        if (count_rien !== 0) {
            description = "Pas de convention ou d'annexe de convention en cours."
        }

        return description
    }

    // Retourne la couleur du dossier selon son état
    getVariantColor () {
        let variant = 'text-secondary'
        let count_success = 0
        let count_info = 0
        let count_secondary = 0
        for (const c in this.$store.state.centre.conventions) {
            // count_success
            if (this.$store.state.centre.conventions[c].convention_published_at && this.$store.state.centre.conventions[c].convention_signed_at && this.$store.state.centre.conventions[c].convention_validated_at) {
                count_success++
            }
            if (this.$store.state.centre.conventions[c].annexe_published_at && this.$store.state.centre.conventions[c].annexe_signed_at && this.$store.state.centre.conventions[c].annexe_validated_at) {
                count_success++
            }

            // count_info
            if (this.$store.state.centre.conventions[c].convention_published_at && this.$store.state.centre.conventions[c].convention_signed_at && !this.$store.state.centre.conventions[c].convention_validated_at) {
                count_info++
            }
            if (this.$store.state.centre.conventions[c].annexe_published_at && this.$store.state.centre.conventions[c].annexe_signed_at && !this.$store.state.centre.conventions[c].annexe_validated_at) {
                count_info++
            }

            // count_secondary
            if (this.$store.state.centre.conventions[c].convention_published_at && !this.$store.state.centre.conventions[c].convention_signed_at && !this.$store.state.centre.conventions[c].convention_validated_at) {
                count_secondary++
            }
            if (this.$store.state.centre.conventions[c].annexe_published_at && !this.$store.state.centre.conventions[c].annexe_signed_at && !this.$store.state.centre.conventions[c].annexe_validated_at) {
                count_secondary++
            }
        }

        if (count_success !== 0) {
            variant = 'text-success'
        }
        if (count_info !== 0) {
            variant = 'text-info'
        }
        if (count_secondary !== 0) {
            variant = 'text-secondary'
        }

        return variant
    }

    mounted () {
        if (this.$store.state.centre.conventions.length === 0) {
            this.$store.dispatch('centre/getConventions')
        }
    }
}

