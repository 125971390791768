export enum etat_avis {
    ETAT_NON_PUBLIEE,
    ETAT_PUBLIE_OBSERVATEUR,
    ETAT_CLOTURE_OBSERVATEUR,
}

export interface ReclamationOralInterface {
    id: number | 0,
    code: string | null,
    identite: string | null,
    filiere: string | null,
    epreuve: string | null,
    message: string | null,
    messageToCand: string | null,
    noteBrute: number | 0,
    noteFinale: number | 0,
    noteRectifiee: number | 0,
    reclamation_validated_at: Date,
    statut: number | 0
}

export interface StateReclamationOralInterface {
    reclamations_oral: Array<ReclamationOralInterface> | [],
    meta: Array<string> | null,
    links: Array<string> | null,
    loading: boolean | false,
    error: Array<string> | null,
    totalRows: number | 0,
    currentPage: number | 0,
    lastPage: number | 0,
    totalPage: number | 0,
    reclamationOralSelect: ReclamationOralInterface | {},
    reclamations_oral_compteurs: Array<string> | null
}
