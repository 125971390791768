









































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import PopupEditCandidat from '@/components/Candidat/PopupEditCandidat.vue'
import PopupCreerReclamation from '@/components/Reclamations/PopupCreerReclamation.vue'
import PopupEnvoieRecorrection from '@/components/Reclamations/PopupEnvoieRecorrection.vue'
import Marqueur from '@/views/Reclamations/Marqueur.vue'
import { checkIcone, getFileNameFromHeader } from '@/utils/helpers'
import {
    Etat,
    getEtatSpecReclamation,
    getTypeRecorrectionReclamation,
    TypeReclamation,
    TypeRecorrectionReclamation
} from '@/types/Reclamation'
import { CandidatStatut } from '@/types/Candidat'
import { isEmpty } from 'lodash'

/**
 * Composant contenant l'ensemble des candidats
*/
@Component({
    computed: {
        Ability() {
            return Ability
        },
        TypeReclamation() {
            return TypeReclamation
        },
        ...mapGetters('reclamation', ['loading', 'reclamations_ecrit', 'reclamationSelect', 'meta', 'links', 'totalRows', 'lastPage', 'totalPage']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id'])
    },
    components: {
        ExaGenericTable,
        PopupEditCandidat,
        PopupCreerReclamation,
        PopupEnvoieRecorrection,
        Marqueur,
        'font-awesome-icon': FontAwesomeIcon
    }
})

export default class  ReclamationsEcrit extends Vue {
    showModalEditCandidat?: boolean = false
    showModalCreerReclamation?: boolean = false
    showModalEnvoieRecorrection?: boolean = false
    tabSelected = ''
    tabSelectedCandidat = 'resultats'
    showModaleMark = false
    exportingIsWorking = false
    filter = '';
    filtres: any = []
    dataForTab: Array<any> = []
    selectReclamation: any = null
    avancementReclamation: any = null
    compteursReclamations: Array<any> = []

    // Ensemble des colonnes du tableau des réclamations
    genericfields = [
        { key: 'marqueur',                    label: 'Marqueur',           sortable: true,  class: 'text-center', type: 'action' },
        { key: 'commentaires',                label: '',                   sortable: false, class: '',            type: 'action' },
        { key: 'candidat.code',               label: 'Code',               sortable: true,  class: '',            type: 'actionText' },
        { key: 'candidat.name',               label: 'Identité',           sortable: true,  class: '',            type: 'actionText' }, // Nom + prénom
        { key: 'candidat.concour_id',         label: 'Filière',            sortable: false, class: '',            type: 'text' },
        { key: 'epreuve.name',                label: 'Épreuve',            sortable: true,  class: '',            type: 'text' },
        { key: 'correcteur.name',             label: 'Correcteur(s)',      sortable: false, class: '',            type: 'text' },
        { key: 'candidatEpreuve.note_brute',  label: 'Note Brute /20',     sortable: false, class: 'text-center', type: 'text' },
        { key: 'candidatEpreuve.note_finale', label: 'Note Finale /20',    sortable: false, class: 'text-center', type: 'text' },
        { key: 'note',                        label: 'Note Rectifiée /20', sortable: true,  class: 'text-center', type: 'text' },
        { key: 'recorrection',                label: 'Recorrection',       sortable: true,  class: 'text-center', type: 'icons' },
        { key: 'avancement',                  label: 'Avancement',         sortable: true,  class: 'text-center', type: 'icons' },
        { key: 'etatEdit',                    label: '',                   sortable: false, class: '',            type: 'action' }
    ]

    /**
     * @description Mise à jour des données du tableau
     * @returns {Promise<void>}
     */
    @Watch('reclamations_ecrit')
    async majDataForGenericTab(): Promise<void> {
        const params = {}
        Vue.set(params, 'filter-type', TypeReclamation.TYPE_NOTE_ECRIT)
        await this.$store.dispatch('reclamation/getReclamationsCompteurs', { filters : params })
        this.initDatas()
        this.initStatsReclamations()
    }

    /**
     * @description Recharge les données
     * @returns {Promise<void>}
     */
    @Watch('user_session_id')
    async refreshInterface(): Promise<void> {
        // force le refresh
        localStorage.setItem('reclamationEcritParams', JSON.stringify({}))
        await this.load()
    }

    /**
     * @description Formatage des datas pour l'affichage dans le tableau générique
     * @param {any} poData - Données à afficher
     * @param {boolean} isLoadMore - Indique si on charge plus de données
     * @returns {void}
     */
    setDataForGenericTab(poData: any, isLoadMore = false): void {
        const can = this.$store.getters['auth/can'](Ability.ECR_RECLAM_MANAGE)
        const droit = checkIcone(Ability.ECR_RECLAM_MANAGE, can)
        if (!isLoadMore) {
            this.dataForTab = []
        }
        if (poData) {
            for (const result of poData) {
                const styleButtonFicheCandidat = 'text-tertiary commons_comment_button text-center fa-2x'
                const iconButtonFicheCandidat = []
                iconButtonFicheCandidat.push({ name:'address-card', class: styleButtonFicheCandidat })

                // Puce avancement réclamation
                let puceAvancement = { name: 'circle', class: 'text-tertiary', title: 'Réclamation non traitée' }
                if (result.canceled_at) {
                    puceAvancement = { name: 'times-circle', class: 'text-secondary', title: 'Réclamation annulée' }
                } else if (result.rejected_at) {
                    puceAvancement = { name: 'circle', class: 'text-danger', title: 'Réclamation rejetée' }
                } else if (result.submitted_at) {
                    puceAvancement = { name: 'circle', class: 'text-success', title: 'Réclamation traitée' }
                }

                // Puce status recorrection
                let puceRecorrection = { name: 'circle', class: 'text-secondary', libelle: 'Non demandé' }
                if (result.recorrection_asked_at && !result.recorrection_updated_at) {
                    puceRecorrection = { name: 'circle', class: 'text-info', libelle: 'Demandé' }
                } else if (result.recorrection_asked_at && result.recorrection_updated_at) {
                    puceRecorrection = { name: 'circle', class: 'text-success', libelle: 'Traitée' }
                }

                // Colonne commentaires
                let styleButtonComment = 'text-tertiary commons_comment_button text-center'
                let nameIcon = 'comment-alt'
                if (result.candidat.comments.length !== 0) {
                    styleButtonComment = 'commons_comment_button text-center'

                    if (result.candidat.comments.filter((c: any) => c.important === 1).length === 0) {
                        nameIcon = 'comment-alt'
                    } else {
                        nameIcon = 'comment-alt-exclamation'
                    }
                }
                let tooltipComments = ''
                for (const c in result.candidat.comments) {
                    tooltipComments += `---------------${result.candidat.comments[c].important ? 'TACHE A FAIRE' : 'COMMENTAIRE'}---------------\n`
                    tooltipComments += result.candidat.comments[c].body
                    tooltipComments += '\n'
                }
                // Marqueur de la réclamation
                let letterMark = '-'
                let letterStyle = 'text-center mark_style_none'
                if (result.marqueur !== '0' && result.marqueur !== null) {
                    letterMark = result.marqueur
                    letterStyle = 'text-center mark_style'
                }

                const noteBrute = result?.candidatEpreuve?.statut === CandidatStatut.PRESENT ? result.candidatEpreuve.note_brute : result?.candidatEpreuve?.statut === CandidatStatut.ABSENT ? 'Absent' : '-'
                const noteFinale = result?.candidatEpreuve?.statut === CandidatStatut.PRESENT ? result.candidatEpreuve.note_finale : result?.candidatEpreuve?.statut === CandidatStatut.ABSENT ? 'Absent' : '-'
                const link = [{ name:'arrow-circle-right', class: 'text-info', id: result.id, result: result.id }]
                const correcteurs = result?.candidatEpreuve?.batch_correction?.corrector_group?.correctors
                    .map((correcteur: any) => `${correcteur.first_name} ${correcteur.name}`)
                    .join(', ') || '-'

                const line = [
                    { label: 'Changer le marqueur', item: result.id,                       type: 'actionText', typeAction: 'mark',         class: letterStyle,             text: letterMark, disabled: false },
                    { label: tooltipComments,       item: result.candidat_id,              type: 'action',     typeAction: 'openComment',  class: styleButtonComment,      icon: nameIcon,   disabled: false },
                    { label: 'Fiche candidat',      item: result.candidat_id,              type: 'actionText', typeAction: 'editCandidat', class: 'text-info item_action', text: result.candidat.code },
                    { label: 'Fiche candidat',      item: result.candidat_id,              type: 'actionText', typeAction: 'editCandidat', class: 'text-info item_action', text: result.candidat.name + ' ' + result.candidat.first_name },
                    { label: '',                    item: result.candidat.concour.name,    type: 'text',       typeAction: null,           class: 'text-start' },
                    { label: '',                    item: result.epreuve.name,             type: 'text',       typeAction: null,           class: 'text-start' },
                    { label: '',                    item: correcteurs,                     type: 'text',       typeAction: null,           class: 'text-start reclamation_graylight_col' },
                    { label: '',                    item: noteBrute,                       type: 'text',       typeAction: null,           class: 'text-center reclamation_graylight_col' },
                    { label: '',                    item: noteFinale,                      type: 'text',       typeAction: null,           class: 'text-center reclamation_graylight_col' },
                    { label: '',                    item: result.note ? result.note : '-', type: 'text',       typeAction: null,           class: 'text-center' },
                    { label: '',                    item: [puceRecorrection],              type: 'icons',      typeAction: null,           class: 'text-center' },
                    { label: '',                    item: [puceAvancement],                type: 'icons',      typeAction: null,           class: 'text-center' },
                    { label: droit.label,           item: link,                            type: 'icons',      typeAction: 'edit',         class: 'text-center edit' }
                ]
                this.dataForTab.push(line)
            }
        }
    }

    /**
     * @description Formatage des datas pour l'affichage dans le tableau générique
     * @returns {void}
     */
    setFiltersForGenericTab(): void {
        // Options filières
        const filieres = this.$store.getters['concour/banques']
        const optionsFilieres = []
        for (const f in filieres) {
            optionsFilieres.push({ index: filieres[f].id, name: filieres[f].name })
        }
        // Options matières (épreuves)
        const matieres = this.$store.getters['matiere/matieres']
        const optionsMatieres = []
        for (const m in matieres) {
            optionsMatieres.push({ index: matieres[m].id, name: matieres[m].name })
        }

        const rootStore = this.$store.state.reclamation

        // Options recorrection
        const optionsReconnection: any = []
        Object.keys(TypeRecorrectionReclamation).slice(0, Math.floor(Object.keys(TypeRecorrectionReclamation).length / 2))
            .forEach((key: string) => {
                const typeReccorection = getTypeRecorrectionReclamation(parseInt(key))
                optionsReconnection.push({ ...typeReccorection, name: typeReccorection.libelle })
            })

        // Options avancements
        const optionsAvancement = []
        optionsAvancement.push({ index: Etat.ETAT_TRAITE, name: 'Réclamations traitées' })
        optionsAvancement.push({ index: Etat.ETAT_SIGNE, name: 'Réclamations signées' })
        optionsAvancement.push({ index: Etat.ETAT_NON_TRAITE, name: 'Réclamations non traitées' })
        optionsAvancement.push({ index: Etat.ETAT_REJETEE, name: 'Réclamations rejetées' })

        // Options filtres marqueurs
        const optionsMarqueurs = []
        optionsMarqueurs.push({ index: 'A', name: 'A' })
        optionsMarqueurs.push({ index: 'B', name: 'B' })
        optionsMarqueurs.push({ index: 'C', name: 'C' })
        optionsMarqueurs.push({ index: 'D', name: 'D' })
        optionsMarqueurs.push({ index: 'E', name: 'E' })
        optionsMarqueurs.push({ index: 'F', name: 'F' })

        this.filtres = [
            { libelle: 'Marqueur',     defautOptionlibelle: 'Rechercher un',  model: 'marqueur',            value: '', index: 'marqueur',            datas: optionsMarqueurs,   loading: rootStore.loading, options: { type: 'deroulant', fieldsKey: 'marqueur',           strict: true } },
            { libelle: 'Code',         defautOptionlibelle: 'Rechercher un',  model: 'candidat.code',       value: '', index: 'candidat.code',       datas: rootStore.code,      loading: rootStore.loading, options: { type: 'form',      fieldsKey: 'candidat.code',      strict: true } },
            { libelle: 'Identité',     defautOptionlibelle: 'Rechercher un',  model: 'candidat.name',       value: '', index: 'candidat.name',       datas: rootStore.identite,  loading: rootStore.loading, options: { type: 'form',      fieldsKey: 'candidat.name'                    } },
            { libelle: 'Filière',      defautOptionlibelle: 'Rechercher une', model: 'candidat.concour_id', value: '', index: 'candidat.concour_id', datas: optionsFilieres,    loading: rootStore.loading, options: { type: 'deroulant', fieldsKey: 'candidat.concour_id'              } },
            { libelle: 'Épreuves',     defautOptionlibelle: 'Rechercher une', model: 'epreuve.name',        value: '', index: 'epreuve.name',        datas: '',                  loading: rootStore.loading, options: { type: 'form',      fieldsKey: 'epreuve.name'                     } },
            { libelle: 'Recorrection', defautOptionlibelle: 'Rechercher une', model: 'recorrection',        value: '', index: 'recorrection',        datas: optionsReconnection, loading: rootStore.loading, options: { type: 'deroulant', fieldsKey: 'recorrection'                     } },
            { libelle: 'Avancement',   defautOptionlibelle: 'Rechercher un',  model: 'avancement',          value: '', index: 'avancement',          datas: optionsAvancement,  loading: rootStore.loading, options: { type: 'deroulant', fieldsKey: 'avancement'                       } }
        ]
    }

    /**
     * @description Récupération des events du tableau
     * @param {any} paParams - Paramètres de l'event (params[0] => action, params[1] => id de l'item)
     * @returns {void}
     */
    handleTableEvent(paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            const reclamations = this.$store.state.reclamation.reclamations_ecrit
            let selectedReclamation = null
            let selectedCandidat = null
            switch (paParams[0]) {
                case 'edit':
                    // Récupération de l'étab by ID
                    selectedReclamation = reclamations.filter((reclamation: any) => reclamation.id === paParams[1][0].result)[0]
                    if (selectedReclamation) {
                        this.$router.push(`/reclamations_ecrit/${selectedReclamation.id}`)
                    }
                    break
                case 'editCandidat':
                    // Récupération de l'étab by ID
                    this.tabSelectedCandidat = 'resultats'
                    selectedCandidat = reclamations.filter((r: any) => r.candidat_id === paParams[1])[0]
                    if (selectedCandidat) {
                        this.editCandidat(selectedCandidat.candidat)
                    }
                    break
                case 'openComment':
                    // Récupération de l'étab by ID
                    this.tabSelectedCandidat = 'commentaires'
                    selectedCandidat = reclamations.filter((r: any) => r.candidat_id === paParams[1])[0]
                    if (selectedCandidat) {
                        this.editCandidat(selectedCandidat.candidat)
                    }
                    break
                case 'mark':
                    this.$store.state.reclamation.error = null
                    selectedReclamation = reclamations.filter((r: any) => r.id === paParams[1])[0]
                    if (selectedReclamation) {
                        this.openMarkSelector(selectedReclamation)
                    }
                    break
                case 'sortHandler':
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
            }
        }
    }

    /**
     * @description Initialisation des statistiques réclamations de la phase "écrit"
     * @returns {void}
     */
    initStatsReclamations(): void {
        this.compteursReclamations = this.$store.getters['reclamation/getReclamations_ecrit_compteurs']
    }

    /**
     * @description Initialisation des données réclamations affichées dans le tableau
     * @returns {void}
     */
    initDatas(): void {
        this.setDataForGenericTab(this.$store.getters['reclamation/reclamations_ecrit'])
    }

    /**
     * @description Appel d'une nouvelle page de candidats lors du scroll
     * @param {any} params - Paramètres de la requête
     * @returns {void}
     */
    loadHandler(params: any): void {
        Vue.set(params, 'filter-type', TypeReclamation.TYPE_NOTE_ECRIT)
        this.$store.dispatch('reclamation/getMoreReclamations', { type: 'ECRIT', filters: params })
    }

    /**
     * @description Appel des datas avec un sort en paramètres
     * @param {any} params - Paramètres de la requête
     * @returns {void}
     */
    filtreSortHandler(params: any): void {
        Vue.set(params, 'filter-type', TypeReclamation.TYPE_NOTE_ECRIT)
        localStorage.setItem('reclamationEcritParams', JSON.stringify(params))
        this.$store.dispatch('reclamation/getReclamations', { type: 'ECRIT', filters: params })
    }

    /**
     * @description Edition d'un candidat: enregistrement du candidat en tant que candidat sélectionné et affichage de la modale
     * @param {any} item - Candidat à éditer
     * @returns {Promise<void>}
     */
    async editCandidat(item: any): Promise<void> {
        if (item && item.id !== undefined) {
            // On charge à partir de la BDD l'ensemble des informations du candidat sélectionné
            this.$store.commit('reclamation/SET_LOADING', true) // on lance le loading

            if (isEmpty(this.$store.getters['concour/concours'])) {
                await this.$store.dispatch('concour/getConcours', {
                    perPage: 0
                })
            }

            this.$store.dispatch('candidat/getCandidat', item)
                .then(() => {
                    const params = {
                        candidatId: item.id,
                        nomFiliere: item.filiere
                    }
                    this.$store.commit('candidat/SET_CANDIDAT_FILIERE', params)
                    this.$store.commit('candidat/SET_SELECTED_CANDIDAT', item.id)
                    this.showModalEditCandidat = true
                })
                .finally(() => {
                    this.$store.commit('reclamation/SET_LOADING', false) // on stoppe le loading
                })
        }
    }

    /**
     * @description Exporter les réclamations
     * @returns {void}
     */
    exporterReclamations(): void {
        this.exportingIsWorking = true
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours...', infosToaster)

        const params = {}
        Vue.set(params, 'filter-type', TypeReclamation.TYPE_NOTE_ECRIT)

        this.$store.dispatch('reclamation/export', params)
            .then((response) => {
                // Logique de téléchargement
                const link = document.createElement('a')
                link.href = URL.createObjectURL(new Blob([response.data]))
                link.setAttribute('Download', getFileNameFromHeader(response.headers) || "Export_reclamations.xlsx")
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.exportingIsWorking = false
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Exporter les statistiques réclamations
     * @returns {void}
     */
    exporterReclamationsStatistiques(): void {
        this.exportingIsWorking = true
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours...', infosToaster)

        const params = {}
        Vue.set(params, 'filter-type', TypeReclamation.TYPE_NOTE_ECRIT)

        this.$store.dispatch('reclamation/exportStatistiques', params)
            .then((response) => {
                // Logique de téléchargement
                const link = document.createElement('a')
                link.href = URL.createObjectURL(new Blob([response.data]))
                link.setAttribute('Download', getFileNameFromHeader(response.headers) || "Export_statistiques_reclamations.xlsx")
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.exportingIsWorking = false
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Récupération des réclamations depuis Viatique
     * @returns {void}
     */
    synchroniserReclamations(): void {
        const idInfo = 't_info_' + Math.random()
        this.$store.dispatch('reclamation/syncRecorrections')
            .then(() => {
                const infosToaster = {
                    id: idInfo,
                    toaster: 'b-toaster-top-right',
                    variant: 'primary',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 10000
                }
                this.$bvToast.toast('Synchronisation des réclamations en cours...', infosToaster)

                setTimeout(async () => {
                    await this.$store.dispatch('reclamation/getReclamations', {
                        type: 'ECRIT',
                        filters: JSON.parse(localStorage.getItem('reclamationEcritParams') || JSON.stringify({}))
                    })
                    await this.load()
                }, 10000)
            })
            .catch(() => {
                const infosToaster = {
                    id: idInfo,
                    toaster: 'b-toaster-top-right',
                    variant: 'danger',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Erreur lors de la synchronisation des réclamations.', infosToaster)
            })
    }

    /**
     * @description Ouvre la modale d'envoie des réclamations en recorrection
     * @returns {void}
     */
    openEnvoieRecorrection(): void {
        this.showModalEnvoieRecorrection = true
    }

    /**
     * @description Ajouter une réclamation
     * @returns {void}
     */
    openCreerReclamation(): void {
        this.showModalCreerReclamation = true
    }

    /**
     * @description Fermeture de la modale d'édition d'un candidat
     * @returns {void}
     */
    reinitShowModalEditCandidat(): void {
        this.showModalEditCandidat = false
    }

    /**
     * @description Fermeture de la modale d'ajout d'une réclamation'
     * @returns {Promise<void>}
     */
    async reinitShowModalCreerReclamation(): Promise<void> {
        this.$store.commit('reclamation/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée

        this.showModalCreerReclamation = false
        const params = {}
        Vue.set(params, 'filter-type', TypeReclamation.TYPE_NOTE_ECRIT)
        await this.$store.dispatch('reclamation/getReclamations', { type: 'ECRIT', filters: params })
        this.initDatas()
        this.initStatsReclamations()
    }

    /**
     * @description Ouvre le popup de sélection d'un marqueur
     * @param {any} reclamation - Réclamation à traiter
     * @returns {void}
     */
    openMarkSelector(reclamation: any): void {
        this.selectReclamation = reclamation
        this.avancementReclamation = this.getEtatAvancementReclamation()
        this.showModaleMark = true
    }

    /**
     * @description Récupère l'état d'avancement de la réclamation
     * @returns {Etat}
     */
    getEtatAvancementReclamation(): Etat {
        let etatFinal = Etat.ETAT_NON_TRAITE
        if (!this.selectReclamation.submitted_at && !this.selectReclamation.validated_at) {
            if (this.selectReclamation.message_gestionnaire || this.selectReclamation.note) {
                // Le traitement à commencer, mais n'est pas terminé
                etatFinal = Etat.ETAT_EN_COURS
            } else {
                etatFinal = Etat.ETAT_NON_TRAITE
            }
        }
        if (this.selectReclamation.submitted_at && !this.selectReclamation.validated_at) {
            etatFinal = Etat.ETAT_TRAITE
        }
        if (this.selectReclamation.submitted_at && this.selectReclamation.validated_at) {
            etatFinal = Etat.ETAT_SIGNE
        }
        return etatFinal
    }

    /**
     * @description Annule la modification du marqueur sur une réclamation
     * @param {boolean} withSave - Indique si la modification doit être sauvegardée
     * @returns {Promise<void>}
     */
    async closeModalMark(withSave: boolean): Promise<void> {
        this.showModaleMark = false
        this.selectReclamation = null

        if (withSave) {
            const params = {}
            Vue.set(params, 'filter-type', TypeReclamation.TYPE_NOTE_ECRIT)
            await this.$store.dispatch('reclamation/getReclamations', { type: 'ECRIT', filters: params })
            this.initDatas()
            this.initStatsReclamations()
        }
    }

    /**
     * @description Charge les données
     * @returns {Promise<void>}
     */
    async load(): Promise<void> {
        this.$store.commit('reclamation/SET_LOADING', true) // lancement du loading

        if (this.$store.getters['epreuve/epreuves'].length === 0) {
            await this.$store.dispatch('epreuve/getEpreuves', { isPrecedente: false, filters: {} })
        }

        // Récupération des concours
        if (this.$store.getters['concour/banques'].length === 0) {
            await this.$store.dispatch('concour/getConcoursActifs')
        }

        // Récupération des matières
        if (this.$store.getters['matiere/matieres'].length === 0) {
            await this.$store.dispatch('matiere/getMatieres')
        }

        // Construction des données si le store est déjà chargé
        if (this.$store.getters['reclamation/reclamations_ecrit'].length > 0) {
            this.initDatas()
            this.initStatsReclamations()
        }

        this.$store.commit('reclamation/SET_LOADING', false) // fin du loading
        this.setFiltersForGenericTab()
    }

    /**
     * @description Au montage du composant, on charge la session et les réclamations
     * @returns {Promise<void>}
     */
    async mounted(): Promise<void> {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            await this.load()
        }
    }
}
