









































































































































































































































































import { mapActions, mapGetters, mapState } from 'vuex'
import { Vue, Component, Watch } from 'vue-property-decorator'
import { isObject, formatDate, formatDateHoursMinutes, formatDateSinTime, getFileNameFromHeader, base64ToArrayBuffer, format_phone_number } from '@/utils/helpers'
import { Ability } from '@/types/Ability'
import _ from 'lodash'
import { Etat, getEtatSpecReclamation, TypeReclamation, TypeReponseReclamation } from '@/types/Reclamation'
import ReclamationsOralGestion from '@/views/Reclamations/ReclamationsOralGestion.vue'
import  ErrorDisplay from '@/components/ErrorDisplay.vue'
import Back from '@/components/Tools/Back.vue'
import PopupInfosEtDocumentsExaminateur from '@/views/Reclamations/PopupInfosEtDocumentsExaminateur.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { etat_avis } from '@/types/ReclamationOral'
import VuePdfApp from 'vue-pdf-app'

@Component({
    components: {
        ErrorDisplay,
        Back,
        ReclamationsOralGestion,
        PopupInfosEtDocumentsExaminateur,
        'font-awesome-icon': FontAwesomeIcon,
        VuePdfApp
    },
    computed: {
        EtatAvis() {
            return etat_avis
        },
        Ability() {
            return Ability
        },
        Etat() {
            return Etat
        },
        ...mapState('reclamationOral', ['reclamationOralSelect', 'loading', 'error']),
        ...mapGetters('reclamationType', ['reclamationTypes']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        totalReclamations(): number {
            return this.$store.getters['reclamationOral/meta']?.total || 0
        }
    },
    methods: {
        ...mapActions('postes', ['getAllPostes']),
        formatDateHoursMinutes,
        formatDateSinTime,
        format_phone_number
    }
})

export default class ReclamationOralGestion extends Vue {
    reclamation: any = {}
    etatReclamation: any = {}
    etatAvancement = Etat.ETAT_NON_TRAITE
    activerRectifiationNote = false
    lockInput = false
    connectedUser = this.$store.state.auth.user
    showModalDemandeAvis = false
    examinateur: any = null
    centre: any = null
    openAvis: any = null
    newMessage = ''
    fileSingleFromInput: any = null
    hasSelectedFile = false
    reclamationType: any = null
    reclamationTypeModal = false
    dataLoading = false
    popInfoExaminateurShow = false
    dataExaminateurTemp: any = null

    configVisionneuse = {
        toolbar: {
            toolbarViewerRight: {
                presentationMode: false,
                openFile: false,
                viewBookmark: false,
                secondaryToolbarToggle: false
            }
        }
    }

    pdfReclamationType: any = {
        name: null,
        content: null,
        id: null
    }

    @Watch('user_session_id')
    retourMainPage(): void {
        this.$router.push('/reclamations_oral').catch(() => { console.log('catch redundant navigation reclamation_oral') })
    }

    showPopInfoExaminateur(): void {
        this.dataExaminateurTemp = {
            examinateur: this.examinateur,
            centre: this.centre,
            matiere_id: this.reclamation.epreuve.matiere_id,
            ensemble: this.reclamation.seance ? this.$store.state.ensemble.ensembles.find((e: any) => e.id === this.reclamation.seance.creneau.ensemble_id) : null
        }
        this.popInfoExaminateurShow = true
    }

    /**
     * @description Aperçu de la fiche orale du candidat
     * @param {any} item
     * @returns {void}
     */
    voirFicheOral(item: any): void {
        if (item?.cle_epreuve_externe) {
            window.open(this.$store.getters['candidat/getCorrectionOnViatique'](item.cle_epreuve_externe), '_blank')
        }
    }

    closePopInfoExaminateur(): void {
        this.dataExaminateurTemp = null
        this.popInfoExaminateurShow = false
    }

    /**
     * @description Ouverture de la modale d'aperçu du modèle de réponse
     * @returns {Promise<void>}
     */
    async openReclamationTypeModal(): Promise<void> {
        if (this.reclamationType) {
            if (!this.pdfReclamationType.name || !this.pdfReclamationType.content || this.pdfReclamationType.id !== this.reclamationType.id) {
                const response = await this.$store.dispatch('reclamation/getPDFReponseReclamation', {
                    reclamation_id: this.reclamation.id,
                    params: {
                        reclamation_type: this.reclamationType.id
                    }
                })
                this.pdfReclamationType = {
                    name: getFileNameFromHeader(response.headers),
                    content: base64ToArrayBuffer(response.data),
                    id: this.reclamationType.id
                }
            }
            this.reclamationTypeModal = true
        }
    }

    /**
     * @description Fermeture de la modale d'aperçu du modèle de réponse
     * @returns {void}
     */
    closeReclamationTypeModal(): void {
        this.reclamationTypeModal = false
    }

    /**
     * @description Vérifie si la valeur est un objet
     * @param {any} value
     * @returns {boolean}
     */
    isObjectLocal(value: any): boolean {
        return isObject(value)
    }

    /**
     * @description Formattage de date
     * @param {Date} date
     * @returns {string}
     */
    formatteDate(date: Date): string | undefined {
        return formatDate(date)
    }

    /**
     * @description Enregistrement de la réclamation
     * @param {Etat} submit
     * @returns {void}
     */
    saveReclamation(submit: Etat = Etat.ETAT_EN_COURS): void {
        this.$store.commit('reclamationOral/SET_ERROR', null) // reset de l'erreur potentiellement existante

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        const params = {
            reclamation_id: this.reclamation.id,
            type: this.reclamation.type,
            payload: {
                message_gestionnaire: this.reclamation.message_gestionnaire,
                decision: 0,
                submit: submit,
                reclamation_type_id: this.reclamationType.id
            }
        }

        if (this.reclamationType.type_reponse === TypeReponseReclamation.TYPE_REPONSE_ACCEPTEE) {
            params.payload.decision = 1
        }

        if (submit !== Etat.ETAT_EN_COURS && this.reclamationType.type_reponse === TypeReponseReclamation.TYPE_REPONSE_REJETEE) {
            params.payload.submit = Etat.ETAT_REJETEE
        }

        this.$bvToast.toast('Enregistrement en cours...', infosToaster)
        this.$store.dispatch('reclamationOral/updateReclamation', params)
            .then((response) => {
                // Maj de la réclamation
                this.reclamation = Object.assign(this.reclamation, response.data.data)
                this.updateEtatReclamation()

                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé', succesToaster)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Ouvre la modale de demande d'avis
     * @param {boolean} open
     * @returns {void}
     */
    openDemanderUnAvis(open: boolean): void {
        this.$store.commit('reclamationOral/SET_ERROR', null) // reset de l'erreur potentiellement existante
        this.showModalDemandeAvis = true
        this.openAvis = open
    }

    /**
     * @description Confirmation de la demande d'avis à l'observateur
     * @returns {void}
     */
    confirmerDemandeAvis(): void {
        this.$store.commit('reclamationOral/SET_ERROR', null) // reset de l'erreur potentiellement existante

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        const params = {
            reclamation_id: this.reclamation.id,
            type: this.reclamation.type,
            payload: {
                etat: this.openAvis ? etat_avis.ETAT_PUBLIE_OBSERVATEUR : etat_avis.ETAT_CLOTURE_OBSERVATEUR
            }
        }

        this.$bvToast.toast('Enregistrement en cours...', infosToaster)
        this.$store.dispatch('reclamation/updateReclamation', params)
            .then((response) => {
                this.reclamation.etat = response.data.data.etat
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé', succesToaster)
                this.showModalDemandeAvis = false
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Annulation de la confirmation de la demande d'avis à l'observateur
     * @returns {void}
     */
    cancelDemandeAvis(): void {
        this.$store.commit('reclamationOral/SET_ERROR', null) // reset de l'erreur potentiellement existante
        this.showModalDemandeAvis = false
    }

    /**
     * @description Save un message dans la discussion
     * @returns {void}
     */
    sendMessage(): void {
        this.$store.commit('reclamationOral/SET_ERROR', null) // reset de l'erreur potentiellement existante

        const payload = new FormData()
        payload.set('content', this.newMessage)
        if (this.fileSingleFromInput) {
            payload.set('document', this.fileSingleFromInput)
        }

        this.$store.dispatch('reclamationOral/addMessage', { reclamation_id: this.reclamation.id, payload: payload }).then((response) => {
            // Maj de la reclamation select
            this.reclamation.messages = response.data.data.messages
            // Vide la zone de saisie de message
            this.newMessage = ''
            this.fileSingleFromInput = null
            this.hasSelectedFile = false
            this.$nextTick(() => {
                const element = document.querySelector('.gestion_reclamation_oral_box_chat_message_display')
                if (element) {
                    element.scrollTop = element.scrollHeight
                }
            })
        }).catch((error) => {
            console.log('ko:' + error)
        })
    }

    /**
     * @description Récupère l'index de la réclamation actuelle
     * @returns {number}
     */
    getIndexCurrentReclamation(): number {
        if (this.$store.getters['reclamationOral/reclamations_oral']) {
            return this.$store.getters['reclamationOral/reclamations_oral']
                .findIndex((reclamation: any) => reclamation.id === Number(this.$route.params.reclamation_id))
        }
        return 0
    }

    /**
     * @description Navigation vers la réclamation précédente
     * @returns {void}
     */
    async previousReclamation(): Promise<void> {
        const previousReclamation = this.$store.getters['reclamationOral/reclamations_oral'][this.getIndexCurrentReclamation() - 1] || null
        if (previousReclamation) {
            await this.$router.push('/reclamations_oral/' + previousReclamation.id)
        }
    }

    /**
     * @description Navigation vers la réclamation suivante
     * @returns {Promise<void>}
     */
    async nextReclamation(): Promise<void> {
        // Charger plus de réclamations si on approche de la limite par page
        const meta = this.$store.getters['reclamationOral/meta']
        const modulo = (this.getIndexCurrentReclamation() + 1) % meta.per_page
        let nextReclamation = this.$store.getters['reclamationOral/reclamations_oral'][this.getIndexCurrentReclamation() + 1] || null

        if (nextReclamation) {
            await this.$router.push('/reclamations_oral/' + nextReclamation.id)
        } else if (modulo === 0 && meta.current_page < meta.last_page) {
            const params = JSON.parse(localStorage.getItem('reclamationOralParams') || JSON.stringify({}))
            params.page = meta.current_page + 1

            await this.$store.dispatch('reclamationOral/getMoreReclamations', { type: 'ORAL', filters: params })

            localStorage.setItem('reclamationOralParams', JSON.stringify(params))
            meta.current_page = params.page
            this.$store.commit('reclamationOral/SET_META', meta)

            nextReclamation = this.$store.getters['reclamationOral/reclamations_oral'][this.getIndexCurrentReclamation() + 1] || null
            if (nextReclamation) {
                await this.$router.push('/reclamations_oral/' + nextReclamation.id)
            }
        }
    }

    /**
     * @description Init datas
     * @returns {Promise<void>}
     */
    @Watch('$route.params.reclamation_id')
    async initDatas(): Promise<void> {
        this.dataLoading = true
        this.reclamation = {}
        this.reclamationType = null
        this.connectedUser = this.$store.state.auth.user
        this.pdfReclamationType = {
            name: null,
            content: null,
            id: null
        }

        await this.$store.dispatch('reclamationOral/getReclamation', Number(this.$route.params.reclamation_id))
        this.reclamation = this.$store.state.reclamationOral.reclamationOralSelect
        if (this.reclamation) {
            if (this.$store.getters['reclamationOral/reclamations_oral'].length === 0) {
                const params = {
                    ...JSON.parse(localStorage.getItem('reclamationOralParams') || JSON.stringify({})),
                    page: 1,
                    'filter-type': TypeReclamation.TYPE_PASSATION_ORAL
                }
                await this.$store.dispatch('reclamationOral/getReclamations', { type: 'ORAL', filters: params })

                while (this.getIndexCurrentReclamation() === -1) {
                    params.page++
                    const response = await this.$store.dispatch('reclamationOral/getMoreReclamations', { type: 'ORAL', filters: params })
                    if (response.data.data.length === 0) {
                        break
                    }

                    const meta = this.$store.getters['reclamationOral/meta']
                    meta.current_page = params.page
                    this.$store.commit('reclamationOral/SET_META', meta)
                }
            }

            if (this.$store.getters['reclamationType/reclamationTypes'].length === 1) {
                this.reclamationType = this.$store.getters['reclamationType/reclamationTypes'][0]
            } else {
                this.reclamationType = this.$store.getters['reclamationType/getReclamationTypeById'](this.reclamation?.reclamationType?.id) || null
            }

            this.lockInput = !!this.reclamation.validated_at

            // Récupération du nom de l'examinateur
            if (this.reclamation.seance && this.reclamation.seance.creneau) {
                const ensemble = this.$store.state.ensemble.ensembles.find((e: any) => e.id === this.reclamation.seance.creneau.ensemble_id)
                if (ensemble) {
                    this.examinateur = ensemble.examinateurs.find((ex: any) => ex.id === this.reclamation.seance.creneau.user_id)
                    if (!this.examinateur) {
                        this.examinateur = ensemble.remplacants.find((ex: any) => ex.id === this.reclamation.seance.creneau.user_id)
                    }

                    this.centre = this.reclamation.seance.creneau.ensemble.centre
                }
            }

            this.updateEtatReclamation();
            this.dataLoading = false

            await this.$nextTick()
            const element = document.querySelector('.gestion_reclamation_oral_box_chat_message_display')
            if (element) {
                element.scrollTop = element.scrollHeight
            }
        }
    }

    /**
     * @description Met à jour l'état de la réclamation
     * @returns {void}
     */
    updateEtatReclamation(): void {
        if (this.reclamation.canceled_at) {
            this.etatReclamation = getEtatSpecReclamation(Etat.ETAT_ANNULEE)
        } else if (this.reclamation.rejected_at) {
            this.etatReclamation = getEtatSpecReclamation(Etat.ETAT_REJETEE)
        } else if (this.reclamation.submitted_at) {
            this.etatReclamation = getEtatSpecReclamation(Etat.ETAT_TRAITE)
        } else {
            this.etatReclamation = getEtatSpecReclamation(Etat.ETAT_NON_TRAITE)
        }
    }

    /**
     * @description Ajoute un fichier au message
     * @param {Event} e
     * @returns {void}
     */
    addFileToMessage(e: Event): void {
        if (e.target !== null) {
            const target = e.target as HTMLInputElement
            const file: File = (target.files as FileList)[0]
            if (file) {
                this.fileSingleFromInput = file
                this.hasSelectedFile = true
                if (!this.newMessage) { // on n'écrase pas l'éventuel message en cours de rédaction
                    this.newMessage = 'Pièce jointe'
                }
            }
        }
    }

    /**
     * @description Delete la pj
     * @returns {void}
     */
    deleteFile(): void {
        this.fileSingleFromInput = null
        this.hasSelectedFile = false
    }

    /**
     * @description Download Pj d'un message
     * @param {any} message
     * @returns {void}
     */
    downloadPJ(message: any): void {
        const params = { format: 'b64', reclamation_id: this.reclamation.id, message_id: message.id }
        const pdfFileName = message.documents[0].name

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Téléchargement en cours ...', infosToaster)

        this.$store.dispatch('reclamationOral/dl_pj_message', params)
            .then((response: any) => {
                const link = document.createElement('a')
                link.href = URL.createObjectURL(new Blob([base64ToArrayBuffer(response.data)]))
                link.setAttribute(
                    'Download',
                    getFileNameFromHeader(response.headers) || pdfFileName
                )
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Retourne le libellé de l'état de la conversation avec l'observateur
     * @param {EtatAvis} etat
     * @returns {string}
     */
    getLibelleDiscussion(etat: etat_avis): string {
        if (etat === etat_avis.ETAT_PUBLIE_OBSERVATEUR) {
            return 'Un avis a été demandé à l\'observateur'
        } else if (etat === etat_avis.ETAT_NON_PUBLIEE) {
            return 'Aucun avis n\'a été demandé à l\'observateur'
        } else if (etat === etat_avis.ETAT_CLOTURE_OBSERVATEUR) {
            return 'La conversation avec l\'observateur est clôturée'
        }
        return '-'
    }

    /**
     * @description Montage du composant
     * @returns {Promise<void>}
     */
    async mounted(): Promise<void> {
        this.dataLoading = true
        if (this.$store.state.ensemble.ensembles.length === 0) {
            await this.$store.dispatch('ensemble/getEnsembles')
        }
        await this.$store.dispatch('reclamationType/getReclamationTypes', { 'filter-type_reclamation': TypeReclamation.TYPE_PASSATION_ORAL })
        await this.initDatas()
    }
}
