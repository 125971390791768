
























































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapState } from 'vuex'
import { type_publishable_message } from '@/types/PublishableMessage'
import { isEmpty } from 'lodash';
const Tinymce = () => import('vue-tinymce-editor')

@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        type_publishable_message() {
            return type_publishable_message
        },
        ...mapState('pv', ['pvSelect', 'loading', 'error', 'source_pdf', 'bloc_insertion'])
    }
})
export default class EditorTinyMCE extends Vue {
    @Prop() content?: string
    @Prop() mode?: string
    @Prop() editor_id?: string
    @Prop() with_apercu?: boolean
    @Prop() readonly?: boolean
    @Prop() toolbarCustom?: string
    @Prop() link_title?: string

    richText = null
    isInitEditor = false
    blocInsert = ''
    cursor_in_editor = true
    empty_editor = false
    options = {
        plugin: 'textpattern',
        height: '47vh',
        resize: true,
        textpattern_patterns: [
            { start: '*', end: '*', format: '' },
            { start: '**', end: '**', format: '' }
        ]
    }

    toolbarDefault = 'table | formatselect |  bold italic strikethrough forecolor backcolor fontsizeselect | link | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | code'

    get editor () {
        return Tinymce
    }

    @Watch('bloc_insertion')
    setBlocInsert () {
        this.blocInsert = this.$store.state.pv.bloc_insertion
    }

    @Watch('content')
    setContent () {
        this.cleanEditor()
        if (this.$props.content && this.isInitEditor) {
            (this.$refs.tm as any).editor.setContent(this.$props.content)
            this.scrollTop()
        }
    }

    @Watch('richText')
    setRichTextContrat () {
        if (this.$props.mode === 'contrat' || this.$props.mode === 'templateNotif' || this.$props.mode === 'templateCopie' || this.$props.mode === 'convention' || this.$props.mode === 'centre' || this.$props.mode.startsWith('templateParamGestionPortailClient') || this.$props.mode === 'templateParamGestionPortailClientPlanning' || this.$props.mode === 'html_pdf' || this.$props.mode.startsWith('templateParamGestionPortailClientResultat')) {
            this.enregistrer()
        }
        if (this.$props.mode === 'bloc_annexe') {
            this.$emit('enregistrer', { bloc_id: this.$props.editor_id, content: (this.$refs.tm as any).editor.getContent() })
        }
        if (this.$props.mode === 'pv') {
            this.$emit('modif_datas', (this.$refs.tm as any).editor.getContent())
        }
    }

    @Watch('richText', { immediate: true, deep: true })
    onRichTextUpdate() {
        this.empty_editor = isEmpty(this.richText);
    }

    // Check si l'éditeur est initialisé
    isInit () {
        this.isInitEditor = true
        this.cleanEditor()
        if (this.$props.content) {
            (this.$refs.tm as any).editor.insertContent(this.$props.content)
            this.scrollTop()
        }
    }

    // Insère un bloc de texte sélectionné et le place au dernier positionnement connu du curseur
    insertText () {
        (this.$refs.tm as any).editor.insertContent(this.blocInsert)
    }

    cleanEditor () {
        if ((this.$refs.tm as any).editor) {
            (this.$refs.tm as any).editor.setContent('')
        }
    }

    scrollTop () {
        (this.$refs.tm as any).editor.getWin().scrollTo(0, 0)
    }

    // Recupere le rich text
    enregistrer () {
        this.$emit('enregistrer', (this.$refs.tm as any).editor.getContent())
    }

    // Aperçu du pv en pdf
    apercu () {
        this.$emit('apercu', (this.$refs.tm as any).editor.getContent())
    }

    // Charge les données selon sélection du bloc
    setBloc (e: any) {
        this.blocInsert = ''
        switch (e.target.value) {
            case 'calendrier':
                this.$store.dispatch('pv/getBlocCalendrier', this.$store.state.pv.pvSelect.id)
                break
            case 'nouvelle_candidature':
                this.$store.dispatch('pv/getBlocNouvelleCandidature', this.$store.state.pv.pvSelect.id)
                break
            case 'non_reconduction':
                this.$store.dispatch('pv/getBlocNonReconduction', this.$store.state.pv.pvSelect.id)
                break
            case 'besoin':
                this.$store.dispatch('pv/getBlocBesoin', this.$store.state.pv.pvSelect.id)
                break
            case 'annexe':
                this.$store.dispatch('pv/getBlocAnnexe', this.$store.state.pv.pvSelect.id)
                break
            case 'signature':
                this.blocInsert = '***signature***'
                break
            case 'saut_page':
                this.blocInsert = '***saut_de_page***'
                break
            case 'logo':
                this.blocInsert = '***logo***'
                break
        }
    }

    // Charge les données bloc pour contrattype
    setBloc_contrat (e: any) {
        this.blocInsert = ''
        switch (e.target.value) {
            case 'logo':
                this.blocInsert = '***logo***'
                break
            case 'signature':
                this.blocInsert = '***signature***'
                break
            case 'signature_intervenant':
                this.blocInsert = '***signature_intervenant***'
                break
            case 'saut_page':
                this.blocInsert = '***saut_de_page***'
                break
                // Données intervenant
            case 'civilite':
                this.blocInsert = '***civilite***'
                break
            case 'nom':
                this.blocInsert = '***nom***'
                break
            case 'prenom':
                this.blocInsert = '***prenom***'
                break
            case 'date_naissance':
                this.blocInsert = '***date_naissance***'
                break
            case 'lieu_naissance':
                this.blocInsert = '***lieu_naissance***'
                break
            case 'nationalite':
                this.blocInsert = '***nationalite***'
                break
            case 'numero_secu':
                this.blocInsert = '***numero_secu***'
                break
            case 'adresse_postale':
                this.blocInsert = '***adresse***'
                break
            case 'activite_principale':
                this.blocInsert = '***activite_principale***'
                break
            case 'remuneration1':
                this.blocInsert = '***remuneration1***'
                break
            case 'remuneration2':
                this.blocInsert = '***remuneration2***'
                break
            case 'remuneration3':
                this.blocInsert = '***remuneration3***'
                break
            case 'date':
                this.blocInsert = '***date***'
                break
            case 'contenu_libre':
                this.blocInsert = '***contenu_libre_avenant***'
                break
            case 'centre':
                this.blocInsert = '***centre***'
                break
            case 'equipe':
                this.blocInsert = '***equipe***'
                break
            case 'Centre.name':
                this.blocInsert = '***Centre.name***'
                break
            case 'Centre.adresse_principale.adresse':
                this.blocInsert = '***Centre.adresse_principale.adresse***'
                break
            case 'Centre.adresse_convocation.adresse':
                this.blocInsert = '***Centre.adresse_convocation.adresse***'
                break
        }
    }

    // Charge les données bloc pour conventiontype
    setBloc_convention (e: any) {
        this.blocInsert = ''
        switch (e.target.value) {
            case 'logo':
                this.blocInsert = '***logo***'
                break
            case 'signature':
                this.blocInsert = '***signature***'
                break
            case 'signature_responsable':
                this.blocInsert = '***signature_responsable***'
                break
            case 'saut_page':
                this.blocInsert = '***saut_de_page***'
                break
                // Données centre
            case 'centre_nom':
                this.blocInsert = '***centre_nom***'
                break
            case 'centre_adresse_principale':
                this.blocInsert = '***centre_adresse_principale***'
                break
            case 'responsable_nom_complet':
                this.blocInsert = '***responsable_nom_complet***'
                break
            case 'blocs_annexe_financiere_redige_par_gestionnaire':
                this.blocInsert = '***blocs_annexe_financiere_redige_par_gestionnaire***'
                break
            case 'montant_annexe_financiere':
                this.blocInsert = '***montant_annexe_financiere***'
                break
        }
    }

    // Charge les données bloc pour notification
    setBloc_template_notif (e: any) {
        this.blocInsert = ''
        switch (e.target.value) {
            case 'logo':
                this.blocInsert = '***logo***'
                break
            case 'posteAffectation':
                this.blocInsert = '***posteAffectation***'
                break
            case 'concours':
                this.blocInsert = '***concours***'
                break
            case 'matiere':
                this.blocInsert = '***matiere***'
                break
            case 'deMatiere':
                this.blocInsert = '***deMatiere***'
                break
            case 'posteQuotite':
                this.blocInsert = '***posteQuotite***'
                break
            case 'posteLongQuotite':
                this.blocInsert = '***posteLongQuotite***'
                break
            case 'posteCompletLongQuotite':
                this.blocInsert = '***posteCompletLongQuotite***'
                break
            case 'posteSouhait':
                this.blocInsert = '***posteSouhait***'
                break
            case 'dePosteSouhait':
                this.blocInsert = '***dePosteSouhait***'
                break
            case 'posteSouhaitComplet':
                this.blocInsert = '***posteSouhaitComplet***'
                break
            case 'posteAffectationComplet':
                this.blocInsert = '***posteAffectationComplet***'
                break
            case 'User.civility':
                this.blocInsert = '***User.civility***'
                break
            case 'candidat_long_civility':
                this.blocInsert = '***candidat_long_civility***'
                break
            case 'User.first_name':
                this.blocInsert = '***User.first_name***'
                break
            case 'User.name':
                this.blocInsert = '***User.name***'
                break
            case 'date':
                this.blocInsert = '***date***'
                break
            case 'DossierAcademique.commentaire_lettre':
                this.blocInsert = '***DossierAcademique.commentaire_lettre***'
                break
            case 'signature':
                this.blocInsert = '***signature***'
                break
            case 'boutons_reponse_affectation':
                this.blocInsert = '***boutons_reponse_affectation***'
                break
            case 'message_gestionnaire':
                this.blocInsert = '***message_gestionnaire***'
                break
            case 'centre':
                this.blocInsert = '***centre***'
                break
            case 'equipe':
                this.blocInsert = '***equipe***'
                break
        }
    }

    // Charge les données bloc pour notification
    setBloc_template_copie (e: any) {
        this.blocInsert = ''
        switch (e.target.value) {
            case 'logo':
                this.blocInsert = '***logo***'
                break
            case 'titre':
                this.blocInsert = '***titre***'
                break
            case 'date_jour':
                this.blocInsert = '***date_jour***'
                break
            case 'type_phase':
                this.blocInsert = '***type_phase***'
                break
            case 'tableau_copies':
                this.blocInsert = '***tableau_copies***'
                break
            case 'candidat.code':
                this.blocInsert = '***candidat.code***'
                break
            case 'dossierInscription.civilite':
                this.blocInsert = '***dossierInscription.civilite***'
                break
            case 'dossierInscription.user.name':
                this.blocInsert = '***dossierInscription.user.name***'
                break
            case 'dossierInscription.user.first_name':
                this.blocInsert = '***dossierInscription.user.first_name***'
                break
            case 'dossierInscription.adressepostale':
                this.blocInsert = '***dossierInscription.adressepostale***'
                break
            case 'dossierInscription.codepostaladresse':
                this.blocInsert = '***dossierInscription.codepostaladresse***'
                break
            case 'dossierInscription.localitepostale':
                this.blocInsert = '***dossierInscription.localitepostale***'
                break
            case 'session_code':
                this.blocInsert = '***session_code***'
                break
            case 'concour.long_name':
                this.blocInsert = '***concour.long_name***'
                break
        }
    }

    // Charge les données bloc pour param gestion portail client
    setBloc_template_param_gestion_portail_client (e: any) {
        this.blocInsert = ''
        switch (e.target.value) {
            case 'logo':
                this.blocInsert = '***logo***'
                break
            case 'saut_page':
                this.blocInsert = '***saut_de_page***'
                break
            case 'serie_date_debut':
                this.blocInsert = '***serie_date_debut***'
                break
            case 'serie_date_fin':
                this.blocInsert = '***serie_date_fin***'
                break
            case 'serie_nom':
                this.blocInsert = '***serie_nom***'
                break
            case 'centre_epreuves_interclassement':
                this.blocInsert = '***centre_epreuves_interclassement***'
                break
            case 'session_code':
                this.blocInsert = '***session_code***'
                break
            case 'candidat_code':
                this.blocInsert = '***candidat_code***'
                break
            case 'candidat_civilite':
                this.blocInsert = '***candidat_civilite***'
                break
            case 'candidat_nom':
                this.blocInsert = '***candidat_nom***'
                break
            case 'candidat_prenom':
                this.blocInsert = '***candidat_prenom***'
                break
            case 'candidat_date_naissance':
                this.blocInsert = '***candidat_date_naissance***'
                break
            case 'candidat_bonification':
                this.blocInsert = '***candidat_bonification***'
                break
            case 'message_candidat':
                this.blocInsert = '***message_candidat***'
                break
            case 'message_candidat_avec_email_contact':
                this.blocInsert = '***message_candidat_avec_email_contact***'
                break
            case 'tableau_planning':
                this.blocInsert = '***tableau_planning***'
                break
            case 'tableau_planning_avec_salles':
                this.blocInsert = '***tableau_planning_avec_salles***'
                break
            case 'centres_oral':
                this.blocInsert = '***centres_oral***'
                break
        }
    }

    // Charge les données bloc pour param gestion portail client error
    setBloc_template_param_gestion_portail_client_error (e: any) {
        this.blocInsert = ''
        switch (e.target.value) {
            case 'publication_name':
                this.blocInsert = '***publication_name***'
                break
        }
    }

    // Charge les données bloc pour param gestion portail client resultat admissibilite
    setBloc_template_param_gestion_portail_client_resultat (e: any) {
        this.blocInsert = ''
        switch (e.target.value) {
            case 'logo':
                this.blocInsert = '***logo***'
                break
            case 'titre':
                this.blocInsert = '***titre***'
                break
            case 'date_jour':
                this.blocInsert = '***date_jour***'
                break
            case 'admissibilite':
                this.blocInsert = '***admissibilite***'
                break
            case 'phase_bonification_admissibilite':
                this.blocInsert = '***phase_bonification_admissibilite***'
                break
            case 'decision_avec_rang':
                this.blocInsert = '***decision_avec_rang***'
                break
            case 'type_phase':
                this.blocInsert = '***type_phase***'
                break
            case 'tableau_notes':
                this.blocInsert = '***tableau_notes***'
                break
            case 'tableau_notes_banque':
                this.blocInsert = '***tableau_notes_banque***'
                break
            case 'bouton_pdf':
                this.blocInsert = '***bouton_pdf***'
                break
            case 'candidat.code':
                this.blocInsert = '***candidat.code***'
                break
            case 'dossierInscription.civilite':
                this.blocInsert = '***dossierInscription.civilite***'
                break
            case 'dossierInscription.user.name':
                this.blocInsert = '***dossierInscription.user.name***'
                break
            case 'dossierInscription.user.first_name':
                this.blocInsert = '***dossierInscription.user.first_name***'
                break
            case 'dossierInscription.adressepostale':
                this.blocInsert = '***dossierInscription.adressepostale***'
                break
            case 'dossierInscription.codepostaladresse':
                this.blocInsert = '***dossierInscription.codepostaladresse***'
                break
            case 'dossierInscription.localitepostale':
                this.blocInsert = '***dossierInscription.localitepostale***'
                break
            case 'candidat_bonification':
                this.blocInsert = '***candidat_bonification***'
                break
            case 'session_code':
                this.blocInsert = '***session_code***'
                break
            case 'concour.long_name':
                this.blocInsert = '***concour.long_name***'
                break
            case 'points':
                this.blocInsert = '***points***'
                break
            case 'max_points':
                this.blocInsert = '***max_points***'
                break
            case 'threshold':
                this.blocInsert = '***threshold***'
                break
        }
    }
}
