


















































































import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { BModal } from 'bootstrap-vue'
import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { Ability } from '../../types/Ability'
import { JobDescriptionStatus } from '../../types/JobDescription'
import { dateisSameOrBefore, formatDate, formatDateVariante } from '../../utils/helpers'
import PopupEditUser from '../../components/Administration/Users/PopupEditUser.vue'
import ErrorDisplay from '../../components/ErrorDisplay.vue'
import CreateEtab from '../../components/BourseEmploi/CreateEtab.vue'
import PopupHistorique from '../../components/BourseEmploi/Popup/PopupHistorique.vue'
import PopupImport from '../../components/BourseEmploi/Popup/PopupImport.vue'
import { isEmpty } from 'lodash';

@Component({
    name: 'ListeEtablissementsComponent',
    components: {
        PopupImport,
        ExaGenericTable,
        PopupEditUser,
        FontAwesomeIcon,
        ErrorDisplay,
        BModal,
        PopupHistorique,
        CreateEtab
    },
    computed: {
        canEdit(): boolean {
            const can = this.$store.getters['auth/can']
            return can(Ability.ADM_ESTABLISHMENT_MANAGE) || can(Ability.EST_ESTABLISHMENT_PARENT_OWN_MANAGE)
        },
        ...mapGetters('workingEstablishment', ['workingEstablishments', 'loading', 'totalRows', 'lastPage', 'totalPage', 'error']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapGetters('session', ['sessionSelect']),
        totalEtablissements(): number {
            return this.$store.getters['workingEstablishment/meta']?.total || 0
        }
    },
    watch: {
        workingEstablishments: {
            handler: 'onWorkingEstablishmentsChange'
        }
    }
})

export default class ListeEtablissements extends Vue {
    Ability = Ability
    filtres = [] as any
    filtreJustInitiated = true
    dataForTab = [] as Array<any>
    params = null as any
    job_descriptions_start_at = null as any
    job_descriptions_end_at = null as any
    formatDate = formatDate
    formatDateVariante = formatDateVariante
    genericfields = [] as any
    last_save = null as any
    showModalEditionEstablishment = false
    showModalMessageDelete = false
    establishment = null as any

    /**
     * @description Mise à jour du tableau des utilisateurs
     * @returns {Promise<void>}
     */
    async onWorkingEstablishmentsChange(): Promise<void> {
        await this.setDataForGenericTab(this.$store.getters['workingEstablishment/workingEstablishments'])
    }

    buildGenericfields () {
        this.genericfields = [
            { key: 'edit', label: '', sortable: false, class: '', type: 'action' },
            { key: 'code', label: 'Finess ET', sortable: true, class: '', type: 'text' },
            { key: 'name', label: 'Nom', sortable: true, class: '', type: 'text' },
            { key: 'adress', label: 'Adresse', sortable: true, class: '', type: 'text' },
            { key: 'code_postal', label: 'Code postal', sortable: true, class: '', type: 'text' },
            { key: 'ville', label: 'Ville', sortable: true, class: '', type: 'text' },
            { key: 'working_establishment_type_id', label: 'Type', sortable: true, class: '', type: 'text' },
            { key: 'working_establishment_parent_name', label: 'Dépendance', sortable: true, class: '', type: 'text' },
            { key: 'updated_at',  label: 'Dernière modification', sortable: true, sortDirection: 'asc', class: 'ps-5' },
            { key: 'delete', label: '', sortable: false, class: '', type: 'action' }
        ]

        const can = this.$store.getters['auth/can']
        if (!can(Ability.ADM_ESTABLISHMENT_MANAGE) && !can(Ability.EST_ESTABLISHMENT_OWN_MANAGE) && !can(Ability.EST_ESTABLISHMENT_PARENT_OWN_MANAGE)) {
            this.genericfields.splice(0, 1)
        }
    }

    /**
     * @description Remplissage du tableau des établissements
     * @param {any} poData - Données à afficher
     * @param {boolean} isLoadMore - Indique si on charge plus de données
     * @returns {Promise<void>}
     */
    async setDataForGenericTab(poData: any, isLoadMore = false): Promise<void> {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            const can = this.$store.getters['auth/can']
            if (!can(Ability.ADM_ESTABLISHMENT_MANAGE) && isEmpty(this.$store.getters['user/reportingUserSelect'])) {
                await this.$store.dispatch('user/getReportingUser', { user_id: this.$store.getters['auth/authUser']?.id })
            }
            const workingEstablishmentsIds = this.$store.getters['user/reportingUserSelect']?.working_establishments?.map((item: any) => item.id)

            for (const result of poData) {
                let deleteIsDisabled = false
                if (result.jobDescriptions && result.jobDescriptions.length > 0) {
                    deleteIsDisabled = result.jobDescriptions.some((item: any) => item.status !== JobDescriptionStatus.STATUS_DRAFT)
                }

                const canEdit = can(Ability.ADM_ESTABLISHMENT_MANAGE) || can(Ability.EST_ESTABLISHMENT_PARENT_OWN_MANAGE) || (can(Ability.EST_ESTABLISHMENT_OWN_MANAGE) && workingEstablishmentsIds?.includes(result.id))
                const line: any = [
                    { label: canEdit ? 'Éditer' : 'Voir', item: result, type: 'action', typeAction: 'edit', class: 'commons_first_action_button', icon: canEdit ? 'pen' : 'eye', disabled: false },
                    { label: '', item: result.code, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.name, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.adress, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.code_postal, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.ville, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.workingEstablishmentType?.name || '-', type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.workingEstablishmentParent?.name || '-', type: 'text', typeAction: null, class: '' }
                ]

                if ((this.last_save === null && result.updated_at) || (this.last_save && result.updated_at && dateisSameOrBefore(this.last_save, result.updated_at))) {
                    this.last_save = result.updated_at
                }

                const updated_at = (result.updated_at ? formatDateVariante(result.updated_at) : ' - ')
                const updated_at_icon = [{
                    name: 'clock',
                    class: 'text-info text-small text-start',
                    title: updated_at,
                    value_comp: updated_at,
                    typeAction: 'popupHistorique',
                    item: result
                }]
                line.push({ label: '', item: updated_at_icon, type: 'icons', typeAction: 'popupHistorique', class: 'text-center' })

                line.push({
                    label: deleteIsDisabled ? 'Impossible de supprimer un établissement qui possède au moins une fiche de poste avec un statut différent de "brouillon".' : result.workingEstablishmentChildren?.length ? 'Cet établissement ne peut pas être supprimé car il possède au moins un établissement dépendant' : 'Supprimer',
                    item: canEdit ? result : null,
                    type: canEdit ? 'action' : 'text',
                    typeAction: deleteIsDisabled || result.workingEstablishmentChildren?.length ? null : 'delete',
                    class: `text-secondary text-end ${deleteIsDisabled || result.workingEstablishmentChildren?.length ? 'action-disabled' : ''}`,
                    icon: canEdit ? 'trash-alt' : '',
                    disabled: !canEdit
                })

                if (!can(Ability.ADM_ESTABLISHMENT_MANAGE) && !can(Ability.EST_ESTABLISHMENT_OWN_MANAGE) && !can(Ability.EST_ESTABLISHMENT_PARENT_OWN_MANAGE)) {
                    line.splice(0, 1)
                }

                this.dataForTab.push(line)
            }
        }
    }

    /**
     * @description Initialisation des filtres du tableau
     * @returns {void}
     */
    setFiltersForGenericTab(): void {
        const typesEstablishment = this.$store.getters['workingEstablishmentType/workingEstablishmentTypes']
            .map((e: any) => ({ index: e.id, name: e.name }))

        this.filtres = [
            { libelle: 'Numéro Finess ET', defautOptionlibelle: 'Rechercher un',  model: 'code', value: '', index: 'code', datas: null, loading: this.$store.getters['workingEstablishment/loading'], options: { type: 'form', fieldsKey: 'code' }},
            { libelle: 'Nom', defautOptionlibelle: 'Rechercher un',  model: 'name', value: '', index: 'name', datas: null, loading: this.$store.getters['workingEstablishment/loading'], options: { type: 'form', fieldsKey: 'name' }},
            { libelle: 'adress', defautOptionlibelle: 'Rechercher une', model: 'adress', value: '', index: 'adress', datas: null, loading: this.$store.getters['workingEstablishment/loading'], options: { type: 'form', fieldsKey: 'adress' }},
            { libelle: 'Code postal', defautOptionlibelle: 'Rechercher un',  model: 'code_postal', value: '', index: 'code_postal', datas: null, loading: this.$store.getters['workingEstablishment/loading'], options: { type: 'form', fieldsKey: 'code_postal' }},
            { libelle: 'Ville', defautOptionlibelle: 'Rechercher une', model: 'ville', value: '', index: 'ville', datas: null, loading: this.$store.getters['workingEstablishment/loading'], options: { type: 'form', fieldsKey: 'ville' }},
            { libelle: 'Type d\'établissement', defautOptionlibelle: 'Rechercher un', model: 'working_establishment_type_id', value: '', index: 'working_establishment_type_id', datas: typesEstablishment, loading: this.$store.getters['workingEstablishment/loading'], options: { type: 'deroulant', fieldsKey: 'working_establishment_type_id' }},
            { libelle: 'Dépendance', defautOptionlibelle: 'Rechercher une', model: 'working_establishment_parent_name', value: '', index: 'working_establishment_parent_name', datas: typesEstablishment, loading: this.$store.getters['workingEstablishment/loading'], options: { type: 'form', fieldsKey: 'working_establishment_parent_name' }}
        ]
    }

    /**
     * @description Gestion des événements du tableau
     * @param {any} paParams - Paramètres de l'événement
     * @returns {Promise<void>}
     */
    async handleTableEvent(paParams: any): Promise<void> {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'edit':
                    await this.$router.push(`/bourse_emploi/etablissements/${paParams[1].id}`)
                    break

                case 'sortHandler':
                case 'filterHandler':
                    await this.filtreSortHandler(paParams[1])
                    break

                case 'onLoadPage':
                    await this.loadHandler(paParams[1])
                    break

                case 'delete':
                    await this.deleteEstablishment(paParams[1])
                    break

                case 'popupHistorique':
                    (this.$refs.historique as PopupHistorique).open({working_establishment_id: paParams[1][0].item.id})
                    break
            }
        }
    }

    openAddEstablishment () {
        this.showModalEditionEstablishment = true
    }

    editEstablishment (data: any) {
        this.establishment = Object.assign({}, data)
        this.showModalEditionEstablishment = true
    }

    cancelEdit () {
        this.establishment = null
        this.showModalEditionEstablishment = false
    }

    editSuite () {
        this.$store.dispatch('workingEstablishment/getWorkingEstablishments', this.params).then(async () => {
            await this.setDataForGenericTab(this.$store.getters['workingEstablishment/workingEstablishments'])
            this.cancelEdit()
        })
    }

    deleteEstablishment(data: any) {
        this.establishment = Object.assign({}, data)
        this.showModalMessageDelete = true
    }

    cancelDelete () {
        this.establishment = null
        this.showModalMessageDelete = false
    }

    deleteSuite () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Suppression en cours...', infosToaster)
        this.$store.dispatch('workingEstablishment/deleteWorkingEstablishment', this.establishment.id)
            .then(() => {
                this.establishment = null
                this.showModalEditionEstablishment = false
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Establishment supprimé avec succès !', succesToaster)
                this.establishment = null
                this.showModalMessageDelete = false
                this.$store.dispatch('workingEstablishment/getWorkingEstablishments', this.params).then(async () => {
                    await this.setDataForGenericTab(this.$store.getters['workingEstablishment/workingEstablishments'])
                })
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Applique des filtres
     * @param {any} params - Paramètres de filtre
     * @returns {Promise<void>}
     */
    async filtreSortHandler(params: any): Promise<void> {
        if (this.filtreJustInitiated) {
            this.filtreJustInitiated = false
        } else {
            this.params = params
            await this.$store.dispatch('workingEstablishment/getWorkingEstablishments', this.params)
        }
    }

    /**
     * @description Complément des données sur un scroll
     * @param {any} params - Paramètres de chargement
     * @returns {Promise<void>}
     */
    async loadHandler(params: any): Promise<void> {
        this.params = params
        await this.$store.dispatch('workingEstablishment/getMoreWorkingEstablishments', this.params)
    }

    /**
     * @description Avant le montage du composant
     * @returns {Promise<void>}
     */
    async beforeMount(): Promise<void> {
        this.params = {
            page: 1,
            sort: 'name',
            direction: 'asc'
        }
        await this.$store.dispatch('workingEstablishment/getWorkingEstablishments', this.params)
        const workingEstablishments = this.$store.getters['workingEstablishment/workingEstablishments']
        this.$store.commit('workingEstablishment/SET_SKIP_LIST', false)
        const cannot = this.$store.getters['auth/cannot']
        if (cannot(Ability.ADM_ESTABLISHMENT_MANAGE) && cannot(Ability.EST_ESTABLISHMENT_PARENT_OWN_VIEW) && workingEstablishments.length === 1) {
            this.$store.commit('workingEstablishment/SET_SKIP_LIST', true)
            await this.$router.push(`/bourse_emploi/etablissements/${workingEstablishments[0].id}`)
        }

        if (isEmpty(this.$store.getters['workingEstablishmentType/workingEstablishmentTypes'])) {
            await this.$store.dispatch('workingEstablishmentType/getWorkingEstablishmentTypes')
        }

        if (!this.$store.getters['auth/can'](Ability.ADM_ESTABLISHMENT_MANAGE) && isEmpty(this.$store.getters['user/reportingUserSelect'])) {
            await this.$store.dispatch('user/getReportingUser', { user_id: this.$store.getters['auth/authUser']?.id })
        }

        this.buildGenericfields()
        this.setFiltersForGenericTab()
    }
}
