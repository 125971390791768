














































import { EtatSeriePublie } from '@/types/Planning'
import { dateIsBetween, dateisSameOrBefore } from '@/utils/helpers'
import { Vue, Component }         from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'
import { Ability } from '@/types/Ability'

@Component({
    components: {
    },
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('session', ['sessionSelect']),
        ...mapState('user', ['incompats_open', 'dispos_open']),
        ...mapState('serie', ['series'])
    },
    methods: {
        ...mapActions('dossieracademique', ['updateDossierAcademique'])
    }
})
export default class ModuleGestionOral extends Vue {
    Ability = Ability
    today = Date.now()
    incompatibilite_open = false
    disponibilites_open = false
    plannings_open = false

    /** Check si les incompatibilites, disponibilites sont ouverte à la saisie + accés au planning des oraux */
    checkOpening () {
        const session_active = this.$store.getters['session/sessionSelect']
        // Indisponibilités
        if (session_active.incompatibilites_start_at || session_active.incompatibilites_end_at) {
            if (dateIsBetween(this.today, session_active.incompatibilites_start_at, session_active.incompatibilites_end_at, '..')) {
                this.incompatibilite_open = true
            } else if (!session_active.incompatibilites_start_at && dateisSameOrBefore(this.today, session_active.incompatibilites_end_at)) {
                this.incompatibilite_open = true
            } else {
                this.incompatibilite_open = false
            }
        } else {
            this.incompatibilite_open = false
        }

        this.$store.commit('user/SET_INCOMPATS_OPEN', this.incompatibilite_open)

        // Disponibilités
        if (session_active.disponibilites_start_at || session_active.disponibilites_end_at) {
            if (dateIsBetween(this.today, session_active.disponibilites_start_at, session_active.disponibilites_end_at, '..')) {
                this.disponibilites_open = true
            } else if (!session_active.disponibilites_start_at && dateisSameOrBefore(this.today, session_active.disponibilites_end_at)) {
                this.disponibilites_open = true
            } else {
                this.disponibilites_open = false
            }
        } else {
            this.disponibilites_open = false
        }

        this.$store.commit('user/SET_DISPOS_OPEN', this.disponibilites_open)

        // Plannings
        // Check sur toutes les séries si au moins une est publiée pour ouvrir l'accès au planning
        for (const s in this.$store.state.serie.series) {
            const serie = this.$store.state.serie.series[s]
            if (serie.publie === EtatSeriePublie.PUBLIER) {
                this.plannings_open = true
            }
        }

        this.$store.commit('user/SET_PLANNING_OPEN', this.plannings_open)
    }

    load () {
        if (!this.$store.getters['session/sessionSelect']) {
            this.$store.dispatch('session/getSession', { session_id: this.$store.getters['auth/user_session_id'] }).then(() => {
                this.$store.dispatch('serie/getSeries').then(() => {
                    this.checkOpening()
                })
            })
        } else {
            this.$store.dispatch('serie/getSeries').then(() => {
                this.checkOpening()
            })
        }
    }

    beforeMount() {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}

