export interface SujetInterface {
    session_id: number | 0,
    name: string,
    code: string,
    reference: string,
    id: number | 0,
    couleur_id: number | 0,
    couleur?: object,
    is_impression_couleur: number | 1,
    nb_pages: number | 5,
    poids: number | 667,
    calculatrice_autorise: number | 1,
    epreuve_ids: Array<number> | [],
    format: FormatSujet | null,
    type: TypeSujet | null,
    sujet_id: number | null,
    sujet?: SujetInterface | null,
    sujets?: Array<SujetInterface> | []
}

export interface StateSujets {
    sujets: Array<SujetInterface> | [],
    previsionnelSujets: Array<any> | [],
    meta: Array<string> | null,
    links: Array<string> | null,
    loading: boolean | false,
    error: Array<string> | null,
    totalRows: number | 0,
    sujetSelect: SujetInterface | {}
}

export enum TypeSujet {
    TYPE_ENONCE,
    TYPE_DOC_REPONSE
}

export function getTypeSujet(type: TypeSujet): { index: number; libelle: string } {
    switch (type) {
        case TypeSujet.TYPE_ENONCE:
            return { index: TypeSujet.TYPE_ENONCE, libelle: 'Enoncé' }
        case TypeSujet.TYPE_DOC_REPONSE:
            return { index: TypeSujet.TYPE_DOC_REPONSE, libelle: 'Document réponse' }
    }
}

export enum FormatSujet {
    A4,
    A4_AGRAFE,
    A3_PLIE
}

export function getFormatSujet(format: FormatSujet): { index: number; libelle: string } {
    switch (format) {
        case FormatSujet.A4:
            return { index: FormatSujet.A4, libelle: 'A4' }
        case FormatSujet.A4_AGRAFE:
            return { index: FormatSujet.A4_AGRAFE, libelle: 'A4 agrafé' }
        case FormatSujet.A3_PLIE:
            return { index: FormatSujet.A3_PLIE, libelle: 'A3 plié' }
    }
}
