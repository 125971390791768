










































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import AdressesCentre from '@/components/Centre/AdressesCentre.vue'
import MessageCandidatsCentre from '@/components/Centre/MessageCandidatsCentre.vue'
import PopupSubmitInformations from '@/components/Centre/Validations/PopupSubmitInformations.vue'
import { Ability } from '@/types/Ability'
import { formatDate } from '@/utils/helpers'
import MessageGeneralIntervenant from '@/components/MessageGeneralIntervenant.vue'


@Component({
    components: {
        AdressesCentre,
        MessageCandidatsCentre,
        PopupSubmitInformations,
        MessageGeneralIntervenant
    },
    computed: {
        ...mapState('centre', ['centreSelect', 'error', 'loading']),
        ...mapGetters('centre', ['informations_is_submit', 'informations_is_validate', 'etat_centre_select']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('auth', ['is_intervenant_centre'])
    }
})
export default class Informations extends Vue {
    Ability = Ability
    showPopupSubmitInformations = false

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    /** Ouvre le popup de confirmation de soumission des adresses et du message aux candidats */
    submitInformations () {
        this.showPopupSubmitInformations = true
    }

    /** Formatage les dates */
    formatteDate (date: Date) {
        return formatDate(date)
    }

    /** Reset showPopups */
    resetPopup () {
        this.showPopupSubmitInformations = false
    }

    /** Retourne une classe css selon l'état de validation des infos */
    getClassBarreBoutons () {
        let class_css = 'barre_default'

        if (this.$store.getters['centre/informations_is_submit'] && !this.$store.getters['centre/informations_is_validate']) {
            class_css = 'barre_soumis'
        } else if (this.$store.getters['centre/informations_is_submit'] && this.$store.getters['centre/informations_is_validate']) {
            class_css = 'barre_valide'
        } else if (this.$store.getters['centre/has_commentaire_rejet_informations']) {
            class_css = 'barre_rejet'
        }

        return class_css
    }

    load () {
        this.$store.dispatch('banque/getBanques', { isPrecedente: false }).then(() => {
            this.$store.dispatch('centre/getCentre', { centre_id: this.$route.params.centre_id })
        })
    }

    beforeMount () {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
