/*
const TYPE_PRINCIPALE = 1;
const TYPE_LIVRAISON_SUJET = 2;
const TYPE_CONVOCATION_CANDIDAT = 4;
const TYPE_LIVRAISION_DOCUMENT = 8;
const TYPE_ENLEVEMENT_COPIE = 16;

*/

export enum TypeAdresseCentre {
    TYPE_PRINCIPALE = 1,
    TYPE_LIVRAISON_SUJET = 2,
    TYPE_CONVOCATION_CANDIDAT = 4,
    TYPE_LIVRAISION_DOCUMENT = 8,
    TYPE_ENLEVEMENT_COPIE = 16
}

interface TypeAdresseCentreSpec {
    index: number,
    libelle: string
}

export function getTypeAdresseCentreSpec(typeCentre: TypeAdresseCentre): TypeAdresseCentreSpec {
    switch (typeCentre) {
        case TypeAdresseCentre.TYPE_PRINCIPALE:
            return {index: 1, libelle: 'Principale'}
        case TypeAdresseCentre.TYPE_LIVRAISON_SUJET:
            return {index: 2, libelle: 'Livraison des sujets'}
        case TypeAdresseCentre.TYPE_CONVOCATION_CANDIDAT:
            return {index: 4, libelle: 'Convocation des candidats'}
        case TypeAdresseCentre.TYPE_LIVRAISION_DOCUMENT:
            return {index: 8, libelle: 'Livraison des documents'}
        case TypeAdresseCentre.TYPE_ENLEVEMENT_COPIE:
            return {index: 16, libelle: 'Enlèvement des copies'}
        default:
            return {index: 1, libelle: 'Principale'}
    }
}

export interface AdresseCentreInterface {
    id: number | 0,
    type: number | 0,
    adresse: string
}

export interface StateAdresseCentres {
    adresseCentres: Array<AdresseCentreInterface> | [],
    meta: Array<string> | null,
    links: Array<string> | null,
    loading: boolean | false,
    error: Array<string> | null,
    totalRows: number | 0,
    currentPage: number | 0,
    lastPage: number | 0,
    totalPage: number | 0,
    adresseCentreSelect: AdresseCentreInterface | {}
}
