






















































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { PosteContext, PosteType } from '@/types/Poste'
import { Ability } from '@/types/Ability'
import { applique_operator, formatDate, isObject } from '@/utils/helpers'
import { MessageIndicationType } from '@/types/MessageIndicationType'
import PopupRejetNbIntervenants from '@/components/Centre/Validations/PopupRejetNbIntervenants.vue'
import PopupValideNbIntervenants from '@/components/Centre/Validations/PopupValideNbIntervenants.vue'
import PopupSubmitNbIntervenants from '@/components/Centre/Validations/PopupSubmitNbIntervenants.vue'
import MessageIndication from '@/components/MessageIndication.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        MessageIndication,
        PopupSubmitNbIntervenants,
        PopupRejetNbIntervenants,
        PopupValideNbIntervenants,
        ErrorDisplay
    },
    computed: {
        ...mapState('centre', ['meta', 'error', 'centreSelect', 'loading']),
        ...mapGetters('centre', ['salles_is_submit', 'salles_is_validate', 'nb_intervenants_is_submit', 'nb_intervenants_is_validate']),
        ...mapGetters('auth', ['is_intervenant_centre']),
        ...mapState('session', ['sessionSelect']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapState('organisationecrit', ['besoins_centre', 'besoins_precedent', 'get_total_intervenants_precedent', 'get_total_intervenants', 'get_total_candidats', 'has_error_code'])
    }
})
export default class SaisieIntervenants extends Vue {
    @Prop() fresh?: boolean
    dicoPostes: any = []

    Ability = Ability
    MessageIndicationType = MessageIndicationType
    poste_chef_centre_id = -1
    inputs_nb: {[index: string ]: any} = {}

    PosteContext = PosteContext

    session_en_cours: any = null
    session_precedente: any = null
    showPopupSubmitEcrit = false
    showPopupRejetEcrit = false
    showPopupValidationEcrit = false
    invalideEcrit = false
    regles_orga: any = null


    refreshCollec () {
        if (this.$props.fresh) {
            // this.initComponent()
        }
    }

    @Watch('fresh')
    @Watch('besoins_centre')
    checkIsEmpty () {
        if (this.$store.state.organisationecrit.besoins_centre.length === 0) {
            this.initComponent()
        } else {
            // Init des valeurs des inputs number
            for (const g in this.$store.state.organisationecrit.besoins_centre) {
                this.inputs_nb[this.$store.state.organisationecrit.besoins_centre[g].poste_id] = this.$store.state.organisationecrit.besoins_centre[g].nb
            }

            this.check_new_postes()
        }
    }

    @Watch('error')
    updateError () {
        this.isObject()
    }

    /** Check le type du retour d'erreur */
    isObject () {
        return isObject(this.$store.state.centre.error)
    }

    updateChamps (e: any) {
        const datas = e.target.name.split('_')
        const besoin_poste = { poste_id: Number(datas[1]), nb: Number(e.target.value), regroupement: datas[2] }
        this.$store.commit('organisationecrit/UPDATE_BESOINS_CENTRE', besoin_poste)
        this.$store.state.centre.centreSelect.besoins = this.$store.state.organisationecrit.besoins_centre
    }

    /** Initialise les besoins intervenants */
    initBesoinsIntervenants () {
        // Initialisation des besoins par postes
        if ((this.$store.state.centre.centreSelect.besoins === null || (this.$store.state.centre.centreSelect.besoins && this.$store.state.centre.centreSelect.besoins.length === 0)) && this.$store.state.organisationecrit.besoins_centre.length === 0) {
            for (const g in this.dicoPostes) {
                for (const p in this.dicoPostes[g]) {
                    if (this.dicoPostes[g][p].context === PosteContext.CONTEXT_CHEF_CENTRE) {
                        this.$store.commit('organisationecrit/ADD_LIGNE_BESOINS_CENTRE', { poste_id: this.dicoPostes[g][p].id, nb: 1, regroupement: this.dicoPostes[g][p].regroupement })
                        this.poste_chef_centre_id = this.dicoPostes[g][p].id
                        this.inputs_nb[this.dicoPostes[g][p].id] = 1
                    } else {
                        this.$store.commit('organisationecrit/ADD_LIGNE_BESOINS_CENTRE', { poste_id: this.dicoPostes[g][p].id, nb: 0, regroupement: this.dicoPostes[g][p].regroupement })
                        this.inputs_nb[this.dicoPostes[g][p].id] = 0
                    }
                }
            }
        } else {
            // Récupère les regroupements
            for (const b in this.$store.state.organisationecrit.besoins_centre) {
                const poste_s = this.$store.state.poste.postes.find((p: any) => p.id === this.$store.state.organisationecrit.besoins_centre[b].poste_id)
                this.$store.state.organisationecrit.besoins_centre[b].regroupement = poste_s.regroupement
            }
        }

        if (this.$store.state.organisationecrit.besoins_centre.length !== 0) {
            for (const b in this.$store.state.organisationecrit.besoins_centre) {
                if (this.$store.state.organisationecrit.besoins_centre[b].poste_id === this.poste_chef_centre_id) {
                    this.inputs_nb[this.$store.state.organisationecrit.besoins_centre[b].poste_id] = 1
                } else {
                    this.inputs_nb[this.$store.state.organisationecrit.besoins_centre[b].poste_id] = this.$store.state.organisationecrit.besoins_centre[b].nb
                }
            }
        }
        this.$store.state.centre.error = null
    }

    /** Calcule le total du nombre d'intervenants de la session précédente */
    getTotalIntervenantsPrecedent (groupe: string) {
        return this.$store.getters['organisationecrit/get_total_intervenants_precedent'](groupe)
    }

    /** Calcule le total du nombre d'intervenants de la session en cours */
    getTotalIntervenants (groupe: string) {
        if (this.$store.state.organisationecrit.besoins_centre.length !== 0) {
            // Check si le poste est existant
            for (const b in this.$store.state.organisationecrit.besoins_centre) {
                const besoin = this.$store.state.organisationecrit.besoins_centre[b]
                if (besoin.regroupement === groupe) {
                    const check_poste_exist = this.dicoPostes[groupe].find((p: any) => p.id === besoin.poste_id)

                    if (check_poste_exist === undefined) {
                        this.$store.state.organisationecrit.besoins_centre.splice(b, 1)
                    }
                }

            }
            return this.$store.getters['organisationecrit/get_total_intervenants'](groupe)
        }

    }

    /** Retourne l'évolution des intervenants entre deux sessions */
    getEvolutionTotalIntervenants (groupe: string) {
        let evolution = ''
        const result = this.getTotalIntervenants(groupe) - this.getTotalIntervenantsPrecedent(groupe)

        if (this.getTotalIntervenantsPrecedent(groupe) < this.getTotalIntervenants(groupe)) {
            evolution = '<div class="fw-bold text-success">+' + result + '</div>'
        }
        if (this.getTotalIntervenantsPrecedent(groupe) > this.getTotalIntervenants(groupe)) {
            evolution = '<div class="fw-bold text-danger"><font-awesome-icon icon="minus"/>' + result + '</div>'
        }
        if (this.getTotalIntervenantsPrecedent(groupe) === this.getTotalIntervenants(groupe)) {
            evolution = '<div class="fw-bold text-dark">=</div>'
        }

        return evolution
    }

    /** Retourne l'évolution des intervenants sur un poste entre deux sessions */
    getEvolutionIntervenantsPoste (poste_id: number) {
        let evolution = ''
        let nb_poste = 0
        let nb_poste_precedent = 0
        const poste_n = this.$store.state.organisationecrit.besoins_centre.find((b: any) => b.poste_id === poste_id)
        const poste_n_1 = this.$store.state.organisationecrit.besoins_precedent.find((b: any) => b.poste_id === poste_id)
        if (poste_n) {
            nb_poste = poste_n.nb ? Number(poste_n.nb) : 0
        }
        if (poste_n_1) {
            nb_poste_precedent = poste_n_1.nb ? Number(poste_n_1.nb) : 0
        }
        const result = nb_poste - nb_poste_precedent

        if (nb_poste_precedent < nb_poste) {
            evolution = '<div class="fw-bold text-success">+' + result + '</div>'
            return evolution
        }
        if (nb_poste_precedent > nb_poste) {
            evolution = '<div class="fw-bold text-danger"><font-awesome-icon icon="minus"/>' + result + '</div>'
            return evolution
        }
        if (nb_poste_precedent === nb_poste) {
            evolution = '<div class="fw-bold text-dark">=</div>'
            return evolution
        }
    }

    /** Retourne le nombre de candidats de la session N-1 */
    getTotalCandidatsPrec () {
        if (this.$store.state.centre.centreSelect.precedent) {
            return this.$store.getters['organisationecrit/get_total_candidats'](this.$store.state.centre.centreSelect.precedent)
        } else {
            return 0
        }
    }

    /** Retourne le nombre de candidats de la session en cours : Estimation ou réel selon état du centre */
    getTotalCandidats () {
        if (this.$store.state.centre.centreSelect) {
            return this.$store.getters['organisationecrit/get_total_candidats'](this.$store.state.centre.centreSelect)
        } else {
            return 0
        }
    }

    /** Retourne l'évolution du total des candidats entre les deux sessions */
    getEvolutionTotalCandidats () {
        let evolution = ''
        const result = this.getTotalCandidats() - this.getTotalCandidatsPrec()

        if (this.getTotalCandidatsPrec() < this.getTotalCandidats()) {
            evolution = '<div class="fw-bold text-success">+' + result + '</div>'
        }
        if (this.getTotalCandidatsPrec() > this.getTotalCandidats()) {
            evolution = '<div class="fw-bold text-danger"><font-awesome-icon icon="minus"/>' + result + '</div>'
        }
        if (this.getTotalCandidatsPrec() === this.getTotalCandidats()) {
            evolution = '<div class="fw-bold text-dark">=</div>'
        }

        return evolution
    }

    /** Check les cas ou l'input peut etre disabled */
    isDisabled (poste: any) {
        if (!this.$store.getters['auth/can'](Ability.GCI_ORGA_OWN_MANAGE, 'App\\Models\\Centre', this.$store.getters['centre/centreSelect']) && !this.$store.getters['auth/can'](Ability.GC_CENTER_MANAGE)) {
            return true
        } else if ((this.$store.getters['centre/orga_is_validate']) || (this.$store.getters['auth/is_intervenant_centre'] && this.$store.getters['centre/orga_is_submit']) || (!this.$store.getters['auth/can'](Ability.GC_CENTER_MANAGE) && !this.$store.getters['auth/is_intervenant_centre'])) {
            return true
        } else {
            if ((this.$store.getters['centre/nb_intervenants_is_submit'] && this.$store.getters['auth/is_intervenant_centre']) || (this.$store.getters['centre/nb_intervenants_is_validate'])) {
                return true
            }
            //  Check si on à pas une restriction de remplissage
            if (poste.options_affectation && poste.options_affectation.editableByAbility) {
                return !this.$store.getters['auth/can'](poste.options_affectation.editableByAbility);
            }
            return false
        }
    }

    /** Check si le poste à un max de remplissage */
    hasMax (poste: any) {
        if (poste.options_affectation && poste.options_affectation.max) {
            return poste.options_affectation.max
        } else {
            return null
        }
    }

    /** Formattage les dates */
    formatteDate (date: Date) {
        return formatDate(date)
    }

    /** Initialisation des datas du composant */
    initComponent () {
        this.$store.dispatch('poste/getPostes', { type: PosteType.TYPE_LOGISTIQUE, context: (PosteContext.CONTEXT_AFFECTATION + PosteContext.CONTEXT_CHEF_CENTRE) }).then(() => {
            this.dicoPostes = this.$store.getters['poste/posteByRegroupement']
            if (!this.$store.state.centre.centreSelect.besoins) {
                this.$store.commit('organisationecrit/SET_BESOINS_CENTRE', [])
                this.initBesoinsIntervenants()
            }

            // Init des valeurs des inputs number
            for (const g in this.$store.state.organisationecrit.besoins_centre) {
                this.inputs_nb[this.$store.state.organisationecrit.besoins_centre[g].poste_id] = this.$store.state.organisationecrit.besoins_centre[g].nb
            }
            this.check_new_postes()
        })
    }

    /** Retourne une classe css selon l'état de validation des infos */
    getClassBarreBoutons () {
        let class_css = 'barre_default'

        if (this.$store.getters['centre/nb_intervenants_is_submit'] && !this.$store.getters['centre/nb_intervenants_is_validate']) {
            class_css = 'barre_soumis'
        } else if (this.$store.getters['centre/nb_intervenants_is_submit'] && this.$store.getters['centre/nb_intervenants_is_validate']) {
            class_css = 'barre_valide'
        } else if (this.$store.getters['centre/has_commentaire_rejet_ecrit']('besoins')) {
            class_css = 'barre_rejet'
        }

        return class_css
    }

    /** Sauvegarde des deux collections : intervenants */
    save () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('organisationecrit/manageOrganisationEcrit', { params: { mode: 'nb_interv', centre_id: this.$store.getters['centre/centreSelect'].id, besoins_submit: null, besoins_validate: null } })
            .then((response) => {
                this.$store.state.centre.centreSelect.besoins = response.data.data.besoins
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /** Ouvre le popup de confirmation de soumission de l'organisation écrite */
    submitEcrit () {
        // this.$store.state.organisationecrit.error = null // reset de l'erreur potentielle déjà affichée
        this.$store.commit('organisationecrit/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
        this.invalideEcrit = false
        this.showPopupSubmitEcrit = true
    }

    /** Message de confirmation de validation de l'organisation des écrits (Salles et intervenants) */
    validerEcrit () {
        // this.$store.state.organisationecrit.error = null // reset de l'erreur potentielle déjà affichée
        this.$store.commit('organisationecrit/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
        this.showPopupValidationEcrit = true
    }

    /** Message de confirmation de rejet de la saisie du gestionnaire pour l'organisation des écrits */
    rejeterEcrit () {
        // this.$store.state.organisationecrit.error = null // reset de l'erreur potentielle déjà affichée
        this.$store.commit('organisationecrit/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
        this.showPopupRejetEcrit = true
    }

    /** Message de confirmation de validation de l'organisation des écrits (Salles et intervenants) */
    invaliderEcrit () {
        // this.$store.state.organisationecrit.error = null // reset de l'erreur potentielle déjà affichée
        this.$store.commit('organisationecrit/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
        this.invalideEcrit = true
        this.showPopupValidationEcrit = true
    }

    /** Reset showPopups */
    resetPopup () {
        // this.$store.state.organisationecrit.error = null // reset de l'erreur potentielle de retour de popup
        this.$store.commit('organisationecrit/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
        this.showPopupSubmitEcrit = false
        this.showPopupValidationEcrit = false
        this.showPopupRejetEcrit = false
        this.invalideEcrit = false
    }

    // Check l'application des règles de gestions
    check_application_regle () {
        const messages: any = []
        if (this.regles_orga && this.$store.state.centre.centreSelect.besoins !== null) {
            // On check l'application de la règle
            this.regles_orga.forEach((regle: any) => {
                if (regle.actif) {
                // Compte les éléments à comparer
                    let compare1: any

                    if (regle.path1.indexOf('[]') !== -1) {
                        // C'est un tableau
                        const key = regle.path1.replace('[]', '')
                        if (regle.path1Property && this.$store.state.centre.centreSelect[key]) {
                            // Filtre
                            compare1 = this.$store.state.centre.centreSelect[key].filter((i: any) => i[regle.path1Property] === regle.path1Value)

                            if (regle.path1CountProperty) {
                                // On parcourt la collection
                                let count = 0
                                compare1.forEach((item: any) => {
                                    count += item[regle.path1CountProperty]
                                })

                                compare1 = count
                            } else {
                                // on somme la collection
                                compare1 = compare1.length
                            }
                        } else {
                            // Parcours sans filtres
                            compare1 = this.$store.state.centre.centreSelect[key]

                            if (regle.path1CountProperty && compare1) {
                                // On parcourt la collection
                                let count = 0
                                compare1.forEach((item: any) => {
                                    count += item[regle.path1CountProperty]
                                })

                                compare1 = count
                            } else {
                                // on somme la collection
                                compare1 = compare1 ? compare1.length : 0
                            }
                        }
                    } else {
                        compare1 = this.$store.state.centre.centreSelect[regle.path1]
                    }

                    // Isole le comparateur
                    let compare2: any

                    if (regle.path2.indexOf('[]') !== -1) {
                        // C'est un tableau
                        const key = regle.path2.replace('[]', '')
                        if (regle.path2Property && this.$store.state.centre.centreSelect[key]) {
                            // Filtre
                            compare2 = this.$store.state.centre.centreSelect[key].filter((i: any) => i[regle.path2Property] === regle.path2Value)

                            if (regle.path2CountProperty && compare2) {
                                // On parcourt la collection
                                let count = 0
                                compare2.forEach((item: any) => {
                                    count += item[regle.path2CountProperty]
                                })

                                compare2 = count
                            } else {
                                // on somme la collection
                                compare2 = compare2 ? compare2.length : 0
                            }
                        } else {
                            // Parcours sans filtres
                            compare2 = this.$store.state.centre.centreSelect[key]

                            if (regle.path2CountProperty && compare2) {
                                // On parcourt la collection
                                let count = 0
                                compare2.forEach((item: any) => {
                                    count += item[regle.path2CountProperty]
                                })

                                compare2 = count
                            } else {
                                // on somme la collection
                                compare2 = compare2 ? compare2.length : 0
                            }
                        }
                    } else {
                        compare2 = this.$store.state.centre.centreSelect[regle.path2]
                    }

                    // Applique les comparaisons avec le seuil à vérifier
                    let check_compare = compare1 / compare2
                    if (!applique_operator(check_compare, regle.value, regle.operator)) {
                        // Règle non appliquée
                        if (compare1 === 0 || compare2 === 0) {
                            check_compare = 0
                        }
                        messages.push('Règle non appliquée - ' + regle.name + ' : ' + regle.value + ', actuel : ' + (check_compare !== 0 ? Math.round(check_compare * 100) / 100 : 0))
                    }
                }
            })
        }

        return messages
    }

    // Check si de nouveaux postes ne sont pas dans la collection des besoins
    check_new_postes () {
        for (const g in this.dicoPostes) {
            for (const p in this.dicoPostes[g]) {
                const check_exist_besoin = this.$store.state.organisationecrit.besoins_centre.find((b: any) => b.poste_id === this.dicoPostes[g][p].id)

                if (check_exist_besoin === undefined) {
                    // Le poste n'existe pas on le rajoute
                    this.$store.commit('organisationecrit/ADD_LIGNE_BESOINS_CENTRE', { poste_id: this.dicoPostes[g][p].id, nb: 0, regroupement: this.dicoPostes[g][p].regroupement })
                    this.inputs_nb[this.dicoPostes[g][p].id] = 0
                }
            }
        }
    }

    mounted () {
        if (this.$store.state.session.sessions) {
            this.session_en_cours = this.$store.state.session.sessions.find((s: any) => s.id === this.$store.getters['auth/user_session_id'])
            this.session_precedente = this.$store.state.session.sessions.find((s: any) => s.id === this.session_en_cours.session_id)
            this.regles_orga = this.session_en_cours && this.session_en_cours.regles ? this.session_en_cours.regles : []
        }
        this.initComponent()
    }
}
