



































import { Vue, Component, Watch }                                    from 'vue-property-decorator'
import { mapGetters, mapState }                                               from 'vuex'
import store                                                        from '@/store/index'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import PopupEditSuppleant                                           from '@/components/Suppleant/PopupEditSuppleant.vue'
import { Ability }                                                  from '@/types/Ability'
import { isObject, formatDate, formatDateShort, checkIcone }        from '@/utils/helpers'
import { TypeEnsemble }                                             from '@/types/Ensemble'
import { FontAwesomeIcon }                                          from '@fortawesome/vue-fontawesome'
import ErrorDisplay                                                 from '@/components/ErrorDisplay.vue'
import PopUpInfoIntervenant from '@/components/PopupInfoIntervenant.vue'

@Component({
    components: {
        ExaGenericTable,
        PopupEditSuppleant,
        ErrorDisplay,
        PopUpInfoIntervenant,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('suppleant', ['loading', 'listeSuppleant', 'listeIntervenants', 'meta', 'links', 'totalRows', 'lastPage', 'totalPage']),
        ...mapGetters('session', ['sessionSelect', 'error']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('serie', ['series'])
    }
})
export default class Suppleant extends Vue {
    // DATAS
    filtres:    any                         = []
    dataForTab: Array<any>                  = []
    Ability                                 = Ability
    totalSuppleantFiltered                  = 0 // pour l'affichage du total réel contenu dans le tableau des affectations affiché (filtrées et on filtrées)
    activeSession: any                      = {}
    firstInit                               = true
    formatDate                              = formatDate
    formatDateShort                         = formatDateShort
    loadingLocal                            = true
    toggleModalePopupEditSuppleant          = false
    affectationSelect: Array<any>           = []
    affectationSelectFormattedInfos: any    = []

    showModalInfoIntervenant = false
    user_id_temp = 0

    paramsLocal = {
        page: 1,
        sort: 'ensemble.name',
        direction: 'asc'
    }

    // Ensemble des colonnes du tableau des affectations
    genericfields = [
        { key: 'etatEdit',                  label: '',                      sortable: false,    class: '',                  type: 'action' },
        { key: 'centre.name',               label: 'Centre d\'oral',        sortable: true,     class: '',                  type: 'text' },
        { key: 'ensemble.name',             label: 'Équipe',                sortable: true,     class: 'text-center',       type: 'text' },
        { key: 'user.name', label: 'Examinateur', sortable: true, sortDirection: 'asc', class: 'text-start ps-4',  type: 'actionText'  },
        { key: 'remplacant_suppleant_name', label: 'Remplaçant', sortable: true, class: 'text-start ps-4',  type: 'actionText'  },
        { key: 'matiere',                   label: 'Matière',               sortable: false,    class: '',                  type: 'text' },
        { key: 'suppleant_name',            label: 'Jours avec Suppléant',  sortable: true,    class: 'text-start ps-4',       type: 'actionText' }
    ]

    // LISTENERS
    @Watch('sessionSelect')
    initDataAfterSessionActiveChange() {
        if (this.$store.getters['session/sessionSelect'])
        {
            if (!this.firstInit) {
                this.initDatas()
            }
        }
    }

    @Watch('listeSuppleant', { immediate: true, deep: true })
    majDataForGenericTab () {
        // if (!this.firstInit) {
        this.initDatas()
        // }
    }

    @Watch('meta')
    majInfosTable () {
        // On récupère le nombre total d'affectations filrées depuis les infos des Metadonnées
        if (this.$store.state.suppleant.meta !== null) {
            if (this.totalSuppleantFiltered !== this.$store.state.suppleant.meta.total) {
                this.totalSuppleantFiltered = this.$store.state.suppleant.meta.total
            }
        } else {
            this.totalSuppleantFiltered = 0
        }
    }

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }
    // METHODS

    isObjectLocal (value: any) {
        return isObject(value)
    }

    /**
     * Initialisation des données affecations affichées dans le tableau.
     */
    initDatas ()
    {
        this.setDataForGenericTab(this.$store.getters['suppleant/listeSuppleant'])
    }

    /**
     * Initialisation des compteurs affectations affichés sur l'interface.
     */
    initCompteurs ()
    {
        /*  this.$store.dispatch('suppleant/getInfoTotauxAffectationsExaminateurs').then(() =>
        {
            this.totalAffectationsAPourvoirLocal = this.$store.state.suppleant.totalAffectationsAPourvoir
            this.totalAffectationsPourvuesLocal = this.$store.state.suppleant.totalAffectationsPourvues
        }) */
    }

    /**
     * Récupération sur le serveur de la session active
     */
    loadSessionActiveIfNotExists (force = false)
    {
        return new Promise((resolve) =>
        {
            if (!this.$store.getters['session/sessionSelect'] || force)
            {
                this.$store.dispatch('session/getSession', { session_id: this.$store.getters['auth/user_session_id'] }).then(() =>
                {
                    resolve(true)
                })
            }
            else
            {
                resolve(true)
            }
        })
    }

    /**
     * Récupération sur le serveur des séries de la session
     */
    loadSeriesSessionActiveIfNotExists ()
    {
        return new Promise((resolve) =>
        {
            // on récupère systématiquement les séries de la session active
            this.$store.dispatch('serie/getSeries').then(() =>
            {
                resolve(true)
            })
        })
    }


    /**
     * Récupération sur le serveur des concours
     */
    loadConcoursIfNotExists ()
    {
        return new Promise((resolve) =>
        {
            if (this.$store.getters['concour/banques'].length === 0)
            {
                this.$store.dispatch('concour/getConcoursActifs').then(() =>
                {
                    resolve(true)
                })
            }
            else
            {
                resolve(true)
            }
        })
    }

    /**
     * Récupération sur le serveur des matieres
     */
    loadMatieresIfNotExists ()
    {
        return new Promise((resolve) =>
        {
            if (this.$store.getters['matiere/matieres'].length === 0)
            {
                this.$store.dispatch('matiere/getMatieres').then(() =>
                {
                    resolve(true)
                })
            }
            else
            {
                resolve(true)
            }
        })
    }

    /**
     * Récupération sur le serveur des 100 premières affectations d'examinateur si pas déja
     * présentes dans le state
     */
    loadAffectationIfNotExists ()
    {
        return new Promise((resolve) =>
        {
            // on récupère systématiquement la liste des affectations à jour
            this.$store.dispatch('suppleant/getSuppleant', this.paramsLocal).then(() =>
            {
                resolve(true)
            })
        })
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        const can = this.$store.getters['auth/can'](Ability.ORAL_AFEX_MANAGE)
        const droit = checkIcone(Ability.ORAL_AFEX_MANAGE, can)
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                const formattedInfos = this.formatInfosLigneAffectation(result)
                const line = [
                    { label: droit.label,   item: result.id,                                    type: 'action',     typeAction: 'edit',     class: 'commons_first_action_button btn_action_ligne', icon:droit.icon, disabled: !droit.canValue },
                    { label: '',            item: formattedInfos.centreName,                    type: 'text',       typeAction: null,       class: 'text-start' },
                    { label: '',            item: formattedInfos.ensembleName,                  type: 'text',       typeAction: null,       class: 'text-center' },
                    { label: '', item: result.user.id, type: 'actionText', typeAction: 'infoIntervenant', class: 'text-info item_action', text: formattedInfos.userNameDisplayed },
                    { label: '', item: formattedInfos.remplacantId, type: 'actionText', typeAction: 'infoIntervenant', class: 'text-info item_action', text: formattedInfos.remplacantNameDisplayed },
                    // { label: '',            item: formattedInfos.userNameDisplayed,             type: 'text',       typeAction: null,       class: formattedInfos.userNameClass + ' table_user_zone' },
                    // { label: '',            item: formattedInfos.remplacantNameDisplayed,       type: 'text',       typeAction: null,       class: 'text-start table_user_zone' },
                    { label: '',            item: formattedInfos.matiereDisplayed,              type: 'text',       typeAction: null,       class: 'text-start' },
                    { label: '', item: formattedInfos.firstSuppleantId, type: 'actionText', typeAction: 'infoIntervenantBis', class: 'text-info item_action', text: formattedInfos.suppleantNameDisplayed }
                    // { label: '',            item: formattedInfos.suppleantNameDisplayed,        type: 'text',       typeAction: null,       class: 'text-start table_user_zone' }

                ]
                this.dataForTab.push(line)
            }
        }
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     * this.$store.state.suppleant.examinateursAffectations
     */
    setFiltersForGenericTab()
    {
        // Options filières
        const filieres = this.$store.getters['concour/banques']
        const options_filieres = []
        for (const f in filieres) {
            options_filieres.push({ index: filieres[f].id, name: filieres[f].name })
        }

        const matieres = this.$store.getters['matiere/matieres']
        const options_matieres = []
        for (const m in matieres) {
            options_matieres.push({ index: matieres[m].id, name: matieres[m].name })
        }

        const rootStore = this.$store.state.suppleant

        this.filtres =
        [
            { libelle: 'Code',                    defautOptionlibelle: 'Rechercher un',     model: 'ensemble.name',             value: '', index: 'ensemble.name',          datas: '',                  loading: rootStore.loading, options: { type: 'form',      fieldsKey: 'ensemble.name', strict: true } },
            { libelle: 'Filière',                 defautOptionlibelle: 'Rechercher une',    model: 'ensemble.concour.id',       value: '', index: 'ensemble.concour.name',  datas: options_filieres,    loading: rootStore.loading, options: { type: 'deroulant', fieldsKey: 'ensemble.concour.name' } },
            { libelle: 'Matière',                defautOptionlibelle: 'Rechercher une',     model: 'matiere_id',                value: '', index: 'matiere',                datas: options_matieres,    loading: rootStore.loading, options: { type: 'deroulant', fieldsKey: 'matiere' } },
            { libelle: 'Centre d\'oral',          defautOptionlibelle: 'Rechercher un',     model: 'centre.name',               value: '', index: 'centre.name',            datas: '',                  loading: rootStore.loading, options: { type: 'form',      fieldsKey: 'centre.name' } },
            {
                libelle: 'Nom d\'examinateur',
                defautOptionlibelle: 'Rechercher un',
                model: 'user.name',
                value: '',
                datas: '',
                loading: rootStore.loading,
                options: { type: 'form', fieldsKey: 'user.name' } // 'form' , 'deroulant'
            },
            { libelle: 'Remplaçant',              defautOptionlibelle: 'Rechercher un',     model: 'remplacant_suppleant_name',           value: '', index: 'remplacant_suppleant_name',        datas: '',                  loading: rootStore.loading, options: { type: 'form',      fieldsKey: 'remplacant_suppleant_name', strict: true } },
            { libelle: 'Suppléant',              defautOptionlibelle: 'Rechercher un',     model: 'suppleant_name',           value: '', index: 'suppleant_name',        datas: '',                  loading: rootStore.loading, options: { type: 'form',      fieldsKey: 'suppleant_name', strict: true } }
        ]
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            const suppleants        = this.$store.state.suppleant.listeSuppleant
            let suppleant_select    = null
            switch (paParams[0]) {
                case 'edit':
                    suppleant_select = suppleants.filter((s: any) => s.id === paParams[1])[0]
                    if (suppleant_select) {
                        this.openEditSuppleant(suppleant_select)
                    }
                    break
                case 'sortHandler':
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'infoIntervenantBis':
                case 'infoIntervenant':
                    this.infoIntervenantHandler(paParams[1])
                    break
            }
        }
    }

    infoIntervenantHandler(user_id: any) {
        this.user_id_temp  = user_id
        this.showModalInfoIntervenant = true
    }

    /** renvoie l'ensemble des infos d'une ligne d'affectation fournie formattées pour l'affichage sur la table. */
    formatInfosLigneAffectation(ligneAffectation: any) {
        // Examinateur, Remplaçant et Suppléants
        let userName = ''
        let userSeries = ''
        let remplacantName = ''
        let remplacantId = ''
        let suppleantsNames = ''
        let firstSuppleantId = null /// on n'indique que le premier suppleant
        let rempacantSeries = ''
        const listeSuppleants: any = []
        let serieIndex = 1

        // Boucle sur l'ensemble des affectations de la ligne d'affectation
        for (const indexAffectation in ligneAffectation.affectations) {
            // Examinateur
            if (ligneAffectation.affectations[indexAffectation].user_id && !ligneAffectation.affectations[indexAffectation].remplacant_id) {
                if (userName === '') {
                    userName = ligneAffectation.affectations[indexAffectation].user.name + ' ' + ligneAffectation.affectations[indexAffectation].user.first_name
                    userSeries = (userSeries.length > 0) ? userSeries + ', S' + serieIndex : '(S' + serieIndex
                } else {
                    userSeries = (userSeries.length > 0) ? userSeries + ', S' + serieIndex : '(S' + serieIndex
                }
            }
            // Remplaçant
            if (ligneAffectation.affectations[indexAffectation].remplacant_id) {
                if (remplacantName === '') {
                    remplacantName = ligneAffectation.affectations[indexAffectation].remplacant.name + ' ' + ligneAffectation.affectations[indexAffectation].remplacant.first_name
                    remplacantId =  ligneAffectation.affectations[indexAffectation].remplacant.id
                    rempacantSeries = (rempacantSeries.length > 0) ? rempacantSeries + ', S' + serieIndex : '(S' + serieIndex
                } else {
                    rempacantSeries = (rempacantSeries.length > 0) ? rempacantSeries + ', S' + serieIndex : '(S' + serieIndex
                }
            }

            /** Suppléants - il peut y avoir plusieurs suppléants pour une même affectation. Un Suppléant peut-être sur plusieurs journées.
             *  On crée donc un tableau par id des suppléants trouvés pour cumuler les journées par suppléant
             *  On reformatera ensuite en une seule expression concaténée pour l'affichage sur la table (suppleantNameDisplayed).
            */
            if (ligneAffectation.affectations[indexAffectation].suppleants.length > 0) {
                for (const suppleant of ligneAffectation.affectations[indexAffectation].suppleants) {
                    if (listeSuppleants[parseInt(suppleant.suppleant.id)]) {
                        // entrée de suppléant déjà existante, on alimente juste au niveau des journées
                        listeSuppleants[parseInt(suppleant.suppleant.id)].jours = listeSuppleants[parseInt(suppleant.suppleant.id)].jours + ', ' + formatDateShort(suppleant.jour)
                    } else {
                        // nouvelle entrée de suppléant, on créé l'entrée avec les infos du suppléant et on ajoute un premier jour
                        suppleantsNames = suppleantsNames === '' ? suppleant.suppleant.name + ' ' + suppleant.suppleant.first_name : suppleantsNames + ', ' + suppleant.suppleant.name + ' ' + suppleant.suppleant.first_name
                        listeSuppleants[parseInt(suppleant.suppleant.id)] = {}
                        firstSuppleantId = suppleant.suppleant.id
                        listeSuppleants[parseInt(suppleant.suppleant.id)].infos = suppleant.suppleant.name + ' ' + suppleant.suppleant.first_name
                        listeSuppleants[parseInt(suppleant.suppleant.id)].jours = formatDateShort(suppleant.jour)
                    }
                }
            }
            if (ligneAffectation.affectations[indexAffectation].suppleantremplacants.length > 0) {
                for (const suppleant of ligneAffectation.affectations[indexAffectation].suppleantremplacants) {
                    if (listeSuppleants[parseInt(suppleant.suppleant.id)]) {
                        // entrée de suppléant déjà existante, on alimente juste au niveau des journées
                        listeSuppleants[parseInt(suppleant.suppleant.id)].jours = listeSuppleants[parseInt(suppleant.suppleant.id)].jours + ', ' + formatDateShort(suppleant.jour)
                    } else {
                        // nouvelle entrée de suppléant, on créé l'entrée avec les infos du suppléant et on ajoute un premier jour
                        suppleantsNames = suppleantsNames === '' ? suppleant.suppleant.name + ' ' + suppleant.suppleant.first_name : suppleantsNames + ', ' + suppleant.suppleant.name + ' ' + suppleant.suppleant.first_name
                        listeSuppleants[parseInt(suppleant.suppleant.id)] = {}
                        firstSuppleantId = suppleant.suppleant.id
                        listeSuppleants[parseInt(suppleant.suppleant.id)].infos = suppleant.suppleant.name + ' ' + suppleant.suppleant.first_name
                        listeSuppleants[parseInt(suppleant.suppleant.id)].jours = formatDateShort(suppleant.jour)
                    }
                }
            }
            serieIndex++
        }

        // Cas où on a un remplaçant qui est affecté à toutes les séries.
        // Dans ce cas, on n'a pas récupéré le userName, donc on le récupère à la racine de l'objet, sachant que s'il y a un examinateur, le user est forcément rempli
        if (userName === '' && ligneAffectation.user) {
            userName = ligneAffectation.user.name + ' ' + ligneAffectation.user.first_name
        }

        const userNameDisplayed = (userName === '') ? 'Non affecté' : (remplacantName === '' || userSeries === '') ? userName : userName + ' ' + userSeries + ')'
        const remplacantNameDisplayed = (remplacantName === '') ? '-' : remplacantName + ' ' + rempacantSeries + ')'

        let suppleantNameDisplayed = '-'
        for (const suppleant of listeSuppleants) {
            if (suppleant) {
                suppleantNameDisplayed = (suppleantNameDisplayed === '-' ? '' : suppleantNameDisplayed + ', ') + suppleant.infos + ' (' + suppleant.jours + ')'
            }
        }

        const userNameClass = (userName === '') ? 'text-start text-danger' : 'text-start'

        // Filière et Filière-Epreuves
        let dataFiliere = ''
        let filiereEpreuveDisplayed = ''
        let idMatiere = ''
        let matiereDisplayed = ''

        if (ligneAffectation.ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT) {
            dataFiliere = ligneAffectation.ensemble.concour.name
            filiereEpreuveDisplayed = ligneAffectation.ensemble.concour.name + '-' + ligneAffectation.epreuveCorrection.name
            idMatiere = ligneAffectation.epreuveCorrection.matiere_id
            matiereDisplayed = this.$store.getters['matiere/getMatiereById'](parseInt(idMatiere)).name
        } else {
            for (let i = 0; i < ligneAffectation.ensemble.groupeEpreuve.epreuves.length; i++) {
                for (let j = 0; j < ligneAffectation.ensemble.groupeEpreuve.epreuves[i].epreuves.length; j++) {
                    const concTemp = store.getters['concour/concourById'](ligneAffectation.ensemble.groupeEpreuve.epreuves[i].epreuves[j].concour_id)
                    if (concTemp) {
                        if (dataFiliere.length > 0) {
                            dataFiliere += ', '
                        }
                        if (filiereEpreuveDisplayed.length > 0) {
                            filiereEpreuveDisplayed += ', '
                        }
                        const codeConcours = store.getters['concour/concourById'](ligneAffectation.ensemble.groupeEpreuve.epreuves[i].epreuves[j].concour_id).code
                        dataFiliere += codeConcours
                        filiereEpreuveDisplayed += codeConcours + '-' + ligneAffectation.ensemble.groupeEpreuve.epreuves[i].epreuves[j].name
                        idMatiere = ligneAffectation.ensemble.groupeEpreuve.epreuves[i].epreuves[j].matiere_id
                        if (matiereDisplayed === '') {
                            matiereDisplayed = this.$store.getters['matiere/getMatiereById'](parseInt(idMatiere)).name
                        }
                    }
                }
            }
        }

        const formattedInfos = {
            userName: userName,
            remplacantName: remplacantName,
            suppleantsNames: suppleantsNames,
            firstSuppleantId: firstSuppleantId,
            userNameDisplayed: userNameDisplayed,
            userNameClass: userNameClass,
            remplacantNameDisplayed: remplacantNameDisplayed,
            remplacantId: remplacantId,
            suppleantNameDisplayed: suppleantNameDisplayed,
            dataFiliere: dataFiliere,
            filiereEpreuveDisplayed: filiereEpreuveDisplayed,
            idMatiere: idMatiere,
            matiereDisplayed: matiereDisplayed,
            centreName: ligneAffectation.centre ? ligneAffectation.centre.name : '-',
            ensembleName: ligneAffectation.ensemble.name
        }

        return formattedInfos
    }

    openEditSuppleant(params: any) {
        // MAJ des données passées au popup d'édition *****************************************************************************************************
        this.affectationSelect = params // mise à jour de l'affectation sélectionnée
        this.affectationSelectFormattedInfos = this.formatInfosLigneAffectation(params) // mise à jour des données formatées de l'affectation sélectionnné
        // **************************************************************************************************************************************************
        this.toggleModalePopupEditSuppleant = true
    }

    closeEditSuppleant(withUpdate: boolean) {
        this.toggleModalePopupEditSuppleant = false
        if (withUpdate) {
            this.loadAffectationIfNotExists().then(() => {
                this.loadingLocal = false
            })
        }
    }


    // Appelle une page lors du scroll
    loadHandler (params: any) {
        this.$store.dispatch('suppleant/getMoreSuppleant', params)
    }

    filtreSortHandler (params: any) {
        if (JSON.stringify(params) !== JSON.stringify(this.paramsLocal)) {
            this.paramsLocal = params
            this.$store.dispatch('suppleant/getSuppleant', params)
        }
    }

    load () {
        // Si la session active n'est pas chargée, alors on la récupère
        this.loadSessionActiveIfNotExists(true).then(() => {
            this.activeSession = this.$store.state.session.sessionSelect
            // Récupération des séries de la session active
            this.loadSeriesSessionActiveIfNotExists().then(() => {
                // Si aucun concours n'a été chargé, alors on les récupère
                this.loadConcoursIfNotExists().then(() => {
                    // Si les matières ne sont pas chargées, alors on les récupère
                    this.loadMatieresIfNotExists().then(() => {
                        // Si les affectations ne sont pas chargées, alors on les récupère
                        this.loadAffectationIfNotExists().then(() => {
                            this.setFiltersForGenericTab()
                            this.firstInit = false
                            this.loadingLocal = false
                        })
                    })
                })
            })
        })
    }

    /**
     * Au montage du composant, on charge les différentes données dans le store si cela est nécéssaire
     */
    mounted ()
    {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
