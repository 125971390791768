
























































































































































































































































































import Formulaire from '@exatech-group/formulaire/src/Formulaire.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { BModal } from 'bootstrap-vue'
import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { isObject } from '../../utils/helpers'
import { Ability } from '../../types/Ability';

enum Tab {
    PROFIL = 'profil',
    FICHE_POSTE = 'fiche_poste'
}

@Component({
    name: 'FormulairesView',
    components: {
        BModal,
        Formulaire,
        FontAwesomeIcon
    },
    computed: {
        ...mapGetters('session', ['sessionActive']),
        ...mapGetters('jobProfileForm', ['jobProfileFormSelect']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    },
    watch: {
        tab: {
            handler(val): void {
                if (this.$route.query.tab !== val) {
                    this.$router.push({ query: { tab: val } })
                }
            }
        }
    }
})
export default class Formulaires extends Vue {
    Ability = Ability
    Tab = Tab
    tab = this.$route.query.tab || Tab.PROFIL as Tab
    jobProfileFormTemp: any = null
    jobDescriptionFormTemp: any = null
    showModalValideFormulaireProfil = false
    showModalValideFormulaireDescription = false
    requestProfilTitle = false
    requestDescriptionTitle = false
    processing = false

    setCurrentTab(tab: Tab): void {
        this.tab = tab
    }

    /**
     * storeDocumentReference
     * Cette fonction permet de stocker un document référence dans le formulaire en cours.
     * @param {string} titre - Titre du document
     * @param {any} data - Données du document à stocker
     * @returns une promesse résolue avec l'UUID du document stockée
     */
    async storeDocumentReference(titre: string, data: any) {
        const formdata = new FormData()
        formdata.set('document', data)
        formdata.set('name', titre)

        const payload: any = {
            payload: formdata
        }

        if (this.tab === Tab.PROFIL) {
            payload['jobProfileForm_id'] = this.jobProfileFormTemp.id
        } else {
            payload['jobDescriptionForm_id'] = this.jobDescriptionFormTemp.id
        }

        return new Promise((resolve) => {
            this.$store
                .dispatch(`${this.tab === Tab.PROFIL ? 'jobProfileForm' : 'jobDescriptionForm'}/storeDocumentReference`, payload)
                .then((response: any) => {
                    resolve({ id: response.data.data.uuid })
                })
        })
    }

    /**
     * getDocumentReference
     * Cette fonction récupère un document référence à partir de son identifiant.
     * @param {any} documentId - Identifiant du document à récupérer
     * @returns une promesse résolue avec le document récupéré
     */
    getDocumentReference(documentId: any) {
        const payload: any = {
            documentId: documentId
        }

        if (this.tab === Tab.PROFIL) {
            payload['jobProfileForm_id'] = this.jobProfileFormTemp.id
        } else {
            payload['jobDescriptionForm_id'] = this.jobDescriptionFormTemp.id
        }

        return new Promise((resolve) => {
            this.$store
                .dispatch(`${this.tab === Tab.PROFIL ? 'jobProfileForm' : 'jobDescriptionForm'}/getDocumentReference`, payload)
                .then((response: any) => {
                    resolve(response)
                })
        })
    }

    /**
     * deleteDocumentReference
     * Cette fonction permet de supprimer un document référence à partir de son identifiant.
     * @param {any} documentId - Identifiant du document à supprimer
     * @returns une promesse résolue avec la réponse de la suppression du document
     */
    deleteDocumentReference(documentId: any) {
        const payload: any = {
            documentId: documentId
        }

        if (this.tab === Tab.PROFIL) {
            payload['jobProfileForm_id'] = this.jobProfileFormTemp.id
        } else {
            payload['jobDescriptionForm_id'] = this.jobDescriptionFormTemp.id
        }

        return new Promise((resolve) => {
            this.$store
                .dispatch(`${this.tab === Tab.PROFIL ? 'jobProfileForm' : 'jobDescriptionForm'}/deleteDocumentReference`, payload)
                .then((response: any) => {
                    resolve(response)
                })
        })
    }

    /**
     * updateProfilFormulaire
     * Cette fonction met à jour le formulaire avec les valeurs fournies.
     * @param {any} value - Nouvelle valeur du formulaire
     */
    updateProfilFormulaire(value: any) {
        if (this.$store.state.jobProfileForm.jobProfileFormSelect === null) {
            this.jobProfileFormTemp = {
                id: null,
                session_id: this.$store.state.session.sessionSelect.id,
                name: '',
                datas: null
            }
        }
        this.jobProfileFormTemp.name = JSON.parse(value).libelle
        this.jobProfileFormTemp.datas = JSON.parse(value)
    }

    /**
     * updateDescriptionFormulaire
     * Cette fonction met à jour le formulaire avec les valeurs fournies.
     * @param {any} value - Nouvelle valeur du formulaire
     */
    updateDescriptionFormulaire(value: any) {
        if (this.$store.state.jobDescriptionForm.jobDescriptionFormSelect === null) {
            this.jobDescriptionFormTemp = {
                id: null,
                session_id: this.$store.state.session.sessionSelect.id,
                name: '',
                datas: null
            }
        }
        this.jobDescriptionFormTemp.name = JSON.parse(value).libelle
        this.jobDescriptionFormTemp.datas = JSON.parse(value)
    }

    /**
     * saveProfilFormulaire
     * Cette fonction permet d'enregistrer ou de mettre à jour un formulaire.
     */
    saveProfilFormulaire() {
        if (this.processing) {
            return
        }
        this.processing = true
        this.jobProfileFormTemp.name = this.jobProfileFormTemp.datas.libelle

        const payload: any = {
            id: this.jobProfileFormTemp.id,
            name: isObject(this.jobProfileFormTemp.datas)
                ? this.jobProfileFormTemp.datas.libelle
                : JSON.parse(this.jobProfileFormTemp.datas).libelle,
            datas: isObject(this.jobProfileFormTemp.datas)
                ? this.jobProfileFormTemp.datas
                : JSON.parse(this.jobProfileFormTemp.datas),
            session_id: this.jobProfileFormTemp.session_id
        }

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.$store.dispatch(`jobProfileForm/${this.jobProfileFormTemp.id ? 'update' : 'add' }JobProfileForm`, payload)
            .then((response) => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Formulaire modifié avec succès !', succesToaster)

                this.requestProfilTitle = false

                this.$store.state.jobProfileForm.jobProfileFormSelect = response.data.data
                this.jobProfileFormTemp.id = response.data.data.id
                this.updateProfilFormulaire(response.data.data.datas)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.processing = false
            })
    }

    /**
     * saveDescriptionFormulaire
     * Cette fonction permet d'enregistrer ou de mettre à jour un formulaire.
     */
    saveDescriptionFormulaire() {
        if (this.processing || !this.checkRefFormulaireDescription()) {
            return
        }
        this.processing = true

        this.jobDescriptionFormTemp.name = this.jobDescriptionFormTemp.datas.libelle

        const payload: any = {
            id: this.jobDescriptionFormTemp.id,
            name: isObject(this.jobDescriptionFormTemp.datas)
                ? this.jobDescriptionFormTemp.datas.libelle
                : JSON.parse(this.jobDescriptionFormTemp.datas).libelle,
            datas: isObject(this.jobDescriptionFormTemp.datas)
                ? this.jobDescriptionFormTemp.datas
                : JSON.parse(this.jobDescriptionFormTemp.datas),
            session_id: this.jobDescriptionFormTemp.session_id
        }

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.$store.dispatch(`jobDescriptionForm/${this.jobDescriptionFormTemp.id ? 'update' : 'add' }JobDescriptionForm`, payload)
            .then((response) => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Formulaire modifié avec succès !', succesToaster)

                this.requestDescriptionTitle = false

                this.$store.state.jobDescriptionForm.jobDescriptionFormSelect = response.data.data
                this.jobDescriptionFormTemp.id = response.data.data.id
                this.updateDescriptionFormulaire(response.data.data.datas)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.processing = false
            })
    }

    /**
     * valideFormulaire
     * Cette fonction ouvre une fenêtre modale pour valider le formulaire.
     */
    valideFormulaireProfil() {
        this.showModalValideFormulaireProfil = true
    }

    /**
     * cancelValideFormulaire
     * Cette fonction annule la validation du formulaire en fermant la fenêtre modale.
     */
    cancelValideFormulaireProfil() {
        this.showModalValideFormulaireProfil = false
    }

    /**
     * valideFormulaireSuite
     * Cette fonction permet de valider le formulaire en cours.
     */
    valideFormulaireProfilSuite() {
        this.jobProfileFormTemp.name = this.jobProfileFormTemp.datas.libelle

        const payload = {
            id: this.jobProfileFormTemp.id,
            name: isObject(this.jobProfileFormTemp.datas)
                ? this.jobProfileFormTemp.datas.libelle
                : JSON.parse(this.jobProfileFormTemp.datas).libelle,
            datas: isObject(this.jobProfileFormTemp.datas)
                ? this.jobProfileFormTemp.datas
                : JSON.parse(this.jobProfileFormTemp.datas),
            validate: this.jobProfileFormTemp.validated_at ? 0 : 1
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('jobProfileForm/updateJobProfileForm', payload)
            .then((response: any) => {
                this.jobProfileFormTemp.validated_at = response.data.data.validated_at
                this.$store.state.jobProfileForm.jobProfileFormSelect =  response.data.data
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Formulaire modifiée avec succès !', succesToaster)
            })
            .finally(() => {
                this.showModalValideFormulaireProfil = false
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * valideFormulaire
     * Cette fonction ouvre une fenêtre modale pour valider le formulaire.
     */
    valideFormulaireDescription() {
        this.showModalValideFormulaireDescription = true
    }

    /**
     * cancelValideFormulaire
     * Cette fonction annule la validation du formulaire en fermant la fenêtre modale.
     */
    cancelValideFormulaireDescription() {
        this.showModalValideFormulaireDescription = false
    }

    checkRefFormulaireDescription(): boolean {
        if (this.jobDescriptionFormTemp?.id) {
            let found = false
            this.jobDescriptionFormTemp.datas.parties.some((partie: any) => {
                partie.inputs.some((input: any) => {
                    return found = input.ref === 'job_descriptions.name'
                })
            })

            if (!found) {
                const idError = 't_error_' + Math.random()
                const errorToaster = {
                    id: idError,
                    toaster: 'b-toaster-top-right',
                    variant: 'danger',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('La référence "job_descriptions.name" est manquante dans le formulaire.', errorToaster)
            }

            return found
        } else {
            return true
        }
    }

    /**
     * valideFormulaireSuite
     * Cette fonction permet de valider le formulaire en cours.
     */
    valideFormulaireDescriptionSuite() {
        if (!this.jobDescriptionFormTemp.validated_at && !this.checkRefFormulaireDescription()) {
            this.showModalValideFormulaireDescription = false
            return
        }

        this.jobDescriptionFormTemp.name = this.jobDescriptionFormTemp.datas.libelle

        const payload = {
            id: this.jobDescriptionFormTemp.id,
            name: isObject(this.jobDescriptionFormTemp.datas)
                ? this.jobDescriptionFormTemp.datas.libelle
                : JSON.parse(this.jobDescriptionFormTemp.datas).libelle,
            datas: isObject(this.jobDescriptionFormTemp.datas)
                ? this.jobDescriptionFormTemp.datas
                : JSON.parse(this.jobDescriptionFormTemp.datas),
            validate: this.jobDescriptionFormTemp.validated_at ? 0 : 1
        }

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('jobDescriptionForm/updateJobDescriptionForm', payload)
            .then((response: any) => {
                this.jobDescriptionFormTemp.validated_at = response.data.data.validated_at
                this.$store.state.jobDescriptionForm.jobDescriptionFormSelect =  response.data.data
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Formulaire modifiée avec succès !', succesToaster)
            })
            .finally(() => {
                this.showModalValideFormulaireDescription = false
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * beforeMount
     * Cette fonction est exécutée avant que le composant ne soit monté.
     */
    beforeMount() {
        this.$store.dispatch('jobProfileForm/getJobProfileForms').then(() =>  {
            if (this.$store.state.jobProfileForm.jobProfileForms.length > 0) {
                this.jobProfileFormTemp = this.$store.state.jobProfileForm.jobProfileForms[0]
                this.$store.state.jobProfileForm.jobProfileFormSelect = this.$store.state.jobProfileForm.jobProfileForms[0]
            } else {
                this.jobProfileFormTemp = {
                    id: null,
                    session_id: this.$store.state.session.sessionSelect.id,
                    name: '',
                    datas: {
                        libelle: '',
                        description: '',
                        parties: []
                    }
                }
                this.requestProfilTitle = true
            }
        })
        this.$store.dispatch('jobDescriptionForm/getJobDescriptionForms').then(() =>  {
            if (this.$store.state.jobDescriptionForm.jobDescriptionForms.length > 0) {
                this.jobDescriptionFormTemp = this.$store.state.jobDescriptionForm.jobDescriptionForms[0]
                this.$store.state.jobDescriptionForm.jobDescriptionFormSelect = this.$store.state.jobDescriptionForm.jobDescriptionForms[0]
            } else {
                this.jobDescriptionFormTemp = {
                    id: null,
                    session_id: this.$store.state.session.sessionSelect.id,
                    name: '',
                    datas: {
                        libelle: '',
                        description: '',
                        parties: []
                    }
                }
                this.requestDescriptionTitle = true
            }
        })
    }
}
