





































































































import { WorkingEstablishmentTypeInterface } from '@/types/WorkingEstablishmentType';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { isEmpty } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { FichePosteStep, StatusEtab } from '../../types/BourseEmploi';
import Select from './Select.vue';
import { Ability } from '../../types/Ability';
import { mapGetters } from 'vuex';
import _ from 'lodash';

@Component({
    name: 'CreateEtabComponent',
    components: {
        FontAwesomeIcon,
        Select
    },
    computed: {
        ...mapGetters('auth', ['can']),
        workingEstablishmentParentLibelle(): string {
            if (this.$props.workingEstablishment?.workingEstablishmentParent) {
                return `${this.$props.workingEstablishment.workingEstablishmentParent.code} - ${this.$props.workingEstablishment.workingEstablishmentParent.name} (${this.$props.workingEstablishment.workingEstablishmentParent.workingEstablishmentType.name})`
            }
            return 'Aucun'
        },
        enableNext(): boolean {
            if (this.$data.processing || this.$data.loadingParents || !(this.$data.name && this.$data.codeET && this.$data.codeEJ && this.$data.adress && this.$data.ville && this.$data.codePostal && this.$data.workingEstablishmentTypeId)) {
                return false
            }

            const can = this.$store.getters['auth/can']
            if (can(Ability.ADM_ESTABLISHMENT_MANAGE) || can(Ability.EST_ESTABLISHMENT_PARENT_OWN_MANAGE)) {
                if (this.$props.selectParent) {
                    if (this.$data.workingEstablishmentTypeId) {
                        const workingEstablishmentType = this.$store.getters['workingEstablishmentType/workingEstablishmentTypes']
                            .find((workingEstablishmentType: WorkingEstablishmentTypeInterface) => workingEstablishmentType.id === this.$data.workingEstablishmentTypeId)
                        return Boolean(!workingEstablishmentType?.working_establishment_type_id || (workingEstablishmentType?.working_establishment_type_id && this.$data.parentSelected))
                    }
                    return false
                }
                return true
            }
            return false
        }
    },
    props: {
        workingEstablishment: {
            type: Object,
            required: false,
            default: null
        },
        establishmentSelected: {
            type: Object,
            required: false,
            default: null
        },
        edit: {
            type: Boolean,
            required: false,
            default: false
        },
        hideBtns: {
            type: Boolean,
            required: false,
            default: false
        },
        selectParent: {
            type: Boolean,
            required: false,
            default: false
        },
        customWorkingEstablishmentTypes: {
            type: Array,
            required: false,
            default: null
        },
        formBtn: {
            type: Boolean,
            required: false,
            default: false
        },
        jobProcess: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    watch: {
        processing: {
            handler(val) {
                this.$emit('processing', val)
            }
        },
        workingEstablishmentTypeId: {
            immediate: true,
            handler: 'searchParents'
        },
        workingEstablishment: {
            immediate: true,
            deep: true,
            handler: 'setData'
        },
        establishmentSelected: {
            immediate: true,
            deep: true,
            handler: 'setData'
        },
        customWorkingEstablishmentTypes: {
            deep: true,
            handler: 'setWorkingEstablishmentTypes'
        }
    }
})

export default class CreateEtab extends Vue {
    StatusEtab = StatusEtab
    Ability = Ability
    isEmpty = isEmpty
    FichePosteStep = FichePosteStep
    processing = false as boolean
    loadingParents = false as boolean
    id = 0 as number
    sessionId = 0 as number
    name = '' as string
    codeET = '' as string
    codeEJ = '' as string
    status = StatusEtab.PUBLIC as number
    adress = '' as string
    ville = '' as string
    codePostal = '' as string
    workingEstablishmentType = {} as WorkingEstablishmentTypeInterface
    workingEstablishmentTypeId = 0 as number
    parentsList = [] as any[]
    parentSelected = null as any
    workingEstablishmentTypeParentId = 0 as number
    workingEstablishmentTypes = [] as WorkingEstablishmentTypeInterface[]

    reset(): void {
        this.processing = false
        this.setData()
    }

    async setData(): Promise<void> {
        const workingEstablishment = this.$props.workingEstablishment
        this.id = workingEstablishment?.id?.valueOf() || 0
        this.sessionId = this.$store.getters['auth/user_session_id'] || 0
        this.name = workingEstablishment?.name?.valueOf() || ''
        this.codeET = workingEstablishment?.code?.valueOf() || ''
        this.codeEJ = workingEstablishment?.code_group?.valueOf() || ''
        this.status = workingEstablishment?.status?.valueOf() || StatusEtab.PUBLIC
        this.adress = workingEstablishment?.adress?.valueOf() || '',
        this.ville = workingEstablishment?.ville?.valueOf() || '',
        this.codePostal = workingEstablishment?.code_postal?.valueOf() || ''
        this.workingEstablishmentType = JSON.parse(JSON.stringify(workingEstablishment?.workingEstablishmentType || null))

        await this.setWorkingEstablishmentTypes(this.$props.customWorkingEstablishmentTypes)
    }

    async setWorkingEstablishmentTypes(array: WorkingEstablishmentTypeInterface[] | null): Promise<void> {
        const can = this.$store.getters['auth/can']
        if (array === null) {
            if (isEmpty(this.$store.getters['workingEstablishmentType/workingEstablishmentTypes'])) {
                await this.$store.dispatch('workingEstablishmentType/getWorkingEstablishmentTypes')
            } else if (can(Ability.ADM_ESTABLISHMENT_MANAGE)) {
                const parentElement = this.$store.getters['workingEstablishmentType/workingEstablishmentTypes']
                    ?.find((workingEstablishmentType: WorkingEstablishmentTypeInterface) => workingEstablishmentType.working_establishment_type_id === null);

                array = parentElement
                    ? [parentElement, ...(parentElement.workingEstablishmentTypeChildren || [])]
                    : null;
            } else {
                array = this.$store.getters['workingEstablishmentType/workingEstablishmentTypes']
                    ?.find((workingEstablishmentType: WorkingEstablishmentTypeInterface) => workingEstablishmentType.working_establishment_type_id === null)
                    ?.workingEstablishmentTypeChildren || null
            }
        }
        this.workingEstablishmentTypes = array || []
        if (this.workingEstablishmentType?.id) {
            this.workingEstablishmentTypeId = this.workingEstablishmentType.id
        } else if (this.workingEstablishmentTypes.length === 1) {
            this.workingEstablishmentTypeId = this.workingEstablishmentTypes[0].id?.valueOf() || 0
        } else {
            this.workingEstablishmentTypeId = this.$props.workingEstablishment?.working_establishment_type_id?.valueOf() || 0
        }
    }

    searchParents(): void {
        this.parentSelected = JSON.parse(JSON.stringify(this.$props.workingEstablishment?.workingEstablishmentParent || this.$props.establishmentSelected || null))
        if (this.parentSelected) {
            this.parentSelected = { id: this.parentSelected?.id, title: `${this.parentSelected?.code} - ${this.parentSelected?.name} (${this.parentSelected?.workingEstablishmentType?.name})`  }
        }
        this.fetchParents()
    }

    fetchParents(): void {
        if (this.loadingParents || !this.$props.selectParent) {
            return
        }

        if (this.workingEstablishmentTypeId) {
            this.loadingParents = true

            this.workingEstablishmentTypeParentId = this.$store.getters['workingEstablishmentType/workingEstablishmentTypes']
                ?.find((w: WorkingEstablishmentTypeInterface) => w.id === this.workingEstablishmentTypeId)
                ?.workingEstablishmentTypeParent
                ?.id || 0

            if (this.workingEstablishmentTypeParentId) {
                this.$store.dispatch('workingEstablishment/getWorkingEstablishments', {
                    skipStore: true,
                    perPage: 0,
                    'filter-working_establishment_type_id': this.workingEstablishmentTypeParentId
                })
                    .then((response) => {
                        this.parentsList = JSON.parse(
                            JSON.stringify(
                                response.data.data
                                    ?.filter((item: any) => item.id !== this.$props.workingEstablishment?.id)
                                    ?.map((item: any) => ({ id: item.id, title: `${item?.code} - ${item?.name} (${item?.workingEstablishmentType?.name})` })))
                        )
                        this.parentsList = _.orderBy(this.parentsList, 'title', 'asc')
                        if (this.parentsList.length === 1) {
                            this.parentSelected = Object.assign({}, this.parentsList[0])
                        }
                    })
                    .catch(() => this.parentsList = [])
                    .finally(() => {
                        this.loadingParents = false
                    })
            } else {
                this.parentsList = []
                this.loadingParents = false
            }
        } else {
            this.parentsList = []
            this.loadingParents = false
        }
    }

    close(): void {
        this.reset()
        this.$emit('close')
    }

    send(): void {
        if (!this.$props.edit || this.processing) {
            return
        }
        this.processing = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        const payload: any = {
            id: this.id,
            session_id: this.sessionId,
            name: this.name,
            code: this.codeET,
            code_group: this.codeEJ,
            status: this.status,
            adress: this.adress,
            ville: this.ville.toUpperCase(),
            code_postal: this.codePostal,
            working_establishment_type_id: this.workingEstablishmentTypeId
        }

        if (this.parentSelected) {
            payload.working_establishment_id = this.parentSelected.id
        }

        this.$store.dispatch(`workingEstablishment/${payload.id > 0 ? 'update' : 'add'}WorkingEstablishment`, payload)
            .then((response) => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast(`Établissement ${payload.id > 0 ? 'modifié' : 'ajouté'} avec succès !`, succesToaster)
                this.$emit('next', { data: response.data.data })
                this.reset()
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.processing = false
            })
    }

    canChangeType(): boolean {
        const can = this.$store.getters['auth/can']
        if (can(Ability.ADM_ESTABLISHMENT_MANAGE)) {
            return true
        } else if (can(Ability.EST_ESTABLISHMENT_OWN_MANAGE)) {
            const workingEstablishmentsIds = this.$store.getters['user/reportingUserSelect']?.working_establishments?.map((item: any) => item.id)
            const test = workingEstablishmentsIds?.includes(this.$props.workingEstablishment?.id)
            return !(test)
        }
        return false
    }

    async mounted(): Promise<void> {
        const can = this.$store.getters['auth/can']
        if (!can(Ability.ADM_ESTABLISHMENT_MANAGE) && isEmpty(this.$store.getters['user/reportingUserSelect'])) {
            await this.$store.dispatch('user/getReportingUser', { user_id: this.$store.getters['auth/authUser']?.id })
        }
    }
}
