























































































import {  mapGetters } from 'vuex'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VuePdfApp from 'vue-pdf-app'
import { base64ToArrayBuffer, getFileNameFromHeader } from '@/utils/helpers'
import { Ability } from '@/types/Ability'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { FournitureType }           from '@/types/Fourniture'

@Component({
    computed: {
        ...mapGetters('auth', [
            'authUser',
            'can',
            'cannot',
            'isA',
            'isNotA'
        ]),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapGetters('impressionExaminateur', ['total'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        VuePdfApp,
        ErrorDisplay
    }
})
export default class PopupImprimer extends Vue {
    @Prop() showPopup?          : boolean
    @Prop() titre?              : string
    @Prop() loadingPdf?         : boolean
    @Prop() pdfData?            : string
    @Prop() pdfFileName?        : string
    @Prop() libelleDocument?    : string
    @Prop() params?             : any
    @Prop() confirmButton?      : boolean | false
    @Prop() checkboxOral?       : boolean| false // affiche la checkbox messure oral
    @Prop() error?              : any
    @Prop() fromOngletCandidat? : boolean // modif les parametres de confirmation impression en fonction de l'onglet

    Ability = Ability
    formData = new FormData()

    centre_name                         = null
    equipe_name: any                    = null
    examinateur_name                    = ''
    serie_name                          = null
    candidat_identite: any              = null
    showModalConfirmationAnnulation     = false
    FournitureType                      = FournitureType

    @Watch('showPopup')
    setLibelleSelection () {
        if (this.$props.showPopup) {
            if (this.$props.params.centre_id) {
                const centre_temp = this.$store.state.centre.centres.find((c: any) => c.id === this.$props.params.centre_id)
                this.centre_name = centre_temp ? centre_temp.name : '-'
            }
            if (this.$props.params.ensemble_id) {
                const ensemble_temp: any = this.$store.state.ensemble.ensembles.find((e: any) => e.id === this.$props.params.ensemble_id)
                this.equipe_name = ensemble_temp ? ensemble_temp.name : '-'
                if (this.$props.params.user_id !== '' && ensemble_temp) {
                    const user_temp = ensemble_temp.examinateurs.find((ex: any) => ex.id === this.$props.params.user_id)
                    this.equipe_name = ensemble_temp ? ensemble_temp.name : '-'
                    this.examinateur_name = user_temp ? user_temp.name + ' ' + user_temp.first_name : '-'
                } else {
                    this.examinateur_name = 'Tous les examinateurs'
                }
            } else {
                this.equipe_name = 'Toutes les équipes'
                this.examinateur_name = 'Tous les examinateurs'
            }
            if (this.$props.params.serie_id) {
                const serie_temp = this.$store.state.serie.series.find((s: any) => s.id === this.$props.params.serie_id)
                this.serie_name = serie_temp ? serie_temp.name : '-'
            }

            if (this.$props.params.candidat) {
                this.candidat_identite = this.$props.params.candidat.name + ' ' + this.$props.params.candidat.first_name
            }
        }
    }

    @Watch('pdfData')
    watchPdfData() {
        if (this.$props.showPopup === true) {
            this.showDocument(this.$props.pdfData)
        }
    }


    config = {
        toolbar: {
            toolbarViewerRight: { presentationMode: false, openFile: false, viewBookmark: false, secondaryToolbarToggle: false }
        }
    }

    source_doc: any = ''
    recherche = {
        fiche_mesure : 0
    }

    // Selectionne un fichier
    selectFile (e: Event) {
        if (e.target !== null) {
            const target = e.target as HTMLInputElement
            const file: File = (target.files as FileList)[0]
            this.formData.set('document', file)
        }
    }

    // Affiche les pièces jointes à l'ordre de mission au format PDF
    showDocument (document: string) {
        this.source_doc = ''
        this.source_doc = base64ToArrayBuffer(document)
    }

    // confirme l'impression
    confirmerImpression () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        const payload = this.params
        payload.print_confirm = 1
        payload.pdf = 0
        // Si la confirmation ne vient pas du côté onglet candidat
        if (this.$props.fromOngletCandidat === false || !this.$props.fromOngletCandidat) {  this.$store.dispatch('impressionExaminateur/CONFIRMATION_IMPRESSION', payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Confirmation impression terminée !', succesToaster)
                this.closePopup()
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
        }

        if (this.$props.fromOngletCandidat === true) {  this.$store.dispatch('impressionCandidat/CONFIRMATION_IMPRESSION', payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Confirmation impression terminée !', succesToaster)
                this.closePopup()
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
        }
    }

    // Close popup
    closePopup () {
        this.source_doc = ''
        this.centre_name = null
        this.equipe_name = null
        this.examinateur_name = ''
        this.serie_name = null
        this.showModalConfirmationAnnulation = false
        this.recherche = {
            fiche_mesure : 0
        }
        this.$emit('close')
    }

    /** Télécharge le pdf */
    dl_pdf () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Téléchargement en cours ...', infosToaster)

        const params         = this.$props.params
        params.pdf           = 1
        params.format        = 'b64'
        params.print_confirm = null

        if (this.fromOngletCandidat) {
            params.fiche_mesure   = this.recherche.fiche_mesure ? 1 : 0
        }
        if (this.$props.params.type === FournitureType.TYPE_FMO) {
            params.fiche_mesure = 1
        }

        if (params.serie) {
            Vue.set(params, 'serie', null)
        }
        if (params.candidat) {
            Vue.set(params, 'candidat', null)
        }
        if (params.epreuve) {
            Vue.set(params, 'epreuve', null)
        }
        this.$store.dispatch('impressionExaminateur/getPdf', params)
            .then(response => {
                // Logique de téléchargement
                const file_name = getFileNameFromHeader(response.headers) || this.$props.pdfFileName
                const url = URL.createObjectURL(new Blob([base64ToArrayBuffer(response.data)]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', file_name)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * Confirmer annulation de confirmation d'impression
     */
    confirmerAnnulation() {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Annulation de l\'impression en cours ...', infosToaster)

        const payload = this.$props.params
        payload.pdf = 0
        payload.format = ''
        payload.print_confirm = 0

        const store_impression = this.$props.fromOngletCandidat ? 'impressionCandidat' : 'impressionExaminateur'

        this.$store.dispatch(store_impression + '/CONFIRMATION_IMPRESSION', payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Annulation de l\'impression terminée !', succesToaster)
                this.showModalConfirmationAnnulation = false
                this.closePopup()
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }
}
