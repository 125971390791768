export interface ConcourInterface {
    id: number,
    sessionId: number,
    code: string,
    name: string,
    nb_candidats: number
}

export interface StateConcours {
    concours: Array<ConcourInterface> | [],
    meta: Array<string> | null,
    links: Array<string> | null,
    loading: boolean | false,
    error: Array<string> | null,
    totalRows: number | 0,
    concourTemp: ConcourInterface | null
}

export enum TypeDeliberation {
    TYPE_SANS_DELIBERATION,
    TYPE_AVEC_DELIBERATION,
}
