





























































































































































































































import { Vue, Component, Watch }    from 'vue-property-decorator'
import { mapGetters, mapState }     from 'vuex'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'
import { Ability }                  from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import {
    base64ToArrayBuffer,
    diffTwoDatesInMinutes,
    formatDate,
    formatDateYYYYMMDD,
    getFileNameFromHeader
} from '@/utils/helpers'
import  ErrorDisplay from '@/components/ErrorDisplay.vue'
import { CentreInterface } from '@/types/Centre'
import _, { isEmpty, isNull, isUndefined } from 'lodash'
import { FournitureAdministrativeInterface } from '@/types/FournitureAdministrative'
import moment from 'moment'
import {
    getLibelleCandidat,
    getLibelleEtiquette,
    getLibelleGenerationEtiquette,
    TypeCandidat,
    TypeEtiquette,
    TypeGenerationEtiquette
} from '@/types/Etiquette'
import iconv from 'iconv-lite'
import { SujetInterface } from '@/types/Sujet'
import { ExportPostscriptEnabled, ProductionFournitureClient, ProductionFournitureMode } from '@/types/Parameter'

@Component({
    methods: {
        getLibelleCandidat,
        isNull,
        isEmpty,
        getLibelleGenerationEtiquette,
        getLibelleEtiquette
    },
    computed: {
        TypeEtiquette() {
            return TypeEtiquette
        },
        TypeGenerationEtiquette() {
            return TypeGenerationEtiquette
        },
        ProductionFournitureMode() {
            return ProductionFournitureMode
        },
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapState('repartitioncandidats', ['liste_centres', 'lastPage', 'totalRows', 'loading_repartition']),
        ...mapGetters('repartitioncandidats', ['get_centres_satures']),
        ...mapState('fournitureAdministrative', ['fournituresAdministratives', 'loading']),
        ...mapGetters('concour', ['banques']),
        ...mapState('ville', ['zones']),
        ...mapState('session', ['sessionSelect']),
        ...mapGetters('concour', ['concourById']),
        ...mapGetters('sujet', ['error']),
        liste_etiquettes() {
            const middle = Math.floor(Object.keys(TypeEtiquette).length / 2)
            return Object.keys(TypeEtiquette).slice(0, middle)
        },
        liste_candidats() {
            const middle = Math.floor(Object.keys(TypeCandidat).length / 2)
            return Object.keys(TypeCandidat).slice(0, middle)
        },
        liste_generation_etiquette() {
            const middle = Math.floor(Object.keys(TypeGenerationEtiquette).length / 2)
            return Object.keys(TypeGenerationEtiquette).slice(0, middle)
        },
        export_postscript_enabled(): boolean {
            const parameter = this.$store.getters['auth/findParameter']('exportPostscriptEnabled')?.value
            return parseInt(parameter) === ExportPostscriptEnabled.YES
        }
    },
    components: {
        ExaGenericTable,
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay
    }
})

export default class ProductionFournitures extends Vue {
    formatDate = formatDate
    Ability = Ability

    // Ensemble des colonnes du tableau
    genericfields: any = []

    sortBy          = '';
    sortDesc        = false;
    sortDirection   = 'asc';
    filter          = '';
    filterOn        = [];
    stickyHeader    = true;
    params: any = {
        page: 1,
        sort: 'name',
        direction: 'asc'
    }

    filtres:    any         = []
    dataForTab: Array<any>  = [
    ]

    options_filieres: Array<any> = []
    options_zones_export: any = []
    save_in_progress = false

    exportingIsWorking = false
    showPopupExportEtiquettes = false
    sujets: Array<SujetInterface> = []
    loading_export = false
    params_export: any = {
        type_etiquette: null,
        candidat: null,
        sujet: null,
        filiere: null,
        select_ordre_generation: null,
        ordre_generation: []
    }

    infos: any = null

    selectedDate = {
        userSelectDateType: 'closeInput',
        dateBetween: {
            date_start: '',
            date_end: ''
        },
        onlyEndDate: {
            date_end: ''
        }
    }

    documents: Array<FournitureAdministrativeInterface> = []
    contexte = ''

    /**
     * refreshInterface
     * @description Rafraichit l'interface lors du changement de session
     * @return {void}
     */
    @Watch('user_session_id')
    refreshInterface(): void {
        this.load()
    }

    /**
     * checkKeyUp
     * @description Modifie la valeur de l'input pour qu'il n'y ait pas de virgule ou de point
     * @param {any} e - Évenement
     * @return {void}
     */
    checkKeyUp(e: any): void {
        if (e.target.value.includes(',')) {
            e.target.value = e.target.value.replace(',', '')
        }
        if (e.target.value.includes('.')) {
            e.target.value = e.target.value.replace('.', '')
        }
    }

    // ------- Parametrage des bacs d'impression --------
    showParametrageBacsImpression = false

    /**
     * openParametrageBacsImpression
     * @description Ouvre la fenêtre de paramétrage des bacs d'impression
     * @return {void}
     */
    openParametrageBacsImpression(): void {
        const params: any = {
            'filter-dispo_papier': 1
        }
        if (this.contexte === ProductionFournitureMode.CENTRES) {
            params['filter-custom'] = 0
        }
        this.$store.dispatch('fournitureAdministrative/getFournitureAdministratives', params).then((response) => {
            this.documents = response.data.data
            this.showParametrageBacsImpression = true
        })
    }

    /**
     * closeParametrageBacsImpression
     * @description Ferme la fenêtre de paramétrage des bacs d'impression
     * @return {void}
     */
    closeParametrageBacsImpression(): void {
        this.showParametrageBacsImpression = false
    }

    /**
     * enregistrerParametrageBacsImpression
     * @description Enregistre le paramétrage des bacs d'impression
     * @return {void}
     */
    enregistrerParametrageBacsImpression(): void {
        const dataIds = []
        const dataNumeroBac = []
        for (let i = 0; i < this.documents.length; i++) {
            dataIds.push(this.documents[i].id)
            dataNumeroBac.push(this.documents[i].bac_impression)
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.$store.dispatch('fournitureAdministrative/setFournitureAdministrativesImpressionBac', { ids: dataIds,  fourniture_administrative_ids: { bac_impression:  dataNumeroBac } })
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                this.closeParametrageBacsImpression()
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // ------- Téléchargement des documents -------
    showTelechargementDocuments = false
    docsToDl: any = {}
    current_centre: any = null

    /**
     * openTelechargementDocuments
     * @description Ouvre la fenêtre de téléchargement des documents
     * @param {any} centre - Centre
     * @return {void}
     */
    openTelechargementDocuments(centre: any): void {
        this.current_centre = centre
        this.$store.dispatch('fournitureAdministrative/getFournitureAdministratives', { 'filter-dispo_papier': 1, 'filter-custom': 0 }).then((response) => {
            this.documents = response.data.data
            this.docsToDl = {}
            for (let i = 0; i < this.documents.length; i++) {
                this.docsToDl[this.documents[i].code] = true
            }
            this.showTelechargementDocuments = true
        })
    }

    /**
     * cancelTelechargementDocuments
     * @description Ferme la fenêtre de téléchargement des documents
     * @return {void}
     */
    cancelTelechargementDocuments(): void {
        this.current_centre = null
        this.showTelechargementDocuments = false
    }

    /**
     * confirmationImpression
     * @description Confirme l'impression des documents du centre
     * @return {void}
     */
    confirmationImpression(): void {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours...', infosToaster)

        const payload: any = {
            params: {
                printed: 1
            }
        }
        let route = ''

        if (this.contexte === ProductionFournitureMode.CENTRES) {
            route = 'centrefa/updateDateImpressionCentres'
            payload.centre_id =this.current_centre.id
        } else {
            route = 'centrefa/updateDateImpressionFournitures'
            payload.fourniture_id = this.current_centre.id
        }

        this.$store.dispatch(route, payload)
            .then((response) => {
                this.current_centre = response.data.data

                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.current_centre.f_adm_printed_at = response.data.data.f_adm_printed_at

                if (this.contexte === ProductionFournitureMode.CENTRES) {
                    this.$store.dispatch('repartitioncandidats/getListeCentres')
                        .then(() => {
                            this.setFiltersForGenericTab()
                            this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                        })
                } else {
                    this.$store.dispatch('fournitureAdministrative/getFournitureAdministratives', { 'filter-dispo_papier': 1 })
                        .then(() => {
                            this.setFiltersForGenericTab()
                            this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                        })
                }
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.cancelTelechargementDocuments()
            })
    }

    /**
     * annulerConfirmationImpression
     * @description Annule la confirmation d'impression des documents du centre
     * @return {void}
     */
    annulerConfirmationImpression(): void {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours...', infosToaster)

        let payload = {}
        let route = ''

        if (this.contexte === ProductionFournitureMode.CENTRES) {
            route = 'centrefa/updateDateImpressionCentres'
            payload = {
                centre_id: this.current_centre.id,
                params: {
                    printed: 0
                }
            }
        } else {
            route = 'centrefa/updateDateImpressionFournitures'
            payload = {
                fourniture_id: this.current_centre.id,
                params: {
                    printed: 0
                }
            }
        }

        this.$store.dispatch(route, payload)
            .then((response) => {
                this.current_centre = response.data.data

                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.current_centre.f_adm_printed_at = response.data.data.f_adm_printed_at

                if (this.contexte === ProductionFournitureMode.CENTRES) {
                    this.$store.dispatch('repartitioncandidats/getListeCentres')
                        .then(() => {
                            this.setFiltersForGenericTab()
                            this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                        })
                } else {
                    this.$store.dispatch('fournitureAdministrative/getFournitureAdministratives', { 'filter-dispo_papier': 1 })
                        .then(() => {
                            this.setFiltersForGenericTab()
                            this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                        })
                }
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.cancelTelechargementDocuments()
            })
    }

    exportWorking = false
    /**
     * downloadExport
     * @description Lance le téléchargement du fichier pdf ou postscript contenant les documents à imprimer
     * @param {'pdf' | 'postscript'} type - Type de fichier à télécharger
     * @return {void}
     */
    downloadImpression(type: 'pdf' | 'postscript'): void {
        if (this.current_centre) {
            this.exportWorking = true

            const idInfo = 't_info_' + Math.random()
            const infosToaster = {
                id: idInfo,
                toaster: 'b-toaster-top-right',
                variant: 'primary',
                noCloseButton: true,
                fade: true,
                noAutoHide: true
            }
            this.$bvToast.toast('Téléchargement en cours...', infosToaster)

            const docs = []
            for (const docToDl in this.docsToDl) {
                if (this.docsToDl[docToDl] === true) {
                    docs.push(docToDl)
                }
            }

            let params: any = {}
            const route = this.contexte === ProductionFournitureMode.CENTRES ? 'fournitureAdministrative/getImpressionFournitureAdministrativeCentres' : 'fournitureAdministrative/getImpressionFournitureAdministrativeFournitures'
            if (type === 'pdf') {
                params = {
                    params: {
                        pdf: 1,
                        format: 'b64'
                    }
                }

                if (this.contexte === ProductionFournitureMode.CENTRES) {
                    params.centre_id = this.current_centre.id
                    params.params.type_fournitures = docs
                } else {
                    params.fourniture_id = this.current_centre.id
                }
            } else {
                params = {
                    centre_id: this.current_centre.id,
                    params: { postscript: 1 }
                }

                if (this.contexte === ProductionFournitureMode.CENTRES) {
                    params.params.type_fournitures = docs
                }
            }

            this.$store.dispatch(route, params)
                .then(response => {
                    const fileName = getFileNameFromHeader(response.headers) || (type === 'pdf' ? 'document.pdf' : 'document.ps')
                    let url = ''

                    if (type === 'pdf') {
                        url = URL.createObjectURL(new Blob([base64ToArrayBuffer(response.data)]))
                    } else {
                        const ansiString = iconv.encode(response.data, 'win1252')
                        const blob = new Blob([ansiString], { type: 'text/plain;charset=windows-1252' })
                        url = URL.createObjectURL(blob)
                    }

                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('Download', fileName)
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)

                    this.cancelTelechargementDocuments()
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                    this.exportWorking = false
                })
        }
    }

    /**
     * showExportEtiquettes
     * @description Affiche la popup d'export des etiquettes
     * @return {void}
     */
    openExportEtiquettes(): void {
        this.params_export = {
            type_etiquette: null,
            candidat: null,
            sujet: null,
            filiere: null,
            ordre_generation: []
        }

        const parameters = this.$store.getters['auth/authUser'].parameters
        if (parameters) {
            for (const parameter of parameters) {
                if (parameter.code === 'productionFournituresClient') {
                    switch (parameter.value) {
                        case ProductionFournitureClient.CCS:
                            this.params_export.ordre_generation = [
                                TypeGenerationEtiquette.TYPE_FILIERE,
                                TypeGenerationEtiquette.TYPE_TOURNEE,
                                TypeGenerationEtiquette.TYPE_CENTRE,
                                TypeGenerationEtiquette.TYPE_SALLE
                            ]
                            break
                        case ProductionFournitureClient.CCMP:
                            this.params_export.ordre_generation = [
                                TypeGenerationEtiquette.TYPE_FILIERE,
                                TypeGenerationEtiquette.TYPE_ZONE,
                                TypeGenerationEtiquette.TYPE_CENTRE,
                                TypeGenerationEtiquette.TYPE_SALLE
                            ]
                            break
                        default:
                            this.params_export.ordre_generation = []
                            break
                    }
                }
            }
        }

        this.filterSujets()
        this.$store.commit('sujet/SET_ERROR', null)
        this.showPopupExportEtiquettes = true
    }

    /**
     * filterSujets
     * @description Filtre les sujets en fonction de la filière sélectionnée
     * @return {void}
     */
    filterSujets(): void {
        const array: Array<SujetInterface> = []
        const sujets = this.params_export.filiere === 0
            ? this.$store.getters['sujet/previsionnelSujets'] :
            this.$store.getters['sujet/previsionnelSujets'].filter((sujet: any) => sujet.concour_id === this.params_export.filiere)

        for (let i = 0; i < sujets.length; i++) {
            array.push(sujets[i].sujet)

            if (sujets[i].sujet.sujets.length > 0) {
                for (let j = 0; j < sujets[i].sujet.sujets.length; j++) {
                    array.push({
                        ...sujets[i].sujet.sujets[j],
                        annexe: true
                    })
                }
            }
        }

        this.sujets = array
    }

    /**
     * onFiliereChange
     * @description Réinitialise le sujet sélectionné lorsque la filière change
     * @param {number} value - Nouvelle valeur de la filière
     * @param {number} oldValue - Ancienne valeur de la filière
     * @return {void}
     */
    @Watch('params_export.filiere', { deep: true })
    onFiliereChange(value: number, oldValue: number): void {
        if (value !== oldValue) {
            this.params_export.sujet = 0
        }
    }

    /**
     * hideExportEtiquettes
     * @description Masque la popup d'export des etiquettes
     * @return {void}
     */
    closeExportEtiquettes(): void {
        this.showPopupExportEtiquettes = false
    }

    /**
     * addOrdreGenerationEtiquettes
     * @description Ajoute un type d'etiquette à la liste des etiquettes à générer
     * @return {void}
     */
    addOrdreGenerationEtiquettes(): void {
        if (
            !isUndefined(this.params_export.select_ordre_generation) &&
            !isNull(this.params_export.select_ordre_generation) &&
            this.params_export.ordre_generation.indexOf(this.params_export.select_ordre_generation) === -1
        ) {
            this.params_export.ordre_generation.push(this.params_export.select_ordre_generation)
        }
    }

    /**
     * removeOrdreGenerationEtiquettes
     * @description Supprime un type d'etiquette de la liste des etiquettes à générer
     * @param {number} index - Index de l'etiquette à supprimer
     * @return {void}
     */
    removeOrdreGenerationEtiquettes(index: number): void {
        this.params_export.ordre_generation.splice(index, 1)
    }

    /**
     * lance le telecchargement du fichier pdf des etiquetttes de la filière choisi
     */
    downloadEtiquette (filiere: any) {
        this.exportingIsWorking = true
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Téléchargement en cours ...', infosToaster)

        this.$store.dispatch('sujet/getPDFEtiquettesSujetsExport', { id: filiere.index })
            .then((response) => {
                // Logique de téléchargement
                const file_name = getFileNameFromHeader(response.headers) || 'etiquettes_' + filiere.name + '.pdf'
                const url = URL.createObjectURL(new Blob([base64ToArrayBuffer(response.data)]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', file_name)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.exportingIsWorking = false
            })
    }

    /**
     * lance le telecchargement du fichier pdf des etiquetttes de la filière choisi
     */
    downloadEtiquetteAmenagements () {
        this.exportingIsWorking = true
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Téléchargement en cours ...', infosToaster)

        this.$store.dispatch('sujet/getPDFEtiquettesSujetsAmenagementsExport')
            .then((response) => {
                // Logique de téléchargement
                const file_name = getFileNameFromHeader(response.headers) || 'etiquettes_sujets_candidats_amenagements.pdf'
                const url = URL.createObjectURL(new Blob([base64ToArrayBuffer(response.data)]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', file_name)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.exportingIsWorking = false
            })
    }

    /**
     * downloadEtiquettes
     * @description Lance le téléchargement des etiquettes
     * @return {void}
     */
    downloadEtiquettes(): void {
        this.$store.commit('sujet/SET_ERROR', null)
        this.loading_export = true

        const payload = {
            format: 'b64',
            type_etiquette: this.params_export.type_etiquette,
            type_candidat: this.params_export.candidat,
            sujet_id: this.params_export.sujet,
            concour_id: this.params_export.filiere,
            ordre: this.params_export.ordre_generation
        }

        if (this.params_export.sujet === 0) {
            delete payload.sujet_id
        }

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Génération en cours...', infosToaster)

        let request = ''
        if (payload.type_etiquette === TypeEtiquette.TYPE_CANTINE) {
            request = 'sujet/getEtiquettesCantinesExport'
        } else {
            request = 'sujet/getEtiquettesSujetsExport'
        }

        this.$store.dispatch(request, payload)
            .then((response) => {
                const fileName = getFileNameFromHeader(response.headers) || 'document.pdf'
                const url = URL.createObjectURL(new Blob([base64ToArrayBuffer(response.data)]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)

                this.closeExportEtiquettes()
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.loading_export = false
            })
    }

    /**
     * downloadDecisions
     * Récupére le ZIP d'export des décisions du centre et lance le téléchargement
     * @param {CentreInterface} centre - Centre
     * @return {void}
     */
    downloadDecisions(centre: CentreInterface): void {
        this.exportingIsWorking = true
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours de création ...', infosToaster)

        this.$store.dispatch('centre/exportDecisions', centre.id)
            .then((response) => {
                // Logique de téléchargement
                const fileName = getFileNameFromHeader(response.headers) || `amenagements_candidats_centres_${centre.name}.zip`
                const url = URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.exportingIsWorking = false
            })
    }

    // --- etatConsultation
    etatConsultation: {etat: number; ouverture: string|null; fermeture: string|null} = {
        etat: 1,
        ouverture: null,
        fermeture: null
    }

    /**
     * etatConsultationChangeHandler
     * @description Gère les changements d'état du formulaire de la consultation des documents
     * @return {void}
     */
    dateErreur = ''
    etatConsultationChangeHandler(): void {
        this.dateErreur = ''
        const dateNow = new Date(Date.now()).toISOString()

        if (Number(this.etatConsultation.etat) === 1) {
            if (!this.etatConsultation.ouverture || !this.etatConsultation.fermeture) {
                this.dateErreur = 'La date d\'ouverture et la date de fermeture doivent être saisies...'
            } else if (this.etatConsultation.ouverture === '' || this.etatConsultation.fermeture === '') {
                this.dateErreur = 'La date d\'ouverture et la date de fermeture doivent être saisies...'
            } else {
                const ouvert = new Date(this.etatConsultation.ouverture).toISOString()
                const ferme = new Date(this.etatConsultation.fermeture).toISOString()
                if (diffTwoDatesInMinutes(ouvert, ferme) < 0) {
                    this.dateErreur = 'La date d\'ouverture doit être antérieure ou égale à la date de Fermeture...'
                } else if (diffTwoDatesInMinutes(formatDateYYYYMMDD(dateNow), ouvert) < 0) {
                    this.dateErreur = 'La date d\'ouverture doit être postérieure ou égale à la date d\'aujourd\'hui...'
                }  else if (diffTwoDatesInMinutes(formatDateYYYYMMDD(dateNow), ferme) < 0) {
                    this.dateErreur = 'La date de fermeture doit être postérieure ou égale à la date d\'aujourd\'hui...'
                }
            }
        } else if (Number(this.etatConsultation.etat) === 2) {
            if (!this.etatConsultation.fermeture) {
                this.dateErreur = 'La date de fermeture doit être saisie...'
            } else if (this.etatConsultation.fermeture === '') {
                this.dateErreur = 'La date de fermeture doit être saisie...'
            }  else  {
                const ferme = new Date(this.etatConsultation.fermeture).toISOString()
                if (diffTwoDatesInMinutes(formatDateYYYYMMDD(dateNow), ferme) < 0) {
                    this.dateErreur = 'La date de fermeture doit être postérieure ou égale à la date d\'aujourd\'hui...'
                }
            }
        }
    }

    /**
     * updateEtatConsultation
     * @description Enregistre les changements d'état de la consultation des documents
     * @return {void}
     */
    updateEtatConsultation(): void {
        let date_end: any = null
        if (this.etatConsultation.fermeture) {
            date_end =  moment(this.etatConsultation.fermeture).local()
            date_end.hours(23)
            date_end.minutes(59)
            date_end.seconds(59)
        }
        const datasConsultation  = {
            bibliotheque_fa_start_at : this.etatConsultation.ouverture,
            bibliotheque_fa_end_at : date_end.toISOString()
        }
        if (Number(this.etatConsultation.etat) === 0) {
            datasConsultation.bibliotheque_fa_start_at = null
            datasConsultation.bibliotheque_fa_end_at = null
        } else if (Number(this.etatConsultation.etat) === 2) {
            datasConsultation.bibliotheque_fa_start_at = null
        }

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.$store.dispatch('fournitureAdministrative/setConsultationFournituresAdministratives', datasConsultation)
            .then((response) => {
                this.activeSession = response.data.data
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // ------- gestion genericTable ------------

    /**
     * resetDataTable
     * @description Réinitialise le tableau de données
     * @return {void}
     */
    @Watch('liste_centres')
    @Watch('fournituresAdministratives')
    resetDataTable(): void {
        // Fields du tableau
        if (this.contexte === ProductionFournitureMode.CENTRES) {
            this.genericfields = [
                { key: 'name', label: 'Centre', sortable: true, class: '', type: 'text' },
                { key: 'filiere', label: 'Filière', sortable: false, class: '', type: 'text' },
                { key: 'ville.name', label: 'Ville', sortable: true, class: '', type: 'text' },
                { key: 'ville.zone.name', label: 'Zone', sortable: true, class: '', type: 'text' },
                { key: 'placement_locked_at', label: 'Répartition des candidats', sortable: true, class: 'text-center', type: 'text' },
                { key: 'f_adm_printed_at', label: 'Impression confirmée', sortable: true, class: 'text-center', type: 'text' },
                { key: 'fourniture', label: 'Fournitures', sortable: false, class: 'text-center', type: 'text' },
                { key: 'export', label: 'Exportation', sortable: false, class: 'text-center', type: 'text' }
            ]

            if (this.genericfields.length !== 0) {
                const datas = _.orderBy(this.$store.getters['repartitioncandidats/liste_centres'], 'ville.name', 'asc')
                this.setDataForGenericTab(datas)
            }
        } else {
            this.genericfields = [
                { key: 'name', label: 'Libellé', sortable: true, class: '', type: 'text' },
                { key: 'f_adm_printed_at', label: 'Impression confirmée', sortable: true, class: 'text-center', type: 'text' },
                { key: 'fourniture', label: 'Télécharger', sortable: false, class: 'text-center', type: 'text' }
            ]

            if (this.genericfields.length !== 0) {
                const fournitures = this.$store.getters['fournitureAdministrative/fournituresAdministratives'].filter((f: any) => f.dispo_papier === 1)
                const datas = _.orderBy(fournitures, 'name', 'asc')
                this.setDataForGenericTab(datas)
            }
        }
    }

    /**
     * setDataForGenericTab
     * @description Charge les données dans le tableau
     * @param {any} poData - Données à charger
     * @param {boolean} isLoadMore - Indique si on charge plus de données
     * @return {void}
     */
    setDataForGenericTab(poData: any, isLoadMore = false): void {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const result of poData) {
                if (this.contexte === ProductionFournitureMode.CENTRES) {
                // Chaine des concours du centre
                    const concours_string = result?.concours
                        ?.map((concour: any) => concour.name)
                        ?.join(', ') || ''

                    // Zone du centre
                    const zone = result?.ville?.zone?.name || ''

                    const repartitionCandidat = [{ name:'check', class:'text-secondary' }]
                    if (result.placement_locked_at) {
                        repartitionCandidat[0].class = 'text-success'
                    }

                    const classDate = 'text-secondary text-center'
                    const dl = [{ name:'arrow-alt-to-bottom', class: 'text-primary', id: result.id, data: result, title: 'Téléchargements des documents' }] // class: result.ajustements_validated_at ? 'text-info' : 'text-secondary'
                    const exportation = [{ name:'arrow-alt-to-bottom', class: 'text-primary', id: result.id, data: result, title: 'Exportation des décisions' }]

                    const line = [
                        { label: '', item: result.name, type: 'text', typeAction: null, class: '' },
                        { label: '', item: concours_string, type: 'text', typeAction: null, class: '' },
                        { label: '', item: result.ville ? result.ville.name : '-', type: 'text', typeAction: null, class: 'font_medium ' + '' },
                        { label: '', item: zone, type: 'text', typeAction: null, class: '' },
                        { label: '',  item: repartitionCandidat,  type: 'icons',   typeAction: null,  class: 'text-center ', title: '' },
                        { label: '', item: result.f_adm_printed_at ? formatDate(result.f_adm_printed_at) : '', type: 'text', typeAction: null, class: classDate },
                        { label: 'link', item: dl, type: 'icons', typeAction: 'telechargements', class: 'text-center' },
                        { label: 'exportation', item: exportation, type: 'icons', typeAction: 'exportation', class: 'text-center' }
                    ]
                    this.dataForTab.push(line)
                } else {
                    const classDate = 'text-secondary text-center'
                    const dl = [{ name:'arrow-alt-to-bottom', class: 'text-primary', id: result.id, data: result, title: 'Téléchargements des documents' }]

                    const line = [
                        { label: '', item: result.name, type: 'text', typeAction: null, class: '' },
                        { label: '', item: result.printed_at ? formatDate(result.printed_at) : '', type: 'text', typeAction: null, class: classDate },
                        { label: 'link', item: dl, type: 'icons', typeAction: 'telechargements', class: 'text-center' }
                    ]
                    this.dataForTab.push(line)
                }
            }
        }
    }

    /**
     * handleTableEvent
     * @description Gère les événements du tableau
     * @param {any} paParams - Paramètres de l'événement [paParams[0] => l'action, paParams[1] => l'id de l'item]
     * @return {void}
     */
    handleTableEvent(paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'goSuivi':
                    this.$router.push('/suivi_sujets_fournitures/' + paParams[1][0].result.id + '?tab=' + paParams[1][0].result.tab)
                    break
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'telechargements':
                    this.openTelechargementDocuments(paParams[1][0].data)
                    break
                case 'exportation':
                    this.downloadDecisions(paParams[1][0].data)
                    break
                default:
                    break
            }
        }
    }

    /**
     * filtreSortHandler
     * @description Gère les filtres et le tri du tableau
     * @param {any} params - Paramètres de l'événement
     * @return {void}
     */
    // Chargement des données du tableau
    filtreSortHandler(params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            // Des filtres sont appliqués on rappele le serveur
            if (this.contexte === ProductionFournitureMode.CENTRES) {
                this.$store.dispatch('repartitioncandidats/getListeCentres', params).then((response) => {
                    const datas = response.data.data
                    this.setDataForGenericTab(datas)
                })
            } else {
                params = {
                    ...params,
                    'filter-dispo_papier': 1
                }
                this.params = params
                this.$store.dispatch('fournitureAdministrative/getFournitureAdministratives', params).then((response) => {
                    const datas = response.data.data
                    this.setDataForGenericTab(datas)
                })
            }
        }
    }

    /**
     * loadHandler
     * @description Gère le chargement des données du tableau
     * @param {any} params - Paramètres de l'événement
     * @return {void}
     */
    loadHandler(params: any): void {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            if (this.contexte === ProductionFournitureMode.CENTRES) {
                this.$store.dispatch('repartitioncandidats/getMoreListeCentres', params).then((response) => {
                    this.setDataForGenericTab(response.data.data)
                })
            }
        }
    }


    /**
     * setFiltersForGenericTab
     * @description Création des filtres pour le tableau
     * @return {void}
     */
    setFiltersForGenericTab(): void {
        // Options zones
        const zones = this.$store.getters['ville/zones']
        const options_zones = []
        for (const z in zones) {
            options_zones.push({ index: zones[z].id, name: zones[z].name })
        }

        // Options statuts
        const options_statuts = []
        options_statuts.push({ index: 0, name: 'En préparation' })
        options_statuts.push({ index: 1, name: 'En production' })
        options_statuts.push({ index: 2, name: 'En livraison' })
        options_statuts.push({ index: 3, name: 'AR Centre' })

        // Options statuts
        const options_repartition_candidats = []
        options_repartition_candidats.push({ index: 1, name: 'Répartition des candidats validée' })
        options_repartition_candidats.push({ index: 0, name: 'Répartition des candidats non validée' })

        this.filtres =
        [
            { libelle: 'Centre', defautOptionlibelle: 'Rechercher un',   model: 'name',  value: '', index: 'name', datas: null, loading: this.$store.getters['repartitioncandidats/loading'], options: { type: 'form', fieldsKey: 'name' } },
            { libelle: 'Filière', defautOptionlibelle: 'Rechercher une',  model: 'concours_id', value: '', index: 'filiere', datas: this.options_filieres, loading: this.$store.getters['repartitioncandidats/loading'], options: { type: 'deroulant', fieldsKey: 'filiere' } },
            { libelle: 'Ville', defautOptionlibelle: 'Rechercher une',   model: 'ville.name',  value: '', index: 'ville.name', datas: null, loading: this.$store.getters['repartitioncandidats/loading'], options: { type: 'form', fieldsKey: 'ville.name' } },
            { libelle: 'Zone', defautOptionlibelle: 'Rechercher une',  model: 'ville.zone_id', value: '', index: 'ville.zone.name', datas: options_zones, loading: this.$store.getters['repartitioncandidats/loading'], options: { type: 'deroulant', fieldsKey: 'ville.zone.name' } },
            { libelle: 'Répartition des candidats', defautOptionlibelle: 'Rechercher un',  model: 'placement_locked_at', value: '', index: 'placement_locked_at', datas: options_repartition_candidats, loading: this.$store.getters['repartitioncandidats/loading'], options: {  type: 'deroulant', fieldsKey: 'placement_locked_at'  } }
        ]
    }


    // --------------------------

    /**
     * initDates
     * @description Initialise les dates pour la publication de l'inventaire
     * @return {void}
     */
    activeSession: any = null
    initDates(): void {
        this.activeSession = this.$store.getters['session/sessionSelect']

        if (this.activeSession && this.activeSession.inventaire_fournitures_end_at !== null && this.activeSession.inventaire_fournitures_start_at !== null) {
            this.selectedDate.dateBetween.date_start = formatDateYYYYMMDD(this.activeSession.inventaire_fournitures_start_at)
            this.selectedDate.dateBetween.date_end = formatDateYYYYMMDD(this.activeSession.inventaire_fournitures_end_at)
            this.selectedDate.onlyEndDate.date_end = ''
            this.selectedDate.userSelectDateType = 'between'
        } else if (this.activeSession && this.activeSession.inventaire_fournitures_end_at !== null) {
            this.selectedDate.onlyEndDate.date_end = formatDateYYYYMMDD(this.activeSession.inventaire_fournitures_end_at)
            this.selectedDate.dateBetween.date_start = ''
            this.selectedDate.dateBetween.date_end = ''
            this.selectedDate.userSelectDateType = 'onlyEndDate'
        }
    }

    // ------chargement-----------------

    /**
     * load
     * @description Charge les données de la page
     * @return {void}
     */
    async load(): Promise<void> {
        // Chargement des concours
        this.$store.dispatch('concour/getConcours').then(() => {
            const filieres = _.orderBy(this.$store.getters['concour/banques'], 'ordre')
            this.options_filieres = []
            for (const f in filieres) {
                this.options_filieres.push({ index: filieres[f].id, name: filieres[f].name })
            }
        })

        // Chargement des zones
        this.$store.dispatch('ville/getZones').then(() => {
            for (const z in this.$store.getters['ville/zones']) {
                this.options_zones_export.push(this.$store.getters['ville/zones'][z].id)
            }
        })

        // Chargement des sujets
        await this.$store.dispatch('sujet/getPrevisionnelSujets',  { perPage: 0 })

        // Chargement des compteurs issus du dashboard
        this.$store.dispatch('dashboardEcrits/getDashboardInformations').then((response: any) => {
            this.infos = response.data.data
        })

        this.initDates()

        // Chargement des centres - Si context par centre
        if (this.contexte === ProductionFournitureMode.CENTRES) {
            this.$store.dispatch('repartitioncandidats/getListeCentres').then(() => {
                this.setFiltersForGenericTab()
            })
        } else {
            this.$store.dispatch('fournitureAdministrative/getFournitureAdministratives', { 'filter-dispo_papier': 1 }).then(() => {
                this.setFiltersForGenericTab()
            })
        }

    }

    /**
     * mounted
     * @description Fonction lancée au montage du composant
     * @return {void}
     */
    mounted(): void {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            const parameter = this.$store.getters['auth/findParameter']('productionFournituresMode')?.value
            this.contexte = parameter || ''

            this.$store.commit('repartitioncandidats/SET_LOADING', true)
            this.load()
        }
    }
}
