





































































































import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { BModal } from 'bootstrap-vue'
import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { EtapeCandidatureEnum, SearchCandidatInterface } from '../../../types/CandidatJob'
import { CandidatJobDescriptionStatus, getCandidatJobDescriptionStatus } from '../../../types/CandidatJobDescription'
import { JobDescriptionStatus } from '../../../types/JobDescription'
import ErrorDisplay from '../../ErrorDisplay.vue'

@Component({
    name: 'PopupAddCandidatureComponent',
    components: {
        ErrorDisplay,
        FontAwesomeIcon,
        BModal
    },
    computed: {
        emptySearch(): boolean {
            return !(this.$data.search.code !== '' || this.$data.search.name !== '' || this.$data.search.first_name !== '')
        },
        candidatureStatus() {
            const middle = Math.floor(Object.keys(CandidatJobDescriptionStatus).length / 2)
            return Object.keys(CandidatJobDescriptionStatus).slice(0, middle)
                .map(status => getCandidatJobDescriptionStatus(parseInt(status)))
        },
        ...mapGetters('candidatJobDescription', ['loading', 'error'])
    },
    props: {
        show: {
            type: Boolean,
            required: false
        }
    },
    watch: {
        show: {
            handler: 'resetError'
        },
        etape: {
            handler: 'resetError'
        },
        searchPoste: {
            handler: 'searchFichePoste'
        }
    }
})

export default class PopupAddCandidature extends Vue {
    EtapeCandidatureEnum = EtapeCandidatureEnum
    etape = EtapeCandidatureEnum.SELECT_CANDIDAT as EtapeCandidatureEnum
    listCandidats = null as Array<any> | null
    listJobs = null as Array<any> | null
    processing = false
    searchLoading = false
    searchPoste = ''
    selectedCandidat: any = null
    selectedJob: any = null
    selectedStatus: any = null
    search = {
        code: '',
        name: '',
        first_name: ''
    } as SearchCandidatInterface

    /**
     * @description Réinitialise l'erreur lors de changement d'étape
     * @return {void}
     */
    resetError(): void {
        this.$store.commit('candidatJobDescription/SET_ERROR', null)
    }

    /**
     * @description Attends le rafraichissement complet du navigateur
     * @return {Promise<void>}
     */
    waitRequestAnimationFrame(): Promise<void> {
        return new Promise((resolve) => {
            this.$nextTick(() => {
                window.requestAnimationFrame(() => {
                    window.requestAnimationFrame(() => {
                        resolve()
                    })
                })
            })
        })
    }

    /**
     * @description Lance la recherche d'un candidat
     * @returns {void}
     */
    searchCandidat(): void {
        if (this.searchLoading) {
            return
        }
        this.searchLoading = true
        this.resetError()

        this.selectedCandidat = null

        const params: any = Object.assign({}, this.search)

        Object.keys(params).forEach((key) => {
            if (params[key] === '') {
                delete params[key]
            } else if (['name', 'first_name'].includes(key)) {
                params[key] = `%${params[key]}%`
            }
        })

        this.$store.dispatch('candidatJobDescription/searchCandidates', params)
            .then((response: any) => {
                this.listCandidats = response.data.data || []
            })
            .finally(() => {
                this.searchLoading = false
            })
    }

    /**
     * @description Recherche de fiche de poste
     * @returns {void}
     */
    searchFichePoste(): void {
        this.resetError()
        if (this.searchPoste.trim()) {
            const listJobs: Array<any> = this.transformJobDescriptions() || []
            this.listJobs = [] as Array<any>

            listJobs.forEach((jobDescription: any) => {
                const postes: Array<any> = []
                jobDescription.postes.forEach((poste: any) => {
                    if (poste.name.toLowerCase().includes(this.searchPoste.toLowerCase())) {
                        postes.push(poste)
                    }
                })

                if (postes.length) {
                    (this.listJobs as Array<any>).push({
                        ...jobDescription,
                        postes: postes
                    })
                }
            })
        } else {
            this.listJobs = this.transformJobDescriptions() || []
        }
    }

    /**
     * @description Vérifie si l'utilisateur consulte en détail un établissement
     * @returns {void}
     */
    isInDetailEstablishment(): boolean {
        return !!this.$route.params.working_establishment_id && this.$route.path === `/bourse_emploi/etablissements/${this.$route.params.working_establishment_id}`
    }

    /**
     * @description Transformation des postes en établissements contenant des postes
     * @returns {Array<any>}
     */
    transformJobDescriptions(): Array<any> {
        let jobDescriptions = JSON.parse(JSON.stringify(this.$store.getters['jobDescription/jobDescriptions']));
        jobDescriptions = jobDescriptions.filter((job: any) => job.status !== JobDescriptionStatus.STATUS_DRAFT);
        const establishmentsById: any = {};

        jobDescriptions.forEach((job: any) => {
            if (!establishmentsById[job.working_establishment_id]) {
                establishmentsById[job.working_establishment_id] = {
                    ...job.workingEstablishment,
                    postes: []
                };
            }

            establishmentsById[job.working_establishment_id].postes.push({
                ...job,
                workingEstablishment: undefined
            });
        });

        if (this.isInDetailEstablishment()) {
            return Object.values(establishmentsById)
                .filter((establishment: any) => establishment.id === Number(this.$route.params.working_establishment_id))
        }

        return Object.values(establishmentsById);
    }

    /**
     * @description Sélection d'un candidat
     * @returns {Promise<void>}
     */
    async confirmSelectCandidat(): Promise<void> {
        this.etape = EtapeCandidatureEnum.SELECT_JOB
        this.searchLoading = true

        this.waitRequestAnimationFrame()
            .then(async () => {
                await this.$store.dispatch('jobDescription/getJobDescriptions', {
                    candidat_id: this.selectedCandidat.id,
                    perPage: -1
                })
                this.listJobs = this.transformJobDescriptions() || []
                this.searchLoading = false
            })
    }

    /**
     * @description Création de la candidature
     * @returns {void}
     */
    createCandidature(): void {
        if (this.processing) {
            return
        }
        this.processing = true
        this.resetError()

        this.$store.dispatch('candidatJobDescription/addCandidacy', {
            candidat_id: this.selectedCandidat.id,
            job_description_id: this.selectedJob.id,
            status: this.selectedStatus
        })
            .then(() => {
                this.$emit('added')
                this.closeModal()
            })
            .catch(() => {
                this.processing = false
            })
    }

    /**
     * @description Ferme la popup
     * @returns {void}
     */
    closeModal(): void {
        this.etape = EtapeCandidatureEnum.SELECT_CANDIDAT
        this.listCandidats = null
        this.selectedCandidat = null
        this.listJobs = null
        this.selectedJob = null
        this.selectedStatus = null
        this.searchLoading = false
        this.processing = false
        this.searchPoste = ''
        this.search = {
            code: '',
            name: '',
            first_name: ''
        }
        this.$emit('close')
    }
}

