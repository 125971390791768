





















































































































































import { Vue, Component, Watch }    from 'vue-property-decorator'
import { mapState }                 from 'vuex'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'
import { Ability }                  from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { checkIcone, formatDate }   from '@/utils/helpers'
import Back from '@/components/Tools/Back.vue'
import PopupEditCandidat            from '@/components/Candidat/PopupEditCandidat.vue'
import { DemandeAmenagement, TypeMesure } from '@/types/Amenagement'
import { getTypeAmenagement, TypeAmenagement } from '@/types/Salle'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { PlacementLock } from '@/types/Centre'
import { PlacementState } from '@/types/Candidat'
import {DecisionAmenagementInterface, EtatDecisionAmenagement} from "@/types/DecisionAmenagement";
import { isEmpty } from "lodash";

@Component({
    methods: {
        isEmpty
    },
    computed: {
        EtatDecisionAmenagement() {
            return EtatDecisionAmenagement
        },
        TypeMesure() {
            return TypeMesure
        },
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapState('repartitioncandidats', ['liste_placements', 'meta_candidats', 'loading_repartition', 'lastPage', 'error_repartition'])
    },
    components: {
        ExaGenericTable,
        Back,
        PopupEditCandidat,
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay
    }
})

export default class Placements extends Vue
{
    formatDate = formatDate
    Ability = Ability
    getTypeAmenagement = getTypeAmenagement
    TypeAmenagement = TypeAmenagement
    DemandeAmenagement = DemandeAmenagement
    PlacementLock = PlacementLock

    // Ensemble des colonnes du tableau
    genericfields = [
        { key: 'etatEdit', label: '', sortable: false,    class: '', type: 'action' },
        { key: 'code', label: 'Code', sortable: true,  class: '', type: 'text' },
        { key: 'name', label: 'Identité', sortable: true, class: '', type: 'text' },
        { key: 'concour.name', label: 'Filière', sortable: true, class: 'text-center', type: 'text' },
        { key: 'demande_amenagement', label: 'Aménagements', sortable: true, class: 'text-center', type: 'text' },
        { key: 'salle.code', label: 'Salle', sortable: true, class: 'text-start', type: 'html' },
        { key: 'place', label: 'Place', sortable: true, class: 'text-center', type: 'html' }
    ]

    sortBy          = '';
    sortDesc        = false;
    sortDirection   = 'asc';
    filter          = '';
    filterOn        = [];
    stickyHeader    = true;
    params: any = {}

    filtres:    any         = []
    dataForTab: Array<any>  = [
    ]

    centre_select: any = null
    showModalEditCandidat?: boolean = false

    exportingIsWorking = false
    tabSelected = ''
    showPopupPlacement = false
    selected_candidat: any = null
    salle_select: any = null
    save_en_cours = false
    showPopupConfirmVerrou = false
    lock_state: any = null

    @Watch('liste_placements')
    resetDataTable () {
        this.setDataForGenericTab(this.$store.state.repartitioncandidats.liste_placements)
    }

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    /**
     * getDecisionAmenagementsEtat
     * Récupération de l'état de la décision d'aménagement
      * @param {any} decisionAmenagements - Liste des décisions d'aménagement
     * @returns {EtatDecisionAmenagement | null} Etat de la décision d'aménagement
     */
    getDecisionAmenagementsEtat(decisionAmenagements: any): EtatDecisionAmenagement | null {
        if (!isEmpty(decisionAmenagements)) {
            return decisionAmenagements.find((d: DecisionAmenagementInterface) => d.type === TypeMesure.TYPE_ECRIT)?.etat
        }
        return null
    }

    /**
     * Chargement des données du tableau
     */
    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        const can = this.$store.getters['auth/can'](Ability.ECR_PREPA_MANAGE)
        const icone = checkIcone(Ability.ECR_PREPA_MANAGE, can)
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                const identity = result.name && result.first_name ? result.name + ' ' +  result.first_name : ' - '

                // Récupération de la décision d'aménagement
                const iconDemandeAm: any[] = []
                let titleDemandeAmenagement = ''
                let decision: DecisionAmenagementInterface = {} as DecisionAmenagementInterface

                // Récupération de la décision d'aménagement
                if (!isEmpty(result.decisionAmenagements)) {
                    const index = result.decisionAmenagements.findIndex(
                        (d: DecisionAmenagementInterface) => d.type === TypeMesure.TYPE_ECRIT
                    )
                    decision = result.decisionAmenagements[index]
                }

                if (result.demande_amenagement) {
                    let state: {title: string; class: string} = { title: 'Non traitée', class: 'text-secondary' }

                    if (!isEmpty(decision) && decision.etat === EtatDecisionAmenagement.ETAT_VALIDE) {
                        state = { title: 'Traitée', class: 'text-success' }
                    }
                    iconDemandeAm.push({
                        name: 'check',
                        class: state.class,
                        title: state.title
                    })
                    titleDemandeAmenagement = state.title
                }


                // Salle
                const libelle_salle = result.salle ? result.salle.name : ''
                const type_salle = result.salle ? getTypeAmenagement(result.salle.amenagement).libelle : ''
                const salle: any[] = []
                salle.push({ name:'exclamation-triangle', typeIcon: 'fal', class:'text-danger', title: '', value_comp: libelle_salle + ' - ' + type_salle })

                // Place
                const place: any[] = []
                place.push({ name:'exclamation-triangle', typeIcon: 'fal', class:'text-danger', title: '', value_comp: result.place })


                const line: any = [
                    { label: icone.label,       item: result.id,    type: 'action',  typeAction: 'edit',        class: 'commons_first_action_button btn_action_ligne', icon:icone.icon, disabled: false },
                    { label: 'Fiche candidat',  item: result.id,    type: 'actionText', typeAction: 'editCandidat', class: 'text-info item_action', text: result.code },
                    { label: 'Fiche candidat',  item: result.id,    type: 'actionText', typeAction: 'editCandidat', class: 'text-info item_action width_col_identity', text: identity },
                    { label: '',                item: result.concour ? result.concour.name : '-',   type: 'text',  typeAction: null,  class: 'text-center' },
                    { label: '',                item: iconDemandeAm,  type: 'icons',   typeAction: null,          class: 'text-center', title: titleDemandeAmenagement }

                ]

                // Check si le candidat a des amenagement et s'il est dans une salle qui n'est pas standard
                if (result.placement_state === PlacementState.PLACEMENT_OK) {
                    line.push({ label: '', item: libelle_salle + ' - ' + type_salle,   type: 'text', typeAction: null, class: 'text-start barre_tertiary item_action' })
                    line.push({ label: '', item: result.place, type: 'text',  typeAction: null, class: 'text-center barre_tertiary item_action' })
                } else {
                    line.push({ label: '', item: salle, type: 'icons', typeAction: null,  class: 'text-start barre_tertiary item_action' })
                    line.push({ label: '', item: place, type: 'icons',  typeAction: null, class: 'text-center barre_tertiary' })
                }
                this.dataForTab.push(line)
            }
        }
    }

    // Création des filtres pour le tableau
    setFiltersForGenericTab()
    {
        // Options pour le filtre des salles
        const centre_select = this.$store.state.repartitioncandidats.liste_centres.find((c: any) => c.id.toString() === this.$route.params.centre_id)
        const options_salles = []
        if (centre_select.salles) {
            for (const s in centre_select.salles) {
                const type_salle = centre_select.salles[s] ? getTypeAmenagement(centre_select.salles[s].amenagement).libelle : ''
                options_salles.push({ index: centre_select.salles[s].code, name: centre_select.salles[s].name + ' - ' + type_salle })
            }
        }


        // Options pour le filtre des demandes d'aménagements
        const etats_demande_amenagement = []
        etats_demande_amenagement.push({ index: DemandeAmenagement.DEMANDE_AMENAGEMENT_NON_HANDI, name: 'Candidats sans demande d\'aménagement' })
        etats_demande_amenagement.push({ index: (DemandeAmenagement.DEMANDE_AMENAGEMENT_HANDI + DemandeAmenagement.DEMANDE_AMENAGEMENT_HANDI_TRAITE_ECRIT), name: 'Candidats avec une demande d\'aménagement' })
        etats_demande_amenagement.push({ index: DemandeAmenagement.DEMANDE_AMENAGEMENT_HANDI, name: 'Candidats avec une demande d\'aménagement non traitée' })
        etats_demande_amenagement.push({ index: DemandeAmenagement.DEMANDE_AMENAGEMENT_HANDI_TRAITE_ECRIT, name: 'Candidats avec une demande d\'aménagement traitée' })

        // Options filières
        const filieres = this.$store.getters['concour/banques']
        const options_filieres = []
        for (const f in filieres) {
            options_filieres.push({ index: filieres[f].id, name: filieres[f].name })
        }

        this.filtres =
        [
            { libelle: 'Code',  defautOptionlibelle: 'Rechercher un',   model: 'code',  value: '', index: 'code',   datas: null,  loading: this.$store.state.repartitioncandidats.loading_repartition, options: { type: 'form', fieldsKey: 'code' } },
            { libelle: 'Nom',   defautOptionlibelle: 'Rechercher un',   model: 'name',  value: '', index: 'name',   datas: null,  loading: this.$store.state.repartitioncandidats.loading_repartition, options: { type: 'form', fieldsKey: 'name' } },
            { libelle: 'Filière', defautOptionlibelle: 'Rechercher une',    model: 'concour.id',       value: '', index: 'concour.name',  datas: options_filieres,    loading: this.$store.state.repartitioncandidats.loading_repartition, options: { type: 'deroulant', fieldsKey: 'concour.name' } },
            { libelle: 'État de Demande d\'aménagement', defautOptionlibelle: 'Rechercher par ', model: 'demande_amenagement', value: '', index: 'demande_amenagement', datas: etats_demande_amenagement, loading: this.$store.state.repartitioncandidats.loading_repartition, options: { type: 'deroulant', fieldsKey: 'demande_amenagement' } },
            { libelle: 'Salles', defautOptionlibelle: 'Toutes les',     model: 'salle.code', value: '', index: 'salle.code',  datas: options_salles, loading: this.$store.state.repartitioncandidats.loading_repartition, options: { type: 'deroulant', fieldsKey: 'salle.code' } },
            { libelle: 'Place', defautOptionlibelle: 'Rechercher une',  model: 'place',  value: '', index: 'place',   datas: null,  loading: this.$store.state.repartitioncandidats.loading_repartition, options: { type: 'form', fieldsKey: 'place', strict: true } }
        ]
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            let selectedCandidat = null
            switch (paParams[0])
            {
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'edit':
                    // Récupération de l'étab by ID
                    selectedCandidat = this.$store.state.repartitioncandidats.liste_placements.filter((candidat: any) => candidat.id === paParams[1])[0]
                    if (selectedCandidat)
                    {
                        this.$store.state.repartitioncandidats.error_repartition = null
                        this.selected_candidat = selectedCandidat
                        if (this.selected_candidat.salle_id !== null) {
                            this.salle_select = this.selected_candidat.salle
                        } else {
                            this.salle_select = { id: null }
                        }
                        // Filtre les aménagements pour n'afficher que les écrits
                        this.selected_candidat.amenagements = this.selected_candidat.amenagements.filter((a: any) => a.type === TypeMesure.TYPE_ECRIT)
                        this.showPopupPlacement = true
                    }
                    break

                case 'editCandidat':
                    // Récupération de l'étab by ID
                    selectedCandidat = this.$store.state.repartitioncandidats.liste_placements.filter((r: any) => r.id === paParams[1])[0]
                    if (selectedCandidat)
                    {
                        this.selected_candidat = selectedCandidat
                        this.editCandidat(selectedCandidat)
                    }
                    break
                case 'openComment':
                    // Récupération de l'étab by ID
                    this.tabSelected = 'commentaires'
                    selectedCandidat = this.$store.state.repartitioncandidats.liste_placements.filter((candidat: any) => candidat.id === paParams[1])[0]
                    if (selectedCandidat)
                    {
                        this.editCandidat(selectedCandidat)
                    }
                    break
                default:
                    break
            }
        }
    }

    /** Chargement des données du tableau */
    filtreSortHandler (params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            // Des filtres sont appliqués on rappele le serveur
            if (!this.params.sort) {
                Vue.set(params, 'sort', 'placement_state')
                Vue.set(params, 'direction', 'desc')
            }

            Vue.set(params, 'filter-centre_id', this.$route.params.centre_id)
            this.$store.dispatch('repartitioncandidats/getListePlacements', params)
        }
    }

    /** Appelle une page lors du scroll */
    loadHandler (params: any) {
        if (params.page <= this.$store.state.repartitioncandidats.lastPage) {
            Vue.set(params, 'filter-centre_id', this.$route.params.centre_id)
            if (!this.params.sort) {
                Vue.set(params, 'sort', 'placement_state')
                Vue.set(params, 'direction', 'desc')
            }
            this.$store.dispatch('repartitioncandidats/getMoreListePlacements', params)
        }
    }

    /**
     * Affichage de la modale du dossier candidat
     */
    editCandidat (item: any)
    {
        if (item && item.id !== undefined)
        {
            // On charge à partir de la BDD l'ensemble des informations du candidat sélectionné
            this.$store.commit('reclamation/SET_LOADING', true) // on lance le loading
            this.$store.dispatch('candidat/getCandidat', item).then(() => {
                const params =
                {
                    candidatId: item.id,
                    nomFiliere: item.filiere
                }
                this.$store.commit('candidat/SET_CANDIDAT_FILIERE', params)
                this.$store.commit('candidat/SET_SELECTED_CANDIDAT', item.id)
                this.showModalEditCandidat = true
                this.$store.commit('reclamation/SET_LOADING', false) // on lance le loading
            })
        }
    }

    /**
     * Fermeture de la modale du dossier candidat
     */
    reinitShowModalEditCandidat ()
    {
        const params_cand = {}
        Vue.set(params_cand, 'sort', 'placement_state')
        Vue.set(params_cand, 'direction', 'desc')
        Vue.set(params_cand, 'filter-centre_id', this.$route.params.centre_id)
        Vue.set(params_cand, 'filter-id', this.selected_candidat.id)

        this.$store.dispatch('repartitioncandidats/getListePlacements_candidat_only', params_cand).then((response) => {
            // MAJ du candidat au cas où on est fait des modifications sur son dossier dans le popup
            const result_candidat = response.data.data[0]
            const index = this.$store.state.repartitioncandidats.liste_placements.map((c: any) => c.id).indexOf(this.selected_candidat.id)
            Vue.set(this.$store.state.repartitioncandidats.liste_placements, index, result_candidat)
            this.showModalEditCandidat = false
        })
    }

    // Annule le placement d'un candidat
    cancel_placement () {
        this.selected_candidat = null
        this.salle_select = null
        this.showPopupPlacement = false
    }

    // Enregistre le placement du candidat
    save_placement () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Déplacement en cours...', infosToaster)

        this.save_en_cours = true
        this.$store.dispatch('repartitioncandidats/deplacerCandidatSalle', {
            candidat_id: this.selected_candidat.id,
            centre_id: this.$route.params.centre_id,
            salle_id: this.salle_select.id
        })
            .then(() => {
                this.cancel_placement()

                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Déplacement terminé.', succesToaster)
            })
            .catch((error: any) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.save_en_cours = false
            })
    }

    // Update la salle select
    updateSalle (e: any) {
        const salle_id = e.target.value
        this.salle_select = this.centre_select.salles.find((s: any) => s.id.toString() === salle_id)
    }

    // Ouvre le popup de confirmation de vérouillage
    confirm_verrou_placement (lock: number) {
        this.$store.state.repartitioncandidats.error_repartition = null
        this.lock_state = lock
        this.showPopupConfirmVerrou = true
    }

    // Annuler le verrouillage
    cancel_verrou () {
        this.$store.state.repartitioncandidats.error_repartition = null
        this.showPopupConfirmVerrou = false
    }

    // Confirmation du verrouillage
    save_verrou () {
        this.$store.state.repartitioncandidats.error_repartition = null
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours...', infosToaster)

        this.$store.dispatch('repartitioncandidats/verouillerPlacement', { centre_id: this.centre_select.id, placement_lock: this.lock_state })
            .then((response) => {
                this.centre_select = response.data.data
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                this.cancel_verrou()
            }).catch((error: any) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    load () {
        const params = {}
        Vue.set(params, 'sort', 'name')
        Vue.set(params, 'direction', 'asc')
        Vue.set(params, 'filter-id', this.$route.params.centre_id)

        if (this.$store.state.repartitioncandidats.liste_centres.length === 0) {
            // Chargement du centre
            this.$store.dispatch('repartitioncandidats/getListeCentres', params).then((response) => {
                this.centre_select = response.data.data[0]
            })
        } else {
            this.centre_select = this.$store.state.repartitioncandidats.liste_centres.find((c: any) => c.id.toString() === this.$route.params.centre_id)
        }

        this.$store.dispatch('concour/getConcours', { isPrecedente: false })

        const params_cand = {}
        Vue.set(params_cand, 'sort', 'placement_state')
        Vue.set(params_cand, 'direction', 'desc')
        Vue.set(params_cand, 'filter-centre_id', this.$route.params.centre_id)

        this.$store.dispatch('repartitioncandidats/getListePlacements', params_cand).then(() => {
            this.setFiltersForGenericTab()
        })
    }

    mounted () {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
