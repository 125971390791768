















































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import  ErrorDisplay from '@/components/ErrorDisplay.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import PopupCreerDecisionJury from '@/views/DecisionJury/PopupCreerDecisionJury.vue'
import PopupConsulterDecisionJury from '@/views/DecisionJury/PopupConsulterDecisionJury.vue'
import PopupEditCandidat from '@/components/Candidat/PopupEditCandidat.vue'
import { checkIcone, formatDate, getFileNameFromHeader, truncateString } from '@/utils/helpers'
import { getTypeStatutSpec } from '@/types/CandidatEpreuve'
import { CandidatStatut } from '@/types/Candidat'
import { EtatDecisionJury } from '@/types/DecisionJury'

@Component({
    computed: {
        ...mapState('decisionjury', ['decisions', 'loading_decisions', 'error_decisions', 'totalRows', 'currentPage', 'lastPage', 'totalPage']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id'])
    },
    components: {
        ExaGenericTable,
        PopupCreerDecisionJury,
        PopupConsulterDecisionJury,
        PopupEditCandidat,
        ErrorDisplay,
        'font-awesome-icon': FontAwesomeIcon
    }
})

export default class DecisionsJury extends Vue {
    showModalCreationDecision?: boolean     = false
    showModalEditCandidat?:     boolean     = false
    showModalConsultationDecision?: boolean = false
    tabSelected_candidat                    = 'resultats'
    formatDate                              = formatDate
    decision_select                         = null
    Ability                                 = Ability
    sortBy                                  = '';
    sortDesc                                = false;
    sortDirection                           = 'asc';
    filter                                  = '';
    filterOn                                = [];
    stickyHeader                            = true;
    filtres: any                            = []
    dataForTab: Array<any>                  = []
    exportWorking                           = false

    // Ensemble des colonnes du tableau
    genericfields = [
        { key: 'etatEdit',      label: '',                      sortable: false, class: '',            type: 'action' },
        { key: 'etat',          label: '',                      sortable: false, class: '',            type: 'text' },
        { key: 'candidat.code', label: 'Code',                  sortable: true,  class: '',            type: 'actionText' },
        { key: 'candidat.name', label: 'Identité',              sortable: true,  class: '',            type: 'actionText' },
        { key: 'phase.name',    label: 'Phase',                 sortable: true,  class: '',            type: 'text' },
        { key: 'filiere',       label: 'Filière',               sortable: false, class: '',            type: 'text' },
        { key: 'epreuve',       label: 'Épreuve',               sortable: false, class: '',            type: 'text' },
        { key: 'statut_avant',  label: 'Statut avant décision', sortable: true,  class: 'text-center', type: 'text' },
        { key: 'note_avant',    label: 'Note avant décision',   sortable: true,  class: 'text-center', type: 'text' },
        { key: 'statut_apres',  label: 'Statut aprés décision', sortable: true,  class: 'text-center', type: 'text' },
        { key: 'note_apres',    label: 'Note aprés décision',   sortable: true,  class: 'text-center', type: 'text' },
        { key: 'commentaire',   label: 'Commentaire',           sortable: false, class: '',            type: 'text' },
        { key: 'updated_at',    label: 'Date',                  sortable: true,  class: '',            type: 'text' },
        { key: 'user.name',     label: 'Responsable',           sortable: true,  class: '',            type: 'text' }
    ]

    /**
     * @description Met à jour les données du tableau
     * @returns {void}
     */
    @Watch('decisions')
    updateTable(): void {
        this.setDataForGenericTab(this.$store.getters['decisionjury/get_decisions'])
    }

    /**
     * @description Rafraichit l'interface
     * @returns {Promise<void>}
     */
    @Watch('user_session_id')
    async refreshInterface(): Promise<void> {
        await this.load()
    }

    /**
     * @description Contruit le tableau de données
     * @param {any} poData - Données à afficher
     * @param {boolean} isLoadMore - Ajoute les données à la suite
     * @returns {void}
     */
    setDataForGenericTab(poData: any, isLoadMore = false): void {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        const can = this.$store.getters['auth/can'](Ability.DLB_PREPJURY_MANAGE)
        const droit = checkIcone(Ability.DLB_PREPJURY_MANAGE, can)

        if (poData) {
            for (const result of poData) {
                const puce_etat = []
                if (result.etat === EtatDecisionJury.ETAT_A_REVALIDER) {
                    puce_etat.push({ name: 'exclamation', class: 'text-danger', title: 'Décision à revalider' })
                }

                if (result.candidat) {
                    const line = [
                        { label: droit.label,      item: result.id,          type: 'action',     typeAction: 'edit',         class: 'commons_first_action_button btn_action_ligne', icon: droit.icon, disabled: false },
                        { label: '',               item: puce_etat,          type: 'icons',      typeAction: null,     class: 'text-center' },
                        { label: 'Fiche candidat', item: result.candidat_id, type: 'actionText', typeAction: 'editCandidat', class: 'text-info item_action', text: result.candidat.code },
                        { label: 'Fiche candidat', item: result.candidat_id, type: 'actionText', typeAction: 'editCandidat', class: 'text-info item_action', text: result.candidat.name + ' ' + result.candidat.first_name },
                        { label: '',               item: result.phase.name,  type: 'text',       typeAction: null,           class: 'text-start' },
                        { label: '',               item: result.candidat.concour.name,  type: 'text',       typeAction: null,           class: 'text-start' },
                        { label: '',               item: result.epreuve.name,  type: 'text',  typeAction: null,  class: 'text-start' },
                        { label: '',               item: getTypeStatutSpec(result.statut_avant).libelle,  type: 'text',  typeAction: null,  class: 'text-center' },
                        { label: '',               item: result.statut_avant === CandidatStatut.PRESENT ? result.note_avant : result.statut_avant === CandidatStatut.ABSENT ? 'Absent' : '-',    type: 'text',  typeAction: null,  class: 'text-center' },
                        { label: '',               item: getTypeStatutSpec(result.statut_apres).libelle,  type: 'text',  typeAction: null,  class: 'text-center' },
                        { label: '',               item: result.statut_apres === CandidatStatut.PRESENT ? result.note_apres : result.statut_apres === CandidatStatut.ABSENT ? 'Absent' : '-',    type: 'text',  typeAction: null,  class: 'text-center' },
                        { label: '',               item: truncateString(result.commentaire, 25),   type: 'text',  typeAction: null,  class: 'text-start reclamation_graylight_col', data_title: result.commentaire, data_title_location: 'left' },
                        { label: '',               item: formatDate(result.updated_at),    type: 'text',  typeAction: null,  class: 'text-start' },
                        { label: '',               item: result.user.first_name + ' ' + result.user.name,     type: 'text',  typeAction: null,  class: 'text-start' }
                    ]

                    this.dataForTab.push(line)
                }
            }
        }
    }

    /**
     * @description Événement du tableau (params[0] => l'action, params[1] => l'id de l'item)
     * @param {any} paParams - Événement
     * @returns {Promise<void>}
     */
    async handleTableEvent(paParams: any): Promise<void> {
        if (paParams && paParams[0] && paParams[1]) {
            const decisionsJury  = this.$store.getters['decisionjury/get_decisions']
            let selectDecision   = null
            let selectedCandidat = null

            switch (paParams[0]) {
                case 'edit':
                    // Récupération du decision_id
                    selectDecision = decisionsJury.filter((decision: any) => decision.id === paParams[1])[0]
                    if (selectDecision) {
                        this.editDecision(selectDecision)
                    }
                    break

                case 'editCandidat':
                    // Récupération de l'étab by ID
                    selectedCandidat = decisionsJury.filter((r: any) => r.candidat_id === paParams[1])[0]
                    if (selectedCandidat) {
                        await this.editCandidat(selectedCandidat.candidat)
                    }
                    break

                case 'sortHandler':
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break

                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
            }
        }
    }

    /**
     * @description Charge les données relative à l'édition d'un candidat
     * @param {any} item - Candidat à éditer
     * @returns {Promise<void>}
     */
    async editCandidat(item: any): Promise<void> {
        if (item && item.id !== undefined) {
            // On charge à partir de la BDD l'ensemble des informations du candidat sélectionné
            this.$store.commit('reclamationOral/SET_LOADING', true) // on lance le loading

            await this.$store.dispatch('candidat/getCandidat', item)
            this.$store.commit('candidat/SET_CANDIDAT_FILIERE', {
                candidatId: item.id,
                nomFiliere: item.filiere
            })
            this.$store.commit('candidat/SET_SELECTED_CANDIDAT', item.id)

            this.showModalEditCandidat = true
            this.$store.commit('reclamationOral/SET_LOADING', false) // on arrête le loading
        }
    }


    /**
     * @description Ferme la modale d'édition d'un candidat
     * @returns {void}
     */
    reinitShowModalEditCandidat(): void {
        this.showModalEditCandidat = false
    }


    /**
     * @description Charge les données supplémentaires lors du scroll
     * @param {any} params - Paramètres
     * @returns {void}
     */
    loadHandler(params: any): void {
        this.$store.dispatch('decisionjury/getMoreDecisions', params)
    }

    /**
     * @description Charge les données avec paramètres personnalisés
     * @param {any} params - Paramètres
     * @returns {void}
     */
    filtreSortHandler(params: any): void {
        this.$store.dispatch('decisionjury/getDecisions', { filters : params })
    }


    /**
     * @description Chargement des données relatives à l'édition d'une décision
     * @param {any} item - Décision à éditer
     * @returns {void}
     */
    editDecision(item: any): void {
        if (item && item.id !== undefined) {
            this.decision_select = item
            this.showModalConsultationDecision = true
        }
    }

    /**
     * @description Ferme la modale d'édition d'une décision
     * @param {boolean} withSave - Sauvegarde des données (= rafrachissement de la liste)
     * @returns {Promise<void>}
     */
    async reinitShowModalDecision(withSave: boolean): Promise<void> {
        if (withSave) {
            await this.$store.dispatch('decisionjury/getDecisions')
            this.updateTable()
        }
        this.$store.commit('decisionjury/SET_ERROR', null)
        this.showModalCreationDecision = false
        this.showModalConsultationDecision = false
    }

    /**
     * @description Ouverture de la modale de création d'une décision
     * @returns {void}
     */
    open_modale_creation_decision(): void {
        this.showModalCreationDecision = true
    }

    /**
     * @description Export le planning au format excel
     * @returns {void}
     */
    export_excel(): void {
        this.$store.commit('decisionjury/SET_ERROR', null)
        let fileName = ''
        this.exportWorking = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Export en cours...', infosToaster)

        this.$store.dispatch('decisionjury/exportDecisions')
            .then(response => {
                const fileNameTemp = getFileNameFromHeader(response.headers) || 'Decisions_jury.xlsx'
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.exportWorking = false
            })
    }

    /**
     * @description Chargement des données du composant
     * @returns {Promise<void>}
     */
    async load(): Promise<void> {
        await this.$store.dispatch('concour/getConcours')
        await this.$store.dispatch('epreuve/getEpreuves', {
            isPrecedente: false,
            filters: {},
            session_id: this.$store.state.session.sessionSelect.id })
        // Chargement des ordres de missions
        await this.$store.dispatch('decisionjury/getDecisions')

        this.filtres = []
    }

    /**
     * @description Montage du composant
     * @returns {Promise<void>}
     */
    async mounted(): Promise<void> {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            await this.load()
        }
    }
}
