

























































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { Ability } from '@/types/Ability'
import { TypePassation } from '@/types/Epreuve'
import { TypeSalle, getTypeSalle, SalleInterface, TypeAmenagement, getTypeAmenagement, getEnumTypeAmenagement } from '@/types/Salle'
import { codeRestrict, slugifyToCode, formatDate, isObject } from '@/utils/helpers'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { MessageIndicationType } from '@/types/MessageIndicationType'
import PopupRejetSalles from '@/components/Centre/Validations/PopupRejetSalles.vue'
import PopupValideSalles from '@/components/Centre/Validations/PopupValideSalles.vue'
import PopupSubmitSalles from '@/components/Centre/Validations/PopupSubmitSalles.vue'
import MessageIndication from '@/components/MessageIndication.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapState('centre', ['centreSelect', 'loading', 'save_in_progress', 'order_save']),
        ...mapGetters('centre', ['salles_is_submit', 'salles_is_validate', 'nb_intervenants_is_submit', 'nb_intervenants_is_validate']),
        ...mapState('salle', ['error']),
        ...mapGetters('auth', ['is_intervenant_centre']),
        ...mapState('organisationecrit', ['dico_salles', 'dico_salles_concours', 'errors_code', 'has_error_code', 'new_id', 'error', 'besoins_centre', 'selectComposant']),
        ...mapGetters('organisationecrit', ['get_total_capacite', 'get_total_repartition']),
        ...mapState('banque', ['banques'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        MessageIndication,
        PopupSubmitSalles,
        PopupRejetSalles,
        PopupValideSalles,
        ErrorDisplay
    }
})
export default class SaisieSalles extends Vue {
    codeRestrict = codeRestrict
    slugifyToCode = slugifyToCode
    Ability = Ability
    TypePassation = TypePassation
    TypeSalle = TypeSalle
    TypeAmenagement = TypeAmenagement
    MessageIndicationType = MessageIndicationType
    modeEdition = false
    gestionnaire = null
    getTypeSalle = getTypeSalle
    getTypeAmenagement = getTypeAmenagement
    getEnumTypeAmenagement = getEnumTypeAmenagement
    salleSelect: SalleInterface = {
        id: 0,
        code: '',
        name: '',
        capacite_std: 0,
        capacite_max: 1,
        total_capacite: 0,
        type: 0,
        amenagement: 0,
        concours: [],
        centre_id: 0
    }

    salleTemp: SalleInterface = {
        id: 0,
        code: '',
        name: '',
        capacite_std: 0,
        capacite_max: 1,
        total_capacite: 0,
        type: 0,
        amenagement: 0,
        concours: [],
        centre_id: 0
    }

    timeoutid = 0
    filieres_centre: Array<any> = []
    creation_in_progress = false
    lock = false
    fromAddNewSalle = false
    showPopupSubmitEcrit = false
    showPopupRejetEcrit = false
    showPopupValidationEcrit = false
    invalideEcrit = false

    currentNew_id = -1

    checkInputCapaciteSalleConcoursIndex (banque: any, salle: any) {
        if(this.$store.state.centre.centreSelect) {

            this.filieres_centre = this.$store.state.centre.centreSelect.concours
            const salle_concours = this.$store.state.organisationecrit.dico_salles_concours.find((sc: any) => sc.concour_id === banque.id && sc.salle_id === salle.id) && salle.amenagement === TypeAmenagement.AMENAGEMENT_AUCUN
            return salle_concours && salle.amenagement === TypeAmenagement.AMENAGEMENT_AUCUN
        }
    }

    @Watch('centreSelect', { deep: true })
    initDicoSalle () {
        if(this.$store.state.centre.centreSelect) {
            this.filieres_centre = this.$store.state.centre.centreSelect.concours
            if (this.$store.state.organisationecrit.dico_salles_concours.length === 0) {
                this.initDicoSalles()
            }
        }
    }

    @Watch('dico_salles', { deep: true })
    reinitDicoSalleConcours () {
        // console.log('Dans Watch dico_salles - reinitDicoSalleConcours')
        this.initDicoSalles()
    }

    @Watch('concours', { deep: true })
    initDicoSallesByConcours () {
        // console.log('Dans Watch centreSelect - initDicoSallesByConcours')
        this.initDicoSalles()
    }

    @Watch('error')
    updateError () {
        this.isObject()
    }

    @Watch('save_in_progress')
    lockFormulaire () {
        this.lock = this.$store.state.centre.save_in_progress;
    }

    /** Init les données */
    initDicoSalles () {
        this.$store.commit('organisationecrit/RESET_DICO_SALLES_CONCOURS')
        if (this.$store.state.banque.banques.length === 0) {
            this.getBanques()
        }
        const banques: Array<any> = this.$store.state.banque.banques
        const dicoSalles = this.$store.state.organisationecrit.dico_salles
        for (const d in dicoSalles) {
            for (const c in banques) {
                const sc_index = dicoSalles[d].concours.findIndex((co: any) => co.concour_id === banques[c].id)
                if (sc_index === -1 && dicoSalles[d].amenagement === TypeAmenagement.AMENAGEMENT_AUCUN) {
                    this.$store.commit('organisationecrit/SET_SALLES_CONCOURS', { concour_id: banques[c].id, salle_id: dicoSalles[d].id, nb_candidats_prevision: null, nb_candidats: null })
                } else if (dicoSalles[d].amenagement === TypeAmenagement.AMENAGEMENT_AUCUN) {
                    this.$store.commit('organisationecrit/SET_SALLES_CONCOURS', { concour_id: banques[c].id, salle_id: dicoSalles[d].id, nb_candidats_prevision: dicoSalles[d].concours[sc_index].nb_candidats_prevision, nb_candidats: dicoSalles[d].concours[sc_index].nb_candidats })
                }
            }
        }

        this.checkCompteurs()

        // Si on vient de créer une nouvelle salle, on tente de forcer le focus sur l'input de la salle.
        if (this.fromAddNewSalle) {
            this.fromAddNewSalle = false
            this.setFocusToNewSalleInputElement()
        }
    }

    setFocusToNewSalleInputElement () {
        this.$nextTick(() => {
            const refNewSalle = this.$refs.newSalleRef as Array<Element>
            if (refNewSalle !== undefined) {
                (refNewSalle[0] as HTMLElement).focus()
            }
        })
    }

    /** Check le type du retour d'erreur */
    isObject () {
        return isObject(this.$store.state.salle.error)
    }

    /** Recuperation du champ */
    updateChamps (e: any) {
        // console.log('Dans updateChamps')
        // console.log(e.target.name)
        if (e.target.name.includes('salle_')) {
            const datas = e.target.name.split('_')
            const salle_concour = { concour_id: Number(datas[3]), salle_id: Number(datas[1]), nb_candidats_prevision: Number(e.target.value), nb_candidats: null }
            this.$store.commit('organisationecrit/UPDATE_REPARTITION', salle_concour)
            this.fixVolumesSalles()
        }

        if (e.target.name.includes('capacite_max_')) {
            // const datas = e.target.name.split('_')
            // const salle = this.$store.state.organisationecrit.dico_salles.find((s: any) => s.id === Number(datas[2]))
            /*
            const e_target = {
                target : {
                    name: 'code_' + salle.id,
                    value : salle.code
                }
            }
            this.checkUniqueCode(e_target)
            */
            this.checkCompteurs()
        }

        // Modifie la capacité de la salle si on passe en isolée
        if (e.target.name.includes('amenagement_')) {
            const datas = e.target.name.split('_')
            const salle = this.$store.state.organisationecrit.dico_salles.find((s: any) => s.id === Number(datas[1]))
            salle.amenagement = Number(e.target.value)

            if (salle.amenagement === TypeAmenagement.AMENAGEMENT_ISOLE) {
                salle.capacite_max = 1
            }
            this.initDicoSalles()
        }
    }

    /** Check l'unicité d'un code */
    checkUniqueCode (e: any) {
        if (e.target.name.includes('nomSalle_')) {
            const datas = e.target.name.split('_')
            this.$store.state.organisationecrit.errors_code = []
            this.$store.state.organisationecrit.has_error_code = false

            const slugified_code = this.slugifyToCode(e.target.value) // création du code à partir du nom de la salle.
            this.$store.state.organisationecrit.dico_salles[Number(datas[2])].code = slugified_code

            const search_doublons = this.$store.state.organisationecrit.dico_salles.filter((ds: any) => ds.code === slugified_code)
            if (search_doublons && search_doublons.length >= 2) {
                for (const s in search_doublons) {
                    this.$store.commit('organisationecrit/SET_ERRORS_CODE', { salle_id: search_doublons[s].id, type_erreur: 'unicite' })
                }
            }

            if (e.target.value.length < 2) {
                this.$store.commit('organisationecrit/SET_ERRORS_CODE', { salle_id: Number(datas[1]), type_erreur: 'taille' })
            }
        }
    }

    /** Ajoute une ligne de salle vide dans la collection */
    addSalle () {
        const new_salle: SalleInterface = {
            id: this.$store.state.organisationecrit.new_id,
            code: '',
            name: '',
            capacite_std: 0,
            capacite_max: 1,
            total_capacite: 0,
            type: 0,
            amenagement: 0,
            concours: [],
            centre_id: this.$store.state.centre.centreSelect.id
        }

        this.currentNew_id = new_salle.id

        this.$store.commit('organisationecrit/ADD_LIGNE_DICO_SALLES', new_salle)

        for (const c in this.$store.state.banque.banques) {
            this.$store.commit('organisationecrit/SET_SALLES_CONCOURS', { concour_id: this.$store.state.banque.banques[c].id, salle_id: this.$store.state.organisationecrit.new_id, nb_candidats_prevision: null, nb_candidats: null })
        }

        this.$store.state.organisationecrit.new_id = this.$store.state.organisationecrit.new_id - 1
        this.$store.commit('centre/SET_SAVE_IN_PROGRESS', false)
        this.fromAddNewSalle = true
    }

    /** Reparse les volumes dans la collection des salles */
    fixVolumesSalles () {
        this.$store.state.salle.error = null
        this.$store.commit('organisationecrit/PARSE_COLLECTION_SALLES')
        this.$store.commit('organisationecrit/SET_HAS_COMPTEURS_INCORRECTS', false)
        this.checkCompteurs()
    }

    /** Calcule le total de repartition sur la salle */
    getTotalConcours (salle: SalleInterface) {
        let total = 0
        const concours = this.$store.state.organisationecrit.dico_salles_concours.filter((sc: any) => sc.salle_id === salle.id)
        for (const s in concours) {
            if (concours[s] && concours[s].nb_candidats_prevision) {
                total += Number(concours[s].nb_candidats_prevision)
            }
        }
        return total
    }

    /** Retourne la capacité totale des salles */
    getTotalCapacite () {
        return this.$store.getters['organisationecrit/get_total_capacite']
    }

    /** Retourne le total de repartition */
    getTotalRepartition () {
        return this.$store.getters['organisationecrit/get_total_repartition']
    }

    /** Retourne le total de repartition par concours */
    getTotalRepartitionByConcours (concour_id: number) {
        let total = 0
        const concours = this.$store.state.organisationecrit.dico_salles_concours.filter((sc: any) => sc.concour_id === concour_id)
        for (const s in concours) {
            if (concours[s] && concours[s].nb_candidats_prevision) {
                total += Number(concours[s].nb_candidats_prevision)
            }
        }
        return total
    }

    /** Retourne le total de l'estimation des filières sur le centre */
    getTotalEstimationFilieres () {
        let total = 0
        const concours = this.filieres_centre
        for (const s in concours) {
            if (this.$store.state.centre.centreSelect && this.$store.state.centre.centreSelect.use_real_data_at) {
                if (concours[s] && concours[s].nb_candidats) {
                    total += Number(concours[s].nb_candidats)
                }
            } else {
                if (concours[s] && concours[s].nb_candidats_prevision) {
                    total += Number(concours[s].nb_candidats_prevision)
                }
            }
        }

        return total
    }

    /** Vérifie si la capacité peut contenir l'estimation des filières */
    checkTotalRepartitionCapacite () {
        let classResult: string

        if (this.getTotalEstimationFilieres() <= this.getTotalRepartition()) {
            classResult = 'text-success'
        } else {
            classResult = 'text-danger'
        }

        return classResult
    }

    /** Vérifie si la capacité peut contenir l'estimation d'une filiere */
    checkTotalRepartitionCapaciteByConcour (concour_id: number) {
        let classResult = 'text-danger'

        if (this.filieres_centre) {
            const compteur_filiere = this.filieres_centre.find((fc: any) => fc.id === concour_id)

            if (compteur_filiere) {
                if (this.$store.state.centre.centreSelect && this.$store.state.centre.centreSelect.use_real_data_at) {
                    if (compteur_filiere.nb_candidats <= this.getTotalRepartitionByConcours(concour_id)) {
                        classResult = 'text-success'
                    } else {
                        classResult = 'text-danger'
                    }
                } else {
                    if (compteur_filiere.nb_candidats_prevision <= this.getTotalRepartitionByConcours(concour_id)) {
                        classResult = 'text-success'
                    } else {
                        classResult = 'text-danger'
                    }
                }

            }
        }

        return classResult
    }

    /** Vérifie si le total de répartition pour la salle est conforme à la capacité max */
    checkTotalLigneCapaciteMax (salle: any) {
        let classResult: string

        const totalRepart = this.getTotalConcours(salle)
        if (totalRepart <= salle.capacite_max) {
            classResult = 'text-success'
        } else {
            classResult = 'text-danger'
        }

        return classResult
    }

    /** Supprime une ligne de la collection */
    deleteLigne (salle_id: number) {
        this.$store.commit('organisationecrit/DELETE_LIGNE_DICO_SALLES', salle_id)
        this.$store.state.salle.error = null
        this.fixVolumesSalles()
    }

    /** Formattage les dates */
    formatteDate (date: Date) {
        return formatDate(date)
    }

    /** Check les compteurs */
    checkCompteurs () {
        this.$store.commit('organisationecrit/SET_HAS_COMPTEURS_INCORRECTS', false)

        if (this.checkTotalRepartitionCapacite() === 'text-danger') {
            this.$store.commit('organisationecrit/SET_HAS_COMPTEURS_INCORRECTS', true)
        }

        for (const s in this.$store.state.organisationecrit.dico_salles) {
            if (this.checkTotalLigneCapaciteMax(this.$store.state.organisationecrit.dico_salles[s]) === 'text-danger') {
                this.$store.commit('organisationecrit/SET_HAS_COMPTEURS_INCORRECTS', true)
            }
        }

        for (const s in this.$store.state.organisationecrit.dico_salles_concours) {
            if (this.checkTotalRepartitionCapaciteByConcour(this.$store.state.organisationecrit.dico_salles_concours[s].concour_id) === 'text-danger'  && this.filieres_centre && this.filieres_centre.find(fc => fc.id === this.$store.state.organisationecrit.dico_salles_concours[s].concour_id)) {
                this.$store.commit('organisationecrit/SET_HAS_COMPTEURS_INCORRECTS', true)
            }
        }
    }

    mountedSuite () {
        this.$store.state.salle.error = null
        this.filieres_centre = this.$store.state.centre.centreSelect.concours
        if (this.$store.state.organisationecrit.dico_salles_concours.length === 0) {
            this.initDicoSalles()
        }

        // Check si on est en readonly
        this.lock = !(this.$store.getters['auth/can'](Ability.GC_CENTER_MANAGE) || this.$store.getters['auth/is_intervenant_centre']);
        this.filieres_centre = this.$store.state.centre.centreSelect.concours
    }

    getBanques () {
        if (this.$store.state.banque.banques.length === 0) {
            const params: any = {}
            this.$store.dispatch('banque/getBanques', { isPrecedente: false, filters: params }).then(() => {
                this.mountedSuite()
            })
        } else {
            this.mountedSuite()
        }
    }

    loadData () {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            if (this.$store.state.banque.banques.length === 0) {
                this.getBanques()
            }
        }
    }

    /** Retourne une classe css selon l'état de validation des infos */
    getClassBarreBoutons () {
        let class_css = 'barre_default'

        if (this.$store.getters['centre/salles_is_submit'] && !this.$store.getters['centre/salles_is_validate']) {
            class_css = 'barre_soumis'
        } else if (this.$store.getters['centre/salles_is_submit'] && this.$store.getters['centre/salles_is_validate']) {
            class_css = 'barre_valide'
        } else if (this.$store.getters['centre/has_commentaire_rejet_ecrit']('salles')) {
            class_css = 'barre_rejet'
        }

        return class_css
    }

    /** Sauvegarde des deux collections : salles et intervenants */
    save () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('organisationecrit/manageOrganisationEcrit', { params: { mode: 'salles', centre_id: this.$store.getters['centre/centreSelect'].id, salles_submit: null, salles_validate: null } })
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /** Ouvre le popup de confirmation de soumission de l'organisation écrite */
    submitEcrit () {
        // this.$store.state.organisationecrit.error = null // reset de l'erreur potentielle déjà affichée
        this.$store.commit('organisationecrit/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
        this.showPopupSubmitEcrit = true
    }

    /** Message de confirmation de validation de l'organisation des écrits (Salles et intervenants) */
    validerEcrit () {
        // this.$store.state.organisationecrit.error = null // reset de l'erreur potentielle déjà affichée
        this.$store.commit('organisationecrit/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
        this.showPopupValidationEcrit = true
    }

    /** Message de confirmation de rejet de la saisie du gestionnaire pour l'organisation des écrits */
    rejeterEcrit () {
        // this.$store.state.organisationecrit.error = null // reset de l'erreur potentielle déjà affichée
        this.$store.commit('organisationecrit/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
        this.showPopupRejetEcrit = true
    }

    /** Message de confirmation de validation de l'organisation des écrits (Salles et intervenants) */
    invaliderEcrit () {
        // this.$store.state.organisationecrit.error = null // reset de l'erreur potentielle déjà affichée
        this.$store.commit('organisationecrit/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
        this.invalideEcrit = true
        this.showPopupValidationEcrit = true
    }

    /** Reset showPopups */
    resetPopup () {
        // this.$store.state.organisationecrit.error = null // reset de l'erreur potentielle de retour de popup
        this.$store.commit('organisationecrit/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
        this.showPopupSubmitEcrit = false
        this.showPopupValidationEcrit = false
        this.showPopupRejetEcrit = false
        this.invalideEcrit = false
    }

    mounted () {
        this.$store.state.salle.error = null
        this.loadData()
    }
}

