













































import { Vue, Component, Prop } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { mapState } from 'vuex'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { TypePassation } from '@/types/Epreuve'
import _ from 'lodash'


@Component({
    computed: {
        // ['loading', 'error', 'epreuves', 'getEpreuveById', 'meta', 'links', 'totalRows', 'getEpreuveByTypePassation', 'typeEpreuves', 'typeEpreuveById']
        ...mapState('epreuve', ['epreuvesCorrections', 'error', 'meta', 'totalRows', 'loading'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay,
        ExaGenericTable
    }
})

export default class CapacitesPhaseSuivante extends Vue {
    @Prop() showPopup?: boolean
    @Prop() phase_id?: number

    genericfields = [
        { key: 'organisateur', label: 'Organisme', sortable: true, class: '', type: 'action' },
        { key: 'epreuve_correction.concours.name', label: 'Filière', sortable: false, class: 'text-left col-min-width', type: 'text' },
        { key: 'name', label: 'Epreuve', sortable: true, sortDirection: 'asc', class: 'text-left', type: 'text' },
        { key: 'admis_count', label: 'Nombre d\'admissible', sortable: true, sortDirection: 'asc', class: 'text-left', type: 'text' },
        { key: 'places_prevues', label: 'Nombre de places prévues', sortable: true, class: '', type: 'action' }
    ]

    params = 'sort=name&direction=asc'
    dataForTab: Array<any> = []
    filtres: any = []

    /**
    * setDataForGenericTab
    * Cette fonction prépare les données pour le ExaGenericTable en formatant les informations reçues.
    * @param poData: données à traiter
    * @param isLoadMore: indique s'il s'agit d'un chargement supplémentaire de données (par défaut false)
    */
    setDataForGenericTab(poData: any, isLoadMore = false) {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const result of poData) {
                const filiere = result?.concours
                    ?.map((concour: any) => concour.name)
                    ?.join(', ') || ''

                const line = [
                    { label: '', item: result.organisateur ? result.organisateur : '-' , type: 'text', typeAction: null, class: '' },
                    { label: '', item: filiere, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.name, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.admis_count, type: 'text', typeAction: null, class: '' },
                    {
                        label: '',
                        item: result.places_prevues,
                        data: result,
                        type: 'inputNumber',
                        typeAction: 'input_nb_place',
                        minInput: 0,
                        class: 'item_action fixed_size_input_table_gen text-center col-min-width',
                        disabled: false,
                        nullEnabled: true
                    }
                ]


                this.dataForTab.push(line)
            }
        }
        return this.dataForTab
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setFiltersForGenericTab() {
        const options_filieres = []

        const filieres: any = _.orderBy(this.$store.getters['concour/banques'], 'ordre', 'asc')
        for (const f in filieres) {
            options_filieres.push({ index: filieres[f].id, name: filieres[f].name })
        }

        this.filtres = [
            { libelle: 'Organisme', defautOptionlibelle: 'Rechercher un', model: 'organisateur', value: '', index: 'organisateur', datas: null, loading: false, options: { type: 'form', fieldsKey: 'organisateur' } },
            { libelle: 'Epreuve', defautOptionlibelle: 'Rechercher un', model: 'name', value: '', index: 'name', datas: null, loading: false, options: { type: 'form', fieldsKey: 'name' } },
            { libelle: 'Filière', defautOptionlibelle: 'Rechercher une', model: 'concour_id', value: '-', index: 'name', datas: options_filieres, loading: false, options: { type: 'deroulant', fieldsKey: 'epreuve_correction.concours.name' } }
        ]
    }

    /**
     * Récupération des events de la table
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'edit':

                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'input_nb_place':
                    this.inputNbPlaceHandler(paParams)
                    break
                default:
                    break
            }
        }
    }

    inputNbPlaceHandler(data: any) {
        const payload: any = {
            id: data[2].id,
            params:{places_prevues: data[1]}
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('epreuve/updateEpreuveCorrectionPlacesPrevues', payload).then(() => {
            this.$bvToast.hide(idInfo)
            const idSucces = 't_succes_' + Math.random()
            const succesToaster = {
                id: idSucces,
                toaster: 'b-toaster-top-right',
                variant: 'success',
                noCloseButton: true,
                fade: true,
                autoHideDelay: 5000
            }
            this.$bvToast.toast('Enregistrement terminée.', succesToaster)
            const params: any = this.params
            params['filter-phase_id'] = this.phase_id
            params['withAdmisCount'] = 1
            params['filter-type_passation'] = JSON.stringify([
                TypePassation.TYPE_PASSATION_ECRIT,
                TypePassation.TYPE_PASSATION_ORAL,
                TypePassation.TYPE_PASSATION_TP]) // [2,3]
            this.$store.dispatch('epreuve/getEpreuvesCorrections', { session_id: this.$store.state.session.sessionSelect.id, params: params }).then(() => {
                this.setDataForGenericTab(this.$store.state.epreuve.epreuvesCorrections)

            }).catch((error) => {
                console.log('ko:' + error)
            })
        }).catch((error) => {
            console.log('ko:' + error)
        })
    }

    /**
    * loadHandler
    * Cette fonction gère le chargement des données en fonction des paramètres fournis.
    * @param params: paramètres de chargement des données
    */
    loadHandler (params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            params['filter-phase_id'] = this.phase_id
            params['withAdmisCount'] = 1
            params['filter-type_passation'] = JSON.stringify([
                TypePassation.TYPE_PASSATION_ECRIT,
                TypePassation.TYPE_PASSATION_ORAL,
                TypePassation.TYPE_PASSATION_TP]) // [2,3]
            this.$store.dispatch('epreuve/getMoreEpreuvesCorrections', { session_id: this.$store.state.session.sessionSelect.id, params: params }).then(() => {
                this.setDataForGenericTab(this.$store.state.epreuve.epreuvesCorrections)
            }).catch((error) => {
                console.log('ko:' + error)
            })
        }
    }

    // Appelle une page lors du scroll
    filtreSortHandler (params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            params['filter-phase_id'] = this.phase_id
            params['withAdmisCount'] = 1
            params['filter-type_passation'] = JSON.stringify([
                TypePassation.TYPE_PASSATION_ECRIT,
                TypePassation.TYPE_PASSATION_ORAL,
                TypePassation.TYPE_PASSATION_TP]) // [2,3]
            this.$store.dispatch('epreuve/getEpreuvesCorrections', { session_id: this.$store.state.session.sessionSelect.id, params: params }).then(() => {
                this.setDataForGenericTab(this.$store.state.epreuve.epreuvesCorrections)
            }).catch((error) => {
                console.log('ko:' + error)
            })
        }
    }


    // Ferme la popup
    cancel () {
        this.$emit('cancel')
    }

    mounted () {
        const params: any = {}
        params['filter-phase_id'] = this.phase_id
        params['withAdmisCount'] = 1
        params['filter-type_passation'] = JSON.stringify([
            TypePassation.TYPE_PASSATION_ECRIT,
            TypePassation.TYPE_PASSATION_ORAL,
            TypePassation.TYPE_PASSATION_TP]) // [2,3]
        this.$store.dispatch('epreuve/getEpreuvesCorrections', { session_id: this.$store.state.session.sessionSelect.id, params: params }).then(() => {
            this.setDataForGenericTab(this.$store.state.epreuve.epreuvesCorrections)
            this.setFiltersForGenericTab()
        })
    }
}
