export enum EntityType {
    ENTITY_TYPE_CANDIDAT = 'candidat',
    ENTITY_TYPE_EPREUVE = 'epreuve',
    ENTITY_TYPE_CENTRE = 'centre',
}

export function getEnumEntityType() {
    const enumEntityType = []
    enumEntityType.push({ id: 0, name: EntityType.ENTITY_TYPE_CANDIDAT, libelle: 'Candidat' })
    enumEntityType.push({ id: 1, name: EntityType.ENTITY_TYPE_EPREUVE, libelle: 'Epreuve' })
    enumEntityType.push({ id: 2, name: EntityType.ENTITY_TYPE_CENTRE, libelle: 'Centre' })

    return enumEntityType
}

export function getLibelleAffichageEntityType(entity_type: EntityType) {
    switch (entity_type) {
        case EntityType.ENTITY_TYPE_CANDIDAT:
            return 'candidat'
            break
        case EntityType.ENTITY_TYPE_EPREUVE:
            return 'épreuve'
            break
        case EntityType.ENTITY_TYPE_CENTRE:
            return 'centre'
            break
        default:
            break
    }
}
