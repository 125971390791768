




































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import store from '@/store/index'
import { Ability } from '@/types/Ability'
import { codeRestrict, formatDate, isObject, checkIcone, base64ToArrayBuffer, getFileNameFromHeader } from '@/utils/helpers'
import { getTypeEnsembleSpec, TypeEnsemble } from '@/types/Ensemble'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import EditionEquipe from '@/views/AffectationExaminateurs/EditionEquipe.vue'
import  ErrorDisplay from '@/components/ErrorDisplay.vue'
import Back from '@/components/Tools/Back.vue'


@Component({
    computed: {
        ...mapGetters('ensemble', ['loading', 'error', 'ensembles', 'lastPage', 'meta', 'links', 'totalRows']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ExaGenericTable,
        EditionEquipe,
        ErrorDisplay,
        Back
    }
})

export default class GestionEquipe extends Vue
{
    // DATAS
    isObject = isObject
    session_id: any = null
    ensembles_validated_at = null
    ensembles_centres_validated_at = null
    ensembles_examinateurs_validated_at = null
    actionLimited = false
    codeRestrict = codeRestrict
    filtreJustInitiated = true
    TypeEnsemble = TypeEnsemble
    filtres: any = []
    genericfields: Array<any> = []
    dataForTab: Array<any> = []
    sortBy = ''
    sortDesc = false
    sortDirection = 'asc'
    filter = ''
    filterOn = []
    stickyHeader = true
    ensemble: any = null
    ensembleTemp: any = null
    Ability = Ability
    paramsLocal: any = null
    showModalEditionEnsemble = false
    showModalMessageDelete = false
    showModalMessageValidation = false
    formatDate = formatDate
    editDisabled = false
    sessionActive: any = []

    // METHODS


    /** check si le bouton de la popop edtion/creation est cliquable */
    @Watch('ensembleTemp', { deep: true, immediate: true })
    checkEditDisabled() {
        this.editDisabled = false
        if (
            !this.ensembleTemp ||
            (this.ensembleTemp.concour_id === 0 && this.ensembleTemp.type_ensemble !== TypeEnsemble.TYPE_ENSEMBLE_TP && this.ensembleTemp.id !== 0) ||
            this.ensembleTemp.type_ensemble === -1 ||
            this.ensembleTemp.nb_estime === 0 ||
            this.ensembleTemp.name.trim().length === 0 ||
            (this.ensembleTemp.id === 0 && this.ensembleTemp.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_PAR_EPREUVE && this.ensembleTemp.epreuvesTemp.length === 0) ||
            this.$store.getters['ensemble/ensembleNameIsNotUnique'](this.ensembleTemp.id, this.ensembleTemp.name)
        ) {
            this.editDisabled = true
        }
    }

    getAffectationRecapitulatif () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours...', infosToaster)
        this.$store.dispatch('affectationExaminateur/getAffectationRecapitulatif')
            .then((response) => {
                const fileNameTemp = getFileNameFromHeader(response.headers) || 'affectation_recapitulatif.pdf'
                const url = URL.createObjectURL(new Blob([base64ToArrayBuffer(response.data)]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileNameTemp)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /** indique si un chargement est en cours */
    getLoading () {
        return this.$store.state.matiere.loading || this.$store.state.concour.loading || this.$store.state.ensemble.loading || this.$store.state.matiere.loading
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        const validated = this.$store.state.session.sessionSelect.ensembles_validated_at

        const can = this.$store.getters['auth/can'](Ability.ORAL_AFEX_MANAGE)
        const droit = checkIcone(Ability.ORAL_AFEX_MANAGE, can, validated)
        if (!isLoadMore) {
            this.dataForTab = []
        }
        if (poData) {
            for (const result of poData) {
                const line = [
                    { label: droit.label, item: result, type: 'action', typeAction: 'edit', class: 'commons_first_action_button btn_action_ligne', icon: droit.icon, disabled: false },
                    { label: '', item: result.name, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: getTypeEnsembleSpec(result.type_ensemble).libelle, type: 'text', typeAction: null, class: '' },
                    { label: '', item: this.getFiliere(result), type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: result.nb_estime, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: this.getEpreuve(result), type: 'text', typeAction: null, class: '' }
                ]
                if (!this.ensembles_validated_at) {
                    line.push({ label: 'Supprimer', item: result, type: 'action', typeAction: 'delete', class: 'text-secondary', icon:'trash-alt', disabled: false })
                }
                this.dataForTab.push(line)
            }
        }
    }

    /** retourne la chaine filiere à afficher ds le tableau */
    getFiliere (result: any) {
        let filiere = ''
        if (result.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT) {
            filiere =  result.concour.name
        } else {
            for (let i = 0; i < result.groupeEpreuve.epreuves.length; i++) {
                for (let j = 0; j < result.groupeEpreuve.epreuves[i].epreuves.length; j++) {
                    const concTemp = store.getters['concour/concourById'](result.groupeEpreuve.epreuves[i].epreuves[j].concour_id)
                    if (concTemp) {
                        if (filiere.length > 0) {
                            filiere += ', '
                        }
                        filiere += concTemp.code
                    }
                }
            }
        }
        return filiere
    }

    /** retourne la chaine epreuve à afficher ds le tableau
     * @mode 'line' separé d'une virgule, 'br' séparé d'un \<br\>, 'array' retourne un tableau de {id, libelle, matiere_id}
     */
    getEpreuve (result: any, mode = 'line') {
        const epreuvesTable = []
        let epreuves = ''
        let indice = 0
        if (result.groupeEpreuve && result.groupeEpreuve.epreuves && result.groupeEpreuve.epreuves.length > 0) {
            for (let i = 0; i < result.groupeEpreuve.epreuves.length; i++) {
                if (result.groupeEpreuve.epreuves[i].epreuves && result.groupeEpreuve.epreuves[i].epreuves.length > 0) {
                    for (let j = 0; j < result.groupeEpreuve.epreuves[i].epreuves.length; j++) {
                        if (indice > 0) {
                            if (mode === 'br') {
                                epreuves += '<br>'
                            } else {
                                epreuves += ', '
                            }
                        }
                        let libEpreuve = ''

                        const conc = store.getters['concour/concourById'](result.groupeEpreuve.epreuves[i].epreuves[j].concour_id)
                        if (conc) {
                            libEpreuve = conc.code + '-' + result.groupeEpreuve.epreuves[i].name
                        } else {
                            libEpreuve = result.groupeEpreuve.epreuves[i].name
                        }
                        if (mode === 'array') {
                            epreuvesTable.push({
                                id: result.groupeEpreuve.epreuves[i].id,
                                libelle: libEpreuve,
                                matiere_id: result.groupeEpreuve.epreuves[i].matiere_id,
                                code_concours: (conc ? conc.code : ''),
                                type_passation : result.groupeEpreuve.epreuves[i].type_passation
                            })
                        }
                        epreuves += libEpreuve
                        indice++
                    }
                } else {
                    if (indice > 0) {
                        if (mode === 'br') {
                            epreuves += '<br>'
                        } else {
                            epreuves += ', '
                        }
                    }
                    let libEpreuve = ''
                    let conc = null
                    if (result.groupeEpreuve.epreuves[i].concour_id) {
                        conc = store.getters['concour/concourById'](result.groupeEpreuve.epreuves[i].concour_id)
                    }
                    if (conc) {
                        libEpreuve = conc.code + '-' + result.groupeEpreuve.epreuves[i].name
                    } else {
                        libEpreuve = result.groupeEpreuve.epreuves[i].name
                    }
                    if (mode === 'array') {
                        epreuvesTable.push({
                            id: result.groupeEpreuve.epreuves[i].id,
                            libelle: libEpreuve,
                            matiere_id: result.groupeEpreuve.epreuves[i].matiere_id,
                            code_concours: (conc ? conc.code : ''),
                            type_passation : result.groupeEpreuve.epreuves[i].type_passation
                        })
                    }
                    epreuves += libEpreuve
                    indice++
                }
            }
        }
        if (mode === 'array') {
            return epreuvesTable
        } else {
            return epreuves
        }
    }

    /**
     * construction filtre pour le tableau générique
     */
    setFiltersForGenericTab() {
        const options_type_ensemble = []
        for (const te in TypeEnsemble) {
            if (!isNaN(parseInt(te))) {
                options_type_ensemble.push({ index: parseInt(te), name: getTypeEnsembleSpec(parseInt(te)).libelle })
            }
        }
        const filieres = this.$store.getters['concour/banques']
        const options_filieres = []
        for (const f in filieres) {
            options_filieres.push({ index: filieres[f].id, name: filieres[f].name })
        }

        if (this.$store.getters['matiere/matieres'].length === 0) {
            this.$store.dispatch('matiere/getMatieres')
        }
        const matieres = this.$store.getters['matiere/matieres']
        const options_matieres = []
        for (const m in matieres) {
            options_matieres.push({ index: matieres[m].id, name: matieres[m].name })
        }
        this.filtres = [
            {
                libelle: 'Code',
                defautOptionlibelle: 'Rechercher un',
                model: 'name',
                value: '',
                index: 'name',
                datas: null,
                loading: false,
                options: { type: 'form', fieldsKey: 'name', strict: true }
            },
            {
                libelle: 'Type d\'équipe',
                defautOptionlibelle: 'Rechercher un',
                model: 'type_ensemble',
                value: '-',
                index: 'type_ensemble',
                datas: options_type_ensemble,
                loading: this.$store.getters['concour/loading'],
                options: { type: 'deroulant', fieldsKey: 'type_ensemble' }
            },
            {
                libelle: 'Filière',
                defautOptionlibelle: 'Rechercher une',
                model: 'concour_id',
                value: '-',
                index: 'concour_id',
                datas: options_filieres,
                loading:  this.$store.getters['concour/loading'],
                options: { type: 'deroulant', fieldsKey: 'concour.name' }
            },
            {
                libelle: 'Examinateurs prévus',
                defautOptionlibelle: 'Rechercher un nombre d\'',
                model: 'nb_estime',
                value: '',
                index: 'nb_estime',
                datas: null,
                loading: false,
                options: { type: 'form', fieldsKey: 'nb_estime', strict: true }
            },
            {
                libelle: 'Épreuves',
                defautOptionlibelle: 'Rechercher une',
                model: 'groupeEpreuve.epreuves.matiere_id',
                value: '-',
                index: 'matiere_id',
                datas: options_matieres,
                loading: this.$store.getters['matiere/loading'],
                options: { type: 'deroulant', fieldsKey: 'groupeEpreuve.epreuves' }
            }
        ]
    }

    /** défini les champs de la GenericTable */
    setFields () {
        this.genericfields = [
            { key: 'etatEdit', label: '', sortable: false, class: '', type: 'action' },
            { key: 'name', label: 'Code', sortable: true, class: 'text-center', type: 'text' },
            { key: 'type_ensemble', label: 'Type d\'équipe', sortable: true, class: '', type: 'text' },
            { key: 'concour.name', label: 'Filière', sortable: false, class: 'text-center', type: 'text' },
            { key: 'nb_estime', label: 'Examinateurs prévus', sortable: true, class: 'text-center', type: 'text' },
            { key: 'groupeEpreuve.epreuves', label: 'Épreuves', sortable: false, class: '', type: 'text' }
        ]
        if (!this.ensembles_validated_at) {
            this.genericfields.push({ key: 'delete', label: '', sortable: false, class: '', type: 'action' })
        }
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item ou params pour un sort/filter
     */
    evenementTable (paParams: any) {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'edit':
                    this.editEnsemble(paParams[1])
                    break
                case 'delete':
                    this.deleteEnsemble(paParams[1])
                    break
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break

                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break

                default:
                    break
            }
        }
    }

    /* chargement des données filtré */
    filtreSortHandler (params: any) {
        if (this.filtreJustInitiated) {
            this.filtreJustInitiated = false
        } else {
            if (JSON.stringify(params) !== JSON.stringify(this.paramsLocal)) {
                this.paramsLocal = params
                this.$store.dispatch('ensemble/getEnsembles', { params: params }).then(() =>  {
                    this.setDataForGenericTab(this.$store.state.ensemble.ensembles)
                })
            }
        }
    }

    /* chargement de plus de données */
    loadHandler (params: any) {
        this.$store.dispatch('ensemble/getMoreEnsembles', { params: params }).then(() =>  {
            this.setDataForGenericTab(this.$store.state.ensemble.ensembles)
        })
    }

    /* chargement des données ensembles */
    getDatas () {
        this.$store.dispatch('ensemble/getEnsembles', { params: this.paramsLocal }).then(() => {
            this.setDataForGenericTab(this.$store.state.ensemble.ensembles)
        })
    }

    /* chargement des données concours */
    getConcours () {
        if (this.$store.state.concour.concours.length === 0) {
            this.$store.dispatch('concour/getConcours', { params: null }).then(() => {
                this.setFiltersForGenericTab()
                this.getDatas()
            })
        } else {
            this.setFiltersForGenericTab()
            this.getDatas()
        }
    }

    /* chargement des données session active */
    getSessionActive () {
        this.$store.state.session.sessionSelect = this.$store.state.session.sessions.find((s: any) => s.id.toString() === this.session_id)
        if (this.$store.state.session.sessionSelect) {
            this.ensembles_validated_at = this.$store.state.session.sessionSelect.ensembles_validated_at
            this.sessionActive = this.$store.state.session.sessionSelect
        }
        this.getConcours()
        this.setFields()
    }


    /** fenetre edition/ajout */
    openAddEnsemble () {
        this.$store.state.ensemble.error = null
        this.ensembleTemp = {
            centre_id: 0,
            groupe_epreuve_id: 0,
            salle_id: 0,
            concour_id: null,
            id: 0,
            name: '',
            nb_estime: 0,
            session_id: this.session_id,
            type_ensemble: -1
        }
        this.showModalEditionEnsemble = true
    }

    editEnsemble (row: any) {
        this.$store.state.ensemble.error = null
        this.ensemble = this.$store.getters['ensemble/ensembleById'](row.id)
        this.ensembleTemp = JSON.parse(JSON.stringify(this.ensemble))
        this.ensembleTemp.epreuvesTemp = this.getEpreuve(this.ensemble, 'array')
        this.showModalEditionEnsemble = true
    }

    cancelEdit () {
        this.ensemble = null
        this.ensembleTemp = null
        this.showModalEditionEnsemble = false
    }

    editSuite () {
        const epreuvesTemp = []
        // console.log('---------------------' +  this.ensembleTemp.groupe_epreuve_id + '------------------')
        // console.log(this.ensembleTemp.epreuvesTemp)
        // console.log('---------------------------------------')
        if (this.ensembleTemp.epreuvesTemp && this.ensembleTemp.epreuvesTemp.length > 0) {
            for (let i = 0; i < this.ensembleTemp.epreuvesTemp.length; i++) {
                epreuvesTemp.push(this.ensembleTemp.epreuvesTemp[i].id)
            }
        }
        const payload = {
            // centre_id: this.ensembleTemp.centre_id,
            // salle_id: this.ensembleTemp.salle_id,
            groupe_epreuve_id: this.ensembleTemp.groupe_epreuve_id,
            id: this.ensembleTemp.id,
            concour_id: (this.ensembleTemp.concour_id === 0 ? null : this.ensembleTemp.concour_id),
            name: this.ensembleTemp.name,
            nb_estime: this.ensembleTemp.nb_estime,
            session_id: this.session_id,
            type_ensemble: this.ensembleTemp.type_ensemble,
            epreuves: epreuvesTemp
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        if (this.ensembleTemp.id === 0) {
            this.$store.dispatch('ensemble/addEnsemble', payload)
                .then(() => {
                    this.ensemble = null
                    this.ensembleTemp = null
                    this.showModalEditionEnsemble = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                    this.$store.dispatch('ensemble/getEnsembles', { params: this.paramsLocal })
                        .then(() => {
                            this.setDataForGenericTab(this.$store.state.ensemble.ensembles)
                        })
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            this.$store.dispatch('ensemble/updateEnsemble', payload)
                .then(() => {
                    this.ensemble = null
                    this.ensembleTemp = null
                    this.showModalEditionEnsemble = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                    this.setDataForGenericTab(this.$store.state.ensemble.ensembles)
                    this.$store.dispatch('ensemble/getEnsembles', { params: this.paramsLocal })
                        .then(() => {
                            this.setDataForGenericTab(this.$store.state.ensemble.ensembles)
                        })
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    /** fenetre validation */
    validate () {
        this.$store.state.ensemble.error = null
        this.showModalMessageValidation = true
    }

    cancelValidate () {
        this.ensemble = null
        this.showModalMessageValidation = false
    }

    validateSuite () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        let validate = 0
        if (!this.ensembles_validated_at) {
            this.$bvToast.toast('Validation en cours ...', infosToaster)
            validate = 1
        } else {
            this.$bvToast.toast('Invalidation en cours ...', infosToaster)
        }
        this.$store.dispatch('ensemble/validateEnsembles', { session_id: this.session_id, ensembles_validate: validate })
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.ensemble = null
                this.showModalMessageValidation = false
                this.$store.dispatch('session/getSession', { session_id: this.session_id })
                    .then(() => {
                        this.ensembles_validated_at = this.$store.state.session.sessionSelect.ensembles_validated_at
                        this.sessionActive = this.$store.state.session.sessionSelect
                        this.setDataForGenericTab(this.$store.state.ensemble.ensembles)
                        if (validate === 0) {
                            this.$bvToast.toast('Invalidation terminée.', succesToaster)
                        } else {
                            this.$bvToast.toast('Validation terminée.', succesToaster)
                        }
                    })
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /** fenetre suppression */
    deleteEnsemble (row: any) {
        this.$store.state.ensemble.error = null
        this.ensemble = this.$store.getters['ensemble/ensembleById'](row.id)
        this.showModalMessageDelete = true
    }

    cancelDelete () {
        this.ensemble = null
        this.showModalMessageDelete = false
    }

    deleteSuite () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Suppression en cours ...', infosToaster)
        this.$store.dispatch('ensemble/deleteEnsemble', this.ensemble.id)
            .then(() => {
                this.ensemble = null
                this.ensembleTemp = null
                this.showModalEditionEnsemble = false
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                this.ensemble = null
                this.showModalMessageDelete = false
                this.$store.dispatch('ensemble/getEnsembles', { params: null }).then(() => {
                    this.setDataForGenericTab(this.$store.state.ensemble.ensembles)
                })
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    load() {
        this.paramsLocal = { sort: 'name', page: 1, direction: 'asc' }
        this.$store.state.ensemble.error = null
        this.session_id = this.$route.params.session_id
        this.getSessionActive()
    }

    mounted () {
        this.load()
    }
}
