






































import { Vue, Component, Watch }    from 'vue-property-decorator'
import { mapGetters, mapState }               from 'vuex'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'
import { Ability }                  from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { formatDate, getFileNameFromHeader, format_phone_number }               from '@/utils/helpers'
import _ from 'lodash'


@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('listeExaminateurCdc', ['listeExaminateurs', 'isLoading', 'totalRows', 'lastPage', 'totalPage', 'selectedExaminateur'])
    },
    components: {
        ExaGenericTable,
        'font-awesome-icon': FontAwesomeIcon
    }
})

export default class ComposantExempleWithoutNav extends Vue
{
    showModalEditionCandidat?:          boolean = false
    showModalEditionCommentCandidat?:   boolean = false
    formatDate                                  = formatDate
    sessionActive: any                          = null
    tableLoading                                = false

    Ability = Ability

    // Ensemble des colonnes du tableau de epreuveCorrectionResultats

    genericfields = [
        { key: 'name',          label: 'Nom',           sortable: true,     class: '', type: 'text' },
        { key: 'first_name',    label: 'Prénom',        sortable: true,     class: '', type: 'text' },
        { key: 'ensemble',      label: 'Équipe',        sortable: true,     class: '', type: 'text' },
        { key: 'epreuve',       label: 'Matière',       sortable: true,     class: '', type: 'text' },
        { key: 'email',         label: 'Courriel',      sortable: false,    class: '', type: 'text' },
        { key: 'telephone',     label: 'Téléphone',     sortable: false,    class: '', type: 'text' }
    ]

    sortDirection   = 'asc'


    filtres:    any         = []
    dataForTab: Array<any>  = []
    allEpreuves             = []

    @Watch('listeExaminateurs')
    getExaminateurs() {
        this.setDataForGenericTab(this.$store.state.listeExaminateurCdc.listeExaminateurs)
    }

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                const name      = result.name ? result.name : '-'
                const first_name = result.first_name ? result.first_name : '-'
                const email     = result.email ? result.email : '-'
                const telephone = result.telephone ? format_phone_number(result.telephone) : '-'
                const equipe    = result.ensemble_name ? result.ensemble_name : ' - '

                // Filière et Filière-Epreuves
                let matiere = null

                matiere = this.$store.state.matiere.matieres.find((m: any) => m.id === result.matiere_id)

                const line = [
                    { label: '', item: name,          type: 'text', typeAction: null, class: '' },
                    { label: '', item: first_name,     type: 'text', typeAction: null, class: '' },
                    { label: '', item: equipe,        type: 'text', typeAction: null, class: '' },
                    { label: '', item: matiere ? matiere.name : '-',       type: 'text', typeAction: null, class: '' },
                    { label: '', item: email,         type: 'text', typeAction: null, class: '' },
                    { label: '', item: telephone,     type: 'text', typeAction: null, class: '' }

                ]
                this.dataForTab.push(line)
            }
        }
    }


    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setFiltersForGenericTab()
    {
        this.filtres =
        [
        ]
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'sortHandler':
                    this.filterWithoutBack(paParams[1])
                    break

                case 'filterHandler':
                    // this.loadHandler(paParams[1])
                    break

                case 'onLoadPage':
                    // this.loadHandler(paParams[1])
                    break

                default:
                    break
            }
        }
    }

    /* Filtre sans le back et relance setDataForGenerictable */
    filterWithoutBack(PaParams1: any) {
        let listeExaminateurs = this.$store.state.listeExaminateurCdc.listeExaminateurs
        listeExaminateurs = _.orderBy(listeExaminateurs, PaParams1.sort, PaParams1.direction)
        this.setDataForGenericTab(listeExaminateurs)
    }

    /** renvois le nom de l'épreuve, prend son id en params  */
    getEpreuveById(group_epreuve_id: any) {
        const epreuve_name = this.allEpreuves.filter((epreuve: any) => parseInt(epreuve.id) ===  parseInt(group_epreuve_id))
        console.log('all', epreuve_name)
        return epreuve_name
    }

    /**
     * Exporter au format excel
     */
    dl_export_examinateur () {
        const params: any = {
            perPage: 10000,
            centre_id: this.$store.state.user.centreId,
            excel: 1
        }

        let fileName = ''

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours...', infosToaster)

        this.$store.dispatch('ensemble/getExcelEnsembles', { session_id: this.$store.state.session.sessionSelect.id, params: params })
            .then((response) => {
                const fileNameTemp = getFileNameFromHeader(response.headers)
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    load () {
        this.$store.commit('listeExaminateurCdc/SET_LOADING', true)
        const params: any = {
            perPage: 10000
        }

        this.$store.dispatch('matiere/getMatieres')
        this.$store.dispatch('epreuve/getEpreuves', { isPrecedente: false, filters: {} })
            .then((resp) => {
                // locale pour alimenter la recherche de l'épreuve dans le generic table
                this.allEpreuves = resp.data.data
                this.$store.dispatch('ensemble/getEnsembles', { params: params })
                    .then((resp) => {
                        this.$store.commit('listeExaminateurCdc/SET_EXAMINATEUR', resp.data.data)
                        this.$store.commit('listeExaminateurCdc/SET_LOADING', false)
                    })
            })
    }

    mounted() {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
