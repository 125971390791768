















































































import { WorkingEstablishmentTypeInterface } from '@/types/WorkingEstablishmentType';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { BModal } from 'bootstrap-vue';
import { isEmpty } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { FichePosteStep, StatusEtab } from '../../../types/BourseEmploi';
import { WorkingEstablishmentInterface } from '../../../types/WorkingEstablishment';
import ErrorDisplay from '../../ErrorDisplay.vue';
import CreateEtab from '../CreateEtab.vue';
import CreateUser from '../CreateUser.vue';
import SelectEtab from '../SelectEtab.vue';
import SelectUser from '../SelectUser.vue';
import FichePoste from '../FichePoste.vue';
import { JobDescriptionStatus, getJobDescriptionStatus } from '@/types/JobDescription';
import { mapGetters } from 'vuex';
import { Ability } from '../../../types/Ability';

@Component({
    name: 'PopupAddFichePosteComponent',
    components: {
        BModal,
        FontAwesomeIcon,
        ErrorDisplay,
        SelectEtab,
        CreateEtab,
        SelectUser,
        CreateUser,
        FichePoste
    },
    computed: {
        ...mapGetters('auth', ['can']),
        establishmentType(): string {
            if (this.$data.establishment) {
                const id: number = this.$data.establishment?.working_establishment_type_id
                return this.$store.getters['workingEstablishmentType/workingEstablishmentTypes']
                    .find((establishment: WorkingEstablishmentInterface) => id === establishment.id)
                    .name
            }
            return '-'
        },
        nextWorkingEstablishementTypeName(): string {
            const workingEstablishmentTypes = this.$store.getters['workingEstablishmentType/workingEstablishmentTypes']
            if (this.$data.establishment) {
                const workingEstablishmentTypeChildren = workingEstablishmentTypes
                    ?.find((workingEstablishmentType: WorkingEstablishmentTypeInterface) => workingEstablishmentType.id === this.$data.establishment?.working_establishment_type_id)
                    ?.workingEstablishmentTypeChildren
                if (workingEstablishmentTypeChildren.length === 1) {
                    return ` "${workingEstablishmentTypeChildren[0].name}"`
                }
            } else {
                if (workingEstablishmentTypes.length === 1) {
                    return workingEstablishmentTypes[0].name
                }
            }
            return ''
        },
        canCreateForm(): boolean {
            return this.$data.establishment?.users?.length && this.$data.establishment?.workingEstablishmentType?.job_description_creation_possible
        },
        customWorkingEstablishmentTypes(): any {
            if (this.$data.establishment) {
                return this.$store.getters['workingEstablishmentType/workingEstablishmentTypes']
                    ?.find((workingEstablishmentType: WorkingEstablishmentTypeInterface) => this.$data.establishment?.working_establishment_type_id === workingEstablishmentType.id)
                    ?.workingEstablishmentTypeChildren || null
            }
            return null
        }
    }
})

export default class PopupAddFichePoste extends Vue {
    Ability = Ability
    JobDescriptionStatus = JobDescriptionStatus as typeof JobDescriptionStatus
    FichePosteStep = FichePosteStep as typeof FichePosteStep
    currentStep = FichePosteStep.SELECT_ETAB as FichePosteStep
    show = false as boolean
    loading = false as boolean
    processing = false as boolean
    error = null as null | Error
    establishment = null as any
    jobDescription = null as any
    formValues = null as any
    formCheckValidation = false as boolean
    formValidation = false as boolean
    jobCurrentStatus = JobDescriptionStatus.STATUS_DRAFT as any
    jobStatus = [] as any

    async open(): Promise<void> {
        this.establishment = null
        if (this.$route.params?.working_establishment_id) {
            await this.$store.dispatch('workingEstablishment/getWorkingEstablishment', { working_establishment_id: Number(this.$route.params?.working_establishment_id) })
            this.establishment = this.$store.getters['workingEstablishment/workingEstablishmentSelect']
        } else {
            if (isEmpty(this.$store.getters['workingEstablishmentType/workingEstablishmentTypes'])) {
                await this.$store.dispatch('workingEstablishmentType/getWorkingEstablishmentTypes')
            }

            const workingEstablishmentType = this.$store.getters['workingEstablishmentType/workingEstablishmentTypes'].filter((w: WorkingEstablishmentTypeInterface) => w.working_establishment_type_id === null)
            if (workingEstablishmentType.length === 1) {
                await this.$store.dispatch('workingEstablishment/getWorkingEstablishments', { 'filter-working_establishment_type_id': workingEstablishmentType[0].id })
                const workingEstablishments = this.$store.getters['workingEstablishment/workingEstablishments']
                if (workingEstablishments.length === 1) {
                    this.establishment = workingEstablishments[0]
                    this.setEtab({ data: this.establishment })
                }
            }
        }

        this.loading = false
        this.processing = false
        this.error = null
        this.formValues = null
        this.formCheckValidation = false
        this.formValidation = false
        this.jobCurrentStatus = JobDescriptionStatus.STATUS_DRAFT
        this.jobDescription = {
            id: 0,
            status: JobDescriptionStatus.STATUS_DRAFT,
            published_at: null,
            workingEstablishment: this.establishment,
            working_establishment_id: this.establishment?.id,
            datas: [
                {
                    "type": "input",
                    "ref": "job_descriptions.name"
                    ,"id": 1,
                    "value": this.establishment?.status === StatusEtab.PUBLIC ? 'Praticien(ne) associé(e)' : null
                }
            ]
        }

        const middle = Math.floor(Object.keys(JobDescriptionStatus).length / 2)
        this.jobStatus = Object.keys(JobDescriptionStatus).slice(0, middle)
            .map(status => getJobDescriptionStatus(parseInt(status)))

        if (this.establishment?.users?.length) {
            this.currentStep = this.isInDetailEstablishment() ? FichePosteStep.CREATE_JOB : FichePosteStep.SELECT_ETAB
        } else {
            this.currentStep = this.establishment ? FichePosteStep.SELECT_ETAB_USER : FichePosteStep.SELECT_ETAB
        }

        this.show = true
    }

    setCurrentStep(step: FichePosteStep): void {
        this.currentStep = step
    }

    setProcessing(processing: boolean): void {
        this.processing = processing
    }

    saveJobDescription(): void {
        if (this.processing) {
            return
        }
        this.processing = true

        this.formCheckValidation = false
        this.$nextTick(() => {
            this.formCheckValidation = true
        })

        if (!this.formValidation) {
            this.processing = false
            return
        }

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.$store.dispatch(`jobDescription/${this.jobDescription.id ? 'update' : 'add'}JobDescription`, {
            id: this.jobDescription.id,
            datas: this.formValues,
            status: this.jobCurrentStatus,
            working_establishment_id: this.establishment.id || null
        })
            .then((response) => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)

                this.jobDescription = response.data.data
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.processing = false
            })
    }

    async setEtab(payload: any): Promise<void> {
        const can = this.$store.getters['auth/can']
        this.processing = true

        if (payload?.setUser) {
            this.establishment?.users?.push(payload.data)
        } else {
            this.establishment = Object.assign({}, payload.data)
        }

        const workingEstablishmentsIds: number[] = this.$store.getters['user/reportingUserSelect']?.working_establishments?.map((item: any) => item.id) || []
        const canManageJob: boolean = can(Ability.ADM_ESTABLISHMENT_MANAGE) || can(Ability.EST_ESTABLISHMENT_PARENT_OWN_MANAGE) || (can(Ability.EST_ESTABLISHMENT_OWN_MANAGE) && workingEstablishmentsIds.includes(this.establishment.id) && can(Ability.EST_JOB_OWN_MANAGE))
        const canManageJobAndUser: boolean = can(Ability.ADM_ESTABLISHMENT_MANAGE) || can(Ability.EST_ESTABLISHMENT_PARENT_OWN_MANAGE) || (can(Ability.EST_ESTABLISHMENT_OWN_MANAGE) && workingEstablishmentsIds.includes(this.establishment.id) && can(Ability.EST_JOB_OWN_MANAGE) && can(Ability.EST_INTERV_OWN_MANAGE))
        const goToCreateJob = () => {
            this.jobDescription = {
                id: 0,
                status: JobDescriptionStatus.STATUS_DRAFT,
                published_at: null,
                workingEstablishment: this.establishment,
                working_establishment_id: this.establishment?.id,
                datas: [
                    {
                        "type": "input",
                        "ref": "job_descriptions.name"
                        ,"id": 1,
                        "value": this.establishment?.status === StatusEtab.PUBLIC ? 'Praticien(ne) associé(e)' : null
                    }
                ]
            }
            this.currentStep = FichePosteStep.CREATE_JOB
        }

        switch (this.currentStep) {
            case FichePosteStep.SELECT_ETAB:
                if (this.establishment.workingEstablishmentChildren?.length) {
                    if (this.establishment.users?.length) {
                        this.currentStep = FichePosteStep.SELECT_ETAB
                    } else if (!this.establishment.users?.length && canManageJobAndUser) {
                        this.currentStep = FichePosteStep.SELECT_ETAB_USER
                    }
                } else {
                    if (this.establishment.users?.length && canManageJob) {
                        goToCreateJob()
                    } else if (!this.establishment.users?.length && canManageJobAndUser) {
                        this.currentStep = FichePosteStep.SELECT_ETAB_USER
                    }
                }
                break

            case FichePosteStep.CREATE_ETAB:
                if (can(Ability.ADM_ESTABLISHMENT_MANAGE) || can(Ability.EST_ESTABLISHMENT_PARENT_OWN_MANAGE)) {
                    this.currentStep = FichePosteStep.SELECT_ETAB_USER
                }
                break

            case FichePosteStep.SELECT_ETAB_USER:
            case FichePosteStep.CREATE_ETAB_USER:
                if (this.establishment.workingEstablishmentChildren?.length) {
                    this.currentStep = FichePosteStep.SELECT_ETAB
                } else if (this.establishment.workingEstablishmentType?.job_description_creation_possible && canManageJob) {
                    goToCreateJob()
                }
        }

        this.processing = false
    }

    async close(): Promise<void> {
        if (this.currentStep === FichePosteStep.CREATE_JOB) {
            this.$emit('refresh')
        }
        this.show = false
    }

    /**
     * @description Vérifie si l'utilisateur consulte en détail un établissement
     * @returns {void}
     */
    isInDetailEstablishment(): boolean {
        return !!this.$route.params.working_establishment_id && this.$route.path === `/bourse_emploi/etablissements/${this.$route.params.working_establishment_id}`
    }

    async mounted(): Promise<void> {
        if (isEmpty(this.$store.getters['workingEstablishmentType/workingEstablishmentTypes'])) {
            await this.$store.dispatch('workingEstablishmentType/getWorkingEstablishmentTypes')
        }

        const can = this.$store.getters['auth/can']
        if (!can(Ability.ADM_ESTABLISHMENT_MANAGE) && isEmpty(this.$store.getters['user/reportingUserSelect'])) {
            await this.$store.dispatch('user/getReportingUser', { user_id: this.$store.getters['auth/authUser']?.id })
        }
    }
}
