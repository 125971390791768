



















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import ItemIntervenant from '@/components/ItemIntervenant.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { DecisionIntervenant, Etat } from '@/types/DossierAcademique'
import { EntityType } from '@/types/Poste'

@Component({
    components: {
        ItemIntervenant
    },
    computed: {
        ...mapGetters('dossieracademique', ['dossiersAcademiques']),
        ...mapState('epreuve', ['epreuvesCorrections'])
    },
    methods: {
        ...mapActions('dossieracademique', ['updateDossierAcademique'])
    }
})
export default class ModuleReponseIntervenant extends Vue {
    @Prop() user?: any;
    etat_vierge = Etat.ETAT_VIERGE
    etat_depose = Etat.ETAT_DEPOSE
    etat_valide = Etat.ETAT_VALIDE
    etat_rejete = Etat.ETAT_REJETE

    decision_intervenant_masquer = DecisionIntervenant.DECISION_INTERVENANT_MASQUE
    decision_intervenant_attente = DecisionIntervenant.DECISION_INTERVENANT_ATTENTE
    decision_intervenant_oui = DecisionIntervenant.DECISION_INTERVENANT_OUI
    decision_intervenant_non = DecisionIntervenant.DECISION_INTERVENANT_NON
    entity_epreuve_correction = EntityType.ET_EPREUVE_CORRECTION

    dismissSecs = 5
    dismissCountDown = 0
    showMessageIndispo = false
    dossier_id_select = 0
    messageIndispo = ''

    @Watch('dossiersAcademiques')
    setDossiersAcademiques () {
        if (this.$store.state.dossieracademique.dossiersAcademiques.length !== 0) {
            for (const d in this.$store.state.dossieracademique.dossiersAcademiques) {
                this.setEpreuveCorrectionPostesCourant(this.$store.state.dossieracademique.dossiersAcademiques[d].courant)
            }
        }
    }

    // Set les noms d'épreuves corrections pour les postes -- session couranre
    setEpreuveCorrectionPostesCourant (dossierAcademique: any) {
        if (dossierAcademique && dossierAcademique.postes_affectations && dossierAcademique.postes_affectations.length !== 0) {
            const postes_affectations = dossierAcademique.postes_affectations
            for (const p in postes_affectations) {
                const epreuve = this.$store.state.epreuve.epreuvesCorrections.find((e: any) => e.id === postes_affectations[p].entity_id)
                dossierAcademique.postes_affectations[p].epreuve_name = (epreuve !== undefined ? this.getConcoursName(epreuve.concours) + '-' + epreuve.name : '')
            }
        }
    }

    // set le nom des concours d'une épreuve
    getConcoursName (concours: any) {
        return concours?.map((concour: any) => concour.name)
            ?.join(', ') || ''
    }

    countDownChanged (dismissCountDown: number) {
        this.dismissCountDown = dismissCountDown
    }

    // Affiche la zone de saisie d'un message d'indisponibilité
    add_message_indispo (dossier_id: number) {
        this.showMessageIndispo = true
        this.dossier_id_select = dossier_id
    }

    // Annule la saisie du message d'indisponibilité
    cancelMessageIndispo () {
        this.dossier_id_select = 0
        this.showMessageIndispo = false
    }

    // Update la réponse de l'intervenant
    updateDisponibilite (dossier_id: number, response: DecisionIntervenant) {
        let datas = {}

        if (response === DecisionIntervenant.DECISION_INTERVENANT_NON) {
            datas = {
                decision_intervenant_affectation: response,
                commentaire_rejet_cle: 'decision_affectation_intervenant',
                commentaire_rejet_message: this.messageIndispo
            }
        } else if (response === DecisionIntervenant.DECISION_INTERVENANT_OUI) {
            datas = {
                decision_intervenant_affectation: response
            }
        }

        this.$store.dispatch('dossieracademique/updateDossierAcademique', { dossier_id: dossier_id, payload: datas }).then(() => {
            this.$store.dispatch('dossieracademique/getDossiersAcademiques')
            this.dismissCountDown = this.dismissSecs

            this.showMessageIndispo = false
            this.dossier_id_select = 0
            this.messageIndispo = ''
        })
    }
}

