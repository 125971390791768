















































































































































































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import store from '@/store/index'
import Table from '@/components/Table.vue'
import { Ability } from '@/types/Ability'
import { getTypePassationSpec, TypePassation } from '@/types/Epreuve'
import { codeRestrict, codeRestrictCodeImport, formatNumber } from '@/utils/helpers'


import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { getListeValueArrondi, getTypeArrondi, getTypeMethodArrondi, TypeMethodArrondi } from '@/types/EpreuveCorrectionResultat'

@Component({
    components: {
        Table,
        ExaGenericTable
    },
    computed: {
        ...mapGetters('concour', ['concourById']),
        ...mapGetters('epreuve', ['loading', 'error', 'epreuves', 'getEpreuveById', 'meta', 'links', 'totalRows', 'getEpreuveByTypePassation', 'typeEpreuves', 'typeEpreuveById']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})
export default class Epreuves extends Vue {
    session_id: any = 0
    currentSession: any = null

    getListeValueArrondi = getListeValueArrondi
    TypeMethodArrondi = TypeMethodArrondi
    getTypeMethodArrondi = getTypeMethodArrondi
    formatNumber = formatNumber

    val_arrondi = '0.1'
    method_arrondi = ''



    TypePassation =  TypePassation
    getTypePassationSpec = getTypePassationSpec

    typesEpreuveLoaded = false

    actionLimited = false
    codeRestrict = codeRestrict
    codeRestrictCodeImport = codeRestrictCodeImport
    // DATAS
    dataForTab: Array<any> = []
    filtres: any = []

    currentEpreuveConcour = null


    params = 'sort=name&direction=asc'
    firstLoading = true


    genericfields = [
        { key: 'etatEdit', label: '', sortable: false, class: '', type: 'action' },
        { key: 'code', label: 'Code', sortable: true, class: 'text-left col-min-width', type: 'text' },
        { key: 'name', label: 'Libellé court', sortable: true, sortDirection: 'asc', class: 'text-left', type: 'text' },
        { key: 'concour_id', label: 'Filière', sortable: true, class: 'text-left', type: 'text' },
        { key: 'matiere_id', label: 'Matière', sortable: true, class: 'text-left', type: 'text' },
        { key: 'type_passation_epreuve_init', label: 'Type passation', sortable: true, class: 'text-center', type: 'text' },
        { key: 'option', label: 'Choix', sortable: true, class: 'text-center', type: 'text' },
        { key: 'tirage_aleatoire', label: 'Tirage', sortable: true, class: 'text-center', type: 'text' },
        { key: 'obligatoire', label: 'Facultative', sortable: true, class: 'text-center', type: 'text' },
        { key: 'estimation_nb_candidats', label: 'Candidats estimés', sortable: true, class: 'text-center', type: 'text' },
        { key: 'ordre', label: 'N° d\'ordre', sortable: true, sortDirection: 'asc', class: 'text-center ps-4' },
        { key: 'delete', label: '', sortable: false, class: '', type: 'action' }
    ]

    fields = [
        {
            key: 'actionEdit',
            label: '',
            sortable: false,
            sortDirection: 'asc',
            tdClass: 'col-w-etat btn_action_ligne'
        },
        { key: 'code', label: 'Code', sortable: true, sortDirection: 'asc', class: 'text-start ps-4' },
        { key: 'name', label: 'Libellé', sortable: true, sortDirection: 'asc', class: 'text-start ps-4' },
        {
            key: 'concour_id',
            label: 'Filière',
            sortable: true,
            sortDirection: 'asc',
            class: 'text-center',
            formatter: function (cell: any) {
                const concourTemp = store.getters['concour/concourById'](cell)
                return concourTemp ? concourTemp.name : '-'
            }
        },
        {
            key: 'matiere_id',
            label: 'Matière',
            sortable: true,
            sortDirection: 'asc',
            class: 'text-start ps-4',
            formatter: function (cell: any) {
                if (cell !== null) {
                    const matiere = store.getters['matiere/getMatiereById'](cell)
                    if (matiere) {
                        return matiere.name
                    } else {
                        return null
                    }
                } else {
                    return '-'
                }
            }
        },
        {
            key: 'type_passation_epreuve_init',
            label: 'Type épreuve',
            sortable: true,
            sortDirection: 'asc',
            class: 'text-center ps-4',
            formatter: function (cell: any, prop: any, row: any) {
                if (row.type_passation === TypePassation.TYPE_EPREUVE_MAITRESSE) {
                    const fc = store.getters['epreuve/getFirstChildById'](row.id)
                    if (fc) {
                        return fc.type_passation
                    } else {
                        return '-'
                    }
                } else {return row.type_passation}
            }
        },
        {
            key: 'option',
            label: 'Choix',
            sortable: true,
            sortDirection: 'asc',
            class: 'text-center ps-4',
            formatter: function (cell: any, prop: any, row: any) {
                if (cell === 1 && row.type_passation === TypePassation.TYPE_EPREUVE_MAITRESSE) {
                    return 'M'
                }
                else if (cell === 1) {
                    const epreuveM = store.getters['epreuve/getEpreuveById'](row.id_epreuve_maitresse)
                    if (epreuveM) {
                        return 'O (' + epreuveM.code ? epreuveM.code : '-' + ')'
                    } else {
                        return 'O (-)'
                    }
                } else {
                    return '-'
                }
            }
        },
        {
            key: 'tirage_aleatoire',
            label: 'Tirage',
            sortable: true,
            sortDirection: 'asc',
            class: 'text-center ps-4',
            formatter: function (cell: any, prop: any, row: any) {
                if (cell === 1 && row.type_passation === TypePassation.TYPE_EPREUVE_MAITRESSE) {
                    return 'M'
                }
                else if (cell === 1) {
                    const epreuveM = store.getters['epreuve/getEpreuveById'](row.id_epreuve_maitresse)
                    return row.taux_tirage + ' % (' + (epreuveM && epreuveM.code ? epreuveM.code : '-') + ')'
                } else {
                    return '-'
                }
            }
        },
        {
            key: 'obligatoire',
            label: 'Facultative',
            sortable: true,
            sortDirection: 'asc',
            class: 'text-center ps-4',
            formatter: function (cell: any) {
                if (cell === 1) {
                    return 'non'
                } else {
                    return 'oui'
                }
            }
        },
        {
            key: 'estimation_nb_candidats',
            label: 'Candidats estimés',
            sortable: true,
            sortDirection: 'asc',
            class: 'text-center ps-4',
            formatter: function (cell: any, prop: any, row: any) {
                if (row.type_passation === TypePassation.TYPE_EPREUVE_MAITRESSE) {
                    return store.getters['epreuve/getEstimationNbCandidatsOfChildById'](row.id)
                }
                else {
                    return cell
                }
            }
        },
        { key: 'ordre', label: 'N° d\'ordre', sortable: true, sortDirection: 'asc', class: 'text-center ps-4' },
        { key: 'delete', label: '', sortable: false, sortDirection: 'asc', tdClass: 'text-end' }
    ]


    sortBy = ''
    sortDesc = false
    sortDirection = 'asc'
    filter = ''
    filterOn = []
    stickyHeader = true
    epreuve: any = null
    epreuveTemp: any = null
    Ability = Ability

    showModalEditionEpreuve = false
    showModalMessageDelete = false
    showModalTypeEpreuve = false
    showModalGestionTypeEpreuve = false
    showModalDeleteTypeEpreuve = false
    options_filieres: Array<any> = []
    options_matieres: Array<any> = []
    epreuvesMaitresses: Array<any> = []

    epreuveTempFacultative = false

    typeEpreuveTemp: any = null
    typesEpreuveTemp: Array<any> = []
    idNewTypeEpreuve = 0


    // METHODS

    // ---- generic table --------------------------

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setDataForGenericTab(poData: any, isLoadMore = false) {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const result of poData) {
                const concourTemp = store.getters['concour/concourById'](result.concour_id)
                const concourLibelle =  concourTemp ? concourTemp.name : '-'

                const matiere = store.getters['matiere/getMatiereById'](result.matiere_id)
                const matiereLibelle = matiere  ? matiere.name : '-'

                let libelleTypePassation  = '-'
                if (result.type_passation === TypePassation.TYPE_EPREUVE_MAITRESSE) {
                    const fc = store.getters['epreuve/getFirstChildById'](result.id)
                    if (fc) {
                        libelleTypePassation =  getTypePassationSpec(fc.type_passation).libelle
                    }
                } else {
                    libelleTypePassation = getTypePassationSpec(result.type_passation).libelle
                }


                let choix = ''
                if (result.option === 1 && result.type_passation === TypePassation.TYPE_EPREUVE_MAITRESSE) {
                    choix = 'M'
                }
                else if (result.option === 1) {
                    const epreuveM = store.getters['epreuve/getEpreuveById'](result.id_epreuve_maitresse)
                    if (epreuveM) {
                        choix =  'O (' + epreuveM.code ? epreuveM.code : '-' + ')'
                    } else {
                        choix =  'O (-)'
                    }
                } else {
                    choix =  '-'
                }

                let tirage = '-'
                if (result.tirage_aleatoire === 1 && result.type_passation === TypePassation.TYPE_EPREUVE_MAITRESSE) {
                    tirage =  'M'
                }
                else if (result.tirage_aleatoire === 1) {
                    const epreuveM = store.getters['epreuve/getEpreuveById'](result.id_epreuve_maitresse)
                    tirage =  result.taux_tirage + ' % (' + (epreuveM && epreuveM.code ? epreuveM.code : '-') + ')'
                } else {
                    tirage =  '-'
                }

                let facultative = '-'
                if (result.obligatoire === 1) {
                    facultative = 'non'
                } else {
                    facultative = 'oui'
                }

                let estimationNbCandidats = 0
                if (result.type_passation === TypePassation.TYPE_EPREUVE_MAITRESSE) {
                    estimationNbCandidats = store.getters['epreuve/getEstimationNbCandidatsOfChildById'](result.id)
                }
                else {
                    estimationNbCandidats = result.estimation_nb_candidats
                }

                const classEtatEdit = 'text-light col-w-etat ' + (!result.closed_at ? 'btn_action_ligne' : 'bg-secondary')
                const iconEtatEdit = !result.closed_at ? this.canEdit() ? 'pen' : 'eye' : 'lock'

                const line = [
                    { label: '', item: result, icon: iconEtatEdit, type: 'action', typeAction: 'etatEdit', class: classEtatEdit, disabled: false },
                    { label: '', item: result.code, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.name, type: 'text', typeAction: null, class: '' },
                    { label: '', item: concourLibelle, type: 'text', typeAction: null, class: '' },
                    { label: '', item: matiereLibelle, type: 'text', typeAction: null, class: '' },
                    { label: '', item: libelleTypePassation, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: choix, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: tirage, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: facultative, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: estimationNbCandidats, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: result.ordre, type: 'text', typeAction: null, class: 'text-center' }
                ]

                if (this.canEdit()) {
                    const trashLine =  { label: 'Supprimer',   item: result, type: 'action',  typeAction: 'delete',  class: 'text-secondary', icon:'trash-alt', disabled: false }
                    line.push(trashLine)
                }
                this.dataForTab.push(line)
            }
        }
        return this.dataForTab
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setFiltersForGenericTab() {
        const filieres = this.$store.getters['concour/banques']
        this.options_filieres = []

        for (const f in filieres) {
            this.options_filieres.push({ index: filieres[f].id, name: filieres[f].name })
        }

        const options_type_passation = []
        for (const tp in TypePassation) {
            if (!isNaN(parseInt(tp))) {
                options_type_passation.push({ index: parseInt(tp), name: getTypePassationSpec(parseInt(tp)).libelle })
            }
        }

        const matieres = this.$store.getters['matiere/matieres']
        this.options_matieres = []
        for (const m in matieres) {
            this.options_matieres.push({ index: matieres[m].id, name: matieres[m].name })
        }
        this.filtres = [
            {
                libelle: 'Code',
                defautOptionlibelle: 'Rechercher un',
                model: 'code',
                value: '',
                index: 'code',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'code', strict: true } // 'form' , 'deroulant'
            },
            {
                libelle: 'Libellé',
                defautOptionlibelle: 'Rechercher un',
                model: 'name',
                value: '',
                index: 'name',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'name' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Filière',
                defautOptionlibelle: 'Rechercher une',
                model: 'concour_id',
                value: '-',
                index: 'id',
                datas: this.options_filieres,
                loading: false,
                options: { type: 'deroulant', fieldsKey: 'concour_id' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Matière',
                defautOptionlibelle: 'Rechercher une',
                model: 'matiere_id',
                value: '',
                index: 'id',
                datas: this.options_matieres, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'deroulant', fieldsKey: 'matiere_id' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Type épreuve',
                defautOptionlibelle: 'Rechercher un',
                model: 'type_passation',
                value: '-',
                index: 'id',
                datas: options_type_passation, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'deroulant', fieldsKey: 'type_passation_epreuve_init' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Facultative',
                defautOptionlibelle: 'Rechercher une épreuve',
                model: 'obligatoire',
                value: '-',
                index: 'id',
                datas: [{ index: 0, name: 'Oui' }, { index: 1, name: 'Non' }], // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'deroulant', fieldsKey: 'obligatoire' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Candidats estimés',
                defautOptionlibelle: 'Rechercher un nbre de ',
                model: 'estimation_nb_candidats',
                value: '',
                index: 'id',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'estimation_nb_candidats', strict: true } // 'form' , 'deroulant'
            }
        ]
    }

    /**
     * Récupération des events de la table
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'edit':

                    break
                case 'openComment':

                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break

                case 'etatEdit':
                    this.editEpreuve(paParams[1])
                    break
                case 'delete':
                    this.deleteEpreuve(paParams[1])
                    break
                default:
                    break
            }
        }
    }

    // --------------------------------------

    canEdit () {
        if (this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE) && this.currentSession && this.currentSession.closed_at === null && this.currentSession.structure_valide === 0) {
            return true
        } else {
            return false
        }
    }

    // ---- gestion des types Epreuves

    openGestionTypeEpreuve () {
        this.idNewTypeEpreuve = 0
        this.typesEpreuveTemp = []
        for (let i = 0; i < this.$store.state.epreuve.typeEpreuves.length; i++) {
            this.typesEpreuveTemp.push({
                id: this.$store.state.epreuve.typeEpreuves[i].id,
                code: this.$store.state.epreuve.typeEpreuves[i].code,
                name: this.$store.state.epreuve.typeEpreuves[i].name,
                session_id: this.$store.state.epreuve.typeEpreuves[i].session_id
            })
        }
        this.showModalGestionTypeEpreuve = true
    }

    closeGestionTypeEpreuve () {
        this.showModalGestionTypeEpreuve = false
        this.typesEpreuveTemp = []
    }

    addNewTypeEpreuve() {
        this.idNewTypeEpreuve--
        this.typesEpreuveTemp.push({
            id:  this.idNewTypeEpreuve,
            code: '',
            name: '',
            session_id: this.session_id
        })
    }

    saveTypeEpreuve(item: any) {
        this.typeEpreuveTemp = {
            id: item.id,
            code: item.code,
            name: item.name
        }
        this.saveTypeEpreuveSuite(true)
    }

    typeEpreuveIsmodified(item: any) {
        if (item && item.id < 0) {
            return true
        } else if (item) {
            for (let i = 0; i < this.$store.state.epreuve.typeEpreuves.length; i++) {
                if (item.id === this.$store.state.epreuve.typeEpreuves[i].id &&
                (item.code.trim() !==  this.$store.state.epreuve.typeEpreuves[i].code.trim() ||
                item.name.trim() !==  this.$store.state.epreuve.typeEpreuves[i].name.trim())) {
                    return true
                }
            }
            return false
        } else {
            return false
        }
    }

    openAddTypeEpreuve () {
        this.showModalTypeEpreuve = true
        this.typeEpreuveTemp = {
            id: 0,
            code: '',
            name: ''
        }
    }

    closeAddTypeEpreuve () {
        this.showModalTypeEpreuve = false
        this.typeEpreuveTemp = null
    }

    deleteTypeEpreuve (data: any) {
        this.typeEpreuveTemp = data
        this.showModalDeleteTypeEpreuve = true
    }

    cancelDeleteTypeEpreuve () {
        this.typeEpreuveTemp = null
        this.showModalDeleteTypeEpreuve = false
    }

    deleteSuiteTypeEpreuve () {
        if (this.typeEpreuveTemp.id < 1) {
            const typesEpreuveTempBis = []
            for (let i = 0; i < this.typesEpreuveTemp.length;  i++) {
                if (this.typeEpreuveTemp.id !== this.typesEpreuveTemp[i].id) {
                    typesEpreuveTempBis.push(this.typesEpreuveTemp[i])
                }
            }
            this.typesEpreuveTemp = typesEpreuveTempBis
            this.typeEpreuveTemp = null
            this.showModalDeleteTypeEpreuve = false
        } else {
            const idInfo = 't_info_' + Math.random()
            const infosToaster = {
                id: idInfo,
                toaster: 'b-toaster-top-right',
                variant: 'primary',
                noCloseButton: true,
                fade: true,
                noAutoHide: true
            }
            this.$bvToast.toast('Suppression en cours ...', infosToaster)
            this.$store.dispatch('epreuve/deleteTypeEpreuve', this.typeEpreuveTemp.id)
                .then(() => {
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Suppression terminée.', succesToaster)
                    this.showModalDeleteTypeEpreuve = false
                    const typesEpreuveTempBis = []
                    for (let i = 0; i < this.typesEpreuveTemp.length;  i++) {
                        if (this.typeEpreuveTemp.id !== this.typesEpreuveTemp[i].id) {
                            typesEpreuveTempBis.push(this.typesEpreuveTemp[i])
                        }
                    }
                    this.typesEpreuveTemp = typesEpreuveTempBis
                    this.typeEpreuveTemp = null
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    saveTypeEpreuveSuite (fromGestion  = false) {
        const idTemp = this.typeEpreuveTemp.id
        const payload = {
            id: this.typeEpreuveTemp.id,
            code: this.typeEpreuveTemp.code,
            name: this.typeEpreuveTemp.name,
            session_id: this.session_id

        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        if (this.typeEpreuveTemp.id < 1) {
            this.$store.dispatch('epreuve/addTypeEpreuve', payload)
                .then(() => {
                    this.typeEpreuveTemp = null
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                    this.closeAddTypeEpreuve()
                    this.typesEpreuveLoaded = false
                    this.$store.dispatch('epreuve/getTypeEpreuves',  { session_id: this.session_id })
                        .then(() => {
                            this.typesEpreuveLoaded = true
                            if (fromGestion) {
                                for (let i = 0; i < this.typesEpreuveTemp.length; i++) {
                                    if (this.typesEpreuveTemp[i].id === idTemp) {
                                        this.typesEpreuveTemp[i].id = idTemp
                                    }
                                }
                            }
                        })
                        .catch((error) => {
                            console.log('ko:' + error)
                        })
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            this.$store.dispatch('epreuve/updateTypeEpreuve', payload)
                .then(() => {
                    this.typeEpreuveTemp = null
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                    this.closeAddTypeEpreuve()
                    this.typesEpreuveLoaded = false
                    this.$store.dispatch('epreuve/getTypeEpreuves',  { session_id: this.session_id })
                        .then(() => {
                            this.typesEpreuveLoaded = true
                        }).catch((error) => {
                            console.log('ko:' + error)
                        })
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    // ------------------------------------------

    // --- gestion des epreuves

    tauxTirageChangeHandler(e: any) {
        if (e.target.value === '') {
            e.target.value = 1
        }
        if (e.target.value <  1) {
            e.target.value = 1
        }
        if (e.target.value > 99) {
            e.target.value = 99
        } else if (e.target.value === '0' || e.target.value === 0) {
            e.target.value = 1
        }
    }

    getLibelleEpreuvesById(id: number) {
        return this.$store.state.epreuves.filter((epreuve: any) => epreuve.banque_id === id).name
    }

    updateListeEpreuvesMaitresses () {
        this.epreuvesIncompatibles = []
        this.epreuve_temp_id = null
        this.epreuvesMaitresses = []
        let resetEpreuveMaitresseId  = true
        this.epreuvesMaitresses = this.$store.getters['epreuve/getEpreuveByTypePassation'](TypePassation.TYPE_EPREUVE_MAITRESSE)
        if (this.epreuveTemp && this.epreuveTemp.concour_id) {
            const epreuvesMatressesTemp = []
            for (let i = 0; i < this.epreuvesMaitresses.length; i++) {
                if (this.epreuvesMaitresses[i].concour_id === this.epreuveTemp.concour_id) {
                    epreuvesMatressesTemp.push(this.epreuvesMaitresses[i])
                    if (this.epreuveTemp.id_epreuve_maitresse === this.epreuvesMaitresses[i].id) {
                        resetEpreuveMaitresseId = false
                    }
                }
            }
            this.epreuvesMaitresses  = epreuvesMatressesTemp
        }
        if (resetEpreuveMaitresseId) {
            this.epreuveTemp.id_epreuve_maitresse = null
        }
        this.currentEpreuveConcour = this.$store.getters['concour/concourById'](this.epreuveTemp.concour_id)
        this.epreuveMaitresseChangeHandler()
        this.getListOfPotentiallyIncompatibleEpreuves()
    }

    epreuveMaitresseChangeHandler () {
        if (this.epreuveTemp && this.epreuveTemp.id_epreuve_maitresse !== null && this.epreuveTemp.id_epreuve_maitresse !== 'null') {
            const epM = this.$store.getters['epreuve/getEpreuveById'](this.epreuveTemp.id_epreuve_maitresse)
            this.epreuveTemp.tirage_aleatoire = epM.tirage_aleatoire
            this.epreuveTemp.option = epM.option
        } else if (this.epreuveTemp && this.epreuveTemp.type_passation !== TypePassation.TYPE_EPREUVE_MAITRESSE && (this.epreuveTemp.id_epreuve_maitresse === null || this.epreuveTemp.id_epreuve_maitresse === 'null')) {
            this.epreuveTemp.tirage_aleatoire = 0
            this.epreuveTemp.option = 0
        }
    }

    openAddEpreuve () {
        this.epreuveTemp = {
            id: 0,
            code: '',
            concour_id: null,
            obligatoire: 1,
            code_import: '',
            phase_id: null,
            ordre: 0,
            option: 0,
            tirage_aleatoire: 0,
            taux_tirage: 0,
            name: '',
            matiere_id: 0,
            long_name: '',
            type_passation: null,
            epreuve_correction_id: null,
            id_epreuve_maitresse: null,
            estimation_nb_candidats: 0,
            type_epreuve_id: null,
            note_sur: 20,
            incompatibilites_inscription: []
        }
        this.epreuveTempFacultative = false
        this.updateListeEpreuvesMaitresses()
        this.showModalEditionEpreuve = true
        this.getListOfPotentiallyIncompatibleEpreuves()
    }

    editEpreuve (data: any) {
        this.epreuve = data
        this.epreuveTemp = {
            id: this.epreuve.id,
            code: this.epreuve.code,
            concour_id: this.epreuve.concour_id,
            obligatoire: this.epreuve.obligatoire,
            code_import: this.epreuve.code_import,
            phase_id: this.epreuve.phase_id,
            ordre: this.epreuve.ordre,
            option: this.epreuve.option,
            tirage_aleatoire: this.epreuve.tirage_aleatoire,
            taux_tirage: this.epreuve.taux_tirage,
            name: this.epreuve.name,
            matiere_id: this.epreuve.matiere_id,
            long_name: this.epreuve.long_name,
            type_passation: this.epreuve.type_passation,
            epreuve_correction_id: this.epreuve.epreuve_correction_id,
            id_epreuve_maitresse: this.epreuve.id_epreuve_maitresse,
            estimation_nb_candidats: this.epreuve.estimation_nb_candidats,
            type_epreuve_id: this.epreuve.epreuveCorrection ? this.epreuve.epreuveCorrection.type_epreuve_id : null,
            note_sur: this.epreuve.note_sur,
            incompatibilites_inscription: this.epreuve.incompatibilites_inscription
        }
        this.epreuveTempFacultative = !(this.epreuve.obligatoire === 1) || (this.epreuve.id_epreuve_maitresse && this.$store.getters['epreuve/getEpreuveById'](this.epreuve.id_epreuve_maitresse).obligatoire === 0)
        this.method_arrondi = getTypeMethodArrondi(getTypeArrondi(this.epreuve.arrondi).method).index
        this.val_arrondi = getTypeArrondi(this.epreuve.arrondi).val

        this.updateListeEpreuvesMaitresses()
        this.showModalEditionEpreuve = true
        this.currentEpreuveConcour = this.$store.getters['concour/concourById'](this.epreuveTemp.concour_id)
        if(this.epreuve.incompatibilites_inscription) {
            for(let i =  0; i < this.epreuve.incompatibilites_inscription.length; i++) {
                this.epreuvesIncompatibles.push(this.$store.state.epreuve.epreuves.find((ep: any) => ep.code === this.epreuve.incompatibilites_inscription[i]))
            }
        }
        this.getListOfPotentiallyIncompatibleEpreuves()
    }


    // ------------- gestion epreuves incompatibles

    listOfPotentiallyIncompatibleEpreuves: Array<any> = []
    epreuvesIncompatibles: Array<any> = []
    epreuve_temp_id: any = null
    key_epreuveIncompatible = 'selectKeyEpreuveIncompatible'

    /**
     * getListOfPotentiallyIncompatibleEpreuves
     * Remplit le tableau listOfPotentiallyIncompatibleEpreuves avec les épreuves potentiellement incompatibles avec l'épreuve actuelle.
     * @param {None} - Aucun paramètre requis.
     */
    getListOfPotentiallyIncompatibleEpreuves() {
        this.listOfPotentiallyIncompatibleEpreuves = []
        if(this.epreuveTemp.concour_id) {
            for(let i =  0; i < this.$store.state.epreuve.epreuves.length; i++) {
                if(this.$store.state.epreuve.epreuves[i].id !== this.epreuveTemp.id && this.$store.state.epreuve.epreuves[i].concour_id === this.epreuveTemp.concour_id && !this.epreuvesIncompatibles.find((ep: any) => ep.id === this.$store.state.epreuve.epreuves[i].id)) {
                    this.listOfPotentiallyIncompatibleEpreuves.push(this.$store.state.epreuve.epreuves[i])
                }
            }
        }
        this.listOfPotentiallyIncompatibleEpreuves.sort((a: any, b: any) => {
            return a.name > b.name ? 1 : a.name < b.name ? -1 : a.code > b.code ? 1 : -1
        })
        this.key_epreuveIncompatible = 'selectKeyEpreuveIncompatible' + Math.random()
    }

    /**
     * addEpreuveToIncompatibles
     * Ajoute l'épreuve sélectionnée aux épreuves incompatibles.
     * @param {None} - Aucun paramètre requis.
     */
    addEpreuveToIncompatibles() {
        const epreuveIncompatibleTemp = this.listOfPotentiallyIncompatibleEpreuves.find((ep: any) => ep.id === this.epreuve_temp_id)
        this.epreuvesIncompatibles.push(epreuveIncompatibleTemp)
        this.getListOfPotentiallyIncompatibleEpreuves()
        this.epreuve_temp_id = null
        this.epreuvesIncompatibles.sort((a: any, b: any) => {
            return a.name > b.name ? 1 : a.name < b.name ? -1 : a.code > b.code ? 1 : -1
        })
    }

    /**
     * removeEpreuveIdToIncompatibles
     * Supprime l'épreuve spécifiée des épreuves incompatibles.
     * @param {number} epreuve_id - L'identifiant de l'épreuve à supprimer des épreuves incompatibles.
     */
    removeEpreuveIdToIncompatibles(epreuve_id: number) {
        const epreuvesIncompatiblesTemp: Array<any> = []
        for(let i = 0; i< this.epreuvesIncompatibles.length; i++) {
            if(this.epreuvesIncompatibles[i].id !== epreuve_id) {
                epreuvesIncompatiblesTemp.push(this.epreuvesIncompatibles[i])
            }
        }
        this.epreuvesIncompatibles = epreuvesIncompatiblesTemp
        this.epreuvesIncompatibles.sort((a: any, b: any) => {
            return a.name > b.name ? 1 : a.name < b.name ? -1 : a.code > b.code ? 1 : -1
        })
        this.getListOfPotentiallyIncompatibleEpreuves()
    }

    //----------------------

    cancelEdit () {
        this.epreuve = null
        this.epreuveTemp = null
        this.showModalEditionEpreuve = false
    }

    editSuite () {

        const incompatibilites_inscription = []

        for(let i = 0; i < this.epreuvesIncompatibles.length; i++) {
            incompatibilites_inscription.push(this.epreuvesIncompatibles[i].code)
        }

        const payload = {
            id: this.epreuveTemp.id,
            code: this.epreuveTemp.code,
            concour_id: this.epreuveTemp.concour_id,
            obligatoire: this.epreuveTempFacultative ? 0 : 1,
            code_import: this.epreuveTemp.code_import,
            phase_id: this.epreuveTemp.phase_id,
            ordre: this.epreuveTemp.ordre,
            option: this.epreuveTemp.option ? 1 : 0,
            tirage_aleatoire: this.epreuveTemp.tirage_aleatoire ? 1 : 0,
            taux_tirage: this.epreuveTemp.taux_tirage,
            name: this.epreuveTemp.name,
            matiere_id: this.epreuveTemp.matiere_id,
            long_name: this.epreuveTemp.long_name,
            type_passation: this.epreuveTemp.type_passation,
            epreuve_correction_id: this.epreuveTemp.epreuve_correction_id,
            id_epreuve_maitresse: (this.epreuveTemp.id_epreuve_maitresse !== 'null' && this.epreuveTemp.id_epreuve_maitresse !== null) ? this.epreuveTemp.id_epreuve_maitresse : null,
            estimation_nb_candidats: this.epreuveTemp.estimation_nb_candidats,
            type_epreuve_id: this.epreuveTemp.type_epreuve_id,
            arrondi:  this.method_arrondi + '' + this.val_arrondi,
            note_sur: this.epreuveTemp.note_sur,
            incompatibilites_inscription: incompatibilites_inscription
        }

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        if (this.epreuveTemp.id === 0) {
            this.$store.dispatch('epreuve/addEpreuve', payload)
                .then(() => {
                    this.epreuve = null
                    this.epreuveTemp = null
                    this.showModalEditionEpreuve = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                    this.$store.dispatch('epreuve/getEpreuves',  { session_id: this.session_id, params: this.params })
                        .then(() => {
                            this.setDataForGenericTab(this.$store.state.epreuve.epreuves)
                        }).catch((error) => {
                            console.log('ko:' + error)
                        })
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            this.$store.dispatch('epreuve/updateEpreuve', payload)
                .then(() => {
                    this.epreuve = null
                    this.epreuveTemp = null
                    this.showModalEditionEpreuve = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                    this.$store.dispatch('epreuve/getEpreuves',  { session_id: this.session_id, params: this.params })
                        .then(() => {
                            this.setDataForGenericTab(this.$store.state.epreuve.epreuves)
                        }).catch((error) => {
                            console.log('ko:' + error)
                        })
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    deleteEpreuve (data: any) {
        this.epreuve = data
        this.showModalMessageDelete = true
    }

    cancelDelete () {
        this.epreuve = null
        this.showModalMessageDelete = false
    }

    deleteSuite () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Suppression en cours ...', infosToaster)
        this.$store.dispatch('epreuve/deleteEpreuve', this.epreuve.id)
            .then(() => {
                this.epreuve = null
                this.epreuveTemp = null
                this.showModalEditionEpreuve = false
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Suppression terminée.', succesToaster)
                this.epreuve = null
                this.showModalMessageDelete = false
                this.$store.dispatch('epreuve/getEpreuves',  { session_id: this.session_id, params: this.params })
                    .then(() => {
                        this.setDataForGenericTab(this.$store.state.epreuve.epreuves)
                    }).catch((error) => {
                        console.log('ko:' + error)
                    })
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    loadHandler (params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)  || (JSON.stringify(this.params) === JSON.stringify(params) && this.firstLoading)) {
            this.params = params
            this.$store.dispatch('epreuve/getMoreEpreuves', { session_id: this.session_id, params: params }).then(() => {
                if (this.firstLoading) {
                    this.setFiltersForGenericTab()
                    this.firstLoading = false
                }
                this.setDataForGenericTab(this.$store.state.epreuve.epreuves)
            }).catch((error) => {
                console.log('ko:' + error)
            })
        }
    }

    // Appelle une page lors du scroll
    filtreSortHandler (params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.$store.dispatch('epreuve/getEpreuves',  { session_id: this.session_id, params: params }).then(() => {
                this.setDataForGenericTab(this.$store.state.epreuve.epreuves)
            }).catch((error) => {
                console.log('ko:' + error)
            })
        }
    }

    // Accéde à l'interface de gestion des équipes
    gestion_equipes () {
        this.$router.push('/session/' + this.$route.params.session_id + '/gestion_equipes')
    }

    mountedFin () {
        this.$store.dispatch('epreuve/getEpreuves',  { session_id: this.session_id, params: this.params }).then(() => {
            this.setDataForGenericTab(this.$store.state.epreuve.epreuves)
        }).catch((error) => {
            console.log('ko:' + error)
        })
    }

    mountedSuite () {
        this.typesEpreuveLoaded = false
        this.$store.dispatch('epreuve/getTypeEpreuves', { session_id: this.session_id }).then(() => {
            this.typesEpreuveLoaded = true
            if (this.$store.getters['concour/banques'].length === 0) {
                this.$store.dispatch('concour/getConcours',  { session_id: this.session_id }).then(() => {
                    this.setFiltersForGenericTab()
                    this.mountedFin()

                })
            } else {
                this.setFiltersForGenericTab()
                this.mountedFin()
            }
        }).catch((error) => {
            console.log('ko:' + error)
        })
    }

    mounted () {
        if (this.$route && this.$route.params && this.$route.params.session_id) {
            this.currentSession = null
            this.session_id = this.$route.params.session_id
            this.$store.dispatch('session/getSession', { session_id: this.session_id }).then(() => {
                this.currentSession = this.$store.state.session.sessionSelect
                this.mountedSuite()
            }).catch((error) => {
                console.log('ko:' + error)
            })
        } else {
            this.mountedSuite()
        }
        // store.dispatch('epreuve/getEpreuves', { session_id: this.session_id, params: null }).then(() => {
        // })
    }
}
