















import { Component, Vue } from 'vue-property-decorator';
import { isEmpty } from 'lodash';

@Component({
    name: 'SelectComponent',
    props: {
        type: {
            type: String,
            required: false,
            default: 'radio'
        },
        rows: {
            type: Array,
            required: true
        },
        selected: {
            required: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    watch: {
        selected: {
            immediate: true,
            deep: true,
            handler: 'onSelectedChange'
        },
        selection: {
            immediate: true,
            deep: true,
            handler: 'onSelectionChange'
        },
        rows: {
            deep: true,
            handler: 'onRowsChange'
        }
    }
})

export default class Select extends Vue {
    selection = this.$props.selected ? this.$props.selected : this.$props.type === 'checkbox' ? [] : null as any
    scroll = true

    isInArray(row: any): boolean {
        if (this.$props.type === 'checkbox') {
            return this.selection.some((r: any) => JSON.stringify(r) === JSON.stringify(row))
        }
        return false
    }

    onRowsChange() {
        this.scroll = true
        this.scrollToView()
    }

    scrollToView() {
        if (this.scroll) {
            this.waitRequestAnimationFrame()
                .then(() => {
                    const element = document.getElementsByClassName('selected')
                    if (!isEmpty(element)) {
                        element[0].scrollIntoView({ block: 'center' })
                        this.scroll = false
                    }
                })
        }
    }

    onSelectedChange(val: any) {
        this.selection = val ? val : this.$props.type === 'checkbox' ? [] : null
        this.scrollToView()
    }

    onSelectionChange(val: any) {
        this.$emit('select', val)
    }

    /**
     * @description Attends le rafraichissement complet du navigateur
     * @return {Promise<void>}
     */
    waitRequestAnimationFrame(): Promise<void> {
        return new Promise((resolve) => {
            this.$nextTick(() => {
                window.requestAnimationFrame(() => {
                    window.requestAnimationFrame(() => {
                        resolve()
                    })
                })
            })
        })
    }
}
