



















































































































import { mapActions, mapGetters }       from 'vuex'
import { Vue, Component, Prop, Watch }  from 'vue-property-decorator'
import _                                from 'lodash'
import  ErrorDisplay                    from '@/components/ErrorDisplay.vue'
import { Ability }                      from '@/types/Ability'
import { PosteType }                    from '@/types/Poste'
import { TypePassation }                from '@/types/Epreuve'
import { formatDateShortWithDayName }                   from '@/utils/helpers'

@Component({
    components: {
        ErrorDisplay
    },
    computed: {
        ...mapGetters('suppleant', ['loading', 'listeSuppleant', 'listeIntervenants', 'meta', 'links', 'totalRows', 'lastPage', 'totalPage', 'error']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    },
    methods: {
        ...mapActions('postes', ['getAllPostes'])
    }
})

export default class PopupEditionSuppleant extends Vue {
    @Prop() show?: boolean;
    @Prop() affectationSelect?: any
    @Prop() affectationSelectFormattedInfos?: any

    formatDateShortWithDayName = formatDateShortWithDayName

    showEdit = true
    // Variables
    nbExam                          = {}
    selectedEnsemble                = []
    selection: any                  = []
    selectionOriginal: any          = [] // stocke l'état de la sélection au chargement de l'interface.
    joursParSerieSuppleants: any    = []
    joursDisposCommunes: any        = []
    idUserBySerie: any              = [] // précise l'id de l'examinateur ou du remplaçant associé aux différentes séries par id
    centreCopy: any                 = []
    ensemblesDataCopy: any          = []
    Ability                         = Ability
    showError                       = false
    localIndexLigneJour             = -1
    localLoading                    = false
    canSaveSuppleants               = true

    /**
     * A l'affichage du popup: initialisation de la liste des suppléants déjà sélectionnés avec leurs jours
     */
    @Watch('show')
    showNoShow () {
        if (this.show === true) {
            this.$store.commit('suppleant/SET_ERROR', null)
            this.localLoading = true
            this.localIndexLigneJour = -1
            this.selection = []
            this.joursParSerieSuppleants = []
            this.joursDisposCommunes = []

            // console.log('this.affectationSelect :', this.affectationSelect)

            // On récupère la liste des intervenants suppléants pour la matière de l'affectation en cours d'édition.
            const params = {
                typePoste: PosteType.TYPE_PEDAGOGIQUE,
                typePassation: TypePassation.TYPE_PASSATION_ORAL,
                typeEntity: 'matiere',
                entity_id: this.affectationSelectFormattedInfos.idMatiere,
                regroupement: 'Examinateur'
            }
            this.$store.dispatch('suppleant/getListeIntervenants', params).then(() => {
                if (this.$store.state.suppleant.listeIntervenants.length > 0) {
                    // Puis on initialise les suppléants déjà sélectionnés avec leurs jours pour l'affectation en cours d'édition.
                    this.initIdUserBySerie()
                    this.initSelection().then(() => {
                        this.selectionOriginal = _.cloneDeep(this.selection)
                        this.localLoading = false
                    })
                } else {
                    this.localLoading = false
                }
            }).catch(() => {
                console.log('Une erreur est survenue lors du chargement de la liste des intervenants suppléants')
            })
        }
    }

    /**  */
    initIdUserBySerie() {
        this.idUserBySerie = []
        for (const affectation of this.affectationSelect.affectations) {
            this.idUserBySerie[affectation.serie_id] = affectation.remplacant_id ? affectation.remplacant_id : affectation.user_id
        }
    }

    /**  */
    jourIsDispo(jour: any) {
        return this.joursDisposCommunes[jour]
    }

    /** Initialise les suppléants déjà sélectionnés avec leurs jours
     * A chaque nouveau suppléant trouvé, on récupère par appel serveur sa liste spécifique des jours disponibles.
     */
    initSelection() {
        return new Promise((resolve) =>
        {
            for (const indexAffectation in this.affectationSelect.affectations) {
                if (this.affectationSelect.affectations[indexAffectation].suppleants.length > 0) {
                    for (const suppleant of this.affectationSelect.affectations[indexAffectation].suppleants) {
                        const line = {
                            idLigne: ++this.localIndexLigneJour,
                            suppleant_id: suppleant.suppleant.id,
                            suppleant_infos: suppleant.suppleant.name + ' ' + suppleant.suppleant.first_name,
                            serie_id: suppleant.serie_id,
                            serie_id_old: suppleant.serie_id,
                            jour: suppleant.jour,
                            jour_old: suppleant.jour
                        }
                        this.selection.push(line)
                    }
                }
                if (this.affectationSelect.affectations[indexAffectation].suppleantremplacants.length > 0) {
                    for (const suppleant of this.affectationSelect.affectations[indexAffectation].suppleantremplacants) {
                        const line = {
                            idLigne: ++this.localIndexLigneJour,
                            suppleant_id: suppleant.suppleant.id,
                            suppleant_infos: suppleant.suppleant.name + ' ' + suppleant.suppleant.first_name,
                            serie_id: suppleant.serie_id,
                            serie_id_old: suppleant.serie_id,
                            jour: suppleant.jour,
                            jour_old: suppleant.jour
                        }
                        this.selection.push(line)
                    }
                }
            }

            if (this.selection.length > 0) {
                let cptJourInitialized = 0
                const dejaInit = []
                for (const jour of this.selection) {
                // On initialise la liste des jours pour les suppléants déjà sélectionnés pour un jour
                    if (dejaInit.indexOf(jour.suppleant_id) === -1) {
                    // On récupère la liste des jours pour le suppléant uniquement si on n'a pas déjà l'entrée pour le suppléant dans le tableau joursParSerieSuppleants
                        dejaInit.push(jour.suppleant_id)
                        const params = {
                            suppleant_id: jour.suppleant_id,
                            examinateur_id: this.affectationSelect.user_id // id exminateur de l'équipe
                        }
                        this.$store.dispatch('suppleant/getJoursDispoSuppleant', params).then((response) => {
                            this.initListeJoursSuppleant(parseInt(jour.suppleant_id), response.data.data)
                            ++cptJourInitialized
                            if (cptJourInitialized === this.selection.length) {
                                resolve(true)
                            }
                        })
                    } else {
                        ++cptJourInitialized
                        if (cptJourInitialized === this.selection.length) {
                            resolve(true)
                        }
                    }
                }
            } else {
                resolve(true)
            }
        })
    }

    closeModal(withUpdate = false) {
        this.$store.commit('suppleant/SET_ERROR', null)
        this.localLoading = false
        this.localIndexLigneJour = -1
        this.selection = []
        this.joursParSerieSuppleants = []
        this.joursDisposCommunes = []
        this.$emit('close', withUpdate)
    }

    ajouterJour() {
        const line = {
            idLigne: ++this.localIndexLigneJour,
            suppleant_id: null,
            suppleant_infos: null,
            serie_id: null,
            serie_id_old: null,
            jour: null,
            jour_old: null
        }
        this.selection.push(line)
        this.canSaveSuppleants = false
    }

    /** supprime la ligne de jour avec suppléant fournie */
    deleteJour(ligneSuppleant: any, selection: any) {
        if (ligneSuppleant) {
            const serieToMAJ_id = ligneSuppleant.serie_id_old
            const jourOldToMAJ = ligneSuppleant.jour_old

            if (jourOldToMAJ) {
                // on passe le jour anciennement sélectionné à dispo
                this.joursDisposCommunes[jourOldToMAJ] = true
                const indexjourOld = this.joursParSerieSuppleants[ligneSuppleant.suppleant_id][serieToMAJ_id].jours.map((item: any) => item.jour).indexOf(jourOldToMAJ)
                if (indexjourOld > -1) {
                    this.joursParSerieSuppleants[ligneSuppleant.suppleant_id][serieToMAJ_id].jours[indexjourOld].dispo = true
                }
            }

            this.selection = selection.filter((ligne: any) => ligne.idLigne !== ligneSuppleant.idLigne)
            this.majCanSaveSuppleants()
        }
    }

    /** Lors du changement de sélection d'un suppléant, mise à jour de la liste des jours associées au suppléant */
    updateSuppleant(ligneSuppleant: any) {
        // if (!this.joursParSerieSuppleants[parseInt(ligneSuppleant.suppleant_id)]) {
        // On récupère la liste des jours pour le suppléant uniquement si on n'a pas déjà l'entrée pour le suppléant dans le tableau joursParSerieSuppleants
        const params = {
            suppleant_id: ligneSuppleant.suppleant_id,
            examinateur_id: this.affectationSelect.user_id // id exminateur de l'équipe
        }
        this.$store.dispatch('suppleant/getJoursDispoSuppleant', params).then((response) => {
            this.joursDisposCommunes = []
            this.initListeJoursSuppleant(parseInt(ligneSuppleant.suppleant_id), response.data.data)

            // on a changé de suppléant donc on reinit le jour associé à la ligne du suppléant
            this.selection[ligneSuppleant.idLigne].jour = null
            this.selection[ligneSuppleant.idLigne].jour_old = null
            this.selection[ligneSuppleant.idLigne].serie_id = null
            this.selection[ligneSuppleant.idLigne].serie_id_old = null

            this.$forceUpdate()
        })
        /*
        } else if (this.selection[ligneSuppleant.idLigne] && this.selection[ligneSuppleant.idLigne].jour) {
            // on a changé de suppléant et on avait déjà un jour sélectionné pour l'ancien suppléant donc on reinit le jour associé à la ligne du suppléant
            this.selection[ligneSuppleant.idLigne].jour = null
            this.selection[ligneSuppleant.idLigne].jour_old = null
            this.selection[ligneSuppleant.idLigne].serie_id = null
            this.selection[ligneSuppleant.idLigne].serie_id_old = null
        }
        */

        this.majCanSaveSuppleants()
    }

    /** */
    updateJourSuppleant(ligneSuppleant: any) {
        const serieToMAJ_id = ligneSuppleant.serie_id_old
        const jourOldToMAJ = ligneSuppleant.jour_old
        const jourNewToMAJ = ligneSuppleant.jour
        let serieNew = -1

        if (jourOldToMAJ) {
            this.joursDisposCommunes[jourOldToMAJ] = true
            // on passe le jour anciennement sélectionné à dispo
            const indexjourOld = this.joursParSerieSuppleants[ligneSuppleant.suppleant_id][serieToMAJ_id].jours.map((item: any) => item.jour).indexOf(jourOldToMAJ)
            if (indexjourOld > -1) {
                this.joursParSerieSuppleants[ligneSuppleant.suppleant_id][serieToMAJ_id].jours[indexjourOld].dispo = true
            }
        }

        // on passe le jour nouvellement sélectionné à NON dispo
        this.joursDisposCommunes[jourNewToMAJ] = false
        for (const serieId in this.joursParSerieSuppleants[ligneSuppleant.suppleant_id]) {
            const indexjourNew = this.joursParSerieSuppleants[ligneSuppleant.suppleant_id][serieId].jours.map((item: any) => item.jour).indexOf(jourNewToMAJ)
            if (indexjourNew > -1) {
                this.joursParSerieSuppleants[ligneSuppleant.suppleant_id][serieId].jours[indexjourNew].dispo = false
                serieNew = this.joursParSerieSuppleants[ligneSuppleant.suppleant_id][serieId].id // on récupère l'id de la série dans laquelle se trouve le jour nouvellement sélectionné
                break
            }
        }

        // maj de la sélection au sein du tableau des sélections
        const indexSelectionToMaj = this.selection.map((item: any) => item.idLigne).indexOf(ligneSuppleant.idLigne)
        if (indexSelectionToMaj > -1) {
            this.selection[indexSelectionToMaj].jour_old = this.selection[indexSelectionToMaj].jour
            this.selection[indexSelectionToMaj].serie_id = serieNew
            this.selection[indexSelectionToMaj].serie_id_old = serieNew
        }

        this.majCanSaveSuppleants()

        // console.log('Après updateJourSuppleantOnChange - this.joursParSerieSuppleants :', this.joursParSerieSuppleants)
        // console.log('Après updateJourSuppleantOnChange - this.selection :', this.selection)
    }

    /** formate la liste des jours disponibles pour un suppléant pour permettre un affichage en groupement par série dans le select */
    initListeJoursSuppleant(suppleant_id: number, listeJoursBrute: any) {
        this.joursParSerieSuppleants[suppleant_id] = {}
        for (const jour of listeJoursBrute) {
            if (this.joursParSerieSuppleants[suppleant_id][parseInt(jour.serie_id)]) {
                // si la série a déjà une entrée dans le tableau, on ajoute juste l'objet jour
                // le jour est considéré disponible si jour.ensemble_id === null && jour.user_id === null
                // this.joursParSerieSuppleants[suppleant_id][parseInt(jour.serie_id)].jours.push({ jour: jour.jour, dispo: jour.ensemble_id === null && jour.user_id === null })
                this.joursParSerieSuppleants[suppleant_id][parseInt(jour.serie_id)].jours.push({ jour: jour.jour, dispo: this.checkJourEnsembleId(suppleant_id, jour.ensemble_id, jour.user_id, jour.jour) })
            } else {
                // sinon, on créé l'entrée pour la série dans le tableau et on alimente avec son nom et l'objet jour associé.
                // le jour est considéré disponible si jour.ensemble_id === null && jour.user_id === null
                this.joursParSerieSuppleants[suppleant_id][parseInt(jour.serie_id)] = {}
                this.joursParSerieSuppleants[suppleant_id][parseInt(jour.serie_id)].name = this.get_name_serie(jour.serie_id)
                this.joursParSerieSuppleants[suppleant_id][parseInt(jour.serie_id)].id = jour.serie_id
                this.joursParSerieSuppleants[suppleant_id][parseInt(jour.serie_id)].jours = []
                // this.joursParSerieSuppleants[suppleant_id][parseInt(jour.serie_id)].jours.push({ jour: jour.jour, dispo: jour.ensemble_id === null && jour.user_id === null })
                this.joursParSerieSuppleants[suppleant_id][parseInt(jour.serie_id)].jours.push({ jour: jour.jour, dispo: this.checkJourEnsembleId(suppleant_id, jour.ensemble_id, jour.user_id, jour.jour)  })
            }

            // MAJ du tableau commun des dispos de jours
            if (this.joursDisposCommunes[jour.jour] === null || this.joursDisposCommunes[jour.jour] === undefined) {
                // this.joursDisposCommunes[jour.jour] = (jour.ensemble_id === null && jour.user_id === null)
                this.joursDisposCommunes[jour.jour] = this.checkJourEnsembleId(suppleant_id, jour.ensemble_id, jour.user_id, jour.jour)
            } else if (this.joursDisposCommunes[jour.jour] === true) {
                // this.joursDisposCommunes[jour.jour] = (jour.ensemble_id === null && jour.user_id === null)
                this.joursDisposCommunes[jour.jour] = this.checkJourEnsembleId(suppleant_id, jour.ensemble_id, jour.user_id, jour.jour)
            }
        }
    }

    checkJourEnsembleId(suppleant_id: number, ensemble_id: number, user_id: number, jour: any) {
        if (ensemble_id !== null) {
            for (let i = 0; i < this.affectationSelect.user.ensembles.length; i++) {
                if (parseInt(this.affectationSelect.user.ensembles[i].id) === ensemble_id) {
                    for (let j = 0; j < this.affectationSelect.affectations.length; j++) {
                        for (let k = 0; k < this.affectationSelect.affectations[j].suppleantremplacants.length; k++) {
                            if (this.affectationSelect.affectations[j].suppleantremplacants[k].jour === jour &&
                                parseInt(this.affectationSelect.affectations[j].suppleantremplacants[k].suppleant_id) === suppleant_id) {
                                return false
                            }
                        }
                    }
                    return true
                }
            }
            return false
            // }
        } else if (user_id === null) {
            return true
        }
    }

    /**
     * Retourne le name d'une série depuis son id (getter)
     */
    get_name_serie (serie_id: number) {
        return this.$store.getters['serie/getNameSerieById'](serie_id)
    }

    /**  */
    majCanSaveSuppleants() {
        // on récupère les éventuelles lignes pour lesquelles le suppléant ou le jour n'a pas été renseigné
        // uniquement sur les lignes existantes
        if (this.selection.length > 0) {
            const selectionhasEmptyLines = this.selection.filter((ligne: any) => !ligne.suppleant_id || !ligne.jour)
            this.canSaveSuppleants = selectionhasEmptyLines.length === 0
        } else {
            this.canSaveSuppleants = true
        }
    }

    /**
     * Confirmation de la validation/invalidation des jours avec suppléants
     */
    saveSuppleants () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours...', infosToaster)

        const sessionActive_Id =  this.$store.getters['session/sessionSelect'].id
        let cptSuppleantsSaved = 0

        for (const suppleantId in this.joursParSerieSuppleants) {
            // on récupère toutes les lignes concernant le suppleantId
            const selectionTampon = this.selection.filter((ligne: any) => parseInt(ligne.suppleant_id) === parseInt(suppleantId))
            let suppleantJoursTampon = []

            if (selectionTampon.length > 0) {
                // création du tableau des jours du suppléant d'id suppleantId
                suppleantJoursTampon = []
                for (const jour of selectionTampon) {
                    const line = {
                        serie_id: jour.serie_id,
                        ensemble_id: this.affectationSelect.ensemble_id,
                        user_id: this.idUserBySerie[jour.serie_id],
                        jour: jour.jour,
                        session_id: sessionActive_Id
                    }
                    suppleantJoursTampon.push(line)
                }
            }

            const params = {
                suppleant_id: suppleantId,
                ensemble_id: this.affectationSelect.ensemble_id,
                payload: {
                    suppleant: suppleantJoursTampon
                }
            }

            this.$store.dispatch('suppleant/updateJoursAvecSuppleants', params)
                .then((response) => {
                    ++cptSuppleantsSaved
                    if (cptSuppleantsSaved === Object.keys(this.joursParSerieSuppleants).length) {
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }
                        this.$bvToast.toast('Enregistrement terminé', succesToaster)
                        if (response.data.data.length > 0) {
                            this.$store.commit('suppleant/MAJ_DATAS_AFFECTATION_SELECT', response.data.data[0])
                        }

                        this.closeModal(true)
                    }
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }
}

