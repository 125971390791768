


































import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { isEmpty } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { FichePosteStep } from '../../types/BourseEmploi';
import { WorkingEstablishmentTypeInterface } from '../../types/WorkingEstablishmentType';
import Select from './Select.vue';
import { Ability } from '../../types/Ability';

@Component({
    name: 'SelectEtabComponent',
    components: {
        FontAwesomeIcon,
        Select
    },
    props: {
        formBtn: {
            type: Boolean,
            required: false,
            default: false
        },
        formBtnDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
        jobProcess: {
            type: Boolean,
            required: false,
            default: false
        },
        establishment: {
            type: Object,
            required: false,
            default: null
        }
    },
    computed: {
        canGoToNextStep(): boolean {
            if (!isEmpty(this.$data.selected)) {
                const can = this.$store.getters['auth/can']
                const workingEstablishmentsIds: number[] = this.$store.getters['user/reportingUserSelect']?.working_establishments?.map((item: any) => item.id) || []
                const canManageJob: boolean = can(Ability.ADM_ESTABLISHMENT_MANAGE) || can(Ability.EST_ESTABLISHMENT_PARENT_OWN_MANAGE) || (can(Ability.EST_ESTABLISHMENT_OWN_MANAGE) && workingEstablishmentsIds.includes(this.$data.selected.id) && can(Ability.EST_JOB_OWN_MANAGE))
                const canManageJobAndUser: boolean = can(Ability.ADM_ESTABLISHMENT_MANAGE) || can(Ability.EST_ESTABLISHMENT_PARENT_OWN_MANAGE) || (can(Ability.EST_ESTABLISHMENT_OWN_MANAGE) && workingEstablishmentsIds.includes(this.$data.selected.id) && can(Ability.EST_JOB_OWN_MANAGE) && can(Ability.EST_INTERV_OWN_MANAGE))
                const hasUsers = !!this.$data.selected.users?.length
                const hasChildren = !!this.$data.selected.workingEstablishmentChildren?.length
                return canManageJobAndUser || (canManageJob && hasUsers) || (!hasUsers && hasChildren && canManageJobAndUser)
            }
            return false
        }
    }
})

export default class SelectEtab extends Vue {
    Ability = Ability
    FichePosteStep = FichePosteStep
    isEmpty = isEmpty as typeof isEmpty
    code = null as null | string
    name = null as null | string
    loading = false as boolean
    results = null as any
    selected = null as any

    reset(): void {
        this.results = null
        this.code = null
        this.name = null
        this.loading = false
        this.selected = null
        this.getWorkingEstablishmentTypeIds()
    }

    search(): void {
        if (this.loading) {
            return
        }
        this.loading = true

        const payload = {
            perPage: 0,
            'filter-working_establishment_type_id': JSON.stringify(this.getWorkingEstablishmentTypeIds())
        } as any

        if (this.$props.establishment) {
            const workingEstablishmentType: WorkingEstablishmentTypeInterface = this.$store.getters['workingEstablishmentType/workingEstablishmentTypes']
                ?.find((workingEstablishmentType: WorkingEstablishmentTypeInterface) => workingEstablishmentType.id === this.$props.establishment?.working_establishment_type_id)
            if (workingEstablishmentType?.workingEstablishmentTypeParent) {
                payload['filter-job_description_creation_possible'] = 1
            }
        }

        if (this.$props.establishment) {
            payload['filter-working_establishment_id'] = this.$props.establishment?.id
        }
        if (this.code) {
            payload['filter-code'] = `%${this.code}%`
        }
        if (this.name) {
            payload['filter-name'] = `%${this.name}%`
        }

        this.$store.dispatch('workingEstablishment/getWorkingEstablishments', payload)
            .then((response) => {
                this.results = response.data.data
                    ?.filter((item: any) => item.id !== this.$props.establishment?.id)
                    ?.map((item: any) => ({ ...item, title: `${item.code} - ${item.name} (${item.workingEstablishmentType?.name})` }))
                this.loading = false
            })
            .catch(() => this.loading = false)
    }

    getWorkingEstablishmentTypeIds(): Array<number> {
        if (this.$props.establishment) {
            return this.$store.getters['workingEstablishmentType/workingEstablishmentTypes']
                ?.find((workingEstablishmentType: WorkingEstablishmentTypeInterface) => workingEstablishmentType.id === this.$props.establishment?.working_establishment_type_id)
                ?.workingEstablishmentTypeChildren
                ?.map((workingEstablishmentType: WorkingEstablishmentTypeInterface) => workingEstablishmentType.id) || []
        } else {
            return this.$store.getters['workingEstablishmentType/workingEstablishmentTypes']
                ?.filter((workingEstablishmentType: WorkingEstablishmentTypeInterface) => !workingEstablishmentType.workingEstablishmentTypeParent)
                ?.map((workingEstablishmentType: WorkingEstablishmentTypeInterface) => workingEstablishmentType.id) || []
        }
    }

    send(createJob = false): void {
        if (this.selected === null) {
            return
        }
        this.$emit('next', { data: this.selected, createJob: createJob })
        this.reset()
    }

    async mounted(): Promise<void> {
        if (isEmpty(this.$store.getters['workingEstablishmentType/workingEstablishmentTypes'])) {
            await this.$store.dispatch('workingEstablishmentType/getWorkingEstablishmentTypes')
        }
    }
}
