import { TypePassation } from './Epreuve'

export enum TypeCentre {
    TYPE_LYCEE = 1,
    TYPE_CENTRE = 2,
    TYPE_PARC = 3,
    TYPE_LABO = 4,
    // TYPE_NON_CONVENTIONNE = 5 // supprimé car remplacé par une option indépendante associée au champ "conventionne" sur le centre.
}

interface TypeCentreSpec {
    index: number,
    libelle: string
}

export enum EtatIntervenant {
    INTERVENANT_NON_DEFINIT = 0,
    INTERVENANT_DEFINIT = 1,
    INTERVENANT_PUBLIE = 2,
    INTERVENANT_SIGNATURE = 3,
    INTERVENANT_SIGNATURE_DIRECTION = 4,
}

export enum EtatConvention {
    CONVENTION_NON_DEFINIE = 0,
    CONVENTION_EN_COURS_REDACTION = 1,
    CONVENTION_SOUMISE = 2,
    // CONVENTION_ANNEXE_REJETE = 3,
    CONVENTION_PUBLIEE = 3,
    CONVENTION_SIGNATURE_RESPONSABLE = 4,
    CONVENTION_SIGNATURE_DIRECTION = 5,
}

export enum EtatAnnexeConvention {
    ANNEXE_CONVENTION_NON_DEFINIE = 0,
    ANNEXE_CONVENTION_EN_COURS_REDACTION = 1,
    ANNEXE_CONVENTION_SOUMISE = 2,
    ANNEXE_CONVENTION_REJETE = 3,
    ANNEXE_CONVENTION_PUBLIEE = 4,
    ANNEXE_CONVENTION_SIGNATURE_RESPONSABLE = 5,
    ANNEXE_CONVENTION_SIGNATURE_DIRECTION = 6,
}

export enum PlacementLock {
    PLACEMENT_NON_VERROUILLER,
    PLACEMENT_VERROUILLER,
}

interface EtatIntervenantSpec {
    index: number,
    libelle: string,
    color: string
}

interface EtatConventionSpec {
    index: number,
    libelle: string,
    color: string
}

interface EtatAnnexeConventionSpec {
    index: number,
    libelle: string,
    color: string
}

export function getEtatIntervenant(etat: EtatIntervenant): EtatIntervenantSpec {
    switch (etat) {
        case EtatIntervenant.INTERVENANT_NON_DEFINIT:
            return { index: 1, libelle: 'Non défini', color: 'tertiary' }
        case EtatIntervenant.INTERVENANT_DEFINIT:
            return { index: 2, libelle: 'Défini', color: 'secondary' }
        case EtatIntervenant.INTERVENANT_PUBLIE:
            return { index: 3, libelle: 'Publié', color: 'info' }
        case EtatIntervenant.INTERVENANT_SIGNATURE:
            return { index: 4, libelle: "Contrat signé par l'intervenant", color: 'primary' }
        case EtatIntervenant.INTERVENANT_SIGNATURE_DIRECTION:
            return { index: 5, libelle: 'Contrat finalisé', color: 'success' }
        default:
            return { index: 0, libelle: '--', color: 'light' }
    }
}

export function getEtatConvention(etat: EtatConvention): EtatConventionSpec {
    switch (etat) {
        case EtatConvention.CONVENTION_NON_DEFINIE:
            return { index: 1, libelle: 'Convention non définie', color: 'tertiary' }
        case EtatConvention.CONVENTION_EN_COURS_REDACTION:
            return { index: 2, libelle: 'Convention en cours de rédaction', color: 'secondary' }
        case EtatConvention.CONVENTION_SOUMISE:
            return { index: 3, libelle: 'Annexe à vérifier', color: 'info' }
        case EtatConvention.CONVENTION_PUBLIEE:
            return { index: 4, libelle: 'Convention publiée', color: 'info' }
        case EtatConvention.CONVENTION_SIGNATURE_RESPONSABLE:
            return { index: 5, libelle: "Convention signée par le chef d'établissement", color: 'primary' }
        case EtatConvention.CONVENTION_SIGNATURE_DIRECTION:
            return { index: 6, libelle: 'Convention signée par la direction', color: 'success' }
        default:
            return { index: 0, libelle: '--', color: 'light' }
    }
}

export function getEtatConventionFromRowItem(centre: any): EtatConventionSpec {
    if (centre.convention_validated_at) {
        // etat = EtatConvention.CONVENTION_SIGNATURE_DIRECTION
        return { index: 5, libelle: 'Convention signée par le concours', color: 'success' }
    }
    if (centre.convention_signed_at) {
        // etat = EtatConvention.CONVENTION_SIGNATURE_RESPONSABLE
        return { index: 4, libelle: "Convention signée par le chef d'établissement", color: 'successcmp' }
    }
    if (centre.convention_published_at) {
        // etat = EtatConvention.CONVENTION_PUBLIEE
        return { index: 3, libelle: "Convention en attente de signature par le chef d'établissement", color: 'primary' }
    }
    if (centre.convention_submitted_at) {
        // etat = EtatConvention.CONVENTION_ANNEXE_SOUMISE
        return { index: 2, libelle: 'Convention en cours de rédaction', color: 'tertiary' }
    }
    if (centre.convention_type_id) {
        // etat = EtatConvention.CONVENTION_EN_COURS_REDACTION
        return { index: 1, libelle: 'Convention en cours de rédaction', color: 'tertiary' }
    }

    // etat = EtatConvention.ANNEXE_CONVENTION_NON_DEFINIE
    return { index: 0, libelle: 'Convention non définie', color: 'secondary' }
}

export function getEtatAnnexeConvention(etat: EtatAnnexeConvention): EtatAnnexeConventionSpec {
    switch (etat) {
        case EtatAnnexeConvention.ANNEXE_CONVENTION_NON_DEFINIE:
            return { index: 1, libelle: 'Non définie', color: 'tertiary' }
        case EtatAnnexeConvention.ANNEXE_CONVENTION_EN_COURS_REDACTION:
            return { index: 2, libelle: 'En cours de rédaction', color: 'secondary' }
        case EtatAnnexeConvention.ANNEXE_CONVENTION_SOUMISE:
            return { index: 3, libelle: 'Annexe à vérifier', color: 'info' }
        case EtatAnnexeConvention.ANNEXE_CONVENTION_REJETE:
            return { index: 4, libelle: 'Annexe à corriger', color: 'danger' }
        case EtatAnnexeConvention.ANNEXE_CONVENTION_PUBLIEE:
            return { index: 5, libelle: 'Convention publiée', color: 'info' }
        case EtatAnnexeConvention.ANNEXE_CONVENTION_SIGNATURE_RESPONSABLE:
            return { index: 6, libelle: "Convention signée par le chef d'établissement", color: 'primary' }
        case EtatAnnexeConvention.ANNEXE_CONVENTION_SIGNATURE_DIRECTION:
            return { index: 7, libelle: 'Convention signée par la direction', color: 'success' }
        default:
            return { index: 0, libelle: '--', color: 'light' }
    }
}

export function getEtatAnnexeConventionFromRowItem(centre: any): EtatAnnexeConventionSpec {
    if (centre.annexe_validated_at) {
        // etat = EtatAnnexeConvention.CONVENTION_SIGNATURE_DIRECTION
        return { index: 6, libelle: 'Annexe de convention signée par le concours', color: 'success' }
    }
    if (centre.annexe_signed_at) {
        // etat = EtatAnnexeConvention.CONVENTION_SIGNATURE_RESPONSABLE
        return { index: 5, libelle: "Annexe de convention signée par le chef d'établissement", color: 'successcmp' }
    }
    if (centre.annexe_published_at) {
        // etat = EtatAnnexeConvention.CONVENTION_PUBLIEE
        return {
            index: 4,
            libelle: "Annexe de convention en attente de signature par le chef d'établissement",
            color: 'primary'
        }
    }
    if (centre.commentaire_rejet) {
        if (centre.commentaire_rejet.annexe) {
            // etat = EtatAnnexeConvention.CONVENTION_ANNEXE_REJETE
            return { index: 3, libelle: 'Annexe de convention à corriger par le centre', color: 'danger' }
        }
    }
    if (centre.annexe_submitted_at) {
        // etat = EtatAnnexeConvention.CONVENTION_ANNEXE_SOUMISE
        return { index: 2, libelle: 'Annexe de convention à valider', color: 'bluecmp' }
    }

    // etat = EtatAnnexeConvention.ANNEXE_CONVENTION_NON_DEFINIE
    // etat = EtatAnnexeConvention.CONVENTION_EN_COURS_REDACTION
    return { index: 0, libelle: 'Annexe de convention en cours de rédaction', color: 'tertiary' }
}

export function getTypeCentreSpec(typeCentre: TypeCentre): TypeCentreSpec {
    switch (typeCentre) {
        case TypeCentre.TYPE_LYCEE:
            return { index: 1, libelle: 'Lycée' }
        case TypeCentre.TYPE_CENTRE:
            return { index: 2, libelle: 'Centre' }
        case TypeCentre.TYPE_PARC:
            return { index: 3, libelle: 'Parc' }
        case TypeCentre.TYPE_LABO:
            return { index: 4, libelle: 'Laboratoire' }
        /*
case TypeCentre.TYPE_NON_CONVENTIONNE:
  return { index: 5, libelle: 'Non conventionné' }
*/
        default:
            return { index: 0, libelle: 'Type Inconnu' }
    }
}

export function getTypeCentreForFilterSelect(typeCentre: string): TypeCentreSpec {
    switch (typeCentre) {
        case 'TYPE_LYCEE':
            return { index: 1, libelle: 'Lycée' }
        case 'TYPE_CENTRE':
            return { index: 2, libelle: 'Centre' }
        case 'TYPE_PARC':
            return { index: 3, libelle: 'Parc' }
        case 'TYPE_LABO':
            return { index: 4, libelle: 'Laboratoire' }
        /*
case 'TYPE_NON_CONVENTIONNE':
return { index: 5, libelle: 'Non conventionné' }
*/
        default:
            return { index: 0, libelle: 'Type Inconnu' }
    }
}

export function getEnumTypeCentreForFilterSelect() {
    const enumTypeCentre = []
    /* for (const typeCentreName in TypeCentre) {
        if (isNaN(Number(typeCentreName))) {
            enumTypeCentre.push({ id: getTypeCentreForFilterSelect(typeCentreName).index, name: getTypeCentreForFilterSelect(typeCentreName).libelle })
        }
    } */
    enumTypeCentre.push({
        index: getTypeCentreSpec(TypeCentre.TYPE_CENTRE).index,
        id: getTypeCentreSpec(TypeCentre.TYPE_CENTRE).index,
        name: getTypeCentreSpec(TypeCentre.TYPE_CENTRE).libelle
    })
    enumTypeCentre.push({
        index: getTypeCentreSpec(TypeCentre.TYPE_LABO).index,
        id: getTypeCentreSpec(TypeCentre.TYPE_LABO).index,
        name: getTypeCentreSpec(TypeCentre.TYPE_LABO).libelle
    })
    enumTypeCentre.push({
        index: getTypeCentreSpec(TypeCentre.TYPE_LYCEE).index,
        id: getTypeCentreSpec(TypeCentre.TYPE_LYCEE).index,
        name: getTypeCentreSpec(TypeCentre.TYPE_LYCEE).libelle
    })
    enumTypeCentre.push({
        index: getTypeCentreSpec(TypeCentre.TYPE_PARC).index,
        id: getTypeCentreSpec(TypeCentre.TYPE_PARC).index,
        name: getTypeCentreSpec(TypeCentre.TYPE_PARC).libelle
    })
    // enumTypeCentre.push({ id: getTypeCentreSpec(TypeCentre.TYPE_NON_CONVENTIONNE).index, name: getTypeCentreSpec(TypeCentre.TYPE_NON_CONVENTIONNE).libelle })
    return enumTypeCentre
}

interface TypePassationSpec {
    index: number,
    libelle: string
}

export function getTypeCentrePassationSpec(typePassation: TypePassation): TypePassationSpec {
    switch (typePassation) {
        case TypePassation.TYPE_PASSATION_ECRIT:
            return { index: 1, libelle: 'Écrit' }
        case TypePassation.TYPE_PASSATION_ORAL:
            return { index: 2, libelle: 'Oral' }
        default:
            return { index: 0, libelle: 'Type Inconnu' }
    }
}

export function getTypeCentrePassationForFilterSelect(typePassation: string): TypeCentreSpec {
    switch (typePassation) {
        case 'TYPE_PASSATION_ECRIT':
            return { index: 1, libelle: 'Ecrit' }
        case 'TYPE_PASSATION_ORAL':
            return { index: 2, libelle: 'Oral' }
        default:
            return { index: 0, libelle: 'Type Inconnu' }
    }
}

export function getEnumTypeCentrePassationForFilterSelect() {
    const enumTypePassation = []
    /* for (const typePassation in TypePassation) {
        if (isNaN(Number(typePassation))) {
            enumTypePassation.push({ id: getTypeCentrePassationForFilterSelect(typePassation).index, name: getTypeCentrePassationForFilterSelect(typePassation).libelle })
        }
    } */
    enumTypePassation.push({
        index: getTypeCentrePassationSpec(TypePassation.TYPE_PASSATION_ECRIT).index,
        id: getTypeCentrePassationSpec(TypePassation.TYPE_PASSATION_ECRIT).index,
        name: getTypeCentrePassationSpec(TypePassation.TYPE_PASSATION_ECRIT).libelle
    })
    enumTypePassation.push({
        index: getTypeCentrePassationSpec(TypePassation.TYPE_PASSATION_ORAL).index,
        id: getTypeCentrePassationSpec(TypePassation.TYPE_PASSATION_ORAL).index,
        name: getTypeCentrePassationSpec(TypePassation.TYPE_PASSATION_ORAL).libelle
    })
    return enumTypePassation
}

export enum TypeMessageRejet {
    TYPE_MSG_REJET_SUBMIT = 'submit',
    TYPE_MSG_REJET_ANNEXE = 'annexe',
    TYPE_MSG_REJET_INFORMATIONS = 'message_adresses',
    TYPE_MSG_REJET_ORGA_ECRIT = 'besoins_salles',
}

export function getTypeMessageRejetLibelle(typeMessageRejet: string): string {
    switch (typeMessageRejet) {
        case TypeMessageRejet.TYPE_MSG_REJET_SUBMIT:
            return 'Liste des intervenants'
        case TypeMessageRejet.TYPE_MSG_REJET_ANNEXE:
            return 'Annexe de convention'
        case TypeMessageRejet.TYPE_MSG_REJET_INFORMATIONS:
            return 'Informations du centre'
        case TypeMessageRejet.TYPE_MSG_REJET_ORGA_ECRIT:
            return 'Organisation des écrits'
        default:
            return ''
    }
}

export enum StatutReception {
    EN_PREPARATION,
    EN_PRODUCTION,
    EN_LIVRAISON,
    AR_TRANSPORTEUR,
    AR_CENTRE,
}

export function getStatutReception(statut_reception: number, anomalies?: number): object {
    switch (statut_reception) {
        case StatutReception.EN_PREPARATION:
            return {
                id: StatutReception.EN_PREPARATION,
                libelle: 'En préparation',
                color: 'secondary',
                icone: 'pen',
                bg_color: 'barre_tertiary'
            }
        case StatutReception.EN_PRODUCTION:
            return {
                id: StatutReception.EN_PRODUCTION,
                libelle: 'En production',
                color: 'primary',
                icone: 'print',
                bg_color: 'barre_soumis'
            }
        case StatutReception.EN_LIVRAISON:
            return {
                id: StatutReception.EN_LIVRAISON,
                libelle: 'En livraison',
                color: 'primary',
                icone: 'dolly',
                bg_color: 'barre_soumis'
            }
        case StatutReception.AR_TRANSPORTEUR:
            return {
                id: StatutReception.AR_TRANSPORTEUR,
                libelle: 'AR Transporteur',
                color: 'primary',
                icone: 'check',
                bg_color: 'barre_soumis'
            }
        case StatutReception.AR_CENTRE:
            if (anomalies === 0 || anomalies === undefined) {
                return {
                    id: StatutReception.AR_CENTRE,
                    libelle: 'AR Centre',
                    color: 'success',
                    icone: 'check',
                    bg_color: 'barre_valide'
                }
            } else {
                return {
                    id: StatutReception.AR_CENTRE,
                    libelle: 'AR Centre',
                    color: 'danger',
                    icone: 'exclamation-triangle',
                    bg_color: 'barre_rejet'
                }
            }
        default:
            return {
                id: StatutReception.EN_PREPARATION,
                libelle: 'En préparation',
                color: 'secondary',
                icone: 'pen',
                bg_color: 'barre_tertiary'
            }
    }
}

export function getListeStatutReception(): Array<any> {
    return [
        { id: StatutReception.EN_PREPARATION, libelle: 'En préparation', color: 'btn-outline-primary' },
        { id: StatutReception.EN_PRODUCTION, libelle: 'En production', color: 'btn-outline-primary' },
        { id: StatutReception.EN_LIVRAISON, libelle: 'En livraison', color: 'btn-outline-primary' },
        { id: StatutReception.AR_TRANSPORTEUR, libelle: 'AR Transporteur', color: 'btn-outline-primary' },
        { id: StatutReception.AR_CENTRE, libelle: 'AR Centre', color: 'btn-outline-success' }
    ]
}

export function get_color_statut(statut_sujets: any, has_anomalies: number, is_control: number) {
    const statut_reception = getStatutReception(statut_sujets)
    if (statut_sujets === StatutReception.AR_CENTRE && has_anomalies === is_control) {
        return statut_reception
    } else if (statut_sujets === StatutReception.AR_CENTRE) {
        return { color: 'danger' }
    } else {
        return statut_reception
    }
}

export interface VilleInterface {
    name: string,
    id: number | 0
}

export interface CentreInterface {
    name: string,
    id: number | 0,
    ville: VilleInterface,
    type: number | 0,
    type_passation: number | 0,
    centreusers: Array<any> | [],
    submitted_at: Date | null,
    ensembles_examinateurs_sum_nb_estime: number | 0,
    ensembles_examinateurs_tp_sum_nb_estime: number | 0,
    conventionne: boolean,
    allotir_par_salle: boolean,
    telephone: string
}

export interface StateCentres {
    centres: Array<CentreInterface> | [],
    meta: Array<string> | null,
    links: Array<string> | null,
    loading: boolean | false,
    error: Array<string> | null,
    totalRows: number | 0,
    currentPage: number | 0,
    lastPage: number | 0,
    totalPage: number | 0,
    centreSelect: CentreInterface | {},
    usersFromSearch: Array<string> | null
}
