















































































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component, Watch }    from 'vue-property-decorator'
import { mapGetters, mapState }     from 'vuex'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { dateisSameOrBefore, formatDate, formatDateSinTime, formatDayHourZDateFromString } from '@/utils/helpers'
import _ from 'lodash'
import ErrorDisplay                 from '@/components/ErrorDisplay.vue'
import { EnsembleInterface, TypeEnsemble } from '@/types/Ensemble'
import { Ability } from '@/types/Ability'


@Component({
    computed: {
        ...mapGetters('ensemble', ['ensembles']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapState('planning', ['seances_notifications', 'totalRows', 'lastPage', 'total', 'loading_planning', 'currentPage', 'error_plannings', 'loading_notify'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ExaGenericTable,
        ErrorDisplay
    }
})

export default class Notifications extends Vue
{
    Ability = Ability
    formatDateSinTime = formatDateSinTime
    genericfields = [
        { key: 'centre',        label: 'Centre',        sortable: false,     class: 'text-start', type: 'text' },
        { key: 'equipe',        label: 'Équipe',        sortable: false,     class: 'text-start', type: 'text' },
        { key: 'examinateur',   label: 'Examinateur',   sortable: false,     class: 'text-start', type: 'text' },
        { key: 'matiere',       label: 'Matière',       sortable: false,     class: 'text-start', type: 'text' },
        { key: 'serie',         label: 'Série',         sortable: false,     class: 'text-start', type: 'text' },
        { key: 'updated_at',    label: 'Date de dernière modification', sortable: false,     class: 'text-center', type: 'text' },
        { key: 'date_publication', label: 'Date de publication',        sortable: false,     class: 'text-center', type: 'text' }
    ]

    sortBy          = '';
    sortDesc        = false;
    sortDirection   = 'asc';
    filter          = '';
    filterOn        = [];
    stickyHeader    = true;

    filtres:    any         = []
    dataForTab: Array<any>  = []

    options_centres         = []
    options_ensembles       = []
    options_examinateurs: any    = []
    recherche = {
        centre_id       : null,
        ensemble_id     : null,
        user_id         : null,
        updated         : null,
        updated_start   : ''
    }

    showNotifications = false
    message_notification = ''
    error_notify = ''
    recherche_temps: any = {}

    @Watch('seances_notifications')
    watchTableau() {
        const data = this.$store.state.planning.seances_notifications
        this.setDataForGenericTab(data)
    }

    @Watch('user_session_id')
    refreshInterface () {
        this.$store.state.planning.seances_notifications = []
        this.options_centres = []
        this.options_ensembles  = []
        this.options_examinateurs = []
        this.$store.state.centre.centres = []
        this.clearResult()
        this.load()
    }

    /** efface la date et clean l'interface  */
    clearDate() {
        this.recherche.updated_start = ''
    }

    /**
     * Lance la requête de sélection
     */
    rechercher () {
        this.$store.commit('planning/RESET_ERROR')
        const params  =
        {
            centre_id       : this.recherche.centre_id,
            ensemble_id     : this.recherche.ensemble_id,
            user_id         : this.recherche.user_id,
            modified        : this.recherche.updated === 1 || this.recherche.updated === 2 ? 1 : null,
            modified_start  : this.recherche.updated_start !== '' ? formatDayHourZDateFromString(this.recherche.updated_start) : null
        }

        this.recherche_temps = params

        this.$store.dispatch('planning/getSeancesNotifications', params).then(() => {
            this.setDataForGenericTab(this.$store.state.planning.seances_notifications)
        })
    }

    /** recup de tous les centres et renvois les centres avec équipe */
    getCentres() {
        if (this.$store.getters['centre/centres'].length === 0) {
            this.$store.dispatch('centre/getCentres').then((resp) => {
                this.options_centres = resp.data.data.filter((centre: { ensembles_count: number }) => centre.ensembles_count !== 0)
            })
        } else {
            this.options_centres = this.$store.getters['centre/centres'].filter((centre: { ensembles_count: number }) => centre.ensembles_count !== 0)
        }
    }

    /** Chargement des ensembles de la session */
    getEnsembles() {
        const params = {
            perPage: 10000
        }
        this.$store.dispatch('ensemble/getEnsembles', { params: params })
            .catch((error) => {
                console.log('ko:' + error)
            })
    }

    /** Chargement des ensembles suite à sélection d'un centre */
    getEnsembleCentre(centre: string) {
        this.options_ensembles      = []
        this.options_examinateurs   = []
        this.recherche.ensemble_id  = null
        this.recherche.user_id      = null
        const allEnsembles = this.$store.getters['ensemble/ensembles']
        this.options_ensembles = allEnsembles.filter((e: any) => e.centre_id === centre)
        this.options_ensembles.sort((a: EnsembleInterface, b: EnsembleInterface) => {
            return (a.type_ensemble > b.type_ensemble) ? 1 : (a.type_ensemble === b.type_ensemble ? (a.name > b.name ? 1 : -1) : -1)
        })
    }

    /** Chargement des examinateurs suite à sélection d'un ensemble  */
    getExaminateurs(equipe_id: number) {
        if (equipe_id)
        {
            this.recherche.user_id      = null
            const allEnsembles = this.$store.getters['ensemble/ensembles']
            const examinateurs = allEnsembles.filter((e: any) => e.id === equipe_id)[0].examinateurs
            const remplacants = allEnsembles.filter((e: any) => e.id === equipe_id)[0].remplacants
            this.options_examinateurs = []
            for (const e in examinateurs) {
                examinateurs[e].is_replace = false
                this.options_examinateurs.push(examinateurs[e])
            }

            if (remplacants.length !== 0) {
                for (const r in remplacants) {
                    remplacants[r].is_replace = true
                    this.options_examinateurs.push(remplacants[r])
                }
            }
            this.options_examinateurs = _.orderBy(this.options_examinateurs, 'name', 'asc')

            this.recherche.user_id = null
        }
    }

    /** Alimentation des lignes du tableau */
    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                const centre = this.$store.state.centre.centres.find((c: any) => c.id === result.centre_id)
                const ensemble = this.$store.state.ensemble.ensembles.find((e: any) => e.id === result.ensemble_id)
                let examinateur = ensemble.examinateurs.find((ex: any) => ex.id === result.user_id)
                if (!examinateur) {
                    // on cherche dans les remplaçants
                    examinateur = ensemble.remplacants.find((ex: any) => ex.id === result.user_id)
                }
                const matiere = result.ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT ? result.epreuveCorrection.matiere.name : result.ensemble.groupeEpreuve.epreuves[0].matiere.name
                const date_derniere_modif = (dateisSameOrBefore(result.updated_at, result.serie.validated_at) ? '' : result.updated_at)
                const line = [
                    { label: 'centre',      item: centre.name,                                      type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'equipe',      item: ensemble.name,                                    type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'examinateur', item: result.user_id ? examinateur.name + ' ' + examinateur.first_name : '-',  type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'matiere',     item: matiere || '-',                                   type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'serie',       item: result.serie.name,                                type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'updated_at',  item: date_derniere_modif ? formatDate(date_derniere_modif) : '-',                    type: 'text', typeAction: null, class: 'text-center' },
                    { label: 'date_publication', item: formatDate(result.serie.published_at),             type: 'text', typeAction: null, class: 'text-center' }
                ]
                this.dataForTab.push(line)
            }
        }
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'onLoadPage':
                    this.loadHandler()
                    break
            }
        }
    }

    /**
     * Appel d'une nouvelle page de séances lors du scroll
     */
    loadHandler ()
    {
        Vue.set(this.recherche, 'page', (this.$store.state.planning.currentPage + 1))
        this.$store.dispatch('planning/getMoreSeancesNotifications', this.recherche)
    }

    /**
     * Ouvre le popup d'envoi de notification à la sélection
     */
    open_notifications () {
        this.$store.commit('planning/SET_LOADING_NOTIFY', false)
        this.showNotifications = true
    }

    // Envoie la notification aux examinateurs sélectionnés
    send_notifications () {
        this.error_notify = ''
        if (this.message_notification.trim().length === 0) {
            this.error_notify = 'Vous devez saisir un message pour envoyer la notification.'
        }
        if (this.error_notify === '') {
            const params  =
        {
            centre_id       : this.recherche_temps.centre_id,
            ensemble_id     : this.recherche_temps.ensemble_id,
            user_id         : this.recherche_temps.user_id,
            modified        : this.recherche_temps.modified,
            modified_start  : this.recherche_temps.modified_start,
            notify          : 1,
            notify_message  : this.message_notification
        }

            /* Toast Area */
            const idInfo = 't_info_' + Math.random()
            const infosToaster = {
                id: idInfo,
                toaster: 'b-toaster-top-right',
                variant: 'primary',
                noCloseButton: true,
                fade: true,
                noAutoHide: true
            }
            const toast_load_message = 'Envoi de la notification en cours ...'
            this.$bvToast.toast(toast_load_message, infosToaster)
            /* End toast */
            this.$store.dispatch('planning/sendNotify', params)
                .then(() => {
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    const toast_valide_message = 'Envoi de la notification terminée'
                    this.$bvToast.toast(toast_valide_message, succesToaster)
                    this.close_notifications()
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    /**
     * Ferme la popup de notification
     */
    close_notifications () {
        this.error_notify = ''
        this.showNotifications = false
        this.message_notification = ''
    }

    clearResult() {
        this.$store.commit('planning/RESET_META')
        this.$store.commit('planning/RESET_SEANCES')
    }

    load () {
        this.$store.state.planning.error_plannings = null
        if (this.$store.state.session.sessionSelect === null) {
            this.$store.dispatch('session/getSession', { session_id: this.$store.getters['auth/user_session_id'] }).then(() => {
                this.getCentres()
                this.getEnsembles()
            })
        }
        else {
            this.getCentres()
            this.getEnsembles()
        }
    }

    mounted () {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
