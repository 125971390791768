


















import { Vue, Component, Prop } from 'vue-property-decorator'
import { mapActions, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapState('user', ['hasContrat']),
        ...mapState('gabaritFicheHoraire', ['centre_user_fh'])
    },
    methods: {
        ...mapActions('dossieradministratif', ['getContrats'])
    }
})
export default class ModuleSignatureFicheHoraire extends Vue {
    @Prop() user?: any;
    // Retourne une description selon l'état des contrats
    getDescription () {
        const description = ''
        return description
    }

    // Retourne la couleur du dossier selon son état
    getVariantColor () {
        let variant = 'text-secondary'

        /* if (count_success !== 0) {
            variant = 'text-success'
        } */
        if (this.$store.state.gabaritFicheHoraire.centre_user_fh) {
            for (const p in this.$store.state.gabaritFicheHoraire.centre_user_fh.postes) {
                if (this.$store.state.gabaritFicheHoraire.centre_user_fh.postes[p].fiche_signed_at) {
                    variant = 'text-success'
                } else {
                    variant = 'text-secondary'
                }
            }
        }

        return variant
    }

    beforeMount () {
        this.$store.dispatch('gabaritFicheHoraire/getFicheHoraire', { }).then((response) => {
            this.$store.commit('gabaritFicheHoraire/SET_CENTRE_USER_FH', response.data.data)
        }).catch((error) => {
            console.log('ko: ' + error)
        })
    }
}
