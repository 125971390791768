














































































































import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import { Ability } from '@/types/Ability'
import { getTypeEnsembleSpec, TypeEnsemble } from '@/types/Ensemble'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { codeRestrict, isObject } from '@/utils/helpers'
import { TypePassation } from '@/types/Epreuve'

@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('epreuve', ['loading']),
        ...mapGetters('ensemble', ['error']),
        ...mapGetters('session', ['sessionActive']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id'])
    }
})
export default class EditionEquipe extends Vue {
    // DATAS
    codeNotSet = true
    isObject = isObject
    @Prop() ensemble?: any;
    @Prop() getfilliere?: any;
    @Prop() getEpreuve?: any;
    Ability = Ability
    getTypeEnsembleSpec = getTypeEnsembleSpec
    codeRestrict = codeRestrict
    TypeEnsemble = TypeEnsemble
    MessageErreurSimple = 'Enregistrement impossible :'
    epreuvesExaminateurs: any = []
    ensembles_validated_at = null
    ensembleNameNotUnique = false
    currentMatiereId = 0
    selectableEpreuves: any = []
    options_filieres: any = []
    options_type_ensemble: any = []
    currentTypeEnsemble = 0
    concoursGroupeEpreuves: any = []
    reconstruct = false
    hidde_btn_ajout = false

    // METHODS
    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    pasDeGroupe = false

    /** retourn la liste des epreuves d'un concoursGroupeEpreuve sous forne d'une chaine séparé par des <br> */
    getEpreuveFromConcoursGroupeEpreuves (concour_id: number) {
        let retour = ''
        let nbEstime = 0
        this.pasDeGroupe = false
        const epreuvesTemp = []
        for (let i = 0; i < this.concoursGroupeEpreuves.length; i++) {
            if (concour_id === this.concoursGroupeEpreuves[i].id) {
                if (this.concoursGroupeEpreuves[i].groupeEpreuve) {
                    this.ensemble.groupe_epreuve_id = this.concoursGroupeEpreuves[i].groupeEpreuve.id
                    for (let j = 0; j < this.concoursGroupeEpreuves[i].groupeEpreuve.epreuves.length; j++) {
                        if (retour !== '') {
                            retour += '<br>'
                        }
                        epreuvesTemp.push({
                            id: this.concoursGroupeEpreuves[i].groupeEpreuve.epreuves[j].id,
                            libelle: this.concoursGroupeEpreuves[i].groupeEpreuve.epreuves[j].name,
                            matiere_id: this.concoursGroupeEpreuves[i].groupeEpreuve.epreuves[j].matiere_id,
                            code_concours: ''
                        })

                        retour += this.concoursGroupeEpreuves[i].name + '-' + this.concoursGroupeEpreuves[i].groupeEpreuve.epreuves[j].name
                        nbEstime++
                    }
                } else {
                    this.pasDeGroupe = true
                }
            }
        }
        if (this.ensemble && this.ensemble.id === 0 && this.ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT) {
            this.ensemble.nb_estime = nbEstime
            if (this.pasDeGroupe) {
                this.ensemble.nb_estime = this.ensemble.epreuvesTemp.length
            }  else {
                this.ensemble.epreuvesTemp = epreuvesTemp
            }
        }
        if (retour !== '') {
            return retour
        } else {return '-'}
    }

    /** ecoute l'evenememt changement de filiere */
    filiereChangeHandler() {
        this.pasDeGroupe = false
        if (this.ensemble && this.ensemble.id === 0 && (this.ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_PAR_EPREUVE || this.ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT)) {
            this.selectableEpreuves = this.$store.getters['epreuve/getEpreuveCorrectionByTypePassationAndConcourId'](TypePassation.TYPE_PASSATION_ORAL, this.ensemble.concour_id)
            this.ensemble.epreuvesTemp = []
            if (this.selectableEpreuves.length > 0) {
                let selectedEpreuve = null
                for (let i = 0; i < this.selectableEpreuves.length; i++) {
                    if (selectedEpreuve === null && this.selectableEpreuves[i].groupe_epreuve_id === null) {
                        selectedEpreuve = this.selectableEpreuves[i]
                    }
                }
                if (selectedEpreuve !== null) {
                    this.ensemble.epreuvesTemp.push({
                        id: selectedEpreuve.id,
                        libelle: selectedEpreuve.concours[0].name + '-' + selectedEpreuve.name,
                        matiere_id: selectedEpreuve.matiere_id,
                        code_concours: selectedEpreuve.concours[0].code
                    })
                }
            }
        }
        if (this.ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT) {
            this.ensemble.nb_estime = this.ensemble.epreuvesTemp.length
        }
    }

    /** ecoute l'evenememt changement de type ensemble */
    typeEnsembleChangeHandler(e: any) {
        this.ensemble.nb_estime = 0
        this.reconstruct = true
        this.pasDeGroupe = false
        if (this.selectableEpreuves && this.selectableEpreuves.length > 0) {
            for (let i = 0; i < this.selectableEpreuves.length; i++) {
                if (this.selectableEpreuves[i].groupe_epreuve_id === 0) {
                    this.selectableEpreuves[i].groupe_epreuve_id = null
                }
            }
        }
        if (this.ensemble && this.ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT && this.concoursGroupeEpreuves.length === 0) {
            this.$store.dispatch('epreuve/getConcoursGroupeEpreuves', { type_ensemble: TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT }).then((response: any) => {
                this.currentTypeEnsemble = e.target.value
                this.concoursGroupeEpreuves = response.data.data
                this.typeEnsembleChangeHandlerSuite()
            })
        } else {
            this.currentTypeEnsemble = e.target.value
            this.typeEnsembleChangeHandlerSuite()
        }
        this.reconstruct = false
    }

    typeEnsembleChangeHandlerSuite () {
        this.hidde_btn_ajout = false
        this.currentTypeEnsemble = this.ensemble.type_ensemble
        this.ensemble.concour_id = 0
        if (this.ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT) {
            this.$store.dispatch('epreuve/getEpreuvesCorrections', { type_passation: TypePassation.TYPE_PASSATION_ORAL }).then(() =>  {
                this.typeEnsembleChangeHandlerSuiteSuite()
            })
        } else {
            this.typeEnsembleChangeHandlerSuiteSuite()
        }
    }

    typeEnsembleChangeHandlerSuiteSuite () {
        if (this.currentTypeEnsemble === TypeEnsemble.TYPE_ENSEMBLE_TP) {
            this.selectableEpreuves = this.$store.getters['epreuve/getEpreuveCorrectionByTypePassation'](TypePassation.TYPE_PASSATION_TP)
            let selectedEpreuve = null
            for (let i = 0; i < this.selectableEpreuves.length; i++) {
                if (selectedEpreuve === null && this.epreuveIsSelectable(this.selectableEpreuves[i].id)) { // && this.selectableEpreuves[i].groupe_epreuve_id === null
                    selectedEpreuve = this.selectableEpreuves[i]
                }
            }
            this.ensemble.epreuvesTemp = []
            if (selectedEpreuve !== null) {
                this.ensemble.groupe_epreuve_id = selectedEpreuve.groupe_epreuve_id
                this.ensemble.concour_id = selectedEpreuve.concours[0].id
                this.ensemble.epreuvesTemp.push({
                    id: selectedEpreuve.id,
                    libelle: selectedEpreuve.concours[0].name + '-' + selectedEpreuve.name,
                    matiere_id: selectedEpreuve.matiere_id,
                    code_concours: selectedEpreuve.concours[0].code
                })
                this.ensemble.nb_estime = this.ensemble.epreuvesTemp.length
            }
        } else {
            this.selectableEpreuves = this.$store.getters['epreuve/getEpreuveCorrectionByTypePassation'](TypePassation.TYPE_PASSATION_ORAL)
            this.ensemble.epreuvesTemp = []
            let selectedEpreuve = null
            for (let i = 0; i < this.selectableEpreuves.length; i++) {
                if (selectedEpreuve === null && this.selectableEpreuves[i].groupe_epreuve_id === null) {
                    selectedEpreuve = this.selectableEpreuves[i]
                }
            }

            if (selectedEpreuve !== null) {
                this.ensemble.epreuvesTemp.push({
                    id: selectedEpreuve.id,
                    libelle: selectedEpreuve.concours[0].name + '-' + selectedEpreuve.name,
                    matiere_id: selectedEpreuve.matiere_id,
                    code_concours: selectedEpreuve.concours[0].code
                })
            }
        }
    }

    /** écoute les changements sur un select d'épreuve */
    epreuveSelectChangeHandler (e: any, key: any,  oldEpreuveId: any) {
        this.reconstruct = true
        e.preventDefault()
        let selectedEpreuve = null

        for (let i = 0; i < this.selectableEpreuves.length; i++) {
            if (parseInt(this.selectableEpreuves[i].id) === parseInt(e.target.value)) {
                if (this.ensemble && this.ensemble.type_ensemble !== TypeEnsemble.TYPE_ENSEMBLE_TP) {
                    this.selectableEpreuves[i].groupe_epreuve_id = this.ensemble.groupe_epreuve_id
                }
                selectedEpreuve = this.selectableEpreuves[i]
            } else if (this.selectableEpreuves[i].id === oldEpreuveId) {
                if (this.ensemble && this.ensemble.type_ensemble !== TypeEnsemble.TYPE_ENSEMBLE_TP) {
                    this.selectableEpreuves[i].groupe_epreuve_id = null
                }
            }
        }

        if (selectedEpreuve) {
            this.ensemble.epreuvesTemp[key] = {
                id: selectedEpreuve.id,
                libelle: selectedEpreuve.concours[0].name + '-' + selectedEpreuve.name,
                matiere_id: selectedEpreuve.matiere_id,
                code_concours: selectedEpreuve.concours[0].code
            }
        }
        if (this.ensemble && this.ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_TP) {
            this.ensemble.concour_id = selectedEpreuve.concours[0].id
            this.ensemble.groupe_epreuve_id = selectedEpreuve.groupe_epreuve_id
        }
        this.reconstruct = false

        /*
        for (let i = 0; i < this.selectableEpreuves.length; i++) {
            if (this.epreuveIsSelectable(this.selectableEpreuves[i].id) && this.selectableEpreuves[i].groupe_epreuve_id === null) {
                this.addEpreuve()
            }
        }
        */
    }

    getCodesConcours () {
        return [
            ...new Set(this.ensemble?.epreuvesTemp
                ?.map((epreuve: any) => epreuve.code_concours) || [])
        ]?.join(', ') || ''
    }

    /** test si une épreuve est selectionnable */
    epreuveIsSelectable (id: number) {
        if (this.ensemble && this.ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_TP) {
            for (let j = 0; j < this.selectableEpreuves.length; j++) {
                if (this.selectableEpreuves[j].id === id) {
                    for (let i = 0; i < this.$store.state.ensemble.ensembles.length; i++) {
                        if (this.$store.state.ensemble.ensembles[i].id !== this.ensemble.id &&
                            this.$store.state.ensemble.ensembles[i].groupe_epreuve_id === this.selectableEpreuves[j].groupe_epreuve_id) {
                            return false
                        }
                    }
                }
            }
            return true
        } else {
            for (let i = 0; i < this.ensemble.epreuvesTemp.length; i++) {
                if (this.ensemble.epreuvesTemp[i].id === id) {
                    return false
                }
            }
            // return  this.ensemble.epreuvesTemp.filter((e: any) => e.id === id).length === 0
            return true
        }
    }

    /* retourne la liste des epreuve de l'ensemble sous forme de chaine séparé par un retour à la ligne  **/
    getEpreuveByConcourId(id: number) {
        let retour = ''
        // 'getEpreuveByConcourId')
        for (let i = 0; i < this.$store.state.epreuve.epreuvesCorrections.length; i++) {
            if (this.$store.state.epreuve.epreuvesCorrections[i].concours[0].id === id && this.$store.state.epreuve.epreuvesCorrections[i].type_passation === TypePassation.TYPE_PASSATION_ORAL) {
                if (retour !== '') {
                    retour += '<br>'
                }
                retour += this.$store.state.epreuve.epreuvesCorrections[i].concours[0].name + '-' + this.$store.state.epreuve.epreuvesCorrections[i].name
            }
        }
        return retour
    }

    /** ajoute une épreuve à l'ensemble */
    addEpreuve () {
        this.reconstruct = true
        if (this.ensemble.epreuvesTemp.length === 1) {
            if (this.ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT) {
                this.selectableEpreuves = this.$store.getters['epreuve/getEpreuveCorrectionByTypePassationAndConcourId'](TypePassation.TYPE_PASSATION_ORAL,  this.ensemble.concour_id)
            } else {
                this.selectableEpreuves = this.$store.getters['epreuve/getEpreuveCorrectionByTypePassationAndMatiereId'](this.ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_TP ? TypePassation.TYPE_PASSATION_TP : TypePassation.TYPE_PASSATION_ORAL, this.ensemble.epreuvesTemp[0].matiere_id)
            }
        }
        let nextEpreuve = null
        this.ensemble.nb_estime = this.ensemble.epreuvesTemp.length
        for (let i = 0; i < this.selectableEpreuves.length; i++) {
            if (nextEpreuve === null && this.epreuveIsSelectable(this.selectableEpreuves[i].id) && this.selectableEpreuves[i].groupe_epreuve_id === null) {
                this.selectableEpreuves[i].groupe_epreuve_id = this.ensemble.groupe_epreuve_id
                nextEpreuve = this.selectableEpreuves[i]
            }
        }
        if (nextEpreuve) {
            this.ensemble.epreuvesTemp.push({
                id: nextEpreuve.id,
                libelle: nextEpreuve.concours[0].name + '-' + nextEpreuve.name,
                matiere_id: nextEpreuve.matiere_id,
                code_concours: nextEpreuve.concours[0].code
            })
            this.hidde_btn_ajout = false
        } else {
            this.hidde_btn_ajout = true
        }
        if (this.ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT || this.ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_PAR_EPREUVE) {
            this.ensemble.nb_estime = this.ensemble.epreuvesTemp.length
        }
        this.reconstruct = false
    }

    /** supprime une epreuve à un ensemble */
    removeEpreuve(epreuveId: number) {
        this.reconstruct = true
        if (this.ensemble.epreuvesTemp.length > 1) {
            const epreuvesTemp = []
            for (let i = 0; i < this.ensemble.epreuvesTemp.length; i++) {
                if (this.ensemble.epreuvesTemp[i].id !== epreuveId) {
                    epreuvesTemp.push(this.ensemble.epreuvesTemp[i])
                } else {
                    const epTemp = this.$store.state.epreuve.epreuvesCorrections.filter((e: any) => e.id === this.ensemble.epreuvesTemp[i].id)[0]
                    epTemp.groupe_epreuve_id = null
                }
            }
            this.ensemble.epreuvesTemp = epreuvesTemp
            if (this.ensemble.epreuvesTemp.length === 1) {
                if (this.ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT) {
                    this.selectableEpreuves = this.$store.getters['epreuve/getEpreuveCorrectionByTypePassationAndConcourId'](TypePassation.TYPE_PASSATION_ORAL,  this.ensemble.concour_id)
                } else if (this.ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_PAR_EPREUVE) {
                    this.selectableEpreuves = this.$store.getters['epreuve/getEpreuveCorrectionByTypePassationAndMatiereId'](TypePassation.TYPE_PASSATION_ORAL, this.ensemble.epreuvesTemp[0].matiere_id)
                } else {
                    this.selectableEpreuves = this.$store.state.epreuve.epreuvesCorrections
                    // this.selectableEpreuves = this.$store.getters['epreuve/getEpreuveCorrectionByTypePassationAndMatiereId'](this.ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_TP ? TypePassation.TYPE_PASSATION_TP : TypePassation.TYPE_PASSATION_ORAL, this.ensemble.epreuvesTemp[0].matiere_id)
                }
            }
        }
        this.reconstruct = false
        if (this.ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT  || this.ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_PAR_EPREUVE) {
            this.ensemble.nb_estime = this.ensemble.epreuvesTemp.length
        }
    }

    /** recupere la liste des epreuves d'un ensemble dans un tableau simplifié */
    getEpreuves () {
        this.ensemble.epreuvesTemp = this.getEpreuve(this.ensemble, 'array')
    }

    /** écoute les changements du nombre d'examinateurs etimé de l'ensemble  */
    nbEstimeChangeHandler(e: any) {
        const min = this.getNbMinExaminateurs()
        if (e.target.value === '') {
            e.target.value = min
        }
        if (e.target.value <  min) {
            e.target.value = min
        }
        if (e.target.value > 99) {
            e.target.value = 99
        } else if (e.target.value === '0' || e.target.value === 0) {
            e.target.value = min
        }
        this.ensemble.nb_estime = e.target.value
    }

    getNbMinExaminateurs () {
        return 1
        /*
        let nbminExaminateurs = 0
        if (this.epreuvesExaminateurs && this.epreuvesExaminateurs.length > 0) {
            for (let i = 0; i < this.epreuvesExaminateurs.length; i++) {
                if (this.epreuvesExaminateurs[i].user !== null) {
                    nbminExaminateurs++
                }
            }
        }
        if (nbminExaminateurs > 0) {
            return nbminExaminateurs
        } else return 1
        */
    }

    /** verifie que la valeur name de l'ensemble est unique */
    checkEnsembleNameIsUnique () {
        this.ensembleNameNotUnique = this.$store.getters['ensemble/ensembleNameIsNotUnique'](this.ensemble.id, this.ensemble.name)
    }

    /** construit un tableau listant les epreuves/examinateurs de l'ensemble */
    buildEpreuvesExaminateur () {
        this.epreuvesExaminateurs = []
        if (this.ensemble.id !== 0) {
            let serie_id = 0
            if (this.ensemble && this.ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT) {
                for (let i = 0; i < this.ensemble.affectation_examinateurs.length; i++) {
                    if (serie_id === 0) {
                        serie_id = this.ensemble.affectation_examinateurs[i].serie_id
                    }
                    if (serie_id === this.ensemble.affectation_examinateurs[i].serie_id) {
                        for (let j = 0; j < this.ensemble.groupeEpreuve.epreuves.length; j++) {
                            if (this.ensemble.groupeEpreuve.epreuves[j].id === this.ensemble.affectation_examinateurs[i].epreuve_correction_id) {
                                const concour = this.$store.getters['concour/concourById'](this.ensemble.concour_id)

                                this.epreuvesExaminateurs.push({
                                    user: { id: this.ensemble.affectation_examinateurs[i].user_id, name: this.ensemble.affectation_examinateurs[i].user ? this.ensemble.affectation_examinateurs[i].user.first_name + ' ' + this.ensemble.affectation_examinateurs[i].user.name : '' },
                                    epreuveCorrection: { id: this.ensemble.groupeEpreuve.epreuves[j].id, name: (concour ? concour.code : '-') + '-' + this.ensemble.groupeEpreuve.epreuves[j].name }
                                })
                            }
                        }
                    }
                }
            } else {
                for (let i = 0; i < this.ensemble.affectation_examinateurs.length; i++) {
                    if (serie_id === 0) {
                        serie_id = this.ensemble.affectation_examinateurs[i].serie_id
                    }
                    if (serie_id === this.ensemble.affectation_examinateurs[i].serie_id) {
                        this.epreuvesExaminateurs.push({
                            user: { id: this.ensemble.affectation_examinateurs[i].user_id, name: this.ensemble.affectation_examinateurs[i].user ? this.ensemble.affectation_examinateurs[i].user.first_name + ' ' + this.ensemble.affectation_examinateurs[i].user.name : '' }
                        })
                    }
                }
            }
        }
    }

    /**
     * Retourne l'index examinateur selon le type de passation de l'épreuve
     * L'index 1 correspond au Coordonateur dans le cas d'une équipe d'épreuve de TP
     */
    get_index_examinateur (ensemble: any, index: any) {
        if (ensemble && ensemble.id !== 0 && ensemble.type_ensemble !== TypeEnsemble.TYPE_ENSEMBLE_PAR_EPREUVE) {
            const epreuve = this.getEpreuve(this.ensemble, 'array')
            if (epreuve[0].type_passation === TypePassation.TYPE_PASSATION_TP && index === 1) {
                // Coordonateur de TP
                return 'C'
            } else {
                return index
            }
        } else {
            return index
        }
    }

    load() {
        if (this.ensemble) {
            this.currentTypeEnsemble = this.ensemble.type_ensemble

            if (this.ensemble.id === 0) {
                this.$store.dispatch('epreuve/getEpreuvesCorrections', { type_passation: TypePassation.TYPE_PASSATION_ORAL })
            } else if (this.ensemble && this.ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_PAR_EPREUVE) {
                this.$store.dispatch('epreuve/getEpreuvesCorrections', { type_passation: TypePassation.TYPE_PASSATION_ORAL }).then(() => {
                    if (this.ensemble.groupeEpreuve && this.ensemble.groupeEpreuve.epreuves && this.ensemble.groupeEpreuve.epreuves.length > 0) {
                        this.selectableEpreuves = this.$store.getters['epreuve/getEpreuveCorrectionByTypePassationAndMatiereId'](TypePassation.TYPE_PASSATION_ORAL, this.ensemble.epreuvesTemp[0].matiere_id)
                    } else {
                        this.selectableEpreuves = this.$store.state.epreuve.epreuvesCorrections
                    }
                })
                this.getEpreuves()
            }
            this.options_type_ensemble = []
            for (const te in TypeEnsemble) {
                if (!isNaN(parseInt(te))) {
                    this.options_type_ensemble.push({ index: parseInt(te), name: getTypeEnsembleSpec(parseInt(te)).libelle })
                }
            }
            const filieres = this.$store.getters['concour/banques']
            this.options_filieres = []
            for (const f in filieres) {
                this.options_filieres.push({ index: filieres[f].id, name: filieres[f].name })
            }
            this.buildEpreuvesExaminateur()

            if (this.$store.state.session.sessionSelect) {
                this.ensembles_validated_at = this.$store.state.session.sessionSelect.ensembles_validated_at
            } else {
                this.$store.dispatch('session/getSession', { session_id: this.$store.state.auth.user_session_id }).then(() => {
                    this.ensembles_validated_at = this.$store.state.session.sessionSelect.ensembles_validated_at
                })
            }
        }
    }

    mounted () {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
