


























































































































































import { Vue, Component } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import Table from '@/components/Table.vue'
import { Ability } from '@/types/Ability'
import { codeRestrict } from '@/utils/helpers'

import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'


@Component({
    components: {
        Table,
        ExaGenericTable
    },
    computed: {
        ...mapGetters('ajustement', ['ajustements', 'meta', 'links', 'totalRows']),
        ...mapGetters('configPhase', ['loading', 'error', 'configPhases', 'meta', 'links', 'totalRows']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})
export default class ConfigurationPhases extends Vue {
    codeRestrict = codeRestrict
    params = 'sort=name&direction=asc'
    dataForTab: Array<any> = []
    filtres: any = []
    keyAjustFirst = ''
    genericfields = [
        { key: 'etatEdit', label: '', sortable: false, class: '', type: 'action' },
        { key: 'name', label: 'Libellé', sortable: true, sortDirection: 'asc', class: 'text-start', type: 'text' },
        { key: 'nb_phases', label: 'Nombre de phase', sortable: true, sortDirection: 'asc', class: 'text-center', type: 'text' },
        { key: 'delete', label: '', sortable: false, class: '', type: 'action' }
    ]

    sortBy = ''
    sortDesc = false
    sortDirection = 'asc'
    filter = ''
    filterOn = []
    stickyHeader = true
    configPhase: any = null
    configPhaseTemp: any = null
    Ability = Ability

    showModalEditionConfigPhase = false
    showModalMessageDelete = false
    idPhaseTemp =  0

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setDataForGenericTab(poData: any, isLoadMore = false) {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const result of poData) {
                const classEtatEdit = 'text-light col-w-etat btn_action_ligne'
                const iconEtatEdit =  this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE) ? 'pen' : 'eye'

                const line = [
                    { label: '', item: result, icon: iconEtatEdit, type: 'action', typeAction: 'etatEdit', class: classEtatEdit, disabled: false },
                    { label: '', item: result.name, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.nb_phases, type: 'text', typeAction: null, class: 'text-center' }
                ]

                if (this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE)) {
                    const trashLine =  { label: 'Supprimer',   item: result, type: 'action',  typeAction: 'delete',  class: 'text-danger text-end', icon:'trash-alt', disabled: false }
                    line.push(trashLine)
                }
                this.dataForTab.push(line)
            }
        }
        return this.dataForTab
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setFiltersForGenericTab() {
        this.filtres = [
            {
                libelle: 'Code',
                defautOptionlibelle: 'Rechercher un',
                model: 'code',
                value: '',
                index: 'code',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'code', strict: true } // 'form' , 'deroulant'
            },
            {
                libelle: 'Libellé',
                defautOptionlibelle: 'Rechercher un',
                model: 'name',
                value: '',
                index: 'name',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'name' } // 'form' , 'deroulant'
            }
        ]
    }

    /**
     * Récupération des events de la table
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break

                case 'etatEdit':
                    this.editConfigPhase(paParams[1])
                    break
                case 'delete':
                    this.deleteConfigPhase(paParams[1])
                    break
                default:
                    break
            }
        }
    }

    // Ajoute une phase
    addPhase () {
        this.idPhaseTemp++
        const ajustements : { [key: string]: any } = {}
        const newPhase: any = {
            config_phase_id: this.configPhaseTemp.id,
            id_phase_suivante: null,
            name :'',
            id: this.idPhaseTemp
        }
        if (this.configPhaseTemp.phases === null) {
            this.configPhaseTemp.phases = []
        }

        ajustements['adjust_1'] = {
            'adjuster': 'arrondi',
            'target': 'global',
            'name': 'Arrondi'
        }
        newPhase.ajustements = ajustements
        this.configPhaseTemp.phases.push(newPhase)
    }

    // Supprimer une phase
    deletePhase (phase: any) {
        const phasesTemp = []
        for (let i = 0; i < this.configPhaseTemp.phases.length; i++) {
            if (phase.id !== this.configPhaseTemp.phases[i].id) {
                phasesTemp.push(this.configPhaseTemp.phases[i])
            }
        }
        this.configPhaseTemp.phases = phasesTemp
    }

    // Edite une phase
    updatePhase(phase: any) {
        for (let i = 0; i < this.configPhaseTemp.phases.length; i++) {
            if (this.configPhaseTemp.phases[i].id === phase.id) {
                this.configPhaseTemp.phases[i] = phase
            }
        }
    }

    // Maj le name sur un rajout d'ajustement
    ajustementChangeHandler(event: any, phase: any, keyAjust: any) {
        const ajustement_select = phase.ajustements[keyAjust]
        const ref_ajustement = this.$store.state.ajustement.ajustements.find((a: any) => a.code === ajustement_select.adjuster)

        ajustement_select.name =  ref_ajustement.name
    }

    // Ajoute un ajustement
    addAjustement(phase: any) {
        const ajustementsTemp = phase.ajustements
        for (const a in ajustementsTemp) {
            if (ajustementsTemp[a].adjuster === 'arrondi') {
                this.$delete(ajustementsTemp, a)
            }
        }

        ajustementsTemp['adjust_' + (Object.keys(phase.ajustements).length+1)] = {
            'adjuster': null,
            'target': null,
            'name': ''
        }

        phase.ajustements = ajustementsTemp
        phase.ajustements['adjust_' + (Object.keys(phase.ajustements).length+1)] = {
            'adjuster': 'arrondi',
            'target': 'global',
            'name': 'Arrondi'
        }
    }

    // Supprime un ajustement
    deleteAjustement(phase: any, index: any) {
        for (const a in phase.ajustements) {
            if (phase.ajustements[a].adjuster === 'arrondi') {
                this.$delete(phase.ajustements, a)
            }
        }

        this.$delete(phase.ajustements, index)

        phase.ajustements['adjust_' + (Object.keys(phase.ajustements).length+1)] = {
            'adjuster': 'arrondi',
            'target': 'global',
            'name': 'Arrondi'
        }
        this.updatePhase(phase)
    }

    // Ouvre la popup d'ajout d'une configuration de phase
    openAddConfigPhase () {
        this.configPhaseTemp = {
            id: 0,
            name: '',
            nb_phases: 0,
            phases: []
        }
        this.showModalEditionConfigPhase = true
    }

    // Edite une configuration de phase
    editConfigPhase (data: any) {
        this.configPhase = data
        this.configPhaseTemp = JSON.parse(JSON.stringify(data))
        this.showModalEditionConfigPhase = true
    }

    // Annule l'édition
    cancelEdit () {
        this.configPhase = null
        this.configPhaseTemp = null
        this.showModalEditionConfigPhase = false
    }

    // Sauvegarde la config phase
    save () {
        const payload = {
            id: this.configPhaseTemp.id,
            name: this.configPhaseTemp.name,
            nb_phases: this.configPhaseTemp.nb_phases,
            phases: JSON.parse(JSON.stringify(this.configPhaseTemp.phases))
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        if (this.configPhaseTemp.id === 0) {
            this.$store.dispatch('configPhase/addConfigPhase', payload)
                .then(() => {
                    this.configPhase = null
                    this.configPhaseTemp = null
                    this.showModalEditionConfigPhase = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                    this.$store.dispatch('configPhase/getConfigPhases', this.params)
                        .then(() => {
                            this.setDataForGenericTab(this.$store.state.configPhase.configPhases)
                        }).catch((error) => {
                            console.log('ko:' + error)
                        })
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            this.$store.dispatch('configPhase/updateConfigPhase', payload)
                .then(() => {
                    this.configPhase = null
                    this.configPhaseTemp = null
                    this.showModalEditionConfigPhase = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                    this.$store.dispatch('configPhase/getConfigPhases', this.params)
                        .then(() => {
                            this.setDataForGenericTab(this.$store.state.configPhase.configPhases)
                        }).catch((error) => {
                            console.log('ko:' + error)
                        })
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    deleteConfigPhase (data: any) {
        this.configPhase = data
        this.showModalMessageDelete = true
    }

    cancelDelete () {
        this.configPhase = null
        this.showModalMessageDelete = false
    }

    deleteSuite () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Supression en cours ...', infosToaster)
        this.$store.dispatch('configPhase/deleteConfigPhase', this.configPhase.id)
            .then(() => {
                this.configPhase = null
                this.configPhaseTemp = null
                this.showModalEditionConfigPhase = false
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Suppression terminée.', succesToaster)
                this.configPhase = null
                this.showModalMessageDelete = false
                this.$store.dispatch('configPhase/getConfigPhases', this.params)
                    .then(() => {
                        this.setDataForGenericTab(this.$store.state.configPhase.configPhases)
                    }).catch((error) => {
                        console.log('ko:' + error)
                    })
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    loadHandler (params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.$store.dispatch('configPhase/getMoreConfigPhases', params).then(() => {
                this.setDataForGenericTab(this.$store.state.configPhase.configPhases)
            }).catch((error) => {
                console.log('ko:' + error)
            })
        }
    }

    filtreSortHandler (params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.$store.dispatch('configPhase/getConfigPhases', params)
                .then(() => {
                    this.setDataForGenericTab(this.$store.state.configPhase.configPhases)
                }).catch((error) => {
                    console.log('ko:' + error)
                })
        }
    }

    mounted () {
        this.$store.dispatch('ajustement/getAjustements').then(() => {
            this.setFiltersForGenericTab()
        })
    }
}
