











































































































































































































import { mapActions, mapGetters } from 'vuex'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { isObject } from '@/utils/helpers'
import { Ability } from '@/types/Ability'
import _ from 'lodash'
import  ErrorDisplay from '@/components/ErrorDisplay.vue'
import { TypeReclamation } from '@/types/Reclamation'

@Component({
    components: {
        ErrorDisplay
    },
    computed: {
        ...mapGetters('reclamation', ['loading', 'reclamationSelect', 'error', 'getNameEpreuveById']),
        ...mapGetters('candidatIncompatible', ['resultatRechercheParCandidat', 'get_meta_recherche_Candidat']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        isFileType(): boolean {
            return this.$store.getters['auth/findParameter']('reclamationMessage')?.value === 'file'
        }
    },
    methods: {
        ...mapActions('postes', ['getAllPostes'])
    }
})

export default class PopupCreerReclamation extends Vue {
    @Prop() show?: boolean;
    @Prop() infosFiliereEpreuveSelect?: any;
    @Prop() type_reclamation?: string

    Ability                                 = Ability
    lockInput                               = false
    etape_selection_candidat                = true
    etape_renseigner_reclamation            = false
    etape_ReclamationExistante              = false
    mode_Rouverture                         = false
    mode_UneSeuleReclamation                = false

    candidatTarget = {
        code: '',
        name: ''
    }

    options_filieres: any               = []
    options_epreuves: any               = []
    resultat: any                       = []
    meta: any                           = []
    loadSearch                          = false
    firstSearch                         = false

    // Dans le cas de la recherche pour les réclamations et démissions, la recherche est basée sur la filière et non la classe, et pas de user_id associé.
    recherche  = {
        user_id: -1,
        name: '',
        first_name: '',
        etablissement_id: '*',
        classe: '*',
        filiere: '*',
        code: ''
    }

    SelectedCandidat: any                   = {} // candidat sélectionné
    messageCandidat                         = '' // message du candidat sélectionné
    epreuveCandidat_Id                      = null // id de l'épreuve du candidat sélectionné

    reclamationSelectLocal: any             = {}
    etat_reclamation_select: any            = {}
    activerRectifiationNote                 = false
    fileCandidat: any                               = null


    @Watch('show')
    initInterface() {
        if (this.show === true) {
            this.$store.commit('reclamation/SET_ERROR', null)
            this.initfilieresMatieres()
        }
    }

    @Watch('resultatRechercheParCandidat')
    watchResultatRechercheParCandidat() {
        this.resultat = this.$store.getters['candidatIncompatible/resultatRechercheParCandidat']
        this.meta     = this.$store.getters['candidatIncompatible/get_meta_recherche_Candidat']
    }

    getFiliereName(concours_id: number) {
        const filiere: any = this.options_filieres.filter((c: any) => c.index === concours_id)
        let nomConcours: any = '-'
        if (filiere && filiere[0]) {
            nomConcours = filiere[0].name
        }

        return nomConcours
    }

    select_epreuve_id(e: any) {
        this.epreuveCandidat_Id = e.target.value
    }

    initfilieresMatieres() {
        // Options filières
        const filieres = this.$store.getters['concour/banques']
        this.options_filieres = []

        for (const f in filieres) {
            this.options_filieres.push({ index: filieres[f].id, name: filieres[f].name })
        }
    }

    isObjectLocal(value: any) {
        return isObject(value)
    }

    /** Retourne la liste passée en paramètre ordonnée par name */
    orderedList(liste: any) {
        return _.orderBy(liste, 'name', 'asc')
    }

    /** fonction de declenchement de la recherche. reinit resultat */
    launchSearch() {
        this.loadSearch = true
        this.resultat = []

        /* *** Note *************************************************************************************************
            On utilise ici la base de la requête de recherche qui avait été mise en place pour les incompatibilités.
            Pour un souci de gain de temps, et parce que le retour de la requête fournit directement l'objet établissement du candidat
            dont on a besoin ici.
        ********************************************************************************************************** */
        this.$store.dispatch('candidatIncompatible/RECHERCHE_PAR_CANDIDAT', this.recherche).then(() => {
            this.loadSearch = false
            this.firstSearch = true
        })
    }

    getEtablissementCandidat(etablissement_id: number) {
        const params: any = {
            etablissement_id: etablissement_id
        }
        this.$store.dispatch('etablissement/getEtablissement', params).then((resEtab) => {
            return resEtab.data.data
        })
    }

    /** Sauvegarde de la selection de l'utilisateur  */
    setSelectedCandidat(candidat: any) {
        this.SelectedCandidat = candidat
    }

    /** on confirme le candidat sélectionné pour passer à l'étape suivante de renseignement de la réclamation  */
    confirmSelectedCandidat() {
        const params = { id: this.SelectedCandidat.id }
        this.$store.dispatch('candidat/getCandidat', params).then((response) => {
            // Init de la combo des épreuves liées aux inscriptions du candidat
            // Filtre les épreuves maîtresses et sur la phase admissibilité
            if (this.$props.type_reclamation === TypeReclamation.TYPE_NOTE_ECRIT) {
                this.options_epreuves = this.$store.getters['epreuve/getEpreuvePhase'](response.data.data.inscriptions)
            } else if (this.$props.type_reclamation === TypeReclamation.TYPE_PASSATION_ORAL) {
                this.options_epreuves = this.$store.getters['epreuve/getEpreuvePhase_ORAL'](response.data.data.inscriptions)
            }
            this.etape_selection_candidat = false
            this.etape_renseigner_reclamation = true
            this.etape_ReclamationExistante = false
        })
    }

    /**
     * Ferme la popup avec réinit des variables locales sensibles.
     */
    closeModal() {
        this.$store.commit('reclamation/SET_ERROR', null)
        this.etape_selection_candidat = true
        this.etape_renseigner_reclamation = false
        this.etape_ReclamationExistante = false
        this.epreuveCandidat_Id = null
        this.messageCandidat = ''
        this.fileCandidat = null

        this.$store.commit('candidatIncompatible/RESET_SEARCH')
        this.SelectedCandidat = {}
        this.firstSearch = false
        this.recherche = {
            user_id: 0,
            name: '',
            first_name: '',
            etablissement_id: '*',
            classe: '*',
            filiere: '*',
            code: ''
        }
        this.$emit('reinitShow', null)
    }

    /** Annule la confirmation de création de réclamation existante et revient à l'étape de sélection de l'épreuve */
    annulerConfirmationReclamation() {
        this.etape_selection_candidat = false
        this.etape_renseigner_reclamation = true
        this.etape_ReclamationExistante = false
    }

    /**  */
    manageSaveReclamation() {
        if (this.$props.type_reclamation === TypeReclamation.TYPE_NOTE_ECRIT) { // réclamation écrit
            // on vérifie si une réclamation de même candidat_id existe déjà

            /* Pour plus tard
            const reclamationMatch = this.$store.state.reclamation.reclamations_ecrit.filter((r: any) => (parseInt(r.candidat_id) === parseInt(this.SelectedCandidat.id) && parseInt(r.epreuve_id) === parseInt(String(this.epreuveCandidat_Id))))
            */
            const reclamationMatch = this.$store.state.reclamation.reclamations_ecrit.filter((r: any) => (parseInt(r.candidat_id) === parseInt(this.SelectedCandidat.id)))

            if (reclamationMatch.length > 0) {
                /* Pour plus tard
                this.mode_Rouverture = reclamationMatch[0].validated_at // si la réclamation a déjà été validée, on doit la rouvrir, sinon on informe juste l'utilisateur.
                */
                this.mode_Rouverture = false
                this.mode_UneSeuleReclamation = true

                // Ouverture de la div de réclamation existante
                this.etape_selection_candidat = false
                this.etape_renseigner_reclamation = false
                this.etape_ReclamationExistante = true
            } else {
            // pas de réclamation déjà existante pour ce contexte, on peut lancer l'enregistrement
                this.saveReclamation()
            }
        } else if (this.$props.type_reclamation === TypeReclamation.TYPE_PASSATION_ORAL) { // réclamation oral
            // on vérifie si une réclamation de même candidat_id et epreuve_id existe déjà
            const reclamationMatch = this.$store.state.reclamationOral.reclamations_oral.filter((r: any) => (parseInt(r.candidat_id) === parseInt(this.SelectedCandidat.id) && parseInt(r.epreuve_id) === parseInt(String(this.epreuveCandidat_Id))))

            if (reclamationMatch.length > 0) {
                this.mode_Rouverture = false
                this.mode_UneSeuleReclamation = false

                // Ouverture de la div de réclamation existante
                this.etape_selection_candidat = false
                this.etape_renseigner_reclamation = false
                this.etape_ReclamationExistante = true
            } else {
            // pas de réclamation déjà existante pour ce contexte, on peut lancer l'enregistrement
                this.saveReclamation()
            }
        } else if (this.$props.type_reclamation === TypeReclamation.TYPE_NOTE_ORAL) { // réclamation post-concours
            // on vérifie si une réclamation de même candidat_id existe déjà
            const reclamationMatch = this.$store.state.reclamationPostConcours.reclamations_post_concours.filter((r: any) => (parseInt(r.candidat_id) === parseInt(this.SelectedCandidat.id)))

            if (reclamationMatch.length > 0) {
                this.mode_Rouverture = false
                this.mode_UneSeuleReclamation = true

                // Ouverture de la div de réclamation existante
                this.etape_selection_candidat = false
                this.etape_renseigner_reclamation = false
                this.etape_ReclamationExistante = true
            } else {
            // pas de réclamation déjà existante pour ce contexte, on peut lancer l'enregistrement
                this.saveReclamation()
            }
        }
    }

    /**
     * Confirmation de la validation/invalidation des affectations
     */
    saveReclamation() {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        const payload = new FormData()
        payload.set('candidat_id', this.SelectedCandidat.id)
        payload.set('epreuve_id', this.epreuveCandidat_Id || '')
        payload.set('type', this.$props.type_reclamation)

        if (this.$store.getters['auth/findParameter']('reclamationMessage')?.value === 'file') {
            payload.set('file', this.fileCandidat)
        } else {
            payload.set('message_candidat', this.messageCandidat)
        }

        this.$bvToast.toast('Enregistrement en cours...', infosToaster)
        this.$store.dispatch('reclamation/addReclamation', payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé', succesToaster)
                this.closeModal()
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Récupération du fichier à envoyer
     * @param {Event} event - Événement de changement de fichier
     * @returns {void}
     */
    fileChange(event: Event): void {
        if (event.target !== null) {
            const target = event.target as HTMLInputElement
            this.fileCandidat = (target.files as FileList)[0]
        }
    }

    /**  */
    rouvrirReclamation() {
        // TODO - appel pour rouvrir la réclamation en question lors que le back sera à jour sur ce point.
    }

    /**
     * Retourne le name d'une épreuve depuis un getter
     */
    get_name_epreuve(epreuve_id: number) {
        return this.$store.getters['epreuve/getNameEpreuveById'](epreuve_id)
    }
}

