







































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Decision, DecisionIntervenant, Etat, getDecisionSpec, getEtatSpec } from '@/types/DossierAcademique'
import { EtatDepot, getEtatSpecContrat } from '@/types/DossierAdministratif'
import { getIncompSpec } from '@/store/modules/CandidatIncompatible'
import {
    getEtatAnnexeConventionFromRowItem,
    getEtatConvention,
    getEtatConventionFromRowItem,
    getEtatIntervenant
} from '@/types/Centre'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { formatDate } from '@/utils/helpers'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import { getTypeAmenagement, TypeAmenagement } from '@/types/Salle'
import { BButton, BTable } from 'bootstrap-vue'
import { getTypePassationSpec } from '@/types/Epreuve'

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapState('session', ['sessionActive', 'sessionSelect']),
        ...mapGetters('auth', ['is_intervenant_centre'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        'b-table': BTable,
        'b-button': BButton
    }
})
export default class Table extends Vue {
    @Prop() fields?: Array<any>
    @Prop() datas?: Array<any>
    @Prop() totalPage?: number
    @Prop() totalRows?: number
    @Prop() lastPage?: number
    @Prop() loading?: boolean
    @Prop() sortDefaut?: string
    @Prop() filtres?: Array<any>
    @Prop() actionsReadOnly?: boolean
    @Prop() mode?: string
    @Prop() collectionDynamique?: any
    @Prop() action?: Function
    @Prop() edit_session?: Function
    @Prop() besoinHandler?: Function
    @Prop() matiere_id?: number
    @Prop() besoins?: any
    @Prop() pvSelect?: any
    @Prop() defautOptionLabel?: string
    @Prop() orga_ecrit?: boolean

    Ability = Ability
    getTypePassationSpec = getTypePassationSpec

    // Tri sur les colonnes : Appelle une liste triée au serveur
    @Watch('sortBy')
    sortByChanged(value: string) {
        this.sortBy = value
        this.sortDesc = false
        this.sortDescChanged(false)
    }

    // @Watch('filtres', { deep: true })
    @Watch('filtres', { deep: true, immediate: true })
    filtresHandler() {
        this.page = 1
        const testParam = this.getParams()
        if (
            JSON.stringify(this.tabParams) === '{}' &&
            JSON.stringify(testParam) === '{"page":1,"sort":"","direction":"asc"}'
        ) {
            return
        }
        this.tabParams = this.getParams() // { page: 1, sortby: 'sort=' + this.sortBy + '&direction=' + this.sortDirection + filtresStr }

        if (document.querySelectorAll('.show')) {
            const query = document.querySelectorAll('.ddFiltre')
            query.forEach((el) => el.classList.remove('show'))
        }
        this.$emit('filtresHandler', this.tabParams)
    }

    @Watch('sortDesc')
    sortDescChanged(value: boolean) {
        this.sortDirection = value ? 'desc' : 'asc'
        // Si on a une colonne et un sens sélectionné, on appelle une liste triée
        if (this.sortBy !== '' && this.sortDirection !== '') {
            this.page = 1
            this.tabParams = this.getParams()
            this.$emit('sortPage', this.tabParams)
            const btable = this.$refs.b_table as Vue
            if (typeof btable.$el.scrollTo === 'function') {
                btable.$el.scrollTo(0, 0)
            }
        }
    }

    @Watch('fields')
    majColonne() {
        if (this.collectionDynamique && this.collectionDynamique.datas !== undefined) {
            this.createColonnes()
        }
    }

    @Watch('datas')
    backToFirstPage() {
        if (this.datas && this.currentDatasLength > this.datas.length) {
            this.currentDatasLength = this.datas.length
            this.page = 1
            const btable = this.$refs.b_table as Vue
            if (typeof btable.$el.scrollTo === 'function') {
                btable.$el.scrollTo(0, 0)
            }
        } else {
            this.currentDatasLength = this.datas ? this.datas.length : 0
        }
    }

    getTypeAmenagementloc = getTypeAmenagement
    TypeAmenagementloc = TypeAmenagement

    sortBy = this.sortDefaut ? this.sortDefaut : ''
    sortDesc = false
    sortDirection = 'asc'
    filter = ''
    filterOn = []
    stickyHeader = true
    page = 1
    windowTop = 0
    maxScroll = 0
    tabParams = {}
    // timeOut = 0;
    etat_invisible = Etat.ETAT_INVISIBLE
    etat_depose = Etat.ETAT_DEPOSE
    etat_valide = Etat.ETAT_VALIDE
    etat_rejete = Etat.ETAT_REJETE
    decision_aucune = Decision.DECISION_AUCUNE
    decision_retenu = Decision.DECISION_RETENU
    decision_ajourne = Decision.DECISION_AJOURNE

    decision_intervenant_masque = DecisionIntervenant.DECISION_INTERVENANT_MASQUE
    decision_intervenant_attente = DecisionIntervenant.DECISION_INTERVENANT_ATTENTE
    decision_intervenant_oui = DecisionIntervenant.DECISION_INTERVENANT_OUI
    decision_intervenant_non = DecisionIntervenant.DECISION_INTERVENANT_NON

    getEtatSpec = getEtatSpec
    getIncompSpec = getIncompSpec

    getEtatSpecContrat = getEtatSpecContrat
    getEtatIntervenant = getEtatIntervenant
    getEtatConvention = getEtatConvention
    getEtatConventionFromRowItem = getEtatConventionFromRowItem
    getEtatAnnexeConventionFromRowItem = getEtatAnnexeConventionFromRowItem
    docs: Array<any> = []
    concours: Array<any> = []
    currentDatasLength = 0
    EtatDepot = EtatDepot

    filterTags: Array<any> = []

    series: Array<any> = []
    jours: Array<any> = []

    // Methods

    getFiltersColumns(filters: any[], data: { field: { key: string } }): any[] {
        const pushFilter: any[] = []
        if (filters && data) {
            filters.forEach((filtre: { options: any; libelle: any }) => {
                if (filtre.options !== undefined && filtre.options.fieldsKey === data.field.key) {
                    pushFilter.push(filtre)
                }
            })
        }
        return pushFilter
    }

    emit_delete(row: any) {
        this.$emit('delete', row)
    }

    emit_dissociate(row: any) {
        this.$emit('dissociate', row)
    }

    emit_edit_session(row: any) {
        this.$emit('edit_session', row)
    }

    emit_enter_session(row: any) {
        this.$emit('enter_session', row)
    }

    emit_etat_edit(row: any) {
        this.$emit('etat_edit', row)
    }

    emit_candidat_edit(row: any) {
        this.$emit('candidat_edit', row)
    }

    /**
     * edition mesure handicap (amenagement)
     **/
    emit_amenagement_edit(row: any) {
        this.$emit('amenagement_edit', row)
    }

    // Emit sur click colonne etat
    actionEtat(row: any) {
        this.$emit('action_etat', row)
    }

    actionLeft(row: any) {
        this.$emit('action_left', row)
    }

    emit_action(row: any) {
        this.$emit('action', row)
    }

    /**
     * checkbox handler accessibilite template mesure handicap (amenagement)
     **/
    emit_amenagement_accessibilite_change(row: any) {
        row.item.accessibilite = row.item.accessibilite === true || row.item.accessibilite === 1 ? 1 : 0
        this.$emit('amenagement_accessibilite_change', row)
    }

    /**
     * checkbox handler temps template mesure handicap (amenagement)
     **/
    emit_amenagement_temps_change(row: any) {
        row.item.temps = row.item.temps === true || row.item.temps === 1 ? 1 : 0
        this.$emit('amenagement_temps_change', row)
    }

    /*  Utilisation de getParams pour push dans le filterTags */
    getParams() {
        this.filterTags = []
        const params: any = {}
        if (this.filtres && this.filtres.length > 0) {
            // console.log('in')
            for (let i = 0; i < this.filtres.length; i++) {
                // console.log('in')
                if (this.filtres[i].value !== '' && this.filtres[i].value !== '-') {
                    // params['filter-' + this.filtres[i].model] = this.filtres[i].value
                    params['filter-' + this.filtres[i].model] =
                        (this.filtres[i].options.type === 'form' && this.filtres[i].options.strict !== true
                            ? '%'
                            : '') + this.filtres[i].value
                    if (this.filtres[i].options.type === 'form') {
                        this.filterTags.push({
                            libelle: this.filtres[i].libelle,
                            value: this.filtres[i].value,
                            options: this.filtres[i].options,
                            model: this.filtres[i].model
                        })
                    } else if (this.filtres[i].options.type === 'deroulant') {
                        let val = ''
                        for (let j = 0; j < this.filtres[i].datas.length; j++) {
                            let filterParsedValue = Number(this.filtres[i].value)
                            if (isNaN(filterParsedValue)) {
                                filterParsedValue = this.filtres[i].value
                            }
                            if (filterParsedValue === this.filtres[i].datas[j][this.filtres[i].index]) {
                                val = this.filtres[i].datas[j].name
                            }
                        }
                        this.filterTags.push({
                            libelle: this.filtres[i].libelle,
                            etat: this.filtres[i].value,
                            value: val,
                            options: this.filtres[i].options,
                            model: this.filtres[i].model
                        })
                    }
                }
            }
        }
        params.page = this.page
        params.sort = this.sortBy
        params.direction = this.sortDirection
        return params
    }

    clearFilters(tag: any | undefined) {
        /* premier if est utilisé pour le button 'effacer les filtres' */
        /* Check si le filtre est deroulant ou text */
        if (this.filtres !== undefined && !tag) {
            for (let index = 0; index < this.filtres.length; index++) {
                if (this.filtres[index].options !== undefined && this.filtres[index].options.type === 'form') {
                    this.filtres[index].value = ''
                } else {
                    this.filtres[index].value = '-'
                }
            }
        }
        if (this.filtres !== undefined && tag !== undefined) {
            for (let index = 0; index < this.filtres.length; index++) {
                if (this.filtres[index].model === tag.model) {
                    if (this.filtres[index].options.type === 'deroulant' && this.filtres[index].value === tag.etat) {
                        this.filtres[index].value = '-'
                    }
                    if (this.filtres[index].options.type === 'form' && this.filtres[index].value === tag.value) {
                        this.filtres[index].value = ''
                    }
                }
            }
        }
        this.filtresHandler()
    }

    // get params filtres
    /* old
  getParams () {
    const params: any = {}
    if (this.filtres && this.filtres.length > 0) {
      for (let i = 0; i < this.filtres.length; i++) {
        if (this.filtres[i].value !== '' && this.filtres[i].value !== '-') {
          params['filter-' + this.filtres[i].model] = this.filtres[i].value
        }
        params.page = this.page
        params.sort = this.sortBy
        params.direction = this.sortDirection
        return params
    }
    params.page = this.page
    params.sort = this.sortBy
    params.direction = this.sortDirection
    return params
  }
  */

    tableBesoinHandler(action: string, row: any) {
        this.$emit('besoinHandler', action, row)
    }

    // Détection de la position du scroll, si on est au bas du tableau, on appelle la page suivante, si on est en haut, on appelle la page précédente
    handleScroll(event: any) {
        if (this.lastPage === undefined || this.lastPage === 0) {
            return
        }
        this.windowTop = Number(event.srcElement.scrollTop.toFixed(0))
        this.maxScroll = event.srcElement.scrollHeight - 25

        // Scroll vers le bas
        if (
            !this.loading &&
            this.windowTop > this.maxScroll - event.srcElement.offsetHeight &&
            this.page !== this.lastPage
        ) {
            this.page += 1
            this.tabParams = this.getParams()
            this.$emit('onLoadPage', this.tabParams)
        }
    }

    setTitle(etat_contrat: string) {
        let retour = ''

        switch (etat_contrat) {
            case 'secondary':
                retour = 'Publié'
                break
            case 'info':
                retour = "Signé par l'intervenant"
                break
            case 'success':
                retour = 'Signé par le concours'
                break
        }

        return retour
    }

    getLibelleDecision(decision: Decision) {
        return getDecisionSpec(decision).libelle
    }

    formatteDate(date: Date) {
        return formatDate(date)
    }

    formatteDateSignature(contrats: Array<any>) {
        let retour = ''
        if (contrats && contrats.length > 0) {
            for (let i = 0; i < contrats.length; i++) {
                if (i > 0) {
                    retour += ', '
                }
                retour += 'Contrat #' + contrats[i].id
                if (contrats[i].relaunched_at) {
                    retour += ' relancé le ' + formatDate(contrats[i].relaunched_at)
                } else {
                    retour += ' non relancé'
                }
            }
        } else {
            return ''
        }
        return retour
    }

    createColonnes() {
        if (this.collectionDynamique && this.collectionDynamique.cle === 'documenttype') {
            for (const d in this.collectionDynamique.datas) {
                this.docs.push({
                    key: 'etat_doc_' + this.collectionDynamique.datas[d].id,
                    id: this.collectionDynamique.datas[d].id
                })
            }
        }
        if (this.collectionDynamique && this.collectionDynamique.cle === 'concours') {
            for (const d in this.collectionDynamique.datas) {
                this.concours.push({
                    key: 'etat_concour_' + this.collectionDynamique.datas[d].id,
                    id: this.collectionDynamique.datas[d].id
                })
            }
        }

        if (this.collectionDynamique && this.collectionDynamique.cle === 'series') {
            for (const d in this.collectionDynamique.datas.series) {
                this.series.push({
                    key: 'serie_' + this.collectionDynamique.datas.series[d].id,
                    id: this.collectionDynamique.datas.series[d].id
                })
            }
        }
        if (this.collectionDynamique && this.collectionDynamique.cle === 'seriesCalendrierOraux') {
            this.jours = this.collectionDynamique.datas
        }
    }

    getSalleName(salleId: number) {
        let salleName = ''
        if (this.collectionDynamique.datas.salles) {
            for (const salle in this.collectionDynamique.datas.salles) {
                if (this.collectionDynamique.datas.salles[salle].id === salleId) {
                    salleName = this.collectionDynamique.datas.salles[salle].name
                    break
                }
            }
        }
        return salleName
    }
}
