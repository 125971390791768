































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component, Prop }  from 'vue-property-decorator'
import { mapGetters, mapState }   from 'vuex'
import { Ability }                from '@/types/Ability'
import { FontAwesomeIcon }        from '@fortawesome/vue-fontawesome'
import { Etat, getEtatSpec } from '@/types/DossierAcademique'
import { TypeReclamation } from '@/types/Reclamation'


@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapState('user', ['dossieracademiqueId']),
        ...mapState('dossieracademique', ['dossierAcademiqueSelect', 'loading']),
        ...mapState('reclamationOral', ['reclamations_oral']),
        ...mapState('user', ['has_poste_aca'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon
    }
})
export default class TDBObservateur extends Vue {
    @Prop() hasContrats?: boolean

    ability             = Ability
    tuiles: any  = []
    info: any          = []
    centre_select: any = null

    /**  creer un objet de deux tableaux (box1, box2) correspondant aux deux colonnes d'affichage. */
    createPreparationDashboard() {
        let color_dossier_admin = 'text-info'
        switch (this.$store.state.dossieradministratif.dossiersAdministratifs.length !== 0 && this.$store.state.dossieradministratif.dossiersAdministratifs[0].etat) {
            case Etat.ETAT_VIERGE:
                color_dossier_admin = 'text-primary'
                break
            case Etat.ETAT_DEPOSE:
                color_dossier_admin = 'text-info'
                break
            case Etat.ETAT_VALIDE:
                color_dossier_admin = 'text-success'
                break
            case Etat.ETAT_REJETE:
                color_dossier_admin = 'text-danger'
                break
        }

        const aides = this.$store.getters['auth/findParameter']('aides')
        const ordreMissionDisabled = this.$store.getters['auth/findParameter']('ordreMissionDisabled')
        this.tuiles = {
            box1: [
                {
                    name: 'Besoin d\'aide ?',
                    icone: 'life-ring',
                    tooltip : null,
                    permissions: aides && (aides.value?.videos?.length > 0 || aides.value?.documents?.length > 0) ? [true] : [false],
                    key: 'aide',
                    informations: [
                        {
                            description: 'TUTORIELS VIDEOS',
                            etat: 'text-info',
                            key: 'aide',
                            link: '/aide',
                            permissions: aides && (aides.value?.videos?.length > 0 || aides.value?.documents?.length > 0) ? [true] : [false]
                        }
                    ]
                },
                {
                    name: 'Mon dossier académique',
                    icone: 'graduation-cap',
                    tooltip : null,
                    permissions: this.$store.state.user.dossieracademiqueId !== null ? [Ability.RH_SPS_DOS_OWN_MANAGE]  : [false],
                    key: 'admin',
                    informations: [
                        {
                            description: 'REMPLIR MON DOSSIER DE CANDIDATURE',
                            etat: 'text-' + getEtatSpec(this.$store.state.dossieracademique.dossierAcademiqueSelect.etat).color,
                            key: 'admin',
                            link: '/dossier_academique/' + this.$store.state.user.dossieracademiqueId,
                            permissions: this.$store.state.user.dossieracademiqueId !== null ? [Ability.RH_SPS_DOS_OWN_MANAGE]  : [false]
                        }
                    ]
                },
                {
                    name: 'Mon dossier administratif',
                    icone: 'folder-open',
                    tooltip : null,
                    permissions: this.$store.state.user.dossieradministratifId !== null ? [Ability.RH_ADM_DOS_OWN_MANAGE]  : [false],
                    key: 'admin',
                    informations: [
                        {
                            description: 'REMPLIR MON DOSSIER ADMINISTRATIF',
                            etat: color_dossier_admin,
                            key: 'admin',
                            link: '/dossier_administratif/' + this.$store.state.user.dossieradministratifId,
                            permissions: this.$store.state.user.dossieradministratifId !== null ? [Ability.RH_ADM_DOS_OWN_MANAGE]  : [false]
                        }
                    ]
                },
                {
                    name: 'Gestion des contrats',
                    icone: 'file-contract',
                    tooltip : null,
                    permissions: this.hasContrats ? [Ability.RH_ADM_DOS_OWN_MANAGE] : [false],
                    key: 'admin',
                    informations: [
                        {
                            description: 'CONTRATS & AVENANTS',
                            etat: 'text-primary',
                            link: '/contrats_intervenant',
                            permissions: [Ability.RH_ADM_DOS_OWN_MANAGE],
                            key: 'admin'
                        },
                        {
                            description: 'ORDRES DE MISSIONS',
                            etat: 'text-primary',
                            link: '/ordres_mission_intervenant',
                            permissions: this.$store.state.user.has_poste_aca && this.$store.getters['auth/findParameter']('ordreMissionDisabled')?.value !== '1' ? [Ability.RH_ADM_DOS_OWN_MANAGE] : [false],
                            key: 'admin'
                        },
                        {
                            description: 'DEMANDES DE REMBOURSEMENT',
                            etat: 'text-primary',
                            link: '/demandes_remboursement_intervenant',
                            permissions: this.$store.state.user.has_poste_aca && this.$store.getters['auth/findParameter']('ordreMissionDisabled')?.value !== '1' ? [Ability.RH_ADM_DOS_OWN_MANAGE] : [false],
                            key: 'admin'
                        }
                    ]
                }

            ],
            box2: [

                {
                    name: 'Gestion des oraux',
                    icone: 'chalkboard-teacher',
                    tooltip: null,
                    permissions: [Ability.INTERV_PLANIF_OWN, Ability.INTERV_SUPPLEANT_OWN],
                    informations: [
                        {
                            description: 'Gérer mes incompatibilités',
                            etat: this.$store.state.sessionuser.sessionUser && this.$store.state.sessionuser.sessionUser.incompatibilites_submitted_at ? 'text-success' : 'text-primary',
                            link: 'gestion_incompatibles_examinateur',
                            permissions: [Ability.INTERV_PLANIF_OWN]
                        },
                        {
                            description: 'Gérer mes disponibilités',
                            etat: this.$store.state.sessionuser.sessionUser && this.$store.state.sessionuser.sessionUser.disponibilites_submitted_at ? 'text-success' : 'text-primary',
                            link: 'gestion_disponibilites_examinateur',
                            permissions: [Ability.INTERV_PLANIF_OWN]
                        },
                        {
                            description: 'Consulter mon planning',
                            etat: 'text-info',
                            link: 'planning_examinateur',
                            permissions: [Ability.INTERV_PLANIF_OWN]
                        },
                        {
                            description: 'Consulter mon planning',
                            etat: 'text-info',
                            link: 'planning_suppleant',
                            permissions: [Ability.INTERV_SUPPLEANT_OWN]
                        },
                        {
                            description: 'Liste des candidats avec aménagements',
                            etat: 'text-info',
                            link: 'liste_candidats_mesures',
                            permissions: [Ability.INTERV_PLANIF_OWN]
                        },
                        {
                            description: 'Liste des candidats avec aménagements',
                            etat: 'text-info',
                            link: 'liste_candidats_mesures_supp',
                            permissions: [Ability.INTERV_SUPPLEANT_OWN]
                        },
                        {
                            description: 'LISTE DES CANDIDATS DÉMISSIONNAIRES',
                            etat: 'text-info',
                            link: '/liste_demissionnaires',
                            permissions: [Ability.INTERV_PLANIF_OWN]
                        },
                        {
                            description: 'LISTE DES CANDIDATS DÉMISSIONNAIRES',
                            etat: 'text-info',
                            link: '/liste_demissionnaires_supp',
                            permissions: [Ability.INTERV_SUPPLEANT_OWN]
                        },
                        {
                            description: 'Trombinoscope des examinateurs',
                            link: '/trombinoscope_examinateurs_ex',
                            etat: 'text-info',
                            permissions: [Ability.INTERV_PLANIF_OWN]
                        },
                        {
                            description: 'Dépôt de fichier',
                            link: '/documents_examinateur',
                            etat: 'text-info',
                            permissions: [Ability.INTERV_PLANIF_OWN] // RH_SPS_DOS_OWN_MANAGE]
                        }
                    ]
                },
                {
                    name: 'Suivi des oraux',
                    icone: 'chalkboard-teacher',
                    tooltip: null,
                    permissions: [Ability.INTERV_OBSERV_MATIERE_OWN],
                    informations: [
                        {
                            description: 'RECLAMATIONS ( ' + this.$store.state.reclamationOral.reclamations_oral.length + ' )',
                            link: '/reclamations_observateur',
                            etat: this.$store.state.reclamationOral.reclamations_oral.length === 0 ? 'text-info' : 'text-primary',
                            permissions: [Ability.INTERV_OBSERV_MATIERE_OWN]
                        },
                        {
                            description: 'CONSULTER LES PLANNINGS',
                            link: '/plannings_observateur',
                            etat: 'text-info',
                            permissions: [Ability.INTERV_OBSERV_MATIERE_OWN]
                        },
                        {
                            description: 'Présences des examinateurs par centre',
                            link: '/presence_examinateurs',
                            etat: 'text-info',
                            permissions: [Ability.INTERV_OBSERV_MATIERE_OWN]
                        },
                        {
                            description: 'Trombinoscope des examinateurs',
                            link: '/trombinoscope_examinateurs',
                            etat: 'text-info',
                            permissions: [Ability.INTERV_OBSERV_MATIERE_OWN]
                        }
                    ]
                },
                {
                    name: 'Résultats aux concours',
                    icone: 'chart-bar',
                    tooltip : null,
                    permissions: [Ability.EXT_RESULTS_VIEW],
                    informations: [
                        {
                            description: 'CONSULTER LES RÉSULTATS AUX CONCOURS',
                            etat: 'text-info',
                            link: '/resultats',
                            permissions: [Ability.EXT_RESULTS_VIEW]
                        }
                    ]
                }
            ]
        }
    }

    // Retourne la couleur d'affichage du lien
    getColor(number1?: number | boolean, number2?: number) {
        if (!number2) {
            return number1 === true ? 'text-success' : 'text-secondary'
        } else {
            if (number1 === 0) {
                return 'text-secondary'
            } else if (number1 !== number2) {
                return 'text-info'
            } else if (number1 === number2) {
                return 'text-success'
            }
        }
    }

    // Check si l'utilisateur dispose de l'ability pour voir la tuile
    has_ability (permissions: any) {
        let has_ability = false

        for (const p in permissions) {
            if (this.$store.getters['auth/can'](permissions[p])) {
                has_ability = true
            }
        }
        return has_ability
    }

    async beforeMount () {
        if (!this.$store.state.reclamationOral.loading && this.$store.state.reclamationOral.reclamations_oral.length === 0 && (this.$store.getters['auth/can'](Ability.INTERV_OBSERV_MATIERE_OWN) || this.$store.getters['auth/can'](Ability.ORAL_RECLAM_VIEW))) {
            const params = {}
            Vue.set(params, 'filter-type', TypeReclamation.TYPE_PASSATION_ORAL)
            await this.$store.dispatch('reclamationOral/getReclamations', { filters : params })
        }
        this.createPreparationDashboard()
    }
}
