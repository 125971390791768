



















import { mapGetters, mapState } from 'vuex'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { isObject } from '@/utils/helpers'
import ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    computed: {
        ...mapState('centre', ['centre_users_select', 'centreSelect']),
        ...mapState('organisationecrit', ['error']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay
    }
})

export default class PopupRejetSalles extends Vue {
    @Prop() showPopup?: boolean

    show = false
    message_rejet = ''
    messageErreurSimple = ''
    commentaire = ''

    @Watch('showPopup')
    initPopup () {
        this.show = this.$props.showPopup
    }

    /** Check le type du retour d'erreur */
    isObject () {
        return isObject(this.$store.state.organisationecrit.error)
    }

    /** Annulation du rejet */
    cancelRejet () {
        this.show = false
        this.$emit('reset')
    }

    /** Confirmation du rejet */
    confirmerRejet () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('organisationecrit/manageOrganisationEcrit', { params: { mode: 'salles', centre_id: this.$store.getters['centre/centreSelect'].id, salles_submit: false, salles_validate: null, commentaire_rejet: this.commentaire } })
            .then(async () => {
                await this.$store.dispatch('centre/getCentre', { centre_id: this.$store.getters['centre/centreSelect'].id })
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('La soumission de la liste des salles a été annulée !', succesToaster)
                this.messageErreurSimple = ''
                this.message_rejet = ''
                this.show = false
                this.$emit('reset')
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }
}
