






























import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import AdresseCentre from '@/components/Centre/AdresseCentre.vue'
import { TypeAdresseCentre } from '@/types/AdresseCentre'
import { Ability } from '@/types/Ability'
import { formatDate } from '@/utils/helpers'
import { MessageIndicationType } from '@/types/MessageIndicationType'
import MessageIndication from '@/components/MessageIndication.vue'


@Component({
    components: {
        AdresseCentre,
        MessageIndication
    },
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapGetters('centre', ['informations_is_submit', 'informations_is_validate']),
        ...mapState('centre', ['centreSelect']),
        ...mapGetters('auth', ['is_intervenant_centre'])
    }
})
export default class AdressesCentre extends Vue {
    adresseCentreType = TypeAdresseCentre
    Ability = Ability
    MessageIndicationType = MessageIndicationType

    lock = true

    @Watch('centreSelect')
    checkLock () {
        this.checkLockInterface()
    }

    checkLockInterface () {
        if (this.$store.state.centre.centreSelect) {
            if (this.$store.getters['centre/informations_is_submit']) {
                this.lock = !!(this.$store.getters['auth/is_intervenant_centre'] || this.$store.getters['centre/informations_is_validate']);
            } else {
                this.lock = false
            }
        }
    }

    // Permet de gérer l'activation/désactivation des élements sur le composant AdresseCentre.vue lorsqu'on est en mode édition par un admin.
    majModeEditionADM (modeEdition: boolean) {
        this.lock = !modeEdition
    }

    /** Formatte les dates */
    formatteDate (date: Date) {
        return formatDate(date)
    }

    beforeMount () {
        this.checkLockInterface()
    }
}
