







































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { PosteContext, PosteInterface, PosteType } from '@/types/Poste'
import { Ability } from '@/types/Ability'
import { formatDate, isObject, format_phone_number } from '@/utils/helpers'
import SetIntervenant from '@/components/Centre/Intervenants/SetIntervenant.vue'
import PopupSubmitIntervenants from '@/components/Centre/Validations/PopupSubmitIntervenants.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import PopUpInfoIntervenant from '@/components/PopupInfoIntervenant.vue'


@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        SetIntervenant,
        PopupSubmitIntervenants,
        ErrorDisplay,
        PopUpInfoIntervenant
    },
    computed: {
        ...mapState('centre', ['meta', 'error', 'centreSelect', 'loading']),
        ...mapGetters('centre', ['centre_select_chef_centre']),
        ...mapState('session', ['sessionActive', 'sessionSelect']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('organisationecrit', ['besoins_centre', 'besoins_precedent', 'get_total_intervenants_precedent', 'get_total_intervenants']),
        ...mapGetters('intervenant', ['users_by_postes', 'compteur_by_poste', 'ligne_time', 'remaining_time_poste', 'remaining_time_ligne']),
        ...mapGetters('auth', ['can(Ability.GCI_SAISIE_INTERV_OWN_MANAGE)'])
    }
})
export default class SaisieIntervenants extends Vue {
    dicoPostes: Array<any> = []
    dicoUsers: Array<any> = []

    formatPhoneNumber = format_phone_number

    Ability = Ability
    showSetIntervenant = false
    poste_select = {
        poste_id: 0,
        poste_name: '',
        user_id: 0,
        slot: 0,
        temps: '',
        remaining_time: 0
    }

    showConfirmationPublier = false
    submit = -1
    validate = -1

    showModalInfoIntervenant = false
    user_id_temp = 0

    @Watch('error')
    updateError () {
        this.isObject()
    }

    @Watch('centreSelect.centreusers')
    setDicoUsers () {
        this.dicoUsers = this.$store.getters['intervenant/users_by_postes'](this.$store.state.centre.centreSelect.centreusers, this.dicoPostes, this.$store.state.organisationecrit.besoins_centre)
        // this.showSetIntervenant = false
    }

    /** Check le type du retour d'erreur */
    isObject () {
        return isObject(this.$store.state.centre.error)
    }

    /** Formattage les dates */
    formatteDate (date: Date) {
        return formatDate(date)
    }

    /** Renseigne / Gère un intervenant dans une place */
    setIntervenant (poste_id: number, slot: number, user_id: number, index: number, ligne: number) {
        this.poste_select = {
            poste_id: poste_id,
            poste_name: this.dicoPostes.find((p: PosteInterface) => p.id === poste_id).name,
            user_id: user_id,
            slot: slot,
            temps: this.dicoUsers[poste_id][index][ligne].poste.temps,
            remaining_time: this.$store.getters['intervenant/remaining_time_ligne'](this.dicoUsers[poste_id][index])
        }
        this.showSetIntervenant = true
        this.$emit('activeEdition', true)
    }

    /** Ferme la popup de gestion de l'intervenant */
    cancelSetIntervenant () {
        this.poste_select = {
            poste_id: 0,
            poste_name: '',
            user_id: 0,
            slot: 0,
            temps: '',
            remaining_time: 0
        }
        this.showSetIntervenant = false
        this.$store.state.centre.error = null
        this.$emit('activeEdition', false)
    }

    /** Retourne le compteur des intervenants selon le poste et les temps de travail */
    setCompteurByPosteTemps (poste_id: number) {
        if (this.dicoUsers && this.dicoUsers[poste_id]) {
            return this.$store.getters['intervenant/compteur_by_poste'](this.dicoUsers[poste_id])
        } else {
            return '--'
        }
    }

    /** Retourne la couleur de fond du bouton selon le contexte */
    setVariantColor (ligne: any) {
        let bgcolor = 'btn_action_ligne'

        if (ligne && ligne.ligne_bis) {
            bgcolor = 'bg-danger'
        }
        return bgcolor
    }

    /** Retourne la couleur du temps de la ligne */
    setColorTemps (poste_id: number, index: number) {
        if (this.dicoUsers[poste_id] && this.dicoUsers[poste_id][index]) {
            const ligne_time = this.$store.getters['intervenant/ligne_time'](this.dicoUsers[poste_id][index])
            if (ligne_time !== 100) {
                return 'text-danger fw-bold'
            }
        }
    }

    /** Reset showPopups */
    resetPopup () {
        this.$store.state.centre.error = null
        this.showConfirmationPublier = false
        this.$emit('activeEdition', false)
    }

    /** Confirmation de la soumission de la liste des intervenants */
    confirmSubmitIntervenant (submit = -1, validate = -1) {
        this.submit = submit
        this.validate = validate
        this.showConfirmationPublier = true
    }

    /** Retourne si la liste des intervenants est complète pour activer le bouton Soumettre */
    listComplete () {
        return this.$store.getters['intervenant/list_complete'](this.dicoUsers, this.$store.state.organisationecrit.besoins_centre)
    }

    infoIntervenantHandler(user_id: any) {
        this.user_id_temp  = user_id
        this.showModalInfoIntervenant = true
    }

    /** Retourne une classe css selon l'état de validation des infos */
    getClassBarreBoutons () {
        let class_css = 'barre_default'

        if (this.$store.state.centre.centreSelect && this.$store.state.centre.centreSelect.id) {
            if (this.$store.state.centre.centreSelect.intervenants_submitted_at && !this.$store.state.centre.centreSelect.intervenants_validated_at) {
                class_css = 'barre_soumis'
            } else if (this.$store.state.centre.centreSelect.intervenants_submitted_at && this.$store.state.centre.centreSelect.intervenants_validated_at) {
                class_css = 'barre_valide'
            } else if (this.$store.state.centre.centreSelect.commentaire_rejet && this.$store.state.centre.centreSelect.commentaire_rejet.intervenants) {
                class_css = 'barre_rejet'
            }
        }

        return class_css
    }

    /** Charge le reste des données dès qu'on a le dicoPostes */
    loadDatas () {
        if (this.$store.state.centre.centreSelect && this.$store.state.centre.centreSelect.centreusers && this.$store.state.centre.centreSelect.centreusers.length !== 0) {
            this.dicoUsers = this.$store.getters['intervenant/users_by_postes'](this.$store.state.centre.centreSelect.centreusers, this.dicoPostes, this.$store.state.organisationecrit.besoins_centre)
            this.showSetIntervenant = false
        } else {
            if (this.$route.params.centre_id) {
                // On charge le centre
                this.$store.dispatch('centre/getCentre', { centre_id: this.$route.params.centre_id })
            }
        }
    }

    mounted () {
        this.$store.state.centre.error = null
        this.$emit('setLibelle', 'SAISIE DES INTERVENANTS')

        this.$store.dispatch('poste/getPostes', { type: PosteType.TYPE_LOGISTIQUE, context: (PosteContext.CONTEXT_AFFECTATION + PosteContext.CONTEXT_CHEF_CENTRE) }).then(() => {
            this.dicoPostes = this.$store.state.poste.postes
            this.loadDatas()
        })
    }
}
