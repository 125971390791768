
























































































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component, Watch }    from 'vue-property-decorator'
import { mapGetters, mapState }     from 'vuex'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import popupImprimer                from '@/components/Fourniture/popupImprimer.vue'
import { formatDateSinTime, formatDate, formatDayHourZDateFromString, getFileNameFromHeader, formatDateDayHourDateForFileSaving, base64ToArrayBuffer }        from '@/utils/helpers'
import { FournitureTypeCheckboxName, printed, typeLongName }           from '@/types/Fourniture'
import ErrorDisplay                 from '@/components/ErrorDisplay.vue'
import _ from 'lodash'
import { Ability } from '@/types/Ability'
import { EnsembleInterface } from '@/types/Ensemble'



@Component({
    computed: {
        ...mapGetters('serie', ['serieById', 'series']),
        ...mapGetters('ensemble', ['ensembles']),
        ...mapGetters('impressionCentre', ['tableauImpressionsCentreTrie', 'getError', 'loadingRecherche', 'loadingPdf', 'meta', 'currentPage', 'totalRows', 'lastPage', 'total']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('auth', ['is_intervenant_centre'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ExaGenericTable,
        popupImprimer,
        ErrorDisplay
    }
})

export default class ImpressionsCentre extends Vue
{
    @Watch('series')
    watchSerie() {
        this.options_series = this.$store.getters['serie/series']
        this.findSerieActive(this.$store.getters['serie/series'])
    }

    @Watch('tableauImpressionsCentreTrie')
    watchTableau() {
        const data = this.$store.getters['impressionCentre/tableauImpressionsCentreTrie']
        this.setDataForGenericTab(data)
    }

    @Watch('recherche.serie')
    wRechercheSerie()
    {
        this.serieIsOpen(this.recherche.serie)
    }

    @Watch('user_session_id')
    refreshInterface () {
        this.clearResult()
        this.updateSeriesAfterUserChangeSession()
        this.load()
        this.recherche = {
            centre_id               : '',
            serie                   : '',
            ensemble_id             : '',
            check_box               : 'etiquette_examinateur',
            user_id                 : '',
            printed                 : printed.TOUTES_LES_FEUILLES,
            fiche_notation          : 0,
            page                    : 1
        }
        this.load()
    }



    // utiliser pour passer le titre dans la popUp

    titrePopUp              = 'Impression par centre'
    sortBy                  = '';
    sortDesc                = false;
    sortDirection           = 'asc';
    filter                  = '';
    filterOn                = [];
    stickyHeader            = true;
    options_centres: any         = []
    options_series               = []
    options_ensembles: any       = []
    options_examinateurs: any    = []
    showModalImprimer       = false
    formatDateSinTime       = formatDateSinTime
    formatDate              = formatDate
    printed                 = printed
    filtres:    any         = []
    dataForTab: Array<any>  = []
    pdfData                 = ''
    pdfFileName             = 'Impression_Par_Centre.pdf'
    libelle_document: any   = ''
    paramsConfirmationImpression: any = {}
    typeLongName            = typeLongName
    FournitureTypeCheckboxName = FournitureTypeCheckboxName
    // sauverarde de la derniere recherche afin de la passer en params pour impression
    imprimer_temp: any                = ''
    Ability = Ability
    // temp utilisé pour les ensembles quand un utilisateur est own
    ensemble_own_centre: any         = ''
    serieIsOpenBoolean                      = false


    // field global
    fieldsForGlobal = [
        { key: 'type',          label: 'Type',                   sortable: false,    class: 'text-center',   type: 'text' },
        { key: 'centre',        label: 'Centre',                 sortable: false,    class: 'text-center',                  type: 'text' },
        { key: 'equipe',        label: 'Équipe',                 sortable: false,    class: 'text-center',                  type: 'text' },
        { key: 'examinateur',   label: 'Examinateur',            sortable: false,    class: '',                  type: 'text' },
        { key: 'serie',         label: 'Série',                  sortable: false,     class: 'text-center',                 type: 'text' }
    ]

    // field si type de document (recherche.checkbox) === fiche_mesure
    fieldsForMesureOral = [
        { key: 'type',          label: 'Type',                   sortable: false,    class: 'text-center',   type: 'text' },
        { key: 'centre',        label: 'Centre',                 sortable: false,    class: 'text-center',                  type: 'text' },
        { key: 'candidat',      label: 'Candidat',                  sortable: false,    class: '',                  type: 'text' },
        { key: 'serie',         label: 'Série',                  sortable: false,     class: 'text-center',                 type: 'text' }
    ]

    genericfields = this.fieldsForGlobal

    recherche = {
        centre_id               : '',
        serie                   : '',
        ensemble_id             : '',
        check_box               : 'etiquette_examinateur',
        user_id                 : '',
        printed                 : printed.TOUTES_LES_FEUILLES,
        fiche_notation          : 0,
        page                    : 1
    }




    updateSeriesAfterUserChangeSession() {
        const sessionUserSelect = this.$store.state.auth.user_session_id

        this.$store.dispatch('serie/getSeries', { session_id: sessionUserSelect })
    }

    /** formatte une valeur string de type'YYYY-MM-DD HH:mm:ss' en valeur moment YYYY-MM-DDTHH:mm:ss[Z] */
    convertDateToIso = (dateString: string | undefined) => {
        if (dateString) {
            return formatDayHourZDateFromString(dateString)
        }
    }

    // recuperation des ensembles s'ils ne sont pas transmis par le parent
    getEnsemblesData() {
        const params: any = {
            perPage: 10000
        }
        // si utilisateur own, on appelle uniquement pour son propre centre
        if (this.$store.getters['auth/is_intervenant_centre']) {
            params['filter-centre_id'] = this.$route.params.centre_id
        }
        this.$store.dispatch('ensemble/getEnsembles', { params: params }).then((response) => {
            if (this.$store.getters['auth/is_intervenant_centre']) {
                params['filter-centre_id'] = this.$route.params.centre_id
                this.ensemble_own_centre = response.data.data
                this.options_ensembles = response.data.data
                this.getEnsembleCentre(this.$route.params.centre_id)
            }
        })
            .catch((error) => {
                console.log('ko:' + error)
            })
    }

    initSeries () {
        if (this.$store.getters['serie/series'].length === 0) {
            this.$store.dispatch('serie/getSeries')
            this.recherche.serie = this.findSerieActive(this.$store.getters['serie/series'])
        } else {
            this.options_series = this.$store.getters['serie/series']
            this.recherche.serie = this.findSerieActive(this.$store.getters['serie/series'])
        }
    }


    /** recuperation des centres et supprime les valeurs des variables */
    getEnsembleCentre(centre: string) {
        this.options_ensembles      = []
        this.options_examinateurs   = []
        this.recherche.ensemble_id  = ''
        this.recherche.user_id      = ''
        const allEnsembles          = this.$store.getters['ensemble/ensembles']
        this.options_ensembles      = allEnsembles.filter((e: any) => (e.centre_id) === centre)

        if (this.$store.getters['auth/is_intervenant_centre']) {
            this.options_ensembles = this.ensemble_own_centre
        }

        this.options_ensembles.sort((a: EnsembleInterface, b: EnsembleInterface) => {
            return (a.type_ensemble > b.type_ensemble) ? 1 : (a.type_ensemble === b.type_ensemble ? (a.name > b.name ? 1 : -1) : -1)
        })

        if (this.options_ensembles && this.options_ensembles[0] && this.options_ensembles[0].id) {
            // this.recherche.ensemble_id = this.options_ensembles[0].id
            this.getExaminateurs(this.recherche.ensemble_id)
        }
    }


    /**  recup de l'id sélectionné dans équipe, dispatch et alimentation de la liste des choix  */
    getExaminateurs(equipeId: any) {
        if (this.$store.getters['ensemble/ensembles'] && equipeId)
        {
            this.recherche.user_id      = ''
            this.options_examinateurs   = ''
            const allEnsembles          = this.$store.getters['ensemble/ensembles']
            this.options_examinateurs   = allEnsembles.filter((e: any) => e.id === equipeId)[0].examinateurs
            const remplacant            = allEnsembles.filter((e: any) => e.id === equipeId)[0]
            if (remplacant && remplacant.remplacants && remplacant.remplacants.length !== 0) {
                remplacant.remplacants.forEach((element: any) => {
                    const pusElement = Object.assign(element)

                    pusElement.is_replace = true
                    this.options_examinateurs.push(pusElement)
                })
            }
            this.recherche.user_id      = ''

            // recup du type d ensemble afin de deactiver le choix si type_ensemble === TYPE_ENSEMBLE_TP
            // this.typeSelectEnsemble = allEnsembles.filter((ens: any) => ens.id === equipeId)[0].type_ensemble
            this.options_examinateurs = _.orderBy(this.options_examinateurs, 'name', 'asc')
        }
        this.recherche.user_id = ''
    }

    /** lance la recherche ou l affichage du pdf. pdf_view 0/1. 1 si apercu du pdf  */
    sendSearch(pdf_view: number) {
        this.$store.commit('impressionCentre/RESET_META')
        this.$store.commit('impressionCentre/RESET_TABLEAU_IMPRESSION')
        const params  =
        {
            centre_id               : this.recherche.centre_id,
            serie_id                : this.recherche.serie,
            ensemble_id             : this.recherche.ensemble_id,
            user_id                 : this.recherche.user_id,
            printed                 : this.recherche.printed,
            [this.recherche.check_box] : 1,
            pdf                     : pdf_view,
            fiche_notation          : 0,
            page                    : this.recherche.page
        }
        this.imprimer_temp = params
        this.$store.dispatch('impressionCentre/getTableauImpressionCentre', params)
    }

    /*
    *
    * Generic Table Area
    *
    */

    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            // deux facons de monter les infos, en fonction du type de documents demandé, change le genericFields en fonction.

            /* si le type de document n'est pas fiche_mesure : fields classique */
            if (this.recherche.check_box && this.recherche.check_box !== 'fiche_mesure')
            {
                this.genericfields = this.fieldsForGlobal
                for (const result of poData)
                {
                    const ensemble = this.$store.state.ensemble.ensembles.find((e: any) => e.id === result.ensemble_id)
                    const type                  = this.typeLongName(result.type)
                    const centre                = result.centre_id      ? this.findCentre(result.centre_id) : '-'
                    const equipe                = result.ensemble_id    ? this.findEnsemble(result.centre_id, result.ensemble_id) : '-'
                    let examinateur = ensemble.examinateurs.find((ex: any) => ex.id === result.user_id)
                    if (!examinateur) {
                    // on cherche dans les remplaçants
                        examinateur = ensemble.remplacants.find((ex: any) => ex.id === result.user_id)
                    }
                    const serie                 = result.serie_id       ? this.findSerie(result.serie_id) : '-'
                    const line = [
                        { label: 'Type',        item: type,                 type: 'text', typeAction: null, class: 'text-center colonne_type' },
                        { label: 'Centre',      item: centre.name,               type: 'text', typeAction: null, class: 'text-center' },
                        { label: 'equipe',      item: equipe.name,               type: 'text', typeAction: null, class: 'text-center' },
                        {
                            label: 'examinateur',
                            item:  examinateur && examinateur.name !== undefined ?  examinateur.name + ' ' + examinateur.first_name : ' - ',
                            type: 'text',
                            typeAction: null,
                            class: ''
                        },
                        { label: 'serie',       item: serie.name,                type: 'text', typeAction: null, class: 'text-center' }
                    ]

                    this.dataForTab.push(line)
                }
            }

            /* Si fiche_mesure , changement de generic fields pour fieldsForMesureOral et changement des infos a afficher */
            if (this.recherche.check_box && this.recherche.check_box === 'fiche_mesure')
            {
                this.genericfields = this.fieldsForMesureOral
                for (const result of poData)
                {
                    const type                  = this.typeLongName(result.type)
                    const centre                = result.centre_id      ? this.findCentre(result.centre_id) : '-'
                    const serie                 = result.serie_id       ? this.findSerie(result.serie_id) : '-'

                    const line = [
                        { label: 'Type',        item: type,                 type: 'text', typeAction: null, class: 'text-center colonne_type' },
                        { label: 'Centre',      item: centre.name,               type: 'text', typeAction: null, class: 'text-center' },
                        {
                            label: 'Candidat',
                            item:  result.candidat && result.candidat.name !== undefined ? result.candidat.code + ' ' + result.candidat.name + ' ' + result.candidat.first_name : ' - ',
                            type: 'text',
                            typeAction: null,
                            class: ''
                        },
                        { label: 'serie',       item: serie.name,                type: 'text', typeAction: null, class: 'text-center' }
                    ]

                    this.dataForTab.push(line)
                }
            }
        }
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'onLoadPage':
                    this.loadHandler()
                    break
            }
        }
    }

    /**
     * Appel d'une nouvelle page de epreuveCorrectionResultats lors du scroll
     */
    loadHandler ()
    {
        const params  =
        {
            centre_id                   : this.recherche.centre_id,
            serie_id                    : this.recherche.serie,
            ensemble_id                 : this.recherche.ensemble_id,
            user_id                     : this.recherche.user_id,
            printed                     : this.recherche.printed,
            [this.recherche.check_box]  : 1,
            pdf                         : 0,
            fiche_notation              : 0,
            page                        : this.$store.state.impressionExaminateur.currentPage + 1
        }

        this.$store.dispatch('impressionCentre/getMoreTableauImpressionCentre', params)
    }

    /** recup de tous les centres et renvois les centres avec équipe */
    getCentres() {
        // cote CCmp
        if (this.$store.getters['auth/can'](Ability.ORAL_GFOU_VIEW))
        {
            if (this.$store.getters['centre/centres'].length === 0) {
                this.$store.dispatch('centre/getCentres').then((resp) => {
                    this.options_centres = resp.data.data.filter((centre: { ensembles_count: number }) => centre.ensembles_count !== 0)
                })
            } else {
                this.options_centres = this.$store.getters['centre/centres'].filter((centre: { ensembles_count: number }) => centre.ensembles_count !== 0)
            }
        }
        // coté intervenant
        else if (this.$store.getters['auth/is_intervenant_centre']) {
            const centre_id = this.$route.params.centre_id
            this.recherche.centre_id = centre_id
            this.getEnsembleCentre(centre_id)
        }
    }


    /** recheche un centre et renvois son nom  */
    findCentre(centre_id: number) {
        const centre: any = this.$store.getters['centre/centres'].filter((centre: any) => centre.id === centre_id)[0]
        return  centre
    }

    /** recherche d'un ensemble et renvois le nom  */
    findEnsemble(centre_id: number, ensemble_id: number) {
        const ensembles = this.$store.getters['ensemble/ensembles']
        const ensemble: any = ensembles.filter((ens: any) => ens.id === ensemble_id)[0]
        return ensemble
    }

    findSerie(serie_id: number) {
        const series = this.$store.getters['serie/series']
        const serie = series.filter((serie: any) => serie.id === serie_id)[0]
        return serie
    }

    serieIsOpen(serieId: any) {
        const selectedSerie: any = this.options_series.filter((serie: any) => serie.id === parseInt(serieId))
        if (selectedSerie && selectedSerie[0]) {
            // eslint-disable-next-line no-unneeded-ternary
            this.serieIsOpenBoolean  = selectedSerie[0].valide ? true : false
        }
    }

    dl_pdf () {
        const centreMatch: any = this.options_centres.filter((c: any) => c.id === this.recherche.centre_id)
        if (centreMatch && centreMatch.length > 0) {
            const currentDateFormated = formatDateDayHourDateForFileSaving(new Date(Date.now()).toISOString())
            this.pdfFileName = 'Impression_Par_Centre_' + centreMatch[0].name + (this.recherche.check_box ? '_' + this.recherche.check_box : '') + '_' + currentDateFormated + '.pdf'
        }

        // recup du temps
        const params = this.imprimer_temp
        this.paramsConfirmationImpression = params
        params.pdf = 1
        params.format = 'b64'

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Téléchargement en cours ...', infosToaster)

        params.pdf = 1
        params.format = 'b64'
        this.$store.dispatch('impressionCentre/getPdf', params)
            .then(response => {
                // Logique de téléchargement
                const file_name = getFileNameFromHeader(response.headers) || this.pdfFileName
                const url = URL.createObjectURL(new Blob([base64ToArrayBuffer(response.data)]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', file_name)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }


    /*
    *
    * Modale Area
    *
    */

    /* Gestion de l'affichage de la modale imprimer */
    affichageModaleImprimer() {
        const centreMatch: any = this.options_centres.filter((c: any) => c.id === this.recherche.centre_id)
        if (centreMatch && centreMatch.length > 0) {
            const currentDateFormated = formatDateDayHourDateForFileSaving(new Date(Date.now()).toISOString())
            this.pdfFileName = 'Impression_Par_Centre_' + centreMatch[0].name + (this.recherche.check_box ? '_' + this.recherche.check_box : '') + '_' + currentDateFormated + '.pdf'
        }

        // recup du temps
        const params = this.imprimer_temp
        this.paramsConfirmationImpression = params
        this.showModalImprimer = true
    }

    closeModaleImprimer() {
        this.pdfData = ''
        this.showModalImprimer = false
    }

    /** trouve la premiere serie active et renvoi l'id de la valeur  */
    findSerieActive(serie: any[]) {
        // logique si on recherche une serie valide
        /*
        let id  = '0'
        if (serie) {
            id = serie.find((serie: { valide: number }) => serie.valide === 1) ? serie.find((serie: { valide: number }) => serie.valide === 1).id : '0'
        }
        return id */

        // recup de la premiere serie
        return this.$store.state.serie.series[0].id
    }

    /** efface les données du tableau, utilisé au changement dans les inputs de recherche  */
    clearResult() {
        this.$store.commit('impressionCentre/RESET_META')
        this.$store.commit('impressionCentre/RESET_TABLEAU_IMPRESSION')
    }

    load () {
        this.$store.commit('impressionCentre/RESET_META')
        this.getCentres()
        this.getEnsemblesData()
        this.initSeries()
    }

    /** Verif si session active, sinon appel  */
    mounted() {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}

