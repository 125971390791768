
















































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import { isObject } from '@/utils/helpers'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { isNull } from 'lodash'

@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay
    },
    computed: {
        ...mapGetters('centre', ['loading', 'error', 'centreSelect']),
        ...mapState('centre', ['meta', 'error']),
        ...mapState('session', ['sessionSelect']),
        ...mapGetters('banque', ['banques']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id'])
    }
})
export default class CapacitesCentres extends Vue {
    Ability = Ability

    centreTemp: any = null
    volumes: Array<any> = []
    enumTypeCentre: Array<any> = []
    session_precedente = null
    timeoutid = 0
    showConfirmationSoumettre = false
    use_real_data = false
    session_en_cours = null
    real_data = false

    @Watch('centreSelect.concours')
    setVolumes() {
        this.use_real_data = !!this.$store.state.centre.centreSelect.use_real_data_at
        this.createVolumes()
    }

    @Watch('user_session_id')
    refreshInterface() {
        this.load()
    }

    /** Check le type du retour d'erreur */
    isObject() {
        return isObject(this.$store.state.centre.error)
    }

    /** Check si le concours est actif pour le centre */
    isChecked(banque_id: any) {
        let isChecked = false
        if (this.centreTemp && this.volumes.length !== 0) {
            const hasConcour = this.volumes.filter((v: any) => v.id === banque_id)
            if (hasConcour.length !== 0) {
                isChecked = true
            }
        }
        return isChecked
    }

    /** Sélectionne ou déselectionne un concours */
    selectConcours(banque: any) {
        this.$store.state.centre.error = null
        const index = this.volumes.findIndex((v: any) => v.id === banque.id)
        if (index !== -1) {
            this.volumes.splice(index, 1)
        } else {
            const concour_init = this.$store.state.centre.centreSelect.concours.filter((c: any) => c.id === banque.id)
            banque.nb_candidats = concour_init.length !== 0 ? concour_init[0].nb_candidats : 0
            banque.nb_candidats_prevision = concour_init.length !== 0 ? concour_init[0].nb_candidats_prevision : 0
            this.volumes.push(banque)
        }
    }

    /** Update les volumes d'un centre */
    updateVolume() {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.$store.state.centre.error = null
        this.$store.commit('centre/SET_SAVE_IN_PROGRESS', true)
        const payload = {
            id: this.$store.state.centre.centreSelect.id,
            concours: this.volumes,
            use_real_data: this.use_real_data
        }
        this.$store.dispatch('centre/updateCentre', payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                this.$store.dispatch('centre/getCentre', { centre_id: this.$store.state.centre.centreSelect.id })
                this.$store.commit('centre/SET_SAVE_IN_PROGRESS', false)
            })
            .catch((error) => {
                console.log('ko:' + error)
                this.$store.commit('centre/SET_SAVE_IN_PROGRESS', false)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /** Calcule le nombre de candidats de la session précédente */
    getTotalPrecedent() {
        let total = 0
        for (const c in this.$store.state.centre.centreSelect.precedent.concours) {
            total += Number(this.$store.state.centre.centreSelect.precedent.concours[c].nb_candidats_prevision)
        }

        return total
    }

    /** Calcul le nombre de candidats prévisionnel de la session en cours */
    getTotalPrevEnCours() {
        let total = 0
        for (const c in this.volumes) {
            total += Number(this.volumes[c].nb_candidats_prevision)
        }

        return total
    }

    /** Calcule le nombre de candidats de la session en cours */
    getTotalEnCours() {
        let total = 0
        for (const c in this.$store.state.centre.centreSelect.concours) {
            total += Number(this.$store.state.centre.centreSelect.concours[c].nb_candidats)
        }

        return total
    }

    /** Calcule le nombre de candidats réels de la session en cours */
    getTotalCandidats() {
        let total = 0
        for (const c in this.volumes) {
            total += Number(this.volumes[c].nb_candidats)
        }

        return total
    }

    /** Création du tableau des volumes par concours */
    createVolumes() {
        this.volumes = []
        this.centreTemp = this.$store.state.centre.centreSelect
        const banques = this.$store.getters['banque/banques']

        if (this.$store.state.centre.centreSelect && this.$store.state.centre.centreSelect.concours) {
            for (const c in banques) {
                const centre_concours = this.$store.state.centre.centreSelect.concours.find(
                    (co: any) => co.id === banques[c].id
                )
                if (centre_concours) {
                    banques[c].nb_candidats = centre_concours.nb_candidats
                    banques[c].nb_candidats_prevision = centre_concours.nb_candidats_prevision
                    this.volumes.push(banques[c])
                }
            }
        }
    }

    /** Récupère l'état de la coche Use real data */
    setRealData(e: any) {
        this.use_real_data = e.target.checked
    }

    load() {
        this.session_en_cours = this.$store.state.session.sessionSelect
        if (this.$store.state.banque.banques.length !== 0) {
            this.$store.dispatch('banque/getBanques', { isPrecedente: false }).then(() => {
                this.$store
                    .dispatch('session/getSession', { session_id: this.$store.state.session.sessionSelect.session_id })
                    .then(() => {
                        this.session_precedente = this.$store.state.session.sessionSelect

                        // Recharge dans la foulée la session en cours
                        this.$store
                            .dispatch('session/getSession', { session_id: this.$store.getters['auth/user_session_id'] })
                            .then(() => {
                                this.createVolumes()
                            })
                    })
            })
        } else {
            this.$store
                .dispatch('session/getSession', { session_id: this.$store.state.session.sessionSelect.session_id })
                .then(() => {
                    this.session_precedente = this.$store.state.session.sessionSelect

                    // Recharge dans la foulée la session en cours
                    this.$store
                        .dispatch('session/getSession', { session_id: this.$store.getters['auth/user_session_id'] })
                        .then(() => {
                            this.createVolumes()
                        })
                })
        }
    }

    mounted() {
        // Blocage de l'édition si "use_real_data_at" à une date
        const session = this.$store.state.session.sessions.find(
            (s: any) => s.id === this.$store.getters['auth/user_session_id']
        )
        if (session) {
            this.real_data = !isNull(session.use_real_data_at)
        }

        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
