






















































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { Etat, getEtatSpec } from '@/types/DossierAdministratif'
import { formatDate, base64ToArrayBuffer } from '@/utils/helpers'
import PopupDemandeRemboursement from '@/components/DemandeRemboursement/PopupDemandeRemboursement.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VuePdfApp from 'vue-pdf-app'

@Component({
    components: {
        PopupDemandeRemboursement,
        'font-awesome-icon': FontAwesomeIcon,
        VuePdfApp
    },
    computed: {
        ...mapGetters('ordremission', ['ordremissions', 'ordreMissionSelect']),
        ...mapState('ordremission', ['ordreMissions', 'ordreMissionSelect']),
        ...mapGetters('demanderemboursement', ['demandesRemboursement', 'demandeRemboursementSelect', 'loading', 'source_pdf']),
        ...mapState('demanderemboursement', ['demandesRemboursement', 'demandeRemboursementSelect', 'source_pdf']),
        ...mapState('dossieradministratif', ['dossiersAdministratifs', 'contrats'])
    },
    methods: {
        ...mapMutations('dossieradministratif', ['SET_CONTRATS'])
    }
})
export default class DemandesRemboursementIntervenant extends Vue {
    @Watch('ordreMissions')
    isPossibleDemande () {
        this.list_ordres_mission = this.$store.state.ordremission.ordreMissions.filter((c: any) => parseInt(c.etat) === Etat.ETAT_VALIDE)
    }

    @Watch('demandeRemboursementSelect')
    setSourceDoc () {
        if (this.$store.state.demanderemboursement.demandeRemboursementSelect.etat === Etat.ETAT_VALIDE) {
            const document_etat_frais = this.$store.state.demanderemboursement.demandeRemboursementSelect.documents.filter((d: any) => d.type === 'etat_de_frais')
            if (document_etat_frais[0]) {
                this.$store.dispatch('demanderemboursement/getDocumentDemandeRemboursement', { demande_remboursement_id: this.$store.state.demanderemboursement.demandeRemboursementSelect.id, document_id: document_etat_frais[0].id }).then(() => {
                    this.source_doc = base64ToArrayBuffer(this.$store.state.demanderemboursement.source_pdf)
                    this.showConsultEtatFrais = true
                })
            }
        }
    }

    // DATAS
    getEtatSpec = getEtatSpec
    Etat = Etat
    showConsultCommentaireRejet = false
    showDemandeRemboursement = false
    list_ordres_mission: Array<any> = []
    demandeSelect: any = null
    showConsultEtatFrais = false
    config = {
        toolbar: {
            toolbarViewerRight: { presentationMode: false, openFile: false, viewBookmark: false, secondaryToolbarToggle: false }
        }
    }

    source_doc: any = ''

    // METHODS

    // Retourne une description selon etat de la demande de remboursement
    getDescription (demandeRemboursement: any) {
        let description = ''
        switch (demandeRemboursement.etat) {
            case Etat.ETAT_DEPOSE:
                description = 'En attente de traitement'
                break
            case Etat.ETAT_VALIDE:
                description = 'Demande acceptée le ' + formatDate(demandeRemboursement.validated_at)
                break
            case Etat.ETAT_REJETE:
                description = 'Demande refusée'
                break
        }

        return description
    }

    // formatte les dates
    formmated_date (date: any) {
        return formatDate(date)
    }

    // Affiche le commentaire de rejet d'une demande de remboursement
    consultCommentaireRejet (demandeRemboursement: any) {
        this.$store.dispatch('demanderemboursement/getDemandeRemboursement', { demande_remboursement_id: demandeRemboursement.id })
        this.showConsultCommentaireRejet = true
    }

    // Popup pour faire une demande de remboursement
    addDemandeRemboursement () {
        this.showDemandeRemboursement = true
    }

    // Edition d'un ordre de mission
    editDemandeRemboursement (demande: any) {
        this.demandeSelect = demande
        this.showDemandeRemboursement = true
    }

    // Ferme la popup de demande de remboursement
    closeDemandeRemboursement () {
        this.demandeSelect = null
        this.showDemandeRemboursement = false
        this.$store.dispatch('demanderemboursement/getDemandesRemboursement')
    }

    // Ferme la poup de commentaire de rejet
    closeCommentaireRejet () {
        this.showConsultCommentaireRejet = false
    }

    // Affiche l'etat de frais en viewer
    consultEtatFrais (demande: any) {
        this.$store.dispatch('demanderemboursement/getDemandeRemboursement', { demande_remboursement_id: demande.id })
    }

    // Ferme la consultation d'un etat de frais
    closeConsultationEtatFrais () {
        this.showConsultEtatFrais = false
    }

    mounted () {
        if (!(this.$store.getters['auth/findParameter']('ordreMissionDisabled')?.value !== '1')) {
            this.$router.go(-1)
        }
        if (this.$store.state.demanderemboursement.demandesRemboursement.length === 0) {
            this.$store.dispatch('demanderemboursement/getDemandesRemboursement')
        }
        if (this.$store.state.ordremission.ordreMissions.length === 0) {
            this.$store.dispatch('ordremission/getOrdreMissions')
        } else {
            this.list_ordres_mission = this.$store.state.ordremission.ordreMissions.filter((c: any) => parseInt(c.etat) === Etat.ETAT_VALIDE)
        }
    }
}
