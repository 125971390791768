





































































































































































































































































































import { mapActions, mapGetters, mapState } from 'vuex'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import { formatDate, base64ToArrayBuffer } from '@/utils/helpers'
import ItemIntervenant from '@/components/ItemIntervenant.vue'
import { Etat, getEtatSpec } from '@/types/DossierAdministratif'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VuePdfApp from 'vue-pdf-app'
import { Ability } from '@/types/Ability'
import  ErrorDisplay  from '@/components/ErrorDisplay.vue'

@Component({
    components: {
        Datetime,
        ItemIntervenant,
        'font-awesome-icon': FontAwesomeIcon,
        VuePdfApp,
        ErrorDisplay
    },
    computed: {
        ...mapGetters('demanderemboursement', ['error', 'save_finish', 'demandeRemboursementSelect', 'source_pdf']),
        ...mapState('demanderemboursement', ['error', 'save_finish', 'demandeRemboursementSelect', 'source_pdf']),
        ...mapGetters('ordremission', ['ordremissions']),
        ...mapState('ordremission', ['ordreMissions']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    },
    methods: {
        ...mapActions('demanderemboursement', ['addDemandeRemboursement', 'getDemandesRemboursement', 'getDemandeRemboursement', 'getDocumentDemandeRemboursement', 'updateDemandeRemboursement', 'deleteDocumentDemandeRemboursement'])
    }
    /* ,
  beforeMount () {
    this.reset()
  }
  */
})

export default class PopupDemandeRemboursement extends Vue {
    @Prop() showPopup?: boolean;
    @Prop() isResponsable?: boolean
    @Prop() demande?: any

    Ability = Ability
    file_name = ''

    @Watch('ordreMissions')
    setOrdreMissionSelect () {
        this.loadListeOrdreMission()
    }

    @Watch('showPopup')
    setDatas () {
        this.$store.state.demanderemboursement.error = null

        // Qu'on ouvre ou qu'on ferme la PopUp, on reset l'input des justificatifs qui nous sert juste à sélectionner des fichiers sur l'instant pour alimenter la liste.
        this.justificatifsFileInputKey++ // La modification de la key va faire réséter l'input

        if (this.$props.demande && this.$props.showPopup) {
            this.$store.dispatch('demanderemboursement/getDemandeRemboursement', { demande_remboursement_id: this.$props.demande.id }).then(() => {
                // this.ordremission_select = this.$props.demande.ordre_mission
                this.motif = this.$props.demande.datas.motif
                this.aller_moyen_transport = this.$props.demande.datas.aller_moyen_transport
                this.aller_depart_lieu = this.$props.demande.datas.aller_depart_lieu
                this.aller_depart_date = this.$props.demande.datas.aller_depart_date
                this.aller_arrivee_lieu = 'PARIS'
                this.aller_arrivee_date = this.$props.demande.datas.aller_arrivee_date

                this.retour_moyen_transport = this.$props.demande.datas.retour_moyen_transport
                this.retour_depart_lieu = 'PARIS'
                this.retour_depart_date = this.$props.demande.datas.retour_depart_date
                this.retour_arrivee_lieu = this.$props.demande.datas.retour_arrivee_lieu
                this.retour_arrivee_date = this.$props.demande.datas.retour_arrivee_date

                this.repas_restaurant_administratif = this.$props.demande.datas.repas_restaurant_administratif
                this.repas_autre = this.$props.demande.datas.repas_autre

                this.frais_annexes = this.$props.demande.datas.frais_annexes
            })
        } else if (this.$props.showPopup) {
            // On est dans le cas d'un ajout de demande de remboursement, réinit des justificatifs qui auraient été instanciés via une précendente consultation par exemple.
            this.justificatifs = []
        }
    }

    @Watch('demandeRemboursementSelect')
    setJustificatifs () {
        if (this.$store.state.demanderemboursement.demandeRemboursementSelect && this.$store.state.demanderemboursement.demandeRemboursementSelect.documents) {
            this.justificatifs = this.$store.state.demanderemboursement.demandeRemboursementSelect.documents.filter((d: any) => d.type === 'justificatifs')
        }
        // On définit que le champ de saisie des fichiers justificatifs est rempli si on a soit des fichiers sélectionnés, soit des fichiers existants (cas de l'édition d'une demande de remboursement)
        this.listIsOk = (this.filesListFromInput.length > 0 || this.justificatifs.length > 0) ? 'OK' : ''
    }

    @Watch('filesListFromInput')
    setlistIsOk () {
        // On définit que le champ de saisie des fichiers justificatifs est rempli si on a soit des fichiers sélectionnés, soit des fichiers existants (cas de l'édition d'une demande de remboursement)
        this.listIsOk = (this.filesListFromInput.length > 0 || this.justificatifs.length > 0) ? 'OK' : ''
    }

    formData = new FormData()
    list_ordres_mission: Array<any> = []
    ordremission_select: any = null
    motif = ''
    aller_moyen_transport = ''
    aller_depart_lieu = ''
    aller_depart_date: any = null
    aller_arrivee_lieu = 'PARIS'
    aller_arrivee_date: any = null

    retour_moyen_transport = ''
    retour_depart_lieu = 'PARIS'
    retour_depart_date: any = null
    retour_arrivee_lieu = ''
    retour_arrivee_date: any = null

    repas_restaurant_administratif = 0
    repas_autre = 0

    frais_annexes = ''
    montant_remboursement = 0
    decision_demande: any = null
    commentaire_refus = ''
    Etat = Etat
    modePreviewDocument = false
    libelle_document = ''
    showConfirmationMessage = false
    getEtatSpec = getEtatSpec
    justificatifs = []
    source_doc: any = ''
    justificatifsFileInputKey = 0
    filesListFromInput: any = []
    fileSingleFromInput: any = null
    listIsOk = ''
    lock_confirm = false

    config = {
        toolbar: {
            toolbarViewerRight: { presentationMode: false, openFile: false, viewBookmark: false, secondaryToolbarToggle: false }
        }
    }

    // Ferme la popup et reinit les données
    reset () {
        this.$store.state.demanderemboursement.error = null
        this.justificatifsFileInputKey++ // La modification de la key va faire réséter l'input
        this.justificatifs = []
        this.filesListFromInput = []
        this.fileSingleFromInput = null

        this.modePreviewDocument = false

        this.motif = ''
        this.aller_moyen_transport = ''
        this.aller_depart_lieu = ''
        this.aller_depart_date = null
        this.aller_arrivee_lieu = 'PARIS'
        this.aller_arrivee_date = null

        this.retour_moyen_transport = ''
        this.retour_depart_lieu = 'PARIS'
        this.retour_depart_date = null
        this.retour_arrivee_lieu = ''
        this.retour_arrivee_date = null

        this.repas_restaurant_administratif = 0
        this.repas_autre = 0
        this.montant_remboursement = 0

        this.frais_annexes = ''
        this.showConfirmationMessage = false

        this.commentaire_refus = ''
        this.decision_demande = null
        this.$emit('reset')
    }

    // Ferme le message de confirmation
    resetMessageConfirmation () {
        this.showConfirmationMessage = false
    }

    // Confirmation avant enregistrement
    showConfirmation () {
        this.$store.state.demanderemboursement.error = null
        if (this.ordremission_select) {
            this.showConfirmationMessage = true
        } else {
            this.$store.state.demanderemboursement.error = 'Merci de sélectionner un ordre de mission.'
        }
    }

    // Pre selectionne le moyen de transport selon l'ordre de mission choisi
    preselectTransport () {
        this.aller_moyen_transport = this.ordremission_select.datas.moyen_transport
        this.retour_moyen_transport = this.ordremission_select.datas.moyen_transport
    }

    // Retourne un libellé pour le moyen de transport
    getLibelleTransport (transport: string) {
        let libelle = ''
        switch (transport) {
            case 'vehicule_perso':
                libelle = 'Route'
                break
            case 'avion':
                libelle = 'Avion'
                break
            case 'train':
                libelle = 'Train'
                break
        }

        return libelle
    }

    // Formatte les dates
    formatteDate (date: Date) {
        return formatDate(date)
    }

    manageSubmitDemandeRemboursement () {
        if (this.isResponsable && this.$store.state.demanderemboursement.demandeRemboursementSelect.etat === Etat.ETAT_DEPOSE) {
            // cas de la gestion de la demande de remboursement par le RH ADMIN
            this.saveDecision()
        } else if (!this.isResponsable) {
            // Cas de la demande de remboursement côté Intervenant.
            this.lock_confirm = true
            this.saveDemandeRemboursement()
        }
    }

    // Enregistre la demande de remboursement
    saveDemandeRemboursement () {
        if (this.ordremission_select) {
            this.$store.state.demanderemboursement.error = null

            // Pour l'enregistrement du remboursement, on utilise un formData créé dans la méthode, sinon, on se récupère tous les appends précédents potentiellement effectué via les autres opérations possible de la popUp.
            const formDataNouvelleDemande = new FormData()

            const datas = {
                motif: this.motif,
                aller_moyen_transport: this.aller_moyen_transport,
                aller_depart_lieu: this.aller_depart_lieu,
                aller_depart_date: this.aller_depart_date,
                aller_arrivee_lieu: this.aller_arrivee_lieu,
                aller_arrivee_date: this.aller_arrivee_date,
                retour_moyen_transport: this.retour_moyen_transport,
                retour_depart_lieu: this.retour_depart_lieu,
                retour_depart_date: this.retour_depart_date,
                retour_arrivee_lieu: this.retour_arrivee_lieu,
                retour_arrivee_date: this.retour_arrivee_date,
                repas_restaurant_administratif: this.repas_restaurant_administratif,
                repas_autre: this.repas_autre,
                frais_annexes: this.frais_annexes
            }

            formDataNouvelleDemande.set('etat', Etat.ETAT_DEPOSE.toString())
            formDataNouvelleDemande.set('ordre_mission_id', this.ordremission_select.id)
            formDataNouvelleDemande.set('datas', JSON.stringify(datas))

            // insertion du ou des fichiers sélectionnés dans le formData
            if (this.filesListFromInput.length > 0) {
                // Cas des justificatifs en potentiel multiple fichiers
                for (const f in this.filesListFromInput) {
                    formDataNouvelleDemande.append('justificatifs[]', this.filesListFromInput[f])
                }
            }

            if (this.$props.demande && this.$props.demande.id) {
                formDataNouvelleDemande.set('_method', 'PUT')
                this.$store.dispatch('demanderemboursement/updateDemandeRemboursement', { demande_remboursement_id: this.$props.demande.id, payload: formDataNouvelleDemande })
                    .then(() => {
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 3000
                        }
                        this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                        this.lock_confirm = false
                        this.reset()
                    })
                    .catch((error) => {
                        console.log('ko:' + error)
                        this.lock_confirm = false
                        this.showConfirmationMessage = false
                        if (this.errorHasJustificatifKey(this.$store.state.demanderemboursement.error)) {
                            // reset de la selection de fichiers
                            this.filesListFromInput = []
                            this.justificatifsFileInputKey++ // La modification de la key va faire réséter l'input
                        }
                    })
            } else {
                this.$store.dispatch('demanderemboursement/addDemandeRemboursement', formDataNouvelleDemande)
                    .then(() => {
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 3000
                        }
                        this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                        this.lock_confirm = false
                        this.reset()
                    })
                    .catch((error) => {
                        console.log('ko:' + error)
                        this.lock_confirm = false
                        this.showConfirmationMessage = false
                        if (this.errorHasJustificatifKey(this.$store.state.demanderemboursement.error)) {
                            // reset de la selection de fichiers
                            this.filesListFromInput = []
                            this.justificatifsFileInputKey++ // La modification de la key va faire réséter l'input
                        }
                    })
            }
        }
    }

    // Permet de savoir si l'erreur concerne les pièces justificatives
    errorHasJustificatifKey (Objet: any): boolean {
        let resultat = false
        const parseddatas = JSON.parse(JSON.stringify(Objet))
        const keys = Object.keys(parseddatas)
        for (const key of keys) {
            if (key.includes('justificatif')) {
                resultat = true
                break
            }
        }
        return resultat
    }

    // Select les PJ
    selectFiles (e: Event) {
        if (e.target !== null) {
            const target = e.target as HTMLInputElement
            const files: any = (target.files as FileList)
            for (const f in files) {
                if (files[f].name && !files[f].length) {
                    if (!this.isAlreadyInfilesListFromInput(files[f].name)) {
                        // On ajoute le fichier dans la liste uniquement s'il n'existe pas un fichier du même nom dans la liste
                        this.filesListFromInput.push(files[f])
                    }
                }
            }
            // Reset de l'input qui nous sert juste à sélectionner des fichiers sur l'instant pour alimenter la liste.
            this.justificatifsFileInputKey++ // La modification de la key va faire réséter l'input
        }
    }

    // Vérifie si un nom de fichier existe déjà dans la liste des fichiers filesListFromInput
    isAlreadyInfilesListFromInput (fileNameToSearch: string) {
        let response = false
        if (this.filesListFromInput.length > 0) {
            for (const f in this.filesListFromInput) {
                if (fileNameToSearch === this.filesListFromInput[f].name) {
                    response = true
                    break
                }
            }
            // Reset de l'input qui nous sert juste à sélectionner des fichiers sur l'instant pour alimenter la liste.
            this.justificatifsFileInputKey++ // La modification de la key va faire réséter l'input
        }
        return response
    }

    deleteFromFilesList (index: number) {
        if (index > -1) {
            this.filesListFromInput.splice(index, 1)
        }
    }

    // Selectionne un fichier
    selectFile (e: Event) {
        if (e.target !== null) {
            const target = e.target as HTMLInputElement
            this.fileSingleFromInput = (target.files as FileList)[0]
            // this.formData.set('document', file)
        }
    }

    // Enregistre la décision pour la demande de remboursement
    saveDecision () {
        this.$store.state.demanderemboursement.error = null
        if (this.decision_demande === Etat.ETAT_VALIDE) {
            this.formData.set('etat', this.decision_demande.toString())
            this.formData.set('montant_remboursement', this.montant_remboursement.toString())
            if (this.fileSingleFromInput) {
                // Cas de l'upload pdf ordre de mission
                this.formData.set('document', this.fileSingleFromInput)
            }
        }
        if (this.decision_demande === Etat.ETAT_REJETE) {
            this.formData.set('etat', this.decision_demande.toString())
            this.formData.set('commentaire_rejet', this.commentaire_refus)
        }

        if (this.decision_demande !== null) {
            this.formData.set('_method', 'PUT')
            this.$store.dispatch('demanderemboursement/updateDemandeRemboursement', { demande_remboursement_id: this.$store.state.demanderemboursement.demandeRemboursementSelect.id, payload: this.formData })
        }

        this.reset()
    }

    // Affiche la preview d'un justificatif
    showDocument (document: any, index: number) {
        this.source_doc = null
        // Appel du pdf
        this.$store.dispatch('demanderemboursement/getDocumentDemandeRemboursement', { demande_remboursement_id: this.$store.state.demanderemboursement.demandeRemboursementSelect.id, document_id: document.id }).then(() => {
            this.libelle_document = document.name + ' #' + index.toString()
            this.file_name = this.libelle_document
            this.source_doc = base64ToArrayBuffer(this.$store.state.demanderemboursement.source_pdf)
            this.modePreviewDocument = true
        })
    }

    // Affiche les pièces jointes a la demande de remboursement au format PDF
    showDocumentResp (document: string) {
        const document_select = this.$store.state.demanderemboursement.demandeRemboursementSelect.documents.filter((d: any) => d.type === document)
        this.showDocument(document_select[0], 1)
    }

    // Ferme la preview d'un document
    backDocument () {
        this.modePreviewDocument = false
    }

    // Supprime un document
    deleteDocument (document: any) {
        this.$store.dispatch('demanderemboursement/deleteDocumentDemandeRemboursement', { demande_remboursement_id: this.$store.state.demanderemboursement.demandeRemboursementSelect.id, document_id: document.id }).then(() => {
            this.$store.dispatch('demanderemboursement/getDemandeRemboursement', { demande_remboursement_id: this.$store.state.demanderemboursement.demandeRemboursementSelect.id })
            const idSucces = 't_succes_' + Math.random()
            const succesToaster = {
                id: idSucces,
                toaster: 'b-toaster-top-right',
                variant: 'success',
                noCloseButton: true,
                fade: true,
                autoHideDelay: 3000
            }
            this.$bvToast.toast('Suppression terminée !', succesToaster)
        })
    }

    loadListeOrdreMission () {
        this.list_ordres_mission = this.$store.state.ordremission.ordreMissions.filter((c: any) => parseInt(c.etat) === Etat.ETAT_VALIDE)
        if (this.list_ordres_mission.length === 1) {
            this.ordremission_select = this.list_ordres_mission[0]
            this.aller_moyen_transport = this.ordremission_select.datas.moyen_transport
            this.retour_moyen_transport = this.ordremission_select.datas.moyen_transport
        }
    }

    mounted () {
        if (this.$store.state.ordremission.ordreMissions.length !== 0) {
            this.loadListeOrdreMission()
        } else {
            this.$store.dispatch('ordremission/getOrdreMissions')
        }
    }
}

