



























































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { base64ToArrayBuffer, formatDate, getFileNameFromHeader } from '@/utils/helpers'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import Back from '@/components/Tools/Back.vue'
import { FournitureAdministrativeInterface } from '@/types/FournitureAdministrative'
import { DepotFichier } from '@/types/Document'

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapState('centre', ['centreSelect']),
        ...mapState('document', ['error', 'loading'])
    },
    components: {
        ExaGenericTable,
        ErrorDisplay,
        Back,
        'font-awesome-icon': FontAwesomeIcon
    }
})

export default class ListeDocumentsExaminateur extends Vue
{
    formatDate = formatDate
    Ability = Ability
    is_mobile_device = false
    sortDirection   = 'asc'
    params: any = {}
    filtres: any = []
    exportWorking = false
    read_only = false
    showModalMessageDeleteDocument = false
    showAjoutDocument = false
    documents: Array<FournitureAdministrativeInterface> = []
    genericfields: Array<any> = [
        { key: 'name_document',        label: 'Libellé',       sortable: true,  class: '', type: 'text'   },
        { key: 'commentaire_document', label: 'Commentaire',   sortable: false, class: '', type: 'text'   },
        { key: 'name',                 label: 'Examinateur',   sortable: true,  class: '', type: 'text'   },
        { key: 'type_document',        label: 'Type',          sortable: true,  class: '', type: 'text'   },
        { key: 'matiere_name',         label: 'Matière',       sortable: true,  class: '', type: 'text'   },
        { key: 'created_at',           label: 'Date de dépôt', sortable: true,  class: '', type: 'text'   },
        { key: 'download',             label: '',              sortable: false, class: '', type: 'action' }
    ]

    dataForTab: Array<any>  = []

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }


    /**
     * memorise le fichier selectionné pour le document en cours de création/édition
     */
    documentTemp: any = null
    documentFileTemp: any = null
    selectFile (e: Event) {
        if (e.target !== null && this.documentTemp) {
            const target = e.target as HTMLInputElement
            const file: File = (target.files as FileList)[0]
            this.documentFileTemp = file
        }
    }


    // Création des lignes du tableau
    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                let line: any = []
                const examinateur = `${result.user.name} ${result.user.first_name}`
                const type = result.type[0].toUpperCase() + result.type.slice(1)

                line = [
                    { label: 'Libellé',       item: result.name_document,               type: 'text',   typeAction: null,       class: ''                                                          },
                    { label: 'Commentaire',   item: result.commentaire_document,        type: 'text',   typeAction: null,       class: ''                                                          },
                    { label: 'Examinateur',   item: examinateur,                        type: 'text',   typeAction: null,       class: ''                                                          },
                    { label: 'Type',          item: type,                               type: 'text',   typeAction: null,       class: ''                                                          },
                    { label: 'Matière',       item: result.matiere.name,                type: 'text',   typeAction: null,       class: ''                                                          },
                    { label: 'Date de dépôt', item: formatDate(result.created_at), type: 'text',   typeAction: null,       class: ''                                                          },
                    { label: 'Télécharger',   item: result,                             type: 'action', typeAction: 'download', class: 'text-primary', icon:'arrow-alt-to-bottom', disabled: false }
                ]

                this.dataForTab.push(line)
            }
        }
    }

    setFiltersForGenericTab() {
        const documentTypes = Object.values(DepotFichier)
            .map((key) => { return { index: key, name: key.charAt(0).toUpperCase() + key.slice(1)}})

        this.filtres = [
            {
                libelle: 'Libellé',
                defautOptionlibelle: 'Rechercher un',
                model: 'name_document',
                value: '',
                index: 'name_document',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'name_document' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Commentaire',
                defautOptionlibelle: 'Rechercher un',
                model: 'commentaire_document',
                value: '',
                index: 'commentaire_document',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'commentaire_document' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Examinateur',
                defautOptionlibelle: 'Rechercher un',
                model: 'name',
                value: '',
                index: 'name',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'name' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Type',
                defautOptionlibelle: 'Rechercher un',
                model: 'type_document',
                value: '',
                index: 'type_document',
                datas: documentTypes,
                loading: false,
                options: { type: 'deroulant', fieldsKey: 'type_document' }
            },
            {
                libelle: 'Matière',
                defautOptionlibelle: 'Rechercher une',
                model: 'matiere_name',
                value: '',
                index: 'matiere_name',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'matiere_name' } // 'form' , 'deroulant'
            }
        ]
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'download':
                    this.downloadFile(paParams[1])
                    break
                default:
                    break
            }
        }
    }

    // Applique le chargement de la pagination
    loadHandler (params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            // this.params.user_id = this.$store.state.auth.user.id
            this.$store.dispatch('document/getDocumentsExaminateur', { user_id: null, params: this.params }).then((response) => {
                this.documents  = response.data.data
                this.setDataForGenericTab(this.documents)
            })
        }
    }

    // Applique les filtres
    filtreSortHandler(params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            // this.params.user_id = this.$store.state.auth.user.id
            this.$store.dispatch('document/getDocumentsExaminateur', { user_id: null, params: this.params }).then((response) => {
                this.documents  = response.data.data
                this.setDataForGenericTab(this.documents)
            })
        }
    }


    // ---------------


    // ----- telecharger un document----------

    downloadFile (file: any) {
        let fileName = ''
        this.exportWorking = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Téléchargement en cours...', infosToaster)

        this.$store.dispatch('document/getDocumentExaminateur',  { user_id: file.user_id,  document_id: file.id })
            .then(response => {
                const fileNameTemp = getFileNameFromHeader(response.headers) || 'document_' + file.name_document + '_' + file.id + '.pdf'
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([base64ToArrayBuffer(response.data)]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                this.documentTemp = null
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    load () {
        // Chargement de tous les documents de fournitures administratives
        this.params = {
            page: 1,
            sort: 'name_document',
            direction: 'asc'
            // user_id: this.$store.state.auth.user.id
        }

        this.$store.dispatch('document/getDocumentsExaminateur', { user_id: null, params: this.params }).then((response) => {
            this.setFiltersForGenericTab()
            this.documents  = response.data.data
            this.setDataForGenericTab(this.documents)
        })
    }

    mounted() {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        } else {
            // Renvoi au dashboard pour reselectionné la session
            this.$router.push('/dashboard_i')
        }

        // Detection si le device est un mobile
        if ('ontouchstart' in document.documentElement)
        {
            this.is_mobile_device = true
        }
        else
        {
            this.is_mobile_device = false
        }
    }
}
