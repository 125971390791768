




























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import EditorTinyMCE from '@/components/Tools/EditorTinyMCE.vue'
import { formatDate, isEmailValid } from '@/utils/helpers'
import { Ability } from '@/types/Ability'
import { MessageIndicationType } from '@/types/MessageIndicationType'
import MessageIndication from '@/components/MessageIndication.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    components: {
        EditorTinyMCE,
        MessageIndication,
        ErrorDisplay
    },
    computed: {
        ...mapGetters('auth', ['authUser']),
        ...mapGetters('centre', ['informations_is_submit', 'informations_is_validate']),
        ...mapState('centre', ['error', 'centreSelect']),
        ...mapGetters('auth', ['is_intervenant_centre']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})
export default class MessageCandidatsCentre extends Vue {
    @Prop() context?: number;

    Ability = Ability
    MessageIndicationType = MessageIndicationType
    lockInput = false
    message_rejet = ''
    messageErreurSimple = ''
    showModalConfirmationSoumettre = false
    message_candidatTampon = ''
    errorEmail = ''


    @Watch('centreSelect')
    updateCentreSelect () {
        this.initInterface()
    }

    /** Formatte les dates */
    formatteDate (date: Date) {
        return formatDate(date)
    }

    /** Initialisation du message candidat */
    initInterface () {
        this.getMessageRejetMessage()
        if (this.$store.state.centre.centreSelect) {
            // this.message_candidatTampon = this.$store.state.centre.centreSelect.message_candidat
            if (this.$store.state.centre.centreSelect.message_validated_at) {
                this.lockInput = true
            } else if (this.$store.state.centre.centreSelect.message_submitted_at) {
                this.lockInput = !!this.$store.getters['auth/is_intervenant_centre'];
            } else {
                this.lockInput = false
            }
        }
    }

    setMessageCandidat (e: any) {
        this.message_candidatTampon = e.target.value
    }

    checkEmailValid (e: any) {
        this.errorEmail = isEmailValid(e.target.value).errorEmail
    }

    /** Retourne l'éventuel message de rejet du contenu du message */
    getMessageRejetMessage () {
        if (this.$store.state.centre.centreSelect) {
            if (this.$store.state.centre.centreSelect.commentaire_rejet) {
                if (this.$store.state.centre.centreSelect.commentaire_rejet.message) {
                    this.message_rejet = this.$store.state.centre.centreSelect.commentaire_rejet.message
                }
            }
        }
    }

    editFin () {
        this.save()
    }

    /** Enregistrement du message candidat */
    save () {
        const payload = {
            id: this.$store.state.centre.centreSelect.id,
            // message_candidat: this.message_candidatTampon,
            email_contact_candidat: this.$store.state.centre.centreSelect.email_contact_candidat,
            message_candidat: this.$store.state.centre.centreSelect.message_candidat
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('centre/updateCentre', payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                this.$store.state.centre.error = null
                this.errorEmail = ''
            })
            .catch((error) => {
                this.errorEmail = ''
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /** Enregistrement en tant que Gestionnaire */
    saveMessageBodyContent () {
        const payload = {
            id: this.$store.state.centre.centreSelect.id,
            // message_candidat: this.message_candidatTampon,
            email_contact_candidat: this.$store.state.centre.centreSelect.email_contact_candidat,
            message_candidat: this.$store.state.centre.centreSelect.message_candidat
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.$store.dispatch('centre/updateCentre', payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                this.$store.state.centre.error = null
                this.errorEmail = ''
            })
            .catch((error) => {
                this.errorEmail = ''
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /** Popup de confirmation de la soumission du message */
    openModalConfirmationSoumettre () {
        this.showModalConfirmationSoumettre = true
    }

    /** Annulation de la confirmation de la soumission du message */
    cancelSoumettre () {
        this.showModalConfirmationSoumettre = false
    }

    /** Confirmation de la soumission du message */
    confirmerSoumettre () {
        this.submitMessageBodyContent()
    }

    /** Enregistrement des données ET soumission du contenu du message */
    submitMessageBodyContent () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        const payload = {
            id: this.$store.state.centre.centreSelect.id,
            // message_candidat: this.message_candidatTampon,
            email_contact_candidat: this.$store.state.centre.centreSelect.email_contact_candidat,
            message_candidat: this.$store.state.centre.centreSelect.message_candidat,
            message_submit: 1
        }

        this.$store.dispatch('centre/updateCentre', payload)
            .then(() => {
                this.messageErreurSimple = ''
                this.message_rejet = ''
                this.lockInput = true

                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                this.errorEmail = ''
                this.$store.state.centre.error = null
                this.initInterface()
                this.showModalConfirmationSoumettre = false
            })
            .catch((error) => {
                this.errorEmail = ''
                console.log('ko:' + error)
                this.showModalConfirmationSoumettre = false
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    beforeMount () {
        this.$store.state.centre.error = null
        this.initInterface()
    }
}

