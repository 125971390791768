






















import { Vue, Component } from 'vue-property-decorator'
import { Ability } from '@/types/Ability'
import Sessions from '@/views/Administration/Sessions/Sessions.vue'
import Matieres from '@/views/Administration/Matieres/Matieres.vue'
import ConfigurationPhases from '@/views/Administration/Sessions/ConfigurationPhases.vue'

@Component({
    components: {
        Sessions,
        Matieres,
        ConfigurationPhases
    }

})
export default class Initialisation extends Vue {
    Ability = Ability
    tabSelected = 'sessions'
    modeDetailsSession = false

    showDetailsSessionHanddler (item: any) {
        this.$store.commit('auth/SET_USER_SESSION_ID', Number(item.id))
        window.sessionStorage.setItem('user_session_id', item.id)
        /*
        this.$store.commit('session/SET_SESSION_SELECT', this.$store.state.session.sessions.find((s: any) => s.id === this.$store.state.auth.user_session_id))
        */
        this.$router.push('/session/' + item.id)
    }
}
