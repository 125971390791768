






























































































































































































































import { mapActions, mapGetters } from 'vuex'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { isObject } from '@/utils/helpers'
import { Ability } from '@/types/Ability'
import _ from 'lodash'
import  ErrorDisplay from '@/components/ErrorDisplay.vue'
import { TypeReclamation } from '@/types/Reclamation'

@Component({
    components: {
        ErrorDisplay
    },
    computed: {
        ...mapGetters('reclamation', ['loading', 'reclamationSelect', 'error', 'getNameEpreuveById']),
        ...mapGetters('candidatIncompatible', ['resultatRechercheParCandidat', 'get_meta_recherche_Candidat']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        isFileType(): boolean {
            return this.$store.getters['auth/findParameter']('reclamationMessage')?.value === 'file'
        }
    },
    methods: {
        ...mapActions('postes', ['getAllPostes'])
    }
})

export default class PopupCreerReclamation extends Vue {
    @Prop() show?: boolean;
    @Prop() infosFiliereEpreuveSelect?: any;
    @Prop() type_reclamation?: string

    Ability                             = Ability
    lockInput                           = false
    etape_selection_candidat            = true
    etape_acces_creation_non_disponible = false
    etape_renseigner_reclamation        = false
    etape_ReclamationExistante          = false
    mode_Rouverture                     = false
    mode_UneSeuleReclamation            = false
    options_filieres: any               = []
    options_epreuves: any               = []
    resultat: any                       = []
    meta: any                           = []
    loadSearch                          = false
    firstSearch                         = false
    SelectedCandidat: any               = {} // candidat sélectionné
    messageCandidat                     = '' // message du candidat sélectionné
    epreuveCandidat_Id                  = null // id de l'épreuve du candidat sélectionné
    reclamationSelectLocal: any         = {}
    etat_reclamation_select: any        = {}
    activerRectifiationNote             = false
    concourCandidatName                 = '' // fourni le nom du concours du candidat concerné par la réclamation post-concours.
    fileCandidat: any                   = null

    // Dans le cas de la recherche pour les réclamations et démissions, la recherche est basée sur la filière et non la classe, et pas de user_id associé.
    recherche  = {
        user_id: -1,
        name: '',
        first_name: '',
        etablissement_id: '*',
        classe: '*',
        filiere: '*',
        code: ''
    }

    @Watch('show')
    initInterface(): void {
        if (this.show === true) {
            this.$store.commit('reclamation/SET_ERROR', null)
            this.initfilieresMatieres()
        }
    }

    @Watch('resultatRechercheParCandidat')
    watchResultatRechercheParCandidat(): void {
        this.resultat = this.$store.getters['candidatIncompatible/resultatRechercheParCandidat']
        this.meta     = this.$store.getters['candidatIncompatible/get_meta_recherche_Candidat']
    }

    getFiliereName(concours_id: number): string {
        return this.options_filieres.filter((c: any) => c.index === concours_id)[0].name || ''
    }

    select_epreuve_id(e: any): void {
        this.epreuveCandidat_Id = e.target.value
    }

    initfilieresMatieres(): void {
        // Options filières
        const filieres = this.$store.getters['concour/banques']
        this.options_filieres = []

        for (const f in filieres) {
            this.options_filieres.push({ index: filieres[f].id, name: filieres[f].name })
        }
    }

    /**
     * @description Vérifie si la valeur passée en paramètre est un objet
     * @param {any} value - Valeur à vérifier
     * @returns {any} - Valeur de retour
     */
    isObjectLocal(value: any): any {
        return isObject(value)
    }

    /**
     * @description Retourne la liste passée en paramètre ordonnée par name
     * @param {any} liste - Liste à ordonner
     * @returns {any} - Liste ordonnée
     */
    orderedList(liste: any): any {
        return _.orderBy(liste, 'name', 'asc')
    }

    /**
     * @description Fonction de declenchement de la recherche. reinit resultat
     * @returns {void}
     */
    launchSearch(): void {
        this.loadSearch = true
        this.resultat = []

        /* *** Note *************************************************************************************************
            On utilise ici la base de la requête de recherche qui avait été mise en place pour les incompatibilités.
            Pour un souci de gain de temps, et parce que le retour de la requête fournit directement l'objet établissement du candidat
            dont on a besoin ici.
        ********************************************************************************************************** */
        this.$store.dispatch('candidatIncompatible/RECHERCHE_PAR_CANDIDAT', this.recherche).then(() => {
            this.loadSearch = false
            this.firstSearch = true
        })
    }

    /**
     * @description Récupère les informations de l'établissement du candidat
     * @param {number} etablissement_id - ID de l'établissement
     * @returns {any} - Informations de l'établissement
     */
    getEtablissementCandidat(etablissement_id: number): any {
        const params: any = {
            etablissement_id: etablissement_id
        }
        this.$store.dispatch('etablissement/getEtablissement', params).then((resEtab) => {
            return resEtab.data.data
        })
    }

    /**
     * @description Sauvegarde de la selection de l'utilisateur
     * @param {any} candidat - Candidat sélectionné
     * @returns {void}
     */
    setSelectedCandidat(candidat: any): void {
        this.SelectedCandidat = candidat
    }

    /**
     * @description On confirme le candidat sélectionné pour passer à l'étape suivante de renseignement de la réclamation
     * @returns {void}
     */
    confirmSelectedCandidat(): void {
        const params = { id: this.SelectedCandidat.id }
        this.$store.dispatch('candidat/getCandidat', params).then((responseCandidat) => {
            this.$store.dispatch('definitionDesBarres/getTableauDesConcours').then((responseConcoursPhase) => {
                // vérification de la validation de la barre pour le concour-phase concerné pour le candidat choisi
                const liste = this.getConcoursIdListFromConcoursPhase(responseConcoursPhase.data.data)
                const concourCandidatID = this.getCandidatConcoursId(responseCandidat.data.data.concours, liste)
                let barreAdmissionValidee = false
                const concourPhaseMatch = responseConcoursPhase.data.data.filter((cp: any) => (parseInt(cp.concour_id) === concourCandidatID && parseInt(cp.phase_id) === 2))
                if (concourPhaseMatch.length > 0) {
                    barreAdmissionValidee = concourPhaseMatch[0].deliberation.barreValide !== 0
                }

                if (barreAdmissionValidee) {
                    // Init de la combo des épreuves liées aux inscriptions du candidat
                    // Filtre les épreuves maîtresses et sur la phase admissibilité
                    this.options_epreuves = this.$store.getters['epreuve/getEpreuvePhase_POST_CONCOURS'](responseCandidat.data.data.inscriptions)
                }
                this.etape_selection_candidat = false
                this.etape_acces_creation_non_disponible = false
                this.etape_renseigner_reclamation = true
                this.etape_ReclamationExistante = false
            })
        })
    }

    /**
     * @description Fourni la liste des id des concours contenus dans la liste des concoursPhase
     * @param {any} responseConcoursPhase - Liste des concoursPhase
     * @returns {any} - Liste des id des concours
     */
    getConcoursIdListFromConcoursPhase(responseConcoursPhase: any): any {
        const liste: any = []
        for (const cf of responseConcoursPhase) {
            if (!liste.includes(cf.concour_id)) {
                liste.push(cf.concour_id)
            }
        }
        return liste
    }

    /**
     * @description Récupère au sein de la liste des concours du candidat celui qui fait partie de la liste des concoursPhase
     * @param {any} concoursCandidat - Liste des concours du candidat
     * @param {any} listeConcoursREF - Liste des concoursPhase
     * @returns {any} - ID du concours
     */
    getCandidatConcoursId(concoursCandidat: any, listeConcoursREF: any): any {
        let concourId = null
        this.concourCandidatName = ''
        for (const c of concoursCandidat) {
            if (listeConcoursREF.includes(c.id)) {
                concourId = c.id
                this.concourCandidatName = c.name
                break
            }
        }
        return concourId
    }

    /**
     * @description Ferme la popup avec réinit des variables locales sensibles.
     * @returns {void}
     */
    closeModal(): void {
        this.$store.commit('reclamation/SET_ERROR', null)
        this.etape_selection_candidat = true
        this.etape_acces_creation_non_disponible = false
        this.etape_renseigner_reclamation = false
        this.etape_ReclamationExistante = false
        this.epreuveCandidat_Id = null
        this.messageCandidat = ''
        this.fileCandidat = null

        this.$store.commit('candidatIncompatible/RESET_SEARCH')
        this.SelectedCandidat = {}
        this.firstSearch = false
        this.recherche = {
            user_id: 0,
            name: '',
            first_name: '',
            etablissement_id: '*',
            classe: '*',
            filiere: '*',
            code: ''
        }
        this.$emit('reinitShow', null)
    }

    /**
     * @description Annule la confirmation de création de réclamation existante et revient à l'étape de sélection de l'épreuve
     * @returns {void}
     */
    annulerConfirmationReclamation(): void {
        this.etape_selection_candidat = false
        this.etape_acces_creation_non_disponible = false
        this.etape_renseigner_reclamation = true
        this.etape_ReclamationExistante = false
    }

    manageSaveReclamation(): void {
        if (this.$props.type_reclamation === TypeReclamation.TYPE_NOTE_ECRIT) { // réclamation écrit
            // on vérifie si une réclamation de même candidat_id existe déjà

            /* Pour plus tard
            const reclamationMatch = this.$store.state.reclamation.reclamations_ecrit.filter((r: any) => (parseInt(r.candidat_id) === parseInt(this.SelectedCandidat.id) && parseInt(r.epreuve_id) === parseInt(String(this.epreuveCandidat_Id))))
            */
            const reclamationMatch = this.$store.state.reclamation.reclamations_ecrit.filter((r: any) => (parseInt(r.candidat_id) === parseInt(this.SelectedCandidat.id)))

            if (reclamationMatch.length > 0) {
                /* Pour plus tard
                this.mode_Rouverture = reclamationMatch[0].validated_at // si la réclamation a déjà été validée, on doit la rouvrir, sinon on informe juste l'utilisateur.
                */
                this.mode_Rouverture = false
                this.mode_UneSeuleReclamation = true

                // Ouverture de la div de réclamation existante
                this.etape_selection_candidat = false
                this.etape_acces_creation_non_disponible = false
                this.etape_renseigner_reclamation = false
                this.etape_ReclamationExistante = true
            } else {
            // pas de réclamation déjà existante pour ce contexte, on peut lancer l'enregistrement
                this.saveReclamation()
            }
        } else if (this.$props.type_reclamation === TypeReclamation.TYPE_PASSATION_ORAL) { // réclamation oral
            // on vérifie si une réclamation de même candidat_id et epreuve_id existe déjà
            const reclamationMatch = this.$store.state.reclamationOral.reclamations_oral.filter((r: any) => (parseInt(r.candidat_id) === parseInt(this.SelectedCandidat.id) && parseInt(r.epreuve_id) === parseInt(String(this.epreuveCandidat_Id))))

            if (reclamationMatch.length > 0) {
                this.mode_Rouverture = false
                this.mode_UneSeuleReclamation = false

                // Ouverture de la div de réclamation existante
                this.etape_selection_candidat = false
                this.etape_acces_creation_non_disponible = false
                this.etape_renseigner_reclamation = false
                this.etape_ReclamationExistante = true
            } else {
            // pas de réclamation déjà existante pour ce contexte, on peut lancer l'enregistrement
                this.saveReclamation()
            }
        } else if (this.$props.type_reclamation === TypeReclamation.TYPE_NOTE_ORAL) { // réclamation post-concours
            // on vérifie si une réclamation de même candidat_id existe déjà
            const reclamationMatch = this.$store.state.reclamationPostConcours.reclamations_post_concours.filter((r: any) => (parseInt(r.candidat_id) === parseInt(this.SelectedCandidat.id)))

            if (reclamationMatch.length > 0) {
                this.mode_Rouverture = false
                this.mode_UneSeuleReclamation = true

                // Ouverture de la div de réclamation existante
                this.etape_selection_candidat = false
                this.etape_acces_creation_non_disponible = false
                this.etape_renseigner_reclamation = false
                this.etape_ReclamationExistante = true
            } else {
            // pas de réclamation déjà existante pour ce contexte, on peut lancer l'enregistrement
                this.saveReclamation()
            }
        }
    }

    /**
     * @description Confirmation de la validation / invalidation des affectations
     * @returns {void}
     */
    saveReclamation(): void {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        const payload = new FormData()
        payload.set('candidat_id', this.SelectedCandidat.id)
        payload.set('epreuve_id', this.epreuveCandidat_Id || '')
        payload.set('type', this.$props.type_reclamation)

        if (this.$store.getters['auth/findParameter']('reclamationMessage')?.value === 'file') {
            payload.set('file', this.fileCandidat)
        } else {
            payload.set('message_candidat', this.messageCandidat)
        }

        this.$bvToast.toast('Enregistrement en cours...', infosToaster)
        this.$store.dispatch('reclamation/addReclamation', payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé', succesToaster)
                this.closeModal()
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Récupération du fichier à envoyer
     * @param {Event} event - Événement de changement de fichier
     * @returns {void}
     */
    fileChange(event: Event): void {
        if (event.target !== null) {
            const target = event.target as HTMLInputElement
            this.fileCandidat = (target.files as FileList)[0]
        }
    }

    rouvrirReclamation() {
        // TODO - appel pour rouvrir la réclamation en question lors que le back sera à jour sur ce point.
    }

    /**
     * @description Retourne le name d'une épreuve depuis un getter
     * @param {number} epreuve_id - ID de l'épreuve
     * @returns {string} - Nom de l'épreuve
     */
    get_name_epreuve(epreuve_id: number): string {
        return this.$store.getters['epreuve/getNameEpreuveById'](epreuve_id) || ''
    }
}
