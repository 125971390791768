




















import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'
import { TypePassation } from '@/types/Epreuve'
import { getTypeMessageRejetLibelle, TypeMessageRejet } from '@/types/Centre'
import { Ability } from '@/types/Ability'

@Component({
    components: {
    },
    computed: {
        ...mapGetters('auth', [
            'authUser',
            'can',
            'cannot',
            'isA',
            'isNotA'
        ]),
        ...mapGetters('user', ['centreId']),
        ...mapState('user', ['centreId']),
        ...mapState('epreuve', ['epreuvesCorrections']),
        ...mapGetters('centre', ['centreSelect', 'centre_users_select', 'loading', 'orga_is_validate', 'is_nb_intervenants_validated_at']),
        ...mapState('centre', ['users_count', 'error'])
    },
    methods: {
        ...mapActions('dossieracademique', ['updateDossierAcademique'])
    }
})
export default class ModuleGestionFicheHoraire extends Vue {
    @Prop() centre_id?: number;
    typePassation = TypePassation

    Ability = Ability

    liste_messagesRejet: Array<any> = []
    TYPE_MSG_REJET_SUBMIT = TypeMessageRejet.TYPE_MSG_REJET_SUBMIT
    TYPE_MSG_REJET_ANNEXE = TypeMessageRejet.TYPE_MSG_REJET_ANNEXE
    TYPE_MSG_REJET_INFORMATIONS = TypeMessageRejet.TYPE_MSG_REJET_INFORMATIONS
    TYPE_MSG_REJET_ORGA_ECRIT = TypeMessageRejet.TYPE_MSG_REJET_ORGA_ECRIT
    hasError = false
    fiches_intervenants_published_at = false

    beforeMount () {
        if (this.$props.centre_id) {
            this.$store.dispatch('centre/getCentre', { centre_id: this.$props.centre_id }).then(() => {
                this.getListeRejetsFromCentre()
            })
        }
    }

    @Watch('centre_select')
    init () {
        this.getListeRejetsFromCentre()
    }

    // Retourne la liste des rejets de soumissions éventuelles
    getListeRejetsFromCentre () {
        if (this.$store.state.centre.centreSelect.commentaire_rejet) {
            const parseddatas = JSON.parse(JSON.stringify(this.$store.state.centre.centreSelect.commentaire_rejet))
            const keys = Object.keys(parseddatas)
            keys.forEach(key => {
                this.liste_messagesRejet.push(getTypeMessageRejetLibelle(key))
            })
        }
        return this.liste_messagesRejet
    }

    getColorLink (typeMessage: TypeMessageRejet) {
        let color = 'primary'
        if (this.$store.state.centre.centreSelect.commentaire_rejet) {
            for (const m in this.$store.state.centre.centreSelect.commentaire_rejet) {
                if (m === typeMessage) {
                    color = 'danger'
                    this.hasError = true
                }
            }
        }

        return color
    }
}

