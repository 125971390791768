















































import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Component, Vue } from 'vue-property-decorator'
import InfosEtablissement from '../../components/BourseEmploi/InfosEtablissement.vue'
import ListeCandidatures from '../../components/BourseEmploi/Liste/ListeCandidatures.vue'
import Utilisateurs from './Utilisateurs.vue'
import Back from '../../components/Tools/Back.vue'
import { Ability } from '../../types/Ability'
import Postes from './Postes.vue'
import { mapGetters } from 'vuex'
import { WorkingEstablishmentInterface } from '@/types/WorkingEstablishment'
import { isEmpty } from 'lodash'

enum Tab {
    ETABLISSEMENT = 'etablissement',
    UTILISATEURS = 'utilisateurs',
    POSTES = 'postes',
    CANDIDATURES = 'candidatures'
}

@Component({
    name: 'EtablissementView',
    components: {
        Back,
        InfosEtablissement,
        Utilisateurs,
        Postes,
        ListeCandidatures,
        FontAwesomeIcon
    },
    computed: {
        ...mapGetters('auth', ['can']),
        ...mapGetters('workingEstablishment', ['skipList']),
        jobDescriptionCreationPossible(): boolean {
            return this.$data.establishment?.workingEstablishmentType?.job_description_creation_possible
        }
    },
    watch: {
        '$route.query.tab': {
            immediate: true,
            handler: 'load'
        }
    }
})

export default class Etablissement extends Vue {
    Ability = Ability
    Tab = Tab
    tab = null as Tab | null
    establishment = {} as WorkingEstablishmentInterface

    async setCurrentTab(tab: Tab): Promise<void> {
        if (this.tab !== tab) {
            this.tab = tab
            this.$router.push({ query: { tab: tab } })
        }
    }

    updateWorkingEstablishment(payload: any): void {
        this.establishment = Object.assign({}, payload.data as WorkingEstablishmentInterface)
    }

    async load() {
        const establishment = this.$store.getters['workingEstablishment/workingEstablishmentSelect']
        if (isEmpty(establishment) || establishment?.id !== Number(this.$route.params.working_establishment_id)) {
            await this.$store.dispatch('workingEstablishment/getWorkingEstablishment', {
                working_establishment_id: Number(this.$route.params.working_establishment_id)
            })
        }
        this.establishment = Object.assign({}, this.$store.getters['workingEstablishment/workingEstablishmentSelect'])

        const can = this.$store.getters['auth/can']
        const tab = this.$route.query?.tab
        if ((tab === Tab.ETABLISSEMENT || !tab) && (can(Ability.ADM_ESTABLISHMENT_VIEW) || can(Ability.EST_ESTABLISHMENT_OWN_VIEW) || can(Ability.EST_ESTABLISHMENT_PARENT_OWN_VIEW))) {
            return await this.setCurrentTab(Tab.ETABLISSEMENT)
        } else if ((tab === Tab.UTILISATEURS || !tab) && (can(Ability.ADM_ESTABLISHMENT_VIEW) || can(Ability.EST_INTERV_OWN_VIEW))) {
            return await this.setCurrentTab(Tab.UTILISATEURS)
        } else if ((tab === Tab.POSTES || !tab) && (can(Ability.ADM_ESTABLISHMENT_VIEW) || (can(Ability.EST_JOB_OWN_VIEW) && this.establishment?.workingEstablishmentType?.job_description_creation_possible))) {
            return await this.setCurrentTab(Tab.POSTES)
        } else if ((tab === Tab.CANDIDATURES || !tab) && (can(Ability.ADM_ESTABLISHMENT_VIEW) || (can(Ability.EST_CANDIDACY_OWN_VIEW) && this.establishment?.workingEstablishmentType?.job_description_creation_possible))) {
            return await this.setCurrentTab(Tab.CANDIDATURES)
        } else {
            return await this.$router.push('/')
        }
    }
}
