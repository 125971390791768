




















































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { mapGetters, mapState }         from 'vuex'
import { FontAwesomeIcon }  from '@fortawesome/vue-fontawesome'
import { formatDateHoursMinutes, getWeekDayWithDayMonth } from '@/utils/helpers'
import { EtatPlanificationAuto } from '@/types/Planification'
import { EtatCreneau } from '@/types/Disponibilites'
import { EntityType, getEntityTypeShortFormat, PosteType } from '@/types/Poste'
import { TypePassation } from '@/types/Epreuve'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import PopupGestionSeance from '@/components/Planification/PopupGestionSeance.vue'
import { etat_seance } from '@/types/Seance'

@Component({
    computed: {
        ...mapState('planification', ['tab_count_dispos_P1', 'tab_count_dispos_P2', 'creneaux_dispos_multi', 'planification_en_cours', 'error_planification', 'serie_selected', 'banque_selected', 'ensemble_selected', 'jours_series', 'liste_candidats_planification', 'loading_planification', 'last_page', 'creneaux_dispos', 'creneaux_empty', 'candidat_id_select', 'seance_id_select', 'jours_multi_series']),
        ...mapState('serie', ['series']),
        ...mapGetters('planification', ['getSelectCreneauxDisposMulti'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay,
        PopupGestionSeance
    }
})

export default class CandidatMultiSeries extends Vue
{
    @Prop() candidat?: any
    @Prop() read_only?: boolean
    @Prop() show_chevauchement?: boolean

    getWeekDayWithDayMonth = getWeekDayWithDayMonth
    formatDateHoursMinutes = formatDateHoursMinutes
    EtatCreneau = EtatCreneau
    EtatPlanificationAuto = EtatPlanificationAuto
    etat_seance = etat_seance
    showPopupGestionSeance = false
    candidat_epreuve_select: any = null
    page            = 1
    windowTop       = 0
    maxScroll       = 0
    showPopupInfo = false
    edition_seance = false
    seance_select: any = null


    /**
     * Met en évidence sur le calendrier les disponibilités de l'examinateur pour l'épreuve choisie
     */
    getDisposExaminateur (seance_id: number, candidat_id: number, seance: any) {
        this.$store.state.planification.error_planification = null
        if (this.seance_select !== null && seance_id === this.seance_select.id) {
            // Reset des créneaux dispos la sélection est désélectionnée
            this.$store.commit('planification/RESET_CRENEAUX_DISPOS')
            this.$store.commit('planification/SET_SEANCE_ID_SELECT', null)
            this.seance_select = null
        } else {
            if (this.$store.state.planification.planification_en_cours === EtatPlanificationAuto.EN_COURS) {
                if (!this.$props.read_only) {
                    this.seance_select = seance
                    this.$store.state.planification.creneaux_empty = false
                    this.$store.commit('planification/SET_CANDIDAT_ID_SELECT', candidat_id)
                    this.$store.commit('planification/SET_SEANCE_ID_SELECT', seance_id)
                    this.$store.dispatch('planification/getCreneauxDisposSeries', { seance_id: seance_id }).then(() => {
                        this.$store.getters['planification/getSelectCreneauxDisposMulti'](this.$store.state.serie.series)
                    })
                } else {
                    this.showPopupInfo = true
                }
            }
        }
    }

    /**
     * Ouvre le popup d'édition d'une épreuve non affectée
     */
    editSeance (candidat: any, seance: any, jour: string) {
        this.$store.state.planification.error_planification = null
        if (!this.$props.read_only) {
            this.edition_seance = true
            // Prépare les datas pour l'affichage dans le popup
            const candidat_ensemble = {
                candidat : candidat,
                epreuve: seance.epreuve_name,
                ensemble : this.$store.getters['ensemble/ensembleById'](seance.ensemble_id),
                filiere_ensemble: this.getConcoursEnsemble(seance.ensemble_id),
                creneau : { h_debut: seance.creneau_debut, h_fin: seance.creneau_fin, jour: jour, id: seance.creneau_id },
                examinateur_id: seance.examinateur_id
            }
            this.candidat_epreuve_select = candidat_ensemble

            // Charge les créneaux dispos pour le popup
            this.getDisposExaminateur(seance.id, candidat.id, seance)

            // Peut être null si on est sur une épreuve de TP, on n'affiche pas l'info de l'examinateur dans ce cas là
            if (seance && seance.examinateur_id) {
                const params: any =     {
                    typePoste: PosteType.TYPE_PEDAGOGIQUE,
                    typePassation: TypePassation.TYPE_PASSATION_ORAL,
                    typeEntity: getEntityTypeShortFormat(EntityType.ET_EPREUVE_CORRECTION),
                    from: 'disponibilites'
                }

                params['filter-id'] = seance.examinateur_id
                this.$store.dispatch('examinateur/getExaminateurByID', params).then(() => {
                    this.showPopupGestionSeance = true
                })
            } else {
                this.showPopupGestionSeance = true
            }
        } else {
            this.showPopupInfo = true
        }
    }

    /**
     * Fermeture de le popup de gestion d'une séance
     */
    closeGestionSeance () {
        // Reset des créneaux dispos la modification est terminée
        this.$store.commit('planification/RESET_CRENEAUX_DISPOS')
        this.showPopupGestionSeance = false
        this.edition_seance = false
        // this.$emit('reload')
    }

    /** Retourne une string du ou des concours lié à l'ensemble */
    getConcoursEnsemble (ensemble_id: number) {
        const ensemble_select = this.$store.state.ensemble.ensembles.find((e: any) => e.id === ensemble_id)

        if (ensemble_select && ensemble_select.concour) {
            return ensemble_select.concour.name
        } else {
            return this.$props.chaine_full_concours
        }
    }

    /**
     * Retourne une title formaté pour affichage
     */
    getTitleFormat (seance: any) {
        let title = ''

        if (seance) {
            if (seance.centre) {
                title += seance.centre.name + '\n'
            }
            title += seance.epreuve_name + '\n' + seance.creneau_debut + ' - ' + seance.creneau_fin
            if (seance.etat === etat_seance.ETAT_DEMISSIONE) {
                title += '\n\n** Séance démissionnée **\n'
            }
        }
        return title
    }

    /**
     * Enregistrement de la sélection du créneau
     */
    saveCreneau (e: any) {
        this.$store.state.planification.error_planification = null
        // Sauvegarde du créneau pour le candidat
        this.$store.dispatch('planification/updateSeance', { seance_id: this.$store.state.planification.seance_id_select, creneau_id: Number(e.target.value), mode: 'ADD' }).then(() => {
            // Reset des créneaux dispos l'ajout est terminé
            this.$store.commit('planification/RESET_CRENEAUX_DISPOS')
            this.seance_select = null
        })
    }
}
