































































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
    components: {}
})
export default class DoubleCardGraph extends Vue {
    /* Communes à toutes les cartes */
    @Prop() readonly cardType!: string; // type de carte
    @Prop() readonly title!: string;
    @Prop() readonly link!: string;

    @Prop() readonly text1!: string;
    @Prop() readonly etat1!: number;

    @Prop() readonly text2!: string;
    @Prop() readonly etat2!: number;

    @Prop() readonly text3!: string;
    @Prop() readonly etat3!: boolean;

    @Prop() readonly text4!: string;
    @Prop() readonly etat4!: boolean;

    @Prop() readonly nb_candidats_bloques!: string;
}
