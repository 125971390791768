













































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import store from '@/store/index'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { Ability } from '@/types/Ability'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

@Component({
    components: {
        ExaGenericTable,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('rgpdentity', ['loading', 'error', 'checkRgpd', 'rgpdEntities', 'meta', 'links', 'totalRows']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})

export default class EtatDataRgpd extends Vue
{
    Ability = Ability

    // Ensemble des colonnes du tableau

    genericfields = [
        { key: 'name', label: 'Libellé', sortable: false, class: '', type: 'text' }
    ]

    selected_rgpd_entity = 0
    dataForTab: Array<any> = []


    @Watch('selected_rgpd_entity')
    rgpdEntitiesChangeHandler () {
        this.$store.dispatch('rgpdentity/checkRgpdEntity', this.selected_rgpd_entity).then(() => {
            this.setDataForGenericTab(this.$store.state.rgpdentity.checkRgpd)
        })
    }

    // Remplissage du tableau
    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                const line = [
                    { label: '', item: result.name, type: 'text', typeAction: null, class: '' }
                ]
                this.dataForTab.push(line)
            }
        }
    }

    // Accède à l'interface de gestion des modeles
    gestionModeles () {
        this.$router.push('/rgpdEntities')
    }

    beforeMount () {
        this.load()
    }

    load() {
        store.dispatch('rgpdentity/getRgpdEntities').then(() => {
            this.setDataForGenericTab(this.$store.state.rgpdentity.checkRgpd)
        })
    }
}
