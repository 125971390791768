






















































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import store from '@/store/index'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { getTypeCentreSpec, getEnumTypeCentreForFilterSelect, getTypeCentrePassationSpec, getEtatIntervenant } from '@/types/Centre'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability, getGroupes } from '@/types/Ability'
import PopupEditionCentre from '@/components/Centre/PopupEditionCentre.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { checkIcone } from '@/utils/helpers'
import { TypePassation } from '@/types/Epreuve'
import _ from 'lodash'
import { PosteContext } from '@/types/Poste'

@Component({
    components: {
        ExaGenericTable,
        'font-awesome-icon': FontAwesomeIcon,
        PopupEditionCentre,
        ErrorDisplay
    },
    computed: {
        ...mapGetters('centre', ['loading', 'centres', 'meta', 'links', 'totalRows', 'lastPage', 'totalPage', 'etat_centre_global']),
        ...mapState('centre', ['meta', 'centreSelect', 'error', 'error_conflict']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapState('user', ['abilities'])
    }
})

export default class Centres extends Vue {
    Ability = Ability
    // DATAS
    genericfields: Array<any> = [
        { key: 'edit',         label: '',       sortable: false, class: '', type: 'action' },
        { key: 'etat',         label: 'État',   sortable: true, class: 'text-center', type: 'text' },
        { key: 'name',         label: 'Libellé',    sortable: true, class: '', type: 'text' },
        { key: 'ville.name',   label: 'Ville', sortable: true,  class: '', type: 'text' },
        { key: 'ville.academie',   label: 'Académie', sortable: true,  class: '', type: 'text' },
        { key: 'type',         label: 'Type', sortable: false,  class: 'text-center', type: 'text' },
        { key: 'type_passation',          label: 'Type passation', sortable: false,  class: 'text-center', type: 'text' },
        { key: 'etat_responsable',        label: 'Chef d\'établissement', sortable: false,  class: 'text-center', type: 'text' },
        { key: 'etat_gestionnaire',       label: 'Chef de centre', sortable: false,  class: 'text-center', type: 'text' },
        { key: 'etat_infos',              label: 'Informations', sortable: false,  class: 'text-center', type: 'text' },
        { key: 'delete',                  label: '', sortable: false,  class: '', type: 'text' }
    ]

    dataForTab: Array<any> = []
    filtres: any = []

    centre: any = null
    centreTemp: any = null
    enumTypeCentre: Array<any> = []

    showModalEditionCentre = false
    showModalMessageDelete = false
    showEditionCentre = false
    showModaleGestionRoles = false

    totalCentres = 0
    isNewCentre = false
    params: any = {}
    roles_gci: any = []
    groupes: any = []
    showFormulaireRole = false
    abilitiesList: any = []
    role_select: any = {
        id: 0,
        name: '',
        abilities_name: []
    }

    postes_defaut: any = [null]
    dicoPostes: any = []
    showConfirmDeleteRole = false
    load_role = false

    @Watch('meta')
    majInfosTable() {
        // On récupère le nombre total de dossiers filtrés depuis les infos des Metadonnées
        if (this.$store.getters['centre/meta']) {
            if (this.totalCentres !== this.$store.getters['centre/meta'].total) {
                this.totalCentres = this.$store.getters['centre/meta'].total
            }
        } else {
            this.totalCentres = 0
        }
    }

    @Watch('user_session_id')
    refreshInterface() {
        this.$store.dispatch('centre/getCentres')
            .then(() => this.setDataForGenericTab(this.$store.state.centre.centres))
    }

    @Watch('centres')
    resetDataTable() {
        this.setDataForGenericTab(this.$store.state.centre.centres)
    }

    // METHODS
    /** Ouvre le popup pour créer un centre */
    openAddCentre() {
        this.$store.commit('centre/SET_ERROR', null)
        this.$store.commit('centre/SET_CENTRE_SELECT', null)
        this.isNewCentre = true
        this.showEditionCentre = true
    }

    /** Ouvre le popup pour éditer un centre */
    editCentre(row: any) {
        this.$store.commit('centre/SET_ERROR', null)
        this.$store.dispatch('centre/getCentre', { centre_id: row.id })
            .then(() =>
                this.$store.dispatch('centre/getUsersCount', { centre_id: row.id })
                    .then(() => this.showEditionCentre = true)
            )
    }

    /** Annule l'édition d'un centre */
    cancelEdit() {
        this.isNewCentre = false
        this.centre = null
        this.centreTemp = null
        this.showEditionCentre = false
    }

    /** Ouvre le popup pour supprimer un centre */
    confirmDeleteCentre(row: any) {
        this.$store.commit('centre/SET_ERROR', null)
        this.centre = row
        this.showModalMessageDelete = true
    }

    /** Annule la suppression d'un centre */
    cancelDelete() {
        this.centre = null
        this.showModalMessageDelete = false
    }

    /** Supprime un centre */
    deleteCentre() {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Suppression en cours ...', infosToaster)

        this.$store.dispatch('centre/deleteCentre', this.centre.id)
            .then(async () => {
                this.centre = null
                this.centreTemp = null
                this.showModalEditionCentre = false
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Suppression terminée.', succesToaster)

                this.centre = null
                this.showModalMessageDelete = false
                await this.$store.dispatch('centre/getCentres')
            })
            .catch((error) => console.log('ko:' + error))
            .finally(() => this.$bvToast.hide(idInfo))
    }


    /** Appelle une page lors du scroll */
    loadHandler(params: any) {
        this.$store.dispatch('centre/getMoreCentres', params)
            .then(() => this.setDataForGenericTab(this.$store.state.centre.centres))
    }

    /** Chargement des données du tableau */
    filtreSortHandler(params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            // Des filtres sont appliqués on rappele le serveur

            this.$store.dispatch('centre/getCentres', params)
                .then(() => this.setDataForGenericTab(this.$store.state.centre.centres))
        }
    }

    /** Exporte les centres au format excel */
    exportCentres() {
        const date: Date = new Date()
        const fileName = `export_centres_${date.getFullYear()}${(date.getMonth() + 1 < 10 ? '0' : '')}${date.getMonth() + 1}${date.getDate()}.xlsx`

        this.$store.dispatch('centre/getExportCentres')
            .then((response) => {
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
    }

    // Remplissage du tableau
    setDataForGenericTab(poData: any, isLoadMore = false) {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const result of poData) {
                const can = this.$store.getters['auth/can'](Ability.GC_CENTER_MANAGE)
                const icone = checkIcone(Ability.GC_CENTER_MANAGE, can)
                const puce_etat = []

                puce_etat.push({ name:'circle', class: 'text-' + this.$store.getters['centre/etat_centre_global'](result).color, title: this.$store.getters['centre/etat_centre_global'](result).title })

                const line: any = [
                    { label: icone.label, item: result, type: 'action',  typeAction: 'edit', class: 'commons_first_action_button', icon: icone.icon, disabled: false },
                    { label: '', item: puce_etat,  type: 'icons', typeAction: null, class: 'text-center' }
                ]

                line.push({ label: '', item: result.name, type: 'text', typeAction: null, class: 'text-start' })
                line.push({ label: '', item: result.ville.name, type: 'text', typeAction: null, class: 'text-start' })
                line.push({ label: '', item: result.ville.academie, type: 'text', typeAction: null, class: 'text-start text-uppercase' })
                line.push({ label: '', item: getTypeCentreSpec(result.type).libelle, type: 'text', typeAction: null, class: 'text-center' })
                line.push({ label: '', item: getTypeCentrePassationSpec(result.type_passation).libelle, type: 'text', typeAction: null, class: 'text-center' })

                const puce_responsable = [{ name:'circle', class: 'text-' + getEtatIntervenant(result.etat_responsable).color, title: getEtatIntervenant(result.etat_responsable).libelle }]
                line.push({ label: '', item: puce_responsable,  type: 'icons', typeAction: null, class: 'text-center' })

                const puce_gestionnaire = [{ name:'circle', class: 'text-' + getEtatIntervenant(result.etat_gestionnaire).color, title: getEtatIntervenant(result.etat_gestionnaire).libelle }]
                line.push({ label: '', item: puce_gestionnaire,  type: 'icons', typeAction: null, class: 'text-center' })

                let puce_info = 'tertiary'
                if (result && result.adresses_submitted_at && result.message_submitted_at) {
                    puce_info = 'info'
                } else if (result.commentaire_rejet && result.commentaire_rejet.message_adresses) {
                    puce_info = 'danger'
                }

                if (result && result.adresses_validated_at && result.message_validated_at) {
                    puce_info = 'success'
                }

                const puce_informations = [{ name:'circle', class: 'text-' + puce_info, title: puce_info === 'success' ? 'Informations validées' : (puce_info === 'info' ? 'Informations soumises' : 'Non défini') }]
                line.push({ label: '', item: puce_informations,  type: 'icons', typeAction: null, class: 'text-center' })
                if (can) {
                    line.push({ label: 'Supprimer', item: result, type: 'action', typeAction: 'delete', class: 'text-secondary commons_delete_action_button delete_button', icon:'trash-alt', disabled: false })
                } else {
                    line.push({ label: '', item: null, type: 'text', typeAction: null, class: '' })
                }

                this.dataForTab.push(line)
            }
        }
    }

    // Events
    handleTableEvent(paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'edit':
                    this.editCentre(paParams[1])
                    break
                case 'sortHandler':
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'delete' :
                    this.confirmDeleteCentre(paParams[1])
                    break
            }
        }
    }

    setFilters() {
        const types = getEnumTypeCentreForFilterSelect()
        const options_types = []
        for (const t in types) {
            options_types.push({ index: types[t].id, name: types[t].name })
        }

        const options_types_passation = []
        options_types_passation.push({ index: TypePassation.TYPE_PASSATION_ECRIT, name: 'Ecrit' })
        options_types_passation.push({ index: TypePassation.TYPE_PASSATION_ORAL, name: 'Oral' })

        const options_etat = [
            { index: 'cree', name: 'Centre créé' },
            { index: 'valide', name: 'Centre validé' },
            { index: 'non valide', name: 'Centre non validé' },
            { index: 'rejete', name: 'Corrections attendues' }
        ]

        this.filtres = [
            { libelle: 'état', defautOptionlibelle: 'Rechercher un', model: 'etat',       value: '', index: 'etat',  datas: options_etat, loading: this.$store.getters['centre/loading'], options: { type: 'deroulant', fieldsKey: 'etat' } },
            { libelle: 'libellé', defautOptionlibelle: 'Rechercher un', model: 'name',       value: '', index: 'name',  datas: '', loading: this.$store.getters['centre/loading'], options: { type: 'form', fieldsKey: 'name', strict: false } },
            { libelle: 'ville', defautOptionlibelle: 'Rechercher une',  model: 'ville.name', value: '', index: 'ville.name',  datas: '', loading: this.$store.getters['centre/loading'], options: { type: 'form', fieldsKey: 'ville.name', strict: false } },
            { libelle: 'académie', defautOptionlibelle: 'Rechercher une',  model: 'ville.academie', value: '', index: 'ville.academie',  datas: '', loading: this.$store.getters['centre/loading'], options: { type: 'form', fieldsKey: 'ville.academie', strict: false } },
            { libelle: 'type', defautOptionlibelle: 'Rechercher un',    model: 'type',       value: '', index: 'type',  datas: options_types, loading: this.$store.getters['centre/loading'], options: { type: 'deroulant', fieldsKey: 'type' } },
            { libelle: 'type de passation', defautOptionlibelle: 'Rechercher un', model: 'type_passation',       value: '', index: 'type_passation',  datas: options_types_passation, loading: this.$store.getters['centre/loading'], options: { type: 'deroulant', fieldsKey: 'type_passation' } }
        ]
    }

    // Ajoute un rôle
    add_role_centre() {
        this.postes_defaut = []
        this.role_select = {
            id: 0,
            name: '',
            abilities_name: []
        }
        this.$store.dispatch('user/getAbilities')
            .then(() => {
                // Chargement des dépendances des abilities
                this.$store.dispatch('user/getDependencies')
                    .then(() => {
                        this.createGroupeAbilities(this.$store.state.user.abilities.filter((a: any) => a.entity_type === null))
                        this.showFormulaireRole   = true
                    })
            })
    }

    // Editer un rôle
    edit_role_centre(role: any) {
        this.load_role = true
        this.postes_defaut = []
        this.$store.dispatch('user/getAbilities')
            .then(() => {
                // Chargement des dépendances des abilities
                this.$store.dispatch('user/getDependencies')
                    .then(() => {
                        this.createGroupeAbilities(this.$store.state.user.abilities.filter((a: any) => a.entity_type === null))
                        this.role_select.id = role.id
                        this.role_select.name = role.name
                        this.role_select.abilities_name = []

                        for (const a in role.abilities) {
                            this.role_select.abilities_name.push(role.abilities[a].name)
                        }

                        if (role.postes.length !== 0) {
                            this.postes_defaut = []
                            for (const p in role.postes) {
                                this.postes_defaut.push(role.postes[p].id)
                            }
                        } else {
                            this.postes_defaut = [null]
                        }

                        this.showFormulaireRole = true
                        this.load_role = false
                    })
            })
    }

    // Supprimer un rôle
    delete_role_centre(role: any) {
        this.showFormulaireRole = false
        this.role_select = role
        this.showConfirmDeleteRole = true
    }

    // Annule la suppression d'un rôle
    cancel_delete_role_centre() {
        this.role_select = {
            id: 0,
            name: '',
            abilities_name: []
        }
        this.showConfirmDeleteRole = false
    }

    // Confirmation de la suppression d'un rôle
    confirm_delete_role_centre() {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Suppression en cours ...', infosToaster)

        // Suppression d'un rôle
        this.$store.dispatch('user/deleteRoleGCI', { role_id: this.role_select.id })
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Suppression terminée !', succesToaster)

                this.$store.dispatch('user/getRolesGCI')
                    .then(() => {
                        const indexR = this.roles_gci.findIndex((r: any) => r.id === this.role_select.id)
                        this.roles_gci.splice(indexR, 1)
                        this.showConfirmDeleteRole = false
                    })
            })
            .finally(() => this.$bvToast.hide(idInfo))
    }

    // Creer la collection groupe / abilities
    createGroupeAbilities(abilities: any) {
        this.abilitiesList = abilities
        this.groupes = getGroupes()
        this.groupes = this.groupes.filter((g: any) => g.prefixe === 'gci')

        for (const a in this.abilitiesList) {
            let split_ability = this.abilitiesList[a].name.split('.')
            let split_name = null

            if (split_ability[0] === 'gci') {
                if (!split_ability[1]) {
                    split_name = split_ability[0].split('-')
                } else {
                    split_name = split_ability[1].split('-')
                }

                if (split_ability[0].indexOf('-') !== -1) {
                    split_ability = split_ability[0].split('-')
                }

                let groupe_select = this.groupes.find((g: any) => g.prefixe === split_ability[0])

                if (split_ability[1].indexOf('own') !== -1 && split_ability[0] !== 'gci') {
                    // On sélectionne le groupe autre permissions
                    groupe_select = this.groupes.find((g: any) => g.prefixe === 'own')
                }

                if (groupe_select) {
                    if (!groupe_select.abilities[split_name[0]]) {
                        groupe_select.abilities[split_name[0]] = []
                    }

                    groupe_select.abilities[split_name[0]].push(this.abilitiesList[a])
                    groupe_select.abilities[split_name[0]] = _.orderBy(groupe_select.abilities[split_name[0]], 'name', 'desc')
                }
            }
        }
    }

    // Ouvre la gestion des rôles pour les centres
    openGestionRoles () {
        this.showFormulaireRole = false
        this.roles_gci = []
        this.$store.dispatch('user/getRolesGCI')
            .then(() => {
                this.roles_gci = []
                for (const r in this.$store.state.user.roles) {
                    const permissions = this.$store.state.user.roles[r].abilities.filter((r: any) => r.name.indexOf('gci') !== -1)
                    if (permissions.length !== 0) {
                        this.roles_gci.push(this.$store.state.user.roles[r])
                        this.$store.dispatch('poste/getPostes', { type: null, context: (PosteContext.CONTEXT_CHEF_CENTRE + PosteContext.CONTEXT_RESPONSABLE_CENTRE) })
                            .then(() => {
                                this.dicoPostes = this.$store.state.poste.postes
                                this.showModaleGestionRoles = true
                            })
                    }
                }
            })
    }

    // Ferme la gestion des rôles
    cancelGestionRoles() {
        this.role_select = {
            id: 0,
            name: '',
            abilities_name: []
        }
        this.postes_defaut = [null]
        this.showFormulaireRole = false
        this.showConfirmDeleteRole = false
        this.showModaleGestionRoles = false
    }

    // Set les value depuis le formulaire
    updateChamps(e: any) {
        switch (e.target.name) {
            case 'name':
                this.role_select.name = e.target.value
                break
        }
    }

    // Check / decheck les abilities du user
    checkAbilities(e: any) {
        const ability_name = e.target.value
        const isChecked = e.target.checked

        if (isChecked) {
            this.role_select.abilities_name.push(ability_name)
        } else {
            const index = this.role_select.abilities_name.findIndex((e: any) => e === ability_name)
            this.role_select.abilities_name.splice(index, 1)
        }
    }

    // Coche toutes les abilities d'un groupe
    checkAll(groupe: any) {
        for (const a in groupe.abilities) {
            for (const ab in groupe.abilities[a]) {
                this.role_select.abilities_name.push(groupe.abilities[a][ab].name)
            }
        }
    }


    // Décoche toutes les abilities d'un groupe
    uncheckAll(groupe: any) {
        // Isole les abilities n'étant pas du groupe sélectionné
        this.role_select.abilities_name = this.role_select.abilities_name
            .filter((e: any) => e.indexOf(groupe.prefixe) === -1)
    }

    // Enregistre ou met a jour un role de centre
    save_role_centre() {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        const data: any = {
            name: this.role_select.name,
            abilities: this.role_select.abilities_name,
            postes: this.postes_defaut[0] !== null ? this.postes_defaut : []
        }

        if (this.role_select.id === 0) {
            // Création d'un role
            this.$store.dispatch('user/addRoleGCI', data)
                .then(async (response) => {
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                    this.roles_gci.push(response.data.data)
                    await this.$store.dispatch('user/getRolesGCI')
                })
                .finally(() => this.$bvToast.hide(idInfo))
        } else {
            // Modification de l'utilisateur
            this.$store.dispatch('user/updateRoleGCI', { role_id: this.role_select.id, payload: data })
                .then(async (response) => {
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé !', succesToaster)

                    const reponse = response.data.data
                    const roleIndex = this.roles_gci.findIndex((role: { id: any }) => role.id === reponse.id)
                    if (roleIndex !== -1) {
                        this.roles_gci[roleIndex] = reponse
                    }

                    await this.$store.dispatch('user/getRolesGCI')
                })
                .finally(() => this.$bvToast.hide(idInfo))
        }
    }

    // Check les ability et dependences
    is_disabled(ability: any) {
        if (this.$store.getters['auth/can'](Ability.GC_CENTER_MANAGE)) {
            // Cherche si l'ability à des dépendences
            const dep = this.$store.state.user.dependencies[ability.name]
            if (dep?.length) {
                // Check si la dépendence est déjà cochée
                if (this.role_select.abilities_name.find((a: any) => a === dep[0])) {
                    return { disabled: false, title: '' }
                } else {
                    // Dépendence non cochée -> coche disabled
                    // Si l'ability était cochée, on la décoche
                    const index = this.role_select.abilities_name.findIndex((e: any) => e === ability.name)
                    if (index !== -1) {
                        this.role_select.abilities_name.splice(index, 1)
                    }
                    const title_dep = this.$store.state.user.abilities.find((e: any) => e.name === dep[0])
                    let prefixe_dep = ''
                    if (title_dep.name.indexOf('view') !== -1) {
                        prefixe_dep = 'Consultation'
                    } else if (title_dep.name.indexOf('manage') !== -1) {
                        prefixe_dep = 'Gestion'
                    } else if (title_dep.name.indexOf('sign') !== -1 && title_dep.name.indexOf('signature') === -1) {
                        prefixe_dep = 'Signature'
                    }

                    return { disabled: true, title: 'Nécessite la permission : ' + prefixe_dep + ' - ' + title_dep.title }
                }
            } else {
                return { disabled: false, title: '' }
            }
        } else {
            // Si l'ability était cochée, on la décoche
            const index = this.role_select.abilities_name.findIndex((e: any) => e === ability.name)
            if (index !== -1) {
                this.role_select.abilities_name.splice(index, 1)
            }

            return { disabled: true, title: '' }
        }
    }

    load() {
        this.setFilters()
    }

    mounted() {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }

    /** renvois le nom de la classe css afin de changer l'ordre d'affichage  */
    reorderAbility(nameOfAbility: any) {
        let cssClass = 'ability_name_view'
        if (nameOfAbility.indexOf('view') !== -1) {
            cssClass = 'ability_name_view'
        } else if (nameOfAbility.indexOf('manage') !== -1) {
            cssClass = 'ability_name_manage'
        } else if (nameOfAbility.indexOf('sign') !== -1) {
            cssClass = 'ability_name_sign'
        }
        return cssClass
    }
}
