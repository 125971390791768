




































































































































































import { Vue, Component, Watch }    from 'vue-property-decorator'
import { mapGetters, mapState }     from 'vuex'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'
import { Ability }                  from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { formatDate, formatDateDocument } from '@/utils/helpers'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import Back from '@/components/Tools/Back.vue'


@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapState('centre', ['centreSelect', 'error'])
    },
    components: {
        ExaGenericTable,
        ErrorDisplay,
        Back,
        'font-awesome-icon': FontAwesomeIcon
    }
})

export default class IndisposReceptionLivraison extends Vue
{
    formatDate = formatDate
    Ability = Ability
    showConfirmationSoumissionIndispos = false
    is_mobile_device = false

    params: any = {}
    exportWorking = false
    read_only = false
    indisponibilites: any = []

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    @Watch('sessionSelect')
    refreshStateInventaire () {
        this.getStateIndispos()
    }

    // Sélectionne toute une journée de d'indispos
    select_full_day (e: any) {
        const dispo: any = this.indisponibilites.find((i: any) => i.jour === e.target.id)
        dispo.full = e.target.checked
        dispo.matin = e.target.checked
        dispo.aprem = e.target.checked
    }

    // Sauvegarde la saisie des indispos
    save_indispos () {
        this.$store.commit('centre/SET_ERROR_FULL', null)
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours...', infosToaster)

        // 0 -> matin | 1 -> aprem | 2 -> full day
        const indispos: any = []
        for (const i in this.indisponibilites) {
            let matin_aprem = null
            if (this.indisponibilites[i].full || (this.indisponibilites[i].matin && this.indisponibilites[i].aprem)) {
                matin_aprem = 2
            } else if (this.indisponibilites[i].matin) {
                matin_aprem = 0
            } else if (this.indisponibilites[i].aprem) {
                matin_aprem = 1
            }

            if (matin_aprem !== null) {
                indispos.push({
                    jour: this.indisponibilites[i].date,
                    matin_aprem: matin_aprem
                })
            }
        }

        const payload = {
            id: this.$store.state.centre.centreSelect.id,
            indisponibilite_fournitures: indispos
        }

        this.$store.dispatch('centre/updateCentre', payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Affiche le message de confirmation de la soumission des indispos
    soumettre_indispos () {
        this.showConfirmationSoumissionIndispos = true
    }

    // Retourne l'état de publication de l'inventaire
    getStateIndispos () {
        let state_livraison = { message: '', bg_color: 'barre_default', text_color: '' }
        if (this.$store.state.session.sessionSelect) {
            if (!this.$store.state.session.sessionSelect.livraison_start_at && !this.$store.state.session.sessionSelect.livraison_end_at) {
                state_livraison = { message: 'La saisie des indisponibilités de réception est fermée.', bg_color: 'barre_default', text_color: 'text-secondary' }
            } else {
                state_livraison = {
                    message: 'Merci d\'indiquer ci-dessous vos indisponibilités de réception de livraison des fournitures pour la période du <b>' + formatDateDocument(this.$store.state.session.sessionSelect.livraison_start_at) + '</b> au <b>' + formatDateDocument(this.$store.state.session.sessionSelect.livraison_end_at) + '</b>',
                    bg_color: 'barre_soumis',
                    text_color: 'barre_text_soumis' }
            }
        }

        return state_livraison
    }

    // Confirme la soumission de l'inventaire aux gestionnaires
    confirmSoumettreIndispos () {
        this.$store.commit('centre/SET_ERROR_FULL', null)
        const payload = {
            id: this.$store.state.centre.centreSelect.id,
            indispo_fournitures_validate : 1
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours...', infosToaster)

        this.$store.dispatch('centre/updateCentre', payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                this.read_only = true
                this.load()
                this.showConfirmationSoumissionIndispos = false
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Annule la soumission de l'inventaire
    cancelSoumettreIndispos () {
        this.showConfirmationSoumissionIndispos = false
    }

    // Retourne la couleur de barre et la couleur de texte selon état
    get_barre_default () {
        let barre_texte = {
            barre: 'barre_default',
            color: '',
            text: ''
        }

        if (this.$store.state.centre.centreSelect.indispo_fournitures_validated_at) {
            barre_texte = {
                barre: 'barre_soumis',
                color: 'barre_text_soumis',
                text: 'Vos indisponibilités de réception de livraison ont été soumise le <b>' + formatDate(this.$store.state.centre.centreSelect.indispo_fournitures_validated_at) + '</b>.<br/>Merci de contacter le concours pour toute demande.'
            }
        }

        return barre_texte
    }

    load () {
        this.$store.dispatch('centre/getCentre', { centre_id: this.$route.params.centre_id }).then(() => {
            // Récupérer les jours entre la période de livraison pour affichage des dates
            if (this.$store.state.session.sessionSelect.livraison_start_at) {
                this.indisponibilites = this.$store.getters['centre/create_jours_livraison'](this.$store.state.session.sessionSelect.livraison_start_at, this.$store.state.session.sessionSelect.livraison_end_at)

                // Maj les flags pour affichage si déjà enregistré
                if (this.$store.state.centre.centreSelect.indisponibiliteFournitures) {
                    for (const i in this.indisponibilites) {
                        const search_day = this.$store.state.centre.centreSelect.indisponibiliteFournitures.find((d: any) => d.jour === this.indisponibilites[i].date)

                        if (search_day) {
                            switch(search_day.matin_aprem) {
                                case 0:
                                    this.indisponibilites[i].matin = 1
                                    break
                                case 1:
                                    this.indisponibilites[i].aprem = 1
                                    break
                                case 2:
                                    this.indisponibilites[i].full = 1
                                    this.indisponibilites[i].matin = 1
                                    this.indisponibilites[i].aprem = 1
                            }

                        }
                    }
                }
            }
            if (this.$store.state.centre.centreSelect.indispo_fournitures_validated_at !== null || this.$store.getters['auth/cannot'](Ability.GCI_FOURNITURES_OWN_MANAGE)) {
                this.read_only = true
            }
        })
    }

    mounted() {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        } else {
            // Renvoi au dashboard pour reselectionné la session
            this.$router.push('/dashboard_i')
        }

        // Detection si le device est un mobile
        if ('ontouchstart' in document.documentElement)
        {
            this.is_mobile_device = true
        }
        else
        {
            this.is_mobile_device = false
        }
    }
}
