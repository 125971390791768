













import { Vue, Component }               from 'vue-property-decorator'
import { mapGetters, mapState }         from 'vuex'
import SaisieIntervenants               from '@/components/Centre/Intervenants/SaisieIntervenants.vue'
import SaisieFicheHoraireIntervenants   from '@/components/Centre/Intervenants/SaisieFicheHoraireIntervenants.vue'
import SignatureFichesHoraire           from '@/components/Centre/Intervenants/SignatureFichesHoraire.vue'
import MessageGeneralIntervenant        from '@/components/MessageGeneralIntervenant.vue'


@Component({
    computed: {
        ...mapGetters('auth', ['authUser']),
        ...mapGetters('centre', ['centreSelect', 'centre_users_select', 'loading']),
        ...mapState('centre', ['users_count', 'error'])
    },
    components: {
        SaisieIntervenants,
        SaisieFicheHoraireIntervenants,
        SignatureFichesHoraire,
        MessageGeneralIntervenant
    }
})

export default class Intervenants extends Vue {
    libelle = 'Gestion des intervenants'
    setLibelle (libelle = '') {
        if (libelle !== '') {
            this.libelle =  libelle
        } else {
            this.libelle = 'Gestion des intervenants'
        }
    }
}
