

































































import { Vue, Component, Prop } from 'vue-property-decorator'
import ItemIntervenant from '@/components/ItemIntervenant.vue'
import { mapGetters } from 'vuex'
import { DecisionIntervenant, Etat, getEtatSpec } from '@/types/DossierAcademique'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

@Component({
    components: {
        ItemIntervenant,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('dossieracademique', ['dossiersAcademiques'])
    }
})
export default class ModuleDossiersAcademiques extends Vue {
    @Prop() user?: any;
    etat_vierge = Etat.ETAT_VIERGE
    etat_depose = Etat.ETAT_DEPOSE
    etat_valide = Etat.ETAT_VALIDE
    etat_rejete = Etat.ETAT_REJETE

    decision_intervenant_masquer = DecisionIntervenant.DECISION_INTERVENANT_MASQUE
    decision_intervenant_attente = DecisionIntervenant.DECISION_INTERVENANT_ATTENTE
    decision_intervenant_oui = DecisionIntervenant.DECISION_INTERVENANT_OUI
    decision_intervenant_non = DecisionIntervenant.DECISION_INTERVENANT_NON

    showMessageIndispo = false
    dossier_id_select = 0
    messageIndispo = ''

    // Retourne une description selon l'état du dossier
    getDescription (etat: number, date_validation: Date) {
        let description = ''
        switch (etat) {
            case Etat.ETAT_VIERGE :
                description = 'Le responsable des recrutements vous invite à remplir un dossier de candidature.'
                break
            case Etat.ETAT_DEPOSE :
                description = 'Votre dossier de candidature est complet, vous devez maintenant le soumettre afin que le responsable des recrutements puisse l\'étudier.'
                break
            case Etat.ETAT_VALIDE :
                description = 'Votre dossier de candidature est complet. '
                break
            case Etat.ETAT_REJETE :
                description = 'Certaines de vos informations nécessitent votre attention.'
                break
        }

        if (date_validation !== null && etat === Etat.ETAT_DEPOSE) {
            description = 'Votre dossier de candidature est complet.'
        }

        return description
    }

    // Retourne la couleur du dossier selon son état
    getVariantColor (etat: Etat) {
        return getEtatSpec(etat).color
    }

    // Update la disponibilité de l'intervenant : pas disponible
    updateDisponibilite (dossier_id: number) {
        const datas = {
            decision_intervenant_selection: DecisionIntervenant.DECISION_INTERVENANT_NON,
            commentaire_rejet_cle: 'decision_selection_intervenant',
            commentaire_rejet_message: this.messageIndispo
        }

        this.$store.dispatch('dossieracademique/updateDossierAcademique', { dossier_id: dossier_id, payload: datas }).then(() => {
            this.dossier_id_select = 0
            this.showMessageIndispo = false
            this.messageIndispo = ''
            this.$store.dispatch('dossieracademique/getDossiersAcademiques')
        })
    }

    // Accede au dossier
    // Update la disponibilite et redirige sur le dossier academique
    accederDossier (dossier_id: number) {
        this.$store.dispatch('dossieracademique/updateDossierAcademique', { dossier_id: dossier_id, payload: { decision_intervenant_selection: DecisionIntervenant.DECISION_INTERVENANT_OUI } }).then(() => {
            this.$router.push('dossier_academique/' + dossier_id)
        })
    }

    // Affiche la zone de saisie d'un message d'indisponibilité
    add_message_indispo (dossier_id: number) {
        this.showMessageIndispo = true
        this.dossier_id_select = dossier_id
    }

    // Annule la saisie du message d'indisponibilité
    cancelMessageIndispo () {
        this.dossier_id_select = 0
        this.showMessageIndispo = false
    }

    // Logout
    logout () {
        this.$store.dispatch('auth/logout').then(() => {
            this.$store.dispatch('reset')
        })
    }
}
