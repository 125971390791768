


























































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Watch }    from 'vue-property-decorator'
import { mapGetters, mapState }     from 'vuex'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'
import { Ability }                  from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { base64ToArrayBuffer, diffTwoDatesInMinutes, formatDate, formatDateYYYYMMDD, formatDayHourZDateFromString, formatEndOfDayHourZDateFromString, getFileNameFromHeader, numberWithSpaces } from '@/utils/helpers'
import  ErrorDisplay from '@/components/ErrorDisplay.vue'
import { getStatutReception, StatutReception } from '@/types/Centre'
import { isNull } from 'lodash'
import { FournitureAdministrativeInterface } from '@/types/FournitureAdministrative'
import moment from 'moment'
import { getTypeAmenagement, TypeAmenagement } from '@/types/Salle'
import { TypePassation } from '@/types/Epreuve'

@Component({
    methods: { getTypeAmenagement, isNull },
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapState('repartitioncandidats', ['liste_centres', 'lastPage', 'totalRows', 'loading_repartition']),
        ...mapGetters('repartitioncandidats', ['get_centres_satures']),
        ...mapGetters('concour', ['banques']),
        ...mapGetters('tournee', ['tournees']),
        ...mapGetters('conditionnement', ['conditionnements']),
        ...mapGetters('centre', ['error']),
        ...mapState('ville', ['zones']),
        ...mapState('session', ['sessionSelect'])
    },
    components: {
        ExaGenericTable,
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay
    }
})

export default class SuiviFournitures extends Vue {
    formatDate = formatDate
    Ability = Ability
    getStatutReception = getStatutReception
    numberWithSpaces = numberWithSpaces
    StatutReception = StatutReception

    // Ensemble des colonnes du tableau
    genericfields: any = []

    sortBy          = '';
    sortDesc        = false;
    sortDirection   = 'asc';
    filter          = '';
    filterOn        = [];
    stickyHeader    = true;
    params: any = {
        page: 1,
        sort: 'name',
        direction: 'asc'
    }

    filtres:    any         = []
    dataForTab: Array<any>  = [
    ]

    exportingIsWorking = false

    infos: any = null

    showPopupPublicationInventaire = false
    showPopupRelanceCentre = false
    showPopupMargeCommande = false
    showPopupConditionnement = false
    showPopupStatutCentre = false
    showPopupOptionsZones = false
    selectedDate = {
        userSelectDateType: 'closeInput',
        dateBetween: {
            date_start: '',
            date_end: ''
        },
        onlyEndDate: {
            date_end: ''
        }
    }

    betweenError = ''
    onlyEndDateError = ''

    params_marge: any = {
        sujet_commande_marge_securite: {},
        sujet_commande_supplementaires_min: {},
        sujet_commande_supplementaires_max: {}
    }

    params_conditionnement: any = {
        name: null,
        type: null,
        tournee: null,
        poids: null,
        conditionnements: null
    }

    type_conditionnement: any = []
    capacity_overflow = true
    showGestionNombreSujets = false
    filiere_gestion_nombre_sujets = null
    marge_securite = 0
    sujet_supplementaire = 0
    liste_epreuves_conditionnement: Array<any> = []
    listeSujets: Array<any> = []
    listeSujetsOriginal: Array<any> = []
    totalPagesImprimees = 0

    options_filieres: Array<any> = []
    type_statut = ''
    statut_centre: any = null
    info_statut_centre = ''
    options_zones_export: any = []
    save_in_progress = false

    /**
     * refreshEtatInventaire
     * @description Rafraichir l'état de l'inventaire
     * @return {void}
     *
     */
    @Watch('sessionSelect')
    refreshEtatInventaire(): void {
        this.getStateInventaire()
    }

    /**
     * refreshInterface
     * @description Rafraichir l'interface
     * @return {void}
     */
    @Watch('user_session_id')
    refreshInterface(): void {
        this.$store.commit('repartitioncandidats/SET_LOADING', true)
        this.load()
    }

    /**
     * resetDataTable
     * @description Rafraichir le tableau
     * @return {void}
     */
    @Watch('liste_centres')
    resetDataTable(): void {
        // Fields du tableau
        this.genericfields = [
            { key: 'name', label: 'Centre', sortable: true, class: '', type: 'text' },
            { key: 'filiere', label: 'Filière', sortable: false, class: '', type: 'text' },
            { key: 'ville', label: 'Ville', sortable: true, class: '', type: 'text' },
            { key: 'tournee', label: 'Tournée', sortable: true, class: '', type: 'text' },
            { key: 'f_poids', label: 'Poids des fournitures (kg)', sortable: false, class: 'text-center', type: 'text' },
            { key: 'conditionnement', label: 'Conditionnement', sortable: false, class: 'text-center', type: 'action' }
        ]

        // Compteur de colonnes pour indiquer les centres ok - AR CENTRE VERT
        const compteur_sujets_ok = this.$store.getters['repartitioncandidats/liste_centres'].filter((c: any) => c.f_suj_statut === StatutReception.AR_CENTRE && c.sujets_pb_count === 0)
        const compteur_pap_ok = this.$store.getters['repartitioncandidats/liste_centres'].filter((c: any) => c.f_pap_statut === StatutReception.AR_CENTRE && c.fourniture_papetieres_pb_count === 0)
        const compteur_fa_ok = this.$store.getters['repartitioncandidats/liste_centres'].filter((c: any) => c.f_adm_statut === StatutReception.AR_CENTRE && c.fourniture_administratives_pb_count === 0)

        this.genericfields.push({ key: 'sujets', label: compteur_sujets_ok.length + ' / ' + this.$store.getters['repartitioncandidats/liste_centres'].length, sortable: false, class: 'text-center text-info', type: 'text', doubleHeaderLabel: 'Sujets', doubleHeaderColSpan: 1, doubleHeaderClass: 'text-center' })
        this.genericfields.push({ key: 'fournitures_papetieres', label: compteur_pap_ok.length + ' / ' + this.$store.getters['repartitioncandidats/liste_centres'].length, sortable: false, class: 'text-center text-info', type: 'text', doubleHeaderLabel: 'Fournitures papetières', doubleHeaderColSpan: 1, doubleHeaderClass: 'text-center' })
        this.genericfields.push({ key: 'fournitures_administratives', label: compteur_fa_ok.length + ' / ' + this.$store.getters['repartitioncandidats/liste_centres'].length, sortable: false, class: 'text-center text-info', type: 'text', doubleHeaderLabel: 'Fournitures administratives', doubleHeaderColSpan: 1, doubleHeaderClass: 'text-center' })
        if (this.genericfields.length !== 0) {
            this.setDataForGenericTab(this.$store.getters['repartitioncandidats/liste_centres'])
        }
    }

    /**
     * buildInfoStatut
     * @description Construit le message d'information pour le changement de statut
     * @return {void}
     */
    @Watch('statut_centre')
    buildInfoStatut(): void {
        if (this.statut_centre === StatutReception.EN_PRODUCTION) {
            this.info_statut_centre = 'Les centres ayant les salles validées et dont le statut est actuellement <strong>"En préparation"</strong> se verront attribuer le statut <strong>"En production"</strong>.'
        } else if (this.statut_centre === StatutReception.EN_LIVRAISON) {
            this.info_statut_centre = 'Les centres ayant les salles validées et dont le statut est actuellement <strong>"En production"</strong> se verront attribuer le statut <strong>"En livraison"</strong>.'
        } else if (this.statut_centre === StatutReception.AR_TRANSPORTEUR) {
            this.info_statut_centre = 'Les centres ayant les salles validées et dont le statut est actuellement <strong>"En livraison"</strong> se verront attribuer le statut <strong>"AR Transporteur"</strong>.'
        }

        this.info_statut_centre += '<br/>Vous pourrez toujours modifier individuellement le statut de chaque centre.'
    }

    /**
     * getStateInventaire
     * @description Retourne l'état de publication de l'inventaire
     * @return {object}
     */
    getStateInventaire(): object {
        let state_inventaire = { message: '', bg_color: 'barre_default', text_color: '' }
        if (this.$store.getters['session/sessionSelect']) {
            if (!this.$store.getters['session/sessionSelect'].inventaire_fournitures_start_at && !this.$store.getters['session/sessionSelect'].inventaire_fournitures_end_at) {
                state_inventaire = { message: 'L\'inventaire n\'est pas accessible aux centres.', bg_color: 'barre_default', text_color: 'barre_text_defaut' }
            } else if (!this.$store.getters['session/sessionSelect'].inventaire_fournitures_start_at && this.$store.getters['session/sessionSelect'].inventaire_fournitures_end_at) {
                state_inventaire = { message: 'L\'inventaire est accessible aux centres jusqu\'au ' + formatDate(this.$store.getters['session/sessionSelect'].inventaire_fournitures_end_at) + ' inclus.', bg_color: 'barre_soumis', text_color: 'barre_text_soumis' }
            } else if (this.$store.getters['session/sessionSelect'].inventaire_fournitures_start_at && this.$store.getters['session/sessionSelect'].inventaire_fournitures_end_at) {
                state_inventaire = { message: 'L\'inventaire est accessible aux centres du ' + formatDate(this.$store.getters['session/sessionSelect'].inventaire_fournitures_start_at) + ' au ' + formatDate(this.$store.getters['session/sessionSelect'].inventaire_fournitures_end_at) + ' inclus.', bg_color: 'barre_soumis', text_color: 'barre_text_soumis' }
            }
        }

        return state_inventaire
    }


    /**
     * setDataForGenericTab
     * @description Chargement des données du tableau
     * @param {any} poData - Données à charger
     * @param {boolean} isLoadMore - Chargement de plus de données
     * @return {void}
     */
    setDataForGenericTab(poData: any, isLoadMore = false): void {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const result of poData) {
                // Chaine des concours du centre
                let concours_string = ''
                if (result.concours.length) {
                    concours_string = [
                        ...new Set(result.concours.map((concours: any) => concours.name))
                    ].join(', ')
                }

                // Accés suivi des sujets
                const btnSujets: any[] = []
                const statut_sujet: any = getStatutReception(result.f_suj_statut, result.sujets_pb_count)
                let class_bg_sujets = ''
                btnSujets.push({ name: statut_sujet.icone, typeIcon: 'fas', class:'text-start font_medium text-' + statut_sujet.color, title: '', value_comp: (result.f_suj_validated_at ? 'Validé' : statut_sujet.libelle), result: { id: result.id, tab: 'Sujet' } })
                btnSujets.push({ name:'arrow-circle-right', typeIcon: 'fal', class:'text-start text-' + statut_sujet.color, title: '', value_comp: null, result: { id: result.id, tab: 'Sujet' } })
                class_bg_sujets = statut_sujet.bg_color

                // Accés suivi des fournitures papetières
                const btnFP: any[] = []
                const statut_fp: any = getStatutReception(result.f_pap_statut, result.fourniture_papetieres_pb_count)
                let class_bg_fp = ''
                btnFP.push({ name: statut_fp.icone, typeIcon: 'fas', class:'text-start font_medium text-' + statut_fp.color, title: '', value_comp: (result.f_pap_validated_at ? 'Validé' : statut_fp.libelle), result: { id: result.id, tab: 'FourniturePapetiere' } })
                btnFP.push({ name:'arrow-circle-right', typeIcon: 'fal', class:'text-start text-' + statut_fp.color, title: '', value_comp: null, result: { id: result.id, tab: 'FourniturePapetiere' } })
                class_bg_fp = statut_fp.bg_color

                // Accés suivi des fournitures administratives
                const btnFA: any[] = []
                const statut_fa: any = getStatutReception(result.f_adm_statut, result.fourniture_administratives_pb_count)
                let class_bg_fa = ''
                btnFA.push({ name: statut_fa.icone, typeIcon: 'fas', class:'text-start font_medium text-' + statut_fa.color, title: '', value_comp: (result.f_adm_validated_at ? 'Validé' : statut_fa.libelle), result: { id: result.id, tab: 'FournitureAdministrative' } })
                btnFA.push({ name:'arrow-circle-right', typeIcon: 'fal', class:'text-start text-' + statut_fa.color, title: '', value_comp: null, result: { id: result.id, tab: 'FournitureAdministrative' } })
                class_bg_fa = statut_fa.bg_color

                const line = [
                    { label: '', item: result.name, type: 'text', typeAction: null, class: '' },
                    { label: '', item: concours_string, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.ville ? result.ville.name : '-', type: 'text', typeAction: null, class: 'font_medium ' },
                    { label: '', item: result.tournee ? result.tournee.name : '-', type: 'text', typeAction: null, class: result.tournee ? '' : 'text-secondary' },
                    { label: '', item: result.f_poids ? (result.f_poids / 1000).toFixed(2) : '-', type: 'text', typeAction: null, class: `text-center ${result.f_poids ? '' : 'text-secondary'}` },
                    { label: '', item: result, type: 'actionText', typeAction: 'editConditionnement', class: 'text-center text-info', text: result.conditionnements ? result.conditionnements.length : '0' },
                    { label: '', item: btnSujets, type: 'icons', typeAction: 'goSuivi', class: 'text-center min_width_suivi ' + class_bg_sujets },
                    { label: '', item: btnFP, type: 'icons', typeAction: 'goSuivi', class: 'text-center min_width_suivi ' + class_bg_fp },
                    { label: '', item: btnFA, type: 'icons', typeAction: 'goSuivi', class: 'text-center min_width_suivi ' + class_bg_fa }
                ]
                this.dataForTab.push(line)
            }
        }
    }

    /**
     * handleTableEvent
     * @description Récupération des events du tableau
     * @param {any} paParams - Événement (paParams[0] => l'action, paParams[1] => l'id de l'item)
     * @return {void}
     */
    handleTableEvent(paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'goSuivi':
                    this.$router.push('/suivi_sujets_fournitures/' + paParams[1][0].result.id + '?tab=' + paParams[1][0].result.tab)
                    break
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'editConditionnement':
                    this.openConditionnement(paParams[1])
                    break
                default:
                    break
            }
        }
    }

    /**
     * filtreSortHandler
     * @description Chargement des données du tableau
     * @param {any} params - Paramètres
     * @return {void}
     */
    // Chargement des données du tableau
    filtreSortHandler(params: any): void {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            // Des filtres sont appliqués on rappele le serveur
            this.$store.commit('repartitioncandidats/SET_LOADING', true)
            this.$store.dispatch('repartitioncandidats/getListeCentres', params).then(() => {
                this.setDataForGenericTab(this.$store.getters['repartitioncandidats/liste_centres'])
                this.$store.commit('repartitioncandidats/SET_LOADING', false)
            })
        }
    }

    /**
     * loadHandler
     * @description Appelle une page lors du scroll
     * @param {any} params - Paramètres
     * @return {void}
     */
    loadHandler(params: any): void {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.$store.commit('repartitioncandidats/SET_LOADING', true)
            this.$store.dispatch('repartitioncandidats/getMoreListeCentres', params).then(() => {
                this.setDataForGenericTab(this.$store.getters['repartitioncandidats/liste_centres'])
                this.$store.commit('repartitioncandidats/SET_LOADING', false)
            })
        }
    }

    /**
     * setFiltersForGenericTab
     * @description Création des filtres pour le tableau
     * @return {void}
     */
    setFiltersForGenericTab(): void {
        // Options filières
        const filieres = this.$store.getters['concour/banques']
        const options_filieres = []
        for (const f in filieres) {
            options_filieres.push({ index: filieres[f].id, name: filieres[f].name })
        }

        // Options tournées
        const tournees = this.$store.getters['tournee/tournees']
        const options_tournees = []
        for (const t in tournees) {
            options_tournees.push({ index: tournees[t].id, name: tournees[t].name })
        }

        // Options zones
        const zones = this.$store.getters['ville/zones']
        const options_zones = []
        for (const z in zones) {
            options_zones.push({ index: zones[z].id, name: zones[z].name })
        }

        // Options statuts
        const options_statuts = []
        options_statuts.push({ index: 0, name: 'En préparation' })
        options_statuts.push({ index: 1, name: 'En production' })
        options_statuts.push({ index: 2, name: 'En livraison' })
        options_statuts.push({ index: 3, name: 'AR Tranporteur' })
        options_statuts.push({ index: 4, name: 'AR Centre' })
        options_statuts.push({ index: -4, name: 'Validé' })

        this.filtres = [
            { libelle: 'Centre', defautOptionlibelle: 'Rechercher un',   model: 'name',  value: '', index: 'name', datas: null, loading: this.$store.getters['repartitioncandidats/loading'], options: { type: 'form', fieldsKey: 'name' } },
            { libelle: 'Ville', defautOptionlibelle: 'Rechercher une',   model: 'ville.name',  value: '', index: 'ville', datas: null, loading: this.$store.getters['repartitioncandidats/loading'], options: { type: 'form', fieldsKey: 'ville' } },
            { libelle: 'Filière', defautOptionlibelle: 'Rechercher une',  model: 'concours_id', value: '', index: 'filiere', datas: options_filieres, loading: this.$store.getters['repartitioncandidats/loading'], options: { type: 'deroulant', fieldsKey: 'filiere' } },
            { libelle: 'Tournée', defautOptionlibelle: 'Rechercher une',  model: 'tournee_id', value: '', index: 'tournee', datas: options_tournees, loading: this.$store.getters['repartitioncandidats/loading'], options: { type: 'deroulant', fieldsKey: 'tournee' } },
            { libelle: 'Statut', defautOptionlibelle: 'Rechercher un',  model: 'f_suj_statut', value: '', index: 'sujets', datas: options_statuts, loading: this.$store.getters['repartitioncandidats/loading'], options: { type: 'deroulant', fieldsKey: 'sujets' } },
            { libelle: 'Statut', defautOptionlibelle: 'Rechercher un',  model: 'f_pap_statut', value: '', index: 'fournitures_papetieres', datas: options_statuts, loading: this.$store.getters['repartitioncandidats/loading'], options: { type: 'deroulant', fieldsKey: 'fournitures_papetieres' } },
            { libelle: 'Statut', defautOptionlibelle: 'Rechercher un',  model: 'f_adm_statut', value: '', index: 'fournitures_administratives', datas: options_statuts, loading: this.$store.getters['repartitioncandidats/loading'], options: { type: 'deroulant', fieldsKey: 'fournitures_administratives' } }
        ]
    }

    /**
     * open_publication_inventaire
     * @description Ouvre le popup de paramétrage de la visualisation de l'inventaire
     * @return {void}
     */
    open_publication_inventaire(): void {
        this.showPopupPublicationInventaire = true
    }

    /**
     * close_publication_inventaire
     * @description Ferme la popup de paramétrage de la visualisation de l'inventaire
     * @return {void}
     */
    close_publication_inventaire(): void {
        this.showPopupPublicationInventaire = false
    }

    /**
     * checkInputValidation
     * @description Vérifie la cohérence des dates avant envoi au serveur
     * @return {void}
     */
    checkInputValidation(): void {
        this.$store.commit('session/SET_ERROR', null)
        this.betweenError = ''
        this.onlyEndDateError = ''
        let valid = false
        const dateNow = new Date(Date.now()).toISOString()

        switch (this.selectedDate.userSelectDateType) {
            case 'between':
                if (diffTwoDatesInMinutes(this.selectedDate.dateBetween.date_start, this.selectedDate.dateBetween.date_end) < 0) {
                    this.betweenError = 'La date d\'ouverture doit être antérieure ou égale à la date de fermeture.'
                } else if (this.selectedDate.dateBetween.date_start === '' || this.selectedDate.dateBetween.date_end === '') {
                    this.betweenError = 'La date d\'ouverture et la date de fermeture doivent être saisies.'
                } else if (diffTwoDatesInMinutes(formatDateYYYYMMDD(dateNow), this.selectedDate.dateBetween.date_start) < 0) {
                    this.betweenError = 'La date d\'ouverture doit être postérieure ou égale à la date d\'aujourd\'hui.'
                }  else if (diffTwoDatesInMinutes(formatDateYYYYMMDD(dateNow), this.selectedDate.dateBetween.date_end) < 0) {
                    this.betweenError = 'La date de fermeture doit être postérieure ou égale à la date d\'aujourd\'hui.'
                } else {
                    this.betweenError = ''
                    valid = true
                }
                break
            case 'onlyEndDate':
                if (diffTwoDatesInMinutes(formatDateYYYYMMDD(dateNow), this.selectedDate.onlyEndDate.date_end) < 0) {
                    this.onlyEndDateError = 'La date de saisie doit être postérieure ou égale à la date d\'aujourd\'hui.'
                } else if (this.selectedDate.onlyEndDate.date_end === '') {
                    this.onlyEndDateError = 'La date doit être saisie.'
                } else {
                    this.onlyEndDateError = ''
                    valid = true
                }
                break
            case 'closeInput':
                valid = true
                break
            default:
                break
        }

        if (valid) {
            this.save_publication_inventaire()
        }
    }

    /**
     * convertDateToIso
     * @description Formatte une valeur string de type'YYYY-MM-DD HH:mm:ss' en valeur moment YYYY-MM-DDTHH:mm:ss[Z]
     * @param {string} dateString - Date à formater
     * @return {string | void} Date formatée
     */
    convertDateToIso(dateString: string | undefined): string | void {
        if (dateString) {
            return formatDayHourZDateFromString(dateString)
        }
    }

    /**
     * save_publication_inventaire
     * @description Sauvegarde de la publication de l'inventaire
     * @return {void}
     */
    save_publication_inventaire(): void {
        let params = {}
        const id = this.$store.getters['session/sessionSelect'].id

        switch (this.selectedDate.userSelectDateType) {
            case 'between':
                params = {
                    inventaire_fournitures_start_at:  this.convertDateToIso(this.selectedDate.dateBetween.date_start),
                    inventaire_fournitures_end_at:    this.convertDateToIso(formatEndOfDayHourZDateFromString(this.selectedDate.dateBetween.date_end)) // La journée de fermeture est incluse
                }
                break
            case 'onlyEndDate':
                params = {
                    inventaire_fournitures_start_at:  null,
                    inventaire_fournitures_end_at:    this.convertDateToIso(formatEndOfDayHourZDateFromString(this.selectedDate.onlyEndDate.date_end)) // La journée de fermeture est incluse
                }
                break
            case 'closeInput':
                params = {
                    inventaire_fournitures_start_at:  null,
                    inventaire_fournitures_end_at:    null
                }
                break
            default:
                break
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.$store.dispatch('session/updateSessionInventaireFourniture', { session_id: id, payload: params })
            .then((response) => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$store.commit('session/SET_SESSION_SELECT', response.data.data)
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                this.close_publication_inventaire()
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * open_relance_centre
     * @description Ouvre le popup de relance des centres
     * @return {void}
     */
    open_relance_centre(): void {
        this.showPopupRelanceCentre = true
    }

    /**
     * close_relance_centre
     * @description Ferme le popup de relance des centres
     * @return {void}
     */
    close_relance_centre(): void {
        this.showPopupRelanceCentre = false
    }

    /**
     * save_relance_centre
     * @description Sauvegarde de la relance des centres
     * @return {void}
     */
    save_relance_centre(): void {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Envoi en cours...', infosToaster)

        this.$store.dispatch('fourniture/relaunchSuiviFournitures')
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Envoi terminé.', succesToaster)
                this.showPopupRelanceCentre = false
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * initDates
     * @description Initialise les dates pour la publication de l'inventaire
     * @return {void}
     */
    initDates(): void {
        const activeSession = this.$store.getters['session/sessionSelect']

        if (activeSession && activeSession.inventaire_fournitures_end_at !== null && activeSession.inventaire_fournitures_start_at !== null) {
            this.selectedDate.dateBetween.date_start = formatDateYYYYMMDD(activeSession.inventaire_fournitures_start_at)
            this.selectedDate.dateBetween.date_end = formatDateYYYYMMDD(activeSession.inventaire_fournitures_end_at)
            this.selectedDate.onlyEndDate.date_end = ''
            this.selectedDate.userSelectDateType = 'between'
        } else if (activeSession && activeSession.inventaire_fournitures_end_at !== null) {
            this.selectedDate.onlyEndDate.date_end = formatDateYYYYMMDD(activeSession.inventaire_fournitures_end_at)
            this.selectedDate.dateBetween.date_start = ''
            this.selectedDate.dateBetween.date_end = ''
            this.selectedDate.userSelectDateType = 'onlyEndDate'
        }
    }

    /**
     * open_select_zone
     * @description Ouvre le popup de sélection de zones pour l'export de la commande des sujets
     * @return {void}
     */
    open_select_zone(): void {
        this.showPopupOptionsZones = true
    }

    /**
     * close_selection_zone
     * @description Ferme le popup de sélection de zones pour l'export de la commande des sujets
     * @return {void}
     */
    close_selection_zone(): void {
        this.options_zones_export = []
        this.showPopupOptionsZones = false
    }

    /**
     * exporter
     * @description Export des fichiers de commandes
     * @param {string} type_fichier - Type de fichier à exporter
     * @return {void}
     */
    exporter(type_fichier: string): void {
        this.exportingIsWorking = true
        let params = {}
        let fileName = 'commande_' + type_fichier + '.xlsx'
        let dispatch_link = ''

        switch (type_fichier) {
            case 'sujets':
                params = this.options_zones_export
                dispatch_link = 'centre/exportCommandeSujets'
                break
            case 'fournitures_papetieres':
                dispatch_link = 'centre/exportCommandeFournituresPap'
                break
            case 'indisponibilites':
                dispatch_link = 'centre/exportCentresIndisponibilites'
                break
        }

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Export en cours de création ...', infosToaster)

        this.$store.dispatch(dispatch_link, params)
            .then((response) => {
                const fileNameTemp = getFileNameFromHeader(response.headers)
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)

                this.showPopupOptionsZones = false
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.exportingIsWorking = false
            })
    }

    // ----- gestion du nombre de sujets --------------------

    /**
     * checkKeyUp
     * @description bloque la saisie de '.' et de ',' dans les inputs de type number déstiné à recevoir des entiers
     * @param {any} e - event
     * @return {void}
     */
    checkKeyUp(e: any): void {
        if (e.target.value.includes(',')) {
            e.target.value = e.target.value.replace(',', '')
        }
        if (e.target.value.includes('.')) {
            e.target.value = e.target.value.replace('.', '')
        }
    }

    /**
     * openGestionNombreSujets
     * @description Ouvre la fenetre de paramétrage du nombre prévisionnel de sujets
     * @return {void}
     */
    openGestionNombreSujets(): void {
        this.listeSujets = []
        const filieres = this.$store.getters['concour/banques']
        this.options_filieres = []
        for (const f in filieres) {
            this.options_filieres.push({ index: filieres[f].id, name: filieres[f].name })
        }
        this.$store.dispatch('sujet/getPrevisionnelSujets').then((response) => {
            this.marge_securite = this.$store.getters['session/sessionSelect'].sujet_marge_securite
            this.sujet_supplementaire = this.$store.getters['session/sessionSelect'].sujet_supplementaires
            this.openGestionNombreSujetsSuite(response)
        }).catch((error) => {
            console.log('ko:' + error)
        })
    }

    /**
     * openGestionNombreSujetsSuite
     * @description Suite de la fonction openGestionNombreSujets
     * @param {any} response - Réponse de la requête
     * @return {void}
     */
    openGestionNombreSujetsSuite(response: any): void {
        for (let i = 0; i < response.data.data.length; i++) {
            this.listeSujets.push({
                id: response.data.data[i].id,
                code:  response.data.data[i].sujet ? response.data.data[i].sujet.reference : '',
                name:  response.data.data[i].sujet ? response.data.data[i].sujet.name : '',
                concour_id: response.data.data[i].concour_id,
                concour_code: response.data.data[i].concour.code,
                estimation_nb_candidats: response.data.data[i].nb_candidats,
                nb_sujets: response.data.data[i].nb_commande,
                nb_sujets_attendu: response.data.data[i].nb_commande,
                nb_pages:  response.data.data[i].sujet ? Number(response.data.data[i].sujet.nb_pages) : 0,
                pages_imprimees: response.data.data[i].sujet ? Number(response.data.data[i].nb_sujets) * Number(response.data.data[i].sujet.nb_pages) : 0
            })
            this.listeSujetsOriginal = this.listeSujets
        }
        this.showGestionNombreSujets = true
        this.getTotalSujetsAttendu()
        this.getTotalPagesImprimees()
    }

    /**
     * cancelGestionNombreSujets
     * @description Ferme la fenetre de paramétrage du nombre prévisionnel de sujets
     * @return {void}
     */
    cancelGestionNombreSujets(): void {
        this.showGestionNombreSujets = false
    }

    /**
     * filiereSujetChangeHandler
     * @description Filtre les épreuves par filière
     * @return {void}
     */
    filiereSujetChangeHandler(): void {
        this.listeSujets = []
        if (this.filiere_gestion_nombre_sujets !== 'null') {
            for (let i = 0; i < this.listeSujetsOriginal.length; i++) {
                if (this.listeSujetsOriginal[i].concour_id === this.filiere_gestion_nombre_sujets) {
                    this.listeSujets.push(this.listeSujetsOriginal[i])
                }
            }
        } else {
            for (let i = 0; i < this.listeSujetsOriginal.length; i++) {
                this.listeSujets.push(this.listeSujetsOriginal[i])
            }
        }
        this.getTotalSujetsAttendu()
    }

    /**
     * getTotalSujetsAttendu
     * @description Calcul le nombre de sujets attendus par épreuveen prenant en compte la marge de sécurité et l'ajout de sujet supplémentaire
     * @return {void}
     */
    getTotalSujetsAttendu(): void {
        for (let i = 0; i < this.listeSujets.length; i++) {
            this.listeSujets[i].nb_sujets_attendu = this.listeSujets[i].estimation_nb_candidats
            if (this.marge_securite > 0) {
                this.listeSujets[i].nb_sujets_attendu = Number(this.listeSujets[i].nb_sujets_attendu) + Math.ceil((Number(this.listeSujets[i].nb_sujets_attendu) * Number(this.marge_securite) / 100))
            }
            this.listeSujets[i].nb_sujets_attendu = Number(this.listeSujets[i].nb_sujets_attendu) + Number(this.sujet_supplementaire)
        }
        this.getTotalPagesImprimees()
    }

    /**
     * getTotalPagesImprimees
     * @description Calcul le nombre de pages imprimées par épreuve
     * @return {void}
     */
    getTotalPagesImprimees(): void {
        this.totalPagesImprimees = 0
        for (let i = 0; i < this.listeSujets.length; i++) {
            this.listeSujets[i].pages_imprimees = Math.ceil(Number(this.listeSujets[i].nb_sujets) * Number(this.listeSujets[i].nb_pages))
            this.totalPagesImprimees = Number(this.totalPagesImprimees) + Number(this.listeSujets[i].pages_imprimees)
        }
    }

    /**
     * nbSujetChangeHandler
     * @description Calcul le nombre de pages imprimées par épreuve lors de la modification du nombre de sujets
     * @param {any} sujet - Sujet
     * @return {void}
     */
    nbSujetChangeHandler(sujet: any): void {
        sujet.pages_imprimees = Number(sujet.nb_sujets) * Number(sujet.nb_pages)
        this.getTotalPagesImprimees()
    }

    /**
     * updateMargePrevisionnelSujets
     * @description Recalcul du nombre de sujets et du nombre de pages impriméés
     * @return {void}
     */
    updateMargePrevisionnelSujets(): void {
        for (let i = 0; i < this.listeSujets.length; i++) {
            this.listeSujets[i].nb_sujets = this.listeSujets[i].estimation_nb_candidats
            if (this.marge_securite > 0) {
                this.listeSujets[i].nb_sujets = Number(this.listeSujets[i].nb_sujets) + Math.ceil((Number(this.listeSujets[i].nb_sujets) * Number(this.marge_securite) / 100))
            }
            this.listeSujets[i].nb_sujets_attendu = this.listeSujets[i].nb_sujets = Number(this.listeSujets[i].nb_sujets) + Number(this.sujet_supplementaire)
            this.listeSujets[i].pages_imprimees = Math.ceil(Number(this.listeSujets[i].nb_sujets) * Number(this.listeSujets[i].nb_pages))
        }
        this.getTotalPagesImprimees()
    }

    /**
     * updatePrevionnelSujets
     * @description Enregistrement de la valeur de la marge de sécurité et du nombre de sujets supplémentaires, et du nombre de sujet et l'estimation des nombres de candidats des épreuves
     * @return {void}
     */
    updatePrevisionnelSujets(): void {
        const data = {
            sujet_marge_securite: this.marge_securite,
            sujet_supplementaires: this.sujet_supplementaire
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('sujet/updateMargePrevisionnelSujets', data)
            .then(() => {
                this.$store.getters['session/sessionSelect'].sujet_marge_securite = this.marge_securite
                this.$store.getters['session/sessionSelect'].sujet_supplementaires = this.sujet_supplementaire
                const concour_sujets_temps: Array<{id: number; nb_commande: number}> = []
                for (let i = 0; i < this.listeSujets.length; i++) {
                    concour_sujets_temps.push({
                        id: this.listeSujets[i].id,
                        nb_commande: this.listeSujets[i].nb_sujets
                    })
                }

                const datab: { concour_sujets: Array<{id: number; nb_commande: number}> } = {
                    concour_sujets: concour_sujets_temps
                }
                this.$store.dispatch('sujet/updatePrevisionnelSujets', datab)
                    .then(() => {
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }
                        this.$bvToast.toast('Enregistré avec succès !', succesToaster)
                    })
                    .catch((error) => {
                        console.log('ko:' + error)
                    })
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * exportPrevionnelSujets
     * @description Export du nombre de sujets, l'estimation des nombres de candidats et du nombre de pages imprimées
     * @return {void}
     */
    exportPrevisionnelSujets(): void {
        this.exportingIsWorking = true
        let fileName = 'export_previsionnel_sujets.xlsx'

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Export en cours de création ...', infosToaster)

        this.$store.dispatch('sujet/exportPrevisionnelSujets')
            .then((response) => {
                const fileNameTemp = getFileNameFromHeader(response.headers)
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.exportingIsWorking = false
            })
    }

    /**
     * listTypeAmenagement
     * @description Liste des types d'aménagement
     * @return {Array<string>}
     */
    listTypeAmenagement(): Array<string> {
        const middle = Math.floor(Object.keys(TypeAmenagement).length / 2)
        return Object.keys(TypeAmenagement).slice(0, middle)
    }

    /**
     * openMargeCommande
     * @description Ouvre la popup de paramétrage de la marge de commande
     * @return {Promise<void>}
     */
    async openMargeCommande(): Promise<void> {
        await this.$store.dispatch('session/getSession', { session_id: this.$store.getters['auth/user_session_id'] })
        const session = this.$store.getters['session/sessionSelect']

        // Génération de l'objet en fonction des noms des variables qu'il contient et du nombre de type d'aménagement
        Object.keys(this.params_marge).forEach((key: any) => {
            this.listTypeAmenagement().forEach((type: any) => {
                this.params_marge[key][type] = session && session[key] && typeof session[key] === 'object' ? session[key][type] : 0
            })
        })

        this.showPopupMargeCommande = true
    }

    /**
     * close_marge_commande
     * @description Ferme la popup de paramétrage de la marge de commande
     * @return {void}
     */
    close_marge_commande(): void {
        this.showPopupMargeCommande = false
    }

    /**
     * openConditionnement
     * @description Ouvre la popup de gestion du conditionnement
     * @param {any} data
     * @return {void}
     */
    openConditionnement(data: any): void {
        this.$store.commit('centre/SET_ERROR', null)

        this.liste_epreuves_conditionnement = []
        for (let i = 0; i < data.concours.length; i++) {
            const array: any = []
            this.$store.getters['epreuve/epreuves']
                .filter((epreuve: any) => epreuve.concour_id === data.concours[i].id && epreuve.type_passation === TypePassation.TYPE_PASSATION_ECRIT)
                .forEach((epreuve: any) => {
                    array.push(this.$store.getters['epreuve/epreuves'].find((ep: any) => ep.id === epreuve.id_epreuve_maitresse) || epreuve)
                })

            this.liste_epreuves_conditionnement.push(
                ...array
                    .filter((v: any, i: any, a: any) => a.findIndex((t: any) => (t.id === v.id)) === i)
                    .map((epreuve: any) => {
                        return {
                            id: epreuve.id,
                            name: `${data.concours[i].name} - ${epreuve.name}`
                        }
                    })
            )
        }

        const conditionnements = []
        if (data.conditionnements && data.conditionnements.length) {
            for (let i = 0; i < data.conditionnements.length; i++) {
                conditionnements.push({
                    ...data.conditionnements[i],
                    conditionnement: this.type_conditionnement.find((type: any) => type.id === data.conditionnements[i].conditionnement_id)
                })
            }
        }

        this.params_conditionnement = {
            name: data.name,
            centre_id: data.id,
            conditionnement_id: null,
            tournee_id: data.tournee_id,
            poids: data.f_poids,
            conditionnements: conditionnements
        }

        this.computeRepartition()
        this.showPopupConditionnement = true
    }

    /**
     * addConditionnement
     * @description Ajoute un conditionnement
     * @return {void}
     */
    addConditionnement(): void {
        if (isNull(this.params_conditionnement.conditionnement_id)) {
            return
        }

        this.params_conditionnement.conditionnements.push({
            infos_complementaire: null,
            epreuve_id: null,
            conditionnement_id: this.params_conditionnement.conditionnement_id,
            conditionnement: this.type_conditionnement.find((type: any) => type.id === this.params_conditionnement.conditionnement_id),
            poids_moyen: 0
        })

        this.computeRepartition()
    }

    /**
     * removeConditionnement
     * @description Supprime un conditionnement
     * @return {void}
     */
    removeConditionnement(index: number): void {
        this.params_conditionnement.conditionnements.splice(index, 1)
        this.computeRepartition()
    }

    /**
     * computeRepartition
     * @description Calcule la répartition du poids dans les différents caissons
     * @return {void}
     */
    computeRepartition(): void {
        const capacites = this.params_conditionnement.conditionnements.map((conditionnement: any) => conditionnement.conditionnement.poids_max)
        const capaciteTotale = capacites.reduce((acc: any, capacite: any) => acc + capacite, 0)
        const parts = capacites.map((capacite: any) => (capacite / capaciteTotale) * this.params_conditionnement.poids)

        for (let i = 0; i < capacites.length; i++) {
            this.params_conditionnement.conditionnements[i].poids_moyen = Math.ceil(parts[i])
        }

        this.capacity_overflow = false
        if (capacites.length) {
            for (let i = 0; i < this.params_conditionnement.conditionnements.length; i++) {
                if (this.params_conditionnement.conditionnements[i].poids_moyen > this.params_conditionnement.conditionnements[i].conditionnement.poids_max) {
                    this.capacity_overflow = true
                    return
                }
            }
        } else {
            this.capacity_overflow = true
        }
    }

    /**
     * closeConditionnement
     * @description Ferme la popup de gestion du conditionnement
     * @return {void}
     */
    closeConditionnement(): void {
        this.showPopupConditionnement = false
    }

    /**
     * saveConditionnement
     * @description Sauvegarde les données du conditionnement
     * @return {void}
     */
    saveConditionnement(): void {
        this.save_in_progress = true
        const payload = JSON.parse(JSON.stringify(this.params_conditionnement))

        // Transformation du payload
        delete payload.name
        delete payload.conditionnement_id
        delete payload.poids
        delete payload.centre_id
        for (let i = 0; i < payload.conditionnements.length; i++) {
            payload.conditionnements[i].name = `${payload.conditionnements[i].conditionnement.name} ${i + 1}`
            delete payload.conditionnements[i].conditionnement
        }

        // Envoie au back
        this.$store.dispatch('centre/updateConditionnement', {
            centre_id: this.params_conditionnement.centre_id,
            payload: payload
        })
            .then(() => {
                this.closeConditionnement()
                this.$store.commit('repartitioncandidats/SET_LOADING', true)
                this.$store.dispatch('repartitioncandidats/getListeCentres', this.params).then(() => {
                    this.setDataForGenericTab(this.$store.getters['repartitioncandidats/liste_centres'])
                    this.$store.commit('repartitioncandidats/SET_LOADING', false)
                })
            })
            .finally(() => {
                this.save_in_progress = false
            })
    }

    /**
     * save_marge_commande
     * @description Sauvegarde de la marge de commande
     * @return {void}
     */
    save_marge_commande(): void {
        this.save_in_progress = true

        this.$store.dispatch('sujet/saveMargeCommande', this.params_marge)
            .then((response) => {
                this.$store.commit('session/SET_SESSION_SELECT', response.data.data)
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                this.showPopupMargeCommande = false
            })
            .finally(() => {
                this.save_in_progress = false
            })
    }

    /**
     * open_statut_centre
     * @description Ouvre le popup de changement de statut pour tous les centres
     * @param {string} statut - Statut
     * @return {void}
     */
    open_statut_centre(statut: string): void {
        this.type_statut = statut
        this.showPopupStatutCentre = true
    }

    /**
     * close_statut_centre
     * @description Ferme la popup de changement de statut pour tous les centres
     * @return {void}
     */
    close_statut_centre(): void {
        this.type_statut = ''
        this.statut_centre = null
        this.info_statut_centre = ''
        this.showPopupStatutCentre = false
    }

    /**
     * save_statut_centre
     * @description Sauvegarde du changement de statut pour tous les centres
     * @return {void}
     */
    save_statut_centre(): void {
        if (this.statut_centre !== null) {
            this.save_in_progress = true
            let params: any = {}

            switch (this.type_statut) {
                case 'Sujets':
                    params = {
                        f_suj_statut: this.statut_centre
                    }
                    break
                case 'Fournitures papetières':
                    params = {
                        f_pap_statut: this.statut_centre
                    }
                    break
                case 'Fournitures administratives':
                    params = {
                        f_adm_statut: this.statut_centre
                    }
                    break
            }

            this.$store.dispatch('centre/updateCentreFournituresStatut', params)
                .then((response) => {
                    this.$store.commit('session/SET_SESSION_SELECT', response.data.data)
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                    this.$store.dispatch('repartitioncandidats/getListeCentres')
                        .then(() => {
                            this.save_in_progress = false
                            this.setFiltersForGenericTab()
                            this.close_statut_centre()
                        })
                })
                .catch(() => {
                    this.save_in_progress = false
                })
        }
    }

    // ------- Bibliothèque de documents -------
    showBibliothequeDocuments = false
    modeEditDocument = false
    showModalMessageDeleteDocument = false
    exportWorking = false
    documents: Array<FournitureAdministrativeInterface> = []
    activeSession: any = null
    documentTemp: FournitureAdministrativeInterface | null = null
    // --- documents

    /**
     * openBibliothequeDocuments
     * @description Ouvre la fenetre de gestion / bibliothèque des documents de l'ensemble des centres
     * @return {void}
     */
    openBibliothequeDocuments(): void {
        this.$store.dispatch('fournitureAdministrative/getFournitureAdministratives', { 'filter-custom': 1 }).then((response) => {
            this.documents = response.data.data
            this.showBibliothequeDocuments = true
            this.etatConsultation.ouverture = this.activeSession.bibliotheque_fa_start_at ? new Date(this.activeSession.bibliotheque_fa_start_at).toISOString().split('T')[0] : null
            this.etatConsultation.fermeture = this.activeSession.bibliotheque_fa_end_at ? new Date(this.activeSession.bibliotheque_fa_end_at).toISOString().split('T')[0] : null
            if (this.etatConsultation.ouverture && this.etatConsultation.fermeture) {
                this.etatConsultation.etat = 1
            } else if (this.etatConsultation.fermeture) {
                this.etatConsultation.etat = 2
            } else {
                this.etatConsultation.etat = 0
            }
            this.etatConsultationChangeHandler()
        })
    }

    /**
     * cancelBibliothequeDocuments
     * @description Ferme la fenetre de gestion / bibliothèque des documents de l'ensemble des centres
     * @return {void}
     */
    cancelBibliothequeDocuments(): void {
        this.showBibliothequeDocuments = false
    }

    /**
     * addDocument
     * @description Affiche le formulaire d'ajout d'un document
     * @return {void}
     */
    addDocument(): void {
        this.documentTemp = {
            id: 0,
            session_id: this.$store.getters['auth/user_session_id'],
            code: '',
            name: '',
            ordre: 1,
            actif: 1,
            dispo_numerique: 0,
            dispo_papier: 0,
            custom: 1,
            bac_impression: 0,
            document: null
        }
        this.modeEditDocument = true
        this.modeSoftDeleteDoc = false
    }

    /**
     * editDocument
     * @description Affiche le formulaire d'édition d'un document
     * @param {any} document - Document
     * @return {void}
     */
    editDocument(document: any): void {
        this.documentTemp = JSON.parse(JSON.stringify(document))
        this.modeEditDocument = true
        this.documentFileTemp = null
        this.modeSoftDeleteDoc = false
    }

    /**
     * saveEditDocument
     * @description Enregistre le document ajouté / edité
     * @return {void}
     */
    saveEditDocument(): void {
        if (this.documentTemp) {
            this.$store.commit('fournitureAdministrative/SET_ERROR', null)
            const formdata = new FormData()

            formdata.set('id', this.documentTemp.id.toString())
            formdata.set('session_id', this.documentTemp.session_id.toString())
            formdata.set('code', this.documentTemp.code.toString())
            formdata.set('name', this.documentTemp.name.toString())
            formdata.set('ordre', this.documentTemp.ordre.toString())
            formdata.set('actif', this.documentTemp.actif.toString())
            formdata.set('dispo_numerique', this.documentTemp.dispo_numerique ? '1' : '0')
            formdata.set('dispo_papier', this.documentTemp.dispo_papier ? '1' : '0')
            formdata.set('custom', this.documentTemp.custom.toString())
            formdata.set('bac_impression', this.documentTemp.bac_impression.toString())
            if (this.documentFileTemp) {
                formdata.set('document', this.documentFileTemp)
            }

            const idInfo = 't_info_' + Math.random()
            const infosToaster = {
                id: idInfo,
                toaster: 'b-toaster-top-right',
                variant: 'primary',
                noCloseButton: true,
                fade: true,
                noAutoHide: true
            }
            this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
            if (this.documentTemp.id === 0) {
                formdata.set('_method', 'POST')
                // Création d'une fourniture administrative
                this.$store.dispatch('fournitureAdministrative/addFournitureAdministrative', formdata)
                    .then(() => {
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }
                        this.$bvToast.toast('Enregistrement terminé !', succesToaster)


                        this.$store.dispatch('fournitureAdministrative/getFournitureAdministratives', { 'filter-custom': 1 })
                            .then((response) => {
                                this.annulerEditDocument()
                                this.documentTemp = null
                                this.documents = response.data.data
                            })
                    })
                    .finally(() => {
                        this.$bvToast.hide(idInfo)
                    })
            } else {
                // Modification d'un fourniture administrative
                formdata.set('_method', 'PUT')
                this.$store.dispatch('fournitureAdministrative/updateFournitureAdministrative', { fa_id:this.documentTemp.id, payload: formdata })
                    .then(() => {
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }
                        this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                        this.$store.dispatch('fournitureAdministrative/getFournitureAdministratives', { 'filter-custom': 1 })
                            .then((response) => {
                                this.annulerEditDocument()
                                this.documentTemp = null
                                this.documents = response.data.data
                            })
                    })
                    .finally(() => {
                        this.$bvToast.hide(idInfo)
                    })
            }
        }
    }

    /**
     * softDeleteDocument
     * @description Masque le document en cours d'édition afin de le remplacer
     * @return {void}
     */
    modeSoftDeleteDoc = false
    softDeleteDocument(): void {
        this.modeSoftDeleteDoc = true
    }

    /**
     * selectFile
     * @description Memorise le fichier selectionné pour le document en cours de création/édition
     * @param {any} e - Event
     * @return {void}
     */
    documentFileTemp: any = null
    selectFile(e: Event): void {
        if (e.target !== null && this.documentTemp) {
            const target = e.target as HTMLInputElement
            const file: File = (target.files as FileList)[0]
            this.documentFileTemp = file
        }
    }

    /**
     * annulerEditDocument
     * @description Ferme le formulaire d'édition d'un document
     * @return {void}
     */
    annulerEditDocument(): void {
        this.modeEditDocument = false
        this.documentTemp = null
    }

    /**
     * dlDocumentPdf
     * @description Lance le téléchargement d'un document
     * @param {any} doc - Document
     * @return {void}
     */
    dlDocumentPdf(doc: any): void {
        // this.$store.commit('candidat/SET_ERROR', null)
        let fileName = ''
        this.exportWorking = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Export en cours...', infosToaster)

        this.$store.dispatch('fournitureAdministrative/getPDFFournitureAdministrative', { fa_id: doc.id, doc_id: doc.document[0].id })
            .then(response => {
                const fileNameTemp = doc.document[0].name // getFileNameFromHeader(response.headers) || 'document.pdf'
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([base64ToArrayBuffer(response.data)]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.exportWorking = false
            })
    }

    /**
     * deleteDocument
     * @description Affiche la fenetre de confirmation de suppression d'un document
     * @param {any} document - Document
     * @return {void}
     */
    deleteDocument(document: any): void {
        this.$store.commit('document/SET_ERROR', null)
        this.documentTemp = JSON.parse(JSON.stringify(document))
        this.showModalMessageDeleteDocument = true
    }

    /**
     * getDocumentError
     * @description Retourne l'erreur de suppression d'un document
     * @return {any | null}
     */
    getDocumentError(): any | null {
        return this.$store.getters['fournitureAdministrative/error']
    }

    /**
     * cancelDeleteDocument
     * @description Ferme la fenêtre de confirmation de suppression d'un document
     * @return {void}
     */
    cancelDeleteDocument(): void {
        this.documentTemp = null
        this.showModalMessageDeleteDocument = false
    }

    /**
     * deleteSuiteDocument
     * @description Confirme et envoi au serveur une demande de suppresion de sujet
     * @return {void}
     */
    deleteSuiteDocument(): void {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Suppression en cours ...', infosToaster)
        this.$store.dispatch('fournitureAdministrative/deleteFournitureAdministrative',  this.documentTemp?.id)
            .then(() => {
                for (let i = 0;  i < this.documents.length; i++) {
                    if (this.documentTemp?.id === this.documents[i].id) {
                        this.documents.splice(i, 1)
                    }
                }
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Suppression terminée.', succesToaster)
                this.documentTemp = null
                this.showModalMessageDeleteDocument = false
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }


    // --- etatConsultation
    etatConsultation: {etat: number; ouverture: string|null; fermeture: string|null} = {
        etat: 1,
        ouverture: null, // new Date(Date.now()).toISOString().split('T')[0],
        fermeture: null //  new Date(Date.now()).toISOString().split('T')[0]
    }

    /**
     * etatConsultationChangeHandler
     * @description Gère les changements d'état du formulaire de la consultation des documents
     * @return {void}
     */
    dateErreur = ''
    etatConsultationChangeHandler(): void {
        this.dateErreur = ''
        const dateNow = new Date(Date.now()).toISOString()

        if (Number(this.etatConsultation.etat) === 1) {
            if (!this.etatConsultation.ouverture || !this.etatConsultation.fermeture) {
                this.dateErreur = 'La date d\'ouverture et la date de fermeture doivent être saisies...'
            } else if (this.etatConsultation.ouverture === '' || this.etatConsultation.fermeture === '') {
                this.dateErreur = 'La date d\'ouverture et la date de fermeture doivent être saisies...'
            } else {
                const ouvert = new Date(this.etatConsultation.ouverture).toISOString()
                const ferme = new Date(this.etatConsultation.fermeture).toISOString()
                if (diffTwoDatesInMinutes(ouvert, ferme) < 0) {
                    this.dateErreur = 'La date d\'ouverture doit être antérieure ou égale à la date de Fermeture...'
                } else if (diffTwoDatesInMinutes(formatDateYYYYMMDD(dateNow), ouvert) < 0) {
                    this.dateErreur = 'La date d\'ouverture doit être postérieure ou égale à la date d\'aujourd\'hui...'
                }  else if (diffTwoDatesInMinutes(formatDateYYYYMMDD(dateNow), ferme) < 0) {
                    this.dateErreur = 'La date de fermeture doit être postérieure ou égale à la date d\'aujourd\'hui...'
                }
            }
        } else if (Number(this.etatConsultation.etat) === 2) {
            if (!this.etatConsultation.fermeture) {
                this.dateErreur = 'La date de fermeture doit être saisie...'
            } else if (this.etatConsultation.fermeture === '') {
                this.dateErreur = 'La date de fermeture doit être saisie...'
            }  else  {
                const ferme = new Date(this.etatConsultation.fermeture).toISOString()
                if (diffTwoDatesInMinutes(formatDateYYYYMMDD(dateNow), ferme) < 0) {
                    this.dateErreur = 'La date de fermeture doit être postérieure ou égale à la date d\'aujourd\'hui...'
                }
            }
        }
    }

    /**
     * updateEtatConsultation
     * @description Enregistre les changements d'état de la consultation des documents
     * @return {void}
     */
    updateEtatConsultation(): void {
        let date_end: any = null
        if (this.etatConsultation.fermeture) {
            date_end =  moment(this.etatConsultation.fermeture).local()
            date_end.hours(23)
            date_end.minutes(59)
            date_end.seconds(59)
        }
        const datasConsultation  = {
            bibliotheque_fa_start_at : this.etatConsultation.ouverture,
            bibliotheque_fa_end_at : date_end.toISOString()
        }
        if (Number(this.etatConsultation.etat) === 0) {
            datasConsultation.bibliotheque_fa_start_at = null
            datasConsultation.bibliotheque_fa_end_at = null
        } else if (Number(this.etatConsultation.etat) === 2) {
            datasConsultation.bibliotheque_fa_start_at = null
        }

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.$store.dispatch('fournitureAdministrative/setConsultationFournituresAdministratives', datasConsultation)
            .then((response) => {
                this.activeSession = response.data.data
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }


    // -----------------------

    /**
     * load
     * @description Chargement des données
     * @return {Promise<void>}
     */
    async load(): Promise<void> {
        // Chargement des concours
        this.activeSession = this.$store.getters['session/sessionSelect']
        await this.$store.dispatch('concour/getConcours')
        await this.$store.dispatch('epreuve/getEpreuves')

        // Chargement des zones
        this.$store.dispatch('ville/getZones').then(() => {
            for (const z in this.$store.getters['ville/zones']) {
                this.options_zones_export.push(this.$store.getters['ville/zones'][z].id)
            }
        })

        // Chargement des compteurs issus du dashboard
        this.$store.dispatch('dashboardEcrits/getDashboardInformations').then((response: any) => {
            this.infos = response.data.data
        })

        // Chargement des conditionnements et des tournées
        await this.$store.dispatch('conditionnement/getConditionnements')
        this.type_conditionnement = this.$store.getters['conditionnement/conditionnements']
        await this.$store.dispatch('tournee/getTournees')

        this.initDates()

        // Chargement des centres
        this.$store.dispatch('repartitioncandidats/getListeCentres').then(() => {
            this.setFiltersForGenericTab()
        })
    }

    /**
     * mounted
     * @description Actions au chargement du composant
     * @return {void}
     */
    mounted(): void {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.$store.commit('repartitioncandidats/SET_LOADING', true)
            this.load()
        }
    }
}
