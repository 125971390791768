import { Ability } from '@/types/Ability'
import store from '@/store/index'

// Affichage quand la personne a terminé les taches
interface DashboardDoneInformations {
    type: string,
    doneText: string,
    doneBottomText?: string // utilisable si SmallCardRegularInterface
}

// Interface des cartes
interface SmallCardRegularInterface {
    cardType: string, // regular
    auth: string[],
    key: string,
    title: string,
    numberShow: number,
    textShowSingular: string,
    textShowPlural: string,
    link: string,
    bottomText?: string | undefined,
    done: DashboardDoneInformations
}

interface SmallCardThreeInformationsInterface {
    cardType: string, // threeInformations
    auth: string[],
    title: string,
    link: string,
    key: string,
    information1Number: number,
    information1TextSingular: string,
    information1TextPlural: string,
    information2Number: number,
    information2TextSingular: string,
    information2TextPlural: string,
    information3Number?: number,
    information3TextSingular?: string,
    information3TextPlural?: string,
    done: DashboardDoneInformations
}

interface SmallCardProgressBarInterface {
    cardType: string, // progressBar
    auth: string[],
    title: string,
    key: string,
    fractionNumber1: number,
    fractionNumber2: number,
    textShowSingular: string,
    textShowPlural: string,
    link: string,
    done: DashboardDoneInformations
}

interface SmallCardDuoCardInterface {
    cardType: string, // DUOCARD
    auth: string[],
    key: string,
    titleFirstCard: string,
    numberShowFirstCard: number,
    textShowSingularFirstCard: string,
    textShowPluralFirstCard: string,
    linkFirstCard: string,
    doneFirstCard: DashboardDoneInformations,
    titleSecondCard: string,
    numberShowSecondCard: number,
    textShowSingularSecondCard: string,
    textShowPluralSecondCard: string,
    linkSecondCard: string,
    doneSecondCard: DashboardDoneInformations
}

// interface spécifique a signature des documents. Affiche un chiffre + texte en mode normal puis un graphique en mode Done
interface SmallCardSignatureInterface {
    cardType: string, // SIGNATURECARD
    auth: string[],
    title: string,
    numberShow: number,
    textShowSingular: string,
    textShowPlural: string,
    link: string,
    bottomText?: string | undefined,
    done: {
        doneText: string,
        graphNumber1: number,
        graphNumber2: number,
        graphText1: string,
        graphText2: string
    }
}

// Interface de chaque ligne de cartes
interface BigCardType {
    cardIcon: string,
    auth: string[],
    allCards: (
        | SmallCardRegularInterface
        | SmallCardThreeInformationsInterface
        | SmallCardProgressBarInterface
        | SmallCardDuoCardInterface
        | SmallCardSignatureInterface
    )[]
}

const recrutementAcademiques: BigCardType = {
    cardIcon: 'graduation-cap',
    auth: [Ability.RH_SPS_VIEW],
    allCards: [
        {
            key: 'dossiers_academique',
            cardType: 'threeinformations',
            title: 'Dossiers académiques',
            auth: [Ability.RH_SPS_VIEW],
            information1Number: 0,
            information1TextSingular: 'Dossier publié',
            information1TextPlural: 'Dossiers publiés',
            information2Number: 0,
            information2TextSingular: 'Dossier validé',
            information2TextPlural: 'Dossiers validés',
            information3Number: 0,
            information3TextSingular: 'Dossier au total',
            information3TextPlural: 'Dossiers au total',
            link: '/dossiers_academiques',
            done: {
                type: 'string',
                doneText: 'doneSingular'
            }
        },
        {
            key: 'selection_intervenants',
            cardType: 'progressbar',
            auth: [Ability.RH_SPS_VIEW],
            title: 'Sélection des intervenants',
            fractionNumber1: 0,
            fractionNumber2: 0,
            textShowSingular: 'procès-verbal terminé',
            textShowPlural: 'procès-verbaux terminés',
            link: '/selections',
            done: {
                type: 'string',
                doneText: 'Toutes les sélections sont effectuées'
            }
        },
        {
            key: 'affectation_intervenants',
            cardType: 'progressbar',
            auth: [Ability.RH_SPS_VIEW],
            title: 'Affectation des intervenants',
            fractionNumber1: 0,
            fractionNumber2: 0,
            textShowSingular: 'Intervenant affecté',
            textShowPlural: 'Intervenants affectés',
            link: '/affectations',
            done: {
                type: 'string',
                doneText: 'Toutes les affectations sont effectuées'
            }
        }
    ]
}

const dossiersAdministratifs: BigCardType = {
    cardIcon: 'folder-open',
    auth: [Ability.RH_DOSADM_VIEW, Ability.RH_FRAIS_VIEW, Ability.RH_CONTRAT_VIEW],
    allCards: [
        {
            key: 'dossiers_administratif',
            cardType: 'threeinformations',
            title: 'dossiers administratifs',
            auth: [Ability.RH_DOSADM_VIEW],
            information1Number: 0,
            information1TextSingular: 'Dossier publié',
            information1TextPlural: 'Dossiers publiés',

            information2Number: 0,
            information2TextSingular: 'Dossier validé',
            information2TextPlural: 'Dossiers validés',

            information3Number: 0,
            information3TextSingular: 'Dossier au total',
            information3TextPlural: 'Dossiers au total',
            link: '/dossiers_administratifs',
            done: {
                type: 'string',
                doneText: 'doneSingular'
            }
        },
        {
            key: 'contrats',
            cardType: 'threeinformations',
            auth: [Ability.RH_CONTRAT_VIEW],
            title: 'Contrats',
            information1Number: 0,
            information1TextSingular: 'Publié pour signature',
            information1TextPlural: 'Publiés pour signature',

            information2Number: 0,
            information2TextSingular: 'A signer par la direction',
            information2TextPlural: 'A signer par la direction',

            information3Number: 0,
            information3TextSingular: 'Signé par les deux parties',
            information3TextPlural: 'Signés par les deux parties',
            link: '/contrats',
            done: {
                type: 'string',
                doneText: 'Tous les contrats sont signés'
            }
        }
    ]
}

if (store.getters['auth/findParameter']('ordreMissionDisabled')?.value !== '1') {
    dossiersAdministratifs.allCards.push({
        key: 'ordre_mission_demande_remboursement',
        cardType: 'duocard',
        auth: [Ability.RH_FRAIS_VIEW],

        titleFirstCard: 'Demandes de remboursement',
        numberShowFirstCard: 10,
        textShowSingularFirstCard: 'Remboursement à traiter',
        textShowPluralFirstCard: 'Remboursements à traiter',
        linkFirstCard: '/demandes_remboursement',
        doneFirstCard: {
            type: 'doneRegular',
            doneText: 'Pas de demandes à traiter'
        },

        titleSecondCard: 'Ordres de mission',
        numberShowSecondCard: 10,
        textShowSingularSecondCard: 'Ordre de mission à traiter',
        textShowPluralSecondCard: 'Ordres de mission à traiter',
        linkSecondCard: '/ordres_mission',
        doneSecondCard: {
            type: 'doneRegular',
            doneText: 'Pas de demandes à traiter'
        }
    })
}

const signatureDeLaDirection: BigCardType = {
    cardIcon: 'file-signature',
    auth: [Ability.SIGN_CONTRAT_VIEW, Ability.SIGN_PVA_VIEW, Ability.SIGN_LETTRE_VIEW, Ability.SIGN_CONVENTION_VIEW, Ability.SIGN_CONVENTIONANNEXE_VIEW, Ability.SIGN_FHRECAP_VIEW, Ability.SIGN_RECLAMATION_VIEW, Ability.SIGN_AVENANT_VIEW, Ability.SIGN_AMENAGEMENT_VIEW],
    allCards: [
        {
            key: 'nb_documents_a_signer',
            cardType: 'REGULAR', // SIGNATURECARD
            auth: [Ability.SIGN_CONTRAT_VIEW, Ability.SIGN_PVA_VIEW, Ability.SIGN_LETTRE_VIEW, Ability.SIGN_CONVENTION_VIEW, Ability.SIGN_CONVENTIONANNEXE_VIEW, Ability.SIGN_FHRECAP_VIEW, Ability.SIGN_RECLAMATION_VIEW, Ability.SIGN_AVENANT_VIEW, Ability.SIGN_AMENAGEMENT_VIEW],
            title: 'SIGNATURE DES DOCUMENTS',
            numberShow: 0,
            textShowSingular: 'Document à signer',
            textShowPlural: 'Documents à signer',
            link: '/signature_documents',
            bottomText: '',
            done: {
                doneText: 'Tous les documents sont signés',
                graphNumber1: 12,
                graphNumber2: 24,
                graphText1: 'SPS',
                graphText2: 'SOL'
            }
        }
    ]
}

const gestionSol: BigCardType = {
    cardIcon: 'clipboard-list',
    auth: [Ability.GC_CENTER_VIEW, Ability.GC_CONVENTION_VIEW],
    allCards: [
        {
            key: 'nb_centres',
            cardType: 'REGULAR', // SIGNATURECARD
            auth: [Ability.GC_CENTER_VIEW],
            title: 'CENTRES',
            numberShow: 1,
            textShowSingular: 'Centre',
            textShowPlural: 'Centres',
            link: '/centres',
            bottomText: '',
            done: {
                doneText: 'Pas de centre à gérer',
                graphNumber1: 12,
                graphNumber2: 24,
                graphText1: 'SPS',
                graphText2: 'SOL'
            }
        },
        {
            key: 'nb_conventions_signees',
            cardType: 'REGULAR', // SIGNATURECARD
            auth: [Ability.GC_CONVENTION_VIEW],
            title: 'CONVENTIONS SIGNÉES',
            numberShow: 1,
            textShowSingular: 'Convention',
            textShowPlural: 'Conventions',
            link: '/conventions',
            bottomText: '',
            done: {
                doneText: 'Toutes les conventions sont signées',
                graphNumber1: 12,
                graphNumber2: 24,
                graphText1: 'SPS',
                graphText2: 'SOL'
            }
        }
    ]
}

const functionsForDashboard = {
    dashboardElements: [signatureDeLaDirection, recrutementAcademiques, dossiersAdministratifs, gestionSol],

    // eslint-disable-next-line
    /** Fonction qui filtre les entrées du menu en fonction des droits de l'user */
    dashboardFilterFunction(
        dashboardElements:
        | BigCardType[]
        | SmallCardThreeInformationsInterface[]
        | SmallCardRegularInterface[]
        | SmallCardRegularInterface[]
    ): unknown {
        const dashboardElementsFilter: Set<any> = new Set()
        for (const element of dashboardElements) {
            for (const ua of element.auth) {
                if (store.getters['auth/can'](ua)) {
                    dashboardElementsFilter.add(element)
                }
            }
        }

        let dashboardFilterArray: any[] = []
        dashboardFilterArray = Array.from(dashboardElementsFilter)
        return dashboardFilterArray
    },

    /**
     * Fonction utilisant userAuth et theFilterFunction. Controle le menu et les sous-menu, renvois le tableau final pret pour SideBarLeft. Utilise theFilterFunction
     */
    // eslint-disable-next-line
    dashboardFiltered(dashboardElements: any[]): any {
        const dashboardFiltered = this.dashboardFilterFunction(dashboardElements)
        this.maj_compteurs()

        for (const element of dashboardElements) {
            if (element.allCards) {
                element.allCards = this.dashboardFilterFunction(element.allCards)
            }
        }

        return dashboardFiltered
    },

    getNbDocumentsSigner(): number {
        let result = 0
        const abilities: Ability[] = [Ability.SIGN_CONTRAT_VIEW, Ability.SIGN_PVA_VIEW, Ability.SIGN_LETTRE_VIEW, Ability.SIGN_CONVENTION_VIEW, Ability.SIGN_CONVENTIONANNEXE_VIEW, Ability.SIGN_FHRECAP_VIEW, Ability.SIGN_RECLAMATION_VIEW, Ability.SIGN_AVENANT_VIEW, Ability.SIGN_AMENAGEMENT_VIEW]
        abilities.forEach((ability: string) => {
            const data = store.getters['user/dashboard'][ability]
            if (data && data.nb_documents_a_signer) {
                result += data.nb_documents_a_signer
            }
        })

        return result
    },

    /**
     **  Fonction qui met a jour les différents compteurs aprés retour serveur
     */
    maj_compteurs(): any {
        const compteurs_sps = store.getters['user/dashboard'][Ability.RH_SPS_VIEW]
            ? store.getters['user/dashboard'][Ability.RH_SPS_VIEW]
            : null
        const compteurs_adm = store.getters['user/dashboard'][Ability.RH_DOSADM_VIEW]
            ? store.getters['user/dashboard'][Ability.RH_DOSADM_VIEW]
            : null
        const compteurs_sol = store.getters['user/dashboard'][Ability.RH_SOL_VIEW]
            ? store.getters['user/dashboard'][Ability.RH_SOL_VIEW]
            : null

        const nb_documents_a_signer: any = signatureDeLaDirection.allCards.find(
            (c: any) => c.key === 'nb_documents_a_signer'
        )
        if (nb_documents_a_signer) {
            nb_documents_a_signer.numberShow = this.getNbDocumentsSigner()
        }

        const dossiers_academique: any = recrutementAcademiques.allCards.find(
            (c: any) => c.key === 'dossiers_academique'
        )
        if (dossiers_academique) {
            dossiers_academique.information1Number = compteurs_sps ? compteurs_sps.nb_dossiers_ouverts : 0
            dossiers_academique.information3Number = compteurs_sps ? compteurs_sps.nb_dossiers_total : 0
            dossiers_academique.information2Number = compteurs_sps ? compteurs_sps.nb_dossiers_valides : 0
        }

        const selection_intervenants: any = recrutementAcademiques.allCards.find(
            (c: any) => c.key === 'selection_intervenants'
        )
        if (selection_intervenants) {
            selection_intervenants.fractionNumber1 = compteurs_sps ? compteurs_sps.nb_pv_valides : 0
            selection_intervenants.fractionNumber2 = compteurs_sps ? compteurs_sps.nb_pv_total : 0
        }

        const affectation_intervenants: any = recrutementAcademiques.allCards.find(
            (c: any) => c.key === 'affectation_intervenants'
        )
        if (affectation_intervenants) {
            affectation_intervenants.fractionNumber1 = compteurs_sps ? compteurs_sps.nb_intervenants_affectation : 0
            affectation_intervenants.fractionNumber2 = compteurs_sps ? compteurs_sps.nb_intervenants_besoin : 0
        }

        const dossiers_administratif: any = dossiersAdministratifs.allCards.find(
            (c: any) => c.key === 'dossiers_administratif'
        )
        if (dossiers_administratif && compteurs_adm) {
            dossiers_administratif.information1Number = compteurs_adm.nb_dossiers_ouverts
            dossiers_administratif.information3Number = compteurs_adm.nb_dossiers_total
            dossiers_administratif.information2Number = compteurs_adm.nb_dossiers_valides
        }

        const contrats: any = dossiersAdministratifs.allCards.find((c: any) => c.key === 'contrats')
        if (contrats) {
            contrats.information1Number = compteurs_adm ? compteurs_adm.nb_contrats_publies : 0
            contrats.information2Number = compteurs_adm ? compteurs_adm.nb_contrats_signes_intervenants : 0
            contrats.information3Number = compteurs_adm ? compteurs_adm.nb_contrats_signes : 0
        }

        const ordre_mission_demande_remboursement: any = dossiersAdministratifs.allCards.find(
            (c: any) => c.key === 'ordre_mission_demande_remboursement'
        )
        if (ordre_mission_demande_remboursement) {
            ordre_mission_demande_remboursement.numberShowFirstCard = compteurs_adm
                ? compteurs_adm.nb_demandes_remb_total - compteurs_adm.nb_demandes_remb_traitees
                : 0
            ordre_mission_demande_remboursement.numberShowSecondCard = compteurs_adm
                ? compteurs_adm.nb_ordres_mission_total - compteurs_adm.nb_ordres_mission_traites
                : 0
        }

        const nb_centres: any = gestionSol.allCards.find((c: any) => c.key === 'nb_centres')
        if (nb_centres) {
            nb_centres.numberShow = compteurs_sol ? compteurs_sol.nb_centres_total : 0
        }
        const nb_conventions_signees: any = gestionSol.allCards.find((c: any) => c.key === 'nb_conventions_signees')
        if (nb_conventions_signees) {
            nb_conventions_signees.numberShow = compteurs_sol ? compteurs_sol.nb_conventions_signees : 0
        }
    }
}

export {
    functionsForDashboard,
    SmallCardRegularInterface,
    SmallCardProgressBarInterface,
    SmallCardThreeInformationsInterface,
    SmallCardDuoCardInterface
}
