













import { Vue, Component } from 'vue-property-decorator'
import SallesOrauxCentre from '@/components/Centre/Salles/SallesCentreOraux.vue'
import { mapGetters, mapState } from 'vuex'
import ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    components: {
        SallesOrauxCentre,
        ErrorDisplay
    },
    computed: {
        ...mapState('centre', ['centreSelect', 'error', 'loading']),
        ...mapGetters('centre', ['informations_is_submit', 'informations_is_validate', 'etat_centre_select']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})
export default class OrganisationOral extends Vue {
    centre_id: any
    beforeMount () {
        this.centre_id = this.$route.params.centre_id
    }
}
