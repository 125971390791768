











































import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { isEmpty } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { FichePosteStep } from '../../types/BourseEmploi';
import { WorkingEstablishmentInterface } from '../../types/WorkingEstablishment';
import { WorkingEstablishmentTypeInterface } from '../../types/WorkingEstablishmentType';
import Select from './Select.vue';
import { Ability } from '@/types/Ability';

@Component({
    name: 'SelectUserComponent',
    components: {
        FontAwesomeIcon,
        Select
    },
    props: {
        establishment: {
            type: Object,
            required: false,
            default: null
        },
        selectRole: {
            type: Boolean,
            required: false,
            default: true
        },
        formBtn: {
            type: Boolean,
            required: false,
            default: false
        },
        jobProcess: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        canGoNextStep(): boolean {
            const can = this.$store.getters['auth/can']
            const workingEstablishmentsIds: number[] = this.$store.getters['user/reportingUserSelect']?.working_establishments?.map((item: any) => item.id) || []
            const canManageJob: boolean = can(Ability.ADM_ESTABLISHMENT_MANAGE) || can(Ability.EST_ESTABLISHMENT_PARENT_OWN_MANAGE) || (can(Ability.EST_ESTABLISHMENT_OWN_MANAGE) && workingEstablishmentsIds.includes(this.$props.establishment.id) && can(Ability.EST_JOB_OWN_MANAGE))

            return (
                this.$data.userSelected !== null &&
                !this.$data.processing &&
                !this.$data.loading &&
                !isEmpty(this.$data.rolesSelected) &&
                !isEmpty(this.$data.fonction) &&
                (this.$props.establishment?.workingEstablishmentChildren?.length > 0 ||
                (this.$props.establishment?.workingEstablishmentType?.job_description_creation_possible && canManageJob))
            )
        }
    },
    watch: {
        processing: {
            handler(val) {
                this.$emit('processing', val)
            }
        },
        establishment: {
            immediate: true,
            deep: true,
            handler: 'setRoles'
        }
    }
})

export default class SelectUser extends Vue {
    FichePosteStep = FichePosteStep
    processing = false as boolean
    isEmpty = isEmpty as typeof isEmpty
    searchString = '' as string
    loading = false as boolean
    results = null as any
    userSelected = null as any
    roles = [] as WorkingEstablishmentTypeInterface[]
    rolesSelected = [] as WorkingEstablishmentTypeInterface[]
    fonction = '' as string

    async reset(): Promise<void> {
        this.processing = false
        this.results = null
        this.searchString = ''
        this.loading = false
        this.userSelected = null
        this.rolesSelected = []
        this.fonction = ''
    }

    search(): void {
        if (!this.searchString || this.searchString.length < 2 || this.loading) {
            return
        }
        this.loading = true

        this.$store.dispatch('workingEstablishment/usersSearch', { name: this.searchString, working_establishment_id: this.$props.establishment?.id || Number(this.$route.params?.working_establishment_id) || 0 })
            .then((response) => {
                this.results = response.data.data
                    ?.map((item: any) => ({ ...item, title: `${item.name} ${item.first_name} (${item.email})` }))
                    ?.filter((item: any) => this.$store.getters['auth/authUser'].id !== item.id) || []
                this.userSelected = null
            })
            .finally(() => this.loading = false)
    }

    async setRoles(): Promise<void> {
        if (this.$props.selectRole) {
            let establishment: WorkingEstablishmentInterface = Object.assign({}, this.$props.establishment || {})

            if (isEmpty(establishment)) {
                establishment = Object.assign({}, this.$store.getters['workingEstablishment/workingEstablishmentSelect'] || {})
                if (this.$route.params?.working_establishment_id && establishment?.id !== Number(this.$route.params?.working_establishment_id)) {
                    await this.$store.dispatch('workingEstablishment/getWorkingEstablishment', { working_establishment_id: this.$route.params?.working_establishment_id })
                    establishment = Object.assign({}, this.$store.getters['workingEstablishment/workingEstablishmentSelect'] || {})
                }
            }

            if (isEmpty(establishment)) {
                this.roles = []
            } else {
                if (isEmpty(this.$store.getters['workingEstablishmentType/workingEstablishmentTypes'])) {
                    await this.$store.dispatch('workingEstablishmentType/getWorkingEstablishmentTypes')
                }

                this.roles = this.$store.getters['workingEstablishmentType/workingEstablishmentTypes']
                    ?.find((establishmentType: WorkingEstablishmentTypeInterface) => establishmentType.id === (establishment.working_establishment_type_id))
                    ?.roles
                    ?.map((item: any) => ({ title: item })) || []

                if (this.roles.length === 1) {
                    this.rolesSelected = this.roles
                }
            }
        }
    }

    send(createJob = false): void {
        if (this.userSelected === null || this.loading || this.processing) {
            return
        }
        this.processing = true

        this.$store.dispatch('user/addReportingUser', {
            user_id: this.userSelected.id,
            roles: this.rolesSelected.map((item: any) => item.title) || [],
            working_establishment_id: this.$props.establishment?.id || Number(this.$route.params?.working_establishment_id) || 0,
            fonction: this.fonction
        })
            .then((response) => {
                this.$emit('next', { data: response.data.data, createJob: createJob, setUser: true })
                this.reset()
            })
            .finally(() => this.processing = false)
    }

    /**
     * @description Vérifie si l'utilisateur consulte en détail un établissement
     * @returns {void}
     */
    isInDetailEstablishment(): boolean {
        return !!this.$route.params.working_establishment_id && this.$route.path === `/bourse_emploi/etablissements/${this.$route.params.working_establishment_id}`
    }
}
