export interface EtablissementInterface {
    name: string,
    academie: string,
    adresse: string,
    code: string,
    code_postal: string,
    type: string,
    ville: string,
    id: number | 0,
    select_name?: string
}

export interface StateEtablissements {
    etablissements: Array<EtablissementInterface> | [],
    meta: Array<string> | null,
    links: Array<string> | null,
    loading: boolean | false,
    error: Array<string> | null,
    totalRows: number | 0,
    etablissementSelect: EtablissementInterface | {},
    currentPage: number | 0,
    lastPage: number | 0
}

export enum EtablissementType {
    TYPE_UNIVERSITE = 'Université - Facultés et IUT',
    TYPE_CLASSE_PREPA_GRANDE_ECOLE = 'Classes préparatoires aux grandes écoles',
    TYPE_CLASSE_ECOLE_INGENIEUR = "Ecole d'ingénieurs",
    TYPE_LYCEE = 'Lycées et autres établissements de secondaire',
    TYPE_ECOLE_COMMERCE = 'Ecole de commerce, santé, social, fonctionnaires, éducation',
    TYPE_AUTRE = 'Autre',
}
