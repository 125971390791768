export interface JobDescriptionInterface {
    candidaciesValidated: Array<any>,
    id: number | 0,
    session_id: number | null,
    name: string,
    type: number | 0,
    ordre: number | 0,
    code: string
}

export interface StateJobDescriptions {
    jobDescriptions: Array<JobDescriptionInterface> | [],
    meta: Array<string> | null,
    links: Array<string> | null,
    loading: boolean | false,
    error: Array<string> | null,
    totalRows: number | 0,
    jobDescriptionSelect: JobDescriptionInterface | {}
}


export enum JobDescriptionStatus {
    STATUS_DRAFT = 0,
    STATUS_PUBLISHED = 1,
    STATUS_PROVIDED = 2
}

export function getJobDescriptionStatus(jobDescriptionStatus: JobDescriptionStatus) {
    switch (jobDescriptionStatus) {
        case JobDescriptionStatus.STATUS_PUBLISHED:
            return {index: 1, name: 'Fiche soumise', color: 'info' }
        case JobDescriptionStatus.STATUS_PROVIDED:
            return {index: 2, name: 'Poste attribué', color: 'success' }
        default:
            return {index: 0, name: 'Brouillon', color: 'secondary' }
    }
}
