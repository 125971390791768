



















































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import Centres from '@/views/Sol/Centres.vue'
import { ExaSignature } from '@exatech-group/signature'
import '@exatech-group/signature/dist/style.css'
import VuePdfApp from 'vue-pdf-app'
import { mapGetters, mapState } from 'vuex'
import { PosteContext } from '@/types/Poste'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

@Component({
    components: {
        Centres,
        ExaSignature,
        VuePdfApp,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapState('gabaritFicheHoraire', ['error', 'loading', 'centre_user_fh']),
        ...mapGetters('gabaritFicheHoraire', ['get_total_fiche_horaire']),
        ...mapGetters('auth', ['is_intervenant_centre']),
        ...mapState('auth', ['user'])
    }

})

export default class SignatureFicheHoraire extends Vue {
    source_doc: any = ''
    showError = false
    showWarning = false
    message_error = ''
    showAnnulationSignature = false
    modeValidation = false
    signature = ''
    modeSignature = false
    centre_id = 0
    user_id = 0
    current_poste_id = 0
    fiches: Array<any> = []
    showConsultDocument = false
    config = {
        toolbar: {
            toolbarViewerRight: { presentationMode: false, openFile: false, viewBookmark: false, secondaryToolbarToggle: false }
        }
    }

    file_name = ''

    isContratSigneForPoste = false

    @Watch('error')
    setError () {
        if (this.$store.state.pv.error !== null) {
            this.showError = true
        } else {
            this.showError = false
        }
    }


    /** Retourne la couleur selon avancée de la signature */
    getEtatColor(fiche: any) {
        let color = 'secondary'

        if (!fiche.fiche_signed_at) {
            color = 'info'
        }
        if (fiche.fiche_validated_at && fiche.fiche_signed_at) {
            color = 'success'
        }

        return color
    }

    /** Enregistre la signature si besoin et update le pv */
    saveSignature (params: any) {
        this.signature = params.signature
        this.$store.dispatch('gabaritFicheHoraire/getTokenSignatureFicheHoraire',
            {
                centre_id: this.centre_id,
                user_id: this.user_id,
                poste_id: this.current_poste_id,
                mode: params.mode
            })
    }

    /** Validation de la signature de la fiche horaire */
    validationFicheHoraire (tokenSMS: string) {
        if (this.signature !== '') {
            this.$store.state.dossieradministratif.error = null
            const datas = {
                signature: this.signature,
                code: tokenSMS
            }

            this.$store.dispatch('gabaritFicheHoraire/signatureFicheHoraire',
                {
                    centre_id: this.centre_id,
                    user_id: this.user_id,
                    poste_id: this.current_poste_id,
                    payload: datas
                })
                .then(() => {
                    if (!this.$store.state.dossieradministratif.error) {
                        this.$store.dispatch('gabaritFicheHoraire/getFicheHoraire', { centreId: this.centre_id })
                            .then((response) => {
                                this.centre_id = response.data.data.centre_id
                                this.user_id = response.data.data.user_id
                                this.fiches = response.data.data.postes

                                // Met à jour le centre du user dans le cadre du CDC
                                if (this.$store.getters['auth/is_intervenant_centre']) {
                                    this.$store.dispatch('centre/getCentre', { centre_id: response.data.data.centre_id })
                                }

                                this.$router.push({ path: '/signature_fiche_horaire' })
                            })
                            .catch((error) => {
                                console.log('ko: ' + error)
                            })
                        this.modeSignature = false
                        this.modeValidation = false
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }
                        this.$bvToast.toast('Signature enregistrée !', succesToaster)
                    }
                })
        }
    }

    /** Affiche si des erreurs de signature sont retournées */
    showErrorSignature (error: string) {
        if (error !== '') {
            this.$store.state.gabaritFicheHoraire.error = error
        }
    }

    /** Annule la signature en cours */
    confirmAnnulation () {
        this.signature = ''
        this.current_poste_id = 0
        this.modeSignature = false
        this.showAnnulationSignature = false
        this.$router.push({ path: '/signature_fiche_horaire' })
    }

    /** Bouton Retour */
    back () {
        this.showAnnulationSignature = true
    }

    /** Ferme la popup d'annulation de signature */
    closeAnnulationSignature () {
        this.showAnnulationSignature = false
    }

    /** Ouvre le mode signature de la fiche horaire */
    signerFiche (fiche_id: number) {
        this.current_poste_id = fiche_id
        this.$store.dispatch('gabaritFicheHoraire/getPdfFicheHoraire', { poste_id: this.current_poste_id, user_id: this.user_id, centre_id: this.centre_id })
            .then((response) => {
                if (!this.$route.query.mode) {
                    this.$router.push({ path: '/signature_fiche_horaire', query: { mode: 'signature', fiche: this.current_poste_id.toString(), user_id: this.user_id.toString(), centre_id: this.centre_id.toString() } })
                }
                this.file_name = 'Fiche_Horaire_' + this.$store.state.auth.user.name + '_' + this.$store.state.gabaritFicheHoraire.centre_user_fh.postes[0].name
                this.source_doc = response.data
                this.modeSignature = true
            })
            .catch((error) => {
                console.log('ko: ' + error)
            })
    }

    /** Consulter la fiche horaire totalement signée */
    consulterFiche (fiche_id: number) {
        this.$store.state.gabaritFicheHoraire.error = null
        this.current_poste_id = fiche_id
        this.$store.dispatch('gabaritFicheHoraire/getPdfFicheHoraire', { poste_id: this.current_poste_id, user_id: this.user_id, centre_id: this.centre_id })
            .then((response) => {
                this.file_name = 'Fiche_Horaire_' + this.$store.state.auth.user.name + '_' + this.$store.state.gabaritFicheHoraire.centre_user_fh.postes[0].name
                this.source_doc = response.data
                this.showConsultDocument = true
            })
            .catch((error) => {
                console.log('ko: ' + error)
            })
    }

    /**  Ferme la consultation d'un contrat */
    closeConsultationDocument () {
        this.source_doc = ''
        this.showConsultDocument = false
    }

    /** Chargement des données */
    loadData () {
        // if (this.$store.state.centre.centreSelect.fiches_intervenants_signed_at) {
        this.$store.dispatch('gabaritFicheHoraire/getFicheHoraire', { centreId: this.$store.state.user.centreId }).then((response) => {
            this.centre_id  = response.data.data.centre_id
            this.user_id = response.data.data.user_id
            this.isContratSigneForPoste = response.data.data.isContratSigneForPoste
            this.fiches = response.data.data.postes.filter((p: any) => p.context !== PosteContext.CONTEXT_RESPONSABLE_CENTRE)

            // Reprend le mode signature si refresh
            if (this.modeSignature && this.$store.state.gabaritFicheHoraire.centre_user_fh !== null) {
                this.user_id = Number(this.$route.query.user_id)
                this.centre_id = Number(this.$route.query.centre_id)
                this.signerFiche(Number(this.$route.query.fiche))
            }
        }).catch((error) => {
            console.log('ko: ' + error)
        })
        // }
    }

    signatureMode = []
    mounted () {
        for(let i = 0; i < this.$store.state.auth.user.parameters.length; i ++) {
            if(this.$store.state.auth.user.parameters[i].code === 'signatureMode') {
                this.signatureMode = this.$store.state.auth.user.parameters[i].value
                // this.codeClient = 'CCMP'
            }
        }
        if (this.$route && this.$route.query && this.$route.query.mode === 'signature') {
            this.modeSignature = true
        }
        this.loadData()
    }
}
