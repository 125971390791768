



























































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'
import { Etat, getEtatSpec } from '@/types/DossierAdministratif'
import { ExaSignature } from '@exatech-group/signature'
import '@exatech-group/signature/dist/style.css'
import { formatDate, base64ToArrayBuffer, getFileNameFromHeader } from '@/utils/helpers'
import DemandeOrdreMission from '@/components/OrdresMission//DemandeOrdreMission.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VuePdfApp from 'vue-pdf-app'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { Ability } from '@/types/Ability'

@Component({
    components: {
        ExaSignature,
        DemandeOrdreMission,
        'font-awesome-icon': FontAwesomeIcon,
        VuePdfApp,
        ErrorDisplay
    },
    computed: {
        ...mapGetters('auth', [
            'authUser',
            'can',
            'cannot',
            'isA',
            'isNotA'
        ]),
        ...mapGetters('centre', ['conventions', 'source_pdf', 'error', 'loading', 'loading_pdf']),
        ...mapGetters('auth', ['is_intervenant_centre']),
        ...mapState('auth', ['user']),
        ...mapState('centre', ['centreSelect'])
    },
    methods: {
        ...mapActions('centre', ['updateCentre'])
    }
})
export default class ConventionsIntervenant extends Vue {
    @Watch('conventions')
    setClass () {
        if (this.$store.state.centre.conventions.length !== 0) {
            // Filtres selon published_at
            this.tab_conventions = this.$store.state.centre.conventions.filter((c: any) => c.convention_published_at !== null)
            this.tab_annexe_conventions = this.$store.state.centre.conventions.filter((c: any) => c.annexe_published_at !== null)
            if (this.$route && this.$route.query && this.$route.query.mode === 'signature' && this.$route.query.convention !== null && !this.signatureConventionEnCours && !this.signatureAnnexeConventionEnCours) {
                const convention = {
                    id: this.$route.query.convention
                }
                this.signatureConvention(convention)
            }
        }
    }

    @Watch('response_token')
    setResponseToken () {
        if (this.$store.state.dossieradministratif.response_token && this.$store.state.dossieradministratif.response_token.status !== '200') {
            this.$store.state.dossieradministratif.error = 'Une erreur est survenue'
        }
    }

    @Watch('$route')
    goBack () {
        if (this.$route.query && !this.$route.query.mode && this.modeSignature) {
            this.modeSignature = false
        } else if (this.$route.query.mode === 'signature' && this.$route.query.convention !== null) {
            if (this.$store.state.centre.conventions.length !== 0) {
                // Filtres selon published_at
                this.tab_conventions = this.$store.state.centre.conventions.filter((c: any) => c.convention_published_at !== null)
                this.tab_annexe_conventions = this.$store.state.centre.conventions.filter((c: any) => c.annexe_published_at !== null)
                if (this.$route && this.$route.query && this.$route.query.mode === 'signature' && this.$route.query.convention !== null) {
                    const convention = {
                        id: this.$route.query.convention
                    }

                    if (this.signatureConventionEnCours) {
                        this.signatureConvention(convention)
                    } else if (this.signatureAnnexeConventionEnCours) {
                        this.signatureAnnexeConvention(convention)
                    }
                }
            }
        }
    }

    // DATAS
    edition = true
    libelle = ''
    modeSignature = false
    modeValidation = false
    signatureConventionEnCours = false // définit qu'on est en cours de signature d'une Convention
    signatureAnnexeConventionEnCours = false // définit qu'on est en cours de signature d'une Annexe de convention
    showAnnulationSignature = false
    showConsultConvention = false
    getEtatSpec = getEtatSpec
    Etat = Etat
    Ability = Ability
    signature = ''
    showDemandeOrdreMission = false
    config = {
        toolbar: {
            toolbarViewerRight: { presentationMode: false, openFile: false, viewBookmark: false, secondaryToolbarToggle: false }
        }
    }

    source_doc: any = ''
    tab_conventions: Array<any> = []
    tab_annexe_conventions: Array<any> = []
    convention_select: any = null
    file_name: any = ''

    // METHODS

    // Retourne une description selon etat de la convention
    getDescription (convention: any) {
        let description = ''
        if (convention.convention_published_at && !convention.convention_signed_at && !convention.convention_validated_at) {
            description = 'Convention en attente de signature'
        } else if (convention.convention_published_at && convention.convention_signed_at && !convention.convention_validated_at) {
            description = 'Vous avez signé le ' + formatDate(convention.convention_signed_at)
        } else if (convention.convention_published_at && convention.convention_signed_at && convention.convention_validated_at) {
            description = 'Convention finalisée le ' + formatDate(convention.convention_validated_at)
        }

        return description
    }

    getAnnexeDescription (convention: any) {
        let description = ''
        if (convention.annexe_published_at && !convention.annexe_signed_at && !convention.annexe_validated_at) {
            if (!convention.convention_signed_at) {
                description = 'Vous devez d\'abord signer la convention afin de pouvoir signer l\'annexe de convention...'
            } else {
                description = 'Annexe de convention en attente de signature'
            }
        } else if (convention.annexe_published_at && convention.annexe_signed_at && !convention.annexe_validated_at) {
            description = 'Vous avez signé le ' + formatDate(convention.annexe_signed_at)
        } else if (convention.annexe_published_at && convention.annexe_signed_at && convention.annexe_validated_at) {
            description = 'Annexe de convention finalisée le ' + formatDate(convention.annexe_validated_at)
        }

        return description
    }

    getColor (convention: any) {
        let color = 'secondary'
        if (convention.convention_published_at && !convention.convention_signed_at && !convention.convention_validated_at) {
            color = 'secondary'
        } else if (convention.convention_published_at && convention.convention_signed_at && !convention.convention_validated_at) {
            color = 'info'
        } else if (convention.convention_published_at && convention.convention_signed_at && convention.convention_validated_at) {
            color = 'success'
        }

        return color
    }

    getAnnexeColor (convention: any) {
        let color = 'secondary'
        if (convention.annexe_published_at && !convention.annexe_signed_at && !convention.annexe_validated_at) {
            if (!convention.convention_signed_at) {
                color = 'tertiary'
            } else {
                color = 'secondary'
            }
        } else if (convention.annexe_published_at && convention.annexe_signed_at && !convention.annexe_validated_at) {
            color = 'info'
        } else if (convention.annexe_published_at && convention.annexe_signed_at && convention.annexe_validated_at) {
            color = 'success'
        }

        return color
    }

    // Ouvre le mode signature pour une convention
    signatureConvention (convention: any) {
        this.$store.state.centre.error = null

        if (!convention.convention_signed_at) {
            this.$store.dispatch('centre/getConventionPDF', { centre_id: convention.id }).then((response) => {
                if (!this.$route.query.mode) {
                    this.$router.push({ path: '/conventions_own', query: { mode: 'signature', convention: convention.id } })
                }
                const actual_centre = this.$store.state.centre.conventions[0]
                this.file_name = getFileNameFromHeader(response.headers) || 'Convention_' + actual_centre.name
                this.source_doc = base64ToArrayBuffer(this.$store.state.centre.source_pdf)
                this.signatureConventionEnCours = true
                this.modeSignature = true
            }).catch((error) => {
                console.log(error)
                this.$router.push({ path: '/conventions_own' })
            })
        } else {
            this.$router.push({ path: '/conventions_own' })
        }
    }

    // Ouvre le mode signature pour une annexe de convention
    signatureAnnexeConvention (convention: any) {
        this.$store.state.centre.error = null

        if (!convention.annexe_signed_at) {
            this.$store.dispatch('centre/getAnnexeConventionPDF', { centre_id: convention.id }).then((response) => {
                if (!this.$route.query.mode) {
                    this.$router.push({ path: '/conventions_own', query: { mode: 'signature', convention: convention.id } })
                }
                const actual_centre = this.$store.state.centre.conventions[0]
                this.file_name = getFileNameFromHeader(response.headers) || 'Annexe_convention_' + actual_centre.name
                this.source_doc = base64ToArrayBuffer(this.$store.state.centre.source_pdf)
                this.signatureAnnexeConventionEnCours = true
                this.modeSignature = true
            }).catch((error) => {
                console.log(error)
                this.$router.push({ path: '/conventions_own' })
            })
        } else {
            this.$router.push({ path: '/conventions_own' })
        }
    }

    // Affiche la convention
    consultConvention (convention: any) {
        this.$store.state.centre.error = null
        this.$store.dispatch('centre/getConventionPDF', { centre_id: convention.id }).then((response) => {
            const actual_centre = this.$store.state.centre.conventions[0]
            this.file_name = getFileNameFromHeader(response.headers) || 'Convention_' + actual_centre.name
            this.source_doc = base64ToArrayBuffer(this.$store.state.centre.source_pdf)
            this.showConsultConvention = true
        }).catch((error) => {
            console.log(error)
        })
    }

    // Affiche l'annexe de convention
    consultAnnexeConvention (convention: any) {
        this.$store.state.centre.error = null
        this.$store.dispatch('centre/getAnnexeConventionPDF', { centre_id: convention.id }).then((response) => {
            const actual_centre = this.$store.state.centre.conventions[0]
            this.file_name = getFileNameFromHeader(response.headers) || 'Annexe_convention_' + actual_centre.name
            this.source_doc = base64ToArrayBuffer(this.$store.state.centre.source_pdf)
            this.showConsultConvention = true
        }).catch((error) => {
            console.log(error)
        })
    }

    // Enregistre la signature sur le contrat
    saveSignature (params: any) {
        this.signature = params.signature

        // Requete demande de token
        this.$store.dispatch('centre/getTokenSignature', { centre_id: this.$route.query.convention, mode: params.mode })
    }

    // Affiche si des erreurs de signature sont retournées
    showErrorSignature (error: string) {
        this.$store.state.centre.error = error
    }

    // Annule la signature en cours
    confirmAnnulation () {
        this.reinitInterface()
    }

    reinitInterface () {
        this.signature = ''
        this.modeSignature = false
        this.signatureConventionEnCours = false
        this.signatureAnnexeConventionEnCours = false
        this.showAnnulationSignature = false
        this.$router.push({ path: '/conventions_own' })
    }

    // Bouton Retour
    back () {
        this.showAnnulationSignature = true
    }

    // Ferme la popup d'annulation de signature
    closeAnnulationSignature () {
        this.showAnnulationSignature = false
    }

    // Ferme la consultation d'une convention
    closeConsultationConvention () {
        this.showConsultConvention = false
    }

    // Confirme la signature du contrat
    validationConvention (tokenSMS: string) {
        if (this.signature !== '') {
            this.$store.state.centre.error = null

            let datas = {}
            let msgToast = ''
            if (this.signatureConventionEnCours) {
                datas = {
                    id: this.$route.query.convention,
                    convention_signature: this.signature,
                    code: tokenSMS
                }
                msgToast = 'La convention a été signée avec succès.'
            } else if (this.signatureAnnexeConventionEnCours) {
                datas = {
                    id: this.$route.query.convention,
                    annexe_signature: this.signature,
                    code: tokenSMS
                }
                msgToast = "L'annexe de convention a été signée avec succès."
            }

            this.$store.dispatch('centre/updateCentre', datas)
                .then(async () => {
                    await this.$store.dispatch('centre/getConventions', { centre_id: this.$store.state.user.centreId })

                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast(msgToast, succesToaster)

                    this.reinitInterface()
                })
                .catch(() => {
                    console.log('error')
                })
        }
    }

    signatureMode = []
    mounted () {
        for(let i = 0; i < this.$store.state.auth.user.parameters.length; i ++) {
            if(this.$store.state.auth.user.parameters[i].code === 'signatureMode') {
                this.signatureMode = this.$store.state.auth.user.parameters[i].value
                // this.codeClient = 'CCMP'
            }
        }
        this.load()
    }

    load() {
        this.$store.dispatch('centre/getConventions', { centre_id: this.$store.state.user.centreId })
        if (this.$route && this.$route.query && this.$route.query.mode === 'signature') {
            const index = this.$store.state.centre.conventions.findIndex((e: any) => e.id === this.$route.query.convention)
            this.convention_select = this.$store.state.centre.conventions[index]
            this.modeSignature = true
        }
    }
}
