



























































import { Vue, Component } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { Ability } from '@/types/Ability'
import { checkIcone } from '@/utils/helpers'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import Back from '@/components/Tools/Back.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

@Component({
    components: {
        ExaGenericTable,
        ErrorDisplay,
        Back,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('rgpdentity', ['loading', 'error', 'rgpdEntities', 'meta', 'links', 'totalRows']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    },
    methods: {
        ...mapActions('user', ['getRoles'])
    }
})

export default class RgpdEntities extends Vue
{

    Ability = Ability

    // Ensemble des colonnes du tableau
    genericfields = [
        { key: 'etatEdit',      label: '', sortable: false, class: '', type: 'action' },
        { key: 'entity_type',   label: 'Modèle RGPD', sortable: true, class: '', type: 'text' },
        { key: 'conservation',  label: 'Délai de conservation (en mois)', sortable: true, class: 'text-center', type: 'text' }
    ]

    rgpdEntity: any = null
    rgpdEntityTemp: any = null
    dataForTab: Array<any> = []
    showModalEditionRgpdEntity = false


    // Edition d'un modele rgpd
    editRgpdEntity (row: any) {
        this.rgpdEntity = row
        this.rgpdEntityTemp = {
            id: this.rgpdEntity.id,
            entity_type: this.rgpdEntity.entity_type,
            conservation: this.rgpdEntity.conservation
        }
        this.showModalEditionRgpdEntity = true
    }

    // Annule l'édition
    cancelEdit () {
        this.rgpdEntity = null
        this.rgpdEntityTemp = null
        this.showModalEditionRgpdEntity = false
    }

    // Enregistrement de l'édition d'un modele rgpd
    editSuite () {
        const payload = {
            id: this.rgpdEntityTemp.id,
            entity_type: this.rgpdEntityTemp.entity_type,
            conservation: this.rgpdEntityTemp.conservation
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        if (this.rgpdEntityTemp.id === 0) {
            this.$store.dispatch('rgpdentity/addRgpdEntity', payload)
                .then(() => {
                    this.rgpdEntity = null
                    this.rgpdEntityTemp = null
                    this.showModalEditionRgpdEntity = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                    this.$store.dispatch('rgpdentity/getRgpdEntities').then(() => {
                        this.setDataForGenericTab(this.$store.state.rgpdentity.rgpdEntities)
                    })
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            this.$store.dispatch('rgpdentity/updateRgpdEntity', payload)
                .then(() => {
                    this.rgpdEntity = null
                    this.rgpdEntityTemp = null
                    this.showModalEditionRgpdEntity = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                    this.$store.dispatch('rgpdentity/getRgpdEntities').then(() => {
                        this.setDataForGenericTab(this.$store.state.rgpdentity.rgpdEntities)
                    })
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    // Remplissage du tableau
    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                const can = this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE)
                const icone = checkIcone(Ability.ADM_PAR_MANAGE, can)

                const line = [
                    { label: icone.label, item: result,      type: 'action',  typeAction: 'edit', class: 'commons_first_action_button', icon: icone.icon, disabled: false },
                    { label: '', item: result.entity_type,   type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.conservation,  type: 'text', typeAction: null, class: 'text-center' }
                ]
                this.dataForTab.push(line)
            }
        }
    }


    // Events
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'edit':
                    this.editRgpdEntity(paParams[1])
                    break
                default:
                    break
            }
        }
    }


    mounted () {
        this.load()
    }

    load() {
        this.$store.dispatch('rgpdentity/getRgpdEntities').then(() => {
            this.setDataForGenericTab(this.$store.state.rgpdentity.rgpdEntities)
        })
    }
}
