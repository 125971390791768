













































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import SmallCardDuo from '@/components/dashboardComp/SmallCardDuo.vue'
import SmallCardProgressBar from '@/components/dashboardComp/SmallCardProgressBar.vue'
import SmallCardRegular from '@/components/dashboardComp/SmallCardRegular.vue'
import SmallCardThreeInformations from '@/components/dashboardComp/SmallCardThreeInformations.vue'
import SmallCardGraph from '@/components/dashboardComp/SmallCardSignature.vue'
import DoubleCardGraph from '@/components/dashboardComp/DoubleCardGraph.vue'
import DoubleCardPortailCandidats from '@/components/dashboardComp/DoubleCardPortailCandidats.vue'
import {
    SmallCardThreeInformationsInterface,
    SmallCardProgressBarInterface,
    SmallCardRegularInterface,
    SmallCardDuoCardInterface
} from '@/types/Dashboard'
import SmallCardTwoFractions from '@/components/dashboardComp/SmallCardTwoFractions.vue'

@Component({
    components: {
        SmallCardDuo,
        SmallCardProgressBar,
        SmallCardRegular,
        SmallCardThreeInformations,
        SmallCardGraph,
        DoubleCardGraph,
        SmallCardTwoFractions,
        DoubleCardPortailCandidats
    }
})
export default class BigCard extends Vue {
    @Prop() cardIcon!: string;
    @Prop() allCards!:
    | SmallCardThreeInformationsInterface
    | SmallCardProgressBarInterface
    | SmallCardRegularInterface
    | SmallCardDuoCardInterface;
}
