










































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { Ability } from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { PosteContext, PosteType } from '@/types/Poste'
import { GroupeIntervenantInterface } from '@/types/GroupeIntervenant'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { TypePassation } from '@/types/Epreuve'

@Component({
    components: {
        ErrorDisplay,
        ExaGenericTable
    },
    computed: {
        ...mapGetters('groupeIntervenant', ['current_page', 'last_page', 'total', 'error']),
        ...mapGetters('auth', ['can']),
        ...mapState('concour', ['concours'])
    }
})
export default class IntervenantsEcrit extends Vue {
    Ability = Ability
    initial_loading = true
    loading = false
    loading_modal = false
    filiere_select = null
    matiere_select = null
    epreuve_select = null
    epreuves: any = []
    matieres: any = []
    postes: any = []
    poste_select = null
    list_users: Array<GroupeIntervenantInterface> = []
    filtres: any = []
    dataForTab: Array<any> = []
    showSendMailCorrecteurs = false
    mailTo = ''
    mailToOverflow = false

    genericfields = [
        { key: 'name',     label: 'Identité', sortable: true,  class: '', type: 'text' },
        { key: 'email',    label: 'Courriel', sortable: true,  class: '', type: 'text' },
        { key: 'poste_id', label: 'Poste',    sortable: false, class: '', type: 'text' },
        { key: 'quotite',  label: 'Quotité',  sortable: false, class: '', type: 'text' }
    ]

    /**
     * refreshInterface
     * @description Recharge les données lors d'un changement de session ou de filtre
     * @return {Promise<void>}
     */
    @Watch('user_session_id')
    @Watch('filiere_select')
    @Watch('matiere_select')
    @Watch('epreuve_select')
    @Watch('poste_select')
    async refreshInterface(): Promise<void> {
        if (!this.initial_loading) {
            if (this.filiere_select || this.matiere_select) {
                this.epreuves = this.$store.getters['epreuve/epreuves'].filter(
                    (epreuve: any) => {
                        if (!epreuve.epreuve_correction_id) {
                            return false
                        }

                        if (this.filiere_select && this.matiere_select) {
                            return epreuve.concour_id === this.filiere_select && epreuve.matiere_id === this.matiere_select
                        } else if (this.filiere_select) {
                            return epreuve.concour_id === this.filiere_select
                        } else if (this.matiere_select) {
                            return epreuve.matiere_id === this.matiere_select
                        } else {
                            return true
                        }
                    }
                )
            } else {
                this.epreuves = this.$store.getters['epreuve/epreuves']
            }

            if (!this.epreuves.find((epreuve: any) => epreuve.id === this.epreuve_select)) {
                this.epreuve_select = null
            }

            await this.load()
        }
    }

    /**
     * openSendMailCorrecteurs
     * @description Ouvre la modale d'envoi d'email
     * @return {Promise<void>}
     */
    async openSendMailCorrecteurs(): Promise<void> {
        this.loading_modal = true
        const payload: any = {}
        if (this.poste_select) {
            payload['filter-poste_id'] = this.poste_select
        }
        if (this.filiere_select) {
            payload['filter-concour_id'] = this.filiere_select
        }
        if (this.matiere_select) {
            payload['filter-matiere_ecrit_id'] = this.matiere_select
        }
        if (this.epreuve_select) {
            payload['filter-epreuve_id'] = this.epreuve_select
        }

        const response = await this.$store.dispatch('groupeIntervenant/getGroupeIntervenants', {
            prevent_store: true,
            payload: { ...payload, perPage: 0 }
        })
        this.list_users = response.data.data

        this.mailTo = this.list_users.map((user: any) => user.email).join(';')
        this.mailToOverflow = this.mailTo.length > 1800

        await this.$nextTick()
        if (this.list_users.length > 0) {
            const button: any = document.getElementById('send-mail-correcteurs')
            if (button) {
                button.innerHTML = this.mailToOverflow ? 'Copier' : 'Envoyer'
            }
        }

        this.showSendMailCorrecteurs = true
        this.loading_modal = false
    }

    /**
     * closeSendMailCorrecteurs
     * @description Ferme la modal d'envoie d'email
     * @return {void}
     */
    closeSendMailCorrecteurs(): void {
        this.showSendMailCorrecteurs = false
        this.list_users = []
        this.mailTo = ''
        this.mailToOverflow = false
    }

    /**
     * sendMailCorrecteurs
     * @description Ouvre le client de messagerie ou copie les adresses emails
     * @return {void}
     */
    sendMailCorrecteurs(): void {
        if (this.mailToOverflow) {
            if (window.isSecureContext && navigator.clipboard) {
                navigator.clipboard.writeText(this.mailTo)

                const button: any = document.getElementById('send-mail-correcteurs')
                if (button) {
                    button.innerHTML = 'Copié !'
                    setTimeout(() => {
                        button.innerHTML = 'Copier'
                    }, 3000)
                }
            } else {
                console.error('Unable to copy to clipboard (unsecure context).')
            }
        } else {
            window.open(`mailto:${this.mailTo}`, '_self')
        }
    }

    /**
     * setDataForGenericTab
     * @description Ajoute les données dans le tableau
     * @param {any} poData - Données à ajouter
     * @param {boolean} loadMore - Ajout de données supplémentaires
     * @return {void}
     */
    setDataForGenericTab(poData: any, loadMore = false): void {
        if (!loadMore) {
            this.dataForTab = []
        }
        if (poData) {
            for (const result of poData) {
                const postes: string = [...new Set(
                    result.dossierAcademiques
                        .filter((dossier: any) => dossier.poste_affectations)
                        .map((dossier: any) => dossier.poste_affectations.map((poste: any) => poste.name))
                        .flat())
                ].join(', ')

                const quotites: any = result.dossierAcademiques
                    .filter((dossier: any) => dossier.poste_affectations)
                    .map((dossier: any) => dossier.poste_affectations.map((poste: any) => {
                        return {
                            poste: poste.name,
                            value: poste.temps / 100
                        }
                    }))
                    .flat()

                const line = [
                    { label: '', item: `${result.name} ${result.first_name}`, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.email, type: 'text', typeAction: null, class: '' },
                    { label: '', item: postes, type: 'text', typeAction: null, class: '' },
                    {
                        label: quotites.map((quotite: any) => `${quotite.poste}: ${quotite.value}`).join(', '),
                        item: quotites.map((quotite: any) => quotite.value).join(', '),
                        type: 'text',
                        typeAction: null,
                        class: ''
                    }
                ]
                this.dataForTab.push(line)
            }
        }
    }

    /**
     * handleTableEvent
     * @description Événéments du tableau
     * @param {any} paParams - Événements ([0] → action, [1] → id de l'item)
     * @return {void}
     */
    handleTableEvent(paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'sortHandler':
                case 'filterHandler':
                    this.load(paParams[1])
                    break
                case 'onLoadPage':
                    this.load(paParams[1], true)
                    break
            }
        }
    }

    /**
     * load
     * @description Charge les données
     * @param {any} params - Paramètres de la requête
     * @param {boolean} loadMore - Ajout de données supplémentaires
     * @return {Promise<void>}
     */
    async load(params: any = { page: 1, sort: 'name', direction: 'asc' }, loadMore = false): Promise<void> {
        if (this.loading) {
            return
        }
        this.loading = true

        if (this.poste_select) {
            params = {
                ...params,
                'filter-poste_id': this.poste_select
            }
        }
        if (this.filiere_select) {
            params = {
                ...params,
                'filter-concour_id': this.filiere_select
            }
        }
        if (this.matiere_select) {
            params = {
                ...params,
                'filter-matiere_ecrit_id': this.matiere_select
            }
        }
        if (this.epreuve_select) {
            params = {
                ...params,
                'filter-epreuve_id': this.epreuve_select
            }
        }

        let data: Array<GroupeIntervenantInterface>
        if (loadMore) {
            const response = await this.$store.dispatch('groupeIntervenant/getMoreGroupeIntervenants', {
                payload: params
            })
            data = response.data.data
        } else {
            await this.$store.dispatch('groupeIntervenant/getGroupeIntervenants', { payload: params })
            data = this.$store.getters['groupeIntervenant/intervenants']
        }
        this.setDataForGenericTab(data, loadMore)
        this.loading = false
        this.initial_loading = false
    }

    /**
     * mounted
     * @description Création du composant
     * @return {Promise<void>}
     */
    async mounted(): Promise<void> {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            let payload = {};
            if (!this.$store.getters['auth/can'](Ability.RH_SPS_MANAGE)) {
                payload = { 'filter-user_id': this.$store.getters['auth/authUser'].id }
            }

            const response_p = await this.$store.dispatch('poste/getSessionPostes', {
                ...payload,
                type: PosteType.TYPE_PEDAGOGIQUE,
                context:
                    PosteContext.CONTEXT_SOUHAIT + PosteContext.CONTEXT_SELECTION + PosteContext.CONTEXT_AFFECTATION
            })
            this.postes = response_p.data.data
            if (this.postes.length === 1) {
                this.poste_select = this.postes[0].id
            }

            await this.$store.dispatch('concour/getConcours', { params: { ...payload, 'filter-is_banque': 1 }})
            const concours = this.$store.state.concour.concours
            if (concours.length === 1) {
                this.filiere_select = concours[0].id
            }

            const response_m = await this.$store.dispatch('matiere/getSessionMatieres', payload)
            this.matieres = response_m.data.data
            if (this.matieres.length === 1) {
                this.matiere_select = this.matieres[0].id
            }

            await this.$store.dispatch('epreuve/getEpreuves', { params: payload })
            this.epreuves = this.$store.getters['epreuve/epreuves'].filter((epreuve: any) => epreuve.epreuve_correction_id && (epreuve.type_passation === TypePassation.TYPE_PASSATION_ECRIT || epreuve.type_passation === TypePassation.TYPE_PASSATION_ORAL))
            if (this.epreuves.length === 1) {
                this.epreuve_select = this.epreuves[0].id
            }

            this.filtres = [
                {
                    libelle: 'Identité',
                    defautOptionlibelle: 'Rechercher une',
                    model: 'name',
                    value: '',
                    index: 'name',
                    datas: '',
                    loading: this.$store.getters['groupeIntervenant/loading'],
                    options: { type: 'form', fieldsKey: 'name' }
                },
                {
                    libelle: 'Courriel',
                    defautOptionlibelle: 'Rechercher un',
                    model: 'email',
                    value: '',
                    index: 'email',
                    datas: '',
                    loading: this.$store.getters['groupeCorrecteur/loading'],
                    options: { type: 'form', fieldsKey: 'email' }
                }
            ]

            await this.load()
        }
    }
}
