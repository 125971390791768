












































import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { BModal } from 'bootstrap-vue';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { Ability } from '../../../types/Ability';
import { convertDateLocalValeurT, formatDayHourZDateFromString, getError } from '../../../utils/helpers';
import ErrorDisplay from '../../ErrorDisplay.vue';

@Component({
    name: 'PopupImportComponent',
    components: {
        BModal,
        FontAwesomeIcon,
        ErrorDisplay
    },
    computed: {
        ...mapGetters('auth', ['can'])
    }
})

export default class PopupPeriodeVisibilite extends Vue {
    Ability = Ability
    show = false
    processing = false
    error = null as null | Error
    start_at = null as null | string
    end_at = null as null | string

    open() {
        this.error = null
        this.processing = false
        this.start_at = null
        this.end_at = null

        const session = this.$store.state.session.sessions.find((s: any) => s.id === this.$store.getters['auth/user_session_id'])
        if (session.job_descriptions_start_at) {
            this.start_at = convertDateLocalValeurT(session.job_descriptions_start_at)
            this.end_at = convertDateLocalValeurT(session.job_descriptions_end_at)
        }
        this.show = true
    }

    send() {
        if (this.processing) {
            return
        }
        this.processing = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.$store.dispatch('session/updateSessionPlages', {
            job_descriptions: 1,
            job_descriptions_start_at: formatDayHourZDateFromString(this.start_at as string),
            job_descriptions_end_at: formatDayHourZDateFromString(this.end_at as string)
        })
            .then(async (response) => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                this.$store.commit('session/UPDATE_SESSION', response.data.data)
                this.close()
            })
            .catch((error) => {
                this.error = getError(error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.processing = false
            })
    }

    close() {
        this.show = false
    }
}
