



















































































































































































import { Vue, Component, Watch }           from 'vue-property-decorator'
import { mapGetters, mapState }               from 'vuex'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { EtablissementType }        from '@/types/Etablissement'
import { Ability }                  from '@/types/Ability'
import { codeRestrict } from '@/utils/helpers'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'

@Component({
    components: {
        ExaGenericTable,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('etablissement', ['loading', 'error', 'etablissements', 'meta', 'links', 'totalRows', 'lastPage']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('ville', ['villes'])
    }

})

export default class Etablissements extends Vue {
    codeRestrict = codeRestrict
    genericfields = [
        { key: 'etatEdit',      label: '',                  sortable: false,    class: '', type: 'action' },
        { key: 'code',          label: 'Code',              sortable: true,     class: '', type: 'text' },
        { key: 'name',          label: 'Libellé',           sortable: true,     class: '', type: 'text' },
        { key: 'academie',      label: 'Académie',          sortable: true,     class: '', type: 'text' },
        { key: 'adresse',       label: 'Adresse',           sortable: true,     class: '', type: 'text' },
        { key: 'ville',         label: 'Ville',             sortable: true,     class: '', type: 'text' },
        { key: 'type',          label: 'Type',              sortable: true,     class: '', type: 'text' },
        { key: 'ville_passation_id',  label: 'Ville de passation', sortable: true,     class: '', type: 'text' },
        { key: 'delete',        label: '',                  sortable: false,    class: '', type: 'action' }
    ]

    sortBy          = '';
    sortDesc        = false;
    sortDirection   = 'asc';
    filter          = '';
    filterOn        = [];
    stickyHeader    = true;

    showModalEditionEtablissement   = false;
    showModalMessageDelete          = false;
    showModalImportEtablissement    = false;

    etablissement:      any = null;
    etablissementTemp:  any = null;

    etablissementType   = EtablissementType;
    importEnCours       = false

    file:           any             = null;
    messagesErreur: Array<string>   = [];
    filtres: any = []
    dataForTab: Array<any> = []

    Ability = Ability

    totalEtablissementsFiltered = 0 // pour l'affichage du total réel contenu dans le tableau des affectations affiché (filtrées et on filtrées)

    // METHODS
    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    @Watch('meta')
    majInfosTable () {
        // On récupère le nombre total d'affectations filrées depuis les infos des Metadonnées
        if (this.$store.state.etablissement && this.$store.state.etablissement.meta && this.$store.state.etablissement.meta !== null && this.$store.state.etablissement.meta.total) {
            if (this.totalEtablissementsFiltered !== this.$store.state.etablissement.meta.total) {
                this.totalEtablissementsFiltered = this.$store.state.etablissement.meta.total
            }
        } else {
            this.totalEtablissementsFiltered = 0
        }
    }


    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setDataForGenericTab(poData: any) {
        this.dataForTab = []
        if (poData) {
            for (const result of poData) {
                let ville_proche = '-'
                if (result.ville_passation_id) {
                    ville_proche = this.$store.state.ville.villes.find((v: any) => v.id === result.ville_passation_id).name
                }

                let line = []
                line = [
                    { label: 'Editer',      item: result.id,              type: 'action',  typeAction: 'edit',    class: 'commons_first_action_button', icon: this.canISeeThat(Ability.ADM_PAR_MANAGE) ? 'pen' : 'eye', disabled: false },
                    { label: '',            item: result.code,            type: 'text',    typeAction: null,      class: '' },
                    { label: '',            item: result.name,            type: 'text',    typeAction: null,      class: '' },
                    { label: '',            item: result.academie,        type: 'text',    typeAction: null,      class: '' },
                    { label: '',            item: result.adresse,         type: 'text',    typeAction: null,      class: '' },
                    { label: '',            item: result.ville,           type: 'text',    typeAction: null,      class: '' },
                    { label: '',            item: result.type,            type: 'text',    typeAction: null,      class: '' },
                    { label: '',            item: ville_proche,           type: 'text',    typeAction: null,      class: '' }
                ]

                if (this.canISeeThat(Ability.ADM_PAR_MANAGE)) {
                    line.push({ label: 'Supprimer',   item: result.id,              type: 'action',  typeAction: 'delete',  class: 'commons_delete_action_button text-secondary', icon:'trash-alt', disabled: false })
                }

                this.dataForTab.push(line)
            }
        }
    }

    // Check une ability
    canISeeThat(ability: any) {
        return this.$store.getters['auth/can'](ability)
    }


    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setFiltersForGenericTab() {
        const options_villes_passation = []
        for (const v in this.$store.state.ville.villes) {
            options_villes_passation.push({ index: this.$store.state.ville.villes[v].id, name: this.$store.state.ville.villes[v].name })
        }

        this.filtres = [
            { libelle: 'Code',      defautOptionlibelle: 'Rechercher un',   model: 'code',      value: '', index: 'code',       datas: this.$store.state.etablissement.code,        loading: this.$store.state.etablissement.loading, options: { type: 'form', fieldsKey: 'code', strict: true } },
            { libelle: 'Name',      defautOptionlibelle: 'Rechercher un',   model: 'name',      value: '', index: 'name',       datas: this.$store.state.etablissement.name,        loading: this.$store.state.etablissement.loading, options: { type: 'form', fieldsKey: 'name' } },
            { libelle: 'Académie',  defautOptionlibelle: 'Rechercher une',  model: 'academie',  value: '', index: 'academie',   datas: this.$store.state.etablissement.academie,    loading: this.$store.state.etablissement.loading, options: { type: 'form', fieldsKey: 'academie' } },
            { libelle: 'Adresse',   defautOptionlibelle: 'Rechercher une',  model: 'adresse',   value: '', index: 'adresse',    datas: this.$store.state.etablissement.adresse,     loading: this.$store.state.etablissement.loading, options: { type: 'form', fieldsKey: 'adresse' } },
            { libelle: 'Ville',     defautOptionlibelle: 'Rechercher une',  model: 'ville',     value: '', index: 'ville',      datas: this.$store.state.etablissement.ville,       loading: this.$store.state.etablissement.loading, options: { type: 'form', fieldsKey: 'ville' } },
            { libelle: 'Type',      defautOptionlibelle: 'Rechercher un',   model: 'type',      value: '', index: 'type',       datas: this.$store.state.etablissement.type,        loading: this.$store.state.etablissement.loading, options: { type: 'form', fieldsKey: 'type' } },
            { libelle: 'Ville de passation', defautOptionlibelle: 'Rechercher par',   model: 'ville_passation_id',    value: '', index: 'ville_passation_id', datas: options_villes_passation, loading: this.$store.state.etablissement.loading, options: { type: 'deroulant', fieldsKey: 'ville_passation_id' } }
        ]
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            const etablissements        = this.$store.state.etablissement.etablissements
            let etablissement_select    = null

            switch (paParams[0]) {
                case 'edit':
                    // Récupération de l'étab by ID
                    etablissement_select = etablissements.filter((etab: any) => etab.id === paParams[1])[0]
                    if (etablissement_select) {
                        this.editEtablissement(etablissement_select)
                    }
                    break
                case 'delete':
                    etablissement_select = etablissements.filter((etab: any) => etab.id === paParams[1])[0]
                    if (etablissement_select) {
                        this.deleteEtablissement(etablissement_select)
                    }
                    break
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
            }
        }
    }


    /**
     * Ouverture de la modale d'import d'etab par fichier
     */
    openImportEtablissement () {
        this.showModalImportEtablissement = true
    }


    /**
     * changement de fichier ?
     */
    fileChange (event: any) {
        this.file = event.target.files[0]
    }


    /**
     * Permet d'envoyer un fichier au back pour ajouter des établissements ?
     */
    envoiFichier () {
        // Création du toaster en cours
        this.messagesErreur = []
        const idInfo        = 't_info_' + Math.random()
        const infosToaster  = {
            id:             idInfo,
            toaster:        'b-toaster-top-right',
            variant:        'primary',
            noCloseButton:  true,
            fade:           true,
            noAutoHide:     true
        }

        this.$bvToast.toast('Envoi en cours ...', infosToaster)
        this.importEnCours = true

        // Appel de la fonction pour importer les etabs
        this.$store.dispatch('etablissement/importEtablissement', this.file)
            .then(() => {
                this.importEnCours = false

                const idSucces      = 't_succes_' + Math.random()
                const succesToaster = {
                    id:             idSucces,
                    toaster:        'b-toaster-top-right',
                    variant:        'success',
                    noCloseButton:  true,
                    fade:           true,
                    autoHideDelay:  5000
                }
                this.$bvToast.toast('Etablissements importés avec succès !', succesToaster)
                this.$store.dispatch('etablissement/getEtablissements').then(() => {
                    this.showModalImportEtablissement = false
                    this.load()
                })
            })
            .catch((error) => {
                this.importEnCours = false
                console.log('ko:' + error)

                // Création du message d'erreurs
                if (error.response && error.response.data && error.response.data.errors) {
                    for (const err in error.response.data.errors) {
                        if (error.response.data.errors[err]) {
                            if (error.response.data.errors[err].row) {
                                const retourError = 'erreur ligne' + error.response.data.errors[err].row + ' : ' + error.response.data.errors[err].errors
                                this.messagesErreur.push(retourError)
                            }
                        }
                    }
                }
                // Toaster it's a fail !
                const idError = 't_error_' + Math.random()
                const errorToaster = {
                    id:             idError,
                    toaster:        'b-toaster-top-right',
                    variant:        'danger',
                    noCloseButton:  true,
                    fade:           true,
                    autoHideDelay:  5000
                }
                this.$bvToast.toast("Une erreur s'est produite lors de l'import", errorToaster)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * Fermeture de la modale d'import par fichier
     */
    closeModalImport () {
        this.messagesErreur                 = []
        this.showModalImportEtablissement   = false
    }

    /**
     * Ouveture de la modale d'ajout d'établissement
     * Plus affectation de la variable temporaire permettant l'ajout
     */
    openAddEtablissement () {
        this.etablissementTemp = {
            id:         0,
            name:       '',
            code:       '',
            academie:   '',
            adresse:    '',
            ville:      '',
            type:       '',
            ville_passation_id: null
        }
        this.showModalEditionEtablissement = true
    }

    /**
     * Ouverture de la modale d'edition
     */
    editEtablissement (row: any) {
        this.etablissement      = row
        this.etablissementTemp  = {
            id:         this.etablissement.id,
            name:       this.etablissement.name,
            code:       this.etablissement.code,
            academie:   this.etablissement.academie,
            adresse:    this.etablissement.adresse,
            ville:      this.etablissement.ville,
            type:       this.etablissement.type,
            ville_passation_id: this.etablissement.ville_passation_id
        }
        this.showModalEditionEtablissement = true
    }

    /**
     * Fermeture de la modale d'édition, plus mise à null de la variable d'édition
     */
    cancelEdit () {
        this.etablissement                  = null
        this.etablissementTemp              = null
        this.showModalEditionEtablissement  = false
    }

    /**
     * Validation d'une modale, envoie au back les datas pour intégration à la BDD
     */
    editSuite () {
        const payload = {
            id:         this.etablissementTemp.id,
            name:       this.etablissementTemp.name,
            code:       this.etablissementTemp.code,
            academie:   this.etablissementTemp.academie,
            adresse:    this.etablissementTemp.adresse,
            ville:      this.etablissementTemp.ville,
            type:       this.etablissementTemp.type,
            ville_passation_id: this.etablissementTemp.ville_passation_id,
            session_id: this.$store.getters['auth/user_session_id']
        }

        // création du toaster enreg en cours...
        const idInfo        = 't_info_' + Math.random()
        const infosToaster  = {
            id:             idInfo,
            toaster:        'b-toaster-top-right',
            variant:        'primary',
            noCloseButton:  true,
            fade:           true,
            noAutoHide:     true
        }

        // si l'étab est nouveau, on l'ajoute
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        if (this.etablissementTemp.id === 0) {
            this.$store.dispatch('etablissement/addEtablissement', payload)
                .then(() => {
                    this.etablissement                  = null
                    this.etablissementTemp              = null
                    this.showModalEditionEtablissement  = false
                    const idSucces      = 't_succes_' + Math.random()
                    const succesToaster =
                    {
                        id:             idSucces,
                        toaster:        'b-toaster-top-right',
                        variant:        'success',
                        noCloseButton:  true,
                        fade:           true,
                        autoHideDelay:  5000
                    }
                    this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                    this.showModalImportEtablissement = false
                    this.setDataForGenericTab(this.$store.state.etablissement.etablissements)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            // Sinon, on fait une édition
            this.$store.dispatch('etablissement/updateEtablissement', payload)
                .then(() => {
                    this.etablissement                  = null
                    this.etablissementTemp              = null
                    this.showModalEditionEtablissement  = false

                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster =
                    {
                        id:             idSucces,
                        toaster:        'b-toaster-top-right',
                        variant:        'success',
                        noCloseButton:  true,
                        fade:           true,
                        autoHideDelay:  5000
                    }
                    this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                    this.showModalImportEtablissement = false
                    this.setDataForGenericTab(this.$store.state.etablissement.etablissements)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    /**
     * Ouverture de la modale de suppression
     */
    deleteEtablissement (row: any) {
        this.etablissement          = row
        this.showModalMessageDelete = true
    }

    /**
     * Fermeture de la modale d'édition
     */
    cancelDelete () {
        this.etablissement          = null
        this.showModalMessageDelete = false
    }

    /**
     * Fonction de suppression
     */
    deleteSuite () {
        // Création du toaster suppression en cours
        const idInfo        = 't_info_' + Math.random()
        const infosToaster  = {
            id:             idInfo,
            toaster:        'b-toaster-top-right',
            variant:        'primary',
            noCloseButton:  true,
            fade:           true,
            noAutoHide:     true
        }

        this.$bvToast.toast('Supression en cours ...', infosToaster)

        // Appel de la fonction de suppression
        this.$store.dispatch('etablissement/deleteEtablissement', this.etablissement.id)
            .then(() => {
                this.etablissementTemp              = null
                this.showModalEditionEtablissement  = false

                const idSucces      = 't_succes_' + Math.random()
                const succesToaster = {
                    id:             idSucces,
                    toaster:        'b-toaster-top-right',
                    variant:        'success',
                    noCloseButton:  true,
                    fade:           true,
                    autoHideDelay:  5000
                }
                // Toaster delete ok
                this.$bvToast.toast('Suppression terminée.', succesToaster)
                const index = this.$store.state.etablissement.etablissements.findIndex((e: any) => e.id === this.etablissement.id)
                this.$store.state.etablissement.etablissements.splice(index, 1)
                this.etablissement = null
                this.setDataForGenericTab(this.$store.state.etablissement.etablissements)
                this.showModalMessageDelete = false
            })
            .catch((error) => {
                const idError       = 't_error_' + Math.random()
                const errorToaster  = {
                    id:             idError,
                    toaster:        'b-toaster-top-right',
                    variant:        'danger',
                    noCloseButton:  true,
                    fade:           true,
                    autoHideDelay:  5000
                }
                this.$bvToast.toast('Une erreur s\'est produite lors de la suppression du etablissement : ' + error, errorToaster)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     *  Appelle une page lors du scroll
     */
    loadHandler (params: any) {
        this.$store.dispatch('etablissement/getMoreEtablissements', params).then(() => {
            this.setDataForGenericTab(this.$store.state.etablissement.etablissements)
        })
    }

    /**
     * Appel des datas avec un sort en paramètres
     */
    filtreSortHandler (params: any) {
        this.$store.dispatch('etablissement/getEtablissements', params).then(() => {
            this.setDataForGenericTab(this.$store.state.etablissement.etablissements)
        })
    }

    load() {
        this.$store.dispatch('etablissement/getEtablissements').then(() => {
            // Chargement des villes
            this.$store.dispatch('ville/getVilles').then(() => {
                this.setDataForGenericTab(this.$store.state.etablissement.etablissements)
                this.setFiltersForGenericTab()
            })
        })
    }

    /**
     * Récupération des datas puis affectation des infos pour le tableau generic
     */
    mounted () {
        this.load()
    }
}
