






































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import EditorTinyMCE from '@/components/Tools/EditorTinyMCE.vue'
import { formatDate, isEmailValid } from '@/utils/helpers'
import { Ability } from '@/types/Ability'
import { MessageIndicationType } from '@/types/MessageIndicationType'
import MessageIndication from '@/components/MessageIndication.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    components: {
        EditorTinyMCE,
        MessageIndication,
        ErrorDisplay
    },
    computed: {
        ...mapGetters('auth', ['authUser']),
        ...mapGetters('centre', ['informations_is_submit', 'informations_is_validate']),
        ...mapState('centre', ['error', 'centreSelect']),
        ...mapGetters('auth', ['is_intervenant_centre']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})
export default class MessageCandidatsCentreBis extends Vue {
    @Prop() context?: number;

    Ability = Ability
    MessageIndicationType = MessageIndicationType
    lockInput = false
    message_rejet = ''
    messageErreurSimple = ''
    showModalConfirmationSoumettre = false
    message_candidatTampon = ''
    errorEmail = ''


    /**
     * updateCentreSelect
     * Cette fonction est un watcher qui se déclenche immédiatement lorsque la propriété 'centreSelect' est modifiée en profondeur.
     * Elle initialise l'interface et récupère les messages de rejet.
     */
    @Watch('centreSelect', { immediate: true, deep:true })
    updateCentreSelect () {
        this.initInterface()
        this.getMessageRejetMessage()
    }

    /**
     * formatteDate
     * Cette fonction formate une date donnée au format spécifié.
     *
     * @param date (Date) - La date à formater.
     * @returns La date formatée.
     */
    formatteDate (date: Date) {
        return formatDate(date)
    }

    /**
     * initInterface
     * Cette fonction initialise l'interface en effectuant différentes opérations.
     * Elle récupère les messages de rejet.
     * Si un centre est sélectionné dans le store, elle vérifie les différentes conditions pour verrouiller ou déverrouiller les entrées.
     *
     */
    initInterface () {
        this.getMessageRejetMessage()
        if (this.$store.state.centre.centreSelect) {
            // this.message_candidatTampon = this.$store.state.centre.centreSelect.message_candidat
            if (this.$store.state.centre.centreSelect.message_validated_at) {
                this.lockInput = true
            } else if (this.$store.state.centre.centreSelect.message_submitted_at) {
                this.lockInput = !!this.$store.getters['auth/is_intervenant_centre'];
            } else {
                this.lockInput = false
            }
        }
    }

    /**
     * setMessageCandidat
     * Cette fonction permet de définir le message du candidat en fonction de la valeur donnée.
     *
     * @param e (any) - L'événement contenant la valeur du message du candidat.
     */
    setMessageCandidat (e: any) {
        this.message_candidatTampon = e.target.value
    }

    /**
     * checkEmailValid
     * Cette fonction vérifie la validité de l'adresse email donnée et définit la propriété 'errorEmail' en conséquence.
     *
     * @param e (any) - L'événement contenant l'adresse email à vérifier.
     */
    checkEmailValid (e: any) {
        this.errorEmail = isEmailValid(e.target.value).errorEmail
    }

    /**
     * getMessageRejetMessage
     * Cette fonction récupère le message de rejet associé au centre sélectionné dans le store.
     * Si un message de rejet est trouvé, il est assigné à la propriété 'message_rejet'.
     * Si aucun message de rejet n'est trouvé, la propriété 'message_rejet' est vidée.
     *
     */
    getMessageRejetMessage () {
        if (this.$store.state.centre.centreSelect) {
            if (this.$store.state.centre.centreSelect.commentaire_rejet) {
                if (this.$store.state.centre.centreSelect.commentaire_rejet.message) {
                    this.message_rejet = this.$store.state.centre.centreSelect.commentaire_rejet.message
                    return
                }
            }
        }
        this.message_rejet = ''
    }

    /**
     * save
     * Cette fonction effectue l'enregistrement des modifications apportées au centre sélectionné.
     * Elle crée un payload contenant les données à mettre à jour.
     * Elle affiche un toast indiquant que l'enregistrement est en cours.
     * Elle appelle la méthode 'updateCentre' du store pour effectuer la mise à jour.
     * En cas de succès, elle cache le toast d'enregistrement en cours et affiche un toast de succès.
     * En cas d'erreur, elle affiche un message d'erreur dans la console.
     *
     */
    save () {
        const payload = {
            id: this.$store.state.centre.centreSelect.id,
            // message_candidat: this.message_candidatTampon,
            email_contact_candidat: this.$store.state.centre.centreSelect.email_contact_candidat,
            message_candidat: this.$store.state.centre.centreSelect.message_candidat
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('centre/updateCentre', payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                this.$store.state.centre.error = null
                this.errorEmail = ''
            })
            .catch((error) => {
                this.errorEmail = ''
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * saveMessageBodyContent
     * Cette fonction effectue l'enregistrement du contenu du message au candidats.
     * Elle crée un payload contenant les données à mettre à jour.
     * Elle affiche un toast indiquant que l'enregistrement est en cours.
     * Elle appelle la méthode 'updateCentre' du store pour effectuer la mise à jour.
     * En cas de succès, elle cache le toast d'enregistrement en cours et affiche un toast de succès.
     * Elle réinitialise les erreurs liées à l'adresse email et au centre.
     * En cas d'erreur, elle affiche un message d'erreur dans la console.
     *
     */
    saveMessageBodyContent () {
        const payload = {
            id: this.$store.state.centre.centreSelect.id,
            // message_candidat: this.message_candidatTampon,
            email_contact_candidat: this.$store.state.centre.centreSelect.email_contact_candidat,
            message_candidat: this.$store.state.centre.centreSelect.message_candidat
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.$store.dispatch('centre/updateCentre', payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                this.$store.state.centre.error = null
                this.errorEmail = ''
            })
            .catch((error) => {
                this.errorEmail = ''
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * openModalConfirmationSoumettre
     * Cette fonction ouvre la modal de confirmation pour soumettre les modifications.
     *
     */
    openModalConfirmationSoumettre () {
        this.showModalConfirmationSoumettre = true
    }

    /**
     * cancelSoumettre
     * Cette fonction annule la soumission des modifications en fermant la modal de confirmation.
     *
     */
    cancelSoumettre () {
        this.showModalConfirmationSoumettre = false
    }

    /**
     * confirmerSoumettre
     * Cette fonction confirme la soumission des modifications en appelant la fonction 'submitMessageBodyContent'.
     *
     */
    confirmerSoumettre () {
        this.submitMessageBodyContent()
    }

    /**
     * submitMessageBodyContent
     * Cette fonction effectue la soumission du contenu du message du candidat.
     * Elle affiche un toast indiquant que l'enregistrement est en cours.
     * Elle crée un payload contenant les données à mettre à jour, y compris le flag 'message_submit' à 1 pour indiquer la soumission.
     * Elle appelle la méthode 'updateCentre' du store pour effectuer la mise à jour.
     * En cas de succès, elle réinitialise les erreurs et verrouille les entrées.
     * Elle cache le toast d'enregistrement en cours et affiche un toast de succès.
     * En cas d'erreur, elle affiche un message d'erreur dans la console et ferme la modal de confirmation.
     *
     */
    submitMessageBodyContent () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        const payload = {
            id: this.$store.state.centre.centreSelect.id,
            // message_candidat: this.message_candidatTampon,
            email_contact_candidat: this.$store.state.centre.centreSelect.email_contact_candidat,
            message_candidat: this.$store.state.centre.centreSelect.message_candidat,
            message_submit: 1
        }

        this.$store.dispatch('centre/updateCentre', payload)
            .then(() => {
                this.messageErreurSimple = ''
                this.message_rejet = ''
                this.lockInput = true

                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                this.errorEmail = ''
                this.$store.state.centre.error = null
                this.initInterface()
                this.showModalConfirmationSoumettre = false
            })
            .catch((error) => {
                this.errorEmail = ''
                console.log('ko:' + error)
                this.showModalConfirmationSoumettre = false
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * beforeMount
     * Cette fonction est un hook du cycle de vie du composant qui est appelé avant que le composant soit monté.
     * Elle réinitialise la propriété d'erreur du store à null.
     * Elle initialise l'interface en appelant la fonction 'initInterface'.
     *
     */
    beforeMount () {
        this.$store.state.centre.error = null
        this.initInterface()
    }
}

