



























import { Vue, Component }   from 'vue-property-decorator'
import { FontAwesomeIcon }  from '@fortawesome/vue-fontawesome'
import DemissionsConcours from '@/components/Demissions/DemissionsConcours.vue'
import DemissionsEpreuveFacultative from '@/components/Demissions/DemissionsEpreuveFacultative.vue'


/**
 * Composant principal de la gestion des candidats
*/
@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        DemissionsConcours,
        DemissionsEpreuveFacultative
    }
})
export default class GestionDemissions extends Vue
{
    tabSelected = 'demission_epreuve_facultative'
}
