
















































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { Ability, getGroupes } from '@/types/Ability'
import { checkIcone } from '@/utils/helpers'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import Back from '@/components/Tools/Back.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import _ from 'lodash'

@Component({
    components: {
        ExaGenericTable,
        ErrorDisplay,
        Back,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('user', ['loading', 'error', 'users', 'meta', 'links', 'totalRows', 'currentPage', 'lastPage', 'totalPage', 'sortby']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapState('user', ['abilities', 'roles', 'dependencies', 'error'])
    },
    methods: {
        ...mapActions('user', ['getRoles'])
    }
})

export default class Roles extends Vue {
    Ability                  = Ability
    role_name                = ''
    showModalEditionRole     = false
    filtres: any             = []
    filtreJustInitiated      = false
    abilities_name: string[] = []
    role_id                  = 0
    abilitiesList: any       = []
    dataForTab: Array<any>   = []
    groupes: any             = []
    role_select: any         = null
    showModaleDeleteRole     = false

    // Ensemble des colonnes du tableau de epreuveCorrectionResultats
    genericfields = [
        { key: 'etatEdit', label: '',    sortable: false, class: '', type: 'action' },
        { key: 'name',     label: 'Nom', sortable: true,  class: '', type: 'text' },
        { key: 'delete',   label: '',    sortable: false }
    ]

    /**
     * @description Met à jour le tableau des données
     */
    @Watch('roles')
    setTable(): void {
        this.setDataForGenericTab(this.$store.state.user.roles)
    }

    /**
     * @description Remplissage du tableau des données
     * @param {any} poData - Données à afficher
     * @param {boolean} isLoadMore - Chargement de plus de données
     * @returns {void}
     */
    setDataForGenericTab(poData: any, isLoadMore = false): void {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const result of poData) {
                if (!result.abilities.find((a: any) => a.name.indexOf('gci') !== -1)) {
                    const can = this.$store.getters['auth/can'](Ability.ADM_UTIL_MANAGE)
                    const icone = checkIcone(Ability.ADM_UTIL_MANAGE, can)

                    const line = [
                        { label: icone.label, item: result, type: 'action', typeAction: 'edit', class: 'commons_first_action_button', icon: icone.icon, disabled: false },
                        { label: '', item: result.name,     type: 'text',   typeAction: null,   class: '' }
                    ]

                    if (can) {
                        line.push({ label: 'Supprimer', item: result, type: 'action', typeAction: 'delete', class: 'text-secondary commons_delete_action_button delete_button', icon:'trash-alt', disabled: !can })
                    } else {
                        line.push({ label: '', item: null, type: 'text', typeAction: null, class: '' })
                    }

                    this.dataForTab.push(line)
                }
            }
        }
    }

    /**
     * @description Initialisation des filtres du tableau
     * @returns {void}
     */
    setFiltersForGenericTab(): void {
        this.filtres = [
            { libelle: 'Nom',      defautOptionlibelle: 'Rechercher un', model: 'name',  value: '', index: 'name',  datas: null, loading: this.$store.state.user.loading, options: { type: 'form', fieldsKey: 'name' } },
            { libelle: 'Courriel', defautOptionlibelle: 'Rechercher un', model: 'email', value: '', index: 'email', datas: null, loading: this.$store.state.user.loading, options: { type: 'form', fieldsKey: 'email' } }
        ]
    }

    /**
     * @description Gestion des événements du tableau
     * @param {any} paParams - Paramètres de l'événement
     * @returns {Promise<void>}
     */
    async handleTableEvent(paParams: any): Promise<void> {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'edit':
                    await this.$store.dispatch('user/getAbilities')
                    // Chargement des dépendances des abilities
                    await this.$store.dispatch('user/getDependencies')
                    this.createGroupeAbilities(this.$store.state.user.abilities)
                    this.role_name = paParams[1].name
                    this.role_id   = paParams[1].id

                    for (const a in paParams[1].abilities) {
                        this.abilities_name.push(paParams[1].abilities[a].name)
                    }
                    this.showModalEditionRole = true
                    break

                case 'sortHandler':
                case 'filterHandler':
                    await this.filtreSortHandler(paParams[1])
                    break

                case 'delete':
                    this.delete_role(paParams[1])
                    break
            }
        }
    }

    /**
     * @description Met à jour les champs du formulaire
     * @param {any} e - Événement
     * @returns {void}
     */
    updateChamps(e: any): void {
        if (e.target.name === 'name') {
            this.role_name = e.target.value
        }
    }

    /**
     * @description Ouvre le popup de création d'un rôle
     * @returns {Promise<void>}
     */
    async openAddRole(): Promise<void> {
        await this.$store.dispatch('user/getAbilities')
        // Chargement des dépendances des abilities
        await this.$store.dispatch('user/getDependencies')
        this.createGroupeAbilities(this.$store.state.user.abilities)
        this.showModalEditionRole = true
    }

    /**
     * @description Crée la collection des groupes et des abilities
     * @param {any} abilities - Abilities
     * @returns {void}
     */
    createGroupeAbilities(abilities: any): void {
        this.abilitiesList = abilities
        this.groupes = getGroupes()

        for (const a in this.abilitiesList) {
            if (this.abilitiesList[a].entity_id === null) {
                let split_ability = this.abilitiesList[a].name.split('.')
                const split_name = split_ability[!split_ability[1] ? 0 : 1].split('-')

                if (split_ability[0].indexOf('-') !== -1) {
                    split_ability = split_ability[0].split('-')
                }

                let groupe_select = this.groupes.find((g: any) => g.prefixe === split_ability[0])

                if (split_ability[1].indexOf('own') !== -1 && split_ability[0] !== 'gci') {
                    // On sélectionne le groupe autre permissions
                    groupe_select = this.groupes.find((g: any) => g.prefixe === 'own')
                }

                if (groupe_select) {
                    if (!groupe_select.abilities[split_name[0]]) {
                        groupe_select.abilities[split_name[0]] = []
                    }
                    groupe_select.abilities[split_name[0]].push(this.abilitiesList[a])
                    groupe_select.abilities[split_name[0]] = _.orderBy(groupe_select.abilities[split_name[0]], 'name', 'desc')
                }
            }
        }
    }

    /**
     * @description Applique des filtres
     * @param {any} params - Paramètres
     * @returns {Promise<void>}
     */
    async filtreSortHandler(params: any): Promise<void> {
        if (this.filtreJustInitiated) {
            this.filtreJustInitiated = false
        } else {
            await this.$store.dispatch('user/getRoles', params)
        }
    }

    /**
     * @description Charge plus de données lors du scroll
     * @param {any} params - Paramètres
     * @returns {Promise<void>}
     */
    async loadHandler(params: any): Promise<void> {
        await this.$store.dispatch('user/getRoles', params)
    }

    /**
     * @description Annule l'édition d'un rôle
     * @returns {void}
     */
    cancelEdit(): void {
        this.role_name = ''
        this.role_id = 0
        this.showModalEditionRole = false
        this.abilities_name = []
        this.$store.state.user.error = null
    }

    /**
     * @description Enregistre un rôle
     * @returns {void}
     */
    saveRole(): void {
        const data: any = {
            name: this.role_name,
            abilities: this.abilities_name
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        const afterDispatch = () => {
            const idSucces = 't_succes_' + Math.random()
            const succesToaster = {
                id: idSucces,
                toaster: 'b-toaster-top-right',
                variant: 'success',
                noCloseButton: true,
                fade: true,
                autoHideDelay: 5000
            }
            this.$bvToast.toast('Enregistrement terminé !', succesToaster)
            this.cancelEdit()
        }

        if (this.role_id === 0) {
            // Création d'un role
            this.$store.dispatch('user/addRole', data)
                .then(afterDispatch)
                .finally(() => this.$bvToast.hide(idInfo))
        } else {
            // Modification de l'utilisateur
            this.$store.dispatch('user/updateRole', { role_id: this.role_id, payload: data })
                .then(afterDispatch)
                .finally(() => this.$bvToast.hide(idInfo))
        }
    }

    /**
     * @description Affiche la confirmation de suppression d'un rôle
     * @param {any} role - Rôle
     * @returns {void}
     */
    delete_role(role: any): void {
        this.role_select = role
        this.showModaleDeleteRole = true
    }

    /**
     * @description Annule la suppression d'un rôle
     * @returns {void}
     */
    cancel_delete_role(): void {
        this.role_select = null
        this.showModaleDeleteRole = false
    }

    /**
     * @description Confirme la suppression d'un rôle
     * @returns {void}
     */
    confirm_delete_role(): void {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Suppression en cours ...', infosToaster)

        // Suppression d'un rôle
        this.$store.dispatch('user/deleteRole', { role_id: this.role_select.id })
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Suppression terminée !', succesToaster)
                const index = this.dataForTab.findIndex((r: any) => r.id === this.role_select.id)
                this.dataForTab.splice(index, 1)
                this.role_select = null
                this.showModaleDeleteRole = false
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Vérifie les abilities de l'utilisateur
     * @param {any} e - Événement
     * @returns {void}
     */
    checkAbilities(e: any): void {
        const ability_name = e.target.value
        const isChecked = e.target.checked

        if (isChecked) {
            this.abilities_name.push(ability_name)
        } else {
            const index = this.abilities_name.findIndex((e: any) => e === ability_name)
            this.abilities_name.splice(index, 1)
        }
    }

    /**
     * @description Coche toutes les abilities d'un groupe
     * @param {any} groupe - Groupe
     * @returns {void}
     */
    checkAll(groupe: any): void {
        for (const a in groupe.abilities) {
            for (const ab in groupe.abilities[a]) {
                this.abilities_name.push(groupe.abilities[a][ab].name)
            }
        }
    }

    /**
     * @description Décoche toutes les abilities d'un groupe
     * @param {any} groupe - Groupe
     * @returns {void}
     */
    uncheckAll(groupe: any): void {
        // Isole les abilities n'étant pas du groupe sélectionné
        this.abilities_name = this.abilities_name
            .filter((e: any) => e.indexOf(groupe.prefixe, 0) === -1 || e.indexOf(groupe.prefixe, 0) !== 0)
    }

    /**
     * @description Vérifie si une ability est désactivée
     * @param {any} ability - Ability
     * @returns {any}
     */
    isDisabled(ability: any): any {
        if (this.$store.getters['auth/can'](Ability.ADM_UTIL_MANAGE)) {
            // Cherche si l'ability à des dépendences
            const dep = this.$store.state.user.dependencies[ability.name]
            if (dep && dep.length !== 0) {
                // Check si la dépendence est déjà cochée
                if (this.abilities_name.find((a) => a === dep[0])) {
                    return { disabled: false, title: '' }
                } else {
                    // Dépendence non cochée -> coche disabled
                    // Si l'ability était cochée, on la décoche
                    const index = this.abilities_name.findIndex((e: any) => e === ability.name)
                    if (index !== -1) {
                        this.abilities_name.splice(index, 1)
                    }
                    const title_dep = this.$store.state.user.abilities.find((e: any) => e.name === dep[0])
                    let prefixe_dep = ''
                    if (title_dep.name.indexOf('view') !== -1) {
                        prefixe_dep = 'Consultation'
                    } else if (title_dep.name.indexOf('manage') !== -1) {
                        prefixe_dep = 'Gestion'
                    } else if (title_dep.name.indexOf('-sign') !== -1 && title_dep.name.indexOf('signature') === -1) {
                        prefixe_dep = 'Signature'
                    }
                    return { disabled: true, title: 'Nécessite la permission : ' + prefixe_dep + ' - ' + title_dep.title }
                }
            } else {
                return { disabled: false, title: '' }
            }
        } else {
            return { disabled: true, title: '' }
        }
    }

    /**
     * @description Montage du composant
     * @returns {Promise<void>}
     */
    async mounted(): Promise<void> {
        this.$store.state.user.error = null
        if (this.$store.state.user.roles.length === 0) {
            await this.$store.dispatch('user/getRoles')
        } else {
            this.setDataForGenericTab(this.$store.state.user.roles)
        }
    }
}
