
























import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import DescriptifCentre from '@/components/Centre/DescriptifCentre.vue'
import { Ability } from '@/types/Ability'
import { formatDate } from '@/utils/helpers'
import ListeContacts from '@/components/Centre/ListeContacts.vue'


@Component({
    components: {
        DescriptifCentre,
        ListeContacts
    },
    computed: {
        ...mapState('centre', ['centreSelect', 'error', 'loading']),
        ...mapGetters('centre', ['informations_is_submit', 'informations_is_validate', 'etat_centre_select', 'centreSelect']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id'])
    }
})
export default class DescriptionCentre extends Vue {
    Ability = Ability
    showPopupSubmitInformations = false

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    /** Formattage les dates */
    formatteDate (date: Date) {
        return formatDate(date)
    }

    load () {
        this.$store.dispatch('concour/getConcours', { isPrecedente: false }).then(() => {
            this.$store.dispatch('centre/getCentre', { centre_id: this.$route.params.centre_id }).then(() => {
                if (this.$store.getters['auth/can'](Ability.GCI_SAISIE_INTERV_OWN_VIEW, 'App\\Models\\Centre', this.$route.params.centre_id)) {
                    this.$store.dispatch('centre/getCentreUsers', { centre_id: this.$route.params.centre_id  }).catch((error) => {
                        console.log('ko:' + error)
                    })
                }
            })
        })
    }

    beforeMount () {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
