export enum Version {
    DISPO_NUMERIQUE,
    DISPO_PAPIER,
}

export function getVersion() {
    const enumVersion = []
    enumVersion.push({ id: 0, name: Version.DISPO_NUMERIQUE, libelle: 'Version numérique' })
    enumVersion.push({ id: 1, name: Version.DISPO_PAPIER, libelle: 'Version papier' })

    return enumVersion
}

export function getLibelleVersion(version: Version) {
    switch (version) {
        case Version.DISPO_NUMERIQUE:
            return 'Version numérique'
            break
        case Version.DISPO_PAPIER:
            return 'Version papier'
            break
        default:
            break
    }
}

export interface FournitureAdministrativeInterface {
    id: number | 0,
    session_id: number | 0,
    code: string,
    name: string,
    ordre: number | 0,
    actif: number | 0,
    dispo_numerique: number | 0,
    dispo_papier: number | 0,
    custom: number | 0,
    bac_impression: number | 0,
    document: any | null
}

export interface StateFournitureAdministratives {
    fournituresAdministratives: Array<FournitureAdministrativeInterface> | [],
    meta: Array<string> | null,
    links: Array<string> | null,
    loading: boolean | false,
    error: Array<string> | null,
    totalRows: number | 0,
    fournituresAdministrativeSelect: FournitureAdministrativeInterface | {}
}
