

























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'
import { TypePassation } from '@/types/Epreuve'
import { getTypeMessageRejetLibelle, TypeMessageRejet } from '@/types/Centre'

@Component({
    components: {
    },
    computed: {
        ...mapGetters('user', ['centreId']),
        ...mapState('user', ['centreId']),
        ...mapState('epreuve', ['epreuvesCorrections']),
        ...mapGetters('centre', ['centreSelect', 'centre_users_select', 'loading', 'orga_is_validate', 'is_nb_intervenants_validated_at']),
        ...mapState('centre', ['users_count', 'error'])
    },
    methods: {
        ...mapActions('dossieracademique', ['updateDossierAcademique'])
    }
})
export default class ModuleGestionCentre extends Vue {
    @Prop() centre_id?: number;
    typePassation = TypePassation

    liste_messagesRejet: Array<any> = []
    TYPE_MSG_REJET_SUBMIT = TypeMessageRejet.TYPE_MSG_REJET_SUBMIT
    TYPE_MSG_REJET_ANNEXE = TypeMessageRejet.TYPE_MSG_REJET_ANNEXE
    TYPE_MSG_REJET_INFORMATIONS = TypeMessageRejet.TYPE_MSG_REJET_INFORMATIONS
    TYPE_MSG_REJET_ORGA_ECRIT = TypeMessageRejet.TYPE_MSG_REJET_ORGA_ECRIT
    hasError = false
    nb_is_validated = false

    beforeMount () {
        if (this.$props.centre_id) {
            this.$store.dispatch('centre/getCentre', { centre_id: this.$props.centre_id }).then(() => {
                this.$store.dispatch('centre/getUsersCount', { centre_id: this.$store.state.centre.centreSelect.id })
                this.getListeRejetsFromCentre()
            })
        }
    }

    @Watch('centre_select')
    init () {
        this.getListeRejetsFromCentre()
    }

    @Watch('is_nb_intervenants_validated_at', { immediate: true })
    orga() {
        this.nb_is_validated = this.$store.getters['centre/is_nb_intervenants_validated_at']
    }

    // Retourne la liste des rejets de soumissions éventuelles
    getListeRejetsFromCentre () {
        if (this.$store.state.centre.centreSelect.commentaire_rejet) {
            const parseddatas = JSON.parse(this.$store.state.centre.centreSelect.commentaire_rejet)
            const keys = Object.keys(parseddatas)
            keys.forEach(key => {
                this.liste_messagesRejet.push(getTypeMessageRejetLibelle(key))
            })
        }
        return this.liste_messagesRejet
    }

    getColorLink (typeMessage: TypeMessageRejet) {
        let color = 'primary'
        if (this.$store.state.centre.centreSelect.commentaire_rejet) {
            for (const m in this.$store.state.centre.centreSelect.commentaire_rejet) {
                if (m === typeMessage) {
                    color = 'danger'
                    this.hasError = true
                }
            }
        }

        return color
    }
}

