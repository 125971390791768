

















































































import { Vue, Component, Watch }    from 'vue-property-decorator'
import { mapGetters, mapState }     from 'vuex'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'
import { Ability }                  from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { base64ToArrayBuffer, formatDate, formatDateSinTime, getFileNameFromHeader } from '@/utils/helpers'
import { MessageIndicationType } from '@/types/MessageIndicationType'
import MessageIndication from '@/components/MessageIndication.vue'

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('matiere', ['getMatieresObs']),
        ...mapState('presenceExaminateur', ['listeExaminateurs', 'isLoading', 'totalRows', 'lastPage', 'totalPage', 'loading_pres', 'meta'])
    },
    components: {
        ExaGenericTable,
        'font-awesome-icon': FontAwesomeIcon,
        MessageIndication
    }
})

export default class PresenceExaminateurs extends Vue
{
    formatDate        = formatDate
    formatDateSinTime = formatDateSinTime
    tableLoading                                = false

    options_centres: Array<any> = []
    options_series: Array<any> = []
    options_matieres: Array<any> = []
    centre_select_id: any = null
    serie_select_id = null
    matiere_select_id = null
    showDismissibleAlert = true

    Ability = Ability
    MessageIndicationType = MessageIndicationType

    genericfields = [
        { key: 'serie_id',      label: 'Série',            sortable: true,   class: 'text-center', type: 'text' },
        { key: 'date',          label: 'Date',             sortable: true,   class: 'text-start', type: 'text' },
        { key: 'centre_id',     label: 'Centre d\'oral',   sortable: true,   class: 'text-start', type: 'text' },
        { key: 'matiere_id',    label: 'Matière',          sortable: true,   class: 'text-start', type: 'text' },
        { key: 'name',          label: 'Examinateur',      sortable: true,   class: 'text-start', type: 'text' },
        { key: 'ensemble_name', label: 'Equipe',           sortable: true,   class: 'text-start', type: 'text' },
        { key: 'nb_matin',      label: 'Nombre de séances - Matin', sortable: true,  class: 'text-center', type: 'text' },
        { key: 'nb_aprem',      label: 'Nombre de séances - Aprés Midi', sortable: true,  class: 'text-center', type: 'text' }
    ]

    sortDirection   = 'asc'

    filtres:    any         = []
    dataForTab: Array<any>  = []
    allEpreuves             = []
    params: any = {}
    exportWorking = false
    params_search: any = {}
    is_search = false

    @Watch('listeExaminateurs')
    getExaminateurs(): void {
        this.setDataForGenericTab(this.$store.state.presenceExaminateur.listeExaminateurs)
    }

    @Watch('user_session_id')
    async refreshInterface(): Promise<void> {
        await this.load()
    }

    // Création des lignes du tableau
    setDataForGenericTab(poData: any, isLoadMore = false): void {
        if (!isLoadMore) {
            this.dataForTab = []
        }
        if (poData) {
            for (const result of poData) {
                const centre = this.$store.state.centre.centres.find((c: any) => c.id === result.centre_id)
                const equipe = result.concour_name ? result.ensemble_name + ' (' + result.concour_name + ')' : result.ensemble_name

                const line: any = [
                    { label: '', item: result.serie.name, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: formatDateSinTime(result.jour), type: 'text', typeAction: null, class: 'text-start' },
                    { label: '', item: centre ? centre.name : '-',  type: 'text', typeAction: null, class: 'text-start' },
                    { label: '', item: result.epreuveCorrection ? result.epreuveCorrection.matiere.name : '-',   type: 'text', typeAction: null, class: 'text-start' },
                    { label: '', item: result.user_name,  type: 'text', typeAction: null, class: 'text-start' },
                    { label: '', item: equipe,  type: 'text', typeAction: null, class: 'text-start' },
                    { label: '', item: result.nb_matin !== '0' ? result.nb_matin : '-',   type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: result.nb_aprem !== '0' ? result.nb_aprem : '-',   type: 'text', typeAction: null, class: 'text-center' }
                ]

                this.dataForTab.push(line)
            }
        }
    }

    // Création des filtres pour le tableau
    setFiltersForGenericTab(): void {
        this.filtres = [
            { libelle: 'Nom',       defautOptionlibelle: 'Rechercher un',   model: 'name',                value: '', index: 'name',           datas: null,               loading: this.$store.state.presenceExaminateur.loading_pres, options: { type: 'form',      fieldsKey: 'name' } }
        ]
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent(paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'sortHandler':
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                default:
                    break
            }
        }
    }

    // Applique le chargement de la pagination
    loadHandler(params: any): void {
        if (params.page <= this.$store.state.presenceExaminateur.lastPage) {
            Vue.set(params, 'centre_id', this.centre_select_id)
            Vue.set(params, 'matiere_id', this.matiere_select_id)
            Vue.set(params, 'serie_id', this.serie_select_id)
            Vue.set(params, 'excel', 0)
            this.$store.dispatch('presenceExaminateur/getMoreExaminateursPres', params)
        }
    }

    // Applique les filtres
    filtreSortHandler(params: any): void {
        Vue.set(params, 'centre_id', this.centre_select_id)
        Vue.set(params, 'matiere_id', this.matiere_select_id)
        Vue.set(params, 'serie_id', this.serie_select_id)
        Vue.set(params, 'excel', 0)
        if (JSON.stringify(this.params) !== JSON.stringify(params) && this.is_search) {
            this.params = params
            this.$store.dispatch('presenceExaminateur/getExaminateursPres', params)
        }
    }

    // Export de la liste des candidats au format excel
    dl_export_examinateurs(): void {
        this.$store.commit('presenceExaminateur/SET_ERROR', null)
        let fileName = ''
        this.exportWorking = true

        Vue.set(this.params_search, 'excel', 1)

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours...', infosToaster)

        this.$store.dispatch('presenceExaminateur/getExaminateursPresExcel', { params : this.params_search })
            .then((response) => {
                const fileNameTemp = getFileNameFromHeader(response.headers)
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Export de la liste des candidats au format pdf
    dl_pdf_examinateurs(): void {
        this.$store.commit('presenceExaminateur/SET_ERROR', null)
        let fileName = ''
        this.exportWorking = true

        Vue.set(this.params_search, 'pdf', 1)
        Vue.set(this.params_search, 'format', 'b64')

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours...', infosToaster)

        this.$store.dispatch('presenceExaminateur/getExaminateursPresPDF', { params : this.params_search })
            .then((response) => {
                const fileNameTemp = getFileNameFromHeader(response.headers) || 'Presence_examinateurs.pdf'
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([base64ToArrayBuffer(response.data)]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.exportWorking = false
            })
    }

    /**
     * Lance la requete de sélection
     */
    rechercher(): void {
        this.$store.state.presenceExaminateur.error = null
        this.$store.commit('presenceExaminateur/SET_META', null)
        this.$store.commit('presenceExaminateur/SET_ERROR', null)
        Vue.set(this.params_search, 'centre_id', this.centre_select_id)
        Vue.set(this.params_search, 'matiere_id', this.matiere_select_id)
        Vue.set(this.params_search, 'serie_id', this.serie_select_id)
        Vue.set(this.params_search, 'page', 1)
        Vue.set(this.params_search, 'pdf', 0)
        Vue.set(this.params_search, 'excel', 0)

        this.$store.dispatch('presenceExaminateur/getExaminateursPres', this.params_search).then(() => {
            this.is_search = true
            this.setDataForGenericTab(this.$store.state.presenceExaminateur.listeExaminateurs)
        })
    }

    async load(): Promise<void> {
        this.params = {
            page: 1,
            sort: 'name',
            direction: 'asc',
            centre_id: this.$route.params.centre_id
        }
        // Load des séries
        await this.$store.dispatch('serie/getSeries')
        this.options_series = this.$store.state.serie.series

        // Load des matières
        await this.$store.dispatch('matiere/getMatieres')
        this.options_matieres = this.$store.getters['matiere/getMatieresObs'](
            this.$store.state.auth.user.abilities.filter((a: any) => a.name === Ability.INTERV_OBSERV_MATIERE_OWN && a.scope === this.$store.getters['auth/user_session_id'])
        )

        // Load des centres
        await this.$store.dispatch('centre/getCentres')
        this.options_centres = this.$store.state.centre.centres
        this.setFiltersForGenericTab()
        this.rechercher()
    }

    async mounted(): Promise<void> {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            await this.load()
        }
    }
}
