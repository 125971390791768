













































































































































































































































































































































































































import { mapGetters } from 'vuex'
import { Vue, Component, Watch } from 'vue-property-decorator'
import { format_phone_number, base64ToArrayBuffer, getFileNameFromHeader, formatDate } from '@/utils/helpers'
import { Ability } from '@/types/Ability'
import _, { isEmpty } from 'lodash'
import {
    Etat,
    EtatReclamationViatique,
    getEtatSpecReclamation,
    TypeReclamation,
    TypeReponseReclamation
} from '@/types/Reclamation'
import  ErrorDisplay from '@/components/ErrorDisplay.vue'
import Back from '@/components/Tools/Back.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { CandidatConcourPhase, CandidatStatut } from '@/types/Candidat'
import PopupEditCandidat from '@/components/Candidat/PopupEditCandidat.vue'
import VuePdfApp from 'vue-pdf-app'
import { ReclamationTypeInterface } from '@/types/ReclamationType'
import { NumCorrection } from '@/types/CandidatEpreuve'
import { reclamationCorrectionAffectationMode } from '@/types/Parameter'

@Component({
    components: {
        ErrorDisplay,
        PopupEditCandidat,
        Back,
        VuePdfApp,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        Etat() {
            return Etat
        },
        Ability() {
            return Ability
        },
        CandidatConcourPhase() {
            return CandidatConcourPhase
        },
        TypeReponseReclamation() {
            return TypeReponseReclamation
        },
        CandidatStatut() {
            return CandidatStatut
        },
        reclamationMessageType(): string {
            return this.$store.getters['auth/findParameter']('reclamationMessage')?.value
        },
        totalReclamations(): number {
            return this.$store.getters['reclamation/meta']?.total || 0
        },
        correcteurs(): string {
            return this.$data.reclamation?.candidatEpreuve?.batch_correction?.corrector_group?.correctors
                .map((correcteur: any) => `${correcteur.first_name} ${correcteur.name}`)
                .join(', ') || '-'
        },
        centre(): string {
            return this.$data.reclamation?.candidat?.centre?.name || '-'
        },
        filiere(): string {
            return this.$data.reclamation?.concour?.name || ( this.$data.reclamation?.epreuve?.concour_id && this.$store.getters['concour/concourById'](this.$data.reclamation.epreuve.concour_id) ? this.$store.getters['concour/concourById'](this.$data.reclamation.epreuve.concour_id).name : '-')
        },
        demandeAmenagement(): boolean {
            return !!this.$data.reclamation?.candidat?.demande_amenagement
        },
        canEdit(): boolean {
            return !this.$data.processing &&
                this.$store.getters['auth/can'](Ability.ECR_RECLAM_MANAGE) &&
                !this.$data.reclamation?.validated_at &&
                !this.$data.reclamation?.canceled_at
        },
        corrections(): any {
            return this.$data.reclamation?.corrections?.filter((correction: any) => correction.num_correction === NumCorrection.NUM_CORRECTION_CONTROLE)
        },
        correcteursRecorrection(): string {
            if (_.isArray(this.$data.recorrecteur)) {
                return this.$data.recorrecteur
                    .map((correcteur: any) => `${correcteur.first_name} ${correcteur.name.toUpperCase()}`)
                    .join(', ')
            }
            return '-'
        },
        decisionRecorrection(): { color: string; text: string } {
            switch (this.$data.recorrection?.etat_reclamation) {
                case EtatReclamationViatique.DECLAREE:
                    return { color: 'text-info', text: 'Déclarée' }
                case EtatReclamationViatique.CONFIRMEE:
                    return { color: 'text-success', text: 'Confirmée' }
                case EtatReclamationViatique.INFIRMEE:
                    return { color: 'text-danger', text: 'Infirmée' }
                default:
                    return { color: 'text-secondary', text: 'Non traitée' }
            }
        },
        ...mapGetters('reclamationPostConcours', ['reclamations_post_concours', 'reclamationSelect', 'error', 'PCSelectReclassementOpen']),
        ...mapGetters('reclamationType', ['reclamationTypes']),
        ...mapGetters('affectationCorrecteur', ['error'])
    },
    methods: {
        formatDate,
        format_phone_number
    }
})

export default class ReclamationPostGestion extends Vue {
    confirmDecisionModal = false
    unconfirmDecisionModal = false
    recorrecteurModal = false
    recorrecteurs: Array<any> = []
    recorrecteur: any = null
    recorrection: any = null
    recorrecteurTemp: any = null
    recorrecteursLoading = false
    reclamationTypeModal = false
    editCandidatModal = false
    cancelReclamationModal = false
    reclamationModal = false
    reclamation: any = {}
    etatReclamation: any = {}
    reclamationType: ReclamationTypeInterface | null = null
    loading = false
    loadingSimulation = false
    disableSimulation = false
    processing = false
    note: any = null
    messageGestionnaire = null
    configVisionneuse = {
        toolbar: {
            toolbarViewerRight: {
                presentationMode: false,
                openFile: false,
                viewBookmark: false,
                secondaryToolbarToggle: false
            }
        }
    }

    pdfReclamationCandidat: any = {
        name: null,
        content: null,
        id: null
    }

    pdfReclamationType: any = {
        name: null,
        content: null,
        id: null
    }

    /**
     * @description Charge la liste des recorrecteurs
     * @param {boolean} value - Affiche ou non la modale de recorrecteur
     * @param {boolean} oldValue - Ancienne valeur de la modale de recorrecteur
     * @param {boolean} forceFetch - Force le chargement des recorrecteurs
     * @returns {Promise<void>}
     */
    @Watch('recorrecteurModal')
    async onRecorrecteurModalChange(value: boolean, oldValue: boolean, forceFetch = false): Promise<void> {
        if (forceFetch || value && this.recorrecteurs.length === 0 && this.recorrecteurModal && this.reclamation?.epreuve?.epreuve_correction_id && !this.recorrecteursLoading) {
            this.recorrecteursLoading = true
            this.$store.commit('affectationCorrecteur/SET_ERROR', null)
            const affectationMode = this.$store.getters['auth/findParameter']('reclamationCorrectionAffectationMode')?.value

            try {
                const response = await this.$store.dispatch('affectationCorrecteur/getReclamationCorrecteurs', {
                    "filter-epreuve_correction_id": this.reclamation.epreuve.epreuve_correction_id
                })

                switch (affectationMode) {
                    case reclamationCorrectionAffectationMode.HORS_CORRECTEUR_INITIAL:
                        this.reclamation?.candidatEpreuve?.batch_correction?.corrector_group?.correctors?.forEach((correcteur: any) => {
                            response.data = response.data?.filter((recorrecteur: any) => recorrecteur.id !== correcteur.id)
                        })
                        break
                    case reclamationCorrectionAffectationMode.CORRECTEUR_INITIAL:
                        this.recorrecteurTemp = this.recorrecteur || this.reclamation?.candidatEpreuve?.batch_correction?.corrector_group?.correctors
                        break
                }

                response.data?.forEach((correcteur: any) => {
                    correcteur.isCorrecteurResponsable = false

                    correcteur.dossier_academiques?.some((dossier: any): boolean => {
                        // (#4610) À changer pour quelque chose de plus générique pour détecter les chefs de groupe
                        const isChefDeGroupe: boolean = [ ...new Set(dossier.poste_affectations?.map((poste: any): string => poste.name)) ]
                            .some((name: any): boolean => name?.toLowerCase().includes('chef de groupe') && name?.toLowerCase().includes('écrit'))
                        const isCoordonnateur: boolean = [ ...new Set(dossier.poste_affectations?.map((poste: any): string => JSON.parse(poste?.pivot?.options || '[]')).flat(Infinity)) ]
                            .some((option: any): boolean => option?.toLowerCase() === 'coordonnateur')

                        if (isChefDeGroupe || isCoordonnateur) {
                            correcteur.isCorrecteurResponsable = true

                            if (affectationMode === reclamationCorrectionAffectationMode.CORRECTEUR_RESPONSABLE && !this.recorrecteurTemp) {
                                this.recorrecteurTemp = [correcteur]
                            }
                            return true
                        }
                        return false
                    })
                })
                this.recorrecteurs = _.orderBy(response.data, ['isCorrecteurResponsable', 'candidat_epreuve_lot_recorrection_count', 'name'], ['desc'])
            } finally {
                this.recorrecteursLoading = false
            }
        }
    }

    /**
     * @description Réinitialise la visionneuse de la réclamation lors du changement de modèle
     * @returns {void}
     */
    @Watch('reclamationType')
    onReclamationTypeChange(): void {
        this.pdfReclamationType = {
            name: null,
            content: null,
            id: null
        }
    }

    /**
     * @description Liste les correcteurs d'une correction
     * @param {any} correction - Correction
     * @returns {string}
     */
    getCorrecteurs(correction: any): string {
        return correction?.batch_correction?.corrector_group?.correctors
            .map((correcteur: any) => `${correcteur.first_name} ${correcteur.name}`)
            .join(', ') || '-'
    }

    /**
     * @description Renvoie le titre associé à un type de correction
     * @param {any} correction - Correction
     * @returns {string}
     */
    getCorrectionTitle(correction: any): string {
        switch (correction?.num_correction) {
            case NumCorrection.NUM_CORRECTION_STD2:
                return 'Seconde correction'
            case NumCorrection.NUM_CORRECTION_HARMO:
                return 'Correction d\'harmonisation'
            case NumCorrection.NUM_CORRECTION_MODERATION:
                return 'Modération'
            case NumCorrection.NUM_CORRECTION_CONTROLE:
                return 'Correction de contrôle'
            default:
                return 'Correction'
        }
    }

    /**
     * @description Récupère le statut d'admission avant simulation
     * @param {number} concourId - Identifiant du concours
     * @returns {number}
     */
    getBeforeSimulationAdmissionStatus(concourId: number): number {
        return this.reclamation.candidat.resultats
            .find((resultat: any) => resultat.concour_id === concourId && resultat.phase_id === this.reclamation.epreuve.phase_id).recu
    }

    /**
     * @description Ouverture de la modale de confirmation de la décision
     * @returns {void}
     */
    openConfirmDecisionModal(): void {
        this.confirmDecisionModal = true
    }

    /**
     * @description Fermeture de la modale de confirmation de la décision
     * @returns {void}
     */
    closeConfirmDecisionModal(): void {
        this.confirmDecisionModal = false
    }

    /**
     * @description Ouverture de la modale d'invalidation de la décision
     * @returns {void}
     */
    openUnconfirmDecisionModal(): void {
        this.unconfirmDecisionModal = true
    }

    /**
     * @description Fermeture de la modale d'invalidation de la décision
     * @returns {void}
     */
    closeUnconfirmDecisionModal(): void {
        this.unconfirmDecisionModal = false
    }

    /**
     * @description Ouverture de la modale d'annulation de la réclamation
     * @returns {void}
     */
    openCancelReclamationModal(): void {
        this.cancelReclamationModal = true
    }

    /**
     * @description Fermeture de la modale d'annulation de la réclamation
     * @returns {void}
     */
    closeCancelReclamationModal(): void {
        this.cancelReclamationModal = false
    }

    /**
     * @description Ouverture de la modale d'aperçu de la réclamation
     * @returns {Promise<void>}
     */
    async openReclamationModal(): Promise<void> {
        if (this.reclamation.media.length) {
            if (!this.pdfReclamationCandidat.name || !this.pdfReclamationCandidat.content || this.pdfReclamationCandidat.id !== this.reclamation.id) {
                const response = await this.$store.dispatch('reclamation/getPDFReclamationCandidat', {
                    reclamation_id: this.reclamation.id,
                    uuid: this.reclamation.media[0].uuid
                })
                this.pdfReclamationCandidat = {
                    name: getFileNameFromHeader(response.headers),
                    content: base64ToArrayBuffer(response.data),
                    id: this.reclamation.id
                }
            }
            this.reclamationModal = true
        }
    }

    /**
     * @description Fermeture de la modale d'aperçu de la réclamation
     * @returns {void}
     */
    closeReclamationModal(): void {
        this.reclamationModal = false
    }

    /**
     * @description Ouverture de l'onglet vers la correction de la copie du candidat
     * @param {any} correction - Correction
     * @returns {Promise<void>}
     */
    showCopie(correction: any): void {
        if (correction?.cle_epreuve_externe) {
            window.open(this.$store.getters['candidat/getCorrectionOnViatique'](correction.cle_epreuve_externe), '_blank')
        }
    }

    /**
     * @description Récupère l'index de la réclamation actuelle
     * @returns {number}
     */
    getIndexCurrentReclamation(): number {
        if (this.$store.getters['reclamation/reclamations_ecrit']) {
            return this.$store.getters['reclamation/reclamations_ecrit']
                .findIndex((reclamation: any) => reclamation.id === Number(this.$route.params.reclamation_id))
        }
        return 0
    }

    /**
     * @description Navigation vers la réclamation précédente
     * @returns {void}
     */
    async previousReclamation(): Promise<void> {
        const previousReclamation = this.$store.getters['reclamation/reclamations_ecrit'][this.getIndexCurrentReclamation() - 1] || null
        if (previousReclamation) {
            await this.$router.push('/reclamations_ecrit/' + previousReclamation.id)
        }
    }

    /**
     * @description Navigation vers la réclamation suivante
     * @returns {Promise<void>}
     */
    async nextReclamation(): Promise<void> {
        // Charger plus de réclamations si on approche de la limite par page
        const meta = this.$store.getters['reclamation/meta']
        const modulo = (this.getIndexCurrentReclamation() + 1) % meta.per_page
        let nextReclamation = this.$store.getters['reclamation/reclamations_ecrit'][this.getIndexCurrentReclamation() + 1] || null

        if (nextReclamation) {
            await this.$router.push('/reclamations_ecrit/' + nextReclamation.id)
        } else if (modulo === 0 && meta.current_page < meta.last_page) {
            const params = JSON.parse(localStorage.getItem('reclamationEcritParams') || JSON.stringify({}))
            params.page = meta.current_page + 1

            await this.$store.dispatch('reclamation/getMoreReclamations', { type: 'ECRIT', filters: params })

            localStorage.setItem('reclamationEcritParams', JSON.stringify(params))
            meta.current_page = params.page
            this.$store.commit('reclamation/SET_META', meta)

            nextReclamation = this.$store.getters['reclamation/reclamations_ecrit'][this.getIndexCurrentReclamation() + 1] || null
            if (nextReclamation) {
                await this.$router.push('/reclamations_ecrit/' + nextReclamation.id)
            }
        }
    }

    /**
     * @description Ouverture de la modale d'aperçu du modèle de réponse
     * @returns {Promise<void>}
     */
    async openReclamationTypeModal(): Promise<void> {
        if (this.reclamationType) {
            if (!this.pdfReclamationType.name || !this.pdfReclamationType.content || this.pdfReclamationType.id !== this.reclamationType.id) {
                const response = await this.$store.dispatch('reclamation/getPDFReponseReclamation', {
                    reclamation_id: this.reclamation.id,
                    params: {
                        reclamation_type: this.reclamationType.id
                    }
                })
                this.pdfReclamationType = {
                    name: getFileNameFromHeader(response.headers),
                    content: base64ToArrayBuffer(response.data),
                    id: this.reclamationType.id
                }
            }
            this.reclamationTypeModal = true
        }
    }

    /**
     * @description Fermeture de la modale d'aperçu du modèle de réponse
     * @returns {void}
     */
    closeReclamationTypeModal(): void {
        this.reclamationTypeModal = false
    }

    /**
     * @description Ouverture de la modale de sélection d'un recorrecteur
     * @returns {void}
     */
    openRecorrecteurModal(): void {
        this.recorrecteurTemp = this.recorrecteur || null
        this.recorrecteurModal = true
    }

    /**
     * @description Fermeture de la modale de sélection d'un recorrecteur
     * @returns {void}
     */
    closeRecorrecteurModal(): void {
        this.recorrecteurModal = false
        this.recorrecteurs = []
        this.recorrecteursLoading = false
    }

    /**
     * @description Sélectionne un recorrecteur
     * @returns {void}
     */
    selectRecorrecteur(): void {
        this.recorrecteur = this.recorrecteurTemp
        this.closeRecorrecteurModal()
    }

    /**
     * @description Charge les données nécessaires et ouvre les résultats du candidat
     * @returns {Promise<void>}
     */
    async openEditCandidatModal(): Promise<void> {
        const candidat = this.reclamation.candidat
        if (candidat?.id !== undefined) {
            // On charge à partir de la BDD l'ensemble des informations du candidat sélectionné
            this.$store.commit('reclamation/SET_LOADING', true)

            if (isEmpty(this.$store.getters['concour/concours'])) {
                await this.$store.dispatch('concour/getConcours', {
                    perPage: 0
                })
            }

            this.$store.dispatch('candidat/getCandidat', candidat)
                .then(() => {
                    const params = {
                        candidatId: candidat.id,
                        nomFiliere: candidat.filiere
                    }
                    this.$store.commit('candidat/SET_CANDIDAT_FILIERE', params)
                    this.$store.commit('candidat/SET_SELECTED_CANDIDAT', candidat.id)
                    this.editCandidatModal = true
                })
                .finally(() => {
                    this.$store.commit('reclamation/SET_LOADING', false)
                })
        }
    }

    /**
     * @description Envoyer la réclamation en recorrection
     * @returns {void}
     */
    sendRecorrection(): void {
        if (!this.recorrecteur || this.processing) {
            return
        }
        this.processing = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Envoi en cours...', infosToaster)

        this.$store.dispatch('reclamationPostConcours/sendRecorrection', {
            reclamation_id: this.reclamation.id,
            payload: {
                user_id: this.recorrecteur[0].id
            }
        })
            .then(async () => {
                await this.load()

                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé', succesToaster)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.processing = false
            })
    }

    /**
     * @description Mise à jour de la réclamation
     * @param {Etat} action - Action de mise à jour
     * @returns {void}
     */
    updateReclamation(action: Etat = Etat.ETAT_TRAITE): void {
        if (this.processing || this.reclamation.validated_at) {
            return
        }
        this.processing = true

        const params: any = {
            reclamation_id: this.reclamation.id,
            type: TypeReclamation.TYPE_NOTE_ECRIT,
            payload: {
                reclamation_type_id: this.reclamationType?.id,
                decision: 0,
                submit: action,
                ...(() => {
                    if ([Etat.ETAT_ANNULEE, Etat.ETAT_INVALIDEE].includes(action)) {
                        return
                    }
                    switch (this.reclamationType?.type_reponse) {
                        case TypeReponseReclamation.TYPE_REPONSE_ACCEPTEE:
                            return {
                                decision: 1,
                                note: this.note
                            }
                        case TypeReponseReclamation.TYPE_REPONSE_REJETEE:
                            return {
                                submit: Etat.ETAT_REJETEE
                            }
                    }
                })(),
                message_gestionnaire: this.messageGestionnaire
            }
        }

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours...', infosToaster)

        this.$store.dispatch('reclamationPostConcours/updateReclamation', params)
            .then(async (response) => {
                this.reclamation = Object.assign(this.reclamation, response.data.data)
                this.reclamation.message_gestionnaire = this.messageGestionnaire
                this.reclamation.note = this.note
                this.updateEtatReclamation()

                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé', succesToaster)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.processing = false

                switch (action) {
                    case Etat.ETAT_ANNULEE:
                        this.closeCancelReclamationModal()
                        break
                    case Etat.ETAT_INVALIDEE:
                        this.closeUnconfirmDecisionModal()
                        break
                    case Etat.ETAT_TRAITE:
                        this.closeConfirmDecisionModal()
                        break
                }
            })
    }

    /**
     * @description Lance une simulation du statut du candidat en fonction de la note renseignée
     * @returns {void}
     */
    simulateNote(): void {
        if (this.loadingSimulation || this.disableSimulation || isNaN(parseInt(this.note))) {
            return
        }
        this.loadingSimulation = true
        this.disableSimulation = true

        this.$store.dispatch('reclamation/getSimulationReclamation', {
            reclamation_id: this.reclamation.id,
            note: this.note
        })
            .then((response) => {
                this.reclamation.simulation = response.data.data.simulation
            })
            .catch(() => {
                this.disableSimulation = false
            })
            .finally(() => {
                this.loadingSimulation = false
            })
    }

    @Watch('note')
    onNoteChange(): void {
        this.disableSimulation = false
    }

    /**
     * @description Chargement des données
     * @returns {Promise<void>}
     */
    @Watch('$route.params.reclamation_id')
    async load(): Promise<void> {
        this.loading = true

        this.recorrecteurTemp = null
        this.pdfReclamationType = {
            name: null,
            content: null,
            id: null
        }
        this.pdfReclamationCandidat = this.pdfReclamationType

        await this.$store.dispatch('reclamationPostConcours/getReclamation', Number(this.$route.params.reclamation_id))
        this.reclamation = _.cloneDeep(this.$store.getters['reclamationPostConcours/reclamationSelect'])
        this.reclamation.decision = this.reclamation.decision === 1 || this.reclamation.decision === true;
        this.recorrection = this.reclamation?.corrections?.find((correction: any) => correction.num_correction === NumCorrection.NUM_CORRECTION_RECLAMATION)
        this.recorrecteur = this.recorrection?.batch_correction?.corrector_group?.correctors

        if (this.$store.getters['reclamationType/reclamationTypes'].length === 1) {
            this.reclamationType = this.$store.getters['reclamationType/reclamationTypes'][0]
        } else {
            this.reclamationType = this.$store.getters['reclamationType/getReclamationTypeById'](this.reclamation?.reclamationType?.id) || null
        }

        const reclamationsList = this.$store.getters['reclamation/reclamations_ecrit']
        if (reclamationsList.length) {
            const index: number = reclamationsList.findIndex((a: any): boolean => a.id === this.reclamation.id)
            if (index === -1) {
                reclamationsList.push(this.reclamation)
            } else {
                reclamationsList[index] = this.reclamation
            }
            this.$store.commit('reclamation/SET_RECLAMATIONS_ECRIT', reclamationsList)
        }

        this.note = this.reclamation.note || this.recorrection?.note_finale || this.reclamation.candidatEpreuve?.note_finale || null
        this.messageGestionnaire = this.reclamation.message_gestionnaire || this.recorrection?.message_candidat || null

        this.updateEtatReclamation()

        if (this.reclamation && this.$store.getters['reclamation/reclamations_ecrit'].length === 0) {
            const params = {
                ...JSON.parse(localStorage.getItem('reclamationEcritParams') || JSON.stringify({})),
                page: 1,
                'filter-type': TypeReclamation.TYPE_NOTE_ECRIT
            }
            await this.$store.dispatch('reclamation/getReclamations', { type: 'ECRIT', filters: params })

            while (this.getIndexCurrentReclamation() === -1) {
                params.page++
                const response = await this.$store.dispatch('reclamation/getMoreReclamations', { type: 'ECRIT', filters: params })
                if (response.data.data.length === 0) {
                    break
                }

                const meta = this.$store.getters['reclamation/meta']
                meta.current_page = params.page
                this.$store.commit('reclamation/SET_META', meta)
            }
        }

        this.loading = false
    }

    /**
     * @description Met à jour l'état de la réclamation
     * @returns {void}
     */
    updateEtatReclamation(): void {
        if (this.reclamation.canceled_at) {
            this.etatReclamation = getEtatSpecReclamation(Etat.ETAT_ANNULEE)
        } else if (this.reclamation.rejected_at) {
            this.etatReclamation = getEtatSpecReclamation(Etat.ETAT_REJETEE)
        } else if (this.reclamation.submitted_at) {
            this.etatReclamation = getEtatSpecReclamation(Etat.ETAT_TRAITE)
        } else if (this.reclamation.recorrection_updated_at) {
            this.etatReclamation = getEtatSpecReclamation(Etat.ETAT_EN_COURS, 'À traiter')
        } else if (this.reclamation.recorrection_asked_at) {
            this.etatReclamation = getEtatSpecReclamation(Etat.ETAT_NON_TRAITE, 'En cours de correction')
        } else {
            this.etatReclamation = getEtatSpecReclamation(Etat.ETAT_NON_TRAITE)
        }
    }

    /**
     * @description Montage du composant
     * @returns {Promise<void>}
     */
    async mounted(): Promise<void> {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.loading = true

            if (this.$store.getters['ensemble/ensembles'].length === 0) {
                await this.$store.dispatch('ensemble/getEnsembles')
            }

            if (this.$store.getters['reclamationType/reclamationTypes'].length === 0) {
                await this.$store.dispatch('reclamationType/getReclamationTypes')
            }

            await this.$store.dispatch('reclamationType/getReclamationTypes', { 'filter-type_reclamation': TypeReclamation.TYPE_NOTE_ECRIT })

            if (this.$store.getters['concour/concours'].length === 0) {
                await this.$store.dispatch('concour/getConcours')
            }

            await this.load()
        }
    }
}
