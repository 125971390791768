





















































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import AdresseCentre from '@/components/Centre/AdresseCentre.vue'
import { TypeAdresseCentre, getTypeAdresseCentreSpec } from '@/types/AdresseCentre'
import { Ability } from '@/types/Ability'
import { formatDate, getWeekDayWithDayMonthYearShort } from '@/utils/helpers'
import { MessageIndicationType } from '@/types/MessageIndicationType'
import MessageIndication from '@/components/MessageIndication.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


@Component({
    components: {
        AdresseCentre,
        MessageIndication,
        FontAwesomeIcon
    },
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapGetters('centre', ['informations_is_submit', 'informations_is_validate']),
        ...mapState('centre', ['centreSelect']),
        ...mapGetters('auth', ['is_intervenant_centre'])
    }
})
export default class AdressesCentreBis extends Vue {
    adresseCentreType = TypeAdresseCentre
    Ability = Ability
    MessageIndicationType = MessageIndicationType
    getTypeAdresseCentreSpec = getTypeAdresseCentreSpec

    mode_edit = false
    adresseTemp: any = null
    adressesTemp: Array<any> = []

    errorType = false
    errorAdresse = false
    showError = false

    lock = false

    showModalMessageDelete = false

    deleteEnCours = false

    modifEnCours =false

    getStringIndispo(indispo: any) {
        let retour = ''
        const length = indispo.length
        if (length > 0) {
            const firstIndispo = indispo[0]
            const firstIndispoText = getWeekDayWithDayMonthYearShort(firstIndispo.jour) + ' ' + (Number(firstIndispo.matin_aprem) === 2 ? ' journée' : (Number(firstIndispo.matin_aprem) === 0 ? ' Matin' : ' Après-midi'))
            if (length > 2) {
                retour += '<span style="cursor:pointer; font-size: 12px;" title="'
            }
            for (let i = 0; i < length; i++) {
                if (length > 2) {
                    retour += '\n'
                } else {
                    retour += '<br />'
                }
                retour += getWeekDayWithDayMonthYearShort(indispo[i].jour) + ' ' + (Number(indispo[i].matin_aprem) === 2 ? ' journée' : (Number(indispo[i].matin_aprem) === 0 ? ' Matin' : ' Après-midi'))
            }
            if (length > 2) {
                retour += '">' + 'INDISPONIBILITÉS DE RÉCEPTION DES SUJETS : <br />' + firstIndispoText + ' ...</span>'
            }
        }
        return retour
    }

    /**
     * editAdresse
     * Cette fonction permet de modifier une adresse existante ou d'ajouter une nouvelle adresse.
     * Si une adresse est fournie, elle effectue une copie profonde de l'adresse pour la stocker dans 'adresseTemp'.
     * Si aucune adresse n'est fournie, elle initialise 'adresseTemp' avec des valeurs par défaut.
     * Elle active le mode d'édition et émet un événement pour informer les composants parents du changement de mode.
     *
     * @param adresse (any) - L'adresse à modifier ou à ajouter.
     */
    editAdresse (adresse: any) {
        if(adresse) {
            this.adresseTemp = JSON.parse(JSON.stringify(adresse))

        } else {
            this.adresseTemp = {
                id: 0,
                type: [0],
                adresse: ''
            }
        }
        this.mode_edit = true
        this.$emit('modeEditAdresseHandler', true)

    }

    /**
     * annulerEdition
     * Cette fonction permet d'annuler l'édition de l'adresse en cours.
     * Elle réinitialise 'adresseTemp' à null.
     * Elle désactive le mode d'édition et émet un événement pour informer les composants parents du changement de mode.
     * Elle affiche également un message de confirmation dans la console.
     *
     */
    annulerEdition  () {
        this.adresseTemp = null
        this.mode_edit = false
        this.$emit('modeEditAdresseHandler', false)
    }


    /**
     * deleteAdresse
     * Cette fonction permet de supprimer une adresse.
     * Elle stocke l'adresse à supprimer dans l'attribut 'adresseTemp'.
     * Elle affiche une modal de confirmation pour confirmer la suppression.
     * @param adresse - L'adresse à supprimer.
     */
    deleteAdresse(adresse: any) {
        if (adresse) {
            this.adresseTemp = JSON.parse(JSON.stringify(adresse))
        }
        this.showModalMessageDelete = true
    }

    /**
     * cancelDelete
     * Cette fonction annule la suppression de l'adresse en fermant la modal de confirmation.
     *
     */
    cancelDelete () {
        this.showModalMessageDelete = false
    }

    /**
     * deleteSuite
     * Cette fonction effectue la suppression définitive d'une adresse.
     * Elle active le flag 'deleteEnCours' pour indiquer que la suppression est en cours.
     * Elle crée un toast d'information pour indiquer que la suppression est en cours.
     * Elle crée un payload contenant les données nécessaires pour la suppression.
     * Elle appelle la méthode 'deleteAdresseCentre' du store pour effectuer la suppression.
     * En cas de succès, elle met à jour les adresses du centre en récupérant les données actualisées depuis le serveur.
     * Elle cache le toast d'information, ferme la modal de confirmation et affiche un toast de succès.
     * En cas d'erreur, elle affiche un message d'erreur dans la console.
     *
     */
    deleteSuite() {
        this.deleteEnCours = true
        const idInfo = this.generateUniqueId()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        const payload = {
            id: this.adresseTemp.id,
            type: this.adresseTemp.type,
            adresse: this.adresseTemp.adresse,
            centre_id: this.$store.state.centre.centreSelect.id
        }
        this.$bvToast.toast('Supression en cours ...', infosToaster)
        this.$store.dispatch('adresseCentre/deleteAdresseCentre', payload)
            .then(() => {
                const idSucces = this.generateUniqueId()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }

                this.$store.dispatch('centre/getCentre', { centre_id: this.$store.state.centre.centreSelect.id })
                    .then((retour) => {
                        const centreSelect = this.$store.state.centre.centreSelect

                        centreSelect.adressesTemp = [...retour.data.data.adresses]
                        this.showModalMessageDelete = false
                        this.deleteEnCours = false
                        this.adresseTemp = null
                        this.$bvToast.toast('Suppression terminée.', succesToaster)
                    })
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
    * editSuite
    * Cette fonction permet de valider les informations saisies par l'utilisateur pour enregistrer ou modifier une adresse.
    * Elle vérifie si l'utilisateur a entré une adresse et si le type d'adresse est valide.
    * Si l'entrée est valide, la fonction crée un payload contenant les informations sur l'adresse et envoie une action au magasin pour enregistrer les modifications.
    *
    */
    editSuite () {
        this.errorAdresse = false
        this.errorType = false
        this.modifEnCours = true

        const adresses = this.$store.state.centre.centreSelect.adresses

        if ((adresses && adresses.length === 0) || !adresses) {
            this.adresseTemp.type = 1
        }

        if (this.adresseTemp.type === 0 && adresses && adresses.length > 0) {
            this.errorType = true
        }

        if (this.adresseTemp.adresse === '') {
            this.errorAdresse = true
        }

        if (this.errorType || this.errorAdresse) {
            return
        }

        this.editFin()
    }

    // Utiliser une fonction pour générer des identifiants uniques
    generateUniqueId() {
        return 't_info_' + Date.now();
    }

    /**
     * editFin
     * Cette fonction gère l'enregistrement ou la mise à jour des informations d'une adresse.
     * Elle utilise les données de l'objet adresseTemp et les envoie au store pour effectuer les opérations nécessaires.
     * Elle affiche également des toasts pour informer l'utilisateur sur l'état de l'enregistrement.
     *
     */
    async editFin() {
        const payload = {
            id: this.adresseTemp.id,
            type: this.adresseTemp.type,
            adresse: this.adresseTemp.adresse,
            centre_id: this.$store.state.centre.centreSelect.id
        }
        const idInfo = this.generateUniqueId()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        try {
            this.$bvToast.toast('Enregistrement en cours ...', infosToaster);
            if (this.adresseTemp.id === 0) {
                await this.$store.dispatch('adresseCentre/addAdresseCentre', payload);
            } else {
                await this.$store.dispatch('adresseCentre/updateAdresseCentre', payload);
            }

            const idSucces = this.generateUniqueId()
            const succesToaster = {
                id: idSucces,
                toaster: 'b-toaster-top-right',
                variant: 'success',
                noCloseButton: true,
                fade: true,
                autoHideDelay: 5000
            }

            const retour = await this.$store.dispatch('centre/getCentre', { centre_id: this.$store.state.centre.centreSelect.id })
            this.$store.state.centre.centreSelect.adressesTemp = retour.data.data.adresses.slice()
            this.$bvToast.toast('Modification terminée.', succesToaster)
            this.modifEnCours = false
            this.adresseTemp.adresse = ''
            this.adresseTemp.type = 0
            this.annulerEdition()
        } catch (error) {
            console.log('ko:' + error)
        } finally {
            this.$bvToast.hide(idInfo)
        }
    }


    /**
     * getTypeString
     * Cette fonction permet de récupérer le libellé associé à un type d'adresse.
     * Elle vérifie si le type est principal, puis ajoute le libellé correspondant.
     * Elle vérifie ensuite si le type est de livraison sujet, de convocation candidat, de livraison document ou d'enlevement de copie.
     * @param type - Le type d'adresse à récupérer.
     * @returns La chaîne de caractères contenant le libellé associé au type d'adresse.
     */
    getTypeString (type: number) {
        let retour = '' // Initialisation de la chaîne de caractères
        if (type & this.adresseCentreType.TYPE_PRINCIPALE) { // Vérification si le type est principal
            if (retour.length > 0) {
                retour += '<br>' // Ajout d'un saut de ligne si nécessaire
            }
            retour += this.getTypeAdresseCentreSpec(this.adresseCentreType.TYPE_PRINCIPALE).libelle // Ajout du libellé du type principal
        }
        if (type & this.adresseCentreType.TYPE_LIVRAISON_SUJET) { // Vérification si le type est de livraison sujet
            retour += '<span class="me-3" title="' + this.getTypeAdresseCentreSpec(this.adresseCentreType.TYPE_LIVRAISON_SUJET).libelle + '">LS</span>' // Ajout du libellé du type de livraison sujet et du symbole
        }
        if (type & this.adresseCentreType.TYPE_CONVOCATION_CANDIDAT) { // Vérification si le type est de convocation candidat
            retour += '<span class="me-3" title="' + this.getTypeAdresseCentreSpec(this.adresseCentreType.TYPE_CONVOCATION_CANDIDAT).libelle + '">CC</span>' // Ajout du libellé du type de convocation candidat et du symbole
        }
        if (type & this.adresseCentreType.TYPE_LIVRAISION_DOCUMENT) { // Vérification si le type est de livraison document
            retour += '<span class="me-3" title="' + this.getTypeAdresseCentreSpec(this.adresseCentreType.TYPE_LIVRAISION_DOCUMENT).libelle + '">LD</span>' // Ajout du libellé du type de livraison document et du symbole
        }
        if (type & this.adresseCentreType.TYPE_ENLEVEMENT_COPIE) { // Vérification si le type est d'enlevement de copie
            retour += '<span class="me-3" title="' + this.getTypeAdresseCentreSpec(this.adresseCentreType.TYPE_ENLEVEMENT_COPIE).libelle + '">EC</span>' // Ajout du libellé du type d'enlevement de copie et du symbole
        }
        return retour // Retour de la chaîne de caractères
    }

    /**
     * getTypeStringFull
     * Cette fonction permet de récupérer le libellé associé à un type d'adresse.
     * Elle vérifie si le type est principal, puis ajoute le libellé correspondant.
     * Elle vérifie ensuite si le type est de livraison sujet, de convocation candidat, de livraison document ou d'enlevement de copie.
     * @param type - Le type d'adresse à récupérer.
     * @returns La chaîne de caractères contenant le libellé associé au type d'adresse.
     */
    getTypeStringFull (type: number) {
        let retour = ''
        if (type & this.adresseCentreType.TYPE_PRINCIPALE) {
            if (retour.length > 0) {
                retour += '<br>'
            }
            retour += this.getTypeAdresseCentreSpec(this.adresseCentreType.TYPE_PRINCIPALE).libelle
        }
        if (type & this.adresseCentreType.TYPE_LIVRAISON_SUJET) {
            if (retour.length > 0) {
                retour += '<br>'
            }
            retour += this.getTypeAdresseCentreSpec(this.adresseCentreType.TYPE_LIVRAISON_SUJET).libelle
        }
        if (type & this.adresseCentreType.TYPE_CONVOCATION_CANDIDAT) {
            if (retour.length > 0) {
                retour += '<br>'
            }
            retour += this.getTypeAdresseCentreSpec(this.adresseCentreType.TYPE_CONVOCATION_CANDIDAT).libelle
        }
        if (type & this.adresseCentreType.TYPE_LIVRAISION_DOCUMENT) {
            if (retour.length > 0) {
                retour += '<br>'
            }
            retour += this.getTypeAdresseCentreSpec(this.adresseCentreType.TYPE_LIVRAISION_DOCUMENT).libelle
        }
        if (type & this.adresseCentreType.TYPE_ENLEVEMENT_COPIE) {
            if (retour.length > 0) {
                retour += '<br>'
            }
            retour += this.getTypeAdresseCentreSpec(this.adresseCentreType.TYPE_ENLEVEMENT_COPIE).libelle
        }
        return retour
    }

    /**
    * checkLockInterface
    * Cette fonction vérifie si l'utilisateur est autorisé à modifier les informations du centre en fonction du statut du centre et des informations saisies.
    * Si le statut du centre est soumis et que l'utilisateur n'est pas un intervenant du centre ou que les informations n'ont pas été validées, la fonction empêche l'utilisateur de modifier les informations.
    */
    @Watch('centreSelect', { immediate: true, deep:true })
    checkLockInterface () {
        if (this.$store.state.centre.centreSelect) {
            if (this.$store.getters['centre/informations_is_submit']) {
                this.lock = !!(this.$store.getters['auth/is_intervenant_centre'] || this.$store.getters['centre/informations_is_validate'])
            } else {
                this.lock = false
            }
        }
    }


    /**
     * checkTypeChangeHandler
     * Cette fonction met à jour la valeur du type d'adresse lorsque l'utilisateur change les types sélectionnés.
     * Elle vérifie si le type est principal, puis ajoute le libellé correspondant.
     * Elle vérifie ensuite si le type est de livraison sujet, de convocation candidat, de livraison document ou d'enlevement de copie.
     * Si le type est sélectionné, elle ajoute un symbole correspondant à la droite du libellé.
     */
    checkTypeChangeHandler() {
        this.adresseTemp.type = 0;
        if ((this.$refs.TYPE_LIVRAISON_SUJET as HTMLInputElement).checked) {
            this.adresseTemp.type += this.adresseCentreType.TYPE_LIVRAISON_SUJET;
        }
        if ((this.$refs.TYPE_CONVOCATION_CANDIDAT as HTMLInputElement).checked) {
            this.adresseTemp.type += this.adresseCentreType.TYPE_CONVOCATION_CANDIDAT;
        }
        if ((this.$refs.TYPE_LIVRAISION_DOCUMENT as HTMLInputElement).checked) {
            this.adresseTemp.type += this.adresseCentreType.TYPE_LIVRAISION_DOCUMENT;
        }
        if ((this.$refs.TYPE_ENLEVEMENT_COPIE as HTMLInputElement).checked) {
            this.adresseTemp.type += this.adresseCentreType.TYPE_ENLEVEMENT_COPIE;
        }
    }

    /**
     * checkTypedisabled
     * Cette fonction vérifie si le type d'adresse saisi est disponible ou non.
     * Elle compare le type saisi avec les types d'adresses existants pour le centre.
     * Si le type saisi est un type existant et que l'adresse en cours de modification n'est pas l'une des adresses existantes, la fonction renvoie true.
     * @param type - Le type d'adresse saisi.
     * @returns true si le type est déjà utilisé, false sinon.
     */
    checkTypedisabled (type: number) {
        if (this.$store.state.centre.centreSelect.adresses) {
            for (let i = 0; i < this.$store.state.centre.centreSelect.adresses.length; i++) {
                if (this.$store.state.centre.centreSelect.adresses[i].type & type && (this.adresseTemp.id !== this.$store.state.centre.centreSelect.adresses[i].id)) {
                    return true
                }
            }
        }
        return false
    }

    /**
     * majModeEditionADM
     * Cette fonction met à jour le mode d'édition des adresses.
     * Si le mode est activé, l'utilisateur est autorisé à modifier les informations.
     * Si le mode est désactivé, l'utilisateur ne peut plus modifier les informations.
     * @param mode_edit - Le nouveau mode d'édition.
     */
    majModeEditionADM (mode_edit: boolean) {
        this.lock = !mode_edit
    }

    /**
     * formatteDate
     * Cette fonction retourne une date au format français.
     * @param date - La date à formater.
     * @returns La date au format français.
     */
    formatteDate (date: Date) {
        return formatDate(date)
    }

    beforeMount () {
        this.checkLockInterface()
    }
}

