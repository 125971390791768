export interface CandidatEpreuveInterface {
    name: string,
    code: string,
    id: number | 0
}

export interface StateCandidatEpreuves {
    candidatEpreuves: Array<CandidatEpreuveInterface> | [],
    meta: Array<string> | null,
    loading: boolean | false,
    error: Array<string> | null,
    sortby: string | '',
    candidatEpreuveSelect: CandidatEpreuveInterface | {}
}

export enum TypeStatut {
    STATUT_INSCRIT = 0,
    STATUT_PRESENT = 1,
    STATUT_ABSENT = 2,
}

export enum NumCorrection {
    NUM_CORRECTION_STD = 1,
    NUM_CORRECTION_STD2 = 2,
    NUM_CORRECTION_HARMO = 3,
    NUM_CORRECTION_MODERATION = 4,
    NUM_CORRECTION_CONTROLE = 5,
    NUM_CORRECTION_RECLAMATION = 6
}

interface TypeStatutSpec {
    index: number,
    libelle: string
}

export function getTypeStatutSpec(typeStatut: TypeStatut): TypeStatutSpec {
    switch (typeStatut) {
        case TypeStatut.STATUT_INSCRIT:
            return { index: 0, libelle: 'Inscrit' }
        case TypeStatut.STATUT_PRESENT:
            return { index: 1, libelle: 'Présent' }
        case TypeStatut.STATUT_ABSENT:
            return { index: 2, libelle: 'Absent' }
        default:
            return { index: 0, libelle: 'Inscrit' }
    }
}
