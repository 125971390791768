






































































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
    components: {}
})
export default class SmallCardGraph extends Vue {
    @Prop() readonly graphNumber1!: number
    @Prop() readonly graphNumber2!: number
    @Prop() readonly graphText1!: string
    @Prop() readonly graphText2!: string
    /* Communes à toutes les cartes */
    @Prop() readonly cardType!: string; // type de carte
    @Prop() readonly title!: string;
    @Prop() readonly textShowSingular!: string;
    @Prop() readonly textShowPlural!: string | undefined;
    @Prop() readonly link!: string;
    @Prop() readonly doneText!: string;

    /* ****************** */
    /* Milieu de la carte */
    /* ****************** */
    /* Affichage un seul chiffre */
    @Prop() readonly numberShow!: number | undefined;

    /* ************ */
    /* Bottom carte */
    /* ************ */
    /* regular card */
    @Prop() readonly bottomText!: string | undefined;

    percentageCalc (number1: number, number2: number): number {
        return (number1 / number2) * 100
    }

    strokeDashCalc (number1: number, number2: number): string {
        const firstNumber = this.percentageCalc(number1, number2)
        // const secondNumber = 100 - firstNumber
        // console.log(firstNumber + ' ' + secondNumber)
        return firstNumber + ' ' + (100 - firstNumber)
    }
}
