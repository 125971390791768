


























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import Table from '@/components/Table.vue'
import { Ability } from '@/types/Ability'
import { AffectationExaminateurGroupedSerieInterface } from '@/types/AffectationExaminateur'
import { BModal, BTable } from 'bootstrap-vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

@Component({
    components: {
        Table,
        'b-table': BTable,
        'b-modal': BModal,
        'font-awesome-icon': FontAwesomeIcon

    },
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapState('serie', ['loading'])
    }
})
export default class AffectationExaminateursSalles extends Vue {
    @Prop() session_id?: number
    @Prop() centre_id?: number
    @Prop() salles: any
    @Prop() affectationExaminateursSerieGrouped?: Array<AffectationExaminateurGroupedSerieInterface>
    @Prop() editable?: boolean

    sortBy = ''
    sortDesc = false
    sortDirection = 'asc'
    filter = ''
    filterOn = []
    stickyHeader = true
    ensemble: any = null
    ensembleTemp: any = null
    Ability = Ability

    showModalEditionEnsemble = false
    showModalMessageDelete = false
    showModalMessageAffecteToAllSeries = false
    showModalMessagelSalleNotAffectedToAllSeries = false

    currentItem: any

    sallesTemp: any

    sessionId?: number

    // DATAS
    fields: Array<any> = []

    collectionDynamique: {cle: string; datas: { series: any; salles: any}; disabled: boolean } = { cle: 'series', datas: { series: {}, salles: {} }, disabled: !this.editable }

    mounted () {
        this.getData()
    }

    /**
    * chargement des series
    */
    getData () {
        if (this.session_id) {
            this.sessionId = this.$store.getters['auth/user_session_id']
            this.getSerieData()
        } else {
            this.sessionId = this.$store.getters['auth/user_session_id']
            this.getSerieData()
        }
    }

    /** construit la collectionDynamique pour le tableau contenant l'etat de l'interface, les salles, les series eet les colonnes des series qui afficheront les menus déroulants permettant de choisir les salles */
    getSerieData () {
        this.$store.dispatch('serie/getSeries').then((series) => {
            this.collectionDynamique = { cle: 'series', datas: { series: {}, salles: {} }, disabled: !this.editable }
            this.collectionDynamique.disabled = !this.editable
            this.collectionDynamique.datas.series = series.data.data
            this.collectionDynamique.datas.salles = this.salles
            // on construit les colonnes des series qui afficheront les menus déroulants permettant de choisir les salles
            this.fields = [
                { key: 'ensemble_name', label: 'Équipe', sortable: true, sortDirection: 'asc', class: 'text-start ps-4' },
                { key: 'epreuveCorrection_name', label: 'Épreuve', sortable: true, sortDirection: 'asc', class: 'text-start ps-4' },
                { key: 'user_name', label: 'Examinateur', sortable: true, sortDirection: 'asc', class: 'text-start ps-4' }
            ]
            for (let i = 0; i < series.data.data.length; i++) {
                const col_serie = {
                    key: `serie_${series.data.data[i].id}`,
                    label: series.data.data[i].name,
                    sortable: false,
                    sortDirection: 'asc',
                    class: 'text-start ps-4'
                }
                this.fields.push(col_serie)
            }
            this.salleIsUniqueInSerie()
        })
    }

    /**
     * surveille la liste des salles afin de mettre à jour les menus déroulants des series
     */
    @Watch('salles', { immediate: true, deep: true })
    setSallesToCollectionDynamique () {
        this.salleIsUniqueInSerie()
    }

    /**
     * surveille la liste des salles afin de mettre à jour les menus déroulants des series
     */
    @Watch('editable')
    setEditableToCollectionDynamique () {
        this.getSerieData()
    }

    /**
     * surveille la liste des affectations et les choix des salles dans les séries (utilisation du deep)
     */
    @Watch('affectationExaminateursSerieGrouped', { immediate: true, deep: true })
    affectationExaminateursSerieGroupedChangeHandler () {
        this.salleIsUniqueInSerie()
    }

    /**
     *  on ajoute une propriété dynamique aux objets salle afin de ne pas pouvoir affeter 2 fois la même salle dans une mêmee serie
     */
    salleIsUniqueInSerie () {
        if (this.affectationExaminateursSerieGrouped) {
            for (let jj = 0; jj < this.affectationExaminateursSerieGrouped.length; jj++) {
                for (const keySeri in this.affectationExaminateursSerieGrouped[jj].series) {
                    if (this.affectationExaminateursSerieGrouped[jj].series[keySeri].salle_id != null && !this.checkSalleExiste(this.affectationExaminateursSerieGrouped[jj].series[keySeri].salle_id)) {
                        this.affectationExaminateursSerieGrouped[jj].series[keySeri].salle_id = null
                    }
                }
            }
            this.sallesTemp = []
            for (let i = 0; i < this.salles.length; i++) {
                // on reconstruit l'objet salle afin de nettoyer les propriétés des series
                const salleTemp: any = {
                    id: this.salles[i].id,
                    code: this.salles[i].code,
                    name: this.salles[i].name,
                    capacite_std: this.salles[i].capacite_std,
                    capacite_max: this.salles[i].capacite_max,
                    total_capacite: this.salles[i].total_capacite,
                    type: this.salles[i].type,
                    amenagement: this.salles[i].amenagement,
                    concours: this.salles[i].concours,
                    centre_id: this.salles[i].centre_id
                }
                for (let j = 0; j < this.affectationExaminateursSerieGrouped.length; j++) {
                    for (const keySerie in this.affectationExaminateursSerieGrouped[j].series) {
                        if (salleTemp[keySerie] === undefined) {
                            salleTemp[keySerie] = { used: false, user_id: null }
                        }
                        if (this.affectationExaminateursSerieGrouped[j].series[keySerie].salle_id === this.salles[i].id) {
                            salleTemp[keySerie] = { used: true, user_id: this.affectationExaminateursSerieGrouped[j].user_id }
                        }
                    }
                }
                this.sallesTemp.push(salleTemp)
            }
        }
        this.collectionDynamique.datas.salles = this.sallesTemp
    }

    /** lors de l'action d'affecter une série à toutes les séries de la ligne test et demande confirmation si des salles sont deja affecté à une série */
    affecteToAllSeries (item: AffectationExaminateurGroupedSerieInterface) {
        this.currentItem = item
        // on boucle sur les serie (via le tableau de clé serie_x) afin de check si des salles ont été déja attribué à des séries de la ligne
        for (let i = 1; i < this.currentItem.tableSeries.length; i++) {
            if (this.currentItem.series[this.currentItem.tableSeries[i]].salle_id !== null &&
            this.currentItem.series[this.currentItem.tableSeries[i]].salle_id !== this.currentItem.series[this.currentItem.tableSeries[0]].salle_id) {
                // si oui, on prévient que cette precedent affectation va être écrasée si on continue via le modal dédié
                this.showModalMessageAffecteToAllSeries = true
                return
            }
        }
        this.affecteToAllSeriesSuite()
    }

    /** ferme la fenêtre de  confirmation d'écrasement des salles deja affecté à une série  */
    cancelAffecteToAllSeries () {
        this.currentItem = null
        this.showModalMessageAffecteToAllSeries = false
    }

    /** affecte une salle à toutes les séries de la ligne */
    affecteToAllSeriesSuite () {
        this.showModalMessageAffecteToAllSeries = false
        let salleToCheck = null
        let affecteImpossible = 0
        // on récupère l'objet salle contenu dans la collection dynamique qui sert à savoir si une salle à déja été affecté à une série
        for (const salle in this.collectionDynamique.datas.salles) {
            if (this.currentItem.series[this.currentItem.tableSeries[0]].salle_id === this.collectionDynamique.datas.salles[salle].id) {
                salleToCheck = this.collectionDynamique.datas.salles[salle]
            }
        }

        // on boucle sur les series de la ligne afin d'y affecté la salle choisie sur la premiere série de la ligne
        for (let i = 1; i < this.currentItem.tableSeries.length; i++) {
            // on check via salleToCheck precedent récupéré si salle peut être affecté à la série
            if (!salleToCheck || !salleToCheck[this.currentItem.tableSeries[i]].used || salleToCheck[this.currentItem.tableSeries[i]].user_id === this.currentItem.user_id) {
                if(this.currentItem.distinctSeriesInEpreuveCorrection[this.currentItem.series[this.currentItem.tableSeries[i]].serie_id]) {
                    this.currentItem.series[this.currentItem.tableSeries[i]].salle_id = this.currentItem.series[this.currentItem.tableSeries[0]].salle_id
                }
            } else if (this.currentItem.series[this.currentItem.tableSeries[i]].salle_id !== this.currentItem.series[this.currentItem.tableSeries[0]].salle_id) {
                // si non, on incrémente affecte impossible qui sera testé apres pour savoir si on doit afficher le modal qui indiquera a l'utilisateur que la salle n'a pas pu être affecté à toutes les series
                affecteImpossible++
            }
        }
        if (affecteImpossible > 0) {
            // affiche un message indiquant que la salle n'a pas pu être affecté à toutes les series de la ligne
            this.showModalMessagelSalleNotAffectedToAllSeries = true
        }
    }

    /** ferme le message indiquant que la salle n'a pas pu être affecté à toutes les series de la ligne  */
    closeModalSalleNotAffectedToAllSeries () {
        this.showModalMessagelSalleNotAffectedToAllSeries = false
    }

    /** verifie si une salle existe deja avec l'id fourni */
    checkSalleExiste (salle_id: number | null) {
        for (let i = 0; i < this.salles.length; i++) {
            if (this.salles[i].id === salle_id) {
                return true
            }
        }
        return false
    }
}
