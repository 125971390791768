
































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
    components: {}
})
export default class SmallCardTwoFractions extends Vue {
    /* Communes à toutes les cartes */
    @Prop() readonly cardType!: string; // type de carte
    @Prop() readonly title!: string;

    @Prop() readonly link!: string;

    /* ****************** */
    /* Milieu de la carte */
    /* ****************** */
    /* twoFractionsCard */
    @Prop() readonly fraction1Number1!: number | undefined
    @Prop() readonly fraction1Number2!: number | undefined
    @Prop() readonly textShow1Singular!: number | undefined
    @Prop() readonly textShow1Plural!: number | undefined


    @Prop() readonly fraction2Number1!: number | undefined
    @Prop() readonly fraction2Number2!: number | undefined
    @Prop() readonly textShow2Singular!: number | undefined
    @Prop() readonly textShow2Plural!: number | undefined
}
