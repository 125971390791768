




















































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import store from '@/store/index'
import Back   from '@/components/Tools/Back.vue'

// import Editor from '@/components/Tools/Editor.vue'
import EditorTinyMCE from '@/components/Tools/EditorTinyMCE.vue'
import VuePdfApp from 'vue-pdf-app'
import { base64ToArrayBuffer } from '@/utils/helpers'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'

@Component({
    components: {

        // Editor,
        EditorTinyMCE,
        VuePdfApp,
        Back,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('contrattype', ['contrattype_select', 'loading', 'totalRows', 'lastPage', 'source_pdf']),
        ...mapGetters('poste', ['postes']),
        ...mapGetters('session', ['sessionActive']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    },
    methods: {
        ...mapActions('contrattype', ['getContratType', 'addContratType', 'updateContratType', 'getPdf']),
        ...mapMutations('contrattype', ['SET_CONTRAT_TYPE'])
    },
    beforeRouteEnter (to: any, from: any, next: any) {
        store.commit('contrattype/SET_CONTRAT_TYPE', null)
        // Chargement des postes
        // if (store.getters['poste/postes'].length === 0) {
        store.dispatch('poste/getAllPostes')
        // }
        if (to.params.contrat_type_id !== '0') {
            store.dispatch('contrattype/getContratType', { contrat_type_id: to.params.contrat_type_id })
            next()
        } else {
            next()
        }
    }
})
export default class EditionContratType extends Vue {
    Ability = Ability

    // DATAS
    modePreview = false
    contrat_name = ''
    body_text = ''
    footer_text = ''
    error = false
    message_error = ''
    poste_select = {
        id: 0,
        name: '',
        entity_type: '',
        type_passation: 0,
        options: null
    }

    associations: Array<any> = []
    option_select: Array<any> = []
    liste_associations: Array<any> = []
    config = {
        toolbar: {
            toolbarViewerRight: { presentationMode: false, openFile: false, viewBookmark: false, secondaryToolbarToggle: false }
        }
    }

    source_doc: any = ''

    @Watch('contrattype_select')
    setContratSelect () {
        if (this.$store.state.contrattype.contrattype_select) {
            this.contrat_name = this.$store.state.contrattype.contrattype_select.name
            this.body_text = this.$store.state.contrattype.contrattype_select.body
            this.footer_text = this.$store.state.contrattype.contrattype_select.footer
            if (this.associations.length === 0) {
                this.associations = this.$store.state.contrattype.contrattype_select.associations
                this.buildAssociations()
            }
        }
        if (!this.$store.getters['auth/can'](Ability.RH_DOSADM_MANAGE)) {
            this.apercuContraType()
        }
    }

    // METHODS

    // Récupère le texte du body depuis Editor
    setBodyText (bodyText: string) {
        this.body_text = bodyText
    }

    // Récupère le texte du footer depuis Editor
    setFooterText (footerText: string) {
        this.footer_text = footerText
    }

    // Save or update le contrat
    saveContratType () {
        this.$store.state.contrattype.error = null
        const datas = {
            name: this.contrat_name,
            body: this.body_text,
            footer: this.footer_text,
            associations: JSON.stringify(this.associations)
        }

        if (this.$store.state.contrattype.contrattype_select) {
            // update
            this.$store.dispatch('contrattype/updateContratType', { contrattype_id: this.$store.state.contrattype.contrattype_select.id, payload: datas })
                .then(() => {
                    if (!this.$store.state.contrattype.error) {
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }
                        this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                    }
                })
        } else {
            // Création d'un type de contrat
            this.$store.dispatch('contrattype/saveContratType', datas)
                .then(() => {
                    if (!this.$store.state.contrattype.error) {
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }
                        this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                        this.$router.push('/contrats_types/' + this.$store.state.contrattype.contrattype_select.id)
                    }
                })
        }
    }

    // Aperçu du pdf
    apercuContraType () {
        this.$store.dispatch('contrattype/getPDF', this.$store.state.contrattype.contrattype_select.id)
            .then(() => {
                this.source_doc = base64ToArrayBuffer(this.$store.state.contrattype.source_pdf)
                this.modePreview = true
            })
    }

    // Ferme l'aperçu du pdf
    closeModePreview () {
        this.modePreview = false
        this.$store.state.contrattype.source_pdf = ''
    }

    // Ajoute une association
    addAssociation () {
        this.associations.push({ poste_id: this.poste_select.id, options: this.option_select })
        this.liste_associations.push({ poste: this.poste_select, option: this.option_select })
        this.resetSelection()
    }

    // Reinit la selection
    resetSelection () {
        this.poste_select = {
            id: 0,
            name: '',
            entity_type: '',
            type_passation: 0,
            options: null
        }

        this.option_select = []
    }

    // Selectionne une option pour le poste
    selectOption (e: any) {
        this.option_select.push(e.target.value)
    }

    // Supprimer une association
    deleteAssociation (index: any) {
        this.associations.splice(index, 1)
        this.liste_associations.splice(index, 1)
    }

    // build associations
    buildAssociations () {
        this.liste_associations = []
        for (const a in this.associations) {
            const poste = this.$store.state.poste.postes.find((p: any) => p.id === this.associations[a].poste_id)
            this.liste_associations.push({ poste: poste, option: this.associations[a].options })
        }
    }
}
