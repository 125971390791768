



















































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { countBonus, getLibelleOperator, titleChildCritere, TypeBonification } from '@/types/Bonification'

@Component({
    name: 'Bonification',
    methods: { titleChildCritere, getLibelleOperator, countBonus },
    computed: {
        TypeBonification(): any {
            return TypeBonification
        }
    }
})
export default class Bonification extends Vue {
    @Prop({ default: {}, type: Object, required: true }) datas!: any
    uuid: string = Math.random().toString(36).substr(2, 9)

    /**
     * addCritere
     * @description Ajoute un critère enfant
     * @return {void}
     */
    addCritere(): void {
        if (this.datas.criteres === undefined) {
            this.datas.criteres = []
        }

        this.datas.criteres.push({
            _type: 0,
            operator: '',
            propCandidat: '',
            valProp: '',
            valBonus: 0,
            criteres: []
        })

        this.$nextTick(() => {
            const list = this.$refs[`recursive_list_${this.uuid}`] as Array<any>
            if (list && list.length !== 0) {
                const lastDom = list[list.length - 1].children[0]
                lastDom.open = true
            }
        })
    }

    /**
     * removeCritere
     * @description Supprime un critère enfant
     * @param {number} index - Index du critère à supprimer
     * @return {void}
     */
    removeCritere(index: number): void {
        this.datas.criteres.splice(index, 1)
    }
}
