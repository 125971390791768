




















































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { base64ToArrayBuffer, formatDateDayHourDateForFileSaving, formatDateSinTime, getFileNameFromHeader } from '@/utils/helpers'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { Ability } from '@/types/Ability'
import { EnsembleInterface } from '@/types/Ensemble'


@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapState('session', ['sessionSelect']),
        ...mapGetters('serie', ['series']),
        ...mapState('ensemble', ['ensembles']),
        ...mapGetters('ensemble', ['ensembleById']),
        ...mapGetters('auth', ['is_intervenant_centre']),
        ...mapState('planning', ['seances_examinateurs', 'totalRows', 'lastPage', 'total', 'loading_planning', 'currentPage', 'error_plannings', 'loadingPdf', 'pdfView'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay
    }
})

export default class EmploiTemps extends Vue {
    Ability = Ability
    formatDateSinTime = formatDateSinTime
    options_centres: Array<any> = []
    options_series: Array<any> = []
    options_ensembles: Array<any> = []
    centre_id: any = null
    serie_select_id = null
    ensemble_id = null
    params_search: any = {}
    showPdf = false
    pdfData = ''
    libelle_document: any = ''
    recherche_temp: any = {}


    @Watch('series')
    setSeries(): void {
        this.getSeries()
    }

    @Watch('user_session_id')
    refreshInterface(): void {
        this.options_centres = []
        this.$store.state.centre.centres = []
        this.resetParams()
        this.getCentres()
    }

    @Watch('ensemble_id')
    setCentreId(): void {
        if (this.centre_id === null) {
            this.centre_id = this.$store.state.ensemble.ensembles
                .find((e: any) => e.id === this.ensemble_id)
                ?.centre_id
        }
    }

    /**
     * Récupération de tous les centres et renvois les centres avec équipe
     * @returns {void}
     */
    getCentres(): void {
        let listCentres = this.$store.getters['centre/centres'] || []
        if (listCentres.length === 0) {
            this.$store.dispatch('centre/getCentres')
                .then((response) => listCentres = response.data.data)
        }
        this.options_centres = listCentres
            .filter((centre: { ensembles_count: number }) => centre.ensembles_count !== 0)
    }

    /**
     * Chargement des options de séries
     * @returns {void}
     */
    getSeries(): void {
        this.options_series = this.$store.getters['serie/series']
    }

    /**
     * Sélection d'un centre lance la recherche des équipes qui lui sont associées
     * @param {any} e - Événement de sélection
     * @returns {void}
     */
    selectCentreId(e: any): void {
        this.resetParams()
        Vue.set(this.params_search, 'centre_id', e.target.value)

        const params = {}
        Vue.set(params, 'filter-centre_id', e.target.value)
        Vue.set(params, 'perPage', 1000)
        this.centre_id = e.target.value

        this.$store.dispatch('ensemble/getEnsembles', { params: params }).then(() => {
            this.options_ensembles = this.$store.state.ensemble.ensembles
            this.options_ensembles.sort((a: EnsembleInterface, b: EnsembleInterface) => {
                return (a.type_ensemble > b.type_ensemble) ? 1 : (a.type_ensemble === b.type_ensemble ? (a.name > b.name ? 1 : -1) : -1)
            })
        })
            .catch((error) => {
                console.log('ko:' + error)
            })
    }

    /**
     * Reset les paramètres de recherche
     * @returns {void}
     */
    resetParams(): void {
        this.$store.state.planning.error_plannings = null
        this.params_search = {}
        this.recherche_temp = {}
        this.options_ensembles = []
        this.serie_select_id = null
    }


    /**
     * Exporter le planning au format excel
     * @returns {void}
     */
    exportExcel(): void {
        this.$store.commit('planning/SET_LOADING_PDF', true)
        Vue.set(this.recherche_temp, 'ensemble_id', this.ensemble_id)
        Vue.set(this.recherche_temp, 'centre_id', this.centre_id)
        Vue.set(this.recherche_temp, 'pdf', 0)
        Vue.set(this.recherche_temp, 'format', '')
        Vue.set(this.recherche_temp, 'excel', 1)
        Vue.set(this.recherche_temp, 'fromEmploiDuTemps', 1)
        Vue.set(this.recherche_temp, 'serie_id', this.serie_select_id)

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours...', infosToaster)

        this.$store.dispatch('planning/getExcel', { params : this.recherche_temp })
            .then(response => {
                const link = document.createElement('a')
                link.href = URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }))
                link.setAttribute(
                    'Download',
                    getFileNameFromHeader(response.headers) || ''
                )
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * Exporter le planning au format pdf
     * @returns {void}
     */
    showPdfPlanning(): void {
        let fileName = 'Emploi_du_temps.pdf'
        const centreSelect: any = this.$store.getters['centre/centres']
            .find((c: any) => c.id.toString() === this.centre_id.toString())
        if (centreSelect) {
            const currentDateFormated = formatDateDayHourDateForFileSaving(new Date(Date.now()).toISOString())
            fileName = `Emploi_temps_${centreSelect.name}_${currentDateFormated}.pdf`
        }

        Vue.set(this.params_search, 'ensemble_id', this.ensemble_id)
        Vue.set(this.params_search, 'centre_id', this.centre_id)
        Vue.set(this.params_search, 'pdf', 1)
        Vue.set(this.params_search, 'format', 'b64')
        Vue.set(this.params_search, 'excel', 0)
        Vue.set(this.params_search, 'fromEmploiDuTemps', 1)
        Vue.set(this.params_search, 'serie_id', this.serie_select_id)

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Téléchargement en cours ...', infosToaster)

        this.$store.dispatch('planning/getEmploiTempsPDF', { params: this.params_search })
            .then(response => {
                const url = URL.createObjectURL(new Blob([base64ToArrayBuffer(response.data)]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute(
                    'Download',
                    getFileNameFromHeader(response.headers) || fileName
                )
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * Fermeture du popup de PDF planning
     * @returns {void}
     */
    closeModaleShowPdf(): void {
        this.pdfData = ''
        this.libelle_document = ''
        this.showPdf = false
    }


    async mounted(): Promise<void> {
        this.$store.commit('planning/SET_ERROR', null)

        // Gestion de l'accès par un Gestionnaire de centre
        if (this.$store.getters['auth/is_intervenant_centre']) {
            this.selectCentreId({ target: { value: this.$store.state.user.centreId } })
        } else if (this.$store.getters['auth/can'](Ability.INTERV_OBSERV_MATIERE_OWN)) {
            // Chargement des ensembles correspondants aux matières de l'utilisateur
            const params = {}
            const tabMatiereId: Array<number> = []
            const authUser = this.$store.getters['auth/authUser']
            const da = this.$store.getters['dossieracademique/dossierAcademiqueByUserId'](authUser.id)

            if (da) {
                let  matieresTemp = null
                if(da.courant && da.courant.matieres) {
                    matieresTemp =  da.courant.matieres
                } else if (da.matieres){
                    matieresTemp =  da.matieres
                }
                if(matieresTemp !== null) {
                    for (let i = 0; i < matieresTemp.length; i++) {
                        tabMatiereId.push(matieresTemp[i].id)
                    }
                }
            } else {
                const abilitiesMatieres = authUser.abilities
                    .filter((a: any) => a.name === Ability.INTERV_OBSERV_MATIERE_OWN)

                for (const ab in abilitiesMatieres) {
                    if (abilitiesMatieres[ab].entity_id) {
                        tabMatiereId.push(abilitiesMatieres[ab].entity_id)
                    }
                }
            }

            Vue.set(params, 'matiere_id', tabMatiereId)

            await this.$store.dispatch('ensemble/getEnsembles', { params: params })
            this.options_ensembles = JSON.parse(JSON.stringify(this.$store.getters['ensemble/ensembles']))
                .sort((a: EnsembleInterface, b: EnsembleInterface) => (a.type_ensemble > b.type_ensemble) ? 1 : (a.type_ensemble === b.type_ensemble ? (a.name > b.name ? 1 : -1) : -1))
        } else {
            this.getCentres()
        }


        if (this.options_series.length === 0) {
            this.getSeries()
        }
    }

    clearResult(): void {
        this.$store.commit('planning/RESET_META')
        this.$store.commit('planning/RESET_SEANCES')
    }
}
