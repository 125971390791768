
































































































































































import { Vue, Component } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'
import '@/store/modules/Epreuve'
import '@/store/modules/Session'
import Table from '@/components/Table.vue'
import PopupEditUser from '@/components/Administration/Users/PopupEditUser.vue'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import { Ability } from '@/types/Ability'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { codeRestrict } from '@/utils/helpers'

import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'

@Component({
    components: {
        ExaGenericTable,
        Table,
        PopupEditUser,
        Datetime,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapState('session', ['loading', 'error', 'sessions', 'meta', 'links', 'totalRows', 'lastPage', 'totalPage', 'totalRows']),
        ...mapState('concour', {
            loadingConcours: 'loading',
            errorConcours: 'error',
            concours: 'concours'
        }),
        ...mapState('session', {
            loadingEpreuve: 'loading',
            errorEpreuve: 'error',
            sessions: 'sessions',
            sessionsCorrections: 'sessionsCorrections'
        })
    }

})
export default class Sessions extends Vue {
    codeRestrict = codeRestrict
    // DATAS

    genericfields = [
        { key: 'etatEdit', label: '', sortable: false, class: '', type: 'action' },
        { key: 'code', label: 'Code', sortable: true, class: 'text-start col-min-width', type: 'text' },
        { key: 'long_name', label: 'Libellé long', sortable: true, sortDirection: 'asc', class: 'text-left' },
        { key: 'name', label: 'Libellé court', sortable: true, sortDirection: 'asc', class: 'text-left' },
        { key: 'validStructureSession', label: 'Structure valide', sortable: false, class: 'text-center', type: 'action' },
        { key: 'syncroViatique', label: 'Synchro. VIATIQUE', sortable: false, class: 'text-center', type: 'action' },
        { key: 'lockSession', label: '', sortable: false, class: '', type: 'action' },
        { key: 'actionSession', label: '', sortable: false, class: '', type: 'action' },
        { key: 'enterSession', label: '', sortable: false, class: '', type: 'action' }
    ]

    dataForTab: Array<any> = []
    filtres: any = []

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setDataForGenericTab(poData: any, isLoadMore = false) {
        if (!isLoadMore) {
            this.dataForTab = []
        }
        if (poData) {
            for (const result of poData) {
                const classEtatEdit = 'text-light col-w-etat ' + (!result.closed_at ? 'btn_action_ligne' : 'bg-secondary')
                const iconEtatEdit = !result.closed_at ? this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE) ? 'pen' : 'eye' : 'lock'
                const classActionSession = 'text-info col-w-action bg-' + (!result.closed_at ? 'transparent' : 'transparent')
                const classEnterSession = 'text-primary col-w-action bg-transparent'

                const iconValidStrucutureSession = result.structure_valide === 0 ? 'times' : 'check'
                const titleValidStructureSession = result.structure_valide === 0 ? 'Structure non validée' : 'Structure validée'
                const classValidStructureSession = result.structure_valide === 0 ? 'text-center text-secondary col-w-action bg-transparent' : 'text-center text-success col-w-action bg-transparent'

                const iconSyncroViatique = result.sync_viatique === 0 ? 'times' : 'check'
                const titleSyncroViatique = result.sync_viatique === 0 ? 'Non synchronisé dans Viatique' : 'Synchronisé dans Viatique'
                const classSyncroViatique = result.sync_viatique === 0 ? 'text-center text-secondary col-w-action bg-transparent' : 'text-center text-success col-w-action bg-transparent'

                const line = [
                    { label: '', item: result, icon: iconEtatEdit, type: 'action', typeAction: 'etatEdit', class: classEtatEdit, disabled:  result.closed_at != null },
                    { label: '', item: result.code, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.long_name, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.name, type: 'text', typeAction: null, class: '' },
                    { label: titleValidStructureSession, item: result, icon: iconValidStrucutureSession, type: 'action', typeAction: 'validStructureSession', class: classValidStructureSession, disabled: !this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE) },
                    { label: titleSyncroViatique, item: result, icon: iconSyncroViatique, type: 'action', typeAction: 'syncroViatique', class: classSyncroViatique, disabled: false },
                    { label: '', item: result, icon: 'lock', type: 'action', typeAction: 'lockSession', class: classActionSession, disabled: result.closed_at != null || !this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE) },
                    { label: '', item: result, icon: ['far', 'clone'], type: 'action', typeAction: 'actionSession', class: classActionSession, disabled: !this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE) }, //  result.closed_at != null
                    { label: '', item: result, icon: 'arrow-circle-right', type: 'action', typeAction: 'enterSession', class: classEnterSession, disabled: false }// result.closed_at != null }
                ]
                this.dataForTab.push(line)
            }
        }
        return this.dataForTab
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setFiltersForGenericTab()
    {
        this.filtres =
        [
            {
                libelle: 'Code',
                defautOptionlibelle: 'Rechercher un',
                model: 'code',
                value: '',
                index: 'code',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'code', strict: true } // 'form' , 'deroulant'
            },
            {
                libelle: 'Libellé long',
                defautOptionlibelle: 'Rechercher un',
                model: 'long_name',
                value: '',
                index: 'long_name',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'long_name' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Libellé court',
                defautOptionlibelle: 'Rechercher un',
                model: 'name',
                value: '',
                index: 'name',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'name' } // 'form' , 'deroulant'
            }
        ]
    }

    /**
     * Récupération des events de la table
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'edit':

                    break
                case 'openComment':

                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'lockSession':
                    if (this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE)) {
                        this.clotureSession(paParams[1])
                    }
                    break
                case 'etatEdit':
                    this.editSession(paParams[1])
                    break
                case 'actionSession':
                    if (this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE)) {
                        this.cloneSession(paParams[1])
                    }
                    break
                case 'enterSession':
                    this.enterSession(paParams[1])
                    break

                case 'validStructureSession':
                    if (this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE)) {
                        this.valideStructure(paParams[1])
                    }
                    break
                default:
                    break
            }
        }
    }


    fields = [
        {
            key: 'etatSession',
            label: '',
            sortable: false,
            sortDirection: 'asc',
            tdClass: function (value: any, key: string, item: any) {
                return 'col-w-etat ' + (!item.closed_at ? 'btn_action_ligne' : 'bg-secondary')
            }
        },
        { key: 'code', label: 'Code', sortable: true, sortDirection: 'asc', class: 'text-left' },
        { key: 'long_name', label: 'Libellé long', sortable: true, sortDirection: 'asc', class: 'text-left' },
        { key: 'name', label: 'Libellé court', sortable: true, sortDirection: 'asc', class: 'text-left' },
        {
            key: 'actionSession',
            label: '',
            sortable: false,
            sortDirection: 'asc',
            tdClass: function (value: number, key: string, item: any) {
                return 'col-w-action bg-' + (!item.closed_at ? 'transparent' : 'transparent')
            }
        },
        {
            key: 'enterSession',
            label: '',
            sortable: false,
            sortDirection: 'asc',
            tdClass: function () {
                return 'col-w-action bg-transparent'
            }
        }
        // { key: 'closed_at', label: 'Terminée', sortable: true, sortDirection: 'asc' }
        // { key: 'cloture', label: '', class: 'text-center' }
    ]


    Ability = Ability

    params = 'sort=name&direction=asc'
    firstLoading = true
    user_select = null
    showModalMessageClone = false
    selectedRowItemId = 0
    selectedRow: any = null
    currentSessionName = ''
    currentConcourName = ''
    affichageEdition = 'listeConcours'
    dismissCountDown = 0

    session: any = null
    sessionTemp: any = null

    showModalEditionSession = false
    showModalMessageCloture = false
    showModalMessageValideStructure = false


    enterSession (row: any) {
        this.$emit('clickDetailsSession', row)
    }

    // METHODS

    cloneSession (row: any) {
        this.selectedRow = row
        this.selectedRowItemId = row.id
        this.sessionTemp = {
            id: 0,
            name: this.selectedRow.name,
            long_name: this.selectedRow.long_name,
            code: this.selectedRow.code
        }
        this.showModalMessageClone = true
    }

    cancelClone () {
        this.selectedRow = null
        this.selectedRowItemId = 0
        this.showModalMessageClone = false
        this.sessionTemp = null
    }

    cloneSuite () {
        this.showModalMessageClone = false
        if (this.selectedRowItemId !== 0) {
            const params = {
                session_id: this.selectedRowItemId,
                long_name: this.sessionTemp.long_name,
                name: this.sessionTemp.name,
                code: this.sessionTemp.code
            }
            const idInfo = 't_info_' + Math.random()
            const infosToaster = {
                id: idInfo,
                toaster: 'b-toaster-top-right',
                variant: 'primary',
                noCloseButton: true,
                fade: true,
                noAutoHide: true
            }
            this.$bvToast.toast('Création de la nouvelle session en cours ...', infosToaster)
            this.$store.dispatch('session/cloneSession', params)
                .then(() => {
                    this.selectedRow = null
                    this.selectedRowItemId = 0
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Nouvelle session créée avec succès !', succesToaster)

                    this.$store.dispatch('session/getSessions', this.params)
                        .then(() => {
                            this.setDataForGenericTab(this.$store.state.session.sessions)
                        }).catch((error) => {
                            console.log('ko:' + error)
                        })
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    openAddSession () {
        this.sessionTemp = {
            id: 0,
            name: '',
            long_name: '',
            code: ''
        }
        this.showModalEditionSession = true
    }

    editSession (row: any) {
        this.session = row
        this.sessionTemp = {
            id: this.session.id,
            name: this.session.name,
            long_name: this.session.long_name,
            code: this.session.code
        }
        this.showModalEditionSession = true
    }

    cancelEdit () {
        this.session = null
        this.sessionTemp = null
        this.showModalEditionSession = false
    }

    editSuite () {
        const params = {
            session_id: this.sessionTemp.id,
            payload: {
                long_name: this.sessionTemp.long_name,
                name: this.sessionTemp.name,
                code: this.sessionTemp.code
            }
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        if (this.sessionTemp.id === 0) {
            this.$store.dispatch('session/addSession', params)
                .then(() => {
                    this.session = null
                    this.sessionTemp = null
                    this.showModalEditionSession = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Session ajoutée avec succès !', succesToaster)
                    this.$store.dispatch('session/getSessions', this.params)
                        .then(() => {
                            this.setDataForGenericTab(this.$store.state.session.sessions)
                        }).catch((error) => {
                            console.log('ko:' + error)
                        })
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            this.$store.dispatch('session/updateSession', params)
                .then(() => {
                    this.session = null
                    this.sessionTemp = null
                    this.showModalEditionSession = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Session modifiée avec succès !', succesToaster)
                    this.$store.dispatch('session/getSessions', this.params)
                        .then(() => {
                            this.setDataForGenericTab(this.$store.state.session.sessions)
                        }).catch((error) => {
                            console.log('ko:' + error)
                        })
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    valideStructure (row: any) {
        this.showModalMessageValideStructure = true
        this.session = row
        this.sessionTemp = {
            id: this.session.id,
            name: this.session.name,
            long_name: this.session.long_name,
            code: this.session.code,
            structure_valide: this.session.structure_valide
        }
    }

    valideStructureSuite () {
        const params = {
            session_id: this.sessionTemp.id,
            payload: {
                structure_valide: this.sessionTemp.structure_valide === 1 ? 0 : 1
            }
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('session/updateSession', params)
            .then(() => {
                this.session = null
                this.sessionTemp = null
                this.showModalMessageValideStructure = false
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Session modifiée avec succès !', succesToaster)
                this.$store.dispatch('session/getSessions', this.params)
                    .then(() => {
                        this.setDataForGenericTab(this.$store.state.session.sessions)
                    }).catch((error) => {
                        console.log('ko:' + error)
                    })
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    cancelValidationStructure () {
        this.showModalMessageValideStructure = false
    }

    listeEpreuves (concour: any) {
        this.affichageEdition = 'listeEpreuves'
        const params: any = {}
        this.currentConcourName = concour.name
        params['filter-concour_id'] = concour.id
        this.$store.state.session.sessions = []
        this.$store.dispatch('session/getEpreuves', { isPrecedente: false, filters: params })
    }

    clotureSession (data: any) {
        this.sessionTemp = data
        this.showModalMessageCloture = true
    }

    cancelCloture () {
        this.sessionTemp = null
        this.showModalMessageCloture = false
    }

    clotureSuite () {
        const params = {
            session_id: this.sessionTemp.id,
            payload: {
                closed_at:  new Date(Date.now()).toISOString(),
                long_name: this.sessionTemp.long_name,
                name: this.sessionTemp.name,
                code: this.sessionTemp.code
            }
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('clôture en cours ...', infosToaster)
        this.$store.dispatch('session/updateSession', params)
            .then(() => {
                this.session = null
                this.sessionTemp = null
                this.showModalMessageCloture = false
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('clôture terminée.', succesToaster)
                this.session = null
                this.showModalMessageCloture = false
                this.$store.dispatch('session/getSessions', this.params)
                    .then(() => {
                        this.setDataForGenericTab(this.$store.state.session.sessions)
                    }).catch((error) => {
                        console.log('ko:' + error)
                    })
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    countDownChanged (dismissCountDown: number) {
        this.dismissCountDown = dismissCountDown
    }

    // Appelle une page lors du scroll
    filtreSortHandler (params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.$store.dispatch('session/getSessions', this.params).then(() => {
                this.setDataForGenericTab(this.$store.state.session.sessions)
            }).catch((error) => {
                console.log('ko:' + error)
            })
        }
    }

    loadHandler (params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)  || (JSON.stringify(this.params) === JSON.stringify(params) && this.firstLoading)) {
            this.firstLoading = false
            this.params = params
            this.$store.dispatch('session/getMoreSessions', this.params).then(() => {
                this.setDataForGenericTab(this.$store.state.session.sessions)
            }).catch((error) => {
                console.log('ko:' + error)
            })
        }
    }

    mounted () {
        this.setFiltersForGenericTab()
        // this.loadHandler(this.params)
        this.$store.dispatch('session/getSessions', this.params).then(() => {
            this.setDataForGenericTab(this.$store.state.session.sessions)
        }).catch((error) => {
            console.log('ko:' + error)
        })
    }
}
