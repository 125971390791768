













































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
    components: {}
})
export default class SmallCardProgressBar extends Vue {
    /* Communes à toutes les cartes */
    @Prop() readonly cardType!: string; // type de carte
    @Prop() readonly title!: string;
    @Prop() readonly textShowSingular!: string;
    @Prop() readonly textShowPlural!: string | undefined;
    @Prop() readonly link!: string;

    @Prop() readonly fractionNumber1!: number
    @Prop() readonly fractionNumber2!: number

    @Prop() readonly doneText!: string

    percentageCalculation (number1: number, number2: number): number {
        return number1 * 100 / number2
    }
}
