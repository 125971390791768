





















































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component, Watch }    from 'vue-property-decorator'
import { mapGetters, mapState }     from 'vuex'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { formatDateDayHourDateForFileSaving, formatDateHoursMinutes, formatDateSinTime, getFileNameFromHeader } from '@/utils/helpers'
import { TypePassation } from '@/types/Epreuve'
import ErrorDisplay                 from '@/components/ErrorDisplay.vue'
import PopupPlanningPDF             from '@/components/Plannings/PopupPlanningPDF.vue'
import { etat_seance } from '@/types/Seance'
import { Ability } from '@/types/Ability'
import { EnsembleInterface } from '@/types/Ensemble'


@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapGetters('serie', ['series']),
        ...mapState('planning', ['seances_candidats', 'totalRows', 'lastPage', 'total', 'loading_planning', 'currentPage', 'error_plannings', 'loadingPdf', 'pdfView']),
        ...mapState('epreuve', ['epreuvesCorrections']),
        ...mapGetters('auth', ['is_intervenant_centre'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ExaGenericTable,
        ErrorDisplay,
        PopupPlanningPDF
    }
})

export default class PlanningsCandidat extends Vue
{
    Ability = Ability
    formatDateSinTime = formatDateSinTime
    genericfields = [
        { key: 'serie',         label: 'Série',         sortable: false,    class: 'text-start',       type: 'text' },
        { key: 'date',          label: 'Date',          sortable: false,    class: 'text-start',       type: 'text' },
        { key: 'heure',         label: 'Heure',         sortable: false,    class: 'text-start',       type: 'text' },
        { key: 'centre',        label: 'Centre',        sortable: false,    class: 'text-start',       type: 'text' },
        { key: 'salle',         label: 'Salle',         sortable: false,    class: 'text-start',       type: 'text' },
        { key: 'equipe',        label: 'Équipe',        sortable: false,    class: 'text-start',       type: 'text' },
        { key: 'examinateur',   label: 'Examinateur',   sortable: false,    class: 'text-start',       type: 'text' },
        { key: 'epreuve',       label: 'Épreuve',       sortable: false,    class: 'text-start',       type: 'text' },
        { key: 'code',          label: 'Code',          sortable: false,    class: 'text-start',       type: 'text' },
        { key: 'candidat',      label: 'Candidat',      sortable: false,    class: 'text-start',       type: 'text' },
        { key: 'filiere',       label: 'Filière',       sortable: false,    class: 'text-start',       type: 'text' }
    ]

    sortBy          = '';
    sortDesc        = false;
    sortDirection   = 'asc';
    filter          = '';
    filterOn        = [];
    stickyHeader    = true;

    filtres:    any         = []
    dataForTab: Array<any>  = []

    options_series: Array<any> = []
    options_filieres: Array<any> = []
    options_epreuves: Array<any> = []
    serie_select_id = null
    filiere_select_id = null
    epreuve_select_id = null
    code_candidat = ''
    name_candidat = ''
    first_name_candidat = ''
    params_search: any = {}
    showPdf = false
    titre_popup = 'Plannings des candidats'
    pdf_filename = ''
    pdfData                 = ''
    libelle_document: any   = ''
    paramsConfirmationImpression: any   = {}
    recherche_temp: any = {}

    @Watch('series')
    setSeries () {
        this.getSeries()
    }

    @Watch('seances_candidats')
    watchTableau() {
        const data = this.$store.state.planning.seances_candidats
        this.setDataForGenericTab(data)
    }

    @Watch('filiere_select_id')
    loadOptionsEpreuves () {
        if (this.filiere_select_id !== null) {
            const params = {}
            Vue.set(params, 'filter-concour_id', this.filiere_select_id)
            Vue.set(params, 'filter-type_passation', TypePassation.TYPE_PASSATION_ORAL)
            this.$store.dispatch('epreuve/getEpreuves', { params: params }).then(() => {
                this.options_epreuves = []
                this.options_epreuves = this.$store.state.epreuve.epreuves
            })
        } else {
            this.epreuve_select_id = null
            this.options_epreuves = []
        }
    }

    /**
     * Chargement des options de séries
     */
    getSeries () {
        this.options_series = this.$store.getters['serie/series']
    }

    /**
     * Chargement des filières
     */
    getFilieres () {
        this.$store.dispatch('concour/getConcoursActifs').then(() => {
            const filieres = this.$store.getters['concour/banques']
            this.options_filieres = []
            for (const f in filieres) {
                this.options_filieres.push({ id: filieres[f].id, name: filieres[f].name })
            }
        })
    }

    /**
     * Lance la requête de sélection
     */
    rechercher () {
        this.$store.commit('planning/RESET_ERROR')

        if (!this.$store.getters['auth/can'](Ability.ORAL_PLAN_VIEW)) {
            // Ajout du parametre de centre
            Vue.set(this.params_search, 'centre_id', this.$route.params.centre_id)
        }
        Vue.set(this.params_search, 'serie_id', this.serie_select_id)
        Vue.set(this.params_search, 'filiere_id', this.filiere_select_id)
        Vue.set(this.params_search, 'candidat_code', this.code_candidat)
        Vue.set(this.params_search, 'candidat_name', this.name_candidat)
        Vue.set(this.params_search, 'candidat_first_name', this.first_name_candidat)
        Vue.set(this.params_search, 'epreuve_id', this.epreuve_select_id)
        Vue.set(this.params_search, 'page', 1)
        Vue.set(this.params_search, 'pdf', 0)
        Vue.set(this.params_search, 'excel', 0)

        this.recherche_temp = this.params_search

        this.$store.dispatch('planning/getSeances', { mode: 'candidat', params : this.recherche_temp }).then(() => {
            this.setDataForGenericTab(this.$store.state.planning.seances_candidats)
        })
    }

    /** Alimentation des lignes du tableau */
    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                let centre = this.$store.state.centre.centres.find((c: any) => c.id === result.creneau.ensemble.centre_id)
                if (!centre && this.$store.getters['auth/can'](Ability.INTERV_OBSERV_MATIERE_OWN) && result.creneau) {
                    centre = result && result.creneau && result.creneau.ensemble && result.creneau.ensemble.centre ? result.creneau.ensemble.centre : ' - '
                } else if (!centre && this.$store.getters['auth/is_intervenant_centre']) {
                    if (result.creneau && result.creneau.ensemble && result.creneau.ensemble.centre) {
                        centre = result.creneau.ensemble.centre
                    }
                }

                const ensemble = result.creneau && result.creneau.ensemble ? result.creneau.ensemble : null
                const examinateur = result.creneau && result.creneau.user ? result.creneau.user : null

                const epreuve = result.epreuve ? result.epreuve : null
                const concour = this.$store.state.concour.concours.find((c: any) => c.id === result.epreuve.concour_id)
                const filiere = concour.banque_id !== null ? concour.banque.code : concour.code
                const salle = result.salle ? result.salle.name : '-'

                let candidat = ''
                candidat = result.candidat.name + ' ' + result.candidat.first_name
                if (result.etat === etat_seance.ETAT_DEMISSIONE) {
                    candidat = '<div class="text-danger">' + candidat + ' (Démission)</div>'
                }

                const line = [
                    { label: 'serie',       item: result.serie.name,                                type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'date',        item: formatDateSinTime(result.creneau.jour),           type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'heure',       item: formatDateHoursMinutes(result.creneau.h_debut),   type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'centre',      item: centre && centre.name ? centre.name : '-',        type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'salle',       item: salle,                                            type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'equipe',      item: ensemble ? ensemble.name : ' - ',                 type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'examinateur', item: result.creneau.user_id && examinateur ? examinateur.name + ' ' + examinateur.first_name : '-',  type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'epreuve',     item: epreuve ? epreuve.name : '-',                     type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'code',        item: result.candidat.code,                             type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'candidat',    item: candidat,                                         type: 'html', typeAction: null, class: 'text-start' },
                    { label: 'filiere',     item: filiere,                                          type: 'text', typeAction: null, class: 'text-start' }
                ]
                this.dataForTab.push(line)
            }
        }
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'onLoadPage':
                    this.loadHandler()
                    break
            }
        }
    }

    /**
     * Appel d'une nouvelle page de séances lors du scroll
     */
    loadHandler ()
    {
        Vue.set(this.params_search, 'page', (this.$store.state.planning.currentPage + 1))
        this.$store.dispatch('planning/getMoreSeances', { mode: 'candidat', params : this.params_search })
    }

    /**
     * Exporter le planning au format excel
     */
    export_excel () {
        this.$store.commit('planning/SET_LOADING_PDF', true)
        Vue.set(this.recherche_temp, 'excel', 1)
        Vue.set(this.recherche_temp, 'pdf', 0)
        Vue.set(this.recherche_temp, 'format', '')
        let fileName = ''

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours...', infosToaster)

        this.$store.dispatch('planning/getExcel', { params : this.recherche_temp })
            .then(response => {
                const fileNameTemp = getFileNameFromHeader(response.headers)
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * Affiche le PDF du planning
     */
    show_pdf_planning () {
        const currentDateFormated = formatDateDayHourDateForFileSaving(new Date(Date.now()).toISOString())
        this.pdf_filename = 'Plannings_candidats_' + currentDateFormated + '.pdf'
        Vue.set(this.recherche_temp, 'pdf', 1)
        Vue.set(this.recherche_temp, 'format', 'b64')
        Vue.set(this.recherche_temp, 'excel', 0)
        this.paramsConfirmationImpression = this.recherche_temp
        this.showPdf = true
    }

    /**
     * Fermeture du popup de PDF planning
     */
    closeModaleShowPdf () {
        this.pdfData = ''
        this.libelle_document = ''
        this.showPdf = false
    }

    mounted () {
        this.$store.state.planning.error_plannings = null
        if (this.$store.getters['auth/can'](Ability.INTERV_OBSERV_MATIERE_OWN)) {
            // Chargement des ensembles correspondants aux matières de l'utilisateur
            const params = {}
            const tab_matiere_id: Array<number> = []
            const da = this.$store.getters['dossieracademique/dossierAcademiqueByUserId'](this.$store.state.auth.user.id)
            if (da) {
                //Vue.set(params, 'matiere_id', this.$store.state.dossieracademique.dossiersAcademiques[this.$store.state.auth.user.id].courant.matiere.id)
                let  matieresTemp = null
                if(da.courant && da.courant.matieres) {
                    matieresTemp =  da.courant.matieres
                } else if (da.matieres){
                    matieresTemp =  da.matieres
                }
                if(matieresTemp !== null) {
                    for (let i = 0; i < matieresTemp.length; i++) {
                        tab_matiere_id.push(matieresTemp[i].id)
                    }
                }
            } else {
                const abilities_matieres = this.$store.state.auth.user.abilities.filter((a: any) => a.name === Ability.INTERV_OBSERV_MATIERE_OWN)
                for (const ab in abilities_matieres) {
                    if (abilities_matieres[ab].entity_id) {
                        tab_matiere_id.push(abilities_matieres[ab].entity_id)
                    }
                }

            }
            Vue.set(params, 'matiere_id', tab_matiere_id)

            this.$store.dispatch('ensemble/getEnsembles', { params: params }).then(() => {
                this.$store.state.ensemble.ensembles.sort((a: EnsembleInterface, b: EnsembleInterface) => {
                    return (a.type_ensemble > b.type_ensemble) ? 1 : (a.type_ensemble === b.type_ensemble ? (a.name > b.name ? 1 : -1) : -1)
                })
                this.getSeries()
                this.getFilieres()
            }).catch((error) => {
                console.log('ko:' + error)
            })
        } else {
            this.$store.dispatch('ensemble/getEnsembles').then(() => {
                this.$store.state.ensemble.ensembles.sort((a: EnsembleInterface, b: EnsembleInterface) => {
                    return (a.type_ensemble > b.type_ensemble) ? 1 : (a.type_ensemble === b.type_ensemble ? (a.name > b.name ? 1 : -1) : -1)
                })
                this.$store.dispatch('centre/getCentres').then(() => {
                    this.getSeries()
                    this.getFilieres()
                })
            })
        }
    }

    clearResult() {
        this.$store.commit('planning/RESET_META')
        this.$store.commit('planning/RESET_SEANCES')
    }
}
