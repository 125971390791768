


















import { Vue, Component, Prop } from 'vue-property-decorator'
import ItemIntervenant from '@/components/ItemIntervenant.vue'
import { mapGetters } from 'vuex'
import { Etat, getEtatSpec } from '@/types/DossierAdministratif'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

@Component({
    components: {
        ItemIntervenant,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('dossieradministratif', ['dossiersAdministratifs'])
    }
})
export default class ModuleDossiersAdministratifs extends Vue {
    @Prop() user?: any;
    etat_vierge = Etat.ETAT_VIERGE
    etat_depose = Etat.ETAT_DEPOSE
    etat_valide = Etat.ETAT_VALIDE
    etat_rejete = Etat.ETAT_REJETE

    // Retourne une description selon l'état du dossier
    getDescription (etat: number, date_validation: Date) {
        let description = ''
        switch (etat) {
            case Etat.ETAT_VIERGE :
                description = 'Le responsable des recrutements vous invite à remplir un dossier administratif.'
                break
            case Etat.ETAT_DEPOSE :
                description = 'Votre dossier a été soumis, il est en cours d\'étude.'
                break
            case Etat.ETAT_VALIDE :
                description = 'Votre dossier administratif est complet. '
                break
            case Etat.ETAT_REJETE :
                description = 'Certaines de vos informations nécessitent votre attention.'
                break
        }

        if (date_validation !== null && etat === Etat.ETAT_DEPOSE) {
            description = 'Votre dossier administratif est complet.'
        }

        return description
    }

    // Retourne la couleur du dossier selon son état
    getVariantColor (etat: Etat) {
        return getEtatSpec(etat).color
    }
}
