import { CandidatInterface } from '@/types/Candidat'
import { JobDescriptionInterface } from '@/types/JobDescription'
import { UserInterface } from '@/types/User'

export interface CandidatJobDescriptionInterface {
    candidat?: CandidatInterface,
    candidat_id: number | null,
    id: number,
    jobDescription?: JobDescriptionInterface,
    job_description_id: number,
    order: number,
    ruled_at: Date | null,
    status: CandidatJobDescriptionStatus,
    updated_at: Date,
    user?: UserInterface,
    user_id: number | null
}

export interface StateCandidatJobDescriptions {
    candidatJobDescriptions: Array<CandidatJobDescriptionInterface> | [],
    candidatJobDescriptionSelect: CandidatJobDescriptionInterface | null,
    meta: Array<string> | null,
    links: Array<string> | null,
    loading: boolean | false,
    error: Array<string> | null,
    totalRows: number | 0
}

export enum CandidatJobDescriptionStatus {
    STATUS_APPLICATION = 0,
    STATUS_REJECTED = 1,
    STATUS_ACCEPTED = 2
}

export function getCandidatJobDescriptionStatus(status: CandidatJobDescriptionStatus) {
    switch (status) {
        case CandidatJobDescriptionStatus.STATUS_REJECTED:
            return {index: 1, name: 'Refusé', color: 'danger' }

        case CandidatJobDescriptionStatus.STATUS_ACCEPTED:
            return {index: 2, name: 'Accepté', color: 'success' }

        default:
            return {index: 0, name: 'Inscrit', color: 'secondary' }
    }
}
