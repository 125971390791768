























import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { Ability } from '@/types/Ability'
import SaisieSalles from '@/components/Centre/Salles/Ecrits/SaisieSalles.vue'
import SaisieIntervenants from '@/components/Centre/Salles/Ecrits/SaisieIntervenants.vue'
import { formatDate, isObject } from '@/utils/helpers'
import ErrorDisplay from '@/components/ErrorDisplay.vue'


@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapState('centre', ['centreSelect', 'loading', 'save_in_progress']),
        ...mapGetters('centre', ['salles_is_submit', 'salles_is_validate', 'nb_intervenants_is_submit', 'nb_intervenants_is_validate']),
        ...mapState('organisationecrit', ['new_id', 'error', 'besoins_centre', 'selectComposant', 'errors_code', 'has_error_code'])
    },
    components: {
        SaisieSalles,
        SaisieIntervenants,
        ErrorDisplay

    }
})
export default class GestionEcrits extends Vue {
    Ability = Ability
    refreshCollec = false

    @Watch('selectComposant')
    checkRefresh () {
        this.refreshCollec = this.$store.state.organisationecrit.selectComposant === 'comp_intervenants_ecrit';
    }


    /** Check le type du retour d'erreur */
    isObject () {
        return isObject(this.$store.state.organisationecrit.error)
    }


    /** Formattage les dates */
    formatteDate (date: Date) {
        return formatDate(date)
    }

    /** Change le composant sélectionné  */
    setSelectComposant (composant: string) {
        this.$store.commit('organisationecrit/SET_SELECT_COMPOSANT', composant)
    }
}

