




















































































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component, Watch }    from 'vue-property-decorator'
import { mapGetters, mapState }     from 'vuex'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import popupImprimer                from '@/components/Fourniture/popupImprimer.vue'
import { formatDateSinTime, formatDate, formatDayHourZDateFromString, formatDateDayHourDateForFileSaving }        from '@/utils/helpers'
import { FournitureType, lastUpdateAfterValidatedSerieDate, printed, RechercheOngletImpressionCandidat, typeLongName, modified, createMidnightDateNow, FournitureTypeCentreOnglet }           from '@/types/Fourniture'
import ErrorDisplay                 from '@/components/ErrorDisplay.vue'
import _ from 'lodash'
import { Ability } from '@/types/Ability'


@Component({
    computed: {
        ...mapGetters('serie', ['serieById', 'series']),
        ...mapGetters('ensemble', ['ensembles']),
        ...mapGetters('impressionCandidat', ['tableauImpressionsCandidatTrie', 'getError', 'loadingRecherche', 'loadingPdf', 'meta', 'currentPage', 'totalRows', 'lastPage', 'total']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('auth', ['is_intervenant_centre'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ExaGenericTable,
        popupImprimer,
        ErrorDisplay
    }
})

export default class ImpressionsCandidat extends Vue
{
    // utiliser pour passer le titre dans la popUp

    titrePopUp              = 'Impression par candidat'
    sortBy                  = '';
    sortDesc                = false;
    sortDirection           = 'asc';
    filter                  = '';
    filterOn                = [];
    stickyHeader            = true;
    options_centres         = []
    options_series          = []
    options_ensembles       = []
    options_examinateurs    = []
    showModalImprimer       = false
    formatDateSinTime       = formatDateSinTime
    formatDate              = formatDate
    printed                 = printed
    modified                = modified
    filtres:    any         = []
    dataForTab: Array<any>  = []
    pdfData                 = ''
    pdfFileName             = 'Impression_Par_Candidat.pdf'
    libelle_document: any   = ''
    paramsConfirmationImpression: any = {}
    options_filieres: any             = []
    typeLongName                      = typeLongName
    Ability                           = Ability
    createMidnightDateNow             = createMidnightDateNow
    // Sauvegarde le dernier choix utilisateur des dates
    saveInputStart              = ''

    genericfields = [
        { key: 'type',              label: 'Type',                   sortable: false,    class: 'text-center',  type: 'text' },
        { key: 'centre',            label: 'Centre',                 sortable: false,    class: 'text-center',  type: 'text' },
        { key: 'equipe',            label: 'Équipe',                 sortable: false,    class: 'text-center',  type: 'text' },
        { key: 'examinateur',       label: 'Examinateur',            sortable: false,    class: '',             type: 'text' },
        { key: 'serie',             label: 'Série',                  sortable: false,    class: 'text-center',  type: 'text' },
        { key: 'candidat',          label: 'Candidat',               sortable: false,    class: '',             type: 'text' },
        { key: 'DateLastModif',     label: 'Dernière modif.',        sortable: false,    class: 'text-center',  type: 'text' },
        { key: 'ConfirmImp',        label: 'Impression confirmée',   sortable: false,    class: 'text-center',  type: 'text' },
        { key: 'openPdf',           label: '',                       sortable: false,    class: 'text-center',  type: 'text' }
    ]

    // this.$store.getters['concour/banques']
    recherche: RechercheOngletImpressionCandidat = {
        centre_id               : '',
        serie_id                : '',
        candidat_code           : '',
        candidat_name           : '',
        candidat_first_name     : '',
        concour_id              : '',
        modified                : modified.TOUTES_LES_SEANCES,
        modified_start          : '',
        printed                 : null,
        printed_start           : '',
        fiche_notation          : 1,
        fiche_mesure            : 1,
        page                    : 1
    }

    @Watch('series')
    watchSerie() {
        this.options_series = this.$store.getters['serie/series']
    }

    @Watch('tableauImpressionsCandidatTrie')
    watchTableau() {
        const data = this.$store.getters['impressionCandidat/tableauImpressionsCandidatTrie']
        this.setDataForGenericTab(data)
    }

    @Watch('user_session_id')
    refreshInterface () {
        this.dataForTab = []
        this.load()
        this.recherche = {
            centre_id               : '',
            serie_id                : '',
            candidat_code           : '',
            candidat_name           : '',
            candidat_first_name     : '',
            concour_id              : '',
            modified                : modified.TOUTES_LES_SEANCES,
            modified_start          : '',
            printed                 : null,
            printed_start           : '',
            fiche_notation          : 1,
            fiche_mesure            : 1,
            page                    : 1
        }
    }

    /** formatte une valeur string de type'YYYY-MM-DD HH:mm:ss' en valeur moment YYYY-MM-DDTHH:mm:ss[Z] */
    convertDateToIso = (dateString: string | undefined) => {
        if (dateString) {
            return formatDayHourZDateFromString(dateString)
        }
    }

    // recuperation des ensembles s'ils ne sont pas transmis par le parent
    getEnsemblesData() {
        const params = {
            perPage: 10000
        }
        this.$store.dispatch('ensemble/getEnsembles', { params: params })
            .catch((error) => {
                console.log('ko:' + error)
            })
    }

    initSeries () {
        if (this.$store.getters['serie/series'].length === 0) {
            this.$store.dispatch('serie/getSeries')
        } else {
            this.options_series = this.$store.getters['serie/series']
        }
    }

    initfilieresMatieres () {
        // Options filières
        if (this.$store.getters['concour/banques'].length === 0) {
            this.$store.dispatch('concour/getConcoursActifs').then(() => {
                const filieres = this.$store.getters['concour/banques']
                this.options_filieres = []
                for (const f in filieres) {
                    this.options_filieres.push({ index: filieres[f].id, name: filieres[f].name })
                }
            })
        } else {
            const filieres = this.$store.getters['concour/banques']
            this.options_filieres = []
            for (const f in filieres) {
                this.options_filieres.push({ index: filieres[f].id, name: filieres[f].name })
            }
        }
    }

    clearModified() {
        this.recherche.modified_start = ''
    }


    /** lance la recherche ou l affichage du pdf. pdf_view 0/1. 1 si apercu du pdf  */
    sendSearch(pdf_view: number) {
        this.$store.commit('impressionCandidat/RESET_META')
        this.$store.commit('impressionCandidat/RESET_TABLEAU_IMPRESSION')
        const params: RechercheOngletImpressionCandidat  =
        {
            candidat_code       : this.recherche.candidat_code,
            candidat_name       : this.recherche.candidat_name,
            candidat_first_name : this.recherche.candidat_first_name,
            centre_id           : this.recherche.centre_id,
            serie_id            : this.recherche.serie_id,
            concour_id          : this.recherche.concour_id,
            modified            : this.recherche.modified === modified.SEANCES_MODIFIEES_A_PARTIR ? 1 : this.recherche.modified,
            modified_start      : this.convertDateToIso(this.recherche.modified_start),
            printed             : null,
            printed_start       : null,
            pdf                 : pdf_view,
            fiche_notation      : 1,
            fiche_mesure        : 1,
            page                : this.recherche.page
        }
        this.$store.dispatch('impressionCandidat/getTableauImpressionCandidat', params)
    }

    erase_date_time() {
        this.saveInputStart              = this.recherche.modified_start !== '' ? this.recherche.modified_start : this.saveInputStart

        this.recherche.modified_start   = ''
        if (this.recherche.modified === modified.SEANCES_MODIFIEES_A_PARTIR) {
            if (this.saveInputStart === '' || !this.saveInputStart)
            {
                this.recherche.modified_start   = this.createMidnightDateNow()
            } else {
                this.recherche.modified_start   = this.saveInputStart
            }
        }
    }
    /*
    *
    * Generic Table Area
    *
    */

    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                const ensemble              = this.$store.state.ensemble.ensembles.find((e: any) => e.id === result.ensemble_id)
                const typeResult            = this.typeLongName(result.type)
                const centre                = result.centre_id      ? this.findCentre(result.centre_id) : '-'
                const equipe                = result.ensemble_id    ? this.findEnsemble(result.centre_id, result.ensemble_id) : '-'
                let examinateur             = ensemble.examinateurs.find((ex: any) => ex.id === result.user_id) ? ensemble.examinateurs.find((ex: any) => ex.id === result.user_id) : '-'
                if (!examinateur) {
                    // on cherche dans les remplaçants
                    examinateur = ensemble.remplacants.find((ex: any) => ex.id === result.user_id)
                }
                const serie                 = result.serie_id       ? this.findSerie(result.serie_id) : '-'
                const candidat              = result.candidat       ? result.candidat.code + ' ' + result.candidat.name + ' ' + result.candidat.first_name : ''
                const dateDerniereModif     = result.updated_at && lastUpdateAfterValidatedSerieDate(result.updated_at, result.serie_id)     ? formatDate(result.updated_at) : '-'
                const impressionConfirmee   = result.printed_at     ? formatDate(result.printed_at) : '-'
                // const impressionConfirmee   = result.printed_at     ? formatDate(result.printed_at) : '-'

                const line: any = [
                    { label: 'Type',        item: typeResult,                 type: 'text', typeAction: null, class: this.getClassFromType(typeResult) + ' text-center colonne_type' },
                    { label: 'Centre',      item: centre.name,               type: 'text', typeAction: null, class: this.getClassFromType(typeResult) + ' text-center' },
                    { label: 'equipe',      item: equipe.name,               type: 'text', typeAction: null, class: this.getClassFromType(typeResult) + ' text-center' },
                    {
                        label: 'examinateur',
                        item:   examinateur.name + ' ' + examinateur.first_name,
                        type: 'text',
                        typeAction: null,
                        class: this.getClassFromType(typeResult)
                    },
                    { label: 'serie',       item: serie.name,                type: 'text', typeAction: null, class: this.getClassFromType(typeResult) + ' text-center' },
                    {
                        label: 'candidat',
                        item: candidat,
                        type: 'text',
                        typeAction: null,
                        class: this.getClassFromType(typeResult)
                    },
                    { label: 'dateModif',   item: dateDerniereModif,        type: 'text',   typeAction: null, class: this.getClassFromType(typeResult) + ' text-center' },
                    { label: 'impConf',     item: impressionConfirmee,  type: 'text', typeAction: null, class: this.getClassFromType(typeResult) + ' text-center' }
                ]

                if (this.$store.getters['auth/can'](Ability.ORAL_GFOU_MANAGE)) {
                    line.push({
                        label: 'Afficher le Pdf',
                        item: typeResult === FournitureType.TYPE_FMO ? '' : result,
                        type: typeResult === FournitureType.TYPE_FMO ? 'text' : 'action',
                        typeAction: typeResult === FournitureType.TYPE_FMO ? null : 'openPdf',
                        class: typeResult === FournitureType.TYPE_FMO ? this.getClassFromType(typeResult) :  this.getClassFromType(typeResult) + ' icone_pdf',
                        icon: typeResult === FournitureType.TYPE_FMO ? '' : 'print'
                    })
                } else {
                    line.push({
                        label: '',
                        item: '',
                        type: 'text',
                        typeAction: null,
                        class: '',
                        icon: ''
                    })
                }

                this.dataForTab.push(line)
            }
        }
    }

    /** recuperation du type et renvois la classe afin de creer un background gris sombre  */
    getClassFromType(type: string) {
        let classType = ''
        switch (true) {
            case type === FournitureType.TYPE_FPO :
                classType = 'type_class_grey'
                break
            case type === FournitureType.TYPE_FMO :
                classType = 'type_class_grey'
                break
            default:
                break
        }
        return classType
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'onLoadPage':
                    this.loadHandler()
                    break
                case 'openPdf':
                    this.downloadPdf(paParams[1])
                    break
            }
        }
    }

    /**
     * Appel d'une nouvelle page de epreuveCorrectionResultats lors du scroll
     */
    loadHandler ()
    {
        const params: RechercheOngletImpressionCandidat  =
        {
            candidat_code       : this.recherche.candidat_code,
            candidat_name       : this.recherche.candidat_name,
            candidat_first_name : this.recherche.candidat_first_name,
            centre_id           : this.recherche.centre_id,
            serie_id            : this.recherche.serie_id,
            concour_id          : this.recherche.concour_id,
            modified            : this.recherche.modified === modified.SEANCES_MODIFIEES_A_PARTIR ? 1 : this.recherche.modified,
            modified_start      : this.convertDateToIso(this.recherche.modified_start),
            printed             : null,
            printed_start       : null,
            pdf                 : 0,
            page                : this.$store.state.impressionExaminateur.currentPage + 1,
            fiche_notation      : 1,
            fiche_mesure        : 1
        }

        this.$store.dispatch('impressionCandidat/getMoreTableauImpressionCandidat', params)
    }

    /** recup de tous les centres  */
    getCentres() {
        if (this.$store.getters['centre/centres'].length === 0) {
            this.$store.dispatch('centre/getCentres').then((resp) => {
                this.options_centres = resp.data.data.filter((centre: { ensembles_count: number }) => centre.ensembles_count !== 0)
            })
        } else {
            this.options_centres = this.$store.getters['centre/centres'].filter((centre: { ensembles_count: number }) => centre.ensembles_count !== 0)
        }
    }


    /** recheche un centre et renvois son nom  */
    findCentre(centre_id: number) {
        const centre: any = this.$store.getters['centre/centres'].filter((centre: any) => centre.id === centre_id)[0]
        return  centre
    }

    /** recherche d'un ensemble et renvois le nom  */
    findEnsemble(centre_id: number, ensemble_id: number) {
        const ensembles = this.$store.getters['ensemble/ensembles']
        const ensemble: any = ensembles.filter((ens: any) => ens.id === ensemble_id)[0]
        return ensemble
    }

    findExaminateur(ensemble_id: number, user_id: number) {
        const ensembles = this.$store.getters['ensemble/ensembles']
        const examinateurs: any = ensembles.filter((ens: any) => ens.id === ensemble_id)[0].examinateurs
        const examinateur: any = examinateurs.filter((examinateur: { id: number }) => examinateur.id === user_id)[0]
        return examinateur
    }

    findSerie(serie_id: number) {
        const series = this.$store.getters['serie/series']
        const serie = series.filter((serie: any) => serie.id === serie_id)[0]
        return serie
    }



    /** Retourne la liste passée en paramètre ordonnée par name */
    orderedList (liste: any) {
        return _.orderBy(liste, 'name', 'asc')
    }

    /*
    *
    * Modale Area
    *
    */


    /* Gestion de l'affichage de la modale imprimer */
    downloadPdf(paParams: any) {
        const currentDateFormated = formatDateDayHourDateForFileSaving(new Date(Date.now()).toISOString())
        this.pdfFileName = 'Impression_Candidat_' + paParams.candidat.name + '_' + paParams.candidat.first_name + (paParams.epreuve ? '_Epreuve_' + paParams.epreuve.name : '') + '_' + currentDateFormated + '.pdf'


        const params: any = paParams
        params.pdf = 1
        params.format = 'b64'
        params.fiche_notation = paParams.type === FournitureTypeCentreOnglet.PO ? 0 : 1
        params.fiche_mesure = paParams.type === FournitureTypeCentreOnglet.PO ? 1 : 0
        /* this.$store.dispatch('impressionCandidat/getPdf', params).then(resp => {
            this.pdfData = resp.data
            this.libelle_document = getFileNameFromHeader(resp.header)
        }) */
        params.amenagementsTypeOral = paParams.amenagementsTypeOral
        params.type = paParams.type
        this.paramsConfirmationImpression = params
        this.showModalImprimer = true
    }

    closeModaleImprimer() {
        this.pdfData = ''
        this.showModalImprimer = false
    }

    load () {
        this.$store.commit('impressionCandidat/RESET_META')
        this.getCentres()
        this.getEnsemblesData()
        this.initSeries()

        if (this.$store.getters['auth/is_intervenant_centre']) {
            this.recherche.centre_id = this.$route.params.centre_id
        }
    }

    /** Verif si session active, sinon appel  */
    mounted() {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
