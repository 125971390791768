




























































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { Ability } from '@/types/Ability'
import EditorTinyMCE from '@/components/Tools/EditorTinyMCE.vue'
import { EntityType, getEntityTypeSpec } from '@/types/NotificationTemplate'
import { Decision, DecisionIntervenant, getDecisionSpec } from '@/types/DossierAcademique'
import VuePdfApp from 'vue-pdf-app'
import { base64ToArrayBuffer } from '@/utils/helpers'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'
import Back from '@/components/Tools/Back.vue'

@Component({
    components: {
        EditorTinyMCE,
        VuePdfApp,
        Back,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('notificationtemplate', ['loading', 'error', 'notificationTemplates', 'meta', 'links', 'totalRows', 'source_pdf']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})

export default class EditionNotificationTemplates extends Vue
{
    Ability = Ability

    // Ensemble des colonnes du tableau

    genericfields = [
        { key: 'etatEdit',      label: '', sortable: false, class: '', type: 'action' },
        { key: 'name', label: 'Libellé', sortable: false, class: '', type: 'text' }
    ]

    notificationTemplate: any = null
    notificationTemplateTemp: any = null

    showModalMessageDelete = false
    modeRedaction = true
    modeConsultation = false

    entityType = EntityType
    getterEntityTypeSpec = getEntityTypeSpec

    decision = Decision
    decisionIntervenant = DecisionIntervenant
    getterDecisionSpec = getDecisionSpec

    customToolbar = [[{ header: [1, 2, 3, 4, 5, 6, false] }], ['bold', 'italic', 'underline', 'strike'], [{ list: 'ordered' }, { list: 'bullet' }], [{ align: [] }]]
    cursor_in_editor = false
    config = {
        toolbar: {
            toolbarViewerRight: { presentationMode: false, openFile: false, viewBookmark: false, secondaryToolbarToggle: false }
        }
    }

    source_doc: any = ''



    // Ouvre l'aperçu d'un pv
    apercuPdf () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('notificationtemplate/updateNotificationTemplate', this.notificationTemplateTemp)
            .then((response: any) => {
                this.notificationTemplate = response.data.data
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                this.apercuPdfSuite()
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Mode consultation du pdf
    apercuPdfSuite () {
        // this.saveDatas(datas)
        this.$store.dispatch('notificationtemplate/getPDF', this.notificationTemplateTemp.id).then(() => {
            this.source_doc = base64ToArrayBuffer(this.$store.state.notificationtemplate.source_pdf)
            this.modeRedaction = false
            this.modeConsultation = true
        })
    }

    // Fermeture du mode consultation
    back () {
        this.source_doc = ''
        this.modeRedaction = true
        this.modeConsultation = false
    }

    // Récupération des valeurs depuis l'editor
    saveDatasEmailSubject (value: string) {
        this.notificationTemplateTemp.tpl_email_subject = value
    }

    saveDatasEmail (value: string) {
        this.notificationTemplateTemp.tpl_email_message = value
    }

    saveDatasPdf (value: string) {
        this.notificationTemplateTemp.tpl_pdf_content = value
    }

    // Ouvre l'édition d'une notification template
    editNotificationTemplate (row: any) {
        this.notificationTemplate = row
        this.notificationTemplateTemp = {
            champ_descriptif: this.notificationTemplate.champ_descriptif,
            entity_id: this.notificationTemplate.entity_id,
            entity_property: this.notificationTemplate.entity_property,
            entity_property_value: this.notificationTemplate.entity_property_value,
            entity_type: this.notificationTemplate.entity_type,
            id: this.notificationTemplate.id,
            name: this.notificationTemplate.name,
            session_id: this.notificationTemplate.session_id,
            tpl_email_message: this.notificationTemplate.tpl_email_message,
            tpl_email_subject: this.notificationTemplate.tpl_email_subject,
            tpl_pdf_content: this.notificationTemplate.tpl_pdf_content,
            enabled: this.notificationTemplate.enabled
        }
        if (!this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE)) {
            this.apercuPdfSuite()
        }
    }


    // Enregistre l'édition
    editSuite () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        if (this.notificationTemplateTemp.id === 0) {
            this.$store.dispatch('notificationtemplate/addNotificationTemplate', this.notificationTemplateTemp)
                .then((response) => {
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                    this.editNotificationTemplate(response.data.data)
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            this.$store.dispatch('notificationtemplate/updateNotificationTemplate', this.notificationTemplateTemp)
                .then((response) => {
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                    this.editNotificationTemplate(response.data.data)
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    mounted () {
        this.load()
    }

    load() {
        if (this.$store.state.notificationtemplate.notificationTemplates.length === 0) {
            // on recharge les notifications template
            this.$store.dispatch('notificationtemplate/getNotificationTemplates').then(() => {
                const notificationSelect = this.$store.state.notificationtemplate.notificationTemplates.find((n: any) => n.id.toString() === this.$route.params.notification_id.toString())
                this.editNotificationTemplate(notificationSelect)
            })
        } else {
            if (this.$route.params.notification_id) {
                const notificationSelect = this.$store.state.notificationtemplate.notificationTemplates.find((n: any) => n.id.toString() === this.$route.params.notification_id.toString())
                this.editNotificationTemplate(notificationSelect)
            }
        }
    }
}
