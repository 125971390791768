









import { Vue, Component, Prop } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon
    }
})

export default class MessageIndication extends Vue {
    @Prop() message?: string
}
