




















import { Vue, Component, Prop } from 'vue-property-decorator'
import ItemIntervenant from '@/components/ItemIntervenant.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { Etat } from '@/types/DossierAdministratif'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

@Component({
    components: {
        ItemIntervenant,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('dossieradministratif', ['contrats', 'dossiersAdministratifs']),
        ...mapState('dossieradministratif', ['contrats']),
        ...mapMutations('dossieradministratif', ['SET_CONTRATS']),
        ...mapState('user', ['hasContrat']),
        ordreMissionDisabled() {
            return this.$store.getters['auth/findParameter']('ordreMissionDisabled')?.value !== '1'
        }
    },
    methods: {
        ...mapActions('dossieradministratif', ['getContrats'])
    }
})
export default class ModuleContrats extends Vue {
    @Prop() user?: any;
    etat_vierge = Etat.ETAT_VIERGE
    etat_depose = Etat.ETAT_DEPOSE
    etat_valide = Etat.ETAT_VALIDE
    etat_rejete = Etat.ETAT_REJETE

    // Retourne une description selon l'état des contrats
    getDescription () {
        let description = ''
        let count_success = 0
        let count_info = 0
        let count_secondary = 0
        for (const c in this.$store.state.dossieradministratif.contrats) {
            if (this.$store.state.dossieradministratif.contrats[c].etat === Etat.ETAT_VIERGE) {
                count_secondary++
            }
            if (this.$store.state.dossieradministratif.contrats[c].etat === Etat.ETAT_DEPOSE) {
                count_info++
            }
            if (this.$store.state.dossieradministratif.contrats[c].etat === Etat.ETAT_VALIDE) {
                count_success++
            }
        }

        if (count_success !== 0) {
            description = 'Vos contrats sont signés.'
        }
        if (count_info !== 0) {
            description = 'Vos contrats sont en attente de signature par la direction.'
        }
        if (count_secondary !== 0) {
            description = 'Vous avez des contrats en attente de signature.'
        }

        return description
    }

    // Retourne la couleur du dossier selon son état
    getVariantColor () {
        let variant = 'text-secondary'
        let count_success = 0
        let count_info = 0
        let count_secondary = 0
        for (const c in this.$store.state.dossieradministratif.contrats) {
            if (this.$store.state.dossieradministratif.contrats[c].etat === Etat.ETAT_VALIDE && this.$store.state.dossieradministratif.contrats[c].signed_at) {
                count_success++
            }
            if (this.$store.state.dossieradministratif.contrats[c].etat === Etat.ETAT_DEPOSE) {
                count_info++
            }
            if (this.$store.state.dossieradministratif.contrats[c].etat === Etat.ETAT_VIERGE) {
                count_secondary++
            }
        }

        if (count_success !== 0) {
            variant = 'text-success'
        }
        if (count_info !== 0) {
            variant = 'text-info'
        }
        if (count_secondary !== 0) {
            variant = 'text-secondary'
        }

        return variant
    }
}
