import { isEmpty, isNull } from 'lodash'

/**
 * Bonification
 * @description Type de bonification
 * @enum {number} Type de bonification
 * @exports
 */
export enum TypeBonification {
    TYPE_VARIABLE = 1,
    TYPE_CRITERES,
}

/**
 * countBonus
 * @description Additionne les valeurs bonus des critères enfants
 * @param {any} datas - Critère enfant
 * @return {number} Valeur bonus
 * @exports
 */
export function countBonus(datas: any): number {
    let bonus: number = parseInt(datas.valBonus)
    if (!isNull(datas.criteres) && !isEmpty(datas.criteres)) {
        datas.criteres.forEach((data: any) => {
            bonus += countBonus(data)
        })
    }
    return bonus
}

/**
 * getLibelleOperator
 * @description Retourne le libellé de l'opérateur
 * @param {string} operator - Opérateur
 * @return {string} Libellé de l'opérateur
 * @exports
 */
export function getLibelleOperator(operator: string): string {
    switch (operator) {
        case '||':
            return 'Ou'
        case '&&':
            return 'Et'
        case '<':
            return 'Inférieur à'
        case '<=':
            return 'Inférieur ou égal à'
        case '=':
            return 'Égal à'
        case '>=':
            return 'Supérieur ou égal à'
        case '>':
            return 'Supérieur à'
        case '!=':
            return 'Différent de'
        default:
            return ''
    }
}

/**
 * titleChildCritere
 * @description Retourne le titre du critère enfant
 * @param {any} datas - Critère enfant
 * @return {string} Titre de la balise details
 * @exports
 */
export function titleChildCritere(datas: any): string {
    if (datas._type === TypeBonification.TYPE_VARIABLE) {
        return `${datas.propCandidat.toLowerCase()} ${
            datas.operator === '||' || datas.operator === '&&'
                ? ''
                : getLibelleOperator(datas.operator).toLowerCase()
        } ${parseInt(datas.valProp) ? datas.valProp : datas.valProp ? '"' + datas.valProp + '"' : ''}`
    }

    if (datas._type === TypeBonification.TYPE_CRITERES) {
        if (datas.criteres.length > 0) {
            return datas.criteres
                .map((data: any) => titleChildCritere(data))
                .join(
                    ` <b>${
                        datas.operator === '||' || datas.operator === '&&'
                            ? getLibelleOperator(datas.operator).toLowerCase()
                            : ''
                    }</b> `
                )
        } else {
            return titleChildCritere(datas.criteres)
        }
    }

    return ''
}
