




















import { Vue, Component } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapState('auth', ['user', 'authUser'])
    }
})
export default class Test extends Vue {
    user_connected = !!this.$store.getters['auth/authUser']
    contenu: any = null
    code_video = ''
    libelle_video = ''
    loading = true

    getVideoSpec(key: string) {
        let videoSpec = this.contenu?.videos?.find((video: any) => video.key === key)

        if (videoSpec === undefined) {
            videoSpec = { code: '', libelle: '', select: false, key: '' }
        }
        return videoSpec
    }

    async beforeMount () {
        if (this.user_connected) {
            this.contenu = this.$store.getters['auth/findParameter']('aides')?.value
        } else {
            const response = await this.$store.dispatch('parameter/getAides')
            this.contenu = response?.data?.data?.value
        }
        this.code_video = this.getVideoSpec(this.$route.params.video_name).code
        this.libelle_video = this.getVideoSpec(this.$route.params.video_name).libelle
        this.loading = false
    }
}
