

















































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { Ability } from '@/types/Ability'
import { TypePassation } from '@/types/Epreuve'
import { TypeSalle, getTypeSalle, SalleInterface, TypeAmenagement, getTypeAmenagement } from '@/types/Salle'
import { BModal, BTable } from 'bootstrap-vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { codeRestrict, slugifyToCode } from '@/utils/helpers'

@Component({
    components: {
        'b-table': BTable,
        'b-modal': BModal,
        'font-awesome-icon': FontAwesomeIcon

    },
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapState('centre', ['centreSelect']),
        ...mapState('salle', ['loading'])
    }
})
export default class ListeSalles extends Vue {
    codeRestrict = codeRestrict
    slugifyToCode = slugifyToCode
    @Prop() colSize: any
    @Prop() salles: any
    @Prop() editable?: boolean

    Ability = Ability
    TypePassation = TypePassation
    TypeSalle = TypeSalle
    TypeAmenagement = TypeAmenagement
    modeEdition = false
    gestionnaire = null
    getTypeSalle = getTypeSalle
    getTypeAmenagement = getTypeAmenagement
    salleSelect: SalleInterface = {
        id: 0,
        code: '',
        name: '',
        capacite_std: 0,
        capacite_max: 0,
        type: null,
        amenagement: null,
        total_capacite: 0,
        concours: [],
        centre_id: 0
    }

    salleTemp: SalleInterface = {
        id: 0,
        code: '',
        name: '',
        capacite_std: 0,
        capacite_max: 0,
        total_capacite: 0,
        type: null,
        amenagement: null,
        concours: [],
        centre_id: 0
    }

    lock = false
    salles_tp = []
    idNewSalle = 0

    showModalMessageDelete = false

    sallesTemp: Array<SalleInterface> = []

    codeVide = false
    codeNonUnique = false

    salleInvalide: {[salleId: number]: {codeVide: boolean; codeNonUnique: boolean}} = {}
    nberror = 0

    inputChecker = false
    /**
     * met à jours sallesTemps qui utilisée dans cette interface
     */
    @Watch('salles', { immediate: true, deep: true })
    sallesChange () {
        this.sallesTemp = []
        for (let i = 0; i < this.salles.length; i++) {
            this.sallesTemp.push({
                id: this.salles[i].id,
                code: this.salles[i].code,
                name: this.salles[i].name,
                capacite_std: this.salles[i].capacite_std,
                capacite_max: this.salles[i].capacite_max,
                type: this.salles[i].type,
                amenagement: this.salles[i].amenagement,
                centre_id: this.salles[i].centre_id,
                concours: this.salles[i].concours,
                total_capacite: this.salles[i].total_capacite
            })
        }
        this.resetSalleInvalide()
    }

    @Watch('sallesTemp', { immediate: true, deep: true })
    wSalleTemps() {
        this.salleInputChecker()
    }

    /**
     * liste les salles et indique si des problèmes existent sur la saisie du code
     */
    resetSalleInvalide () {
        this.salleInvalide = {}
        for (let i = 0; i < this.sallesTemp.length; i++) {
            this.salleInvalide[this.sallesTemp[i].id] = {
                codeVide: false,
                codeNonUnique: false
            }
        }
    }


    /** Controle si toutes les salles ont un nom  */
    salleInputChecker() {
        let state = false
        if (this.sallesTemp && this.sallesTemp.length !== 0) {
            this.sallesTemp.forEach(salle => {
                if (salle.name === '' || salle.name === undefined)
                {
                    state = true
                }
            })
        }
        this.inputChecker = state
    }

    /**
     * check que le code des salles est bien unique
     */
    checkCodeSalleIsUniqueOrEmpty () {
        this.nberror = 0
        for (let i = 0; i < this.sallesTemp.length; i++) {
            this.salleInvalide[this.sallesTemp[i].id] = {
                codeVide: false,
                codeNonUnique: false
            }
            if (this.sallesTemp[i].code.trim().length < 2) {
                this.salleInvalide[this.sallesTemp[i].id].codeVide = true
                this.nberror++
            } else {
                this.salleInvalide[this.sallesTemp[i].id].codeVide = false
            }

            for (let j = 0; j < this.sallesTemp.length; j++) {
                if (this.sallesTemp[i].code.trim() === this.sallesTemp[j].code.trim() && (this.sallesTemp[i].id !== this.sallesTemp[j].id)) {
                    this.salleInvalide[this.sallesTemp[i].id].codeNonUnique = true
                    this.nberror++
                }
            }
        }
        this.$emit('sallesErrors', this.nberror)
    }

    /**
     * gere le changement d'etat type d'une salle via la case à cocher dans la liste et le changement d'etat aménagement d'une salle via la case à cocher dans la liste
     * appelle salleUpdate du parent afin de mettre a jour les salle du parent pour affichage
     */
    salleChangeHandler (salle: SalleInterface, property: string, index: number) {
        switch (property) {
            case 'code':
                this.sallesTemp[index].code = this.slugifyToCode(salle.name) // création du code à partir du nom de la salle.
                this.checkCodeSalleIsUniqueOrEmpty()
                if (this.nberror > 0) {
                    return
                }
                break
            case 'libelle':
                break
            case 'amenagement':
                for (let i = 0; i < this.sallesTemp.length; i++) {
                    if (this.sallesTemp[i].id === salle.id) {
                        if (this.sallesTemp[i].amenagement === TypeAmenagement.AMENAGEMENT_HANDICAP) {
                            this.sallesTemp[i].amenagement = TypeAmenagement.AMENAGEMENT_AUCUN
                        } else {
                            this.sallesTemp[i].amenagement = TypeAmenagement.AMENAGEMENT_HANDICAP
                        }
                    }
                }
                break
            case 'type':
                for (let i = 0; i < this.sallesTemp.length; i++) {
                    if (this.sallesTemp[i].id === salle.id) {
                        if (this.sallesTemp[i].type === TypeSalle.TP) {
                            this.sallesTemp[i].type = TypeSalle.STANDARD
                        } else {
                            this.sallesTemp[i].type = TypeSalle.TP
                        }
                    }
                }
                break
        }
        this.$emit('sallesUpdate', this.sallesTemp)
    }

    /**
     * @description Attends le rafraichissement complet du navigateur
     * @return {Promise<void>}
     */
    waitRequestAnimationFrame(): Promise<void> {
        return new Promise((resolve) => {
            this.$nextTick(() => {
                window.requestAnimationFrame(() => {
                    window.requestAnimationFrame(() => {
                        resolve()
                    })
                })
            })
        })
    }

    /**
     * Active le mode édition en ajout
     */
    addSalle () {
        this.idNewSalle--
        const newSalleTemp: SalleInterface = {
            id: this.idNewSalle,
            code: '',
            name: '',
            capacite_std: 0,
            capacite_max: 0,
            type: TypeSalle.STANDARD,
            amenagement: TypeAmenagement.AMENAGEMENT_AUCUN,
            centre_id: this.$store.state.centre.centreSelect.id,
            concours: [],
            total_capacite: null
        }
        this.sallesTemp.push(newSalleTemp)
        // this.checkCodeSalleIsUniqueOrEmpty()

        this.waitRequestAnimationFrame().then(() => {
            document.getElementsByClassName('list-salles')[0].scrollTop = document.getElementsByClassName('list-salles')[0].scrollHeight
            document.getElementById('in_code_amenagement_' + this.idNewSalle)?.focus()
        })
    }

    /**
     * @description Ajoute une salle lors de l'appui sur la touche entrée sur le dernier champ de la liste
     * @param {any} event - L'événement clavier
     * @return {void}
     */
    watchKey(event: any): void {
        const lastId = this.sallesTemp[this.sallesTemp.length - 1]?.id
        const id = Number(event.target.id.split('_')[3])
        if (!this.inputChecker && id === lastId && event.key === 'Enter') {
            this.addSalle()
        }
    }

    /**
     * affiche la modale de confirmation de suppression
     */
    showModaleRemoveSalle (salle: SalleInterface) {
        this.salleTemp = salle
        this.showModalMessageDelete = true
    }

    /**
     * affiche la modale de confirmation de suppression
     */
    cancelRemoveSalle () {
        this.salleTemp = {
            id: 0,
            code: '',
            name: '',
            capacite_std: 0,
            capacite_max: 0,
            total_capacite: 0,
            type: null,
            amenagement: null,
            concours: [],
            centre_id: 0
        }
        this.showModalMessageDelete = false
    }

    /**
     * supprime la salle de la liste sallesTemp
     */
    removeSalle () {
        const newSallesTemp: Array<SalleInterface> = []
        for (let i = 0; i < this.sallesTemp.length; i++) {
            if (this.sallesTemp[i].id !== this.salleTemp.id) {
                newSallesTemp.push(this.sallesTemp[i])
            }
        }
        this.salleTemp = {
            id: 0,
            code: '',
            name: '',
            capacite_std: 0,
            capacite_max: 0,
            total_capacite: 0,
            type: null,
            amenagement: null,
            concours: [],
            centre_id: 0
        }
        this.sallesTemp = newSallesTemp
        this.$emit('sallesUpdate', this.sallesTemp)
        this.showModalMessageDelete = false
    }

    mounted () {
        this.resetSalleInvalide()
    }
}
