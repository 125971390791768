


















































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import { Etat } from '@/types/DossierAcademique'
import { Dossier_type } from '@/types/DocumentType'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

@Component({
    computed: {
        ...mapState('document', ['documents', 'documentSelect', 'errorDocument']),
        ...mapState('dossieracademique', ['posteSelect']),
        ...mapGetters('document', ['documents', 'documentSelect', 'success']),
        ...mapGetters('poste', ['listePostes']),
        ...mapGetters('dossieracademique', ['dossierAcademiqueSelect'])
    },
    methods: {
        ...mapActions('document', ['storeDocument', 'deleteDocument', 'getDocument', 'rejeteDocument', 'storeDocumentAdm', 'deleteDocumentAdm', 'getDocumentAdm', 'updateDocumentAdm']),
        ...mapMutations('document', ['SET_FILENAME', 'SET_FILE', 'SET_DOCUMENT_TYPE']),
        ...mapActions('dossieracademique', ['getDossierAcademique']),
        ...mapActions('dossieradministratif', ['getDossierAdministratif'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon
    }
})
export default class ItemIntervenantModal extends Vue {
    @Prop() id?: string;
    @Prop() etat?: number;
    @Prop() selected?: boolean;
    @Prop() libelle?: string;
    @Prop() description?: string;
    @Prop() popupId?: string;
    @Prop() icone?: string;
    @Prop() name?: string;
    @Prop() value?: string;
    @Prop() index?: number;
    @Prop() dossier_id?: number;
    @Prop() document_type?: string;
    @Prop() item_type?: string;
    @Prop() multiple?: boolean;
    @Prop() validation?: Date;
    @Prop() readOnly?: boolean;
    @Prop() formats?: string;
    @Prop() requis?: number;
    @Prop() mode?: string;
    @Prop() dossier_type?: number;

    datas = new FormData()
    documents_filtre: Array<any> = []
    showSuccess = false
    showInputFile = true
    classListDocument = 'list-group-item-primary'
    etat_vierge = Etat.ETAT_VIERGE
    etat_rejete = Etat.ETAT_REJETE
    etat_valide = Etat.ETAT_VALIDE
    etat_depose = Etat.ETAT_DEPOSE
    hasSelectedFile = false

    @Watch('etat')
    setEtat (value: number) {
        this.$props.etat = value
        this.setAlertClass()
    }

    @Watch('documents')
    filtreDocs () {
        if (this.$props.document_type !== undefined) {
            this.filtreDocuments()
        }
    }

    @Watch('readOnly')
    resetEdition () {
        if (this.readOnly) {
            this.reset()
        }
    }

    alertStyle = 'alert-secondary'
    iconeClass = 'icone_etape_' + this.$props.etat
    variant = 'secondary'
    errors = null

    // Change l'état d'un item
    setAlertClass () {
        switch (this.$props.etat) {
            case Etat.ETAT_VIERGE:
                this.alertStyle = 'alert-secondary'
                this.variant = 'secondary'
                break
            case Etat.ETAT_DEPOSE:
                this.alertStyle = 'alert-primary'
                this.variant = 'primary'
                break
            case Etat.ETAT_VALIDE:
                this.alertStyle = 'alert-success'
                this.variant = 'success'
                break
            case Etat.ETAT_REJETE:
                this.alertStyle = 'alert-danger'
                this.variant = 'danger'
                break
        }
    }

    // Event sur la selection d'un item
    selectItem () {
        this.$emit('selectItem', this.index, !this.$props.selected)
    }

    // Event change sur l'input filename
    updateFileName (e: Event) {
        if (e.target !== null) {
            const target = e.target as HTMLTextAreaElement
            this.datas.append('name', target.value)
            this.$store.commit('document/SET_FILENAME', target.value)
        }
    }

    // Event change sur l'input file
    selectFile (e: Event) {
        if (e.target !== null) {
            const target = e.target as HTMLInputElement
            const file: File = (target.files as FileList)[0]
            this.datas.append('document', file)
        }
        this.hasSelectedFile = !!this.datas.get('document');
    }

    // Stocke le document sur le dossier sélectionné
    storeDocument (e: Event) {
        e.preventDefault()
        this.datas.append('documenttype_id', this.$props.document_type)
        this.$store.commit('document/SET_DOCUMENT_TYPE', this.$props.document_type)

        if (this.$props.dossier_type === Dossier_type.DOSSIER_ACADEMIQUE) {
            this.$store.dispatch('document/storeDocument', { dossier_id: this.$props.dossier_id, payload: this.datas }).then(() => {
                if (this.$store.state.document.error === undefined) {
                    this.datas = new FormData()
                    this.reset()
                    if (this.$props.multiple === 0 && this.documents_filtre.length >= 1) {
                        // On a un document renseigné, on enlève le formulaire
                        this.showInputFile = false
                    }
                    // On recharge le dossier pour checker les états
                    this.$store.dispatch('dossieracademique/getDossierAcademique', this.$props.dossier_id)
                }
            })
        } else if (this.$props.dossier_type === Dossier_type.DOSSIER_ADMINISTRATIF) {
            this.$store.dispatch('document/storeDocumentAdm', { dossier_id: this.$props.dossier_id, payload: this.datas }).then(() => {
                if (this.$store.state.document.error === undefined) {
                    this.datas = new FormData()
                    this.reset()
                    if (this.$props.multiple === 0 && this.documents_filtre.length >= 1) {
                        // On a un document renseigné, on enlève le formulaire
                        this.showInputFile = false
                    }
                    // On recharge le dossier pour checker les états
                    this.$store.dispatch('dossieradministratif/getDossierAdministratif', this.$props.dossier_id)
                }
            })
        }
    }

    // Consulter un document
    showDocument (doc: any) {
        this.$emit('showDocument', doc)
    }

    // Delete fichier
    deleteFile (id: number) {
        if (this.$props.dossier_type === Dossier_type.DOSSIER_ACADEMIQUE) {
            this.$store.dispatch('document/deleteDocument', { dossier_id: this.$props.dossier_id, document_id: id }).then(() => {
                this.showSuccess = true
                // On recharge le dossier pour checker les états
                this.$store.dispatch('dossieracademique/getDossierAcademique', this.$props.dossier_id)
                window.setInterval(() => {
                    this.showSuccess = false
                }, 3000)
                // Supprime le document des documents filtres
                this.showInputFile = !(this.$props.multiple === 0 && this.documents_filtre.length >= 1);
            })
        } else if (this.$props.dossier_type === Dossier_type.DOSSIER_ADMINISTRATIF) {
            this.$store.dispatch('document/deleteDocumentAdm', { dossier_id: this.$props.dossier_id, document_id: id }).then(() => {
                this.showSuccess = true
                this.$store.dispatch('dossieradministratif/getDossierAdministratif', this.$props.dossier_id)
                window.setInterval(() => {
                    this.showSuccess = false
                }, 3000)
                // Supprime le document des documents filtres
                this.showInputFile = !(this.$props.multiple === 0 && this.documents_filtre.length >= 1);
            })
        }
    }

    // Filtre les documents selon le document type
    filtreDocuments () {
        this.documents_filtre = []
        for (const i in this.$store.getters['document/documents']) {
            if (this.$store.getters['document/documents'][i].type === this.$props.document_type.toString()) {
                switch (this.$store.getters['document/documents'][i].etat) {
                    case Etat.ETAT_VIERGE:
                        this.classListDocument = 'list-group-item-secondary'
                        break
                    case Etat.ETAT_DEPOSE:
                        this.classListDocument = 'list-group-item-primary'
                        break
                    case Etat.ETAT_VALIDE:
                        this.classListDocument = 'list-group-item-success'
                        break
                    case Etat.ETAT_REJETE:
                        this.classListDocument = 'list-group-item-danger'
                        this.$store.commit('document/SET_NB_PJ_REJETE')
                        break
                }
                this.documents_filtre.push({ id: this.$store.getters['document/documents'][i].id, name: this.$store.getters['document/documents'][i].name, type: this.$store.getters['document/documents'][i].type, etat: this.$store.getters['document/documents'][i].etat, mime_type: this.$store.getters['document/documents'][i].mime_type, class: this.classListDocument })
            }
        }
        this.showInputFile = !(this.$props.multiple === 0 && this.documents_filtre.length >= 1);
    }

    // Reset formulaire
    reset () {
        const ref_file = this.$refs.file as HTMLInputElement
        const ref_filename = this.$refs.filename as HTMLInputElement
        ref_file.value = ''
        ref_filename.value = ''
        this.hasSelectedFile = false
    }

    // Récupère une icone en fonction du nom du document type
    getIconeDocumentType (name: string) {
        let icone = ''
        switch (name) {
            case 'default':
                icone = 'upload'
                break
            case 'Diplôme':
                icone = 'user-graduate'
                break
            case 'CV':
                icone = 'file-alt'
                break
            case 'Autre':
                icone = 'copy'
                break
            case 'Relevé d\'identité bancaire':
                icone = 'money-check'
                break
            case 'Carte nationale d\'identité':
                icone = 'id-card'
                break
            case 'Carte Vitale':
                icone = 'credit-card'
                break
            case 'Dernier bulletin de salaire':
                icone = 'money-check-alt'
                break
            case 'Autorisation de cumul':
                icone = 'vote-yea'
                break
        }

        return icone
    }

    created () {
        this.setAlertClass()
    }

    mounted () {
        if (this.$props.document_type !== undefined) {
            this.filtreDocuments()
        }
    }
}
