

















import { Vue, Component } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { VueEditor } from 'vue2-editor'
import AnnexeConventionEditor from '@/components/Conventions/AnnexeConventionEditor.vue'
import { Ability } from '@/types/Ability'

@Component({
    components: {
        VueEditor,
        AnnexeConventionEditor
    },
    computed: {
        ...mapGetters('centre', ['error', 'loading']),
        ...mapState('centre', ['error', 'centreSelect']),
        ...mapGetters('auth', ['is_intervenant_centre'])
    }
})
export default class AnnexeConvention extends Vue {
    // DATAS
    context = ''

    Ability = Ability

    // METHODS
    beforeMount () {
        if (!this.$store.state.centre.centreSelect.id) {
            this.$store.dispatch('centre/getCentre', { centre_id: this.$route.params.centre_id })
        }
        if (this.$store.getters['auth/can'](Ability.GCI_ANNEXE_CONVENTION_OWN_VIEW, 'App\\Models\\Centre', { id : Number(this.$route.params.centre_id) })) {
            this.context = 'readonly'
        }
        if (this.$store.getters['auth/can'](Ability.GCI_ANNEXE_CONVENTION_OWN_MANAGE, 'App\\Models\\Centre', { id : Number(this.$route.params.centre_id) })) {
            this.context = 'cdc'
        }
    }
}
