


















































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { Etat, getEtatSpec } from '@/types/DossierAdministratif'
import { formatDate, base64ToArrayBuffer } from '@/utils/helpers'
import DemandeOrdreMission from '@/components/OrdresMission/DemandeOrdreMission.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VuePdfApp from 'vue-pdf-app'

@Component({
    components: {
        DemandeOrdreMission,
        'font-awesome-icon': FontAwesomeIcon,
        VuePdfApp
    },
    computed: {
        ...mapGetters('ordremission', ['ordreMissions', 'ordreMissionSelect', 'source_pdf', 'loading']),
        ...mapState('ordremission', ['ordreMissions', 'ordreMissionSelect', 'source_pdf']),
        ...mapState('dossieradministratif', ['dossiersAdministratifs', 'contrats'])
    },
    methods: {
        ...mapMutations('dossieradministratif', ['SET_CONTRATS'])
    }
})
export default class OrdresMissionIntervenant extends Vue {
    @Watch('dossiersAdministratifs')
    setClass () {
        if (this.$store.state.dossieradministratif.dossiersAdministratifs.length !== 0) {
            this.createCollectionContrats()
        }
    }

    @Watch('ordreMissionSelect')
    setSourceDoc () {
        if (this.$store.state.ordremission.ordreMissionSelect.etat === Etat.ETAT_VALIDE) {
            const document_ordre_mission = this.$store.state.ordremission.ordreMissionSelect.documents.filter((d: any) => d.name === 'Ordre de mission')
            if (document_ordre_mission[0]) {
                this.$store.dispatch('ordremission/getDocumentOrdreMission', { ordremission_id: this.$store.state.ordremission.ordreMissionSelect.id, document_id: document_ordre_mission[0].id }).then(() => {
                    this.source_doc = base64ToArrayBuffer(this.$store.state.ordremission.source_pdf)
                    this.showConsultOrdreMission = true
                })
            }
        }
    }

    // DATAS
    getEtatSpec = getEtatSpec
    Etat = Etat
    showConsultOrdreMission = false
    showConsultCommentaireRejet = false
    showDemandeOrdreMission = false
    ordremissionSelect = null
    source_doc: any = ''
    config = {
        toolbar: {
            toolbarViewerRight: { presentationMode: false, openFile: false, viewBookmark: false, secondaryToolbarToggle: false }
        }
    }

    // METHODS

    // formatte les dates
    formmated_date (date: any) {
        return formatDate(date)
    }

    // Retourne une description selon etat de l'ordre de mission
    getDescription (ordremission: any) {
        let description = ''
        switch (ordremission.etat) {
            case Etat.ETAT_DEPOSE:
                description = 'En attente de traitement'
                break
            case Etat.ETAT_VALIDE:
                description = 'Demande acceptée le ' + formatDate(ordremission.validated_at)
                break
            case Etat.ETAT_REJETE:
                description = 'Demande refusée.'
                break
            case Etat.ETAT_VIERGE:
                description = 'Demande rejetée.'
                break
        }

        return description
    }

    // Affiche l'ordre de mission en viewer
    consultOrdreMission (ordremission: any) {
        this.$store.dispatch('ordremission/getOrdreMission', { ordremission_id: ordremission.id })
    }

    // Ferme la consultation d'un ordre de mission
    closeConsultationOrdreMission () {
        this.showConsultOrdreMission = false
    }

    // Affiche le commentaire de rejet d'un ordre de mission
    consultCommentaireRejet (ordremission: any) {
        this.$store.dispatch('ordremission/getOrdreMission', { ordremission_id: ordremission.id })
        this.showConsultCommentaireRejet = true
    }

    // Popup pour faire la demande d'un ordre de mission
    addOrdreMission () {
        this.showDemandeOrdreMission = true
    }

    // Popup pour editer la demande d'un ordre de mission
    editOrdreMission (ordremission: any) {
        this.showDemandeOrdreMission = true
        this.ordremissionSelect = ordremission
    }

    // Ferme la popup de demande d'ordre de mission
    closeDemandeOrdreMission () {
        this.showDemandeOrdreMission = false
        this.$store.dispatch('ordremission/getOrdreMissions')
    }

    // Filtre les contrats selon les ordres de missions dispos
    createCollectionContrats () {
        this.$store.state.dossieradministratif.contrats = []
        for (const d in this.$store.state.dossieradministratif.dossiersAdministratifs) {
            if (this.$store.state.dossieradministratif.dossiersAdministratifs[d].contrats.length !== 0) {
                const filter_contrats = this.$store.state.dossieradministratif.dossiersAdministratifs[d].contrats.filter((c: any) => c.ordre_mission === null)
                if (filter_contrats.length !== 0) {
                    this.$store.commit('dossieradministratif/SET_CONTRATS', filter_contrats)
                }
            }
        }
    }

    // Ferme la poup de commentaire de rejet
    closeCommentaireRejet () {
        this.showConsultCommentaireRejet = false
    }

    mounted () {
        if (!(this.$store.getters['auth/findParameter']('ordreMissionDisabled')?.value !== '1')) {
            this.$router.go(-1)
        }
        if (this.$store.state.ordremission.ordreMissions.length === 0) {
            this.$store.dispatch('ordremission/getOrdreMissions')
        }
        if (this.$store.state.dossieradministratif.dossiersAdministratifs.length === 0) {
            this.$store.dispatch('dossieradministratif/getDossiersAdministratifs')
        } else {
            // Tri des contrats
            this.createCollectionContrats()
        }
    }
}
