export enum Dossier_type {
    DOSSIER_ACADEMIQUE = 1,
    DOSSIER_ADMINISTRATIF = 2,
}

export interface DocumentTypeInterface {
    id: number | 0,
    etat: number | 0,
    name: string | '',
    type: string | '',
    dossier_type: number | 0,
    mime_type: string | ''
}
