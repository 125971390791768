



























import { Vue, Component, Prop }   from 'vue-property-decorator'
import { FontAwesomeIcon }  from '@fortawesome/vue-fontawesome'


@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon
    }
})
export default class Marqueur extends Vue
{
    @Prop() show?: boolean
    @Prop() reclamation_id?: number
    @Prop() marqueur_select?: string
    @Prop() avancement?: any

    marqueur_selectionne = '0'

    /**
     * Save le marqueur sur une réclamation
     */
    saveMark () {
        const params = {
            reclamation_id: this.$props.reclamation_id,
            type: 'ECRIT',
            payload: {
                marqueur: this.marqueur_selectionne,
                submit: this.$props.avancement
            }
        }
        this.$store.dispatch('reclamation/updateReclamation', params).then(() => {
            this.$emit('close', true)
        })
    }

    /**
     * Annule la modification du marqueur sur une réclamation
     */
    closeModalMark () {
        this.$emit('close', false)
    }

    mounted () {
        this.marqueur_selectionne = this.$props.marqueur_select
    }
}
