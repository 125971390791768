


























import { Vue, Component } from 'vue-property-decorator'
import BigCard from '@/components/dashboardComp/DashboardCard.vue'
import { mapGetters, mapState } from 'vuex'
import ModuleIntervenants from './Modules/ModuleIntervenants.vue'
import { functionsForDashboard } from '@/types/Dashboard'
import { Ability } from '@/types/Ability'
import { Etat } from '@/types/DossierAcademique'

@Component({
    computed: {
        onlyExt(): boolean {
            // Sert à afficher correctement le dashboard quand un utilisateur n'a seulement que les droits externes
            const user = this.$store.getters['auth/authUser']
            if (user) {
                const abilities = [
                    ...new Set(user?.abilities.map((ability: any) => ability.name))
                ]

                if (abilities.length > 3) {
                    return false
                }

                if (this.$store.getters['auth/can'](Ability.EXT_RESULTS_VIEW)) {
                    return true
                }
            }

            return false
        },
        ...mapGetters('auth', ['authUser', 'can', 'user_session_id', 'is_intervenant_centre']),
        ...mapGetters('user', ['dashboard']),
        ...mapState('auth', ['multi_session'])
    },
    components: {
        BigCard,
        ModuleIntervenants
    }
})
export default class Dashboard extends Vue {
    Ability = Ability
    dashboardElements!: string[]
    dashboardReady = false

    reload = false

    // choix de l'utilisateur
    userCentreSelect: any = null

    /**
     * Chargement de l'interface
     * @return {Promise<void>}
     */
    async load(): Promise<void> {
        // Init selon profil
        const can = this.$store.getters['auth/can']
        if (can(Ability.RH_ADM_DOS_OWN_MANAGE) || this.$store.getters['auth/is_intervenant_centre'] || can(Ability.RH_SPS_DOS_OWN_MANAGE)) {
            if (this.$store.getters['user/centreId'] !== null || this.$store.getters['user/conventionPublished'] !== null || this.$store.getters['user/dossieracademiqueId'] !== null || this.$store.getters['user/dossieradministratifId'] !== null || this.$store.getters['user/hasContrat'] !== null) {
                this.reload = true
            } else {
                this.$store.state.user.centreId = this.$store.state.centre.centres[0]?.id || null
                this.$store.state.user.centreTypePassation = this.$store.state.centre.centres[0]?.type_passation || null
                if (this.$store.state.centre.centres[0]) {
                    this.$store.commit('centre/SET_CENTRE_SELECT', this.$store.state.centre.centres[0])
                }

                if (this.$store.getters['auth/is_intervenant_centre']) {
                    this.$store.state.user.conventionPublished = null
                    if (this.$store.state.user.centreId && (can(Ability.GCI_CONVENTION_OWN_VIEW) || can(Ability.GC_CONVENTION_VIEW))) {
                        await this.$store.dispatch('centre/getConventions', { centre_id: this.$store.state.user.centreId })
                        this.$store.state.user.conventionPublished = this.$store.state.centre.conventions[0]?.convention_published_at || null
                    }
                }

                if (can(Ability.RH_ADM_DOS_OWN_MANAGE) && !this.$store.getters['dossieradministratif/loading']) {
                    this.$store.state.user.dossieradministratifId = null
                    await this.$store.dispatch('dossieradministratif/getDossiersAdministratifs')

                    const dossiersAdministratifs = this.$store.getters['dossieradministratif/dossiersAdministratifs']
                    this.$store.state.user.dossieradministratifId = (dossiersAdministratifs[0]?.etat || 0) === Etat.ETAT_INVISIBLE ? null : dossiersAdministratifs[0].id
                    if (dossiersAdministratifs[0]?.contrats?.length > 0) {
                        this.$store.commit('dossieradministratif/SET_CONTRATS', dossiersAdministratifs[0].contrats)
                        this.$store.state.user.hasContrat = true
                    }
                }
            }
        } else {
            await this.$store.dispatch('user/getDashboard', null) // Pour forcer la récup des données à jour sinon les compteurs ne sont pas MAJ sur un simple suivi du lien vers le Tableau de bord.
            this.dashboardElements = functionsForDashboard.dashboardFiltered(functionsForDashboard.dashboardElements)
            this.dashboardReady = true
        }
    }

    /**
     * Montage du composant
     * @return {void}
     */
    mounted(): void {
        this.dashboardReady = false
        if (this.$store.state.auth.multi_session) {
            this.reload = true
        } else {
            this.load()
        }
    }
}
