import store from '@/store'

export interface StateImpressionExaminateurInterface {
    tableauImpressionsExaminateur: any,
    loadingRecherche: boolean,
    loadingPdf: boolean,
    pdfView: any,
    meta: any,
    error: any,
    totalRows: number,
    currentPage: number,
    lastPage: number,
    total: number
}

export interface StateImpressionCentreInterface {
    tableauImpressionCentre: any,
    loadingRecherche: boolean,
    loadingPdf: boolean,
    pdfView: any,
    meta: any,
    error: any,
    totalRows: number,
    currentPage: number,
    lastPage: number,
    total: number
}

export interface StateImpressionCandidatInterface {
    tableauImpressionsCandidat: any,
    loadingRecherche: boolean,
    loadingPdf: boolean,
    pdfView: any,
    meta: any,
    error: any,
    totalRows: number,
    currentPage: number,
    lastPage: number,
    total: number
}

export interface RechercheOngletImpressionCandidat {
    candidat_code: string,
    candidat_name: string,
    candidat_first_name: string,
    centre_id: any,
    serie_id: any,
    concour_id: string,
    modified: any,
    modified_start: any,
    printed: boolean | null | any,
    printed_start: any,
    pdf?: number | undefined, // 1 si demande apercu au back
    page?: number | undefined, // pagination
    format?: string | undefined, // format du pdf 'b64'
    fiche_notation: number,
    fiche_mesure: number,
    amenagementsTypeOral?: number, // utilisé pour la modale du pdf afin d afficher la checkbox ajout des mesures oral
    user_id?: any // examinateur id
}

export enum printed {
    TOUTES_LES_FEUILLES = '',
    FEUILLES_NON_IMPRIMEES = 0,
    FEUILLES_IMPRIMEES_ENTRE = 1,
}

export enum modified {
    TOUTES_LES_SEANCES = '',
    SEANCES_MODIFIEES = 1,
    SEANCES_MODIFIEES_A_PARTIR = 2,
}

export enum FournitureType {
    TYPE_FPO = 'FPO', // feuille de pointage oral
    TYPE_FNO = 'FNO', // feuille de notation oral
    TYPE_FMO = 'FMO', // feuille mesure oral
    TYPE_EE = 'EE',
    TYPE_EC = 'EC',
}

// utilisé pour les tableaux
export enum FournitureTypeLongName {
    FPO = 'Pointage',
    FNO = 'Notation',
    FMO = 'Mesures',
    EE = 'Étiquette examinateur',
    EC = 'Étiquette centre',
}

// nom des checkbox
export enum FournitureTypeCheckboxName {
    FPO = 'Feuilles de pointage',
    FNO = 'Fiches de notation',
    FMO = "Feuilles de mesure d'oral",
    EE = 'Étiquettes examinateur',
    EC = 'Étiquettes centre',
}

export enum FournitureTypeCentreOnglet {
    EE = 'FPO', // feuille de pointage oral
    EC = 'FNO', // feuille de notation oral
    PO = 'FMO', // feuille mesure oral
}

/** cherche la correspondance des noms des type pour renvoyer le long name  */
export function typeLongName(typeShortName: any) {
    let longTypeName = ' - '
    switch (true) {
        case typeShortName === FournitureType.TYPE_FPO:
            longTypeName = FournitureTypeLongName.FPO
            break
        case typeShortName === FournitureType.TYPE_FNO:
            longTypeName = FournitureTypeLongName.FNO
            break
        case typeShortName === FournitureType.TYPE_FMO:
            longTypeName = FournitureTypeLongName.FMO
            break
        case typeShortName === FournitureType.TYPE_EE:
            longTypeName = FournitureTypeLongName.EE
            break
        case typeShortName === FournitureType.TYPE_EC:
            longTypeName = FournitureTypeLongName.EC
            break
        default:
            break
    }
    return longTypeName
}

/** verifie que la date de validated de la derniere modif > que la date de validation des series  */
export function lastUpdateAfterValidatedSerieDate(date_derniere_modif: string, serie_id: number) {
    // Recup de toutes les series
    const series: any = store.getters['serie/series']
    let isModifiedAfterValidatedSerie = false
    // trouve la bonne serie
    const serie: any = series.filter((serie: { id: number }) => serie.id === serie_id)[0]
    // tranforme les string en date
    const date_derniere_modif_converted_to_date = new Date(date_derniere_modif)
    const date_serie_validated_at = new Date(serie.validated_at)

    // si les deux valeurs existent return true si derniere_modif >= date validation series
    if (date_derniere_modif_converted_to_date && date_serie_validated_at) {
        if (date_derniere_modif_converted_to_date >= date_serie_validated_at) {
            isModifiedAfterValidatedSerie = true
        }
    }
    return isModifiedAfterValidatedSerie
}

/* Renvoois la date du jour mais a 00:00 */
export function createMidnightDateNow() {
    const today = new Date()
    const dd = String(today.getDate()).padStart(2, '0')
    const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
    const yyyy = today.getFullYear()
    const dateMidnight = yyyy + '-' + mm + '-' + dd + 'T' + '00:00'
    return dateMidnight
}

/* Renvoois la date du jour mais a 23:59 */
export function createLastSecondDateNow() {
    const today = new Date()
    const dd = String(today.getDate()).padStart(2, '0')
    const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
    const yyyy = today.getFullYear()
    const dateMidnight = yyyy + '-' + mm + '-' + dd + 'T' + '23:59'
    return dateMidnight
}

export interface FournitureInterface {
    name: string,
    code: string,
    id: number | 0,
    session_id: number | 0,
    unite_oeuvre: string,
    nb_par_candidat: number | 0,
    nb_par_unite_oeuvre: number | 0
}

export interface StateFournitures {
    fournitures: Array<FournitureInterface> | [],
    meta: Array<string> | null,
    links: Array<string> | null,
    loading: boolean | false,
    error: Array<string> | null,
    totalRows: number | 0,
    fournitureSelect: FournitureInterface | {}
}

