




























import { mapGetters, mapState } from 'vuex'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { isObject } from '@/utils/helpers'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { PosteContext, PosteType } from '@/types/Poste'

@Component({
    computed: {
        ...mapState('centre', ['centre_users_select', 'error', 'centreSelect']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapState('organisationecrit', ['has_compteurs_incorrect']),
        ...mapGetters('organisationecrit', ['has_salle_isolee', 'has_salle_amenagee', 'has_besoins_centre'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay
    }
})

export default class PopupSubmitSalles extends Vue {
    @Prop() showPopup?: boolean
    @Prop() invalide?: boolean

    show = false
    message_rejet = ''
    messageErreurSimple = ''
    dicoPostes: Array<any> = []

    @Watch('showPopup')
    initPopup () {
        if (this.$store.state.centre.centreSelect && this.$store.state.centre.centreSelect.besoins && this.$store.state.centre.centreSelect.besoins.length === 0) {
            this.$store.dispatch('poste/getPostes', { type: PosteType.TYPE_LOGISTIQUE, context: (PosteContext.CONTEXT_AFFECTATION + PosteContext.CONTEXT_CHEF_CENTRE) }).then(() => {
                this.dicoPostes = this.$store.getters['poste/posteByRegroupement']
                this.initBesoinsIntervenants()
            })
        } else {
            this.show = this.$props.showPopup
        }
    }

    /** Initialise les besoins intervenants */
    initBesoinsIntervenants () {
        // Initialisation des besoins par postes
        if ((this.$store.state.centre.centreSelect.besoins === null || (this.$store.state.centre.centreSelect.besoins && this.$store.state.centre.centreSelect.besoins.length === 0))) {
            this.$store.commit('organisationecrit/SET_BESOINS_CENTRE', [])
            for (const g in this.dicoPostes) {
                for (const p in this.dicoPostes[g]) {
                    if (this.dicoPostes[g][p].context === PosteContext.CONTEXT_CHEF_CENTRE) {
                        this.$store.commit('organisationecrit/ADD_LIGNE_BESOINS_CENTRE', { poste_id: this.dicoPostes[g][p].id, nb: 1, regroupement: this.dicoPostes[g][p].regroupement })
                    } else {
                        this.$store.commit('organisationecrit/ADD_LIGNE_BESOINS_CENTRE', { poste_id: this.dicoPostes[g][p].id, nb: 0, regroupement: this.dicoPostes[g][p].regroupement })
                    }
                }
            }
            this.$store.state.centre.centreSelect.besoins = this.$store.state.organisationecrit.besoins_centre
            this.show = this.$props.showPopup
        }
    }

    /** Check le type du retour d'erreur */
    isObject () {
        return isObject(this.$store.state.centre.error)
    }

    /** Annulation de la confirmation de la soumission de l'écrit */
    cancelSoumettre () {
        this.show = false
        this.$emit('reset')
    }

    /** Confirmation de la soumission de l'écrit */
    confirmerSoumettre () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('organisationecrit/manageOrganisationEcrit', { params: { mode: 'salles', centre_id: this.$store.getters['centre/centreSelect'].id, salles_submit: true, salles_validate: null } })
            .then(async () => {
                await this.$store.dispatch('centre/getCentre', { centre_id: this.$store.getters['centre/centreSelect'].id })
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('La liste des salles a été soumise !', succesToaster)
                this.show = false
                this.$emit('reset')
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }
}
