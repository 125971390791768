




























































































































































































































































































import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { BModal } from 'bootstrap-vue'
import _, { isEmpty } from 'lodash'
import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import Select from '../../components/BourseEmploi/Select.vue'
import { Ability, getGroupes } from '../../types/Ability'
import { WorkingEstablishmentTypeInterface } from '../../types/WorkingEstablishmentType'
import { formatDateVariante } from '../../utils/helpers'

@Component({
    name: 'TypesEtablissementView',
    computed: {
        ...mapGetters('workingEstablishmentType', ['meta']),
        ...mapGetters('auth', ['can'])
    },
    components: {
        FontAwesomeIcon,
        ExaGenericTable,
        BModal,
        Select
    },
    watch: {
        '$store.state.workingEstablishmentType.workingEstablishmentTypes': {
            handler: 'setGenericTableData',
            deep: true
        }
    }
})

export default class TypesEtablissement extends Vue {
    isEmpty = isEmpty
    Ability = Ability
    formatDateVariante = formatDateVariante
    genericfields = [] as any
    dataForTab = [] as Array<any>
    filtres = [] as any
    workingEstablishmentType = null as null | WorkingEstablishmentTypeInterface
    showEditWorkingEstablishmentType = false
    showCreateWorkingEstablishmentType = false
    showDeleteWorkingEstablishmentType = false
    showGestionRoles = false
    processing = false as boolean
    showFormulaireRole = false as boolean
    showConfirmDeleteRole = false as boolean
    load_role = false as boolean
    abilitiesList = [] as any
    groupes = [] as any
    rolesEst = [] as any
    rolesSelected = [] as any
    workingEstablishmentTypeParentsList = [] as Array<WorkingEstablishmentTypeInterface>
    params = null as any
    role_select = {
        id: 0,
        name: '',
        abilities_name: []
    } as any

    /**
     * @description Initialisation des colonnes et filtres du tableau
     * @returns {void}
     */
    setupGenericTable(): void {
        this.genericfields = [
            { key: 'edit', label: '', sortable: false, class: '', type: 'action' },
            { key: 'code', label: 'Code', sortable: true, class: '', type: 'text' },
            { key: 'name', label: 'Nom', sortable: true, class: '', type: 'text' },
            { key: 'roles', label: 'Rôle(s)', sortable: true, class: '', type: 'text' },
            { key: 'parent', label: 'Parent', sortable: true, class: '', type: 'text' },
            { key: 'job_description_creation_possible', label: 'Fiches de poste', sortable: true, class: 'text-center', type: 'text' },
            { key: 'workingEstablishments', label: 'Établissement(s)', sortable: false, class: 'text-center', type: 'action' },
            { key: 'delete', label: '', sortable: false, class: '', type: 'action' }
        ]

        if (!this.$store.getters['auth/can'](Ability.ADM_ESTABLISHMENT_MANAGE)) {
            this.genericfields.splice(7, 1)
            this.genericfields.splice(0, 1)
        }

        const optionsFichesPoste = [
            { index: 0, name: 'Non' },
            { index: 1, name: 'Oui' }
        ]

        this.filtres = [
            { libelle: 'Code', defautOptionlibelle: 'Rechercher un', model: 'code', value: '', index: 'code', datas: null, loading: this.$store.getters['workingEstablishmentType/loading'], options: { type: 'form', fieldsKey: 'code' } },
            { libelle: 'Nom', defautOptionlibelle: 'Rechercher un', model: 'name', value: '', index: 'name', datas: null, loading: this.$store.getters['workingEstablishmentType/loading'], options: { type: 'form', fieldsKey: 'name' } },
            { libelle: 'Fiches de poste', defautOptionlibelle: 'Rechercher par', model: 'job_description_creation_possible', value: '', index: 'job_description_creation_possible', datas: optionsFichesPoste, loading: this.$store.getters['workingEstablishmentType/loading'], options: { type: 'deroulant', fieldsKey: 'job_description_creation_possible' } }
        ]
    }

    /**
     * @description Remplissage du tableau des établissements
     * @param {any} poData - Données à afficher
     * @param {boolean} isLoadMore - Indique si on charge plus de données
     * @returns {void}
     */
    setGenericTableData(): void {
        const list = []
        const workingEstablishmentTypes = this.$store.getters['workingEstablishmentType/workingEstablishmentTypes'] as Array<WorkingEstablishmentTypeInterface>

        if (!isEmpty(workingEstablishmentTypes)) {
            for (const data of workingEstablishmentTypes) {
                const roles: string = data.roles.map((role: any) => `<p class='m-0'>${ role }</p>`).join('') || '-'
                const fichePosteIcon = [{
                    name: 'circle',
                    class: `text-${data.job_description_creation_possible ? 'success' : 'secondary'}`,
                    title: data.job_description_creation_possible ? 'Oui' : 'Non'
                }]
                const row: any = [
                    { label: 'Éditer', item: data, type: 'action', typeAction: 'edit', class: 'commons_first_action_button', icon: 'pen', disabled: false },
                    { label: '', item: data.code, type: 'text', typeAction: null, class: '' },
                    { label: '', item: data.name, type: 'text', typeAction: null, class: '' },
                    { label: '', item: roles, type: 'html', typeAction: null, class: '' },
                    { label: '', item: data.workingEstablishmentTypeParent?.name || '-', type: 'html', typeAction: null, class: '' },
                    { label: '', item: fichePosteIcon, type: 'icons', typeAction: null, class: 'text-center' },
                    { label: '', item: data.workingEstablishments?.length || '-', type: 'text', typeAction: null, class: 'text-center' },
                    {
                        label: data.workingEstablishmentTypeChildren?.length ? 'Ce type d\'établissement ne peut pas être supprimé car il possède au moins un type d\'établissement enfant' : data?.workingEstablishments?.length ? 'Ce type d\'établissement ne peut pas être supprimé car il possède au moins un établissement' : 'Supprimer',
                        item: data,
                        type: 'action',
                        typeAction: data.workingEstablishmentTypeChildren?.length ? null : 'delete',
                        class: `text-secondary text-end ${data.workingEstablishmentTypeChildren?.length || data?.workingEstablishments?.length ? 'action-disabled' : ''}`,
                        icon: 'trash-alt',
                        disabled: false
                    }
                ]

                if (!this.$store.getters['auth/can'](Ability.ADM_ESTABLISHMENT_MANAGE)) {
                    row.splice(7, 1)
                    row.splice(0, 1)
                }

                list.push(row)
            }
        }

        this.dataForTab = list || []
    }

    /**
     * @description Gestion des événements du tableau
     * @param {any} paParams - Paramètres de l'événement
     * @returns {Promise<void>}
     */
    async handleTableEvent(paParams: any): Promise<void> {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'edit':
                    this.openEditWorkingEstablishmentType(paParams[1])
                    break

                case 'onLoadPage':
                    this.params = paParams[1]
                    await this.$store.dispatch('workingEstablishmentType/getMoreWorkingEstablishmentTypes', this.params)
                    break

                case 'sortHandler':
                case 'filterHandler':
                    this.params = paParams[1]
                    await this.$store.dispatch('workingEstablishmentType/getWorkingEstablishmentTypes', this.params)
                    break

                case 'delete':
                    this.openDeleteWorkingEstablishmentType(paParams[1])
                    break
            }
        }
    }

    /**
     * @description Ouverture de la popup d'édition d'un type d'établissement
     * @param {WorkingEstablishmentTypeInterface} workingEstablishmentType - Type d'établissement
     * @returns {void}
     */
    openEditWorkingEstablishmentType(workingEstablishmentType: WorkingEstablishmentTypeInterface): void {
        this.processing = false
        this.workingEstablishmentType = Object.assign({}, workingEstablishmentType) as WorkingEstablishmentTypeInterface
        this.workingEstablishmentType.roles = this.rolesEst.filter((role: any) => workingEstablishmentType.roles.includes(role.name))
        this.workingEstablishmentType.job_description_creation_possible = workingEstablishmentType.job_description_creation_possible || 0
        this.workingEstablishmentTypeParentsList = this.$store.getters['workingEstablishmentType/workingEstablishmentTypes']
            .filter((w: WorkingEstablishmentTypeInterface) => {
                if (this.workingEstablishmentType?.workingEstablishmentTypeChildren) {
                    const childrens = this.workingEstablishmentType.workingEstablishmentTypeChildren
                        .map((w: WorkingEstablishmentTypeInterface) => w.id)
                    return w.id !== workingEstablishmentType.id && !childrens.includes(w.id)
                }
                return w.id !== workingEstablishmentType.id
            })
            .map((w: WorkingEstablishmentTypeInterface) => ({
                code: w.code,
                id: w.id,
                job_description_creation_possible: w.job_description_creation_possible,
                name: w.name,
                roles: w.roles,
                session_id: w.session_id,
                working_establishment_type_id: w.working_establishment_type_id
            }))
        this.workingEstablishmentTypeParentsList = _.orderBy(this.workingEstablishmentTypeParentsList, 'name', 'asc')
        this.rolesSelected = this.workingEstablishmentType.roles
        this.showFormulaireRole = false
        this.showEditWorkingEstablishmentType = true
    }

    /**
     * @description Modification d'un type d'établissement
     * @returns {Promise<void>}
     */
    editWorkingEstablishmentType(): void {
        if (this.processing || !this.workingEstablishmentType) {
            return
        }
        this.processing = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Modification en cours ...', infosToaster)

        const payload: any = {
            ...this.workingEstablishmentType,
            working_establishment_type_id: this.workingEstablishmentType?.workingEstablishmentTypeParent?.id || null,
            session_id: this.$store.getters['auth/user_session_id'],
            roles: this.rolesSelected.map((role: any) => role.name)
        }

        this.$store.dispatch('workingEstablishmentType/editWorkingEstablishmentType', payload)
            .then(async () => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Modification terminée !', succesToaster)
                await this.$store.dispatch('workingEstablishmentType/getWorkingEstablishmentTypes', this.params)
                this.closeEditWorkingEstablishmentType()
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.processing = false
            })
    }

    /**
     * @description Fermeture de la popup d'édition d'un type d'établissement
     * @returns {void}
     */
    closeEditWorkingEstablishmentType(): void {
        this.showEditWorkingEstablishmentType = false
        this.processing = false
        this.showFormulaireRole = false
        this.showConfirmDeleteRole = false
        this.load_role = false
        this.abilitiesList = []
        this.groupes = []
        this.rolesSelected = []
        this.workingEstablishmentTypeParentsList = []
        this.role_select = {
            id: 0,
            name: '',
            abilities_name: []
        }
    }

    /**
     * @description Ouverture de la popup de création d'un type d'établissement
     * @returns {void}
     */
    openCreateWorkingEstablishmentType(): void {
        this.processing = false
        this.rolesSelected = []
        this.workingEstablishmentTypeParentsList = _.orderBy(this.$store.getters['workingEstablishmentType/workingEstablishmentTypes'], 'name', 'asc')
        this.workingEstablishmentType = {
            code: '',
            name: '',
            roles: [],
            job_description_creation_possible: 0,
            workingEstablishmentTypeChildren: [],
            workingEstablishmentTypeParent: null,
            working_establishment_type_id: null,
            workingEstablishments: []
        }
        this.showCreateWorkingEstablishmentType = true
    }

    /**
     * @description Création d'un type d'établissement
     * @returns {Promise<void>}
     */
    createWorkingEstablishmentType(): void {
        if (this.processing || !this.workingEstablishmentType) {
            return
        }
        this.processing = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Création en cours ...', infosToaster)

        this.$store.dispatch('workingEstablishmentType/createWorkingEstablishmentType', {
            session_id: this.$store.getters['auth/user_session_id'],
            code: this.workingEstablishmentType.code,
            name: this.workingEstablishmentType.name,
            roles: this.rolesSelected.map((role: any) => role.name)
        })
            .then(async () => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Création terminée !', succesToaster)
                this.closeCreateWorkingEstablishmentType()
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.processing = false
            })
    }


    /**
     * @description Fermeture de la popup de création d'un type d'établissement
     * @returns {void}
     */
    closeCreateWorkingEstablishmentType(): void {
        this.showCreateWorkingEstablishmentType = false
        this.processing = false
        this.showFormulaireRole = false
        this.showConfirmDeleteRole = false
        this.load_role = false
        this.abilitiesList = []
        this.groupes = []
        this.rolesSelected = []
        this.workingEstablishmentTypeParentsList = []
        this.role_select = {
            id: 0,
            name: '',
            abilities_name: []
        }
    }

    /**
     * @description Ouverture de la popup de suppression d'un type d'établissement
     * @param {WorkingEstablishmentTypeInterface} workingEstablishmentType - Type d'établissement
     * @returns {void}
     */
    openDeleteWorkingEstablishmentType(workingEstablishmentType: WorkingEstablishmentTypeInterface): void {
        this.processing = false
        this.workingEstablishmentType = workingEstablishmentType
        this.showDeleteWorkingEstablishmentType = true
    }

    /**
     * @description Fermeture de la popup de suppression d'un type d'établissement
     * @returns {void}
     */
    closeDeleteWorkingEstablishmentType(): void {
        this.showDeleteWorkingEstablishmentType = false
        this.workingEstablishmentType = null
        this.processing = false
    }

    /**
     * @description Suppression d'un type d'établissement
     * @returns {void}
     */
    confirmDeleteWorkingEstablishmentType(): void {
        if (this.processing || !this.workingEstablishmentType) {
            return
        }
        this.processing = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Suppression en cours ...', infosToaster)

        this.$store.dispatch('workingEstablishmentType/deleteWorkingEstablishmentType', this.workingEstablishmentType.id)
            .then(async () => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Suppression terminée !', succesToaster)
                this.closeDeleteWorkingEstablishmentType()
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.processing = false
            })
    }

    // Ajoute un rôle
    addRole() {
        this.role_select = {
            id: 0,
            name: '',
            abilities_name: []
        }
        this.$store.dispatch('user/getAbilities')
            .then(() => {
                // Chargement des dépendances des abilities
                this.$store.dispatch('user/getDependencies')
                    .then(() => {
                        this.createGroupeAbilities(this.$store.state.user.abilities.filter((a: any) => a.entity_type === null))
                        this.showFormulaireRole = true
                    })
            })
    }

    // Editer un rôle
    editRole(role: any) {
        this.load_role = true
        this.$store.dispatch('user/getAbilities')
            .then(() => {
                // Chargement des dépendances des abilities
                this.$store.dispatch('user/getDependencies')
                    .then(() => {
                        this.createGroupeAbilities(this.$store.state.user.abilities.filter((a: any) => a.entity_type === null))
                        this.role_select.id = role.id
                        this.role_select.name = role.name
                        this.role_select.abilities_name = []

                        for (const a in role.abilities) {
                            this.role_select.abilities_name.push(role.abilities[a].name)
                        }

                        this.showFormulaireRole = true
                        this.load_role = false
                    })
            })
    }

    // Supprimer un rôle
    deleteRole(role: any) {
        this.showFormulaireRole = false
        this.role_select = role
        this.showConfirmDeleteRole = true
    }

    // Annule la suppression d'un rôle
    cancelDeleteRole() {
        this.role_select = {
            id: 0,
            name: '',
            abilities_name: []
        }
        this.showConfirmDeleteRole = false
    }

    // Confirmation de la suppression d'un rôle
    confirmDeleteRole() {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Suppression en cours ...', infosToaster)

        // Suppression d'un rôle
        this.$store.dispatch('user/deleteRoleEst', { role_id: this.role_select.id })
            .then(async () => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Suppression terminée !', succesToaster)

                await this.$store.dispatch('user/getRolesEst')
                await this.getRolesEst()
                this.showConfirmDeleteRole = false
            })
            .finally(() => this.$bvToast.hide(idInfo))
    }

    // Creer la collection groupe / abilities
    createGroupeAbilities(abilities: any) {
        this.abilitiesList = abilities
        this.groupes = getGroupes()
        this.groupes = this.groupes.filter((g: any) => g.prefixe === 'est')

        for (const a in this.abilitiesList) {
            let split_ability = this.abilitiesList[a].name.split('.')
            let split_name = null

            if (split_ability[0] === 'est') {
                if (!split_ability[1]) {
                    split_name = split_ability[0].split('-')
                } else {
                    split_name = split_ability[1].split('-')
                }

                if (split_ability[0].indexOf('-') !== -1) {
                    split_ability = split_ability[0].split('-')
                }

                let groupe_select = this.groupes.find((g: any) => g.prefixe === split_ability[0])

                if (split_ability[1].indexOf('own') !== -1 && split_ability[0] !== 'est') {
                    // On sélectionne le groupe autre permissions
                    groupe_select = this.groupes.find((g: any) => g.prefixe === 'own')
                }

                if (groupe_select) {
                    if (!groupe_select.abilities[split_name[split_name.indexOf('own') -1]]) {
                        groupe_select.abilities[split_name[split_name.indexOf('own') -1]] = []
                    }

                    groupe_select.abilities[split_name[split_name.indexOf('own') -1]].push(this.abilitiesList[a])
                    groupe_select.abilities[split_name[split_name.indexOf('own') -1]] = _.orderBy(groupe_select.abilities[split_name[split_name.indexOf('own') -1]], 'name', 'desc')
                }
            }
        }
    }

    // Set les value depuis le formulaire
    updateChamps(e: any) {
        switch (e.target.name) {
            case 'name':
                this.role_select.name = e.target.value
                break
        }
    }

    // Coche toutes les abilities d'un groupe
    checkAll(groupe: any) {
        for (const a in groupe.abilities) {
            for (const ab in groupe.abilities[a]) {
                this.role_select.abilities_name.push(groupe.abilities[a][ab].name)
            }
        }
    }

    /** renvois le nom de la classe css afin de changer l'ordre d'affichage  */
    reorderAbility(nameOfAbility: any) {
        let cssClass = 'ability_name_view'
        if (nameOfAbility.indexOf('view') !== -1) {
            cssClass = 'ability_name_view'
        } else if (nameOfAbility.indexOf('manage') !== -1) {
            cssClass = 'ability_name_manage'
        } else if (nameOfAbility.indexOf('sign') !== -1) {
            cssClass = 'ability_name_sign'
        }
        return cssClass
    }

    // Décoche toutes les abilities d'un groupe
    uncheckAll(groupe: any) {
        // Isole les abilities n'étant pas du groupe sélectionné
        this.role_select.abilities_name = this.role_select.abilities_name
            .filter((e: any) => e.indexOf(groupe.prefixe) === -1)
    }

    // Check les ability et dependences
    isDisabled(ability: any) {
        if (this.$store.getters['auth/can'](Ability.GC_CENTER_MANAGE)) {
            // Cherche si l'ability à des dépendences
            const dep = this.$store.state.user.dependencies[ability.name]
            if (dep?.length) {
                // Check si la dépendence est déjà cochée
                if (this.role_select.abilities_name.find((a: any) => a === dep[0])) {
                    return { disabled: false, title: '' }
                } else {
                    // Dépendence non cochée -> coche disabled
                    // Si l'ability était cochée, on la décoche
                    const index = this.role_select.abilities_name.findIndex((e: any) => e === ability.name)
                    if (index !== -1) {
                        this.role_select.abilities_name.splice(index, 1)
                    }
                    const title_dep = this.$store.state.user.abilities.find((e: any) => e.name === dep[0])
                    let prefixe_dep = ''
                    if (title_dep.name.indexOf('view') !== -1) {
                        prefixe_dep = 'Consultation'
                    } else if (title_dep.name.indexOf('manage') !== -1) {
                        prefixe_dep = 'Gestion'
                    } else if (title_dep.name.indexOf('sign') !== -1 && title_dep.name.indexOf('signature') === -1) {
                        prefixe_dep = 'Signature'
                    }

                    return { disabled: true, title: 'Nécessite la permission : ' + prefixe_dep + ' - ' + title_dep.title }
                }
            } else {
                return { disabled: false, title: '' }
            }
        } else {
            // Si l'ability était cochée, on la décoche
            const index = this.role_select.abilities_name.findIndex((e: any) => e === ability.name)
            if (index !== -1) {
                this.role_select.abilities_name.splice(index, 1)
            }

            return { disabled: true, title: '' }
        }
    }

    // Check / decheck les abilities du user
    checkAbilities(e: any) {
        const ability_name = e.target.value
        const isChecked = e.target.checked

        if (isChecked) {
            this.role_select.abilities_name.push(ability_name)
        } else {
            const index = this.role_select.abilities_name.findIndex((e: any) => e === ability_name)
            this.role_select.abilities_name.splice(index, 1)
        }
    }

    // Enregistre ou met a jour un role de centre
    saveRole() {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        const payload: any = {
            name: this.role_select.name,
            abilities: this.role_select.abilities_name
        }

        if (this.role_select.id) {
            payload.id = this.role_select.id
        }

        // Création / Mise à jour d'un role
        this.$store.dispatch(`user/${this.role_select.id === 0 ? 'add' : 'update'}RoleEst`, payload)
            .then(async () => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                await this.$store.dispatch('user/getRolesEst')
                await this.getRolesEst()
            })
            .finally(() => this.$bvToast.hide(idInfo))
    }

    // Ouvre la gestion des rôles pour les centres
    async openGestionRoles() {
        this.showFormulaireRole = false
        this.showGestionRoles = true
    }

    async getRolesEst() {
        await this.$store.dispatch('user/getRolesEst')
            .then(() => {
                this.rolesEst = []
                this.$store.getters['user/roles'].forEach((r: any) => {
                    const permissions = r.abilities.filter((a: any) => a.name.indexOf('est') !== -1)
                    if (permissions.length) {
                        this.rolesEst.push(r)
                    }
                })
            })
    }

    closeGestionRoles() {
        this.showGestionRoles = false
    }

    /**
     * @description Montage du composant
     * @returns {Promise<void>}
     */
    async mounted(): Promise<void> {
        await this.getRolesEst()
        this.setupGenericTable()
    }
}
