















































































































































































































































import { mapActions, mapGetters, mapState } from 'vuex'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { formatDayDate, isObject } from '@/utils/helpers'
import { Ability } from '@/types/Ability'
import _ from 'lodash'
import  ErrorDisplay from '@/components/ErrorDisplay.vue'
import { getTypeStatutSpec, TypeStatut } from '@/types/CandidatEpreuve'
import { CandidatStatut } from '@/types/Candidat'

@Component({
    components: {
        ErrorDisplay
    },
    computed: {
        ...mapState('decisionjury', ['loading_decisions', 'error_decisions']),
        ...mapGetters('candidatIncompatible', ['resultatRechercheParCandidat', 'get_meta_recherche_Candidat']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    },
    methods: {
        ...mapActions('postes', ['getAllPostes'])
    }
})

export default class PopupCreerDecisionJury extends Vue {
    @Prop() show?: boolean;
    @Prop() infosFiliereEpreuveSelect?: any;

    Ability                      = Ability
    CandidatStatut               = CandidatStatut
    TypeStatut                   = TypeStatut
    getTypeStatutSpec            = getTypeStatutSpec
    lockInput                    = false
    etape_selection_candidat     = true
    etape_renseigner_decision    = false
    dateNow                      = formatDayDate(new Date(Date.now()))
    options_filieres: any        = []
    options_epreuves: any        = []
    resultat: any                = []
    meta: any                    = []
    loadSearch                   = false
    firstSearch                  = false
    SelectedCandidat: any        = {} // candidat sélectionné
    infos_epreuves: any          = null
    reclamationSelectLocal: any  = {}
    etat_reclamation_select: any = {}
    activerRectifiationNote      = false
    phase_en_cours: any          = null
    show_message_confirmation    = false
    decision_exist               = false
    decision_exist_id            = 0

    // Dans le cas de la recherche pour les réclamations et démissions, la recherche est basée sur la filière et non la classe, et pas de user_id associé.
    recherche = {
        user_id: -1,
        name: '',
        first_name: '',
        etablissement_id: '*',
        classe: '*',
        filiere: '*',
        code: ''
    }

    decision: any = {
        statut_modifier: null,
        note_finale_modifiee: null,
        commentaire: '',
        epreuve_id : null
    }

    /**
     * @description Récupère les données de la recherche
     * @returns {void}
     */
    @Watch('resultatRechercheParCandidat')
    watchResultatRechercheParCandidat(): void {
        this.resultat = this.$store.getters['candidatIncompatible/resultatRechercheParCandidat']
        this.meta     = this.$store.getters['candidatIncompatible/get_meta_recherche_Candidat']
    }

    /**
     * @description Récupère les données de l'épreuve sélectionnée
     * @returns {void}
     */
    @Watch('decision.epreuve_id')
    setDatasEpreuve(): void {
        this.decision.statut_modifier = null
        this.decision.note_finale_modifiee = null

        this.infos_epreuves = this.SelectedCandidat.inscriptions.find((i: any) => i.epreuve_id === this.decision.epreuve_id)
        // Check si une décision à déjà était prise pour la combinaison candidat_id / epreuve_id
        const decision_exist = this.$store.state.decisionjury.decisions.find((d: any) => d.candidat_id === this.SelectedCandidat.id && d.epreuve_id === this.decision.epreuve_id)
        if (decision_exist !== undefined) {
            this.decision_exist = true
            this.decision_exist_id = decision_exist.id

            // Met à jour les datas selon la décision existante
            this.infos_epreuves.statut = decision_exist.statut_apres
            this.infos_epreuves.note_finale = decision_exist.note_apres
            this.infos_epreuves.note_brute = decision_exist.note_brute
        } else {
            this.decision_exist = false
            if (this.infos_epreuves.statut === CandidatStatut.ABSENT) {
                this.decision.statut_modifier = TypeStatut.STATUT_ABSENT
            } else {
                this.decision.statut_modifier = TypeStatut.STATUT_PRESENT
                this.decision.note_finale_modifiee = this.infos_epreuves.note_finale
            }
        }
    }

    /**
     * @description Récupère le nom de la filière
     * @param {number} concours_id - ID du concours
     * @returns {string}
     */
    getFiliereName(concours_id: number): string {
        if (concours_id) {
            // Récupère la phase en cours du concours du candidat
            return this.$store.getters['concour/banques'].find((b: any) => b.id === concours_id)?.name
        }
        return '-'
    }

    /**
     * @description Initialisation des filières et matières
     * @returns {void}
     */
    initfilieresMatieres(): void {
        // Options filières
        const filieres = this.$store.getters['concour/banques']
        this.options_filieres = []

        for (const f in filieres) {
            if (filieres[f].banque_id === null && filieres[f].session_id === this.$store.state.session.sessionSelect.id) {
                this.options_filieres.push({ index: filieres[f].id, name: filieres[f].name })
            }
        }
    }

    /**
     * @description Vérifie si la valeur est un objet
     * @param {any} value - Valeur à vérifier
     * @returns {boolean}
     */
    isObjectLocal(value: any): boolean {
        return isObject(value)
    }

    /**
     * @description Ordonne une liste par name
     * @param {any} liste - Liste à ordonner
     * @returns {any}
     */
    orderedList(liste: any): any {
        return _.orderBy(liste, 'name', 'asc')
    }

    /**
     * @description Recherche de candidat
     * @returns {void}
     */
    launchSearch(): void {
        this.loadSearch = true
        this.resultat = []

        /* *** Note *************************************************************************************************
            On utilise ici la base de la requête de recherche qui avait été mise en place pour les incompatibilités.
            Pour un souci de gain de temps, et parce que le retour de la requête fournit directement l'objet établissement du candidat
            dont on a besoin ici.
        ********************************************************************************************************** */
        this.$store.dispatch('candidatIncompatible/RECHERCHE_PAR_CANDIDAT', this.recherche).then(() => {
            this.loadSearch = false
            this.firstSearch = true
        })
    }

    /**
     * @description Sauvegarde du candidat sélectionné
     * @param {any} candidat - Candidat sélectionné
     * @returns {void}
     */
    setSelectedCandidat(candidat: any): void {
        this.SelectedCandidat = candidat
        const concour = this.$store.getters['concour/banques'].find((b: any) => b.id === candidat.concour_id)
        this.phase_en_cours = concour.phases.find((p: any) => p.phase_id === concour.id_phase_en_cours)
    }

    /**
     * @description Confirmation de la sélection du candidat
     * @returns {void}
     */
    confirmSelectedCandidat(): void {
        const params = { id: this.SelectedCandidat.id }
        this.$store.dispatch('candidat/getCandidat', params).then((response) => {
            // Init de la combo des épreuves liées aux inscriptions du candidat
            // Filtre les épreuves maîtresses et selon la phase
            this.SelectedCandidat.inscriptions = response.data.data.inscriptions
            this.options_epreuves = this.$store.getters['epreuve/getEpreuvePhase_with_id'](response.data.data.inscriptions, this.phase_en_cours.phase_id)
            this.etape_selection_candidat = false
            this.etape_renseigner_decision = true
        })
    }

    /**
     * @description Réinitialisation des variables locales sensibles
     * @returns {void}
     */
    reinitModale(): void {
        this.$store.commit('decisionjury/SET_ERROR', null)
        this.etape_selection_candidat = true
        this.etape_renseigner_decision = false
        this.decision = {
            note_finale_modifiee: null,
            statut_modifier: null,
            commentaire: '',
            epreuve_id: null
        }

        this.$store.commit('candidatIncompatible/RESET_SEARCH')
        this.SelectedCandidat = {}
        this.firstSearch = false
        this.recherche = {
            user_id: 0,
            name: '',
            first_name: '',
            etablissement_id: '*',
            classe: '*',
            filiere: '*',
            code: ''
        }
    }

    /**
     * @description Ferme la popup
     * @param {boolean} withSave - Sauvegarde des données
     * @returns {void}
     */
    closeModal(withSave = false): void {
        this.$emit('reinitShow', withSave)
    }

    /**
     * @description Annule l'étape de confirmation pour revenir à la saisie
     * @returns {void}
     */
    cancelConfirmation(): void {
        this.etape_renseigner_decision = true
        this.show_message_confirmation = false
        this.$store.state.decisionjury.error_decisions = null
    }

    /**
     * @description Gestion de la sauvegarde de la décision
     * @returns {void}
     */
    manageSaveDecision(): void {
        this.etape_selection_candidat  = false
        this.etape_renseigner_decision = false
        this.show_message_confirmation = true
    }

    /**
     * @description Confirmation de la validation / invalidation des affectations
     * @returns {void}
     */
    saveDecision(): void {
        this.$store.state.decisionjury.error_decisions = null

        const payload: any = {}
        Vue.set(payload, 'phase_id', this.phase_en_cours.phase_id)
        Vue.set(payload, 'candidat_id', this.SelectedCandidat.id)
        Vue.set(payload, 'epreuve_id', this.decision.epreuve_id)
        Vue.set(payload, 'statut_avant', this.infos_epreuves.statut)
        Vue.set(payload, 'statut_apres', this.decision.statut_modifier)
        Vue.set(payload, 'note_avant', this.infos_epreuves.note_finale ? this.infos_epreuves.note_finale : 0)
        Vue.set(payload, 'note_brute', this.infos_epreuves.note_brute ? this.infos_epreuves.note_brute : 0)
        Vue.set(payload, 'note_apres',this.decision.statut_modifier === CandidatStatut.ABSENT ? null : this.decision.note_finale_modifiee)
        Vue.set(payload, 'commentaire', this.decision.commentaire)

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours...', infosToaster)

        if (this.decision_exist) {
            Vue.set(payload, 'id', this.decision_exist_id)
        }
        this.$store.dispatch(`decisionjury/${this.decision_exist ? 'updateDecision' : 'addDecision'}`, payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé', succesToaster)
                this.closeModal(true)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Montage du composant
     * @returns {void}
     */
    mounted(): void {
        this.$store.commit('decisionjury/SET_ERROR', null)
        this.reinitModale()
        this.initfilieresMatieres()
    }
}

