
































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import Table from '@/components/Table.vue'
import { Ability } from '@/types/Ability'
import { codeRestrict } from '@/utils/helpers'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    components: {
        Table,
        ExaGenericTable,
        ErrorDisplay
    },
    computed: {
        ...mapGetters('domaine', ['loading', 'error', 'domaines', 'meta', 'links', 'totalRows']),
        ...mapGetters('matiere', ['loading', 'error', 'matieres', 'meta', 'links', 'totalRows']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})

export default class Matieres extends Vue {
    codeRestrict = codeRestrict
    // DATAS
    params = 'sort=name&direction=asc'
    dataForTab: Array<any> = []
    filtres: any = []


    showModalGestionDomaine = false
    showConfirmDeleteDomaine = false
    domaine_select = {
        id: 0,
        code: '',
        name: ''
    }

    showFormulaireDomaine = false


    fields = [
        {
            key: 'actionEdit',
            label: '',
            sortable: false,
            sortDirection: 'asc',
            tdClass: 'col-w-etat btn_action_ligne'
        },
        { key: 'code', label: 'Code', sortable: true, sortDirection: 'asc', class: 'text-start ps-4' },
        { key: 'name', label: 'Libellé', sortable: true, sortDirection: 'asc', class: 'text-start ps-4' },
        { key: 'domaine', label: 'Domaine', sortable: true, sortDirection: 'asc', class: 'text-start ps-4' },
        {
            key: 'delete',
            label: '',
            sortable: false,
            sortDirection: 'asc',
            tdClass: 'text-end'
        }
    ]

    genericfields = [
        { key: 'etatEdit', label: '', sortable: false, class: '', type: 'action' },
        { key: 'code', label: 'Code', sortable: true, class: 'text-left col-min-width', type: 'text' },
        { key: 'name', label: 'Libellé', sortable: true, sortDirection: 'asc', class: 'text-left', type: 'text' },
        { key: 'domaine_id', label: 'Domaine', sortable: true, sortDirection: 'asc', class: 'text-left', type: 'text' },
        { key: 'delete', label: '', sortable: false, class: '', type: 'action' }
    ]

    sortBy = ''
    sortDesc = false
    sortDirection = 'asc'
    filter = ''
    filterOn = []
    stickyHeader = true
    matiere: any = null
    matiereTemp: any = null
    Ability = Ability

    showModalEditionMatiere = false
    showModalMessageDelete = false

    data_domaines: Array<any> = []

    // METHODS

    @Watch('domaines')
    buildDataDomaines () {
        this.data_domaines = []
        for (let i = 0; i < this.$store.state.domaine.domaines.length; i++) {
            this.data_domaines.push({ index: this.$store.state.domaine.domaines[i].id, name: this.$store.state.domaine.domaines[i].name })
        }
        this.setFiltersForGenericTab()
    }

    // ---- generic table --------------------------

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setDataForGenericTab(poData: any, isLoadMore = false) {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const result of poData) {
                const classEtatEdit = 'text-light col-w-etat btn_action_ligne'
                const iconEtatEdit =  this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE) ? 'pen' : 'eye'

                const line = [
                    { label: '', item: result, icon: iconEtatEdit, type: 'action', typeAction: 'etatEdit', class: classEtatEdit, disabled: false },
                    { label: '', item: result.code, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.name, type: 'text', typeAction: null, class: '' },
                    { label: '', item: this.getLibelleDomaine(result.domaine_id), type: 'text', typeAction: null, class: '' }
                ]

                if (this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE)) {
                    const trashLine =  { label: 'Supprimer',   item: result, type: 'action',  typeAction: 'delete',  class: 'text-secondary', icon:'trash-alt', disabled: false }
                    line.push(trashLine)
                }
                this.dataForTab.push(line)
            }
        }
        return this.dataForTab
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setFiltersForGenericTab() {
        this.filtres = [
            {
                libelle: 'Code',
                defautOptionlibelle: 'Rechercher un',
                model: 'code',
                value: '',
                index: 'code',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'code', strict: true } // 'form' , 'deroulant'
            },
            {
                libelle: 'Libellé',
                defautOptionlibelle: 'Rechercher un',
                model: 'name',
                value: '',
                index: 'name',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'name' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Domaine',
                defautOptionlibelle: 'Rechercher un',
                model: 'domaine_id',
                value: '-',
                index: 'id',
                datas: this.data_domaines,
                loading: false,
                options: { type: 'deroulant', fieldsKey: 'domaine_id' } // 'form' , 'deroulant'
            }
        ]
    }

    /**
     * Récupération des events de la table
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'edit':

                    break
                case 'openComment':

                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break

                case 'etatEdit':
                    this.editMatiere(paParams[1])
                    break
                case 'delete':
                    this.deleteMatiere(paParams[1])
                    break
                default:
                    break
            }
        }
    }

    // --------------------------------------

    // ------------------gestion des domaines --------------------------------

    openGestionDomaine() {
        this.showModalGestionDomaine = true
    }

    getListeDomaine() {
        this.$store.dispatch('domaine/getDomaines').then(() => {
            this.showModalGestionDomaine = true
        }).catch((error) => {
            console.log('ko:' + error)
        })
    }

    cancelGestionDomaine () {
        this.$store.commit('domaine/SET_ERROR', null)
        this.showFormulaireDomaine = false
        this.domaine_select = {
            id: 0,
            name: '',
            code: ''
        }
        this.showModalGestionDomaine = false
        this.showConfirmDeleteDomaine = false
    }

    edit_domaine(domaine: any) {
        this.domaine_select.id = domaine.id
        this.domaine_select.code = domaine.code
        this.domaine_select.name = domaine.name
        this.showFormulaireDomaine = true
    }

    add_domaine () {
        this.domaine_select = {
            id: 0,
            code: '',
            name: ''
        }
        this.showFormulaireDomaine = true
    }

    // Set les value depuis le formulaire
    updateChamps (e: any) {
        switch (e.target.name) {
            case 'code':
                this.domaine_select.code = e.target.value
                break
            case 'name':
                this.domaine_select.name = e.target.value
                break
            default:
                break
        }
    }

    save_domaine () {
        this.$store.commit('domaine/SET_ERROR', null)
        const data: any = {
            code: this.domaine_select.code,
            name: this.domaine_select.name,
            domaine_id: this.domaine_select.id
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        if (this.domaine_select.id === 0) {
            // Création d'un role
            this.$store.dispatch('domaine/addDomaine', data)
                .then(() => {
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé !', succesToaster)

                    this.showFormulaireDomaine = false
                    this.domaine_select = {
                        id:0,
                        code: '',
                        name: ''
                    }
                    this.$store.dispatch('domaine/getDomaines')
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            this.$store.dispatch('domaine/updateDomaine', data)
                .then(() => {
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                    this.showFormulaireDomaine = false
                    this.domaine_select = {
                        id:0,
                        code: '',
                        name: ''
                    }
                    this.$store.dispatch('domaine/getDomaines')
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    delete_domaine (domaine: any) {
        this.showFormulaireDomaine = false
        this.domaine_select = domaine
        this.showConfirmDeleteDomaine = true
    }

    cancel_delete_domaine () {
        this.domaine_select = {
            id: 0,
            code: '',
            name: ''
        }
        this.showConfirmDeleteDomaine = false
    }

    confirm_delete_domaine () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Suppression en cours ...', infosToaster)

        // Suppression d'un rôle
        this.$store.dispatch('domaine/deleteDomaine', this.domaine_select.id)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Suppression terminée !', succesToaster)
                this.$store.dispatch('domaine/getDomaines').then(() => {
                    this.showConfirmDeleteDomaine = false
                })
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    getLibelleDomaine(domaine_id: number) {
        if (domaine_id !== null) {
            for (let i = 0; i < this.$store.state.domaine.domaines.length; i++) {
                if (domaine_id === this.$store.state.domaine.domaines[i].id) {
                    return this.$store.state.domaine.domaines[i].name
                }
            }
        }
    }

    // --------------------------------------------------

    openAddMatiere () {
        this.matiereTemp = {
            id: 0,
            code: '',
            name: '',
            domaine_id: null
        }
        this.showModalEditionMatiere = true
    }

    editMatiere (data: any) {
        this.matiere = data
        this.matiereTemp = {
            id: this.matiere.id,
            code: this.matiere.code,
            name: this.matiere.name,
            domaine_id:this.matiere.domaine_id
        }
        this.showModalEditionMatiere = true
    }

    cancelEdit () {
        this.matiere = null
        this.matiereTemp = null
        this.showModalEditionMatiere = false
    }

    editSuite () {
        const payload = {
            id: this.matiereTemp.id,
            code: this.matiereTemp.code,
            name: this.matiereTemp.name,
            domaine_id:this.matiereTemp.domaine_id
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        if (this.matiereTemp.id === 0) {
            this.$store.dispatch('matiere/addMatiere', payload)
                .then(() => {
                    this.matiere = null
                    this.matiereTemp = null
                    this.showModalEditionMatiere = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Matière ajoutée avec succès !', succesToaster)
                    this.$store.dispatch('matiere/getMatieres', this.params).then(() => {
                        this.setDataForGenericTab(this.$store.state.matiere.matieres)
                    }).catch((error) => {
                        console.log('ko:' + error)
                    })
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            this.$store.dispatch('matiere/updateMatiere', payload)
                .then(() => {
                    this.matiere = null
                    this.matiereTemp = null
                    this.showModalEditionMatiere = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Matière modifiée avec succès !', succesToaster)
                    this.$store.dispatch('matiere/getMatieres', this.params).then(() => {
                        this.setDataForGenericTab(this.$store.state.matiere.matieres)
                    }).catch((error) => {
                        console.log('ko:' + error)
                    })
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    deleteMatiere (data: any) {
        this.matiere = data
        this.showModalMessageDelete = true
    }

    cancelDelete () {
        this.matiere = null
        this.showModalMessageDelete = false
    }

    deleteSuite () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Supression en cours ...', infosToaster)
        this.$store.dispatch('matiere/deleteMatiere', this.matiere.id)
            .then(() => {
                this.matiere = null
                this.matiereTemp = null
                this.showModalEditionMatiere = false
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Matiere supprimé avec succès !', succesToaster)
                this.matiere = null
                this.showModalMessageDelete = false
                this.$store.dispatch('matiere/getMatieres', this.params).then(() => {
                    this.setDataForGenericTab(this.$store.state.matiere.matieres)
                }).catch((error) => {
                    console.log('ko:' + error)
                })
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    loadHandler (params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.$store.dispatch('matiere/getMoreMatieres', params).then(() => {
                this.setDataForGenericTab(this.$store.state.matiere.matieres)
            }).catch((error) => {
                console.log('ko:' + error)
            })
        }
    }

    // Appelle une page lors du scroll
    filtreSortHandler (params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.$store.dispatch('matiere/getMatieres', params).then(() => {
                this.setDataForGenericTab(this.$store.state.matiere.matieres)
            }).catch((error) => {
                console.log('ko:' + error)
            })
        }
    }

    mounted () {
        this.$store.dispatch('domaine/getDomaines').then(() => {
            this.setFiltersForGenericTab()
        }).catch((error) => {
            console.log('ko:' + error)
        })
    }
}
