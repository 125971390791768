





























import { Vue, Component } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { CandidatInterface } from '@/types/Candidat'
import { base64ToArrayBuffer, dateNow, formatStringDate, getFileNameFromHeader } from '@/utils/helpers'
import { isEmpty } from 'lodash'

/**
 * Composant relatif aux épreuves écrites d'un candidat
 */
@Component({
    components: {
        FontAwesomeIcon
    }
})
export default class PopupEditCandidatInfEcrits extends Vue {
    candidat: CandidatInterface = {} as CandidatInterface
    phase_id = 0

    /**
     * getConvocation
     * Récupère la convocation du candidat
     * @return {void}
     */
    getConvocation(): void {
        const params = {
            candidat_id: this.candidat.id,
            phase_id: this.phase_id
        }

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Relance de la publication en cours...', infosToaster)

        this.$store.dispatch('candidat/getConvocationCandidat', params)
            .then((response) => {
                const fileName = getFileNameFromHeader(response.headers) || `Candidat_convocation_${this.candidat.code}_${formatStringDate(dateNow(), 'DDMMYYYY_HHmm')}.pdf`
                const url = URL.createObjectURL(new Blob([base64ToArrayBuffer(response.data)]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * retryPublication
     * Relance de la publication des résultats
     * @returns {void}
     */
    retryPublication(): void {
        console.log(this.candidat)
        const params = {
            candidat_id: this.candidat.id,
            phase_id: this.phase_id,
            payload: {
                concour_id: this.candidat.concour_id,
                publicationName: 'planning'
            }
        }

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Relance de la publication en cours...', infosToaster)

        this.$store.dispatch('candidat/retryPublication', params)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Publication relancée !', succesToaster)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    async beforeMount () {
        await this.initDatas()
    }

    async initDatas() {
        if (this.$store.state.candidat.editedCandidat) {
            this.candidat = this.$store.state.candidat.editedCandidat

            if (isEmpty(this.$store.getters['concour/concours'])) {
                await this.$store.dispatch('concour/getConcours', {
                    perPage: 0
                })
            }
            const concours = this.$store.getters['concour/concours']
                .find((concour: any) => concour.id === this.candidat.concours[0].id)
            const phases: Array<number> = concours.phases.map((phase: any): Array<number> => phase.phase_id)
            this.phase_id = phases[0]
        }
    }
}
