









































































































import { Vue, Component, Watch }    from 'vue-property-decorator'
import { mapGetters, mapState }     from 'vuex'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'
import { Ability }                  from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { formatDate } from '@/utils/helpers'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import Back from '@/components/Tools/Back.vue'


@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapState('centrefp', ['centre_fp', 'loading_cfp', 'totalRows', 'error_cfp']),
        ...mapState('centre', ['centreSelect', 'error'])
    },
    components: {
        ExaGenericTable,
        ErrorDisplay,
        Back,
        'font-awesome-icon': FontAwesomeIcon
    }
})

export default class InventaireFp extends Vue
{
    formatDate = formatDate
    Ability = Ability
    showConfirmationSoumissionInventaire = false
    is_mobile_device = false

    genericfields: Array<any> = [
        { key: 'stock',         label: 'Stock',      sortable: false,   class: 'input_size', type: 'text' },
        { key: 'unite',         label: 'Unité',      sortable: false,   class: 'input_size', type: 'text' },
        { key: 'fourniture',    label: 'Fourniture',    sortable: false,   class: 'max_size_column', type: 'text' }

    ]

    sortDirection   = 'asc'

    dataForTab: Array<any>  = []

    params: any = {}
    exportWorking = false
    read_only = false

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    @Watch('sessionSelect')
    refreshStateInventaire () {
        this.getStateInventaire()
    }

    // Création des lignes du tableau
    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                const line: any = [
                ]

                if (this.read_only) {
                    line.push({ label: '', item: result.stock, data: result, type: 'text', typeAction: null, class: 'input_size' })
                } else {
                    line.push({ label: '', item: result.stock, data: result, type: 'inputNumber', typeAction: 'input_stock', class: 'input_size', minInput: 0 })
                }
                line.push({ label: '', item: result.fourniture_papetiere.unite_oeuvre,  type: 'text', typeAction: null, class: '' })
                line.push({ label: '', item: result.fourniture_papetiere.name,  type: 'text', typeAction: null, class: '' })

                this.dataForTab.push(line)
            }
        }
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'input_stock':
                    paParams[2].stock = paParams[1]
                    break
                default:
                    break
            }
        }
    }

    // Applique le chargement de la pagination
    loadHandler (params: any) {
        this.$store.dispatch('centrefp/getMoreCentreFournituresPapetieres', { centre_id: this.$route.params.centre_id, params: params })
    }

    // Applique les filtres
    filtreSortHandler(params: any) {
        Vue.set(params, 'centre_id', this.$route.params.centre_id)
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.$store.dispatch('centrefp/getCentreFournituresPapetieres', { centre_id: this.$route.params.centre_id, params: this.params })
        }
    }

    // Sauvegarde la saisie du détail du stock
    save_inventaire () {
        this.$store.commit('centre/SET_ERROR_FULL', null)
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours...', infosToaster)

        const payload = {
            _method: 'PUT',
            items: this.$store.state.centrefp.centre_fp
        }

        this.$store.dispatch('centrefp/saveCentreFournituresPapetieres', { centre_id:this.$route.params.centre_id, params: payload })
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                const datas = this.$store.state.centrefp.centre_fp.filter((c: any) => c.fourniture_papetiere.inventaire_centre === 1)
                this.setDataForGenericTab(datas)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Affiche le message de confirmation de la soumission de l'inventaire
    soumettre_inventaire () {
        this.showConfirmationSoumissionInventaire = true
    }

    // Retourne l'état de publication de l'inventaire
    getStateInventaire () {
        let state_inventaire = { message: '', bg_color: 'barre_default', text_color: '' }
        if (this.$store.state.session.sessionSelect) {
            if (!this.$store.state.session.sessionSelect.inventaire_fournitures_start_at && !this.$store.state.session.sessionSelect.inventaire_fournitures_end_at) {
                state_inventaire = { message: 'L\'inventaire n\'est pas accessible.', bg_color: 'barre_default', text_color: 'barre_text_defaut' }
                this.read_only = true
            } else if (!this.$store.state.session.sessionSelect.inventaire_fournitures_start_at && this.$store.state.session.sessionSelect.inventaire_fournitures_end_at) {
                state_inventaire = { message: 'L\'inventaire est accessible jusqu\'au ' + formatDate(this.$store.state.session.sessionSelect.inventaire_fournitures_end_at) + ' inclus.', bg_color: 'barre_soumis', text_color: 'barre_text_soumis' }
            } else if (this.$store.state.session.sessionSelect.inventaire_fournitures_start_at && this.$store.state.session.sessionSelect.inventaire_fournitures_end_at) {
                state_inventaire = { message: 'L\'inventaire est accessible du ' + formatDate(this.$store.state.session.sessionSelect.inventaire_fournitures_start_at) + ' au ' + formatDate(this.$store.state.session.sessionSelect.inventaire_fournitures_end_at) + ' inclus.', bg_color: 'barre_soumis', text_color: 'barre_text_soumis' }
            }
        }

        return state_inventaire
    }

    // Confirme la soumission de l'inventaire aux gestionnaires
    confirmSoumettreInventaire () {
        const payload = {
            _method: 'PUT',
            f_pap_inventaire_submit : 1
        }
        const payload_save = {
            _method: 'PUT',
            items: this.$store.state.centrefp.centre_fp
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours...', infosToaster)

        this.$store.dispatch('centrefp/saveCentreFournituresPapetieres', { centre_id:this.$route.params.centre_id, params: payload_save })
            .then(() => {
                // Save ok on submit
                this.$store.dispatch('centre/majStatutFournitures', { centre_id : this.$route.params.centre_id, payload: payload })
                    .then(() => {
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }
                        this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                        this.read_only = true
                        this.showConfirmationSoumissionInventaire = false
                        const datas = this.$store.state.centrefp.centre_fp.filter((c: any) => c.fourniture_papetiere.inventaire_centre === 1)
                        this.setDataForGenericTab(datas)
                    })
                    .finally(() => {
                        this.$bvToast.hide(idInfo)
                    })
            })
    }

    // Annule la soumission de l'inventaire
    cancelSoumettreInventaire () {
        this.showConfirmationSoumissionInventaire = false
    }

    // Retourne la couleur de barre et la couleur de texte selon état
    get_barre_default () {
        let barre_texte = {
            barre: 'barre_default',
            color: '',
            text: ''
        }

        if (this.$store.state.centre.centreSelect.f_pap_inv_validated_at) {
            barre_texte = {
                barre: 'barre_valide',
                color: 'barre_text_valide',
                text: 'L\'inventaire des fournitures papetières a été validé le ' + formatDate(this.$store.state.centre.centreSelect.f_pap_inv_validated_at)
            }
        } else if (!this.$store.state.centre.centreSelect.f_pap_inv_validated_at && this.$store.state.centre.centreSelect.f_pap_inv_submitted_at) {
            barre_texte = {
                barre: 'barre_soumis',
                color: 'barre_text_soumis',
                text: 'L\'inventaire des fournitures papetières a été soumis le ' + formatDate(this.$store.state.centre.centreSelect.f_pap_inv_submitted_at) + '. Merci de contacter le concours pour toute demande.'
            }
        }

        return barre_texte
    }

    load () {
        this.$store.dispatch('centre/getCentre', { centre_id: this.$route.params.centre_id }).then(() => {
            if (this.$store.state.centre.centreSelect.f_pap_inventaire_submitted_at !== null || this.$store.getters['auth/cannot'](Ability.GCI_FOURNITURES_OWN_MANAGE)) {
                this.read_only = true
            }

            this.$store.dispatch('centrefp/getCentreFournituresPapetieres', { centre_id: this.$route.params.centre_id, params: null }).then(() => {
                const datas = this.$store.state.centrefp.centre_fp.filter((c: any) => c.fourniture_papetiere.inventaire_centre === 1)
                this.setDataForGenericTab(datas)
            })
        })
    }

    mounted() {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        } else {
            // Renvoi au dashboard pour reselectionné la session
            this.$router.push('/dashboard_i')
        }

        // Detection si le device est un mobile
        if ('ontouchstart' in document.documentElement)
        {
            this.is_mobile_device = true
        }
        else
        {
            this.is_mobile_device = false
        }
    }
}
