


























































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { Ability } from '@/types/Ability'
import { checkIcone } from '@/utils/helpers'
import ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    components: {
        ExaGenericTable,
        ErrorDisplay
    },
    computed: {
        ...mapGetters('contrattype', ['contrattypes', 'loading', 'totalRows', 'lastPage', 'error']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id'])
    },
    methods: {
        ...mapActions('contrattype', ['getContratTypes'])
    }
})
export default class ContratsTypes extends Vue {
    @Watch('user_session_id')
    async refreshInterface () {
        await this.load()
    }

    @Watch('contrattypes')
    wContratTypes() {
        const data = this.$store.getters['contrattype/contrattypes']
        this.setDataForGenericTab(data)
    }

    Ability = Ability
    // DATAS
    genericfields = [
        {
            key: 'actionLeft',
            label: '',
            sortable: false,
            sortDirection: 'asc',
            tdClass: 'col-w-etat bg-info'
        },
        {
            key: 'name',
            label: 'Libellé',
            sortable: true,
            sortDirection: 'asc',
            class: 'text-start ps-4'
        },
        {
            key: 'delete',
            label: '',
            sortable: false
        }
    ]

    dataForTab: any = []
    filtres: any = []
    params: any = []

    showDeleteContratType = false
    contrattype: any = {}
    // METHODS

    // Ajouter un nouveau type de contrat
    ajouterContrat () {
        this.$router.push('/contrats_types/0')
    }

    // Editer un type de contrat
    editContrat (row: any) {
        this.$store.state.contrattype.error = null
        this.$router.push('/contrats_types/' + row.id)
    }

    // Ouvre la confirmation de suppression d'un type de contrat
    confirmDeleteContrat (row: any) {
        this.showDeleteContratType = true
        this.contrattype = row
    }

    // Supprime le type de contrat sélectionné
    deleteContratType () {
        this.$store.dispatch('contrattype/deleteContratType', { contrattype_id: this.contrattype.id }).then(() => {
            this.showDeleteContratType = false
            this.$store.dispatch('contrattype/getContratTypes')
        })
    }

    // Annulaton de la suppression du type de contrat
    cancelDeleteContratType () {
        this.showDeleteContratType = false
        this.contrattype = {}
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'edit' :
                    this.editContrat(paParams[1])
                    break
                case 'delete' :
                    this.confirmDeleteContrat(paParams[1])
                    break
            }
        }
    }

    filtreSortHandler (params: any) {
        this.params = params
        this.$store.state.contrattype.error = null
        this.$store.dispatch('contrattype/getContratTypes', this.params).then((response) => {
            this.setDataForGenericTab(response.data.data)
        })
    }

    buildFiltre () {
        this.filtres = [
            {
                libelle: 'Libellé',
                defautOptionlibelle: 'Tous les',
                model: 'name',
                value: '',
                index: 'name',
                datas: null,
                loading: this.$store.getters['contrattype/loading'],
                options: { type: 'form', fieldsKey: 'name', strict: true }
            }
        ]
    }

    setDataForGenericTab(poData: any, isLoadMore = false) {
        if (!isLoadMore) {
            this.dataForTab = []
        }
        if (poData) {
            for (const result of poData) {
                const name = result.name ? result.name : ' - '
                const can = this.$store.getters['auth/can'](Ability.RH_DOSADM_MANAGE)
                const icone = checkIcone(Ability.RH_DOSADM_MANAGE, can)
                const line = [
                    { label: icone.label,      item: result, type: 'action',  typeAction: 'edit', class: 'commons_first_action_button', icon: icone.icon, disabled: false },
                    { label: 'Nom', item: name,         type: 'text', typeAction: null, class: '' }
                ]
                if (can) {
                    line.push({ label: 'Supprimer', item: result, type: 'action', typeAction: 'delete', class: 'text-secondary commons_delete_action_button delete_button', icon:'trash-alt', disabled: !can })
                } else {
                    line.push({ label: '', item: null, type: 'text', typeAction: null, class: '' })
                }

                this.dataForTab.push(line)
            }
        }
    }

    async load() {
        await this.$store.dispatch('contrattype/getContratTypes', this.params)
        this.$store.state.contrattype.error = null
        this.buildFiltre()
    }

    async beforeMount () {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            await this.load()
        }
    }
}
