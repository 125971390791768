





























































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { Ability } from '@/types/Ability'
import { MessageIndicationType } from '@/types/MessageIndicationType'
import MessageIndication from '@/components/MessageIndication.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import ContactCentre from '@/components/Centre/ContactCentre.vue'
import { PosteContext } from '@/types/Poste'
import { format_phone_number } from '@/utils/helpers'
import GestionRoles from '@/components/Centre/GestionRoles.vue'
import _ from 'lodash'

@Component({
    components: {
        MessageIndication,
        ContactCentre,
        GestionRoles,
        ErrorDisplay
    },
    computed: {
        ...mapGetters('auth', ['authUser']),
        ...mapGetters('centre', ['informations_is_submit', 'informations_is_validate']),
        ...mapState('centre', ['error', 'centreSelect']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapState('user', ['roles'])
    }
})
export default class ListeContacts extends Vue {
    @Prop() edit_user_parent?: boolean;

    Ability = Ability
    MessageIndicationType = MessageIndicationType
    formatPhoneNumber = format_phone_number

    lockInput = false
    message_rejet = ''
    messageErreurSimple = ''
    showModalConfirmationSoumettre = false
    message_candidatTampon = ''
    errorEmail = ''
    edit_user = false
    editing_user = null
    add_user_flag = false
    roles_gci: any = []
    showModalMessageDissociate = false
    user_select: any = null
    liste_centre_users: any = []
    loading_save = false
    poste_id_ref = 0
    editRoles = false
    centreUserEdit: any = null
    has_responsable = false
    has_chef_centre = false


    // Ajoute un contact au centre
    add_user () {
        this.editing_user = null
        this.add_user_flag = true
    }

    // Edition d'un contact du centre
    edit_contact (centreuser: any) {
        this.editing_user = centreuser
        this.edit_user = true
    }

    // Reinit les flags d'édition ou de création
    finaliseEdit () {
        this.loading_save = true
        this.edit_user = false
        this.add_user_flag = false
        this.createListUsers()
        this.loading_save = false

    }

    /** Affichage du message de confirmation de la dissociation du contact  */
    dissocier_contact (centreuser: any) {
        this.$store.commit('centre/SET_ERROR', null)
        this.showModalMessageDissociate = true
        this.user_select = centreuser
    }

    /** Annulation de la confirmation de dissociation du contact */
    cancelDissociate () {
        this.messageErreurSimple = ''
        this.showModalMessageDissociate = false
        this.$store.commit('centre/SET_ERROR', null)
        this.user_select = null
    }

    /** Enregistrement de la dissociation du contact */
    dissociateSuite () {
        this.$store.commit('centre/SET_ERROR', null)
        this.loading_save = true
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Dissociation en cours ...', infosToaster)

        let count_postes = this.user_select.postes.length
        for (const poste of this.user_select.postes) {
            this.poste_id_ref = poste.id
            const data = {
                centre_id: this.$store.state.centre.centreSelect.id,
                centre_user_id: this.user_select.id,
                payload: { _method: 'DELETE', poste_id: this.poste_id_ref }
            }

            this.$store.dispatch('centre/dissociateUserFromCentre', data)
                .then(() => {
                    this.messageErreurSimple = ''
                    this.showModalMessageDissociate = false

                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }

                    count_postes--

                    if (count_postes === 0) {
                        this.$bvToast.toast('Dissociation terminée.', succesToaster)
                        this.createListUsers()
                        this.loading_save = false
                    }
                })
                .catch((error) => {
                    console.log('ko:' + error)
                    this.messageErreurSimple = 'Une erreur est survenue :'
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    // Charge la liste des contacts
    createListUsers () {
        this.liste_centre_users = []
        this.has_chef_centre = false
        this.has_responsable = false
        this.loading_save = true
        this.$store.dispatch('centre/getCentreUsers', { centre_id: this.$store.state.centre.centreSelect.id }).then((response) => {
            const centreusers = _.orderBy(response.data.data, 'user.name', 'asc')
            const liste_cu = centreusers.filter((cu: any) => cu.postes.length !== 0)
            for (const c in liste_cu) {
                if (liste_cu[c].postes.find((p: any) => p.context !== PosteContext.CONTEXT_AFFECTATION && p.context !== PosteContext.CONTEXT_SELECTION)) {
                    liste_cu[c].postes_aff = liste_cu[c].postes.filter((p: any) => p.context !== PosteContext.CONTEXT_AFFECTATION && p.context !== PosteContext.CONTEXT_SELECTION)

                    // Détecte la présence d'un responsable et/ou du chef d'établissement
                    for (const p in liste_cu[c].postes_aff) {
                        if (liste_cu[c].postes_aff[p].context === PosteContext.CONTEXT_CHEF_CENTRE) {
                            this.has_chef_centre = true
                        }

                        if (liste_cu[c].postes_aff[p].context === PosteContext.CONTEXT_RESPONSABLE_CENTRE) {
                            this.has_responsable = true
                        }
                    }

                    this.liste_centre_users.push(liste_cu[c])
                }
            }
            this.loading_save = false
        })
    }

    // Ouvre la gestion des rôles
    edit_roles (centreuser: any) {
        this.centreUserEdit = centreuser
        this.editRoles = true
    }

    // Ferme la gestion des rôles
    cancel_edit_roles () {
        this.centreUserEdit = null
        this.editRoles = false
    }

    mounted () {
        this.edit_user = false
        this.createListUsers()
    }

    beforeMount () {
        this.$store.commit('centre/SET_ERROR', null)
    }
}

