

































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {  BPopover } from 'bootstrap-vue'
import { Ability } from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import PopupEditCandidat from '@/components/Candidat/PopupEditCandidat.vue'
import PopupCreerReclamationPost from '@/components/Reclamations/PopupCreerReclamationPost.vue'
import { formatDate, getFileNameFromHeader, truncateString, format_phone_number } from '@/utils/helpers'
import { Etat, TypeReclamation } from '@/types/Reclamation'
import Marqueur from '@/views/Reclamations/Marqueur.vue'
import { CandidatStatut } from '@/types/Candidat'

/**
 * Composant contenant l'ensemble des candidats
*/
@Component({
    computed: {
        TypeReclamation() {
            return TypeReclamation
        },
        Ability() {
            return Ability
        },
        ...mapGetters('reclamationPostConcours', ['loading', 'reclamations_post_concours', 'meta', 'totalRows', 'lastPage', 'totalPage']),
        ...mapGetters('auth', ['can', 'user_session_id'])
    },
    components: {
        ExaGenericTable,
        PopupEditCandidat,
        PopupCreerReclamationPost,
        Marqueur,
        BPopover,
        'font-awesome-icon': FontAwesomeIcon
    }
})

export default class ReclamationsPostConcours extends Vue {
    showModalEditCandidat?: boolean = false
    showModalEditReclamation?: boolean = false
    showModalCreerReclamation?: boolean = false
    tabSelected = ''
    tabSelectedCandidat = 'resultats'
    showModaleMark = false
    exportingIsWorking = false
    sortBy = '';
    sortDesc = false;
    sortDirection = 'asc';
    filter = '';
    filterOn = [];
    stickyHeader = true;
    filtres: any = []
    dataForTab: Array<any> = []
    selectReclamation: any = null
    avancementReclamation: any = null
    compteursReclamations: Array<any> = []

    // Ensemble des colonnes du tableau des réclamations
    genericfields = [
        { key: 'marqueur',            label: 'Marqueur',            sortable: true,  class: 'text-center', type: 'action'     },
        { key: 'commentaires',        label: '',                    sortable: false, class: '',            type: 'action'     },
        { key: 'candidat.code',       label: 'Code',                sortable: true,  class: '',            type: 'actionText' },
        { key: 'candidat.identite',   label: 'Identité',            sortable: true,  class: '',            type: 'actionText' }, // Nom + prénom
        { key: 'candidat.concour_id', label: 'Filière',             sortable: false, class: 'text-start',  type: 'text'       },
        { key: 'epreuve.name',        label: 'Épreuve',             sortable: true,  class: 'text-start',  type: 'text'       },
        { key: 'message',             label: 'Message Candidat',    sortable: false, class: 'text-start',  type: 'text'       },
        { key: 'examinateur',         label: 'Examinateur',         sortable: false, class: 'text-start',  type: 'text'       },
        { key: 'note_finale',         label: 'note',                sortable: false, class: 'text-center', type: 'text'       },
        { key: 'copie_notation',      label: 'Feuille de notation', sortable: false, class: '',            type: 'actionText' },
        { key: 'note',                label: 'Note Rectifiée /20',  sortable: true,  class: 'text-center', type: 'text'       },
        { key: 'decision',            label: 'Reclassement',        sortable: true,  class: 'text-center', type: 'text'       },
        { key: 'avancement',          label: 'Avancement',          sortable: true,  class: 'text-center', type: 'icons'      },
        { key: 'etatEdit',            label: '',                    sortable: false, class: '',            type: 'action'     }
    ]

    @Watch('reclamations_post_concours')
    async majDataForGenericTab(): Promise<void> {
        const params = {}
        Vue.set(params, 'filter-type', TypeReclamation.TYPE_NOTE_ORAL)
        await this.$store.dispatch('reclamationPostConcours/getReclamationsCompteurs', { filters : params })
        this.initDatas()
        this.initStatsReclamations()
    }

    @Watch('user_session_id')
    async refreshInterface(): Promise<void> {
        // force le refresh
        localStorage.setItem('reclamationPostConcoursParams', JSON.stringify({}))
        await this.load()
    }

    /**
     * @description Formatage des datas pour l'affichage dans le tableau générique
     * @param {any} poData - Données à afficher
     * @param {boolean} isLoadMore - Ajout de données en fin de tableau
     * @returns {void}
     */
    setDataForGenericTab(poData: any, isLoadMore = false): void {
        if (!isLoadMore) {
            this.dataForTab = []
        }
        if (poData) {
            for (const result of poData) {
                const styleButtonFicheCandidat = 'text-tertiary commons_comment_button text-center fa-2x'
                const iconButtonFicheCandidat = []
                iconButtonFicheCandidat.push({ name:'address-card', class: styleButtonFicheCandidat })
                // Puce Saisie examinateur
                const puceAvancement = []
                let clickIsPossible = true
                let examinateur = null
                let centre: any = null

                const ensemble = result.seance && result.seance.creneau ? this.$store.state.ensemble.ensembles.find((e: any) => e.id === result.seance.creneau.ensemble_id) : null

                if (ensemble) {
                    centre = result.seance.creneau.ensemble.centre
                    examinateur = ensemble.examinateurs.find((ex: any) => ex.id === result.seance.creneau.user_id)

                    if (!examinateur) {
                        examinateur = ensemble.remplacants.find((ex: any) => ex.id === result.seance.creneau.user_id)
                    }
                }

                // Recup des infos afin de les envoyer dans la popup
                result.examinateur = examinateur

                if (result) {
                    if (result.rejected_at) {
                        puceAvancement.push({ name:'circle', class: 'text-danger', title: 'Réclamation rejetée' })
                    } else if ((!result.submitted_at && !result.validated_at) && (result.note || result.message_gestionnaire)) {
                        puceAvancement.push({ name:'circle', class: 'text-info', title: 'Réclamation en cours' })
                    } else if (result.submitted_at && !result.validated_at) {
                        puceAvancement.push({ name:'circle', class: 'text-success', title: 'Réclamation traitée - En attente de signature' })
                    } else if (result.submitted_at && result.validated_at) {
                        puceAvancement.push({ name:'check', class: 'text-success', title: `Signée le ${formatDate(result.validated_at)}` })
                        clickIsPossible = false
                    } else if (!result.submitted_at && !result.validated_at) {
                        puceAvancement.push({ name:'circle', class: 'text-secondary', title: 'Réclamation non traitée' })
                    }
                }

                // Bouton aperçu actif si remonté des notes effectuées
                let classBtnApercu = 'text-tertiary fw-bold text-uppercase'
                if (result.candidatEpreuve.cle_epreuve_externe) {
                    classBtnApercu = 'text-info fw-bold text-uppercase'
                }
                // Colonne commentaires
                let styleButtonComment = 'text-tertiary commons_comment_button text-center'
                let nameIcon = 'comment-alt'
                if (result.candidat.comments.length !== 0) {
                    styleButtonComment = 'commons_comment_button text-center'


                    if (result.candidat.comments.filter((c: any) => c.important === 1).length === 0) {
                        nameIcon = 'comment-alt'
                    } else {
                        nameIcon = 'comment-alt-exclamation'
                    }
                }
                let tooltipComments = ''
                for (const c in result.candidat.comments) {
                    tooltipComments += result.candidat.comments[c].important ? '---------------TACHE A FAIRE---------------\n' : '---------------COMMENTAIRE---------------\n'
                    tooltipComments += result.candidat.comments[c].body
                    tooltipComments += '\n'
                }
                // Marqueur de la réclamation
                let letterMark = '-'
                let letterStyle = 'text-center mark_style_none'
                if (result.marqueur !== '0' && result.marqueur !== null) {
                    letterMark = result.marqueur
                    letterStyle = 'text-center mark_style'
                }

                const link = [{ name:'arrow-circle-right', class: 'text-info', id: result.id, result: result }]
                const reclassement = result.decision ? [{ name:'sort-size-up-alt', class: (result.note && result.rang === null) || (result.note === 0 && result.rang === null) ? 'text-danger' : 'text-secondary', id: result.id, result: result, title: result.note ? '' : 'Reclassement en attente' }] : ''

                const noteFinale = result.candidatEpreuve.statut === CandidatStatut.ABSENT ? '0.00' : (result.candidatEpreuve.notes.avant_reclamation ? result.candidatEpreuve.notes.avant_reclamation : result.candidatEpreuve.note_finale ? result.candidatEpreuve.note_finale : '-')

                const matiere = this.$store.state.matiere.matieres.find((m: any) => m.id === result.epreuve.matiere_id)
                const line = [
                    { label: 'Changer le marqueur', item: result.id,                                          type: 'actionText', typeAction: clickIsPossible ? 'mark' : '',   class: letterStyle,             text: letterMark, disabled: false },
                    { label: tooltipComments,       item: result.candidat_id,                                 type: 'action',     typeAction: 'openComment',                   class: styleButtonComment,      icon: nameIcon,   disabled: false },
                    { label: 'Fiche candidat',      item: result.candidat_id,                                 type: 'actionText', typeAction: 'editCandidat',                  class: 'text-info item_action', text: result.candidat.code },
                    { label: 'Fiche candidat',      item: result.candidat_id,                                 type: 'actionText', typeAction: 'editCandidat',                  class: 'text-info item_action', text: result.candidat.name + ' ' + result.candidat.first_name },
                    { label: '',                    item: result.candidat.concour.name,                       type: 'text',       typeAction: null,                            class: 'text-start' },
                    { label: '',                    item: result.epreuve.name,                                type: 'text',       typeAction: null,                            class: 'text-start' },
                    { label: '',                    item: truncateString(result.message_candidat, 25), type: 'text',       typeAction: null,                            class: 'text-start reclamation_graylight_col', data_title: result.message_candidat, data_title_location: 'right' },
                    {
                        label: '',
                        item: examinateur ? examinateur.name + ' ' + examinateur.first_name : '-',
                        type: 'popover',
                        typeAction: null,
                        class: 'text-start text-dark item_action',
                        popoverOptions: {
                            affichable: true,
                            titre: 'Informations examinateur',
                            contents: [
                                { content: examinateur ? examinateur.name + ' ' + examinateur.first_name : '-', bold: true },
                                { content: '<span class="text-secondary">Téléphone : </span><span class="fw-bold">' + (examinateur ? format_phone_number(examinateur.telephone) : '-') + '</span>' },
                                { content: '<span class="text-secondary">Courriel : </span><span class="fw-bold">' + (examinateur ? examinateur.email : '-') + '</span>' },
                                { content: '<span class="text-secondary">Équipe : </span><span class="fw-bold">' + (ensemble ? ensemble.name : '-') + '</span>' },
                                { content: '<span class="text-secondary">Matière : </span><span class="fw-bold">' + (matiere ? matiere.name : '-') + '</span>' },
                                { content: '<span class="text-secondary">Centre : </span><span class="fw-bold">' + (centre ? centre.name : '-') + '</span>' }
                            ],
                            html: true
                        }
                    },
                    { label: result.candidatEpreuve.statut === CandidatStatut.ABSENT ? 'Absent' : '', item: noteFinale,                                           type: 'text',                             typeAction: null,           class: 'text-center' },
                    { label: '',                                                                      item: result.id,                                            type: 'actionText',                       typeAction: 'showNotation', class: 'reclamation_link ' + classBtnApercu, text: 'aperçu >' },
                    { label: '',                                                                      item: result.note || result.note === 0 ? result.note : '-', type: 'text',                             typeAction: null,           class: 'text-center' },
                    { label: '',                                                                      item: reclassement,                                         type: result.decision ? 'icons' : 'text', typeAction: null,           class: 'text-center' },
                    { label: '',                                                                      item: puceAvancement,                                       type: 'icons',                            typeAction: null,           class: 'text-center' },
                    { label: 'link',                                                                  item: link,                                                 type: 'icons',                            typeAction: 'link',         class: 'text-center' }
                ]
                this.dataForTab.push(line)
            }
        }
    }

    /**
     * @description Formatage des datas pour l'affichage dans le tableau générique
     * @returns {void}
     */
    setFiltersForGenericTab(): void {
        this.filtres = []

        // Options filières
        const filieres = this.$store.getters['concour/banques']
        const optionsFilieres = []
        for (const f in filieres) {
            optionsFilieres.push({ index: filieres[f].id, name: filieres[f].name })
        }

        // Options matières (épreuves)
        const matieres = this.$store.getters['matiere/matieres']
        const optionsMatieres = []
        for (const m in matieres) {
            optionsMatieres.push({ index: matieres[m].id, name: matieres[m].name })
        }

        const rootStore = this.$store.state.reclamation
        const optionsAvancement = []
        optionsAvancement.push({ index: Etat.ETAT_TRAITE, name: 'Réclamations traitées' })
        optionsAvancement.push({ index: Etat.ETAT_SIGNE, name: 'Réclamations signées' })
        optionsAvancement.push({ index: Etat.ETAT_NON_TRAITE, name: 'Réclamations non traitées' })
        optionsAvancement.push({ index: Etat.ETAT_REJETEE, name: 'Réclamations rejetées' })

        // Options filtres marqueurs
        const optionsMarqueurs = []
        optionsMarqueurs.push({ index: 'A', name: 'A' })
        optionsMarqueurs.push({ index: 'B', name: 'B' })
        optionsMarqueurs.push({ index: 'C', name: 'C' })
        optionsMarqueurs.push({ index: 'D', name: 'D' })
        optionsMarqueurs.push({ index: 'E', name: 'E' })
        optionsMarqueurs.push({ index: 'F', name: 'F' })

        this.filtres =
        [
            { libelle: 'Marqueur',   defautOptionlibelle: 'Rechercher un',  model: 'marqueur',            value: '', index: 'marqueur',            datas: optionsMarqueurs,   loading: rootStore.loading, options: { type: 'deroulant', fieldsKey: 'marqueur',      strict: true } },
            { libelle: 'Code',       defautOptionlibelle: 'Rechercher un',  model: 'candidat.code',       value: '', index: 'candidat.code',       datas: rootStore.code,     loading: rootStore.loading, options: { type: 'form',      fieldsKey: 'candidat.code', strict: true } },
            { libelle: 'Identité',   defautOptionlibelle: 'Rechercher un',  model: 'candidat.name',       value: '', index: 'candidat.name',       datas: rootStore.identite, loading: rootStore.loading, options: { type: 'form',      fieldsKey: 'candidat.identite'           } },
            { libelle: 'Filière',    defautOptionlibelle: 'Rechercher une', model: 'candidat.concour_id', value: '', index: 'candidat.concour_id', datas: optionsFilieres,    loading: rootStore.loading, options: { type: 'deroulant', fieldsKey: 'candidat.concour_id'         } },
            { libelle: 'Épreuves',   defautOptionlibelle: 'Rechercher une', model: 'epreuve.name',        value: '', index: 'epreuve.name',        datas: optionsMatieres,    loading: rootStore.loading, options: { type: 'form',      fieldsKey: 'epreuve.name'                } },
            { libelle: 'Avancement', defautOptionlibelle: 'Rechercher un',  model: 'avancement',          value: '', index: 'avancement',          datas: optionsAvancement,  loading: rootStore.loading, options: { type: 'deroulant', fieldsKey: 'avancement'                  } }
        ]
    }

    /**
     * @description Récupération des events du tableau (params[0] => l'action, params[1] => l'id de l'item)
     * @param {any} paParams - Paramètres de l'événement
     * @returns {void}
     */
    handleTableEvent(paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            const reclamations = this.$store.state.reclamationPostConcours.reclamations_post_concours
            let selectedReclamation = null
            let selectedCandidat = null

            switch (paParams[0]) {
                case 'edit':
                    // Récupération de l'étab by ID
                    selectedReclamation = reclamations.filter((reclamation: any) => reclamation.id === paParams[1].id)[0]
                    if (selectedReclamation) {
                        this.editReclamation(selectedReclamation, paParams[1])
                    }
                    break

                case 'editCandidat':
                    // Récupération de l'étab by ID
                    this.tabSelectedCandidat = 'resultats'
                    selectedCandidat = reclamations.filter((r: any) => r.candidat_id === paParams[1])[0]
                    if (selectedCandidat) {
                        selectedCandidat.centre = paParams[1].centre
                        this.editCandidat(selectedCandidat.candidat)
                    }
                    break

                case 'openComment':
                    // Récupération de l'étab by ID
                    this.tabSelectedCandidat = 'commentaires'
                    selectedCandidat = reclamations.filter((r: any) => r.candidat_id === paParams[1])[0]
                    if (selectedCandidat) {
                        this.editCandidat(selectedCandidat.candidat)
                    }
                    break

                case 'mark':
                    this.$store.state.reclamation.error = null
                    selectedReclamation = reclamations.filter((r: any) => r.id === paParams[1])[0]
                    if (selectedReclamation) {
                        this.openMarkSelector(selectedReclamation)
                    }
                    break

                case 'showNotation':
                    // Récupération de l'état by ID
                    selectedReclamation = reclamations.filter((reclamation: any) => reclamation.id === paParams[1])[0]
                    if (selectedReclamation && selectedReclamation.candidatEpreuve && selectedReclamation.candidatEpreuve.cle_epreuve_externe) {
                        this.showNotation(selectedReclamation)
                    }
                    break

                case 'sortHandler':
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break

                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break

                case 'link':
                    if (paParams[1] && paParams[1][0] && paParams[1][0].result) {
                        const result = paParams[1][0].result
                        selectedReclamation = reclamations.filter((reclamation: any) => reclamation.id === result.id)[0]
                        if (selectedReclamation) {
                            this.editOralReclamation(selectedReclamation)
                        }
                    }
                    break
            }
        }
    }

    /**
     * @description Récupération sur le serveur des concours
     * @returns {Promise<boolean>}
     */
    loadConcoursIfNotExists(): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.$store.getters['concour/banques'].length === 0) {
                this.$store.dispatch('concour/getConcoursActifs')
                    .then(() => resolve(true))
            } else {
                resolve(true)
            }
        })
    }

    /**
     * @description Récupération sur le serveur des matieres
     * @returns {Promise<boolean>}
     */
    loadMatieresIfNotExists(): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.$store.getters['matiere/matieres'].length === 0) {
                this.$store.dispatch('matiere/getMatieres').then(() => {
                    resolve(true)
                })
            } else {
                resolve(true)
            }
        })
    }

    /**
     * @description Intitialisation des statistiques réclamations de la phase "post-concours"
     * @returns {void}
     */
    initStatsReclamations(): void {
        this.compteursReclamations = this.$store.getters['reclamationPostConcours/getReclamations_postC_compteurs']
    }

    /**
     * @description Enregistrement du candidat en tant que candidat selectionnée et affichage de la modale
     * @param {any} item - Candidat à éditer
     * @returns {void}
     */
    editOralReclamation(item: any): void {
        if (item?.id !== undefined) {
            this.$router.push(`reclamations_post_concours/${item.id}`)
        }
    }

    /**
     * @description Initialisation des données réclamations affichées dans le tableau
     * @returns {void}
     */
    initDatas(): void {
        this.setDataForGenericTab(this.$store.state.reclamationPostConcours.reclamations_post_concours)
    }

    /**
     * @description Appel d'une nouvelle page de reclamations lors du scroll
     * @param {any} params - Paramètres de la requête
     * @returns {void}
     */
    loadHandler(params: any): void {
        Vue.set(params, 'filter-type', TypeReclamation.TYPE_NOTE_ORAL)
        this.$store.dispatch('reclamationPostConcours/getMoreReclamations', { filters : params })
    }

    /**
     * @description Appel des datas avec un sort en paramètres
     * @param {any} params - Paramètres de la requête
     * @returns {void}
     */
    filtreSortHandler(params: any): void {
        Vue.set(params, 'filter-type', TypeReclamation.TYPE_NOTE_ORAL)
        localStorage.setItem('reclamationPostConcoursParams', JSON.stringify(params))
        this.$store.dispatch('reclamationPostConcours/getReclamations', { filters : params })
    }

    /**
     * @description Edition d'une réclamation et affichage de la modale
     * @param {any} item - Réclamation à éditer
     * @param {any} params - Paramètres de la réclamation
     * @returns {void}
     */
    editReclamation(item: any, params: any): void {
        if (item && item.id !== undefined) {
            item.centre = params.centre
            item.examinateur = params.examinateur
            this.$store.commit('reclamationPostConcours/SET_RECLAMATION_SELECT', item) // init de la réclamation sélectionnée
        }
    }

    /**
     * @description Aperçu de la correction de la copie du candidat
     * @param {any} item - Réclamation à afficher
     * @returns {void}
     */
    showNotation(item: any): void {
        if (item?.cle_epreuve_externe) {
            window.open(this.$store.getters['candidat/getCorrectionOnViatique'](item.candidatEpreuve.cle_epreuve_externe), '_blank')
        }
    }

    /**
     * @description Exporter les réclamations
     * @returns {void}
     */
    exporterReclamations(): void {
        if (this.exportingIsWorking) {
            return
        }
        this.exportingIsWorking = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours...', infosToaster)

        const params = {}
        Vue.set(params, 'filter-type', TypeReclamation.TYPE_NOTE_ORAL)

        this.$store.dispatch('reclamation/export', params)
            .then((response) => {
                // Logique de téléchargement
                const link = document.createElement('a')
                link.href = URL.createObjectURL(new Blob([response.data]))
                link.setAttribute('Download', getFileNameFromHeader(response.headers) || "Export_reclamations.xlsx")
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.exportingIsWorking = false
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Exporter les statistiques réclamations
     * @returns {void}
     */
    exporterReclamationsStatistiques(): void {
        if (this.exportingIsWorking) {
            return
        }
        this.exportingIsWorking = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours...', infosToaster)

        const params = {}
        Vue.set(params, 'filter-type', TypeReclamation.TYPE_NOTE_ORAL)

        this.$store.dispatch('reclamation/exportStatistiques', params)
            .then((response) => {
                // Logique de téléchargement
                const link = document.createElement('a')
                link.href = URL.createObjectURL(new Blob([response.data]))
                link.setAttribute('Download', getFileNameFromHeader(response.headers) || "Export_statistiques_reclamations.xlsx")
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.exportingIsWorking = false
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Ajouter une réclamation
     * @returns {void}
     */
    openCreerReclamation(): void {
        this.showModalCreerReclamation = true
    }

    /**
     * @description Fermeture de la modale d'édition d'une réclamation
     * @returns {void}
     */
    reinitShowModalEditReclamation(): void {
        this.$store.commit('reclamationPostConcours/SET_RECLAMATION_SELECT', {}) // reset de la réclamation sélectionnée
        this.$store.commit('reclamationPostConcours/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
        this.initDatas()
        this.initStatsReclamations()
        this.showModalEditReclamation = false
    }

    /**
     * @description Fermeture de la modale d'édition d'un candidat
     * @returns {void}
     */
    reinitShowModalEditCandidat(): void {
        this.showModalEditCandidat = false
    }

    /**
     * @description Enregistrement du candidat en tant que candidat sélectionné et affichage de la modale
     * @param {any} item - Candidat à éditer
     * @returns {void}
     */
    editCandidat(item: any): void {
        if (item && item.id !== undefined) {
            // On charge à partir de la BDD l'ensemble des informations du candidat sélectionné
            this.$store.commit('reclamation/SET_LOADING', true) // on lance le loading
            this.$store.dispatch('candidat/getCandidat', item)
                .then(() => {
                    this.$store.commit('candidat/SET_CANDIDAT_FILIERE', {
                        candidatId: item.id,
                        nomFiliere: item.filiere
                    })
                    this.$store.commit('candidat/SET_SELECTED_CANDIDAT', item.id)
                    this.showModalEditCandidat = true
                    this.$store.commit('reclamationPostConcours/SET_LOADING', false) // on lance le loading
                })
        }
    }

    /**
     * @description Fermeture de la modale d'ajout d'une réclamation'
     * @returns {void}
     */
    reinitShowModalCreerReclamation(): void {
        this.showModalCreerReclamation = false
        const params = {}
        Vue.set(params, 'filter-type', TypeReclamation.TYPE_NOTE_ORAL)
        this.$store.dispatch('reclamationPostConcours/getReclamations', { filters : params }).then(() => {
            this.initDatas()
            this.initStatsReclamations()
        })
    }

    /**
     * @description Ouvre le popup de sélection d'un marqueur
     * @param {any} reclamation - Réclamation à modifier
     * @returns {void}
     */
    openMarkSelector(reclamation: any): void {
        this.selectReclamation = reclamation
        this.avancementReclamation = this.getEtatAvancementReclamation()
        this.showModaleMark = true
    }

    getEtatAvancementReclamation(): Etat {
        let etatFinal = Etat.ETAT_NON_TRAITE
        if (!this.selectReclamation.submitted_at && !this.selectReclamation.validated_at) {
            if (this.selectReclamation.message_gestionnaire || this.selectReclamation.note) {
                // Le traitement à commencer, mais n'est pas terminé
                etatFinal = Etat.ETAT_EN_COURS
            } else {
                etatFinal = Etat.ETAT_NON_TRAITE
            }
        }
        if (this.selectReclamation.submitted_at && !this.selectReclamation.validated_at) {
            etatFinal = Etat.ETAT_TRAITE
        }
        if (this.selectReclamation.submitted_at && this.selectReclamation.validated_at) {
            etatFinal = Etat.ETAT_SIGNE
        }
        return etatFinal
    }

    /**
     * @description Annule la modification du marqueur sur une réclamation
     * @param {boolean} withSave - Sauvegarde des modifications
     * @returns {void}
     */
    closeModalMark(withSave: boolean): void {
        this.showModaleMark = false
        this.selectReclamation = null

        if (withSave) {
            const params = {}
            Vue.set(params, 'filter-type', TypeReclamation.TYPE_NOTE_ORAL)
            this.$store.dispatch('reclamationPostConcours/getReclamations', { filters : params })
        }
    }

    async load(): Promise<void> {
        this.$store.commit('reclamationPostConcours/SET_LOADING', true) // lancement du loading
        try {
            await this.$store.dispatch('ensemble/getEnsembles')
            await this.$store.dispatch('epreuve/getEpreuves', { isPrecedente: false, filters: {} })
            await this.loadConcoursIfNotExists()
            await this.loadMatieresIfNotExists()
            this.setFiltersForGenericTab()
        } catch (err) {
            console.log('ReclamationsPostConcours : erreur chargement des données :', err)
        } finally {
            this.$store.commit('reclamationPostConcours/SET_LOADING', false) // fin du loading
        }
    }

    /**
     * @description Au montage du composant, on charge la session et les réclamations
     * @returns {Promise<void>}
     */
    async mounted(): Promise<void> {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            await this.load()
        }
    }
}
