export enum EtatSeriePublie {
    NON_PUBLIER,
    PUBLIER,
}

export enum EtatSerieValide {
    NON_VALIDER,
    VALIDER,
}

export function getItemEtatSerie(serie: any) {
    if (serie.publie === EtatSeriePublie.NON_PUBLIER && serie.valide === EtatSerieValide.VALIDER) {
        return { title: 'Série prête pour publication', icon: 'circle', color: 'success' }
    } else if (serie.publie === EtatSeriePublie.NON_PUBLIER && serie.valide === EtatSerieValide.NON_VALIDER) {
        return { title: 'Série non validée', icon: 'circle', color: 'secondary' }
    }
}
