export interface AmenagementInterface {
    name: string,
    id: number | 0,
    amenagement_id?: number | 0,
    code: string,
    temps: number | 0,
    type: number | 0,
    nb_candidats?: number | 0,
    accessibilite: number | 0,
    gestion_particulieres: Array<number> | [],
    domaiines: Array<number> | []
}

export interface StateAmenagements {
    amenagements: Array<AmenagementInterface> | [],
    meta: Array<string> | null,
    links: Array<string> | null,
    loading: boolean | false,
    error: Array<string> | null,
    totalRows: number | 0,
    currentPage: number | 0,
    lastPage: number | 0,
    totalPage: number | 0,
    amenagementSelect: AmenagementInterface | {},
    import_done: string
}

export enum TypeMesure {
    TYPE_ECRIT = 1,
    TYPE_ORAL = 2,
    TYPE_ECRIT_ET_ORAL = 3,
}

export enum DemandeAmenagement {
    DEMANDE_AMENAGEMENT_NON_HANDI = 0,
    DEMANDE_AMENAGEMENT_HANDI = 1,
    DEMANDE_AMENAGEMENT_HANDI_SOUMIS_ECRIT = 8,
    DEMANDE_AMENAGEMENT_HANDI_SOUMIS_ORAL = 16,
    DEMANDE_AMENAGEMENT_HANDI_TRAITE_ECRIT = 2,
    DEMANDE_AMENAGEMENT_HANDI_TRAITE_ORAL = 4,
}

export function getListeTypesMesure() {
    const listeTypesMesure = [
        { libelle: 'ECRIT', value: TypeMesure.TYPE_ECRIT },
        { libelle: 'ORAL', value: TypeMesure.TYPE_ORAL }
        // { libelle: 'ECRIT / ORAL', value: TypeMesure.TYPE_ECRIT_ET_ORAL }
    ]
    return listeTypesMesure
}

export function getTypeMesure(mesure: TypeMesure) {
    switch (mesure) {
        case TypeMesure.TYPE_ECRIT:
            return { libelle: 'ECRIT', value: TypeMesure.TYPE_ECRIT }
        case TypeMesure.TYPE_ORAL:
            return { libelle: 'ORAL', value: TypeMesure.TYPE_ORAL }
        case TypeMesure.TYPE_ECRIT_ET_ORAL:
            return { libelle: 'ECRIT / ORAL', value: TypeMesure.TYPE_ECRIT_ET_ORAL }
    }
}
