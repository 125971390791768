











































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { PosteContext, PosteType } from '@/types/Poste'
import { Ability } from '@/types/Ability'
import { formatDate, isObject, format_phone_number } from '@/utils/helpers'
import PopupSubmitIntervenants from '@/components/Centre/Validations/PopupSubmitIntervenants.vue'
import  FicheHoraire from '@/components/Centre/Intervenants/FicheHoraire.vue'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import { BButton } from 'bootstrap-vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import PopUpInfoIntervenant from '@/components/PopupInfoIntervenant.vue'



@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        PopupSubmitIntervenants,
        'vue-timepicker': VueTimepicker,
        FicheHoraire,
        'b-button': BButton,
        ErrorDisplay,
        PopUpInfoIntervenant
    },
    computed: {
        ...mapState('centre', ['meta', 'centreSelect', 'loading']),
        ...mapGetters('centre', ['centre_select_chef_centre']),
        ...mapState('session', ['sessionActive', 'sessionSelect']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('organisationecrit', ['besoins_centre', 'besoins_precedent', 'get_total_intervenants_precedent', 'get_total_intervenants']),
        ...mapGetters('intervenant', ['users_by_postes', 'compteur_by_poste', 'ligne_time', 'remaining_time_poste', 'remaining_time_ligne']),
        ...mapGetters('gabaritFicheHoraire', ['can_save_gabarit']),
        ...mapGetters('intervenant', ['intervenants_has_real_time']),
        ...mapState('gabaritFicheHoraire', ['error']),
        ...mapState('poste', ['postes']),
        ...mapGetters('auth', ['is_intervenant_centre'])
    }
})
export default class SaisieFicheHoraireIntervenants extends Vue {
    dicoPostes: Array<any> = []
    dicoUsers: Array<any> = []

    Ability = Ability
    showSetFicheIntervenant = false
    poste_select = {
        poste_id: 0,
        poste_name: '',
        user_id: 0,
        slot: 0,
        temps: '',
        remaining_time: 0
    }

    formatPhoneNumber = format_phone_number

    showConfirmationFichePublier = false
    publie = -1
    submit = -1
    validate = -1
    ficheHoraireSelect = null
    posteIdSelect = 0
    userIdSelect = 0
    posteNameTemp = ''
    userTemp = null
    chargementEnCours = false
    showPopupConfirmationPublicationFicheHoraireIntervenant = false
    titreConfirmation = ''
    textConfirmation = ''
    publier = -1
    valider = -1

    showModalInfoIntervenant = false
    user_id_temp = 0

    @Watch('centreSelect.centreusers')
    setDicoUsers () {
        this.dicoUsers = this.$store.getters['intervenant/users_by_postes'](this.$store.state.centre.centreSelect.centreusers, this.dicoPostes, this.$store.state.organisationecrit.besoins_centre)
        this.showSetFicheIntervenant = false
    }

    /** Retourne le total des heures d'une fiche horaire */
    getTotalHeureFromFiche (ficheHoraire: any) {
        return this.$store.getters['gabaritFicheHoraire/get_total_fiche_horaire'](ficheHoraire)
    }

    /** Check le type du retour d'erreur */
    isObject () {
        return isObject(this.$store.state.centre.error)
    }

    /** Formattage les dates */
    formatteDate (date: Date) {
        return formatDate(date)
    }

    infoIntervenantHandler(user_id: any) {
        this.user_id_temp  = user_id
        this.showModalInfoIntervenant = true
    }

    /** Ouvre le popup de confirmation la publication des fiches horaires intervenants pour signature */
    ouvrirConfirmationPublicationFicheHoraires (publier: number, valider: number) {
        this.$store.state.gabaritFicheHoraire.error = null
        this.showPopupConfirmationPublicationFicheHoraireIntervenant = true
        this.publier = publier
        this.valider = valider
        if (publier !== -1) {
            if (publier === 1) {
                this.titreConfirmation = 'Publier les fiches horaires pour signature'
                this.textConfirmation = 'Les fiches horaires vont être mises à disposition pour signature au contact du centre disposant des droits.'
            } else {
                this.titreConfirmation = 'Dépublier les fiches horaires'
                this.textConfirmation = 'Les fiches horaires ne seront plus disponibles en signature.'
            }
        }
    }

    /** Ferme la popup de confirmation la publication des fiches horaires intervenants pour signature */
    hideConfirmationPublicationFicheHoraires () {
        this.$store.state.gabaritFicheHoraire.error = null
        this.showPopupConfirmationPublicationFicheHoraireIntervenant = false
    }


    isAtZeroHours (data: any) {
        return !!(data && data.poste.temps && this.getTotalHeureFromFiche(data.poste.fiche_horaire) === '0 h 00');

    }

    /** Confirme la publication des fiches horaires intervenants pour signature */
    publierFichesHorairesIntervenants () {
        this.$store.state.gabaritFicheHoraire.error = null
        this.titreConfirmation = ''
        this.textConfirmation = 'Enregistrement en cours...'
        this.showPopupConfirmationPublicationFicheHoraireIntervenant = false
        this.chargementEnCours = true
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        let params = {}
        if (this.publier !== -1) {
            if (this.publier === 1) {
                this.titreConfirmation = 'Publier les fiches horaires'
                this.textConfirmation = 'Les fiches horaires vont être publiées.'
            } else {
                this.titreConfirmation = 'Annuler la publication les fiches horaires'
                this.textConfirmation = 'la publication des fiches horaires va être annuler.'
            }

            params = {
                centre_id: this.$store.state.centre.centreSelect.id,
                fiches_intervenants_publish: this.publier
            }
        }
        // console.log('params', params)
        this.$store.dispatch('gabaritFicheHoraire/publierFichesHorairesIntervenants', params)
            .then((response) => {
                this.$store.state.centre.centreSelect.fiches_intervenants_validated_at = response.data.data.fiches_intervenants_validated_at
                this.$store.state.centre.centreSelect.fiches_intervenants_published_at = response.data.data.fiches_intervenants_published_at
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.chargementEnCours = false

                // Met à jour les droits du user dans le cadre du CDC
                if (this.$store.getters['auth/is_intervenant_centre']) {
                    this.$store.dispatch('user/getDashboard', null)
                }

                this.$bvToast.toast(`${this.publier === 1 ? 'Publication' : 'Dépublication'} pour signature terminée.`, succesToaster)
            })
            .catch((error) => {
                console.log('ko :' + error)
                this.chargementEnCours = false
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /** Renseigne / Gère un intervenant dans une place */
    setFicheIntervenant (centreUserSelect: any) {
        this.ficheHoraireSelect = centreUserSelect.poste.fiche_horaire ? JSON.parse(JSON.stringify(centreUserSelect.poste.fiche_horaire)) : ''
        this.posteIdSelect = centreUserSelect.poste.id
        this.userIdSelect = centreUserSelect.user.id
        this.posteNameTemp = centreUserSelect.poste.name
        this.userTemp = centreUserSelect.user
        this.showSetFicheIntervenant = true
        this.$emit('activeEdition', true)
    }

    /** Ferme la popup de gestion de l'intervenant */
    cancelSetFicheIntervenant () {
        this.posteIdSelect = 0
        this.userIdSelect = 0
        this.posteNameTemp = ''
        this.ficheHoraireSelect = null
        this.userTemp = null
        this.showSetFicheIntervenant = false
        this.$emit('activeEdition', false)
    }

    /** enregistre la fiche horaire d'un intervenant */
    saveFicheIntervenant () {
        this.chargementEnCours = true
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        const params = {
            centre_id: this.$store.state.centre.centreSelect.id,
            poste_id: this.posteIdSelect,
            user_id: this.userIdSelect,
            fiche_horaire: this.$store.state.gabaritFicheHoraire.gabarit
        }

        this.$store.dispatch('gabaritFicheHoraire/updateFicheHorairePosteUser', params)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.chargementEnCours = false
                this.$bvToast.toast('Fiche horaire enregistrée.', succesToaster)
                this.$store.dispatch('centre/getCentre', { centre_id: this.$store.state.centre.centreSelect.id })
                this.$emit('activeEdition', false)
            })
            .catch((error) => {
                console.log('ko:' + error)
                this.chargementEnCours = false
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /** Retourne le compteur des intervenants selon le poste et les temps de travail */
    setCompteurByPosteTemps (poste_id: number) {
        if (this.dicoUsers && this.dicoUsers[poste_id]) {
            return this.$store.getters['intervenant/compteur_by_poste'](this.dicoUsers[poste_id])
        } else {
            return '--'
        }
    }

    /** Retourne la couleur de fond du bouton selon le contexte */
    setVariantColor (ligne: any) {
        let bgcolor = 'btn_action_ligne'

        if (ligne && ligne.ligne_bis) {
            bgcolor = 'bg-danger'
        }
        return bgcolor
    }

    /** Retourne la couleur du temps de la ligne */
    setColorTemps (poste_id: number, index: number, isAtZero = false) {
        if (this.dicoUsers[poste_id] && this.dicoUsers[poste_id][index]) {
            const ligne_time = this.$store.getters['intervenant/ligne_time'](this.dicoUsers[poste_id][index])
            if (ligne_time !== 100 || isAtZero) {
                return 'text-danger fw-bold'
            } else {return ''}
        }
    }

    /** Reset showPopups */
    resetPopup () {
        this.posteIdSelect = 0
        this.userIdSelect = 0
        this.posteNameTemp = ''
        this.ficheHoraireSelect = null
        this.$store.state.centre.error = null
        this.$store.state.gabaritFicheHoraire.error = null
        this.showConfirmationFichePublier = false
        this.$emit('activeEdition', false)
    }

    /** Confirmation de la soumission de la liste des intervenants */
    confirmSubmitFicheIntervenant (submit = -1, validate = -1) {
        this.submit = submit
        this.validate = validate
        this.showConfirmationFichePublier = true
    }

    /** Retourne si la liste des intervenants est complète pour activer le bouton Soumettre */
    listComplete () {
        return this.$store.getters['intervenant/list_complete'](this.dicoUsers, this.$store.state.organisationecrit.besoins_centre)
    }

    /** Retourne une classe css selon l'état de validation des infos */
    getClassBarreBoutons () {
        let class_css = 'barre_default'
        if (this.$store.state.centre.centreSelect && this.$store.state.centre.centreSelect.id) {
            if (this.$store.state.centre.centreSelect.fiches_intervenants_published_at && this.$store.state.centre.centreSelect.fiches_intervenants_validated_at) {
                class_css = 'barre_valide'
            } else if (this.$store.state.centre.centreSelect.fiches_intervenants_published_at && !this.$store.state.centre.centreSelect.fiches_intervenants_validated_at) {
                class_css = 'barre_soumis'
            } else if (this.$store.state.centre.centreSelect.commentaire_rejet && this.$store.state.centre.centreSelect.commentaire_rejet.intervenants) {
                class_css = 'barre_rejet'
            }
        }

        return class_css
    }


    beforeMount () {
        this.$emit('setLibelle', 'SAISIE DES FICHES HORAIRES')
        this.posteIdSelect = 0
        this.userIdSelect = 0
        this.posteNameTemp = ''
        this.ficheHoraireSelect = null
        if (this.$store.state.poste.postes.length === 0 && !this.$store.state.poste.loading) {
            this.$store.dispatch('poste/getPostes', { type: PosteType.TYPE_LOGISTIQUE, context: (PosteContext.CONTEXT_AFFECTATION + PosteContext.CONTEXT_CHEF_CENTRE) }).then(() => {
                this.dicoPostes = this.$store.state.poste.postes
            })
        } else {
            this.dicoPostes = this.$store.state.poste.postes
        }

        if (this.$store.state.centre.centreSelect && this.$store.state.centre.centreSelect.centreusers && this.$store.state.centre.centreSelect.centreusers.length !== 0) {
            this.dicoUsers = this.$store.getters['intervenant/users_by_postes'](this.$store.state.centre.centreSelect.centreusers, this.dicoPostes, this.$store.state.organisationecrit.besoins_centre)
            this.showSetFicheIntervenant = false
        } else {
            if (this.$route.params.centre_id) {
                // On charge le centre

                this.$store.dispatch('centre/getCentre', { centre_id: this.$route.params.centre_id })
            }
        }
    }
}
