























































import { Vue, Component, Watch }    from 'vue-property-decorator'
import { mapGetters, mapState }               from 'vuex'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'
import { Ability }                  from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { base64ToArrayBuffer, formatDate, getFileNameFromHeader, format_phone_number }               from '@/utils/helpers'
import { TypeEnsemble } from '@/types/Ensemble'
import CandidatComments from '@/components/Candidat/CandidatComments.vue'



@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapState('listeCandidatCdc', ['listeCandidats', 'isLoading', 'totalRows', 'lastPage', 'totalPage', 'loading_cand_cdc', 'meta'])
    },
    components: {
        ExaGenericTable,
        CandidatComments,
        'font-awesome-icon': FontAwesomeIcon
    }
})

export default class ListeCandidats extends Vue
{
    showModalEditionCandidat?:          boolean = false
    showModalEditionCommentCandidat?:   boolean = false
    formatDate                                  = formatDate
    tableLoading                                = false


    Ability = Ability

    genericfields = [
        { key: 'code',          label: 'Code',          sortable: true,   class: '', type: 'text' },
        { key: 'commentaires', label: '', sortable: true, class: '', type: 'action' },
        { key: 'name',          label: 'Nom',           sortable: true,   class: '', type: 'text' },
        { key: 'first_name',    label: 'Prénom',        sortable: true,   class: '', type: 'text' },
        { key: 'filiere',       label: 'Filière',       sortable: true,   class: 'text-center', type: 'text' },
        { key: 'equipes_inter',  label: 'Équipe',       sortable: true,   class: 'text-center', type: 'text' },
        { key: 'equipes_par_epreuve', label: 'Langue',  sortable: true,   class: 'text-center', type: 'text' },
        { key: 'equipes_tp',      label: 'TP',          sortable: true,   class: 'text-center', type: 'text' },
        { key: 'serie_id',      label: 'Série',         sortable: true,   class: 'text-center', type: 'text' },
        { key: 'email',         label: 'Courriel',      sortable: false,  class: '', type: 'text' },
        { key: 'telephone',     label: 'Téléphone',     sortable: false,  class: '', type: 'text' },
        { key: 'mesures',       label: 'Mesures',       sortable: true,   class: 'text-center', type: 'text' }
    ]

    sortDirection   = 'asc'

    filtres:    any         = []
    dataForTab: Array<any>  = []
    allEpreuves             = []
    params: any = {}
    exportWorking = false

    @Watch('listeCandidats')
    getExaminateurs() {
        this.setDataForGenericTab(this.$store.state.listeCandidatCdc.listeCandidats)
    }

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    showCommentCandidat = false
    selectedCandidat: any = null
    closeCommentCandidat () {
        this.showCommentCandidat = false
        this.selectedCandidat = null
    }

    // Création des lignes du tableau
    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                result.hasImportantComments = false
                if (!result.comments || result.comments.length === 0) {
                    result.hasComments = false
                    result.comments = []
                } else {
                    result.hasComments = true
                    for (const comment of result.comments) {
                        if (comment.important === 1) {
                            comment.important = true
                            result.hasImportantComments = true
                        } else {
                            comment.important = false
                        }
                    }
                }


                let styleButtonComment = 'text-tertiary commons_comment_button text-center'
                let nameIcon = 'comment-alt'
                if (result.hasComments === true) {
                    styleButtonComment = 'commons_comment_button text-center'
                    if (result.hasImportantComments === false) {
                        nameIcon = 'comment-alt'
                    } else {
                        nameIcon = 'comment-alt-exclamation'
                    }
                }
                let tooltip_comments = ''

                for (const c in result.comments) {
                    tooltip_comments += result.comments[c].important ? '---------------TACHE A FAIRE---------------\n' : '---------------COMMENTAIRE---------------\n'
                    tooltip_comments += result.comments[c].body
                    tooltip_comments += '\n'
                }

                const equipe = result.ensembles.find((e: any) => e.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT)
                const equipe_langue = result.ensembles.find((e: any) => e.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_PAR_EPREUVE)
                const equipe_tp = result.ensembles.find((e: any) => e.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_TP)

                const line: any = [
                    { label: '', item: result.code,                                 type: 'text', typeAction: null, class: '' },
                    { label: tooltip_comments,  item: result.id, type: 'action',  typeAction: 'openComment', class: styleButtonComment, icon: nameIcon, disabled: false },
                    { label: '', item: result.name,                                 type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.first_name,                           type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.concour.name,                         type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: equipe ? equipe.name : '-',                  type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: equipe_langue ? equipe_langue.name : '-',    type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: equipe_tp ? equipe_tp.name : '-',            type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: result.serie.name,                           type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: result.email,                                type: 'text', typeAction: null, class: '' },
                    { label: '', item: format_phone_number(result.portable),                             type: 'text', typeAction: null, class: '' }
                ]

                if (result.amenagements.length !== 0) {
                    line.push({ label: 'Télécharger le PDF', item: result, type: 'action', typeAction: 'openPdf', class: 'text-center text-primary', icon: 'print' })
                } else {
                    line.push({ label: '', item: null, type: 'text', typeAction: null, class: '' })
                }
                this.dataForTab.push(line)
            }
        }
    }

    // Création des filtres pour le tableau
    setFiltersForGenericTab()
    {
        // Options filières
        const filieres = this.$store.getters['concour/banques']
        const options_filieres = []
        for (const f in filieres) {
            options_filieres.push({ index: filieres[f].id, name: filieres[f].name })
        }

        // Options séries
        const series = this.$store.getters['serie/series']
        const options_series = []
        for (const s in series) {
            options_series.push({ index: series[s].id, name: series[s].name })
        }

        this.filtres =
        [
            { libelle: 'Code',      defautOptionlibelle: 'Rechercher un',   model: 'code',                value: '', index: 'code',           datas: null,               loading: this.$store.state.candidat.loading, options: { type: 'form',      fieldsKey: 'code', strict: true } },
            { libelle: 'Nom',       defautOptionlibelle: 'Rechercher un',   model: 'name',                value: '', index: 'name',           datas: null,               loading: this.$store.state.candidat.loading, options: { type: 'form',      fieldsKey: 'name' } },
            { libelle: 'Prénom',    defautOptionlibelle: 'Rechercher une',  model: 'first_name',          value: '', index: 'first_name',     datas: null,               loading: this.$store.state.candidat.loading, options: { type: 'form',      fieldsKey: 'first_name' } },
            { libelle: 'Filière',   defautOptionlibelle: 'Rechercher une',  model: 'concour_id',          value: '', index: 'filiere',        datas: options_filieres,   loading: this.$store.state.candidat.loading, options: { type: 'deroulant', fieldsKey: 'filiere' } },
            { libelle: 'Équipe',    defautOptionlibelle: 'Rechercher une',  model: 'equipes_inter',       value: '', index: 'equipes_inter',  datas: '',                loading: this.$store.state.candidat.loading, options: { type: 'form',      fieldsKey: 'equipes_inter', strict: true } },
            { libelle: 'Langue',    defautOptionlibelle: 'Rechercher une',  model: 'equipes_par_epreuve', value: '', index: 'equipes_par_epreuve', datas: '',                 loading: this.$store.state.candidat.loading, options: { type: 'form',      fieldsKey: 'equipes_par_epreuve' } },
            { libelle: 'TP',        defautOptionlibelle: 'Rechercher un',   model: 'equipes_tp',          value: '', index: 'equipes_tp',      datas: '',                 loading: this.$store.state.candidat.loading, options: { type: 'form',      fieldsKey: 'equipes_tp' } },
            { libelle: 'Série',     defautOptionlibelle: 'Rechercher une',  model: 'serie_id',            value: '', index: 'serie_id',       datas: options_series,     loading: this.$store.state.candidat.loading, options: { type: 'deroulant', fieldsKey: 'serie_id' } }
        ]
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'openPdf':
                    this.export_fiche_pdf(paParams[1])
                    break
                case 'openComment':
                    // Récupération de l'étab by ID
                    this.selectedCandidat = this.$store.state.listeCandidatCdc.listeCandidats.filter((candidat: any) => candidat.id === paParams[1])[0]
                    if (this.selectedCandidat) {
                        this.showCommentCandidat = true
                    }
                    break
                default:
                    break
            }
        }
    }

    // Applique le chargement de la pagination
    loadHandler (params: any) {
        Vue.set(params, 'centre_id', this.$route.params.centre_id)
        this.$store.dispatch('listeCandidatCdc/getMoreCandidatsCdc', params)
    }

    // Applique les filtres
    filtreSortHandler(params: any) {
        Vue.set(params, 'centre_id', this.$route.params.centre_id)
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.$store.dispatch('listeCandidatCdc/getCandidatsCdc', params)
        }
    }

    // Export de la liste des candidats au format excel
    dl_export_candidats () {
        if (this.exportWorking) {
            return
        }
        this.$store.commit('listeCandidatCdc/SET_ERROR', null)
        let fileName = ''
        this.exportWorking = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours...', infosToaster)

        this.$store.dispatch('listeCandidatCdc/getCandidatsCdcExport', { centre_id: this.$route.params.centre_id })
            .then((response) => {
                const fileNameTemp = getFileNameFromHeader(response.headers)
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.exportWorking = false
            })
    }

    /**
     * @description Récupére le ZIP d'export des décisions du centre et lance le téléchargement
     * @returns {void}
     */
    dl_export_decisions(): void {
        if (this.exportWorking) {
            return
        }
        this.exportWorking = true
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Téléchargement en cours ...', infosToaster)

        this.$store.dispatch('centre/exportDecisions', parseInt(this.$route.params.centre_id))
            .then((response) => {
                // Logique de téléchargement
                const file_name = getFileNameFromHeader(response.headers) || 'candidat_amenagements_centres.zip'
                const link = document.createElement('a')
                link.href = URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }))
                link.setAttribute('Download', file_name)
                link.download = file_name
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.exportWorking = false
            })
    }

    /** Exporte au format pdf la fiche des mesures d'aménagements du candidat */
    export_fiche_pdf (candidat: any) {
        this.$store.commit('listeCandidatCdc/SET_ERROR', null)
        let fileName = ''
        this.exportWorking = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Export en cours...', infosToaster)

        this.$store.dispatch('candidat/getPDFAmenagements', { candidat_id: candidat.id })
            .then(response => {
                const fileNameTemp = getFileNameFromHeader(response.headers) || 'Amenagements_' + candidat.name + '_' + candidat.first_name + '.pdf'
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([base64ToArrayBuffer(response.data)]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.exportWorking = false
            })
    }

    load () {
        this.$store.commit('listeCandidatCdc/SET_LOADING', true)
        const params: any = {
            page: 1,
            sort: 'name',
            direction: 'asc',
            centre_id: this.$route.params.centre_id
        }
        this.params = params
        // Load des séries
        this.$store.dispatch('serie/getSeries').then(() => {
            // Load des concours actifs
            this.$store.dispatch('concour/getConcoursActifs').then(() => {
                // Load des candidats du centre
                this.$store.dispatch('listeCandidatCdc/getCandidatsCdc', params).then(() => {
                    this.setFiltersForGenericTab()
                })
            })
        })
    }

    mounted() {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
