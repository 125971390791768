




































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component }    from 'vue-property-decorator'
import { mapGetters, mapState }               from 'vuex'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'

import { Ability }                  from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import PopupEditCandidat            from '@/components/Candidat/PopupEditCandidat.vue'
import PopupEditCommentCandidat     from '@/components/Candidat/PopupEditCommentCandidat.vue'
import { base64ToArrayBuffer, formatDateHoursMinutes, formatDateSinTime, getFileNameFromHeader } from '@/utils/helpers'
import _ from 'lodash'


/**
 * Composant contenant l'ensemble des candidats
*/
@Component({
    computed: {
        ...mapState('planning', ['total', 'totalRows', 'lastPage', 'loading_planning']),
        ...mapGetters('auth', ['is_intervenant_centre'])
    },
    components: {
        ExaGenericTable,
        PopupEditCandidat,
        PopupEditCommentCandidat,
        'font-awesome-icon': FontAwesomeIcon
    }
})

export default class Demissionnaires extends Vue
{
    Ability = Ability
    exportWorking = false

    // Ensemble des colonnes du tableau de candidats

    genericfields = [
        { key: 'candidat.code',         label: 'Code',              sortable: true,  class: '', type: 'text' },
        { key: 'candidat.name',         label: 'Identité',          sortable: true,  class: '', type: 'text' },
        { key: 'candidat.demission_at', label: 'Date de démission', sortable: true,  class: '', type: 'text' },
        { key: 'serie.name',            label: 'Série',             sortable: true,  class: '', type: 'text' },
        { key: 'creneau.ensemble.name', label: 'Équipe',            sortable: true,  class: '', type: 'text' },
        { key: 'epreuve',               label: 'Épreuve',           sortable: false, class: '', type: 'text' },
        { key: 'séances',               label: 'Séance',            sortable: false, class: '', type: 'text' },
        { key: 'creneau.user.name',     label: 'Examinateur',       sortable: true,  class: '', type: 'text' }
    ]

    sortBy          = '';
    sortDesc        = false;
    sortDirection   = 'asc';
    filter          = '';
    filterOn        = [];
    stickyHeader    = true;

    filtres:    any         = []
    dataForTab: Array<any>  = []
    params_search: any = {}


    /**
     * Au montage du composant
     */
    constructor ()
    {
        super()
        this.filtres = []
    }


    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                let candidat = ''
                candidat = result.candidat.name + ' ' + result.candidat.first_name

                const dateDemission = formatDateSinTime(result.candidat.demission_at)
                const labelDateDemission = formatDateHoursMinutes(result.candidat.demission_at)

                let creneau = ''
                creneau = 'Le ' + formatDateSinTime(result.creneau.jour) + ' à ' + formatDateHoursMinutes(result.creneau.h_debut)

                let examinateur = ''
                examinateur = result.creneau.user ? result.creneau.user.name + ' ' + result.creneau.user.first_name : '-'

                const line = [
                    { label: '',                 item: result.candidat.code,             type: 'text',    typeAction: null,          class: '' },
                    { label: '',                 item: candidat,                         type: 'text',    typeAction: null,          class: '' },
                    { label: labelDateDemission, item: dateDemission,                    type: 'text',    typeAction: null,          class: '' },
                    { label: '',                 item: result.serie.name,                type: 'text',    typeAction: null,          class: '' },
                    { label: '',                 item: result.creneau.ensemble.name,     type: 'text',    typeAction: null,          class: '' },
                    { label: '',                 item: result.epreuve.name,              type: 'text',    typeAction: null,          class: '' },
                    { label: '',                 item: creneau,                          type: 'text',    typeAction: null,          class: '' },
                    { label: '',                 item: examinateur,                      type: 'text',    typeAction: null,          class: '' }
                ]
                this.dataForTab.push(line)
            }
        }
    }


    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setFiltersForGenericTab()
    {
        this.filtres =
        [

        ]
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'sortHandler':
                    this.filterWithoutBack(paParams[1])
                    break

                case 'filterHandler':
                    this.loadHandler()
                    break

                case 'onLoadPage':
                    this.loadHandler()
                    break

                default:
                    break
            }
        }
    }

    /* Filtre sans le back et relance setDataForGenerictable */
    filterWithoutBack(PaParams1: any) {
        let listeCandidats = this.$store.state.planning.seances_examinateurs
        listeCandidats = _.orderBy(listeCandidats, PaParams1.sort, PaParams1.direction)
        this.setDataForGenericTab(listeCandidats)
    }

    /**
     * Initialisation des données affichées dans le tableau ainsi que les compteurs
     */
    initDatas (poDatas: any)
    {
        if (poDatas && poDatas.data && poDatas.data.data)
        {
            this.setDataForGenericTab(poDatas.data.data)
        }
    }


    /**
     * Appel d'une nouvelle page de candidats lors du scroll
     */
    loadHandler ()
    {
        /*  */
        // this.$store.dispatch('candidat/getMoreCandidats', params)
    }

    /**
     * Sélection d'un centre lance la recherche des équipes qui lui sont associées
     */
    select_centre_id (e: any) {
        Vue.set(this.params_search, 'centre_id', e.target.value)
        const params = {}
        Vue.set(params, 'filter-centre_id', e.target.value)
        Vue.set(params, 'demission', 1)
        Vue.set(params, 'perPage', 1000)
        this.$store.dispatch('planning/getSeances', { mode: 'examinateur', params : this.params_search }).then(() => {
            this.setDataForGenericTab(this.$store.state.planning.seances_examinateurs)
        })
    }

    /**
     * Exporter la liste des démissionnaires au format PDF
     */
    export_pdf () {
        this.$store.commit('planning/SET_ERROR', null)
        let fileName = ''
        this.exportWorking = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Export en cours...', infosToaster)
        const params = {}
        if (this.$store.getters['auth/can'](Ability.GCI_DEMISSIONNAIRES_OWN_VIEW)) {
            Vue.set(params, 'centre_id', this.$route.params.centre_id)
        }
        Vue.set(params, 'pdf', 1)
        Vue.set(params, 'format', 'b64')
        Vue.set(params, 'excel', 0)
        Vue.set(params, 'demission', 1)

        this.$store.dispatch('planning/getPDF', { params : params })
            .then(response => {
                const fileNameTemp = getFileNameFromHeader(response.headers) || 'Candidats_demissionnaires.pdf'
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([base64ToArrayBuffer(response.data)]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.exportWorking = false
            })
    }

    mounted () {
        this.$store.commit('planning/RESET_META')
        this.$store.commit('planning/RESET_SEANCES')
        this.$store.commit('planning/RESET_ERROR')
        Vue.set(this.params_search, 'page', 1)
        Vue.set(this.params_search, 'pdf', 0)
        Vue.set(this.params_search, 'excel', 0)
        Vue.set(this.params_search, 'demission', 1)

        // Gestion de l'accés par un Gestionnaire de centre
        if (this.$store.getters['auth/can'](Ability.GCI_DEMISSIONNAIRES_OWN_VIEW)) {
            const e = {
                target: {
                    value: this.$route.params.centre_id
                }
            }
            this.select_centre_id(e)
        } else if (this.$store.getters['auth/can'](Ability.INTERV_PLANIF_OWN) || this.$store.getters['auth/can'](Ability.INTERV_SUPPLEANT_OWN)) {
            const params = {}
            Vue.set(params, 'perPage', 1000)
            Vue.set(params, 'demission', 1)
            this.$store.dispatch('planning/getSeances', { mode: 'examinateur', params : params }).then(() => {
                this.setDataForGenericTab(this.$store.state.planning.seances_examinateurs)
            })
        }
    }
}
