
























































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { PosteContext, PosteType } from '@/types/Poste'
import { Ability } from '@/types/Ability'
import { formatDate, isObject, format_phone_number } from '@/utils/helpers'
import PopupSubmitIntervenants from '@/components/Centre/Validations/PopupSubmitIntervenants.vue'
import VuePdfApp from 'vue-pdf-app'
import { ExaSignature } from '@exatech-group/signature'
import '@exatech-group/signature/dist/style.css'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import PopUpInfoIntervenant from '@/components/PopupInfoIntervenant.vue'

@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        PopupSubmitIntervenants,
        VuePdfApp,
        ExaSignature,
        ErrorDisplay,
        PopUpInfoIntervenant
    },
    computed: {
        ...mapState('centre', ['meta', 'centreSelect', 'loading']),
        ...mapGetters('centre', ['centre_select_chef_centre']),
        ...mapState('session', ['sessionActive', 'sessionSelect']),
        ...mapState('auth', ['user']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('organisationecrit', ['besoins_centre', 'besoins_precedent', 'get_total_intervenants_precedent', 'get_total_intervenants']),
        ...mapGetters('intervenant', ['users_by_postes', 'compteur_by_poste', 'ligne_time', 'remaining_time_poste', 'remaining_time_ligne']),
        ...mapGetters('gabaritFicheHoraire', ['get_total_fiche_horaire']),
        ...mapState('poste', ['postes']),
        ...mapState(['error']),
        ...mapGetters('auth', ['is_intervenant_centre']),
        ...mapState('gabaritFicheHoraire', ['loading'])
    }
})
export default class SignatureFichesHoraire extends Vue {
    dicoPostes: Array<any> = []
    dicoUsers: Array<any> = []

    Ability = Ability
    showPdfFicheHoraireIntervenant = false
    poste_select = {
        poste_id: 0,
        poste_name: '',
        user_id: 0,
        slot: 0,
        temps: '',
        remaining_time: 0
    }

    formatPhoneNumber = format_phone_number

    showPopupValidationSignature = false
    posteIdSelect = 0
    userIdSelect = 0
    source_doc: any = null
    config = {
        toolbar: {
            toolbarViewerRight: { presentationMode: false, openFile: false, viewBookmark: false, secondaryToolbarToggle: false }
        }
    }

    file_name =''

    chargementEnCours = false
    validation = -1

    showSignatureFichesHoraireResponsable = false
    signature = ''
    modeSignature = true
    showAnnulationSignature = false
    modeValidation = false
    loadToken = false


    showModalInfoIntervenant = false
    user_id_temp = 0

    @Watch('centreSelect.centreusers')
    setDicoUsers () {
        this.dicoUsers = this.$store.getters['intervenant/users_by_postes'](this.$store.state.centre.centreSelect.centreusers, this.dicoPostes, this.$store.state.organisationecrit.besoins_centre)
        this.showPdfFicheHoraireIntervenant = false
    }

    isAtZeroHours (data: any) {
        return !!(data && data.poste.temps && this.getTotalHeureFromFiche(data.poste.fiche_horaire) === '0 h 00');

    }

    infoIntervenantHandler(user_id: any) {
        this.user_id_temp  = user_id
        this.showModalInfoIntervenant = true
    }

    getTotalHeureFromFiche (ficheHoraire: any) {
        return this.$store.getters['gabaritFicheHoraire/get_total_fiche_horaire'](ficheHoraire)
    }

    /** Retourne la couleur de la puce selon etat de signature de la fiche */
    getColorFiche (fiche: any, isZero = false) {
        let color = 'tertiary'
        if (fiche.fiche_validated_at) {
            color = 'info'
        }
        if (fiche.fiche_signed_at) {
            color = 'success'
        }
        if (isZero) {
            return 'danger'
        }
        return color
    }

    getTitleFiche (fiche: any, isZero = false) {
        let title = 'Fiche horaire non signée'
        if (fiche.fiche_signed_at) {
            title = 'Fiche horaire signée par l\'intervenant le ' + formatDate(fiche.fiche_signed_at)
        }
        if (fiche.fiche_validated_at) {
            title = 'Fiche horaire signée par le chef d\'établissement le ' + formatDate(fiche.fiche_validated_at)
        }
        if (isZero) {
            title = 'Fiche horaire non signable'
        }
        return title
    }

    /** afficher la zone de signature des fiches horraire des intervenants par le chef d'établissement */
    ouvrirSignatureFichesHorairesResponsable () {
        this.source_doc =  null
        this.showSignatureFichesHoraireResponsable = true
        this.$store.dispatch('gabaritFicheHoraire/getPdfFichesHoraire', { centre_id: this.$store.state.centre.centreSelect.id })
            .then((response) => {
                this.file_name = 'Fiches_Horaires_' + this.$store.state.centre.centreSelect.code
                this.source_doc = response.data
            })
            .catch((error) => {
                console.log('ko: ' + error)
            })
    }

    /** masquer la zone de signature des fiches horraire des intervenants par le chef d'établissement */
    hidePdfSignatureFicheHoraireResponsable () {
        this.source_doc =  null
        this.showSignatureFichesHoraireResponsable = false
    }

    // Enregistre la signature si besoin et update le pv
    saveSignature (params: any) {
        this.signature = params.signature
        this.loadToken = true
        this.$store.dispatch('gabaritFicheHoraire/getTokenSignatureFichesHoraire',
            {
                centre_id: this.$store.state.centre.centreSelect.id,
                mode: params.mode
            }).then(() => {
            this.loadToken = false
        })
    }

    signatureFichesHorairesResponsable (tokenSMS: string) {
        if (this.signature !== '') {
            this.loadToken = true
            const idInfo = 't_info_' + Math.random()
            const infosToaster = {
                id: idInfo,
                toaster: 'b-toaster-top-right',
                variant: 'primary',
                noCloseButton: true,
                fade: true,
                noAutoHide: true
            }

            this.$bvToast.toast('Signature en cours ...', infosToaster)
            this.$store.state.dossieradministratif.error = null
            const datas = {
                signature: this.signature,
                code: tokenSMS
            }

            this.$store.dispatch('gabaritFicheHoraire/signatureFichesHoraire',
                {
                    centre_id: this.$store.state.centre.centreSelect.id,
                    payload: datas
                })
                .then(async (response) => {
                    await this.$store.dispatch('centre/getCentre', { centre_id: this.$store.state.centre.centreSelect.id })
                    this.$store.state.centre.centreSelect.fiches_intervenants_signed_at = response.data.data.fiches_intervenants_signed_at
                    this.modeSignature = false
                    this.modeValidation = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Signature enregistrée !', succesToaster)
                    this.loadToken = false
                    this.ouvrirSignatureFichesHorairesResponsable()
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    // Affiche si des erreurs de signature sont retournées
    showErrorSignature (error: string) {
        if (error !== '') {
            this.$store.state.gabaritFicheHoraire.error = error
        }
    }

    // Annule la signature en cours
    confirmAnnulation () {
        this.signature = ''
        this.modeSignature = false
        this.showAnnulationSignature = false
        this.hidePdfSignatureFicheHoraireResponsable()
    }

    // Bouton Retour
    back () {
        this.showAnnulationSignature = true
    }

    // Ferme la popup d'annulation de signature
    closeAnnulationSignature () {
        this.signature = ''
        this.modeSignature = true
        this.showAnnulationSignature = false
    }

    /** Check le type du retour d'erreur */
    isObject () {
        return isObject(this.$store.state.centre.error)
    }

    /** Formattage les dates */
    formatteDate (date: Date) {
        return formatDate(date)
    }

    /** Retourne le compteur des intervenants selon le poste et les temps de travail */
    setCompteurByPosteTemps (poste_id: number) {
        if (this.dicoUsers && this.dicoUsers[poste_id]) {
            return this.$store.getters['intervenant/compteur_by_poste'](this.dicoUsers[poste_id])
        } else {
            return '--'
        }
    }

    /** Retourne la couleur de fond du bouton selon le contexte */
    setVariantColor (ligne: any) {
        let bgcolor = 'btn_action_ligne'

        if (ligne && ligne.ligne_bis) {
            bgcolor = 'bg-danger'
        }
        return bgcolor
    }

    /** Retourne la couleur du temps de la ligne */
    setColorTemps (poste_id: number, index: number, isZero = false) {
        if (this.dicoUsers[poste_id] && this.dicoUsers[poste_id][index]) {
            const ligne_time = this.$store.getters['intervenant/ligne_time'](this.dicoUsers[poste_id][index])
            if (ligne_time !== 100 || isZero) {
                return 'text-danger fw-bold'
            }
        }
    }

    /** Retourne si la liste des intervenants est complète pour activer le bouton Soumettre */
    listComplete () {
        return this.$store.getters['intervenant/list_complete'](this.dicoUsers, this.$store.state.organisationecrit.besoins_centre)
    }

    /** Retourne une classe css selon l'état de validation des infos */
    getClassBarreBoutons () {
        // console.log(this.$store.state.centre)
        let class_css = 'barre_default'
        if (this.$store.state.centre && this.$store.state.centre.centreSelect && this.$store.state.centre.centreSelect.fiches_intervenants_validated_at) {
            class_css = 'barre_valide'
        } else if (this.$store.state.centre && this.$store.state.centre.centreSelect && (this.$store.state.centre.centreSelect.fiches_intervenants_published_at || this.$store.state.centre.centreSelect.fiches_intervenants_signed_at)) {
            class_css = 'barre_soumis'
        }
        return class_css
    }

    /** Ouvre le viewer pdf */
    showPdfFicheHoraire (centreUserSelect: any) {
        this.showPdfFicheHoraireIntervenant = true
        this.$store.dispatch('gabaritFicheHoraire/getPdfFicheHoraire', { poste_id: centreUserSelect.poste.id, user_id: centreUserSelect.user.id, centre_id: this.$store.state.centre.centreSelect.id })
            .then((response) => {
                this.file_name = 'Fiche_Horaire_' + centreUserSelect.user.name + '_' + centreUserSelect.poste.name
                this.source_doc = response.data
                this.$emit('activeEdition', true)
            })
            .catch((error) => {
                console.log('ko: ' + error)
                this.showPdfFicheHoraireIntervenant = false
            })
    }

    /** Ferme le viewer pdf */
    hidePdfFicheHoraire () {
        this.source_doc = null
        this.showPdfFicheHoraireIntervenant = false
        this.$emit('activeEdition', false)
    }

    /** Ferme la popup de confirmation de validation des signatures */
    cancelConfirmSignature () {
        this.showPopupValidationSignature = false
    }

    /** Ouvre le popup de confirmation de validation des signatures */
    confirmSignaturesIntervenants (validation: number) {
        this.showPopupValidationSignature = true
        this.validation = validation
    }

    /** Valide l'action de validation des signatures */
    validerSignaturesIntervenants () {
        this.showPopupValidationSignature = false
        this.chargementEnCours = true
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        if (this.validation === 1) {
            this.$bvToast.toast('Validation en cours ...', infosToaster)
        } else if (this.validation === 0) {
            this.$bvToast.toast('Invalidation en cours ...', infosToaster)
        }

        const params = {
            id: this.$store.state.centre.centreSelect.id,
            fiches_intervenants_validate: this.validation
        }

        this.$store.dispatch('centre/updateCentre', params)
            .then(async (response) => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                await this.$store.dispatch('centre/getCentre', { centre_id: this.$store.state.centre.centreSelect.id })
                this.$store.state.centre.centreSelect.fiches_intervenants_validated_at = response.data.data.fiches_intervenants_validated_at
                this.chargementEnCours = false

                if (this.validation === 1) {
                    this.$bvToast.toast('Validation des signatures terminée.', succesToaster)
                } else if (this.validation === 0) {
                    this.$bvToast.toast('Invalidation des signatures terminée.', succesToaster)
                }
            })
            .catch((error) => {
                console.log('ko :' + error)
                this.chargementEnCours = false
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }


    signatureMode = []
    beforeMount () {
        for(let i = 0; i < this.$store.state.auth.user.parameters.length; i ++) {
            if(this.$store.state.auth.user.parameters[i].code === 'signatureMode') {
                this.signatureMode = this.$store.state.auth.user.parameters[i].value
            // this.codeClient = 'CCMP'
            }
        }
        // SIGNATURES FICHES HORAIRES
        this.$emit('setLibelle', 'SIGNATURE DES FICHES HORAIRES')
        if (this.$store.state.poste.postes.length === 0 && !this.$store.state.poste.loading) {
            this.$store.dispatch('poste/getPostes', { type: PosteType.TYPE_LOGISTIQUE, context: (PosteContext.CONTEXT_AFFECTATION + PosteContext.CONTEXT_CHEF_CENTRE) }).then(() => {
                this.dicoPostes = this.$store.state.poste.postes
            })
        } else {
            this.dicoPostes = this.$store.state.poste.postes
        }

        if (this.$store.state.centre.centreSelect && this.$store.state.centre.centreSelect.centreusers && this.$store.state.centre.centreSelect.centreusers.length !== 0) {
            this.dicoUsers = this.$store.getters['intervenant/users_by_postes'](this.$store.state.centre.centreSelect.centreusers, this.dicoPostes, this.$store.state.organisationecrit.besoins_centre)
            this.showPdfFicheHoraireIntervenant = false
        } else {
            if (this.$route.params.centre_id) {
            // On charge le centre
                this.$store.dispatch('centre/getCentre', { centre_id: this.$route.params.centre_id })
            }
        }
    }
}
