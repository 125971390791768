

















































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { TypeCentre, getTypeCentreSpec, getTypeCentrePassationSpec } from '@/types/Centre'
import { Ability } from '@/types/Ability'
import { TypePassation } from '@/types/Epreuve'
import PhoneInput from '@/components/Tools/PhoneInput.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { codeRestrict, format_phone_number } from '@/utils/helpers'


@Component({
    computed: {
        ...mapGetters('auth', ['authUser']),
        ...mapGetters('ville', ['villes']),
        ...mapState('centre', ['centreSelect']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    },
    components: {
        PhoneInput,
        'font-awesome-icon': FontAwesomeIcon
    }
})
export default class DescriptifCentre extends Vue {
    codeRestrict = codeRestrict
    centreType = TypeCentre
    getterCentreTypeSpec = getTypeCentreSpec
    PassationType = TypePassation
    getterTypePassationSpec = getTypeCentrePassationSpec
    centreTemp: any = {
        id: 0,
        ville_id: 0,
        code: '',
        name: '',
        type: 0,
        type_passation: 0,
        conventionne: 1,
        telephone: '',
        allotir_par_salle: 1,
        color: null,
        submitted_at: null
    }

    formatPhoneNumber = format_phone_number

    Ability = Ability

    @Watch('centreSelect')
    init () {
        if (this.$store.state.centre.centreSelect && this.$store.state.centre.centreSelect.id) {
            this.initDescriptif()
        }
    }

    /** Enregistrement des informations générales d'un centre */
    saveDescriptif () {
        if (this.checkValidPhoneNumberInputData()) {
            this.updatePhoneNumberInputData()
            const payload = {
                id: (this.centreTemp.id === undefined ? 0 : this.centreTemp.id),
                name: this.centreTemp.name,
                ville_id: this.centreTemp.ville_id,
                code: this.centreTemp.code,
                type: this.centreTemp.type,
                telephone: this.centreTemp.telephone,
                type_passation: this.centreTemp.type_passation,
                conventionne: this.centreTemp.conventionne,
                allotir_par_salle: this.centreTemp.allotir_par_salle,
                color: this.centreTemp.color
            }

            const idInfo = 't_info_' + Math.random()
            const infosToaster = {
                id: idInfo,
                toaster: 'b-toaster-top-right',
                variant: 'primary',
                noCloseButton: true,
                fade: true,
                noAutoHide: true
            }
            if (payload.id === 0) {
                this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
                this.$store.dispatch('centre/addCentre', payload)
                    .then(async () => {
                        await this.$store.dispatch('centre/getCentre', { centre_id: this.$store.state.centre.centreSelect.id })
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }
                        this.$bvToast.toast('Ajout terminé.', succesToaster)

                        // il faut remettre à jour la liste des users et les compteurs de postes du centre, sinon on hérite des infos du dernier centre consulté...
                        this.$store.dispatch('centre/getCentreUsers', { centre_id: this.$store.state.centre.centreSelect.id }).then(() => {
                            this.$store.dispatch('centre/getUsersCount', { centre_id: this.$store.state.centre.centreSelect.id })
                        })

                        // Emit que le centre est créé
                        this.$emit('newCentre', true)
                    })
                    .catch((error) => {
                        console.log('ko:' + error)
                    })
                    .finally(() => {
                        this.$bvToast.hide(idInfo)
                    })
            } else {
                this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
                this.$store.dispatch('centre/updateCentre', payload)
                    .then(async () => {
                        await this.$store.dispatch('centre/getCentre', { centre_id: this.$store.state.centre.centreSelect.id })
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }
                        this.$bvToast.toast('Modification terminée.', succesToaster)
                    })
                    .catch((error) => {
                        console.log('ko:' + error)
                    })
                    .finally(() => {
                        this.$bvToast.hide(idInfo)
                    })
            }
        }
    }

    /** Initialisation du descriptif */
    initDescriptif () {
        if (this.$store.state.centre.centreSelect && this.$store.state.centre.centreSelect.id) {
            // Check si besoin de charger les villes
            if (this.$store.state.ville.villes.length === 0) {
                this.$store.dispatch('ville/getVilles')
            }
            this.centreTemp = {
                id: this.$store.state.centre.centreSelect.id,
                ville_id: this.$store.state.centre.centreSelect.ville_id,
                ville_name: this.$store.state.centre.centreSelect.ville ? this.$store.state.centre.centreSelect.ville.name : '',
                code: this.$store.state.centre.centreSelect.code,
                name: this.$store.state.centre.centreSelect.name,
                type: this.$store.state.centre.centreSelect.type,
                type_passation: this.$store.state.centre.centreSelect.type_passation,
                conventionne: this.$store.state.centre.centreSelect.conventionne,
                allotir_par_salle: this.$store.state.centre.centreSelect.allotir_par_salle,
                color: this.$store.state.centre.centreSelect.color,
                telephone: this.$store.state.centre.centreSelect.telephone,
                submitted_at: this.$store.state.centre.centreSelect.submitted_at
            }
        } else {
            this.centreTemp = {
                id: 0,
                ville_id: 0,
                code: '',
                name: '',
                type: 0,
                type_passation: 0,
                conventionne: 1,
                allotir_par_salle: 1,
                color: null,
                telephone: '',
                submitted_at: null
            }
        }
    }

    /** Met à jour la valeur locale du numéro de téléphone saisi retourné par le composant PhoneInput */
    updatePhoneNumberInputData () {
        if ((this.$refs.phoneInput as any)) {
            if ((this.$refs.phoneInput as any).infosPhoneInput) {
                if ((this.$refs.phoneInput as any).infosPhoneInput.isValidatedPhoneNumber) {
                    this.centreTemp.telephone = (this.$refs.phoneInput as any).infosPhoneInput.formattedPhoneNumber
                } else {
                    this.centreTemp.telephone = ''
                }
            }
        }
    }

    /** Vérifie la validité de la saisie en fonction de l'état des données fournies par le composant PhoneInput */
    checkValidPhoneNumberInputData () {
        let isValid = false
        if ((this.$refs.phoneInput as any)) {
            if ((this.$refs.phoneInput as any).infosPhoneInput) {
                isValid = (this.$refs.phoneInput as any).infosPhoneInput.isValidatedPhoneNumber || (this.$refs.phoneInput as any).infosPhoneInput.phoneNumber === '';
            }
        }
        return isValid
    }

    mounted () {
        this.$store.state.centre.error = null
        this.initDescriptif()
    }
}
