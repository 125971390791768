












































































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component, Watch }    from 'vue-property-decorator'
import { mapGetters, mapState }     from 'vuex'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { formatDateDayHourDateForFileSaving, formatDateHoursMinutes, formatDateSinTime, getFileNameFromHeader } from '@/utils/helpers'
import _ from 'lodash'
import ErrorDisplay                 from '@/components/ErrorDisplay.vue'
import PopupPlanningPDF             from '@/components/Plannings/PopupPlanningPDF.vue'
import { Ability } from '@/types/Ability'
import { etat_seance } from '@/types/Seance'
import { EnsembleInterface } from '@/types/Ensemble'


@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapState('session', ['sessionSelect']),
        ...mapGetters('serie', ['series']),
        ...mapState('ensemble', ['ensembles']),
        ...mapGetters('ensemble', ['ensembleById']),
        ...mapGetters('auth', ['is_intervenant_centre']),
        ...mapState('planning', ['seances_examinateurs', 'totalRows', 'lastPage', 'total', 'loading_planning', 'currentPage', 'error_plannings', 'loadingPdf', 'pdfView'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ExaGenericTable,
        ErrorDisplay,
        PopupPlanningPDF
    }
})

export default class PlanningsExaminateur extends Vue
{
    formatDateSinTime = formatDateSinTime
    genericfields = [
        { key: 'serie',         label: 'Série',         sortable: false,    class: 'text-start',  type: 'text' },
        { key: 'date',          label: 'Date',          sortable: false,    class: 'text-start',  type: 'text' },
        { key: 'heure',         label: 'Heure',         sortable: false,    class: 'text-start',  type: 'text' },
        { key: 'centre',        label: 'Centre',        sortable: false,    class: 'text-start',  type: 'text' },
        { key: 'salle',         label: 'Salle',         sortable: false,    class: 'text-start',  type: 'text' },
        { key: 'equipe',        label: 'Équipe',        sortable: false,    class: 'text-start',  type: 'text' },
        { key: 'examinateur',   label: 'Examinateur',   sortable: false,    class: 'text-start',  type: 'text' },
        { key: 'matiere',       label: 'Matière',       sortable: false,    class: 'text-start',  type: 'text' },
        { key: 'code',          label: 'Code',          sortable: false,    class: 'text-start',  type: 'text' },
        { key: 'candidat',      label: 'Candidat',      sortable: false,    class: 'text-start',  type: 'text' },
        { key: 'filiere',       label: 'Filière',       sortable: false,    class: 'text-start',  type: 'text' }
    ]

    sortBy          = '';
    sortDesc        = false;
    sortDirection   = 'asc';
    filter          = '';
    filterOn        = [];
    stickyHeader    = true;

    filtres:    any         = []
    dataForTab: Array<any>  = []

    options_centres: Array<any> = []
    options_series: Array<any> = []
    options_ensembles: Array<any> = []
    options_examinateurs: Array<any> = []
    centre_id: any = null
    serie_select_id = null
    examinateur_select_id = null
    loading_equipes = false
    loading_examinateurs = false

    params_search: any = {}
    showPdf = false
    titre_popup = 'Plannings des examinateurs'
    pdf_filename = ''
    pdfData                 = ''
    libelle_document: any   = ''
    paramsConfirmationImpression: any   = {}
    recherche_temp: any = {}
    dicoCentres: Array<any> = []

    Ability = Ability

    @Watch('series')
    setSeries () {
        this.getSeries()
    }

    @Watch('user_session_id')
    refreshInterface () {
        this.$store.state.planning.seances_examinateurs = []
        this.$store.state.planning.total = 0
        this.options_centres = []
        this.$store.state.centre.centres = []
        this.reset_params()
        this.getCentres()
    }

    @Watch('seances_examinateurs')
    watchTableau() {
        const data = this.$store.state.planning.seances_examinateurs
        this.setDataForGenericTab(data)
    }

    /** recup de tous les centres et renvois les centres avec équipe */
    getCentres() {
        if (this.$store.getters['centre/centres'].length === 0) {
            this.$store.dispatch('centre/getCentres').then((resp) => {
                this.options_centres = resp.data.data.filter((centre: { ensembles_count: number }) => centre.ensembles_count !== 0)
            })
        } else {
            this.options_centres = this.$store.getters['centre/centres'].filter((centre: { ensembles_count: number }) => centre.ensembles_count !== 0)
        }
    }

    resetFromSelectChange () {
        this.$store.state.planning.seances_examinateurs = []
        this.$store.state.planning.total = 0
    }

    /**
     * Chargement des options de séries
     */
    getSeries () {
        this.options_series = this.$store.getters['serie/series']
    }

    getAllExaminateurs () {
        if (this.loading_examinateurs) {
            return
        }
        this.loading_examinateurs = true
        this.serie_select_id = null

        this.$store.dispatch('ensemble/getEnsembles', { params: { perPage: -1 } })
            .then(() => {
                this.options_examinateurs = _.orderBy(
                    this.$store.getters['ensemble/ensembles']
                        .flatMap((ensemble: any) => [
                            ...ensemble.examinateurs.map((examinateur: any) => ({ ...examinateur, is_replace: false })),
                            ...ensemble.remplacants?.map((remplacant: any) => ({ ...remplacant, is_replace: true })) || []
                        ])
                        .reduce((acc: any, examinateur: any) => {
                            if (!acc.find((e: any) => e.id === examinateur.id)) {
                                acc.push(examinateur)
                            }
                            return acc
                        }, []),
                    'name',
                    'asc'
                );
            })
            .finally(() => {
                this.loading_examinateurs = false
            })
    }

    /**
     * Coche l'option d'affichage des creneaux non utilisé
     */
    changeCheckBoxNoUse (e: any) {
        this.resetFromSelectChange()
        if (e.target.checked) {
            Vue.set(this.params_search, 'disponibilite', 1)
        } else {
            Vue.set(this.params_search, 'disponibilite', 0)
        }
    }

    /**
     * Sélection d'un centre lance la recherche des équipes qui lui sont associées
     */
    select_centre_id (e: any) {
        this.reset_params()
        Vue.set(this.params_search, 'centre_id', e.target.value)
        const params = {}
        Vue.set(params, 'filter-centre_id', e.target.value)
        Vue.set(params, 'perPage', 1000)
        this.centre_id = e.target.value
        this.examinateur_select_id = null

        this.loading_equipes = true
        this.$store.dispatch('ensemble/getEnsembles', { params: params })
            .then(() => {
                this.options_ensembles = this.$store.getters['ensemble/ensembles']
                    .sort((a: EnsembleInterface, b: EnsembleInterface) =>
                        (a.type_ensemble > b.type_ensemble) ? 1 : (a.type_ensemble === b.type_ensemble ? (a.name > b.name ? 1 : -1) : -1)
                    )
            })
            .finally(() => {
                this.loading_equipes = false
            })
    }

    /**
     * Sélection d'une équipe lance la recherche des examinateurs qui lui sont associées
     */
    select_ensemble_id (e: any) {
        this.$store.state.planning.seances_examinateurs = []
        this.$store.state.planning.total = 0
        this.examinateur_select_id = null
        Vue.set(this.params_search, 'ensemble_id', e.target.value)

        if (this.params_search.centre_id === null) {
            const ensemble_temp = this.$store.state.ensemble.ensembles
                .find((e: any) => e.id ===  parseInt(this.params_search.ensemble_id))
            Vue.set(this.params_search, 'centre_id', ensemble_temp.centre_id)
        } else if (this.params_search.ensemble_id === '') {
            Vue.delete(this.params_search, 'centre_id')
            Vue.delete(this.params_search, 'ensemble_id')
        }

        let ensemble_select: any = null
        if (e.target.value) {
            ensemble_select = this.$store.getters['ensemble/ensembleById'](Number(e.target.value))

            this.options_examinateurs = []
            for (const e in ensemble_select.examinateurs) {
                ensemble_select.examinateurs[e].is_replace = false
                this.options_examinateurs.push(ensemble_select.examinateurs[e])
            }

            if (ensemble_select?.remplacants?.length !== 0) {
                for (const r in ensemble_select.remplacants) {
                    ensemble_select.remplacants[r].is_replace = true
                    this.options_examinateurs.push(ensemble_select.remplacants[r])
                }
            }
            this.options_examinateurs = _.orderBy(this.options_examinateurs, 'name', 'asc')

            // CAS de l'observateur
            if (this.$store.getters['auth/can'](Ability.INTERV_OBSERV_MATIERE_OWN)) {
                this.centre_id = ensemble_select.centre_id
                Vue.set(this.params_search, 'centre_id', ensemble_select.centre_id)
            }
        } else {
            this.getAllExaminateurs()
        }
    }

    /**
     * Lance la requete de sélection des séances examinateurs selon les filtres appliqués
     */
    rechercher () {
        this.$store.state.planning.error_plannings = null
        this.$store.commit('planning/RESET_META')
        this.$store.commit('planning/RESET_SEANCES')
        this.$store.commit('planning/RESET_ERROR')
        Vue.set(this.params_search, 'serie_id', this.serie_select_id)
        Vue.set(this.params_search, 'user_id', this.examinateur_select_id)
        Vue.set(this.params_search, 'page', 1)
        Vue.set(this.params_search, 'pdf', 0)
        Vue.set(this.params_search, 'excel', 0)

        this.recherche_temp = this.params_search

        this.$store.dispatch('planning/getSeances', { mode: 'examinateur', params : this.recherche_temp }).then(() => {
            this.setDataForGenericTab(this.$store.state.planning.seances_examinateurs)
        })
    }

    /**
     * Reset les parametres de recherche
     */
    reset_params () {
        this.params_search = {}
        this.recherche_temp = {}
        this.options_ensembles = []
        this.serie_select_id = null
        this.examinateur_select_id = null

        this.getAllExaminateurs()
    }

    /** Alimentation des lignes du tableau */
    setDataForGenericTab(poData: any, isLoadMore = false) {
        if (!isLoadMore) {
            this.dataForTab = []
        }
        if (poData) {
            for (const result of poData) {
                let centre: any = null

                if (!centre && this.$store.getters['auth/can'](Ability.INTERV_OBSERV_MATIERE_OWN) && result.creneau) {
                    centre = result.creneau.ensemble.centre

                    if (!this.dicoCentres.find((dc: any) => dc.id === centre.id)) {
                        this.dicoCentres.push(centre)
                    }
                } else {
                    centre = result.creneau.ensemble.centre
                }


                let ensemble: any = null
                let examinateur: any = null


                if (result.creneau && result.creneau.ensemble) {
                    ensemble = result.creneau.ensemble
                }

                if (result.creneau) {
                    examinateur = result.creneau.user
                }

                const matiere = this.$store.state.matiere.matieres.find((m: any) => m.id === result.epreuve.matiere_id)
                const concour = this.$store.state.concour.concours.find((c: any) => c.id === result.epreuve.concour_id)
                const filiere = result.epreuve.concour.banque_id ? concour.banque.code : result.epreuve.concour.code
                const salle = result.salle ? result.salle.name : '-'

                let candidat = ''
                if (result.candidat) {
                    candidat = result.candidat.name + ' ' + result.candidat.first_name
                    if (result.etat === etat_seance.ETAT_DEMISSIONE) {
                        candidat = '<div class="text-danger">' + candidat + ' (Démission)</div>'
                    }
                } else {
                    candidat = '<div class="text-info">Créneau disponible</div>'
                }

                const line = [
                    { label: 'serie',       item: result.serie.name,                                type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'date',        item: formatDateSinTime(result.creneau.jour),           type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'heure',       item: formatDateHoursMinutes(result.creneau.h_debut),   type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'centre',      item: centre ? centre.name : '-',                       type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'salle',       item: salle,                                            type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'equipe',      item: ensemble ? ensemble.name : '-',                   type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'examinateur', item: examinateur ? examinateur.name + ' ' + examinateur.first_name : '-',  type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'matiere',     item: matiere.name,                                     type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'code',        item: result.candidat ? result.candidat.code : '-',     type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'candidat',    item: candidat,                                         type: 'html', typeAction: null, class: 'text-start' },
                    { label: 'filiere',     item: filiere,                                          type: 'text', typeAction: null, class: 'text-start' }
                ]
                this.dataForTab.push(line)
            }
        }
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'onLoadPage':
                    this.loadHandler()
                    break
            }
        }
    }

    /**
     * Appel d'une nouvelle page de séances lors du scroll
     */
    loadHandler () {
        Vue.set(this.params_search, 'page', (this.$store.state.planning.currentPage + 1))
        this.$store.dispatch('planning/getMoreSeances', { mode: 'examinateur', params : this.params_search })
    }

    /**
     * Exporter le planning au format excel
     */
    export_excel () {
        this.$store.commit('planning/SET_LOADING_PDF', true)
        Vue.set(this.recherche_temp, 'excel', 1)
        Vue.set(this.recherche_temp, 'pdf', 0)
        Vue.set(this.recherche_temp, 'format', '')

        let fileName = ''

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours...', infosToaster)

        this.$store.dispatch('planning/getExcel', { params : this.recherche_temp })
            .then(response => {
                const fileNameTemp = getFileNameFromHeader(response.headers)
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const link = document.createElement('a')
                const url = URL.createObjectURL(new Blob([response.data],  { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }))
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Exporter le bilan journalier au format journalier
    export_excel_bilan_journalier () {
        this.$store.commit('planning/SET_LOADING_PDF', true)
        let fileName = ''

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours...', infosToaster)

        this.$store.dispatch('planning/getBilanJournalier', { params : this.recherche_temp })
            .then(response => {
                const fileNameTemp = getFileNameFromHeader(response.headers)
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const link = document.createElement('a')
                const url = URL.createObjectURL(new Blob([response.data],  { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }))
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * Affiche le PDF du planning
     */
    show_pdf_planning () {
        if (!this.$store.getters['auth/can'](Ability.INTERV_OBSERV_MATIERE_OWN)) {
            const centre_select: any = this.$store.state.centre.centres.find((c: any) => c.id.toString() === this.centre_id.toString())
            if (centre_select) {
                const currentDateFormated = formatDateDayHourDateForFileSaving(new Date(Date.now()).toISOString())
                this.pdf_filename = 'Plannings_examinateurs_' + centre_select.name + '_' + currentDateFormated + '.pdf'
            }
        } else {
            const centre_select: any = this.dicoCentres.find((c: any) => c.id === this.centre_id)
            if (centre_select) {
                const currentDateFormated = formatDateDayHourDateForFileSaving(new Date(Date.now()).toISOString())
                this.pdf_filename = 'Plannings_examinateurs_' + centre_select.name + '_' + currentDateFormated + '.pdf'
            }
        }

        Vue.set(this.recherche_temp, 'pdf', 1)
        Vue.set(this.recherche_temp, 'format', 'b64')
        Vue.set(this.recherche_temp, 'excel', 0)
        this.paramsConfirmationImpression = this.recherche_temp
        this.showPdf = true
    }

    /**
     * Fermeture du popup de PDF planning
     */
    closeModaleShowPdf () {
        this.pdfData = ''
        this.libelle_document = ''
        this.showPdf = false
    }


    mounted () {
        this.$store.state.planning.error_plannings = null

        // Gestion de l'accés par un Gestionnaire de centre
        if (this.$store.getters['auth/is_intervenant_centre']) {
            if (this.$store.state.user.centreId) {
                const e = {
                    target: {
                        value: this.$store.state.user.centreId
                    }
                }
                this.select_centre_id(e)
            } else {
                this.$store.dispatch('centre/getCentres').then(() => {
                    this.$store.state.user.centreId = this.$store.state.centre.centres[0] ? this.$store.state.centre.centres[0].id : null
                    const e = {
                        target: {
                            value: this.$store.state.user.centreId
                        }
                    }
                    this.select_centre_id(e)
                })
            }
        } if (this.$store.getters['auth/can'](Ability.INTERV_OBSERV_MATIERE_OWN)) {
            // Chargement des ensembles correspondants aux matières de l'utilisateur
            const params = {}
            const tab_matiere_id: Array<number> = []
            const da = this.$store.getters['dossieracademique/dossierAcademiqueByUserId'](this.$store.state.auth.user.id)
            if (da) {
                let  matieresTemp = null
                if(da.courant && da.courant.matieres) {
                    matieresTemp =  da.courant.matieres
                } else if (da.matieres){
                    matieresTemp =  da.matieres
                }
                if(matieresTemp !== null) {
                    for (let i = 0; i < matieresTemp.length; i++) {
                        tab_matiere_id.push(matieresTemp[i].id)
                    }
                }
            } else {
                const abilities_matieres = this.$store.state.auth.user.abilities.filter((a: any) => a.name === Ability.INTERV_OBSERV_MATIERE_OWN)
                for (const ab in abilities_matieres) {
                    if (abilities_matieres[ab].entity_id) {
                        tab_matiere_id.push(abilities_matieres[ab].entity_id)
                    }
                }
            }

            Vue.set(params, 'matiere_id', tab_matiere_id)

            this.$store.dispatch('ensemble/getEnsembles', { params: params })
                .then(() => {
                    this.options_ensembles = this.$store.getters['ensemble/ensembles']
                        .sort((a: EnsembleInterface, b: EnsembleInterface) =>
                            (a.type_ensemble > b.type_ensemble) ? 1 : (a.type_ensemble === b.type_ensemble ? (a.name > b.name ? 1 : -1) : -1)
                        )
                })
        } else {
            this.getCentres()
        }

        this.getAllExaminateurs()

        if (this.options_series.length === 0) {
            this.getSeries()
        }
    }

    clearResult() {
        this.$store.commit('planning/RESET_META')
        this.$store.commit('planning/RESET_SEANCES')
    }
}
