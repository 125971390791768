








































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import PopupEditCandidat from '@/components/Candidat/PopupEditCandidat.vue'
import PopupCreerReclamation from '@/components/Reclamations/PopupCreerReclamation.vue'
import { formatDate, formatDateHoursMinutes, formatDateSinTime, getFileNameFromHeader, truncateString, format_phone_number } from '@/utils/helpers'
import { Etat, TypeReclamation } from '@/types/Reclamation'
import { TypeAdresseCentre } from '@/types/AdresseCentre'
import Marqueur from '@/views/Reclamations/Marqueur.vue'
import PopupInfosEtDocumentsExaminateur from '@/views/Reclamations/PopupInfosEtDocumentsExaminateur.vue'
import { etat_avis } from '@/types/ReclamationOral'

/**
 * Composant contenant l'ensemble des candidats
 */
@Component({
    computed: {
        TypeReclamation() {
            return TypeReclamation
        },
        Ability() {
            return Ability
        },
        ...mapGetters('reclamationOral', ['loading', 'reclamations_oral', 'meta', 'links', 'totalRows', 'lastPage', 'totalPage']),
        ...mapGetters('matiere', ['getMatiereById']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id'])
    },
    components: {
        ExaGenericTable,
        PopupEditCandidat,
        PopupCreerReclamation,
        PopupInfosEtDocumentsExaminateur,
        Marqueur,
        'font-awesome-icon': FontAwesomeIcon
    }
})

export default class ReclamationsOral extends Vue {
    showModalEditCandidat?: boolean = false
    showModalEditReclamation?: boolean = false
    showModalCreerReclamation?: boolean = false
    tabSelected = ''
    tabSelectedCandidat = 'resultats'
    showModaleMark = false
    filter = '';
    filtres: any = []
    dataForTab: Array<any> = []
    selectReclamation: any = null
    avancementReclamation: any = null
    exportingIsWorking = false
    paramsTemp = {}
    compteursReclamations: Array<any> = []
    popInfoExaminateurShow = false
    dataExaminateurTemp: any = null

    // Ensemble des colonnes du tableau des réclamations
    genericfields = [
        { key: 'marqueur',            label: 'Marqueur',           sortable: true,  class: 'text-center', type: 'action' },
        { key: 'commentaires',        label: '',                   sortable: false, class: '',            type: 'action' },
        { key: 'candidat.code',       label: 'Code',               sortable: true,  class: '',            type: 'actionText' },
        { key: 'candidat.identite',   label: 'Identité',           sortable: true,  class: '',            type: 'actionText' }, // Nom + prénom
        { key: 'candidat.concour_id', label: 'Filière',            sortable: false, class: 'text-start',  type: 'popover' },
        { key: 'epreuve.name',        label: 'Épreuve',            sortable: true,  class: 'text-start',  type: 'popover' },
        { key: 'message_candidat',    label: 'Message candidat',   sortable: false, class: 'text-start',  type: 'popover' },
        { key: 'date_demande',        label: 'Date de la demande', sortable: false, class: 'text-start',  type: 'text' },
        { key: 'date_seance',         label: 'Date de la séance',  sortable: false, class: 'text-start',  type: 'text' },
        { key: 'examinateur',         label: 'Examinateur',        sortable: false, class: 'text-start',  type: 'text' },
        { key: 'centre',              label: 'Centre',             sortable: false, class: 'text-start',  type: 'text' },
        { key: 'etat',                label: 'Avis observateur',   sortable: true,  class: 'text-center', type: 'icons' },
        { key: 'decision',            label: 'Nouvelle séance',    sortable: true,  class: 'text-center', type: 'icons' },
        { key: 'avancement',          label: 'Avancement',         sortable: true,  class: 'text-center', type: 'icons' },
        { key: 'etatEdit',            label: '',                   sortable: false, class: '',            type: 'action' }
    ]

    genericfields_obs = [
        { key: 'candidat.code',       label: 'Code',               sortable: true,  class: '',             type: 'actionText' },
        { key: 'candidat.identite',   label: 'Identité',           sortable: true,  class: '',             type: 'actionText' }, // Nom + prénom
        { key: 'candidat.concour_id', label: 'Filière',            sortable: false, class: 'text-start',   type: 'popover' },
        { key: 'epreuve.name',        label: 'Épreuve',            sortable: true,  class: 'text-start',   type: 'popover' },
        { key: 'message_candidat',    label: 'Message candidat',   sortable: false, class: 'text-start',   type: 'popover' },
        { key: 'date_demande',        label: 'Date de la demande', sortable: false, class: 'text-start',   type: 'text' },
        { key: 'date_seance',         label: 'Date de la séance',  sortable: false, class: 'text-start',   type: 'text' },
        { key: 'examinateur',         label: 'Examinateur',        sortable: false, class: 'text-start',   type: 'text' },
        { key: 'centre',              label: 'Centre',             sortable: false, class: 'text-start',   type: 'text' },
        { key: 'etat',                label: 'Avis observateur',   sortable: true,  class: 'text-center',  type: 'icons' },
        { key: 'etatEdit',            label: '',                   sortable: false, class: '',             type: 'action' }
    ]

    @Watch('reclamations_oral')
    majDataForGenericTab(): void {
        if (this.$store.getters['auth/can'](Ability.INTERV_OBSERV_MATIERE_OWN)) {
            this.initDatas()
            this.initStatsReclamations()
        } else {
            const params = {}
            Vue.set(params, 'filter-type', TypeReclamation.TYPE_PASSATION_ORAL)
            this.$store.dispatch('reclamationOral/getReclamationsCompteurs', { filters : params }).then(() => {
                this.initDatas()
                this.initStatsReclamations()
            })
        }
    }

    @Watch('user_session_id')
    refreshInterface(): void {
        // force le refresh
        localStorage.setItem('reclamationOralParams', JSON.stringify({}))
        this.paramsTemp = {}
        this.load()
    }

    /**
     * @description Formatage des datas pour l'affichage dans le tableau générique
     * @param {any} poData
     * @param {boolean} isLoadMore
     * @returns {void}
     */
    setDataForGenericTab(poData: any, isLoadMore = false): void {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const result of poData) {
                const styleButtonFicheCandidat = 'text-tertiary commons_comment_button text-center fa-2x'
                const iconButtonFicheCandidat = []
                iconButtonFicheCandidat.push({ name:'address-card', class: styleButtonFicheCandidat })
                // Puce Saisie examinateur
                const puceAvancement = []
                const puceDecision: Array<any> = []
                let clickIsPossible = true

                if (result) {
                    if (result.rejected_at) {
                        puceAvancement.push({ name:'circle', class: 'text-danger', title: 'Réclamation rejetée' })
                    } else if (
                        (!result.submitted_at && !result.validated_at) && (result.note || result.message_gestionnaire) ||
                        (!result.submitted_at && !result.validated_at && result.etat === etat_avis.ETAT_PUBLIE_OBSERVATEUR)
                    ) {
                        puceAvancement.push({ name:'circle', class: 'text-info', title: 'Réclamation en cours' })
                    } else if (result.submitted_at && !result.validated_at) {
                        puceAvancement.push({ name:'circle', class: 'text-success', title: 'Réclamation traitée - En attente de signature' })
                    } else if (result.submitted_at && result.validated_at) {
                        puceAvancement.push({ name:'check', class: 'text-success', title: 'Signée le ' +  formatDate(result.validated_at) })
                        clickIsPossible = false
                    } else if (!result.submitted_at && !result.validated_at) {
                        puceAvancement.push({ name:'circle', class: 'text-secondary', title: 'Réclamation non traitée' })
                    }

                    // Puce de nouvelle séance
                    if (result.decision) {
                        puceDecision.push({ name:'calendar-check', class: 'text-secondary', title: '' })
                    }
                }

                // Colonne commentaires
                let styleButtonComment = 'text-tertiary commons_comment_button text-center'
                let nameIcon = 'comment-alt'
                if (result.candidat.comments.length !== 0) {
                    styleButtonComment = 'commons_comment_button text-center'

                    if (result.candidat.comments.filter((c: any) => c.important === 1).length === 0) {
                        nameIcon = 'comment-alt'
                    } else {
                        nameIcon = 'comment-alt-exclamation'
                    }
                }

                let tooltipComments = ''
                for (const c in result.candidat.comments) {
                    tooltipComments += result.candidat.comments[c].important ? '---------------TACHE A FAIRE---------------\n' : '---------------COMMENTAIRE---------------\n'
                    tooltipComments += result.candidat.comments[c].body
                    tooltipComments += '\n'
                }

                // Marqueur de la réclamation
                let letterMark = '-'
                let letterStyle = 'text-center mark_style_none'
                if (result.marqueur !== '0' && result.marqueur !== null) {
                    letterMark = result.marqueur
                    letterStyle = 'text-center mark_style'
                }

                const link = [{ name:'arrow-circle-right', class: 'text-info', id: result.id, result: result }]
                const puceObservateur = []
                if (result.etat === etat_avis.ETAT_CLOTURE_OBSERVATEUR) {
                    puceObservateur.push({ name:'circle', class: 'text-secondary', id: result.id, title: 'Conversation avec l\'observateur clôturée' })
                } else if (result.etat === etat_avis.ETAT_PUBLIE_OBSERVATEUR) {
                    puceObservateur.push({ name:'circle', class: 'text-success', id: result.id, title: 'Conversation avec l\'observateur ouverte' })
                } else if (result.etat === etat_avis.ETAT_NON_PUBLIEE) {
                    puceObservateur.push({ name:'circle', class: 'text-tertiary', id: result.id, title: 'Conversation avec l\'observateur non initiée' })
                }
                const dateSeance = result.seance && result.seance.creneau ? formatDateSinTime(result.seance.creneau.jour) + ' à ' + formatDateHoursMinutes(result.seance.creneau.h_debut) : '-'

                let examinateur = null
                let centre: any = null
                let centreAdresse: any = null
                let listeEquipesCentre = ''
                const ensemble = result.seance && result.seance.creneau ? this.$store.state.ensemble.ensembles.find((e: any) => e.id === result.seance.creneau.ensemble_id) : null
                if (ensemble) {
                    centre = result.seance.creneau.ensemble.centre
                    centreAdresse = centre.adresses.find((a: any) => a.type === TypeAdresseCentre.TYPE_PRINCIPALE)
                    const equipesCentre = this.$store.state.ensemble.ensembles.filter((e: any) => e.centre_id === ensemble.centre_id)
                    for (const ec in equipesCentre) {
                        if (Number(ec) === (equipesCentre.length - 1)) {
                            listeEquipesCentre += equipesCentre[ec].name
                        } else {
                            listeEquipesCentre += equipesCentre[ec].name + ', '
                        }
                    }

                    if (ensemble) {
                        examinateur = ensemble.examinateurs.find((ex: any) => ex.id === result.seance.creneau.user_id)

                        if (!examinateur) {
                            examinateur = ensemble.remplacants.find((ex: any) => ex.id === result.seance.creneau.user_id)
                        }
                    }
                }

                let line = []
                if (this.$store.getters['auth/can'](Ability.INTERV_OBSERV_MATIERE_OWN)) {
                    line = [
                        {
                            label: '',
                            item: result.candidat.code,
                            type: 'popover',

                            typeAction: null,
                            class: 'text-start text-info item_action',
                            popoverOptions: {
                                affichable: true,
                                titre: 'Informations candidat',
                                contents: [
                                    { content: result.candidat.name + ' ' + result.candidat.first_name, bold: true },
                                    { content: '<span class="text-secondary">Téléphone | Mobile : </span><span class="fw-bold">' + format_phone_number(result.candidat.telephone) + ' | ' + result.candidat.portable + '</span>' },
                                    { content: '<span class="text-secondary">Courriel : </span><span class="fw-bold">' + result.candidat.email + '</span>' },
                                    { content: '<span class="text-secondary">Centre : </span><span class="fw-bold">' + (centre ? centre.name : '-') + '</span>' }
                                ],
                                html: true
                            }
                        },
                        {
                            label: '',
                            item: result.candidat.name + ' ' + result.candidat.first_name,
                            type: 'popover',

                            typeAction: null,
                            class: 'text-start text-info item_action',
                            popoverOptions: {
                                affichable: true,
                                titre: 'Informations candidat',
                                contents: [
                                    { content: result.candidat.name + ' ' + result.candidat.first_name, bold: true },
                                    { content: '<span class="text-secondary">Téléphone | Mobile : </span><span class="fw-bold">' + format_phone_number(result.candidat.telephone) + ' | ' + result.candidat.portable + '</span>' },
                                    { content: '<span class="text-secondary">Courriel : </span><span class="fw-bold">' + result.candidat.email + '</span>' },
                                    { content: '<span class="text-secondary">Centre : </span><span class="fw-bold">' + (centre ? centre.name : '-') + '</span>' }
                                ],
                                html: true
                            }
                        },
                        { label: '',               item: result.candidat.concour.name,                 type: 'text',       typeAction: null,                  class: 'text-secondary text-start' },
                        { label: '',               item: result.epreuve.name,                          type: 'text',       typeAction: null,                  class: 'text-secondary text-start' },
                        { label: '',               item: truncateString(result.message_candidat, 25),  type: 'text',       typeAction: null,                  class: 'text-secondary text-start reclamation_graylight_col', data_title: result.message_candidat, data_title_location: 'right' },
                        { label: '',               item: formatDate(result.created_at),                type: 'text',       typeAction: null,                  class: 'text-secondary text-start' },
                        { label: '',               item: dateSeance,                                  type: 'text',       typeAction: null,                  class: 'text-secondary text-start' },
                        {
                            label: '',
                            item: examinateur ? examinateur.name + ' ' + examinateur.first_name : '-',
                            type: 'popover',

                            typeAction: null,
                            class: 'text-start text-dark item_action',
                            popoverOptions: {
                                affichable: true,
                                titre: 'Informations examinateur',
                                contents: [
                                    { content: examinateur ? examinateur.name + ' ' + examinateur.first_name : '-', bold: true },
                                    { content: '<span class="text-secondary">Téléphone : </span><span class="fw-bold">' + (examinateur ? format_phone_number(examinateur.telephone) : '-') + '</span>' },
                                    { content: '<span class="text-secondary">Courriel : </span><span class="fw-bold">' + (examinateur ? examinateur.email : '-') + '</span>' },
                                    { content: '<span class="text-secondary">Équipe : </span><span class="fw-bold">' + (ensemble ? ensemble.name : '-') + '</span>' },
                                    { content: '<span class="text-secondary">Matière : </span><span class="fw-bold">' + (examinateur ? examinateur.name + ' ' + examinateur.first_name : '-') + '</span>' },
                                    { content: '<span class="text-secondary">Centre : </span><span class="fw-bold">' + (centre ? centre.name : '-') + '</span>' }
                                ],
                                html: true
                            }
                        },
                        {
                            label: '',
                            item: centre ? centre.name : '-',
                            type: 'popover',
                            typeAction: null,
                            class: 'text-start text-dark item_action',
                            popoverOptions: {
                                affichable: true,
                                titre: 'Informations centre',
                                contents: [
                                    { content: centre ? centre.name : '-', bold: true },
                                    { content: '<span class="text-secondary">Chef de centre : </span><span class="fw-bold">' + (centre && centre.gestionnaire ? centre.gestionnaire.name + ' ' + centre.gestionnaire.first_name : '-') + '</span>' },
                                    { content: '<span class="fw-bold">' + (centre && centre.gestionnaire && centre.gestionnaire.telephone ? format_phone_number(centre.gestionnaire.telephone) : '-') + '</span> | <span class="fw-bold">' + (centre && centre.gestionnaire && centre.gestionnaire.email ? centre.gestionnaire.email : '-') + '</span>' },
                                    { content: '<span class="text-secondary">Téléphone permanence : </span><span class="fw-bold">' + (centre && centre.telephone ? format_phone_number(centre.telephone) : '-') + '</span>' },
                                    { content: '<span class="text-secondary">Courriel du centre : </span><span class="fw-bold">' + (centre && centre.email_contact_candidat ? centre.email_contact_candidat : '-') + '</span>' },
                                    { content: '<span class="text-secondary">Equipes : </span><span class="fw-bold">' + (centre ? listeEquipesCentre : '-') + '</span>' },
                                    { content: '<span class="text-secondary">Adresse : </span><span class="fw-bold">' + (centreAdresse ? centreAdresse.adresse : '-') + '</span>' }
                                ],
                                html: true
                            }
                        },
                        { label: '',               item: puceObservateur,     type: 'icons',      typeAction: null,                  class: 'text-center' },
                        { label: 'link',           item: link,                 type: 'icons',      typeAction: 'link',                class: 'text-center' }
                    ]
                } else {
                    line = [
                        { label: 'Changer le marqueur', item: result.id,                               type: 'actionText', typeAction: clickIsPossible ? 'mark' : '',                class: letterStyle, text: letterMark, disabled: false },
                        { label: tooltipComments, item: result.candidat_id,                           type: 'action',     typeAction: 'openComment',         class: styleButtonComment, icon: nameIcon, disabled: false },
                        { label: 'Fiche candidat', item: result.candidat_id,                           type: 'actionText', typeAction: 'editCandidat',        class: 'text-info item_action', text: result.candidat.code },
                        { label: 'Fiche candidat', item: result.candidat_id,                           type: 'actionText', typeAction: 'editCandidat',        class: 'text-info item_action', text: result.candidat.name + ' ' + result.candidat.first_name },
                        { label: '',               item: result.candidat.concour.name,                 type: 'text',       typeAction: null,                  class: 'text-secondary text-start' },
                        { label: '',               item: result.epreuve.name,                          type: 'text',       typeAction: null,                  class: 'text-secondary text-start' },
                        { label: '',               item: truncateString(result.message_candidat, 25),  type: 'text',       typeAction: null,                  class: 'text-secondary text-start reclamation_graylight_col', data_title: result.message_candidat, data_title_location: 'right' },
                        { label: '',               item: formatDate(result.created_at),                type: 'text',       typeAction: null,                  class: 'text-secondary text-start' },
                        { label: '',               item: dateSeance,                                  type: 'text',       typeAction: null,                  class: 'text-secondary text-start' },
                        { label: '', item: { examinateur: examinateur, centre: centre, ensemble: ensemble, matiere_id: result.epreuve.matiere_id }, type: 'actionText', typeAction: 'popInfoExaminateur', class: 'text-info item_action', text: examinateur ? examinateur.name + ' ' + examinateur.first_name : '-' },
                        {
                            label: '',
                            item: centre ? centre.name : '-',
                            type: 'popover',
                            typeAction: null,
                            class: 'text-start text-dark item_action',
                            popoverOptions: {
                                affichable: true,
                                titre: 'Informations centre',
                                contents: [
                                    { content: centre ? centre.name : '-', bold: true },
                                    { content: '<span class="text-secondary">Chef de centre : </span><span class="fw-bold">' + (centre && centre.gestionnaire ? centre.gestionnaire.name + ' ' + centre.gestionnaire.first_name : '-') + '</span>' },
                                    { content: '<span class="fw-bold">' + (centre && centre.gestionnaire && centre.gestionnaire.telephone ? format_phone_number(centre.gestionnaire.telephone) : '-') + '</span> | <span class="fw-bold">' + (centre && centre.gestionnaire && centre.gestionnaire.email ? centre.gestionnaire.email : '-') + '</span>' },
                                    { content: '<span class="text-secondary">Téléphone permanence : </span><span class="fw-bold">' + (centre && centre.telephone ? format_phone_number(centre.telephone) : '-') + '</span>' },
                                    { content: '<span class="text-secondary">Courriel du centre : </span><span class="fw-bold">' + (centre && centre.email_contact_candidat ? centre.email_contact_candidat : '-') + '</span>' },
                                    { content: '<span class="text-secondary">Equipes : </span><span class="fw-bold">' + (centre ? listeEquipesCentre : '-') + '</span>' },
                                    { content: '<span class="text-secondary">Adresse : </span><span class="fw-bold">' + (centreAdresse ? centreAdresse.adresse : '-') + '</span>' }
                                ],
                                html: true
                            }
                        },
                        { label: '',               item: puceObservateur,     type: 'icons',      typeAction: null,                  class: 'text-center' },
                        { label: '',               item: puceDecision,        type: 'icons',      typeAction: null,                  class: 'text-center' },
                        { label: '',               item: puceAvancement,      type: 'icons',      typeAction: null,                  class: 'text-center' },
                        { label: 'link',           item: link,                 type: 'icons',      typeAction: 'link',                class: 'text-center' }
                    ]
                }

                this.dataForTab.push(line)
            }
        }
    }

    /**
     * @description Formatage des datas pour l'affichage dans le tableau générique
     * @returns {void}
     */
    setFiltersForGenericTab(): void {
        this.filtres = []
        // Options filières
        const filieres = this.$store.getters['concour/banques']
        const optionsFilieres = []
        for (const f in filieres) {
            optionsFilieres.push({ index: filieres[f].id, name: filieres[f].name })
        }

        // Options matières (épreuves)
        const matieres = this.$store.getters['matiere/matieres']
        const optionsMatieres = []
        for (const m in matieres) {
            optionsMatieres.push({ index: matieres[m].id, name: matieres[m].name })
        }

        const rootStore = this.$store.state.reclamation
        const optionsAvancement = []
        optionsAvancement.push({ index: Etat.ETAT_TRAITE, name: 'Réclamations traitées' })
        optionsAvancement.push({ index: Etat.ETAT_SIGNE, name: 'Réclamations signées' })
        optionsAvancement.push({ index: Etat.ETAT_NON_TRAITE, name: 'Réclamations non traitées' })
        optionsAvancement.push({ index: Etat.ETAT_REJETEE, name: 'Réclamations rejetées' })

        // Options filtres marqueurs
        const optionsMarqueurs = []
        optionsMarqueurs.push({ index: 'A', name: 'A' })
        optionsMarqueurs.push({ index: 'B', name: 'B' })
        optionsMarqueurs.push({ index: 'C', name: 'C' })
        optionsMarqueurs.push({ index: 'D', name: 'D' })
        optionsMarqueurs.push({ index: 'E', name: 'E' })
        optionsMarqueurs.push({ index: 'F', name: 'F' })

        this.filtres = [
            { libelle: 'Marqueur',        defautOptionlibelle: 'Rechercher un',     model: 'marqueur', value: '', index: 'marqueur',  datas: optionsMarqueurs,        loading: rootStore.loading, options: { type: 'deroulant',      fieldsKey: 'marqueur', strict: true } },
            { libelle: 'Code',            defautOptionlibelle: 'Rechercher un',     model: 'candidat.code', value: '', index: 'candidat.code',  datas: rootStore.code,        loading: rootStore.loading, options: { type: 'form',      fieldsKey: 'candidat.code', strict: true } },
            { libelle: 'Identité',        defautOptionlibelle: 'Rechercher un',     model: 'candidat.name', value: '', index: 'candidat.name',  datas: rootStore.identite,    loading: rootStore.loading, options: { type: 'form',      fieldsKey: 'candidat.identite' } },
            { libelle: 'Filière',         defautOptionlibelle: 'Rechercher une',    model: 'candidat.concour_id', value: '', index: 'candidat.concour_id',        datas: optionsFilieres,      loading: rootStore.loading, options: { type: 'deroulant', fieldsKey: 'candidat.concour_id' } },
            { libelle: 'Épreuves',        defautOptionlibelle: 'Rechercher une',    model: 'epreuve.name',  value: '', index: 'epreuve.name',   datas: optionsMatieres,      loading: rootStore.loading, options: { type: 'form',      fieldsKey: 'epreuve.name' } },
            { libelle: 'Avancement',      defautOptionlibelle: 'Rechercher un',     model: 'avancement',  value: '', index: 'avancement',   datas: optionsAvancement,      loading: rootStore.loading, options: { type: 'deroulant',      fieldsKey: 'avancement' } }
        ]
    }

    /**
     * @description Récupération des events du tableau (params[0] => l'action, params[1] => l'id de l'item)
     * @param {any} paParams
     * @returns {void}
     */
    handleTableEvent(paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            const reclamations      = this.$store.state.reclamationOral.reclamations_oral
            let selectedReclamation = null
            let selectedCandidat    = null

            switch (paParams[0]) {
                case 'link':
                    if (paParams[1] && paParams[1][0] && paParams[1][0].result) {
                        const result = paParams[1][0].result
                        selectedReclamation = reclamations.filter((reclamation: any) => reclamation.id === result.id)[0]
                        if (selectedReclamation) {
                            this.editOralReclamation(selectedReclamation)
                        }
                    }
                    break

                case 'editCandidat':
                    // Récupération de l'étab by ID
                    this.tabSelectedCandidat = 'resultats'
                    selectedCandidat = reclamations.filter((r: any) => r.candidat_id === paParams[1])[0]
                    if (selectedCandidat) {
                        this.editCandidat(selectedCandidat.candidat)
                    }
                    break

                case 'openComment':
                    // Récupération de l'étab by ID
                    this.tabSelectedCandidat = 'commentaires'
                    selectedCandidat = reclamations.filter((r: any) => r.candidat_id === paParams[1])[0]
                    if (selectedCandidat) {
                        this.editCandidat(selectedCandidat.candidat)
                    }
                    break

                case 'mark':
                    this.$store.state.reclamation.error = null
                    selectedReclamation = reclamations.filter((r: any) => r.id === paParams[1])[0]
                    if (selectedReclamation) {
                        this.openMarkSelector(selectedReclamation)
                    }
                    break

                case 'popInfoExaminateur':
                    this.showPopInfoExaminateur(paParams[1])
                    break

                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break

                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break

                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
            }
        }
    }

    showPopInfoExaminateur(data: any): void {
        this.dataExaminateurTemp = data
        this.popInfoExaminateurShow = true
    }

    closePopInfoExaminateur(): void {
        this.dataExaminateurTemp = null
        this.popInfoExaminateurShow = false
    }

    /**
     * @description Récupération sur le serveur des concours
     * @returns {Promise<boolean>}
     */
    loadConcoursIfNotExists(): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.$store.getters['concour/banques'].length === 0) {
                this.$store.dispatch('concour/getConcoursActifs')
                    .then(() => resolve(true))
            } else {
                resolve(true)
            }
        })
    }

    /**
     * @description Récupération sur le serveur des matieres
     * @returns {Promise<boolean>}
     */
    loadMatieresIfNotExists(): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.$store.getters['matiere/matieres'].length === 0) {
                this.$store.dispatch('matiere/getMatieres')
                    .then(() => resolve(true))
            } else {
                resolve(true)
            }
        })
    }

    /**
     * @description Intitialisation des statistiques réclamations de la phase "oral"
     * @returns {void}
     */
    initStatsReclamations(): void {
        this.compteursReclamations = this.$store.getters['reclamationOral/getReclamations_oral_compteurs']
    }

    /**
     * @description Initialisation des données réclamations affichées dans le tableau
     * @returns {void}
     */
    initDatas(): void {
        this.setDataForGenericTab(this.$store.state.reclamationOral.reclamations_oral)
    }

    /**
     * @description Appel d'une nouvelle page de candidats lors du scroll
     * @param {any} params
     * @returns {void}
     */
    loadHandler(params: any): void {
        Vue.set(params, 'filter-type', TypeReclamation.TYPE_PASSATION_ORAL)
        this.$store.dispatch('reclamationOral/getMoreReclamations', { filters : params })
    }

    /**
     * @description Appel des datas avec un sort en paramètres
     * @param {any} params
     * @returns {void}
     */
    filtreSortHandler (params: any): void {
        Vue.set(params, 'filter-type', TypeReclamation.TYPE_PASSATION_ORAL)
        localStorage.setItem('reclamationOralParams', JSON.stringify(params))
        this.$store.dispatch('reclamationOral/getReclamations', { type: 'ORAL', filters: params })
    }

    /**
     * @description Édition d'une réclamation : enregistrement du candidat en tant que candidat selectionnée et affichage de la modale
     * @param {any} item
     * @returns {void}
     */
    editOralReclamation(item: any): void {
        if (item && item.id !== undefined) {
            if (this.$store.getters['auth/can'](Ability.INTERV_OBSERV_MATIERE_OWN)) {
                this.$router.push(`reclamations_observateur/${item.id}`)
            } else {
                this.$router.push(`reclamations_oral/${item.id}`)
            }
        }
    }

    /**
     * @description Édition d'un candidat: enregistrement du candidat en tant que candidat sélectionné et affichage de la modale
     * @param {any} item
     * @returns {void}
     */
    editCandidat(item: any): void {
        if (item && item.id !== undefined) {
            // On charge à partir de la BDD l'ensemble des informations du candidat sélectionné
            this.$store.commit('reclamationOral/SET_LOADING', true) // on lance le loading
            this.$store.dispatch('candidat/getCandidat', item).then(() => {
                this.$store.commit('candidat/SET_CANDIDAT_FILIERE', {
                    candidatId: item.id,
                    nomFiliere: item.filiere
                })
                this.$store.commit('candidat/SET_SELECTED_CANDIDAT', item.id)
                this.showModalEditCandidat = true
                this.$store.commit('reclamationOral/SET_LOADING', false) // on lance le loading
            })
        }
    }

    /**
     * @description Exporter les réclamations
     * @returns {void}
     */
    exporterReclamations(): void {
        if (this.exportingIsWorking) {
            return
        }
        this.exportingIsWorking = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours...', infosToaster)

        const params = {}
        Vue.set(params, 'filter-type', TypeReclamation.TYPE_PASSATION_ORAL)

        this.$store.dispatch('reclamation/export', params)
            .then((response) => {
                // Logique de téléchargement
                const link = document.createElement('a')
                link.href = URL.createObjectURL(new Blob([response.data]))
                link.setAttribute('Download', getFileNameFromHeader(response.headers) || "Export_reclamations.xlsx")
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.exportingIsWorking = false
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Exporter les statistiques réclamations
     * @returns {void}
     */
    exporterReclamationsStatistiques(): void {
        if (this.exportingIsWorking) {
            return
        }
        this.exportingIsWorking = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours...', infosToaster)

        const params = {}
        Vue.set(params, 'filter-type', TypeReclamation.TYPE_PASSATION_ORAL)

        this.$store.dispatch('reclamation/exportStatistiques', params)
            .then((response) => {
                // Logique de téléchargement
                const link = document.createElement('a')
                link.href = URL.createObjectURL(new Blob([response.data]))
                link.setAttribute('Download', getFileNameFromHeader(response.headers) || "Export_statistiques_reclamations.xlsx")
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.exportingIsWorking = false
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Ajouter une réclamation
     * @returns {void}
     */
    openCreerReclamation(): void {
        this.showModalCreerReclamation = true
    }

    /**
     * @description Fermeture de la modale d'édition d'une réclamation
     * @returns {void}
     */
    reinitShowModalEditReclamation(): void {
        this.$store.commit('reclamationOral/SET_RECLAMATION_SELECT', {}) // reset de la réclamation sélectionnée
        this.$store.commit('reclamationOral/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
        this.initDatas()
        this.initStatsReclamations()
        this.showModalEditReclamation = false
    }

    /**
     * @description Fermeture de la modale d'édition d'un candidat
     * @returns {void}
     */
    reinitShowModalEditCandidat(): void {
        this.showModalEditCandidat = false
    }

    /**
     * @description Fermeture de la modale d'ajout d'une réclamation'
     * @returns {void}
     */
    reinitShowModalCreerReclamation(): void {
        this.showModalCreerReclamation = false
        const params = {}
        Vue.set(params, 'filter-type', TypeReclamation.TYPE_PASSATION_ORAL)
        localStorage.setItem('reclamationOralParams', JSON.stringify(params))
        this.$store.dispatch('reclamationOral/getReclamations', { filters : params })
    }

    /**
     * @description Ouvre le popup de sélection d'un marqueur
     * @param {any} reclamation
     * @returns {void}
     */
    openMarkSelector(reclamation: any): void {
        this.selectReclamation = reclamation
        this.avancementReclamation = this.getEtatAvancementReclamation()
        this.showModaleMark = true
    }

    getEtatAvancementReclamation(): number {
        let etatFinal = Etat.ETAT_NON_TRAITE
        if (!this.selectReclamation.submitted_at && !this.selectReclamation.validated_at) {
            if (this.selectReclamation.message_gestionnaire || this.selectReclamation.etat === etat_avis.ETAT_PUBLIE_OBSERVATEUR) {
                // Le traitement à commencer, mais n'est pas terminé
                etatFinal = Etat.ETAT_EN_COURS
            } else {
                etatFinal = Etat.ETAT_NON_TRAITE
            }
        }
        if (this.selectReclamation.submitted_at && !this.selectReclamation.validated_at) {
            etatFinal = Etat.ETAT_TRAITE
        }
        if (this.selectReclamation.submitted_at && this.selectReclamation.validated_at) {
            etatFinal = Etat.ETAT_SIGNE
        }
        return etatFinal
    }

    /**
     * @description Annule la modification du marqueur sur une réclamation
     * @param {boolean} withSave
     * @returns {void}
     */
    closeModalMark(withSave: boolean): void {
        this.showModaleMark = false
        this.selectReclamation = null

        if (withSave) {
            const params = {}
            Vue.set(params, 'filter-type', TypeReclamation.TYPE_PASSATION_ORAL)
            localStorage.setItem('reclamationOralParams', JSON.stringify(params))
            this.$store.dispatch('reclamationOral/getReclamations', { filters : params })
        }
    }

    load(): void {
        this.$store.commit('reclamationOral/SET_LOADING', true) // lancement du loading

        this.$store.dispatch('ensemble/getEnsembles').then(() => {
            this.$store.dispatch('epreuve/getEpreuves', { isPrecedente: false, filters: {} }).then(() => {
                // Si aucun concours n'a été chargé, alors on les récupère
                this.loadConcoursIfNotExists().then(() => {
                    // Si les matières ne sont pas chargées, alors on les récupère
                    this.loadMatieresIfNotExists().then(() => {
                        this.$store.commit('reclamationOral/SET_LOADING', false) // fin du loading
                        this.$store.state.centre.centres = []
                        this.setFiltersForGenericTab()
                    }).catch(err => {
                        this.$store.commit('reclamationOral/SET_LOADING', false) // fin du loading
                        console.log('ReclamationsOral : erreur chargement des matières: ', err)
                    })
                }).catch(err => {
                    this.$store.commit('reclamationOral/SET_LOADING', false) // fin du loading
                    console.log('ReclamationsOral : erreur chargement des concours : ', err)
                })
            }).catch(err => {
                this.$store.commit('reclamationOral/SET_LOADING', false) // fin du loading
                console.log('ReclamationsOral : erreur chargement des épreuves : ', err)
            })
        }).catch(err => {
            this.$store.commit('reclamationOral/SET_LOADING', false) // fin du loading
            console.log('ReclamationsOral : erreur chargement des ensembles : ', err)
        })
    }

    /**
     * @description Au montage du composant, on charge la session et les réclamations
     * @returns {void}
     */
    mounted(): void {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
