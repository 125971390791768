







import { Vue, Component } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'

@Component({
    components: {
    },
    computed: {
        ...mapState('dossieracademique', ['hasAvatar', 'isValide', 'error']),
        ...mapGetters('auth', ['authUser']),
        ...mapGetters('dossieracademique', ['dossierAcademiqueSelect', 'dacaConcours', 'avatar', 'loading', 'posteSelect', 'isValide', 'error'])
    },
    methods: {
        ...mapActions('dossieracademique', ['getDossierAcademique', 'updateDossierAcademique'])
    }
})
export default class Adresses extends Vue {

}
