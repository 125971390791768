



















































import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { getError } from '@/utils/helpers'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    components: {
        ErrorDisplay,
        FontAwesomeIcon
    }
})

export default class PopupPlacementCandidats extends Vue {
    @Prop({ default: false }) show!: boolean

    loading = false

    /**
     * closeModal
     * @description Ferme la modale
     * @return {void}
     */
    closeModal(): void {
        this.$emit('close')
    }

    /**
     * savePlacementCandidats
     * @description Enregistre le placement des candidats
     * @return {void}
     */
    savePlacementCandidats(): void {
        if (this.loading) {
            return
        }
        this.loading = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Placement en cours ...', infosToaster)

        this.$store.dispatch('repartitioncandidats/postPlacementCandidats')
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Placement effectué !', succesToaster)

                this.$emit('reload')
                this.closeModal()
            })
            .catch((error) => {
                const idError = 't_error_' + Math.random()
                const errorToaster = {
                    id: idError,
                    toaster: 'b-toaster-top-right',
                    variant: 'danger',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast(getError(error), errorToaster)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.loading = false
            })
    }
}
