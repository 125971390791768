























































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { formatStringDate } from '@/utils/helpers'
/**
 * Composant tableau permettant l'implementation dans une colonne d'éléments interactifs (interrupteurs)
 */
@Component({
    filters: {
        /**
         * Filtre permettant de formatter les dates dans les colonnes
         * de type dates
         */
        formatDate (date: string, format: string) {
            if (date) {
                if (format) {
                    return formatStringDate(date, format)
                } else {
                    return formatStringDate(date, 'MM/DD/YYYY HH:mm')
                }
            }
        }
    }
})
export default class TableSelection extends Vue {
    @Prop() fields?: Array<any>; // Structures des colonnes
    @Prop() datas?: Array<any>; // Structures des données présentes dans le tableau
    @Prop() lineStyle?: string
    @Prop() colorPairLine?: string
    @Prop() colorImpairLine?: string

    colorPair?: string = 'f6f7fa'
    colorImpair?: string = 'f6f7fa'

    /**
     * Initialisations au montage du composant
     */
    mounted() {
        if (this.$props.colorPairLine) {
            this.colorPair = this.$props.colorPairLine
        }

        if (this.$props.colorImpairLine) {
            this.colorImpair = this.$props.colorImpairLine
        }
    }

    /**
     * Lorsqu'on change la valeur d'un interupteur, on transmet à son composant parent
     * un event avec la valeur de l'interrupteur (booleen), l'identifiant de la colonne
     * et l'identifiant de la ligne
     */
    changeValueSwitch (lineId: string, fieldId: string, value: any) {
        const params: any = {
            lineId: lineId,
            fieldId: fieldId,
            value: value
        }
        this.$emit('changeValueSwitch', params)
    }

    /**
     * Lorsqu'on change la valeur d'un selecteur, on transmet à son composant parent
     * un event avec la valeur du selecteur, l'identifiant de la colonne
     * et l'identifiant de la ligne
     */
    changeValueSelect (line: any, fieldId: string) {
        const params: any = {
            line: line,
            fieldId: fieldId
        }
        this.$emit('changeValueSelect', params)
    }

    /**
     * Lorsqu'on clique sur une ligne du tableau et qu'une colonne transmise en propriété
     * est associé à l'option onClickLine (valeur true) et que cette colonne est une checkbox
     * alors, on inverse sa valeur (transmission de l'évènement de changement de valeur au
     * composant parent)
     */
    click_line (line: any) {
        if (this.fields) {
            for (const field of this.fields) {
                if (field && field.key && field.onClickLine === true && field.type === 'checkbox') {
                    if (!line.alreadySelected) {
                        line[field.key] = !line[field.key]
                        this.changeValueSwitch(line.id, field.key, line[field.key])
                    }
                }
            }
        }
    }
}
