
























































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import store from '@/store/index'
import { PosteContext, PosteType, getPosteTypeSpec, getPosteContextSpec, getPosteContextFromArray, getPosteContextToArray, EntityType, getEntityTypeSpec } from '@/types/Poste'
import { getTypePassationSpec, TypePassation } from '@/types/Epreuve'
import { Ability } from '@/types/Ability'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { checkIcone } from '@/utils/helpers'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


@Component({
    components: {
        ErrorDisplay,
        ExaGenericTable,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('poste', ['loading', 'error', 'postes', 'meta', 'links', 'totalRows']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})
export default class Postes extends Vue {
    @Watch('postes')
    wPostes() {
        this.setDataForGenericTab(this.$store.getters['poste/postes'])
    }

    // DATAS
    fields = [
        {
            key: 'etatEdit',
            label: '',
            sortable: false,
            sortDirection: 'asc',
            tdClass: 'col-w-etat bg-info'
        },
        { key: 'name', label: 'Postes', sortable: true, sortDirection: 'asc', class: 'text-start ps-4' },
        {
            key: 'type',
            label: 'Type',
            sortable: true,
            sortDirection: 'asc',
            class: 'text-start ps-4'
        },
        {
            key: 'context',
            label: 'Contexte',
            sortable: true,
            sortDirection: 'asc',
            class: 'text-start ps-4'
        },
        { key: 'regroupement', label: 'Regroupement', sortable: true, sortDirection: 'asc', class: 'text-start ps-4' },
        {
            key: 'poste_type_passation',
            label: 'Type de passation',
            sortable: true,
            sortDirection: 'asc',
            class: 'text-start'
        },
        {
            key: 'poste_entity_type',
            label: 'Type de liaison',
            sortable: true,
            sortDirection: 'asc',
            class: 'text-start'

        },
        {
            key: 'options',
            label: 'Options',
            sortable: true,
            sortDirection: 'asc',
            class: 'text-start'

        },
        {
            key: 'delete',
            label: '',
            sortable: false,
            sortDirection: 'asc',
            tdClass: 'text-end'
        }
    ]

    beforeMount () {
        this.load()
    }

    load() {
        store.dispatch('poste/getAllPostes', { sort:'name', page: 1, direction: 'asc' })
    }

    Ability = Ability
    posteType = PosteType
    posteContext = PosteContext
    entityType = EntityType
    getterEntityTypeSpec = getEntityTypeSpec
    getterPosteTypeSpec = getPosteTypeSpec
    getterPosteContextSpec = getPosteContextSpec
    getterPosteContextFromArray = getPosteContextFromArray
    getterPosteContextToArray = getPosteContextToArray
    typePassation = TypePassation
    getterTypePassation = getTypePassationSpec
    sortBy = ''
    sortDesc = false
    sortDirection = 'asc'
    filter = ''
    filterOn = []
    stickyHeader = true
    poste: any = null
    posteTemp: any = null
    params = { sort:'name', page: 1, direction: 'asc' }

    showModalEditionPoste = false
    showModalMessageDelete = false

    dataForTab: any = []

    no_edit = false

    // METHODS
    posteOptionsToString (values: any) {
        let constTemp = []
        if (values && !Array.isArray(values)) {
            constTemp = values.split(',')
        } else if (values) {
            constTemp = values
        }
        if (constTemp.length > 0) {
            return constTemp?.map((value: any) => value.trim())
                ?.join(', ') || ''
        } else {
            return ''
        }
    }

    postesOptionsToArray (value: string) {
        return value.split(',')
    }

    checkPosteOptionsArray (value: string) {
        const test = value.split(',')
        const retour = []
        for (let i = 0; i < test.length; i++) {
            if (test[i].trim().length > 0) {
                retour.push(test[i].trim())
            }
        }
        return retour
    }

    openAddPoste () {
        this.$store.state.poste.error = null
        this.no_edit = false

        this.posteTemp = {
            id: 0,
            type: PosteType.TYPE_PEDAGOGIQUE,
            context: PosteContext.CONTEXT_SOUHAIT,
            name: '',
            name_plural: '',
            regroupement: '',
            type_passation: TypePassation.TYPE_PASSATION_ECRIT,
            options: [],
            entity_type: EntityType.ET_EPREUVE_CORRECTION
        }
        this.showModalEditionPoste = true
    }

    editPoste (row: any) {
        this.$store.state.poste.error = null
        this.poste = row
        this.posteTemp = {
            id: this.poste.id,
            type: this.poste.type,
            context: this.poste.context,
            name: this.poste.name,
            name_plural: this.poste.name_plural,
            regroupement: this.poste.regroupement,
            type_passation: this.poste.type_passation,
            options: this.poste.options,
            entity_type: this.poste.entity_type
        }

        if (this.posteTemp.context === PosteContext.CONTEXT_RESPONSABLE_CENTRE || this.posteTemp.context === PosteContext.CONTEXT_CHEF_CENTRE ||
        this.posteTemp.context === PosteContext.CONTEXT_CORRESPONDANT_ORAL || this.posteTemp.context === PosteContext.CONTEXT_SECRETAIRE) {
            this.no_edit = true
        } else {
            this.no_edit = false
        }
        this.showModalEditionPoste = true
    }

    cancelEdit () {
        this.poste = null
        this.posteTemp = null
        this.showModalEditionPoste = false
    }

    editSuite () {
        this.$store.state.poste.error = null
        const payload = {
            id: this.posteTemp.id,
            type: this.posteTemp.type,
            context: this.posteTemp.context,
            name: this.posteTemp.name,
            name_plural: this.posteTemp.name_plural,
            regroupement: this.posteTemp.regroupement,
            type_passation: this.posteTemp.type_passation,
            options: Array.isArray(this.posteTemp.options) ? this.posteTemp.options.join() : this.posteTemp.options,
            entity_type: this.posteTemp.entity_type
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        if (this.posteTemp.id === 0) {
            this.$store.dispatch('poste/addPoste', payload)
                .then(() => {
                    this.poste = null
                    this.posteTemp = null
                    this.showModalEditionPoste = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Poste ajouté avec succès !', succesToaster)
                    store.dispatch('poste/getAllPostes', this.params)
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            this.$store.dispatch('poste/updatePoste', payload)
                .then(() => {
                    this.poste = null
                    this.posteTemp = null
                    this.showModalEditionPoste = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Poste modifié avec succès !', succesToaster)
                    store.dispatch('poste/getAllPostes', this.params)
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    deletePoste (row: any) {
        this.poste = row
        this.showModalMessageDelete = true
    }

    cancelDelete () {
        this.poste = null
        this.showModalMessageDelete = false
    }

    deleteSuite () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Supression en cours ...', infosToaster)
        this.$store.dispatch('poste/deletePoste', this.poste.id)
            .then(() => {
                this.poste = null
                this.posteTemp = null
                this.showModalEditionPoste = false
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Poste supprimé avec succès !', succesToaster)
                this.poste = null
                this.showModalMessageDelete = false
                store.dispatch('poste/getAllPostes', this.params)
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Appelle une page lors du scroll
    filtreSortHandler (params: any) {
        this.$store.dispatch('poste/getAllPostes', params)
    }

    loadHandler (params: any) {
        this.$store.dispatch('poste/getMoreAllPostes', params)
    }


    /**
     *
     *
     *
     *  Generic table area
     *
     *
      */


    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            // let postes: Array<any> = this.$store.getters['poste/postes']
            switch (paParams[0])
            {
                case 'edit':
                    this.editPoste(paParams[1])
                    break

                case 'sortHandler':
                    this.params = paParams[1]
                    this.filtreSortHandler(paParams[1])
                    break

                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break

                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'delete':
                    this.deletePoste(paParams[1])
                    break

                default:
                    break
            }
        }
    }

    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            const can = this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE)
            const icone = checkIcone(Ability.RH_SPS_MANAGE, can)
            for (const result of poData)
            {
                const postes = result.name ? result.name : '-'
                const type = result.type ? getPosteTypeSpec(result.type).libelle : '-'
                const contexte = result.context ? getPosteContextSpec(result.context).libelle : '-'
                const regroupement = result.regroupement ? result.regroupement : '-'
                const typePassation = result.type_passation ? getTypePassationSpec(result.type_passation).libelle : '-'
                const typeLiaison = result.entity_type ? getEntityTypeSpec(result.entity_type).libelle : '-'
                const options = result.options ? this.getOptions(result) : '-'

                const line = [
                    { label: icone.label, item: result, type: 'action',  typeAction: 'edit', class: 'commons_first_action_button', icon: icone.icon, disabled: false },
                    { label: '', item: postes, type: 'text', typeAction: null, class: '' },
                    { label: '', item: type, type: 'text', typeAction: null, class: '' },
                    { label: '', item: contexte, type: 'text', typeAction: null, class: '' },
                    { label: '', item: regroupement, type: 'text', typeAction: null, class: '' }, { label: '', item: typePassation, type: 'text', typeAction: null, class: '' },
                    { label: '', item: typeLiaison, type: 'text', typeAction: null, class: '' },
                    { label: '', item: options, type: 'text', typeAction: null, class: '' }

                ]
                if (this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE)) {
                    line.push({ label: 'Supprimer', item: result, type: 'action',  typeAction: 'delete',  class: 'text-secondary', icon:'trash-alt', disabled: false })
                } else {
                    line.push({ label: '', item: null, type: 'text', typeAction: null, class: '' })
                }
                this.dataForTab.push(line)
            }
        }
    }

    getOptions(row: { options: any }) {
        let constTemp = []
        const values = row.options
        if (values && !Array.isArray(values)) {
            constTemp = values.split(',')
        } else if (values) {
            constTemp = values
        }
        if (constTemp.length > 0) {
            return constTemp?.map((value: any) => value.trim())
                ?.join(', ') || ''
        } else {
            return ''
        }
    }
}

