























































































































import { mapActions, mapGetters, mapState } from 'vuex'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { formatDayDate, isObject } from '@/utils/helpers'
import { Ability } from '@/types/Ability'
import _ from 'lodash'
import  ErrorDisplay from '@/components/ErrorDisplay.vue'
import { getTypeStatutSpec } from '@/types/CandidatEpreuve'
import { EtatDecisionJury } from '@/types/DecisionJury'

@Component({
    components: {
        ErrorDisplay
    },
    computed: {
        ...mapState('decisionjury', ['loading_decisions', 'error_decisions']),
        ...mapGetters('candidatIncompatible', ['resultatRechercheParCandidat', 'get_meta_recherche_Candidat']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    },
    methods: {
        ...mapActions('postes', ['getAllPostes'])
    }
})

export default class PopupConsulterDecisionJury extends Vue {
    @Prop() show?: boolean;
    @Prop() infosFiliereEpreuveSelect?: any;
    @Prop() decisionSelect?: any

    Ability                    = Ability
    EtatDecisionJury           = EtatDecisionJury
    getTypeStatutSpec          = getTypeStatutSpec
    lockInput                  = false
    etape_selection_candidat   = true
    etape_renseigner_decision  = false
    dateNow                    = formatDayDate(new Date(Date.now()))
    formatDayDate              = formatDayDate
    options_filieres: any      = []
    resultat: any              = []
    meta: any                  = []
    phase_en_cours: any        = null
    showMessageConfirmValidate = false
    showMessageConfirmDelete   = false

    /**
     * @description Récupère le nom du concours et la phase en cours
     * @param {number} concours_id - L'identifiant du concours
     * @returns {string} - Le nom du concours
     */
    getFiliereName(concours_id: number): string {
        if (concours_id) {
            // Récupère la phase en cours du concours du candidat
            const concour = this.$store.getters['concour/banques'].find((b: any) => b.id === concours_id)
            this.phase_en_cours = concour.phases.find((p: any) => p.phase_id === concour.id_phase_en_cours)
            return concour.name
        }
        return '-'
    }

    /**
     * @description Vérifie si la valeur est un objet
     * @param {any} value - La valeur à vérifier
     * @returns {boolean} - Vrai si la valeur est un objet, faux sinon
     */
    isObjectLocal(value: any): boolean {
        return isObject(value)
    }

    /**
     * @description Réinitialisation des variables locales sensibles
     * @returns {void}
     */
    reinitModale(): void {
        this.$store.commit('decisionjury/SET_ERROR', null)
    }

    /**
     * @description Ferme la popup
     * @param {boolean} withSave - Sauvegarde des données
     * @returns {void}
     */
    closeModal(withSave = false): void {
        this.$emit('reinitShow', withSave)
    }

    /**
     * @description Confirmation de revalidation d'une décision de jury
     * @returns {void}
     */
    confirmValidate(): void {
        this.showMessageConfirmValidate = true
    }

    /**
     * @description Confirmation de suppression d'une décision de jury
     * @returns {void}
     */
    confirmDelete(): void {
        this.showMessageConfirmDelete = true
    }

    /**
     * @description Annule la confirmation de la suppression ou de la validation
     * @returns {void}
     */
    cancelConfirm(): void {
        this.$store.commit('decisionjury/SET_ERROR', null)
        this.showMessageConfirmValidate = false
        this.showMessageConfirmDelete = false
    }

    /**
     * @description Validation de la décision de jury
     * @returns {void}
     */
    validate(): void {
        this.$store.commit('decisionjury/SET_ERROR', null)
        this.$store.commit('decisionjury/SET_LOADING', true)

        const payload: any = {}
        Vue.set(payload, 'id', this.decisionSelect.id)
        Vue.set(payload, 'etat', EtatDecisionJury.ETAT_VALIDE)
        Vue.set(payload, 'phase_id', this.decisionSelect.phase_id)
        Vue.set(payload, 'candidat_id', this.decisionSelect.candidat.id)
        Vue.set(payload, 'epreuve_id', this.decisionSelect.epreuve_id)
        Vue.set(payload, 'statut_avant', this.decisionSelect.statut_avant)
        Vue.set(payload, 'statut_apres', this.decisionSelect.statut_apres)
        Vue.set(payload, 'note_avant', this.decisionSelect.note_avant)
        Vue.set(payload, 'note_brute', this.decisionSelect.note_brute)
        Vue.set(payload, 'note_apres', this.decisionSelect.note_apres)
        Vue.set(payload, 'commentaire', this.decisionSelect.commentaire)

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours...', infosToaster)

        this.$store.dispatch('decisionjury/updateDecision', payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé', succesToaster)
                this.cancelConfirm()
                this.closeModal(true)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Suppression de la décision de jury
     * @returns {void}
     */
    deleteDecision(): void {
        this.$store.commit('decisionjury/SET_ERROR', null)

        const payload: any = {}
        Vue.set(payload, 'id', this.decisionSelect.id)

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours...', infosToaster)

        this.$store.dispatch('decisionjury/deleteDecision', payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé', succesToaster)
                this.cancelConfirm()
                this.closeModal(true)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Montage du composant
     * @returns {void}
     */
    mounted(): void {
        this.$store.commit('decisionjury/SET_ERROR', null)
        this.reinitModale()
    }
}
