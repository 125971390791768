


































import { Vue, Component, Watch } from 'vue-property-decorator'
import Back from '@/components/Tools/Back.vue'
import ReceptionSujets from '@/views/Sol/CDC/Ecrits/ReceptionSujets.vue'
import ReceptionFP from '@/views/Sol/CDC/Ecrits/ReceptionFp.vue'
import ReceptionFA from '@/views/Sol/CDC/Ecrits/ReceptionFa.vue'
import { getStatutReception } from '@/types/Centre'

@Component({
    components: {
        Back,
        ReceptionSujets,
        ReceptionFP,
        ReceptionFA
    }
})

export default class SuiviFournituresCentre extends Vue {
    selectedTab: any = null
    centre_select: any = null
    color_statut_sujet: any = { color: 'tertiary' }
    color_statut_pap: any = { color: 'tertiary' }
    color_statut_fa: any = { color: 'tertiary' }

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    // Met à jour la puce de couleur si le statut des sujets est modifié dans le composant
    update_centre_statut_sujet (color_maj: any) {
        this.color_statut_sujet = color_maj
    }

    // Met à jour la puce de couleur si le statut des pap est modifié dans le composant
    update_centre_statut_pap (color_maj: any) {
        this.color_statut_pap = color_maj
    }

    // Met à jour la puce de couleur si le statut des fa est modifié dans le composant
    update_centre_statut_fa (color_maj: any) {
        this.color_statut_fa = color_maj
    }


    load () {
        // Chargement du centre sélectionné
        const params = {}
        Vue.set(params, 'sort', 'name')
        Vue.set(params, 'direction', 'asc')
        Vue.set(params, 'filter-id', this.$route.params.centre_id)

        if (this.$store.state.repartitioncandidats.liste_centres.length === 0) {
            // Chargement du centre
            this.$store.dispatch('repartitioncandidats/getListeCentres', params).then((response) => {
                this.centre_select = response.data.data[0]
                this.color_statut_sujet = getStatutReception(this.centre_select.f_suj_statut)
                this.color_statut_pap = getStatutReception(this.centre_select.f_pap_statut)
                this.color_statut_fa = getStatutReception(this.centre_select.f_adm_statut)
            })
        } else {
            this.centre_select = this.$store.state.repartitioncandidats.liste_centres.find((c: any) => c.id.toString() === this.$route.params.centre_id)
        }

        // Sélection de l'onglet que l'on veut consulter
        this.selectedTab = this.$route.query.tab
    }

    mounted () {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
