





































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
    components: {}
})
export default class SmallCard extends Vue {
    /* Communes à toutes les cartes */
    @Prop() readonly cardType!: string; // type de carte
    @Prop() readonly title!: string;

    @Prop() readonly link!: string;

    /* ****************** */
    /* Milieu de la carte */
    /* ****************** */
    /* ThreeInformationsCard */
    @Prop() readonly threeinformationsNumber1!: string | undefined
    @Prop() readonly threeinformationsNumber1parse!: number | undefined
    @Prop() readonly threeinformationsText1Singular!: string | undefined
    @Prop() readonly threeinformationsText1Plural!: string | undefined

    @Prop() readonly threeinformationsNumber2!: string | undefined
    @Prop() readonly threeinformationsNumber2parse!: number | undefined
    @Prop() readonly threeinformationsText2Singular!: string | undefined
    @Prop() readonly threeinformationsText2Plural!: string | undefined

    @Prop() readonly threeinformationsNumber3!: string | undefined
    @Prop() readonly threeinformationsNumber3parse!: number | undefined
    @Prop() readonly threeinformationsText3Singular!: string | undefined
    @Prop() readonly threeinformationsText3Plural!: string | undefined
}
