































































import { Vue, Component, Watch, Prop }  from 'vue-property-decorator'
import { mapGetters, mapState }   from 'vuex'
import { Ability }                from '@/types/Ability'
import { FontAwesomeIcon }        from '@fortawesome/vue-fontawesome'
import { TypePassation } from '@/types/Epreuve'
import { Etat, getEtatSpec } from '@/types/DossierAcademique'
import { dateIsBetween, dateisSameOrBefore, formatDate } from '@/utils/helpers'


@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapState('centre', ['centres', 'loading']),
        ...mapState('gabaritFicheHoraire', ['centre_user_fh']),
        ...mapState('dossieradministratif', ['dossiersAdministratifs']),
        ...mapState('user', ['dossieradministratifId', 'has_poste_aca']),
        ...mapState('session', ['sessionSelect'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon
    }
})
export default class TDBGestionCentre extends Vue {
    @Prop() hasContrats?: boolean

    ability             = Ability
    tuiles: any  = []
    info: any          = []
    centre_select: any = null
    color_signature_fiche_horaire = 'text-primary'

    @Watch('centre_user_fh')
    initColor () {
        // Couleur de l'avancée de signature de la fiche horaire
        this.color_signature_fiche_horaire = 'text-primary'
        if (this.$store.state.gabaritFicheHoraire.centre_user_fh !== null) {
            for (const p in this.$store.state.gabaritFicheHoraire.centre_user_fh.postes) {
                if (this.$store.state.gabaritFicheHoraire.centre_user_fh.postes[p].fiche_signed_at) {
                    this.color_signature_fiche_horaire = 'text-success'
                } else {
                    this.color_signature_fiche_horaire = 'text-primary'
                }
            }
            this.createPreparationDashboard()
        }
    }

    @Watch('dossieradministratifId')
    refresh_entrees () {
        if (this.$store.state.user.dossieradministratifId !== null) {
            this.createPreparationDashboard()
        }
    }

    /**  creer un objet de deux tableaux (box1, box2) correspondant aux deux colonnes d'affichage. */
    createPreparationDashboard() {
        if (this.centre_select.type_passation === TypePassation.TYPE_PASSATION_ECRIT) {
            // Lien pour la gestion des intervenants
            let titre_intervenants = ''
            let color_intervenants = 'text-info'
            if (!this.centre_select.intervenants_validated_at && !this.centre_select.fiches_intervenants_published_at) {
                titre_intervenants = 'SAISIE DES INTERVENANTS'
                color_intervenants = !this.centre_select.intervenants_submitted_at ? 'text-primary' : 'text-info'
            } else if (this.centre_select.intervenants_validated_at && !this.centre_select.fiches_intervenants_published_at) {
                titre_intervenants = 'SAISIE DES FICHES HORAIRES'
                color_intervenants = !this.centre_select.fiches_intervenants_published_at  ? 'text-primary' : 'text-info'
            } else if (this.centre_select.intervenants_validated_at && this.centre_select.fiches_intervenants_published_at) {
                titre_intervenants = 'SUIVI DES SIGNATURES DES FICHES HORAIRES'
                color_intervenants = this.centre_select.fiches_intervenants_validated_at ? 'text-success' : (this.centre_select.fiches_intervenants_signed_at ? 'text-primary' : 'text-info')
            }



            let color_dossier_admin = 'text-info'
            switch (this.$store.state.dossieradministratif.dossiersAdministratifs.length !== 0 && this.$store.state.dossieradministratif.dossiersAdministratifs[0].etat) {
                case Etat.ETAT_VIERGE:
                    color_dossier_admin = 'text-primary'
                    break
                case Etat.ETAT_DEPOSE:
                    color_dossier_admin = 'text-info'
                    break
                case Etat.ETAT_VALIDE:
                    color_dossier_admin = 'text-success'
                    break
                case Etat.ETAT_REJETE:
                    color_dossier_admin = 'text-danger'
                    break
            }

            const aides = this.$store.getters['auth/findParameter']('aides')
            const ordreMissionDisabled = this.$store.getters['auth/findParameter']('ordreMissionDisabled')
            this.tuiles = {
                box1: [
                    {
                        name: 'Besoin d\'aide ?',
                        icone: 'life-ring',
                        tooltip : null,
                        permissions: aides && (aides.value?.videos?.length > 0 || aides.value?.documents?.length > 0) ? [true] : [false],
                        key: 'aide',
                        informations: [
                            {
                                description: 'TUTORIELS VIDEOS',
                                etat: 'text-info',
                                key: 'aide',
                                link: '/aide',
                                permissions: aides && (aides.value?.videos?.length > 0 || aides.value?.documents?.length > 0) ? [true] : [false]
                            }
                        ]
                    },
                    {
                        name: 'Mon dossier académique',
                        icone: 'graduation-cap',
                        tooltip : null,
                        permissions: this.$store.state.user.dossieracademiqueId !== null ? [Ability.RH_SPS_DOS_OWN_MANAGE]  : [false],
                        key: 'admin',
                        informations: [
                            {
                                description: 'REMPLIR MON DOSSIER DE CANDIDATURE',
                                etat: 'text-' + getEtatSpec(this.$store.state.dossieracademique.dossierAcademiqueSelect.etat).color,
                                key: 'admin',
                                link: '/dossier_academique/' + this.$store.state.user.dossieracademiqueId,
                                permissions: this.$store.state.user.dossieracademiqueId !== null ? [Ability.RH_SPS_DOS_OWN_MANAGE]  : [false]
                            }
                        ]
                    },
                    {
                        name: 'Mon dossier administratif',
                        icone: 'folder-open',
                        tooltip : null,
                        permissions: this.$store.state.user.dossieradministratifId !== null ? [Ability.RH_ADM_DOS_OWN_MANAGE]  : [false],
                        key: 'admin',
                        informations: [
                            {
                                description: 'REMPLIR MON DOSSIER ADMINISTRATIF',
                                etat: color_dossier_admin,
                                key: 'admin',
                                link: '/dossier_administratif/' + this.$store.state.user.dossieradministratifId,
                                permissions: this.$store.state.user.dossieradministratifId !== null ? [Ability.RH_ADM_DOS_OWN_MANAGE]  : [false]
                            }
                        ]
                    },
                    {
                        name: 'Gestion des contrats',
                        icone: 'file-contract',
                        tooltip : null,
                        permissions: this.hasContrats ? [Ability.RH_ADM_DOS_OWN_MANAGE] : [false],
                        key: 'admin',
                        informations: [
                            {
                                description: 'CONTRATS & AVENANTS',
                                etat: 'text-primary',
                                link: '/contrats_intervenant',
                                permissions: [Ability.RH_ADM_DOS_OWN_MANAGE],
                                key: 'admin'
                            },
                            {
                                description: 'ORDRES DE MISSIONS',
                                etat: 'text-primary',
                                link: '/ordres_mission_intervenant',
                                permissions: this.$store.state.user.has_poste_aca && this.$store.getters['auth/findParameter']('ordreMissionDisabled')?.value !== '1' ? [Ability.RH_ADM_DOS_OWN_MANAGE] : [false],
                                key: 'admin'
                            },
                            {
                                description: 'DEMANDES DE REMBOURSEMENT',
                                etat: 'text-primary',
                                link: '/demandes_remboursement_intervenant',
                                permissions: this.$store.state.user.has_poste_aca && this.$store.getters['auth/findParameter']('ordreMissionDisabled')?.value !== '1' ? [Ability.RH_ADM_DOS_OWN_MANAGE] : [false],
                                key: 'admin'
                            }
                        ]
                    },
                    {
                        name: 'Fiche horaire',
                        icone: 'watch',
                        tooltip: null,
                        permissions: this.$store.state.centre.centreSelect.fiches_intervenants_published_at && [Ability.RH_FICHEHORAIRE_OWN_SIGN],
                        key: 'admin',
                        informations: [
                            {
                                description: 'SIGNER MA FICHE HORAIRE',
                                etat: this.color_signature_fiche_horaire,
                                link: '/signature_fiche_horaire',
                                permissions: [Ability.RH_FICHEHORAIRE_OWN_SIGN],
                                key: 'admin'
                            }
                        ]
                    },
                    {
                        name: 'Convention',
                        icone: 'file-contract',
                        tooltip: null,
                        permissions: this.centre_select.convention_published_at  ? [Ability.GCI_CONVENTION_OWN_VIEW] : [false],
                        key: 'centre',
                        informations: [
                            {
                                description: 'CONSULTATION DE LA CONVENTION',
                                link: '/centres/' + this.$store.state.user.centreId + '/conventions',
                                etat: this.centre_select.convention_signed_at ? 'text-success' : (this.centre_select.convention_published_at ? 'text-info' : 'text-info'),
                                permissions: this.$store.getters['auth/can'](Ability.GCI_CONVENTION_OWN_SIGN, 'App\\Models\\Centre', { id : this.centre_select.id }) ?  [false] : [Ability.GCI_CONVENTION_OWN_VIEW],
                                key: 'centre'
                            },
                            {
                                description: 'SIGNATURE DE LA CONVENTION',
                                link: '/conventions_own',
                                etat: (this.centre_select.convention_signed_at && this.centre_select.annexe_signed_at && this.centre_select.convention_type_id !== null) || (this.centre_select.convention_signed_at && this.centre_select.convention_type_id === null) ? 'text-success' : (this.centre_select.convention_published_at ? 'text-primary' : 'text-info'),
                                permissions: this.$store.getters['auth/can'](Ability.GCI_CONVENTION_OWN_SIGN, 'App\\Models\\Centre', { id : this.centre_select.id })  ? [Ability.GCI_CONVENTION_OWN_SIGN] : [false],
                                key: 'centre'
                            },
                            {
                                description: 'GESTION DE L\'ANNEXE DE CONVENTION',
                                link: '/centres/' + this.$store.state.user.centreId + '/annexeconvention',
                                etat: this.centre_select.annexe_signed_at ? 'text-success' : (!this.centre_select.annexe_submitted_at ? 'text-primary' : 'text-info'),
                                permissions: this.centre_select.convention_type_id && this.$store.getters['auth/can'](Ability.GCI_ANNEXE_CONVENTION_OWN_MANAGE, 'App\\Models\\Centre', { id : this.centre_select.id }) ? [Ability.GCI_ANNEXE_CONVENTION_OWN_MANAGE] : [false],
                                key: 'centre'
                            },
                            {
                                description: 'CONSULTATION DE L\'ANNEXE DE CONVENTION',
                                link: '/centres/' + this.$store.state.user.centreId + '/annexeconvention',
                                etat: this.centre_select.annexe_signed_at ? 'text-success' : 'text-info',
                                permissions:  !this.centre_select.convention_type_id || this.$store.getters['auth/can'](Ability.GCI_ANNEXE_CONVENTION_OWN_MANAGE, 'App\\Models\\Centre', { id : this.centre_select.id })  ?  [false] : [Ability.GCI_ANNEXE_CONVENTION_OWN_VIEW],
                                key: 'centre'
                            }
                        ]
                    }
                ],
                box2: [
                    {
                        name: 'Mon Centre',
                        icone: 'school',
                        tooltip : null,
                        permissions: [Ability.GCI_DESCR_OWN_VIEW, Ability.GCI_INFOS_OWN_VIEW],
                        informations: [
                            {
                                description: 'DESCRIPTION & CONTACTS',
                                etat: 'text-info',
                                link: '/centres/' + this.$store.state.user.centreId + '/description',
                                permissions: [Ability.GCI_DESCR_OWN_VIEW],
                                key: 'centre'
                            },
                            {
                                description: 'GESTION DES INFORMATIONS',
                                etat: this.$store.getters['centre/informations_is_validate'] ? 'text-success' : (this.$store.getters['centre/informations_is_submit'] ? 'text-info' : 'text-primary'),
                                link: '/centres/' + this.$store.state.user.centreId + '/informations',
                                permissions: [Ability.GCI_INFOS_OWN_VIEW],
                                key: 'centre'
                            }
                        ]
                    },
                    {
                        name: 'Intervenants',
                        icone: 'users',
                        tooltip : null,
                        permissions: this.$store.getters['centre/nb_intervenants_is_validate'] || this.centre_select.fiches_intervenants_published_at ? [Ability.GCI_SAISIE_INTERV_OWN_VIEW, Ability.GCI_FICHES_HORAIRES_OWN_SIGN] : [false],
                        key: 'centre',
                        informations: [
                            {
                                description: titre_intervenants,
                                etat: color_intervenants,
                                link: '/centres/' + this.$store.state.user.centreId + '/intervenants',
                                permissions: this.$store.getters['centre/nb_intervenants_is_validate'] ? [Ability.GCI_SAISIE_INTERV_OWN_VIEW] : [false],
                                key: 'centre'
                            },
                            {
                                description: 'SIGNATURE DES FICHES HORAIRES',
                                etat: this.centre_select.fiches_intervenants_signed_at ? 'text-success' : (this.centre_select.fiches_intervenants_published_at ? 'text-primary' : 'text-info'),
                                link: '/centres/' + this.$store.state.user.centreId + '/signature_intervenant',
                                permissions: this.centre_select.fiches_intervenants_published_at ? [Ability.GCI_FICHES_HORAIRES_OWN_SIGN] : [false],
                                key: 'centre'
                            }
                        ]
                    },
                    {
                        name: 'Résultats aux concours',
                        icone: 'chart-bar',
                        tooltip : null,
                        permissions: [Ability.EXT_RESULTS_VIEW],
                        informations: [
                            {
                                description: 'CONSULTER LES RÉSULTATS AUX CONCOURS',
                                etat: 'text-info',
                                link: '/resultats',
                                permissions: [Ability.EXT_RESULTS_VIEW]
                            }
                        ]
                    }
                ],
                box3: [
                    {
                        name: 'Organisation des écrits',
                        icone: 'pencil',
                        tooltip: null,
                        permissions: [Ability.GCI_ORGA_OWN_VIEW, Ability.GCI_CANDIDATS_OWN_VIEW, Ability.GCI_FOURNITURES_OWN_VIEW],
                        key: 'centre',
                        informations: [
                            {
                                description: 'GESTION DES SALLES',
                                link: '/centres/' + this.$store.state.user.centreId + '/organisationecrit/salles',
                                etat: this.$store.getters['centre/salles_is_validate'] ? 'text-success' : (this.$store.getters['centre/salles_is_submit'] ? 'text-info' : 'text-primary'),
                                permissions: [Ability.GCI_ORGA_OWN_VIEW],
                                key: 'centre'
                            },
                            {
                                description: 'GESTION DU NOMBRE D\'INTERVENANTS',
                                link: '/centres/' + this.$store.state.user.centreId + '/organisationecrit/nb_intervenants',
                                etat: this.$store.getters['centre/nb_intervenants_is_validate'] ? 'text-success' : (this.$store.getters['centre/nb_intervenants_is_submit'] ? 'text-info' : 'text-primary'),
                                permissions: [Ability.GCI_ORGA_OWN_VIEW],
                                key: 'centre'
                            },
                            {
                                description: 'LISTE DES CANDIDATS',
                                link: '/centres/' + this.$store.state.user.centreId + '/candidats_ecrits',
                                etat: 'text-info',
                                permissions: [Ability.GCI_FOURNITURES_OWN_VIEW],
                                key: 'centre'
                            },
                            {
                                description: 'INDISPONIBILITÉS DE RÉCEPTION DE LIVRAISON',
                                link: this.getStateLivraison().text_color !== 'text-secondary' ? '/centres/' + this.$store.state.user.centreId + '/indisponibilites_reception' : '',
                                etat: this.getStateLivraison().text_color,
                                permissions: [Ability.GCI_FOURNITURES_OWN_VIEW],
                                key: 'centre',
                                title: this.getStateLivraison().message
                            },
                            {
                                description: 'INVENTAIRE DES FOURNITURES PAPETIÈRES',
                                link: this.getStateInventaire().text_color !== 'text-secondary' ? '/centres/' + this.$store.state.user.centreId + '/inventaire_fp' : '',
                                etat: this.centre_select.f_pap_inventaire_submitted_at ? 'text-success' : this.getStateInventaire().text_color,
                                permissions: [Ability.GCI_FOURNITURES_OWN_VIEW],
                                key: 'centre',
                                title: this.getStateInventaire().message
                            },
                            {
                                description: 'RÉCEPTION DES SUJETS',
                                link: '/centres/' + this.$store.state.user.centreId + '/reception_sujets',
                                etat: this.centre_select.f_suj_validated_at ? 'text-success' : (this.centre_select.f_suj_submitted_at ? 'text-info' : 'text-primary'),
                                permissions: [Ability.GCI_FOURNITURES_OWN_VIEW],
                                key: 'centre'
                            },
                            {
                                description: 'RÉCEPTION DES FOURNITURES PAPETIÈRES',
                                link: '/centres/' + this.$store.state.user.centreId + '/reception_fp',
                                etat: this.centre_select.f_pap_validated_at ? 'text-success' : (this.centre_select.f_pap_submitted_at ? 'text-info' : 'text-primary'),
                                permissions: [Ability.GCI_FOURNITURES_OWN_VIEW],
                                key: 'centre'
                            },
                            {
                                description: 'RÉCEPTION DES FOURNITURES ADMINISTRATIVES',
                                link: '/centres/' + this.$store.state.user.centreId + '/reception_fa',
                                etat: this.centre_select.f_adm_validated_at ? 'text-success' : (this.centre_select.f_adm_submitted_at ? 'text-info' : 'text-primary'),
                                permissions: [Ability.GCI_FOURNITURES_OWN_VIEW],
                                key: 'centre'
                            },
                            {
                                description: 'BIBLIOTHÈQUE DES DOCUMENTS ADMINISTRATIFS',
                                link: this.getStateBibliotheque().text_color !== 'text-secondary' ? '/centres/' + this.$store.state.user.centreId + '/bibliotheque_documents_administratifs' : '',
                                etat: this.getStateBibliotheque().text_color,
                                permissions: [Ability.GCI_FOURNITURES_OWN_VIEW],
                                key: 'centre',
                                title: this.getStateBibliotheque().message
                            }
                        ]
                    }
                ]
            }
        } else if (this.centre_select.type_passation === TypePassation.TYPE_PASSATION_ORAL) {
            this.tuiles = {
                box1: [
                    {
                        name: 'Mon Centre',
                        icone: 'school',
                        tooltip : null,
                        permissions: [Ability.GCI_DESCR_OWN_VIEW, Ability.GCI_INFOS_OWN_VIEW],
                        key: 'centre',
                        informations: [
                            {
                                description: 'DESCRIPTION & CONTACTS',
                                etat: 'text-info',
                                link: '/centres/' + this.$store.state.user.centreId + '/description',
                                permissions: [Ability.GCI_DESCR_OWN_VIEW],
                                key: 'centre'
                            },
                            {
                                description: 'GESTION DES INFORMATIONS',
                                etat: this.$store.getters['centre/informations_is_validate'] ? 'text-success' : (this.$store.getters['centre/informations_is_submit'] ? 'text-info' : 'text-primary'),
                                link: '/centres/' + this.$store.state.user.centreId + '/informations',
                                permissions: [Ability.GCI_INFOS_OWN_VIEW],
                                key: 'centre'
                            }
                        ]
                    }
                ],
                box2: [
                    {
                        name: 'Organisation des oraux',
                        icone: 'chalkboard-teacher',
                        tooltip: null,
                        permissions: [Ability.GCI_ORGA_OWN_VIEW, Ability.GCI_EXAMINATEURS_OWN_VIEW, Ability.GCI_CANDIDATS_OWN_VIEW, Ability.GCI_IMPRESSIONS_OWN_VIEW, Ability.GCI_PLANNINGS_OWN_VIEW, Ability.GCI_DEMISSIONNAIRES_OWN_VIEW],
                        key: 'centre',
                        informations: [
                            {
                                description: 'AFFECTATION DES SALLES',
                                etat: this.centre_select.salles_validated_at ? 'text-success' : (this.centre_select.salles_submitted_at ? 'text-info' : 'text-primary'),
                                link: '/centres/' + this.$store.state.user.centreId + '/organisationoral',
                                permissions: [Ability.GCI_ORGA_OWN_VIEW],
                                key: 'centre'
                            },
                            { description: 'LISTE DES EXAMINATEURS', etat: 'text-info', link: '/centres/' + this.$store.state.user.centreId + '/examinateurs', permissions: [Ability.GCI_EXAMINATEURS_OWN_VIEW], key: 'centre' },
                            { description: 'Trombinoscope des examinateurs', link: '/trombinoscope_examinateurs_co', etat: 'text-info', permissions: [Ability.GCI_EXAMINATEURS_OWN_VIEW] },
                            { description: 'LISTE DES CANDIDATS', etat: 'text-info', link: '/centres/' + this.$store.state.user.centreId + '/candidats', permissions: [Ability.GCI_CANDIDATS_OWN_VIEW], key: 'centre' },
                            { description: 'IMPRESSIONS', etat: 'text-info', link: '/centres/' + this.$store.state.user.centreId + '/impressions', permissions: [Ability.GCI_IMPRESSIONS_OWN_VIEW], key: 'centre' },
                            { description: 'PLANNINGS', etat: 'text-info', link: '/centres/' + this.$store.state.user.centreId + '/plannings', permissions: [Ability.GCI_PLANNINGS_OWN_VIEW], key: 'centre' },
                            { description: 'LISTE DES CANDIDATS DÉMISSIONNAIRES', etat: 'text-info', link: '/centres/' + this.$store.state.user.centreId + '/demissionnaires', permissions: [Ability.GCI_DEMISSIONNAIRES_OWN_VIEW], key: 'centre' }
                        ]
                    }
                ]
            }
        }
    }

    // Retourne l'état de publication de l'inventaire
    getStateInventaire () {
        let state_inventaire = { message: '', text_color: '' }
        if (this.$store.state.session.sessionSelect) {
            if (!this.$store.state.session.sessionSelect.inventaire_fournitures_start_at && !this.$store.state.session.sessionSelect.inventaire_fournitures_end_at) {
                state_inventaire = { message: 'L\'inventaire n\'est pas accessible.', text_color: 'text-secondary' }
            } else if (!this.$store.state.session.sessionSelect.inventaire_fournitures_start_at && this.$store.state.session.sessionSelect.inventaire_fournitures_end_at) {
                state_inventaire = { message: 'L\'inventaire est accessible jusqu\'au ' + formatDate(this.$store.state.session.sessionSelect.inventaire_fournitures_end_at) + ' inclus.', text_color: 'text-primary' }
            } else if (this.$store.state.session.sessionSelect.inventaire_fournitures_start_at && this.$store.state.session.sessionSelect.inventaire_fournitures_end_at) {
                state_inventaire = { message: 'L\'inventaire est accessible du ' + formatDate(this.$store.state.session.sessionSelect.inventaire_fournitures_start_at) + ' au ' + formatDate(this.$store.state.session.sessionSelect.inventaire_fournitures_end_at) + ' inclus.', text_color: 'text-primary' }
            }
        }

        return state_inventaire
    }

    // Retourne l'état de publication de la bibliothèque
    getStateBibliotheque () {
        let state_biblio = { message: '', text_color: '' }
        if (this.$store.state.session.sessionSelect) {
            const now = Date.now()
            if (!this.$store.state.session.sessionSelect.bibliotheque_fa_start_at && !this.$store.state.session.sessionSelect.bibliotheque_fa_end_at) {
                state_biblio = { message: 'La bibliothèque des documents administratifs est fermée.', text_color: 'text-secondary' }
            } else if (!this.$store.state.session.sessionSelect.bibliotheque_fa_start_at && this.$store.state.session.sessionSelect.bibliotheque_fa_end_at) {
                state_biblio = { message: 'La bibliothèque des documents administratifs est ouverte jusqu\'au ' + formatDate(this.$store.state.session.sessionSelect.bibliotheque_fa_end_at) + ' inclus.', text_color:  dateisSameOrBefore(now, this.$store.state.session.sessionSelect.bibliotheque_fa_end_at) ? 'text-info' : 'text-secondary' }
            } else if (this.$store.state.session.sessionSelect.bibliotheque_fa_start_at && this.$store.state.session.sessionSelect.bibliotheque_fa_end_at) {
                state_biblio = { message: 'La bibliothèque des documents administratifs est ouverte du ' + formatDate(this.$store.state.session.sessionSelect.bibliotheque_fa_start_at) + ' au ' + formatDate(this.$store.state.session.sessionSelect.bibliotheque_fa_end_at) + ' inclus.', text_color:  dateIsBetween(now, this.$store.state.session.sessionSelect.bibliotheque_fa_start_at, this.$store.state.session.sessionSelect.bibliotheque_fa_end_at, true) ? 'text-info' : 'text-secondary' }
            }
        }

        return state_biblio
    }

    // Retourne l'état de publication des indisponibilités de livraison
    getStateLivraison () {
        let state_livraison = { message: '', text_color: '' }
        if (this.$store.state.session.sessionSelect) {
            if (!this.$store.state.session.sessionSelect.livraison_start_at && !this.$store.state.session.sessionSelect.livraison_end_at) {
                state_livraison = { message: 'La saisie des indisponibilités de réception est fermée.', text_color: 'text-secondary' }
            } else {
                state_livraison = { message: 'La saisie des indisponibilités de réception est ouverte.', text_color: 'text-primary' }
            }
        }

        return state_livraison
    }

    // Retourne la couleur d'affichage du lien
    getColor(number1?: number | boolean, number2?: number) {
        if (!number2) {
            return number1 === true ? 'text-success' : 'text-info'
        } else {
            if (number1 === 0)
            {
                return 'text-info'
            } else if (number1 !== number2) {
                return 'text-primary'
            } else if (number1 === number2) {
                return 'text-success'
            }
        }
    }

    // Check si l'utilisateur dispose de l'ability pour voir la tuile
    has_ability (permissions: any, route_name: string, key: string) {
        let has_ability = false

        for (const p in permissions) {
            if (key === 'centre') {
                if (this.$store.getters['auth/can'](permissions[p], 'App\\Models\\Centre', { id : this.centre_select.id })) {
                    has_ability = true
                }
            } else {
                if (this.$store.getters['auth/can'](permissions[p])) {
                    has_ability = true
                }
            }
        }
        return has_ability
    }

    mounted () {
        if (this.$store.state.centre.centres.length !== 0) {
            if (this.$store.getters['auth/can'](Ability.RH_FICHEHORAIRE_OWN_SIGN) && this.$store.state.user.centreId && this.$store.state.centre.centreSelect.fiches_intervenants_published_at) {
                const params = {
                    centreId : this.$store.state.user.centreId
                }
                this.$store.dispatch('gabaritFicheHoraire/getFicheHoraire', params).then((response) => {
                    this.$store.commit('gabaritFicheHoraire/SET_CENTRE_USER_FH', response.data.data)
                }).catch((error) => {
                    console.log('ko: ' + error)
                })
            }

            this.centre_select = this.$store.state.centre.centres.find((centre: { id: any }) => centre.id === this.$store.state.user.centreId)
            this.createPreparationDashboard()
        }
    }
}
