


























































































import { Vue, Component, Watch }    from 'vue-property-decorator'
import { mapGetters, mapState }     from 'vuex'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'
import { Ability }                  from '@/types/Ability'
import { formatDate, formatDateSinTime, format_phone_number } from '@/utils/helpers'
import { MessageIndicationType } from '@/types/MessageIndicationType'
import Back from '@/components/Tools/Back.vue'
import MessageIndication from '@/components/MessageIndication.vue'
import { TypePassation } from '@/types/Epreuve'
import { getTypeEnsembleSpec, TypeEnsemble } from '@/types/Ensemble'
import store from '@/store'


@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('matiere', ['getMatieresObs']),
        ...mapState('ensemble', ['ensembles']),
        ...mapState('presenceExaminateur', ['trombinoscope', 'isLoading', 'loading_pres', 'meta', 'back_url'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        Back,
        MessageIndication
    },
    beforeRouteEnter (to: any, from: any, next: any) {
        if (from) {
            store.commit('presenceExaminateur/SET_BACK_URL', from.path)
        }
        next()
    }
})

export default class TrombinoscopeExaminateurs extends Vue
{
    formatDate        = formatDate
    formatDateSinTime = formatDateSinTime
    tableLoading = false
    getTypeEnsembleSpec = getTypeEnsembleSpec
    format_phone_number = format_phone_number

    options_centres: Array<any> = []
    options_matieres: Array<any> = []
    options_filieres: Array<any> = []
    centre_select_id: any = null
    serie_select_id = null
    matiere_select_id = null
    ensemble_select_id = null
    filiere_select_id = null
    showDismissibleAlert = true

    Ability = Ability
    MessageIndicationType = MessageIndicationType

    allEpreuves             = []
    params: any = {}
    params_search: any = {}
    is_search = false
    ensembles_trier: any = {}
    ensembles_examinateurs: any= []
    old_ensembles: any = []
    chaine_concours = ''

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    @Watch('ensembles')
    loadEnsembles () {
        this.ensembles_trier = this.$store.getters['ensemble/ensemblesByType']
    }

    @Watch('centre_select_id')
    filterEnsembles () {
        if (this.centre_select_id !== null) {
            this.$store.state.ensemble.ensembles = this.old_ensembles.filter((ex: any) => ex.centre_id.toString() === this.centre_select_id.toString())
        } else {
            this.$store.state.ensemble.ensembles = this.old_ensembles
        }
    }

    /**
     * Lance la requete de sélection
     */
    rechercher () {
        this.$store.state.presenceExaminateur.error = null
        this.$store.commit('presenceExaminateur/SET_TROMBINOSCOPE', [])
        this.$store.commit('presenceExaminateur/SET_ERROR', null)
        Vue.set(this.params_search, 'centre_id', this.centre_select_id)
        Vue.set(this.params_search, 'matiere_id', this.matiere_select_id)
        Vue.set(this.params_search, 'ensemble_id', this.ensemble_select_id)
        Vue.set(this.params_search, 'concour_id', this.filiere_select_id)

        this.$store.dispatch('presenceExaminateur/getTrombinoscope', this.params_search).then(() => {
            this.is_search = true

            // Charge les avatars pour les utilisateurs qui en on
            for (const e in this.$store.state.presenceExaminateur.trombinoscope) {
                if (this.$store.state.presenceExaminateur.trombinoscope[e].photo !== null) {
                    this.getAvatar(e, this.$store.state.presenceExaminateur.trombinoscope[e].dossier_administratif_id)
                }
            }
        })
    }

    // Retourne l'avatar si existant au format b64
    getAvatar (index: any, dossier_id: number) {
        this.$store.dispatch('dossieradministratif/getAvatar', { dossier_id: dossier_id }).then((response) => {
            Vue.set(this.$store.state.presenceExaminateur.trombinoscope[index], 'imgb64', response.data)
        })
    }

    /** Retourne une string du ou des concours lié à l'ensemble */
    getConcoursEnsemble (ensemble: any) {
        let chaine_concours = ''
        if (ensemble && ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT) {
            const ensemble_select = this.$store.state.ensemble.ensembles.find((e: any) => e.id === ensemble.id)

            if (ensemble_select && ensemble_select.concour) {
                chaine_concours = ensemble_select.concour.name
            } else {
                chaine_concours = this.chaine_concours
            }
        } else {
            // Parcours des groupes épreuves
            let separateur = ''
            for (const e in ensemble.groupeEpreuve.epreuves) {
                for (const ep in ensemble.groupeEpreuve.epreuves[e].epreuves) {
                    if (ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_TP) {
                        separateur = (Number(ep) === ensemble.groupeEpreuve.epreuves[e].epreuves.length - 1) ? '' : ', '
                    } else if (ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_PAR_EPREUVE) {
                        separateur = (Number(e) === ensemble.groupeEpreuve.epreuves.length - 1) ? '' : ', '
                    }

                    chaine_concours += ensemble.groupeEpreuve.epreuves[e].epreuves[ep].concour.code + separateur
                }
            }
        }


        return chaine_concours
    }

    load () {
        this.$store.commit('presenceExaminateur/SET_TROMBINOSCOPE', [])
        const params: any = {
            page: 1,
            sort: 'name',
            direction: 'asc',
            centre_id: this.$route.params.centre_id
        }
        this.params = params

        // Chargement des ensembles
        this.$store.dispatch('ensemble/getEnsembles', { params: null }).then(() => {
            this.old_ensembles = this.$store.state.ensemble.ensembles
            if (this.$store.getters['auth/can'](Ability.INTERV_PLANIF_OWN)) {
                let centre_id_examinateur: any = null
                // Isole l'ensemble de l'examinateur connecté
                for (const e in this.$store.state.ensemble.ensembles) {
                    const ensemble = this.$store.state.ensemble.ensembles[e]
                    const search_examinateurs = ensemble.examinateurs.filter((ex: any) => ex.id === this.$store.state.auth.user.id)

                    if (search_examinateurs.length !== 0) {
                        centre_id_examinateur = ensemble.centre_id
                        this.centre_select_id = centre_id_examinateur
                    }
                }

                if (centre_id_examinateur) {
                    this.ensembles_examinateurs = this.$store.state.ensemble.ensembles.filter((ex: any) => ex.centre_id.toString() === centre_id_examinateur.toString())
                }
                this.$store.state.ensemble.ensembles = this.ensembles_examinateurs
            }

            // Load des banques
            this.$store.dispatch('banque/getBanques', { isPrecedente: false })

            // Load des matières
            this.$store.dispatch('matiere/getMatieres').then(() => {
                if (this.$store.getters['auth/can'](Ability.INTERV_OBSERV_MATIERE_OWN)) {
                // Profil Observateur
                    this.options_matieres = this.$store.getters['matiere/getMatieresObs'](this.$store.state.auth.user.abilities.filter((a: any) => a.name === Ability.INTERV_OBSERV_MATIERE_OWN))
                }

                // Filtre les matières et filières selon les ensembles autorisés
                this.options_filieres = []
                const ensembles_check = this.$store.state.ensemble.ensembles
                for (const e in ensembles_check) {
                    if (this.$store.getters['auth/cannot'](Ability.INTERV_OBSERV_MATIERE_OWN)) {
                    // Matières
                        for (const ep in ensembles_check[e].groupeEpreuve.epreuves) {
                            if (!this.options_matieres.find((m: any) => m.id === ensembles_check[e].groupeEpreuve.epreuves[ep].matiere_id)) {
                                this.options_matieres.push(ensembles_check[e].groupeEpreuve.epreuves[ep].matiere)
                            }
                        }
                    }

                    // Filières
                    if (ensembles_check[e].type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT) {
                        if (ensembles_check[e].concour) {
                            if (!this.options_filieres.find((f: any) => f.id === ensembles_check[e].concour_id)) {
                                this.options_filieres.push(ensembles_check[e].concour)
                            }
                        }
                    } else {
                        // Parcours des groupes épreuves
                        for (const ge in ensembles_check[e].groupeEpreuve.epreuves) {
                            for (const ep in ensembles_check[e].groupeEpreuve.epreuves[ge].epreuves) {
                                if (!this.options_filieres.find((f: any) => f.id === ensembles_check[e].groupeEpreuve.epreuves[ge].epreuves[ep].concour_id)) {
                                    this.options_filieres.push(ensembles_check[e].groupeEpreuve.epreuves[ge].epreuves[ep].concour)
                                }
                            }
                        }
                    }
                }


                // Load des centres
                this.$store.dispatch('centre/getCentres').then(() => {
                    const centres_oraux = this.$store.state.centre.centres.filter((c: any) => c.type_passation === TypePassation.TYPE_PASSATION_ORAL)
                    // Check si le user à les droits de liste examinateurs sur le centre
                    for (const c in centres_oraux) {
                    // Correspondant d'oral
                        if (this.$store.getters['auth/can'](Ability.GCI_EXAMINATEURS_OWN_VIEW, 'App\\Models\\Centre', { id : centres_oraux[c].id })) {
                            this.options_centres.push(centres_oraux[c])
                        }

                        // Gestionnaire ou
                        if (this.$store.getters['auth/can'](Ability.ORAL_AFEX_VIEW) || this.$store.getters['auth/can'](Ability.INTERV_OBSERV_MATIERE_OWN)) {
                            this.options_centres.push(centres_oraux[c])
                        }
                    }

                    // Si on n'a qu'un centre on le préselectionne
                    if (this.options_centres.length !== 0 && this.options_centres.length <= 1 && this.$store.getters['auth/cannot'](Ability.INTERV_OBSERV_MATIERE_OWN)) {
                        this.centre_select_id = this.options_centres[0].id
                    }

                    // On lance la recherche avec les éléments sélectionnés par défaut
                    this.rechercher()
                })
            })
        })
    }

    mounted() {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
