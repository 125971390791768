export interface CandidatJobInterface {
    id: number | 0,
    session_id: number | 0,
    name: string,
    first_name: string,
    code:  string,
    datas: any | null
}

export interface StateCandidatJobs {
    candidatJobs: Array<CandidatJobInterface> | [],
    meta: Array<string> | null,
    links: Array<string> | null,
    loading: boolean | false,
    error: Array<string> | null
}

export enum EtapeCandidatureEnum {
    SELECT_CANDIDAT = 'candidat',
    SELECT_JOB = 'job',
    SELECT_STATUS = 'status'
}

export interface SearchCandidatInterface {
    code: string,
    name: string,
    first_name: string
}
