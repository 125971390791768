






































































































































































































import { Vue, Component, Watch }                                    from 'vue-property-decorator'
import { FontAwesomeIcon }                                          from '@fortawesome/vue-fontawesome'
import { mapGetters, mapState }                                     from 'vuex'
import ErrorDisplay                                                 from '@/components/ErrorDisplay.vue'
import { CandidatInterface, TypeIndispoCandidat, getIndispoType }   from '@/types/Candidat'
import {
    base64ToArrayBuffer,
    dateNow,
    formatDateSinTime,
    formatStringDate,
    getFileNameFromHeader
} from '@/utils/helpers'
import { TypePassation }                                            from '@/types/Epreuve'
import { Ability }                                                  from '@/types/Ability'
import { EnsembleInterface, TypeEnsemble }                                             from '@/types/Ensemble'
import Candidats                                                    from '@/views/Planification/Vues/Candidats.vue'
import { isEmpty } from 'lodash'

/**
 * Composant relatif aux épreuves orales et travaux pratique d'un candidat
 */
@Component({
    computed: {
        ...mapGetters('auth', [
            'authUser',
            'can',
            'cannot',
            'isA',
            'isNotA'
        ]),
        ...mapGetters('candidat', ['editedCandidat']),
        ...mapState('candidat', ['loading', 'error']),
        ...mapState('session', ['sessionSelect']),
        ...mapGetters('epreuve', ['hasEpreuveTp']),
        ...mapState('planification', ['nb_resultats', 'error_planification', 'loading_planification', 'serie_selected', 'liste_candidats_planification']),
        ...mapState('serie', ['series', 'serieById']),
        ...mapGetters('serie', ['serieById']),
        ...mapGetters('centre', ['getNameCentreById'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay,
        Candidats
    }
})
export default class PopupEditCandidatInfOraux extends Vue {
    currentCandidat: CandidatInterface          = {} as CandidatInterface
    epreuvesIdInscriptions: number[]            = []
    lastPage                                    = 1
    formatDateSinTime                           = formatDateSinTime
    TypeIndispoCandidat                         = TypeIndispoCandidat
    getIndispoType                              = getIndispoType
    ability                                     = Ability

    fieldsPlanning: any[] = [
        { key: 'long_name',   label: 'Épreuve', type: 'text' },
        { key: 'equipe', label: 'Équipe',  type: 'text', class: 'text-center' },
        { key: 'examinateur', label: 'Examinateur',  type: 'text' },
        { key: 'centre', label: 'Centre',  type: 'text' },
        { key: 'date', label: 'Date',  type: 'text' },
        { key: 'heure', label: 'Heure',  type: 'text' }
    ]

    dataForTab: Array<any>  = []
    add_indispo             = false

    new_indispo: any = {
        session_id: 0,
        candidat_id: 0,
        jour: null,
        motif: null,
        matin_aprem: TypeIndispoCandidat.TYPE_JOURNEE,
        h_debut: null,
        h_fin: null
    }

    currentCandidatEquipesInter: any        = []
    currentCandidatEquipesParEpreuve: any   = []
    currentCandidatEquipesTp: any           = []
    edit_equipe                             = false // mode modification d'une équipe
    blocEquipeModification_id               = -1 // id de l'équipe en cours de modification.
    currentEquipeModificationCandidat_id    = -1 // id de l'équipe choisie pour la modification de l'équipe en cours de modification.
    currentListeChoixEquipeCandidat: any    = [] // tableau des équipe à disposition pour le choix de modification de l'équipe en cours de modification.
    planification_open                      = false
    currentCandidat_HasSerie                = false
    chaine_concours                         = ''
    seances_candidat: Array<any>            = []
    erreur_newIndispo                       = null
    canModifySerie                          = false
    edit_serie                              = false // mode modification de la série
    currentCandidat_serie_id                = -1 // id de la série choisie pour la modification de la série du candidat.

    fullyLoaded                             = false
    show_confirm_delete_indispo = false
    indispo_select: any = null
    phase_id = 0

    @Watch('editedCandidat.inscriptions')
    updateCandidatEdited () {
        this.initDatas()
    }

    @Watch('editedCandidat.ensembles')
    updateCandidatEditedEquipes () {
        // console.log('Dans updateCandidatEditedEquipes')
        // this.initEquipes()
    }

    beforeMount () {
        this.initDatas()
    }

    /** Retourne une string du ou des concours lié à l'ensemble */
    getConcoursEnsemble (ensemble_id: number) {
        let chaine_concours = ''
        const ensemble_select = this.$store.state.ensemble.ensembles.find((e: any) => e.id === ensemble_id)

        if (ensemble_select && ensemble_select.concour) {
            chaine_concours = ensemble_select.concour.name
        }

        return chaine_concours
    }

    /**
     * Initialisation des données relatives aux épreuves et au planning
     */
    async initDatas() {
        this.dataForTab = []
        this.epreuvesIdInscriptions = []
        if (this.$store.state.candidat.editedCandidat) {
            this.currentCandidat = this.$store.state.candidat.editedCandidat
            this.currentCandidat_serie_id = this.$store.state.candidat.editedCandidat.serie_id

            if (isEmpty(this.$store.getters['concour/concours'])) {
                await this.$store.dispatch('concour/getConcours', {
                    perPage: 0
                })
            }
            const concours = this.$store.getters['concour/concours']
                .find((concour: any) => concour.id === this.currentCandidat.concours[0].id)
            const phases: Array<number> = concours.phases.map((phase: any): Array<number> => phase.phase_id)
            this.phase_id = phases[1]
            // console.log('ORAUX - this.$store.state.candidat.candidats :', this.$store.state.candidat.candidats)
            // console.log('ORAUX - this.$store.state.candidat.editedCandidat :', this.$store.state.candidat.editedCandidat)

            // Le bloc qui suit ne sert plus dans la mesure où on n'affiche plus la table des épreuves du candidat.
            /*
            if (this.currentCandidat && this.currentCandidat.concour_id) {
                if (this.currentCandidat.inscriptions) {
                    for (const inscription of this.currentCandidat.inscriptions) {
                        if (inscription && inscription.epreuve_id) {
                            this.epreuvesIdInscriptions.push(inscription.epreuve_id)
                        }
                    }
                }
                const epreuvesConcours = this.$store.getters['epreuve/orderedEpreuves'](this.$store.getters['epreuve/epreuves'])

                for (const epreuve of epreuvesConcours) {
                    const indexEpreuveCandidat = this.epreuvesIdInscriptions.indexOf(epreuve.id)

                    // TODO: Gérer la phase d'admissibilité
                    if (indexEpreuveCandidat !== -1 && epreuve.phase_id === 2) {
                        // On n'affiche pas les épreuves maîtresses à option
                        const line = []
                        const epreuveDatasPlanning = Object.assign({}, epreuve)
                        epreuveDatasPlanning.centre = '-'

                        if (epreuve.type_passation === TypePassation.TYPE_EPREUVE_MAITRESSE) {
                        // Epreuve Maitresse on cherche a voir si on a une épreuve fille associée dans les inscriptions
                            const epreuves_filles = epreuvesConcours.filter((ep: any) => ep.id_epreuve_maitresse === epreuve.id)
                            let inscription_find = false
                            for (const epf in epreuves_filles) {
                                const ep_fille_index = this.currentCandidat.inscriptions.findIndex((ins: any) => { return ins.epreuve_id === epreuves_filles[epf].id })
                                if (ep_fille_index !== -1) {
                                    const epreuve_fille_id: number = this.currentCandidat.inscriptions[ep_fille_index].epreuve_id
                                    const epreuve_fille = epreuvesConcours.find((ep: any) => ep.id === epreuve_fille_id)

                                    line.push(
                                        { label: '', item: epreuveDatasPlanning.long_name + ' ( ' + (epreuve_fille ? epreuve_fille.name : '-') + ' )', type: 'text', typeAction: null, class: '' },
                                        { label: '', item: '-', type: 'text', typeAction: null, class: 'text-center' },
                                        { label: '', item: '-', type: 'text', typeAction: null, class: '' },
                                        { label: '', item: epreuveDatasPlanning.centre, type: 'text', typeAction: null, class: '' },
                                        { label: '', item: '-', type: 'text', typeAction: null, class: '' },
                                        { label: '', item: '-', type: 'text', typeAction: null, class: '' }
                                    )

                                    inscription_find = true
                                }
                            }

                            // Si pas d'inscription pour l'épreuve Maitresse on l'affiche avec une mention non effectuée
                            if (!inscription_find) {
                                let libelle_none = 'Aucune'
                                if (epreuve.tirage_aleatoire) {
                                    libelle_none = 'Tirage non effectué'
                                }

                                line.push(
                                    { label: '', item: epreuveDatasPlanning.long_name + ' ( ' + libelle_none + ' )', type: 'text', typeAction: null, class: '' },
                                    { label: '', item: '-', type: 'text', typeAction: null, class: 'text-center' },
                                    { label: '', item: '-', type: 'text', typeAction: null, class: '' },
                                    { label: '', item: epreuveDatasPlanning.centre, type: 'text', typeAction: null, class: '' },
                                    { label: '', item: '-', type: 'text', typeAction: null, class: '' },
                                    { label: '', item: '-', type: 'text', typeAction: null, class: '' }
                                )
                            }
                        } else if (epreuve.id_epreuve_maitresse === 0 && epreuve.type_passation !== TypePassation.TYPE_PASSATION_EXTERNE) {
                            line.push(
                                { label: '', item: epreuveDatasPlanning.long_name, type: 'text', typeAction: null, class: '' },
                                { label: '', item: '-', type: 'text', typeAction: null, class: 'text-center' },
                                { label: '', item: '-', type: 'text', typeAction: null, class: '' },
                                { label: '', item: epreuveDatasPlanning.centre, type: 'text', typeAction: null, class: '' },
                                { label: '', item: '-', type: 'text', typeAction: null, class: '' },
                                { label: '', item: '-', type: 'text', typeAction: null, class: '' }
                            )
                        }

                        this.dataForTab.push(line)
                    }
                }
            }
            */
        }
        // console.log('this.currentCandidat :', this.currentCandidat)

        this.loadCentresIfNotExists().then(() => {
            this.initEquipes()
            this.loadChaineConcours()

            // Check si la planification est ouverte - Condition -> Les candidats doivent avoir étaient répartis dans les équipes
            this.planification_open = !!(this.$store.state.session && this.$store.state.session.sessionSelect && this.$store.state.session.sessionSelect.candidats_ensembles_validated_at);

            if (this.$store.state.candidat.editedCandidat.serie) {
                this.currentCandidat_HasSerie = true
                this.loadSeriesSessionActiveIfNotExists().then(() => {
                    this.loadInfoSerieCandidat()
                })
            } else {
                this.currentCandidat_HasSerie = false
                this.fullyLoaded = true
            }
        })
    }

    /**
     * Récupération sur le serveur des séries de la session
     */
    loadSeriesSessionActiveIfNotExists ()
    {
        return new Promise((resolve) =>
        {
            // on récupère les séries de la session active si on ne les a pas
            if (this.$store.getters['serie/series'].length === 0) {
                this.$store.dispatch('serie/getSeries').then(() => {
                    resolve(true)
                })
            } else {
                resolve(true)
            }
        })
    }

    /**
     * Récupération sur le serveur des centres
     */
    loadCentresIfNotExists ()
    {
        return new Promise((resolve) =>
        {
            // on récupère les centres si on ne les a pas
            if (this.$store.getters['centre/centres'].length === 0) {
                this.$store.dispatch('centre/getCentres').then(() => {
                    resolve(true)
                })
            } else {
                resolve(true)
            }
        })
    }

    initEquipes () {
        this.currentCandidatEquipesParEpreuve = []
        this.currentCandidatEquipesInter = []
        this.currentCandidatEquipesTp = []

        if (this.$store.state.candidat.editedCandidat && this.$store.state.candidat.editedCandidat.ensembles.length > 0) {
            for (const ensemble of this.$store.state.candidat.editedCandidat.ensembles) {
                switch (ensemble.type_ensemble) {
                    case TypeEnsemble.TYPE_ENSEMBLE_PAR_EPREUVE: {
                        this.currentCandidatEquipesParEpreuve.push({ id: ensemble.id, name: ensemble.name, type_ensemble: ensemble.type_ensemble, centre_name: this.$store.getters['centre/getNameCentreById'](ensemble.centre_id) })
                        break
                    }
                    case TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT: {
                        this.currentCandidatEquipesInter.push({ id: ensemble.id, name: ensemble.name, type_ensemble: ensemble.type_ensemble, centre_name: this.$store.getters['centre/getNameCentreById'](ensemble.centre_id) })
                        break
                    }
                    case TypeEnsemble.TYPE_ENSEMBLE_TP: {
                        this.currentCandidatEquipesTp.push({ id: ensemble.id, name: ensemble.name, type_ensemble: ensemble.type_ensemble, centre_name: this.$store.getters['centre/getNameCentreById'](ensemble.centre_id) })
                        break
                    }
                }
            }
        }
    }

    /** Recharge complètement le candidat en cours d'édition (spécifique à la modification des équipes du candidat. Permet notamment la maj des données pour le rechargement du calendrier des oraux) */
    fullCandidatReload() {
        // On recharge à partir de la BDD l'ensemble des informations du candidat
        const editedCandidat = this.$store.state.candidat.editedCandidat
        this.$store.dispatch('candidat/getCandidat', editedCandidat).then(() => {
            const params =
                {
                    candidatId: editedCandidat.id,
                    nomFiliere: editedCandidat.filiere
                }
            this.$store.commit('candidat/SET_CANDIDAT_FILIERE', params)
            this.$store.commit('candidat/SET_SELECTED_CANDIDAT', editedCandidat.id)
            this.$store.commit('candidat/SET_EDITED_CANDIDAT', editedCandidat.id)

            this.initDatas()
        })
    }

    loadChaineConcours () {
        // Chaine de concours pour les équipes transverses
        if (this.$store.state.banque) {
            this.chaine_concours = this.$store.getters['banque/banques']
                ?.map((banque: any) => banque.code)
                ?.join(', ') || ''
        }
    }

    /** Affiche le formulaire pour ajouter une indisponibilité du candidat */
    addIndisponibilite () {
        if (this.edit_equipe) {
            // On arrête la modif d'équipe éventuellement en cours
            this.cancelModificationEquipeCandidat()
        }
        if (this.edit_serie) {
            // On arrête la modif de la série éventuellement en cours
            this.cancelModificationSerieCandidat()
        }
        this.$store.state.candidat.error = null
        this.add_indispo = true
    }

    /** Annule la création d'une indisponibilité */
    cancelIndisponibilite () {
        this.$store.state.candidat.error = null
        this.new_indispo = {
            session_id: 0,
            candidat_id: 0,
            jour: null,
            motif: null,
            matin_aprem: TypeIndispoCandidat.TYPE_JOURNEE,
            h_debut: null,
            h_fin: null
        }
        this.add_indispo = false
        this.indispo_select = null
        this.show_confirm_delete_indispo = false
    }

    /**
     * Construction des données globales de mise à jour pour un candidat
     */
    buildPayLoad () {
        const payload: any = {}
        const currentCandidat = this.$store.state.candidat.editedCandidat
        if (currentCandidat) {
            payload.id = currentCandidat.id
            payload.code = currentCandidat.code
            payload.concour_id = currentCandidat.concour_id
            payload.demission = currentCandidat.demission
            payload.name = currentCandidat.name
            payload.etat_dossier = currentCandidat.etat_dossier
            payload.nationalite_fr = currentCandidat.nationalite_fr
            payload.first_name = currentCandidat.first_name
            payload.email = currentCandidat.email
            payload.filiere = currentCandidat.filiere
            payload.date_naissance = currentCandidat.date_naissance
            payload.ville_naissance = currentCandidat.ville_naissance
            payload.pays_naissance = currentCandidat.pays_naissance
            payload.portable = currentCandidat.portable
            payload.telephone = currentCandidat.telephone
            payload.nationalite = currentCandidat.nationalite
            payload.adresse1 = currentCandidat.adresse1
            payload.adresse2 = currentCandidat.adresse2
            payload.code_postal = currentCandidat.code_postal
            payload.bureau_distribution = currentCandidat.bureau_distribution
            payload.pays_distribution = currentCandidat.pays_distribution
            payload.etablissement_id = currentCandidat.etablissement_id
            payload.inscriptions = currentCandidat.inscriptions
            payload.amenagements = currentCandidat.amenagements
            payload.indisponibilites = currentCandidat.indisponibilites
            payload.serie_id = currentCandidat.serie_id
        }
        return payload
    }

    /** gère un contrôle de la validité de l'indispo saisie avant de l'enregistrer */
    controleSaveIndisponibilite () {
        this.erreur_newIndispo = this.controleNewIndispo()
        if (!this.erreur_newIndispo) {
            // on lance l'enregistrement de l'indisponibilité si pas d'erreur sur sa validité
            this.saveIndisponibilite()
        }
    }

    /** détermine si l'indisponibilité saisie est valide et retourne l'erreur à afficher en fonction du contexte */
    controleNewIndispo () {
        let erreurCheck: any = null
        if (this.seances_candidat && this.seances_candidat[this.new_indispo.jour]) {
            switch (parseInt(this.new_indispo.matin_aprem)) {
                case 0: {
                    // AM
                    if (this.seances_candidat[this.new_indispo.jour].AM.length > 0) {
                        erreurCheck = 'Vous ne pouvez pas ajouter cette indisponibilité car le candidat a déjà une séance ce jour pour le matin.'
                    }
                    break
                }
                case 1: {
                    // PM
                    if (this.seances_candidat[this.new_indispo.jour].PM.length > 0) {
                        erreurCheck = 'Vous ne pouvez pas ajouter cette indisponibilité car le candidat a déjà une séance ce jour pour l\'après-midi.'
                    }
                    break
                }
                case 2: {
                    // DAY
                    if (this.seances_candidat[this.new_indispo.jour].AM.length > 0 || this.seances_candidat[this.new_indispo.jour].PM.length > 0) {
                        erreurCheck = 'Vous ne pouvez pas ajouter cette indisponibilité car le candidat a déjà une séance pour ce jour.'
                    }
                    break
                }
            }
        }
        return erreurCheck
    }

    /** Enregistre une indisponibilité sur un candidat */
    saveIndisponibilite () {
        this.$store.state.candidat.error = null
        // Alimente les indispos
        if (this.$store.state.candidat.editedCandidat && this.new_indispo.jour) {
            const currentCandidat = this.$store.state.candidat.editedCandidat
            // Complète l'indisponibilité
            this.new_indispo.session_id = this.$store.state.session.sessionSelect.id

            this.new_indispo.candidat_id = currentCandidat.id
            currentCandidat.indisponibilites.push(this.new_indispo)

            const idInfo = 't_info_' + Math.random()
            const infosToaster = {
                id: idInfo,
                toaster: 'b-toaster-top-right',
                variant: 'primary',
                noCloseButton: true,
                fade: true,
                noAutoHide: true
            }

            this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

            // Update du candidat
            const payload = this.buildPayLoad()
            this.$store.dispatch('candidat/updateCandidat', payload)
                .then(() => {
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    currentCandidat.indisponibilites = this.$store.state.candidat.selectedCandidat.indisponibilites
                    this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                    this.cancelIndisponibilite()
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            this.$store.state.candidat.error = {
                indisponibilites: [
                    'Le champ jour est obligatoire.'
                ]
            }
        }
    }

    /**
     * Confirmation de suppression indisponibilité
     */

    confirmation_delete_indispo (index: number) {
        this.indispo_select = index
        this.show_confirm_delete_indispo = true
        this.add_indispo = false
    }

    /** Delete une indisponibilité */
    deleteIndisponibilite () {
        if (this.$store.state.candidat.editedCandidat) {
            if (this.edit_equipe) {
                // On arrête la modif d'équipe éventuellement en cours
                this.cancelModificationEquipeCandidat()
            }
            if (this.edit_serie) {
                // On arrête la modif de la série éventuellement en cours
                this.cancelModificationSerieCandidat()
            }

            const currentCandidat = this.$store.state.candidat.editedCandidat
            currentCandidat.indisponibilites.splice(this.indispo_select, 1)

            const idInfo = 't_info_' + Math.random()
            const infosToaster = {
                id: idInfo,
                toaster: 'b-toaster-top-right',
                variant: 'primary',
                noCloseButton: true,
                fade: true,
                noAutoHide: true
            }

            this.$bvToast.toast('Suppression en cours ...', infosToaster)

            // Update du candidat
            const payload = this.buildPayLoad()
            this.$store.dispatch('candidat/updateCandidat', payload)
                .then(() => {
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Suppression terminée.', succesToaster)
                    this.cancelIndisponibilite()
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    /** Enregistre la modification de la serie sur le candidat */
    saveModificationSerieCandidat () {
        this.$store.state.candidat.error = null
        // Alimente les indispos
        if (this.$store.state.candidat.editedCandidat) {
            const currentCandidat = this.$store.state.candidat.editedCandidat

            currentCandidat.serie_id = this.currentCandidat_serie_id

            const idInfo = 't_info_' + Math.random()
            const infosToaster = {
                id: idInfo,
                toaster: 'b-toaster-top-right',
                variant: 'primary',
                noCloseButton: true,
                fade: true,
                noAutoHide: true
            }

            this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

            // Update du candidat
            const payload = this.buildPayLoad()
            this.$store.dispatch('candidat/updateCandidat', payload)
                .then(() => {
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    // currentCandidat.indisponibilites = this.$store.state.candidat.selectedCandidat.indisponibilites
                    this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                    this.cancelModificationSerieCandidat()
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            //
        }
    }

    /**  */
    checkcanModifySerie () {
        let tempCanModifySerie = true
        if (this.currentCandidat && this.currentCandidat.serie && this.seances_candidat) {
            tempCanModifySerie = true
            for (const k in this.seances_candidat) {
                if (k !== '-1') {
                    if (k === '-2') {
                        // cas des séances planifiées dans une autre série.
                        if (this.seances_candidat[k].NP) {
                            if (this.seances_candidat[k].NP.length > 0) {
                                tempCanModifySerie = false
                                break
                            }
                        }
                    } else {
                        // cas des séances planifiées dans la série. Une séance est considérée planifiée si au moins un des tableaux AM ou PM n'est pas vide.
                        if (this.seances_candidat[k].AM && this.seances_candidat[k].PM) {
                            if (this.seances_candidat[k].AM.length > 0 || this.seances_candidat[k].PM.length > 0) {
                                tempCanModifySerie = false
                                break
                            }
                        }
                    }
                }
            }
        }

        this.canModifySerie = tempCanModifySerie
    }

    /**  */
    checkcanModifyEquipe (equipe_id: number) {
        let tempHasSeance = false
        if (this.seances_candidat) {
            for (const k in this.seances_candidat) {
                if (k !== '-1') {
                    if (k === '-2') {
                        // cas des séances planifiées dans une autre série.
                        if (this.seances_candidat[k].NP) {
                            if (this.seances_candidat[k].NP.length > 0) {
                                for (const sNP of this.seances_candidat[k].NP) {
                                    if (parseInt(sNP.ensemble_id) === equipe_id) {
                                        tempHasSeance = true
                                        break
                                    }
                                }
                            }
                        }
                    } else {
                        // cas des séances planifiées dans la série.
                        if (this.seances_candidat[k].AM && this.seances_candidat[k].PM) {
                            if (this.seances_candidat[k].AM.length > 0) {
                                for (const sAM of this.seances_candidat[k].AM) {
                                    if (parseInt(sAM.ensemble_id) === equipe_id) {
                                        tempHasSeance = true
                                        break
                                    }
                                }
                            }
                            if (this.seances_candidat[k].PM.length > 0) {
                                for (const sPM of this.seances_candidat[k].PM) {
                                    if (parseInt(sPM.ensemble_id) === equipe_id) {
                                        tempHasSeance = true
                                        break
                                    }
                                }
                            }
                        }
                    }
                }
                if (tempHasSeance) {
                    // on a déjà trouvé une séance planifiée, donc on sort de la boucle.
                    break
                }
            }
        }
        return !tempHasSeance
    }

    /**
     * Load les infos de la série du candidat en cours d'édition
     */
    loadInfoSerieCandidat () {
        this.$store.commit('planification/SET_LOADING', true)


        // Chargement des épreuves de correction
        this.$store.dispatch('epreuve/getEpreuvesCorrections', { type_passation: TypePassation.TYPE_PASSATION_ORAL, params: null }).then((response) => {
            this.$store.state.planification.dico_epreuve_corrections = response.data.data
            const serie_temp = this.$store.state.serie.series.find((s: any) => s.id === this.$store.state.candidat.editedCandidat.serie_id)
            if (serie_temp) {
                this.$store.commit('planification/SET_SERIE_SELECTED', serie_temp)
                this.loadDatesSeriesCandidat()
            }
            // Chargement des matières
            this.$store.dispatch('matiere/getMatieres').then(() => {
                const candidatForCalendrier = this.createCandidatForCalendrier()
                const liste_cand: any = []

                liste_cand.push(candidatForCalendrier)
                // MAJ de la liste des candidats qui sert à la construction du calendrier des oraux
                this.$store.commit('planification/SET_LISTE_CANDIDATS_PLANIFICATION', liste_cand)
                this.$store.commit('planification/SET_LOADING', false)

                // on récupère les séances du candidat (sert pour les checks de création d'indisponibilités du candidat)
                this.seances_candidat = this.$store.state.planification.liste_candidats_planification[0].seances
                this.fullyLoaded = true

                this.checkcanModifySerie()
                // console.log('this.seances_candidat :', this.seances_candidat)
            }).catch((error) => {
                console.log('ko:' + error)
                this.$store.commit('planification/SET_LOADING', false)
            })
        }).catch((error) => {
            console.log('ko:' + error)
            this.$store.commit('planification/SET_LOADING', false)
        })
    }

    /**
     * Créé l'objet candidat qui sera envoyé au composant Candidats.vue pour l'affichage du calendrier.
     * On rajoute notamment dans chaque séance l'objet epreuveCorrection qu'on n'a pas par défaut via le getCandidats pour les oraux (on n'a que la donnée epreuve_correction_id).
     */
    createCandidatForCalendrier () {
        const candidatForCalendrier = Object.assign([], this.$store.state.candidat.editedCandidat)
        if (candidatForCalendrier.seances && candidatForCalendrier.seances.length > 0) {
            for (const s in candidatForCalendrier.seances) {
                if (candidatForCalendrier.seances[s].epreuve_correction_id) {
                    const ep_correction = this.$store.getters['epreuve/getEpreuveCorrectionById'](candidatForCalendrier.seances[s].epreuve_correction_id)
                    if (ep_correction) {
                        candidatForCalendrier.seances[s].epreuveCorrection = ep_correction
                    }
                }
            }
        }
        return candidatForCalendrier
    }

    /**
     * Charge les jours du semainier
     */
    loadDatesSeriesCandidat () {
        this.$store.commit('planification/SET_ERROR', null)
        const chevauchement: any = this.$store.getters['planification/get_nb_jours_chevauchement']
        this.$store.commit('planification/SET_JOURS_SERIES', this.$store.getters['disponibilite/create_jours_serie_with_chevauchement'](this.$store.state.planification.serie_selected, chevauchement.jours_avant, chevauchement.jours_apres))
    }

    /** Edite une équipe */
    openModificationEquipeCandidat (equipe: any) {
        if (this.edit_serie) {
            // On arrête la modif de la série éventuellement en cours
            this.cancelModificationSerieCandidat()
        }
        if (this.add_indispo) {
            // On arrête l'ajout de l'indispo éventuellement en cours
            this.cancelIndisponibilite()
        }

        this.blocEquipeModification_id = equipe.id
        this.currentEquipeModificationCandidat_id = equipe.id
        this.currentListeChoixEquipeCandidat = []
        const params = {
            'filter-type_ensemble': equipe.type_ensemble,
            'filter-concour_id': this.$store.state.candidat.editedCandidat.concour_id,
            page: 1,
            sort: 'name',
            direction: 'asc'
        }
        this.$store.dispatch('ensemble/getEnsembles', { params: params }).then(() =>  {
            this.currentListeChoixEquipeCandidat = this.$store.state.ensemble.ensembles
            this.currentListeChoixEquipeCandidat.sort((a: EnsembleInterface, b: EnsembleInterface) => {
                return (a.type_ensemble > b.type_ensemble) ? 1 : (a.type_ensemble === b.type_ensemble ? (a.name > b.name ? 1 : -1) : -1)
            })
            this.edit_equipe = true
        })
    }

    /** Annule la modification de la série du candidat */
    cancelModificationEquipeCandidat () {
        this.blocEquipeModification_id = -1
        this.currentListeChoixEquipeCandidat = []
        this.$store.commit('ensemble/SET_ENSEMBLES', null)
        this.edit_equipe = false
    }

    /** Enregistre la modification de l'équipe sur le candidat */
    saveModificationEquipeCandidat (id_equipe: number) {
        this.$store.state.candidat.error = null
        if (this.$store.state.candidat.editedCandidat) {
            const idInfo = 't_info_' + Math.random()
            const infosToaster = {
                id: idInfo,
                toaster: 'b-toaster-top-right',
                variant: 'primary',
                noCloseButton: true,
                fade: true,
                noAutoHide: true
            }

            this.$bvToast.toast('Enregistrement en cours ...', infosToaster)



            // Update de l'équipe du candidat
            const payload = {
                id: this.$store.state.candidat.editedCandidat.id,
                oldEnsembleOld_id: id_equipe,
                newEnsemble_id: this.currentEquipeModificationCandidat_id
            }
            this.$store.dispatch('candidat/updateEnsembleCandidat', payload)
                .then(() => {
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    // currentCandidat.indisponibilites = this.$store.state.candidat.selectedCandidat.indisponibilites
                    this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                    this.cancelModificationEquipeCandidat()

                    // rechargement complet du candidat pour maj du calendrier des oraux.
                    this.fullCandidatReload()
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    /** Ouvre l'accès à la modification de la série du candidat */
    openModificationSerieCandidat () {
        if (this.edit_equipe) {
            // On arrête la modif d'équipe éventuellement en cours
            this.cancelModificationEquipeCandidat()
        }
        if (this.add_indispo) {
            // On arrête l'ajout de l'indispo éventuellement en cours
            this.cancelIndisponibilite()
        }
        this.edit_serie = true
    }

    /** Annule la modification de la série du candidat */
    cancelModificationSerieCandidat () {
        this.currentCandidat_serie_id = this.$store.state.candidat.editedCandidat.serie_id
        this.edit_serie = false
    }

    /** Retourne les noms des Ensembles en filtrant les doublons */
    filterDuplicatesEnsembles (ensembles: any[]) {
        ensembles = ensembles.map(e => e.name)
        return [...new Set(ensembles)]
    }

    /**
     * getConvocation
     * Récupère la convocation du candidat
     * @return {void}
     */
    getConvocation(): void {
        const params = {
            candidat_id: this.currentCandidat.id,
            phase_id: this.phase_id
        }

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Relance de la publication en cours...', infosToaster)

        this.$store.dispatch('candidat/getConvocationCandidat', params)
            .then((response) => {
                const fileName = getFileNameFromHeader(response.headers) || `Candidat_convocation_${this.currentCandidat.code}_${formatStringDate(dateNow(), 'DDMMYYYY_HHmm')}.pdf`
                const url = URL.createObjectURL(new Blob([base64ToArrayBuffer(response.data)]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * retryPublication
     * Relance de la publication des résultats
     * @returns {void}
     */
    retryPublication(): void {
        const params = {
            candidat_id: this.currentCandidat.id,
            phase_id: this.phase_id,
            payload: {
                concour_id: this.currentCandidat.concour_id,
                publicationName: 'planning'
            }
        }

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Relance de la publication en cours...', infosToaster)

        this.$store.dispatch('candidat/retryPublication', params)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Publication relancée !', succesToaster)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }
}
