


































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component, Watch }    from 'vue-property-decorator'
import { mapGetters, mapState }     from 'vuex'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'
import { Ability }                  from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { base64ToArrayBuffer, formatDate, getFileNameFromHeader } from '@/utils/helpers'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import Back from '@/components/Tools/Back.vue'
import { FournitureAdministrativeInterface } from '@/types/FournitureAdministrative'


@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapState('fournitureAdministrative', ['loading', 'error']),
        ...mapState('centre', ['centreSelect'])
    },
    components: {
        ExaGenericTable,
        ErrorDisplay,
        Back,
        'font-awesome-icon': FontAwesomeIcon
    }
})

export default class BibliothequeDocumentsAdministratifs extends Vue
{
    formatDate = formatDate
    Ability = Ability
    is_mobile_device = false
    sortDirection   = 'asc'
    params: any = {}
    exportWorking = false
    read_only = false
    documents: Array<FournitureAdministrativeInterface> = []
    genericfields: Array<any> = [
        { key: 'document',          label: 'Document',          sortable: false,   class: '', type: 'text' },
        { key: 'pdf',               label: 'Télécharger',       sortable: false,   class: 'text-center max_size_column', type: 'icons' }
    ]

    dataForTab: Array<any>  = []
    contexte = 'centres'

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    @Watch('sessionSelect')
    refreshStateInventaire () {
        this.getStateBibliotheque()
    }

    // Création des lignes du tableau
    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                let line: any = []
                const icone_pdf  = [{ name:'file-pdf', class: 'text-primary', id: result.id, data: result, title: 'Télécharger' }]

                line = [
                    { label: '', item: result.name,  type: 'text', typeAction: null, class: '' },
                    { label: 'link', item: icone_pdf, type: 'icons', typeAction: 'dl_pdf', class: 'text-center max_size_column' }
                ]

                this.dataForTab.push(line)
            }
        }
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    this.loadHandler()
                    break
                case 'dl_pdf':
                    this.$store.commit('fournitureAdministrative/SET_ERROR', null)
                    if (paParams[1][0].data.custom === 1) {
                        // Téléchargement du pdf si version numérique
                        this.dlPdf(paParams[1][0].data)
                    } else {
                        // Génération du doc si fourniture générée par le système
                        this.generePdf(paParams[1][0].data)
                    }
                    break
                default:
                    break
            }
        }
    }

    // Applique le chargement de la pagination
    loadHandler () {
        this.$store.dispatch('fournitureAdministrative/getFournitureAdministratives')
    }

    // Applique les filtres
    filtreSortHandler(params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.$store.dispatch('fournitureAdministrative/getFournitureAdministratives',  { params: this.params }).then((response) => {
                this.documents  = response.data.data
                this.setDataForGenericTab(this.documents)
            })
        }
    }

    // Lance le techargement du fichier pdf du document custom
    dlPdf (doc: any) {
        let fileName = ''
        this.exportWorking = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Téléchargement en cours...', infosToaster)

        this.$store.dispatch('fournitureAdministrative/getPDFFournitureAdministrative', { fa_id: doc.id, doc_id: doc.document[0].id })
            .then((response) => {
                const fileNameTemp = doc.document[0].name
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([base64ToArrayBuffer(response.data)]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.exportWorking = false
            })
    }

    // Lance la génération du fichier pdf du document non custom
    generePdf (doc: any) {
        let fileName = ''
        this.exportWorking = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Téléchargement en cours...', infosToaster)

        const params = {
            centre_id: this.$store.state.centre.centreSelect.id,
            params: {
                pdf: 1,
                format: 'b64',
                type_fournitures: [doc.code]
            }
        }

        this.$store.dispatch('fournitureAdministrative/getImpressionFournitureAdministrativeCentres', params)
            .then((response) => {
                const fileNameTemp = getFileNameFromHeader(response.headers) || 'document.pdf'
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([base64ToArrayBuffer(response.data)]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.exportWorking = false
            })
    }

    // Retourne l'état de publication de la bibliothèque
    getStateBibliotheque () {
        let state_biblio = { message: '', bg_color: 'barre_default', text_color: '' }
        if (this.$store.state.session.sessionSelect) {
            if (!this.$store.state.session.sessionSelect.bibliotheque_fa_start_at && !this.$store.state.session.sessionSelect.bibliotheque_fa_end_at) {
                state_biblio = { message: 'La bibliothèque des documents administratifs est fermée.', bg_color: 'barre_default', text_color: 'barre_text_defaut' }
                this.read_only = true
            } else if (!this.$store.state.session.sessionSelect.bibliotheque_fa_start_at && this.$store.state.session.sessionSelect.bibliotheque_fa_end_at) {
                state_biblio = { message: 'La bibliothèque des documents administratifs est ouverte jusqu\'au ' + formatDate(this.$store.state.session.sessionSelect.bibliotheque_fa_end_at) + ' inclus.', bg_color: 'barre_soumis', text_color: 'barre_text_soumis' }
            } else if (this.$store.state.session.sessionSelect.bibliotheque_fa_start_at && this.$store.state.session.sessionSelect.bibliotheque_fa_end_at) {
                state_biblio = { message: 'La bibliothèque des documents administratifs est ouverte du ' + formatDate(this.$store.state.session.sessionSelect.bibliotheque_fa_start_at) + ' au ' + formatDate(this.$store.state.session.sessionSelect.bibliotheque_fa_end_at) + ' inclus.', bg_color: 'barre_soumis', text_color: 'barre_text_soumis' }
            }
        }

        return state_biblio
    }

    load () {
        // Chargement de tous les documents de fournitures administratives
        let params = {}

        // TODO : A finir de cabler avec contexte et printed_at sur fournitures
        if (this.contexte === 'centres') {
            params = { 'filter-dispo_numerique': 1 }
        } else {
            params = { 'filter-printed_at': 1, 'filter-dispo_numerique': 1 }
        }

        this.$store.dispatch('fournitureAdministrative/getFournitureAdministratives', params).then((response) => {
            this.documents  = response.data.data
            this.setDataForGenericTab(this.documents)
        })
    }

    mounted() {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        } else {
            // Renvoi au dashboard pour reselectionné la session
            this.$router.push('/dashboard_i')
        }

        // Detection si le device est un mobile
        if ('ontouchstart' in document.documentElement)
        {
            this.is_mobile_device = true
        }
        else
        {
            this.is_mobile_device = false
        }
    }
}
