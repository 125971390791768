export enum code_publishable_message {
    NON_INSCRIT_BANQUE = 'non_inscrit_banque',
    NON_INSCRIT_CONCOURS = 'non_inscrit_concours',
    AVANT_DATE_OUVERTURE = 'avant_date_ouverture',
    APRES_DATE_FERMETURE = 'apres_date_fermeture',
    PUBLICATION_BLOQUEE = 'publication_bloquee',
    CANDIDAT_BLOQUE = 'candidat_bloque',
    DONNEES_NON_TRANSFEREES = 'donnees_non_transferees',
    ACCES_OK = 'acces_ok',
}

export enum type_publishable_message {
    TYPE_HTML = 1,
    TYPE_PDF = 2,
    TYPE_HTML_AND_PDF = 3
}

export interface PublishableMessageInterface {
    id: number | 0,
    name: string,
    code: string,
    entity_type: string,
    entity_properties_to_check: any | null,
    subparts_publishables: any | null,
    display_group: string,
    messages: Array<any>
}

export interface PublishableInterface {
    id: number | 0,
    name: string,
    code: string
}

export interface StatePublishableMessages {
    publishableMessages: Array<PublishableMessageInterface> | [],
    meta: Array<string> | null,
    links: Array<string> | null,
    loading: boolean | false,
    error: Array<string> | null,
    totalRows: number | 0,
    publishableMessageSelect: PublishableMessageInterface | {},
    publishables: Array<PublishableInterface> | []
}
