
































































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { Ability } from '@/types/Ability'
import EditorTinyMCE from '@/components/Tools/EditorTinyMCE.vue'
import { Decision } from '@/types/DossierAcademique'
import VuePdfApp from 'vue-pdf-app'
import { checkIcone } from '@/utils/helpers'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { getEtatSpecTypeReclamation, TypeReclamation, getEtatSpecTypeReponseReclamation, TypeReponseReclamation } from '@/types/Reclamation'

@Component({
    components: {
        ExaGenericTable,
        EditorTinyMCE,
        VuePdfApp,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('reclamationType', ['loading', 'error', 'reclamationTypes', 'meta', 'links', 'totalRows']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})

export default class ReclamationTemplates extends Vue {
    Ability                           = Ability
    reclamationType: any              = null
    reclamationTypeTemp: any          = null
    showModalEditionReclamationType   = false
    showModalMessageDelete            = false
    modeRedaction                     = true
    modeConsultation                  = false
    decision                          = Decision
    source_doc: any                   = ''
    dataForTab: Array<any>            = []
    getEtatSpecTypeReclamation        = getEtatSpecTypeReclamation
    TypeReclamation                   = TypeReclamation
    getEtatSpecTypeReponseReclamation = getEtatSpecTypeReponseReclamation
    TypeReponseReclamation            = TypeReponseReclamation

    // Ensemble des colonnes du tableau
    genericfields = [
        { key: 'etatEdit'        , label: ''                   , sortable: false, class: '', type: 'action' },
        { key: 'name'            , label: 'Libellé'            , sortable: false, class: '', type: 'text' }  ,
        { key: 'type_reclamation', label: 'Type de réclamation', sortable: false, class: '', type: 'text' }  ,
        { key: 'type_reponse'    , label: 'Type de réponse'    , sortable: false, class: '', type: 'text' }
    ]

    config = {
        toolbar: {
            toolbarViewerRight: { presentationMode: false, openFile: false, viewBookmark: false, secondaryToolbarToggle: false }
        }
    }

    // Remplissage du tableau
    setDataForGenericTab(poData: any, isLoadMore = false) {
        if (!isLoadMore) {
            this.dataForTab = []
        }
        if (poData) {
            for (const result of poData) {
                const can = this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE) && result.nbReclamations === 0
                const icone = checkIcone(Ability.ADM_PAR_MANAGE, can)
                if (result.nbReclamations > 0) {
                    icone.label = 'Voir (Réponse non éditable)'
                }
                const line = [
                    { label: icone.label, item: result, type: 'action',  typeAction: 'edit', class: 'commons_first_action_button', icon: icone.icon, disabled: false },
                    { label: '', item: result.name, type: 'text', typeAction: null, class: '' },
                    { label: '', item: getEtatSpecTypeReclamation(result.type_reclamation).libelle, type: 'text', typeAction: null, class: '' },
                    { label: '', item: getEtatSpecTypeReponseReclamation(result.type_reponse).libelle, type: 'text', typeAction: null, class: '' }
                ]
                this.dataForTab.push(line)
            }
        }
    }


    // Fermeture du mode consultation
    back () {
        this.source_doc = ''
        this.modeRedaction = true
        this.modeConsultation = false
    }

    saveDatasEmail (value: string) {
        this.reclamationTypeTemp.tpl_email_message = value
    }

    openAddReclamationType () {
        this.reclamationTypeTemp = {
            id: 0,
            name: '',
            type: 0,
            body: '',
            nbReclamations: 0
        }

        this.showModalEditionReclamationType = true
    }

    // Ouvre l'édition d'une notification template
    editReclamationType (row: any) {
        this.reclamationType = row
        this.reclamationTypeTemp = {
            id: this.reclamationType.id,
            name: this.reclamationType.name,
            type_reclamation: this.reclamationType.type_reclamation,
            type_reponse: this.reclamationType.type_reponse,
            body: this.reclamationType.body,
            nbReclamations: this.reclamationType.nbReclamations
        }

        this.showModalEditionReclamationType = true
    }

    // Annule l'ouverture d'une édition
    cancelEdit () {
        this.reclamationType = null
        this.reclamationTypeTemp = null
        this.showModalEditionReclamationType = false
    }

    // Enregistre l'édition
    editSuite () {
        const payload: any = {
            id: this.reclamationTypeTemp.id,
            name: this.reclamationTypeTemp.name,
            type_reclamation: this.reclamationTypeTemp.type_reclamation,
            type_reponse: this.reclamationTypeTemp.type_reponse,
            body: this.reclamationTypeTemp.tpl_email_message,
            response_personalization: this.reclamationTypeTemp.tpl_email_message.includes('***message_gestionnaire***') ? 1 : 0
        }

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.$store.dispatch(`reclamationType/${this.reclamationTypeTemp.id === 0 ? 'add' : 'update'}ReclamationType`, payload)
            .then(() => {
                this.reclamationType = null
                this.reclamationTypeTemp = null
                this.showModalEditionReclamationType = false

                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast(`Modèle ${this.reclamationTypeTemp.id === 0 ? 'ajouté' : 'enregistré'} avec succès !`, succesToaster)

                this.$store.dispatch('reclamationType/getReclamationTypes').then(() => {
                    this.setDataForGenericTab(this.$store.state.reclamationType.reclamationTypes)
                })
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Events
    handleTableEvent (paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'edit':
                    // this.$router.push('/reclamationTypes/' + paParams[1])
                    this.editReclamationType(paParams[1])
                    break
            }
        }
    }

    beforeMount () {
        this.load()
    }

    load() {
        this.$store.dispatch('reclamationType/getReclamationTypes').then(() => {
            this.setDataForGenericTab(this.$store.state.reclamationType.reclamationTypes)
        })
    }
}
