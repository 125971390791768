import { Ability } from '@/types/Ability'

export interface DocumentInterface {
    id: number | 0,
    name: string | '',
    type: string | '',
    mime_type: string | ''
}

export interface DocumentSelectInterface {
    name: string | '',
    document_type: number | 0,
    file: File | null
}

export enum DocumentService {
    OUTPUT_FORMAT_B64S = 'b64s',
    OUTPUT_FORMAT_B64_INLINE = 'b64',
    OUTPUT_FORMAT_INLINE = 'inline',
    OUTPUT_FORMAT_STRING = 'string',
    OUTPUT_FORMAT_B64_DATA = 'base64'
}

export enum DepotFichier {
    DEPOT_FICHE_ORAL_PAPIER = 'fiche d\'oral papier',
    DEPOT_SUJET = 'sujet',
    DEPOT_AUTRE_DOCUMENT = 'autre document'
}

export function getAbilitiesFromEntityType(entity_type: string): {view: Ability; manage: Ability}  {
    switch (entity_type) {
        case 'ContratDossierAdministratif':
            return { view: Ability.SIGN_CONTRAT_VIEW, manage: Ability.SIGN_CONTRAT_MANAGE }
        case 'ProcesVerbalAcademique':
            return { view: Ability.SIGN_PVA_VIEW, manage: Ability.SIGN_PVA_MANAGE }
        case 'DossierAcademique':
            return { view: Ability.SIGN_LETTRE_VIEW, manage: Ability.SIGN_LETTRE_MANAGE }
        case 'Centre':
            return { view: Ability.SIGN_CONVENTION_VIEW, manage: Ability.SIGN_CONVENTION_MANAGE }
        case 'ConventionAnnexe':
            return { view: Ability.SIGN_CONVENTIONANNEXE_VIEW, manage: Ability.SIGN_CONVENTIONANNEXE_MANAGE }
        case 'CentreFicheHoraireRecapitulatif':
            return { view: Ability.SIGN_FHRECAP_VIEW, manage: Ability.SIGN_FHRECAP_MANAGE }
        case 'Reclamation':
            return { view: Ability.SIGN_RECLAMATION_VIEW, manage: Ability.SIGN_RECLAMATION_MANAGE }
        case 'Avenant':
            return { view: Ability.SIGN_AVENANT_VIEW, manage: Ability.SIGN_AVENANT_MANAGE }
        case 'CandidatAmenagementEcrit':
        case 'CandidatAmenagementOral':
            return { view: Ability.SIGN_AMENAGEMENT_VIEW, manage: Ability.SIGN_AMENAGEMENT_MANAGE }
        default:
            return { view: Ability.SIGN_CONTRAT_VIEW, manage: Ability.SIGN_CONTRAT_MANAGE }
    }
}
