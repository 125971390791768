export enum TypeRemuneration {
    TYPE_REMUNERATION_NOMBRE = 1,
    TYPE_REMUNERATION_FORFAIT = 2,
    TYPE_REMUNERATION_VACATION = 3,
}

export function getTypeRemuneration(type: TypeRemuneration) {
    switch (type) {
        case TypeRemuneration.TYPE_REMUNERATION_NOMBRE:
            return 'NOMBRE'
        case TypeRemuneration.TYPE_REMUNERATION_FORFAIT:
            return 'FORFAIT'
        case TypeRemuneration.TYPE_REMUNERATION_VACATION:
            return 'VACATION'
    }
}

export function getRoundedValue(value: number, decimalNumber: number) {
    const roundFactor = Math.pow(10, decimalNumber)
    return (Math.round(value * roundFactor) / roundFactor).toFixed(decimalNumber)
}

export interface BaremeRemunerationInterface {
    id: number,
    nature: string,
    prestation: string,
    domaine: string,
    type: number,
    remuneration: number,
    frais_atelier: number
}

export interface StateBaremeRemuneration {
    baremesRemuneration: Array<BaremeRemunerationInterface> | [],
    baremeRemuneration_select: BaremeRemunerationInterface | null,
    meta: Array<string> | null,
    links: Array<string> | null,
    loading: boolean | false,
    error: Array<string> | null,
    lastPage: number | 0,
    totalRows: number | 0
}
