export enum FichePosteStep {
    SELECT_ETAB,
    CREATE_ETAB,
    SELECT_ETAB_USER,
    CREATE_ETAB_USER,
    CREATE_JOB
}

export enum StatusEtab {
    PUBLIC = 1,
    PRIVATE
}
