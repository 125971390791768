














































































import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { state_demission, type_demission } from '@/types/Demission'
import { formatDate } from '@/utils/helpers'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component({
    computed: {
        ...mapGetters('demission', ['error_demission']),
        ...mapGetters('auth', [
            'authUser',
            'can',
            'cannot',
            'isA',
            'isNotA'
        ])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay
    }
})

export default class PopUpValidationDemission extends Vue {
    @Prop() showPopup?: boolean
    @Prop() infosCandidat?: any
    @Prop() demissionOf?: any

    show                        = false
    ready                       = false
    candidatSelectionne         = []
    inputAcceptationDemission = state_demission.DEMISSION_DEMANDE
    state_demission = state_demission
    formatDate = formatDate
    demissionAcceptee = false
    modeSaisie = true // définit si on est en mode de saisie de la décision sur la démission, ou si on est en mode de demande de confirmation de la décision à valider

    @Watch('showPopup')
    forceModeSaisie () {
        // Force le mode saisie à la création du popup
        this.modeSaisie = true
    }

    @Watch('infosCandidat')
    setDatas () {
        this.inputAcceptationDemission = this.infosCandidat.etat
        this.demissionAcceptee = this.inputAcceptationDemission === state_demission.DEMISSION_TRAITE_OUI
    }

    @Watch('inputAcceptationDemission')
    reinitError() {
        this.$store.state.demission.error_demission = null
    }

    /** Récupère la date à laquelle la démission a été validée */
    getDateAcceptationDemission() {
        let date: any = ''
        if (this.demissionOf === 'concours') {
            date = this.infosCandidat.candidat.demission_at
        } else if (this.demissionOf === 'langueFacultative') {
            const eprMatch = this.infosCandidat.candidat.inscriptions.filter((epr: any) => parseInt(epr.epreuve_id) === this.infosCandidat.epreuve_fac.id)
            if (eprMatch.length > 0) {
                date = eprMatch[0].demission_at
            }
        }
        return formatDate(date)
    }

    /** Annule l'enregistrement de la décision */
    cancelEnregistrer() {
        this.$store.state.demission.error_demission = null
        this.$emit('close')
    }

    /** Annule la confirmation de la décision */
    cancelConfirmer() {
        this.modeSaisie = true
    }

    /**  */
    validerEnregistrer() {
        this.modeSaisie = false
    }

    confirmerEnregistrer() {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$store.state.demission.error_demission = null
        const payload = {
            demission: this.inputAcceptationDemission,
            epreuve_id: this.infosCandidat.type_demission === type_demission.EPREUVES_FAC ? this.infosCandidat.epreuve_fac.id : 0
        }

        this.$bvToast.toast('Enregistrement en cours...', infosToaster)
        this.$store.dispatch('demission/updateDemission', { type: this.infosCandidat.type_demission, candidat_id: this.infosCandidat.candidat.id, payload: payload })
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Votre décision a bien été enregistrée.', succesToaster)

                this.modeSaisie = false
                this.$emit('close')
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }
}

