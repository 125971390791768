


































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { isEmpty, isNull } from "lodash";
import { Ability } from "@/types/Ability";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { CandidatInterface } from "@/types/Candidat";

/**
 * Composant relatif au "compteCandidat" d'un candidat
 */
@Component({
    methods: {
        isNull,
        isEmpty
    },
    computed: {
        Ability() {
            return Ability
        }
    },
    components: {
        FontAwesomeIcon
    }
})
export default class PopupEditCandidatCompte extends Vue {
    can                         = this.$store.getters['auth/can']
    candidat: CandidatInterface = JSON.parse(JSON.stringify(this.$store.state.candidat.editedCandidat))
    edit                        = false
    loading                     = false

    /**
     * onEditChanged
     * Actions à entreprendre lorsque l'état d'édition change
     * @param {boolean} value - Valeur de l'état d'édition
     * @returns {void}
     */
    @Watch('edit')
    onEditChanged (value: boolean): void {
        this.$emit('changeEditing', value)
        if (value) {
            if(!this.can(Ability.CAND_MANAGE)) {
                this.edit = false
                return
            }
        } else {
            this.candidat = JSON.parse(JSON.stringify(this.$store.state.candidat.editedCandidat))
        }
    }

    /**
     * saveInfos
     * Sauvegarde les informations du candidat
     * @returns {void}
     */
    saveInfos(): void {
        if (this.edit && !this.loading && this.can(Ability.CAND_MANAGE) && !isEmpty(this.candidat)) {
            this.loading = true

            const idInfo = 't_info_' + Math.random()
            this.$bvToast.toast('Enregistrement en cours ...', {
                id: idInfo,
                toaster: 'b-toaster-top-right',
                variant: 'primary',
                noCloseButton: true,
                fade: true,
                noAutoHide: true
            })

            this.$store.dispatch('comptecandidat/updateCompteCandidats', {
                compte_candidat_id: this.candidat.compteCandidat.id,
                payload: {
                    code: this.candidat.compteCandidat.code,
                    name: this.candidat.compteCandidat.name,
                    first_name: this.candidat.compteCandidat.first_name,
                    email: this.candidat.compteCandidat.email
                }
            })
                .then((response) => {
                    this.candidat.compteCandidat = { ...response.data.data }
                    this.$store.commit('candidat/SET_CANDIDAT', this.candidat)
                    this.$store.commit('candidat/SET_EDITED_CANDIDAT', this.candidat.id)
                    this.$store.commit('candidat/SET_SELECTED_CANDIDAT', this.candidat.id)

                    this.$bvToast.toast('Enregistrement terminé.', {
                        id: 't_succes_' + Math.random(),
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    })

                    this.edit = false
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                    this.loading = false
                })
        }
    }
}
