











































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import PopupEditUser from '@/components/Administration/Users/PopupEditUser.vue'
import { UserInterface } from '@/types/User'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { Ability } from '@/types/Ability'
import { checkIcone } from '@/utils/helpers'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

@Component({
    components: {
        ExaGenericTable,
        PopupEditUser,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('user', ['loading', 'error', 'users', 'meta', 'links', 'totalRows', 'currentPage', 'lastPage', 'totalPage', 'sortby']),
        ...mapState('user', ['users', 'loading']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})

export default class Users extends Vue {
    Ability                = Ability
    user_select: any       = {}
    showAddUser            = false
    filtres: any           = []
    filtreJustInitiated    = false
    dataForTab: Array<any> = []

    // Ensemble des colonnes du tableau de epreuveCorrectionResultats
    genericfields = [
        { key: 'etatEdit'      , label: ''                , sortable: false, class: ''           , type: 'action' },
        { key: 'name'          , label: 'Nom'             , sortable: true , class: ''           , type: 'text'   },
        { key: 'first_name'    , label: 'Prénom'          , sortable: true , class: ''           , type: 'text'   },
        { key: 'email'         , label: 'Courriel'        , sortable: true , class: ''           , type: 'text'   },
        { key: 'email_verified', label: 'Courriel vérifié', sortable: true , class: 'text-center', type: 'text'   },
        { key: 'two_factor'    , label: 'OTP activé'      , sortable: true , class: 'text-center', type: 'text'   },
        { key: 'rgpd_accepted' , label: 'RGPD accepté'    , sortable: true , class: 'text-center', type: 'text'   }
    ]

    /**
     * @description Mise à jour du tableau des utilisateurs
     * @returns {void}
     */
    @Watch('users')
    setTable(): void {
        this.setDataForGenericTab(this.$store.state.user.users)
    }

    /**
     * @description Remplissage du tableau des utilisateurs
     * @param {any} poData - Données à afficher
     * @param {boolean} isLoadMore - Indique si on charge plus de données
     * @returns {void}
     */
    setDataForGenericTab(poData: any, isLoadMore = false): void {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const result of poData) {
                const can = this.$store.getters['auth/can'](Ability.ADM_UTIL_MANAGE)
                const icone = checkIcone(Ability.ADM_UTIL_MANAGE, can)

                let puce_courriel_verif = []
                if (result.email_verified) {
                    puce_courriel_verif = [{ name: 'circle', class: 'text-success', title: 'Courriel vérifié' }]
                } else {
                    puce_courriel_verif = [{ name: 'circle', class: 'text-danger', title: 'Courriel non vérifié' }]
                }

                let puce_two_factor = []
                if (result.two_factor) {
                    puce_two_factor = [{ name: 'circle', class: 'text-success', title: 'OTP activé' }]
                } else {
                    puce_two_factor = [{ name: 'circle', class: 'text-danger', title: 'OTP non activé' }]
                }

                let puce_rgpd = []
                if (result.rgpd_accepted) {
                    puce_rgpd = [{ name: 'circle', class: 'text-success', title: 'RGPD accepté' }]
                } else {
                    puce_rgpd = [{ name: 'circle', class: 'text-danger', title: 'RGPD non accepté' }]
                }

                const line = [
                    { label: icone.label, item: result,              type: 'action', typeAction: 'edit', class: 'commons_first_action_button', icon: icone.icon, disabled: false },
                    { label: '',          item: result.name,         type: 'text',   typeAction: null,   class: ''                                                               },
                    { label: '',          item: result.first_name,   type: 'text',   typeAction: null,   class: ''                                                               },
                    { label: '',          item: result.email,        type: 'text',   typeAction: null,   class: ''                                                               },
                    { label: '',          item: puce_courriel_verif, type: 'icons',  typeAction: null,   class: 'text-center'                                                    },
                    { label: '',          item: puce_two_factor,     type: 'icons',  typeAction: null,   class: 'text-center'                                                    },
                    { label: '',          item: puce_rgpd,           type: 'icons',  typeAction: null,   class: 'text-center'                                                    }
                ]
                this.dataForTab.push(line)
            }
        }
    }

    /**
     * @description Initialisation des filtres du tableau
     * @returns {void}
     */
    setFiltersForGenericTab(): void {
        this.filtres = [
            { libelle: 'Nom',      defautOptionlibelle: 'Rechercher un', model: 'name',  value: '', index: 'name',  datas: null, loading: this.$store.getters['user/loading'], options: { type: 'form', fieldsKey: 'name'  } },
            { libelle: 'Courriel', defautOptionlibelle: 'Rechercher un', model: 'email', value: '', index: 'email', datas: null, loading: this.$store.getters['user/loading'], options: { type: 'form', fieldsKey: 'email' } }
        ]
    }

    /**
     * @description Gestion des événements du tableau
     * @param {any} paParams - Paramètres de l'événement
     * @returns {Promise<void>}
     */
    async handleTableEvent(paParams: any): Promise<void> {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'edit':
                    this.user_select = paParams[1]
                    await this.$store.dispatch('user/getUser', { user_id: paParams[1].id })
                    this.showAddUser = true
                    break

                case 'sortHandler':
                case 'filterHandler':
                    await this.filtreSortHandler(paParams[1])
                    break

                case 'onLoadPage':
                    await this.loadHandler(paParams[1])
                    break
            }
        }
    }

    /**
     * @description Ouvre le popup de création d'un utilisateur
     * @returns {void}
     */
    openAddUser(): void {
        this.showAddUser = true
    }

    /**
     * @description Ferme la popup d'édition d'un utilisateur
     * @param {UserInterface} response - Réponse de la popup
     * @returns {void}
     */
    reinitShowModal(response: UserInterface): void {
        this.showAddUser = !this.showAddUser
        if (response) {
            if (response.id === this.user_select.id) {
                this.$store.commit('user/SET_USER_DATA', response)
            }
        }
    }

    /**
     * @description Accède à l'interface de gestion des rôles
     * @returns {void}
     */
    gestionRoles(): void {
        this.$router.push('/roles')
    }

    /**
     * @description Applique des filtres
     * @param {any} params - Paramètres de filtre
     * @returns {Promise<void>}
     */
    async filtreSortHandler(params: any): Promise<void> {
        if (this.filtreJustInitiated) {
            this.filtreJustInitiated = false
        } else {
            await this.$store.dispatch('user/getUsers', params)
        }
    }

    /**
     * @description Complément des données sur un scroll
     * @param {any} params - Paramètres de chargement
     * @returns {Promise<void>}
     */
    async loadHandler(params: any): Promise<void> {
        await this.$store.dispatch('user/getMoreUsers', params)
    }

    /**
     * @description Avant le montage du composant
     * @returns {Promise<void>}
     */
    async beforeMount(): Promise<void> {
        await this.$store.dispatch('user/getRoles')
        await this.$store.dispatch('user/getUsers', { page: 1, sort: 'name', direction: 'asc' })
        this.setFiltersForGenericTab()
    }
}
