






















































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import store from '@/store/index'
import { getTypeRemuneration, TypeRemuneration } from '@/types/BaremeRemuneration'
import { EntityType } from '@/types/Poste'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import Back from '@/components/Tools/Back.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    components: {
        Back,
        ErrorDisplay,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('baremeremuneration', ['baremeRemuneration_select', 'loading', 'error']),
        ...mapGetters('matiere', ['matieres']),
        ...mapGetters('banque', ['banques']),
        ...mapGetters('poste', ['postes']),
        ...mapGetters('epreuve', ['epreuves']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    },
    methods: {
        ...mapActions('baremeremuneration', ['getBaremeRemuneration', 'addBaremeRemuneration', 'updateBaremeRemuneration']),
        ...mapMutations('baremeremuneration', ['SET_BAREME_SELECT']),
        getStringConcours (concours) {
            return concours?.map((concour: any) => concour.name)
                ?.join(', ') || ''
        }
    },
    beforeRouteEnter (to: any, from: any, next: any) {
        store.commit('baremeremuneration/SET_BAREME_SELECT', null)
        // Chargement des matieres, concours, postes
        if (store.getters['matiere/matieres'].length === 0) {
            store.dispatch('matiere/getMatieres')
        }
        if (store.getters['banque/banques'].length === 0) {
            store.dispatch('banque/getBanquesActives')
        }
        // if (store.getters['poste/postes'].length === 0) {
        store.dispatch('poste/getAllPostes')
        if (to.params.bareme_id !== '0') {
            store.dispatch('baremeremuneration/getBaremeRemuneration', { bareme_id: to.params.bareme_id })
            next()
        } else {
            next()
        }
    }
})

export default class BaremesRemunerations extends Vue
{

    Ability = Ability
    modePreview = false
    nature = ''
    prestation = ''
    domaine = ''
    type = 0
    remuneration = ''
    frais_atelier = ''
    equivalent_horaire = ''
    message_error = ''
    errorAssocs = ''
    showMatiere = true

    poste_select = {
        id: 0,
        name: '',
        entity_type: '',
        type_passation: 0,
        options: null
    }

    matiere_select = {
        id: 0,
        name: ''
    }

    filiere_select = {
        id: 0,
        name: ''
    }

    epreuve_select = {
        id: 0,
        name: '',
        type_passation: 0,
        epreuve_correction_id: 0
    }

    associations: Array<any> = []
    option_select: Array<any> = []
    liste_associations: Array<any> = []
    typeRemuneration = TypeRemuneration
    getTypeRemuneration = getTypeRemuneration

    @Watch('baremeRemuneration_select')
    setContratSelect () {
        this.$store.state.baremeremuneration.error = null
        if (this.$store.state.baremeremuneration.baremeRemuneration_select) {
            this.nature = this.$store.state.baremeremuneration.baremeRemuneration_select.nature
            this.domaine = this.$store.state.baremeremuneration.baremeRemuneration_select.domaine
            this.prestation = this.$store.state.baremeremuneration.baremeRemuneration_select.prestation
            this.remuneration = this.$store.state.baremeremuneration.baremeRemuneration_select.remuneration
            this.frais_atelier = this.$store.state.baremeremuneration.baremeRemuneration_select.frais_atelier
            this.type = this.$store.state.baremeremuneration.baremeRemuneration_select.type
            this.equivalent_horaire = this.$store.state.baremeremuneration.baremeRemuneration_select.equivalent_horaire

            if (this.$store.state.baremeremuneration.baremeRemuneration_select.associations.length !== 0) {
                // Construit le tableau des associations
                const params: any = []
                this.$store.dispatch('epreuve/getEpreuves', { isPrecedente: false, filters: params }).then(() => {
                    if (this.associations.length === 0 && this.$store.state.baremeremuneration.baremeRemuneration_select) {
                        this.associations = this.$store.state.baremeremuneration.baremeRemuneration_select.associations
                        this.buildAssociations()
                    }
                })
            }
        }
    }


    @Watch('filiere_select')
    setEpreuves () {
        this.getEpreuves()
    }

    @Watch('matiere_select')
    setEpreuvesByMatiere () {
        this.getEpreuves()
    }

    @Watch('poste_select')
    setPosteCentre () {
        this.$store.state.baremeremuneration.error = null
        if (this.poste_select.entity_type === EntityType.ET_CENTRE) {
            this.showMatiere = false
        } else {
            this.showMatiere = true
        }
    }

    // Supprimer une association
    deleteAssociation (index: any) {
        this.associations.splice(index, 1)
        this.liste_associations.splice(index, 1)
    }

    // Save or update le contrat
    saveBaremeRemuneration () {
        this.$store.state.baremeremuneration.error = null
        const datas = {
            nature: this.nature,
            prestation: this.prestation,
            domaine: this.domaine,
            type: this.type,
            remuneration: this.remuneration,
            frais_atelier: this.frais_atelier,
            equivalent_horaire: this.equivalent_horaire,
            associations: JSON.stringify(this.associations)
        }

        if (this.$store.state.baremeremuneration.baremeRemuneration_select) {
            // update
            this.$store.dispatch('baremeremuneration/updateBaremeRemuneration', { bareme_id: this.$store.state.baremeremuneration.baremeRemuneration_select.id, payload: datas })
                .then(() => {
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                })
                .catch(() => {
                    console.log('error')
                })
        } else {
            // Création d'un bareme de remuneration
            this.$store.dispatch('baremeremuneration/saveBaremeRemuneration', datas)
                .then(() => {
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                    this.$router.push('/baremes_remunerations/' + this.$store.state.baremeremuneration.baremeRemuneration_select.id)
                })
                .catch(() => {
                    console.log('error')
                })
        }
    }

    // Ajoute une association
    addAssociation () {
        this.errorAssocs = ''
        if (this.poste_select.entity_type !== EntityType.ET_CENTRE) {
            // On check qu'on a au moins un poste et une matiere selectionnée
            if ((this.poste_select.id !== 0 && this.matiere_select && this.matiere_select.id !== 0) || (this.poste_select.id !== 0 && this.matiere_select == null)) {
                // On envoi qu'un seul entity type
                if (this.epreuve_select.id !== 0) {
                    // Entity type : EpreuveCorrection
                    this.associations.push({ poste_id: this.poste_select.id, options: this.option_select, entity_type: EntityType.ET_EPREUVE_CORRECTION, entity_id: this.epreuve_select.epreuve_correction_id })
                } else if ((this.matiere_select && this.matiere_select.id !== 0) || (this.matiere_select === null)) {
                    // Entity type : Matiere
                    this.associations.push({ poste_id: this.poste_select.id, options: this.option_select, entity_type: EntityType.ET_MATIERE, entity_id: (this.matiere_select ? this.matiere_select.id : null) })
                }

                this.liste_associations.push({ poste: this.poste_select, option: this.option_select, matiere: this.matiere_select, filiere: this.filiere_select, epreuve: this.epreuve_select })
                this.resetSelection()
            } else {
                this.errorAssocs = 'Au moins un poste et une matière doivent être sélectionnés.'
            }
        } else {
            this.liste_associations.push({ poste: this.poste_select, option: this.option_select, matiere: null, filiere: null, epreuve: null })
            this.associations.push({ poste_id: this.poste_select.id, options: this.option_select, entity_type: null, entity_id: null })
            this.resetSelection()
        }
    }

    // Reinit la selection
    resetSelection () {
        this.poste_select = {
            id: 0,
            name: '',
            entity_type: '',
            type_passation: 0,
            options: null
        }

        this.matiere_select = {
            id: 0,
            name: ''
        }

        this.filiere_select = {
            id: 0,
            name: ''
        }

        this.epreuve_select = {
            id: 0,
            name: '',
            type_passation: 0,
            epreuve_correction_id: 0
        }

        this.option_select = []
        this.errorAssocs = ''
    }

    // Recupere les epreuves selon le concours et la matiere choisi
    getEpreuves () {
        if (this.filiere_select && this.filiere_select.id !== 0 && this.matiere_select && this.matiere_select.id !== 0) {
            const params: any = {}
            params['filter-concour_id'] = this.filiere_select.id
            params['filter-matiere_id'] = this.matiere_select.id
            this.$store.dispatch('epreuve/getEpreuves', { isPrecedente: false, filters: params }).then(() => {
                if (this.associations.length === 0 && this.$store.state.baremeremuneration.baremeRemuneration_select) {
                    this.associations = this.$store.state.baremeremuneration.baremeRemuneration_select.associations
                    this.buildAssociations()
                }
            })
        }
    }

    // build associations
    buildAssociations () {
        this.liste_associations = []
        const liste_epreuves = store.getters['epreuve/epreuves']
        if (liste_epreuves.length !== 0 && this.associations.length !== 0) {
            for (const a in this.associations) {
                let matiere: any = null
                let filiere: any = null
                let epreuve: any = null
                const option = this.associations[a].options
                const poste = this.$store.state.poste.postes.find((p: any) => p.id === this.associations[a].poste_id)

                if (this.associations[a].entity_type === EntityType.ET_MATIERE) {
                    if (!this.associations[a].entity_id) {
                        matiere = null
                    } else {
                        matiere = this.$store.state.matiere.matieres.find((m: any) => m.id === this.associations[a].entity_id)
                    }
                } else if (this.associations[a].entity_type === EntityType.ET_EPREUVE_CORRECTION) {
                    if (this.associations[a].entity_id !== null) {
                        epreuve = liste_epreuves.find((ep: any) => ep.epreuve_correction_id === this.associations[a].entity_id)
                        matiere = this.$store.state.matiere.matieres.find((m: any) => m.id === epreuve.matiere_id)
                        filiere = this.$store.state.banque.banques.find((c: any) => c.id === epreuve.concour_id)
                    }
                }

                this.liste_associations.push({ poste: poste, option: option, matiere: matiere, filiere: filiere, epreuve: epreuve })
            }
        }
    }

    mounted () {
        this.load()
    }

    load() {
        this.$store.state.baremeremuneration.error = null
    }
}
