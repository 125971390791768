

































import { Vue, Component, Watch } from 'vue-property-decorator'
import { FontAwesomeIcon }      from '@fortawesome/vue-fontawesome'
import { mapGetters, mapState } from 'vuex'
import ImpressionsCandidat  from '@/views/Fournitures/ImpressionsCandidat.vue'
import ImpressionsCentre  from '@/views/Fournitures/ImpressionsCentre.vue'
import ImpressionsExaminateur  from '@/views/Fournitures/ImpressionsExaminateur.vue'


@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('serie', ['serieById', 'series'])

    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ImpressionsExaminateur,
        ImpressionsCentre,
        ImpressionsCandidat
    }
})
export default class Fournitures extends Vue
{
    ongletSelect = 'examinateur'

    serieActive = true
    allSeries: any = ''

    @Watch('series')
    watchSerie() {
        this.allSeries = this.$store.getters['serie/series']
        this.activeSeries(this.allSeries)
    }

    /**  si aucune série n'est valide, passe serieActive à false pour afficher un message d'alerte  */
    activeSeries(series: any) {
        const findActiveSerie = series.findIndex((serie: { valide: number }) => serie.valide === 1)
        this.serieActive = findActiveSerie !== -1
    }

    /** contrôle que sessionSelect n'est pas null et charge les series  */
    load () {
        const sessionUserSelect = this.$store.state.auth.user_session_id
        if (this.$store.getters['serie/series'].length === 0) {
            this.$store.dispatch('serie/getSeries', { session_id: sessionUserSelect }).then(() => {
                this.allSeries = this.$store.getters['serie/series']

                this.activeSeries(this.allSeries)
            })
        } else {
            this.allSeries = this.$store.getters['serie/series']

            this.activeSeries(this.allSeries)
        }
    }

    updateSeriesAfterUserChangeSession() {
        const sessionUserSelect = this.$store.state.auth.user_session_id

        this.$store.dispatch('serie/getSeries', { session_id: sessionUserSelect }).then(() => {
            this.allSeries = this.$store.getters['serie/series']

            this.activeSeries(this.allSeries)
        })
    }

    mounted() {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}

