




























import { mapGetters, mapState } from 'vuex'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { isObject } from '@/utils/helpers'
import ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    computed: {
        ...mapState('centre', ['centre_users_select', 'error', 'centreSelect']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay
    }
})

export default class PopupSubmitIntervenants extends Vue {
    @Prop() showPopup?: boolean
    @Prop() invalide?: boolean
    @Prop() submit?: number
    @Prop() validate?: number
    @Prop() fichePublished?: boolean

    errorComm = ''

    show = false
    message_rejet = ''
    messageErreurSimple = ''

    titre = ''
    message = ''
    commentaire_rejet  = ''

    @Watch('showPopup')
    initPopup () {
        this.errorComm = ''
        this.commentaire_rejet = ''
        if (this.validate === 1) {
            this.titre = 'Valider la liste des intervenants'
            this.message = 'La liste des intervenants va être validée. Par cette action, vous allez créer un dossier administratif pour chaque intervenant qui sera transmis au service administratif pour publication.'
        } else if (this.validate === 0) {
            this.titre = 'Invalider la liste des intervenants'
            this.message = 'La liste des intervenants va être invalidée.'
        } else if (this.submit  === 1) {
            this.titre = 'Soumettre la liste des intervenants'
            this.message = 'La liste des intervenants va être soumise au service opérationnel du concours.'
        }  else if (this.submit  === 0) {
            this.titre = 'Ne pas accepter la liste des intervenants'
            this.message = 'Veuillez saisir un commentaire explicatif pour indiquer au chef de centre les corrections à apporter à la saisie.'
        }
        this.show = this.$props.showPopup
    }

    /** Check le type du retour d'erreur */
    isObject () {
        return isObject(this.$store.state.centre.error)
    }

    /** Annulation de la confirmation de la soumission des informations adresses et message */
    cancelSoumettre () {
        this.show = false
        this.$emit('reset')
    }

    /** Confirmation de la soumission des informations adresses et message */
    confirmerSoumettre () {
        if (this.submit === -1 && this.validate === -1) {
            return
        }
        if (this.submit === 0 && this.commentaire_rejet.trim() === '') {
            this.errorComm = 'Un commentaire est obligatoire.'
            return
        }
        let payload: {}
        if (this.submit === 0) {
            payload = {
                id: this.$store.state.centre.centreSelect.id,
                intervenants_submit: 0,
                commentaire_rejet: (this.commentaire_rejet.trim() !== '' ? this.commentaire_rejet : null)
            }
        } else if (this.submit === 1) {
            payload = {
                id: this.$store.state.centre.centreSelect.id,
                intervenants_submit: 1
            }
        } else {
            payload = {
                id: this.$store.state.centre.centreSelect.id,
                intervenants_validate: this.validate !== -1 ?  this.validate : null
            }
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('centre/updateCentre', payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Modifications enregistrées.', succesToaster)
                this.show = false
                this.$emit('reset')
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }
}
