

























































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import store from '@/store/index'
import { TypeAdresseCentre, getTypeAdresseCentreSpec } from '@/types/AdresseCentre'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'

@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapState('adresseCentre', ['error']),
        ...mapGetters('auth', ['is_intervenant_centre'])
    }
})

export default class AdresseCentre extends Vue {
    @Prop() centre_select: any
    @Prop() adresse?: any
    @Prop() adresses?: any
    @Prop() lockInterface?: boolean

    adresseCentreType = TypeAdresseCentre
    Ability = Ability
    getterTypeAdresseCentreSpec = getTypeAdresseCentreSpec

    adresseTemp: any = null
    adressesTemp: Array<any> = []

    modeEdition = false

    showModalMessageDelete = false

    deleteEnCours = false
    modifEnCours =false

    errorType = false
    errorAdresse = false
    showError = false

    addDisabled = false
    // lockInterface = false

    beforeMount () {
        if (this.adresse && this.adresse.id && this.adresse.id !== 0) {
            this.adresseTemp = {
                id: this.adresse.id,
                type: this.adresse.type,
                adresse: this.adresse.adresse

            }
        } else {
            this.adresseTemp = {
                id: 0,
                type: [0],
                adresse: ''
            }
        }
        this.adressesTemp = []
        if (this.adresses) {
            for (let i = 0; i < this.adresses.length; i++) {
                this.adressesTemp.push(this.adresses[i])
            }
        }
    }

    checkTypeChangeHandler () {
        this.adresseTemp.type = 0
        if ((this.$refs.TYPE_LIVRAISON_SUJET as HTMLInputElement).checked) {
            this.adresseTemp.type += this.adresseCentreType.TYPE_LIVRAISON_SUJET
        }
        if ((this.$refs.TYPE_CONVOCATION_CANDIDAT as HTMLInputElement).checked) {
            this.adresseTemp.type += this.adresseCentreType.TYPE_CONVOCATION_CANDIDAT
        }
        if ((this.$refs.TYPE_LIVRAISION_DOCUMENT as HTMLInputElement).checked) {
            this.adresseTemp.type += this.adresseCentreType.TYPE_LIVRAISION_DOCUMENT
        }
        if ((this.$refs.TYPE_ENLEVEMENT_COPIE as HTMLInputElement).checked) {
            this.adresseTemp.type += this.adresseCentreType.TYPE_ENLEVEMENT_COPIE
        }
    }

    checkAddEditIsDisabled () {
        if (!this.$store.getters['auth/can'](Ability.GC_CENTER_MANAGE) && !this.$store.getters['auth/can'](Ability.GCI_INFOS_OWN_MANAGE, 'App\\Models\\Centre', this.centre_select)) {
            return true
        }
        if ((this.adresseTemp.type > 0 || (this.adresses && this.adresses.length === 0) || !this.adresses) && this.adresseTemp.adresse !== '') {
            return false
        }
        return true
    }

    checkTypedisabled (type: number) {
        if (this.adresses) {
            for (let i = 0; i < this.adresses.length; i++) {
                if (this.adresses[i].type & type && (this.adresseTemp.id !== this.adresses[i].id)) {
                    return true
                }
            }
        }
        return false
    }

    editAdresse () {
        this.modeEdition = true
    }

    annulerEdition () {
        if (this.adresse && this.adresse.id && this.adresse.id !== 0) {
            this.adresseTemp = {
                id: this.adresse.id,
                type: this.adresse.type,
                adresse: this.adresse.adresse

            }
        } else {
            this.adresseTemp = {
                id: 0,
                type: [0],
                adresse: ''
            }
        }
        this.adressesTemp = []
        if (this.adresses) {
            for (let i = 0; i < this.adresses.length; i++) {
                this.adressesTemp.push(this.adresses[i])
            }
        }
        this.modeEdition = false
    }

    deleteAdresse () {
        this.showModalMessageDelete = true
    }

    cancelDelete () {
        this.showModalMessageDelete = false
    }

    getTypeString (type: number) {
        let retour = ''
        if (type & this.adresseCentreType.TYPE_PRINCIPALE) {
            if (retour.length > 0) {
                retour += '<br>'
            }
            retour += this.getterTypeAdresseCentreSpec(this.adresseCentreType.TYPE_PRINCIPALE).libelle
        }
        if (type & this.adresseCentreType.TYPE_LIVRAISON_SUJET) {
            /* if (retour.length > 0) {
        retour += '<br>'
      } */
            // retour += this.getterTypeAdresseCentreSpec(this.adresseCentreType.TYPE_LIVRAISON_SUJET).libelle
            retour += '<span class="me-3" title="' + this.getterTypeAdresseCentreSpec(this.adresseCentreType.TYPE_LIVRAISON_SUJET).libelle + '">LS</span>'
        }
        if (type & this.adresseCentreType.TYPE_CONVOCATION_CANDIDAT) {
            /* if (retour.length > 0) {
        retour += '<br>'
      }
      retour += this.getterTypeAdresseCentreSpec(this.adresseCentreType.TYPE_CONVOCATION_CANDIDAT).libelle */
            retour += '<span class="me-3" title="' + this.getterTypeAdresseCentreSpec(this.adresseCentreType.TYPE_CONVOCATION_CANDIDAT).libelle + '">CC</span>'
        }
        if (type & this.adresseCentreType.TYPE_LIVRAISION_DOCUMENT) {
            /* if (retour.length > 0) {
        retour += '<br>'
      }
      retour += this.getterTypeAdresseCentreSpec(this.adresseCentreType.TYPE_LIVRAISION_DOCUMENT).libelle */
            retour += '<span class="me-3" title="' + this.getterTypeAdresseCentreSpec(this.adresseCentreType.TYPE_LIVRAISION_DOCUMENT).libelle + '">LD</span>'
        }
        if (type & this.adresseCentreType.TYPE_ENLEVEMENT_COPIE) {
            /* if (retour.length > 0) {
        retour += '<br>'
      }
      retour += this.getterTypeAdresseCentreSpec(this.adresseCentreType.TYPE_ENLEVEMENT_COPIE).libelle */
            retour += '<span class="me-3" title="' + this.getterTypeAdresseCentreSpec(this.adresseCentreType.TYPE_ENLEVEMENT_COPIE).libelle + '">EC</span>'
        }
        return retour
    }

    getTypeStringFull (type: number) {
        let retour = ''
        if (type & this.adresseCentreType.TYPE_PRINCIPALE) {
            if (retour.length > 0) {
                retour += '<br>'
            }
            retour += this.getterTypeAdresseCentreSpec(this.adresseCentreType.TYPE_PRINCIPALE).libelle
        }
        if (type & this.adresseCentreType.TYPE_LIVRAISON_SUJET) {
            if (retour.length > 0) {
                retour += '<br>'
            }
            retour += this.getterTypeAdresseCentreSpec(this.adresseCentreType.TYPE_LIVRAISON_SUJET).libelle
        }
        if (type & this.adresseCentreType.TYPE_CONVOCATION_CANDIDAT) {
            if (retour.length > 0) {
                retour += '<br>'
            }
            retour += this.getterTypeAdresseCentreSpec(this.adresseCentreType.TYPE_CONVOCATION_CANDIDAT).libelle
        }
        if (type & this.adresseCentreType.TYPE_LIVRAISION_DOCUMENT) {
            if (retour.length > 0) {
                retour += '<br>'
            }
            retour += this.getterTypeAdresseCentreSpec(this.adresseCentreType.TYPE_LIVRAISION_DOCUMENT).libelle
        }
        if (type & this.adresseCentreType.TYPE_ENLEVEMENT_COPIE) {
            if (retour.length > 0) {
                retour += '<br>'
            }
            retour += this.getterTypeAdresseCentreSpec(this.adresseCentreType.TYPE_ENLEVEMENT_COPIE).libelle
        }
        return retour
    }

    deleteSuite () {
        this.deleteEnCours = true
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        const payload = {
            id: this.adresseTemp.id,
            type: this.adresseTemp.type,
            adresse: this.adresseTemp.adresse,
            centre_id: this.centre_select.id
        }
        this.$bvToast.toast('Supression en cours ...', infosToaster)
        this.$store.dispatch('adresseCentre/deleteAdresseCentre', payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }

                store.dispatch('centre/getCentre', { centre_id: this.centre_select.id }).then((retour) => {
                    this.adressesTemp = []
                    for (let i = 0; i < retour.data.data.adresses.length; i++) {
                        this.adressesTemp.push(retour.data.data.adresses[i])
                    }

                    this.showModalMessageDelete = false
                    this.deleteEnCours = false
                    this.$bvToast.toast('Suppression terminée.', succesToaster)
                })
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    editSuite () {
        this.errorAdresse = false
        this.errorType = false
        this.modifEnCours = true

        if ((this.adresses && this.adresses.length === 0) || !this.adresses) {
            this.adresseTemp.type = 1
        }

        if (this.adresseTemp.type === 0 && this.adresses && this.adresses.length > 0) {
            this.errorType = true
        }
        if (this.adresseTemp.adresse === '') {
            this.errorAdresse = true
        }
        if (this.errorType || this.errorAdresse) {
            return
        }

        const payload = {
            id: this.adresseTemp.id,
            type: this.adresseTemp.type,
            adresse: this.adresseTemp.adresse,
            centre_id: this.centre_select.id
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        if (this.adresseTemp.id === 0) {
            this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
            this.$store.dispatch('adresseCentre/addAdresseCentre', payload)
                .then(() => {
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }

                    store.dispatch('centre/getCentre', { centre_id: this.centre_select.id })
                        .then((retour) => {
                            this.adressesTemp = []
                            for (let i = 0; i < retour.data.data.adresses.length; i++) {
                                this.adressesTemp.push(retour.data.data.adresses[i])
                            }
                            this.$bvToast.toast('Modification terminée.', succesToaster)
                            this.modifEnCours = false
                            this.adresseTemp.adresse = ''
                            this.adresseTemp.type = 0
                        })
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
            this.$store.dispatch('adresseCentre/updateAdresseCentre', payload)
                .then(() => {
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }

                    store.dispatch('centre/getCentre', { centre_id: this.centre_select.id })
                        .then((retour) => {
                            this.adressesTemp = []
                            for (let i = 0; i < retour.data.data.adresses.length; i++) {
                                this.adressesTemp.push(retour.data.data.adresses[i])
                            }
                            this.$bvToast.toast('Modification terminée.', succesToaster)
                            this.modifEnCours = false
                            this.modeEdition = false
                        })
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }
}

