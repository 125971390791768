



































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Ability } from '@/types/Ability'
import { mapGetters } from 'vuex'
import Concours from '@/views/Administration/Sessions/Concours.vue'
import Banques from '@/views/Administration/Sessions/Banques.vue'
import Epreuves from '@/views/Administration/Sessions/Epreuves.vue'
import CalendrierOraux from '@/views/Administration/Sessions/CalendrierOraux.vue'
import CalendrierEcrit from '@/views/Administration/Sessions/CalendrierEcrit.vue'
import Back from '@/components/Tools/Back.vue'


@Component({
    components: {
        Concours,
        Banques,
        Epreuves,
        CalendrierOraux,
        CalendrierEcrit,
        Back
    },
    computed: {
        ...mapGetters('session', ['sessionSelect', 'loading'])
    }
})
export default class DetailsSession extends Vue {
    @Prop() session_id?: number;
    Ability = Ability
    tabSelected = 'Concours'

    clickEditConcour (item: any) {
        this.$router.push('/editconcour/' + item.session_id + '/' + item.concour_id)
    }

    beforeMount () {
        // if (!this.$store.getters['session/sessionSelect']) {
        this.$store.dispatch('session/getSession', { session_id: this.$props.session_id })
        if (this.$store.state.matiere.totalRows === 0 && !this.$store.state.matiere.loading) {
            this.$store.dispatch('matiere/getMatieres')
        }
        this.$store.dispatch('serie/getSeries', { session_id: this.session_id })
    }
}
