






















































































































































































import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VuePdfApp from 'vue-pdf-app'
import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import ActivityLog from '../../components/ActivityLog.vue'
import PopupEditUser from '../../components/Administration/Users/PopupEditUser.vue'
import PopupAddFichePoste from '../../components/BourseEmploi/Popup/PopupAddFichePoste.vue'
import PopupHistorique from '../../components/BourseEmploi/Popup/PopupHistorique.vue'
import PopupPeriodeVisibilite from '../../components/BourseEmploi/Popup/PopupPeriodeVisibilite.vue'
import PopupEditCandidat from '../../components/Candidat/PopupEditCandidat.vue'
import ErrorDisplay from '../../components/ErrorDisplay.vue'
import { Ability } from '../../types/Ability'
import { getJobDescriptionStatus, JobDescriptionInterface, JobDescriptionStatus } from '../../types/JobDescription'
import FichePoste from '../../components/BourseEmploi/FichePoste.vue';
import {
    base64ToArrayBuffer,
    dateisSameOrBefore,
    formatDate,
    formatDateVariante,
    getFileNameFromHeader,
    formatStringServeurToDate,
    dateIsBetween
} from '../../utils/helpers';
import { WorkingEstablishmentInterface } from '@/types/WorkingEstablishment'


@Component({
    name: 'PostesView',
    methods: {
        getJobDescriptionStatus
    },
    components: {
        ExaGenericTable,
        PopupEditUser,
        FontAwesomeIcon,
        ActivityLog,
        VuePdfApp,
        ErrorDisplay,
        PopupEditCandidat,
        PopupPeriodeVisibilite,
        PopupHistorique,
        PopupAddFichePoste,
        FichePoste
    },
    props: {
        establishment: {
            type: Object as () => WorkingEstablishmentInterface,
            required: false,
            default: null
        }
    },
    computed: {
        ...mapGetters('jobDescription', ['jobDescriptions', 'loading', 'totalRows', 'lastPage', 'totalPage', 'error', 'meta']),
        ...mapGetters('auth', ['can']),
        ...mapGetters('session', ['sessionSelect']),
        canAddJob(): boolean {
            const can = this.$store.getters['auth/can']
            if (can(Ability.ADM_ESTABLISHMENT_MANAGE)) {
                return true
            }

            const workingEstablishmentsIds: number[] = this.$store.getters['user/reportingUserSelect']?.working_establishments?.map((item: any) => item.id) || []
            if (can(Ability.EST_ESTABLISHMENT_PARENT_OWN_MANAGE) || (can(Ability.EST_ESTABLISHMENT_OWN_MANAGE) && workingEstablishmentsIds.includes(this.$props.establishment?.id) && can(Ability.EST_JOB_OWN_MANAGE))) {
                const isInPeriod = (): boolean => {
                    const currentSession = this.$store.getters['session/sessionSelect']
                    if (!currentSession || currentSession.job_descriptions_start_at === null || currentSession.job_descriptions_end_at === null) {
                        return false
                    }

                    const startAt = formatStringServeurToDate(currentSession.job_descriptions_start_at)
                    const endAt = formatStringServeurToDate(currentSession.job_descriptions_end_at)
                    return dateIsBetween(new Date(), startAt, endAt, '[]')
                }

                if (isInPeriod()) {
                    if (!!this.$route.params.working_establishment_id && this.$route.path === `/bourse_emploi/etablissements/${this.$route.params.working_establishment_id}` && this.$props.establishment) {
                        return this.$props.establishment?.workingEstablishmentType?.job_description_creation_possible
                    } else if (can(Ability.EST_INTERV_OWN_MANAGE)) {
                        return true
                    }
                }
            }

            return false
        }
    },
    watch: {
        jobDescriptions: {
            handler: 'onJobDescriptionsChange'
        }
    }
})

export default class Postes extends Vue {
    Ability = Ability
    JobDescriptionStatus = JobDescriptionStatus
    filtres = [] as any
    filtreJustInitiated = false
    dataForTab = [] as Array<any>
    genericfields = [] as Array<any>
    showUpdateJobModal = false
    showRemoveJobModal = false
    showEditJobStatus = false
    showCandidatModal = false
    showJobDocument = false
    jobToRemove = null as any
    jobToUpdate = null as any
    jobLoading = false
    jobStatus = null as any
    jobCurrentStatus = null as any
    sessionEnCours = null as any
    readOnly = false
    formatDateVariante = formatDateVariante
    formatDate = formatDate
    errorKeeper = null
    lastSave = null as any
    formValues = null as any
    formCheckValidation = false as boolean
    formValidation = false as boolean

    documentToShow = {
        name: null,
        content: null
    } as any

    params = {
        page: 1,
        sort: 'name',
        direction: 'asc'
    } as any

    config = {
        toolbar: {
            toolbarViewerRight: {
                presentationMode: false,
                openFile: false,
                viewBookmark: false,
                secondaryToolbarToggle: false
            }
        }
    }

    /**
     * @description Vérifie si l'utilisateur peut éditer
     * @param {JobDescriptionInterface} job - Fiche de poste
     * @returns {boolean}
     */
    canEdit(job?: JobDescriptionInterface, status = false): boolean {
        const can = this.$store.getters['auth/can']
        if (can(Ability.ADM_ESTABLISHMENT_MANAGE)) {
            return true
        }

        const workingEstablishmentsIds = this.$store.getters['user/reportingUserSelect']?.working_establishments?.map((item: any) => item.id)
        if (can(Ability.EST_ESTABLISHMENT_PARENT_OWN_MANAGE) || (can(Ability.EST_ESTABLISHMENT_OWN_MANAGE) && workingEstablishmentsIds?.includes(this.$props.establishment?.id) && can(Ability.EST_JOB_OWN_MANAGE))) {
            if (!status) {
                if (job && !this.isDraft(job)) {
                    return false
                }
            }
            const session = this.$store.getters['session/sessions']
                .find((session: any) => session.id === this.$store.getters['auth/user_session_id'])
            const startAt = session.job_descriptions_start_at
            const endAt = session.job_descriptions_end_at

            if (startAt && endAt) {
                const start = new Date(startAt)
                const end = new Date(endAt)
                const now = new Date()

                if (now >= start && now <= end) {
                    return true
                }
            }
        }
        return false
    }

    /**
     * @description Vérifie si la fiche de poste est en brouillon
     * @param {any} job - Fiche de poste
     * @returns {boolean}
     */
    isDraft(job: any): boolean {
        return job?.status === JobDescriptionStatus.STATUS_DRAFT
    }

    /**
     * @description Mise à jour du tableau des fiches de poste
     * @returns {void}
     */
    onJobDescriptionsChange(): void {
        this.setDataForGenericTab(this.$store.getters['jobDescription/jobDescriptions'])
    }

    /**
     * @description Ouvre la modale de consultation des informations du candidat
     * @param {any} candidat - Candidat à consulter
     * @returns {void}
     */
    openCandidatModal(candidat: any): void {
        this.$store.commit('candidatJobDescription/SET_LOADING', true)
        this.$store.dispatch('candidat/getCandidat', { id: candidat.id })
            .then(() => {
                this.$store.commit('candidat/SET_SELECTED_CANDIDAT', candidat.id)
                this.showCandidatModal = true
            })
            .finally(() => {
                this.$store.commit('candidatJobDescription/SET_LOADING', false)
            })
    }

    /**
     * @description Fermeture de la modale de consultation des informations du candidat
     * @returns {void}
     */
    closeCandidatModal() {
        this.showCandidatModal = false
    }

    /**
     * @description Ouverture de la modale d'ajout de fiche de poste
     * @param {any} job - Fiche de poste à modifier
     * @returns {Promise<void>}
     */
    async openJobModal(job?: any): Promise<void> {
        this.$store.commit('jobDescription/SET_ERROR', null)

        if (job) {
            await this.$store.dispatch('jobDescription/getJobDescription', { jobDescription_id: job.id })
            this.jobToUpdate = JSON.parse(JSON.stringify(this.$store.getters['jobDescription/jobDescriptionSelect']))
        } else {
            this.jobToUpdate = {
                datas: [],
                status: JobDescriptionStatus.STATUS_DRAFT,
                working_establishment_id: this.$route.params?.working_establishment_id || null
            }
        }

        this.jobCurrentStatus = JSON.parse(JSON.stringify(this.jobToUpdate.status))
        this.formValues = JSON.parse(JSON.stringify(this.jobToUpdate.datas))
        this.formCheckValidation = false
        this.formValidation = false
        this.showUpdateJobModal = true
    }

    /**
     * @description Fermeture de la modale d'ajout de fiche de poste
     * @returns {void}
     */
    closeJobModal(): void {
        this.showUpdateJobModal = false
        this.jobCurrentStatus = null
        this.jobToUpdate = null
        this.readOnly = false
        this.formValues = null
        this.formCheckValidation = false
        this.formValidation = false
        this.$store.commit('jobDescription/SET_ERROR', null)
    }

    /**
     * @description Ajout / Mise à jour de la fiche de poste
     * @returns {void}
     */
    updateJob(): void {
        if (this.jobLoading || !this.jobToUpdate) {
            return
        }
        this.jobLoading = true

        this.formCheckValidation = false
        this.$nextTick(() => {
            this.formCheckValidation = true
        })

        if (!this.formValidation) {
            this.jobLoading = false
            return
        }

        const payload = {
            id: this.jobToUpdate.id,
            datas: this.formValues,
            status: this.jobCurrentStatus
        }

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.$store.dispatch(`jobDescription/updateJobDescription`, payload)
            .then(async () => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)

                if (this.isInDetailEstablishment()) {
                    this.params.working_establishment_id = this.$route.params.working_establishment_id
                }
                await this.$store.dispatch('jobDescription/getJobDescriptions', this.params)
            })
            .finally(() => {
                this.closeJobModal()
                this.jobLoading = false
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Ouverture de la modale de suppression de fiche de poste
     * @returns {void}
     */
    openRemoveJobModal(job: any): void {
        this.jobToRemove = job
        this.showRemoveJobModal = true
    }

    /**
     * @description Fermeture de la modale de suppression de fiche de poste
     * @returns {void}
     */
    closeRemoveJobModal(): void {
        this.showRemoveJobModal = false
        this.jobToRemove = null
    }

    /**
     * @description Suppression de la fiche de poste
     * @returns {void}
     */
    removeJob(): void {
        if (this.jobLoading) {
            return
        }
        this.jobLoading = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Suppression en cours ...', infosToaster)

        this.$store.dispatch('jobDescription/deleteJobDescription', this.jobToRemove.id)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Suppression terminée !', succesToaster)
                if (this.isInDetailEstablishment()) {
                    this.params.working_establishment_id = this.$route.params.working_establishment_id
                }
                this.$store.dispatch('jobDescription/getJobDescriptions', this.params)
            })
            .catch(() => {
                const idError = 't_error_' + Math.random()
                const errorToaster = {
                    id: idError,
                    toaster: 'b-toaster-top-right',
                    variant: 'danger',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Une erreur est survenue.', errorToaster)
            })
            .finally(() => {
                this.closeRemoveJobModal()
                this.jobLoading = false
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Ouverture de la modale de consultation d'un document de la fiche de poste
     * @param {any} job - Fiche de poste
     * @param {number} index - Index du document
     * @returns {Promise<void>}
     */
    async openJobDocument(job: any, index: number): Promise<void> {
        this.documentToShow.name = job.documents[index].name
        const response = await this.$store.dispatch('jobDescription/getJobDocument', {
            job_id: job.id, document_uuid: job.documents[index].uuid
        })
        this.documentToShow.content = base64ToArrayBuffer(response.data)
        this.showJobDocument = true
    }

    /**
     * @description Fermeture de la modale de consultation d'un document de la fiche de poste
     * @returns {void}
     */
    closeJobDocument(): void {
        this.showJobDocument = false
        this.documentToShow = {
            name: null,
            content: null
        }
    }

    /**
     * @description Ouverture de la modale d'édition du statut de la fiche de poste
     * @param {any} job - Fiche de poste à modifier
     * @returns {void}
     */
    openEditJobStatus(job: any): void {
        this.jobToUpdate = JSON.parse(JSON.stringify(job))
        this.jobCurrentStatus = JSON.parse(JSON.stringify(this.jobToUpdate.status))
        this.showEditJobStatus = true
    }

    /**
     * @description Fermeture de la modale d'édition du statut de la fiche de poste
     * @returns {void}
     */
    closeEditJobStatus(): void {
        this.showEditJobStatus = false
        this.jobToUpdate = null
        this.jobCurrentStatus = null
    }

    /**
     * @description Mise à jour du statut de la fiche de poste
     * @param {JobDescriptionStatus | null} status - Statut de la fiche de poste
     * @returns {void}
     */
    updateJobStatus(status: JobDescriptionStatus | null = null): void {
        if (this.jobLoading) {
            return
        }
        this.jobLoading = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.$store.dispatch('jobDescription/updateStatus', {
            job_id: this.jobToUpdate.id, status: status === 0 ? 0 : status || this.jobCurrentStatus
        })
            .then(async () => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)

                await this.$store.dispatch('jobDescription/getJobDescriptions', this.params)
                if (status) {
                    await this.openJobModal(
                        this.$store.getters['jobDescription/jobDescriptions']
                            .find((job: any) => job.id === this.jobToUpdate.id)
                    )
                }
            })
            .finally(() => {
                this.closeEditJobStatus()
                this.jobLoading = false
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Exportation des fiches de poste
     * @returns {void}
     */
    exportJobs(): void {
        if (this.jobLoading) {
            return
        }
        this.jobLoading = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours ...', infosToaster)

        this.$store.dispatch('jobDescription/export')
            .then((response) => {
                const link = document.createElement('a')
                link.href = URL.createObjectURL(new Blob([response.data]))
                link.setAttribute(
                    'Download',
                    getFileNameFromHeader(response.headers) || 'postes.xlsx'
                )
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.jobLoading = false
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Remplissage du tableau des fiches de poste
     * @param {any} poData - Données à afficher
     * @param {boolean} isLoadMore - Indique si on charge plus de données
     * @returns {void}
     */
    setDataForGenericTab(poData: any, isLoadMore = false): void {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            const can = this.$store.getters['auth/can']
            const canViewEstablishmentParent = can(Ability.EST_ESTABLISHMENT_PARENT_OWN_VIEW) || can(Ability.ADM_ESTABLISHMENT_VIEW)
            const canViewEstablishment = can(Ability.EST_ESTABLISHMENT_OWN_VIEW) || can(Ability.ADM_ESTABLISHMENT_VIEW)
            const canViewChildEstablishment = can(Ability.EST_ESTABLISHMENT_PARENT_OWN_VIEW) || can(Ability.ADM_ESTABLISHMENT_VIEW)

            for (const result of poData) {
                const status = getJobDescriptionStatus(result.status)
                let statusTitle = status.name
                if (status.index === JobDescriptionStatus.STATUS_PUBLISHED && result.published_at) {
                    statusTitle += ` le ${formatDateVariante(result.published_at)}`
                } else if (status.index === JobDescriptionStatus.STATUS_PROVIDED && result.provided_at) {
                    statusTitle += ` le ${formatDateVariante(result.provided_at)}`
                }
                const puceStatus = [{ name: 'circle', class: `text-${status.color}`, title: statusTitle, result: result }]

                const documents = result.documents.map((document: any, index: number) => {
                    return { name: 'file-alt', class: 'text-info cursor-pointer', title: document.name, result: { job: result, index: index }}
                })

                const datas: any = []
                datas.push({ label: 'Éditer', item: result, type: 'action', typeAction: 'edit', class: 'commons_first_action_button', icon: this.canEdit(result) && this.isDraft(result) ? 'pen' : 'eye', disabled: false })

                if (this.canEdit(result) && this.isDraft(result)) {
                    datas.push({ label: 'Voir', item: result, type: 'action', typeAction: 'show', class: 'commons_first_action_button', icon: 'eye', disabled: false })
                } else {
                    datas.push({ label: '', item: null, type: null, typeAction: null, class: 'commons_first_action_button', icon: null, disabled: true })
                }

                datas.push(
                    { label: '', item: result.code || '-', type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.name, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.workingEstablishment?.workingEstablishmentParent?.id, text: result.workingEstablishment?.workingEstablishmentParent?.name || '-', type: 'actionText', typeAction: canViewEstablishmentParent ? 'openEtablissement' : null, class: canViewEstablishmentParent && result.workingEstablishment?.workingEstablishmentParent?.name ? 'text-info' : '' },
                    { label: '', item: result.workingEstablishment?.id, text: result.workingEstablishment?.name || '-', type: 'actionText', typeAction: canViewEstablishment || canViewChildEstablishment ? 'openEtablissement' : null, class: (canViewEstablishment || canViewChildEstablishment) && result.workingEstablishment?.name ? 'text-info' : '' },
                    { label: '', item: result.speciality?.id, text: result.speciality?.name || '-', type: 'actionText', typeAction: null, class: '' },
                    { label: '', item: documents, type: 'icons', typeAction: 'showDocument', class: '' },
                    { label: '', item: result.candidacies_count, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: puceStatus, type: 'icons', typeAction: 'editStatus', class: `text-center ${!this.canEdit() ? 'pe-none' : ''}` }
                )

                if ((this.lastSave === null && result.updated_at) || (this.lastSave && result.updated_at && dateisSameOrBefore(this.lastSave, result.updated_at))) {
                    this.lastSave = result.updated_at
                }
                const updatedAt = (result.updated_at ? formatDateVariante(result.updated_at) : ' - ')
                const updatedAtIcon = [{
                    name: 'clock',
                    class: 'text-info text-small text-start',
                    title: updatedAt,
                    value_comp: updatedAt,
                    typeAction: 'popupHistorique',
                    item: result
                }]
                datas.push(
                    { label: '', item: updatedAtIcon, type: 'icons', typeAction: 'popupHistorique', class: 'text-center' },
                    { label: 'Supprimer', item: result, type: 'action', typeAction: 'remove', class: 'text-secondary text-end', icon: 'trash-alt', disabled: result.status !== 0 }
                )

                if (this.isInDetailEstablishment()) {
                    datas.splice(4, 1)
                }

                if (!can(Ability.EST_JOB_OWN_MANAGE) && !can(Ability.ADM_ESTABLISHMENT_MANAGE)) {
                    datas.splice(this.isInDetailEstablishment() ? 8 : 9, 1)
                    datas.splice(0, 1)
                }

                this.dataForTab.push(datas)
            }
        }
    }

    /**
     * @description Initialisation des filtres du tableau
     * @returns {void}
     */
    setFiltersForGenericTab(): void {
        const middle = Math.floor(Object.keys(JobDescriptionStatus).length / 2)
        this.jobStatus = Object.keys(JobDescriptionStatus).slice(0, middle)
            .map(status => getJobDescriptionStatus(parseInt(status)))

        this.filtres = [
            { libelle: 'Code', defautOptionlibelle: 'Rechercher un',  model: 'code', value: '', index: 'code', datas: null, loading: this.$store.getters['jobDescription/loading'], options: { type: 'form', fieldsKey: 'code' } },
            { libelle: 'Intitulé', defautOptionlibelle: 'Rechercher un', model: 'name', value: '', index: 'name', datas: null, loading: this.$store.getters['jobDescription/loading'], options: { type: 'form', fieldsKey: 'name' } },
            { libelle: 'Statut', defautOptionlibelle: 'Rechercher un', model: 'status',   value: '', index: 'status', datas: this.jobStatus, loading: this.$store.getters['jobDescription/loading'], options: { type: 'deroulant', fieldsKey: 'status' } },
            { libelle: 'ARS', defautOptionlibelle: 'Rechercher une', model: 'working_establishment_parent_name', value: '', index: 'working_establishment_parent_name', datas: this.jobStatus, loading: this.$store.getters['jobDescription/loading'], options: { type: 'form', fieldsKey: 'working_establishment_parent_name' } },
            { libelle: 'Établissement', defautOptionlibelle: 'Rechercher un', model: 'working_establishment_name', value: '', index: 'working_establishment_name', datas: this.jobStatus, loading: this.$store.getters['jobDescription/loading'], options: { type: 'form', fieldsKey: 'working_establishment_name' } },
            { libelle: 'Spécialité', defautOptionlibelle: 'Rechercher une', model: 'speciality_name', value: '', index: 'speciality_name', datas: this.jobStatus, loading: this.$store.getters['jobDescription/loading'], options: { type: 'form', fieldsKey: 'speciality_name' } }
        ]
    }

    /**
     * @description Initialisation des colonnes du tableau
     * @returns {void}
     */
    setFieldsForGenericTab(): void {
        this.genericfields = [
            { key: 'edit', label: '', sortable: false, class: '', type: 'action' },
            { key: 'show', label: '', sortable: false, class: '', type: 'action' },
            { key: 'code',                      label: 'Code',                  sortable: true,  class: '',            type: 'text' },
            { key: 'name',                      label: 'Intitulé',              sortable: true,  class: '',            type: 'text' },
            { key: 'working_establishment_parent_name', label: 'ARS',         sortable: true,  class: '',            type: 'text' },
            { key: 'working_establishment_name', label: 'Établissement',         sortable: true,  class: '',            type: 'text' },
            { key: 'speciality_name',           label: 'Spécialité',            sortable: true,   class: '',            type: 'text' },
            { key: 'documents',                 label: 'Documents',             sortable: false, class: 'text-center', type: 'text' },
            { key: 'candidatures',              label: 'Candidatures',          sortable: true,  class: 'text-center', type: 'text' },
            { key: 'status',                    label: 'Statut',                sortable: true,  class: 'text-center', type: 'text' },
            { key: 'updated_at',                label: 'Dernière modification', sortable: true,  class: 'ps-5'                      },
            { key: 'remove', label: '', sortable: false, class: 'text-end', type: 'action' }
        ]

        if (this.isInDetailEstablishment()) {
            this.genericfields.splice(4, 1)
        }

        const can = this.$store.getters['auth/can']
        if (!can(Ability.ADM_ESTABLISHMENT_MANAGE) && !can(Ability.EST_JOB_OWN_MANAGE)) {
            this.genericfields.splice(this.isInDetailEstablishment() ? 8 : 9, 1)
            this.genericfields.splice(0, 1)
        }
    }

    /**
     * @description Gestion des événements du tableau
     * @param {any} paParams - Paramètres de l'événement
     * @returns {Promise<void>}
     */
    async handleTableEvent(paParams: any): Promise<void> {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'edit':
                    if (this.canEdit()) {
                        await this.openJobModal(paParams[1])
                    }
                    break

                case 'show':
                    this.readOnly = true
                    await this.openJobModal(paParams[1])
                    break

                case 'showDocument':
                    await this.openJobDocument(
                        paParams[1][paParams[2]].result.job,
                        paParams[1][paParams[2]].result.index
                    )
                    break

                case 'editStatus':
                    if (this.canEdit()) {
                        await this.openEditJobStatus(paParams[1][0].result)
                    }
                    break

                case 'remove':
                    if (this.canEdit()) {
                        await this.openRemoveJobModal(paParams[1])
                    }
                    break

                case 'openEtablissement':
                    await this.$router.push(`/bourse_emploi/etablissements/${paParams[1]}`)
                    break

                case 'sortHandler':
                case 'filterHandler':
                    await this.filtreSortHandler(paParams[1])
                    break

                case 'onLoadPage':
                    await this.loadHandler(paParams[1])
                    break

                case 'popupHistorique':
                    (this.$refs.historique as PopupHistorique).open({ jobDescription_id: paParams[1][0].item.id })
                    break
            }
        }
    }

    /**
     * @description Applique des filtres
     * @param {any} params - Paramètres de filtre
     * @returns {Promise<void>}
     */
    async filtreSortHandler(params: any): Promise<void> {
        if (this.filtreJustInitiated) {
            this.filtreJustInitiated = false
        } else {
            if (this.isInDetailEstablishment()) {
                params['filter-working_establishment_id'] = this.$route.params.working_establishment_id
            }
            this.params = params
            await this.$store.dispatch('jobDescription/getJobDescriptions', params)
        }
    }

    /**
     * @description Complément des données sur un scroll
     * @param {any} params - Paramètres de chargement
     * @returns {Promise<void>}
     */
    async loadHandler(params: any): Promise<void> {
        if (this.isInDetailEstablishment()) {
            params['filter-working_establishment_id'] = this.$route.params.working_establishment_id
        }
        this.params = params
        await this.$store.dispatch('jobDescription/getMoreJobDescriptions', params)
    }

    /**
     * @description Avant le montage du composant
     * @returns {Promise<void>}
     */
    async beforeMount(): Promise<void> {
        this.filtreJustInitiated = true
        if (this.isInDetailEstablishment()) {
            this.params['filter-working_establishment_id'] = this.$route.params.working_establishment_id
        }
        await this.$store.dispatch('jobDescription/getJobDescriptions', this.params)
        this.setFieldsForGenericTab()
        this.setFiltersForGenericTab()
    }

    /**
     * @description Mise à jour des données de la table
     * @returns {Promise<void>}
     */
    async updateJobDescriptions(): Promise<void> {
        await this.$store.dispatch('jobDescription/getJobDescriptions', this.params)
    }

    /**
     * @description Montage du composant
     * @returns {void}
     */
    mounted(): void {
        (window as any).handleTableEvent = this.handleTableEvent
    }

    /**
     * @description Avant la destruction du composant
     * @returns {void}
     */
    beforeDestroy(): void {
        (window as any).handleTableEvent = null
    }

    /**
     * @description Vérifie si l'utilisateur consulte en détail un établissement
     * @returns {void}
     */
    isInDetailEstablishment(): boolean {
        return !!this.$route.params.working_establishment_id && this.$route.path === `/bourse_emploi/etablissements/${this.$route.params.working_establishment_id}`
    }
}
