
































































import { Watch, Prop, Vue, Component } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import PopupEditCandidatCompte from '@/components/Candidat/PopupEditCandidatCompte.vue'
import PopupEditCandidatSession from '@/components/Candidat/PopupEditCandidatSession.vue'
import PopupEditCandidatInscriptions from '@/components/Candidat/PopupEditCandidatInscriptions.vue'
import PopupEditCandidatAmenag from '@/components/Candidat/PopupEditCandidatAmenag.vue'
import PopupEditCandidatInfEcrits from '@/components/Candidat/PopupEditCandidatInfEcrits.vue'
import PopupEditCandidatInfOraux from '@/components/Candidat/PopupEditCandidatInfOraux.vue'
import PopupEditCandidatResultats from '@/components/Candidat/PopupEditCandidatResultats.vue'
import EditCommentFromDashboard from '@/components/Candidat/EditCommentFromDashboard.vue'
import { CandidatInterface } from '@/types/Candidat'

/**
 * Composant principal de la pop up d'édition de candidats
 */
@Component({
    computed: {
        ...mapGetters('etablissement', ['getEtablissementById']),
        ...mapGetters('candidat', ['editedCandidat']),
        ...mapState('candidat', ['error'])
    },
    components: {
        PopupEditCandidatCompte,
        PopupEditCandidatSession,
        PopupEditCandidatInscriptions,
        PopupEditCandidatAmenag,
        PopupEditCandidatInfEcrits,
        PopupEditCandidatInfOraux,
        PopupEditCandidatResultats,
        EditCommentFromDashboard
    }
})
export default class PopupEditCandidat extends Vue {
    @Prop() showEdit?: boolean;
    @Prop() tabSelected?: string
    @Prop() fromDashboard? : boolean | false
    @Prop() type? : string


    showModal?: boolean = false
    dataInitiated?: boolean = false
    candidatSelected: any  = null
    // tempCandidatSelected?: CandidatInterface = undefined

    epreuvesToAdd: number[] = []
    epreuvesToRemove: number[] = []
    commentaires: any = []

    isDirtyEtatCivil?: boolean = false
    lockActionsButtons?: boolean = false
    selected_tab = ''
    edit = false

    /**
     * A l'affichage du popup: initialisation des informations relatives au candidat et
     * aux épreuves
     */
    @Watch('showEdit')
    async showNoShow() {
        this.selected_tab = this.$props.tabSelected ? this.$props.tabSelected : 'candidat'
        this.showModal = this.$props.showEdit
        if (this.showModal) {
            this.candidatSelected = this.$store.getters['candidat/selectedCandidat']

            // Commenté, ne sert nulle part (???)
            // this.tempCandidatSelected = Object.assign({}, this.candidatSelected)

            if (this.candidatSelected) {
                this.$store.commit('candidat/SET_EDITED_CANDIDAT', this.candidatSelected.id)
                this.loadEtablissementCandidat(this.candidatSelected).then(() => {
                    // Chargement de l'ensemble des épreuves
                    // Check si le chargement est sur la session active
                    /*
                    let session_active_load = false
                    if (this.$store.state.session.sessionSelect && this.$store.getters['concour/banques'].length !== 0 &&
                        this.$store.state.session.sessionSelect.id === this.$store.getters['concour/banques'][0].session_id) {
                        session_active_load = true
                    }
                    */

                    // ***********************************************************************************************************************************************************************************
                    // on force le rechargement des épreuves sur la session active, car le state épreuves semble avoir été modifié spécifiquement lorsqu'on fait suite à l'édition d'un autre candidat (correctif bug #1557).
                    this.$store.dispatch('epreuve/getEpreuves', { isPrecedente: false, filters: {} }).then(() => {
                        this.dataInitiated = true
                    }).catch(err => {
                        console.log('error get epreuves: ', err)
                        this.dataInitiated = true
                    })
                })
                this.commentaires = this.candidatSelected.comments
            }
        } else if (this.lockActionsButtons) {
            this.lockActionsButtons = false
        }
    }

    /**
     * Mise à jour de la valeur de 'lockActionsButtons' indiquant si un des formulaires de l'interface
     * est en cours d'édition ou pas. Si cela est le cas, on doit bloquer les boutons permettant de fermer
     * le popup ou de naviguer dans un autre onglet
     */
    changeEdit(value: boolean) {
        this.lockActionsButtons = value
    }

    /**
     * Chargement de l'établissement du candidat si cela n'a pas déja été fait
     */
    loadEtablissementCandidat (candidatSelected: CandidatInterface) {
        return new Promise((resolve, reject) =>
        {
            if (candidatSelected.etablissement_id) {
                const etab = this.$store.getters['etablissement/getEtablissementById'](candidatSelected.etablissement_id)
                if (etab) {
                    this.$store.commit('etablissement/SET_ETABLISSEMENT_SELECT', etab)
                    resolve(true)
                } else {
                    const params: any = {
                        etablissement_id: candidatSelected.etablissement_id
                    }
                    this.$store.dispatch('etablissement/getEtablissement', params).then(() => {
                        resolve(true)
                    }).catch(err => {
                        reject(err)
                    })
                }
            } else {
                resolve(true)
            }
        })
    }

    closeModal () {
        this.epreuvesToAdd = []
        this.epreuvesToRemove = []

        this.$store.commit('candidat/RESET_EDITED_CANDIDAT')

        this.$emit('reinitShow', null)
    }
}
