





























import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import ListeCandidats from '../../components/BourseEmploi/Liste/ListeCandidats.vue'
import ListeCandidatures from '../../components/BourseEmploi/Liste/ListeCandidatures.vue'
import { Ability } from '../../types/Ability';

enum Tab {
    LISTE = 'liste',
    CANDIDATS = 'candidats'
}

@Component({
    name: 'CandidaturesView',
    components: {
        FontAwesomeIcon,
        ListeCandidatures,
        ListeCandidats
    },
    computed: {
        ...mapGetters('auth', ['can'])
    },
    watch: {
        tab: {
            handler(val) {
                if (this.$route.query.tab !== val) {
                    this.$router.push({ query: { tab: val } })
                }
            }
        }
    }
})

export default class Candidatures extends Vue {
    Ability = Ability
    Tab = Tab
    tab = this.$store.getters['auth/can'](Ability.ADM_ESTABLISHMENT_VIEW) ? this.$route.query.tab || Tab.LISTE : Tab.LISTE as Tab.LISTE | Tab.CANDIDATS


    setCurrentTab(tab: Tab.LISTE | Tab.CANDIDATS): void {
        if (this.$store.getters['auth/can'](Ability.ADM_ESTABLISHMENT_VIEW)) {
            this.tab = tab
        } else {
            this.tab = Tab.LISTE
        }
    }
}
