





















































































import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { isEmpty } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { FichePosteStep } from '../../types/BourseEmploi';
import { WorkingEstablishmentInterface } from '../../types/WorkingEstablishment';
import { WorkingEstablishmentTypeInterface } from '../../types/WorkingEstablishmentType';
import PhoneInput from '../Tools/PhoneInput.vue';
import Select from './Select.vue';
import { Ability } from '@/types/Ability';

@Component({
    name: 'CreateUserComponent',
    components: {
        FontAwesomeIcon,
        PhoneInput,
        Select
    },
    props: {
        user: {
            type: Object,
            required: false,
            default: null
        },
        edit: {
            type: Boolean,
            required: false,
            default: false
        },
        hideBtns: {
            type: Boolean,
            required: false,
            default: false
        },
        establishment: {
            type: Object,
            required: false,
            default: null
        },
        selectRole: {
            type: Boolean,
            required: false,
            default: false
        },
        formBtn: {
            type: Boolean,
            required: false,
            default: false
        },
        jobProcess: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    watch: {
        processing: {
            handler(val) {
                this.$emit('processing', val)
            }
        },
        user: {
            immediate: true,
            deep: true,
            handler: 'setData'
        },
        establishment: {
            immediate: true,
            deep: true,
            handler: 'setRoles'
        }
    }
})

export default class CreateUser extends Vue {
    FichePosteStep = FichePosteStep
    isEmpty = isEmpty
    processing = false as boolean
    id = 0 as number
    civility = null as null | string
    name = null as null | string
    first_name = null as null | string
    email = null as null | string
    telephone = null as null | string
    telephone_fixe = null as null | string
    fonction = '' as string
    roles = [] as WorkingEstablishmentTypeInterface[]
    rolesSelected = [] as WorkingEstablishmentTypeInterface[]

    reset(): void {
        this.processing = false
        this.rolesSelected = []
        this.setData()
    }

    setData(): void {
        this.id = this.$props.user?.id?.valueOf() || 0
        this.civility = this.$props.user?.civility?.valueOf() || ''
        this.name = this.$props.user?.name?.valueOf() || ''
        this.first_name = this.$props.user?.first_name?.valueOf() || ''
        this.email = this.$props.user?.email?.valueOf() || ''
        this.telephone = this.$props.user?.telephone?.valueOf() || ''
        this.telephone_fixe = this.$props.user?.telephone_fixe?.valueOf() || ''
        this.fonction = this.$props.user?.fonction?.valueOf() || ''
    }

    async setRoles(): Promise<void> {
        if (this.$props.selectRole) {
            let establishment: WorkingEstablishmentInterface = Object.assign({}, this.$props.establishment || {})

            if (isEmpty(establishment)) {
                establishment = Object.assign({}, this.$store.getters['workingEstablishment/workingEstablishmentSelect'] || {})
                if (this.$route.params?.working_establishment_id && establishment?.id !== Number(this.$route.params?.working_establishment_id)) {
                    await this.$store.dispatch('workingEstablishment/getWorkingEstablishment', { working_establishment_id: this.$route.params?.working_establishment_id })
                    establishment = Object.assign({}, this.$store.getters['workingEstablishment/workingEstablishmentSelect'] || {})
                }
            }

            if (isEmpty(establishment)) {
                this.roles = []
            } else {
                if (isEmpty(this.$store.getters['workingEstablishmentType/workingEstablishmentTypes'])) {
                    await this.$store.dispatch('workingEstablishmentType/getWorkingEstablishmentTypes')
                }

                this.roles = this.$store.getters['workingEstablishmentType/workingEstablishmentTypes']
                    ?.find((establishmentType: WorkingEstablishmentTypeInterface) => establishmentType.id === (establishment.working_establishment_type_id))
                    ?.roles
                    ?.map((item: any) => ({ title: item })) || []

                if (this.roles.length === 1) {
                    this.rolesSelected = this.roles
                }
            }
        }
    }

    canGoNextStep(): boolean {
        const can = this.$store.getters['auth/can']
        const workingEstablishmentsIds: number[] = this.$store.getters['user/reportingUserSelect']?.working_establishments?.map((item: any) => item.id) || []
        const canManageJob: boolean = can(Ability.ADM_ESTABLISHMENT_MANAGE) || can(Ability.EST_ESTABLISHMENT_PARENT_OWN_MANAGE) || (can(Ability.EST_ESTABLISHMENT_OWN_MANAGE) && workingEstablishmentsIds.includes(this.$props.establishment.id) && can(Ability.EST_JOB_OWN_MANAGE))

        const formValid = (): boolean => {
            this.updatePhones()
            return !!this.name && !!this.first_name && !!this.email && !!this.telephone_fixe && !!this.fonction
        }

        return (
            formValid() &&
            !this.processing &&
            !(this.$props.selectRole && isEmpty(this.$data.rolesSelected)) &&
            (this.$props.establishment?.workingEstablishmentChildren?.length > 0 ||
            (this.$props.establishment?.workingEstablishmentType?.job_description_creation_possible && canManageJob))
        )
    }

    updatePhones(): void {
        const telephone = (this.$refs.telephone as any | typeof PhoneInput)
        if (telephone?.infosPhoneInput?.isValidatedPhoneNumber) {
            this.telephone = telephone.infosPhoneInput.formattedPhoneNumber
        }

        const telephone_fixe = (this.$refs.telephone_fixe as any | typeof PhoneInput)
        if (telephone_fixe?.infosPhoneInput?.isValidatedPhoneNumber) {
            this.telephone_fixe = telephone_fixe.infosPhoneInput.formattedPhoneNumber
        }
    }

    send(createJob = false): void {
        if (!this.$props.edit || this.processing) {
            return
        }
        this.processing = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours...', infosToaster)

        this.updatePhones()

        const payload: any = {
            civility: this.civility,
            name: this.name,
            first_name: this.first_name,
            email: this.email,
            telephone: this.telephone,
            telephone_fixe: this.telephone_fixe,
            fonction: this.fonction,
            roles: this.rolesSelected.map((item: any) => item.title),
            working_establishment_id: this.$props.establishment?.id || Number(this.$route.params?.working_establishment_id)
        }

        if (this.id) {
            payload.id = this.id
        }

        this.$store.dispatch(`user/${payload?.id ? 'updateUser' : 'addReportingUser'}`, payload)
            .then((response) => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast(`Utilisateur ajouté avec succès !`, succesToaster)
                this.$emit('next', { data: response.data.data, createJob: createJob, setUser: true })
                this.reset()
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.processing = false
            })
    }

    /**
     * @description Vérifie si l'utilisateur consulte en détail un établissement
     * @returns {void}
     */
    isInDetailEstablishment(): boolean {
        return !!this.$route.params.working_establishment_id && this.$route.path === `/bourse_emploi/etablissements/${this.$route.params.working_establishment_id}`
    }
}
