

























































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { checkIcone, formatDate } from '@/utils/helpers'
import { getRoundedValue, getTypeRemuneration } from '@/types/BaremeRemuneration'

@Component({
    computed: {
        ...mapGetters('baremeremuneration', ['baremesRemuneration', 'loading', 'totalRows', 'lastPage', 'meta']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id'])
    },
    components: {
        ExaGenericTable,
        'font-awesome-icon': FontAwesomeIcon
    }
})

export default class BaremesRemunerations extends Vue {
    formatDate = formatDate
    sessionActive: any = null

    Ability = Ability

    // Ensemble des colonnes du tableau de epreuveCorrectionResultats

    genericfields = [
        { key: 'etatEdit', label: '', sortable: false, class: '', type: 'action' },
        { key: 'nature', label: 'Nature', sortable: false, class: '', type: 'text' },
        { key: 'prestation', label: 'Prestation', sortable: false, class: '', type: 'text' },
        { key: 'domaine', label: 'Domaine', sortable: false, class: '', type: 'text' },
        { key: 'type', label: 'Type', sortable: false, class: '', type: 'text' },
        { key: 'remuneration', label: 'Rémunération', sortable: false, class: 'text-center', type: 'text' },
        { key: 'frais_atelier', label: 'Frais d\'atelier', sortable: false, class: 'text-center', type: 'text' },
        { key: 'delete', label: '', sortable: false, class: '', type: 'action' }
    ]

    sortBy          = '';
    sortDesc        = false;
    sortDirection   = 'asc';
    filterOn        = [];
    stickyHeader    = true;

    filtres:    any         = []
    dataForTab: Array<any>  = []
    showDeleteBareme = false
    bareme: any = {}

    @Watch('baremesRemuneration')
    setLignesDatas() {
        this.setDataForGenericTab(this.$store.getters['baremeremuneration/baremesRemuneration'])
    }

    @Watch('user_session_id')
    async refreshInterface() {
        await this.load()
    }

    // Ajouter un nouveau bareme de remuneration
    ajouterBareme() {
        this.$router.push('/baremes_remunerations/0')
    }

    // Editer un bareme de remuneration
    editerBareme(row: any) {
        this.$router.push('/baremes_remunerations/' + row.item.id)
    }

    // Ouvre la confirmation de suppression d'un bareme de remuneration
    confirmDeleteBareme(row: any) {
        this.showDeleteBareme = true
        this.bareme = row.item
    }

    // Supprime le bareme de remuneration sélectionné
    deleteBaremeRemuneration() {
        this.$store.dispatch('baremeremuneration/deleteBaremeRemuneration', { bareme_id: this.bareme.id }).then(() => {
            this.showDeleteBareme = false
            this.$store.dispatch('baremeremuneration/getBaremesRemuneration')
        })
    }

    // Annulaton de la suppression du type de contrat
    cancelDeleteBareme() {
        this.showDeleteBareme = false
        this.bareme = {}
    }

    // Remplissage du tableau
    setDataForGenericTab(poData: any, isLoadMore = false) {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const result of poData) {
                const can = this.$store.getters['auth/can'](Ability.RH_CONTRAT_MANAGE)
                const icone = checkIcone(Ability.RH_CONTRAT_MANAGE, can)
                const icone_delete = { label: 'Supprimer', icon:'trash-alt' }

                const line = [
                    { label: icone.label, item: result.id,   type: 'action',  typeAction: 'edit', class: 'commons_first_action_button', icon: icone.icon, disabled: false },
                    { label: '', item: result.nature,        type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.prestation,    type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.domaine,       type: 'text', typeAction: null, class: '' },
                    { label: '', item: getTypeRemuneration(result.type), type: 'text', typeAction: null, class: '' },
                    { label: '', item: getRoundedValue(result.remuneration, 2),  type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: getRoundedValue(result.frais_atelier, 2), type: 'text', typeAction: null, class: 'text-center' }
                ]

                if (can) {
                    line.push({ label: icone_delete.label, item: result.id,   type: 'action',  typeAction: 'delete', class: 'text-secondary', icon: icone_delete.icon, disabled: false })
                }

                this.dataForTab.push(line)
            }
        }
    }

    // Init des filtres du tableau
    setFiltersForGenericTab() {
        this.filtres = [
            {
                libelle: 'Nature',
                defautOptionlibelle: 'Rechercher une',
                model: 'nature',
                value: '',
                index: 'nature',
                datas: null,
                loading: false,
                options: { type: 'form', fieldsKey: 'nature' }
            },
            {
                libelle: 'Epreuve de correction',
                defautOptionlibelle: 'Rechercher une',
                model: 'name',
                value: '',
                index: 'name',
                datas: null,
                loading: false,
                options: { type: 'form', fieldsKey: 'epreuve_correction.name' }
            }
        ]
    }

    // Events
    handleTableEvent(paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            const baremes: Array<any> = this.$store.state.baremeremuneration.baremesRemuneration
            let selectedBareme: any = null

            switch (paParams[0]) {
                case 'edit':
                    // Récupération du candidat by ID
                    selectedBareme = baremes.filter((b: any) => b.id === paParams[1])[0]
                    if (selectedBareme) {
                        this.$router.push('/baremes_remunerations/' + selectedBareme.id)
                    }
                    break
                case 'delete':
                    selectedBareme = baremes.filter((b: any) => b.id === paParams[1])[0]
                    if (selectedBareme) {
                        this.showDeleteBareme = true
                        this.bareme = selectedBareme
                    }
                    break
                case 'sortHandler':
                case 'filterHandler':
                    this.filterSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    this.loadHandler()
                    break
            }
        }
    }

    // Complement des datas sur un scroll
    loadHandler() {
        // this.$store.dispatch('baremes_remunerations/baremes_remunerations', params)
    }

    // Gestion des filtres et tris
    filterSortHandler(params: any) {
        this.$store.dispatch('baremeremuneration/getBaremesRemuneration', params)
    }

    async load() {
        await this.$store.dispatch('baremeremuneration/getBaremesRemuneration')
        this.setFiltersForGenericTab()
    }

    async mounted() {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            await this.load()
        }
    }
}
