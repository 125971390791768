

























































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component, Watch }    from 'vue-property-decorator'
import { mapGetters, mapState }               from 'vuex'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'
import { Ability }                  from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { checkIcone, formatDate } from '@/utils/helpers'

@Component({
    computed: {
        ...mapGetters('conventiontype', ['conventiontypes', 'loading', 'totalRows', 'lastPage']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id'])
    },
    components: {
        ExaGenericTable,
        'font-awesome-icon': FontAwesomeIcon
    }
})

export default class ConventionsTypes extends Vue
{
    formatDate = formatDate
    sessionActive: any = null

    Ability = Ability

    // Ensemble des colonnes du tableau de epreuveCorrectionResultats

    genericfields = [
        { key: 'etatEdit',   label: '', sortable: false, class: '', type: 'action' },
        { key: 'name',       label: 'Libellé', sortable: false, class: '', type: 'text' },
        { key: 'delete',     label: '', sortable: false, class: 'max_width_col', type: 'action' }
    ]

    sortBy          = '';
    sortDesc        = false;
    sortDirection   = 'asc';
    filter          = '';
    filterOn        = [];
    stickyHeader    = true;

    filtres:    any         = []
    dataForTab: Array<any>  = []
    showDeleteBareme = false
    bareme: any = {}

    @Watch('conventiontypes')
    setLignesDatas () {
        this.setDataForGenericTab(this.$store.state.conventiontype.conventiontypes)
    }

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    showDeleteConventionType = false
    conventiontype: any = {}
    // METHODS

    // Ajouter un nouveau type de convention
    ajouterConvention () {
        this.$router.push('/conventions_types/0')
    }

    // Supprime le type de convention sélectionné
    deleteConventionType () {
        this.$store.dispatch('conventiontype/deleteConventionType', { convention_type_id: this.conventiontype.id }).then(() => {
            this.showDeleteConventionType = false
            this.$store.dispatch('conventiontype/getConventionTypes')
        })
    }

    // Annulaton de la suppression du type de contrat
    cancelDeleteConventionType () {
        this.showDeleteConventionType = false
        this.conventiontype = {}
    }

    // Remplissage du tableau
    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                const can = this.$store.getters['auth/can'](Ability.GC_CONVENTION_MANAGE)
                const icone = checkIcone(Ability.GC_CONVENTION_MANAGE, can)
                const icone_delete = { label: 'Supprimer', icon:'trash-alt' }
                const line = [
                    { label: icone.label, item: result.id,   type: 'action',  typeAction: 'edit', class: 'commons_first_action_button', icon: icone.icon, disabled: false },
                    { label: '', item: result.name,        type: 'text', typeAction: null, class: '' }
                ]
                if (this.$store.getters['auth/can'](Ability.GC_CONVENTION_MANAGE)) {
                    line.push({ label: icone_delete.label, item: result.id,   type: 'action',  typeAction: 'delete', class: 'text-secondary max_width_col', icon: icone_delete.icon, disabled: false })
                } else {
                    line.push({ label: '', item: null, type: 'text', typeAction: null, class: '' })
                }
                this.dataForTab.push(line)
            }
        }
    }

    // Init des filtres du tableau
    setFiltersForGenericTab()
    {
        this.filtres =
        [
            { libelle: 'Epreuve de correction', defautOptionlibelle: 'Rechercher une', model: 'name', value: '', index: 'name', datas: null, loading: false, options: { type: 'form', fieldsKey: 'epreuve_correction.name' } }
        ]
    }

    // Events
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            const types_convention: Array<any> = this.$store.state.conventiontype.conventiontypes
            let selectType: any = null

            switch (paParams[0])
            {
                case 'edit':
                    // Récupération du candidat by ID
                    selectType = types_convention.filter((b: any) => b.id === paParams[1])[0]
                    if (selectType)
                    {
                        this.$router.push('/conventions_types/' + selectType.id)
                    }
                    break

                case 'delete':
                    selectType = types_convention.filter((b: any) => b.id === paParams[1])[0]
                    if (selectType) {
                        this.showDeleteConventionType = true
                        this.conventiontype = selectType
                    }
                    break

                case 'sortHandler':
                    this.loadHandler()
                    break

                case 'filterHandler':
                    this.loadHandler()
                    break

                case 'onLoadPage':
                    this.loadHandler()
                    break

                default:
                    break
            }
        }
    }


    // Complement des datas sur un scroll
    loadHandler ()
    {
        // this.$store.dispatch('baremes_remunerations/baremes_remunerations', params)
    }

    load () {
        this.filtres = []
        this.$store.dispatch('conventiontype/getConventionTypes')
    }

    mounted () {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
