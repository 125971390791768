











































import { BModal } from 'bootstrap-vue'
import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { Ability } from '../../types/Ability'
import { WorkingEstablishmentInterface } from '../../types/WorkingEstablishment'
import { formatDateVariante } from '../../utils/helpers'
import ActivityLog from '../ActivityLog.vue'
import ErrorDisplay from '../ErrorDisplay.vue'
import CreateEtab from './CreateEtab.vue'
import { isEmpty } from 'lodash'

@Component({
    name: 'InfosEtablissementComponent',
    components: {
        BModal,
        ActivityLog,
        ErrorDisplay,
        CreateEtab
    },
    props: {
        establishment: {
            type: Object as () => WorkingEstablishmentInterface,
            required: true
        }
    },
    watch: {
        '$route.params.working_establishment_id': {
            immediate: true,
            deep: true,
            handler: 'load'
        }
    },
    computed: {
        async canEdit() {
            const can = this.$store.getters['auth/can']
            if (!can(Ability.ADM_ESTABLISHMENT_MANAGE) && isEmpty(this.$store.getters['user/reportingUserSelect'])) {
                await this.$store.dispatch('user/getReportingUser', { user_id: this.$store.getters['auth/authUser']?.id })
            }

            const workingEstablishmentsIds = this.$store.getters['user/reportingUserSelect']?.working_establishments?.map((item: any) => item.id)
            return can(Ability.ADM_ESTABLISHMENT_MANAGE) || can(Ability.EST_ESTABLISHMENT_PARENT_OWN_MANAGE) || (can(Ability.EST_ESTABLISHMENT_OWN_MANAGE) && workingEstablishmentsIds?.includes(this.$data.establishment?.id))
        },
        ...mapGetters('workingEstablishment', ['error']),
        ...mapGetters('auth', ['can'])
    }
})

export default class InfosEtablissement extends Vue {
    Ability = Ability
    editMode = false
    processing = false
    formatDateVariante = formatDateVariante
    togglePopupHistoriqueWorkingEstablishment = false
    errorKeeper = null
    historiqueWorkingEstablishment: any = null

    /**
     * Toggle le popup d'historique des validations / invalidations des barres
     * @returns {void}
     */
    openPopupHistoriqueWorkingEstablishment(workingEstablishmentId: any): void {
        this.errorKeeper = null
        this.historiqueWorkingEstablishment = null
        this.$store.dispatch('workingEstablishment/getActivityLog', { working_establishment_id: workingEstablishmentId })
            .then((response) => {
                this.historiqueWorkingEstablishment = response.data.data
                this.togglePopupHistoriqueWorkingEstablishment = true
            })
    }

    closePopupHistoriqueWorkingEstablishment() {
        this.historiqueWorkingEstablishment = null
        this.togglePopupHistoriqueWorkingEstablishment = false
    }

    updateWorkingEstablishment(payload: any): void {
        this.$emit('updateWorkingEstablishment', payload)
        this.editMode = false
    }

    /**
     * @description Montage du composant
     * @returns {Promise<void>}
     */
    async load(): Promise<void> {
        if (!this.$store.getters['auth/can'](Ability.ADM_ESTABLISHMENT_MANAGE) && isEmpty(this.$store.getters['user/reportingUserSelect'])) {
            await this.$store.dispatch('user/getReportingUser', { user_id: this.$store.getters['auth/authUser']?.id })
        }
    }
}
