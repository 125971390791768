









































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { base64ToArrayBuffer, formatDate, getFileNameFromHeader, format_phone_number } from '@/utils/helpers'
import { getEnumTypeAmenagement, getTypeAmenagement, TypeAmenagement } from '@/types/Salle'
import { TypeMesure } from '@/types/Amenagement'
import { DecisionAmenagementInterface, EtatDecisionAmenagement } from "@/types/DecisionAmenagement";
import { isEmpty } from "lodash";

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapState('listeCandidatCdc', ['listeCandidats', 'isLoading', 'totalRows', 'lastPage', 'totalPage', 'loading_cand_cdc', 'meta'])
    },
    components: {
        ExaGenericTable,
        'font-awesome-icon': FontAwesomeIcon
    }
})

export default class ListeCandidatsEcrits extends Vue
{
    showModalEditionCandidat?:          boolean = false
    showModalEditionCommentCandidat?:   boolean = false
    formatDate                                  = formatDate
    tableLoading                                = false

    Ability = Ability

    genericfields: Array<any> = []

    sortDirection   = 'asc'

    filtres:    any         = []
    dataForTab: Array<any>  = []
    allEpreuves             = []
    params: any = {}
    exportWorking = false

    @Watch('listeCandidats')
    getExaminateurs() {
        this.setDataForGenericTab(this.$store.state.listeCandidatCdc.listeCandidats)
    }

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    // Charge les gestions particulières
    load_gestions_particulieres () {
        this.$store.dispatch('gestionParticuliere/getGestionParticulieres').then(() => {
            this.buildGenericFieldsSuite()
        })
    }

    // Rajoute les colonnes de gestions particulières
    buildGenericFieldsSuite () {
        this.genericfields = [
            { key: 'code',          label: 'Code',          sortable: true,   class: '', type: 'text' },
            { key: 'name',          label: 'Nom',           sortable: true,   class: '', type: 'text' },
            { key: 'first_name',    label: 'Prénom',        sortable: true,   class: '', type: 'text' },
            { key: 'filiere',       label: 'Filière',       sortable: true,   class: 'text-center', type: 'text' },
            { key: 'email',         label: 'Courriel',      sortable: false,  class: '', type: 'text' },
            { key: 'telephone',     label: 'Téléphone',     sortable: false,  class: '', type: 'text' },
            { key: 'demande_amenagement', label: 'Demande aménagements', sortable: true, class: 'text-center col-min-width', type: 'icons' }
        ]
        for (let i = 0; i < this.$store.state.domaine.domaines.length; i++) {
            this.genericfields.push({ key: 'domaineid_' + this.$store.state.domaine.domaines[i].id + '-amenagements.accessibilite', label: 'Salle', sortable: false,  class: 'text-center bg-gray-100', type: 'text', doubleHeaderLabel: this.$store.state.domaine.domaines[i].name.toUpperCase(), doubleHeaderColSpan: 3, doubleHeaderClass: 'bg-gray-100 text-center' })
            this.genericfields.push({ key: 'domaineid_' + this.$store.state.domaine.domaines[i].id + '-amenagements.gestion_particuliere.name', label: 'Gestion Part.', sortable: false, type: 'text',  class: 'text-center bg-gray-100', doubleHeaderClass: 'bg-gray-100 text-center'  })
            this.genericfields.push({ key: 'domaineid_' + this.$store.state.domaine.domaines[i].id + '-amenagements.temps_supplementaire.name', label: 'Temps', sortable: false, type: 'text',  class: 'text-center bg-gray-100', doubleHeaderClass: 'bg-gray-100 text-center'  })
        }

        this.genericfields.push({ key: 'mesures',       label: 'Fichier PDF',       sortable: true,   class: 'text-center', type: 'text' })
    }

    // Création des lignes du tableau
    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                const iconDemandeAm: any[] = []
                let titleDemandeAmenagement = ''
                let decision_ecrit: DecisionAmenagementInterface = {} as DecisionAmenagementInterface
                let decision_oral: DecisionAmenagementInterface = {} as DecisionAmenagementInterface

                // Récupération de la décision d'aménagement
                if (!isEmpty(result.decisionAmenagements)) {
                    const index_ecrit = result.decisionAmenagements.findIndex(
                        (d: DecisionAmenagementInterface) => d.type === TypeMesure.TYPE_ECRIT
                    )
                    const index_oral = result.decisionAmenagements.findIndex(
                        (d: DecisionAmenagementInterface) => d.type === TypeMesure.TYPE_ORAL
                    )
                    decision_ecrit = result.decisionAmenagements[index_ecrit]
                    decision_oral = result.decisionAmenagements[index_oral]
                }

                if (result.demande_amenagement) {
                    let state: {title: string; class: string} = { title: 'Non traitée', class: 'text-secondary' }

                    if (!isEmpty(decision_oral) && decision_oral.etat === EtatDecisionAmenagement.ETAT_VALIDE) {
                        state = { title: 'Demande d\'aménagements ORAL - Traitée', class: 'text-success' }
                    }
                    if (!isEmpty(decision_ecrit) && decision_ecrit.etat === EtatDecisionAmenagement.ETAT_VALIDE) {
                        state = { title: 'Demande d\'aménagements ECRIT - Traitée', class: 'text-success' }
                    }
                    iconDemandeAm.push({
                        name: 'check',
                        class: state.class,
                        title: state.title
                    })
                    titleDemandeAmenagement = state.title
                }

                const line: any = [
                    { label: '', item: result.code,                                 type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.name,                                 type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.first_name,                           type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.concour.name,                         type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: result.email,                                type: 'text', typeAction: null, class: '' },
                    { label: '', item: format_phone_number(result.portable),                             type: 'text', typeAction: null, class: '' },
                    { label: '', item: iconDemandeAm, type: 'icons', typeAction: null, class: 'text-center', title: titleDemandeAmenagement }
                ]


                // Affiche les mesures de salles, gestion particulières et majoration de temps pour le candidat
                for (let i = 0; i < this.$store.state.domaine.domaines.length; i++) {
                    let salle = ''
                    let gestionPart = ''
                    let temps = ''

                    for (let j = 0; j < result.amenagements.length; j++) {
                        if (result.amenagements[j].domaine_ids.includes(this.$store.state.domaine.domaines[i].id)) {
                            if (result.amenagements[j].accessibilite !== TypeAmenagement.AMENAGEMENT_AUCUN) {
                                const salleTemps = getTypeAmenagement(result.amenagements[j].accessibilite).libelle
                                if (!salle.includes(salleTemps)) {
                                    salle += '<div class="text-center icone_oral_ecrit m-0 p-0" title="' + getTypeAmenagement(result.amenagements[j].accessibilite).libelle + '">' + salleTemps + '</div>'
                                }
                            }

                            if (result.amenagements[j].gestion_particuliere_ids && result.amenagements[j].gestion_particuliere_ids.length > 0) {
                                for (let k  = 0; k < result.amenagements[j].gestion_particuliere_ids.length; k++) {
                                    const gp = this.getGestionParticuliereById(result.amenagements[j].gestion_particuliere_ids[k])
                                    let nameGestionPart = ''
                                    if (gp) {
                                        nameGestionPart = gp.code
                                    }
                                    if (!gestionPart.includes(nameGestionPart)) {
                                        gestionPart = gestionPart + '<div class="text-center icone_oral_ecrit m-0 p-0" title="' + gp.name + '">' + nameGestionPart + '</div>'
                                    }
                                }
                            }
                            if (result.amenagements[j].temps_supplementaire_id) {
                                const tempsTemp = this.getTempsSuplementaireById(result.amenagements[j].temps_supplementaire_id).code + ' TEMPS'
                                if (!temps.includes(tempsTemp)) {
                                    temps = temps + '<div class="text-center icone_oral_ecrit m-0 p-0" title="' + this.getTempsSuplementaireById(result.amenagements[j].temps_supplementaire_id).name + '">' + tempsTemp + '</div>'
                                }
                            }
                        }
                    }

                    line.push({ label: '', item: salle, type: 'html', typeAction: null, class: 'text-center' })
                    line.push({ label: '', item: gestionPart, type: 'html', typeAction: null, class: 'text-center' })
                    line.push({ label: '', item: temps, type: 'html', typeAction: null, class: 'text-center' })
                }

                if (result.amenagements.length !== 0 && result.amenagements.find((a: any) => a.type === TypeMesure.TYPE_ECRIT)) {
                    line.push({ label: 'Télécharger le PDF', item: result, type: 'action', typeAction: 'openPdf', class: 'text-center text-primary', icon: 'print' })
                } else {
                    line.push({ label: '', item: null, type: 'text', typeAction: null, class: '' })
                }

                this.dataForTab.push(line)
            }
        }
    }

    // Création des filtres pour le tableau
    setFiltersForGenericTab()
    {
        // Options filières
        const filieres = this.$store.getters['concour/banques']
        const options_filieres = []
        for (const f in filieres) {
            options_filieres.push({ index: filieres[f].id, name: filieres[f].name })
        }

        // Options séries
        const series = this.$store.getters['serie/series']
        const options_series = []
        for (const s in series) {
            options_series.push({ index: series[s].id, name: series[s].name })
        }

        this.filtres =
        [
            { libelle: 'Code',      defautOptionlibelle: 'Rechercher un',   model: 'code',                value: '', index: 'code',           datas: null,               loading: this.$store.state.candidat.loading, options: { type: 'form',      fieldsKey: 'code', strict: true } },
            { libelle: 'Nom',       defautOptionlibelle: 'Rechercher un',   model: 'name',                value: '', index: 'name',           datas: null,               loading: this.$store.state.candidat.loading, options: { type: 'form',      fieldsKey: 'name' } },
            { libelle: 'Prénom',    defautOptionlibelle: 'Rechercher une',  model: 'first_name',          value: '', index: 'first_name',     datas: null,               loading: this.$store.state.candidat.loading, options: { type: 'form',      fieldsKey: 'first_name' } },
            { libelle: 'Filière',   defautOptionlibelle: 'Rechercher une',  model: 'concour_id',          value: '', index: 'filiere',        datas: options_filieres,   loading: this.$store.state.candidat.loading, options: { type: 'deroulant', fieldsKey: 'filiere' } }
        ]

        const accessibilite_temp = getEnumTypeAmenagement()
        const gestionParticulieres = this.$store.getters['gestionParticuliere/gestionParticulieres']
        const tempsSupplementaires = this.$store.getters['tempsSupplementaire/tempsSupplementaires']

        for (let i = 0; i < this.$store.state.domaine.domaines.length; i++) {
            const accessibilites: any = []
            for (const a in accessibilite_temp) {
                accessibilites.push({ index:  accessibilite_temp[a].id + ':' + this.$store.state.domaine.domaines[i].id, name: accessibilite_temp[a].name })
            }

            const gestion_parts: any = []
            for (const g in gestionParticulieres) {
                gestion_parts.push({ index: gestionParticulieres[g].id + ':' + this.$store.state.domaine.domaines[i].id, name: gestionParticulieres[g].code  })
            }

            const temps_sup: any = []
            for (const t in tempsSupplementaires) {
                temps_sup.push({ index: tempsSupplementaires[t].id + ':' + this.$store.state.domaine.domaines[i].id, name: tempsSupplementaires[t].code })
            }
            this.filtres.push({
                libelle: 'Salle',
                defautOptionlibelle: 'Rechercher une',
                model:  'amenagements_accessibilite',
                value: '',
                index: 'amenagements_accessibilite',
                datas: accessibilites,
                loading: false,
                options: { type: 'deroulant', fieldsKey:  'domaineid_' + this.$store.state.domaine.domaines[i].id + '-amenagements.accessibilite' }
            })
            this.filtres.push({
                libelle: 'Temps',
                defautOptionlibelle: 'Rechercher un',
                model:  'amenagements_temps_supplementaire_id',
                value: '',
                index: 'amenagements_temps_supplementaire_id',
                datas: temps_sup,
                loading: false,
                options: { type: 'deroulant', fieldsKey:  'domaineid_' + this.$store.state.domaine.domaines[i].id + '-amenagements.temps_supplementaire.name' }
            })
            this.filtres.push({
                libelle: 'Gestion particulière',
                defautOptionlibelle: 'Rechercher une',
                model: 'amenagements_gestion_particuliere_id',
                value: '',
                index: 'amenagements_gestion_particuliere_id',
                datas: gestion_parts,
                loading: false,
                options: { type: 'deroulant', fieldsKey: 'domaineid_' + this.$store.state.domaine.domaines[i].id + '-amenagements.gestion_particuliere.name' }
            })
        }
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'openPdf':
                    this.export_fiche_pdf(paParams[1])
                    break
                default:
                    break
            }
        }
    }

    // Applique le chargement de la pagination
    loadHandler (params: any) {
        Vue.set(params, 'centre_id', this.$route.params.centre_id)
        this.$store.dispatch('listeCandidatCdc/getMoreCandidatsCdc', params)
    }

    // Applique les filtres
    filtreSortHandler(params: any) {
        Vue.set(params, 'centre_id', this.$route.params.centre_id)
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.$store.dispatch('listeCandidatCdc/getCandidatsCdc', params)
        }
    }

    // Export de la liste des candidats au format excel
    dl_export_candidats () {
        this.$store.commit('listeCandidatCdc/SET_ERROR', null)
        let fileName = ''
        this.exportWorking = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours...', infosToaster)

        this.$store.dispatch('listeCandidatCdc/getCandidatsCdcExport', { centre_id: this.$route.params.centre_id })
            .then((response) => {
                const fileNameTemp = getFileNameFromHeader(response.headers)
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.exportWorking = false
            })
    }

    /** Exporte au format pdf la fiche des mesures d'aménagements du candidat */
    export_fiche_pdf (candidat: any) {
        if (this.exportWorking) {
            return
        }
        this.$store.commit('listeCandidatCdc/SET_ERROR', null)
        let fileName = ''
        this.exportWorking = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Export en cours...', infosToaster)

        this.$store.dispatch('candidat/getPDFAmenagements', { candidat_id: candidat.id, ecrit: true })
            .then((response) => {
                const fileNameTemp = getFileNameFromHeader(response.headers) || 'Amenagements_' + candidat.name + '_' + candidat.first_name + '.pdf'
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([base64ToArrayBuffer(response.data)]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.exportWorking = false
            })
    }

    /**
     * downloadDecisions
     * Récupére le ZIP d'export des décisions du centre et lance le téléchargement
     * @returns {void}
     */
    downloadDecisions(): void {
        if (this.exportWorking) {
            return
        }
        this.exportWorking = true
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Téléchargement en cours ...', infosToaster)

        this.$store.dispatch('centre/exportDecisions', parseInt(this.$route.params.centre_id))
            .then((response) => {
                // Logique de téléchargement
                const file_name = getFileNameFromHeader(response.headers) || 'candidat_amenagements_centres.zip'
                const url = URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', file_name)
                link.download = file_name
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.exportWorking = false
            })
    }

    // Retourne la gestion particulière en fonction de l'id donné
    getGestionParticuliereById(id: number) {
        for (let i = 0; i < this.$store.state.gestionParticuliere.gestionParticulieres.length; i++) {
            if (id === this.$store.state.gestionParticuliere.gestionParticulieres[i].id) {
                return this.$store.state.gestionParticuliere.gestionParticulieres[i]
            }
        }
        return null
    }

    // Retourne la majoration de temps en fonction de l'id donné
    getTempsSuplementaireById(id: number) {
        for (let i = 0; i < this.$store.state.tempsSupplementaire.tempsSupplementaires.length; i++) {
            if (id === this.$store.state.tempsSupplementaire.tempsSupplementaires[i].id) {
                return this.$store.state.tempsSupplementaire.tempsSupplementaires[i]
            }
        }
        return null
    }


    load () {
        this.$store.commit('listeCandidatCdc/SET_LOADING', true)
        const params: any = {
            page: 1,
            sort: 'name',
            direction: 'asc',
            centre_id: this.$route.params.centre_id
        }
        this.params = params
        // Load des domaines
        this.$store.dispatch('domaine/getDomaines').then(() => {
            // Load des temps supplémentaires
            this.$store.dispatch('tempsSupplementaire/getTempsSupplementaires').then(() => {
            // Load des concours actifs
                this.$store.dispatch('concour/getConcoursActifs').then(() => {
                // Load des candidats du centre
                    this.$store.dispatch('listeCandidatCdc/getCandidatsCdc', params).then(() => {
                        this.load_gestions_particulieres()
                        this.setFiltersForGenericTab()
                    })
                })
            })
        })
    }

    mounted() {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
