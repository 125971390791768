






















































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
    components: {}
})
export default class SmallCardDuo extends Vue {
    /* DUO CARD */
    @Prop() readonly titleFirstCard!: string | undefined
    @Prop() readonly numberShowFirstCard!: number | undefined
    @Prop() readonly numberShow2FirstCard!: number | undefined

    @Prop() readonly textShowSingularFirstCard!: string | undefined
    @Prop() readonly textShowPluralFirstCard!: string | undefined
    @Prop() readonly linkFirstCard!: string

    @Prop() readonly titleSecondCard!: string | undefined
    @Prop() readonly numberShowSecondCard!: number | undefined
    @Prop() readonly numberShow2SecondCard!: number | undefined
    @Prop() readonly textShowSingularSecondCard!: string | undefined
    @Prop() readonly textShowPluralSecondCard!: string | undefined
    @Prop() readonly linkSecondCard!: string

    @Prop() readonly doneFirstCard!: string | undefined
    @Prop() readonly doneSecondCard!: string | undefined
}
