
































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import store from '@/store/index'
// import Editor from '@/components/Tools/Editor.vue'
import EditorTinyMCE from '@/components/Tools/EditorTinyMCE.vue'
import VuePdfApp from 'vue-pdf-app'
import { base64ToArrayBuffer, codeRestrict } from '@/utils/helpers'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import Back from '@/components/Tools/Back.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    components: {
        EditorTinyMCE,
        VuePdfApp,
        Back,
        ErrorDisplay,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('conventiontype', ['conventiontype_select', 'loading', 'totalRows', 'lastPage', 'source_pdf']),
        ...mapState('conventiontype', ['error']),
        ...mapGetters('session', ['sessionActive']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id'])
    },
    methods: {
        ...mapActions('conventiontype', ['getconventiontype', 'addconventiontype', 'updateconventiontype', 'getPdf']),
        ...mapMutations('conventiontype', ['SET_CONVENTION_TYPE'])
    },
    beforeRouteEnter (to: any, from: any, next: any) {
        store.commit('conventiontype/SET_CONVENTION_TYPE', null)
        if (to.params.convention_type_id !== '0') {
            next()
        } else {
            next()
        }
    }
})
export default class EditionConventionType extends Vue {
    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    codeRestrict = codeRestrict
    @Watch('conventiontype_select')
    setContratSelect () {
        if (this.$store.state.conventiontype.conventiontype_select) {
            this.convention_name = this.$store.state.conventiontype.conventiontype_select.name
            this.body_text = this.$store.state.conventiontype.conventiontype_select.body
            this.footer_text = this.$store.state.conventiontype.conventiontype_select.footer
            this.blocs = this.$store.state.conventiontype.conventiontype_select.annexe_blocs
        }
    }

    // DATAS
    Ability = Ability
    modePreview = false
    convention_name = ''
    body_text = ''
    annexe_text = ''
    footer_text = ''
    blocs: Array<any> = []
    message_error = ''
    config = {
        toolbar: {
            toolbarViewerRight: { presentationMode: false, openFile: false, viewBookmark: false, secondaryToolbarToggle: false }
        }
    }

    source_doc: any = ''
    showFormulaireBloc = false
    confirmDeleteBloc = false
    addBloc = false
    bloc_af_temp = {
        code: '',
        libelle: '',
        description: '',
        montant: 0
    }
    // METHODS

    // Récupère le texte du body depuis Editor
    setBodyText (bodyText: string) {
        this.body_text = bodyText
    }

    // Récupère le texte du body depuis Editor
    setAnnexeText (annexeText: string) {
        this.annexe_text = annexeText
    }

    // Récupère le texte du footer depuis Editor
    setFooterText (footerText: string) {
        this.footer_text = footerText
    }

    // Save or update la convention
    saveconventiontype () {
        this.$store.state.conventiontype.error = null
        const datas = {
            name: this.convention_name,
            body: this.body_text,
            annexe: this.annexe_text,
            footer: this.footer_text,
            session_id: this.$store.state.session.sessionSelect.id,
            annexe_blocs: this.blocs
        }

        if (this.$store.state.conventiontype.conventiontype_select) {
            // update
            this.$store.dispatch('conventiontype/updateConventionType', { convention_type_id: this.$store.state.conventiontype.conventiontype_select.id, payload: datas })
                .then(() => {
                    if (!this.$store.state.conventiontype.error) {
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }
                        this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                    }
                })
        } else {
            // Création d'un type de convention
            this.$store.dispatch('conventiontype/saveConventionType', datas)
                .then(() => {
                    if (!this.$store.state.conventiontype.error) {
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }
                        this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                        this.$router.push('/conventions_types/' + this.$store.state.conventiontype.conventiontype_select.id)
                    }
                })
        }
    }

    // Aperçu du pdf
    apercuConvention () {
        this.$store.dispatch('conventiontype/getPDF', this.$store.state.conventiontype.conventiontype_select.id)
            .then(() => {
                this.source_doc = base64ToArrayBuffer(this.$store.state.conventiontype.source_pdf)
                this.modePreview = true
            })
    }

    // Aperçu annexe financière
    apercuAnnexe () {
        this.$store.dispatch('conventiontype/getPDFAnnexe', this.$store.state.conventiontype.conventiontype_select.id)
            .then(() => {
                this.source_doc = base64ToArrayBuffer(this.$store.state.conventiontype.source_pdf)
                this.modePreview = true
            })
    }

    // Ferme l'aperçu du pdf
    closeModePreview () {
        this.modePreview = false
        this.$store.state.conventiontype.source_pdf = ''
    }

    // Ouvre le formulaire de création d'un bloc d'annexe financiere
    createBloc () {
        this.showFormulaireBloc = true
        this.addBloc = true
    }

    // Sauvegarde un nouveau bloc AF
    saveBlocAF () {
        if (this.addBloc) {
            this.blocs.push(this.bloc_af_temp)
        } else {
            const index = this.blocs.findIndex((b: any) => b.code === this.bloc_af_temp.code)
            this.blocs[index] = this.bloc_af_temp
        }

        const datas = {
            name: this.convention_name,
            body: this.body_text,
            annexe: this.annexe_text,
            footer: this.footer_text,
            session_id: this.$store.state.session.sessionSelect.id,
            annexe_blocs: this.blocs
        }

        if (this.$store.state.conventiontype.conventiontype_select) {
            // update
            this.$store.dispatch('conventiontype/updateConventionType', { convention_type_id: this.$store.state.conventiontype.conventiontype_select.id, payload: datas })
                .then(() => {
                    if (!this.$store.state.conventiontype.error) {
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }
                        this.$bvToast.toast('Enregistrement terminé !', succesToaster)

                        this.addBloc = false
                        this.showFormulaireBloc = false
                        this.bloc_af_temp = {
                            code: '',
                            libelle: '',
                            description: '',
                            montant: 0
                        }
                    }
                })
        } else {
            // Création d'un type de convention
            this.$store.dispatch('conventiontype/saveConventionType', datas)
                .then(() => {
                    if (!this.$store.state.conventiontype.error) {
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }
                        this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                        this.$router.push('/conventions_types/' + this.$store.state.conventiontype.conventiontype_select.id)

                        this.addBloc = false
                        this.showFormulaireBloc = false
                        this.bloc_af_temp = {
                            code: '',
                            libelle: '',
                            description: '',
                            montant: 0
                        }
                    }
                })
        }
    }

    // Edition d'un bloc existant
    editionBlocAF (bloc: any) {
        this.bloc_af_temp = {
            code: bloc.code,
            libelle: bloc.libelle,
            description: bloc.description,
            montant: bloc.montant
        }
        this.showFormulaireBloc = true
        this.confirmDeleteBloc = false
        this.addBloc = false
    }

    // Annule l'édition ou la création d'un bloc
    cancelEditionBloc () {
        this.bloc_af_temp = {
            code: '',
            libelle: '',
            description: '',
            montant: 0
        }
        this.showFormulaireBloc = false
    }

    // Message de confirmation de suppression d'un bloc AF
    showConfirmDeleteBloc (bloc: any) {
        this.bloc_af_temp = bloc
        this.confirmDeleteBloc = true
    }

    // Confirme la suppression d'un bloc AF
    confirmDeleteBlocFunc () {
        const index = this.blocs.findIndex((b: any) => b.code === this.bloc_af_temp.code)
        this.blocs.splice(index, 1)

        const datas = {
            name: this.convention_name,
            body: this.body_text,
            annexe: this.annexe_text,
            footer: this.footer_text,
            session_id: this.$store.state.session.sessionSelect.id,
            annexe_blocs: this.blocs
        }

        this.$store.dispatch('conventiontype/updateConventionType', { convention_type_id: this.$store.state.conventiontype.conventiontype_select.id, payload: datas })
            .then(() => {
                if (!this.$store.state.conventiontype.error) {
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Suppression terminée !', succesToaster)

                    this.addBloc = false
                    this.confirmDeleteBloc = false
                    this.bloc_af_temp = {
                        code: '',
                        libelle: '',
                        description: '',
                        montant: 0
                    }
                }
            })
    }

    // Annule la confirmation de suppression d'un bloc AF
    cancelConfirmDeleteBloc () {
        this.bloc_af_temp = {
            code: '',
            libelle: '',
            description: '',
            montant: 0
        }

        this.confirmDeleteBloc = false
    }

    load() {
        this.$store.state.conventiontype.error = null
        if (this.$store.state.conventiontype.conventiontypes.length !== 0) {
            const index = this.$store.state.conventiontype.conventiontypes.findIndex((c: any) => c.id.toString() === this.$route.params.convention_type_id)
            this.$store.state.conventiontype.conventiontype_select = this.$store.state.conventiontype.conventiontypes[index]
        } else {
            this.$store.dispatch('conventiontype/getConventionTypes').then(() => {
                const index = this.$store.state.conventiontype.conventiontypes.findIndex((c: any) => c.id.toString() === this.$route.params.convention_type_id)
                this.$store.state.conventiontype.conventiontype_select = this.$store.state.conventiontype.conventiontypes[index]
            })
        }
    }

    mounted () {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
