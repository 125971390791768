





















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
    components: {}
})
export default class DoubleCardGraph extends Vue {
    /* Communes à toutes les cartes */
    @Prop() readonly cardType!: string; // type de carte
    @Prop() readonly title!: string;
    @Prop() readonly textShowSingular!: string
    @Prop() readonly textShowPlural!: string | undefined;
    @Prop() readonly link!: string;
    @Prop() readonly doneText!: string;
    @Prop() readonly doneBottomText!: string | undefined

    /* ****************** */
    /* Milieu de la carte */
    /* ****************** */
    /* Affichage un seul chiffre */
    @Prop() readonly numberShow!: number | undefined

    /* ************ */
    /* Bottom carte */
    /* ************ */
    /* regular card */
    @Prop() readonly bottomText!: string | undefined;
}
