
























































import Bonification from '@/views/Bonification/Bonification.vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { isEmpty } from 'lodash'
import { countBonus, titleChildCritere, TypeBonification } from '@/types/Bonification'
import { BModal, BootstrapVue, BootstrapVueIcons, BTable } from 'bootstrap-vue'

@Component({
    name: 'BonificationModal',
    components: {
        Bonification,
        'b-modal': BModal
    }
})
export default class BonificationModal extends Vue {
    @Prop({ default: {}, required: true }) regles!: any
    @Prop({ default: false, type: Boolean, required: false }) disabled: any
    @Prop({ default: false, type: Boolean, required: false }) apercu: any
    modal = false
    datas: any = {}
    title = ''
    bonus = 0

    /**
     * created
     * @description Initialise les informations à afficher
     * @return {void}
     */
    mounted(): void {
        this.updateDatas()
    }

    /**
     * updateDatas
     * @description Met à jour les données
     * @return {void}
     */
    @Watch('regles', { immediate: true, deep: true })
    updateDatas(): void {
        this.datas = JSON.parse(JSON.stringify(this.regles))
        this.checkProperties(this.datas)
    }

    /**
     * onReglesChange
     * @description Calcul le bonus à chaque changement des règles
     * @return {void}
     */
    @Watch('datas', { immediate: true })
    updateInfos(): void {
        this.title = titleChildCritere(this.datas)
        this.bonus = countBonus(this.datas)
    }

    /**
     * toggleModal
     * @description Ouvre ou ferme la modale
     * @return {void}
     */
    toggleModal(): void {
        if (!this.disabled) {
            if (this.modal) {
                this.updateDatas()
            }
            this.modal = !this.modal
        }
    }

    /**
     * checkProperties
     * @description Vérifie si les propriétés sont bien définies
     * @param {Object} object - Objet à vérifier
     * @return {void}
     */
    checkProperties(object: any): void {
        // Propriétés à vérifier
        const properties = ['propCandidat', 'valProp', 'valBonus', 'criteres', 'operator', '_type']
        // Valeur par défaut des propriétés
        const getDefaultValue = (prop: string): any => {
            switch (prop) {
                case '_type':
                    if (object.propCandidat) {
                        return TypeBonification.TYPE_VARIABLE
                    } else if (!isEmpty(object.criteres)) {
                        return TypeBonification.TYPE_CRITERES
                    }
                    return 0
                case 'valBonus':
                    return 0
                case 'criteres':
                    return []
                default:
                    return ''
            }
        }

        properties.forEach((prop) => {
            if (object[prop] === undefined || object[prop] === null) {
                Vue.set(object, prop, getDefaultValue(prop))
            }
        })

        if (object.criteres && object.criteres.length > 0) {
            object.criteres.forEach((critere: any) => this.checkProperties(critere))
        }
    }

    /**
     * submitForm
     * @description Valide le formulaire
     * @return {void}
     */
    submitForm(): void {
        // Vérification des champs requis
        const checkRequired = (noeuds: NodeList): void => {
            // Ouverture les details ayant des champs requis non remplis
            const openParentDetails = (noeud: any): void => {
                const parentDetails = noeud.closest('details:not([open])')
                if (parentDetails && !parentDetails.open) {
                    parentDetails.open = true
                    openParentDetails(parentDetails)
                }
            }

            noeuds.forEach((noeud: any) => {
                if (!noeud.value) {
                    openParentDetails(noeud)
                }
            })
        }

        checkRequired(document.querySelectorAll('#bonification input[required]'))
        checkRequired(document.querySelectorAll('#bonification select[required]'))

        // Soumission du formulaire pour validation / faire apparaitre les erreurs
        const submit_button = document.getElementById('submit-form-bonification')
        if (submit_button) {
            submit_button.click()
        }
    }

    /**
     * saveBonification
     * @description Nettoye et sauvegarde les règles de bonification
     * @return {void}
     */
    saveBonification(): void {
        // Suppression des propriétés inutiles
        const cleanRegles = (data: any): void => {
            if (data.valBonus === 0) {
                delete data.valBonus
            }

            if (data._type === TypeBonification.TYPE_VARIABLE) {
                delete data.criteres
            } else if (data._type === TypeBonification.TYPE_CRITERES) {
                delete data.propCandidat
                delete data.valProp
            }
            delete data._type

            if (data.criteres) {
                if (data.criteres.length > 0) {
                    data.criteres.forEach((_data: any) => {
                        cleanRegles(_data)
                    })
                } else {
                    cleanRegles(data.criteres)
                }
            }
        }

        cleanRegles(this.datas)
        this.$emit('save', this.datas)
        this.modal = false
        this.checkProperties(this.datas)
    }
}
