






















import Formulaire from '@exatech-group/formulaire/src/Formulaire.vue'
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { StatusEtab } from '@/types/BourseEmploi';

@Component({
    name: 'FichePosteComponent',
    components: {
        Formulaire
    },
    computed: {
        ...mapGetters('auth', ['authUser']),
        ...mapGetters('jobDescriptionForm', ['jobDescriptionFormSelect'])
    },
    props: {
        jobDescription: {
            type: Object,
            required: true
        },
        edit: {
            type: Boolean,
            required: false,
            default: false
        },
        validate: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    watch: {
        jobDescription: {
            handler: 'updateDOM',
            deep: true
        },
        validate: {
            handler: 'updateDOM'
        }
    }
})

export default class FichePoste extends Vue {
    /**
     * Cette fonction récupère un document à partir de son identifiant
     * @param {any} document_id - Identifiant du document à récupérer
     * @returns {Promise<any>} Une promesse résolue avec le document récupéré
     */
    getDocument(document_uuid: any): Promise<any> {
        return new Promise((resolve) => {
            this.$store
                .dispatch('jobDescription/getJobDocument', {
                    job_id: this.$props.jobDescription.id,
                    document_uuid: document_uuid
                })
                .then((response: any) => resolve(response))
        })
    }

    /**
     * getDocumentReference
     * Cette fonction récupère un document référence à partir de son identifiant.
     * @param {any} documentId - Identifiant du document à récupérer
     * @returns une promesse résolue avec le document récupéré
     */
    getDocumentReference(documentId: any) {
        return new Promise((resolve) => {
            this.$store
                .dispatch('jobDescriptionForm/getDocumentReference', {
                    job_id: this.$props.jobDescription.id,
                    documentId: documentId
                })
                .then((response: any) => resolve(response))
        })
    }

    /**
     * Ajoute un document au formulaire
     * @param {string} titre - Titre du document
     * @param {any} data - Données du document à stocker
     * @returns {Promise<any>} Une promesse résolue avec l'id du document stocké
     */
    async storeDocument(titre: string, data: any): Promise<any> {
        const formdata = new FormData()
        formdata.set('document', data)
        formdata.set('name', titre)

        const response = await this.$store.dispatch('jobDescription/addJobDocument', {
            document: formdata,
            job_id: this.$props.jobDescription.id
        })
        return Promise.resolve({ id: response.data.data.uuid })
    }

    /**
     * Supprime un document du formulaire
     * @param {any} document_uuid - Identifiant du document à supprimer
     * @returns {Promise<any>} Une promesse résolue avec la réponse de la suppression du document
     */
    async deleteDocument(document_uuid: any): Promise<any> {
        return Promise.resolve(
            await this.$store.dispatch('jobDescription/deleteJobDocument', {
                job_id: this.$props.jobDescription.id,
                document_uuid: document_uuid
            })
        )
    }

    /**
     * updateDOM
     * Cette fonction met à jour le DOM du formulaire.
     */
    updateDOM(): void {
        if (this.$props.jobDescription?.workingEstablishment?.status === StatusEtab.PUBLIC) {
            const nameInput: HTMLInputElement | null = document.querySelector('.partieFormulaire input#it_1')
            if (nameInput) {
                nameInput.value = 'Praticien(ne) associé(e)'
                nameInput.disabled = true
                nameInput.style.border = '1px solid #ced4da'
            }
        }

        const inputsFiles: NodeListOf<HTMLElement> = document.querySelectorAll('.partieFormulaire .inputFile')
        if (inputsFiles) {
            inputsFiles.forEach((input) => {
                if (this.$props.jobDescription?.id) {
                    input.style.pointerEvents = 'all'
                    input.style.opacity = '1'
                    input.style.filter = 'saturate(1)'
                    input.style.cursor = 'auto'
                } else {
                    input.style.pointerEvents = 'none'
                    input.style.opacity = '0.5'
                    input.style.filter = 'saturate(0)'
                    input.style.cursor = 'not-allowed'
                }
            })
        }
    }

    /**
     * updateFormulaire
     * Cette fonction met à jour le formulaire avec les valeurs fournies.
     * @param {any} value - Nouvelle valeur du formulaire
     */
    updateFormulaire(value: any) {
        this.updateDOM()
        this.$emit('update', JSON.parse(value))
    }

    async mounted(): Promise<void> {
        this.$emit('loading', true)
        if (this.$store.getters['jobDescriptionForm/jobDescriptionForms'].length === 0) {
            await this.$store.dispatch('jobDescriptionForm/getJobDescriptionForms')
        }
        this.$store.commit('jobDescriptionForm/SET_JOB_DESCRIPTION_FORM_SELECT', this.$store.getters['jobDescriptionForm/jobDescriptionForms'][0])
        this.$emit('loading', false)
    }
}
