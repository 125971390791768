









































import { Vue, Component } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { Ability } from '@/types/Ability'
import PopupSubmitInformations from '@/components/Centre/Validations/PopupSubmitInformations.vue'
import PopupValideInformations from '@/components/Centre/Validations/PopupValideInformations.vue'
import PopupRejetInformations from '@/components/Centre/Validations/PopupRejetInformations.vue'
import AdressesCentre from '@/components/Centre/AdressesCentre.vue'
import MessageCandidatsCentre from '@/components/Centre/MessageCandidatsCentre.vue'
import { formatDate, isObject } from '@/utils/helpers'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import InformationCentre from '@/components/Centre/InformationCentre.vue'

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapState('centre', ['centreSelect', 'loading', 'save_in_progress']),
        ...mapGetters('centre', ['informations_is_submit', 'informations_is_validate']),
        ...mapState('organisationecrit', ['new_id', 'error'])
    },
    components: {
        AdressesCentre,
        MessageCandidatsCentre,
        PopupSubmitInformations,
        PopupValideInformations,
        PopupRejetInformations,
        ErrorDisplay,
        InformationCentre
    }
})
export default class GestionInformations extends Vue {
    Ability = Ability
    selectComposant = 'comp_adresses'
    showPopupValidationInformations = false
    showPopupRejetInformations = false
    showPopupSubmitInformations = false
    invalideInformations = false

    /** Check le type du retour d'erreur */
    isObject () {
        return isObject(this.$store.state.organisationecrit.error)
    }

    /** Ouvre le popup de confirmation de soumission des adresses et du message aux candidats */
    submitInformations () {
        this.showPopupSubmitInformations = true
    }

    /** Message de confirmation de validation des informations message candidat et adresses */
    validerInformations () {
        this.showPopupValidationInformations = true
    }

    /** Message de confirmation d'invalidation des informations message candidat et adresses */
    invaliderInformations () {
        this.invalideInformations = true
        this.showPopupValidationInformations = true
    }

    /** Message de confirmation de rejet de la saisie du gestionnaire pour les informations */
    rejeterInformations () {
        this.showPopupRejetInformations = true
    }

    /** Reset showPopups */
    resetPopup () {
        this.showPopupSubmitInformations = false
        this.showPopupValidationInformations = false
        this.showPopupRejetInformations = false
        this.invalideInformations = false
        this.$store.state.centre.error = null
    }

    /** Formattage les dates */
    formatteDate (date: Date) {
        return formatDate(date)
    }

    /** Retourne une classe css selon l'état de validation des infos */
    getClassBarreBoutons () {
        let class_css = 'barre_default'

        if (this.$store.getters['centre/informations_is_submit'] && !this.$store.getters['centre/informations_is_validate']) {
            class_css = 'barre_soumis'
        } else if (this.$store.getters['centre/informations_is_submit'] && this.$store.getters['centre/informations_is_validate']) {
            class_css = 'barre_valide'
        } else if (this.$store.getters['centre/has_commentaire_rejet_informations']) {
            class_css = 'barre_rejet'
        }

        return class_css
    }
}

