



















import { Vue, Component, Prop }  from 'vue-property-decorator'
import { FontAwesomeIcon }              from '@fortawesome/vue-fontawesome'
import {  formatDateVariante } from '@/utils/helpers'
import _ from 'lodash'
import { getJobDescriptionStatus } from '@/types/JobDescription'

/**
 * Composant relatif à n bloc de commentaire pour l'édition des candidats
 */
@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon
    }
})
export default class ActivityLog extends Vue {
    @Prop() entity: any
    @Prop() historique: any
    errorKeeper = null

    formatDateVariante = formatDateVariante

    /**
    * Fonction getStringHistory
    * Cette fonction génère un objet historique en fonction du type d'action effectuée (création, modification, suppression)
    * @param data: tout objet contenant des informations sur l'action effectuée
    * @returns un tableau contenant l'historique généré
    */
    getStringHistory(data: any) {
        const retour: any = []
        let content = ''
        switch(data.description) {
            case 'deleted':
                content = ''
                for(const prop in data.properties.old) {
                    if(prop !== 'working_establishment_id' && prop !== 'published_at' && prop !== 'provided_at' && prop !== 'session_id') {
                        if(prop === 'status') {
                            content += this.propToLibelle(prop) + '  : "' + getJobDescriptionStatus(data.properties.old[prop]).name+ '"\n'
                        } else {
                            content += this.propToLibelle(prop) + '  : "' + data.properties.old[prop] + '"\n'
                        }
                    }
                }
                retour.push({
                    libelle: 'Suppression '+this.entityToLibelle(this.entity)+ ' ' +  (data.subject && data.subject.name ? data.subject.name : data.subject && data.subject.jobDescription ? data.subject.jobDescription.name : (data.properties && data.properties.old && data.properties.old['name'] ? data.properties.old['name'] : '* Supprimé *')),
                    more: content
                })
                break
            case 'created':
                retour.push({
                    libelle: 'Création '+this.entityToLibelle(this.entity)+' ' + (data.subject && data.subject.candidat ? `${data.subject.candidat.name} ${data.subject.candidat.first_name} / ` : '') + (data.subject && data.subject.name ? data.subject.name : data.subject && data.subject.jobDescription ? data.subject.jobDescription.name : (data.properties && data.properties.old && data.properties.old['name'] ? data.properties.old['name'] : '* Supprimé *')),
                    more: ''
                })
                break
            case'updated':
                content = ''
                for(const prop in data.properties.old) {
                    if(prop !== 'working_establishment_id' && prop !== 'published_at' && prop !== 'provided_at' && prop !== 'session_id') {
                        if(prop === 'status') {
                            content += this.propToLibelle(prop) + '  : "' + getJobDescriptionStatus(data.properties.old[prop]).name + '" devient "' +  getJobDescriptionStatus(data.properties.attributes[prop]).name +'"\n'
                        } else {
                            content += this.propToLibelle(prop) + '  : "' + data.properties.old[prop] + '" devient "' +  data.properties.attributes[prop] +'"\n'
                        }
                    }
                }
                retour.push({
                    libelle: 'Modification '+this.entityToLibelle(this.entity)+' ' + (data.subject && data.subject.candidat ? `${data.subject.candidat.name} ${data.subject.candidat.first_name} / ` : '') +  (data.subject && data.subject.name ? data.subject.name : data.subject && data.subject.jobDescription ? data.subject.jobDescription.name : (data.properties && data.properties.old && data.properties.old['name'] ? data.properties.old['name'] : '* Supprimé *')),
                    more: content
                })
                break
        }

        return retour
    }

    /**
    * Fonction propToLibelle
    * Cette fonction retourne le libellé correspondant à une propriété donnée
    * @param prop: la propriété pour laquelle on souhaite obtenir le libellé
    * @returns le libellé correspondant à la propriété, ou la propriété elle-même si aucun libellé n'est défini
    */
    propToLibelle (prop: string) {
        switch(prop) {
            case 'name':
                return 'Libellé'
            case 'ville':
                return 'Ville'
            case 'adress':
                return 'Adresse'
            case 'code_postal':
                return'Code postal'
            case 'num_affiliation':
                return 'Numéro d\'affiliation'

            case 'missions':
                return 'Missions'
            case 'required_profile':
                return 'Profil recherché'
            case 'description':
                return 'Description'
            case 'contact':
                return 'Informations de contact'
            case 'status':
                return 'Status'


            default:
                return prop
        }
    }

    /**
    * Fonction entityToLibelle
    * Cette fonction retourne le libellé correspondant à une entité donnée
    * @param prop: l'entité pour laquelle on souhaite obtenir le libellé
    * @returns le libellé correspondant à l'entité, ou l'entité elle-même si aucun libellé n'est défini
    */
    entityToLibelle (prop: string) {
        switch(prop) {
            case 'workingestablishment':
                return 'de l\'établissement'
            case 'jobdescription':
                return 'du poste'
            case 'candidatjobdescription':
                return 'de la candidature'

            default:
                return prop
        }
    }

    /**
     * Retourne la liste passée en paramètre ordonnée par created_at descendant
     * @param {any} liste - Liste à ordonner
     * @returns {any}
     */
    orderedListeByCreatedDateDesc(liste: any): any {
        return _.orderBy(liste, 'created_at', 'desc')
    }
}
