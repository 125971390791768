




















import { mapGetters, mapState } from 'vuex'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { isObject } from '@/utils/helpers'
import ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    computed: {
        ...mapState('centre', ['centre_users_select', 'error', 'centreSelect']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay
    }
})

export default class PopupRejetInformations extends Vue {
    @Prop() showPopup?: boolean

    show = false
    message_rejet = ''
    messageErreurSimple = ''
    commentaire = ''

    @Watch('showPopup')
    initPopup () {
        this.show = this.$props.showPopup
    }

    /** Check le type du retour d'erreur */
    isObject () {
        return isObject(this.$store.state.centre.error)
    }

    /** Annulation du rejet */
    cancelRejet () {
        this.show = false
        this.$emit('reset')
    }

    /** Confirmation du rejet */
    confirmerRejet () {
        this.$store.dispatch('centre/manageInformations', { params: { message_adresses_submit: false, message_adresses_validate: null, commentaire_rejet: this.commentaire } }).then(() => {
            this.$store.dispatch('centre/getCentre', { centre_id: this.$store.getters['centre/centreSelect'].id }).then(() => {
                this.messageErreurSimple = ''
                this.message_rejet = ''
                this.show = false
                this.$emit('reset')
            })
        })
    }
}
