




















































import { BModal } from 'bootstrap-vue'
import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { EtatTraitementDossier, TypeDossier, getEtatTraitementDossier } from '../../../types/Candidat'
import { isObject } from '../../../utils/helpers'

/**
 * Composant relatif à l'import de candidats et aux dossiers de handicaps
 */
@Component({
    name: 'PopupImportCandidatComponent',
    components: {
        BModal
    },
    computed: {
        ...mapState('candidat', ['liste_dossiers', 'loading_import'])
    },
    props: {
        showEdit: {
            type: Boolean,
            required: false
        }
    },
    watch: {
        showEdit: {
            handler: 'showNoShow'
        }
    }
})

export default class PopupImportCandidat extends Vue {
    showModal = false as boolean
    listeImportCandidatsJobs = [] as Array<any>
    selectedDossier = null
    getEtatTraitementDossier = getEtatTraitementDossier
    etatTraitementDossier = EtatTraitementDossier
    showErrorDossier = false
    dossierError = null
    lockActionsButtons = false
    isObject = isObject
    file = null as any
    messagesErreur = [] as Array<string>
    importEnCours = false
    currentTimeoutID = -1
    currentTimeoutIDArray = [] as Array<any>

    /**
     * Affichage ou non de la modale
     */
    showNoShow() {
        this.showModal = this.$props.showEdit

        // Appel des listes
        this.loadListesDossiers()
    }

    /**
     * changement de fichier ?
     */
    fileChange(event: any) {
        this.file = event.target.files[0]
    }

    /**
     * Envoie un fichier au back
     */
    envoiFichier() {
        // Création du toaster en cours
        this.messagesErreur = []
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Envoi en cours ...', infosToaster)
        this.importEnCours = true

        // Appel de la fonction pour importer les etabs

        this.$store.dispatch('candidatJob/importCandidatJobs', this.file)
            .then(() => {
                this.importEnCours = false

                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Fichier déposé avec succès !', succesToaster)
                this.$store.dispatch('candidat/getDossiers', { type: TypeDossier.TYPE_IMPORT_CANDIDATS_JOB }).then(() => {
                    this.listeImportCandidatsJobs = this.$store.state.candidat.liste_dossiers
                })
            })
            .catch((error) => {
                this.importEnCours = false
                console.log('ko:' + error)

                // Création du message d'erreurs
                if (error.response && error.response.data && error.response.data.errors) {
                    for (const err in error.response.data.errors) {
                        if (error.response.data.errors[err]) {
                            if (error.response.data.errors[err].row) {
                                const retourError = 'erreur ligne' + error.response.data.errors[err].row + ' : ' + error.response.data.errors[err].errors
                                this.messagesErreur.push(retourError)
                            }
                        }
                    }
                }
                // Toaster it's a fail !
                const idError = 't_error_' + Math.random()
                const errorToaster = {
                    id: idError,
                    toaster: 'b-toaster-top-right',
                    variant: 'danger',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast("Une erreur s'est produite lors de l'import", errorToaster)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * Fermeture de la modale
     */
    closeModal() {
        this.showErrorDossier = false
        this.dossierError = null
        this.$emit('reinitShow', null)
    }

    /** Chargement des listes des dossiers */
    loadListesDossiers() {
        this.$store.dispatch('candidat/getDossiers', { type: TypeDossier.TYPE_IMPORT_CANDIDATS_JOB }).then(() => {
            this.listeImportCandidatsJobs = this.$store.state.candidat.liste_dossiers
        })
        this.currentTimeoutID = setTimeout(() => {
            if (this.showModal) {
                this.currentTimeoutIDArray.push(this.currentTimeoutID)
                this.loadListesDossiers()
            } else {
                this.reinitTimeOut()
            }
        }, 5000)
    }

    /** Affiche les erreurs d'un fichier */
    showErrorsDossier(dossier: any) {
        this.dossierError = dossier
        this.showErrorDossier = true
    }

    /** Ferme la visualisation des errors */
    closeErrorDossiers() {
        this.dossierError = null
        this.showErrorDossier = false
    }

    reinitTimeOut() {
        for (const idTimeout in this.currentTimeoutIDArray) {
            clearTimeout(parseInt(this.currentTimeoutIDArray[idTimeout]))
        }
        if (this.currentTimeoutID !== -1) {
            clearTimeout(this.currentTimeoutID)
        }
        this.currentTimeoutIDArray = []
        this.currentTimeoutID = -1
    }


    beforeUnmount() {
        this.reinitTimeOut()
    }

    mounted() {
        window.onpopstate = () => {
            this.reinitTimeOut()
        }
        window.addEventListener('popstate', () => {
            this.reinitTimeOut()
        })
        this.reinitTimeOut()
    }
}
