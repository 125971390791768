




















































































































































import { getListeTypesMesure, TypeMesure } from '@/types/Amenagement'
import { Prop, Vue, Component } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { codeRestrict } from '@/utils/helpers'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { TypeAmenagement } from '@/types/Salle'
@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay
    },
    computed: {
        ...mapState('amenagement', ['error']),
        ...mapGetters('tempsSupplementaire', ['loading', 'error', 'tempsSupplementaires', 'meta', 'links', 'totalRows']),
        ...mapGetters('domaine', ['domaines'])

    }
})
export default class EditMesureHandicaps extends Vue {
    codeRestrict = codeRestrict
    @Prop() amenagement?: any;
    @Prop() type?: string
    @Prop() fromCandidat?: boolean

    modeAmenagementEcrit = false
    TypeAmenagement = TypeAmenagement

    sujetA3 = false
    gestionsParticulieres: Array<{id: number; code: string; name: string; selected: boolean}> = []

    getListeTypesMesure = getListeTypesMesure

    TypeMesure = TypeMesure

    selected_domaines_ids: Array<number> = []


    gestionParticuliereChangeHandler () {
        const gpIds: Array <number> = []
        for (let i = 0; i < this.gestionsParticulieres.length; i++) {
            if (this.gestionsParticulieres[i].selected) {
                gpIds.push(this.gestionsParticulieres[i].id)
            }
        }
        if (this.amenagement) {
            this.amenagement.gestion_particulieres = gpIds
        }
    }

    domaineCheckChangeHandler(domaine: any) {
        if (this.selected_domaines_ids.includes(domaine.id)) {
            const indexSelectedId = this.selected_domaines_ids.indexOf(domaine.id)
            if (indexSelectedId !== -1)
            {
                this.selected_domaines_ids.splice(indexSelectedId, 1)
            }
        } else {
            this.selected_domaines_ids.push(domaine.id)
        }
        this.amenagement.selected_domaines_ids = this.selected_domaines_ids
    }

    validateForm () {
        this.$store.state.amenagement.error = null
        this.gestionParticuliereChangeHandler()
        this.$emit('validateForm', null)
    }

    cancelEdit () {
        this.$store.state.amenagement.error = null
        this.$emit('reinitShow', null)
    }

    typeAmenagementChangeHandler () {
        this.modeAmenagementEcrit = this.amenagement.type === TypeMesure.TYPE_ECRIT;
    }

    loadData () {
        this.$store.dispatch('tempsSupplementaire/getTempsSupplementaires').then(() => {
            this.gestionsParticulieres = []
            this.$store.dispatch('gestionParticuliere/getGestionParticulieres').then(() => {
                for (let i = 0; i < this.$store.state.gestionParticuliere.gestionParticulieres.length; i++) {
                    let selected = false
                    if (this.amenagement && this.amenagement.gestion_particulieres && this.amenagement.gestion_particulieres.length > 0) {
                        for (let j = 0; j < this.amenagement.gestion_particulieres.length; j++) {
                            if (this.amenagement.gestion_particulieres[j].id === this.$store.state.gestionParticuliere.gestionParticulieres[i].id) {
                                selected = true
                            }
                        }
                    }
                    this.gestionsParticulieres.push({
                        id: this.$store.state.gestionParticuliere.gestionParticulieres[i].id,
                        code: this.$store.state.gestionParticuliere.gestionParticulieres[i].code,
                        name: this.$store.state.gestionParticuliere.gestionParticulieres[i].name,
                        selected: selected
                    })
                }
                this.gestionParticuliereChangeHandler()
            })
        })
    }


    mounted () {
        if (this.type && this.type !== '') {
            if (this.type === 'ecrit') {
                this.amenagement.type = TypeMesure.TYPE_ECRIT
            } else if (this.type === 'oral') {
                this.amenagement.type = TypeMesure.TYPE_ORAL
            }
        }
        if (this.fromCandidat) {
            this.$store.dispatch('domaine/getDomaines').then(() => {
                for (let i = 0; i < this.$store.state.domaine.domaines.length; i++) {
                    this.selected_domaines_ids.push(this.$store.state.domaine.domaines[i].id)
                }
                this.amenagement.selected_domaines_ids = this.selected_domaines_ids
                this.loadData()
            })
        } else {
            this.loadData()
        }
    }
}
