


























































import { Vue, Component } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import AdressesCentre from '@/components/Centre/AdressesCentre.vue'
import { formatDate } from '@/utils/helpers'
import ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    components: {
        AdressesCentre,
        ErrorDisplay
    },
    computed: {
        ...mapGetters('auth', ['authUser']),
        ...mapGetters('centre', ['error', 'centreSelect', 'loading']),
        ...mapState('centre', ['centreSelect'])
    }
})
export default class Adresses extends Vue {
    mounted () {
        if (!this.$store.state.centre.centreSelect.id) {
            this.$store.dispatch('centre/getCentre', { centre_id: this.$route.params.centre_id }).then(() => {
                this.initInterface()
            })
        } else {
            this.initInterface()
        }
    }

    lockInput = false
    showModalConfirmationSoumettre = false
    message_rejet = ''

    // Formatte les dates
    formatteDate (date: Date) {
        return formatDate(date)
    }

    initInterface () {
        this.getMessageRejetMessage()
        if (this.$store.state.centre.centreSelect.adresses_submitted_at) {
            this.lockInput = true
        } else {
            this.lockInput = false
        }
    }

    // Retourne l'éventuel message de rejet des adresses
    getMessageRejetMessage () {
        if (this.$store.state.centre.centreSelect.commentaire_rejet) {
            if (this.$store.state.centre.centreSelect.commentaire_rejet.adresses) {
                this.message_rejet = this.$store.state.centre.centreSelect.commentaire_rejet.adresses
            }
        }
    }

    openModalConfirmationSoumettre () {
        this.showModalConfirmationSoumettre = true
    }

    cancelSoumettre () {
        this.showModalConfirmationSoumettre = false
    }

    confirmerSoumettre () {
        this.submitAdressesCentre()
    }

    // Enregistrement des données ET soumission du contenu du message
    submitAdressesCentre () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        const payload = {
            id: this.$store.state.centre.centreSelect.id,
            adresses_submit: 1
        }

        this.$store.dispatch('centre/updateCentre', payload)
            .then(() => {
                this.message_rejet = ''
                this.lockInput = true

                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Adresses du centre soumises avec succès !', succesToaster)
                this.initInterface()
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.showModalConfirmationSoumettre = false
            })
    }
}
