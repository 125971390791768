
































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import _ from 'lodash'
import { ConcourInterface } from '@/types/Concour'
import { BanqueInterface } from '@/types/Banque'
import { mapGetters } from 'vuex'

@Component({
    computed: {
        ...mapGetters('groupeComparaisonConcour', ['groupeComparaisonConcours', 'loading', 'totalRows', 'lastPage', 'totalPage', 'error', 'meta']),
        ...mapGetters('banque', ['getBanqueById']),
        // Liste des banques
        liste_banques(): BanqueInterface[] {
            return _.orderBy(this.$store.getters['banque/banques'], 'name', 'asc')
        },
        // Liste des concours en fonction de la banque sélectionnée
        liste_concours(): ConcourInterface[] {
            return _.orderBy(
                this.$store.getters['concour/concours'].filter((concour: any) => {
                    return concour.banque_id === this.$data.select_banque
                }),
                'name',
                'asc'
            )
        }
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay
    }
})
export default class Comparaisons extends Vue {
    @Prop() showPopup?: boolean

    edit_groupe = false
    select_banque = 0
    select_concour = 0

    groupTemp = null
    showModalMessageDeleteGroup = false

    groupe_edit: {id: number; libelle: string; concours: Array<{banque: BanqueInterface; concour: ConcourInterface}>} = {
        id: 0,
        libelle: '',
        concours: []
    }

    /**
     * resetGroupe
     * @description Réinitialise le formulaire d'ajout de groupe de comparaisons
     * @return {void}
     */
    @Watch('edit_groupe')
    resetGroupe(): void {
        if (!this.edit_groupe) {
            this.select_banque = 0
            this.select_concour = 0

            this.groupe_edit = {
                id: 0,
                libelle: '',
                concours: []
            }
        }
    }

    /**
     * resetSelectConcour
     * @description Réinitialise la sélection d'un concours
     * @return {void}
     */
    @Watch('select_banque')
    resetSelectConcour(): void {
        this.select_concour = 0
    }

    /**
     * alreadyInList
     * @description Vérifie si un concours est déjà dans la liste
     * @param {number} concour_id - Id du concours à vérifier
     * @return {boolean}
     */
    alreadyInList(concour_id: number = this.select_concour): boolean {
        return !!this.groupe_edit.concours.find((row: any) => row.concour.id === concour_id)
    }

    /**
     * addConcour
     * @description Ajoute un concours à la liste des concours à comparer
     * @return {void}
     */
    addConcour(): void {
        const select = document.getElementById('select-banque') as HTMLSelectElement
        select.setCustomValidity('')
        select.reportValidity()

        if (this.select_banque && this.select_concour && !this.alreadyInList()) {
            this.groupe_edit.concours.push({
                banque: this.$store.getters['banque/banques']
                    .find((banque: any): boolean => banque.id === this.select_banque),
                concour: this.$store.getters['concour/concours']
                    .find((concour: any): boolean => concour.id === this.select_concour)
            })
        }
    }

    /**
     * removeConcour
     * @description Supprime un concours de la liste des concours à comparer
     * @param {number} concour_id - Id du concours à supprimer
     * @return {void}
     */
    removeConcour(concour_id: number): void {
        this.groupe_edit.concours = this.groupe_edit.concours.filter((row: any) => row.concour.id !== concour_id)
    }

    /** 
    * convertConcourToBanqueConcour 
    * Cette fonction convertit un tableau de concours en un objet regroupant les concours par banque. 
    * @param concours: tableau des concours à convertir 
    */
    convertConcourToBanqueConcour(concours: any) {
        const retour: any = {}
        for(let i = 0; i < concours.length; i++) {
            if(!retour[concours[i].banque_id]){
                retour[concours[i].banque_id] = []
            }
            retour[concours[i].banque_id].push(concours[i])
        }
        return retour
    }

    // -----------edit groupe

    /**
    * update_groupe
    * Cette fonction met à jour les informations d'un groupe de comparaison pour l'édition.
    * @param groupe_id: identifiant du groupe à mettre à jour
    */
    update_groupe(groupe_id: number) {
        const groupTemp = this.$store.state.groupeComparaisonConcour.groupeComparaisonConcours.find((g: any) => g.id === groupe_id)
        this.groupe_edit.id = groupTemp.id
        this.groupe_edit.libelle = groupTemp.name
        this.groupe_edit.concours = []
        for(let i = 0; i < groupTemp.concours.length; i++) {
            this.groupe_edit.concours.push({
                banque: this.$store.getters['banque/getBanqueById'](groupTemp.concours[i].banque_id),
                concour:groupTemp.concours[i]
            })
        }
        this.edit_groupe = true
    }

    // ----------------------------------------

    // ---------- delete groupe

    /** 
    * delete_groupe 
    * Cette fonction supprime un groupe de comparaison en affichant un message de confirmation. 
    * @param groupe_id: identifiant du groupe à supprimer 
    */
    delete_groupe(groupe_id: number) {
        this.groupTemp = this.$store.state.groupeComparaisonConcour.groupeComparaisonConcours.find((g: any) => g.id === groupe_id)
        this.showModalMessageDeleteGroup = true
    }

    /** 
    * cancelDeleteGroup 
    * Cette fonction annule la suppression d'un groupe de comparaison en réinitialisant les variables temporaires. 
    */
    cancelDeleteGroup () {
        this.groupTemp = null
        this.showModalMessageDeleteGroup = false
    }

    /** 
    * deleteGroupSuite 
    * Cette fonction gère la suppression d'un groupe de comparaison en affichant des messages de confirmation. 
    * @param id: identifiant du groupe à supprimer 
    */
    deleteGroupSuite (id: number) {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
    
        const payload = {
            id:id
        }
        this.$bvToast.toast('Supression en cours ...', infosToaster)
        this.$store.dispatch('groupeComparaisonConcour/deleteGroupeComparaisonConcour', payload)
            .then(() => {    
                this.$store.dispatch('groupeComparaisonConcour/getGroupeComparaisonConcours', payload).then(() => {
                    this.cancelDeleteGroup()
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Suppression terminée.', succesToaster)
                })
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })

    }

    //-------------------------------

    /**
     * save
     * @description Enregistre le groupe de comparaisons
     * @return {void}
     */
    save(): void {
        if (this.groupe_edit.concours.length === 0) {
            const select = document.getElementById('select-banque') as HTMLSelectElement
            select.setCustomValidity('Veuillez ajouter au moins un concours à comparer.')
            select.reportValidity()
            return
        }

        const params: any = {
            id: this.groupe_edit.id,
            name: this.groupe_edit.libelle,
            concour_ids: []
        }

        for (let i = 0; i < this.groupe_edit.concours.length; i++) {
            params.concour_ids.push(this.groupe_edit.concours[i].concour.id)
        }

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        let dispatch = 'groupeComparaisonConcour/addGroupeComparaisonConcour'
        if(params.id !== 0) {
            dispatch = 'groupeComparaisonConcour/updateGroupeComparaisonConcour'
        }
        this.$store
            .dispatch(dispatch, params)
            .then(() => {
                this.$store.dispatch('groupeComparaisonConcour/getGroupeComparaisonConcours').then(() => {
                    this.$bvToast.hide(idInfo)
                    this.edit_groupe = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminée.', succesToaster)
                })
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
    }

    /**
     * cancel
     * @description Ferme la popup
     * @return {void}
     */
    cancel(): void {
        this.$emit('cancel')
    }

    /**
     * mounted
     * @description Chargement des banques et des concours
     * @return {Promise<void>}
     */
    async mounted(): Promise<void> {
        if (!this.$store.getters['banque/banques']) {
            await this.$store.dispatch('banque/getBanques', { perPage: 0 })
        }

        if (!this.$store.getters['concour/concours']) {
            await this.$store.dispatch('concour/getConcours', { perPage: 0 })
        }
        this.$store.dispatch('groupeComparaisonConcour/getGroupeComparaisonConcours').catch((error: any) => {
            console.log('error', error)
        })
    }
}
