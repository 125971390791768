













































































































































































































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import {
    CandidatInterface,
    Civilite, EtatDossierCandidat, getEtatDossier,
    getLibelleCivilite,
    getMentionBac,
    MentionBAC,
    TypeDataCandidat
} from '@/types/Candidat'
import TableSelection from '@/components/TableSelection.vue'
import { Ability } from '@/types/Ability'
import ErrorDisplay from '@/components/ErrorDisplay.vue';
import PhoneInput from '@/components/Tools/PhoneInput.vue';
import { formatDate, formatDateDocument, formatDateSinTime, formatDateYYYYMMDD } from "@/utils/helpers";
import { isNull, isEmpty } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { EtablissementInterface } from "@/types/Etablissement";
import Multiselect from "vue-multiselect";
import { PaysInterface } from "@/types/Pays";
import Formulaire from "@exatech-group/formulaire/src/Formulaire.vue";
import { DossierInscription } from '@/types/DossierInscription'

@Component({
    methods: {
        getEtatDossier,
        isNull,
        isEmpty,
        formatDateSinTime,
        formatDateDocument,
        formatDateYYYYMMDD,
        formatDate,
        getLibelleCivilite,
        getMentionBac
    },
    computed: {
        TypeDataCandidat() {
            return TypeDataCandidat
        },
        Ability() {
            return Ability
        },
        civility() {
            const middle = Math.floor(Object.keys(Civilite).length / 2)
            return Object.keys(Civilite).slice(0, middle)
        },
        mentionBac() {
            const middle = Math.floor(Object.keys(MentionBAC).length / 2)
            return Object.keys(MentionBAC).slice(0, middle)
        },
        etatDossier() {
            const middle = Math.floor(Object.keys(EtatDossierCandidat).length / 2)
            return Object.keys(EtatDossierCandidat).slice(0, middle)
        },
        listePays() {
            return this.$store.getters['pays/pays']
        },
        listePaysNationalite() {
            const pays = JSON.parse(JSON.stringify(this.$store.getters['pays/pays']))
            const index = pays.findIndex((pays: any) => pays.nationalite === 'Française')
            pays.splice(index, 1)
            return pays
        },
        listeEtablissements() {
            const etablissements = JSON.parse(JSON.stringify(this.$store.getters['etablissement/etablissements']))
            if (!isEmpty(etablissements)) {
                for (let i = 0; i < etablissements.length; i++) {
                    let select_name = `${etablissements[i].name}`

                    if (etablissements[i].academie || etablissements[i].adresse || etablissements[i].code_postal || etablissements[i].ville || etablissements[i].code) {
                        select_name += ' ('
                        if (etablissements[i].academie) {
                            select_name += `Académie : ${etablissements[i].academie} - `
                        }
                        if (etablissements[i].adresse) {
                            select_name += `Adresse : ${etablissements[i].adresse} - `
                        }
                        if (etablissements[i].code_postal) {
                            select_name += `Code postal : ${etablissements[i].code_postal} - `
                        }
                        if (etablissements[i].ville) {
                            select_name += `Ville : ${etablissements[i].ville} - `
                        }
                        if (etablissements[i].code) {
                            select_name += `Code : ${etablissements[i].code} - `
                        }
                        select_name = select_name.slice(0, -3)
                        select_name += `)`
                    }

                    etablissements[i].select_name = select_name
                }
            }

            return etablissements
        },
        listeVilles() {
            return this.$store.getters['ville/villes']
        }
    },
    components: {
        PhoneInput,
        ErrorDisplay,
        TableSelection,
        FontAwesomeIcon,
        Multiselect,
        Formulaire
    }
})
export default class PopupEditCandidatSession extends Vue {
    can                                   = this.$store.getters['auth/can']
    candidat: CandidatInterface           = JSON.parse(JSON.stringify(this.$store.getters['candidat/editedCandidat']))
    edit                                  = false
    loading                               = false
    loading_edit                          = false
    departement_naissance: any            = {}
    pays_naissance: PaysInterface         = {} as PaysInterface
    pays_nationalite: PaysInterface       = {} as PaysInterface
    pays_distribution: PaysInterface      = {} as PaysInterface
    csp_pere                              = {}
    csp_mere                              = {}
    etablissement: EtablissementInterface = {} as EtablissementInterface
    souhait1                              = {}
    souhait2                              = {}
    datas_formulaire: any                 = ''
    check_validation                      = false
    validation_formulaire                 = false
    refresh                               = false

    /**
     * Actions à entreprendre lorsque l'état d'édition change
     * @param {boolean} value - Valeur de l'état d'édition
     * @returns {void}
     */
    @Watch('edit')
    async toggleEdit(value: boolean): Promise<void> {
        if (!this.can(Ability.CAND_MANAGE)) {
            this.edit = false
            return
        }

        this.candidat = JSON.parse(JSON.stringify(this.$store.getters['candidat/editedCandidat']))

        this.departement_naissance = {}
        this.pays_naissance = {} as PaysInterface
        this.pays_nationalite = {} as PaysInterface
        this.pays_distribution = {} as PaysInterface
        this.csp_pere = {}
        this.csp_mere = {}
        this.etablissement = {} as EtablissementInterface
        this.souhait1 = {}
        this.souhait2 = {}

        if (value) {
            this.loading_edit = true

            if (!isEmpty(this.candidat.dossierInscription)) {
                this.$store.commit('dossierinscription/SET_DOSSIER', {
                    data: { data: this.candidat.dossierInscription }
                })
            } else {

                if (isEmpty(this.$store.getters['pays/pays'])) {
                    await this.$store.dispatch('pays/getPays', {
                        perPage: 0
                    })
                }

                if (isEmpty(this.$store.getters['ville/villes'])) {
                    await this.$store.dispatch('ville/getVilles', {
                        perPage: 0
                    })
                }

                if (isEmpty(this.$store.getters['etablissement/etablissements'])) {
                    await this.$store.dispatch('etablissement/getEtablissements', {
                        perPage: 0
                    })
                }

                if (this.candidat.departement_naissance) {
                    this.departement_naissance =  this.candidat.departement_naissance
                }
                if (this.candidat.pays_naissance) {
                    this.pays_naissance = this.candidat.pays_naissance
                }
                if (this.candidat.pays_nationalite) {
                    this.pays_nationalite = this.candidat.pays_nationalite
                }
                if (this.candidat.pays_distribution) {
                    this.pays_distribution = this.candidat.pays_distribution
                }
                if (this.candidat.csp_pere) {
                    this.csp_pere = this.candidat.csp_pere
                }
                if (this.candidat.csp_mere) {
                    this.csp_mere = this.candidat.csp_mere
                }
                if (this.candidat.etablissement) {
                    const etablissement: EtablissementInterface = this.candidat.etablissement
                    const infos: string[] = []

                    if (etablissement.academie) {
                        infos.push(`Académie : ${etablissement.academie}`)
                    }
                    if (etablissement.adresse) {
                        infos.push(`Adresse : ${etablissement.adresse}`)
                    }
                    if (etablissement.code_postal) {
                        infos.push(`Code postal : ${etablissement.code_postal}`)
                    }
                    if (etablissement.ville) {
                        infos.push(`Ville : ${etablissement.ville}`)
                    }
                    if (etablissement.code) {
                        infos.push(`Code : ${etablissement.code}`)
                    }

                    this.etablissement = {
                        ...this.candidat.etablissement,
                        select_name: `${etablissement.name}${infos.length ? ` (${infos.join(' - ')})` : ''}`
                    }
                }
                if (this.candidat.souhait1) {
                    this.souhait1 = this.candidat.souhait1
                }
                if (this.candidat.souhait2) {
                    this.souhait2 = this.candidat.souhait2
                }
            }
        } else {
            if (!isEmpty(this.candidat.dossierInscription)) {
                this.refresh = true
                this.candidat.dossierInscription = this.$store.getters['dossierinscription/dossier']
                this.$nextTick().then(() => {
                    this.refresh = false
                })
            }
        }

        this.$nextTick(() => {
            this.loading_edit = false
            this.edit = value
            this.$emit('changeEditing', value)
        })
    }

    /**
     * Cette fonction récupère un document à partir de son identifiant
     * @param {any} document_id - Identifiant du document à récupérer
     * @returns {Promise<any>} Une promesse résolue avec le document récupéré
     */
    getDocument(document_id: any): Promise<any> {
        return new Promise((resolve) => {
            if (isEmpty(this.candidat)) {
                resolve(null)
            } else {
                this.$store
                    .dispatch('dossierinscription/getDocument', {
                        dossier_inscription_id: this.candidat.dossierInscription.id,
                        document_id: document_id
                    })
                    .then((response: any) => {
                        resolve(response)
                    })
            }
        })
    }

    /**
     * Supprime un document du formulaire
     * @param {any} document_uuid - Identifiant du document à supprimer
     * @returns {Promise<any>} Une promesse résolue avec la réponse de la suppression du document
     */
    async deleteDocument(document_uuid: any): Promise<any> {
        this.loading = true

        return Promise.resolve(
            await this.$store.dispatch('dossierinscription/deleteDocument', {
                dossier_inscription_id: this.candidat.dossierInscription.id,
                document_uuid: document_uuid
            })
        )
    }

    /**
     * Ajoute un document au formulaire
     * @param {string} titre - Titre du document
     * @param {any} data - Données du document à stocker
     * @returns {Promise<any>} Une promesse résolue avec l'id du document stocké
     */
    async storeDocument(titre: string, data: any): Promise<any> {
        this.loading = true
        const formdata = new FormData()
        formdata.set('document', data)
        formdata.set('name', titre)

        const response = await this.$store.dispatch('dossierinscription/postDocument', {
            payload: formdata,
            dossier_inscription_id: this.candidat.dossierInscription.id
        })
        return Promise.resolve({ id: response.data.data.uuid })
    }

    /**
     * Cette fonction récupère un document référence à partir de son identifiant
     * @param {any} document_id - Identifiant du document à récupérer
     * @returns {Promise<any>} Une promesse résolue avec le document récupéré
     */
    getDocumentReference(document_id: any): Promise<any> {
        return new Promise((resolve) => {
            if (isEmpty(this.candidat)) {
                resolve(null)
            } else {
                this.$store
                    .dispatch('formulaire/getDocumentReference', {
                        formulaire_id: this.candidat.dossierInscription.publication.entity_id,
                        documentId: document_id
                    })
                    .then((response: any) => {
                        resolve(response)
                    })
            }
        })
    }

    /**
     * Récupère les datas du candidat en fonction du type demandé
     * @param {TypeDataCandidat} type - Type de données à récupérer
     * @returns {any}
     */
    getDatasCandidat(type: TypeDataCandidat): any {
        return this.$store.getters['candidat/datasCandidat']
            .filter((data: any) => data.type === type)
    }

    /**
     * Récupère l'identifiant de la qualité "Boursier"
     * @returns {number}
     */
    getQualiteBoursierId():number {
        const qualite_boursier = this.getDatasCandidat(TypeDataCandidat.TYPE_BOURSIER)
            .filter((qualite: any) => qualite.name === 'Boursier')
        if (qualite_boursier.length) {
            return qualite_boursier[0].id
        }
        return 0
    }

    /**
     * Met à jour le statut de validation du formulaire
     * @param {boolean} statut - Statut de validation du formulaire
     * @returns {void}
     */
    validationHandler(statut: boolean): void {
        this.validation_formulaire = statut
    }

    /**
     * Met à jour les informations du formulaire du dossier
     * @param {string} values - Valeurs des champs du formulaire
     * @returns {void}
     */
    updateValuesFormulaire(values: any): void {
        this.datas_formulaire = typeof values === 'string' ? JSON.parse(values) : values

        // Supprime les valeurs vides des champs de type input
        if (this.datas_formulaire && this.datas_formulaire.length) {
            for (let i = 0; i < this.datas_formulaire.length; i++) {
                if (
                    this.datas_formulaire[i].type === 'input' &&
                    typeof this.datas_formulaire[i].value === 'string' &&
                    this.datas_formulaire[i].value.trim() === ''
                ) {
                    this.datas_formulaire[i].value = null
                }
            }
        }

        if (this.loading && this.edit) {
            this.loading = false
        }
    }

    /**
     * Met à jour le dossier
     * @returns {void}
     */
    updateDatasDossier(): void {
        if (this.loading || !this.can(Ability.CAND_MANAGE)) {
            return
        }
        this.loading = true

        const dossier: DossierInscription = JSON.parse(JSON.stringify(this.candidat.dossierInscription))
        if (this.datas_formulaire && JSON.stringify(dossier.datas) !== JSON.stringify(this.datas_formulaire)) {
            this.check_validation = false
            this.$nextTick(() => {
                this.check_validation = true
            })

            if (!this.validation_formulaire) {
                this.loading = false
                return
            }

            dossier.datas = this.datas_formulaire
            this.$store.dispatch('dossierinscription/putFormulaireDossierInscription', {
                dossier_inscription_id: dossier.id,
                payload: {
                    datas: dossier.datas
                }
            })
                .finally(() => {
                    this.loading = false
                    this.edit = false
                })
        } else {
            this.loading = false
            this.edit = false
        }
    }

    /**
     * Sauvegarde les informations du candidat
     * @returns {void}
     */
    saveInfos(): void {
        if (this.edit && !this.loading && this.can(Ability.CAND_MANAGE) && !isEmpty(this.candidat)) {
            this.loading = true

            const idInfo = 't_info_' + Math.random()
            this.$bvToast.toast('Enregistrement en cours ...', {
                id: idInfo,
                toaster: 'b-toaster-top-right',
                variant: 'primary',
                noCloseButton: true,
                fade: true,
                noAutoHide: true
            })

            const payload: any = {
                id: this.candidat.id,
                code: this.candidat.code,
                concour_id: this.candidat.concour_id,
                demission: this.candidat.demission,
                civility: this.candidat.civility,
                name: this.candidat.name,
                usual_name: this.candidat.usual_name,
                first_name: this.candidat.first_name,
                middle_name: this.candidat.middle_name,
                date_naissance: this.candidat.date_naissance,
                ville_naissance: this.candidat.ville_naissance,
                arrondissement_naissance: this.candidat.arrondissement_naissance,
                departement_naissance_id: this.departement_naissance.id,
                pays_naissance_id: this.candidat.pays_naissance_id,
                nationalite_fr: this.candidat.nationalite_fr,
                pays_nationalite_id: this.candidat.pays_nationalite_id,
                adresse1: this.candidat.adresse1,
                adresse2: this.candidat.adresse2,
                code_postal: this.candidat.code_postal,
                bureau_distribution: this.candidat.bureau_distribution,
                pays_distribution_id: this.candidat.pays_distribution_id,
                email: this.candidat.email,
                telephone: this.candidat.telephone,
                portable: this.candidat.portable,
                csp_mere_id: this.candidat.csp_mere_id,
                csp_pere_id: this.candidat.csp_pere_id,
                etat_dossier: this.candidat.etat_dossier,
                ine: this.candidat.ine,
                qualite_id: this.candidat.qualite_id,
                bourses_echelon: this.candidat.qualite_id == this.getQualiteBoursierId() ? this.candidat.bourses_echelon : '',
                annee_cpge: this.candidat.annee_cpge,
                first_second_year_after_bac: this.candidat.first_second_year_after_bac,
                classe: this.candidat.classe,
                souhait1_ville_id: this.candidat.souhait1_ville_id,
                souhait2_ville_id: this.candidat.souhait2_ville_id,
                etablissement_id: this.candidat.etablissement_id,
                mois_bac: this.candidat.mois_bac,
                annee_bac: this.candidat.annee_bac,
                bac_id: this.candidat.bac_id,
                mention_bac: this.candidat.mention_bac,
                serie_id: this.candidat.serie_id
            }

            if (payload.demission) {
                payload.demission_at = this.candidat.demission_at
            }

            this.$store.dispatch('candidat/updateCandidat', payload)
                .then(() => {
                    this.$bvToast.toast('Enregistrement terminé.', {
                        id: 't_succes_' + Math.random(),
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    })

                    this.edit = false
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                    this.loading = false
                })
        }
    }

    /**
     * Montage du composant
     * @returns {Promise<void>}
     */
    async mounted(): Promise<void> {
        if (isEmpty(this.$store.getters['candidat/datasCandidat'])) {
            await this.$store.dispatch('candidat/getDatasCandidat', {
                perPage: 0
            })
        }
    }
}
