














import { Vue, Component, Prop }  from 'vue-property-decorator'
import { FontAwesomeIcon }              from '@fortawesome/vue-fontawesome'
import { Ability }                      from '@/types/Ability'
import { mapGetters } from 'vuex'
import { formatDateDocument } from '@/utils/helpers'

/**
 * Composant relatif à n bloc de commentaire pour l'édition des candidats
 */
@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])

    }
})
export default class CandidatComment extends Vue {
    @Prop() currentComment?: any[]
    // canAccess recup des droits et rend l interface disabled si false
    @Prop() canAccess?: boolean

    ability = Ability

    deleteComment () {
        this.$emit('deleteComment', this.currentComment)
    }

    editComment () {
        console.log('edit com')
        this.$emit('editComment', this.currentComment)
    }

    /** formatte la date */
    formatteDate (date: Date) {
        return formatDateDocument(date)
    }
}
