




























































































































import { mapGetters, mapState } from 'vuex'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import DescriptifCentre from '@/components/Centre/DescriptifCentre.vue'
import CapaciteFilieres from '@/components/Centre/Salles/Ecrits/CapaciteFilieres.vue'
import GestionEcrits from '@/components/Centre/GestionEcrits.vue'
import GestionInformations from '@/components/Centre/GestionInformations.vue'
import { Ability } from '@/types/Ability'
import { TypePassation } from '@/types/Epreuve'
import SallesOrauxCentre from '@/components/Centre/Salles/SallesCentreOraux.vue'
import ListeContacts from '@/components/Centre/ListeContacts.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import SaisieSalles from '@/components/Centre/Salles/Ecrits/SaisieSalles.vue'
import SaisieIntervenants from '@/components/Centre/Salles/Ecrits/SaisieIntervenants.vue'

@Component({
    components: {
        DescriptifCentre,
        CapaciteFilieres,
        GestionEcrits,
        GestionInformations,
        SallesOrauxCentre,
        ListeContacts,
        ErrorDisplay,
        FontAwesomeIcon,
        SaisieSalles,
        SaisieIntervenants
    },
    computed: {
        ...mapState('centre', ['centreSelect', 'error']),
        ...mapGetters('centre', ['informations_is_submit', 'informations_is_validate', 'etat_centre_select', 'has_commentaire_rejet_ecrit', 'has_commentaire_rejet_informations']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapState('session', ['sessionSelect'])// ,
        // ...mapState('organisationecrit', ['error'])
    }

})

export default class PopupEditionCentre extends Vue {
    @Prop() showPopup: any
    @Prop() tab_index?: number
    @Prop() newCentre?: boolean

    Ability = Ability
    tabSelected = 'descriptif'
    tabIndex = 0
    show = false
    showConfirmationPublier = false
    new_centre = false
    TypePassation = TypePassation
    error_roles = 0

    @Watch('showPopup')
    initPopup () {
        this.$store.state.organisationecrit.error = null
        this.show = this.$props.showPopup
        if (this.$props.showPopup) {
            // Check si on a les concours
            if (this.$store.getters['concour/concours'] && this.$store.getters['concour/concours'].length === 0) {
                this.$store.dispatch('concour/getConcours', { isPrecedente: false })
            }

            this.initSelectionOnglet()
        }
    }

    @Watch('newCentre')
    setNewCentre () {
        this.new_centre = this.$props.newCentre
        if (this.new_centre) {
            this.tabSelected = 'descriptif'
            if (this.$store.state.ville.villes.length === 0) {
                this.$store.dispatch('ville/getVilles')
            }
        }
    }

    hasCentreSelect () {
        if (this.$store.state.centre.centreSelect) {
            this.new_centre = false
        }
    }


    /** Ferme la popup d'édition d'un centre */
    closeEdition () {
        this.new_centre = false
        this.$store.state.centre.centreSelect = {}
        this.destroyOnglet()
        this.$emit('close')
    }

    /** Ouvre le popup de confirmation de publication */
    confirmPublier () {
        this.new_centre = false
        this.$store.state.centre.error = null

        this.error_roles = 0
        // Check la présence de rôles sur les contacts du centre avant de permettre de publier
        this.$store.dispatch('centre/getCentre', { centre_id: this.$store.state.centre.centreSelect.id }).then(() => {
            for (const cu in this.$store.state.centre.centreSelect.centreusers) {
                if (this.$store.state.centre.centreSelect.centreusers[cu].roles === null) {
                    this.error_roles++
                }
            }
            this.showConfirmationPublier = true
        })
    }

    /** Annule la confirmation de publication */
    cancelConfirmPublier () {
        this.new_centre = false
        this.$store.state.centre.error = null
        this.showConfirmationPublier = false
    }

    /** Confirmer la publication du centre */
    publierCentre () {
        const payload = {
            id: this.$store.state.centre.centreSelect.id,
            submit: 1
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('centre/updateCentre', payload)
            .then(() => {
                this.showConfirmationPublier = false

                this.$store.dispatch('centre/getCentre', { centre_id: this.$store.state.centre.centreSelect.id })
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Publication terminée.', succesToaster)
                this.closeEdition()
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /** Initialisation de la sélection des onglets */
    initSelectionOnglet () {
        this.tabIndex = this.$props.tab_index
        switch (this.tabIndex) {
            case 2:
                this.tabSelected = 'informations'
                break
            case 3:
                this.tabSelected = 'oraux'
                break
            case 4:
                this.tabSelected = 'ecrit_salles'
                break
        }
    }

    /** Remet les onglets en état initial à la fermeture du popup */
    destroyOnglet () {
        this.tabIndex = 0
        this.$store.commit('organisationecrit/SET_SELECT_COMPOSANT', 'comp_salles_ecrit')
    }

    /** Initialisation du tabSelected */
    setTabselect (onglet: string) {
        this.tabSelected = onglet
    }

    /** Retourne l'état de l'onglet */
    getEtatOnglet (onglet: string) {
        let class_text = 'text-tertiary'
        if (onglet === 'informations') {
            if (this.$store.getters['centre/informations_is_submit']) {
                class_text = 'text-info'
            } else if (this.$store.getters['centre/has_commentaire_rejet_informations']) {
                class_text = 'text-danger'
            }

            if (this.$store.getters['centre/informations_is_validate']) {
                class_text = 'text-success'
            }
        }
        if (onglet === 'ecrit_salles') {
            if (this.$store.getters['centre/salles_is_submit']) {
                class_text = 'text-info'
            } else if (this.$store.getters['centre/has_commentaire_rejet_ecrit']) {
                class_text = 'text-danger'
            }

            if (this.$store.getters['centre/salles_is_validate']) {
                class_text = 'text-success'
            }
        }

        if (onglet === 'ecrit_intervenants') {
            if (this.$store.getters['centre/nb_intervenants_is_submit']) {
                class_text = 'text-info'
            } else if (this.$store.getters['centre/has_commentaire_rejet_ecrit']) {
                class_text = 'text-danger'
            }

            if (this.$store.getters['centre/nb_intervenants_is_validate']) {
                class_text = 'text-success'
            }
        }

        if (onglet === 'oraux') {
            if (this.$store.getters['centre/salles_is_submit'] && this.$store.getters['centre/nb_intervenants_is_submit']) {
                class_text = 'text-info'
            } else if (this.$store.getters['centre/has_commentaire_rejet_ecrit']) {
                class_text = 'text-danger'
            }

            if (this.$store.getters['centre/salles_is_validate'] && this.$store.getters['centre/nb_intervenants_is_validate']) {
                class_text = 'text-success'
            }
        }
        return class_text
    }
}

