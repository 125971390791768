





















































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'
import { Etat, getEtatSpec } from '@/types/DossierAdministratif'
import { ExaSignature } from '@exatech-group/signature'
import '@exatech-group/signature/dist/style.css'
import { formatDate, base64ToArrayBuffer, formatDateDocument } from '@/utils/helpers'
import DemandeOrdreMission from '@/components/OrdresMission//DemandeOrdreMission.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VuePdfApp from 'vue-pdf-app'
import ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    components: {
        ExaSignature,
        DemandeOrdreMission,
        'font-awesome-icon': FontAwesomeIcon,
        VuePdfApp,
        ErrorDisplay
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapGetters('dossieradministratif', ['contrats', 'sourcePdf', 'contratSelect', 'error', 'loading']),
        ...mapState('dossieradministratif', ['dossiersAdministratifs', 'contrats', 'contratSelect', 'response_token']),
        ...mapState('avenant', ['error_avenant', 'loading_avenant', 'source_pdf_avenant', 'avenant_select'])
    },
    methods: {
        ...mapActions('dossieracademique', ['updateContrat'])
    }
})
export default class ContratsIntervenant extends Vue {
    @Watch('dossiersAdministratifs')
    setClass () {
        if (this.$store.state.dossieradministratif.dossiersAdministratifs.length !== 0) {
            this.$store.state.dossieradministratif.contrats = []
            for (const d in this.$store.state.dossieradministratif.dossiersAdministratifs) {
                if (this.$store.state.dossieradministratif.dossiersAdministratifs[d].contrats && this.$store.state.dossieradministratif.dossiersAdministratifs[d].contrats.length !== 0) {
                    this.$store.commit('dossieradministratif/SET_CONTRATS', this.$store.state.dossieradministratif.dossiersAdministratifs[d].contrats)
                    this.$emit('hasContrats', true)
                }
            }

            if (this.$route && this.$route.query && this.$route.query.mode === 'signature' && this.$route.query.contrat !== null) {
                const contrat = {
                    id: this.$route.query.contrat,
                    dossier_administratif_id: this.$route.query.dossier
                }
                if (this.chargementPdf === false) {
                    this.signatureContrat(contrat)
                }
            }
        }
    }

    @Watch('response_token')
    setResponseToken () {
        if (this.$store.state.dossieradministratif.response_token && this.$store.state.dossieradministratif.response_token.status !== '200') {
            this.$store.state.dossieradministratif.error = 'Une erreur est survenue'
        }
    }

    @Watch('$route')
    goBack () {
        if (this.$route.query && !this.$route.query.mode && this.modeSignature) {
            this.modeSignature = false
        } else if (this.$route.query.mode === 'signature' && this.$route.query.contrat !== null) {
            const contrat = {
                id: this.$route.query.contrat,
                dossier_administratif_id: this.$route.query.dossier
            }
            this.signatureContrat(contrat)
        }
    }

    // DATAS
    edition = true
    libelle = ''
    modeSignature = false
    modeSignatureAvenant = false
    modeValidation = false
    modeValidationAvenant = false
    showAnnulationSignature = false
    showConsultContrat = false
    getEtatSpec = getEtatSpec
    Etat = Etat
    signature = ''
    showDemandeOrdreMission = false
    showConsultDocument = false
    file_name = ''
    config = {
        toolbar: {
            toolbarViewerRight: { presentationMode: false, openFile: false, viewBookmark: false, secondaryToolbarToggle: false }
        }
    }

    source_doc: any = ''

    // METHODS
    chargementPdf = false

    // Retourne une description selon etat du contrat
    getDescription (element: any, is_avenant: boolean) {
        let description = ''
        switch (element.etat) {
            case Etat.ETAT_VIERGE:
                description = !is_avenant ? 'Contrat en attente de signature' : 'Avenant en attente de signature'
                break
            case Etat.ETAT_DEPOSE:
                description = 'Vous avez signé le ' + formatDate(element.signed_at)
                break
            case Etat.ETAT_VALIDE:
                description = !is_avenant ? 'Contrat finalisé le ' + formatDate(element.validated_at) : 'Avenant finalisé le ' + formatDate(element.validated_at)
                break
        }

        return description
    }

    // Ouvre le mode signature pour un contrat
    signatureContrat (contrat: any) {
        this.chargementPdf = true
        this.$store.state.dossieradministratif.error = null
        this.$store.dispatch('dossieradministratif/getContrat', { dossier_id: contrat.dossier_administratif_id, contrat_id: contrat.id }).then(() => {
            this.$store.dispatch('dossieradministratif/getDossierAdministratif', contrat.dossier_administratif_id)
            if (!this.$store.state.dossieradministratif.contratSelect.signed_at) {
                this.$store.dispatch('dossieradministratif/getContratPDF', { dossier_id: contrat.dossier_administratif_id, contrat_id: contrat.id }).then(() => {
                    if (!this.$route.query.mode) {
                        this.$router.push({ path: '/contrats_intervenant', query: { mode: 'signature', contrat: contrat.id, dossier: contrat.dossier_administratif_id } })
                    }
                    this.source_doc = base64ToArrayBuffer(this.$store.state.dossieradministratif.source_pdf)
                    this.file_name = 'Contrat_' + this.$store.state.dossieradministratif.dossierAdministratifSelect.user.name + '_' + this.$store.state.dossieradministratif.dossierAdministratifSelect.user.first_name + '_' + formatDateDocument(this.$store.state.dossieradministratif.contratSelect.signed_at)
                    this.modeSignature = true
                    this.chargementPdf = false
                }).catch((error) => {
                    console.log(error)
                    this.$router.push({ path: '/contrats_intervenant' })
                })
            } else {
                this.$router.push({ path: '/contrats_intervenant' })
            }
        }).catch((error) => {
            console.log(error)
            this.$router.push({ path: '/contrats_intervenant' })
        })
    }

    // Affiche le contrat en viewer quand il n'est pas encore signé par le responsable
    consultContrat (contrat: any) {
        this.$store.state.dossieradministratif.error = null
        this.$store.dispatch('dossieradministratif/getContrat', { dossier_id: contrat.dossier_administratif_id, contrat_id: contrat.id }).then(() => {
            this.$store.dispatch('dossieradministratif/getDossierAdministratif', contrat.dossier_administratif_id)
            this.$store.dispatch('dossieradministratif/getContratPDF', { dossier_id: contrat.dossier_administratif_id, contrat_id: contrat.id }).then(() => {
                this.source_doc = base64ToArrayBuffer(this.$store.state.dossieradministratif.source_pdf)
                this.showConsultContrat = true
                this.file_name = 'Contrat_' + this.$store.state.dossieradministratif.dossierAdministratifSelect.user.name + '_' + this.$store.state.dossieradministratif.dossierAdministratifSelect.user.first_name + '_' + formatDateDocument(this.$store.state.dossieradministratif.contratSelect.signed_at)
            }).catch((error) => {
                console.log(error)
            })
        }).catch((error) => {
            console.log(error)
        })
    }

    // Ouvre le mode signature pour un avenant
    signatureAvenant (contrat: any, avenant: any) {
        this.$store.state.avenant.error_avenant = null

        if (!avenant.signed_at) {
            this.$store.dispatch('dossieradministratif/getDossierAdministratif', contrat.dossier_administratif_id).then(() => {
                this.$store.commit('avenant/SET_AVENANT_SELECT', avenant)
                this.$store.dispatch('avenant/getAvenantPDF', { contrat_id: contrat.id, avenant_id: avenant.id }).then(() => {
                    this.source_doc = base64ToArrayBuffer(this.$store.state.avenant.source_pdf_avenant)
                    this.file_name = 'Avenant_' + this.$store.state.dossieradministratif.dossierAdministratifSelect.user.name + '_' + this.$store.state.dossieradministratif.dossierAdministratifSelect.user.first_name + '_' + formatDateDocument(avenant.signed_at)
                    this.modeSignatureAvenant = true
                }).catch((error) => {
                    console.log(error)
                    this.$router.push({ path: '/contrats_intervenant' })
                })
            })
        } else {
            this.$router.push({ path: '/contrats_intervenant' })
        }
    }

    // Affiche l'avenant en viewer quand il n'est pas encore signé par le responsable
    consultAvenant (contrat: any, avenant: any) {
        this.$store.state.avenant.error_avenant = null
        this.$store.dispatch('dossieradministratif/getDossierAdministratif', contrat.dossier_administratif_id)
        this.$store.dispatch('avenant/getAvenantPDF', { contrat_id: contrat.id, avenant_id: avenant.id }).then(() => {
            this.source_doc = base64ToArrayBuffer(this.$store.state.avenant.source_pdf_avenant)
            this.file_name = 'Avenant_' + this.$store.state.dossieradministratif.dossierAdministratifSelect.user.name + '_' + this.$store.state.dossieradministratif.dossierAdministratifSelect.user.first_name + '_' + formatDateDocument(avenant.signed_at)
            this.showConsultContrat = true
        }).catch((error) => {
            console.log(error)
        })
    }

    // Enregistre la signature sur le contrat
    saveSignature (params: any) {
        this.$store.state.dossieradministratif.error = null
        this.$store.state.avenant.error_avenant = null
        this.signature = params.signature

        // Requete demande de token
        if (this.modeSignature) {
            this.$store.dispatch('dossieradministratif/getTokenSignature', { dossier_id: this.$store.state.dossieradministratif.contratSelect.dossier_administratif_id, contrat_id: this.$store.state.dossieradministratif.contratSelect.id, mode: params.mode })
        }

        if (this.modeSignatureAvenant) {
            this.$store.dispatch('avenant/getTokenSignature', { contrat_id: this.$store.state.avenant.avenant_select.avenantable_id, avenant_id: this.$store.state.avenant.avenant_select.id, mode: params.mode })
        }
    }

    // Affiche si des erreurs de signature sont retournées
    showErrorSignature (error: string) {
        if (error !== '') {
            this.$store.state.dossieradministratif.error = error
        }
    }

    // Annule la signature en cours
    confirmAnnulation () {
        this.signature = ''
        this.modeSignature = false
        this.showAnnulationSignature = false
        this.modeSignatureAvenant = false
        this.showAnnulationSignature = false
        this.$router.push({ path: '/contrats_intervenant' })
    }

    // Bouton Retour
    back () {
        this.showAnnulationSignature = true
    }

    // Ferme la popup d'annulation de signature
    closeAnnulationSignature () {
        this.showAnnulationSignature = false
    }

    // Ferme la consultation d'un contrat
    closeConsultationContrat () {
        this.showConsultContrat = false
    }

    // Confirme la signature du contrat
    validationContrat (tokenSMS: string) {
        if (this.signature !== '') {
            this.$store.state.dossieradministratif.error = null
            this.$store.state.avenant.error_avant = null
            const datas = {
                signature: this.signature,
                code: tokenSMS
            }

            if (this.modeSignature) {
                this.$store.dispatch('dossieradministratif/updateContrat', { dossier_id: this.$store.state.dossieradministratif.contratSelect.dossier_administratif_id, contrat_id: this.$store.state.dossieradministratif.contratSelect.id, payload: datas })
                    .then(() => {
                        if (!this.$store.state.dossieradministratif.error) {
                            this.$store.dispatch('dossieradministratif/getDossiersAdministratifs')
                            this.modeSignature = false
                            this.modeValidation = false
                            const idSucces = 't_succes_' + Math.random()
                            const succesToaster = {
                                id: idSucces,
                                toaster: 'b-toaster-top-right',
                                variant: 'success',
                                noCloseButton: true,
                                fade: true,
                                autoHideDelay: 5000
                            }
                            this.$bvToast.toast('Signature enregistrée !', succesToaster)

                            this.$router.push({ path: '/contrats_intervenant' })
                        }
                    })
            }

            if (this.modeSignatureAvenant) {
                this.$store.dispatch('avenant/updateAvenant', { contrat_id: this.$store.state.avenant.avenant_select.avenantable_id, avenant_id: this.$store.state.avenant.avenant_select.id, payload: datas })
                    .then(() => {
                        if (!this.$store.state.dossieradministratif.error) {
                            this.$store.dispatch('dossieradministratif/getDossiersAdministratifs')
                            this.modeSignatureAvenant = false
                            this.modeValidationAvenant = false
                            const idSucces = 't_succes_' + Math.random()
                            const succesToaster = {
                                id: idSucces,
                                toaster: 'b-toaster-top-right',
                                variant: 'success',
                                noCloseButton: true,
                                fade: true,
                                autoHideDelay: 5000
                            }
                            this.$bvToast.toast('Signature enregistrée !', succesToaster)

                            this.$router.push({ path: '/contrats_intervenant' })
                        }
                    })
            }
        }
    }

    // Ouvre le popup de demande d'ordre de mission
    openDemandeOrdreMission (contrat: any) {
        this.$store.dispatch('dossieradministratif/getContrat', { dossier_id: contrat.dossier_administratif_id, contrat_id: contrat.id }).then(() => {
            this.showDemandeOrdreMission = true
        })
    }

    // Ferme la popup de demande d'ordre de mission
    closeDemandeOrdreMission () {
        this.showDemandeOrdreMission = false
    }

    // Check si un contrat a un document en particulier
    getDocument (collection: any, doc: string) {
        if (collection && collection.length !== 0) {
            const document = collection.find((d: any) => d.type === doc)

            if (document && document.length !== 0) {
                return document.id
            } else {
                return null
            }
        } else {
            return null
        }
    }

    // Permet de consulter un bulletin de salaire ou une attestation
    consultDocument (document_id: any, contrat: any) {
        this.$store
            .dispatch('dossieradministratif/getPDFDocumentContrat', { dossier_id: contrat.dossier_administratif_id, contrat_id: contrat.id, document_id: document_id })
            .then(() => {
                this.source_doc = base64ToArrayBuffer(this.$store.state.dossieradministratif.source_pdf)
                this.showConsultDocument = true
            })
            .catch((error) => {
                console.log(error)
            })
    }

    // Ferme la consultation d'un document
    closeConsultationDocument () {
        this.showConsultDocument = false
    }

    // Retourne une classe texte barré si l'avenant est remplacé par un autre
    is_annule(contrat: any, avenant_id: number) {
        if (contrat.avenants.find((a: any) => a.avenant_id === avenant_id)) {
            const avenant_remplace = contrat.avenants.find((a: any) => a.avenant_id === avenant_id)
            // Un avenant est présent pour remplacer l'autre, il est remplaçant si son état est au minimum publié
            if (avenant_remplace.etat >= Etat.ETAT_VIERGE) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    // Retourne vrai ou faux si un avenant est considéré remplaçant
    is_remplace (contrat: any, avenant: any) {
        if (avenant.avenant_id !== null) {
            const avenant_remplace = contrat.avenants.find((a: any) => a.avenant_id === avenant.avenant_id)
            if (avenant_remplace.etat >= Etat.ETAT_VIERGE) {
                return true
            } else { return false }
        } else { return false }
    }

    signatureMode = []
    mounted () {
        for(let i = 0; i < this.$store.state.auth.user.parameters.length; i ++) {
            if(this.$store.state.auth.user.parameters[i].code === 'signatureMode') {
                this.signatureMode = this.$store.state.auth.user.parameters[i].value
                // this.codeClient = 'CCMP'
            }
        }
        this.load()
    }

    load() {
        if (this.$store.state.dossieradministratif.dossiersAdministratifs.length === 0) {
            this.$store.dispatch('dossieradministratif/getDossiersAdministratifs')
        }
        if (this.$route && this.$route.query && this.$route.query.mode === 'signature') {
            this.modeSignature = true
        }
    }
}
