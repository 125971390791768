


























































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { Ability } from '@/types/Ability'
import { checkIcone } from '@/utils/helpers'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import _ from 'lodash'

@Component({
    components: {
        ExaGenericTable,
        ErrorDisplay
    },
    computed: {
        ...mapGetters('avenanttype', ['avenanttypes', 'loading', 'totalRows', 'lastPage']),
        ...mapState('avenanttype', ['error_avenant']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id'])
    }
})

export default class AvenantsTypes extends Vue {
    @Watch('user_session_id')
    async refreshInterface () {
        await this.load()
    }

    @Watch('avenanttypes')
    initListe() {
        const data = this.$store.getters['avenanttype/avenanttypes']
        this.setDataForGenericTab(data)
    }

    Ability = Ability
    // DATAS
    genericfields = [
        {
            key: 'actionLeft',
            label: '',
            sortable: false,
            sortDirection: 'asc',
            tdClass: 'col-w-etat bg-info'
        },
        {
            key: 'name',
            label: 'Libellé',
            sortable: true,
            sortDirection: 'asc',
            class: 'text-start ps-4'
        }
    ]

    filters: any = []

    dataForTab: any = []

    showDeleteAvenantType = false
    avenanttype: any = {}
    // METHODS

    // Ajouter un nouveau type d'avenant
    ajouterAvenant () {
        this.$store.state.avenanttype.error_avenant = null
        this.$router.push('/avenants_types/0')
    }

    // Editer un type d'avenant
    editAvenant (row: any) {
        this.$store.state.avenanttype.error_avenant = null
        this.$router.push('/avenants_types/' + row.id)
    }

    // Ouvre la confirmation de suppression d'un type d'avenant
    confirmDeleteAvenant (row: any) {
        this.showDeleteAvenantType = true
        this.avenanttype = row
    }

    // Supprime le type de contrat sélectionné
    deleteContratType () {
        this.$store.dispatch('avenanttype/deleteAvenantType', { avenanttype_id: this.avenanttype.id })
            .then(() => {
                this.showDeleteAvenantType = false
                this.$store.dispatch('avenanttype/getAvenantTypes')
            })
    }

    // Annulaton de la suppression du type de contrat
    cancelDeleteContratType () {
        this.showDeleteAvenantType = false
        this.avenanttype = {}
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    async handleTableEvent (paParams: any): Promise<void> {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'sortHandler':
                case 'filterHandler':
                    await this.filterSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    this.loadHandler()
                    break
                case 'edit' :
                    this.editAvenant(paParams[1])
                    break
                case 'delete' :
                    this.confirmDeleteAvenant(paParams[1])
                    break
            }
        }
    }

    /**
     * Appel d'une nouvelle page de epreuveCorrectionResultats lors du scroll
     */
    loadHandler () {
        // this.$store.dispatch('epreuveCorrectionResultat/getMoreepreuveCorrectionResultats', params)
    }

    async filterSortHandler (params: any) {
        await this.$store.dispatch('avenanttype/getAvenantTypes', params)
    }

    /** Retourne la liste passée en paramètre ordonnée par name */
    orderedList (liste: any) {
        return _.orderBy(liste, 'name', 'asc')
    }

    setDataForGenericTab(poData: any, isLoadMore = false) {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const result of poData) {
                const name = result?.name || '-'
                const can = this.$store.getters['auth/can'](Ability.RH_DOSADM_MANAGE)
                const icone = checkIcone(Ability.RH_DOSADM_MANAGE, can)

                const line = [
                    {
                        label: icone.label,
                        item: result,
                        type: 'action',
                        typeAction: 'edit',
                        class: 'commons_first_action_button',
                        icon: icone.icon,
                        disabled: false
                    },
                    {
                        label: result.name,
                        item: name,
                        type: 'text',
                        typeAction: null,
                        class: ''
                    }
                ]

                this.dataForTab.push(line)
            }
        }
    }

    setFiltersForGenericTab() {
        this.filters = [
            {
                libelle: 'Libellé',
                defautOptionlibelle: 'Rechercher un',
                model: 'name',
                value: '',
                index: 'name',
                datas: '',
                loading: this.$store.getters['avenanttype/loading'],
                options: { type: 'form', fieldsKey: 'name' }
            }
        ]
    }

    async load() {
        this.$store.state.avenanttype.error = null
        await this.$store.dispatch('avenanttype/getAvenantTypes')
        this.setFiltersForGenericTab()
    }

    async beforeMount () {
        if (this.$store.getters['auth/user_session_id']) {
            await this.load()
        }
    }
}
