


































































































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component, Watch }    from 'vue-property-decorator'
import { mapGetters, mapState }     from 'vuex'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import popupImprimer                from '@/components/Fourniture/popupImprimer.vue'
import { formatDateSinTime, formatDate, formatDayHourZDateFromString, formatDateDayHourDateForFileSaving }                       from '@/utils/helpers'
import { printed, FournitureType, typeLongName, FournitureTypeCheckboxName, lastUpdateAfterValidatedSerieDate, createMidnightDateNow, createLastSecondDateNow }     from '@/types/Fourniture'
import ErrorDisplay                 from '@/components/ErrorDisplay.vue'
import { Ability }                  from '@/types/Ability'
import { TypeEnsemble } from '@/types/Ensemble'
import _ from 'lodash'


@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('serie', ['serieById', 'series']),
        ...mapGetters('ensemble', ['ensembles']),
        ...mapGetters('auth', ['is_intervenant_centre']),
        ...mapGetters('impressionExaminateur', ['tableauImpressionsExaminateurTrie', 'getError', 'loadingRecherche', 'loadingPdf', 'meta', 'currentPage', 'totalRows', 'lastPage', 'total'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ExaGenericTable,
        popupImprimer,
        ErrorDisplay
    }
})

export default class ImpressionsExaminateur extends Vue
{
    // utiliser pour passer le titre dans la popUp
    titrePopUp              = 'Impression par examinateur'
    sortBy                  = ''
    sortDesc                = false
    sortDirection           = 'asc'
    filter                  = ''
    filterOn                = []
    stickyHeader            = true
    options_centres         = []
    options_series          = []
    options_ensembles: any  = []
    options_examinateurs: any    = []
    showModalImprimer       = false
    formatDateSinTime       = formatDateSinTime
    formatDate              = formatDate
    printed                 = printed
    filtres:    any         = []
    dataForTab: Array<any>  = []
    pdfData                 = ''
    pdfFileName             = 'Impression_Par_Examinateur.pdf'
    libelle_document: any   = ''
    paramsConfirmationImpression: any   = {}
    showModalAnnulationImpression       = false
    Ability                = Ability
    typeLongName           = typeLongName
    FournitureTypeCheckboxName = FournitureTypeCheckboxName
    typeSelectEnsemble          = ''
    TypeEnsemble                = TypeEnsemble
    imprimer_temp: any          = ''
    headers_pdf: any            = null
    // Sauvegarde le dernier choix utilisateur des dates
    saveInputStart              = ''
    saveInputEnd                = ''
    // temp utilisé pour les ensembles quand un utilisateur est own
    ensemble_own_centre: any    = ''
    createMidnightDateNow       = createMidnightDateNow
    createLastSecondDateNow     = createLastSecondDateNow

    genericfields = [
        { key: 'type',          label: 'Type',                   sortable: false,    class: 'text-center',           type: 'text' },
        { key: 'centre',        label: 'Centre',                 sortable: false,    class: 'text-center',           type: 'text' },
        { key: 'equipe',        label: 'Équipe',                 sortable: false,    class: 'text-center',           type: 'text' },
        { key: 'examinateur',   label: 'Examinateur',            sortable: false,    class: 'text-center',           type: 'text' },
        { key: 'serie',         label: 'Série',                  sortable: false,     class: 'text-center',           type: 'text' },
        { key: 'candidat',      label: 'Candidat',               sortable: false,     class: '',           type: 'text' },
        { key: 'DateLastModif', label: 'Dernière modif.',        sortable: false,     class: 'text-center',           type: 'text' },
        { key: 'ConfirmImp',    label: 'Impression confirmée',    sortable: false,     class: 'text-center',           type: 'text' }
    ]

    recherche: any = {
        centre_id       : '',
        serie           : '',
        ensemble_id     : '',
        user_id         : '',
        printed         : printed.TOUTES_LES_FEUILLES,
        fiche_pointage  : 1,
        fiche_mesure    : 1,
        printed_start   : '',
        printed_end     : '',
        fiche_notation  : 1,
        page            : 1
    }

    @Watch('series')
    watchSerie() {
        this.options_series = this.$store.getters['serie/series']
        this.recherche.serie = this.findSerieActive(this.$store.getters['serie/series'])
    }

    @Watch('tableauImpressionsExaminateurTrie')
    watchTableau() {
        const data = this.$store.getters['impressionExaminateur/tableauImpressionsExaminateurTrie']
        this.setDataForGenericTab(data)
    }


    @Watch('user_session_id')
    refreshInterface () {
        this.clearResult()
        this.updateSeriesAfterUserChangeSession()
        this.load()
        this.recherche = {
            centre_id       : '',
            serie           : '',
            ensemble_id     : '',
            user_id         : '',
            printed         : printed.TOUTES_LES_FEUILLES,
            fiche_pointage  : 1,
            fiche_mesure    : 1,
            printed_start   : '',
            printed_end     : '',
            fiche_notation  : 1,
            page            : 1
        }
    }

    /** formate une valeur string de type'YYYY-MM-DD HH:mm:ss' en valeur moment YYYY-MM-DDTHH:mm:ss[Z] */
    convertDateToIso = (dateString: string | undefined) => {
        if (dateString) {
            return formatDayHourZDateFromString(dateString)
        }
    }

    /** recup de tous les centres et renvois les centres avec équipe */
    getCentres() {
        // cote CCmp
        if (this.$store.getters['auth/can'](Ability.ORAL_GFOU_VIEW))
        {
            if (this.$store.getters['centre/centres'].length === 0) {
                this.$store.dispatch('centre/getCentres').then((resp) => {
                    this.options_centres = resp.data.data.filter((centre: { ensembles_count: number }) => centre.ensembles_count !== 0)
                })
            } else {
                this.options_centres = this.$store.getters['centre/centres'].filter((centre: { ensembles_count: number }) => centre.ensembles_count !== 0)
            }
        }
        // coté intervenant
        else if (this.$store.getters['auth/is_intervenant_centre']) {
            const centre_id = this.$route.params.centre_id
            this.recherche.centre_id = centre_id
            this.getEnsembleCentre(centre_id)
        }
    }

    // recuperation des ensembles si ils ne sont pas transmis par le parent
    getEnsemblesData() {
        const params: any = {
            perPage: 10000
        }
        // si utilisateur own, on appelle uniquement pour son propre centre
        if (this.$store.getters['auth/is_intervenant_centre']) {
            params['filter-centre_id'] = this.$route.params.centre_id
        }
        this.$store.dispatch('ensemble/getEnsembles', { params: params }).then((response) => {
            if (this.$store.getters['auth/is_intervenant_centre']) {
                params['filter-centre_id'] = this.$route.params.centre_id
                this.ensemble_own_centre = response.data.data
                this.options_ensembles = response.data.data
                this.getEnsembleCentre(this.$route.params.centre_id)
            }
        })
            .catch((error) => {
                console.log('ko:' + error)
            })
    }

    initSeries () {
        if (this.$store.getters['serie/series'].length === 0) {
            this.$store.dispatch('serie/getSeries').then(() => {
                this.recherche.serie = this.findSerieActive(this.$store.getters['serie/series'])
            })
        } else {
            this.options_series = this.$store.getters['serie/series']
            this.recherche.serie = this.findSerieActive(this.$store.getters['serie/series'])
        }
    }


    /** recuperation des centres et supprime les valeurs des variables */
    getEnsembleCentre(centre: string) {
        this.options_ensembles      = []
        this.options_examinateurs   = []
        this.recherche.ensemble_id  = ''
        this.recherche.user_id      = ''
        const allEnsembles          = this.$store.getters['ensemble/ensembles']
        this.options_ensembles      = allEnsembles.filter((e: any) => (e.centre_id) === centre)

        if (this.$store.getters['auth/is_intervenant_centre']) {
            this.options_ensembles = this.ensemble_own_centre
        }

        if (this.options_ensembles && this.options_ensembles[0] && this.options_ensembles[0].id) {
            // this.recherche.ensemble_id = this.options_ensembles[0].id
            this.getExaminateurs(this.recherche.ensemble_id)
        }
    }


    /**  recup de l'id sélectionné dans équipe, dispatch et alimentation de la liste des choix  */
    getExaminateurs(equipeId: any) {
        if (this.$store.getters['ensemble/ensembles'] && equipeId)
        {
            this.recherche.user_id      = ''
            this.options_examinateurs   = ''
            const allEnsembles          = this.$store.getters['ensemble/ensembles']
            this.options_examinateurs   = allEnsembles.filter((e: any) => e.id === equipeId)[0].examinateurs
            // recup des remplacants
            const remplacant            = allEnsembles.filter((e: any) => e.id === equipeId)[0]
            // ajout des remplacants s'il y a, dans les options_examinateurs
            if (remplacant && remplacant.remplacants && remplacant.remplacants.length !== 0) {
                remplacant.remplacants.forEach((element: any) => {
                    const pusElement = Object.assign(element)
                    pusElement.is_replace = true
                    if (this.options_examinateurs.findIndex((exam: { id: any }) => exam.id === element.id) === -1) {
                        this.options_examinateurs.push(pusElement)
                    }
                })
            }
            this.recherche.user_id      = ''

            // recup du type d'ensemble afin de deactiver le choix si type_ensemble === TYPE_ENSEMBLE_TP
            this.typeSelectEnsemble = allEnsembles.filter((ens: any) => ens.id === equipeId)[0].type_ensemble
            this.options_examinateurs = _.orderBy(this.options_examinateurs, 'name', 'asc')
        }
        this.recherche.user_id = ''
    }

    /** lance la recherche ou l affichage du pdf. pdf_view 0/1. 1 si apercu du pdf  */
    sendSearch(pdf_view: number) {
        this.$store.commit('impressionExaminateur/RESET_META')
        this.$store.commit('impressionExaminateur/RESET_TABLEAU_IMPRESSION')
        const params  =
        {
            centre_id       : this.recherche.centre_id,
            serie_id        : this.recherche.serie,
            ensemble_id     : this.recherche.ensemble_id,
            user_id         : this.recherche.user_id,
            printed         : this.recherche.printed,
            fiche_pointage  : this.recherche.fiche_pointage ? 1 : 0,
            fiche_mesure    : this.recherche.fiche_mesure ? 1 : 0,
            printed_start   : this.convertDateToIso(this.recherche.printed_start),
            printed_end     : this.convertDateToIso(this.recherche.printed_end),
            pdf             : pdf_view || 0,
            fiche_notation  : 1,
            page            : 1
        }
        this.imprimer_temp = params
        this.$store.dispatch('impressionExaminateur/getTableauImpressionExaminateur', params)
    }


    erase_date_time() {
        this.saveInputStart              = this.recherche.printed_start !== '' ? this.recherche.printed_start : this.saveInputStart
        this.saveInputEnd                = this.recherche.printed_end    !== '' ? this.recherche.printed_end : this.saveInputEnd
        this.recherche.printed_start   = ''
        this.recherche.printed_end     = ''
        if (this.recherche.printed === printed.FEUILLES_IMPRIMEES_ENTRE) {
            if (this.saveInputStart === '' || !this.saveInputStart)
            {
                this.recherche.printed_start   = this.createMidnightDateNow()
            } else {
                this.recherche.printed_start   = this.saveInputStart
            }

            if (this.saveInputEnd === '' || !this.saveInputEnd)
            {
                this.recherche.printed_end   = this.createLastSecondDateNow()
            } else {
                this.recherche.printed_end   = this.saveInputEnd
            }
        }
    }


    updateSeriesAfterUserChangeSession() {
        const sessionUserSelect = this.$store.state.auth.user_session_id
        this.$store.dispatch('serie/getSeries', { session_id: sessionUserSelect })
    }

    /*
    *
    * Generic Table Area
    *
    */

    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                const ensemble = this.$store.state.ensemble.ensembles.find((e: any) => e.id === result.ensemble_id)
                const type                  = this.typeLongName(result.type)
                const centre                = result.centre_id      ? this.findCentre(result.centre_id) : '-'
                const equipe                = result.ensemble_id    ? this.findEnsemble(result.centre_id, result.ensemble_id) : '-'
                let examinateur = ensemble.examinateurs.find((ex: any) => ex.id === result.user_id)
                if (!examinateur) {
                    // on cherche dans les remplaçants
                    examinateur = ensemble.remplacants.find((ex: any) => ex.id === result.user_id)
                }
                const serie                 = result.serie_id       ? this.findSerie(result.serie_id) : '-'
                const candidat              = result.candidat       ? result.candidat.code + ' ' + result.candidat.name + ' ' + result.candidat.first_name : ''
                const dateDerniereModif     = result.updated_at && lastUpdateAfterValidatedSerieDate(result.updated_at, result.serie_id)     ? formatDate(result.updated_at) : '-'
                const impressionConfirmee   = result.printed_at     ? formatDate(result.printed_at) : '-'


                const line = [
                    { label: type,        item: type,                     type: 'text', typeAction: null, class: this.getClassFromType(type) + ' text-center colonne_type' },
                    { label: 'Centre',      item: centre.name,              type: 'text', typeAction: null, class:  this.getClassFromType(type) + ' text-center' },
                    { label: 'equipe',      item: equipe.name,              type: 'text', typeAction: null, class:  this.getClassFromType(type) + ' text-center' },
                    {
                        label: 'examinateur',
                        item:   examinateur.name + ' ' + examinateur.first_name,
                        type: 'text',
                        typeAction: null,
                        class:  this.getClassFromType(type) + ' text-center'
                    },
                    { label: 'serie',       item: serie.name,                type: 'text', typeAction: null, class:  this.getClassFromType(type) + ' text-center' },
                    {
                        label: 'candidat',
                        item: candidat,
                        type: 'text',
                        typeAction: null,
                        class: this.getClassFromType(type)
                    },
                    { label: 'dateModif',   item: dateDerniereModif,    type: 'text', typeAction: null, class:  this.getClassFromType(type) + ' text-center' },
                    { label: 'impConf',     item: impressionConfirmee,  type: 'text', typeAction: null, class: this.getClassFromType(type) + ' text-center' }
                ]
                this.dataForTab.push(line)
            }
        }
    }

    getClassFromType(type: string) {
        let classType = ''
        switch (true) {
            case type === FournitureType.TYPE_FPO :
                classType = 'type_class_grey'
                break
            case type === FournitureType.TYPE_FMO :
                classType = 'type_class_grey'
                break
            default:
                break
        }
        return classType
    }

    /** trouve la premiere serie active et renvoi l'id de la valeur  */
    findSerieActive(serie: any[]) {
        let id  = '0'
        if (serie) {
            id = serie.find((serie: { valide: number }) => serie.valide === 1) ? serie.find((serie: { valide: number }) => serie.valide === 1).id : '0'
        }

        return id
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'onLoadPage':
                    this.loadHandler()
                    break
            }
        }
    }

    /**
     * Appel d'une nouvelle page de epreuveCorrectionResultats lors du scroll
     */
    loadHandler ()
    {
        const params  =
        {
            centre_id       : this.recherche.centre_id,
            serie_id        : this.recherche.serie,
            ensemble_id     : this.recherche.ensemble_id,
            user_id         : this.recherche.user_id,
            printed         : this.recherche.printed,
            fiche_pointage  : this.recherche.fiche_pointage ? 1 : 0,
            fiche_mesure    : this.recherche.fiche_mesure ? 1 : 0,
            printed_start   : this.convertDateToIso(this.recherche.printed_start),
            printed_end     : this.convertDateToIso(this.recherche.printed_end),
            pdf             : 0,
            page            : this.$store.state.impressionExaminateur.currentPage + 1,
            fiche_notation  : 1
        }

        this.$store.dispatch('impressionExaminateur/getMoreTableauImpressionExaminateur', params)
    }




    /** recheche un centre et renvois son nom  */
    findCentre(centre_id: number) {
        const centre: any = this.$store.getters['centre/centres'].filter((centre: any) => centre.id === centre_id)[0]
        return  centre
    }

    /** recherche d un ensemble et renvois le nom  */
    findEnsemble(centre_id: number, ensemble_id: number) {
        const ensembles = this.$store.getters['ensemble/ensembles']
        const ensemble: any = ensembles.filter((ens: any) => ens.id === ensemble_id)[0]
        return ensemble
    }

    findExaminateur(ensemble_id: number, user_id: number) {
        const ensembles = this.$store.getters['ensemble/ensembles']
        const examinateurs: any = ensembles.filter((ens: any) => ens.id === ensemble_id)[0].examinateurs
        const examinateur: any = examinateurs ? examinateurs.filter((examinateur: { id: number }) => examinateur.id === user_id)[0] : ' - '
        return examinateur
    }

    findSerie(serie_id: number) {
        const series = this.$store.getters['serie/series']
        const serie = series.filter((serie: any) => serie.id === serie_id)[0]
        return serie
    }



    /*
    *
    * Modale Area
    *
    */


    /* Gestion de l'affichage de la modale imprimer */
    affichageModaleImprimer() {
        const centreMatch: any = this.options_centres.filter((c: any) => c.id === this.recherche.centre_id)
        if (centreMatch && centreMatch.length > 0) {
            const currentDateFormated = formatDateDayHourDateForFileSaving(new Date(Date.now()).toISOString())
            this.pdfFileName = 'Impression_Par_Examinateur_' + centreMatch[0].name + '_' + currentDateFormated + '.pdf'
        }

        const params  = this.imprimer_temp
        params.pdf = 1
        params.format = ''
        this.paramsConfirmationImpression = params
        this.showModalImprimer = true
    }

    /** Fermeture de la popup d affichage de la modale pdf */
    closeModaleImprimer() {
        this.pdfData = ''
        this.showModalImprimer = false
    }

    /** efface les données du tableau, utilisé au changement dans les inputs de recherche  */
    clearResult() {
        this.$store.commit('impressionExaminateur/RESET_META')
        this.$store.commit('impressionExaminateur/RESET_TABLEAU_IMPRESSION')
    }

    load () {
        this.$store.commit('impressionExaminateur/RESET_META')

        this.getCentres()
        this.getEnsemblesData()
        this.initSeries()
    }

    /** Verif si session active, sinon appel  */
    mounted() {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
