



























import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { BModal } from 'bootstrap-vue';
import { isEmpty } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { getError } from '../../../utils/helpers';
import ActivityLog from '../../ActivityLog.vue';
import ErrorDisplay from '../../ErrorDisplay.vue';

@Component({
    name: 'PopupHistoriqueComponent',
    components: {
        BModal,
        FontAwesomeIcon,
        ErrorDisplay,
        ActivityLog
    },
    props: {
        entity: {
            type: String,
            required: true
        },
        target: {
            type: String,
            required: true
        }
    }
})

export default class PopupHistorique extends Vue {
    isEmpty = isEmpty
    show = false
    loading = false
    error = null as null | Error
    historique = null as any

    open(params: unknown = {}) {
        this.error = null
        this.loading = true
        this.show = true

        this.$store.dispatch(this.$props.target, params)
            .then((response) => this.historique = response.data.data)
            .catch((error) => this.error = getError(error))
            .finally(() => this.loading = false)
    }

    close() {
        this.show = false
    }
}
