






















































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { Ability } from '@/types/Ability'
import { PosteContext } from '@/types/Poste'
import ListeSalles from '@/components/Centre/Salles/ListeSalles.vue'
import AffectationExaminateursSalles from '@/components/Centre/Salles/AffectationExaminateursSalles.vue'
import { SalleInterface, TypeSalle } from '@/types/Salle'
// import { CentreInterface } from '@/types/Centre'
import { AffectationExaminateurGroupedSerieInterface } from '@/types/AffectationExaminateur'
import { formatDate, isObject } from '@/utils/helpers'
import { CentreInterface } from '@/types/Centre'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapGetters('centre', ['centreSelect']),
        // ...mapState('salle', ['error']),
        ...mapState('affectationExaminateur', ['error']),
        ...mapGetters('auth', ['is_intervenant_centre']),
        ...mapGetters('affectationExaminateur', ['check_salle_equipe_serie'])
    },
    components: {
        ListeSalles,
        AffectationExaminateursSalles,
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay

    }
})
export default class SallesOrauxCentre extends Vue {
    @Prop() centre?: CentreInterface
    @Prop() centre_id?: number

    Ability = Ability
    modeEdition = false
    gestionnaire = null
    commentaire_rejet = ''
    showCommentaireRejet = false

    salles_tp: Array<SalleInterface> = []
    salles: Array<SalleInterface> = []
    affectationExaminateursSerieGrouped: Array<AffectationExaminateurGroupedSerieInterface> = []
    sallesError = false

    chargementEnCours = false

    showModalMessageValidation = false
    showModalMessageInvalidation = false
    showModalMessageSoumission = false

    errors_soumission: any = []

    @Watch('centre_id')
    centreIdChangeHandler () {
        this.loadCentre()
    }

    mounted () {
        if (this.centre_id !== undefined) {
            this.loadCentre()
        }
    }

    loadCentre () {
        this.chargementEnCours = true
        this.$store.dispatch('centre/getCentre', { centre_id: this.centre_id }).then(() => {
            this.loadData()
        })
    }

    loadData () {
        if (!this.chargementEnCours) {
            this.chargementEnCours = true
        }
        this.salles = []
        this.salles_tp = []
        // chargement du centre
        // this.salles = this.$store.state.centre.centreSelect.salles
        // this.salles_tp = this.$store.state.centre.centreSelect.salles.filter((s: SalleInterface) => s.type === TypeSalle.TP)
        for (let i = 0; i < this.$store.state.centre.centreSelect.salles.length; i++) {
            this.salles.push({
                id: this.$store.state.centre.centreSelect.salles[i].id,
                code: this.$store.state.centre.centreSelect.salles[i].code,
                name: this.$store.state.centre.centreSelect.salles[i].name,
                capacite_std: this.$store.state.centre.centreSelect.salles[i].capacite_std,
                capacite_max: this.$store.state.centre.centreSelect.salles[i].capacite_max,
                type: this.$store.state.centre.centreSelect.salles[i].type,
                amenagement: this.$store.state.centre.centreSelect.salles[i].amenagement,
                centre_id: this.$store.state.centre.centreSelect.salles[i].centre_id,
                concours: this.$store.state.centre.centreSelect.salles[i].concours,
                total_capacite: this.$store.state.centre.centreSelect.salles[i].total_capacite
            })
        }
        this.salles_tp = this.salles.filter((s: SalleInterface) => s.type === TypeSalle.TP)

        // chargement des affectations
        this.$store.dispatch('affectationExaminateur/getAffectationExaminateurs', { 'filter-centre_id': this.centre_id }).then(() => {
            // on récupère la liste des affectations dont les series sont regroupées
            this.affectationExaminateursSerieGrouped = this.$store.getters['affectationExaminateur/affectationExaminateursSerieGrouped']
            this.chargementEnCours = false
        })
        for (const indexUser in this.$store.state.centre.centreSelect.centreusers) {
            const posteMatch = this.$store.state.centre.centreSelect.centreusers[indexUser].postes.filter((poste: any) => parseInt(poste.context) === PosteContext.CONTEXT_CHEF_CENTRE)
            if (posteMatch.length > 0) {
                this.gestionnaire = this.$store.state.centre.centreSelect.centreusers[indexUser].user
                break
            }
        }
    }

    /*  */
    listeSalleEditable() {
        return this.$store.state.centre && this.$store.state.centre.centreSelect && !this.$store.state.centre.centreSelect.salles_validated_at && !(this.$store.getters['auth/is_intervenant_centre'] && this.$store.state.centre.centreSelect.salles_submitted_at) && this.affectationExaminateursSerieGrouped.length !== 0;
    }

    /** Check le type du retour d'erreur */
    isObject () {
        return isObject(this.$store.state.affectationExaminateur.error)
    }

    /**
     * met à jour salle et salles_tp suite à un changement depuis ListeSalle
     */
    sallesUpdate (salles: Array<SalleInterface>) {
        this.salles = salles
        this.salles_tp = salles.filter((s: SalleInterface) => s.type === TypeSalle.TP)
    }

    /**
     * si le nombre de pb sur salles est superieurs à 0, les boutons d'enregistrement sont disabled
     */
    sallesErrorsHandler (nbErrors: number) {
        this.sallesError = nbErrors > 0;
    }

    /**
     * foramtage de la date en utilisant la fonction presente dans le helper
     */
    formatDate (date: any) {
        return formatDate(date)
    }

    /**
     * affiche la fenêtre permettant de saisir un commentaire de rejet
     */
    showModalCommentaireRejet () {
        this.$store.commit('affectationExaminateur/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
        this.showCommentaireRejet = true
        this.commentaire_rejet = ''
    }

    /**
     * ferme la fênetre de saissie de commentaire de rejets (annulation)
     */
    cancelRejet () {
        this.$store.commit('affectationExaminateur/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
        this.showCommentaireRejet = false
        this.commentaire_rejet = ''
    }

    /** *
     * affiche la fenêtre de confirmation de validation de l'organisation des oraux
     */
    showModalValidation () {
        // Check si toutes les équipes ont une salle pour chaque série
        this.errors_soumission = this.$store.getters['affectationExaminateur/check_salle_equipe_serie'](this.affectationExaminateursSerieGrouped, this.$store.state.serie.series)
        this.$store.commit('affectationExaminateur/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
        this.showModalMessageValidation = true
    }

    /** *
     * ferme la fenêtre de confirmation de validation de l'organisation des oraux
     */
    hideModalMessageValidation () {
        this.$store.commit('affectationExaminateur/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
        this.showModalMessageValidation = false
    }

    /** *
     * affiche la fenêtre de confirmation de l'invalidation de l'organisation des oraux
     */
    showModalInvalidation () {
        this.$store.commit('affectationExaminateur/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
        this.showModalMessageInvalidation = true
    }

    /** *
     * ferme la fenêtre de confirmation de l'invalidation de l'organisation des oraux
     */
    hideModalMessageInvalidation () {
        this.$store.commit('affectationExaminateur/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
        this.showModalMessageInvalidation = false
    }

    /** *
     * affiche la fenêtre de confirmation de soumission de l'organisation des oraux
     */
    showModalSoumission () {
        // Check si toutes les équipes ont une salle pour chaque série
        this.errors_soumission = this.$store.getters['affectationExaminateur/check_salle_equipe_serie'](this.affectationExaminateursSerieGrouped, this.$store.state.serie.series)
        this.$store.commit('affectationExaminateur/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
        this.showModalMessageSoumission = true
    }

    /** *
     * ferme la fenêtre de confirmation de soumission de l'organisation des oraux
     */
    hideModalMessageSoumission () {
        this.$store.commit('affectationExaminateur/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
        this.showModalMessageSoumission = false
    }

    /**
     * envoi la liste des salles modifié et la liste des affectations au serveur
     */
    enregistrer (submit = -1, validate = -1) {
        this.chargementEnCours = true
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        const payload = {
            centre_id: this.centre_id,
            salles: this.salles,
            affectationsGrouped: this.affectationExaminateursSerieGrouped,
            salles_submit: (submit === -1 ? null : submit),
            salles_validate: (validate === -1 ? null : validate),
            commentaire_rejet: this.commentaire_rejet// (submit === 0 && this.commentaire_rejet.trim() !== '' ? this.commentaire_rejet : null)
        }

        this.$store.dispatch('affectationExaminateur/updateAfectationExaminateursSalles', payload)
            .then((retour) => {
                this.showModalMessageValidation = false
                this.showModalMessageInvalidation = false
                this.showModalMessageSoumission = false
                this.showCommentaireRejet = false
                this.commentaire_rejet = ''
                this.salles = retour.data.data.salles
                this.affectationExaminateursSerieGrouped = this.$store.getters['affectationExaminateur/affectationExaminateursSerieGrouped']
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }

                this.$store.commit('centre/MAJ_COLLECTION_UPDATE', retour.data.data)
                this.loadCentre()
                this.$bvToast.toast('Modifications enregistrées.', succesToaster)
            })
            .catch((error) => {
                console.log('ko:' + error)
                this.chargementEnCours = false
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /** Retourne une classe css selon l'état de validation des infos */
    getClassBarreBoutons () {
        let class_css = 'barre_default'

        if (this.$store.state.centre.centreSelect.salles_submitted_at && !this.$store.state.centre.centreSelect.salles_validated_at) {
            class_css = 'barre_soumis'
        } else if (this.$store.state.centre.centreSelect.salles_submitted_at && this.$store.state.centre.centreSelect.salles_validated_at) {
            class_css = 'barre_valide'
        } else if (this.$store.state.centre.centreSelect.commentaire_rejet && this.$store.state.centre.centreSelect.commentaire_rejet.salles) {
            class_css = 'barre_rejet'
        }

        return class_css
    }
}

