



























































































import { Watch, Prop, Vue, Component } from 'vue-property-decorator'
import TableSelection from '@/components/TableSelection.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapGetters } from 'vuex'
import { AmenagementInterface, TypeMesure } from '@/types/Amenagement'
import { getTypeAmenagement, TypeAmenagement } from '@/types/Salle'

@Component({
    computed: {
        ...mapGetters('candidat', ['editedCandidat']),
        ...mapGetters('amenagement', ['amenagements']),
        ...mapGetters('domaine', ['domaines'])
    },
    components: {
        TableSelection,
        'font-awesome-icon': FontAwesomeIcon
    }
})
export default class SelectMesuresHandicap extends Vue {
    fields = [
        { key: 'selected', label: '', type: 'checkbox', onClickLine: true },
        { key: 'code', label: 'Code', type: 'text' },
        { key: 'name', label: 'Libellé', type: 'text' },
        { key: 'temps', label: 'Maj. temps', type: 'text' },
        { key: 'gestionPart', label: 'Gestion part.', type: 'html' },
        { key: 'placementParticulier', label: 'Placement part.', type: 'text' }
    ]

    filtres: any = []

    @Prop() showEdit?: boolean;
    @Prop() type?: string;

    candidatAmenagments: any[] = []
    selectedMesures: any[] = []
    search_mesure = ''
    search_on = false
    temp_all_amenagements: Array<AmenagementInterface> = []
    current_selected_mesures: {[id: number]: {amenagement: AmenagementInterface; commentaire: string; domaines_ids: Array<number>}} = {}
    selected_domaines_ids: Array<number> = []
    validateIsDisabled = true
    currentComment = ''

    @Watch('amenagements')
    amenagementWatcher () {
        this.initDatasAmenagements()
    }

    /**
     * Au montage du composant, initialisation des données propres aux aménagements
     * disponibles et courament selectionné pour le candidat en édition
     */
    mounted () {
        this.$store.dispatch('amenagement/getAmenagements').then(() => {
            this.temp_all_amenagements = this.$store.state.amenagement.amenagements
            this.$store.dispatch('domaine/getDomaines').then(() => {
                this.initDatasAmenagements()
                this.selected_domaines_ids = []
                for (let i = 0; i < this.$store.state.domaine.domaines.length; i++) {
                    this.selected_domaines_ids.push(this.$store.state.domaine.domaines[i].id)
                }
            })
        })
    }

    getGestionParticuliereById(id: number) {
        for (let i = 0; i < this.$store.state.gestionParticuliere.gestionParticulieres.length; i++) {
            if (id === this.$store.state.gestionParticuliere.gestionParticulieres[i].id) {
                return this.$store.state.gestionParticuliere.gestionParticulieres[i]
            }
        }
        return null
    }

    getTempsSuplementaireById(id: number) {
        for (let i = 0; i < this.$store.state.tempsSupplementaire.tempsSupplementaires.length; i++) {
            if (id === this.$store.state.tempsSupplementaire.tempsSupplementaires[i].id) {
                return this.$store.state.tempsSupplementaire.tempsSupplementaires[i]
            }
        }
        return null
    }

    /**
     * Pour chaque mesure disponible, définition de l'état de selection pour le candidat courant
     */
    initDatasAmenagements() {
        this.candidatAmenagments = []
        const allAmenagement = this.$store.getters['amenagement/amenagements']
        for (const amenagement of allAmenagement) {
            if (this.type === undefined || (this.type === 'oral' && amenagement.type === TypeMesure.TYPE_ORAL) || (this.type === 'ecrit' &&  amenagement.type === TypeMesure.TYPE_ECRIT)) {
                const currentAmenagement = Object.assign({}, amenagement)
                currentAmenagement.selected = this.current_selected_mesures[currentAmenagement.id] !== null && this.current_selected_mesures[currentAmenagement.id] !== undefined

                let placement = ''
                if (currentAmenagement.accessibilite !== TypeAmenagement.AMENAGEMENT_AUCUN) {
                    placement = getTypeAmenagement(currentAmenagement.accessibilite).libelle
                }
                currentAmenagement.placement = placement

                let gestionPart = ''

                if (amenagement.type === TypeMesure.TYPE_ECRIT) {
                    if (currentAmenagement.gestion_particulieres && currentAmenagement.gestion_particulieres.length > 0) {
                        for (let k  = 0; k < currentAmenagement.gestion_particulieres.length; k++) {
                            const gp = currentAmenagement.gestion_particulieres[k]
                            let nameGestionPart = ''
                            if (gp) {
                                nameGestionPart = gp.code
                            }
                            if (!gestionPart.includes(nameGestionPart)) {
                                gestionPart = gestionPart + '<div class="text-center" title="' + gp.name + '">' + nameGestionPart + '</div>'
                            }
                        }
                    }
                    currentAmenagement.gestionPart = gestionPart
                    currentAmenagement.temps = currentAmenagement.temps_supplementaire_id !== null ? this.getTempsSuplementaireById(currentAmenagement.temps_supplementaire_id).code + ' TEMPS' : ''
                } else if (amenagement.type === TypeMesure.TYPE_ORAL) {
                    currentAmenagement.accessibilite = amenagement.accessibilite
                    currentAmenagement.temps = amenagement.temps
                }

                let placementParticulier = ''
                if (currentAmenagement.accessibilite !== TypeAmenagement.AMENAGEMENT_AUCUN) {
                    placementParticulier = getTypeAmenagement(currentAmenagement.accessibilite).libelle
                }
                currentAmenagement.placementParticulier = placementParticulier

                this.candidatAmenagments.push(currentAmenagement)
            }
        }

        const candidatEdited = this.$store.getters['candidat/editedCandidat']

        if (candidatEdited && candidatEdited.amenagements && candidatEdited.amenagements.length > 0) {
            for (const selectedAmenagement of candidatEdited.amenagements) {
                const index = this.candidatAmenagments.findIndex(amenagement => {
                    return amenagement.id === selectedAmenagement.amenagement_id || amenagement.id === selectedAmenagement.id
                })
                if (index !== -1) {
                    // this.selected_mesures_ids.push(this.candidatAmenagments[index].id)
                    this.candidatAmenagments[index].selected = true
                    this.candidatAmenagments[index].alreadySelected = true
                    this.candidatAmenagments[index].commentaire = selectedAmenagement.commentaire
                    this.candidatAmenagments[index].domaine_ids = selectedAmenagement.domaine_ids
                }
            }
        }

        this.candidatAmenagments.sort(function (a, b) {
            return a.code - b.code
        })
    }

    /**
     * Gestion de l'évenement de selection ou de déselection d'un aménagement
     */
    changeAmenagementSelectValue(candidatAmenagment: any) {
        if (this.current_selected_mesures[candidatAmenagment.id]) {
            delete this.current_selected_mesures[candidatAmenagment.id]
        } else {
            this.current_selected_mesures[candidatAmenagment.id] = {
                amenagement: candidatAmenagment,
                commentaire : '',
                domaines_ids: []
            }
            for (const domaine of this.$store.state.domaine.domaines) {
                this.current_selected_mesures[candidatAmenagment.id].domaines_ids.push(domaine.id)
            }
        }
        this.domaineCheckChangeHandler(-1, -1)
    }

    domaineCheckChangeHandler(candidatAmenagmentId: number, domaineId: number) {
        if (candidatAmenagmentId !== -1 && domaineId !== -1) {
            const domIndex = this.current_selected_mesures[candidatAmenagmentId].domaines_ids.findIndex(value => {
                return value === domaineId
            })
            if (domIndex === -1) {
                this.current_selected_mesures[candidatAmenagmentId].domaines_ids.push(domaineId)
            } else {
                this.current_selected_mesures[candidatAmenagmentId].domaines_ids.splice(domIndex, 1)
            }
        }
        this.validateIsDisabled = false
        if (Object.keys(this.current_selected_mesures).length === 0) {
            this.validateIsDisabled = true
        } else {
            for (const idcsm in this.current_selected_mesures) {
                if (this.current_selected_mesures[idcsm].domaines_ids.length  === 0) {
                    this.validateIsDisabled = true
                }
            }
        }
        /*
        console.log('---------domaineId : ' + domaineId + '------------')
        console.log(this.current_selected_mesures)
        console.log('---------------------')
        */
    }

    /**
     * Fermeture de la modale de selection d'aménagements
     */
    cancel() {
        this.$emit('validate', null)
    }

    /**
     * Validation de la modale de selection d'aménagements
     */
    validate() {
        // this.setSelectedMesures()
        this.$emit('validate', this.current_selected_mesures)
    }

    /** Lance la requête de recherche de mesure à partir d'un mot clé */
    searchMesure (e: any) {
        e.preventDefault()
        if (this.search_mesure !== '') {
            this.search_on = true
            this.$store.dispatch('amenagement/getAmenagements', { 'filter-name': '%' + this.search_mesure + '%' })
        }
    }

    /** Réinitialise la recherche */
    reinitSearch () {
        this.search_mesure = ''
        this.search_on = false
        this.$store.dispatch('amenagement/getAmenagements')
    }

    /** Set selected mesures pour validation */
    // setSelectedMesures () {
    /*
        const unique_mesures_id = new Set(this.selected_mesures_ids)
        const tab_unique_id = Array.from(unique_mesures_id)

        for (const uid in tab_unique_id) {
            const mesure = this.temp_all_amenagements.find((amenagement: any) => amenagement.id  === tab_unique_id[uid])
            if (mesure) {
                this.selectedMesures.push(mesure)
            }
        }
        */

    // for (const uid in this.current_selected_mesures) {
    //  const mesure = this.temp_all_amenagements.find((amenagement: any) => amenagement.id  === uid)
    // mesure.commentaire =
    // if (mesure) {
    //     this.selectedMesures.push(mesure)
    // }
    // }
    // }
}
