




























































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import Multiselect from 'vue-multiselect'
import _ from 'lodash'
import { EpreuveInterface, TypePassation } from '@/types/Epreuve'
import { reclamationCorrectionAffectationMode } from '@/types/Parameter'

@Component({
    components: {
        Multiselect,
        ErrorDisplay
    },
    computed: {
        countSelectedCorrecteurs(): number {
            return this.$data.correcteurs
                ?.filter((correcteur: any): boolean => correcteur.selected)?.length || 0
        },
        error(): any {
            return this.$store.getters['epreuve/error'] || this.$store.getters['affectationCorrecteur/error'] || null
        }
    }
})

export default class PopupEnvoieRecorrection extends Vue {
    @Prop() show?: boolean

    // error: any = null
    epreuves: Array<EpreuveInterface> = []
    epreuve: EpreuveInterface = {} as EpreuveInterface
    correcteurs: Array<any> = []
    correcteursLoading = false
    processing = false

    /**
     * @description Charge les épreuves à l'ouverture de la modale
     * @param {boolean} show - État de la modale
     * @returns {Promise<void>}
     */
    @Watch('show')
    async onShowChange(show: boolean): Promise<void> {
        this.resetErrors()
        if (show) {
            if (this.$store.getters['epreuve/epreuvesCorrections'].length === 0) {
                await this.$store.dispatch('epreuve/getEpreuvesCorrections')
            }
            this.epreuves = this.$store.getters['epreuve/epreuvesCorrections']
                .filter((epreuve: EpreuveInterface): boolean => epreuve.type_passation === TypePassation.TYPE_PASSATION_ECRIT)
                .map((epreuve: any): EpreuveInterface => ({
                    ...epreuve,
                    name: `${epreuve.concours.map((concour: any) => concour.name).join(', ')} - ${epreuve.name}`
                }))
        } else {
            this.epreuves = []
            this.epreuve = {} as EpreuveInterface
            this.correcteurs = []
            this.correcteursLoading = false
        }
    }

    /**
     * @description Charge les correcteurs de l'épreuve sélectionnée
     * @param {EpreuveInterface} epreuve - Identifiant de l'épreuve
     * @returns {void}
     */
    @Watch('epreuve')
    onEpreuveChange(epreuve: EpreuveInterface): void {
        this.resetErrors()
        if (epreuve && JSON.stringify(epreuve) !== '{}') {
            this.correcteurs = []
            this.correcteursLoading = true
            this.$store.dispatch('affectationCorrecteur/getReclamationCorrecteurs', {
                "filter-epreuve_correction_id": epreuve.id
            })
                .then((response) => {
                    response.data?.forEach((correcteur: any) => {
                        correcteur.selected = false
                        correcteur.isCorrecteurResponsable = false

                        correcteur.dossier_academiques?.some((dossier: any) => {
                            // (#4610) À changer pour quelque chose de plus générique pour détecter les chefs de groupe
                            const isChefDeGroupe: boolean = [ ...new Set(dossier.poste_affectations?.map((poste: any): string => poste.name)) ]
                                .some((name: any): boolean => name?.toLowerCase().includes('chef de groupe') && name?.toLowerCase().includes('écrit'))
                            const isCoordonnateur: boolean = [ ...new Set(dossier.poste_affectations?.map((poste: any): string => JSON.parse(poste?.pivot?.options || '[]')).flat(Infinity)) ]
                                .some((option: any): boolean => option?.toLowerCase() === 'coordonnateur')

                            if (isChefDeGroupe || isCoordonnateur) {
                                correcteur.isCorrecteurResponsable = true
                                return true
                            }
                        })
                    })

                    this.correcteurs = _.orderBy(response.data, ['isCorrecteurResponsable', 'candidat_epreuve_lot_recorrection_count', 'name'], ['desc'])
                })
                .finally(() => {
                    this.correcteursLoading = false
                })
        } else {
            this.correcteursLoading = false
            this.correcteurs = []
        }
    }

    /**
     * @description Réinitialisation des variables et fermeture de la modale
     * @returns {void}
     */
    closeModal(): void {
        this.$emit('reinitShow', null)
    }

    /**
     * @description Réinitialisation des erreurs dans le store
     * @returns {void}
     */
    resetErrors(): void {
        this.$store.commit('epreuve/SET_ERROR', null)
        this.$store.commit('affectationCorrecteur/SET_ERROR', null)
    }

    /**
     * @description Envoie la liste des correcteurs sélectionnés pour la recorrection
     * @returns {void}
     */
    sendRecorrection(): void {
        if (this.epreuve && JSON.stringify(this.epreuve) === '{}' || this.processing) {
            return
        }
        this.processing = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Envoi en cours...', infosToaster)

        this.$store.dispatch('reclamationPostConcours/sendRecorrections', {
            epreuve_correction_id: this.epreuve.id,
            user_ids: this.correcteurs
                .filter((correcteur: any): boolean => correcteur.selected)
                .map((correcteur: any): number => correcteur.id)
        })
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Envoi terminé', succesToaster)
                this.closeModal()
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.processing = false
            })
    }
}
