


























































































































































































import { mapActions, mapGetters } from 'vuex'
import { Vue, Component, Watch } from 'vue-property-decorator'
import { formatDate, formatDateHoursMinutes, formatDateSinTime, format_phone_number, getFileNameFromHeader, base64ToArrayBuffer } from '@/utils/helpers'
import { Ability } from '@/types/Ability'
import _ from 'lodash'
import { Etat, getEtatSpecReclamation, TypeReclamation, TypeReponseReclamation } from '@/types/Reclamation'
import  ErrorDisplay from '@/components/ErrorDisplay.vue'
import Back from '@/components/Tools/Back.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { CandidatStatut } from '@/types/Candidat'
import VuePdfApp from 'vue-pdf-app'

@Component({
    components: {
        ErrorDisplay,
        Back,
        'font-awesome-icon': FontAwesomeIcon,
        VuePdfApp
    },
    computed: {
        Ability() {
            return Ability
        },
        CandidatStatut() {
            return CandidatStatut
        },
        TypeReponseReclamation() {
            return TypeReponseReclamation
        },
        examinateur(): any {
            const creneau: any = this.$data.reclamation?.seance?.creneau
            if (creneau) {
                const ensemble = this.$store.getters['ensemble/ensembles']
                    .find((e: any) => e.id === creneau.ensemble_id)

                if(ensemble) {
                    return ensemble.examinateurs.find((ex: any) => ex.id === creneau.user_id) ||
                        ensemble.remplacants.find((ex: any) => ex.id === creneau.user_id) ||
                        null
                } else {
                    return null
                }
            }

            return null
        },
        centre(): any {
            return this.$data.reclamation?.seance?.creneau?.ensemble?.centre || null
        },
        savedNote() {
            return this.$store.getters['reclamationPostConcours/reclamationSelect']?.note || null
        },
        totalReclamations(): number {
            return this.$store.getters['reclamationPostConcours/meta']?.total || 0
        },
        concourId(): number {
            return parseInt(this.$data.reclamation?.candidat?.resultats[1]?.concour_id || -1)
        },
        canEdit(): boolean {
            return !this.$data.processing &&
                !this.$data.loading &&
                this.$store.getters['auth/can'](Ability.ORAL_RECLAM_MANAGE) &&
                !this.$data.reclamation?.validated_at &&
                !this.$data.reclamation?.canceled_at &&
                !this.$data.reclamation?.submitted_at
        },
        ...mapGetters('concour', ['concourById']),
        ...mapGetters('reclamationPostConcours', ['reclamationSelect', 'error', 'PCSelectReclassementOpen']),
        ...mapGetters('auth', ['can', 'user_session_id']),
        ...mapGetters('reclamationType', ['reclamationTypes'])
    },
    methods: {
        formatDate,
        format_phone_number,
        formatDateHoursMinutes,
        formatDateSinTime,
        ...mapActions('postes', ['getAllPostes'])
    }
})

export default class ReclamationPostGestion extends Vue {
    reclamation: any = {}
    etatReclamationSelect: any = {}
    etatAvancement = Etat.ETAT_NON_TRAITE
    processing = false
    loading = true
    reclamationType: any = null
    reclamationTypeModal = false

    configVisionneuse = {
        toolbar: {
            toolbarViewerRight: {
                presentationMode: false,
                openFile: false,
                viewBookmark: false,
                secondaryToolbarToggle: false
            }
        }
    }

    pdfReclamationType: any = {
        name: null,
        content: null,
        id: null
    }

    /**
     * @description Récupère l'index de la réclamation actuelle
     * @returns {number}
     */
    getIndexCurrentReclamation(): number {
        if (this.$store.getters['reclamationPostConcours/reclamations_post_concours']) {
            return this.$store.getters['reclamationPostConcours/reclamations_post_concours']
                .findIndex((reclamation: any) => reclamation.id === Number(this.$route.params.reclamation_id))
        }
        return 0
    }

    /**
     * @description Navigation vers la réclamation précédente
     * @returns {void}
     */
    async previousReclamation(): Promise<void> {
        const previousReclamation = this.$store.getters['reclamationPostConcours/reclamations_post_concours'][this.getIndexCurrentReclamation() - 1] || null
        if (previousReclamation) {
            await this.$router.push('/reclamations_post_concours/' + previousReclamation.id)
        }
    }

    /**
     * @description Navigation vers la réclamation suivante
     * @returns {Promise<void>}
     */
    async nextReclamation(): Promise<void> {
        // Charger plus de réclamations si on approche de la limite par page
        const meta = this.$store.getters['reclamationPostConcours/meta']
        const modulo = (this.getIndexCurrentReclamation() + 1) % meta.per_page
        let nextReclamation = this.$store.getters['reclamationPostConcours/reclamations_post_concours'][this.getIndexCurrentReclamation() + 1] || null

        if (nextReclamation) {
            await this.$router.push('/reclamations_post_concours/' + nextReclamation.id)
        } else if (modulo === 0 && meta.current_page < meta.last_page) {
            const params = JSON.parse(localStorage.getItem('reclamationPostConcoursParams') || JSON.stringify({}))
            Vue.set(params, 'filter-type', TypeReclamation.TYPE_NOTE_ORAL)
            params.page = meta.current_page + 1

            await this.$store.dispatch('reclamationPostConcours/getMoreReclamations', { filters: params })

            localStorage.setItem('reclamationPostConcoursParams', JSON.stringify(params))
            meta.current_page = params.page
            this.$store.commit('reclamationPostConcours/SET_META', meta)

            nextReclamation = this.$store.getters['reclamationPostConcours/reclamations_post_concours'][this.getIndexCurrentReclamation() + 1] || null
            if (nextReclamation) {
                await this.$router.push('/reclamations_post_concours/' + nextReclamation.id)
            }
        }
    }

    /**
     * @description Ouverture de la modale d'aperçu du modèle de réponse
     * @returns {Promise<void>}
     */
    async openReclamationTypeModal(): Promise<void> {
        if (this.reclamationType) {
            if (!this.pdfReclamationType.name || !this.pdfReclamationType.content || this.pdfReclamationType.id !== this.reclamationType.id) {
                const response = await this.$store.dispatch('reclamation/getPDFReponseReclamation', {
                    reclamation_id: this.reclamation.id,
                    params: {
                        reclamation_type: this.reclamationType.id
                    }
                })
                this.pdfReclamationType = {
                    name: getFileNameFromHeader(response.headers),
                    content: base64ToArrayBuffer(response.data),
                    id: this.reclamationType.id
                }
            }
            this.reclamationTypeModal = true
        }
    }

    /**
     * @description Fermeture de la modale d'aperçu du modèle de réponse
     * @returns {void}
     */
    closeReclamationTypeModal(): void {
        this.reclamationTypeModal = false
    }

    /**
     * @description Enregistrement de la réclamation
     * @param {Etat} submit - Soumission de la réclamation
     * @returns {void}
     */
    saveReclamation(submit: Etat = Etat.ETAT_EN_COURS): void {
        if (this.processing || !this.reclamationType) {
            return
        }
        this.processing = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours...', infosToaster)

        const params: any = {
            reclamation_id: this.reclamation.id,
            type: TypeReclamation.TYPE_NOTE_ORAL,
            payload: {
                submit: submit,
                decision: 0,
                reclamation_type_id: this.reclamationType.id
            }
        }

        if (this.reclamationType.type_reponse === TypeReponseReclamation.TYPE_REPONSE_ACCEPTEE) {
            params.payload.decision = 1
            params.payload.note = this.reclamation.note
        }

        if (submit !== Etat.ETAT_EN_COURS && this.reclamationType.type_reponse === TypeReponseReclamation.TYPE_REPONSE_REJETEE) {
            params.payload.submit = Etat.ETAT_REJETEE
        }

        if (this.reclamationType.response_personalization) {
            params.payload.message_gestionnaire = this.reclamation.message_gestionnaire
        }

        this.$store.dispatch('reclamationPostConcours/updateReclamation', params)
            .then(async () => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé', succesToaster)
                await this.load(false)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.processing = false
            })
    }

    /**
     * @description Push vers la page des reclassements, transmet l'id de la reclamation et l'id du candidat
     * @returns {void}
     */
    async reclassementRoute(concourId:number): Promise<void> {
        this.$store.commit('reclassement/SET_RECLASSEMENT_CANDIDAT_SELECT', this.reclamation)
        const path = `/reclamations_post_concours/${this.reclamation.id}/reclassement/${this.reclamation.candidat.id}/concours/${concourId}`
        await this.$router.push({ path: path }).catch(() => console.log(`catch redundant navigation ${path}`))
    }

    /**
     * @description Aperçu de la fiche orale du candidat
     * @param {any} item - Candidat concerné
     * @returns {void}
     */
    voirFicheOral(item: any): void {
        if (item?.cle_epreuve_externe) {
            window.open(this.$store.getters['candidat/getCorrectionOnViatique'](item.cle_epreuve_externe), '_blank')
        }
    }

    /**
     * @description Chargement des données
     * @returns {Promise<void>}
     */
    @Watch('$route.params.reclamation_id')
    async load(loading = true): Promise<void> {
        this.loading = loading

        this.etatReclamationSelect = getEtatSpecReclamation(Etat.ETAT_NON_TRAITE)
        this.etatAvancement = Etat.ETAT_NON_TRAITE
        this.reclamationType = null
        this.pdfReclamationType = {
            name: null,
            content: null,
            id: null
        }

        await this.$store.dispatch('reclamationPostConcours/getReclamation', Number(this.$route.params.reclamation_id))
        this.reclamation = _.cloneDeep(this.$store.getters['reclamationPostConcours/reclamationSelect'])

        if (this.reclamation.rejected_at) {
            this.etatReclamationSelect = getEtatSpecReclamation(Etat.ETAT_REJETEE)
            this.etatAvancement = Etat.ETAT_REJETEE
        } else if ((!this.reclamation.submitted_at && !this.reclamation.validated_at) && (this.reclamation.note || this.reclamation.message_gestionnaire)) {
            this.etatReclamationSelect = getEtatSpecReclamation(Etat.ETAT_EN_COURS)
            this.etatAvancement = Etat.ETAT_NON_TRAITE
        } else if (this.reclamation.submitted_at && !this.reclamation.validated_at) {
            this.etatReclamationSelect = getEtatSpecReclamation(Etat.ETAT_TRAITE)
            this.etatAvancement = Etat.ETAT_TRAITE
        } else if (this.reclamation.submitted_at && this.reclamation.validated_at) {
            this.etatReclamationSelect = getEtatSpecReclamation(Etat.ETAT_SIGNE)
            this.etatAvancement = Etat.ETAT_SIGNE
        } else if (!this.reclamation.submitted_at && !this.reclamation.validated_at) {
            this.etatReclamationSelect = getEtatSpecReclamation(Etat.ETAT_EN_COURS)
            this.etatAvancement = Etat.ETAT_NON_TRAITE
        }

        if (this.$store.getters['reclamationType/reclamationTypes'].length === 1) {
            this.reclamationType = this.$store.getters['reclamationType/reclamationTypes'][0]
        } else {
            this.reclamationType = this.$store.getters['reclamationType/getReclamationTypeById'](this.reclamation?.reclamationType?.id) || null
        }

        if (this.reclamation && this.$store.getters['reclamationPostConcours/reclamations_post_concours'].length === 0) {
            const params = {
                ...JSON.parse(localStorage.getItem('reclamationPostConcoursParams') || JSON.stringify({})),
                page: 1,
                'filter-type': TypeReclamation.TYPE_NOTE_ORAL
            }
            await this.$store.dispatch('reclamationPostConcours/getReclamations', { filters: params })

            while (this.getIndexCurrentReclamation() === -1) {
                params.page++
                const response = await this.$store.dispatch('reclamationPostConcours/getMoreReclamations', { filters: params })
                if (response.data.data.length === 0) {
                    break
                }

                const meta = this.$store.getters['reclamationPostConcours/meta']
                meta.current_page = params.page
                this.$store.commit('reclamationPostConcours/SET_META', meta)
            }
        }

        if(this.reclamation.new_results && this.reclamation.new_results.length > 0) {
            for(let i = 0; i < this.reclamation.new_results.length; i++) {
                await this.$store.dispatch('rankingGroup/getRankingGroupsConcourPhase', { concour_id: this.reclamation.new_results[i].concour_id, phase_id: this.reclamation.new_results[i].phase_id }).then((response) => {
                    const retour = response.data.data.find((oa: any) => oa.id === this.reclamation.new_results[i].ranking_group_id)
                    if (retour) {
                        this.reclamation.new_results[i].ranking_group_name = retour.name
                    } else {
                        this.reclamation.new_results[i].ranking_group_name =  '-'
                    }
                })
            }
        }

        this.loading = false
    }

    loadConcoursIfNotExists () {
        return new Promise((resolve) => {
            if (this.$store.getters['concour/concours'].length === 0) {
                this.$store.dispatch('concour/getConcoursActifs').then(() => {
                    resolve(true)
                })
            } else {
                resolve(true)
            }
        })
    }

    async mounted(): Promise<void> {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            if (this.$store.getters['ensemble/ensembles'].length === 0) {
                await this.$store.dispatch('ensemble/getEnsembles')
            }

            await this.loadConcoursIfNotExists()

            await this.$store.dispatch('reclamationType/getReclamationTypes', { 'filter-type_reclamation':  TypeReclamation.TYPE_NOTE_ORAL })
            await this.load()
        }
    }
}
