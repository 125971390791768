













































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import store from '@/store/index'
import Back   from '@/components/Tools/Back.vue'

// import Editor from '@/components/Tools/Editor.vue'
import EditorTinyMCE from '@/components/Tools/EditorTinyMCE.vue'
import VuePdfApp from 'vue-pdf-app'
import { base64ToArrayBuffer } from '@/utils/helpers'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { Ability } from '@/types/Ability'

@Component({
    components: {
        EditorTinyMCE,
        VuePdfApp,
        Back,
        ErrorDisplay,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('avenanttype', ['avenanttype_select', 'loading', 'totalRows', 'lastPage', 'source_pdf']),
        ...mapState('avenanttype', ['error_avenant']),
        ...mapGetters('poste', ['postes']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    },
    beforeRouteEnter (to: any, from: any, next: any) {
        store.commit('avenanttype/SET_AVENANT_TYPE', null)
        store.dispatch('poste/getAllPostes')
        if (to.params.avenant_type_id !== '0') {
            store.dispatch('avenanttype/getAvenantType', { avenant_type_id: to.params.avenant_type_id })
            next()
        } else {
            next()
        }
    }
})
export default class EditionAvenantType extends Vue {
    Ability = Ability

    // DATAS
    modePreview = false
    avenant_name = ''
    body_text = ''
    footer_text = ''
    poste_select = {
        id: 0,
        name: '',
        entity_type: '',
        type_passation: 0,
        options: null
    }

    associations: Array<any> = []
    option_select: Array<any> = []
    liste_associations: Array<any> = []
    config = {
        toolbar: {
            toolbarViewerRight: { presentationMode: false, openFile: false, viewBookmark: false, secondaryToolbarToggle: false }
        }
    }

    source_doc: any = ''

    @Watch('avenanttype_select')
    setContratSelect () {
        if (this.$store.state.avenanttype.avenanttype_select) {
            this.avenant_name = this.$store.state.avenanttype.avenanttype_select.name
            this.body_text = this.$store.state.avenanttype.avenanttype_select.body
            this.footer_text = this.$store.state.avenanttype.avenanttype_select.footer
        }
        if (!this.$store.getters['auth/can'](Ability.RH_DOSADM_MANAGE)) {
            this.apercuAvenantType()
        }
    }

    // METHODS

    // Récupère le texte du body depuis Editor
    setBodyText (bodyText: string) {
        this.body_text = bodyText
    }

    // Récupère le texte du footer depuis Editor
    setFooterText (footerText: string) {
        this.footer_text = footerText
    }

    // Save or update le type d'avenant
    saveAvenantType () {
        this.$store.state.avenanttype.error = null
        const datas = {
            name: this.avenant_name,
            avenantable_type: 'App\\Models\\ContratDossierAdministratif',
            body: this.body_text,
            footer: this.footer_text,
            associations: JSON.stringify(this.associations)
        }

        if (this.$store.state.avenanttype.avenanttype_select) {
            // update
            this.$store.dispatch('avenanttype/updateAvenantType', { avenanttype_id: this.$store.state.avenanttype.avenanttype_select.id, payload: datas })
                .then(() => {
                    if (!this.$store.state.avenanttype.error) {
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }
                        this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                    }
                })
        } else {
            // Création d'un type d'avenant
            this.$store.dispatch('avenanttype/saveAvenantType', datas)
                .then(() => {
                    if (!this.$store.state.avenanttype.error) {
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }
                        this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                        this.$router.push('/avenants_types/' + this.$store.state.avenanttype.avenanttype_select.id)
                    }
                })
        }
    }

    // Aperçu du pdf
    apercuAvenantType () {
        this.$store.dispatch('avenanttype/getPDF', this.$store.state.avenanttype.avenanttype_select.id).then(() => {
            this.source_doc = base64ToArrayBuffer(this.$store.state.avenanttype.source_pdf)
            this.modePreview = true
        })
    }

    // Ferme l'aperçu du pdf
    closeModePreview () {
        this.modePreview = false
        this.$store.state.avenanttype.source_pdf = ''
    }

    // Ajoute une association
    addAssociation () {
        this.associations.push({ poste_id: this.poste_select.id, options: this.option_select })
        this.liste_associations.push({ poste: this.poste_select, option: this.option_select })
        this.resetSelection()
    }

    // Reinit la selection
    resetSelection () {
        this.poste_select = {
            id: 0,
            name: '',
            entity_type: '',
            type_passation: 0,
            options: null
        }

        this.option_select = []
    }

    // Selectionne une option pour le poste
    selectOption (e: any) {
        this.option_select.push(e.target.value)
    }

    // Supprimer une association
    deleteAssociation (index: any) {
        this.associations.splice(index, 1)
        this.liste_associations.splice(index, 1)
    }

    // build associations
    buildAssociations () {
        this.liste_associations = []
        for (const a in this.associations) {
            const poste = this.$store.state.poste.postes.find((p: any) => p.id === this.associations[a].poste_id)
            this.liste_associations.push({ poste: poste, option: this.associations[a].options })
        }
    }
}
