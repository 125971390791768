


























import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { BModal } from 'bootstrap-vue';
import { Component, Vue } from 'vue-property-decorator';
import { getError } from '../../../utils/helpers';
import ErrorDisplay from '../../ErrorDisplay.vue';

@Component({
    name: 'PopupImportComponent',
    components: {
        BModal,
        FontAwesomeIcon,
        ErrorDisplay
    },
    props: {
        title: {
            type: String,
            required: true
        },
        target: {
            type: String,
            required: true
        },
        refresh: {
            type: String,
            required: false
        },
        refreshParams: {
            type: Object,
            required: false
        } as any
    }
})

export default class PopupImport extends Vue {
    show = false
    processing = false
    error = null as null | Error
    file = null as null | File

    open() {
        const input: HTMLInputElement = document.getElementById('input-file') as HTMLInputElement
        if (input) {
            input.value = ''
        }
        this.processing = false
        this.error = null
        this.file = null
        this.show = true
    }

    updateFile(event: any): void {
        this.error = null
        this.file = event.target.files[0]
    }

    send() {
        if (this.processing) {
            return
        }
        this.processing = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Importation en cours...', infosToaster)

        this.$store.dispatch(this.$props.target, this.file)
            .then(async () => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Importation terminée !', succesToaster)

                if (this.$props.refresh) {
                    await this.$store.dispatch(this.$props.refresh, this.$props.refreshParams)
                }
                this.close()
            })
            .catch((error) => {
                this.error = getError(error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.processing = false
            })
    }

    close() {
        this.show = false
    }
}
