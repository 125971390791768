export enum TypeEtiquette {
    TYPE_ENVELOPPE,
    TYPE_MANCHON,
    TYPE_CANTINE
}

export enum TypeGenerationEtiquette {
    TYPE_FILIERE,
    TYPE_ZONE,
    TYPE_CENTRE,
    TYPE_SALLE,
    TYPE_TOURNEE
}

export enum TypeCandidat {
    TYPE_AVEC_AMENAGEMENT,
    TYPE_SANS_AMENAGEMENT
}

export function getLibelleEtiquette(type: TypeEtiquette): string {
    switch (type) {
        case TypeEtiquette.TYPE_ENVELOPPE:
            return 'Enveloppe';
        case TypeEtiquette.TYPE_MANCHON:
            return 'Manchon';
        case TypeEtiquette.TYPE_CANTINE:
            return 'Cantine';
        default:
            return '';
    }
}

export function getLibelleGenerationEtiquette(type: TypeGenerationEtiquette): string {
    switch (type) {
        case TypeGenerationEtiquette.TYPE_FILIERE:
            return 'Filière';
        case TypeGenerationEtiquette.TYPE_ZONE:
            return 'Zone';
        case TypeGenerationEtiquette.TYPE_CENTRE:
            return 'Centre';
        case TypeGenerationEtiquette.TYPE_SALLE:
            return 'Salle';
        case TypeGenerationEtiquette.TYPE_TOURNEE:
            return 'Tournée';
        default:
            return '';
    }
}

export function getLibelleCandidat(type: TypeCandidat): string {
    switch (type) {
        case TypeCandidat.TYPE_AVEC_AMENAGEMENT:
            return 'Avec aménagement';
        case TypeCandidat.TYPE_SANS_AMENAGEMENT:
            return 'Sans aménagement';
        default:
            return '';
    }
}
