



























































































































































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import Table from '@/components/Table.vue'
import { Ability } from '@/types/Ability'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import { codeRestrict } from '@/utils/helpers'


import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'


@Component({
    components: {
        Table,
        Datetime,
        ExaGenericTable
    },
    computed: {
        ...mapGetters('configPhase', ['configPhases']),
        ...mapGetters('concour', ['loading', 'error', 'banques', 'meta', 'links', 'totalRows']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})
export default class Banques extends Vue {
    codeRestrict = codeRestrict
    session_id: any = 0
    currentSession: any = null
    actionLimited = false

    params = 'sort=name&direction=asc'

    // DATAS
    dataForTab: Array<any> = []
    filtres: any = []

    genericfields = [
        { key: 'etatEdit', label: '', sortable: false, class: '', type: 'action' },
        { key: 'code', label: 'Code', sortable: true, class: 'text-left col-min-width', type: 'text' },
        { key: 'long_name', label: 'Libellé long', sortable: true, sortDirection: 'asc', class: 'text-left', type: 'text' },
        { key: 'name', label: 'Libellé court', sortable: true, sortDirection: 'asc', class: 'text-left', type: 'text' },
        { key: 'ordre', label: 'N° d\'ordre', sortable: true, sortDirection: 'asc', class: 'text-center ps-4' },
        { key: 'color', label: 'Couleur', sortable: false, sortDirection: 'asc', class: '' },
        { key: 'phases', label: 'Phases', sortable: true, class: 'text-left', type: 'text' },
        { key: 'delete', label: '', sortable: false, class: '', type: 'action' }
    ]

    fields = [
        {
            key: 'actionEdit',
            label: '',
            sortable: false,
            sortDirection: 'asc',
            tdClass: 'col-w-etat btn_action_ligne'
        },
        { key: 'code', label: 'Code', sortable: true, sortDirection: 'asc', class: 'text-start ps-4' },
        { key: 'long_name', label: 'Libellé long', sortable: true, sortDirection: 'asc', class: 'text-start ps-4' },
        { key: 'name', label: 'Libellé court', sortable: true, sortDirection: 'asc', class: 'text-start ps-4' },
        { key: 'ordre', label: 'N° d\'ordre', sortable: true, sortDirection: 'asc', class: 'text-center ps-4' },
        { key: 'color', label: 'Couleur', sortable: false, sortDirection: 'asc', class: '' },
        {
            key: 'phases',
            label: 'Phases',
            sortable: true,
            sortDirection: 'asc',
            class: 'text-start ps-4',
            formatter: function (cell: any) {
                let retour = ''
                for (let i = 0; i < cell.length; i++) {
                    if (retour !== '') {
                        retour += '/'
                    }
                    retour += cell[i].name
                }
                return retour
            }
        },
        {
            key: 'delete',
            label: '',
            sortable: false,
            sortDirection: 'asc',
            tdClass: 'text-end'
        }
    ]

    /*
    filtres = [
        {
            libelle: 'Code',
            defautOptionlibelle: 'Rechercher un',
            model: 'code',
            value: '',
            index: 'code',
            datas: null, // this.$store.state.user.users.name,
            loading: false,
            options: { type: 'form', fieldsKey: 'code', strict: true } // 'form' , 'deroulant'
        },
        {
            libelle: 'Libellé long',
            defautOptionlibelle: 'Rechercher un',
            model: 'long_name',
            value: '',
            index: 'long_name',
            datas: null, // this.$store.state.user.users.name,
            loading: false,
            options: { type: 'form', fieldsKey: 'long_name' } // 'form' , 'deroulant'
        },
        {
            libelle: 'Libellé court',
            defautOptionlibelle: 'Rechercher un',
            model: 'name',
            value: '',
            index: 'name',
            datas: null, // this.$store.state.user.users.name,
            loading: false,
            options: { type: 'form', fieldsKey: 'name' } // 'form' , 'deroulant'
        },
        {
            libelle: 'N° d\'ordre',
            defautOptionlibelle: 'Rechercher un',
            model: 'ordre',
            value: '',
            index: 'ordre',
            datas: null, // this.$store.state.user.users.name,
            loading: false,
            options: { type: 'form', fieldsKey: 'ordre', strict: true } // 'form' , 'deroulant'
        },
        {
            libelle: 'Phase',
            defautOptionlibelle: 'Rechercher une',
            model: 'phases.name',
            value: '',
            index: 'id',
            datas: null,
            loading: false,
            options: { type: 'form', fieldsKey: 'phases' } // 'form' , 'deroulant'
        }
    ]
    */

    sortBy = ''
    sortDesc = false
    sortDirection = 'asc'
    filter = ''
    filterOn = []
    stickyHeader = true
    banqu: any = null
    banqueTemp: any = null
    Ability = Ability

    showModalEditionBanque = false
    showModalMessageDelete = false

    // METHODS

    // ---- generic table --------------------------

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setDataForGenericTab(poData: any, isLoadMore = false) {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const result of poData) {
                let phases = ''
                for (let i = 0; i < result.phases.length; i++) {
                    if (phases !== '') {
                        phases += ' / '
                    }
                    phases += result.phases[i].name
                }
                const classEtatEdit = 'text-light col-w-etat ' + (!result.closed_at ? 'btn_action_ligne' : 'bg-secondary')
                const iconEtatEdit = !result.closed_at ? this.canEdit() ? 'pen' : 'eye' : 'lock'

                let color = '-'
                if (result.color) {
                    color = '<div class="left-panel"><span style="color: #'+ result.color +'!important;"><font-awesome-icon icon="circle"/></span><div class="right-panel">#'+ result.color +'</div>'
                }

                const line = [
                    { label: '', item: result, icon: iconEtatEdit, type: 'action', typeAction: 'etatEdit', class: classEtatEdit, disabled: false },
                    { label: '', item: result.code, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.long_name, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.name, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.ordre, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: color, type: 'html', typeAction: null, class: '' },
                    { label: '', item: phases, type: 'text', typeAction: null, class: '' }
                ]

                if (this.canEdit()) {
                    const trashLine =  { label: 'Supprimer',   item: result, type: 'action',  typeAction: 'delete',  class: 'text-secondary', icon:'trash-alt', disabled: false }
                    line.push(trashLine)
                }
                this.dataForTab.push(line)
            }
        }
        return this.dataForTab
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setFiltersForGenericTab() {
        this.filtres = [
            {
                libelle: 'Code',
                defautOptionlibelle: 'Rechercher un',
                model: 'code',
                value: '',
                index: 'code',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'code', strict: true } // 'form' , 'deroulant'
            },
            {
                libelle: 'Libellé long',
                defautOptionlibelle: 'Rechercher un',
                model: 'long_name',
                value: '',
                index: 'long_name',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'long_name' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Libellé court',
                defautOptionlibelle: 'Rechercher un',
                model: 'name',
                value: '',
                index: 'name',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'name' } // 'form' , 'deroulant'
            },
            {
                libelle: 'N° d\'ordre',
                defautOptionlibelle: 'Rechercher un',
                model: 'ordre',
                value: '',
                index: 'ordre',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'ordre', strict: true } // 'form' , 'deroulant'
            },
            {
                libelle: 'Phase',
                defautOptionlibelle: 'Rechercher une',
                model: 'phases.name',
                value: '',
                index: 'id',
                datas: null,
                loading: false,
                options: { type: 'form', fieldsKey: 'phases' } // 'form' , 'deroulant'
            }
        ]
    }

    /**
     * Récupération des events de la table
     */
    handleTableEvent (paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'edit':
                case 'openComment':
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'etatEdit':
                    this.editBanque(paParams[1])
                    break
                case 'delete':
                    this.deleteBanque(paParams[1])
                    break
            }
        }
    }

    // --------------------------------------

    canEdit () {
        if (this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE) && this.currentSession && this.currentSession.closed_at === null && this.currentSession.structure_valide === 0) {
            return true
        } else {
            return false
        }
    }

    openAddBanque () {
        this.banqueTemp = {
            id: 0,
            code: '',
            code_import: '',
            name: '',
            long_name: '',
            ordre: 1,
            color: null,
            config_phase_id: 0
        }
        this.showModalEditionBanque = true
    }

    newBanquePhaseCHangeHandler () {
        for (let i = 0; i < this.$store.state.configPhase.configPhases.length; i++) {
            if (this.$store.state.configPhase.configPhases[i].id === this.banqueTemp.config_phase_id) {
                const phasesTemp = []
                for (let j = 0; j < this.$store.state.configPhase.configPhases[i].phases.length; j++) {
                    phasesTemp.push({
                        ajustements_validated_at: null,
                        barre_valide: 0,
                        barres: null,
                        bonus: null,
                        end_at: null,
                        name: this.$store.state.configPhase.configPhases[i].phases[j].name,
                        phase_id: this.$store.state.configPhase.configPhases[i].phases[j].id,
                        start_at: null,
                        type_deliberation: 0
                    })
                }
                this.banqueTemp.phases = phasesTemp
            }
        }

        /*

        ajustements_validated_at: null
        barre_valide: 0
        barres: null
        bonus: null
        end_at: null
        name: "Admissibilité"
        phase_id: 1
        start_at: null
        type_deliberation: 0

        */

        /*

       ajustements_validated_at: null
        barre_valide: 0
        barres: null
        bonus: null
        end_at: null
        name: "Admission"
        phase_id: 2
        start_at: null
        type_deliberation: 0

        */
    }

    editBanque (data: any) {
        this.banqu = data
        this.banqueTemp = {
            id_phase_en_cours: this.banqu.id_phase_en_cours,
            id: this.banqu.id,
            code: this.banqu.code,
            code_import: this.banqu.code_import,
            name: this.banqu.name,
            long_name: this.banqu.long_name,
            ordre: this.banqu.ordre,
            color: this.banqu.color,
            config_phase_id: this.banqu.config_phase_id,
            phases: JSON.parse(JSON.stringify(this.banqu.phases))
        }
        this.showModalEditionBanque = true
    }

    cancelEdit () {
        this.banqu = null
        this.banqueTemp = null
        this.showModalEditionBanque = false
    }

    editSuite () {
        const payload = {
            id_phase_en_cours: this.banqueTemp.id_phase_en_cours,
            session_id: this.session_id,
            id: this.banqueTemp.id,
            code: this.banqueTemp.code,
            code_import: this.banqueTemp.code_import,
            name: this.banqueTemp.name,
            long_name: this.banqueTemp.long_name,
            ordre: this.banqueTemp.ordre,
            color: this.banqueTemp.color,
            phases: this.banqueTemp.phases,
            config_phase_id: this.banqueTemp.config_phase_id
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        if (this.banqueTemp.id === 0) {
            this.$store.dispatch('concour/addConcour', payload)
                .then(() => {
                    this.banqu = null
                    this.banqueTemp = null
                    this.showModalEditionBanque = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                    this.$store.dispatch('concour/getConcours',  { session_id: this.session_id, params: this.params }).then(() => {
                        this.setDataForGenericTab(this.$store.getters['concour/banques'])
                    }).catch((error) => {
                        console.log('ko:' + error)
                    })
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            this.$store.dispatch('concour/updateConcour', payload)
                .then(() => {
                    this.banqu = null
                    this.banqueTemp = null
                    this.showModalEditionBanque = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                    this.$store.dispatch('concour/getConcours',  { session_id: this.session_id, params: this.params }).then(() => {
                        this.setDataForGenericTab(this.$store.getters['concour/banques'])
                    }).catch((error) => {
                        console.log('ko:' + error)
                    })
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    deleteBanque (data: any) {
        this.banqu = data
        this.showModalMessageDelete = true
    }

    cancelDelete () {
        this.banqu = null
        this.showModalMessageDelete = false
    }

    deleteSuite () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Suppression en cours ...', infosToaster)
        this.$store.dispatch('concour/deleteConcour', this.banqu.id)
            .then(() => {
                this.banqu = null
                this.banqueTemp = null
                this.showModalEditionBanque = false
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Suppression terminée.', succesToaster)
                this.banqu = null
                this.showModalMessageDelete = false
                this.$store.dispatch('concour/getConcours',  { session_id: this.session_id, params: this.params }).then(() => {
                    this.setDataForGenericTab(this.$store.getters['concour/banques'])
                }).catch((error) => {
                    console.log('ko:' + error)
                })
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    loadHandler (params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)  || (JSON.stringify(this.params) === JSON.stringify(params))) {
            this.params = params
            this.$store.dispatch('concour/getMoreConcours', { session_id: this.session_id, params: params }).then(() => {
                this.setDataForGenericTab(this.$store.getters['concour/banques'])
            }).catch((error) => {
                console.log('ko:' + error)
            })
        }
    }

    // Appelle une page lors du scroll
    filtreSortHandler (params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.$store.dispatch('concour/getConcours',  { session_id: this.session_id, params: params }).then(() => {
                this.setDataForGenericTab(this.$store.getters['concour/banques'])
            }).catch((error) => {
                console.log('ko:' + error)
            })
        }
    }

    mountedSuite() {
        this.currentSession = this.$store.state.session.sessionSelect
        this.$store.dispatch('configPhase/getConfigPhases').then(() => {
            this.setFiltersForGenericTab()
            this.setDataForGenericTab(this.$store.getters['concour/banques'])
        }).catch((error) => {
            console.log('ko:' + error)
        })
    }

    mounted () {
        if (this.$route && this.$route.params && this.$route.params.session_id) {
            this.currentSession = null
            this.session_id = this.$route.params.session_id
            this.$store.dispatch('session/getSession', { session_id: this.session_id }).then(() => {
                this.mountedSuite()
            }).catch((error) => {
                console.log('ko:' + error)
            })
        } else {
            this.mountedSuite()
        }
    }
}
