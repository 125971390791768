












import { Vue, Component } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import AdressesCentreBis from '@/components/Centre/AdressesCentreBis.vue'
import MessageCandidatsCentreBis from '@/components/Centre/MessageCandidatsCentreBis.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapState('centre', ['centreSelect', 'loading', 'save_in_progress']),
        ...mapGetters('centre', ['informations_is_submit', 'informations_is_validate']),
        ...mapState('organisationecrit', ['new_id', 'error'])
    },
    components: {
        AdressesCentreBis,
        MessageCandidatsCentreBis,
        ErrorDisplay
    }
})
export default class InformationCentre extends Vue {
    mode_edit_adresse = false

    /** 
     * modeEditAdresseHandler 
     * Cette fonction permet de modifier le mode d'édition de l'adresse. 
     *  
     * @param edit (boolean) - Un booléen indiquant si le mode d'édition est activé ou désactivé. 
     */
    modeEditAdresseHandler(edit: boolean) {
        this.mode_edit_adresse = edit
    }
}

