





















































































































































































import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { BModal } from 'bootstrap-vue'
import VuePdfApp from 'vue-pdf-app'
import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { Ability } from '../../../types/Ability'
import { CandidatJobDescriptionStatus, getCandidatJobDescriptionStatus } from '../../../types/CandidatJobDescription'
import { getJobDescriptionStatus } from '../../../types/JobDescription'
import { base64ToArrayBuffer, dateisSameOrBefore, formatDateVariante } from '../../../utils/helpers'
import PopupEditUser from '../../Administration/Users/PopupEditUser.vue'
import PopupEditCandidat from '../../Candidat/PopupEditCandidat.vue'
import ErrorDisplay from '../../ErrorDisplay.vue'
import EditorTinyMCE from '../../Tools/EditorTinyMCE.vue'
import PopupAddCandidature from '../Popup/PopupAddCandidature.vue'
import PopupHistorique from '../Popup/PopupHistorique.vue'
import { WorkingEstablishmentInterface } from '@/types/WorkingEstablishment'

@Component({
    name: 'ListeCandidaturesComponent',
    methods: {
        getJobDescriptionStatus,
        formatDateVariante
    },
    components: {
        ExaGenericTable,
        PopupEditUser,
        FontAwesomeIcon,
        VuePdfApp,
        ErrorDisplay,
        EditorTinyMCE,
        PopupEditCandidat,
        PopupAddCandidature,
        BModal,
        PopupHistorique
    },
    computed: {
        ...mapGetters('candidatJobDescription', ['candidatJobDescriptions', 'loading', 'totalRows', 'lastPage', 'totalPage', 'error', 'meta']),
        ...mapGetters('auth', ['authUser', 'can']),
        canAddCandidacy(): boolean {
            const can = this.$store.getters['auth/can']
            if (can(Ability.ADM_ESTABLISHMENT_MANAGE)) {
                return true
            }

            const workingEstablishmentsIds = this.$store.getters['user/reportingUserSelect']?.working_establishments?.map((item: any) => item.id)
            if (can(Ability.EST_ESTABLISHMENT_PARENT_OWN_MANAGE) || (can(Ability.EST_ESTABLISHMENT_OWN_MANAGE) && workingEstablishmentsIds?.includes(this.$props.establishment?.id) && can(Ability.EST_CANDIDACY_OWN_MANAGE))) {
                return true
            }

            return false
        }
    },
    watch: {
        candidatJobDescriptions: {
            handler: 'onCandidatJobDescriptionsChange'
        }
    },
    props: {
        establishment: {
            type: Object as () => WorkingEstablishmentInterface,
            required: false,
            default: null
        }
    }
})

export default class ListeCandidatures extends Vue {
    Ability = Ability
    filtres: any = []
    filtreJustInitiated = false
    dataForTab: Array<any> = []
    genericfields: Array<any> = []
    showCandidatModal = false
    showAddCandidature = false
    showJobModal = false
    showEditStatus = false
    showConfirmEditStatus = false
    showJobDocument = false
    jobToShow: any = null
    candidatureLoading = false
    statusToEdit: any = null
    candidatureStatus: any = null
    currentStatus: any = null
    search = ''
    errorKeeper = null
    lastSave: any = null
    jobVisibility: any = {
        start_at: null,
        end_at: null
    }

    jobCandidature: any = {
        start_at: null,
        end_at: null
    }

    documentToShow: any = {
        name: null,
        content: null
    }

    config = {
        toolbar: {
            toolbarViewerRight: {
                presentationMode: false,
                openFile: false,
                viewBookmark: false,
                secondaryToolbarToggle: false
            }
        }
    }

    /**
     * @description Vérifie si l'utilisateur peut éditer
     * @returns {boolean}
     */
    canEdit(): boolean {
        const can = this.$store.getters['auth/can']
        if (can(Ability.ADM_ESTABLISHMENT_MANAGE)) {
            return true
        }
        if (can(Ability.EST_CANDIDACY_OWN_MANAGE)) {
            const session = this.$store.getters['session/sessions']
                .find((session: any) => session.id === this.$store.getters['auth/user_session_id'])
            const startAt = session.job_descriptions_start_at
            const endAt = session.job_descriptions_end_at

            if (startAt && endAt) {
                const start = new Date(startAt)
                const end = new Date(endAt)
                const now = new Date()

                if (now >= start && now <= end) {
                    return true
                }
            }
        }
        return false
    }

    /**
     * @description Chargement des données
     * @returns {Promise<void>}
     */
    async loadDatas(): Promise<void> {
        const params: any = {
            page: 1,
            sort: 'name',
            direction: 'asc'
        }

        if (this.isInDetailEstablishment()) {
            params['filter-working_establishment_id'] = this.$route.params.working_establishment_id
        }
        localStorage.setItem('candidatureParams', JSON.stringify(params))

        await this.$store.dispatch('candidatJobDescription/getCandidatJobDescriptions', params)
    }

    /**
     * @description Mise à jour du tableau des fiches de poste
     * @returns {void}
     */
    onCandidatJobDescriptionsChange(): void {
        this.setDataForGenericTab(this.$store.getters['candidatJobDescription/candidatJobDescriptions'])
    }

    /**
     * @description Ouverture de la modale d'ajout de candidature
     * @returns {Promise<void>}
     */
    async openAddCandidature(): Promise<void> {
        this.showAddCandidature = true
    }

    /**
     * @description Fermeture de la modale d'ajout de candidature
     * @returns {void}
     */
    closeAddCandidature(): void {
        this.showAddCandidature = false
    }

    /**
     * @description Ouvre la modale de consultation des informations du candidat
     * @param {any} candidat - Candidat à consulter
     * @returns {void}
     */
    openCandidatModal(candidat: any): void {
        this.$store.commit('candidatJobDescription/SET_LOADING', true)
        this.$store.dispatch('candidat/getCandidat', { id: candidat.id })
            .then(() => {
                this.$store.commit('candidat/SET_SELECTED_CANDIDAT', candidat.id)
                this.showCandidatModal = true
            })
            .finally(() => {
                this.$store.commit('candidatJobDescription/SET_LOADING', false)
            })
    }

    /**
     * @description Fermeture de la modale de consultation des informations du candidat
     * @returns {void}
     */
    closeCandidatModal() {
        this.showCandidatModal = false
    }

    /**
     * @description Ouverture de la modale de consultation de fiche de poste
     * @param {any} job - Fiche de poste à consulter
     * @returns {void}
     */
    openJobModal(job: any): void {
        this.$store.commit('candidatJobDescription/SET_ERROR', null)
        this.jobToShow = JSON.parse(JSON.stringify(job))
        this.showJobModal = true
    }

    /**
     * @description Fermeture de la modale d'ajout de fiche de poste
     * @returns {void}
     */
    closeJobModal(): void {
        this.showJobModal = false
        this.jobToShow = null
        this.$store.commit('candidatJobDescription/SET_ERROR', null)
    }

    /**
     * @description Ouverture de la modale de consultation d'un document de la fiche de poste
     * @param {any} job - Fiche de poste
     * @param {number} index - Index du document
     * @returns {Promise<void>}
     */
    async openJobDocument(job: any, index: number): Promise<void> {
        this.documentToShow.name = job.documents[index].name
        const response = await this.$store.dispatch('jobDescription/getJobDocument', {
            job_id: job.id, document_uuid: job.documents[index].uuid
        })
        this.documentToShow.content = base64ToArrayBuffer(response.data)
        this.showJobDocument = true
    }

    /**
     * @description Fermeture de la modale de consultation d'un document de la fiche de poste
     * @returns {void}
     */
    closeJobDocument(): void {
        this.showJobDocument = false
        this.documentToShow = {
            name: null,
            content: null
        }
    }

    /**
     * @description Ouverture de la modale d'édition du statut de la candidature
     * @param {any} candidature - Candidature à modifier
     * @returns {void}
     */
    openEditStatus(candidature: any): void {
        this.statusToEdit = candidature
        this.currentStatus = JSON.parse(JSON.stringify(candidature.status))
        this.showEditStatus = true
    }

    /**
     * @description Fermeture de la modale d'édition du statut de la candidature
     * @returns {void}
     */
    closeEditStatus(): void {
        this.showEditStatus = false
        this.statusToEdit = null
        this.currentStatus = null
    }

    /**
     * @description Ouverture de la confirmation de la modification du statut de la candidature
     * @returns {void}
     */
    openConfirmEditStatus(): void {
        this.showConfirmEditStatus = true
    }

    /**
     * @description Confirmation de la modification du statut de la candidature
     * @returns {void}
     */
    confirmEditStatus(): void {
        this.closeConfirmEditStatus()
        this.updateStatus(false)
    }

    /**
     * @description Fermeture de la confirmation de la modification du statut de la candidature
     * @returns {void}
     */
    closeConfirmEditStatus(): void {
        this.showConfirmEditStatus = false
    }

    /**
     * @description Mise à jour du statut de la candidature
     * @returns {void}
     */
    updateStatus(checkCandidaciesValidated = true): void {
        if (this.candidatureLoading) {
            return
        }

        if (checkCandidaciesValidated && this.statusToEdit.status === CandidatJobDescriptionStatus.STATUS_ACCEPTED) {
            const otherCandidacies = this.statusToEdit.jobDescription.candidaciesValidated
                .filter((candidature: any) => candidature.id !== this.statusToEdit.id)

            if (otherCandidacies.length > 0) {
                this.openConfirmEditStatus()
                return
            }
        }

        this.candidatureLoading = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.$store.dispatch('candidatJobDescription/putCandidatJobDescriptionStatus', {
            id: this.statusToEdit.id,
            status: this.statusToEdit.status
        })
            .then(async () => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)

                this.setDataForGenericTab(this.$store.getters['candidatJobDescription/candidatJobDescriptions'])
            })
            .finally(() => {
                this.closeEditStatus()
                this.candidatureLoading = false
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Tronquer une chaîne de caractères
     * @param {string} str - Chaîne de caractères à tronquer
     * @param {number} length - Longueur maximale de la chaîne
     * @returns {string} - Chaîne tronquée
     */
    sliceString(str: string, length = 100): string {
        if (str) {
            const temp = document.createElement('div')
            temp.innerHTML = str
            str = temp.textContent || str
            temp.remove()
            str = str.replace(/\s\s+/g, ' ')

            return str.length > length ? `${str.slice(0, length)}...` : str
        }
        return '-'
    }

    /**
     * @description Remplissage du tableau des fiches de poste
     * @param {any} poData - Données à afficher
     * @param {boolean} isLoadMore - Indique si on charge plus de données
     * @returns {void}
     */
    setDataForGenericTab(poData: any, isLoadMore = false): void {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            const can = this.$store.getters['auth/can']
            const canViewCandidacy = can(Ability.CAND_VIEW)
            const canViewJob = can(Ability.EST_JOB_OWN_VIEW) || can(Ability.ADM_ESTABLISHMENT_VIEW)
            const canViewEstablishment = can(Ability.EST_ESTABLISHMENT_OWN_VIEW) || can(Ability.ADM_ESTABLISHMENT_VIEW)
            const canViewChildEstablishment = can(Ability.EST_ESTABLISHMENT_PARENT_OWN_VIEW) || can(Ability.ADM_ESTABLISHMENT_VIEW)

            for (const result of poData) {
                const candidat: string = result.candidat ? `${result.candidat.name} ${result.candidat.first_name}` : '-'
                const status = getCandidatJobDescriptionStatus(result.status)
                const statusTitle = `${status.name} ${result.updated_at ? `le ${formatDateVariante(result.updated_at)}` : ''}`
                const puceStatus = [{ name: 'circle', class: `text-${status.color}`, title: statusTitle, result: result }]
                const link = [{ name:'arrow-circle-right', class: 'text-info', id: result.id, result: result.id }]

                if ((this.lastSave === null && result.updated_at) || (this.lastSave && result.updated_at && dateisSameOrBefore(this.lastSave, result.updated_at))) {
                    this.lastSave = result.updated_at
                }
                const updatedAt = (result.updated_at ? formatDateVariante(result.updated_at) : ' - ')
                const updatedAtIcon = [{
                    name: 'clock',
                    class: 'text-info text-small text-start',
                    title: updatedAt,
                    value_comp: updatedAt,
                    typeAction: 'popupHistorique',
                    item: result
                }]

                const datas: any = []
                datas.push(
                    { label: canViewCandidacy ? 'Cliquez pour consulter les informations du candidat' : '', text: candidat, item: result.candidat, type: 'actionText', typeAction: canViewCandidacy ? 'showCandidat' : null, class: canViewCandidacy ? 'text-info' : '' },
                    { label: canViewJob ? 'Cliquez pour consulter la fiche de poste' : '', text: result.jobDescription.name || '-', item: result.jobDescription, type: 'actionText', typeAction: canViewJob ? 'showJobDescription' : null, class: canViewJob ? 'text-info' : '' },
                    { label: canViewEstablishment || canViewChildEstablishment ? 'Cliquez pour consulter l\'établissement' : '', text: result.jobDescription.workingEstablishment.name, item: result.jobDescription.workingEstablishment.id, type: 'actionText', typeAction: canViewEstablishment || canViewChildEstablishment ? 'openEtablissement' : null, class: canViewEstablishment || canViewChildEstablishment ? 'text-info' : '' },
                    { label: '', item: puceStatus, type: 'icons', typeAction: 'editStatus', class: `text-center ${(this.canEdit() ? '' : 'pe-none')}` },
                    { label: '', item: updatedAtIcon, type: 'icons', typeAction: 'popupHistorique', class: 'text-center' },
                    { label: 'Accéder à la candidature', item: link, type: 'icons', typeAction: 'showCandidature', class: 'text-center edit' }
                )

                if (this.isInDetailEstablishment()) {
                    datas.splice(2, 1)
                }

                this.dataForTab.push(datas)
            }
        }
    }

    /**
     * @description Initialisation des filtres du tableau
     * @returns {void}
     */
    setFiltersForGenericTab(): void {
        const middle = Math.floor(Object.keys(CandidatJobDescriptionStatus).length / 2)
        this.candidatureStatus = Object.keys(CandidatJobDescriptionStatus).slice(0, middle)
            .map(status => getCandidatJobDescriptionStatus(parseInt(status)))

        this.filtres = [
            { libelle: 'Candidat',      defautOptionlibelle: 'Rechercher un', model: 'candidat.name'             , value: '', index: 'candidat.name'             , datas: null          , loading: this.$store.getters['jobDescription/loading'], options: { type: 'form'     , fieldsKey: 'candidat.name'              } },
            { libelle: 'Intitulé',      defautOptionlibelle: 'Rechercher un', model: 'jobDescription.name'       , value: '', index: 'jobDescription.name'       , datas: null          , loading: this.$store.getters['jobDescription/loading'], options: { type: 'form'     , fieldsKey: 'jobDescription.name'        } },
            { libelle: 'Établissement', defautOptionlibelle: 'Rechercher un', model: 'working_establishment_name', value: '', index: 'working_establishment_name', datas: null          , loading: this.$store.getters['jobDescription/loading'], options: { type: 'form'     , fieldsKey: 'working_establishment_name' } },
            { libelle: 'Statut',        defautOptionlibelle: 'Rechercher un', model: 'status'                    , value: '', index: 'status'                    , datas: this.candidatureStatus, loading: this.$store.getters['jobDescription/loading'], options: { type: 'deroulant', fieldsKey: 'status'             } }
        ]
    }

    /**
     * @description Initialisation des colonnes du tableau
     * @returns {void}
     */
    setFieldsForGenericTab(): void {
        this.genericfields = []

        this.genericfields.push(
            { key: 'candidat.name',               label: 'Candidat',              sortable: true,  class: '',            type: 'actionText' },
            { key: 'jobDescription.name',         label: 'Intitulé',              sortable: true,  class: '',            type: 'actionText' },
            { key: 'working_establishment_name',  label: 'Établissement',         sortable: true,  class: '',            type: 'text'       },
            { key: 'status',                      label: 'Statut',                sortable: true,  class: 'text-center', type: 'text'       },
            { key: 'updated_at',                  label: 'Dernière modification', sortable: true,  class: 'ps-5'                            },
            { key: 'etatEdit',                    label: '',                      sortable: false, class: '',            type: 'action'     }
        )

        if (this.isInDetailEstablishment()) {
            this.genericfields.splice(2, 1)
        }
    }

    /**
     * @description Gestion des événements du tableau
     * @param {any} paParams - Paramètres de l'événement
     * @returns {Promise<void>}
     */
    async handleTableEvent(paParams: any): Promise<void> {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'showCandidat':
                    this.openCandidatModal(paParams[1])
                    break

                case 'showJobDescription':
                    await this.openJobModal(paParams[1])
                    break

                case 'editStatus':
                    if (this.canEdit()) {
                        await this.openEditStatus(paParams[1][0].result)
                    }
                    break

                case 'openEtablissement':
                    await this.$router.push(`/bourse_emploi/etablissements/${paParams[1]}`)
                    break

                case 'showCandidature':
                    await this.$router.push(`/bourse_emploi/candidatures/${paParams[1][0].result}`)
                    break

                case 'sortHandler':
                case 'filterHandler':
                    await this.filtreSortHandler(paParams[1])
                    break

                case 'onLoadPage':
                    await this.loadHandler(paParams[1])
                    break

                case 'popupHistorique':
                    (this.$refs.historique as PopupHistorique).open({ candidatJobDescription_id: paParams[1][0].item.id })
                    break
            }
        }
    }

    /**
     * @description Applique des filtres
     * @param {any} params - Paramètres de filtre
     * @returns {Promise<void>}
     */
    async filtreSortHandler(params: any): Promise<void> {
        if (this.filtreJustInitiated) {
            this.filtreJustInitiated = false
        } else {
            if (this.isInDetailEstablishment()) {
                params['filter-working_establishment_id'] = this.$route.params.working_establishment_id
            }
            localStorage.setItem('candidatureParams', JSON.stringify(params))
            await this.$store.dispatch('candidatJobDescription/getCandidatJobDescriptions', params)
        }
    }

    /**
     * @description Complément des données sur un scroll
     * @param {any} params - Paramètres de chargement
     * @returns {Promise<void>}
     */
    async loadHandler(params: any): Promise<void> {
        if (this.isInDetailEstablishment()) {
            params['filter-working_establishment_id'] = this.$route.params.working_establishment_id
        }
        localStorage.setItem('candidatureParams', JSON.stringify(params))
        await this.$store.dispatch('candidatJobDescription/getMoreCandidatJobDescriptions', params)
    }

    /**
     * @description Avant le montage du composant
     * @returns {Promise<void>}
     */
    async beforeMount(): Promise<void> {
        this.filtreJustInitiated = true

        await this.loadDatas()
        this.setFieldsForGenericTab()
        this.setFiltersForGenericTab()
    }

    /**
     * @description Montage du composant
     * @returns {void}
     */
    mounted(): void {
        (window as any).handleTableEvent = this.handleTableEvent
    }

    /**
     * @description Avant la destruction du composant
     * @returns {void}
     */
    beforeDestroy(): void {
        (window as any).handleTableEvent = null
    }

    /**
     * @description Vérifie si l'utilisateur consulte en détail un établissement
     * @returns {void}
     */
    isInDetailEstablishment(): boolean {
        return !!this.$route.params.working_establishment_id && this.$route.path === `/bourse_emploi/etablissements/${this.$route.params.working_establishment_id}`
    }
}
