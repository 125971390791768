
















































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import EditMesureHandicaps from '@/components/Mesure/EditMesureHandicaps.vue'
import SelectMesuresHandicap from '@/components/Candidat/SelectMesuresHandicap.vue'
import { mapGetters, mapState } from 'vuex'
import { DemandeAmenagement, TypeMesure } from '@/types/Amenagement'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { Ability } from '@/types/Ability'
import { base64ToArrayBuffer, getFileNameFromHeader } from '@/utils/helpers'
import { getTypeAmenagement, TypeAmenagement } from '@/types/Salle'
import VuePdfApp from 'vue-pdf-app'
import {
    DecisionAmenagementInterface,
    EtatDecisionAmenagement,
    EtatDecisionAmenagementInterface,
    getEtatDecisionAmenagement
} from '@/types/DecisionAmenagement'
import _ from "lodash";

/**
 * Composant de gestion des aménagements
 */
@Component({
    computed: {
        ...mapState('candidat', ['loading', 'editedCandidat', 'error']),
        ...mapState('domaine', ['domaines']),
        ...mapState('gestionParticuliere', ['gestionParticulieres']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        decision(): DecisionAmenagementInterface {
            const decision = this.$store.getters['candidat/selectedCandidat'].decisionAmenagements
            if (decision && decision.length > 0) {
                const type: TypeMesure =
                    this.$data.da_selected_tab === 'ecrit' ? TypeMesure.TYPE_ECRIT : TypeMesure.TYPE_ORAL
                const index = decision.findIndex((d: DecisionAmenagementInterface) => d.type === type)
                return index >= 0 ? decision[index] : {} as DecisionAmenagementInterface
            }
            return {} as DecisionAmenagementInterface
        },
        etatDemande(): EtatDecisionAmenagementInterface {
            const candidat = this.$store.getters['candidat/selectedCandidat']
            const type: TypeMesure =
                this.$data.da_selected_tab === 'ecrit' ? TypeMesure.TYPE_ECRIT : TypeMesure.TYPE_ORAL
            let decision: DecisionAmenagementInterface = {} as DecisionAmenagementInterface
            let amenagements: Array<any> = []

            // Récupération de la décision d'aménagement
            if (!_.isEmpty(candidat.decisionAmenagements)) {
                const index = candidat.decisionAmenagements.findIndex(
                    (d: DecisionAmenagementInterface) => d.type === type
                )
                decision = candidat.decisionAmenagements[index]
            }

            // Récupération des mesures d'aménagement
            if (!_.isEmpty(candidat.amenagements)) {
                amenagements = candidat.amenagements.filter((a: any) => a.type === type)
            }

            if (!_.isEmpty(decision)) {
                return getEtatDecisionAmenagement(decision.etat)
            } else if (candidat.demande_amenagement || !_.isEmpty(amenagements)) {
                return getEtatDecisionAmenagement(EtatDecisionAmenagement.ETAT_NON_SOUMIS)
            } else {
                return { etat: -1, libelle: 'Aucune demande', color: 'secondary' }
            }
        }
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        EditMesureHandicaps,
        SelectMesuresHandicap,
        ErrorDisplay,
        VuePdfApp,
        ExaGenericTable
    }
})
export default class PopupEditCandidatAmenag extends Vue {
    mode?: string = 'normal'
    @Prop() type?: string

    genericfields: Array<any> = []

    dataForTab: Array<any> = []
    filtres: any = []
    DemandeAmenagement = DemandeAmenagement
    ability = Ability

    showModalCreationAmenagement?: boolean = false
    newAmenagement?: any = {}

    activeMobilite?: boolean = false
    activeTemps?: boolean = false
    enableMobilite?: boolean = true
    enableTemps?: boolean = true
    edit_decision = false
    save_decision = false
    new_decision = ''

    da_selected_tab = 'ecrit'

    showModalMessageValidationDemandeAmenagement = false
    showModalMessageInvalidationDemandeAmenagement = false
    index_mesure: any = null
    show_confirmation_delete_mesure = false
    exportWorking = false

    infosGestionParticuliereGlobal: Array<any> = []

    modePdf = false
    currentDocIndice = null
    sourcePdf: any = null
    namePdf = ''
    config = {
        toolbar: {
            toolbarViewerRight: {
                presentationMode: false,
                openFile: false,
                viewBookmark: false,
                secondaryToolbarToggle: false
            }
        }
    }

    /**
     * editDecisionWatcher
     * Met à jour le contenu de la décision d'aménagement en fonction de l'état d'édition de la décision
     * @returns void
     */
    @Watch('edit_decision')
    editDecisionWatcher(): void {
        this.save_decision = false

        if (this.edit_decision) {
            const decisions = this.$store.getters['candidat/selectedCandidat'].decisionAmenagements
            if (decisions && decisions.length > 0) {
                const type: TypeMesure =
                    this.$data.da_selected_tab === 'ecrit' ? TypeMesure.TYPE_ECRIT : TypeMesure.TYPE_ORAL
                const index = decisions.findIndex((d: DecisionAmenagementInterface) => d.type === type)
                this.new_decision = decisions[index].commentaire
            }
        } else {
            this.new_decision = ''
        }
    }

    /**
     * saveDecision
     * Enregistre la décision d'aménagement
     * @returns void
     */
    saveDecision(): void {
        this.save_decision = true

        const candidat = this.$store.getters['candidat/selectedCandidat']
        const type: TypeMesure = this.da_selected_tab === 'ecrit' ? TypeMesure.TYPE_ECRIT : TypeMesure.TYPE_ORAL
        const payload = {
            candidat_id: parseInt(candidat.id),
            type: type,
            commentaire: this.new_decision
        }
        const index: number = candidat.decisionAmenagements.findIndex(
            (d: DecisionAmenagementInterface) => d.type === type
        )

        if (index >= 0) {
            this.$store
                .dispatch('decisionamenagement/updateDecision', {
                    decision_id: candidat.decisionAmenagements[index].id,
                    payload: payload
                })
                .then(() => {
                    this.$store.dispatch('candidat/getCandidat', { id: candidat.id }).then(() => {
                        this.$store.commit('candidat/SET_SELECTED_CANDIDAT', candidat.id)
                        this.$nextTick(() => {
                            this.edit_decision = false
                        })
                    })
                })
                .catch(() => {
                    this.edit_decision = false
                })
        } else {
            this.$store
                .dispatch('decisionamenagement/addDecision', { payload: payload })
                .then(() => {
                    this.$store.dispatch('candidat/getCandidat', { id: candidat.id }).then(() => {
                        this.$store.commit('candidat/SET_SELECTED_CANDIDAT', candidat.id)
                        this.$nextTick(() => {
                            this.edit_decision = false
                        })
                    })
                })
                .catch(() => {
                    this.edit_decision = false
                })
        }
    }

    invalideDemande() {
        const candidat = this.$store.getters['candidat/selectedCandidat']
        const payload = this.buildPayLoad()
        const index: number = candidat.decisionAmenagements.findIndex((d: DecisionAmenagementInterface) => d.type === payload.type)
        const decision: DecisionAmenagementInterface = candidat.decisionAmenagements[index]

        if (decision.etat === EtatDecisionAmenagement.ETAT_VALIDE && !this.showModalMessageInvalidationDemandeAmenagement) {
            this.showModalMessageInvalidationDemandeAmenagement = true
            return
        }

        this.$store.dispatch('candidat/updateCandidat', {
            id: payload.id,
            type: payload.type,
            etat: decision.etat - 1
        }).then(() => {
            this.$store.dispatch('candidat/getCandidat', { id: candidat.id }).then(() => {
                this.$store.commit('candidat/SET_SELECTED_CANDIDAT', candidat.id)
                this.closeModal()
            })
        })
    }

    soumettreDemande() {
        const candidat = this.$store.getters['candidat/selectedCandidat']
        const payload = this.buildPayLoad()
        this.$store.dispatch('candidat/updateCandidat', {
            id: payload.id,
            type: payload.type,
            etat: 1
        }).then(() => {
            this.$store.dispatch('candidat/getCandidat', { id: candidat.id }).then(() => {
                this.$store.commit('candidat/SET_SELECTED_CANDIDAT', candidat.id)
            })
        })
    }

    closeModal() {
        this.showModalMessageValidationDemandeAmenagement = false
        this.showModalMessageInvalidationDemandeAmenagement = false
        this.$store.state.candidat.error = null
    }

    isValidated() {
        if (
            (this.$store.state.candidat.editedCandidat.demande_amenagement &
                DemandeAmenagement.DEMANDE_AMENAGEMENT_HANDI) ===
            DemandeAmenagement.DEMANDE_AMENAGEMENT_HANDI
        ) {
            // +1
            if (this.da_selected_tab === 'ecrit') {
                return (
                    (this.$store.state.candidat.editedCandidat.demande_amenagement &
                        DemandeAmenagement.DEMANDE_AMENAGEMENT_HANDI_TRAITE_ECRIT) ===
                    DemandeAmenagement.DEMANDE_AMENAGEMENT_HANDI_TRAITE_ECRIT
                )
            }
            if (this.da_selected_tab === 'oral') {
                return (
                    (this.$store.state.candidat.editedCandidat.demande_amenagement &
                        DemandeAmenagement.DEMANDE_AMENAGEMENT_HANDI_TRAITE_ORAL) ===
                    DemandeAmenagement.DEMANDE_AMENAGEMENT_HANDI_TRAITE_ORAL
                )
            }
        } else {
            return false
        }
    }

    hideDocument() {
        this.modePdf = false
        this.namePdf = ''
        this.sourcePdf = null
    }

    showDocument(documentKey: any) {
        this.currentDocIndice = documentKey
        const doc = this.$store.state.candidat.editedCandidat.documents[documentKey]
        this.$store
            .dispatch('document/getDocumentCandidat', {
                candidat_id: this.$store.state.candidat.editedCandidat.id,
                document_id: doc.id,
                b64: true
            })
            .then(() => {
                this.namePdf = doc.name + '' + (doc.mime_type === 'application/pdf' ? '.pdf' : '')
                this.sourcePdf = base64ToArrayBuffer(this.$store.state.document.documentSelect.data)
                // this.sourcePdf = base64ToArrayBuffer(response.data)
                // this.sourcePdf = response.data // base64ToArrayBuffer(response.data)
                this.modePdf = true
            })
            .catch((error) => {
                console.log('ko: ' + error)
            })
    }

    /** passe le statut du dossier d'une demande d'aménagement de en cours à traitée et inversement */
    validateDemandeSuite() {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        const candidat = this.$store.getters['candidat/selectedCandidat']
        const payload = this.buildPayLoad()
        this.$store.dispatch('candidat/updateCandidat', {
            id: payload.id,
            type: payload.type,
            etat: 2
        })
            .then(() => {
                this.$store.dispatch('candidat/getCandidat', { id: candidat.id })
                    .then(() => {
                        this.$store.commit('candidat/SET_SELECTED_CANDIDAT', candidat.id)
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }
                        this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                        this.closeModal()
                    })
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * Avant montage du composant, initialisation des paramètres relatifs aux aménagements
     */
    beforeMount() {
        this.da_selected_tab = this.type === 'ecrit' ? 'ecrit' : 'oral'

        this.$store.dispatch('domaine/getDomaines').then(() => {
            this.$store.dispatch('tempsSupplementaire/getTempsSupplementaires').then(() => {
                this.$store.dispatch('gestionParticuliere/getGestionParticulieres').then(() => {
                    this.initCustomAmenagement()
                    this.buildGenericFields()
                    this.setDataForGenericTab()
                    this.initDatasDossierHandicap()
                })
            })
        })
    }

    getDossierHandicapByTypePassation(dossiers: any, tp: string) {
        const retour = []
        for (let i = 0; i < dossiers.length; i++) {
            const dossier = {
                name: dossiers[i].name,
                commentaire: dossiers[i].commentaire,
                domaines: ''
            }
            let nbTp = 0
            const domaines: { [domaine: string]: { d: string; gds: Array<string> } } = {}
            for (let j = 0; j < dossiers[i].domaines.length; j++) {
                if (dossiers[i].domaines[j].n === tp) {
                    if (!domaines[dossiers[i].domaines[j].d]) {
                        domaines[dossiers[i].domaines[j].d] = {
                            d: dossiers[i].domaines[j].d,
                            gds: [dossiers[i].domaines[j].gd]
                        }
                    } else {
                        domaines[dossiers[i].domaines[j].d].gds.push(dossiers[i].domaines[j].gd)
                    }
                    // dossier.domaines += dossiers[i].domaines[j].d + ' '  + (dossiers[i].domaines[j].gd !== '' ? '(' + dossiers[i].domaines[j].gd + ')' : '')
                    nbTp++
                }
            }
            for (const domaine in domaines) {
                let chaineGd = ''
                for (let k = 0; k < domaines[domaine].gds.length; k++) {
                    if (domaines[domaine].gds[k].trim().length > 0) {
                        if (chaineGd.length > 0) {
                            chaineGd += ', '
                        }
                    }
                    chaineGd += domaines[domaine].gds[k]
                }
                if (dossier.domaines.length > 0) {
                    dossier.domaines += ', '
                }
                dossier.domaines += domaine
                if (chaineGd.length > 0) {
                    dossier.domaines += ' (' + chaineGd + ')'
                }
            }
            if (nbTp > 0) {
                retour.push(dossier)
            }
        }
        return retour
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setFiltersForGenericTab() {
        this.filtres = []
    }

    /**
     * initialisation des options 'Mobilité réduite' et 'Placement particulier' pour un candidat
     * en fonction des mésures sélectionnées ou des options candidat enregistrées
     */
    initCustomAmenagement() {
        /*
        this.activeMobilite = false
        this.activeTemps = false
        this.enableMobilite = true
        this.enableTemps = true
        if (this.$store.state.candidat.editedCandidat.amenagements && this.$store.state.candidat.editedCandidat.amenagements.length > 0) {
            for (const amenagement of this.$store.state.candidat.editedCandidat.amenagements) {
                if (amenagement.accessibilite === 1) {
                    this.activeMobilite = true
                }

                if (amenagement.accessibilite_am === 1) {
                    this.activeMobilite = true
                    this.enableMobilite = false
                }

                if (amenagement.temps === 1) {
                    this.activeTemps = true
                }

                if (amenagement.temps_am === 1) {
                    this.activeTemps = true
                    this.enableTemps = false
                }
            }
        } else {
            this.enableMobilite = false
            this.enableTemps = false
        }
        */

        this.infosGestionParticuliereGlobal = []
        for (let i = 0; i < this.$store.state.domaine.domaines.length; i++) {
            this.infosGestionParticuliereGlobal[this.$store.state.domaine.domaines[i].id] = {
                gps: [],
                majTemp: ''
            }
            for (let j = 0; j < this.$store.state.gestionParticuliere.gestionParticulieres.length; j++) {
                this.infosGestionParticuliereGlobal[this.$store.state.domaine.domaines[i].id].gps[
                    this.$store.state.gestionParticuliere.gestionParticulieres[j].id
                ] = false
            }
            if (
                this.$store.state.candidat.editedCandidat.amenagements &&
                this.$store.state.candidat.editedCandidat.amenagements.length > 0
            ) {
                for (const amenagement of this.$store.state.candidat.editedCandidat.amenagements) {
                    if (amenagement.domaine_ids.includes(this.$store.state.domaine.domaines[i].id)) {
                        if (amenagement.gestion_particuliere_ids && amenagement.gestion_particuliere_ids.length > 0) {
                            for (let k = 0; k < amenagement.gestion_particuliere_ids.length; k++) {
                                this.infosGestionParticuliereGlobal[this.$store.state.domaine.domaines[i].id].gps[
                                    amenagement.gestion_particuliere_ids[k]
                                ] = true
                            }
                        }
                        if (
                            amenagement.temps_supplementaire_id !== null &&
                            ((amenagement.type === TypeMesure.TYPE_ECRIT && this.da_selected_tab === 'ecrit') ||
                                (amenagement.type === TypeMesure.TYPE_ORAL && this.da_selected_tab === 'oral'))
                        ) {
                            const codeTempsSup = this.getTempsSuplementaireById(
                                amenagement.temps_supplementaire_id
                            ).code
                            if (
                                !this.infosGestionParticuliereGlobal[
                                    this.$store.state.domaine.domaines[i].id
                                ].majTemp.includes(codeTempsSup)
                            ) {
                                if (
                                    this.infosGestionParticuliereGlobal[this.$store.state.domaine.domaines[i].id]
                                        .majTemp.length > 0
                                ) {
                                    this.infosGestionParticuliereGlobal[
                                        this.$store.state.domaine.domaines[i].id
                                    ].majTemp += ', '
                                }
                                this.infosGestionParticuliereGlobal[this.$store.state.domaine.domaines[i].id].majTemp +=
                                    codeTempsSup
                            }
                        }
                    }
                }
            }
        }
    }

    getTempsSuplementaireById(id: number) {
        for (let i = 0; i < this.$store.state.tempsSupplementaire.tempsSupplementaires.length; i++) {
            if (id === this.$store.state.tempsSupplementaire.tempsSupplementaires[i].id) {
                return this.$store.state.tempsSupplementaire.tempsSupplementaires[i]
            }
        }
        return null
    }

    /**
     * Initialisation des données relatives aux dossiers de handicap du candidat courant
     */
    initDatasDossierHandicap() {
        const currentCandidat = this.$store.state.candidat.editedCandidat
        if (currentCandidat && currentCandidat.dossiers_handicap) {
            for (const d in currentCandidat.dossiers_handicap) {
                const dossier = currentCandidat.dossiers_handicap[d]
                if (dossier && dossier.domaines) {
                    if (dossier.domaines.length !== 0) {
                        const domaines = dossier.domaines
                        const arrayDomainesFormat: string[] = []
                        for (const c in domaines) {
                            let strDomaine = ''
                            if (domaines[c].n && domaines[c].n !== '') {
                                strDomaine += domaines[c].n
                            }
                            if (domaines[c].d && domaines[c].d !== '') {
                                strDomaine += ' / ' + domaines[c].d
                            }
                            if (domaines[c].gd && domaines[c].gd !== '') {
                                strDomaine += ' / ' + domaines[c].gd
                            }
                            arrayDomainesFormat.push(strDomaine)
                        }
                        dossier.strDomaines = arrayDomainesFormat.join(', ')
                    }
                }
            }
        }
    }

    buildGenericFields() {
        this.genericfields = []
        this.genericfields.push({
            key: 'libelle',
            label: 'Libellé',
            sortable: false,
            class: 'text-start',
            type: 'text'
        })
        this.genericfields.push({ key: 'type', label: 'Type', sortable: false, class: 'text-center', type: 'icons' })
        this.genericfields.push({
            key: 'domaine',
            label: 'Domaines',
            sortable: false,
            class: 'text-center',
            type: 'icons'
        })
        if (this.da_selected_tab === 'ecrit') {
            this.genericfields.push({
                key: 'temps',
                label: 'Maj. temps',
                sortable: false,
                class: 'text-center',
                type: 'icons'
            })
        }
        this.genericfields.push({
            key: 'gestPart',
            label: 'Gestion Particulière',
            sortable: true,
            type: 'text',
            class: 'text-center bg-gray-100'
        })
        if (this.da_selected_tab === 'ecrit') {
            this.genericfields.push({
                key: 'placementParticulier',
                label: 'Planification particulière',
                sortable: true,
                type: 'text',
                class: 'text-center bg-gray-100'
            })
        }
        this.genericfields.push({ key: 'delete', label: '', sortable: false, class: '', type: 'action' })
    }

    /**
     * Formatage des datas pour l'affichage des mesures selectionnées
     * dans le tableau générique
     */
    setDataForGenericTab() {
        const datas = JSON.parse(JSON.stringify(this.$store.state.candidat.editedCandidat.amenagements))
        if (datas) {
            this.dataForTab = []

            let etatDemande: EtatDecisionAmenagementInterface = { etat: -1, libelle: 'Aucune demande', color: 'secondary' }
            const candidat = this.$store.getters['candidat/selectedCandidat']
            const type: TypeMesure =
                this.$data.da_selected_tab === 'ecrit' ? TypeMesure.TYPE_ECRIT : TypeMesure.TYPE_ORAL
            let decision: DecisionAmenagementInterface = {} as DecisionAmenagementInterface
            let amenagements: Array<any> = []

            // Récupération de la décision d'aménagement
            if (!_.isEmpty(candidat.decisionAmenagements)) {
                const index = candidat.decisionAmenagements.findIndex(
                    (d: DecisionAmenagementInterface) => d.type === type
                )
                decision = candidat.decisionAmenagements[index]
            }

            // Récupération des mesures d'aménagement
            if (!_.isEmpty(candidat.amenagements)) {
                amenagements = candidat.amenagements.filter((a: any) => a.type === type)
            }

            if (!_.isEmpty(decision)) {
                etatDemande = getEtatDecisionAmenagement(decision.etat)
            } else if (candidat.demande_amenagement || !_.isEmpty(amenagements)) {
                etatDemande = getEtatDecisionAmenagement(EtatDecisionAmenagement.ETAT_NON_SOUMIS)
            }

            for (const d in datas) {
                const result = datas[d]
                const icones = []
                if (result.temps === 1) {
                    icones.push({ name: 'clock', class: 'text-secondary' })
                }
                if (result.accessibilite === 1) {
                    icones.push({ name: 'wheelchair', class: 'text-secondary' })
                }
                // const iconsTemps = result.temps === 1 ? [{ name:'clock', class:'text-secondary' }] : []
                // const iconsAccessibilite = result.accessibilite === 1 ? [{ name:'wheelchair', class:'text-secondary' }] : []

                let libelle_type = ''
                if (result.type) {
                    switch (result.type) {
                        case TypeMesure.TYPE_ECRIT:
                            libelle_type = 'ECRIT'
                            break
                        case TypeMesure.TYPE_ORAL:
                            libelle_type = 'ORAL'
                            break
                        case TypeMesure.TYPE_ECRIT_ET_ORAL:
                            libelle_type = 'ECRIT / ORAL'
                            break
                        default:
                            break
                    }
                }
                const temps =
                    result.temps_supplementaire_id !== null
                        ? '<div class="text-center icone_oral_ecrit m-0 p-0" title="' +
                          this.getTempsSuplementaireById(result.temps_supplementaire_id).name +
                          '">' +
                          this.getTempsSuplementaireById(result.temps_supplementaire_id).code +
                          '</div>'
                        : ''
                let gestionPart = ''
                let domaineshtml = ''
                let nameGestionPart = ''
                let placementParticulier = ''
                // let iconPlacementParticulier: any = []
                if (result.accessibilite !== TypeAmenagement.AMENAGEMENT_AUCUN) {
                    placementParticulier = getTypeAmenagement(result.accessibilite).libelle
                    // placementParticulier = '<font-awesome-icon icon="' + getTypeAmenagement(result.accessibilite).icone + '" title="' + getTypeAmenagement(result.accessibilite).libelle + '" class="text-secondary"/>'
                    // iconPlacementParticulier = [{ name:getTypeAmenagement(result.accessibilite).icone, class:'text-secondary' }]
                }

                for (let i = 0; i < result.domaine_ids.length; i++) {
                    const domaineTemp = this.getDomaineById(result.domaine_ids[i])
                    if (domaineTemp && !domaineshtml.includes(domaineTemp.code)) {
                        domaineshtml =
                            domaineshtml +
                            '<div class="text-center icone_oral_ecrit m-0 p-0" title="' +
                            domaineTemp.name +
                            '">' +
                            domaineTemp.code +
                            '</div>'
                    }
                }
                if (result.gestion_particuliere_ids && result.gestion_particuliere_ids.length > 0) {
                    for (let k = 0; k < result.gestion_particuliere_ids.length; k++) {
                        const gp = this.getGestionParticuliereById(result.gestion_particuliere_ids[k])
                        if (gp) {
                            nameGestionPart = gp.code
                        }
                        if (!gestionPart.includes(nameGestionPart)) {
                            gestionPart =
                                gestionPart +
                                '<div class="text-center icone_oral_ecrit m-0 p-0" title="' +
                                gp.name +
                                '">' +
                                nameGestionPart +
                                '</div>'
                        }
                    }
                }

                const line = []
                line.push({
                    label: 'libelle',
                    item: result.commentaire
                        ? `${result.name} <p class="mb-0 text-secondary">${result.commentaire}</p>`
                        : result.name,
                    type: 'html',
                    typeAction: null,
                    class: ''
                })
                line.push({
                    label: 'type',
                    item: libelle_type,
                    type: 'text',
                    typeAction: null,
                    class: 'text-center  icone_oral_ecrit'
                })
                line.push({
                    label: 'domaine',
                    item: domaineshtml,
                    type: 'html',
                    typeAction: null,
                    class: 'text-center'
                })
                if (this.da_selected_tab === 'ecrit') {
                    line.push({ label: 'temps', item: temps, type: 'html', typeAction: null, class: 'text-center' })
                }
                if (this.da_selected_tab === 'ecrit') {
                    line.push({
                        label: 'gestPart',
                        item: gestionPart,
                        type: 'html',
                        typeAction: null,
                        class: 'text-center'
                    })
                }
                // if (this.da_selected_tab === 'oral') line.push({ label: 'placementParticulier', item: iconPlacementParticulier, type: 'icons', typeAction: null, class: 'text-center' }) // , title: placementParticulier
                if (this.da_selected_tab === 'ecrit') {
                    line.push({
                        label: 'placementParticulier',
                        item: placementParticulier,
                        type: 'text',
                        typeAction: null,
                        class: 'text-center icone_oral_ecrit'
                    })
                } // , title: placementParticulier

                if (this.da_selected_tab === 'oral') {
                    line.push({ label: 'temps', item: icones, type: 'icons', typeAction: null, class: 'text-center' })
                }
                // line.push({ label: 'accessibilite', item: iconsAccessibilite, type: 'icons', typeAction: null, class: 'text-center' })

                if (this.$store.getters['auth/can'](Ability.CAND_MANAGE) && !this.isValidated()) {
                    const trashLine: any = {
                        label: 'Supprimer',
                        item: result,
                        type: 'action',
                        typeAction: 'delete',
                        class: `text-secondary ${etatDemande.etat > 0 ? 'disabled' : ''}`,
                        icon: 'trash-alt'
                    }
                    line.push(trashLine)
                }

                if (
                    (result.type === TypeMesure.TYPE_ECRIT && this.da_selected_tab === 'ecrit') ||
                    (result.type === TypeMesure.TYPE_ORAL && this.da_selected_tab === 'oral')
                ) {
                    this.dataForTab.push(line)
                }
            }
        }
    }

    getDomaineById(id: number) {
        for (let i = 0; i < this.$store.state.domaine.domaines.length; i++) {
            if (id === this.$store.state.domaine.domaines[i].id) {
                return this.$store.state.domaine.domaines[i]
            }
        }
        return null
    }

    getGestionParticuliereById(id: number) {
        for (let i = 0; i < this.$store.state.gestionParticuliere.gestionParticulieres.length; i++) {
            if (id === this.$store.state.gestionParticuliere.gestionParticulieres[i].id) {
                return this.$store.state.gestionParticuliere.gestionParticulieres[i]
            }
        }
        return null
    }

    /**
     * Récupération des events de la table
     */
    handleTableEvent(paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'delete':
                    this.confirmation_delete_mesures(paParams[1].id)
                    break
                default:
                    break
            }
        }
    }

    /**
     * Confirmation de suppression d'une mesure d'oral
     */
    confirmation_delete_mesures(id: number) {
        const currentCandidat = this.$store.state.candidat.editedCandidat
        currentCandidat.amenagements.findIndex((amenagement: any) => amenagement.id === id)
        this.index_mesure = currentCandidat.amenagements.findIndex((amenagement: any) => amenagement.id === id)

        this.show_confirmation_delete_mesure = true
    }

    /**
     * Annuler la confirmation de suppression
     */
    cancelDeleteMesure() {
        this.index_mesure = null
        this.show_confirmation_delete_mesure = false
    }

    /**
     * Suppression d'une mesure parmit les mesures associées au candidat courant
     * La mesure a supprimer est donnée par son identifiant
     */
    deleteMesureCandidat() {
        this.show_confirmation_delete_mesure = false
        const currentCandidat = this.$store.state.candidat.editedCandidat

        if (this.index_mesure !== -1) {
            currentCandidat.amenagements.splice(this.index_mesure, 1)
            const payload = this.buildPayLoad()
            this.$store.dispatch('candidat/updateCandidat', payload).then(() => {
                this.index_mesure = null
                this.$store.dispatch('candidat/getCandidat', currentCandidat).then(() => {
                    this.$store.commit('candidat/SET_EDITED_CANDIDAT', currentCandidat.id)
                    this.$store.commit('candidat/SET_SELECTED_CANDIDAT', currentCandidat.id)
                    this.initCustomAmenagement()
                    this.setDataForGenericTab()
                })
            })
        }
    }

    /**
     * Ouverture du popup  de selection des mesures d'handicap
     */
    selectMesure() {
        this.mode = 'select_mesures'
        this.$emit('changeEditing', true)
    }

    /**
     * Ouverture du popup d'édition d'une mésure d'handicap
     * afin de créer une nouvelle mesure d'handicap
     */
    openCreateMesure() {
        this.newAmenagement = {
            id: 0,
            code: '',
            name: '',
            accessibilite: 0,
            temps: 0,
            type: 0,
            selected_domaines_ids: [],
            to_viatique: 0
        }
        this.$store.state.amenagement.error = null
        this.mode = 'create_mesure'
        this.$emit('changeEditing', true)
        // this.showModalCreationAmenagement = true
    }

    /**
     * Changement de valeur pour l'option de mobilité réduite.
     * Si coché, alors toutes les mesures sélectionnées prennent l'option 'Mobilité réduite' si elles ne la possèdent pas déja
     * Si non coché, alors toutes les mesures sélectionnées perdent l'option 'Mobilité réduite' si elles la possèdent
     */
    changeMobiliteValue() {
        for (const amenagement of this.$store.state.candidat.editedCandidat.amenagements) {
            if (this.activeMobilite === true) {
                amenagement.accessibilite = 1
            } else {
                amenagement.accessibilite = 0
            }
        }

        const payload = this.buildPayLoad()
        this.$store.dispatch('candidat/updateCandidat', payload).then(() => {
            this.initCustomAmenagement()
            this.setDataForGenericTab()
        })
    }

    /**
     * Changement de valeur pour l'option de Placement particulier.
     * Si coché, alors toutes les mesures sélectionnées prennent l'option 'Placement particulier' si elles ne la possèdent pas déja
     * Si non coché, alors toutes les mesures sélectionnées perdent l'option 'Placement particulier' si elles la possèdent
     */
    changeTempsValue() {
        for (const amenagement of this.$store.state.candidat.editedCandidat.amenagements) {
            if (this.activeTemps === true) {
                amenagement.temps = 1
            } else {
                amenagement.temps = 0
            }
        }

        const payload = this.buildPayLoad()
        this.$store.dispatch('candidat/updateCandidat', payload).then(() => {
            this.initCustomAmenagement()
            this.setDataForGenericTab()
        })
    }

    /**
     * Création coté serveur d'une nouvelle mesure éditée à partir
     * du popup d'édition
     */
    createMesure() {
        const payload = {
            /*
            id: this.newAmenagement.id,
            name: this.newAmenagement.name,
            code: this.newAmenagement.code,
            type: this.newAmenagement.type,
            temps: (this.newAmenagement.temps === true || this.newAmenagement.temps === 1 ? 1 : 0),
            accessibilite: (this.newAmenagement.accessibilite === true || this.newAmenagement.accessibilite === 1 ? 1 : 0),
            session_id: this.$store.getters['auth/user_session_id']
            */

            id: this.newAmenagement.id,
            name: this.newAmenagement.name,
            code: this.newAmenagement.code,
            type: this.newAmenagement.type,
            temps_supplementaire_id: this.newAmenagement.temps_supplementaire_id,
            gestion_particulieres: this.newAmenagement.gestion_particulieres,
            temps: this.newAmenagement.temps === true || this.newAmenagement.temps === 1 ? 1 : 0,
            to_viatique: this.newAmenagement.to_viatique === true || this.newAmenagement.to_viatique === 1 ? 1 : 0,
            // accessibilite: (this.amenagementTemp.accessibilite === true || this.amenagementTemp.accessibilite === 1 ? 1 : 0),
            accessibilite: this.newAmenagement.accessibilite,
            session_id: this.$store.getters['auth/user_session_id']
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('amenagement/addAmenagement', payload)
            .then((resAddAmenagement) => {
                const currentCandidat = this.$store.state.candidat.editedCandidat
                if (currentCandidat) {
                    if (!currentCandidat.amenagements) {
                        currentCandidat.amenagements = []
                    }
                    const amenagementAdded = resAddAmenagement.data.data
                    amenagementAdded.amenagement_id = amenagementAdded.id
                    amenagementAdded.temps_am = amenagementAdded.temps
                    amenagementAdded.accessibilite_am = amenagementAdded.accessibilite
                    amenagementAdded.type_am = amenagementAdded.type
                    amenagementAdded.domaine_ids = this.newAmenagement.selected_domaines_ids // liste des domaines
                    amenagementAdded.gestion_particulieres = this.newAmenagement.gestion_particulieres
                    amenagementAdded.to_viatique = this.newAmenagement.to_viatique

                    currentCandidat.amenagements.push(amenagementAdded)

                    // Ajout du nouvel amenagement sur le candidat

                    const payload = this.buildPayLoad()

                    this.$store.dispatch('candidat/updateCandidat', payload).then(() => {
                        this.$store.commit('amenagement/SET_MORE_AMENAGEMENTS', [amenagementAdded])
                        this.newAmenagement = {}
                        this.showModalCreationAmenagement = false
                        this.mode = 'normal'
                        this.$emit('changeEditing', false)
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }
                        this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                        this.$store.commit('candidat/SET_EDITED_CANDIDAT', currentCandidat.id)
                        this.initCustomAmenagement()
                        this.setDataForGenericTab()
                    })
                }
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * Fermeture de la modale de création d'une nouvelle mesure
     */
    reinitShowModalMesure() {
        this.showModalCreationAmenagement = false
    }

    /** Reinitialisation de la modale de gestion candidat */
    reinitShowModalCandidat() {
        this.$store.state.amenagement.error = null
        this.mode = 'normal'
        this.$emit('changeEditing', false)
    }

    /**
     * Construction des données globales de mise à jour pour un candidat
     */
    buildPayLoad() {
        const payload: any = {}
        const currentCandidat = this.$store.state.candidat.editedCandidat
        if (currentCandidat) {
            payload.id = currentCandidat.id
            payload.code = currentCandidat.code
            payload.concour_id = currentCandidat.concour_id
            payload.demission = currentCandidat.demission
            payload.name = currentCandidat.name
            payload.etat_dossier = currentCandidat.etat_dossier
            payload.nationalite_fr = currentCandidat.nationalite_fr
            payload.first_name = currentCandidat.first_name
            payload.email = currentCandidat.email
            payload.filiere = currentCandidat.filiere
            payload.date_naissance = currentCandidat.date_naissance
            payload.ville_naissance = currentCandidat.ville_naissance
            payload.pays_naissance = currentCandidat.pays_naissance
            payload.portable = currentCandidat.portable
            payload.telephone = currentCandidat.telephone
            payload.nationalite = currentCandidat.nationalite
            payload.adresse1 = currentCandidat.adresse1
            payload.adresse2 = currentCandidat.adresse2
            payload.code_postal = currentCandidat.code_postal
            payload.bureau_distribution = currentCandidat.bureau_distribution
            payload.pays_distribution = currentCandidat.pays_distribution
            payload.etablissement_id = currentCandidat.etablissement_id
            payload.inscriptions = currentCandidat.inscriptions
            payload.amenagements = currentCandidat.amenagements
            payload.serie_id = currentCandidat.serie_id
        }
        payload.type = this.da_selected_tab === 'ecrit' ? TypeMesure.TYPE_ECRIT : TypeMesure.TYPE_ORAL
        return payload
    }

    /**
     * Fermeture de l'interface d'ajout/suppression de mesures
     */
    validateAmenagementsSelect(amenagementsSelected: any) {
        this.mode = 'normal'
        this.$emit('changeEditing', false)

        if (amenagementsSelected && this.$store.state.candidat.editedCandidat) {
            const currentCandidat = this.$store.state.candidat.editedCandidat
            const newAmenagements: any[] = []

            for (const amenagementId in amenagementsSelected) {
                const amenagementToAdd = Object.assign({}, amenagementsSelected[amenagementId].amenagement)
                // amenagementToAdd.temps_am = amenagementToAdd.temps
                // amenagementToAdd.accessibilite_am = amenagementToAdd.accessibilite
                amenagementToAdd.domaine_ids = amenagementsSelected[amenagementId].domaines_ids
                amenagementToAdd.commentaire = amenagementsSelected[amenagementId].commentaire
                amenagementToAdd.amenagement_id = amenagementId

                amenagementToAdd.gestion_particuliere_ids = []
                for (let i = 0; i < amenagementToAdd.gestion_particulieres.length; i++) {
                    amenagementToAdd.gestion_particuliere_ids.push(amenagementToAdd.gestion_particulieres[i].id)
                }

                delete amenagementToAdd.temps_supplementaire
                delete amenagementToAdd.gestionPart
                delete amenagementToAdd.gestion_particulieres
                delete amenagementToAdd.amenagement

                const index = currentCandidat.amenagements.findIndex(
                    (amenagement: any) =>
                        amenagementId === amenagementToAdd.id || amenagement.amenagement_id === amenagementToAdd.id
                )

                if (index !== -1) {
                    amenagementToAdd.temps = currentCandidat.amenagements[index].temps
                    amenagementToAdd.accessibilite = currentCandidat.amenagements[index].accessibilite
                    amenagementToAdd.domaine_ids = currentCandidat.amenagements[index].domaine_ids
                }

                newAmenagements.push(amenagementToAdd)
            }
            const selectedCandidat = this.$store.state.candidat.selectedCandidat
            for (const ame of selectedCandidat.amenagements) {
                let amePresent = false
                for (let i = 0; i < newAmenagements.length; i++) {
                    if (ame.id === newAmenagements[i].id) {
                        amePresent = true
                    }
                }
                if (!amePresent) {
                    newAmenagements.push(ame)
                }
            }

            currentCandidat.amenagements = newAmenagements

            const payload = this.buildPayLoad()
            this.$store.dispatch('candidat/updateCandidat', payload).then(() => {
                this.$store.dispatch('candidat/getCandidat', currentCandidat).then(() => {
                    this.$store.commit('candidat/SET_EDITED_CANDIDAT', currentCandidat.id)
                    this.$store.commit('candidat/SET_SELECTED_CANDIDAT', currentCandidat.id)
                    this.initCustomAmenagement()
                    this.setDataForGenericTab()
                })
            })
        }
    }

    /** Exporte au format pdf la fiche des mesures d'aménagements du candidat */
    export_fiche_pdf() {
        this.$store.commit('candidat/SET_ERROR', null)
        let fileName = ''
        this.exportWorking = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Export en cours...', infosToaster)

        this.$store
            .dispatch('candidat/getPDFAmenagements', {
                candidat_id: this.$store.state.candidat.editedCandidat.id,
                ecrit: this.da_selected_tab === 'ecrit'
            })
            .then((response) => {
                const fileNameTemp = getFileNameFromHeader(response.headers) || `Amenagements_${this.$store.state.candidat.editedCandidat.name}_${this.$store.state.candidat.editedCandidat.first_name}.pdf`
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([base64ToArrayBuffer(response.data)]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.exportWorking = false
            })
    }
}
