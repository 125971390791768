






















































































import { Vue, Component,  Watch }      from 'vue-property-decorator'
import { mapGetters, mapState }             from 'vuex'
import { FontAwesomeIcon }                  from '@fortawesome/vue-fontawesome'
import { Ability }                          from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import PopupEditCandidat                    from '@/components/Candidat/PopupEditCandidat.vue'
import PopupEditCommentCandidat             from '@/components/Candidat/PopupEditCommentCandidat.vue'
import  ErrorDisplay                        from '@/components/ErrorDisplay.vue'
import Back                                 from '@/components/Tools/Back.vue'
import _ from 'lodash'
import { formatNumber, truncateString }   from '@/utils/helpers'
import { TypeEnsemble } from '@/types/Ensemble'
import { typeBarre } from '@/types/DefinitionDesBarres'
import { CandidatStatut } from '@/types/Candidat'
import { candidatRecu } from '@/types/DefinitionDesBarresListeNotes'

/**
 * Composant contenant l'ensemble des candidats
*/
@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapGetters('reclamationPostConcours', ['reclamations_post_concours', 'reclamationSelect', 'loading', 'error', 'PCSelectReclassementOpen']),

        ...mapGetters('reclassement', ['liste_candidats', 'meta', 'links', 'loading', 'error', 'currentPage', 'lastPage', 'totalPage', 'totalRows']),
        ...mapState('reclassement', ['params_liste_candidats'])

    },
    components: {
        ExaGenericTable,
        PopupEditCandidat,
        PopupEditCommentCandidat,
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay,
        Back
    }
})

export default class Reclassement extends Vue
{
    /**
     *
     *
     * Infos : les colonnes des deux tableaux se font de maniere dynamique via createDynamicFields
     *
     *
     *
     *  */

    loadingListeCand = false


    Ability                 = Ability
    genericfields: any      = []
    sortBy                  = '';
    sortDesc                = false;
    sortDirection           = 'asc';
    filter                  = '';
    filterOn                = [];
    stickyHeader            = true;
    filtres:    any         = []
    dataForTab: Array<any>  = []
    selectedConcour: any         = []
    dataForTabCandidatInformations: any = []
    // sav des informations venant de reclamation
    selectedCandidatReclamation: any        = []
    nbCandidatClasse = null
    ligne2: any = {}
    simulation: any = null
    passCurrentPhaseRankingGroupId = null //34 n'etait pas utilisé (valeur d'init)

    //
    selectedCandidatRangInformation: any = []

    // recup du dernier sort et le sauvegarde, sera utilisé pour le passer dans les parametres du reclassement
    lastSortable: any                         = { sort: 'rang', direction: 'asc' }
    showModaleValidationREclassement = false
    calculEnCours = false
    savChangementClassement: any = null

    /** creation de la deuxieme ligne du tableau candidat afin de coller aux informations de la reclamation  */
    firstCreateLigne2() {
        const concour_id                = this.$route.params.concour_id
        // const candidat_id               = this.$route.params.candidat_id
        this.ligne2                     = _.cloneDeep(this.selectedCandidatRangInformation[0]) // creation de la deuxieme ligne du premier tableau
        this.ligne2.moyenne             = this.simulation && this.simulation.moyenne ? this.simulation.moyenne : this.ligne2.moyenne // recup de la nouvelle moyenne dans l objet simulation de l objet reclamation
        this.ligne2.points              = this.simulation && this.simulation.points ? this.simulation.points : this.ligne2.points  // this.ligne2.points

        // la logique du dessous : creer une ligne avec les informations modifiées, si elles l ont deja ete (le rang choisi)

        this.ligne2.ranking_group_id = this.simulation.ranking_group_id
        this.savChangementClassement = this.simulation.rang
        this.createCandidatInformations(this.selectedCandidatRangInformation)
    }

    /**
     * Récupération sur le serveur des concours
     */
    loadConcoursIfNotExists ()
    {
        return new Promise((resolve) =>
        {
            if (this.$store.getters['concour/banques'].length === 0)
            {
                this.$store.dispatch('concour/getConcoursActifs').then(() =>
                {
                    resolve(true)
                })
            }
            else
            {
                resolve(true)
            }
        })
    }

    barres: any = []
    fieldRangPosition = 0

    /** creation des fields et footer en dynamique  */
    createDynamicFields(epreuvesDeliberation: any) {
        this.barres = this.$store.getters['definitionDesBarres/selectedConcour'].deliberation.bar_rules

        // 1ère partie en dur - candidat
        const fields = [
            { key: 'candidat.code', label: 'Code', sortable: true, class: '', type: 'text', epreuve_id: null },
            { key: 'candidat.name', label: 'Identité', sortable: true, class: '', type: 'text', epreuve_id: null }
        ]

        /* 2ème partie - Creation des colonnes dynamiques - colonnes des notes finales par épreuve */
        const colonnes_epreuves_phase_autre = [] // récupère l'ensemble des colonnes d'épreuves de phases autre (normalement phase précédente) qui seront placées après les colonnes d'épreuves de la phase 2 d'admission
        for (const epreuve of epreuvesDeliberation) {
            /* filtre des phases */
            if ( epreuvesDeliberation && epreuve.phase_id === this.selectedCandidatReclamation.epreuve.phase_id) {
                if (epreuve?.epreuve?.phase_id === this.selectedCandidatReclamation.epreuve.phase_id) {
                    const field = { key: 'dyn:epreuves:epreuve_id:' + epreuve.epreuve.id, label: truncateString(epreuve.epreuve.name, 6), sortable: true, class: 'text-center colonne_epreuve', type: 'text', epreuve_id: epreuve.epreuve.id, placement: 'dynamique1', title: epreuve.epreuve.name }
                    fields.push(field)
                } else {
                    // epreuve d'une autre phase (normalement phase précédente)
                    const field = { key: 'dyn:epreuves:epreuve_id:' + epreuve.epreuve.id, label: truncateString(epreuve.epreuve.name, 6), sortable: true, class: 'text-center colonne_epreuve', type: 'text', epreuve_id: epreuve.epreuve.id, placement: 'dynamique1', title: epreuve.epreuve.name }
                    colonnes_epreuves_phase_autre.push(field)
                }
            }
        }

        if (colonnes_epreuves_phase_autre.length > 0) {
            // si au moins une colonne d'épreuve autre, on rajoute le tableau des colonnes d'épreuve de phase autre (normalement phase précédente)
            // fields.push(...colonnes_epreuves_phase_autre)
        }

        if(this.selectedConcour.phases.length > 1) {
            const phasesFields: any = []
            for(let i = 0; i < this.selectedConcour.phases.length;  i++) {
                if(this.selectedConcour.phases[i].phase_id === this.selectedCandidatReclamation.epreuve.phase_id) {
                    for(let j = 0; j < this.selectedConcour.phases[i].bar_rules.length; j++) {
                        if(this.selectedConcour.phases[i].bar_rules[j].type === 'point') {
                            phasesFields.push( { key: 'json:bar_rule_results:' + this.selectedConcour.phases[i].bar_rules[j].id, label: 'Total ' + this.selectedConcour.phases[i].bar_rules[j].name , sortable: true,     class: 'text-center', type: 'text', epreuve_id: null })
                        }
                    }
                }
            }

            fields.push(...phasesFields)
        }
        this.fieldRangPosition = fields.length +4
        /* 3ème partie en dur - totaux, rang, points, moyenne, classement */
        const colonnes_resultats: any = [
            // { key: 'points',                            label: 'Total Admission',                   sortable: true,     class: 'text-center',                                           type: 'text', epreuve_id: null }, // pointsAdmission
            { key: 'moyenne',                           label: 'Moyenne Générale',                  sortable: true,     class: 'text-center',                                           type: 'text', epreuve_id: null }, // moyenneGenerale
            { key: 'equipeInterclassement',             label: 'Équipe',                            sortable: false,    class: 'bg-light text-center',                                  type: 'text', epreuve_id: null, doubleHeaderLabel: 'Interclassement', doubleHeaderColSpan: 2, doubleHeaderClass: 'bg-light text-center header_interclassement' }, // interclassementEquipe
            { key: 'rang_equipe',                       label: 'Rang',                              sortable: true,     class: 'bg-light text-center',                                  type: 'text', epreuve_id: null, doubleHeaderClass: 'text-center header_interclassement' }, // interclassementRang
            { key: 'ranking_group_id',                  label: 'Statut',                            sortable: false,    class: 'text-center',                                           type: 'text', epreuve_id: null }, // isClasse
            { key: 'rang',                              label: 'Classement Interne',                sortable: true,     class: 'bg-gray-light text-center',                             type: 'text', epreuve_id: null, doubleHeaderClass: 'text-center' }, // classementInterne
            { key: 'rang_externe',                      label: 'Classement Final (public)',         sortable: false,    class: 'bg-gray-light text-center colonne_classement_final',    type: 'text', epreuve_id: null, doubleHeaderClass: 'text-center' } // classementFinalPublic
        ]

        fields.push(...colonnes_resultats)
        return fields
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     * forSelectedCandidatTab permet de choisir entre les deux tables forSelectedCandidatTab = table du candidat selectionné
     */
    setDataForGenericTab(poData: any, isLoadMore = false, forCandidatSelect = false) {
        if (!isLoadMore)
        {
            if (forCandidatSelect === false) {
                this.dataForTab = []
            } else if (forCandidatSelect === true) {
                this.dataForTabCandidatInformations = []
            }
        }
        const options_admissiom: any = this.$store.getters['rankingGroup/rankingGroupsConcourPhase']
            .map((rg: any) => ({ value: rg.id, text: rg.name }))

        if (poData) {
            const idCandidatSelect = this.$route.params.candidat_id
            const infoReclamationsCandidat: any = this.selectedCandidatReclamation
            poData.forEach((result: any, index: any) =>
            {
                const pointsAdmiss  = parseInt(result.candidat_id) === parseInt(idCandidatSelect) && (forCandidatSelect === false || (forCandidatSelect === true && index === 1)) && this.simulation && this.simulation.points ?  this.simulation.points.toFixed(2) : result.points ? result.points.toFixed(2) : '-'  // recup de l info simulée si candidat id et pas index 0 du tableau 1
                const pointsAdmissModifie = pointsAdmiss !== result.points.toFixed(2)

                const moyenneGenerale = parseInt(result.candidat_id) === parseInt(idCandidatSelect) && (forCandidatSelect === false || (forCandidatSelect === true && index === 1)) && this.simulation && this.simulation.moyenne ?  this.simulation.moyenne.toFixed(2) : result.moyenne ? result.moyenne.toFixed(2) : '-' // recup de l info simulée si candidat id et pas index 0 du tableau 1
                const moyenneGeneraleModifie = moyenneGenerale !== result.moyenne.toFixed(2)

                const rang_equipe = parseInt(result.candidat_id) === parseInt(idCandidatSelect) && (forCandidatSelect === false || (forCandidatSelect === true && index === 1)) && this.simulation  ?  this.simulation.rang_equipe ? this.simulation.rang_equipe : '-'  : result.rang_equipe ? result.rang_equipe : '-' // recup de l info simulée si candidat id et pas index 0 du tableau 1
                const rang_equipeModifie = rang_equipe !== result.rang_equipe

                const ranking_group_id = parseInt(result.candidat_id) === parseInt(idCandidatSelect) && (forCandidatSelect === false || (forCandidatSelect === true && index === 1)) && this.simulation && this.simulation.ranking_group_id ?  this.simulation.ranking_group_id : result.ranking_group_id ? result.ranking_group_id : '-' // recup de l info simulée si candidat id et pas index 0 du tableau 1
                const rankGroupName =  options_admissiom.find((oa: any) => oa.value === ranking_group_id)?.text
                const ranking_groupModifie = ranking_group_id !== result.ranking_group_id

                const rang = parseInt(result.candidat_id) === parseInt(idCandidatSelect) && (forCandidatSelect === false || (forCandidatSelect === true && index === 1)) && this.simulation ? (this.simulation.rang ? parseInt(this.simulation.rang)  : '-' ): (parseInt(result.rang) ? parseInt(result.rang) : '-' )// recup de l info simulée si candidat id et pas index 0 du tableau 1
                const rangModifie = rang !== this.selectedCandidatRangInformation[0].rang
                const classRang = parseInt(result.candidat_id) === parseInt(idCandidatSelect) ? index === 1 ? 'item_action fixed_size_classement margin_classement text-center' : 'item_action fixed_size_classement margin_classement text-center' : ' bg-gray-light fixed_size_classement margin_classement text-center '

                const rang_externe = parseInt(result.candidat_id) === parseInt(idCandidatSelect) && (forCandidatSelect === false || (forCandidatSelect === true && index === 1)) && this.simulation ? (this.simulation.rang_externe ? parseInt(this.simulation.rang_externe) : '-') : (result.rang_externe ? parseInt(result.rang_externe) : '-') // recup de l info simulée si candidat id et pas index 0 du tableau 1
                const rang_externeModifie = rang_externe !== this.selectedCandidatRangInformation[0].rang_externe



                const classIsMoved = result.isMoved ? ' isMoved ' : ''
                // 1ère partie en dur - candidat
                const identite = result.candidat ? (result.candidat.name ? result.candidat.name : '-') + ' ' + (result.candidat.first_name ? result.candidat.first_name : '-') : '-'
                const line: any = [
                    {
                        label: result.candidat.code,
                        item: result.candidat.code,
                        type: 'text',
                        class: 'code_identite_affichage fixed_size'
                    },
                    {
                        label: identite,
                        item: identite,
                        type: 'text',
                        class: 'code_identite_affichage fixed_size_identity'
                    }
                ]

                /* 2ème partie - Creation des colonnes dynamiques - colonnes des notes finales par épreuve */
                if (result && result.epreuves) {
                    for (const field of this.genericfields) {
                        let itemNotef = ''
                        let labelItem = ''
                        let classItem = 'text-center fixed_size'
                        if (field.epreuve_id !== null && field.placement === 'dynamique1') {
                            const filter = result.epreuves.filter((epreuve: { epreuve_id: null; id_epreuve_maitresse: null }) => epreuve.epreuve_id === field.epreuve_id || epreuve.id_epreuve_maitresse === field.epreuve_id)
                            if ((forCandidatSelect && index === 1 &&
                                (parseInt(field.epreuve_id) === parseInt(infoReclamationsCandidat.epreuve.id) || parseInt(field.epreuve_id) === parseInt(infoReclamationsCandidat.epreuve.id_epreuve_maitresse))) || // si tableau du haut et ligne deux
                                (!forCandidatSelect &&
                                (parseInt(field.epreuve_id) === parseInt(infoReclamationsCandidat.epreuve.id) || parseInt(field.epreuve_id) === parseInt(infoReclamationsCandidat.epreuve.id_epreuve_maitresse)) && // si c'est l'epreuve maitresse qui est affiché
                                parseInt(result.candidat_id) === parseInt(idCandidatSelect))) { // si tableau du bas et epreuve_id === epreuve de la reclamation
                                itemNotef = formatNumber(infoReclamationsCandidat.note)
                                classItem = 'isMoved text-center fixed_size'
                            } else {
                                if (filter && filter[0] && (filter[0].note_finale || filter[0].note_finale === 0)) {
                                    itemNotef = formatNumber(filter[0].note_finale)
                                    if (index === 1) {
                                        if (parseInt(result.candidat_id) === parseInt(idCandidatSelect) &&
                                        (parseInt(field.epreuve_id) === parseInt(infoReclamationsCandidat.epreuve.id) ||
                                        parseInt(field.epreuve_id) === parseInt(infoReclamationsCandidat.epreuve.id_epreuve_maitresse))) {
                                            itemNotef = formatNumber(infoReclamationsCandidat.note)
                                            classItem = 'isMoved text-center fixed_size'
                                        } else {
                                            itemNotef = formatNumber(filter[0].note_finale)
                                        }
                                    } else {
                                        itemNotef = formatNumber(filter[0].note_finale)
                                    }
                                } else {
                                    if (filter && filter[0] && filter[0].statut === CandidatStatut.ABSENT) {
                                        itemNotef = 'Abs.'
                                        labelItem = 'Absent'
                                    } else if (filter && filter[0] && filter[0].statut === CandidatStatut.INSCRIT) {
                                        itemNotef = '-'
                                        labelItem = 'Inscrit'
                                    } else {
                                        itemNotef = 'NI.'
                                        labelItem = 'Non inscrit'
                                    }
                                }
                            }
                            line.push({
                                label: labelItem,
                                // l'item se charge en fonction du tableau à charger
                                item: itemNotef,
                                type: 'text',
                                typeAction: null,
                                // class :
                                class: classItem
                                /* (forCandidatSelect && index === 1 &&
                                 parseInt(field.epreuve_id) === parseInt(infoReclamationsCandidat.epreuve.id)) || // si tableau du haut et deuxieme ligne
                                 (!forCandidatSelect &&
                                 (parseInt(field.epreuve_id) === parseInt(infoReclamationsCandidat.epreuve.id) ||
                                    parseInt(field.epreuve_id) === parseInt(infoReclamationsCandidat.epreuve.id_epreuve_maitresse)) && // si c'est l'epreuve maitresse qui est affiché  // si tableau du bas et epreuve et candidat id concorde avec le candidat et la note changée
                                 parseInt(result.candidat_id) === parseInt(idCandidatSelect)) ? 'isMoved text-center fixed_size' : 'text-center fixed_size'
                                 */
                            })
                        }
                    }
                }

                /* 3ème partie en dur - totaux, rang, points, moyenne, classement */

                // Equipe
                const equipeInterclassementMatch = result.candidat.ensembles.filter((e: any) => e.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT)
                let equipeInterclassement = ''
                if (equipeInterclassementMatch.length > 0) {
                    equipeInterclassement = equipeInterclassementMatch[0].name
                }

                // info "est classé"
                let isClasse = false
                isClasse = result.recu === candidatRecu.OUI


                if (this.selectedConcour.phases.length > 1) {
                    const phasesFields = []
                    for (let i = 0; i < this.selectedConcour.phases.length;  i++) {
                        if(this.selectedConcour.phases[i].phase_id === this.selectedCandidatReclamation.epreuve.phase_id) {
                            for (let j = 0; j < this.selectedConcour.phases[i].bar_rules.length; j++) {
                                if(this.selectedConcour.phases[i].bar_rules[j].type === 'point') {
                                    phasesFields.push({
                                        label: '',
                                        item: pointsAdmiss,
                                        type: 'text',
                                        typeAction: null,
                                        class: 'text-center' + (pointsAdmissModifie && (parseInt(result.candidat_id) === parseInt(idCandidatSelect) && forCandidatSelect === false) || (forCandidatSelect === true && index === 1)  ?  ' isMoved' : '')
                                    })
                                }
                            }
                        }
                    }
                    line.push(...phasesFields)
                }

                /* 3ème partie en dur - totaux, rang, poins, moyenne, classement */
                const colonnes_resultats = [
                    {
                        label: '',
                        item: formatNumber(moyenneGenerale),
                        type: 'text',
                        typeAction: null,
                        class: 'fixed_size text-center item_action' + (moyenneGeneraleModifie && (parseInt(result.candidat_id) === parseInt(idCandidatSelect) && forCandidatSelect === false) || (forCandidatSelect === true && index === 1)  ?  ' isMoved' : '')
                    }, // moyenneGenerale => OK

                    {
                        label: '',
                        item: equipeInterclassement !== '' ?  equipeInterclassement : '-',
                        type: 'text',
                        typeAction: null,
                        class: 'fixed_size text-center item_action '
                    }, // interclassementEquipe => OK

                    {
                        label: '',
                        item: rang_equipe,
                        type: 'text',
                        typeAction: null,
                        class: 'fixed_size text-center item_action' + (rang_equipeModifie && (parseInt(result.candidat_id) === parseInt(idCandidatSelect) && forCandidatSelect === false) || (forCandidatSelect === true && index === 1)  ?  ' isMoved' : '')
                    }, // interclassementRang => OK
                    // label si dessous
                    /*
                    {
                        label: '',
                        item: forCandidatSelect && index === 1  ? ranking_group_id : rankGroupName ,
                        type: forCandidatSelect && index === 1 ? 'comboDeroulant' : 'text',
                        typeAction: forCandidatSelect && index === 1 ? 'comboDeroulant' : null, // si tableau du dessus : combo
                        optionsDeroulant: options_admissiom,
                        class: 'text-center item_medium' + (ranking_groupModifie && (parseInt(result.candidat_id) === parseInt(idCandidatSelect) && forCandidatSelect === false) || (forCandidatSelect === true && index === 1)  ?  ' isMoved' : '')
                    },*/
                    {
                        label: '',
                        item: rankGroupName ,
                        type:  'text',
                        typeAction: null, // si tableau du dessus : combo
                        class: 'text-center item_medium' + (ranking_groupModifie && (parseInt(result.candidat_id) === parseInt(idCandidatSelect) && forCandidatSelect === false) || (forCandidatSelect === true && index === 1)  ?  ' isMoved' : '')
                    },
                    /*
                    {
                        label: '',
                        item: isClasse ?  'Oui' : 'Non',
                        type: forCandidatSelect && index === 1 ? 'comboDeroulant' : 'text',
                        typeAction: forCandidatSelect && index === 1 ? 'comboDeroulant' : null, // si tableau du dessus : combo
                        class: 'fixed_size_classe text-center item_action',
                        optionsDeroulant: [{ text: 'Oui', value: 'Oui' }, { text: 'Non', value: 'Non' }]  // sert à alimenter le menu déroulant de la 2ème ligne du premier tableau
                    }, // isClasse => OK
                    */

                    {
                        label: '',
                        item:  rang,
                        disabled: ranking_group_id !== this.passCurrentPhaseRankingGroupId, // !isClasse,
                        type: forCandidatSelect && index === 1 ? 'inputNumber' : 'text', // si ligne 2 du premier tableau, mise en place de inputNumber
                        typeAction: forCandidatSelect && index === 1 ? 'inputNumber' : null, // same as type
                        minInput: 1,
                        class: classRang + (rangModifie && (parseInt(result.candidat_id) === parseInt(idCandidatSelect) && forCandidatSelect === false) || (forCandidatSelect === true && index === 1)  ?  ' isMoved' : '')
                    }, // classementInterne

                    {
                        label: '',
                        item:  rang_externe,
                        type: 'text',
                        typeAction: null,
                        class:'fixed_size text-center item_action' + (rang_externeModifie && (parseInt(result.candidat_id) === parseInt(idCandidatSelect) && forCandidatSelect === false) || (forCandidatSelect === true && index === 1)  ?  ' isMoved' : '')
                    } // classementFinalPublic  => OK
                ]
                line.push(...colonnes_resultats)
                // si la construction demandée est faite pour le candidat selectionné
                if (forCandidatSelect === true) {
                    // push de la premiere ligne
                    if (index === 1) {
                        line.trClass = 'ProductSans-Bold background_selected_candidat'
                    }
                    this.dataForTabCandidatInformations.push(line)
                }
                if (forCandidatSelect === false) {
                    line.trClass = parseInt(result.candidat_id) === parseInt(idCandidatSelect) ? 'background_selected_candidat' : ''
                    this.dataForTab.push(line)
                    this.calculEnCours = false
                }
            })
        }
    }

    getEliminationInfos(infos: any) {
        if (infos.absenceEliminatoire) {
            return 'Absent(e)'
        } else if (infos.barreEliminatoire) {
            return `Rejet ${this.$store.getters['definitionDesBarres/selectedConcour'].deliberation.barres[0].nom}`
        }
        return ' - '
    }

    /** Recup de la ligne correspondant au candidat dans le setData et push les informations dans le tableau 1, change le dernier */
    createCandidatInformations(candidat: any, updateLigne: any = null) {
        const lignesCandidatSelect = []
        if (candidat) {
            lignesCandidatSelect.push(candidat[0])
            const doublementDeLigne = updateLigne || candidat[0]
            lignesCandidatSelect.push(doublementDeLigne)
            this.setDataForGenericTab(lignesCandidatSelect, false, true)
        }
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setFiltersForGenericTab()
    {
        // no filter
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    // this.loadHandler(paParams[1])
                    break
                case 'inputNumber':
                    if(paParams[1] < 1) {
                        paParams[1] = 1
                    }
                    this.reclassementCandidat(paParams[1])
                    break
                default:
                    break
            }
        }
    }

    /**
     *
     *  appelle le getter pour reclasser le candidat
     * forceUpdate et nextTick sont là pour contrer le problème de rafraichissement du dom et de l'affichage du message de recalcul
     * (l'affichage du message ne se faisait pas)
     *
      */
    reclassementCandidat(paParams: any) {

        this.$store.commit('reclassement/SET_ERROR', null)
        const candidat_id = this.$route.params.candidat_id
        const maxClassement = this.$store.state.reclassement.nbCandidatsClasses
        let newCandidatPosition = paParams

        if (maxClassement && parseInt(newCandidatPosition) > parseInt(maxClassement)) {
            newCandidatPosition = maxClassement
        }
        if(parseInt(newCandidatPosition) < 1) {
            newCandidatPosition = 1
        }

        // si l'utilisateur met un rang trop bas, on prend la valeur max du classement

        const data = this.$store.getters['reclassement/reclassementOnNumberInputChange'](candidat_id, newCandidatPosition, this.lastSortable, this.ligne2.ranking_group_id)
        this.simulation.rang = parseInt(newCandidatPosition)
        // recup du nouveau classement final pour l'affichage
        this.savChangementClassement = this.$store.state.reclassement.newReclassementFinal
        // recup info du canddiat apres reclassement
        const candidat = data.filter((candidat: { candidat_id: string }) => parseInt(candidat.candidat_id) === parseInt(candidat_id))[0]
        // mise à jour de la 2ème ligne des informations candidats classement final
        this.dataForTabCandidatInformations[1][this.fieldRangPosition].item = candidat && candidat.rang_externe ? candidat.rang_externe : null
        this.simulation.rang_externe = this.dataForTabCandidatInformations[1][this.fieldRangPosition].item

        const dataOriginal = this.selectedCandidatRangInformation.find((cri: any) => cri.concour_id === this.selectedConcour.id)
        if(dataOriginal.rang === parseInt(newCandidatPosition)) {
            this.simulation.rang = dataOriginal.rang
            this.simulation.rang_externe = dataOriginal.rang_externe
        }

        this.calculEnCours = true
        this.$forceUpdate()
        this.$nextTick().then(() => {
            setTimeout(() => {
                this.setDataForGenericTab(data)
                this.getScrollOnGoodPlace()
                this.calculEnCours = false
            }, 1)
        })
        this.ligne2.rang         = this.simulation.rang
        this.ligne2.rang_externe = this.simulation.rang_externe
        this.createCandidatInformations(this.selectedCandidatRangInformation, this.ligne2)
    }

    /**
     * Initialisation des données candidat affichées dans le tableau ainsi que les compteurs
     */
    initDatas (poCandidats: any)
    {
        if (poCandidats && poCandidats.data && poCandidats.data.data)
        {
            this.setDataForGenericTab(poCandidats.data.data)
        }
    }

    /**
     * Appel d'une nouvelle page de candidats lors du scroll
     */
    loadHandler (params: any)
    {
        this.$store.commit('reclassement/SET_ERROR', null)
        // Init des params par défaut si vide
        /*
        if (this.$store.state.reclassement.params_liste_candidats === {}) {
            const params = { page: 1, sort: 'rang', direction: 'asc' }
            this.$store.commit('reclassement/SET_PARAMS', params)
        }
        */
        if (JSON.stringify(this.$store.state.reclassement.params_liste_candidats) !== JSON.stringify(params)) {
            this.$store.commit('reclassement/SET_PARAMS', params)
            const payload = {
                concour_id: this.$route.params.concour_id,
                phase_id: this.selectedCandidatReclamation.epreuve.phase_id,
                params: this.$store.state.reclassement.params_liste_candidats
            }
            this.$store.dispatch('reclassement/getMoreClassement', payload).then(() => {
                this.setDataForGenericTab(this.$store.getters['reclassement/liste_candidats'])
            }).catch(() => {
                console.log('ko:')
            })
        }
    }

    /**
     * Appel d'une nouvelle page de candidats lors du scroll
     */
    async filtreSortHandler (params: any)
    {
        this.$store.commit('reclassement/SET_ERROR', null)
        const dataFiltered = _.orderBy(this.$store.state.reclassement.liste_candidats_copy, params.sort, params.direction)

        this.calculEnCours = true
        await this.$nextTick().then(() => {
            setTimeout(() => {
                this.setDataForGenericTab(dataFiltered)
                this.getScrollOnGoodPlace()
            }, 1)
        })
        // this.calculEnCours = false
        this.lastSortable =  params

        // this.calculEnCours = false
    }

    openModaleValidationREclassement() {
        this.$store.commit('reclassement/SET_ERROR', null)
        this.showModaleValidationREclassement = true
    }

    closeModaleValidationReclassement() {
        this.$store.commit('reclassement/SET_ERROR', null)
        this.showModaleValidationREclassement = false
    }

    /** requete confirmation reclassement  */
    confirmValidationReclassement () {
        // toast
        this.$store.commit('reclassement/SET_ERROR', null)
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        // fin toast
        const params = {
            candidat_id : this.$route.params.candidat_id,
            phase_id : this.selectedCandidatReclamation.epreuve.phase_id,
            reclamation_id : this.$route.params.reclamation_id,
            new_state: {
                concour_id: this.$route.params.concour_id,
                rang: this.savChangementClassement ? parseInt(this.savChangementClassement) : this.savChangementClassement,
                ranking_group_id: this.ligne2.ranking_group_id
            }
        }

        this.$store.dispatch('reclassement/validationReclassement', params)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                // à la validation, push vers la gestion des
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                this.closeModaleValidationReclassement()
                this.$router.push(`/reclamations_post_concours/${this.$route.params.reclamation_id}`).catch(() => { console.log('catch redundant navigation ' + `/reclamations_post_concours/${this.$route.params.reclamation_id}`) })
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /** scroll automatique vers le candidat selectionné  */
    getScrollOnGoodPlace() {
        this.$nextTick(() => {
            // index candidat
            const indexOfCandidat = this.$store.state.reclassement.liste_candidats_copy.findIndex((candidat: { candidat_id: any }) => parseInt(candidat.candidat_id) === parseInt(this.$route.params.candidat_id))
            // taille de chaque row du tableau
            const heightOfRow = 50
            // indice du next row
            const sizeOfNextScroll = (indexOfCandidat - 3) * heightOfRow
            // au prochain tick, recup du query du tableau et changement du scroll
            const table2 = document.querySelector('.table2')
            const defilableTable = table2?.querySelector('.commons_table_box')
            if (defilableTable) {
                defilableTable.scrollTop = sizeOfNextScroll
            }
        })
    }

    load() {
        this.loadingListeCand = true
        this.dataForTab = []
        const concour_id = this.$route.params.concour_id
        const candidat_id = this.$route.params.candidat_id
        this.$store.commit('reclassement/SET_ERROR', null)
        // Si aucun concours n'a été chargé, alors on les récupère

        this.loadConcoursIfNotExists().then(() => {

            this.$store.dispatch('reclamationPostConcours/getReclamation', Number(this.$route.params.reclamation_id)).then((response) => {

                this.selectedCandidatReclamation = response.data.data

                this.$store.dispatch('definitionDesBarres/getTableauDesConcours').then((resp) => {

                    this.$store.commit('definitionDesBarres/SET_SELECTED_CONCOUR', resp.data.data.filter((concour: { concour_id: any }) => concour.concour_id === parseInt(this.$route.params.concour_id))[0])

                    this.$store.dispatch('concour/getConcour', this.$route.params.concour_id).then( (response) => {

                        this.selectedConcour = response.data.data
                        for(let i = 0; i < this.selectedConcour.phases.length; i++) { // this.passCurrentPhaseRankingGroupId
                            if(parseInt(this.selectedConcour.phases[i].phase_id) === parseInt(this.selectedCandidatReclamation.epreuve.phase_id)) {
                                for (let j = 0; j < this.selectedConcour.phases[i].barres.length; j++) {
                                    if(this.selectedConcour.phases[i].barres[j].type === 'pass') {
                                        this.passCurrentPhaseRankingGroupId = this.selectedConcour.phases[i].barres[j].ranking_group_id
                                    }
                                }
                            }
                        }
                        const payload_1 = { concour_id: this.$route.params.concour_id, phase_id: this.selectedCandidatReclamation.epreuve.phase_id }
                        this.genericfields = this.createDynamicFields(response.data.data.epreuves_deliberation)

                        this.$store.dispatch('rankingGroup/getRankingGroupsConcourPhase', payload_1).then(() => {

                            if (!this.$store.state.definitionBarresListeNotesAdmission.params_definition_barres) {
                                const params = { page: 1, sort: 'rang', dzirection: 'asc' }
                                this.$store.commit('definitionBarresListeNotesAdmission/SET_PARAMS', params)
                            }
                            const payload = {
                                concour_id: this.$route.params.concour_id,
                                phase_id: this.selectedCandidatReclamation.epreuve.phase_id,
                                params:  { perPage: -1, sort: 'rang', direction: 'asc' }
                            }
                            this.$store.commit('definitionBarresListeNotesAdmission/SET_PARAMS', payload.params)
                            this.$store.dispatch('definitionBarresListeNotesAdmission/getTableauListeDesNotes', payload).then((response) => { /// same (getDefinitionDesBarresListeNotes)

                                this.simulation = this.selectedCandidatReclamation.new_results.filter((simu: { candidat_id: string; concour_id: string }) => parseInt(simu.candidat_id) === parseInt(candidat_id) && parseInt(simu.concour_id) === parseInt(concour_id))[0]

                                // this.$store.dispatch('reclassement/getListeClassement', payload).then(() => { /// same (getListeCandidats)
                                this.$store.dispatch('reclassement/setReclassementListeCandidat', response)


                                // recup de la ligne du candidat dans la liste des candidats

                                // this.$store.dispatch('definitionBarresListeNotesAdmission/getTableauListeDesNotes', payload).then(() => {/// same (getDefinitionDesBarresListeNotes)
                                this.setFiltersForGenericTab()
                                this.selectedCandidatRangInformation = _.cloneDeep(this.$store.state.reclassement.liste_candidats.filter((candidat: {  candidat_id: any }) => parseInt(candidat.candidat_id) === parseInt(this.$route.params.candidat_id)))
                                this.$store.state.reclassement.reclassementCandidatSelect = this.selectedCandidatRangInformation[0]
                                this.firstCreateLigne2()
                                if(this.simulation && this.simulation.rang) {
                                    this.reclassementCandidat(this.simulation.rang)
                                }
                                // })
                                // })
                                this.loadingListeCand = false
                            })
                        })
                    })
                })
            })

        })
    }

    mounted() {
        this.load()
    }
}
