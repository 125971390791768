



















































































































































































































































import { Vue, Component } from 'vue-property-decorator'
import { mapGetters }           from 'vuex'
import Table                    from '@/components/Table.vue'
import { Ability }              from '@/types/Ability'
import { codeRestrict, isObject }         from '@/utils/helpers'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import EditionConcour from '@/views/Administration/Sessions/EditionConcour.vue'
import { TypeDossier, getEtatTraitementDossier, EtatTraitementDossier } from '@/types/Candidat'

@Component({
    components: {
        Table,
        ExaGenericTable,
        EditionConcour
    },
    computed: {
        ...mapGetters('epreuve', ['epreuves', 'getEpreuveByConcour_id']),
        ...mapGetters('concour', ['loading', 'error', 'concours', 'concourById', 'meta', 'links', 'totalRows']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})

export default class Concours extends Vue  {
    sortBy                          = ''
    sortDesc                        = false
    sortDirection                   = 'asc'
    filter                          = ''
    filterOn                        = []
    stickyHeader                    = true
    concour: any                    = null
    concourTemp: any                = null
    Ability                         = Ability

    showModalEditionConcour         = false
    showModalMessageDelete          = false

    session_id: any                 = 0
    currentSession: any             = null
    actionLimited                   = false
    codeRestrict                    = codeRestrict

    // DATAS
    params                          = 'sort=name&direction=asc'
    firstLoading                    = true
    options_filieres: Array<any>    = []
    selected_tab                    = 'concours'
    loadingConcours                 = false

    showModalImportConcours = false
    messagesErreur: Array<string> = []
    importEnCours = false
    file: any = null

    // ---- generic table --------------------------

    dataForTab: Array<any>          = []
    filtres: any                    = []

    getEtatTraitementDossier = getEtatTraitementDossier
    EtatTraitementDossier = EtatTraitementDossier

    genericfields = [
        // { key: 'etatEdit', label: '', sortable: false, class: '', type: 'action' },
        { key: 'code',          label: 'Code',          sortable: true,     class: 'text-left col-min-width', type: 'text' },
        { key: 'long_name',     label: 'Libellé long',  sortable: true,     sortDirection: 'asc', class: 'text-left', type: 'text' },
        { key: 'name',          label: 'Libellé court', sortable: true,     sortDirection: 'asc', class: 'text-left', type: 'text' },
        { key: 'banque_id',     label: 'Filière',       sortable: true,     class: 'text-left', type: 'text' },
        { key: 'phases',        label: 'Phases',        sortable: true,     class: 'text-left', type: 'text' },
        { key: 'nb_epreuves',   label: 'Épreuves',      sortable: false,    class: 'text-center col-min-width', type: 'text' },
        { key: 'delib',         label: 'Déliberation',  sortable: false,    class: 'text-center col-min-width', type: 'text' },
        { key: 'delete',        label: '',              sortable: false,    class: '', type: 'action' },
        { key: 'etatEdit',      label: '',              sortable: false,    class: '', type: 'action' }

    ]

    // METHODS
    // ---- generic table --------------------------

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setDataForGenericTab(poData: any, isLoadMore = false) {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const result of poData) {
                if (result.banque_id != null) {
                    const filiere = result.banque ? result.banque.name : '-'

                    let phases = ''
                    for (let i = 0; i < result.phases.length; i++) {
                        if (phases !== '') {
                            phases += ' / '
                        }
                        phases += result.phases[i].name
                    }

                    const nb_epreuves = this.$store.getters['concour/nbEpreuvesFromPhaseById'](result.id)

                    let deliberation = ''
                    for (let i = 0; i < result.phases.length; i++) {
                        if (deliberation !== '') {
                            deliberation += ' / '
                        }
                        deliberation += result.phases[i].type_deliberation === 1 ? 'Oui' : '-'
                    }

                    // const classEtatEdit = 'text-light col-w-etat ' + (!result.closed_at ? 'btn_action_ligne' : 'bg-secondary')
                    // const iconEtatEdit = !result.closed_at ? this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE) ? 'pen' : 'eye' : 'lock'

                    const line: Array<any> = [
                        // { label: '', item: result, icon: iconEtatEdit, type: 'action', typeAction: 'etatEdit', class: classEtatEdit, disabled: false },
                        { label: '', item: result.code, type: 'text', typeAction: null, class: '' },
                        { label: '', item: result.long_name, type: 'text', typeAction: null, class: '' },
                        { label: '', item: result.name, type: 'text', typeAction: null, class: '' },
                        { label: '', item: filiere, type: 'text', typeAction: null, class: '' },
                        { label: '', item: phases, type: 'text', typeAction: null, class: '' },
                        { label: nb_epreuves.title, item: nb_epreuves.val, type: 'text', typeAction: null, class: 'text-center' },
                        { label: '', item: deliberation, type: 'text', typeAction: null, class: 'text-center' }
                    ]

                    if (this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE) && this.currentSession.closed_at === null && this.currentSession.structure_valide === 0) {
                        const trashLine =  { label: 'Supprimer',   item: result, type: 'action',  typeAction: 'delete',  class: 'text-secondary', icon:'trash-alt', disabled: false }
                        line.push(trashLine)
                    }
                    line.push({ label: '', item: result, icon: 'arrow-circle-right', type: 'action', typeAction: 'etatEdit', class: 'text-primary col-w-action bg-transparent', disabled: false })
                    this.dataForTab.push(line)
                }
            }
        }
        return this.dataForTab
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setFiltersForGenericTab() {
        const filieres = this.$store.getters['concour/banques']
        this.options_filieres = []
        for (const f in filieres) {
            this.options_filieres.push({ index: filieres[f].id, name: filieres[f].name, long_name: filieres[f].long_name, config_phase_id: filieres[f].config_phase_id })
        }

        this.filtres = [
            {
                libelle: 'Code',
                defautOptionlibelle: 'Rechercher un',
                model: 'code',
                value: '',
                index: 'code',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'code', strict: true } // 'form' , 'deroulant'
            },
            {
                libelle: 'Libellé long',
                defautOptionlibelle: 'Rechercher un',
                model: 'long_name',
                value: '',
                index: 'long_name',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'long_name' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Libellé court',
                defautOptionlibelle: 'Rechercher un',
                model: 'name',
                value: '',
                index: 'name',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'name' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Filière',
                defautOptionlibelle: 'Rechercher une',
                model: 'banque_id',
                value: '-',
                index: 'id',
                datas: this.options_filieres,
                loading: false,
                options: { type: 'deroulant', fieldsKey: 'banque_id' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Phase',
                defautOptionlibelle: 'Rechercher une',
                model: 'phases.name',
                value: '',
                index: 'id',
                datas: null,
                loading: false,
                options: { type: 'form', fieldsKey: 'phases' } // 'form' , 'deroulant'
            }
        ]
    }

    /**
     * Récupération des events de la table
     */
    handleTableEvent (paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'edit':
                case 'openComment':
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'etatEdit':
                    this.editConcour(paParams[1])
                    break
                case 'delete':
                    this.deleteConcour(paParams[1])
                    break
            }
        }
    }

    // --------------------------------------

    // ------- imporrt conccours ----------------------------

    liste_dossiers_concours = []
    showErrorDossier = false
    dossier_error = null

    isObject = isObject

    openImportFichiersConcours () {
        this.showModalImportConcours = true
        this.loadListesDossiers()
    }


    /** Affiche les erreurs d'un fichier */
    showErrorsFichiers (dossier: any) {
        this.dossier_error = dossier
        this.showErrorDossier = true
    }

    /** Ferme la visualisation des errors */
    closeErrorFichiers () {
        this.dossier_error = null
        this.showErrorDossier = false
    }

    /*
    loadListesDossiers () {
        this.$store.dispatch('candidat/getDossiers', { type: TypeDossier.TYPE_IMPORT_CONCOURS_VIATIQUE }).then(() => {
            this.liste_dossiers_concours = this.$store.state.candidat.liste_dossiers
            this.showModalImportConcours = true
        })
    }
    */

    reinitTimeOut() {
        // console.log('Dans reinitTimeOut - this.currentTimeoutIDArray', this.currentTimeoutIDArray)
        for (const idTimeout in this.currentTimeoutIDArray) {
            clearTimeout(parseInt(this.currentTimeoutIDArray[idTimeout]))
        }
        if (this.currentTimeoutID !== -1) {
            clearTimeout(this.currentTimeoutID)
        }
        this.currentTimeoutIDArray = []
        this.currentTimeoutID = -1
    }

    currentTimeoutID = -1
    currentTimeoutIDArray: Array<any> = []
    loadListesDossiers () {
        this.$store.dispatch('candidat/getDossiers', { type: TypeDossier.TYPE_IMPORT_CONCOURS_VIATIQUE }).then(() => {
            this.liste_dossiers_concours = this.$store.state.candidat.liste_dossiers
        })
        this.currentTimeoutID = setTimeout(() => {
            if (this.showModalImportConcours) {
                this.currentTimeoutIDArray.push(this.currentTimeoutID)
                this.loadListesDossiers()
            } else {
                this.reinitTimeOut()
            }
        }, 5000)
    }

    closeModalImportConcours () {
        this.reinitTimeOut()
        this.closeErrorFichiers()
        this.showModalImportConcours = false
        this.$store.dispatch('concour/getConcours',  { session_id: this.session_id, params: this.params }).then(() => {
            this.setDataForGenericTab(this.$store.state.concour.concours)
        }).catch((error) => {
            console.log('ko:' + error)
        })
    }

    envoiFichierImportConcours() {
        // Création du toaster en cours
        this.messagesErreur = []
        const idInfo        = 't_info_' + Math.random()
        const infosToaster  = {
            id:             idInfo,
            toaster:        'b-toaster-top-right',
            variant:        'primary',
            noCloseButton:  true,
            fade:           true,
            noAutoHide:     true
        }

        this.$bvToast.toast('Envoi en cours ...', infosToaster)
        this.importEnCours = true

        // Appel de la fonction pour importer les etabs
        this.$store.dispatch('concour/import', this.file)
            .then(() => {
                this.importEnCours = false

                const idSucces      = 't_succes_' + Math.random()
                const succesToaster = {
                    id:             idSucces,
                    toaster:        'b-toaster-top-right',
                    variant:        'success',
                    noCloseButton:  true,
                    fade:           true,
                    autoHideDelay:  5000
                }
                this.$bvToast.toast('Concours importés avec succès !', succesToaster)
                this.$store.dispatch('concour/getConcours',  { session_id: this.session_id, params: this.params })
                    .then(() => {
                        this.setDataForGenericTab(this.$store.state.concour.concours)
                        this.loadListesDossiers()
                        this.reinitTimeOut()
                        this.loadingConcours = false
                    })
                    .catch((error) => {
                        console.log('ko:' + error)
                    })
            })
            .catch((error) => {
                this.importEnCours = false
                console.log('ko:' + error)

                // Création du message d'erreurs
                if (error.response && error.response.data && error.response.data.errors) {
                    for (const err in error.response.data.errors) {
                        if (error.response.data.errors[err]) {
                            if (error.response.data.errors[err].row) {
                                const retourError = 'erreur ligne' + error.response.data.errors[err].row + ' : ' + error.response.data.errors[err].errors
                                this.messagesErreur.push(retourError)
                            }
                        }
                    }
                }
                // Toaster its a fail !
                const idError = 't_error_' + Math.random()
                const errorToaster = {
                    id:             idError,
                    toaster:        'b-toaster-top-right',
                    variant:        'danger',
                    noCloseButton:  true,
                    fade:           true,
                    autoHideDelay:  5000
                }
                this.$bvToast.toast("Une erreur s'est produite lors de l'import", errorToaster)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * changement de fichier ?
     */
    fileChange (event: any) {
        this.file = event.target.files[0]
    }

    // -----------------------------------------

    buildFiltres () {
        const filieres = this.$store.getters['concour/banques']
        const options_filieres = []
        for (const f in filieres) {
            options_filieres.push({ id: filieres[f].id, name: filieres[f].name })
        }

        this.filtres = [
            /*
            {
                libelle: 'Code',
                defautOptionlibelle: 'Rechercher un',
                model: 'code',
                value: '',
                index: 'code',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'code', strict: true } // 'form' , 'deroulant'
            },
            {
                libelle: 'Libellé long',
                defautOptionlibelle: 'Rechercher un',
                model: 'long_name',
                value: '',
                index: 'long_name',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'long_name' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Libellé court',
                defautOptionlibelle: 'Rechercher un',
                model: 'name',
                value: '',
                index: 'name',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'name' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Filière',
                defautOptionlibelle: 'Rechercher une',
                model: 'banque_id',
                value: '-',
                index: 'id',
                datas: options_filieres,
                loading: false,
                options: { type: 'deroulant', fieldsKey: 'banque_id' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Phase',
                defautOptionlibelle: 'Rechercher une',
                model: 'phases.name',
                value: '',
                index: 'id',
                datas: null,
                loading: false,
                options: { type: 'form', fieldsKey: 'phases' } // 'form' , 'deroulant'
            }
            */
        ]
    }

    getLibelleConcoursById(id: number) {
        return this.$store.state.concours.filter((concour: any) => concour.banque_id === id).name
    }

    openAddConcour () {
        this.concourTemp = {
            id: 0,
            code: '',
            name: '',
            long_name: '',
            code_import: '',
            banque_id: null,
            ordre: 1

        }
        this.showModalEditionConcour = true
    }

    editConcour (data: any) {
        this.$emit('clickEditConcour', { session_id: this.session_id, concour_id: data.id })
    }


    loadEpreuve = false
    loadListeEpreuves () {
        this.loadEpreuve = true
        this.$store.dispatch('epreuve/getEpreuves',  { session_id: this.session_id }).then(() => {
            this.loadConcour(this.concourTemp.id)
        }).catch((error) => {
            console.log('ko:' + error)
        })
    }

    loadConcour(concour_id: number) {
        this.$store.dispatch('concour/getConcour',  concour_id).then(() => {
            this.concourTemp = this.$store.state.concour.concourTemp
            this.loadEpreuve = false
        })
    }

    cancelEdit () {
        this.concour = null
        this.concourTemp = null
        this.showModalEditionConcour = false
    }

    editSuite () {
        let config_phase_id = 0
        for (let i = 0; i < this.options_filieres.length; i++) {
            if (this.options_filieres[i].index === this.concourTemp.banque_id) {
                config_phase_id = this.options_filieres[i].config_phase_id
            }
        }
        const payload = {
            id: this.concourTemp.id,
            code: this.concourTemp.code,
            name: this.concourTemp.name,
            long_name: this.concourTemp.long_name,
            session_id: this.session_id,
            code_import: this.concourTemp.code_import,
            banque_id: this.concourTemp.banque_id,
            ordre: this.concourTemp.ordre,
            config_phase_id: config_phase_id

        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        if (this.concourTemp.id === 0) {
            this.$store.dispatch('concour/addConcour', payload)
                .then(() => {
                    this.concour = null
                    this.concourTemp = null
                    this.showModalEditionConcour = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                    this.$store.dispatch('concour/getConcours',  { session_id: this.session_id, params: this.params })
                        .then(() => {
                            this.setDataForGenericTab(this.$store.state.concour.concours)
                        }).catch((error) => {
                            console.log('ko:' + error)
                        })
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            this.$store.dispatch('concour/updateConcour', payload)
                .then(() => {
                    this.concour = null
                    this.concourTemp = null
                    this.showModalEditionConcour = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                    this.$store.dispatch('concour/getConcours',  { session_id: this.session_id, params: this.params })
                        .then(() => {
                            this.setDataForGenericTab(this.$store.state.concour.concours)
                        }).catch((error) => {
                            console.log('ko:' + error)
                        })
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    deleteConcour (data: any) {
        this.concour = data
        this.showModalMessageDelete = true
    }

    cancelDelete () {
        this.concour = null
        this.showModalMessageDelete = false
    }

    deleteSuite () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Suppression en cours ...', infosToaster)
        this.$store.dispatch('concour/deleteConcour', this.concour.id)
            .then(() => {
                this.concour = null
                this.concourTemp = null
                this.showModalEditionConcour = false
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                this.concour = null
                this.showModalMessageDelete = false
                this.$store.dispatch('concour/getConcours',  { session_id: this.session_id, params: this.params })
                    .then(() => {
                        this.setDataForGenericTab(this.$store.state.concour.concours)
                    }).catch((error) => {
                        console.log('ko:' + error)
                    })
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    loadHandler (params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)  || (JSON.stringify(this.params) === JSON.stringify(params) && this.firstLoading)) {
            this.params = params
            this.loadingConcours = true
            this.$store.dispatch('concour/getMoreConcours', { session_id: this.session_id, params: params }).then(() => {
                if (this.firstLoading) {
                    this.setFiltersForGenericTab()
                    this.firstLoading = false
                }
                this.setDataForGenericTab(this.$store.state.concour.concours)
                this.loadingConcours = false
            }).catch((error) => {
                console.log('ko:' + error)
            })
        }
    }

    // Appelle une page lors du scroll
    filtreSortHandler (params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.loadingConcours = true
            this.$store.dispatch('concour/getConcours',  { session_id: this.session_id, params: params }).then(() => {
                this.setDataForGenericTab(this.$store.state.concour.concours)
                this.loadingConcours = false
            }).catch((error) => {
                console.log('ko:' + error)
            })
        }
    }

    mountedSuite () {
        this.currentSession = this.$store.state.session.sessionSelect
        if (this.$store.getters['concour/banques'].length === 0) {
            this.$store.dispatch('concour/getConcours',  { session_id: this.session_id }).then(() => {
                this.setFiltersForGenericTab()
                this.setDataForGenericTab(this.$store.state.concour.concours)
            }).catch((error) => {
                console.log('ko:' + error)
            })
        } else {
            this.setFiltersForGenericTab()
        }
    }

    beforeUnmount () {
        this.reinitTimeOut()
    }

    mounted () {
        window.onpopstate = () => {
            this.reinitTimeOut()
        }
        window.addEventListener('popstate', () => {
            this.reinitTimeOut()
        })
        this.reinitTimeOut()
        if (this.$route && this.$route.params && this.$route.params.session_id) {
            this.currentSession = null
            this.session_id = this.$route.params.session_id
            this.$store.dispatch('session/getSession', { session_id: this.session_id })
                .then(() => {
                    this.mountedSuite()
                }).catch((error) => {
                    console.log('ko:' + error)
                })
        } else {
            this.mountedSuite()
        }
    }
}
