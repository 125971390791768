































































































































































































































import { mapActions, mapGetters, mapState } from 'vuex'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Etat } from '@/types/DossierAcademique'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VuePdfApp from 'vue-pdf-app'
import { base64ToArrayBuffer } from '@/utils/helpers'
import  ErrorDisplay  from '@/components/ErrorDisplay.vue'

@Component({
    computed: {
        ...mapGetters('dossieradministratif', ['contrats']),
        ...mapState('dossieradministratif', ['contrats']),
        ...mapGetters('ordremission', ['ordreMissionSelect', 'save_finish', 'source_pdf']),
        ...mapState('ordremission', ['loading', 'ordreMissionSelect', 'save_finish', 'source_pdf', 'error'])
    },
    methods: {
        ...mapActions('ordremission', ['addOrdreMission'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        VuePdfApp,
        ErrorDisplay
    }
})

export default class DemandeOrdreMission extends Vue {
    @Prop() showPopup?: boolean;
    @Prop() ordremission?: any;

    list_contrats: Array<any> = []

    @Watch('error')
    hiddenConfirm () {
        if (this.$store.state.ordremission.error !== null) {
            this.showConfirmationMessage = false
        }
    }

    @Watch('contrats')
    setContratSelect () {
        // Filtre les contrats qui sont disponibles seulement
        this.list_contrats = this.$store.state.dossieradministratif.contrats.filter((c: any) => parseInt(c.etat) >= Etat.ETAT_VIERGE)
        if (this.list_contrats.length === 1) {
            this.contrat_select = this.list_contrats[0]
        }
    }

    @Watch('save_finish')
    async getSaveFinish() {
        if (this.$store.state.ordremission.save_finish) {
            await this.$store.dispatch('dossieradministratif/getDossiersAdministratifs')
            const idSucces = 't_succes_' + Math.random()
            const succesToaster = {
                id: idSucces,
                toaster: 'b-toaster-top-right',
                variant: 'success',
                noCloseButton: true,
                fade: true,
                autoHideDelay: 3000
            }
            this.$bvToast.toast('Ordre de mission enregistré !', succesToaster)
            this.resetError()
            this.reset()
        } else {
            this.showConfirmationMessage = false
        }
    }

    @Watch('ordremission')
    ordreMissionChangeHandler () {
        if (this.ordremission) {
            this.$store.dispatch('ordremission/getOrdreMission', { ordremission_id: this.ordremission.id })
                .then(() => {
                    for (const d in this.ordremission.datas) {
                        switch (this.ordremission.datas[d].moyen_transport) {
                            case 'vehicule_perso':
                                this.check_transports.vehicule_perso = true
                                this.input_marque_vehicule = this.ordremission.datas[d].marque_vehicule
                                this.input_immatriculation = this.ordremission.datas[d].immatriculation
                                this.input_cie_assurance = this.ordremission.datas[d].cie_assurance
                                this.input_num_assurance = this.ordremission.datas[d].num_assurance
                                break
                            case 'avion':
                                this.check_transports.avion = true
                                this.textarea_avion = this.ordremission.datas[d].demande_avion
                                break
                            case 'train':
                                this.check_transports.train = true
                                break
                            case 'transports_public':
                                this.check_transports.transports_public = true
                                break
                        }
                    }

                    this.contrat_select = this.ordremission.contrat
                    this.list_contrats = [this.contrat_select]
                    this.commentaire_rejet = this.ordremission.commentaire_rejet
                })
        }
    }

    commentaire_rejet = ''

    formData = new FormData()
    contrat_select: any = null
    moyen_transport: any = []
    check_transports: any = {
        avion: false,
        train: false,
        vehicule_perso: false,
        transports_public: false
    }

    disabled_transports: any = {
        avion: false,
        train: false,
        vehicule_perso: false,
        transports_public: false
    }

    input_marque_vehicule = ''
    input_immatriculation = ''
    input_cie_assurance = ''
    input_num_assurance = ''
    file_carte_grise: any = null
    file_assurance: any = null
    textarea_avion = ''

    error_vehicule_personnel_mv = false
    error_vehicule_personnel_immat = false
    error_vehicule_personnel_ciea = false
    error_vehicule_personnel_numa = false
    error_vehicule_personnel_fcg= false
    error_vehicule_personnel_fa = false
    error_vehicule_glob = false
    error_avion = false

    showConfirmationMessage = false

    modePreviewDocument = false
    libelle_document = ''
    source_doc: any = ''
    config = {
        toolbar: {
            toolbarViewerRight: { presentationMode: false, openFile: false, viewBookmark: false, secondaryToolbarToggle: false }
        }
    }

    checkMoyenTransports (e: any) {
        this.check_transports[e.target.defaultValue] = e.target.checked
        this.checkLimited()
    }

    showDocument (document: string) {
        const document_select = this.$store.state.ordremission.ordreMissionSelect.documents.filter((d: any) => d.name === document)
        if (document_select[0]) {
            // Appel du pdf
            this.$store.dispatch('ordremission/getDocumentOrdreMission', { ordremission_id: this.$store.state.ordremission.ordreMissionSelect.id, document_id: document_select[0].id }).then(() => {
                this.libelle_document = document_select[0].name
                this.source_doc = ''
                this.source_doc = base64ToArrayBuffer(this.$store.state.ordremission.source_pdf)
                this.modePreviewDocument = true
            })
        }
    }

    // Ferme la preview d'un document
    backDocument () {
        this.modePreviewDocument = false
    }

    // Ferme la popup et reinit les données
    reset () {
        this.moyen_transport = []
        this.input_marque_vehicule = ''
        this.input_immatriculation = ''
        this.input_cie_assurance = ''
        this.input_num_assurance = ''
        this.textarea_avion = ''
        this.showConfirmationMessage = false
        this.$store.state.ordremission.error = null
        this.$emit('reset')
    }

    // Ferme le message de confirmation
    resetMessageConfirmation () {
        this.showConfirmationMessage = false
    }

    // Confirmation avant enregistrement
    showConfirmation () {
        this.resetError()
        this.$store.state.ordremission.error = null
        if (this.contrat_select) {
            this.showConfirmationMessage = true
        } else {
            this.$store.state.ordremission.error = 'Merci de sélectionner un contrat.'
        }
    }

    // Enregistrement de l'ordre de mission
    saveOrdreMission () {
        this.$store.state.ordremission.error = null
        this.resetError()
        let send_ok = false
        const datas = []

        if (this.check_transports.vehicule_perso) {
            // Check que les informations attendues sont remplies
            if (this.input_marque_vehicule !== '' && this.input_immatriculation !== '' && this.input_cie_assurance !== '' && this.input_num_assurance !== '') {
                // On peut envoyer les données
                send_ok = true
                datas.push({
                    moyen_transport: 'vehicule_perso',
                    marque_vehicule: this.input_marque_vehicule,
                    immatriculation: this.input_immatriculation,
                    cie_assurance: this.input_cie_assurance,
                    num_assurance: this.input_num_assurance
                })
            } else {
                // Affiche les erreurs
                if (this.input_marque_vehicule === '') {
                    this.error_vehicule_personnel_mv = true
                    this.error_vehicule_glob = true
                }
                if (this.input_immatriculation === '') {
                    this.error_vehicule_personnel_immat = true
                    this.error_vehicule_glob = true
                }
                if (this.input_cie_assurance === '') {
                    this.error_vehicule_personnel_ciea = true
                    this.error_vehicule_glob = true
                }
                if (this.input_num_assurance === '') {
                    this.error_vehicule_personnel_numa = true
                    this.error_vehicule_glob = true
                }
                if (this.file_assurance === null) {
                    this.error_vehicule_personnel_fa = true
                    this.error_vehicule_glob = true
                }
                if (this.file_carte_grise === null) {
                    this.error_vehicule_personnel_fcg = true
                    this.error_vehicule_glob = true
                }
            }
        }
        if (this.check_transports.train) {
            datas.push({
                moyen_transport: 'train'
            })
            send_ok = true
        }
        if (this.check_transports.avion) {
            // Check que les informations attendues sont remplies
            if (this.textarea_avion !== '') {
                // On peut envoyer les données
                send_ok = true
                datas.push({
                    moyen_transport: 'avion',
                    demande_avion: this.textarea_avion
                })
            } else {
                this.error_avion = true
            }
        }
        if (this.check_transports.transports_public) {
            datas.push({
                moyen_transport: 'transports_public'
            })
            send_ok = true
        }

        if (send_ok) {
            const etat_depose = Etat.ETAT_DEPOSE

            this.formData.set('contrat_type_dossier_administratif_id', this.contrat_select.id)
            this.formData.set('dossier_administratif_id', this.contrat_select.dossier_administratif_id)
            this.formData.set('contrat_type_id', this.contrat_select.contrat_type_id)
            this.formData.set('etat', etat_depose.toString())
            this.formData.set('datas', JSON.stringify(datas))

            if (this.ordremission) {
                this.formData.set('_method', 'PUT')
                this.$store.dispatch('ordremission/updateOrdreMission', { ordremission_id: this.ordremission.id, payload: this.formData })
            } else {
                this.$store.dispatch('ordremission/addOrdreMission', this.formData)
            }
        } else {
            this.showConfirmationMessage = false
        }
    }

    // Select la PJ Carte grise
    selectFileCG (e: Event) {
        if (e.target !== null) {
            const target = e.target as HTMLInputElement
            const file: File = (target.files as FileList)[0]
            this.file_carte_grise = file
            this.formData.set('file_carte_grise', file)
        }
    }

    // Select la PJ Assurance
    selectFileAssurance (e: Event) {
        if (e.target !== null) {
            const target = e.target as HTMLInputElement
            const file: File = (target.files as FileList)[0]
            this.file_assurance = file
            this.formData.set('file_assurance', file)
        }
    }

    // Reinit les erreurs de formulaire
    resetError () {
        this.error_vehicule_personnel_mv = false
        this.error_vehicule_personnel_immat = false
        this.error_vehicule_personnel_ciea = false
        this.error_vehicule_personnel_numa = false
        this.error_vehicule_personnel_fcg = false
        this.error_vehicule_personnel_fa = false
        this.error_avion = false
        this.error_vehicule_glob = false
        this.showConfirmationMessage = false
    }

    /**
     * Limite de sélection à deux modes de transports
     */
    checkLimited () {
        let count = 0

        if (this.check_transports.vehicule_perso) {
            count++
        }
        if (this.check_transports.train) {
            count++
        }
        if (this.check_transports.avion) {
            count++
        }
        if (this.check_transports.transports_public) {
            count++
        }

        if (count === 2) {
            // On disabled les autres
            if (!this.check_transports.vehicule_perso) {
                this.disabled_transports.vehicule_perso = true
            }
            if (!this.check_transports.train) {
                this.disabled_transports.train = true
            }
            if (!this.check_transports.avion) {
                this.disabled_transports.avion = true
            }
            if (!this.check_transports.transports_public) {
                this.disabled_transports.transports_public = true
            }
        } else if (count < 2) {
            this.disabled_transports.vehicule_perso = false
            this.disabled_transports.train = false
            this.disabled_transports.avion = false
            this.disabled_transports.transports_public = false
        }
    }
}

