


































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { CandidatCommentaire, CandidatCommentaireInterface, getCandidatCommentaire } from '@/types/Candidat'
import { mapGetters } from 'vuex'
import { Ability } from '@/types/Ability'
import { formatDate } from '@/utils/helpers'
import { isNull } from 'lodash'

/**
 * Composant relatif à la gestion des commentaires pour un candidat donné
 */
@Component({
    methods: { getCandidatCommentaire, isNull },
    computed: {
        CandidatCommentaire() {
            const middle = Math.floor(Object.keys(CandidatCommentaire).length / 2)
            return Object.keys(CandidatCommentaire).slice(0, middle)
        },
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon
    }
})
export default class CandidatComments extends Vue {
    @Prop() candidat: any
    @Prop() canEdit?: boolean
    @Prop() fromCorrespondant?: boolean
    @Prop() params?: any

    currentComments: CandidatCommentaireInterface[] = []
    Ability = Ability
    formatteDate = formatDate

    beforeMount() {
        this.$store.state.candidat.error = null
    }

    /**
     * Ajout d'un nouveau commentaire dans l'interface de saisie
     */
    addComment() {
        this.commentTemp = {
            id: 0,
            important: false,
            is_public: 0,
            body: ''
        }
        this.modeEdit = true
    }

    /**
     * Suppression d'un commentaire existant
     */

    deleteComment(commentToDelete: CandidatCommentaireInterface) {
        this.commentTemp = JSON.parse(JSON.stringify(commentToDelete))
        this.showModalMessageDeleteComment = true
    }

    deleteCommentSuite() {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Supression en cours ...', infosToaster)
        const payload: any = {
            comment_id: this.commentTemp.id,
            candidat_id: this.candidat.id,
            body: this.commentTemp.body,
            important: this.commentTemp.important ? 1 : 0,
            is_public: this.commentTemp.is_public ? 1 : 0
        }

        this.$store.dispatch('candidat/deleteCommentCandidat', payload)
            .then(() => {
                this.saveCommentSuite(idInfo, 'delete')
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    showModalMessageDeleteComment = false
    cancelDeleteComment() {
        this.commentTemp = null
        this.showModalMessageDeleteComment = false
    }

    commentTemp: any = null
    modeEdit = false
    editComment(commentToEdit: any) {
        this.commentTemp = JSON.parse(JSON.stringify(commentToEdit))
        this.modeEdit = true
    }

    cancelEditComment() {
        this.commentTemp = null
        this.modeEdit = false
    }

    /**
     * Sauvegarde des commentaires ajoutés ou édités
     */
    saveComment() {
        if (this.candidat) {
            const idInfo = 't_info_' + Math.random()
            const infosToaster = {
                id: idInfo,
                toaster: 'b-toaster-top-right',
                variant: 'primary',
                noCloseButton: true,
                fade: true,
                noAutoHide: true
            }
            this.$bvToast.toast('Enregistrement des commentaires en cours ...', infosToaster)

            const payload: any = {
                comment_id: this.commentTemp.id,
                candidat_id: this.candidat.id,
                body: this.commentTemp.body,
                important: this.commentTemp.important && !this.fromCorrespondant ? 1 : 0,
                is_public: this.commentTemp.is_public || this.fromCorrespondant ? 1 : 0,
                type: this.commentTemp.type
            }
            this.candidat.hasImportantComments = false
            if (this.commentTemp.id === 0) {
                this.$store.dispatch('candidat/addCommentCandidat', payload)
                    .then(() => {
                        this.saveCommentSuite(idInfo, 'add')
                    })
                    .catch((err) => {
                        console.log('error save comments candidat: ', err)
                    })
                    .finally(() => {
                        this.$bvToast.hide(idInfo)
                    })
            } else {
                this.$store.dispatch('candidat/updateCommentCandidat', payload)
                    .then(() => {
                        this.saveCommentSuite(idInfo, 'edit')
                    })
                    .catch((err) => {
                        console.log('error save comments candidat: ', err)
                    })
                    .finally(() => {
                        this.$bvToast.hide(idInfo)
                    })
            }
        }
    }

    saveCommentSuite(idInfo: any, mode = 'add') {
        if (this.fromCorrespondant) {
            this.$store.dispatch('listeCandidatCdc/getCandidatsCdc', this.params)
                .then(() => {
                    for (let i = 0; i < this.$store.state.listeCandidatCdc.listeCandidats.length; i++) {
                        if (this.$store.state.listeCandidatCdc.listeCandidats[i].id === this.candidat.id) {
                            this.candidat.comments = this.$store.state.listeCandidatCdc.listeCandidats[i].comments
                        }
                    }
                    this.saveCommentfin(idInfo, mode)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            this.$store.dispatch('candidat/getCandidat', this.candidat)
                .then(() => {
                    this.$store.commit('candidat/SET_EDITED_CANDIDAT', this.candidat.id)
                    this.$store.commit('candidat/SET_SELECTED_CANDIDAT', this.candidat.id)
                    this.saveCommentfin(idInfo, mode)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    saveCommentfin(idInfo: any, mode = 'add') {
        const idSucces = 't_succes_' + Math.random()
        const succesToaster = {
            id: idSucces,
            toaster: 'b-toaster-top-right',
            variant: 'success',
            noCloseButton: true,
            fade: true,
            autoHideDelay: 5000
        }
        this.$bvToast.toast(
            (mode === 'add' ? 'Ajout' : mode === 'edit' ? 'Enregistrement' : 'Suppression') +
                ' du commentaire terminé !',
            succesToaster
        )
        this.commentTemp = null
        this.modeEdit = false
        this.showModalMessageDeleteComment = false
    }

    closeModal() {
        this.$emit('reinitShow', null)
    }
}
