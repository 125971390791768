































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { formatDate, getFileNameFromHeader } from '@/utils/helpers'
import _ from 'lodash'
import PopupPlacementCandidat from '@/components/Repartition/PopupPlacementCandidats.vue'

@Component({
    computed: {
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapState('repartitioncandidats', ['liste_centres', 'lastPage', 'totalRows', 'loading_repartition']),
        ...mapGetters('repartitioncandidats', ['get_centres_satures'])
    },
    components: {
        ExaGenericTable,
        PopupPlacementCandidat,
        'font-awesome-icon': FontAwesomeIcon
    }
})
export default class ListeCentres extends Vue {
    formatDate = formatDate
    Ability = Ability

    // Ensemble des colonnes du tableau
    genericfields = [
        { key: 'ville.name', label: 'Ville', sortable: false, class: '', type: 'text' },
        { key: 'ville.academie', label: 'Académie', sortable: false, class: '', type: 'text' },
        { key: 'name', label: 'Centre', sortable: false, class: '', type: 'text' },
        { key: 'zone.name', label: 'Zone', sortable: false, class: '', type: 'text' },
        {
            key: 'nb_candidat_par_filiere',
            label: 'Nb Candidats / Capacité par filière',
            sortable: false,
            class: '',
            type: 'html'
        },
        { key: 'candidats_exces', label: 'Candidats en excès', sortable: false, class: 'text-center', type: 'icons' },
        { key: 'placement', label: 'Placement', sortable: false, class: 'text-center', type: 'html' },
        { key: 'traite', label: 'Traité', sortable: false, class: 'text-center', type: 'icons' }
    ]

    sortBy = ''
    sortDesc = false
    sortDirection = 'asc'
    filter = ''
    filterOn = []
    stickyHeader = true
    params: any = {
        page: 1,
        sort: 'name',
        direction: 'asc'
    }

    filtres: any = []
    dataForTab: Array<any> = []

    exportingIsWorking = false

    infos: any = null
    modal_placement_candidat = false

    @Watch('user_session_id')
    refreshInterface() {
        this.$store.state.repartitioncandidats.loading_repartition = true
        this.load()
    }

    @Watch('liste_centres')
    resetDataTable() {
        const datas = _.orderBy(this.$store.state.repartitioncandidats.liste_centres, 'flag_saturation', 'desc')
        this.setDataForGenericTab(datas)
    }

    // Chargement des données du tableau
    setDataForGenericTab(poData: any, isLoadMore = false) {
        if (!isLoadMore) {
            this.dataForTab = []
        }
        if (poData) {
            for (const result of poData) {
                let class_libelle = ''

                let zone = ''
                if (result.ville && result.ville.zone_id) {
                    zone = result.ville.zone.name
                }

                // Capacité par concours
                let nb_exces = 0
                let capacite = '<div class="ligne_concours">'
                const liste_concours = _.orderBy(this.$store.getters['concour/banques'], 'ordre', 'asc')
                for (const c in liste_concours) {
                    const has_concours = result.repartition_concours.filter(
                        (co: any) => co.concour_id === liste_concours[c].id
                    )
                    if (has_concours) {
                        let nb_capacite = 0
                        let nb_candidats = 0

                        for (const h in has_concours) {
                            nb_capacite += has_concours[h].capacite
                            nb_candidats += has_concours[h].nb_candidats
                        }

                        let class_color = 'text-secondary'

                        if (nb_candidats > nb_capacite) {
                            class_color = 'text-danger'
                            class_libelle = 'text-danger'
                            nb_exces += nb_candidats - nb_capacite
                        } else if (nb_candidats === nb_capacite && nb_candidats !== 0) {
                            class_color = 'text-secondary'
                        } else if (nb_candidats <= nb_capacite && nb_candidats !== 0) {
                            class_color = 'text-success'
                        }

                        if (nb_capacite !== 0) {
                            capacite +=
                                '<div class="celulle_concours ' +
                                class_color +
                                '"><strong>' +
                                liste_concours[c].name +
                                '</strong>  ' +
                                nb_candidats +
                                ' / ' +
                                nb_capacite +
                                '</div>'
                        } else {
                            capacite += '<div class="celulle_concours"></div>'
                        }
                    } else {
                        capacite += '<div class="celulle_concours"></div>'
                    }
                }

                capacite += '</div>'

                // Candidats en excés
                const btnCandidatsExces: any[] = []
                let class_bg = ''
                if (nb_exces !== 0) {
                    btnCandidatsExces.push({
                        name: 'exclamation-triangle',
                        typeIcon: 'fal',
                        class: 'fw-bold text-center text-danger',
                        title: '',
                        value_comp: nb_exces,
                        result: result.id
                    })
                    btnCandidatsExces.push({
                        name: 'arrow-circle-right',
                        typeIcon: 'fal',
                        class: 'text-danger',
                        title: '',
                        value_comp: null,
                        result: result.id
                    })
                    class_bg = 'barre_rejet'
                } else {
                    btnCandidatsExces.push({
                        name: 'check',
                        typeIcon: 'fas',
                        class: 'text-center text-secondary',
                        title: '',
                        value_comp: '',
                        result: result.id
                    })
                    btnCandidatsExces.push({
                        name: 'arrow-circle-right',
                        typeIcon: 'fal',
                        class: 'text-secondary',
                        title: '',
                        value_comp: null,
                        result: result.id
                    })
                    class_bg = 'barre_tertiary'
                }

                // Placements
                const btnPlacements: any[] = []
                let class_bg_place = ''
                if (result.placement_state !== 0) {
                    btnPlacements.push({
                        name: 'exclamation-triangle',
                        typeIcon: 'fal',
                        class: 'text-danger',
                        title: '',
                        value_comp: result.candidat_amenagement_ecrits_count,
                        result: result.id
                    })
                    btnPlacements.push({
                        name: 'arrow-circle-right',
                        typeIcon: 'fal',
                        class: 'text-danger',
                        title: '',
                        value_comp: null,
                        result: result.id
                    })
                    class_bg_place = 'barre_rejet'
                } else {
                    btnPlacements.push({
                        name: 'check',
                        typeIcon: 'fas',
                        class: 'text-secondary',
                        title: '',
                        value_comp: result.candidat_amenagement_ecrits_count,
                        result: result.id
                    })
                    btnPlacements.push({
                        name: 'arrow-circle-right',
                        typeIcon: 'fal',
                        class: 'text-secondary',
                        title: '',
                        value_comp: null,
                        result: result.id
                    })
                    class_bg_place = 'barre_tertiary'
                }

                // Prêt à partir en impression
                const isTraiter: any[] = []
                if (result.placement_locked_at) {
                    isTraiter.push({ name: 'check', class: 'text-success', typeIcon: null, title: '' })
                }

                const line = [
                    {
                        label: '',
                        item: result.ville ? result.ville.name : '-',
                        type: 'text',
                        typeAction: null,
                        class: 'item_action ' + class_libelle
                    },
                    {
                        label: '',
                        item: result.ville ? result.ville.academie : '-',
                        type: 'text',
                        typeAction: null,
                        class: 'item_action text-uppercase ' + class_libelle
                    },
                    { label: '', item: result.name, type: 'text', typeAction: null, class: class_libelle },
                    { label: '', item: zone, type: 'text', typeAction: null, class: class_libelle },
                    { label: '', item: capacite, type: 'html', typeAction: null, class: 'min_width_capacite' },
                    {
                        label: '',
                        item: btnCandidatsExces,
                        type: 'icons',
                        typeAction: 'goCentre',
                        class: 'text-center ' + class_bg,
                        separator: true
                    },
                    {
                        label: '',
                        item: btnPlacements,
                        type: 'icons',
                        typeAction: 'goPlacement',
                        class: 'text-center ' + class_bg_place
                    },
                    { label: '', item: isTraiter, type: 'icons', typeAction: null, class: 'text-center' }
                ]
                this.dataForTab.push(line)
            }
        }
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent(paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'goCentre':
                    this.$router.push('/gestion_repartitions?centre_id=' + paParams[1][0].result)
                    break
                case 'goPlacement':
                    this.$router.push('/placements_centre/' + paParams[1][0].result)
                    break
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break

                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break

                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break

                default:
                    break
            }
        }
    }

    // Chargement des données du tableau
    filtreSortHandler(params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            // Des filtres sont appliqués on rappele le serveur
            this.$store.dispatch('repartitioncandidats/getListeCentres', params).then(() => {
                const datas = _.orderBy(this.$store.state.repartitioncandidats.liste_centres, 'flag_saturation', 'desc')
                this.setDataForGenericTab(datas)
            })
        }
    }

    // Appelle une page lors du scroll
    loadHandler(params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.$store.dispatch('repartitioncandidats/getMoreListeCentres', params).then(() => {
                const datas = _.orderBy(this.$store.state.repartitioncandidats.liste_centres, 'flag_saturation', 'desc')
                this.setDataForGenericTab(datas)
            })
        }
    }

    // Ouvre le requêteur pour gérer les affectations
    goToGestionSature() {
        this.$router.push('/gestion_repartitions')
    }

    // Création des filtres pour le tableau
    setFiltersForGenericTab() {
        this.filtres = [
            {
                libelle: 'Ville',
                defautOptionlibelle: 'Rechercher une',
                model: 'ville.name',
                value: '',
                index: 'ville.name',
                datas: null,
                loading: this.$store.state.repartitioncandidats.loading_repartition,
                options: { type: 'form', fieldsKey: 'ville.name' }
            },
            {
                libelle: 'Académie',
                defautOptionlibelle: 'Rechercher une',
                model: 'ville.academie',
                value: '',
                index: 'ville.academie',
                datas: null,
                loading: this.$store.state.repartitioncandidats.loading_repartition,
                options: { type: 'form', fieldsKey: 'ville.academie' }
            },
            {
                libelle: 'Centre',
                defautOptionlibelle: 'Rechercher un',
                model: 'name',
                value: '',
                index: 'name',
                datas: null,
                loading: this.$store.state.repartitioncandidats.loading_repartition,
                options: { type: 'form', fieldsKey: 'name' }
            }
        ]
    }

    // Export de la liste des déplacements des candidats
    exporter() {
        this.$store.commit('repartitioncandidats/SET_ERROR', null)
        let fileName = ''
        this.exportingIsWorking = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours...', infosToaster)

        this.$store.dispatch('repartitioncandidats/getExportDeplacements')
            .then((response) => {
                const fileNameTemp = getFileNameFromHeader(response.headers)
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.exportingIsWorking = false
            })
    }

    // Function de chargement des éléments
    load() {
        // Chargement des concours
        this.$store.dispatch('concour/getConcours')

        // Chargement des zones
        this.$store.dispatch('ville/getZones')

        // Chargement des compteurs issus du dashboard
        this.$store.dispatch('dashboardEcrits/getDashboardInformations').then((response: any) => {
            this.infos = response.data.data
        })

        // Chargement des centres
        this.$store.dispatch('repartitioncandidats/getListeCentres').then(() => {
            this.setFiltersForGenericTab()
        })
    }

    // Retourne le nombre de centres saturé
    get_nb_centres_sature() {
        return this.$store.getters['repartitioncandidats/get_centres_satures']
    }

    mounted() {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.$store.state.repartitioncandidats.loading_repartition = true
            this.load()
        }
    }
}
