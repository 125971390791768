import {TypeMesure} from "@/types/Amenagement";

export interface DecisionAmenagementInterface {
    id: number | 0,
    candidat_id: number | 0,
    type: TypeMesure,
    commentaire: string | '',
    etat: EtatDecisionAmenagement,
    validated_at: Date | string | '',
    submitted_at: Date | string | ''
}

export enum EtatDecisionAmenagement {
    ETAT_NON_SOUMIS,
    ETAT_SOUMIS,
    ETAT_VALIDE,
}

export interface EtatDecisionAmenagementInterface {
    etat: number | 0,
    libelle: string | '',
    color: string | ''
}

export function getEtatDecisionAmenagement(etat: EtatDecisionAmenagement): EtatDecisionAmenagementInterface {
    switch (etat) {
        default:
            return { etat: EtatDecisionAmenagement.ETAT_NON_SOUMIS, libelle: 'Non traitée', color: 'secondary' }
        case EtatDecisionAmenagement.ETAT_SOUMIS:
            return { etat: EtatDecisionAmenagement.ETAT_SOUMIS, libelle: 'Traitement en cours', color: 'info' }
        case EtatDecisionAmenagement.ETAT_VALIDE:
            return { etat: EtatDecisionAmenagement.ETAT_VALIDE, libelle: 'Traitée', color: 'success' }
    }
}
