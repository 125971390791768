




































































































































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { Ability } from '@/types/Ability'
import { codeRestrict } from '@/utils/helpers'
import { BModal, BootstrapVue, BootstrapVueIcons, BTable } from 'bootstrap-vue'

import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import Back from '@/components/Tools/Back.vue'
import BonificationModal from '@/views/Bonification/BonificationModal.vue'

@Component({
    components: {
        BonificationModal,
        ExaGenericTable,
        Back
    },
    computed: {
        ...mapGetters('bonusCandidat', ['bonusCandidats']),
        ...mapGetters('epreuve', ['epreuves', 'getEpreuveByConcour_id']),
        ...mapGetters('concour', ['loading', 'error', 'concours', 'concourById', 'meta', 'links', 'totalRows']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})
export default class EditionConcour extends Vue {
    @Prop() session_id?: number
    @Prop() concour_id?: number
    currentSession: any = null
    actionLimited = false
    codeRestrict = codeRestrict

    valPropVisible = true

    // DATAS
    params = 'sort=name&direction=asc'
    firstLoading = true
    options_filieres: Array<any> = []
    selected_tab = 'concours'
    loadingEpreuve = true
    loadingListeEpreuves = true
    epreuvesFromCurrentBanque: Array<any> = []

    // ---- generic table --------------------------

    dataForTab: Array<any> = []
    filtres: any = []

    genericfields = [
        { key: 'phase_id', label: 'Phase', sortable: false, class: '', type: 'text' },
        { key: 'epreuve.name', label: 'Epreuve', sortable: true, class: 'text-left col-min-width', type: 'text' },
        {
            key: 'obligatoire',
            label: 'Facultative',
            sortable: true,
            sortDirection: 'asc',
            class: 'text-center',
            type: 'text'
        },
        {
            key: 'note_eliminatoire_operator',
            label: 'Opérateur',
            sortable: false,
            sortDirection: 'asc',
            class: 'fixed_size_input_table_gen',
            type: 'text'
        },
        {
            key: 'note_eliminatoire',
            label: 'Note éliminatoire',
            sortable: true,
            sortDirection: 'asc',
            class: 'fixed_size_input_table_gen',
            type: 'text'
        },
        {
            key: 'coefficient',
            label: 'Coefficient',
            sortable: true,
            class: ' fixed_size_input_table_gen',
            type: 'text'
        },
        { key: 'seuil_remontee', label: 'Seuil', sortable: true, class: ' fixed_size_input_table_gen', type: 'text' },
        { key: 'max_remontee', label: 'Plafond', sortable: true, class: ' fixed_size_input_table_gen', type: 'text' },
        { key: 'inscription', label: 'Inscription', sortable: true, class: '', type: 'text' },
        { key: 'delete', label: '', sortable: false, class: '', type: 'action' }
    ]

    sortBy = ''
    sortDesc = false
    sortDirection = 'asc'
    filter = ''
    filterOn = []
    stickyHeader = true
    concour: any = null
    concourTemp: any = null
    Ability = Ability

    // METHODS

    // ---- generic table --------------------------

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setDataForGenericTab(poData: any, isLoadMore = false) {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        const option_phases = []
        for (let i = 0; i < this.concourTemp.phases.length; i++) {
            option_phases.push({
                text: this.concourTemp.phases[i].name,
                value: this.concourTemp.phases[i].phase_id
            })
        }

        const option_epreuves = []
        for (let j = 0; j < this.epreuvesFromCurrentBanque.length; j++) {
            if (this.epreuvesFromCurrentBanque[j].id_epreuve_maitresse === null) {
                option_epreuves.push({
                    text: this.epreuvesFromCurrentBanque[j].code + ' - ' + this.epreuvesFromCurrentBanque[j].long_name,
                    value: this.epreuvesFromCurrentBanque[j].id
                })
            }
        }

        const option_operator = [
            { text: '=', value: '=' },
            { text: '<=', value: '<=' },
            { text: '<', value: '<' }
        ]

        if (poData) {
            for (const result of poData) {
                const line: Array<any> = [
                    {
                        label: '',
                        item: result.phase_id,
                        data: result,
                        type: 'comboDeroulant',
                        typeAction: 'phaseDeroulant',
                        class: 'fixed_size_classe text-center item_action',
                        optionsDeroulant: option_phases,
                        disabled: !this.canEdit()
                    },
                    // { label: '', item: result.phase_id, type: 'text', typeAction: null, class: '' },
                    {
                        label: '',
                        item: result.epreuve_id,
                        data: result,
                        type: 'comboDeroulant',
                        typeAction: 'epreuveDeroulant',
                        class: 'fixed_size_classe_epreuve text-center item_action',
                        optionsDeroulant: option_epreuves,
                        disabled: !this.canEdit()
                    },
                    // { label: '', item: result.epreuve.name, type: 'text', typeAction: null, class: '' },
                    {
                        label: '',
                        item: result.epreuve && result.epreuve.obligatoire === 1 ? 'Non' : 'Oui',
                        type: 'text',
                        typeAction: null,
                        class: 'text-center'
                    },
                    {
                        label: '',
                        item: result.note_eliminatoire_operator,
                        data: result,
                        type: 'comboDeroulant',
                        typeAction: 'input_operator',
                        optionsDeroulant: option_operator,
                        disabled: !this.canEdit(),
                        class: 'item_action fixed_size_input_table_gen'
                    },
                    {
                        label: '',
                        item: isNaN(result.note_eliminatoire) ? '-' : result.note_eliminatoire,
                        data: result,
                        type: 'inputNumber',
                        typeAction: 'input_note_eliminatoire',
                        disabled: !this.canEdit(),
                        nullEnabled: true,
                        class: 'item_action fixed_size_input_table_gen text-center',
                        minInput: 0
                    },
                    // { label: '', item: result.note_eliminatoire ? result.note_eliminatoire : '-', type: 'text', typeAction: null, class: '' },
                    {
                        label: '',
                        item: result.coefficient ? result.coefficient : '-',
                        data: result,
                        type: 'inputNumber',
                        typeAction: 'input_coefficient',
                        nullEnabled: false,
                        disabled: !this.canEdit(),
                        class: 'item_action fixed_size_input_table_gen text-center',
                        minInput: 0
                    },
                    {
                        label: '',
                        item: result.seuil_remontee ? result.seuil_remontee : '-',
                        data: result,
                        type: 'inputNumber',
                        typeAction: 'input_seuil_remontee',
                        nullEnabled: true,
                        disabled: !this.canEdit(),
                        class: 'item_action fixed_size_input_table_gen text-center',
                        minInput: 0
                    },
                    {
                        label: '',
                        item: result.max_remontee ? result.max_remontee : '-',
                        data: result,
                        type: 'inputNumber',
                        typeAction: 'input_max_remontee',
                        nullEnabled: true,
                        disabled: !this.canEdit(),
                        class: 'item_action fixed_size_input_table_gen text-center',
                        minInput: 0
                    },
                    // { label: '', item: result.coefficient ? result.coefficient : '-', type: 'text', typeAction: null, class: '' },
                    // { label: '', item: result.epreuve && result.epreuve.inscription === 1 ? 'Oui' : 'Non', type: 'text', typeAction: null, class: 'col-min-width' }
                    {
                        label: '',
                        item: result.inscription === 1,
                        data: result,
                        type: 'checkBox',
                        typeAction: 'inscriptionCheckHandler',
                        class: 'fixed_size_classe text-center item_action',
                        disabled: !this.canEdit()
                    }
                ]

                for (let k = 0; k < this.concourTemp.phases.length; k++) {
                    // for (let l = 0; l < this.concourTemp.phases[k].barres.length; l++) {
                    if (
                        this.concourTemp.phases[k] &&
                        this.concourTemp.phases[k].barres &&
                        this.concourTemp.phases[k].barres.length > 1 &&
                        this.concourTemp.phases[k].barres[1]
                    ) {
                        if (result.phase_id === this.concourTemp.phases[k].phase_id) {
                            line.push({
                                label: '',
                                item:
                                    this.concourTemp?.phases[k]?.barres[1]?.epreuves === '*' || result?.epreuve
                                        ? this.concourTemp?.phases[k]?.barres[1]?.epreuves?.includes(result.epreuve.code)
                                        : null,
                                data: [result, k + '_' + 1],
                                type: 'checkBox',
                                typeAction: 'barrePhaseCheckHandler',
                                class: 'fixed_size_classe text-center item_action',
                                disabled: !this.canEdit()
                            })
                        } else {
                            line.push({
                                label: '',
                                item: '-',
                                type: 'text',
                                typeAction: null,
                                class: 'col-min-width text-center'
                            })
                        }
                    }
                    // }
                }

                if (this.canEdit()) {
                    const trashLine = {
                        label: 'Supprimer',
                        item: result,
                        indice: result.epreuve_id,
                        type: 'action',
                        typeAction: 'delete',
                        class: 'text-secondary col-min-width',
                        icon: 'trash-alt',
                        disabled: !this.canEdit()
                    }
                    line.push(trashLine)
                }
                this.dataForTab.push(line)
            }
        }

        return this.dataForTab
    }

    /** défini les champs de la GenericTable */
    setFields() {
        this.genericfields = [
            { key: 'phase_id', label: 'Phase', sortable: false, class: '', type: 'text' },
            { key: 'epreuve.name', label: 'Epreuve', sortable: true, class: 'text-left col-min-width', type: 'text' },
            { key: 'obligatoire', label: 'Facultative', sortable: true, sortDirection: 'asc', class: '', type: 'text' },
            {
                key: 'note_eliminatoire_operator',
                label: 'Opérateur',
                sortable: false,
                sortDirection: 'asc',
                class: 'fixed_size_input_table_gen',
                type: 'text'
            },
            {
                key: 'note_eliminatoire',
                label: 'Note éliminatoire',
                sortable: true,
                sortDirection: 'asc',
                class: 'fixed_size_input_table_gen',
                type: 'text'
            },
            {
                key: 'coefficient',
                label: 'Coefficient',
                sortable: true,
                class: 'fixed_size_input_table_gen',
                type: 'text'
            },
            {
                key: 'seuil_remontee',
                label: 'Seuil',
                sortable: true,
                class: ' fixed_size_input_table_gen',
                type: 'text'
            },
            {
                key: 'max_remontee',
                label: 'Plafond',
                sortable: true,
                class: ' fixed_size_input_table_gen',
                type: 'text'
            },
            { key: 'inscription', label: 'Inscription', sortable: true, class: 'text-center', type: 'text' }
        ]

        for (let m = 0; m < this.concourTemp.phases.length; m++) {
            // for (let n = 0; n < this.concourTemp.phases[m].barres.length; n++) {
            if (
                this.concourTemp.phases[m] &&
                this.concourTemp.phases[m].barres &&
                this.concourTemp.phases[m].barres.length > 1 &&
                this.concourTemp.phases[m].barres[1]
            ) {
                this.genericfields.push({
                    key: '',
                    label:
                        this.concourTemp?.phases[m]?.barres[1]?.name?.trim().length > 0
                            ? this.concourTemp.phases[m].barres[1].name
                            : this.concourTemp.phases[m].name + ' (spec)',
                    sortable: false,
                    class: 'text-center col-min-width',
                    type: 'text'
                })
            }
            // }
        }

        this.genericfields.push({ key: 'delete', label: '', sortable: false, class: '', type: 'action' })
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setFiltersForGenericTab() {
        this.filtres = []
    }

    /**
     * Récupération des events de la table
     */
    handleTableEvent(paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'edit':
                    break
                case 'openComment':
                    break
                case 'onLoadPage':
                    this.loadHandler()
                    break
                case 'sortHandler':
                    this.filtreSortHandler()
                    break
                case 'filterHandler':
                    this.filtreSortHandler()
                    break
                case 'input_operator':
                    paParams[2].note_eliminatoire_operator = paParams[1]
                    break
                case 'input_note_eliminatoire':
                    paParams[2].note_eliminatoire = paParams[1] !== '-' ? paParams[1] : null
                    break
                case 'input_coefficient':
                    paParams[2].coefficient = paParams[1] !== '-' ? paParams[1] : null
                    break
                case 'input_seuil_remontee':
                    paParams[2].seuil_remontee = paParams[1] !== '-' ? paParams[1] : null
                    break
                case 'input_max_remontee':
                    paParams[2].max_remontee = paParams[1] !== '-' ? paParams[1] : null
                    break
                case 'epreuveDeroulant':
                    this.listeEpreuveEpreuveChangeHandler(paParams[1], paParams[2])
                    break
                case 'phaseDeroulant':
                    this.listeEpreuvePhaseChangeHandler(paParams[1], paParams[2])
                    break
                case 'barrePhaseCheckHandler':
                    this.barrePhaseCheckHandler(paParams)
                    break
                case 'delete':
                    this.removeEpreuve(paParams[1])
                    break
                case 'inscriptionCheckHandler':
                    paParams[2].inscription = paParams[1]
                    break
                default:
                    break
            }
        }
    }

    // --------------------------------------

    canEdit() {
        if (
            this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE) &&
            this.currentSession &&
            this.currentSession.closed_at === null &&
            this.currentSession.structure_valide === 0
        ) {
            return true
        } else {
            return false
        }
    }

    saveIsDisabled() {
        if (this.concourTemp && this.concourTemp.phases) {
            for (const phaseKey in this.concourTemp.phases) {
                const phase = this.concourTemp.phases[phaseKey]
                if (
                    phase.type_deliberation === 1 &&
                    phase.barres &&
                    phase.barres.length > 0 &&
                    phase.barres &&
                    phase.barres.length > 1 &&
                    phase.barres[1] &&
                    !(phase.barres[1].name !== null && phase?.barres[1]?.name?.trim().length > 0)
                ) {
                    return true
                }
            }
        }
        return false
    }

    checkBarreSpeChangeHandler(event: any, phase: any) {
        if (event.target.checked) {
            const barreSpec = {
                code: '',
                epreuves: [],
                maxBarre: 0,
                nbAbs: 0,
                nom: '',
                type: 1,
                valBarre: null
            }
            phase.barres.push(barreSpec)
        } else {
            phase.barres.pop()
        }
        this.phaseBarreChangeHandler()
    }

    updateCumulPoints(event: any) {
        const phase_id = parseInt(event.target.id.split('_')[3]) - 1

        this.concourTemp.phases[phase_id].max_remontee = parseInt(this.concourTemp.phases[phase_id].max_remontee)
        if (isNaN(this.concourTemp.phases[phase_id].max_remontee)) {
            this.concourTemp.phases[phase_id].max_remontee = null
        }
    }

    phaseBarreChangeHandler() {
        this.setFields()
        this.setDataForGenericTab(this.concourTemp.epreuves_deliberation_structure)
    }

    barrePhaseCheckHandler(params: any) {
        const phase = params[2][1].split('_')[0]
        const barre = params[2][1].split('_')[1]

        // console.log('-------' + params[2][0].epreuve.code + '-----phase : ' + phase  + '----barre : ' + barre + '----checked :  '  + (params[1] === '1' ? 'true' : 'false'))

        if (params[1] === '1') {
            if (
                this.concourTemp?.phases[phase]?.barres[barre]?.epreuves !== '*' &&
                !this.concourTemp?.phases[phase]?.barres[barre]?.epreuves?.includes(params[2][0].epreuve.code)
            ) {
                if (!Array.isArray(this.concourTemp.phases[phase].barres[barre].epreuves)) {
                    this.concourTemp.phases[phase].barres[barre].epreuves = []
                }
                this.concourTemp.phases[phase].barres[barre].epreuves.push(params[2][0].epreuve.code)
            }
        } else {
            if (
                Array.isArray(this.concourTemp?.phases[phase]?.barres[barre]?.epreuves) &&
                this.concourTemp?.phases[phase]?.barres[barre]?.epreuves?.includes(params[2][0]?.epreuve?.code)
            ) {
                const epreuvesTemp = []
                for (let i = 0; i < this.concourTemp.phases[phase].barres[barre].epreuves.length; i++) {
                    if (this.concourTemp.phases[phase].barres[barre].epreuves[i] !== params[2][0].epreuve.code) {
                        epreuvesTemp.push(this.concourTemp.phases[phase].barres[barre].epreuves[i])
                    }
                }
                this.concourTemp.phases[phase].barres[barre].epreuves = epreuvesTemp
            } else if (this.concourTemp.phases[phase].barres[barre].epreuves === '*') {
                this.concourTemp.phases[phase].barres[barre].epreuves = []
                for (let j = 0; j < this.epreuvesFromCurrentBanque.length; j++) {
                    if (this.epreuvesFromCurrentBanque[j].id !== params[2][0].epreuve.code) {
                        this.concourTemp.phases[phase].barres[barre].epreuves.push(
                            this.epreuvesFromCurrentBanque[j].code
                        )
                    }
                }
            }
        }
    }

    conflitPhaseEpreuve() {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'danger',
            noCloseButton: true,
            fade: true,
            noAutoHide: false
        }
        this.$bvToast.toast('cette épreuve est dèjà présente dans cette phase', infosToaster)
    }

    listeEpreuveEpreuveChangeHandler(epreuve_id: number, epreuve: any) {
        const epreuveTemp = this.epreuveByPhase_idEpreuve_Id(epreuve.phase_id, epreuve_id)
        if (epreuveTemp !== null) {
            this.setDataForGenericTab(this.concourTemp.epreuves_deliberation_structure)
            this.conflitPhaseEpreuve()
        } else {
            for (let i = 0; i < this.concourTemp.epreuves_deliberation_structure.length; i++) {
                if (
                    this.concourTemp.epreuves_deliberation_structure[i].epreuve_id === epreuve.epreuve_id &&
                    this.concourTemp.epreuves_deliberation_structure[i].phase_id === epreuve.phase_id
                ) {
                    this.concourTemp.epreuves_deliberation_structure[i].epreuve_id = epreuve_id
                    this.concourTemp.epreuves_deliberation_structure[i].epreuve = this.getEpreuveById(epreuve_id)
                    this.setDataForGenericTab(this.concourTemp.epreuves_deliberation_structure)
                    return
                }
            }
        }
    }

    getEpreuveById(epreuve_id: number) {
        return this.epreuvesFromCurrentBanque.filter((epreu: any) => epreu.id === epreuve_id)[0]
    }

    listeEpreuvePhaseChangeHandler(phase_id: number, epreuve: any) {
        const epreuveTemp = this.epreuveByPhase_idEpreuve_Id(phase_id, epreuve.epreuve_id)
        if (epreuveTemp !== null) {
            this.setDataForGenericTab(this.concourTemp.epreuves_deliberation_structure)
            this.conflitPhaseEpreuve()
        } else {
            epreuve.phase_id = phase_id
            this.setDataForGenericTab(this.concourTemp.epreuves_deliberation_structure)
        }
    }

    epreuveByPhase_idEpreuve_Id(phase_id: number, epreuve_id: number) {
        for (let i = 0; i < this.concourTemp.epreuves_deliberation_structure.length; i++) {
            if (
                phase_id === this.concourTemp.epreuves_deliberation_structure[i].phase_id &&
                epreuve_id === this.concourTemp.epreuves_deliberation_structure[i].epreuve_id
            ) {
                return this.concourTemp.epreuves_deliberation_structure[i]
            }
        }
        return null
    }

    getLibelleConcoursById(id: number) {
        return this.$store.state.concours.filter((concour: any) => concour.banque_id === id).name
    }

    editSuite() {
        for (let i = 0; i < this.concourTemp.epreuves_deliberation_structure.length; i++) {
            if (
                this.concourTemp.epreuves_deliberation_structure[i].phase_id == null ||
                this.concourTemp.epreuves_deliberation_structure[i].epreuve_id == null
            ) {
                const idInfoerr = 't_info_' + Math.random()
                const infosToastererr = {
                    id: idInfoerr,
                    toaster: 'b-toaster-top-right',
                    variant: 'danger',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast(
                    'Une phase et une épreuve doivent être choisies pour chaque ligne des épreuves du concours ...',
                    infosToastererr
                )
                return
            }
        }
        const payload = JSON.parse(JSON.stringify(this.concourTemp))
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        if (this.concourTemp.id === 0) {
            this.$store.dispatch('concour/addConcour', payload)
                .then(() => {
                    this.concour = null
                    this.concourTemp = null
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                    this.loadConcour()
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            this.$store.dispatch('concour/updateConcour', payload)
                .then(() => {
                    this.concour = null
                    this.concourTemp = null
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                    this.loadConcour()
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    ajouterEpreuve() {
        if (
            this.concourTemp.epreuves_deliberation_structure === null ||
            this.concourTemp.epreuves_deliberation_structure === undefined
        ) {
            this.concourTemp.epreuves_deliberation_structure = []
        }
        // if (this.concourTemp.epreuves_deliberation_structure !== '*' && this.concourTemp.epreuves_deliberation_structure.length !== this.epreuvesFromCurrentBanque.length) {
        this.concourTemp.epreuves_deliberation_structure.push({
            coefficient: 1,
            concour_id: this.concourTemp.id,
            epreuve: null,
            epreuve_id: null,
            inscription: 1,
            note_eliminatoire: null,
            phase_id: null
        })
        this.setDataForGenericTab(this.concourTemp.epreuves_deliberation_structure)
    }

    removeEpreuve(epreuve: any) {
        const epreuvesTemp = []
        for (let i = 0; i < this.concourTemp.epreuves_deliberation_structure.length; i++) {
            if (
                epreuve.phase_id !== this.concourTemp.epreuves_deliberation_structure[i].phase_id ||
                epreuve.epreuve_id !== this.concourTemp.epreuves_deliberation_structure[i].epreuve_id
            ) {
                epreuvesTemp.push(this.concourTemp.epreuves_deliberation_structure[i])
            }
        }
        this.concourTemp.epreuves_deliberation_structure = epreuvesTemp
        this.setDataForGenericTab(this.concourTemp.epreuves_deliberation_structure)
    }

    /**
     * setBonus
     * @description MAJ d'un bonus dans la phase
     * @param {any} data - Nouvelles données
     * @param {number} index_phase - Index de la phase
     * @return {void}
     */
    setBonus(data: any, index_phase: number): void {
        this.concourTemp.phases[index_phase].bonus = JSON.parse(JSON.stringify(data))

        this.$nextTick(() => {
            this.$forceUpdate()
        })
    }

    loadHandler() {
        console.log('---loadHandler editionConcour---')
    }

    // Appelle une page lors du scroll
    filtreSortHandler() {
        console.log('---filtreSortHandler editionConcour---')
    }

    loadListeEpreuves() {
        this.loadingListeEpreuves = true

        this.$store
            .dispatch('epreuve/getEpreuves', { session_id: this.session_id })
            .then(() => {
                this.epreuvesFromCurrentBanque = this.$store.getters['epreuve/getEpreuveByConcour_id'](
                    this.concourTemp.banque_id
                )
                this.setDataForGenericTab(this.$store.state.concour.concourTemp.epreuves_deliberation_structure)
                this.loadingListeEpreuves = false
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
    }

    loadConcour() {
        this.loadingEpreuve = true

        this.$store.dispatch('bonusCandidat/getBonusCandidats').then(() => {
            this.$store
                .dispatch('concour/getConcour', this.concour_id)
                .then(() => {
                    this.concourTemp = this.$store.state.concour.concourTemp
                    this.concourTemp.phases.forEach((phase: any) => {
                        if (phase.barres === null || phase.barres.length === 0) {
                            phase.barres = [
                                {
                                    code: '',
                                    epreuves: '*',
                                    maxBarre: 0,
                                    nbAbs: 0,
                                    nom: '',
                                    type: 1,
                                    valBarre: null
                                }
                            ]
                        }

                        if (phase.bonus === null || phase.bonus.length === 0) {
                            phase.bonus = [
                                {
                                    operator: '=',
                                    propCandidat: null,
                                    valBonus: 0,
                                    valProp: 1
                                }
                            ]
                        }
                    })
                    const filieres = this.$store.getters['concour/banques']
                    this.options_filieres = []
                    for (const f in filieres) {
                        this.options_filieres.push({
                            id: filieres[f].id,
                            index: filieres[f].id,
                            name: filieres[f].name,
                            long_name: filieres[f].long_name
                        })
                    }
                    this.setFields()
                    this.loadListeEpreuves()

                    this.loadingEpreuve = false
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
        })
    }

    mounted() {
        this.$store
            .dispatch('session/getSession', { session_id: this.session_id })
            .then(() => {
                this.currentSession = this.$store.state.session.sessionSelect
                this.loadConcour()
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
    }
}
