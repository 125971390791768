
































import { Vue, Component, Prop } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { Ability } from '@/types/Ability'
import MessageIndication from '@/components/MessageIndication.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    components: {
        MessageIndication,
        ErrorDisplay
    },
    computed: {
        ...mapGetters('auth', ['authUser']),
        ...mapState('centre', ['error', 'centreSelect']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapState('user', ['roles'])
    }
})
export default class GestionRoles extends Vue {
    @Prop() centreuser?: any;
    @Prop() edit?: boolean;
    @Prop() context?: string;

    Ability = Ability
    roles_gci: any = []
    edit_role = false
    edit_role_user_id = null
    save_role_in_progress = false
    centreuserold: any = null



    // Sélection d'un role pour un contact du centre
    select_role (e: any) {
        const centreu = this.$props.centreuser
        centreu.roles = []
        for (const o in e.target.selectedOptions) {
            if (e.target.selectedOptions[o]._value) {
                centreu.roles.push(e.target.selectedOptions[o]._value)
            }
        }
    }

    // Edition des roles d'un contact du centre
    edit_roles (centreuser: any) {
        // Chargement des roles
        this.$store.dispatch('user/getRoles').then(() => {
            this.roles_gci = []
            this.centreuserold = centreuser
            for (const r in this.$store.state.user.roles) {
                const permissions = this.$store.state.user.roles[r].abilities.filter((r: any) => r.name.indexOf('gci') !== -1)
                if (permissions.length !== 0) {
                    this.roles_gci.push(this.$store.state.user.roles[r])
                    this.edit_role = true
                    this.edit_role_user_id = centreuser.id
                }
            }
        })
    }

    // Sauvegarder l'affectation d'un role sur un contact
    saveRole (centreuser: any) {
        this.save_role_in_progress = true
        const centreu = this.$props.centreuser

        // Préparations des roles
        const roles_name = []
        for (const r in centreu.roles) {
            roles_name.push(centreu.roles[r])
        }

        // Préparation des postes
        const postes: Array<any> = []
        for (const p in centreuser.postes) {
            postes.push({
                poste_id: centreuser.postes[p].id
            })
        }

        const data: any = {
            payload: {
                civility: centreuser.user.civility,
                name: centreuser.user.name,
                first_name: centreuser.user.first_name,
                email: centreuser.user.email,
                telephone: centreuser.user.telephone,
                roles: roles_name,
                postes: postes,
                temps: 100,
                responsabilite_centre: centreuser.responsabilite_centre,
                responsabilite_concours: centreuser.responsabilite_concours
            },
            centre_id: this.$store.state.centre.centreSelect.id,
            centre_user_id: centreuser.id
        }

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('centre/updateUserFromCentre', data)
            .then((response) => {
                centreuser.roles = response.data.data.roles

                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)

                this.save_role_in_progress = false
                if (this.$props.context !== 'contact_centre') {
                    this.edit_role_user_id = null
                    this.edit_role = false
                }

                this.$emit('finish_role')
            })
            .catch(() => {
                centreuser.roles = this.$props.centreuser.roles

                this.save_role_in_progress = false
                this.edit_role_user_id = null
                this.edit_role = false
                this.$emit('finish_role')
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Annule l'édition des rôles sur un contact
    cancel_edit_roles (centreuser: any) {
        this.roles_gci = []
        this.edit_role = false
        this.edit_role_user_id = null
        centreuser.roles = this.centreuserold.roles
        this.centreuserold = null
    }

    beforeMount () {
        this.$store.commit('centre/SET_ERROR', null)
        if (this.$props.edit) {
            this.edit_roles(this.$props.centreuser)
        } else {
            this.cancel_edit_roles(this.$props.centreuser)
        }
    }
}

