export interface NotificationTemplateInterface {
    champ_descriptif: string,
    entity_id: number | 0,
    entity_property: string,
    entity_property_value: string,
    entity_type: string,
    id: number | 0,
    name: string,
    session_id: number | 0,
    tpl_email_message: string,
    tpl_email_subject: string,
    tpl_pdf_content: string
}

export interface StateNotificationTemplates {
    notificationTemplates: Array<NotificationTemplateInterface> | [],
    meta: Array<string> | null,
    links: Array<string> | null,
    loading: boolean | false,
    error: Array<string> | null,
    totalRows: number | 0,
    currentPage: number | 1,
    lastPage: number | 0,
    notificationTemplateSelect: NotificationTemplateInterface | {},
    source_pdf: string | '',
    params: object | {}
}

export enum EntityType {
    ET_DOSSIER_ACADEMIQUE = 'App\\Models\\DossierAcademique',
    ET_DOSSIER_ADMINISTRATIF = 'App\\Models\\DossierAdministratif',
}

interface EntityTypeSpec {
    value: string,
    libelle: string
}

export function getEntityTypeSpec(posteType: EntityType): EntityTypeSpec {
    switch (posteType) {
        case EntityType.ET_DOSSIER_ACADEMIQUE:
            return { value: 'App\\Models\\DossierAcademique', libelle: 'Dossier académique' }
        case EntityType.ET_DOSSIER_ADMINISTRATIF:
            return { value: 'App\\Models\\DossierAdministratif', libelle: 'Dossier administratif' }
        default:
            return { value: 'App\\Models\\DossierAcademique', libelle: 'Dossier académique' }
    }
}
