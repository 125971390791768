























import { mapGetters, mapState } from 'vuex'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { isObject } from '@/utils/helpers'
import ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    computed: {
        ...mapState('centre', ['centre_users_select', 'centreSelect']),
        ...mapState('organisationecrit', ['error']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay
    }
})

export default class PopupValideEcrit extends Vue {
    @Prop() showPopup?: boolean
    @Prop() invalide?: boolean

    show = false
    message_rejet = ''
    messageErreurSimple = ''

    @Watch('showPopup')
    initPopup () {
        this.show = this.$props.showPopup
    }

    /** Check le type du retour d'erreur */
    isObject () {
        return isObject(this.$store.state.organisationecrit.error)
    }

    /** Annulation de la confirmation de la validation de l'écrit */
    cancelValide () {
        this.show = false
        this.$emit('reset')
    }

    /** Confirmation de la validation de l'écrit */
    confirmerValide () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        const message_toast = this.$props.invalide ? 'La liste du nombre d\'intervenant a été invalidée !' : 'La liste du nombre d\'intervenant a été validée !'
        this.$bvToast.toast('Validation en cours ...', infosToaster)
        this.$store.dispatch('organisationecrit/manageOrganisationEcrit', { params: { mode: 'nb_interv', centre_id: this.$store.getters['centre/centreSelect'].id, besoins_submit: null, besoins_validate: !this.$props.invalide } })
            .then(async () => {
                await this.$store.dispatch('centre/getCentre', { centre_id: this.$store.getters['centre/centreSelect'].id })
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast(message_toast, succesToaster)
                this.messageErreurSimple = ''
                this.message_rejet = ''
                this.show = false
                this.$emit('reset')
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }
}
