












































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Ability } from '@/types/Ability'
import { mapGetters } from 'vuex'
import { Datetime } from 'vue-datetime'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import 'vue-datetime/dist/vue-datetime.css'
import { BButton } from 'bootstrap-vue'
import { addDaysToDate, formatDateYYYYMMDD } from '@/utils/helpers'


@Component({
    components: {
        Datetime,
        'font-awesome-icon': FontAwesomeIcon,
        'b-button': BButton
    },
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])

    }
})
export default class GestionSeries extends Vue {
    @Prop() series?: any;
    @Prop() session_id?: any;
    @Prop() disabled?: boolean;
    @Prop() currentSession?: any;


    Ability = Ability


    idAdded = -1

    canEdit () {
        if (this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE) && this.currentSession && this.currentSession.closed_at === null) {
            return true
        } else {
            return false
        }
    }

    getDateStringForDateInput(date: Date, addDay = 0) {
        if (addDay !== 0) {
            return formatDateYYYYMMDD(addDaysToDate(date, addDay))
        } else {
            return formatDateYYYYMMDD(date)
        }
    }

    checkDateMax(serie: any, precSerie: any = null) {
        if (serie.fin) {
            if (serie.debut >= serie.fin) {
                serie.debut = this.getDateStringForDateInput(serie.fin, -1)
            }
        }
        if (precSerie) {
            if (serie.debut <= precSerie) {
                serie.debut = this.getDateStringForDateInput(precSerie, 1)
            }
        }
    }

    checkDateMin(serie: any, nextSerie: any = null) {
        if (serie.debut) {
            if (serie.fin <= serie.debut) {
                serie.fin = this.getDateStringForDateInput(serie.debut, 1)
            }
        }
        if (nextSerie) {
            if (serie.fin >= nextSerie) {
                serie.fin = this.getDateStringForDateInput(nextSerie, -1)
            }
        }
    }

    deleteSerie (id: number) {
        for (let i = 0; i < this.series.length; i++) {
            if (this.series[i].id === id) {
                this.series.splice(i, 1)
            }
        }
    }

    addSerie() {
        this.series.push({
            code: (this.series.length + 1).toString(),
            debut: '',
            debut_pub_a_salle: '',
            debut_pub_s_salle: '',
            debut_saisie_dispo: '',
            del: 1,
            fin: '',
            fin_pub: '',
            fin_saisie_dispo: '',
            id: this.idAdded,
            name: '',
            planifie: 0,
            publie: 0,
            session_id: this.session_id,
            valide: 0
        })
        this.idAdded--
    }
}
