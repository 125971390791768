













































































































































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import store from '@/store/index'
import Table from '@/components/Table.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { Ability } from '@/types/Ability'
import GestionSeries from '@/views/Administration/Sessions/GestionSeries.vue'
import OrganisationJourneesOral from '@/views/Administration/Sessions/OrganisationJourneesOral.vue'
import { addDaysToDate, codeRestrict, diffTwoDatesInDays, formatDateSinTime, formatDateYYYYMMDD, getUnixTime, getWeekDayOfTime, hoursMinutesToLocal } from '@/utils/helpers'
import { TypePassation } from '@/types/Epreuve'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { BButton } from 'bootstrap-vue'


@Component({
    components: {
        Table,
        GestionSeries,
        OrganisationJourneesOral,
        ErrorDisplay,
        'font-awesome-icon': FontAwesomeIcon,
        'b-button': BButton
    },
    computed: {
        ...mapGetters('epreuve', ['loading', 'error', 'epreuvesCorrections', 'epreuveById', 'meta', 'links', 'totalRows']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapGetters('serie', ['series']),
        ...mapGetters('session', ['error'])
    }
})
export default class CalendrierOraux extends Vue {
    codeRestrict = codeRestrict
    @Prop() session_id?: number
    // session_id = 0
    currentSession: any = null

    series_validated_at: any = null
    actionLimited = true
    selectedSerieId = 0
    showModalOrganisationJourneesOral = false
    modeLimite = false
    // DATAS
    fields = [
        {
            key: 'actionEdit',
            label: '',
            sortable: false,
            sortDirection: 'asc',
            tdClass: 'col-w-etat btn_action_ligne'
        },
        // { key: 'id', label: 'Id', sortable: true, sortDirection: 'asc', class: 'text-start ps-4' },
        { key: 'name', label: 'Libellé', sortable: true, sortDirection: 'asc', class: 'text-start ps-4' },
        {
            key: 'id',
            label: 'Filière',
            sortable: true,
            sortDirection: 'asc',
            class: 'text-start ps-4',
            formatter: function (cell: any, prop: any, row: any) {
                return store.getters['epreuve/getStringFiliereOfEpreuveCorrectionById'](cell)
            }
        },
        {
            key: 'h_debut',
            label: 'Début',
            sortable: true,
            sortDirection: 'asc',
            class: 'text-start ps-4',
            formatter: function (cell: any, prop: any, row: any) {
                if (cell) {
                    return hoursMinutesToLocal(cell)
                }
            }
        },
        {
            key: 'h_pause_debut',
            label: 'Pause',
            sortable: true,
            sortDirection: 'asc',
            class: 'text-center',
            formatter: function (cell: any, prop: any, row: any) {
                if (row && row.h_pause_debut) {
                    return hoursMinutesToLocal(row.h_pause_debut) + '-' + hoursMinutesToLocal(row.h_pause_fin)
                } else {
                    return ''
                }
            }
        },
        {
            key: 'h_fin',
            label: 'Fin',
            sortable: true,
            sortDirection: 'asc',
            class: 'text-start ps-4',
            formatter: function (cell: any, prop: any, row: any) {
                if (cell) {
                    return hoursMinutesToLocal(cell)
                }
            }
        },
        {
            key: 'duree_preparation',
            label: 'Durée préparation',
            sortable: true,
            sortDirection: 'asc',
            class: 'text-center ps-4',
            formatter: function (cell: any, prop: any, row: any) {
                return cell + ' min'
            }
        },
        {
            key: 'duree_composition',
            label: 'Durée interrogation',
            sortable: true,
            sortDirection: 'asc',
            class: 'text-center ps-4',
            formatter: function (cell: any, prop: any, row: any) {
                return cell + ' min'
            }
        },
        { key: 'nb_cand_jour', label: 'Candidats par journée', sortable: true, sortDirection: 'asc', class: 'text-start ps-4' }
        // ,{ key: 'delete', label: '', sortable: false, sortDirection: 'asc', tdClass: 'text-end' }
    ]

    sortBy = ''
    sortDesc = false
    sortDirection = 'asc'
    filter = ''
    filterOn = []
    stickyHeader = true
    epreuve: any = null
    epreuveTemp: any = null
    Ability = Ability
    seriesTemp: any = null
    formatDateSinTime = formatDateSinTime

    showModalEditionEpreuve = false
    showModalMessageDelete = false
    showModalGestionSerie = false
    showConfirmeValidationSerie = false

    collectionDynamique: {cle: string; datas: Array<string> } = { cle: 'seriesCalendrierOraux', datas: [] }

    filtres: any = []

    // METHODS

    serieFiltered (series: any) {
        return series.filter((se: any) => se.debut !== null)
    }

    canEdit () {
        if (this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE) && this.currentSession && this.currentSession.closed_at === null) {
            return true
        } else {
            return false
        }
    }

    buildFiltres () {
        const filieres = this.$store.getters['concour/banques']
        const options_filieres = []
        for (const f in filieres) {
            options_filieres.push({ id: filieres[f].id, name: filieres[f].name })
        }
        this.filtres = [
            {
                libelle: 'Libellé',
                defautOptionlibelle: 'Rechercher un',
                model: 'name',
                value: '',
                index: 'name',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'name' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Filière',
                defautOptionlibelle: 'Rechercher une',
                model: 'concour_id',
                value: '-',
                index: 'id',
                datas: options_filieres,
                loading: false,
                options: { type: 'deroulant', fieldsKey: 'id' } // 'form' , 'deroulant'
            }
        ]
    }

    /** quand on sélectionne une série ds le select au-dessus du tableau, on relance la construction des colonnes du tableau */
    serieChangeHandler() {
        this.buildFields()
    }

    /** construction des colonnes du tableau */
    buildFields () {
        this.collectionDynamique = { cle: 'seriesCalendrierOraux', datas: [] }
        this.fields = [
            {
                key: 'actionEdit',
                label: '',
                sortable: false,
                sortDirection: 'asc',
                tdClass: 'col-w-etat btn_action_ligne'
            },
            // { key: 'id', label: 'Id', sortable: true, sortDirection: 'asc', class: 'text-start ps-4' },
            { key: 'name', label: 'Libellé', sortable: true, sortDirection: 'asc', class: 'text-start ps-4' },
            {
                key: 'id',
                label: 'Filière',
                sortable: true,
                sortDirection: 'asc',
                class: 'text-start ps-4',
                formatter: function (cell: any, prop: any, row: any) {
                    return store.getters['epreuve/getStringFiliereOfEpreuveCorrectionById'](cell)
                }
            },
            {
                key: 'h_debut',
                label: 'Début',
                sortable: true,
                sortDirection: 'asc',
                class: 'text-start ps-4',
                formatter: function (cell: any, prop: any, row: any) {
                    if (cell) {
                        return hoursMinutesToLocal(cell)
                    }
                }
            },
            {
                key: 'h_pause_debut',
                label: 'Pause',
                sortable: true,
                sortDirection: 'asc',
                class: 'text-center',
                formatter: function (cell: any, prop: any, row: any) {
                    if (row && row.h_pause_debut) {
                        return hoursMinutesToLocal(row.h_pause_debut) + '-' + hoursMinutesToLocal(row.h_pause_fin)
                    } else {
                        return ''
                    }
                }
            },
            {
                key: 'h_fin',
                label: 'Fin',
                sortable: true,
                sortDirection: 'asc',
                class: 'text-start ps-4',
                formatter: function (cell: any, prop: any, row: any) {
                    if (cell) {
                        return hoursMinutesToLocal(cell)
                    }
                }
            },
            {
                key: 'duree_preparation',
                label: 'Durée préparation',
                sortable: true,
                sortDirection: 'asc',
                class: 'text-center ps-4',
                formatter: function (cell: any, prop: any, row: any) {
                    if (cell === 0) {
                        return 'Incluse'
                    } else {
                        return cell + ' min'
                    }
                }
            },
            {
                key: 'duree_composition',
                label: 'Durée interrogation',
                sortable: true,
                sortDirection: 'asc',
                class: 'text-center ps-4',
                formatter: function (cell: any, prop: any, row: any) {
                    if (cell !== 0) {
                        return cell + ' min'
                    } else {
                        return '-'
                    }
                }
            },
            { key: 'nb_cand_jour', label: 'Candidats par journée', sortable: true, sortDirection: 'asc', class: 'text-start ps-4' }
        ]

        /** construction des colonnes corespondantes au jour de la serie selectionnée */
        for (let i = 0; i < this.$store.state.serie.series.length; i++) {
            if(this.$store.state.serie.series[i].debut) {
                if (this.$store.state.serie.series[i].id === this.selectedSerieId) {
                    const CurrentFirstDayFormatted =  formatDateSinTime(this.$store.state.serie.series[i].debut)
                    const currentFirstDayString =  CurrentFirstDayFormatted.split('/')[0] + '/' + CurrentFirstDayFormatted.split('/')[1] + ' ' + getWeekDayOfTime(this.$store.state.serie.series[i].debut)
                    this.collectionDynamique.datas.push('series_' + this.selectedSerieId + '_' + getUnixTime(this.$store.state.serie.series[i].debut) + '_' +  formatDateYYYYMMDD(this.$store.state.serie.series[i].debut))

                    const fieldTemp0 = {
                        key: 'series_' + this.selectedSerieId + '_' + getUnixTime(this.$store.state.serie.series[i].debut) + '_' +  formatDateYYYYMMDD(this.$store.state.serie.series[i].debut),
                        label: currentFirstDayString,
                        sortable: false,
                        sortDirection: 'asc',
                        class: 'text-center jours_taille_fix',
                        formatter: function (cell: any, prop: any, row: any): any {
                            let retour = ''
                            const serieId = prop.split('_')[1]
                            const unixtim = prop.split('_')[2]
                            if (row.series && row.series.length > 0) {
                                for (let k = 0; k < row.series.length; k++) {
                                    if (parseInt(row.series[k].serie_id) === parseInt(serieId) && unixtim === getUnixTime(row.series[k].jour).toString()) {
                                        retour += 'ok'
                                    }
                                }
                            }
                            return retour
                        }
                    }
                    this.fields.push(fieldTemp0)
                    const daysDiff = diffTwoDatesInDays(this.$store.state.serie.series[i].debut, this.$store.state.serie.series[i].fin)
                    let currentDay = this.$store.state.serie.series[i].debut
                    for (let j = 0; j < daysDiff; j++) {
                        currentDay = addDaysToDate(currentDay, 1)
                        const constCurrentDayFormatted = formatDateSinTime(currentDay)

                        const currentDayString =  constCurrentDayFormatted.split('/')[0] + '/' + constCurrentDayFormatted.split('/')[1] + ' ' + getWeekDayOfTime(currentDay)
                        this.collectionDynamique.datas.push('series_' + this.selectedSerieId + '_' + getUnixTime(currentDay) + '_' + formatDateYYYYMMDD(currentDay))
                        const fieldTemp = {
                            key: 'series_' + this.selectedSerieId + '_' + getUnixTime(currentDay) + '_' + formatDateYYYYMMDD(currentDay),
                            label: currentDayString,
                            sortable: false,
                            sortDirection: 'asc',
                            class: 'text-center jours_taille_fix',
                            formatter: function (cell: any, prop: any, row: any): any {
                                let retour = ''
                                const serieId = prop.split('_')[1]
                                const unixtim = prop.split('_')[2]
                                if (row.series && row.series.length > 0) {
                                    for (let k = 0; k < row.series.length; k++) {
                                        if (parseInt(row.series[k].serie_id) === parseInt(serieId) && unixtim === getUnixTime(row.series[k].jour).toString()) {
                                            retour = 'ok'
                                        }
                                    }
                                }
                                return retour
                            }
                        }
                        this.fields.push(fieldTemp)
                    }
                }
            }
        }
        // this.fields.push({ key: 'delete', label: '', sortable: false, sortDirection: 'asc', tdClass: 'text-end' })
    }

    /** ouvre la fenetre de gestion des séries */
    openGestionSerie () {
        this.$store.state.session.error = null
        this.$store.state.epreuve.error = null
        this.seriesTemp = JSON.parse(JSON.stringify(this.$store.state.serie.series))
        this.showModalGestionSerie = true
    }

    /** ferme la fenetre de gestion des séries */
    closeGestionSerie () {
        if (this.showConfirmeValidationSerie) {
            this.$store.state.session.error = null
            this.showConfirmeValidationSerie = false
        } else {
            this.showModalGestionSerie = false
            this.$store.state.session.error = null
        }
    }

    submitGestionSerieHandler(e: any) {
        if(e.submitter.id === 'submit_gestion_serie_valid') {
            this.valideGestionSerie()
        }
        if(e.submitter.id === 'submit_gestion_serie_enreg') {
            this.enregistreGestionSerie()
        }
    }

    /** valide les modifications faites dans la fenetre de la gestion des series */
    valideGestionSerie () {
        this.showConfirmeValidationSerie = true
    }

    valideGestionSerieSuite () {
        if (!this.series_validated_at) {
            this.enregistreGestionSerie(true)
        } else {
            this.valideGestionSeriesEnvoi()
        }
    }

    /** enregistre les modifications faites dans la fenetre de la gestion des series */
    enregistreGestionSerie (valide = false) {
        for (let i = 0; i < this.seriesTemp.length; i++) {
            if(this.seriesTemp[i].debut === null || this.seriesTemp[i].fin === null) {
                return
            }
        }
        const payload = {
            series : this.seriesTemp
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('serie/updateSeries', payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                if (valide) {
                    // series_validated_at sur session
                    // put 'api/sessions/{session_id}/afex' avec ‘series_validate’ 0/1
                    this.valideGestionSeriesEnvoi()
                } else {
                    this.showModalGestionSerie = false
                    this.showConfirmeValidationSerie = false
                    this.$store.dispatch('epreuve/getEpreuvesCorrections', { session_id: this.session_id, type_passation: TypePassation.TYPE_PASSATION_ORAL, params: null }).then(() => {
                        this.buildFields()
                        this.buildFiltres()
                    })
                }
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    valideGestionSeriesEnvoi () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast((!this.series_validated_at ? 'Validation' : 'Invalidation') + ' en cours ...', infosToaster)
        const params = {
            session_id: this.session_id,
            payload: {
                series_validate: this.series_validated_at ? 0 : 1
            }
        }

        this.$store.dispatch('session/updateSessionAfex', params)
            .then((response) => {
                this.$store.state.session.sessionSelect.series_validated_at = response.data.data.series_validated_at
                this.series_validated_at = this.$store.state.session.sessionSelect.series_validated_at
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast((!this.series_validated_at ? 'Invalidation' : 'Validation') + ' terminé.', succesToaster)

                // this.showModalGestionSerie = false
                this.showConfirmeValidationSerie = false
                this.$store.dispatch('epreuve/getEpreuvesCorrections', { session_id: this.session_id, type_passation: TypePassation.TYPE_PASSATION_ORAL, params: null }).then(() => {
                    this.buildFields()
                    this.buildFiltres()
                })
            }).catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /** retourne le name d'une epreuve par sa banque_id */
    getLibelleEpreuvesById(id: number) {
        return this.$store.state.epreuves.filter((epreuve: any) => epreuve.banque_id === id).name
    }

    /** ouvre la fenentre d'ajout d'epreuve (pas utilisé) */
    openAddEpreuve () {
        this.epreuveTemp = {
            id: 0,
            code: '',
            name: ''
        }
        this.showModalEditionEpreuve = true
    }

    /** ouvre la fenetre d'organisation d'une journée */
    editEpreuve (row: any) {
        this.epreuve = row.item
        this.epreuveTemp = JSON.parse(JSON.stringify(this.epreuve))
        this.showModalOrganisationJourneesOral = true
    }

    /** ferme la fenetre d'organisation d'une journée */
    closeOrganisationJourneesOral () {
        this.epreuve = null
        this.epreuveTemp = null
        this.showModalOrganisationJourneesOral = false
    }

    /** enregistre les modification faites dans la fenêtre d'organisation d'une journée */
    enregistreOrganisationJourneeOral () {
        // console.log(' this.epreuveTemp.h_pause_debut : ' + this.epreuveTemp.h_pause_debut)
        let payload: {}
        if (this.epreuveTemp.h_pause_debut && this.epreuveTemp.h_pause_fin && this.epreuveTemp.h_pause_debut !== '' && this.epreuveTemp.h_pause_fin !== '') {
            payload = {
                is_configurable_schedule: this.epreuveTemp.is_configurable_schedule,
                h_start_min: this.epreuveTemp.h_start_min,
                h_end_max: this.epreuveTemp.h_end_max,
                id: this.epreuveTemp.id,
                session_id: this.session_id,
                code: this.epreuveTemp.code,
                name: this.epreuveTemp.name,
                estimation_nb_candidats: this.epreuveTemp.estimation_nb_candidats,
                long_name: this.epreuveTemp.long_name,
                nb_cand_jour: this.epreuveTemp.nb_cand_jour,
                nb_cand_demi_jour: this.epreuveTemp.nb_cand_demi_jour,
                nb_cand_vacation: this.epreuveTemp.nb_cand_vacation,
                h_debut: this.epreuveTemp.h_debut,
                h_fin: this.epreuveTemp.h_fin,
                h_pause_debut: this.epreuveTemp.h_pause_debut,
                h_pause_fin: this.epreuveTemp.h_pause_fin,
                series: this.epreuveTemp.series,
                duree_composition: this.epreuveTemp.duree_composition,
                duree_preparation: this.epreuveTemp.duree_preparation,
                type_passation : this.epreuveTemp.type_passation,
                jour_chevauchement_avant: this.epreuveTemp.jour_chevauchement_avant,
                jour_chevauchement_apres: this.epreuveTemp.jour_chevauchement_apres
            }
        } else {
            payload = {
                is_configurable_schedule: this.epreuveTemp.is_configurable_schedule,
                h_start_min: this.epreuveTemp.h_start_min,
                h_end_max: this.epreuveTemp.h_end_max,
                id: this.epreuveTemp.id,
                session_id: this.session_id,
                code: this.epreuveTemp.code,
                name: this.epreuveTemp.name,
                estimation_nb_candidats: this.epreuveTemp.estimation_nb_candidats,
                long_name: this.epreuveTemp.long_name,
                nb_cand_jour: this.epreuveTemp.nb_cand_jour,
                nb_cand_demi_jour: this.epreuveTemp.nb_cand_demi_jour,
                nb_cand_vacation: this.epreuveTemp.nb_cand_vacation,
                h_debut: this.epreuveTemp.h_debut,
                h_fin: this.epreuveTemp.h_fin,
                h_pause_debut: null,
                h_pause_fin: null,
                series: this.epreuveTemp.series,
                duree_composition: this.epreuveTemp.duree_composition,
                duree_preparation: this.epreuveTemp.duree_preparation,
                type_passation : this.epreuveTemp.type_passation,
                jour_chevauchement_avant: this.epreuveTemp.jour_chevauchement_avant,
                jour_chevauchement_apres: this.epreuveTemp.jour_chevauchement_apres
            }
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('epreuve/updateEpreuveCorrection', payload)
            .then(() => {
                this.epreuve = null
                this.epreuveTemp = null
                this.showModalOrganisationJourneesOral = false
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                store.dispatch('epreuve/getEpreuvesCorrections', { session_id: this.session_id, type_passation: TypePassation.TYPE_PASSATION_ORAL, params: null }).then(() => {
                    if (this.$store.state.serie.series[0]) {
                        this.selectedSerieId = this.$store.state.serie.series[0].id
                        this.buildFields()
                    }
                })
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /** ouvre la fenetre de confirmation de suppression d'une epreuve (non utilisée) */
    deleteEpreuve (row: any) {
        this.epreuve = row.item
        this.showModalMessageDelete = true
    }

    /** ferme la fentre de confirmation de suppression d'epreuv e (non utilisée) */
    cancelDelete () {
        this.epreuve = null
        this.showModalMessageDelete = false
    }

    /** confirme et suprime la suppresion d'une epreuve (non utilisée) */
    deleteSuite () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Suppression en cours ...', infosToaster)
        this.$store.dispatch('epreuve/deleteEpreuve', this.epreuve.id)
            .then(() => {
                this.epreuve = null
                this.epreuveTemp = null
                this.showModalEditionEpreuve = false
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Suppression terminée.', succesToaster)
                this.epreuve = null
                this.showModalMessageDelete = false
                store.dispatch('epreuve/getEpreuves')
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Appelle une page lors du scroll
    loadHandler (params: any) {
        this.$store.dispatch('epreuve/getMoreEpreuvesCorrections', { session_id: this.session_id, type_passation: TypePassation.TYPE_PASSATION_ORAL, params: params })
    }

    /** applique les filtres et les changements d'ordre du tableau */
    paramsLocal = null
    filtreSortHandler (params: any) {
        if (JSON.stringify(params) !== JSON.stringify(this.paramsLocal)) {
            this.paramsLocal = params
            this.$store.dispatch('epreuve/getEpreuvesCorrections', { session_id: this.session_id, type_passation: TypePassation.TYPE_PASSATION_ORAL, params: params })
        }
    }

    mountedSuite() {
        this.selectedSerieId = this.$store.state.serie.series[0].id
        this.buildFields()
        this.buildFiltres()

        this.$store.dispatch('epreuve/getEpreuvesCorrections', { session_id: this.session_id, type_passation: TypePassation.TYPE_PASSATION_ORAL, params: this.paramsLocal })
    }

    mounted () {
        this.currentSession = null
        // this.session_id = parseInt(this.$route.params.session_id)
        // this.$store.dispatch('session/getSession', { session_id: this.session_id }).then(() => {
        this.currentSession = this.$store.state.session.sessionSelect

        this.series_validated_at = this.$store.state.session.sessionSelect.series_validated_at
        if (this.$store.state.concour.totalRows === 0 && !this.$store.state.concour.loading) {
            this.$store.dispatch('concour/getConcours', { session_id: this.session_id, params: null })
        }
        if (this.$store.state.matiere.totalRows === 0 && !this.$store.state.matiere.loading) {
            this.$store.dispatch('matiere/getMatieres')
        }
        // store.dispatch('epreuve/getEpreuvesCorrections', { session_id: this.session_id, type_passation: TypePassation.TYPE_PASSATION_ORAL, params: null }).then(() => {
        if (this.$store.state.serie.series.length === 0) {
            this.$store.dispatch('serie/getSeries', { session_id: this.session_id }).then(() => {
                if (this.$store.state.serie.series[0]) {
                    this.mountedSuite()
                }
            })
        } else {
            this.mountedSuite()
        }
        // }).catch((error) => {
        //     console.log('ko:' + error)
        // })
    }
}
