























import { Vue, Component, Watch }   from 'vue-property-decorator'
import { FontAwesomeIcon }  from '@fortawesome/vue-fontawesome'

import ReclamationsEcrit   from '@/views/Reclamations/ReclamationsEcrit.vue'
// import ReclamationsOral    from '@/views/Reclamations/ReclamationsOral.vue'

/**
 * Composant principal de la gestion des candidats
*/
@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ReclamationsEcrit
        // ReclamationsOral
    }
})
export default class Reclamations extends Vue
{
    tabSelected = 'ecrit'

    @Watch('$route.params')
    setTabSelected () {
        // maj du tab à sélectionner (lorsque le composant a déjà été monté)
        this.tabSelected = this.$route.params.phase
    }


    beforeMount () {
        // Première init du tab à sélectionner
        this.tabSelected = this.$route.params.phase
    }
}
