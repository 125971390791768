




























































import { Watch, Prop, Vue, Component } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { CandidatInterface, CandidatCommentaireInterface } from '@/types/Candidat'
import CandidatComment from '@/components/Candidat/CandidatComment.vue'
/**
 * Composant relatif à la gestion des commentaires pour un candidat donné
 */
@Component({
    components: {
        CandidatComment,
        'font-awesome-icon': FontAwesomeIcon
    }
})
export default class PopupEditCommentCandidat extends Vue {
    @Prop() showEdit?: boolean;
    showModal?: boolean = false
    candidatSelected?: CandidatInterface = undefined
    currentComments: CandidatCommentaireInterface[] = []
    modeEdit = false

    /**
     * A l'affichage du popup: initialisation des informations relatives au candidat et
     * aux commentaires
     */
    @Watch('showEdit')
    showNoShow () {
        this.showModal = this.$props.showEdit
        if (this.showModal === true) {
            this.candidatSelected = this.$store.getters['candidat/selectedCandidat']
            if (this.candidatSelected && this.candidatSelected.comments) {
                this.currentComments = []
                for (const comment of this.candidatSelected.comments) {
                    this.currentComments.push(Object.assign({}, comment))
                }
            }
        }
    }

    /**
     * Ajout d'un nouveau commentaire dans l'interface de saisie
     */
    addComment () {
        const newComment: CandidatCommentaireInterface = {
            id: this.currentComments.length + 1,
            body: '',
            important: false,
            is_public: false
        }
        this.currentComments.push(newComment)
        if (this.candidatSelected) {
            this.candidatSelected.hasComments = true
        }
    }

    /**
     * Suppression d'un commentaire existant
     */
    deleteComment(commentToDelete: CandidatCommentaireInterface) {
        const index = this.currentComments.findIndex((comment: CandidatCommentaireInterface) => comment.id === commentToDelete.id)
        if (index !== -1) {
            this.currentComments.splice(index, 1)
        }
        if (this.candidatSelected && this.currentComments.length === 0) {
            this.candidatSelected.hasComments = false
        }
    }

    commentTemp: any = null
    editComment (commentToEdit: CandidatCommentaireInterface) {
        console.log('editComment ' + commentToEdit.body)
        this.commentTemp = JSON.parse(JSON.stringify(commentToEdit))
        this.modeEdit = true
    }

    cancelEditComment () {
        this.commentTemp = null
        this.modeEdit = false
    }


    /**
     * Sauvegarde des commentaires ajoutés ou édités
     */
    saveComments () {
        if (this.candidatSelected) {
            const payload: any = {
                id: this.candidatSelected.id,
                comments: []
            }
            this.candidatSelected.hasImportantComments = false
            for (const comment of this.currentComments) {
                payload.comments.push({
                    id: comment.id,
                    body: comment.body,
                    important: comment.important === true ? 1 : 0
                })

                if (comment.important === true) {
                    this.candidatSelected.hasImportantComments = true
                }
            }
            this.$store.dispatch('candidat/updateCommentsCandidat', payload).then(() => {
                this.$emit('reinitShow', this.candidatSelected)
            }).catch(err => {
                console.log('error save comments candidat: ', err)
                this.$emit('reinitShow', null)
            })
        }
    }

    closeModal () {
        this.$emit('reinitShow', null)
    }
}
