




















































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { FontAwesomeIcon }      from '@fortawesome/vue-fontawesome'
import { mapGetters, mapState } from 'vuex'
import PlanningsExaminateur  from '@/views/Plannings/PlanningsExaminateur.vue'
import PlanningsCandidat  from '@/views/Plannings/PlanningsCandidat.vue'
import EmploiTemps  from '@/views/Plannings/EmploiTemps.vue'
import Notifications from '@/views/Plannings/Notifications.vue'
import { Ability } from '@/types/Ability'
import { EtatSeriePublie, EtatSerieValide, getItemEtatSerie } from '@/types/Planning'

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapState('serie', ['series']),
        ...mapState('session', ['sessionSelect'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        PlanningsExaminateur,
        PlanningsCandidat,
        EmploiTemps,
        Notifications
    }
})
export default class Plannings extends Vue
{
    Ability                     = Ability
    ongletSelect                = 'examinateur'
    getItemEtatSerie            = getItemEtatSerie
    EtatSeriePublie             = EtatSeriePublie
    EtatSerieValide             = EtatSerieValide

    serie_publish_select: any   = null
    publish_serie               = 0
    showModalePublishSerie      = false
    message_depublier           = ''
    error_publish               = ''
    loadingConfirmation         = false


    @Watch('ongletSelect')
    resetMeta () {
        this.$store.commit('planning/RESET_META')
    }

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    /**
     * Ouvre le popup de confirmation de publication d'une série
     */
    openModalePublicationSerie (serie: any, publish: boolean) {
        this.serie_publish_select = serie
        if (publish) {
            this.publish_serie = 1
        } else {
            this.publish_serie = 0
        }
        this.showModalePublishSerie = true
    }

    /**
     * Ferme et annule la publication d'une série
     */
    closePublishSerie () {
        this.error_publish = ''
        this.message_depublier = ''
        this.serie_publish_select = null
        this.showModalePublishSerie = false
    }

    /**
     * Publie une série
     */
    confirmPublishSerie () {
        this.error_publish = ''
        if (this.publish_serie === 0) {
            // Depublication on check si le message est présent
            if (this.message_depublier.trim().length === 0) {
                this.error_publish = 'Vous devez saisir un message dans le cas d\'une annulation de publication.'
            }
        }

        if (this.error_publish === '') {
            this.loadingConfirmation = true
            /* Toast Area */
            const idInfo = 't_info_' + Math.random()
            const infosToaster = {
                id: idInfo,
                toaster: 'b-toaster-top-right',
                variant: 'primary',
                noCloseButton: true,
                fade: true,
                noAutoHide: true
            }
            const toast_load_message = this.publish_serie === 0 ? 'Annulation de la publication en cours ... ' : 'Publication en cours ...'
            this.$bvToast.toast(toast_load_message, infosToaster)
            /* End toast */

            this.$store.dispatch('planification/validateSerie', {
                serie_id: this.serie_publish_select.id,
                payload : { valide: this.serie_publish_select.valide, publie: this.publish_serie, message: this.message_depublier, _method: 'PUT' }
            })
                .then(() => {
                    // Refresh series
                    this.$store.dispatch('serie/getSeries')
                        .then(() => {
                            const idSucces = 't_succes_' + Math.random()
                            const succesToaster = {
                                id: idSucces,
                                toaster: 'b-toaster-top-right',
                                variant: 'success',
                                noCloseButton: true,
                                fade: true,
                                autoHideDelay: 5000
                            }
                            const toast_valide_message = this.publish_serie === 1 ? 'Publication terminée' : 'Annulation de la publication terminée'
                            this.$bvToast.toast(toast_valide_message, succesToaster)
                            this.closePublishSerie()
                        })
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                    this.loadingConfirmation = false
                })
        }
    }

    load () {
        this.$store.dispatch('serie/getSeries').then(() => {
            this.$store.dispatch('matiere/getMatieres').then(() => {
                this.$store.dispatch('concour/getConcoursActifs')
            })
        })
    }

    beforeMount () {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
