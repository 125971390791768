








































































































































































import { mapActions, mapGetters }     from 'vuex'
import { Vue, Component, Prop, Watch }          from 'vue-property-decorator'
import { isObject }                             from '@/utils/helpers'
import { Ability }                              from '@/types/Ability'
import _                                        from 'lodash'
import  ErrorDisplay                            from '@/components/ErrorDisplay.vue'
import { type_demission } from '@/types/Demission'

@Component({
    components: {
        ErrorDisplay
    },
    computed: {
        ...mapGetters('demission', ['loading_demission', 'error_demission']),
        ...mapGetters('candidatIncompatible', ['resultatRechercheParCandidat', 'get_meta_recherche_Candidat']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    },
    methods: {
        ...mapActions('postes', ['getAllPostes'])
    }
})

export default class PopupCreerDemission extends Vue {
    @Prop() show?: boolean;
    @Prop() infosFiliereEpreuveSelect?: any;
    @Prop() type_demission?: string

    Ability = Ability
    lockInput = false
    etape_selection_candidat = true
    etape_renseigner_demission = false
    candidatTarget = {
        code: '',
        name: ''
    }

    options_filieres: any               = []
    options_epreuves: any               = []
    resultat: any                       = []
    meta: any                           = []
    loadSearch                          = false
    firstSearch                         = false

    // Dans le cas de la recherche pour les réclamations et démissions, la recherche est basée sur la filière et non la classe, et pas de user_id associé.
    recherche = {
        user_id: -1,
        name: '',
        first_name: '',
        etablissement_id: '*',
        classe: '*',
        filiere: '*',
        code: ''
    }

    SelectedCandidat: any               = {} // candidat sélectionné
    epreuveCandidat_Id                  = 0 // id de l'épreuve du candidat sélectionné
    type_demission_ref = type_demission


    @Watch('show')
    initInterface () {
        if (this.show === true) {
            this.initfilieresMatieres()
        }
    }

    @Watch('resultatRechercheParCandidat')
    watchResultatRechercheParCandidat() {
        this.resultat = this.$store.getters['candidatIncompatible/resultatRechercheParCandidat']
        this.meta     = this.$store.getters['candidatIncompatible/get_meta_recherche_Candidat']
    }

    getFiliereName(concours_id: number) {
        const nomConcours: any = this.options_filieres.filter((c: any) => c.index === concours_id)[0].name
        return nomConcours
    }

    select_epreuve_id (e: any) {
        this.epreuveCandidat_Id = e.target.value
    }

    initfilieresMatieres () {
        // Options filières
        const filieres = this.$store.getters['concour/banques']
        this.options_filieres = []
        for (const f in filieres) {
            this.options_filieres.push({ index: filieres[f].id, name: filieres[f].name })
        }
    }

    isObjectLocal (value: any) {
        return isObject(value)
    }

    /** Retourne la liste passée en paramètre ordonnée par name */
    orderedList (liste: any) {
        return _.orderBy(liste, 'name', 'asc')
    }

    /** fonction de declenchement de la recherche. reinit resultat */
    launchSearch() {
        this.loadSearch = true
        this.resultat = []

        /* *** Note *************************************************************************************************
            On utilise ici la base de la requête de recherche qui avait été mise en place pour les incompatibilités.
            Pour un souci de gain de temps, et parce que le retour de la requête fournit directement l'objet établissement du candidat
            dont on a besoin ici.
        ********************************************************************************************************** */
        this.$store.dispatch('candidatIncompatible/RECHERCHE_PAR_CANDIDAT', this.recherche).then(() => {
            this.loadSearch = false
            this.firstSearch = true
        })
    }

    /** Sauvegarde de la selection de l'utilisateur  */
    setSelectedCandidat(candidat: any) {
        this.SelectedCandidat = candidat
    }

    /** on confirme le candidat sélectionné pour passer à l'étape suivante de renseignement de la réclamation  */
    confirmSelectedCandidat() {
        const params = { id: this.SelectedCandidat.id }
        this.$store.dispatch('candidat/getCandidat', params).then((response) => {
            // Init de la combo des épreuves liées aux inscriptions du candidat
            // Filtre les épreuves maîtresses et sur la phase admissibilité
            this.SelectedCandidat = response.data.data
            // Inscriptions concours
            this.SelectedCandidat.concours_result = this.SelectedCandidat?.concours
                ?.map((concour: any) => concour.code)
                ?.join(', ') || ''

            if (this.$props.type_demission === type_demission.CONCOURS) {
                this.epreuveCandidat_Id = 0
            }

            this.etape_selection_candidat = false
            this.etape_renseigner_demission = true
        })
    }

    /**
     * Ferme la popup avec réinit des variables locales sensibles.
     */
    closeModal () {
        this.$store.state.demission.error_demission = null
        this.etape_selection_candidat = true
        this.etape_renseigner_demission = false
        this.epreuveCandidat_Id = 0

        this.$store.commit('candidatIncompatible/RESET_SEARCH')
        this.SelectedCandidat = {}
        this.firstSearch = false
        this.recherche = {
            user_id: 0,
            name: '',
            first_name: '',
            etablissement_id: '*',
            classe: '*',
            filiere: '*',
            code: ''
        }
        this.$emit('reinitShow', null)
    }

    /**
     * Enregistrement de la démission
     */
    saveDemission () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        const params = {
            epreuve_id: this.$props.type_demission ===  type_demission.EPREUVES_FAC ? this.epreuveCandidat_Id : 0
        }

        this.$bvToast.toast('Enregistrement en cours...', infosToaster)
        this.$store.dispatch('demission/postDemission', { type: this.$props.type_demission, candidat_id: this.SelectedCandidat.id, payload: params })
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé', succesToaster)
                this.closeModal()
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * Retourne le name d'une épreuve depuis un getter
     */
    get_name_epreuve (epreuve_id: number) {
        return this.$store.getters['epreuve/getNameEpreuveById'](epreuve_id)
    }
}

