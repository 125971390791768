






















































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { base64ToArrayBuffer, formatDate, isObject } from '@/utils/helpers'
import EditorTinyMCE from '@/components/Tools/EditorTinyMCE.vue'
import VuePdfApp from 'vue-pdf-app'
import { Ability } from '@/types/Ability'
import { getTypeAmenagement } from '@/types/Salle'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

@Component({
    components: {
    // VueEditor
        EditorTinyMCE,
        VuePdfApp,
        ErrorDisplay,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('centre', ['error', 'loading']),
        ...mapState('centre', ['error', 'centreSelect']),
        ...mapState('conventiontype', ['conventiontype_select']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})
export default class AnnexeConventionEditor extends Vue {
    @Prop() context?: number;
    @Prop() centre?: any

    Ability = Ability
    getTypeAmenagement = getTypeAmenagement

    // DATAS
    montant_total = 0
    convention_annexefinanciere: Array<any> = []
    empty_editor = false
    showError = false
    messageErreurSimple = ''
    lockInput = true
    modeEditionADM = false
    lockInputAdmin = true
    msgSuccess_toast = ''
    message_rejet = ''
    messageRejetAnnexe = ''
    fileSingleFromInput: any = null
    showPreview = false

    toolbarAnnexe = 'formatselect | bold italic strikethrough forecolor | numlist bullist outdent indent'
    cursor_in_editor = false
    showModalConfirmationSoumettre = false
    centre_select: any = null
    infoModeEdition = {
        modeEdition: false,
        annexeSaved: false
    }

    blocs: Array<any> = []
    datas = new FormData()
    hasSelectedFile = false
    source_doc: any = null
    file_name = ''
    showMessageRejet = false
    showConfirmPublier = false
    showConfirmDepublier = false
    config = {
        toolbar: {
            toolbarViewerRight: { presentationMode: false, openFile: false, viewBookmark: false, secondaryToolbarToggle: false }
        }
    }

    // METHODS
    beforeMount () {
        if (this.$props.centre) {
            this.$store.dispatch('centre/getCentre', { centre_id: this.$props.centre.id }).then(() => {
                this.centre_select = this.$store.state.centre.centreSelect

                if (this.centre_select.convention_type_id) {
                    this.initChampsDeSaisie()
                    this.getMessageRejetAnnexe()
                }
            })
        }
    }

    /** Check le type du retour d'erreur */
    isObject () {
        return isObject(this.$store.state.centre.error)
    }

    initChampsDeSaisie () {
        this.empty_editor = false

        this.$store.dispatch('conventiontype/getConventionType', { convention_type_id: this.centre_select.convention_type_id }).then(() => {
            this.blocs = this.$store.state.conventiontype.conventiontype_select.annexe_blocs
            for (const i in this.blocs) {
                this.convention_annexefinanciere[this.blocs[i].code] = {
                    code: this.blocs[i].code,
                    contenu: null,
                    montant: 0
                }
            }

            if (this.centre_select.annexe_financiere) {
                const parse_annexe = this.centre_select.annexe_financiere
                for (const i in parse_annexe) {
                    this.convention_annexefinanciere[parse_annexe[i].code] = {
                        code: parse_annexe[i].code,
                        contenu: parse_annexe[i].contenu,
                        montant: parse_annexe[i].montant
                    }
                }
            }
        })

        if (this.centre_select.montant_annexe_financiere) {
            this.montant_total = this.centre_select.montant_annexe_financiere
        }
        if (this.centre_select.annexe_submitted_at || this.centre_select.annexe_published_at) {
            this.lockInput = true
        } else {
            if (this.$props.context !== 'admin') {
                this.lockInput = false
            }
        }

        if (this.$props.context === 'readonly') {
            this.lockInput = true
        }
    }

    setContentBloc (bloc: any) {
        const bloc_id_split = bloc.bloc_id.split('_')
        const bloc_id = bloc_id_split[1]
        this.convention_annexefinanciere[bloc_id].contenu = bloc.content
    }

    // Formatte les dates
    formatteDate (date: Date) {
        return formatDate(date)
    }

    // Retourne l'éventuel message de rejet de l'annexe
    getMessageRejetAnnexe () {
        if (this.centre_select.commentaire_rejet) {
            if (this.centre_select.commentaire_rejet.annexe) {
                this.message_rejet = this.centre_select.commentaire_rejet.annexe
            }
        }
    }

    ouvrirEdition () {
        this.modeEditionADM = true
        this.infoModeEdition.modeEdition = true
        this.infoModeEdition.annexeSaved = false
        this.$emit('changeModeEditionAnnexe', this.infoModeEdition)
    }

    annulerEdition (saved: boolean) {
        this.modeEditionADM = false
        this.infoModeEdition.modeEdition = false
        this.infoModeEdition.annexeSaved = saved
        this.$emit('changeModeEditionAnnexe', this.infoModeEdition)
    }

    updateMontantTotal () {
        this.montant_total = 0
        for (const i in this.convention_annexefinanciere) {
            this.montant_total += parseFloat(this.convention_annexefinanciere[i].montant)
        }
    }

    manageSubmitAnnexeConvention (mode: string) {
        const errors = 0
        switch (mode) {
            case 'Enregistrer':
                /* for (const i in this.convention_annexefinanciere) {
                    if (this.convention_annexefinanciere[i].contenu === null || this.convention_annexefinanciere[i].contenu === '') {
                        errors++
                    }
                } */

                if (errors >= 1) {
                    this.empty_editor = true
                } else {
                    this.empty_editor = false
                    this.saveDataAnnexeConvention()
                }
                break
            case 'Soumettre':
                /* for (const i in this.convention_annexefinanciere) {
                    if (this.convention_annexefinanciere[i].contenu === null || this.convention_annexefinanciere[i].contenu === '') {
                        errors++
                    }
                } */

                if (errors >= 1) {
                    this.empty_editor = true
                } else {
                    this.empty_editor = false
                    this.showModalConfirmationSoumettre = true
                }
                break
            default:
                break
        }
    }

    cancelSoumettre () {
        this.showModalConfirmationSoumettre = false
    }

    confirmerSoumettre () {
        this.submitAnnexeConvention()
    }

    // Uniquement enregistrer les données de l'annexe de convention
    saveDataAnnexeConvention () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.msgSuccess_toast = 'Enregistrement terminé.'

        // Préparation des données
        const blocs_annexe_financiere: Array<any> = []
        for (const i in this.convention_annexefinanciere) {
            blocs_annexe_financiere.push({ code: i, contenu: this.convention_annexefinanciere[i].contenu, montant: this.convention_annexefinanciere[i].montant })
        }

        const payload = new FormData()
        payload.set('_method', 'PUT')
        payload.set('annexe_financiere', JSON.stringify(blocs_annexe_financiere))
        payload.set('montant_annexe_financiere', this.montant_total.toString())
        if (this.fileSingleFromInput) {
            payload.set('document', this.fileSingleFromInput)
        }

        this.$store.dispatch('centre/updateCentreFormData', { centre_id: this.centre_select.id, payload: payload })
            .then(() => {
                this.showError = false
                this.messageErreurSimple = ''
                this.montant_total = 0
                this.message_rejet = ''
                this.fileSingleFromInput = null
                this.lockInput = true

                if (this.$props.context === 'admin') {
                    this.annulerEdition(true)
                }

                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast(this.msgSuccess_toast, succesToaster)
                this.centre_select = this.$store.state.centre.centreSelect
                this.initChampsDeSaisie()
                // })
            })
            .catch(() => {
                this.showError = true
                this.messageErreurSimple = 'Une erreur est survenue :'
                if (this.$props.context === 'admin') {
                    this.annulerEdition(false)
                }
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Enregistrement des données ET soumission de l'annexe de convention
    submitAnnexeConvention () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        // Préparation des données
        const blocs_annexe_financiere: Array<any> = []
        for (const i in this.convention_annexefinanciere) {
            blocs_annexe_financiere.push({ code: i, contenu: this.convention_annexefinanciere[i].contenu, montant: this.convention_annexefinanciere[i].montant })
        }

        this.msgSuccess_toast = 'Enregistrement terminée.'
        const payload = new FormData()
        payload.set('_method', 'PUT')
        payload.set('annexe_financiere', JSON.stringify(blocs_annexe_financiere))
        payload.set('montant_annexe_financiere', this.montant_total.toString())
        if (this.fileSingleFromInput) {
            payload.set('document', this.fileSingleFromInput)
        }
        payload.set('annexe_submit', '1')

        this.$store.dispatch('centre/updateCentreFormData', { centre_id: this.centre_select.id, payload: payload })
            .then(() => {
                this.showError = false
                this.messageErreurSimple = ''
                this.montant_total = 0
                this.message_rejet = ''
                this.lockInput = true
                this.fileSingleFromInput = null

                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast(this.msgSuccess_toast, succesToaster)
                this.centre_select = this.$store.state.centre.centreSelect
                this.initChampsDeSaisie()
                this.showModalConfirmationSoumettre = false
            })
            .catch(() => {
                this.showError = true
                this.messageErreurSimple = 'Une erreur est survenue :'
                this.showModalConfirmationSoumettre = false
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /** Sélection du RIB du centre */
    selectFile (e: Event) {
        if (e.target !== null) {
            const target = e.target as HTMLInputElement
            this.fileSingleFromInput = (target.files as FileList)[0]
            this.hasSelectedFile = true
        }
    }

    /** Retourne une classe css selon l'état de validation des infos */
    getClassBarreBoutons () {
        let class_css = 'barre_default'

        if (this.$store.state.centre.centreSelect.annexe_submitted_at && !this.$store.state.centre.centreSelect.annexe_published_at && !this.$store.state.centre.centreSelect.annexe_signed_at && !this.$store.state.centre.centreSelect.annexe_validated_at) {
            class_css = 'barre_soumis'
        } else if (this.$store.state.centre.centreSelect.annexe_submitted_at && this.$store.state.centre.centreSelect.annexe_published_at && !this.$store.state.centre.centreSelect.annexe_signed_at && !this.$store.state.centre.centreSelect.annexe_validated_at) {
            class_css = 'barre_soumis'
        } else if (this.$store.state.centre.centreSelect.annexe_submitted_at && this.$store.state.centre.centreSelect.annexe_published_at && this.$store.state.centre.centreSelect.annexe_signed_at && !this.$store.state.centre.centreSelect.annexe_validated_at) {
            class_css = 'barre_soumis'
        } else if (this.$store.state.centre.centreSelect.annexe_submitted_at && this.$store.state.centre.centreSelect.annexe_published_at && this.$store.state.centre.centreSelect.annexe_signed_at && this.$store.state.centre.centreSelect.annexe_validated_at) {
            class_css = 'barre_valide'
        } else if (this.$store.state.centre.centreSelect.commentaire_rejet && this.$store.state.centre.centreSelect.commentaire_rejet.annexe) {
            class_css = 'barre_rejet'
        }

        return class_css
    }

    /** Ouvre le mode de prévisualisation de l'annexe de convention */
    previewAnnexe () {
        this.source_doc = ''
        this.$store.state.centre.error = null
        this.$store.dispatch('centre/getAnnexeConventionPDF', { centre_id: this.$props.centre.id }).then(() => {
            this.source_doc = base64ToArrayBuffer(this.$store.state.centre.source_pdf)
            this.file_name = 'Convention_' + this.$store.state.centre.code
            this.showPreview = true
        }).catch(err => {
            console.log('error', err)
        })
    }

    /** Ferme le mode visualisation de l'annexe de convention */
    closePreview () {
        this.source_doc = null
        this.file_name = ''
        this.showPreview = false
    }

    // Rejette la saisie de l'annexe proposée
    rejeterAnnexe () {
        this.showMessageRejet = true
    }

    // Confirmation du rejet de l'annexe financière
    saveRejetConvention () {
        const payload = {
            id: this.centre_select.id,
            annexe_reject: 1,
            commentaire_rejet: this.messageRejetAnnexe
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('centre/updateCentre', payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Annexe financière mise à jour.', succesToaster)
                this.showMessageRejet = false
                this.messageRejetAnnexe = ''
                this.centre_select = this.$store.state.centre.centreSelect
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Annulation du rejet de l'annexe financière
    cancelRejetConvention () {
        this.showMessageRejet = false
        this.messageRejetAnnexe = ''
    }

    // Affiche le message de confirmation de la publication de l'annexe de convention
    confirmPublier () {
        this.showConfirmPublier = true
    }

    // Publication de l'annexe de convention pour signature
    publier () {
        const payload = {
            id: this.centre_select.id,
            annexe_publish: 1
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('centre/updateCentre', payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast("Publication de l'annexe de convention terminée.", succesToaster)

                this.$store.dispatch('centre/getCentre',  { centre_id: this.$store.state.centre.centreSelect.id }).then(() => {
                    this.centre_select = this.$store.state.centre.centreSelect
                    this.showConfirmPublier = false
                })
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Annulation de la publication
    cancelPublier () {
        this.showConfirmPublier = false
    }

    // Afficher la confirmation de dépublication
    confirmDepublier () {
        this.showConfirmDepublier = true
    }

    // Annulation de la publication
    cancelDepublier () {
        this.showConfirmDepublier = false
    }

    // Dépublication de l'annexe de convention pour signature
    depublier () {
        const payload = {
            id: this.centre_select.id,
            annexe_publish: 0
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('centre/updateCentre', payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast("Dépublication de l'annexe de convention terminée.", succesToaster)

                this.$store.dispatch('centre/getCentre',  { centre_id: this.$store.state.centre.centreSelect.id }).then(() => {
                    this.centre_select = this.$store.state.centre.centreSelect
                    this.showConfirmDepublier = false
                })
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /** Ouvre le mode de prévisualisation du rib */
    previewRIB () {
        this.source_doc = null
        this.$store.dispatch('centre/getRIBPdf', { centre_id: this.$props.centre.id }).then(() => {
            this.source_doc = base64ToArrayBuffer(this.$store.state.centre.source_pdf)
            this.file_name = 'Convention_' + this.$store.state.centre.code
            this.showPreview = true
        })
    }

    /** Supprime le RIB de l'annexe de convention */
    deleteRIB () {
        this.$store.dispatch('centre/deleteRIB', this.$props.centre.id).then(() => {
            this.$store.dispatch('centre/getCentre',  { centre_id: this.$props.centre.id }).then(() => {
                this.centre_select = this.$store.state.centre.centreSelect
                this.initChampsDeSaisie()
            })
        })
    }
}
