

















































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import SaisieSalles from '@/components/Centre/Salles/Ecrits/SaisieSalles.vue'
import SaisieIntervenants from '@/components/Centre/Salles/Ecrits/SaisieIntervenants.vue'
import PopupSubmitNbIntervenants from '@/components/Centre/Validations/PopupSubmitNbIntervenants.vue'
import { Ability } from '@/types/Ability'
import { formatDate } from '@/utils/helpers'
import MessageGeneralIntervenant from '@/components/MessageGeneralIntervenant.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    components: {
        SaisieSalles,
        SaisieIntervenants,
        PopupSubmitNbIntervenants,
        MessageGeneralIntervenant,
        ErrorDisplay
    },
    computed: {
        ...mapState('centre', ['centreSelect', 'error', 'loading']),
        ...mapGetters('centre', ['informations_is_submit', 'informations_is_validate', 'nb_intervenants_is_submit', 'nb_intervenants_is_validate', 'etat_centre_select']),
        ...mapGetters('organisationecrit', ['has_salle_amenagee', 'has_salle_isolee']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('auth', ['is_intervenant_centre'])
    }
})
export default class IntervenantsEcrit extends Vue {
    Ability = Ability
    showPopupSubmitIntervenant = false
    onglet_select = 'salles'

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    /** Ouvre le popup de confirmation de soumission de l'organisation écrite */
    submitEcrit () {
        this.showPopupSubmitIntervenant = true
    }

    /** Sauvegarde des deux collections : salles et intervenants */
    save () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('organisationecrit/manageOrganisationEcrit', { params: { mode: 'nb_interv', centre_id: this.$route.params.centre_id, besoins_submit: null, besoins_validate: null } })
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /** Formattage les dates */
    formatteDate (date: Date) {
        return formatDate(date)
    }

    /** Reset showPopups */
    resetPopup () {
        this.showPopupSubmitIntervenant = false
    }

    /** Retourne une classe css selon l'état de validation des infos */
    getClassBarreBoutons () {
        let class_css = 'barre_default'

        if (this.$store.getters['centre/orga_is_submit'] && !this.$store.getters['centre/orga_is_validate']) {
            class_css = 'barre_soumis'
        } else if (this.$store.getters['centre/orga_is_submit'] && this.$store.getters['centre/orga_is_validate']) {
            class_css = 'barre_valide'
        } else if (this.$store.getters['centre/has_commentaire_rejet_ecrit']('besoins')) {
            class_css = 'barre_rejet'
        }

        return class_css
    }

    load () {
        this.$store.dispatch('concour/getConcours', { isPrecedente: false }).then(() => {
            this.$store.dispatch('centre/getCentre', { centre_id: this.$route.params.centre_id })
        })
    }

    beforeMount () {
        if (this.$store && this.$store.state && this.$store.state.centre && this.$store.state.centre.error && this.$store.state.centre.error !== null) {
            this.$store.state.centre.error = null
        }
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
