export enum ProductionFournitureClient {
    CCS = 'ccs',
    CCMP = 'ccmp',
}

export enum ProductionFournitureMode {
    FOURNITURES = 'fournitures',
    CENTRES = 'centres'
}

export enum ExportPostscriptEnabled {
    NO,
    YES
}

export enum reclamationCorrectionAffectationMode {
    NO_CHECK = 'noCheck',
    CORRECTEUR_INITIAL = 'correcteurInitial',
    HORS_CORRECTEUR_INITIAL = 'horsCorrecteurInitial',
    CORRECTEUR_RESPONSABLE = 'correcteurResponsable'
}
