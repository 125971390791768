


























































































































































































































import { Vue, Component, Watch, Prop }    from 'vue-property-decorator'
import { mapGetters, mapState }     from 'vuex'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'
import { Ability }                  from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { formatDate } from '@/utils/helpers'
import { getListeStatutReception, getStatutReception, get_color_statut, StatutReception } from '@/types/Centre'
import MessageIndication from '@/components/MessageIndication.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { MessageIndicationType } from '@/types/MessageIndicationType'
import Back from '@/components/Tools/Back.vue'


@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapState('centresujet', ['centre_sujets', 'loading_cs', 'totalRows', 'error_cs']),
        ...mapState('centre', ['centreSelect', 'error'])
    },
    components: {
        ExaGenericTable,
        'font-awesome-icon': FontAwesomeIcon,
        MessageIndication,
        ErrorDisplay,
        Back
    }
})

export default class ReceptionSujets extends Vue
{
    @Prop() contexte?: string

    formatDate = formatDate
    Ability = Ability
    StatutReception = StatutReception
    getListeStatutReception = getListeStatutReception
    getStatutReception = getStatutReception
    MessageIndicationType = MessageIndicationType
    showConfirmationSoumissionReception = false
    showConfirmationValidationReception = false
    showConfirmationRejectSoumissionReception = false
    showPopupRelanceCentre = false
    is_mobile_device = false
    read_only = false
    has_anomalies = 0
    is_control = 0
    get_color_statut = get_color_statut

    genericfields: Array<any> = []

    sortDirection   = 'asc'
    dataForTab: Array<any>  = []
    params: any = {}
    exportWorking = false

    statut_sujets = StatutReception.EN_PREPARATION
    message_rejet = ''
    validate = true
    filtres: any = []

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    @Watch('statut_sujets')
    refreshStatut () {
        const color_maj = get_color_statut(this.statut_sujets, this.has_anomalies, this.is_control)
        this.$emit('maj_statut', color_maj)
        this.setDataForGenericTab(this.$store.state.centresujet.centre_sujets, false)
    }

    getForPrint () {
        const date: Date = new Date()
        const fileName = `reception_sujets_${date.getFullYear()}${(date.getMonth() + 1) < 10 ? '0' : ''}${date.getMonth() + 1}${date.getDate()}.xlsx`

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Export en cours...', infosToaster)
        this.$store.dispatch('centresujet/getPDF', { centre_id: this.$route.params.centre_id})
            .then((response) => {
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Création des lignes du tableau
    setDataForGenericTab(poData: any, apply_calc: boolean, isLoadMore = false)
    {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                // Etat de réception de la ligne
                let etat_yes = null
                let etat_no = null

                // Etat de controle de la ligne
                let etat_controle = null

                if (result.statut_reception === 1) {
                    etat_yes = true
                    etat_no = false
                } else if (result.statut_reception === 0) {
                    etat_yes = false
                    etat_no = true
                }

                if (result.statut_controle) {
                    etat_controle = result.statut_controle
                }
                let line: any = []

                const sujet = result.sujet ? result.sujet : null

                // Liste les filières sans doublons pour le sujet
                let filieres = ''
                let separator = ', '
                const concours: any = []
                if (result.sujet.epreuves) {
                    for (const e in result.sujet.epreuves) {
                        if (concours.length === 0 || concours.findIndex((c: any) => c.id === result.sujet.epreuves[e].concour.id) === -1) {
                            concours.push(result.sujet.epreuves[e].concour)
                        }
                    }

                    for (const e in concours) {
                        if (Number(e) === (concours.length - 1)) {
                            separator = ''
                        }

                        if (filieres.indexOf(concours[e].code) === -1) {
                            filieres += concours[e].name + separator
                        }
                    }
                }
                if (this.$props.contexte === 'gestionnaire') {
                    line = [
                        { label: '', item: sujet ? sujet.code + ' - ' + sujet.name : 'NR',  type: 'text', typeAction: null, class: 'max_size_column' },
                        { label: '', item: sujet ? sujet.couleur.name : 'NR', type: 'text', typeAction: null, class: 'thin_column text-uppercase' },
                        { label: '', item: filieres, type: 'text', typeAction: null, class: 'text-center thin_column' },
                        { label: '', item: result.nb_candidats, type: 'text', typeAction: null, class: 'input_size text-center' }
                    ]

                    if (this.statut_sujets > StatutReception.EN_PREPARATION) {
                        line.push({ label: '', item: result.nb_sujets, type: 'text', typeAction: null, class: 'input_size text-center' })
                    } else {
                        line.push({ label: '', item: result.nb_sujets, data: result, type: 'inputNumber', typeAction: 'insert_qte_commandee', class: 'input_size text-center', minInput: 0 })
                    }

                    line.push({ label: '', item: result, type: 'yes_no', typeAction: 'select_reponse', class: 'text-center input_yes_no', select: { yes: etat_yes, no: etat_no }, show_yes: true, show_no: true, disabled: (this.$store.state.centre.centreSelect.f_suj_validated_at !== null) })

                    if (etat_no === true) {
                        line.push({ label: '', item: result.nb_sujets_reception, data: result, type: 'inputNumber', typeAction: 'insert_qte_recue', class: 'input_size text-center', disabled: (this.$store.state.centre.centreSelect.f_suj_validated_at !== null), minInput: 0 })
                        line.push({ label: '', item: result, value: result.anomalie, type: 'inputText', typeAction: 'insert_anomalie', class: '', class_td: 'bg_no_actif text-danger', disabled: (this.$store.state.centre.centreSelect.f_suj_validated_at !== null) })
                    } else {
                        if (etat_yes) {
                            line.push({ label: '', item: (result.nb_sujets_reception ? result.nb_sujets_reception : result.nb_sujets), data: result, type: 'inputNumber', typeAction: 'insert_qte_recue', class: 'input_size text-center', disabled: (this.$store.state.centre.centreSelect.f_suj_validated_at !== null), minInput: result.nb_sujets })
                        } else {
                            line.push({ label: '', item: result.nb_sujets_reception, type: 'text', typeAction: null, class: 'input_size text-center' })
                        }

                        line.push({ label: '', item: null, type: 'text', typeAction: null, class: '', class_td: 'bg_gray_light' })
                    }
                    line.push({ label: '', item: result, type: 'yes_no', typeAction: 'confirm_control', class: 'text-center input_yes_no', select: { yes: etat_controle, no: null }, show_yes: true, show_no: false, disabled: (this.$store.state.centre.centreSelect.f_suj_validated_at !== null) })

                    if (etat_controle === 1) {
                        line.push({ label: '', item: result, value: result.commentaire, type: 'inputText', typeAction: 'insert_commentaire', class: '', class_td: 'bg_yes_actif', disabled: (this.$store.state.centre.centreSelect.f_suj_validated_at !== null) })
                    } else {
                        line.push({ label: '', item: null, type: 'text', typeAction: null, class: '', class_td: 'bg_gray_light' })
                    }
                } else {
                    line = [
                        { label: '', item: sujet ? sujet.code + ' - ' + sujet.name : 'NR',  type: 'text', typeAction: null, class: 'max_size_column' },
                        { label: '', item: sujet ? sujet.couleur.name : 'NR', type: 'text', typeAction: null, class: 'thin_column text-uppercase' },
                        { label: '', item: filieres, type: 'text', typeAction: null, class: 'text-center thin_column' },
                        { label: '', item: result.nb_sujets, type: 'text', typeAction: null, class: 'input_size text-center' },
                        { label: '', item: result, type: 'yes_no', typeAction: 'select_reponse', class: 'text-center input_yes_no', select: { yes: etat_yes, no: etat_no }, show_yes: true, show_no: true, disabled: (!!(this.$props.contexte !== 'gestionnaire' && this.read_only)) }
                    ]

                    if (etat_no === true) {
                        if (this.$props.contexte !== 'gestionnaire' && this.read_only) {
                            line.push({ label: '', item: result.nb_sujets_reception, data: result, type: 'text', typeAction: null, class: 'input_size text-center' })
                        } else {
                            line.push({ label: '', item: result.nb_sujets_reception, data: result, type: 'inputNumber', typeAction: 'insert_qte_recue', class: 'input_size text-center' })
                        }

                        line.push({ label: '', item: result, value: result.anomalie, type: 'inputText', typeAction: 'insert_anomalie', class: '', class_td: 'bg_no_actif text-danger', disabled: (!!(this.$props.contexte !== 'gestionnaire' && this.read_only)) })
                    } else {
                        if (etat_yes) {
                            line.push({ label: '', item: (result.nb_sujets_reception ? result.nb_sujets_reception : result.nb_sujets), data: result, type: 'inputNumber', typeAction: 'insert_qte_recue', class: 'input_size text-center', disabled: (this.$store.state.centre.centreSelect.f_suj_validated_at !== null), minInput: result.nb_sujets })
                        } else {
                            line.push({ label: '', item: result.nb_sujets_reception, type: 'text', typeAction: null, class: 'input_size text-center' })
                        }

                        line.push({ label: '', item: null, type: 'text', typeAction: null, class: '', class_td: 'bg_gray_light' })
                    }
                }



                this.dataForTab.push(line)
            }
        }
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'input_stock':
                    paParams[2].stock = paParams[1]
                    break
                case 'select_reponse':
                    this.selectionne_reponse(paParams[1].id, paParams[2])
                    break
                case 'insert_qte_recue':
                    this.insert_qte_recue(paParams[2].id, paParams[1])
                    break
                case 'insert_anomalie':
                    this.insert_anomalie(paParams[1].id, paParams[2])
                    break
                case 'insert_qte_commandee':
                    this.insert_qte_commandee(paParams[2].id, paParams[1])
                    break
                case 'confirm_control':
                    this.controle_ligne(paParams[1].id, paParams[2])
                    break
                case 'insert_commentaire':
                    this.insert_commentaire(paParams[1].id, paParams[2])
                    break
                default:
                    break
            }
        }
    }

    // Sélection une réponse de réception sur une ligne
    selectionne_reponse (id: number, reponse: string) {
        const datas = this.$store.state.centresujet.centre_sujets
        const ligne_select = datas.find((i: any) => i.id === id)
        const etat_reception = reponse === 'yes' ? 1 : (reponse === 'no' ? 0 : null)
        if (etat_reception !== ligne_select.statut_reception) {
            ligne_select.statut_reception = reponse === 'yes' ? 1 : (reponse === 'no' ? 0 : null)
        } else {
            // On réinit la réception
            ligne_select.statut_reception = null
        }

        this.setDataForGenericTab(datas, false)
    }

    // Active ou désactive le contrôle d'une ligne
    controle_ligne (id: number, reponse: string) {
        const datas = this.$store.state.centresujet.centre_sujets
        const ligne_select = datas.find((i: any) => i.id === id)
        const etat_controle = reponse === 'yes' ? 1 : (reponse === 'no' ? 0 : null)
        if (etat_controle !== ligne_select.statut_controle) {
            ligne_select.statut_controle = 1
        } else {
            ligne_select.statut_controle = 0
        }

        this.setDataForGenericTab(datas, false)
    }

    // Insére une anomalie sur une ligne
    insert_anomalie (id: number, anomalie: string) {
        const datas = this.$store.state.centresujet.centre_sujets
        const ligne_select = datas.find((i: any) => i.id === id)
        ligne_select.anomalie = anomalie
        this.setDataForGenericTab(datas, false)
    }

    // Insére un commentaire sur une ligne
    insert_commentaire (id: number, commentaire: string) {
        const datas = this.$store.state.centresujet.centre_sujets
        const ligne_select = datas.find((i: any) => i.id === id)
        ligne_select.commentaire = commentaire
        this.setDataForGenericTab(datas, false)
    }

    // Insére une quantité reçue sur une ligne
    insert_qte_recue (id: number, qte: any) {
        const datas = this.$store.state.centresujet.centre_sujets
        const ligne_select = datas.find((i: any) => i.id === id)

        // Si la réception est conforme, on s'assure que la quantité n'est pas inférieure à la quantité commandée
        if (ligne_select.statut_reception === 1) {
            if (qte < ligne_select.nb_sujets) {
                qte = ligne_select.nb_sujets
            }
        }
        ligne_select.nb_sujets_reception = Number(qte)
        this.setDataForGenericTab(datas, false)
    }

    // Insère une quantité totale sur une ligne
    insert_qte_commandee (id: number, qte: any) {
        const datas = this.$store.state.centresujet.centre_sujets
        const ligne_select = datas.find((i: any) => i.id === id)
        ligne_select.nb_sujets = Number(qte)
        this.setDataForGenericTab(datas, false)
    }

    // Applique le chargement de la pagination
    loadHandler (params: any) {
        this.$store.dispatch('centresujet/getMoreCentreSujets', { centre_id: this.$route.params.centre_id, params: params })
    }

    // Applique les filtres
    filtreSortHandler(params: any) {
        Vue.set(params, 'centre_id', this.$route.params.centre_id)
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.$store.dispatch('centresujet/getCentreSujets',  { centre_id: this.$route.params.centre_id, params: this.params }).then(() => {
                this.setDataForGenericTab(this.$store.state.centresujet.centre_sujets, false)
            })
        }
    }

    // Sauvegarde la saisie de la réception
    save_reception () {
        this.$store.commit('centre/SET_ERROR_FULL', null)
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours...', infosToaster)

        const payload = {
            _method: 'PUT',
            items: this.$store.state.centresujet.centre_sujets
        }

        this.$store.dispatch('centresujet/saveReceptionSujets', { centre_id:this.$route.params.centre_id, params: payload })
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                this.has_anomalies = this.$store.state.centresujet.centre_sujets.filter((ce: any) => ce.anomalie !== null).length
                this.is_control = this.$store.state.centresujet.centre_sujets.filter((ce: any) => ce.anomalie !== null && ce.statut_controle === 1 && ce.commentaire !== null).length
                const color_maj = get_color_statut(this.statut_sujets, this.has_anomalies, this.is_control)
                this.$emit('maj_statut', color_maj)
                this.setDataForGenericTab(this.$store.state.centresujet.centre_sujets, false)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Affiche le message de confirmation de la soumission de la réception
    soumettre_reception () {
        this.showConfirmationSoumissionReception = true
    }

    // Confirme la soumission de la réception
    confirmSoumettreReception () {
        const payload = {
            _method: 'PUT',
            f_suj_submit: true
        }
        const payload_save = {
            _method: 'PUT',
            items: this.$store.state.centresujet.centre_sujets
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours...', infosToaster)

        this.$store.dispatch('centresujet/saveReceptionSujets', { centre_id:this.$route.params.centre_id, params: payload_save })
            .then(() => {
                // Save ok on submit
                this.$store.dispatch('centre/majStatutFournitures', { centre_id : this.$route.params.centre_id, payload: payload })
                    .then(() => {
                        this.statut_sujets = StatutReception.AR_CENTRE
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }
                        this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                        this.read_only = true
                        this.showConfirmationSoumissionReception = false
                        this.setDataForGenericTab(this.$store.state.centresujet.centre_sujets, false)
                        this.$store.dispatch('centre/getCentre', { centre_id: this.$route.params.centre_id })
                    })
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Annule la soumission de la réception
    cancelSoumettreReception () {
        this.showConfirmationSoumissionReception = false
    }

    // Rejette la soumission de la réception
    reject_soumettre_reception () {
        this.showConfirmationRejectSoumissionReception = true
    }

    // Confirme le rejet de la soumission de la réception
    confirmRejectSoumettreReception () {
        const payload = {
            _method: 'PUT',
            f_suj_submit: false,
            commentaire_rejet: this.message_rejet
        }
        const payload_save = {
            _method: 'PUT',
            items: this.$store.state.centresujet.centre_sujets
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours...', infosToaster)

        this.$store.dispatch('centresujet/saveReceptionSujets', { centre_id:this.$route.params.centre_id, params: payload_save })
            .then(() => {
                // Save ok on submit
                this.$store.dispatch('centre/majStatutFournitures', { centre_id : this.$route.params.centre_id, payload: payload })
                    .then(() => {
                        this.statut_sujets = StatutReception.AR_CENTRE
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }
                        this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                        this.read_only = true
                        this.showConfirmationRejectSoumissionReception = false
                    })
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Annule le rejet de la soumission de la réception
    cancelRejectSoumettreReception () {
        this.showConfirmationRejectSoumissionReception = false
    }

    // Confirme la validation de la réception
    validation_reception (etat: boolean) {
        this.validate = etat
        this.showConfirmationValidationReception = true
    }

    // Enregistre la validation de la réception
    confirmValidationReception () {
        const payload = {
            _method: 'PUT',
            f_suj_validate: this.validate
        }
        const payload_save = {
            _method: 'PUT',
            items: this.$store.state.centresujet.centre_sujets
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours...', infosToaster)

        if (this.validate) {
            this.$store.dispatch('centresujet/saveReceptionSujets', { centre_id:this.$route.params.centre_id, params: payload_save })
                .then(() => {
                    // Save ok on submit
                    this.$store.dispatch('centre/majStatutFournitures', { centre_id : this.$route.params.centre_id, payload: payload })
                        .then(() => {
                            this.statut_sujets = StatutReception.AR_CENTRE
                            const idSucces = 't_succes_' + Math.random()
                            const succesToaster = {
                                id: idSucces,
                                toaster: 'b-toaster-top-right',
                                variant: 'success',
                                noCloseButton: true,
                                fade: true,
                                autoHideDelay: 5000
                            }
                            this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                            this.showConfirmationValidationReception = false
                            this.$store.dispatch('centre/getCentre', { centre_id: this.$route.params.centre_id })
                                .then(() => {
                                    this.setDataForGenericTab(this.$store.state.centresujet.centre_sujets, false)
                                })
                        })
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            this.$store.dispatch('centre/majStatutFournitures', { centre_id : this.$route.params.centre_id, payload: payload })
                .then(() => {
                    this.statut_sujets = StatutReception.AR_CENTRE
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                    this.showConfirmationValidationReception = false
                    this.$store.dispatch('centre/getCentre', { centre_id: this.$route.params.centre_id })
                        .then(() => {
                            this.setDataForGenericTab(this.$store.state.centresujet.centre_sujets, false)
                        })
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    // Annule la validation de la réception
    cancelValidationReception () {
        this.showConfirmationValidationReception = false
    }

    // Applique les paramètres de calcul pour estimer les quantités commandées
    apply_calcul () {
        this.setDataForGenericTab(this.$store.state.centresujet.centre_sujets, true)
    }

    // Sélectionne un statut pour l'état des sujets du centre
    select_statut (statut_id: number) {
        const payload = {
            _method: 'PUT',
            f_suj_statut: statut_id
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours...', infosToaster)

        this.$store.dispatch('centre/majStatutFournitures', { centre_id : this.$route.params.centre_id, payload: payload })
            .then(() => {
                this.statut_sujets = statut_id
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Statut mis à jour.', succesToaster)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Retourne la couleur de barre et la couleur de texte selon état
    get_barre_default () {
        let barre_texte = {
            barre: 'barre_default',
            color: '',
            text: ''
        }

        if (this.$store.state.centre.centreSelect.f_suj_validated_at && this.$props.contexte !== 'gestionnaire') {
            barre_texte = {
                barre: 'barre_valide',
                color: 'barre_text_valide',
                text: 'La réception des sujets a été validée le ' + formatDate(this.$store.state.centre.centreSelect.f_suj_validated_at)
            }
        } else if (!this.$store.state.centre.centreSelect.f_suj_validated_at && this.$store.state.centre.centreSelect.f_suj_submitted_at && this.$props.contexte !== 'gestionnaire') {
            barre_texte = {
                barre: 'barre_soumis',
                color: 'barre_text_soumis',
                text: 'La réception des sujets a été soumise le ' + formatDate(this.$store.state.centre.centreSelect.f_suj_submitted_at) + '. Merci de contacter le concours pour toute demande.'
            }
        }

        return barre_texte
    }

    // Ouvre le popup de relance du centre
    open_relance_centre () {
        this.showPopupRelanceCentre = true
    }

    // Ferme le popup de relance du centre
    close_relance_centre () {
        this.showPopupRelanceCentre = false
    }

    // Enregistre la relance du centre
    save_relance_centre () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Envoi en cours...', infosToaster)

        this.$store.dispatch('centre/relaunchSuiviFournituresCentre', { centre_id: this.$route.params.centre_id })
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Envoi terminé.', succesToaster)
                this.showPopupRelanceCentre = false
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Création des filtres pour le tableau
    setFiltersForGenericTab() {
        // Options filières
        const filieres = this.$store.getters['concour/banques']
        const options_filieres = []
        for (const f in filieres) {
            options_filieres.push({ index: filieres[f].id, name: filieres[f].name })
        }

        // Options couleur
        const couleurs = this.$store.getters['couleur/couleurs']
        const options_couleurs = []
        for (const c in couleurs) {
            options_couleurs.push({ index: couleurs[c].id, name: couleurs[c].name })
        }

        // Options reception
        const options_reception = []
        options_reception.push({ index: null, name: 'Non renseigné' })
        options_reception.push({ index: 0, name: 'Réception avec anomalie' })
        options_reception.push({ index: 1, name: 'Réception sans anomalie' })

        this.filtres = [
            { libelle: 'Référence de sujet', defautOptionlibelle: 'Rechercher une',   model: 'sujet.reference',  value: '', index: 'sujet.reference', datas: null, loading: this.$store.state.repartitioncandidats.loading_repartition, options: { type: 'form', fieldsKey: 'sujet' } },
            { libelle: 'Couleur', defautOptionlibelle: 'Rechercher une',   model: 'sujet.couleur_id',  value: '', index: 'sujet.couleur_id', datas: options_couleurs, loading: this.$store.state.repartitioncandidats.loading_repartition, options: { type: 'deroulant', fieldsKey: 'couleur' } },
            { libelle: 'Filière', defautOptionlibelle: 'Rechercher une',  model: 'sujet.epreuves.concour_id', value: '', index: 'sujet.epreuves.concour_id', datas: options_filieres, loading: this.$store.state.repartitioncandidats.loading_repartition, options: { type: 'deroulant', fieldsKey: 'concour_id' } },
            { libelle: 'Statut de réception', defautOptionlibelle: 'Rechercher un',  model: 'statut_reception', value: '', index: 'statut_reception', datas: options_reception, loading: this.$store.state.repartitioncandidats.loading_repartition, options: { type: 'deroulant', fieldsKey: 'reception' } }
        ]
    }

    load () {
        this.$store.dispatch('centre/getCentre', { centre_id: this.$route.params.centre_id }).then(() => {
            // Chargement des couleurs
            this.$store.dispatch('couleur/getCouleurs')

            this.statut_sujets = this.$store.state.centre.centreSelect.f_suj_statut
            if (this.$store.state.centre.centreSelect.f_suj_submitted_at && this.$props.contexte !== 'gestionnaire') {
                this.read_only = true
            }

            if (this.$store.getters['concour/banques'].length === 0) {
                this.$store.dispatch('concour/getConcoursActifs')
            }

            // Read only selon droit d'accés
            if ((this.$props.contexte === 'gestionnaire' && this.$store.getters['auth/cannot'](Ability.ECR_SUJET_MANAGE)) ||
            (this.$props.contexte !== 'gestionnaire' && this.$store.getters['auth/cannot'](Ability.GCI_FOURNITURES_OWN_MANAGE))) {
                this.read_only = true
            }

            if (this.$props.contexte === 'gestionnaire') {
                this.genericfields = [
                    { key: 'sujet',             label: 'Sujets',            sortable: false,   class: 'max_size_column', type: 'text' },
                    { key: 'couleur',           label: 'Couleur',           sortable: false,   class: '', type: 'text' },
                    { key: 'concour_id',        label: 'Filière',           sortable: false,   class: 'text-center', type: 'text' },
                    { key: 'nb_candidats',      label: 'Nb de candidats',   sortable: false,   class: 'text-center', type: 'text' },
                    { key: 'quantite_commandee', label: 'Quantité commandée', sortable: false,   class: 'input_size text-center', type: 'text' },
                    { key: 'reception',         label: 'Réception',         sortable: false,   class: 'text-center', type: 'text' },
                    { key: 'quantite_recue',    label: 'Quantité reçue',    sortable: false,   class: 'input_size text-center', type: 'text' },
                    { key: 'anomalie',          label: 'Anomalie',          sortable: false,   class: 'text-center', type: 'text' },
                    { key: 'controle',          label: 'Contrôle',          sortable: false,   class: 'text-center', type: 'text' },
                    { key: 'commentaire',       label: 'Commentaire',       sortable: false,   class: 'text-center', type: 'text' }
                ]
            } else {
                this.genericfields = [
                    { key: 'sujet',             label: 'Sujets',            sortable: false,   class: 'max_size_column', type: 'text' },
                    { key: 'couleur',           label: 'Couleur',           sortable: false,   class: '', type: 'text' },
                    { key: 'concour_id',        label: 'Filière',           sortable: false,   class: 'text-center', type: 'text' },
                    { key: 'quantite_attendue', label: 'Quantité attendue', sortable: false,   class: 'input_size text-center', type: 'text' },
                    { key: 'reception',         label: 'Réception',         sortable: false,   class: 'text-center', type: 'text' },
                    { key: 'quantite_recue',    label: 'Quantité reçue',    sortable: false,   class: 'input_size text-center', type: 'text' },
                    { key: 'anomalie',          label: 'Anomalie',          sortable: false,   class: '', type: 'text' }
                ]
            }

            // Chargement des données
            this.$store.dispatch('centresujet/getCentreSujets', { centre_id: this.$route.params.centre_id, params: null }).then(() => {
                this.has_anomalies = this.$store.state.centresujet.centre_sujets.filter((cs: any) => cs.anomalie !== null).length
                this.is_control = this.$store.state.centresujet.centre_sujets.filter((cs: any) => cs.anomalie !== null && cs.statut_controle === 1 && cs.commentaire !== null).length
                const color_maj = get_color_statut(this.statut_sujets, this.has_anomalies, this.is_control)
                this.$emit('maj_statut', color_maj)
                this.setFiltersForGenericTab()
                this.setDataForGenericTab(this.$store.state.centresujet.centre_sujets, false)
            })
        })
    }

    mounted() {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        } else {
            // Renvoi au dashboard pour reselectionné la session
            this.$router.push('/dashboard_i').catch(err => { console.log(err) })
        }

        // Detection si le device est un mobile
        if ('ontouchstart' in document.documentElement)
        {
            this.is_mobile_device = true
        }
        else
        {
            this.is_mobile_device = false
        }
    }
}
