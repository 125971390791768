





import {  Prop, Vue, Component } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import CandidatComments from '@/components/Candidat/CandidatComments.vue'
import { mapGetters } from 'vuex'
import { Ability } from '@/types/Ability'
/**
 * Composant relatif à la gestion des commentaires pour un candidat donné
 */
@Component({
    computed: {
        ...mapGetters('candidat', ['editedCandidat', 'selectedCandidat']),
        ...mapGetters('auth', [
            'authUser',
            'can',
            'cannot',
            'isA',
            'isNotA'
        ])
    },
    components: {
        CandidatComments,
        'font-awesome-icon': FontAwesomeIcon
    }
})
export default class EditCommentFromDashboard extends Vue {
    @Prop() showEdit?: boolean;
    Ability = Ability
}
