







































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { base64ToArrayBuffer, formatDate, getFileNameFromHeader, format_phone_number } from '@/utils/helpers'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import Back from '@/components/Tools/Back.vue'
import { FournitureAdministrativeInterface } from '@/types/FournitureAdministrative'

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('matiere', ['getMatiereById']),
        ...mapState('centre', ['centreSelect']),
        ...mapState('document', ['error', 'loading'])
    },
    components: {
        ExaGenericTable,
        ErrorDisplay,
        Back,
        'font-awesome-icon': FontAwesomeIcon
    }
})

export default class PopupInfosEtDocumentsExaminateur extends Vue {
    @Prop() dataExaminateur: any
    @Prop() showPopup?: boolean

    formatDate                                          = formatDate
    Ability                                             = Ability
    sortDirection                                       = 'asc'
    params: any                                         = {}
    filtres: any                                        = []
    documents: Array<FournitureAdministrativeInterface> = []
    formatPhoneNumber                                   = format_phone_number
    dataForTab: Array<any>                              = []
    genericfields: Array<any>                           = [
        { key: 'name_document', label: 'Libellé', sortable: true, class: '', type: 'text' },
        { key: 'commentaire_document', label: 'Commentaire', sortable: false, class: '', type: 'text' },
        { key: 'created_at', label: 'Date de dépôt', sortable: true,   class: '', type: 'text' },
        { key: 'download', label: '', sortable: false, class: '', type: 'action' }
    ]

    @Watch('user_session_id')
    refreshInterface(): void {
        this.load()
    }

    // Création des lignes du tableau
    setDataForGenericTab(poData: any, isLoadMore = false): void {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const result of poData) {
                this.dataForTab.push([
                    { label: 'Libelle', item: result.name_document,  type: 'text', typeAction: null, class: '' },
                    { label: 'Commentaire', item: result.commentaire_document,  type: 'text', typeAction: null, class: '' },
                    { label: 'Date de dépôt', item: formatDate(result.created_at),  type: 'text', typeAction: null, class: '' },
                    { label: 'Télécharger',   item: result, type: 'action',  typeAction: 'download',  class: 'text-primary', icon:'arrow-alt-to-bottom', disabled: false }
                ])
            }
        }
    }

    setFiltersForGenericTab(): void {
        this.filtres = [
            {
                libelle: 'Libellé',
                defautOptionlibelle: 'Rechercher un',
                model: 'name_document',
                value: '',
                index: 'name_document',
                datas: null,
                loading: false,
                options: { type: 'form', fieldsKey: 'name_document' }
            },
            {
                libelle: 'Commentaire',
                defautOptionlibelle: 'Rechercher un',
                model: 'commentaire_document',
                value: '',
                index: 'commentaire_document',
                datas: null,
                loading: false,
                options: { type: 'form', fieldsKey: 'commentaire_document' }
            }
        ]
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'sortHandler':
                    Vue.set(paParams[1], 'user_id', this.dataExaminateur.examinateur.id)
                    this.filtreSortHandler(paParams[1])
                    break

                case 'filterHandler':
                    Vue.set(paParams[1], 'user_id', this.dataExaminateur.examinateur.id)
                    this.filtreSortHandler(paParams[1])
                    break

                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break

                case 'download':
                    this.downloadFile(paParams[1])
                    break
            }
        }
    }

    // Applique le chargement de la pagination
    loadHandler(params: any): void {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.$store.dispatch('document/getDocumentsExaminateur', { user_id: this.dataExaminateur.examinateur.id, params: this.params })
                .then((response) => {
                    this.documents  = response.data.data
                    this.setDataForGenericTab(this.documents)
                })
                .catch((error: any) => {
                    console.log('error : '  + error)
                })
        }
    }

    // Applique les filtres
    filtreSortHandler(params: any): void {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.$store.dispatch('document/getDocumentsExaminateur', { user_id: this.dataExaminateur.examinateur.id, params: this.params })
                .then((response) => {
                    this.documents  = response.data.data
                    this.setDataForGenericTab(this.documents)
                })
                .catch((error: any) => {
                    console.log('error : '  + error)
                })
        }
    }


    // ---------------

    closePopInfoExaminateur(): void {
        this.$emit('close')
    }


    // ----- telecharger un document----------

    downloadFile(file: any): void {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Téléchargement en cours...', infosToaster)

        this.$store.dispatch('document/getDocumentExaminateur',  { user_id: file.user_id, document_id: file.id })
            .then(response => {
                const link = document.createElement('a')
                link.href = URL.createObjectURL(new Blob([base64ToArrayBuffer(response.data)]))
                link.setAttribute(
                    'Download',
                    getFileNameFromHeader(response.headers) || 'document_' + file.name_document + '_' + file.id + '.pdf'
                )
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    @Watch('showPopup')
    load(): void {
        this.dataForTab = []
        if (this.showPopup && this.dataExaminateur?.examinateur?.id) {
            this.loadMatieresIfNotExists().then(() => {
                this.params = {
                    page: 1,
                    sort: 'name_document',
                    direction: 'asc',
                    user_id: this.dataExaminateur.examinateur.id
                }
                this.$store.dispatch('document/getDocumentsExaminateur', { user_id: this.dataExaminateur.examinateur.id, params: this.params })
                    .then((response) => {
                        this.setFiltersForGenericTab()
                        this.documents = response.data.data
                        this.setDataForGenericTab(this.documents)
                    })
                    .catch((error: any) => {
                        console.log('error : '  + error)
                    })
            })
        }
    }

    loadMatieresIfNotExists(): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.$store.getters['matiere/matieres'].length === 0) {
                this.$store.dispatch('matiere/getMatieres')
                    .then(() => resolve(true))
                    .catch((error: any) => console.log('error : '  + error))
            } else {
                resolve(true)
            }
        })
    }
}
