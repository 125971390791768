




































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { Ability } from '@/types/Ability'
import { formatDate, isObject, totalFormatToHourMinutesHumanizer, dateHumanizerDisplay, formatToHourMinutesFromAny, formatToHourMinutes, formatDayHourZDateFromString, dateDuJour } from '@/utils/helpers'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import { BButton } from 'bootstrap-vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'


@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapState('gabaritFicheHoraire', ['gabarit', 'errorGabaritFicheHoraireJournees', 'errorGabaritFicheHoraireCategories', 'loadingGabaritFicheHoraire']),
        ...mapGetters('gabaritFicheHoraire', ['get_total_journee', 'get_total_fiche_horaire', 'get_minutes_from_hours']),
        ...mapState('session', ['error']),
        ...mapGetters('auth', ['can(Ability.GCI_FICHES_HORAIRES_OWN_MANAGE)'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        'vue-timepicker': VueTimepicker,
        'b-button': BButton,
        ErrorDisplay
    }
})
export default class FicheHoraire extends Vue {
    @Prop() modeSaisie?: boolean
    @Prop() ficheHoraire?: any
    @Prop() open?: boolean

    Ability = Ability
    localGabaritJournees: Array<any> = []
    localGabaritCategories: Array<any> = []
    localAppliquerFicheATous = false
    journees_temps: Array<any> = []
    total_journee = null

    MessageErreurSimple = 'Enregistrement impossible :'

    @Watch('open')
    setTotal () {
        if (this.$props.open) {
            this.initDatas()
        }
    }

    @Watch('gabarit', { deep: true, immediate: true })
    updateGabarit () {
        if (this.$store.state.gabaritFicheHoraire.gabarit.journees) {
            this.localGabaritJournees = this.$store.state.gabaritFicheHoraire.gabarit.journees // ici on garde le lien directement avec le state car on assigne d'objet à objet
        }
        if (this.$store.state.gabaritFicheHoraire.gabarit.autres) {
            this.localGabaritCategories = this.$store.state.gabaritFicheHoraire.gabarit.autres // ici on garde le lien directement avec le state car on assigne d'objet à objet
        }

        this.total_journee = this.$store.getters['gabaritFicheHoraire/get_total_fiche_horaire'](this.$store.state.gabaritFicheHoraire.gabarit)
    }

    @Watch('error')
    updateError () {
        this.isObject()
    }

    /** Initialisation des datas */
    initDatas () {
        this.$store.commit('gabaritFicheHoraire/RESET_STATE')
        this.$store.commit('session/SET_ERROR', null)
        this.$store.commit('gabaritFicheHoraire/SET_LOADING_GABARIT_FICHE_HORAIRE', true)
        if (this.$props.modeSaisie) {
            if (this.$props.ficheHoraire) {
                // recuperation du gabarit sur le centre_user_poste
                this.$store.commit('gabaritFicheHoraire/SET_GABARIT_FICHE_HORAIRE', this.$props.ficheHoraire)
                this.$store.commit('gabaritFicheHoraire/SET_LOADING_GABARIT_FICHE_HORAIRE', false)
            } else {
                this.setGabaritFromSession()
            }
        }
        else {
            this.setGabaritFromSession()
        }
    }

    /** Initialisation du gabarit */
    setGabaritFromSession () {
        if (this.$store.state.session && this.$store.state.session.sessionSelect && this.$store.state.session.sessionSelect.fiche_horaire) {
            this.$store.commit('gabaritFicheHoraire/SET_GABARIT_FICHE_HORAIRE', this.$store.state.session.sessionSelect.fiche_horaire)
        } else {
            const gabarit_vierge = { journees: [], autres: [] }
            this.$store.commit('gabaritFicheHoraire/SET_GABARIT_FICHE_HORAIRE', gabarit_vierge)
        }
        this.$store.commit('gabaritFicheHoraire/SET_LOADING_GABARIT_FICHE_HORAIRE', false)
    }

    /** reformatte une valeur en minutes vers un affichage en HH h mm */
    localTotalFormatToHourMinutesHumanizer (value: number) {
        return totalFormatToHourMinutesHumanizer(value)
    }

    /** reformatte une date en affichage humain jour mois année */
    localDateHumanizerDisplay (value: string) {
        return dateHumanizerDisplay(value)
    }

    /** Check le type du retour d'erreur */
    isObject () {
        return isObject(this.$store.state.salle.error)
    }

    /** Récuperation du champs */
    updateChamps (e: any) {
        // Update les champs des dates
        if (e.target.name.includes('date_')) {
            const datas = e.target.name.split('_')
            this.localGabaritJournees[datas[1]].date = e.target.value
            this.$store.commit('gabaritFicheHoraire/UPDATE_JOURNEES_GABARIT', this.localGabaritJournees)

            if (e.target.value && e.target.value.toString() !== 'Invalid date') {
                for (const p in this.localGabaritJournees[datas[1]].plages) {
                    if (this.localGabaritJournees[datas[1]].plages[p].debut) {
                        const plage_d = this.localGabaritJournees[datas[1]].plages[p].debut.split('T')
                        if (plage_d[0].toString() !== 'Invalid date') {
                            const dateToFormat_d: string = e.target.value + ' ' + plage_d[1]
                            this.localGabaritJournees[datas[1]].plages[p].debut = formatDayHourZDateFromString(dateToFormat_d)
                        }

                        const plage_f = this.localGabaritJournees[datas[1]].plages[p].fin.split('T')
                        if (plage_f[0].toString() !== 'Invalid date') {
                            const dateToFormat_f: string = e.target.value + ' ' + plage_f[1]
                            this.localGabaritJournees[datas[1]].plages[p].fin = formatDayHourZDateFromString(dateToFormat_f)
                        }
                    }
                }

                this.$store.commit('gabaritFicheHoraire/UPDATE_JOURNEES_GABARIT', this.localGabaritJournees)
            }
        }

        // Update les noms des catégories
        if (e.target.name.includes('categorie_name')) {
            const datas = e.target.name.split('_')
            this.localGabaritCategories[datas[2]].name = e.target.value
            this.$store.commit('gabaritFicheHoraire/UPDATE_AUTRES_GABARIT', this.localGabaritCategories)
        }
    }

    /** Update les horaires dans la collection */
    updatePlageHoraire (e: any, name: string) {
        // Update les champs des plages horaires
        if (name.includes('journee_')) {
            const datas: any = name.split('_')
            const dateToFormat: string = this.localGabaritJournees[datas[1]].date + ' ' + e.displayTime + ':00'

            if (datas[3] === 'debut') {
                this.localGabaritJournees[datas[1]].plages[datas[4]].debut = formatDayHourZDateFromString(dateToFormat)
            } else {
                this.localGabaritJournees[datas[1]].plages[datas[4]].fin = formatDayHourZDateFromString(dateToFormat)
            }
            this.$store.commit('gabaritFicheHoraire/UPDATE_JOURNEES_GABARIT', this.localGabaritJournees)
        }
    }

    /** Update les temps des catégories dans la collection */
    updateTempsCategorie (e: any, name: string) {
        // Update les quantités des catégories
        const datas: any = name.split('_')
        const date_hours = dateDuJour() + ' ' + e.displayTime + ':00'
        this.localGabaritCategories[datas[2]].total = this.$store.getters['gabaritFicheHoraire/get_minutes_from_hours'](date_hours)
        this.$store.commit('gabaritFicheHoraire/UPDATE_AUTRES_GABARIT', this.localGabaritCategories)
        this.total_journee = this.$store.getters['gabaritFicheHoraire/get_total_fiche_horaire'](this.$store.state.gabaritFicheHoraire.gabarit)
    }

    /** Coche ou décoche la notion d'appliquer à tous les postes */
    changeAppliquerATous () {
        this.$store.commit('gabaritFicheHoraire/SET_APPLIQUER_A_TOUS', this.localAppliquerFicheATous)
    }

    /** Désactive une plage horaire */
    confirmNoWork (indexJ: number, indexP: number) {
        this.journees_temps.push({ j: indexJ, p: indexP, debut: this.localGabaritJournees[indexJ].plages[indexP].debut, fin: this.localGabaritJournees[indexJ].plages[indexP].fin })
        this.localGabaritJournees[indexJ].plages[indexP].debut = null
        this.localGabaritJournees[indexJ].plages[indexP].fin = null
    }

    /** Réactive une plage horaire */
    confirmWork (indexJ: number, indexP: number) {
        const journeeTemp = this.journees_temps.find((j: any) => j.j === indexJ && j.p === indexP)

        if (journeeTemp) {
            this.localGabaritJournees[indexJ].plages[indexP].debut = journeeTemp.debut
            this.localGabaritJournees[indexJ].plages[indexP].fin = journeeTemp.fin
        } else {
            this.localGabaritJournees[indexJ].plages[indexP].debut = ''
            this.localGabaritJournees[indexJ].plages[indexP].fin = ''
        }
    }

    /** Ajoute une ligne de journee vide dans la collection */
    addJournee () {
        const new_journee: any = {
            date: dateDuJour(),
            plages: [
                { debut: '', fin: '' },
                { debut: '', fin: '' }
            ]
        }

        this.localGabaritJournees.push(new_journee)
        this.$store.commit('gabaritFicheHoraire/UPDATE_JOURNEES_GABARIT', this.localGabaritJournees)
    }

    /** Ajoute une ligne de categorie vide dans la collection */
    addCategorie () {
        const new_categorie: any = {
            name: ''
        }
        this.localGabaritCategories.push(new_categorie)
        this.$store.commit('gabaritFicheHoraire/UPDATE_AUTRES_GABARIT', this.localGabaritCategories)
    }

    /** Supprime une ligne de la collection des journées */
    deleteLigneJournee (index: number) {
        this.localGabaritJournees.splice(index, 1)
        this.$store.commit('gabaritFicheHoraire/UPDATE_JOURNEES_GABARIT', this.localGabaritJournees)
    }

    /** Supprime une ligne de la collection des categories */
    deleteLigneCategorie (index: number) {
        this.localGabaritCategories.splice(index, 1)
        this.$store.commit('gabaritFicheHoraire/UPDATE_AUTRES_GABARIT', this.localGabaritCategories)
    }

    /** Formattage les dates */
    formatteDate (date: Date) {
        return formatDate(date)
    }

    /** Formattage des heures sur les plages */
    formatToHourMinutes (plage: any) {
        return formatToHourMinutes(plage)
    }

    /** Formattage des heures sur les plages */
    formatInputPlageToHours (plage: any) {
        return formatToHourMinutesFromAny(plage)
    }

    mounted () {
        this.initDatas()
    }
}

