export interface EpreuveCorrectionResultatInterface {
    name: string,
    code: string,
    id: number | 0
}

export interface StateEpreuveCorrectionResultats {
    epreuveCorrectionResultats: Array<EpreuveCorrectionResultatInterface> | [],
    meta: Array<string> | null,
    loading: boolean | false,
    error: Array<string> | null,
    epreuveCorrectionResultatSelect: EpreuveCorrectionResultatInterface | {},
    notes: any | null,
    notes_globales: any | null,
    epreuveCorrectionCorrecteurs: Array<any> | null
}


export enum ScopeAjustement {
    EPREUVE = 'epreuve',
    BATCH_CORRECTION = 'batch-correction',
    CORRECTOR_GROUP = 'corrector-group'
}

export enum TargetAjustement {
    TARGET_GLOBAL = 'global',
    TARGET_BATCH = 'batch'
}

export enum TypeMethodArrondi {
    LEPLUSPROCHE = 'lePlusProche',
    SUPERIEUR = 'superieur',
    INFERIEUR = 'inferieur',
}

interface TypeMethodArrondiSpec {
    index: string,
    libelle: string
}

export function getTypeMethodArrondi(etat: TypeMethodArrondi | string): TypeMethodArrondiSpec {
    switch (etat) {
        case TypeMethodArrondi.LEPLUSPROCHE:
        case '':
            return { index: '', libelle: 'Au plus près' }
        case TypeMethodArrondi.INFERIEUR:
        case '-':
            return { index: '-', libelle: 'Inférieur' }
        case TypeMethodArrondi.SUPERIEUR:
        case '+':
            return { index: '+', libelle: 'Supérieur' }
        default:
            return { index: '', libelle: 'Au plus près' }
    }
}

export function getListeValueArrondi() {
    return ['1', '0.5', '0.25', '0.2', '0.125', '0.1', '0.05', '0.025', '0.01', '0.001']
}

export enum TypeArrondi {
    ARRONDI_POINTSUPERIEUR = '+1',
    ARRONDI_POINTINFERIEUR = '-1',
    ARRONDI_POINTLEPLUSPROCHE = '1',
    ARRONDI_DEMIPOINTSUPERIEUR = '+0.5',
    ARRONDI_DEMIPOINTINFERIEUR = '-0.5',
    ARRONDI_DEMIPOINTLEPLUSPROCHE = '0.5',
    ARRONDI_QUARTPOINTSUPERIEUR = '+0.25',
    ARRONDI_QUARTPOINTINFERIEUR = '-0.25',
    ARRONDI_QUARTPOINTLEPLUSPROCHE = '0.25',
    ARRONDI_VINGTIEMESUPERIEUR = '+0.2',
    ARRONDI_VINGTIEMEINFERIEUR = '-0.2',
    ARRONDI_VINGTIEMELEPLUSPROCHE = '0.2',
    ARRONDI_125MILLIEMESUPERIEUR = '+0.125',
    ARRONDI_125MILLIEMEINFERIEUR = '-0.125',
    ARRONDI_125MILLIEMELEPLUSPROCHE = '0.125',
    ARRONDI_DIXIEMESUPERIEUR = '+0.1',
    ARRONDI_DIXIEMEINFERIEUR = '-0.1',
    ARRONDI_DIXIEMELEPLUSPROCHE = '0.1',
    ARRONDI_5DIXIEMESUPERIEUR = '+0.05',
    ARRONDI_5DIXIEMEINFERIEUR = '-0.05',
    ARRONDI_5DIXIEMELEPLUSPROCHE = '0.05',
    ARRONDI_25MILLIEMESUPERIEUR = '+0.025',
    ARRONDI_25MILLIEMEINFERIEUR = '-0.025',
    ARRONDI_25MILLIEMELEPLUSPROCHE = '0.025',
    ARRONDI_CENTIEMESUPERIEUR = '+0.01',
    ARRONDI_CENTIEMEINFERIEUR = '-0.01',
    ARRONDI_CENTIEMELEPLUSPROCHE = '0.01',
    ARRONDI_MILLIEMESUPERIEUR = '+0.001',
    ARRONDI_MILLIEMEINFERIEUR = '-0.001',
    ARRONDI_MILLIEMELEPLUSPROCHE = '0.001',
}

interface TypeArrondiSpec {
    index: string,
    libelle: string,
    method: TypeMethodArrondi,
    val: string
}

export function getTypeArrondi(etat: TypeArrondi): TypeArrondiSpec {
    switch (etat) {
        case TypeArrondi.ARRONDI_POINTSUPERIEUR:
            return { index: '+1', libelle: '+1', method: TypeMethodArrondi.SUPERIEUR, val: '1' }
        case TypeArrondi.ARRONDI_POINTINFERIEUR:
            return { index: '-1', libelle: '-1', method: TypeMethodArrondi.INFERIEUR, val: '1' }
        case TypeArrondi.ARRONDI_POINTLEPLUSPROCHE:
            return { index: '1', libelle: '1', method: TypeMethodArrondi.LEPLUSPROCHE, val: '1' }
        case TypeArrondi.ARRONDI_DEMIPOINTSUPERIEUR:
            return { index: '+0.5', libelle: '+0.5', method: TypeMethodArrondi.SUPERIEUR, val: '0.5' }
        case TypeArrondi.ARRONDI_DEMIPOINTINFERIEUR:
            return { index: '-0.5', libelle: '-0.5', method: TypeMethodArrondi.INFERIEUR, val: '0.5' }
        case TypeArrondi.ARRONDI_DEMIPOINTLEPLUSPROCHE:
            return { index: '0.5', libelle: '0.5', method: TypeMethodArrondi.LEPLUSPROCHE, val: '0.5' }
        case TypeArrondi.ARRONDI_QUARTPOINTSUPERIEUR:
            return { index: '+0.25', libelle: '+0.25', method: TypeMethodArrondi.SUPERIEUR, val: '0.25' }
        case TypeArrondi.ARRONDI_QUARTPOINTINFERIEUR:
            return { index: '-0.25', libelle: '-0.25', method: TypeMethodArrondi.INFERIEUR, val: '0.25' }
        case TypeArrondi.ARRONDI_QUARTPOINTLEPLUSPROCHE:
            return { index: '0.25', libelle: '0.25', method: TypeMethodArrondi.LEPLUSPROCHE, val: '0.25' }
        case TypeArrondi.ARRONDI_VINGTIEMESUPERIEUR:
            return { index: '+0.2', libelle: '+0.2', method: TypeMethodArrondi.SUPERIEUR, val: '0.2' }
        case TypeArrondi.ARRONDI_VINGTIEMEINFERIEUR:
            return { index: '-0.2', libelle: '-0.2', method: TypeMethodArrondi.INFERIEUR, val: '0.2' }
        case TypeArrondi.ARRONDI_VINGTIEMELEPLUSPROCHE:
            return { index: '0.2', libelle: '0.2', method: TypeMethodArrondi.LEPLUSPROCHE, val: '0.2' }
        case TypeArrondi.ARRONDI_125MILLIEMESUPERIEUR:
            return { index: '+0.125', libelle: '+0.125', method: TypeMethodArrondi.SUPERIEUR, val: '0.125' }
        case TypeArrondi.ARRONDI_125MILLIEMEINFERIEUR:
            return { index: '-0.125', libelle: '-0.125', method: TypeMethodArrondi.INFERIEUR, val: '0.125' }
        case TypeArrondi.ARRONDI_125MILLIEMELEPLUSPROCHE:
            return { index: '0.125', libelle: '0.125', method: TypeMethodArrondi.LEPLUSPROCHE, val: '0.125' }
        case TypeArrondi.ARRONDI_DIXIEMESUPERIEUR:
            return { index: '+0.1', libelle: '+0.1', method: TypeMethodArrondi.SUPERIEUR, val: '0.1' }
        case TypeArrondi.ARRONDI_DIXIEMEINFERIEUR:
            return { index: '-0.1', libelle: '-0.1', method: TypeMethodArrondi.INFERIEUR, val: '0.1' }
        case TypeArrondi.ARRONDI_DIXIEMELEPLUSPROCHE:
            return { index: '0.1', libelle: '0.1', method: TypeMethodArrondi.LEPLUSPROCHE, val: '0.1' }
        case TypeArrondi.ARRONDI_5DIXIEMESUPERIEUR:
            return { index: '+0.05', libelle: '+0.05', method: TypeMethodArrondi.SUPERIEUR, val: '0.05' }
        case TypeArrondi.ARRONDI_5DIXIEMEINFERIEUR:
            return { index: '-0.05', libelle: '-0.05', method: TypeMethodArrondi.INFERIEUR, val: '0.05' }
        case TypeArrondi.ARRONDI_5DIXIEMELEPLUSPROCHE:
            return { index: '0.05', libelle: '0.05', method: TypeMethodArrondi.LEPLUSPROCHE, val: '0.05' }
        case TypeArrondi.ARRONDI_25MILLIEMESUPERIEUR:
            return { index: '+0.025', libelle: '+0.025', method: TypeMethodArrondi.SUPERIEUR, val: '0.025' }
        case TypeArrondi.ARRONDI_25MILLIEMEINFERIEUR:
            return { index: '-0.025', libelle: '-0.025', method: TypeMethodArrondi.INFERIEUR, val: '0.025' }
        case TypeArrondi.ARRONDI_25MILLIEMELEPLUSPROCHE:
            return { index: '0.025', libelle: '0.025', method: TypeMethodArrondi.LEPLUSPROCHE, val: '0.025' }
        case TypeArrondi.ARRONDI_CENTIEMESUPERIEUR:
            return { index: '+0.01', libelle: '+0.01', method: TypeMethodArrondi.SUPERIEUR, val: '0.01' }
        case TypeArrondi.ARRONDI_CENTIEMEINFERIEUR:
            return { index: '-0.01', libelle: '-0.01', method: TypeMethodArrondi.INFERIEUR, val: '0.01' }
        case TypeArrondi.ARRONDI_CENTIEMELEPLUSPROCHE:
            return { index: '0.01', libelle: '0.01', method: TypeMethodArrondi.LEPLUSPROCHE, val: '0.01' }
        case TypeArrondi.ARRONDI_MILLIEMESUPERIEUR:
            return { index: '+0.001', libelle: '+0.001', method: TypeMethodArrondi.SUPERIEUR, val: '0.001' }
        case TypeArrondi.ARRONDI_MILLIEMEINFERIEUR:
            return { index: '-0.001', libelle: '-0.001', method: TypeMethodArrondi.INFERIEUR, val: '0.001' }
        case TypeArrondi.ARRONDI_MILLIEMELEPLUSPROCHE:
            return { index: '0.001', libelle: '0.001', method: TypeMethodArrondi.LEPLUSPROCHE, val: '0.001' }
        default:
            return { index: '+1', libelle: '+1', method: TypeMethodArrondi.SUPERIEUR, val: '1' }
    }
}

export function getArrondiByMethodAndValue(method: TypeMethodArrondi, val: string) {
    for (const item in TypeArrondi) {
        if (method + '' + val === item) {
            return item
        }
    }
}


