



















































































































































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import store from '@/store/index'
import Table from '@/components/Table.vue'
import { Ability } from '@/types/Ability'
import { Datetime } from 'vue-datetime'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import 'vue-datetime/dist/vue-datetime.css'
import { formatDateSinTime, formatDateHoursMinutes, time_convert, convertDateLocalValeurT, convertDateUTCValeurT, getFileNameFromHeader } from '@/utils/helpers'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { TypePassation } from '@/types/Epreuve'
import { getEnumEntityType } from '@/types/FourniturePapetiere'
import { TypeSujet, FormatSujet, getTypeSujet, getFormatSujet, SujetInterface } from '@/types/Sujet'
import { isNull } from 'lodash'
import { EnveloppeInterface } from '@/types/Enveloppe'
import { CouleurInterface } from '@/types/Couleur'
import { FournitureInterface } from '@/types/Fourniture'

@Component({
    methods: { isNull, getFormatSujet, getTypeSujet },
    components: {
        Table,
        Datetime,
        ExaGenericTable,
        ErrorDisplay
    },
    computed: {
        TypeSujet() {
            return TypeSujet
        },
        ...mapGetters('couleur', ['couleurs', 'getCouleurById']),
        ...mapGetters('epreuve', ['epreuves', 'getEpreuveById']),
        ...mapGetters('sujet', ['sujets', 'getSujetById']),
        ...mapGetters('concour', ['loading', 'error', 'banques', 'meta', 'links', 'totalRows', 'concourById']),
        ...mapGetters('tempsSupplementaire', ['loading', 'error', 'tempsSupplementaires', 'meta', 'links', 'totalRows']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        liste_type_sujet() {
            const middle = Math.floor(Object.keys(TypeSujet).length / 2)
            return Object.keys(TypeSujet).slice(0, middle)
        },
        liste_format_sujet() {
            const middle = Math.floor(Object.keys(FormatSujet).length / 2)
            return Object.keys(FormatSujet).slice(0, middle)
        }
    }
})
export default class CalendrierEcrit extends Vue {
    session_id: any = 0
    currentSession: any = null
    getEnumEntityType = getEnumEntityType

    params = 'sort=name&direction=asc'

    // DATAS
    dataForTab: Array<any> = []
    filtres: any = []

    genericfields = [
        { key: 'etatEdit', label: '', sortable: false, class: '', type: 'action' },
        { key: 'concour_id', label: 'Filière', sortable: true, class: 'text-center', type: 'text' },
        { key: 'name', label: 'Épreuve', sortable: true, sortDirection: 'asc', class: 'text-left', type: 'text' },
        { key: 'sujet.name', label: 'Sujet', sortable: true, sortDirection: 'asc', class: 'text-left', type: 'text' },
        { key: 'passation_at', label: 'Date de passation', sortable: true, sortDirection: 'asc', class: 'text-center', type: 'text' },
        { key: 'debut', label: 'Début', sortable: true, sortDirection: 'asc', class: 'text-center background_td', type: 'text' },
        { key: 'duree', label: 'Durée', sortable: true, sortDirection: 'asc', class: 'text-center background_td', type: 'text' }
    ]

    sortBy = ''
    sortDesc = false
    sortDirection = 'asc'
    filter = ''
    filterOn = []
    stickyHeader = true
    epreu: any = null
    epreuveTemp: any = null
    Ability = Ability

    showModalEditionEcrit = false
    showModalMessageDelete = false
    options_filieres: Array<any> = []
    firstLoading = false

    showModalGestionTempsSup = false

    showConfirmDeleteTs = false

    ts_select = {
        id: 0,
        code: '',
        name: '',
        numerateur: 1,
        denominateur: 1

    }

    showFormulaireTs = false
    save_in_progress = false


    // METHODS
    // ---- generic table --------------------------

    /**
     * setDataForGenericTab
     * @description Formatage des datas pour l'affichage dans le tableau générique
     * @param {any} poData - Données à formater
     * @param {boolean} isLoadMore - Indique si on charge plus de données
     * @return {Array<any>} - Données formatées
     */
    setDataForGenericTab(poData: any, isLoadMore = false): Array<any> {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const result of poData) {
                const concourTemp = store.getters['concour/concourById'](result.concour_id)
                const concourLibelle =  concourTemp ? concourTemp.name : '-'
                const classEtatEdit = 'text-light col-w-etat ' + (!result.closed_at ? 'btn_action_ligne' : 'bg-secondary')
                const iconEtatEdit = !result.closed_at ? this.canEdit() ? 'pen' : 'eye' : 'lock'

                const datePassation = formatDateSinTime(result.passation_at)
                const debut = formatDateHoursMinutes(result.passation_at)
                const duree = time_convert(result.duree / 60)

                const line = [
                    { label: '', item: result, icon: iconEtatEdit, type: 'action', typeAction: 'etatEdit', class: classEtatEdit, disabled: false },
                    { label: '', item: concourLibelle, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: result.long_name, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.sujet ? result.sujet.name : '-', type: 'text', typeAction: null, class: '' },
                    { label: '', item: datePassation, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: debut, type: 'text', typeAction: null, class: 'background_td text-center' },
                    { label: '', item: duree, type: 'text', typeAction: null, class: 'background_td text-center' }
                ]
                this.dataForTab.push(line)
            }
        }
        return this.dataForTab
    }

    /**
     * setFiltersForGenericTab
     * @description Formatage des datas pour l'affichage dans le tableau générique
     * @return {void}
     */
    setFiltersForGenericTab(): void {
        const filieres = this.$store.getters['concour/banques']
        this.options_filieres = []

        for (const f in filieres) {
            this.options_filieres.push({ index: filieres[f].id, name: filieres[f].name })
        }
        this.filtres = [
            {
                libelle: 'Filière',
                defautOptionlibelle: 'Rechercher une',
                model: 'concour_id',
                value: '-',
                index: 'id',
                datas: this.options_filieres,
                loading: false,
                options: { type: 'deroulant', fieldsKey: 'concour_id' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Epreuve',
                defautOptionlibelle: 'Rechercher une',
                model: 'name',
                value: '',
                index: 'name',
                datas: null, // this.$store.getters['user/users'].name,
                loading: false,
                options: { type: 'form', fieldsKey: 'name' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Sujet',
                defautOptionlibelle: 'Rechercher un',
                model: 'sujet.name',
                value: '',
                index: 'sujet.name',
                datas: null, // this.$store.getters['user/users'].name,
                loading: false,
                options: { type: 'form', fieldsKey: 'sujet.name' } // 'form' , 'deroulant'
            }
        ]
    }

    /**
     * handleTableEvent
     * @description Récupération des events de la table
     * @param {any} paParams - Paramètres de l'event
     * @return {void}
     */
    handleTableEvent (paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'edit':
                    break
                case 'openComment':
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'etatEdit':
                    this.editEpreuve(paParams[1])
                    break
                default:
                    break
            }
        }
    }

    // --------------------------------------
    // ------------------Paramétrage des fournitures papetières--------------------------------

    showParamFournituresPapetieres = false
    listeParamFournituresPapetieres: Array<any> = []
    fournitureTemp: any = null
    fournitureDelete: any = null
    modeEditParamFournituresPapetieres = false
    showModalMessageDeleteFournituresPapetieres = false

    /**
     * openParamFournituresPapetieres
     * @description Ouvre après chargment la fenêtre de paramétrage des fournitures papetières
     * @return {void}
     */
    openParamFournituresPapetieres(): void {
        this.$store.dispatch('fourniture/getFournitures').then((response) => {
            this.listeParamFournituresPapetieres = JSON.parse(JSON.stringify(response.data.data))
            this.showParamFournituresPapetieres = true
        }).catch((error) => {
            console.log('ko:' + error)
        })
    }

    /**
     * cancelFournituresPapetieres
     * @description Ferme la fenêtre de paramétrage des fournitures papetières
     * @return {void}
     */
    cancelFournituresPapetieres(): void {
        this.$store.commit('fourniture/SET_ERROR', null)
        this.showParamFournituresPapetieres = false
    }

    /**
     * editParamFournituresPapetieres
     * @description Ouvre la fenêtre d'édition d'une fourniture papetière
     * @param {FournitureInterface} fourniture - Fourniture à éditer
     * @return {void}
     */
    editParamFournituresPapetieres(fourniture: FournitureInterface): void {
        this.$store.commit('fourniture/SET_ERROR', null)
        this.fournitureTemp = JSON.parse(JSON.stringify(fourniture))
        this.modeEditParamFournituresPapetieres = true
    }

    /**
     * addParamFournituresPapetieres
     * @description Ouvre la fenêtre d'ajout d'une fourniture papetière
     * @return {void}
     */
    addParamFournituresPapetieres(): void {
        this.$store.commit('fourniture/SET_ERROR', null)
        this.fournitureTemp = {
            id: 0,
            session_id: this.session_id,
            code: '',
            name: '',
            unite_oeuvre: '',
            nb_par_entity: 1,
            entity_type: 'null',
            nb_par_unite_oeuvre: 1,
            inventaire_centre: true,
            poids: 0
        }
        this.modeEditParamFournituresPapetieres = true
    }

    /**
     * annulerEditParamFournituresPapetieres
     * @description Ferme l'espace d'ajout/edition d'une fourniture
     * @return {void}
     */
    annulerEditParamFournituresPapetieres(): void {
        this.$store.commit('fourniture/SET_ERROR', null)
        this.fournitureTemp = null
        this.modeEditParamFournituresPapetieres = false
    }

    /**
     * saveEditParamFournituresPapetieres
     * @description Enregistre les informations pour une modification / ajout d'une fourniture
     * @return {void}
     */
    saveEditParamFournituresPapetieres(): void {
        this.save_in_progress = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours...', infosToaster)

        const data = {
            code: this.fournitureTemp.code,
            session_id: this.fournitureTemp.session_id,
            name: this.fournitureTemp.name,
            unite_oeuvre: this.fournitureTemp.unite_oeuvre,
            nb_par_entity: this.fournitureTemp.nb_par_entity,
            entity_type: this.fournitureTemp.entity_type,
            nb_par_unite_oeuvre: this.fournitureTemp.nb_par_unite_oeuvre,
            inventaire_centre: this.fournitureTemp.inventaire_centre,
            poids: this.fournitureTemp.poids
        }

        const requestSuccess = () => {
            this.fournitureTemp = null
            this.save_in_progress = false
            this.modeEditParamFournituresPapetieres = false
            const idSucces = 't_succes_' + Math.random()
            const succesToaster = {
                id: idSucces,
                toaster: 'b-toaster-top-right',
                variant: 'success',
                noCloseButton: true,
                fade: true,
                autoHideDelay: 5000
            }
            this.$bvToast.toast('Enregistrement terminé.', succesToaster)
        }

        const requestError = (error: any) => {
            this.modeEditParamFournituresPapetieres = false
            this.fournitureTemp = null
            this.save_in_progress = false
            console.log('ko:' + error)
        }

        if (this.fournitureTemp.id) {
            this.$store.dispatch('fourniture/updateFourniture', {
                ...data,
                id: this.fournitureTemp.id
            })
                .then((response) => {
                    for (let i = 0;  i < this.listeParamFournituresPapetieres.length; i++) {
                        if (this.listeParamFournituresPapetieres[i].id === response.data.data.id) {
                            this.listeParamFournituresPapetieres[i].code = response.data.data.code
                            this.listeParamFournituresPapetieres[i].session_id = response.data.data.session_id
                            this.listeParamFournituresPapetieres[i].name = response.data.data.name
                            this.listeParamFournituresPapetieres[i].unite_oeuvre = response.data.data.unite_oeuvre
                            this.listeParamFournituresPapetieres[i].nb_par_entity = response.data.data.nb_par_entity
                            this.listeParamFournituresPapetieres[i].entity_type = response.data.data.entity_type
                            this.listeParamFournituresPapetieres[i].nb_par_unite_oeuvre = response.data.data.nb_par_unite_oeuvre
                            this.listeParamFournituresPapetieres[i].inventaire_centre = (response.data.data.inventaire_centre === true ? 1 : 0)
                            this.listeParamFournituresPapetieres[i].poids = response.data.data.poids
                        }
                    }
                    requestSuccess()
                })
                .catch(requestError)
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            this.$store.dispatch('fourniture/addFourniture', data)
                .then((response) => {
                    this.listeParamFournituresPapetieres.push({
                        id: response.data.data.id,
                        code: response.data.data.code,
                        session_id: response.data.data.session_id,
                        name: response.data.data.name,
                        unite_oeuvre: response.data.data.unite_oeuvre,
                        nb_par_entity: this.fournitureTemp.nb_par_entity,
                        entity_type: this.fournitureTemp.entity_type,
                        nb_par_unite_oeuvre: response.data.data.nb_par_unite_oeuvre,
                        inventaire_centre: (response.data.data.inventaire_centre === true ? 1 : 0),
                        poids: response.data.data.poids
                    })
                    requestSuccess()
                })
                .catch(requestError)
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    /**
     * deleteParamFournituresPapetieres
     * @description Ouvre la fenêtre de confirmation de suppression d'une fourniture
     * @param {FournitureInterface} fourniture - Fourniture à supprimer
     * @return {void}
     */
    deleteParamFournituresPapetieres(fourniture: FournitureInterface): void {
        this.$store.commit('fourniture/SET_ERROR', null)
        this.fournitureDelete = JSON.parse(JSON.stringify(fourniture))
        this.showModalMessageDeleteFournituresPapetieres = true
    }

    /**
     * getFournitureError
     * @description Récupère l'erreur de la fourniture
     * @return {any} - Erreur de la fourniture
     */
    getFournitureError(): any {
        return this.$store.getters['fourniture/error']
    }


    /**
     * cancelDeleteParamFournituresPapetieres
     * @description Ferme la fenêtre de confirmation de suppression d'une fourniture
     * @return {void}
     */
    cancelDeleteParamFournituresPapetieres(): void {
        this.fournitureDelete = null
        this.showModalMessageDeleteFournituresPapetieres = false
    }

    /**
     * deleteSuiteParamFournituresPapetieres
     * @description Confirme et envoi au serveur une demande de suppresion de fourniture
     * @return {void}
     */
    deleteSuiteParamFournituresPapetieres(): void {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('suppression en cours...', infosToaster)

        this.$store.dispatch('fourniture/deleteFourniture',  this.fournitureDelete.id)
            .then(() => {
                for (let i = 0;  i < this.listeParamFournituresPapetieres.length; i++) {
                    if (this.fournitureDelete.id === this.listeParamFournituresPapetieres[i].id) {
                        this.listeParamFournituresPapetieres.splice(i, 1)
                    }
                }
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Supprimé avec succès !', succesToaster)
                this.fournitureDelete = null
                this.showModalMessageDeleteFournituresPapetieres = false
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // --------------------------------------
    // ------------------Paramétrage des sujets et des enveloppes--------------------------------
    showParamSujetsEnveloppes = false
    param_sujets_enveloppes_tabSelected = 'sujet'
    listeSujetsOriginal: Array<any> = []

    /**
     * openParamSujetsEnveloppes
     * @description Ouvre après chargment la fenêtre de paramétrage des sujets et des enveloppes
     * @return {void}
     */
    openParamSujetsEnveloppes(): void {
        const  paramsWithTypeFilter = {
            page: 1,
            sort: 'passation_at',
            direction: 'asc',
            'filter-type_passation': TypePassation.TYPE_PASSATION_ECRIT
        }

        // paramsWithTypeFilter['filter-type_passation'] = TypePassation.TYPE_PASSATION_ECRIT
        this.$store.dispatch('epreuve/getEpreuves',  { session_id: this.session_id, params: paramsWithTypeFilter })
            .then(() => {
                // this.setDataForGenericTab(this.$store.getters['epreuve/epreuves'])
                this.$store.dispatch('couleur/getCouleurs').then((response) => {
                    this.listeCouleurs = response.data.data
                    this.$store.dispatch('sujet/getSujets').then((response) => {
                        this.showParamSujetsEnveloppes = true
                        this.listeSujetsOriginal = this.listeSujets = response.data.data
                        this.$store.dispatch('enveloppe/getEnveloppes').then((response) => {
                            this.listeEnveloppes = response.data.data
                        }).catch((error) => {
                            console.log('ko:' + error)
                        })
                    }).catch((error) => {
                        console.log('ko:' + error)
                    })
                }).catch((error) => {
                    console.log('ko:' + error)
                })
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
    }

    /**
     * cancelParamSujetsEnveloppes
     * @description Ferme la fenêtre de paramétrage des sujets et des enveloppes
     * @return {void}
     */
    cancelParamSujetsEnveloppes(): void {
        const paramsWithTypeFilter = JSON.parse(JSON.stringify(this.params))
        this.showParamSujetsEnveloppes = false
        paramsWithTypeFilter['filter-type_passation'] = TypePassation.TYPE_PASSATION_ECRIT
        this.$store.dispatch('epreuve/getEpreuves',  { session_id: this.session_id, params: paramsWithTypeFilter }).then(() => {
            this.setDataForGenericTab(this.$store.getters['epreuve/epreuves'])
        }).catch((error) => {
            console.log('ko:' + error)
        })
    }

    // ----sujets

    listeSujets: Array<SujetInterface> = []
    listeSelectSujets: Array<SujetInterface> = []
    sujetTemp: any = null
    sujetDelete: any = null
    modeEditSujet = false
    showModalMessageDeleteSujet = false
    filiere_param_sujet = null

    /**
     * filiereSujetChangeHandler
     * @description Filtre les sujets par filière
     * @return {void}
     */
    filiereSujetChangeHandler(): void {
        this.listeSujets = []
        if (this.filiere_param_sujet !== 'null') {
            for (let i = 0; i < this.listeSujetsOriginal.length; i++) {
                for (let j  = 0; j < this.listeSujetsOriginal[i].epreuve_ids.length; j++) {
                    if (store.getters['epreuve/getEpreuveById'](this.listeSujetsOriginal[i].epreuve_ids[j]).concour_id === this.filiere_param_sujet) {
                        if (!this.listeSujets.includes(this.listeSujetsOriginal[i])) {
                            this.listeSujets.push(this.listeSujetsOriginal[i])
                        }
                    }
                }
            }
        } else {
            for (let i = 0; i < this.listeSujetsOriginal.length; i++) {
                this.listeSujets.push(this.listeSujetsOriginal[i])
            }
        }
    }

    /**
     * editSujet
     * @description Ouvre la fenêtre d'édition d'un sujet
     * @param {SujetInterface} sujet - Sujet à éditer
     * @return {void}
     */
    editSujet (sujet: SujetInterface): void {
        this.sujetTemp = JSON.parse(JSON.stringify(sujet))
        this.getListeSujets()
        this.modeEditSujet = true
    }

    /**
     * addSujet
     * @description Ouvre la fenêtre d'ajout d'un sujet
     * @return {void}
     */
    addSujet(): void {
        this.sujetTemp = {
            session_id: this.session_id,
            code: '',
            name: '',
            couleur_id: null,
            couleur: null,
            is_impression_couleur: 0,
            nb_pages: 0,
            poids: 0,
            calculatrice_autorise: 0,
            reference: "",
            format: null,
            type: null,
            sujet_id: null,
            sujet: null,
            sujets: [],
            epreuve_ids: [],
            sujet_ids: []
        }
        this.getListeSujets()
        this.modeEditSujet = true
    }

    /**
     * annulerEditSujet
     * @description Ferme l'espace d'ajout/edition d'un sujet
     * @return {void}
     */
    annulerEditSujet(): void {
        this.sujetTemp = null
        this.listeSelectSujets = []
        this.modeEditSujet = false
    }

    /**
     * epreuvesCheckHandler
     * @description Gère la selection / déselection des épreuves d'un sujet
     * @param {number} epreuve_id - id de l'épreuve
     * @return {void}
     */
    epreuvesCheckHandler(epreuve_id: any): void {
        const index = this.sujetTemp.epreuve_ids.findIndex((epreuveId: any) => epreuveId === epreuve_id)
        if (index === -1) {
            this.sujetTemp.epreuve_ids.push(epreuve_id)
        } else {
            this.sujetTemp.epreuve_ids.splice(index, 1)
        }
    }

    /**
     * sujetsCheckHandler
     * @description Gère la selection / déselection des sujets
     * @param {number} sujet_id - id du sujet
     * @return {void}
     */
    sujetsCheckHandler(sujet_id: number): void {
        const index = this.sujetTemp.sujet_ids.findIndex((sujet: any) => sujet === sujet_id)
        if (index === -1) {
            this.sujetTemp.sujet_ids.push(sujet_id)
        } else {
            this.sujetTemp.sujet_ids.splice(index, 1)
        }
    }

    /**
     * enveloppesCheckHandler
     * @description Gère la selection / déselection des enveloppes
     * @param {number} enveloppe_id - id de l'enveloppe
     * @return {void}
     */
    enveloppesCheckHandler(enveloppe_id: number): void {
        const index = this.enveloppeTemp.enveloppe_ids.findIndex((enveloppe: any) => enveloppe === enveloppe_id)
        if (index === -1) {
            this.enveloppeTemp.enveloppe_ids.push(enveloppe_id)
        } else {
            this.enveloppeTemp.enveloppe_ids.splice(index, 1)
        }
    }

    /**
     * checkSujet
     * @description Sélectionne ou non un sujet en fonction des données
     * @param {number} sujet_id - id du sujet
     * @return {boolean} - true si le sujet est sélectionné, false sinon
     */
    checkSujet(sujet_id: number): boolean {
        for (let i = 0; i < this.sujetTemp.sujets.length; i++) {
            if (this.sujetTemp.sujets[i].id === sujet_id) {
                return true
            }
        }
        return false
    }

    /**
     * checkEnveloppe
     * @description Sélectionne ou non une enveloppe en fonction des données
     * @param {number} enveloppe_id - id de l'enveloppe
     * @return {boolean} - true si l'enveloppe est sélectionnée, false sinon
     */
    checkEnveloppe(enveloppe_id: number): boolean {
        for (let i = 0; i < this.enveloppeTemp.enveloppes.length; i++) {
            if (this.enveloppeTemp.enveloppes[i].id === enveloppe_id) {
                return true
            }
        }
        return false
    }

    /**
     * saveEditSujet
     * @description Enregistre les informations pour une modification / ajout d'un sujet
     * @return {void}
     */
    saveEditSujet(): void {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours...', infosToaster)

        const data: any = {
            session_id: this.sujetTemp.session_id,
            code: this.sujetTemp.code,
            reference: this.sujetTemp.reference,
            name: this.sujetTemp.name,
            calculatrice_autorise: this.sujetTemp.calculatrice_autorise,
            couleur_id: this.sujetTemp.couleur_id,
            nb_pages: this.sujetTemp.nb_pages,
            is_impression_couleur: this.sujetTemp.is_impression_couleur,
            poids: this.sujetTemp.poids,
            type: this.sujetTemp.type,
            format: this.sujetTemp.format,
            sujet_ids: this.sujetTemp.type === TypeSujet.TYPE_ENONCE ? this.sujetTemp.sujet_ids : [],
            epreuve_ids: this.sujetTemp.type === TypeSujet.TYPE_ENONCE ? this.sujetTemp.epreuve_ids : []
        }

        const request = (target: string, data: object) => {
            this.$store.dispatch(target, data)
                .then(() => {
                    this.$store.dispatch('sujet/getSujets')
                        .then((response) => {
                            this.showParamSujetsEnveloppes = true
                            this.listeSujetsOriginal = this.listeSujets = response.data.data
                            this.$store.dispatch('enveloppe/getEnveloppes')
                                .then((response) => {
                                    this.filiere_param_sujet = null
                                    this.listeEnveloppes = response.data.data
                                })
                                .catch((error) => {
                                    console.log('ko:' + error)
                                })
                        })
                        .catch((error) => {
                            console.log('ko:' + error)
                        })

                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistré avec succès !', succesToaster)
                    this.sujetTemp = null
                    this.modeEditSujet = false
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }

        if (this.sujetTemp.id) {
            request('sujet/updateSujet', {
                ...data,
                id: this.sujetTemp.id
            })
        } else {
            request('sujet/addSujet', data)
        }
    }

    /**
     * deleteSujet
     * @description Ouvre la fenêtre de confirmation de suppression d'un sujet
     * @param {SujetInterface} sujet - Sujet à supprimer
     * @return {void}
     */
    deleteSujet (sujet: SujetInterface): void {
        this.$store.commit('sujet/SET_ERROR', null)
        this.sujetDelete = JSON.parse(JSON.stringify(sujet))
        this.showModalMessageDeleteSujet = true
    }

    /**
     * getSujetError
     * @description Récupère l'erreur du sujet
     * @return {any} - Erreur du sujet
     */
    getSujetError(): any {
        return this.$store.getters['sujet/error']
    }

    /**
     * cancelDeleteSujet
     * @description Ferme la fenêtre de confirmation de suppression d'un sujet
     * @return {void}
     */
    cancelDeleteSujet(): void {
        this.sujetDelete = null
        this.showModalMessageDeleteSujet = false
    }

    /**
     * deleteSuiteSujet
     * @description Confirme et envoi au serveur une demande de suppresion de sujet
     * @return {void}
     */
    deleteSuiteSujet(): void {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Suppression en cours...', infosToaster)

        this.$store.dispatch('sujet/deleteSujet',  this.sujetDelete.id)
            .then(() => {
                for (let i = 0;  i < this.listeSujets.length; i++) {
                    if (this.sujetDelete.id === this.listeSujets[i].id) {
                        this.listeSujets.splice(i, 1)
                    }
                }
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Suppression terminée.', succesToaster)
                this.sujetDelete = null
                this.showModalMessageDeleteSujet = false
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * getListeSujets
     * @description Retourne la liste des sujets filtrés (sans lui-même et sans les sujets déjà liés)
     * @return {void}
     */
    getListeSujets(): void {
        const sujets = this.$store.getters['sujet/sujets'].filter((sujet: any) => sujet.id !== this.sujetTemp.id && (isNull(sujet.sujet_id) || sujet.sujet_id === this.sujetTemp.id) && sujet.type !== TypeSujet.TYPE_ENONCE)
        this.sujetTemp.sujet_ids = []
        sujets.forEach((sujet: any) => {
            if (this.checkSujet(sujet.id)) {
                this.sujetsCheckHandler(sujet.id)
            }
        })
        this.listeSelectSujets = sujets
    }

    /**
     * getListeEnveloppes
     * @description Retourne la liste des enveloppes filtrées (sans elle-même et sans les enveloppes déjà liées)
     * @return {void}
     */
    getListeEnveloppes(): void {
        const enveloppes = this.$store.getters['enveloppe/enveloppes'].filter((enveloppe: any) => enveloppe.id !== this.enveloppeTemp.id && (isNull(enveloppe.enveloppe_id) || enveloppe.enveloppe_id === this.enveloppeTemp.id))
        this.enveloppeTemp.enveloppe_ids = []
        enveloppes.forEach((enveloppe: any) => {
            if (this.checkEnveloppe(enveloppe.id)) {
                this.enveloppesCheckHandler(enveloppe.id)
            }
        })
        this.listeSelectEnveloppes = enveloppes
    }

    /**
     * epreuveIsSetToAnotherSujet
     * @description Vérifie si une épreuve est déjà liée à un sujet
     * @param {number} epreuve_id - id de l'épreuve
     * @return {boolean} - true si l'épreuve est déjà liée à un sujet, false sinon
     */
    epreuveIsSetToAnotherSujet(epreuve_id: number): boolean {
        for (let i = 0; i < this.listeSujetsOriginal.length; i++) {
            if (this.listeSujetsOriginal[i].id !== this.sujetTemp.id) {
                const found = this.listeSujetsOriginal[i].epreuve_ids.findIndex((epreuveId: any) => epreuveId === epreuve_id)
                if (found !== -1) {
                    return true
                }
            }
        }
        return false
    }

    // --- enveloppe

    listeEnveloppes: Array<EnveloppeInterface> = []
    listeSelectEnveloppes: Array<EnveloppeInterface> = []
    enveloppeTemp: any = null
    enveloppeDelete: any = null
    modeEditEnveloppe = false
    showModalMessageDeleteEnveloppe = false

    /**
     * editEnveloppe
     * @description Ouvre la fenêtre d'édition d'une enveloppe
     * @param {EnveloppeInterface} enveloppe - Enveloppe à éditer
     * @return {void}
     */
    editEnveloppe(enveloppe: EnveloppeInterface): void {
        this.enveloppeTemp = JSON.parse(JSON.stringify(enveloppe))
        this.getListeEnveloppes()
        this.modeEditEnveloppe = true
    }

    /**
     * addEnveloppe
     * @description Ouvre la fenêtre d'ajout d'une enveloppe
     * @return {void}
     */
    addEnveloppe(): void {
        this.enveloppeTemp = {
            capacite: 0,
            code: '',
            enveloppe_id: null,
            enveloppes: [],
            manchon: null,
            name: '',
            par_defaut: 0,
            poids: 0,
            session_id: this.session_id,
            enveloppe_ids: []
        }
        this.getListeEnveloppes()
        this.modeEditEnveloppe = true
    }

    /**
     * annulerEditEnveloppe
     * @description Ferme l'espace d'ajout/edition d'une enveloppe
     * @return {void}
     */
    annulerEditEnveloppe(): void {
        this.enveloppeTemp = null
        this.listeSelectEnveloppes = []
        this.modeEditEnveloppe = false
    }

    /**
     * saveEditEnveloppe
     * @description Enregistre les informations pour une modification / ajout d'une enveloppe
     * @return {Promise<void>}
     */
    saveEditEnveloppe(): void {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours...', infosToaster)

        const data: any = {
            session_id: this.enveloppeTemp.session_id,
            code: this.enveloppeTemp.code,
            name: this.enveloppeTemp.name,
            capacite: this.enveloppeTemp.capacite,
            poids: this.enveloppeTemp.poids,
            par_defaut: this.enveloppeTemp.par_defaut ? 1 : 0,
            enveloppe_ids: this.enveloppeTemp.enveloppe_ids
        }

        const getEnveloppes = () => {
            this.$store.dispatch('enveloppe/getEnveloppes')
                .then((response) => {
                    this.listeEnveloppes = response.data.data
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistré avec succès !', succesToaster)
                    this.enveloppeTemp = null
                    this.modeEditEnveloppe = false
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
        }

        const requestError = (error: any) => {
            console.log('ko:' + error)
        }

        if (this.enveloppeTemp.id) {
            this.$store.dispatch('enveloppe/updateEnveloppe', {
                ...data,
                id: this.enveloppeTemp.id
            })
                .then((response) => {
                    for (let i = 0;  i < this.listeEnveloppes.length; i++) {
                        if (response.data.data.id === this.listeEnveloppes[i].id) {
                            this.listeEnveloppes[i].session_id = response.data.data.session_id
                            this.listeEnveloppes[i].code = response.data.data.code
                            this.listeEnveloppes[i].name = response.data.data.name
                            this.listeEnveloppes[i].capacite = response.data.data.capacite
                            this.listeEnveloppes[i].poids = response.data.data.poids
                            this.listeEnveloppes[i].par_defaut = response.data.data.par_defaut
                        }
                    }
                    getEnveloppes()
                })
                .catch(requestError)
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            this.$store.dispatch('enveloppe/addEnveloppe', data)
                .then((response) => {
                    this.listeEnveloppes.push({
                        id: response.data.data.id,
                        session_id: response.data.data.session_id,
                        code: response.data.data.code,
                        name: response.data.data.name,
                        capacite: response.data.data.capacite,
                        poids: response.data.data.poids,
                        par_defaut: response.data.data.par_defaut
                    })
                    getEnveloppes()
                })
                .catch(requestError)
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    /**
     * deleteEnveloppe
     * @description Ouvre la fenêtre de confirmation de suppression d'une enveloppe
     * @param {EnveloppeInterface} enveloppe - Enveloppe à supprimer
     * @return {void}
     */
    deleteEnveloppe(enveloppe: EnveloppeInterface): void {
        this.$store.commit('enveloppe/SET_ERROR', null)
        this.enveloppeDelete = JSON.parse(JSON.stringify(enveloppe))
        this.showModalMessageDeleteEnveloppe = true
    }

    /**
     * getEnveloppeError
     * @description Récupère l'erreur de l'enveloppe
     * @return {any} - Erreur de l'enveloppe
     */
    getEnveloppeError(): any {
        return this.$store.getters['enveloppe/error']
    }

    /**
     * cancelDeleteEnveloppe
     * @description Ferme la fenêtre de confirmation de suppression d'une enveloppe
     * @return {void}
     */
    cancelDeleteEnveloppe(): void {
        this.enveloppeDelete = null
        this.showModalMessageDeleteEnveloppe = false
    }

    /**
     * deleteSuiteEnveloppe
     * @description Confirme et envoi au serveur une demande de suppresion d'enveloppe
     * @return {void}
     */
    deleteSuiteEnveloppe(): void {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Supression en cours...', infosToaster)
        this.$store.dispatch('enveloppe/deleteEnveloppe',  this.enveloppeDelete.id)
            .then(() => {
                for (let i = 0;  i < this.listeEnveloppes.length; i++) {
                    if (this.enveloppeDelete.id === this.listeEnveloppes[i].id) {
                        this.listeEnveloppes.splice(i, 1)
                    }
                }
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Suppression terminée.', succesToaster)
                this.enveloppeDelete = null
                this.showModalMessageDeleteEnveloppe = false
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }


    // --- Couleurs

    listeCouleurs: Array<any> = []
    couleurTemp: any = null
    couleurDelete: any = null
    modeEditCouleur = false
    showModalMessageDeleteCouleur = false

    /**
     * editCouleur
     * @description Ouvre la fenêtre d'édition d'une couleur
     * @param {CouleurInterface} couleur - Couleur à éditer
     * @return {void}
     */
    editCouleur (couleur: CouleurInterface): void {
        this.couleurTemp = JSON.parse(JSON.stringify(couleur))
        this.modeEditCouleur = true
    }

    /**
     * addCouleur
     * @description Ouvre la fenêtre d'ajout d'une couleur
     * @return {void}
     */
    addCouleur(): void {
        this.couleurTemp = { id: 0, name: '', hexa: '' }
        this.modeEditCouleur = true
    }

    /**
     * annulerEditCouleur
     * @description Ferme l'espace d'ajout/edition d'une couleur
     * @return {void}
     */
    annulerEditCouleur(): void {
        this.couleurTemp = null
        this.modeEditCouleur = false
    }

    /**
     * saveEditCouleur
     * @description Enregistre les informations pour une modification / ajout d'une couleur
     * @return {void}
     */
    saveEditCouleur(): void {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours...', infosToaster)

        const data = {
            name: this.couleurTemp.name,
            hexa: this.couleurTemp.hexa
        }

        const getCouleurs = () => {
            this.$store.dispatch('couleur/getCouleurs')
                .then((response) => {
                    this.listeCouleurs = response.data.data
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                    this.couleurTemp = null
                    this.modeEditCouleur = false
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
        }

        const requestError = (error: any) => {
            console.log('ko:' + error)
        }

        if (this.couleurTemp.id) {
            this.$store.dispatch('couleur/updateCouleur', {
                ...data,
                id: this.couleurTemp.id
            })
                .then((response) => {
                    for (let i = 0;  i < this.listeCouleurs.length; i++) {
                        if (response.data.data.id === this.listeCouleurs[i].id) {
                            this.listeCouleurs[i].name = response.data.data.name
                            this.listeCouleurs[i].hexa = response.data.data.hexa
                        }
                    }
                    getCouleurs()
                })
                .catch(requestError)
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            this.$store.dispatch('couleur/addCouleur', data)
                .then((response) => {
                    this.listeCouleurs.push({
                        id: response.data.data.id,
                        name: response.data.data.name,
                        hexa: response.data.data.hexa
                    })
                    getCouleurs()
                })
                .catch(requestError)
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    /**
     * deleteCouleur
     * @description Ouvre la fenêtre de confirmation de suppression d'une couleur
     * @param {CouleurInterface} couleur - Couleur à supprimer
     * @return {void}
     */
    deleteCouleur(couleur: CouleurInterface): void {
        this.$store.commit('couleur/SET_ERROR', null)
        this.couleurDelete = JSON.parse(JSON.stringify(couleur))
        this.showModalMessageDeleteCouleur = true
    }

    /**
     * getCouleurError
     * @description Récupère l'erreur de la couleur
     * @return {any} - Erreur de la couleur
     */
    getCouleurError(): any {
        return this.$store.getters['couleur/error']
    }


    /**
     * cancelDeleteCouleur
     * @description Ferme la fenêtre de confirmation de suppression d'une couleur
     * @return {void}
     */
    cancelDeleteCouleur(): void {
        this.couleurDelete = null
        this.showModalMessageDeleteCouleur = false
    }

    /**
     * deleteSuiteCouleur
     * @description Confirme et envoi au serveur une demande de suppresion d'une couleur
     * @return {void}
     */
    deleteSuiteCouleur(): void {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Supression en cours...', infosToaster)
        this.$store.dispatch('couleur/deleteCouleur',  this.couleurDelete.id)
            .then(() => {
                for (let i = 0;  i < this.listeCouleurs.length; i++) {
                    if (this.couleurDelete.id === this.listeCouleurs[i].id) {
                        this.listeCouleurs.splice(i, 1)
                    }
                }
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Suppression terminée.', succesToaster)
                this.couleurDelete = null
                this.showModalMessageDeleteCouleur = false
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // --------------------------------------
    // ------------------gestion des temps supplémentaires--------------------------------

    /**
     * openGestionTempsSup
     * @description Ouvre la fenêtre de gestion des temps supplémentaires
     * @return {void}
     */
    openGestionTempsSup(): void {
        this.$store.dispatch('tempsSupplementaire/getTempsSupplementaires').then(() => {
            this.showModalGestionTempsSup = true
        }).catch((error) => {
            console.log('ko:' + error)
        })
    }

    /**
     * cancelGestionTempsSup
     * @description Ferme la fenêtre de gestion des temps supplémentaires
     * @return {void}
     */
    cancelGestionTempsSup(): void {
        this.$store.commit('tempsSupplementaire/SET_ERROR', null)
        this.showFormulaireTs = false
        this.ts_select = {
            id: 0,
            name: '',
            code: '',
            numerateur: 1,
            denominateur: 1
        }
        this.showModalGestionTempsSup = false
    }

    /**
     * edit_ts
     * @description Ouvre l'espace d'édtion pour modifier d'un temps supplémentaire
     * @param {any} tempsSupplementaire - Temps supplémentaire à éditer
     * @return {void}
     */
    edit_ts(tempsSupplementaire: any) {
        this.ts_select.id = tempsSupplementaire.id
        this.ts_select.code = tempsSupplementaire.code
        this.ts_select.name = tempsSupplementaire.name
        this.ts_select.numerateur = tempsSupplementaire.numerateur
        this.ts_select.denominateur = tempsSupplementaire.denominateur
        this.showFormulaireTs = true
    }

    /**
     * add_ts
     * @description Ouvre l'espace d'édition pour ajouter un temps supplémentaire
     * @return {void}
     */
    add_ts(): void {
        this.ts_select = {
            id: 0,
            code: '',
            name: '',
            numerateur: 1,
            denominateur: 1
        }
        this.showFormulaireTs = true
    }

    /**
     * updateChamps
     * @description Définition des valeurs depuis le formulaire
     * @param {any} e - Evenement
     * @return {void}
     */
    updateChamps(e: any): void {
        switch (e.target.name) {
            case 'code':
                this.ts_select.code = e.target.value
                break
            case 'name':
                this.ts_select.name = e.target.value
                break
            case 'numerateur':
                this.ts_select.numerateur = e.target.value
                break
            case 'denominateur':
                this.ts_select.denominateur = e.target.value
                break
            default:
                break
        }
    }

    /**
     * save_ts
     * @description Enregistre les informations pour une modification / ajout d'un temps supplémentaire
     * @return {void}
     */
    save_ts(): void {
        this.$store.commit('tempsSupplementaire/SET_ERROR', null)
        const data: any = {
            code: this.ts_select.code,
            name: this.ts_select.name,
            numerateur: this.ts_select.numerateur > 255 ? 255 : this.ts_select.numerateur,
            denominateur: this.ts_select.denominateur > 255 ? 255 : this.ts_select.denominateur,
            tempsSupplementaire_id: this.ts_select.id
        }

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours...', infosToaster)

        const request = (target: string) => {
            this.$store.dispatch(target, data)
                .then(() => {
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé !', succesToaster)

                    this.showFormulaireTs = false
                    this.ts_select = {
                        id:0,
                        code: '',
                        name: '',
                        numerateur: 1,
                        denominateur: 1
                    }
                    this.$store.dispatch('tempsSupplementaire/getTempsSupplementaires')
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }

        if (this.ts_select.id) {
            // Modification de l'utilisateur
            request('tempsSupplementaire/updateTempsSupplementaire')
        } else {
            // Création d'un role
            request('tempsSupplementaire/addTempsSupplementaire')
        }
    }

    /**
     * cancel_edit_ts
     * @description Ferme l'espace d'ajout / edition d'un temps supplémentaire
     * @return {void}
     */
    cancel_edit_ts(): void {
        this.ts_select = {
            id: 0,
            code: '',
            name: '',
            numerateur: 1,
            denominateur: 1
        }
        this.showFormulaireTs = false
    }

    /**
     * delete_ts
     * @description Ouvre la fenêtre de confirmation de suppression d'un temps supplémentaire
     * @param {any} tempsSupplementaire - Temps supplémentaire à supprimer
     * @return {void}
     */
    delete_ts(tempsSupplementaire: any): void {
        this.showFormulaireTs = false
        this.ts_select = tempsSupplementaire
        this.showConfirmDeleteTs = true
    }

    /**
     * cancel_delete_ts
     * @description Ferme la fenêtre de confirmation de suppression d'un temps supplémentaire
     * @return {void}
     */
    cancel_delete_ts(): void {
        this.ts_select = {
            id: 0,
            code: '',
            name: '',
            numerateur: 1,
            denominateur: 1
        }
        this.showConfirmDeleteTs = false
    }

    /**
     * confirm_delete_ts
     * @description Confirme et envoi au serveur une demande de suppression d'un temps supplémentaire
     * @return {void}
     */
    confirm_delete_ts(): void {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Suppression en cours ...', infosToaster)

        // Suppression d'un rôle
        this.$store.dispatch('tempsSupplementaire/deleteTempsSupplementaire', this.ts_select.id)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Suppression terminée !', succesToaster)
                this.$store.dispatch('tempsSupplementaire/getTempsSupplementaires')
                    .then(() => {
                        this.showConfirmDeleteTs = false
                    })
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // --------------------------------------------------

    /**
     * canEdit
     * @description Vérifie si l'utilisateur peut modifier les horaires et temps supplémentaires
     * @return {boolean}
     */
    canEdit(): boolean {
        return !!(this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE) && this.currentSession && this.currentSession.closed_at === null);
    }

    /**
     * resetDureeTheorique
     * @description Recalcul des durées théoriques
     * @return {void}
     */
    resetDureeTheorique(): void {
        for (const tempsSup of this.epreuveTemp.tempsSupplementaires) {
            let duree = Number(this.epreuveTemp.duree)
            if (tempsSup.denominateur !== 0 && tempsSup.numerateur !== 0) {
                duree += ((this.epreuveTemp.duree) / tempsSup.denominateur * tempsSup.numerateur)
                tempsSup.dureeTheorique =  Math.floor(duree)
            }
        }
    }

    /**
     * resetTempsSupEpreuve
     * @description Recalcul des temps supplémentaires
     * @return {void}
     */
    resetTempsSupEpreuve(): void {
        const tempsSups: Array<any> = []
        for (const tempsSup of this.$store.getters['tempsSupplementaire/tempsSupplementaires']) {
            let duree = Number(this.epreuveTemp.duree)
            if (tempsSup.denominateur !== 0 && tempsSup.numerateur !== 0) {
                duree += ((this.epreuveTemp.duree) / tempsSup.denominateur * tempsSup.numerateur)
            }
            tempsSups.push({
                code: tempsSup.code,
                name: tempsSup.name,
                denominateur: tempsSup.denominateur,
                numerateur: tempsSup.numerateur,
                id: tempsSup.id,
                passation_at: convertDateLocalValeurT(this.epreuveTemp.passation_at),
                duree:  Math.floor(duree),
                dureeTheorique:  Math.floor(duree)
            })
        }
        this.epreuveTemp.tempsSupplementaires = tempsSups
    }

    /**
     * editEpreuve
     * @description Ouvre l'espace de modification des horaires et temps supplémentaires d'une épreuve
     * @param {any} data - Données de l'épreuve
     * @return {void}
     */
    editEpreuve(data: any): void {
        this.$store.dispatch('epreuve/getEpreuve',  { id: data.id })
            .then((response) => {
                this.epreu = response.data.data
                this.epreuveTemp = {
                    id: this.epreu.id,
                    passation_at: convertDateLocalValeurT(this.epreu.passation_at),
                    duree: Math.floor(this.epreu.duree / 60)
                }

                this.$store.dispatch('tempsSupplementaire/getTempsSupplementaires')
                    .then(() => {
                        const tempsSups: Array<any> = []
                        if (this.epreu.tempsSupplementaires && this.epreu.tempsSupplementaires.length === this.$store.getters['tempsSupplementaire/tempsSupplementaires'].length) {
                            for (const tempsSup of this.epreu.tempsSupplementaires) {
                                let dureeTheorique = this.epreu.duree / 60
                                if (tempsSup.denominateur !== 0 && tempsSup.numerateur !== 0) {
                                    dureeTheorique = (this.epreu.duree / 60) + ((this.epreu.duree / 60) / tempsSup.denominateur * tempsSup.numerateur)
                                }
                                tempsSups.push({
                                    code: tempsSup.code,
                                    name: tempsSup.name,
                                    denominateur: tempsSup.denominateur,
                                    numerateur: tempsSup.numerateur,
                                    id: tempsSup.id,
                                    passation_at: convertDateLocalValeurT(tempsSup.passation_at),
                                    duree:  Math.floor(tempsSup.duree / 60),
                                    dureeTheorique: Math.floor(dureeTheorique)
                                })
                            }
                        } else {
                            for (const tempsSup of this.$store.getters['tempsSupplementaire/tempsSupplementaires']) {
                                let duree = this.epreu.duree / 60
                                if (tempsSup.denominateur !== 0 && tempsSup.numerateur !== 0) {
                                    duree = (this.epreu.duree / 60) + ((this.epreu.duree / 60) / tempsSup.denominateur * tempsSup.numerateur)
                                }
                                tempsSups.push({
                                    code: tempsSup.code,
                                    name: tempsSup.name,
                                    denominateur: tempsSup.denominateur,
                                    numerateur: tempsSup.numerateur,
                                    id: tempsSup.id,
                                    passation_at: convertDateLocalValeurT(this.epreu.passation_at),
                                    duree: Math.floor(duree),
                                    dureeTheorique:  Math.floor(duree)
                                })
                            }
                        }
                        this.epreuveTemp = {
                            id: this.epreu.id,
                            passation_at: convertDateLocalValeurT(this.epreu.passation_at),
                            duree: this.epreu.duree / 60,
                            tempsSupplementaires: tempsSups
                        }
                        this.showModalEditionEcrit = true
                    })
                    .catch((error) => {
                        console.log('ko:' + error)
                    })
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
    }

    /**
     * cancelEdit
     * @description Ferme l'espace de modification des horaires et temps supplémentaires d'une épreuve
     * @return {void}
     */
    cancelEdit(): void {
        this.epreu = null
        this.epreuveTemp = null
        this.showModalEditionEcrit = false
    }

    /**
     * editSuite
     * @description Enregistre les modifications des horaires et temps supplémentaires d'une épreuve
     * @return {void}
     */
    editSuite(): void {
        const tempsSupForPayload = []
        for (let i = 0; i < this.epreuveTemp.tempsSupplementaires.length; i++) {
            tempsSupForPayload.push(JSON.parse(JSON.stringify(this.epreuveTemp.tempsSupplementaires[i])))
            tempsSupForPayload[i].duree = this.epreuveTemp.tempsSupplementaires[i].duree * 60
            tempsSupForPayload[i].dureeTheorique = this.epreuveTemp.tempsSupplementaires[i].dureeTheorique * 60
        }
        const payload = {
            id: this.epreuveTemp.id,
            passation_at: convertDateUTCValeurT(this.epreuveTemp.passation_at),
            duree: this.epreuveTemp.duree * 60,
            tempsSupplementaires: tempsSupForPayload // this.epreuveTemp.tempsSupplementaires
        }
        for (const ts of payload.tempsSupplementaires) {
            ts.passation_at = convertDateUTCValeurT(ts.passation_at)
        }

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours...', infosToaster)

        this.$store.dispatch('epreuve/updateEpreuveCalendrier', payload)
            .then(() => {
                this.epreu = null
                this.epreuveTemp = null
                this.showModalEditionEcrit = false
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                const paramsWithTypeFilter = JSON.parse(JSON.stringify(this.params))
                paramsWithTypeFilter['filter-type_passation'] = TypePassation.TYPE_PASSATION_ECRIT
                this.$store.dispatch('epreuve/getEpreuves',  { session_id: this.session_id, params: paramsWithTypeFilter })
                    .then(() => {
                        this.setDataForGenericTab(this.$store.getters['epreuve/epreuves'])
                    })
                    .catch((error) => {
                        console.log('ko:' + error)
                    })
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * exportCalendrierEpreuves
     * @description Demande au serveur d'un export excel du nombres de sujets, l'estimation des nombres de candidats et du nombre de pages imprimées
     * @return {void}
     */
    exportingIsWorking =  false
    exportCalendrierEpreuves(): void {
        this.exportingIsWorking = true
        let fileName = 'export_calendrier_epreuves.xlsx'

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours de création ...', infosToaster)

        this.$store.dispatch('epreuve/exportCalendrierEpreuves')
            .then((response) => {
                const fileNameTemp = getFileNameFromHeader(response.headers)
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.exportingIsWorking = false
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * loadHandler
     * @description Charge les données supplémentaires en bas du tableau
     * @param {any} params - Paramètres
     * @return {void}
     */
    loadHandler(params: any): void {
        if (JSON.stringify(this.params) !== JSON.stringify(params) || (JSON.stringify(this.params) === JSON.stringify(params) && this.firstLoading)) {
            this.params = params
            this.$store.dispatch('epreuve/getMoreEpreuves', { session_id: this.session_id, params: params }).then(() => { // , type_passation: TypePassation.TYPE_PASSATION_ECRIT
                if (this.firstLoading) {
                    this.setFiltersForGenericTab()
                    this.firstLoading = false
                }
                this.setDataForGenericTab(this.$store.getters['epreuve/epreuves'])
            }).catch((error) => {
                console.log('ko:' + error)
            })
        }
    }

    /**
     * filtreSortHandler
     * @description Gestion des filtres
     * @param {any} params - Paramètres
     * @return {void}
     */
    filtreSortHandler(params: any): void {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            const paramsWithTypeFilter = JSON.parse(JSON.stringify(this.params))
            paramsWithTypeFilter['filter-type_passation'] = TypePassation.TYPE_PASSATION_ECRIT
            this.$store.dispatch('epreuve/getEpreuves',  { session_id: this.session_id, params: paramsWithTypeFilter }).then(() => {
                this.setDataForGenericTab(this.$store.getters['epreuve/epreuves'])
            }).catch((error) => {
                console.log('ko:' + error)
            })
        }
    }

    mountedFin() {
        const paramsWithTypeFilter: any = {
            sort: 'name',
            direction: 'asc'
        }
        paramsWithTypeFilter['filter-type_passation'] = TypePassation.TYPE_PASSATION_ECRIT
        this.$store.dispatch('epreuve/getEpreuves',  { session_id: this.session_id, params: paramsWithTypeFilter }).then(() => {
            this.setDataForGenericTab(this.$store.getters['epreuve/epreuves'])
        }).catch((error) => {
            console.log('ko:' + error)
        })
    }

    /**
     * mountedSuite
     * @description Suite du montage du composant
     * @return {void}
     */
    mountedSuite(): void {
        if (this.$store.getters['concour/banques'].length === 0) {
            this.$store.dispatch('concour/getConcours',  { session_id: this.session_id }).then(() => {
                this.setFiltersForGenericTab()
                this.mountedFin()
            })
        } else {
            this.setFiltersForGenericTab()
            this.mountedFin()
        }
    }

    /**
     * mounted
     * @description Montage du composant
     * @return {void}
     */
    mounted(): void {
        if (this.$route && this.$route.params && this.$route.params.session_id) {
            this.currentSession = null
            this.session_id = this.$route.params.session_id
            this.$store.dispatch('session/getSession', { session_id: this.session_id }).then(() => {
                this.currentSession = this.$store.getters['session/sessionSelect']
                this.mountedSuite()
            }).catch((error) => {
                console.log('ko:' + error)
            })
        } else {
            this.mountedSuite()
        }
    }
}
