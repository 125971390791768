
















































































import { Vue, Component } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { TypePassation } from '@/types/Epreuve'
import { TypeDeliberation } from '@/types/Concour'
import { mapState } from 'vuex'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import _, {isEmpty} from 'lodash'
import {CandidatConcourPhase, CandidatInterface, CandidatStatut, TypeBarre} from '@/types/Candidat'
import { base64ToArrayBuffer, getFileNameFromHeader } from '@/utils/helpers'
import { BarRuleType } from '@/types/Barre'

/**
 * Composant relatif aux résultats d'un candidat
 */
@Component({
    computed: {
        ...mapState('concour', ['concours'])
    },
    components: {
        ExaGenericTable,
        FontAwesomeIcon
    }
})

export default class PopupEditCandidatResultats extends Vue {
    TypeDeliberation = TypeDeliberation
    TypeBarre = TypeBarre
    CandidatConcourPhase = CandidatConcourPhase
    candidat: CandidatInterface = JSON.parse(JSON.stringify(this.$store.getters['candidat/editedCandidat']))
    loading = true

    /**
     * getRelevePdf
     * Téléchargement du relevé PDF
     * @param {number} concour_id - Concours concerné
     * @param {number} phase_id - Phase concernée
     * @returns {void}
     */
    getRelevePdf(concour_id: number, phase_id: number): void {
        const params = {
            candidat_id: this.candidat.id,
            concour_id: concour_id,
            phase_id: phase_id
        }

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Export en cours...', infosToaster)

        this.$store.dispatch('candidat/getRelevePdf', params)
            .then((response) => {
                const fileName = getFileNameFromHeader(response.headers) || 'Candidat_releve.pdf'
                const url = URL.createObjectURL(new Blob([base64ToArrayBuffer(response.data)]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * retryPublication
     * Relance de la publication des résultats
     * @param {number} concour_id - Concours concerné
     * @param {number} phase_id - Phase concernée
     * @returns {void}
     */
    retryPublication(concour_id: number, phase_id: number): void {
        const params = {
            candidat_id: this.candidat.id,
            phase_id: phase_id,
            payload: {
                publicationName: 'resultats',
                concour_id: concour_id
            }
        }

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Relance de la publication en cours...', infosToaster)

        this.$store.dispatch('candidat/retryPublication', params)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Publication relancée !', succesToaster)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * handleTableEvent
     * Récupération des events du tableau
     * @param {any} paParams - [l'action, l'id de l'item ou l'item]
     * @returns {void}
     */
    handleTableEvent(paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'openApercu':
                    if (paParams[1]?.cle_epreuve_externe) {
                        window.open(this.$store.getters['candidat/getCorrectionOnViatique'](paParams[1].cle_epreuve_externe), '_blank')
                    }
                    break
            }
        }
    }

    /**
     * resultatsPhases
     * Résultats aux épreuves des phases
     * @param {number} index_concours - Index du concours
     * @returns {any[]} Tableau des phases
     */
    resultatsPhases(index_concours = 0): any[] {
        const concours = this.$store.getters['concour/concours']
            .find((c: any) => c.id === this.candidat.concours[index_concours].id)
        const phases: any[] = []

        const results_candidat = this.candidat.resultats.filter(
            (r: any) => r.concour_id === concours.id
        )

        // Chargement des phases
        for (const phase in concours.phases) {
            const result_phase = results_candidat.find(
                (r: any) => r.phase_id === concours.phases[phase].phase_id
            )

            const fieldsTab: any = [
                { key: 'name', label: 'Épreuve', type: 'text', class: 'entete_epreuve' },
                { key: 'presence', label: 'Présence', type: 'text', class: 'text-center' },
                { key: 'noteB', label: 'Notes brutes', type: 'text', class: 'text-center' },
                { key: 'noteF', label: 'Notes définitives', type: 'text', class: 'text-center' }
            ]

            // Colonne aperçue de la copie
            fieldsTab.push({ key: 'apercu_copie', label: 'Détails', type: 'text', class: 'text-center' })

            let bar_rules: any = []
            if (concours?.phases[phase]?.bar_rules && concours?.phases[phase]?.barres) {
                bar_rules = concours.phases[phase].bar_rules
                    .filter((bar_rule: any) => bar_rule.type !== BarRuleType.BAR_RULE_TYPE_ABSENCE)
                    .map((bar_rule: any) => {
                        let value: any
                        concours.phases[phase].barres.some((barre: any) => {
                            return barre.thresholds.some((threshold: any) => {
                                if (threshold.bar_rule_id === bar_rule.id) {
                                    value = threshold.value
                                    return true
                                }
                            })
                        })

                        return {
                            name: bar_rule.name.charAt(0).toUpperCase() + bar_rule.name.slice(1),
                            id: bar_rule.id,
                            type: bar_rule.type,
                            value: value || null
                        }
                    })
            }

            // Rendu des barres
            const barres = []
            for (const b in bar_rules) {
                if (result_phase?.bar_rule_results) {
                    barres.push({
                        name: bar_rules[b].name,
                        result: result_phase.bar_rule_results[bar_rules[b].id],
                        vbarre: bar_rules[b].value || result_phase.max_points,
                        type: bar_rules[b].type
                    })
                }
            }

            phases[concours.phases[phase].phase_id] = {
                phase_id: concours.phases[phase].phase_id,
                name: concours.phases[phase].name,
                lib_element: concours.phases[phase].name,
                bonus: result_phase?.bonus?.toString() || null,
                barres: barres,
                type_deliberation: concours.phases[phase].type_deliberation,
                epreuves: [],
                fields: fieldsTab,
                points: result_phase?.points?.toString() || null,
                rang: result_phase?.rang?.toString() || null,
                recu: result_phase?.recu?.toString() || null
            }
        }

        // Chargement des résultats par épreuves
        const epreuvesConcours = this.$store.getters['epreuve/orderedEpreuves'](
            this.$store.getters['epreuve/epreuves']
        )

        for (const epreuve of epreuvesConcours) {
            const indexEpreuveCandidat = this.candidat.inscriptions.findIndex((ins: any) => {
                return ins.epreuve_id === epreuve.id
            })

            if (indexEpreuveCandidat !== -1) {
                const currentCandidatInscription = this.candidat.inscriptions[indexEpreuveCandidat]

                const epreuveDatas: any = Object.assign({}, epreuve)
                const iconPresence: any[] = []
                let afficherLienCopie = false // si candidat en état inscrit ou absent, on n'affiche pas le lien vers la copie.
                if (currentCandidatInscription.statut === CandidatStatut.PRESENT) {
                    iconPresence.push({name: 'check', class: 'text-success', title: 'Présent'})
                    epreuveDatas.noteB =
                        currentCandidatInscription.note_brute !== null
                            ? currentCandidatInscription.note_brute.toFixed(2)
                            : '-'
                    epreuveDatas.noteF =
                        currentCandidatInscription.note_finale !== null
                            ? currentCandidatInscription.note_finale.toFixed(2)
                            : '-'
                    afficherLienCopie = true
                } else if (currentCandidatInscription.statut === CandidatStatut.ABSENT) {
                    iconPresence.push({name: 'times', class: 'text-secondary', title: 'Absent'})
                    epreuveDatas.noteB = '0.00'
                    epreuveDatas.noteF = '0.00'
                    afficherLienCopie = false
                } else if (currentCandidatInscription.cle_epreuve_externe) {
                    iconPresence.push({name: 'window-minimize', class: 'text-tertiary', title: 'Inscrit'})
                    epreuveDatas.noteB = '-'
                    epreuveDatas.noteF = '-'
                    afficherLienCopie = true
                } else {
                    iconPresence.push({name: 'window-minimize', class: 'text-tertiary', title: 'Inscrit'})
                    epreuveDatas.noteB = '-'
                    epreuveDatas.noteF = '-'
                    afficherLienCopie = false
                }

                const infosNoteModified: any = {}

                if (currentCandidatInscription.notes) {
                    if (currentCandidatInscription.notes.avant_decisionjury !== undefined) {
                        const infoDecisionjury =
                            'Modification décision jury - note avant décision : ' +
                            (currentCandidatInscription.notes.avant_decisionjury === null
                                ? 'Abs.'
                                : currentCandidatInscription.notes.avant_decisionjury)
                        infosNoteModified.avant_decisionjury =
                            '<span class="commons_custom_tooltip custom_rounded_letter text-primary" data-title-location="top" data-title="' +
                            infoDecisionjury +
                            '">J</span>'
                    }
                    if (currentCandidatInscription.notes.avant_reclamation !== undefined) {
                        const infoReclamation =
                            'Modification réclamation - note avant réclamation : ' +
                            (currentCandidatInscription.notes.avant_reclamation === 'null'
                                ? 'Abs.'
                                : currentCandidatInscription.notes.avant_reclamation)
                        infosNoteModified.avant_reclamation =
                            '<span class="commons_custom_tooltip custom_rounded_letter text-primary" data-title-location="top" data-title="' +
                            infoReclamation +
                            '">R</span>'
                    }
                }

                const noteFinalHasModifications = infosNoteModified
                    ? '<div class="bloc_valeurNote">' +
                    epreuveDatas.noteF +
                    '</div>' +
                    (infosNoteModified ? '<div class="bloc_infosNoteModified">' : '') +
                    (infosNoteModified.avant_decisionjury ? infosNoteModified.avant_decisionjury : '') +
                    (infosNoteModified.avant_reclamation ? infosNoteModified.avant_reclamation : '') +
                    (infosNoteModified ? '</div>' : '')
                    : null

                // Remplissage des lignes d'épreuves
                const line = []
                if (epreuve.type_passation === TypePassation.TYPE_EPREUVE_MAITRESSE) {
                    // Epreuve Maitresse, on cherche à voir si on a une épreuve fille associée dans les inscriptions
                    const epreuves_filles = epreuvesConcours.filter(
                        (ep: any) => ep.id_epreuve_maitresse === epreuve.id
                    )
                    let inscription_find = false

                    for (const epf in epreuves_filles) {
                        const ep_fille_index = this.candidat.inscriptions.findIndex((ins: any) => {
                            return ins.epreuve_id === epreuves_filles[epf].id
                        })
                        if (ep_fille_index !== -1) {
                            const epreuve_fille_id: number =
                                this.candidat.inscriptions[ep_fille_index].epreuve_id
                            const epreuve_fille = epreuvesConcours.find((ep: any) => ep.id === epreuve_fille_id)

                            line.push(
                                {
                                    label: '',
                                    item:
                                        epreuve.long_name +
                                        ' ( ' +
                                        (epreuve_fille ? epreuve_fille.name : '-') +
                                        ' )',
                                    type: 'text',
                                    typeAction: null,
                                    class: 'text-start fix_width'
                                },
                                {
                                    label: '',
                                    item: iconPresence,
                                    type: 'icons',
                                    typeAction: null,
                                    class: 'text-center'
                                },
                                {
                                    label: '',
                                    item: epreuveDatas.noteB,
                                    type: 'text',
                                    typeAction: null,
                                    class: 'align_number'
                                },
                                {
                                    ...(noteFinalHasModifications
                                        ? {
                                            label: '',
                                            item: noteFinalHasModifications,
                                            type: 'html',
                                            typeAction: null,
                                            class: 'align_number note_with_infos'
                                        }
                                        : {
                                            label: '',
                                            item: epreuveDatas.noteF,
                                            type: 'text',
                                            typeAction: null,
                                            class: 'align_number'
                                        })
                                }
                            )

                            if (afficherLienCopie) {
                                line.push({
                                    label: '',
                                    text: 'APERÇU >',
                                    type: 'actionText',
                                    item: this.candidat.inscriptions[ep_fille_index],
                                    typeAction: 'openApercu',
                                    disabled:
                                        !this.candidat.inscriptions[ep_fille_index].cle_epreuve_externe,
                                    class:
                                        'text-center colonne_copie ' +
                                        (this.candidat.inscriptions[ep_fille_index].cle_epreuve_externe
                                            ? 'text-primary'
                                            : 'text-tertiary cursor-default')
                                })
                            } else {
                                line.push({
                                    label: '',
                                    text: '',
                                    type: 'Text',
                                    item: null,
                                    typeAction: null,
                                    class: 'text-center colonne_copie'
                                })
                            }

                            inscription_find = true
                        }
                    }

                    // Si pas d'inscription pour l'épreuve Maitresse, on l'affiche avec une mention non effectuée
                    if (!inscription_find) {
                        const ep_index = this.candidat.inscriptions.findIndex((ins: any) => {
                            return ins.epreuve_id === epreuve.id
                        })
                        const hasCleExterne = this.candidat.inscriptions[ep_index].cle_epreuve_externe

                        let libelle_none = 'Aucune'
                        if (epreuve.tirage_aleatoire) {
                            libelle_none = 'Tirage non effectué'
                        }
                        line.push(
                            {
                                label: '',
                                item: epreuve.long_name + ' ( ' + libelle_none + ' )',
                                type: 'text',
                                typeAction: null,
                                class: 'text-start'
                            },
                            {
                                label: '',
                                item: iconPresence,
                                type: 'icons',
                                typeAction: null,
                                class: 'text-center'
                            },
                            {
                                label: '',
                                item: epreuveDatas.noteB,
                                type: 'text',
                                typeAction: null,
                                class: 'align_number'
                            },
                            {
                                ...(noteFinalHasModifications
                                    ? {
                                        label: '',
                                        item: noteFinalHasModifications,
                                        type: 'html',
                                        typeAction: null,
                                        class: 'align_number note_with_infos'
                                    }
                                    : {
                                        label: '',
                                        item: epreuveDatas.noteF,
                                        type: 'text',
                                        typeAction: null,
                                        class: 'align_number'
                                    })
                            }
                        )

                        if (afficherLienCopie) {
                            line.push({
                                label: '',
                                text: 'APERÇU >',
                                type: 'actionText',
                                item: this.candidat.inscriptions[ep_index],
                                typeAction: 'openApercu',
                                disabled: !hasCleExterne,
                                class:
                                    'text-center colonne_copie ' +
                                    (hasCleExterne ? 'text-primary' : 'text-tertiary cursor-default')
                            })
                        } else {
                            line.push({
                                label: '',
                                text: '',
                                type: 'Text',
                                item: null,
                                typeAction: null,
                                class: 'text-center colonne_copie'
                            })
                        }
                    }
                } else if (epreuve.id_epreuve_maitresse === 0 || !epreuve.id_epreuve_maitresse) {
                    const ep_index = this.candidat.inscriptions.findIndex((ins: any) => {
                        return ins.epreuve_id === epreuve.id
                    })
                    const hasCleExterne = this.candidat.inscriptions[ep_index].cle_epreuve_externe

                    line.push(
                        {label: '', item: epreuve.name, type: 'text', typeAction: null, class: 'text-start'},
                        {
                            label: '',
                            item: iconPresence,
                            type: 'icons',
                            typeAction: null,
                            class: 'text-center'
                        },
                        {
                            label: '',
                            item: epreuveDatas.noteB,
                            type: 'text',
                            typeAction: null,
                            class: 'align_number'
                        },
                        {
                            ...(noteFinalHasModifications
                                ? {
                                    label: '',
                                    item: noteFinalHasModifications,
                                    type: 'html',
                                    typeAction: null,
                                    class: 'align_number note_with_infos'
                                }
                                : {
                                    label: '',
                                    item: epreuveDatas.noteF,
                                    type: 'text',
                                    typeAction: null,
                                    class: 'align_number'
                                })
                        }
                    )
                    if (afficherLienCopie) {
                        line.push({
                            label: '',
                            text: 'APERÇU >',
                            type: 'actionText',
                            item: this.candidat.inscriptions[ep_index],
                            typeAction: 'openApercu',
                            disabled: !hasCleExterne,
                            class:
                                'text-center colonne_copie ' +
                                (hasCleExterne ? 'text-primary' : 'text-tertiary cursor-default')
                        })
                    } else {
                        line.push({
                            label: '',
                            text: '',
                            type: 'Text',
                            item: null,
                            typeAction: null,
                            class: 'text-center colonne_copie'
                        })
                    }
                }

                if (phases[epreuve.phase_id] && line && line.length !== 0) {
                    phases[epreuve.phase_id].epreuves.push(line)
                }
            }
        }

        return phases.filter((value: any) => Object.keys(value).length !== 0)
    }

    /**
     * beforeMount
     * Initialisation des données au montage du composant
     * @returns {Promise<void>}
     */
    async beforeMount(): Promise<void> {
        if (isEmpty(this.$store.getters['concour/concours'])) {
            await this.$store.dispatch('concour/getConcours', {
                perPage: 0
            })
        }

        if (!isEmpty(this.candidat) && !isEmpty(this.candidat.concours)) {
            this.candidat.concours = _.orderBy(this.candidat.concours, 'ordre', 'asc')
            for (let i = 0; i < this.candidat.concours.length; i++) {
                this.candidat.concours[i].phases = this.resultatsPhases(i)

                for (const phase of this.candidat.concours[i].phases) {
                    if (phase.type_deliberation === TypeDeliberation.TYPE_AVEC_DELIBERATION) {
                        phase.deliberation = {
                            fields: [
                                { key: 'section', label: 'Total des points', type: 'text' },
                                { key: 'total', label: '', type: 'text' }
                            ],
                            datas: []
                        }

                        for (const barre of phase.barres) {
                            phase.deliberation.datas.push([
                                {
                                    label: '',
                                    item: barre.name,
                                    type: 'text',
                                    typeAction: null,
                                    class: 'text-start'
                                },
                                {
                                    label: '',
                                    item: `${barre.result || '-'} / ${barre.vbarre || '-'}`,
                                    type: 'text',
                                    typeAction: null,
                                    class: 'text-center'
                                }
                            ])
                        }
                    }
                }
            }
        }
        this.loading = false;
    }
}
