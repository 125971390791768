




























import { Vue, Component, Watch } from 'vue-property-decorator'
import { FontAwesomeIcon }      from '@fortawesome/vue-fontawesome'
import { mapGetters, mapState } from 'vuex'
import PlanningsExaminateur  from '@/views/Plannings/PlanningsExaminateur.vue'
import PlanningsCandidat  from '@/views/Plannings/PlanningsCandidat.vue'
import EmploiTemps  from '@/views/Plannings/EmploiTemps.vue'
import { Ability } from '@/types/Ability'
import { EtatSeriePublie, EtatSerieValide, getItemEtatSerie } from '@/types/Planning'

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapState('serie', ['series']),
        ...mapState('session', ['sessionSelect'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        PlanningsExaminateur,
        PlanningsCandidat,
        EmploiTemps
    }
})
export default class Plannings extends Vue
{
    Ability                     = Ability
    ongletSelect                = 'examinateur'
    getItemEtatSerie            = getItemEtatSerie
    EtatSeriePublie             = EtatSeriePublie
    EtatSerieValide             = EtatSerieValide

    serie_publish_select: any   = null
    publish_serie               = 0
    showModalePublishSerie      = false
    message_depublier           = ''
    error_publish               = ''
    loadingConfirmation         = false


    @Watch('ongletSelect')
    resetMeta () {
        this.$store.commit('planning/RESET_META')
    }

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    load () {
        this.$store.dispatch('serie/getSeries').then(() => {
            this.$store.dispatch('matiere/getMatieres').then(() => {
                this.$store.dispatch('concour/getConcoursActifs')
            })
        })
    }

    beforeMount () {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
