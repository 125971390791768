


















































































































import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { BModal } from 'bootstrap-vue'
import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { Ability } from '../../types/Ability'
import PopupEditUser from '../../components/Administration/Users/PopupEditUser.vue'
import ErrorDisplay from '../../components/ErrorDisplay.vue'
import PhoneInput from '../../components/Tools/PhoneInput.vue'
import CreateUser from '../../components/BourseEmploi/CreateUser.vue'
import PopupImport from '../../components/BourseEmploi/Popup/PopupImport.vue'
import SelectUser from '../../components/BourseEmploi/SelectUser.vue'
import { isEmpty } from 'lodash';
import { WorkingEstablishmentInterface } from '@/types/WorkingEstablishment'
import Select from '../../components/BourseEmploi/Select.vue';

@Component({
    name: 'ListeUtilisateursComponent',
    components: {
        ErrorDisplay,
        ExaGenericTable,
        PopupEditUser,
        PhoneInput,
        FontAwesomeIcon,
        BModal,
        PopupImport,
        SelectUser,
        CreateUser,
        Select
    },
    props: {
        establishment: {
            type: Object as () => WorkingEstablishmentInterface,
            required: false,
            default: null
        }
    },
    computed: {
        ...mapGetters('user', ['loading', 'users', 'meta', 'totalRows', 'lastPage', 'totalPage', 'error', 'meta']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        canAddUser(): boolean {
            const can = this.$store.getters['auth/can']
            if (!!this.$route.params.working_establishment_id && this.$route.path === `/bourse_emploi/etablissements/${this.$route.params.working_establishment_id}`) {
                if (can(Ability.ADM_ESTABLISHMENT_MANAGE)) {
                    return true
                }

                const workingEstablishmentsIds = this.$store.getters['user/reportingUserSelect']?.working_establishments?.map((item: any) => item.id)
                if (can(Ability.EST_ESTABLISHMENT_PARENT_OWN_MANAGE) || (can(Ability.EST_ESTABLISHMENT_OWN_MANAGE) && workingEstablishmentsIds?.includes(this.$props.establishment?.id) && can(Ability.EST_INTERV_OWN_MANAGE))) {
                    return true
                }
            }

            return false
        }
    },
    watch: {
        users: {
            handler: 'onReportingUsersChange'
        }
    }
})

export default class ListeUtilisateurs extends Vue {
    isEmpty = isEmpty
    Ability = Ability
    filtres = [] as any
    filtreJustInitiated = false
    dataForTab = [] as Array<any>
    genericfields = [] as any
    params = null as any
    showSelectUserModal = false
    tabSelected = this.isInDetailEstablishment() ? 'search' : 'add'
    userSelected = null as any
    searching = false
    searchString = ''
    searchResults = [] as Array<any>
    savingUserInfo = false
    showEditUserInfo = false
    userToRemove = null as any
    userToEdit = null as any
    showModalMessageDelete = false
    showModalEditRole = false
    processing = false as boolean
    roles = [] as any
    rolesSelected = null as any
    fonction = '' as string
    userInfo = {
        civility: null,
        name: null,
        first_name: null,
        email: null,
        telephone: '',
        telephone_fixe: ''
    } as any

    buildGenericfields () {
        this.genericfields = []
        if (this.isInDetailEstablishment()) {
            this.genericfields.push({ key: 'edit', label: '', sortable: false, class: '', type: 'action' })
        }

        this.genericfields.push(
            { key: 'name', label: 'Nom', sortable: true,  class: '', type: 'text' },
            { key: 'first_name', label: 'Prénom', sortable: true,  class: '', type: 'text' },
            { key: 'email', label: 'Courriel', sortable: true,  class: '', type: 'text' },
            { key: 'telephone', label: 'Téléphone', sortable: false, class: '', type: 'text' },
            { key: 'roles', label: 'Rôle(s)', sortable: true, class: '', type: 'text' },
            { key: 'fonction', label: this.isInDetailEstablishment() ? 'Fonction' : 'Fonction(s)', sortable: false, class: '', type: 'text' },
            { key: 'email_verified', label: 'Activé', sortable: true, class: 'text-center', type: 'text' }
        )

        if (!this.isInDetailEstablishment()) {
            this.genericfields.push({ key: 'workingEstablishment.name', label: 'Établissement(s)', sortable: false, class: '', type: 'text' })
        } else {
            this.genericfields.push({ key: 'delete', label: '', sortable: false, class: 'text-end', type: 'action' })
        }
    }

    /**
     * @description Mise à jour du tableau des utilisateurs
     * @returns {Promise<void>}
     */
    async onReportingUsersChange(): Promise<void> {
        await this.setDataForGenericTab(this.$store.getters['user/users'])
    }

    async delete(user: any): Promise<void> {
        this.userToRemove = user
        this.showModalMessageDelete =  true
    }

    cancelDelete () {
        this.showModalMessageDelete =  false
        this.userToRemove = null
    }

    deleteSuite () {
        if (this.processing) {
            return
        }
        this.processing = true
        this.$store.dispatch('user/deleteReportingUser', { user_id: this.userToRemove.id, working_establishment_id: Number(this.$route.params?.working_establishment_id) })
            .then(async () => {
                this.cancelDelete()
                await this.$store.dispatch('user/getReportingUsers', this.params)
            })
            .finally(() => this.processing = false)
    }

    /**
     * Ouvre la modal de sélection d'un utilisateur
     * @returns {void}
     */
    openSelectUserModal(): void {
        this.showSelectUserModal = true
    }

    /**
     * Ferme la modal de sélection d'un utilisateur
     * @returns {void}
     */
    closeSelectUserModal(): void {
        this.processing = false
        this.showSelectUserModal = false
        this.tabSelected = this.isInDetailEstablishment() ? 'search' : 'add'
        this.searchString = ''
        this.searchResults = []
        this.userInfo = {
            civility: null,
            name: null,
            first_name: null,
            email: null,
            telephone: '',
            telephone_fixe: ''
        }
    }

    /**
     * Ouvre la section d'édition des informations de l'utilisateur
     * @returns {void}
     */
    openEditUserInfo(): void {
        this.userInfo = {
            civility: this.userSelected.civility,
            name: this.userSelected.name,
            first_name: this.userSelected.first_name,
            email: this.userSelected.email,
            telephone: this.userSelected.telephone,
            telephone_fixe: this.userSelected.telephone_fixe
        }
        this.showEditUserInfo = true
    }

    /**
     * Ferme la section d'édition des informations de l'utilisateur
     * @returns {void}
     */
    closeEditUserInfo(): void {
        this.processing = false
        this.showEditUserInfo = false
        this.userInfo = {
            civility: null,
            name: null,
            first_name: null,
            email: null,
            telephone: '',
            telephone_fixe: ''
        }
    }

    /**
     * @description Remplissage du tableau des utilisateurs
     * @param {any} poData - Données à afficher
     * @param {boolean} isLoadMore - Indique si on charge plus de données
     * @returns {Promise<void>}
     */
    async setDataForGenericTab(poData: any, isLoadMore = false): Promise<void> {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            const can = this.$store.getters['auth/can']
            const canViewEstablishment = can(Ability.EST_ESTABLISHMENT_OWN_VIEW) || can(Ability.ADM_ESTABLISHMENT_VIEW)
            const canViewChildEstablishment = can(Ability.EST_ESTABLISHMENT_PARENT_OWN_VIEW) || can(Ability.ADM_ESTABLISHMENT_VIEW)

            for (const result of poData) {
                // Liste des établissements
                const listEtablissements = result.working_establishments
                    ?.map((etablissement: any) =>
                        `<button type="button" class="m-0 p-0 ${canViewEstablishment || canViewChildEstablishment ? 'text-info' : ''} ${this.$route.path === `/bourse_emploi/etablissements/${etablissement.id}` || (!canViewEstablishment && !canViewChildEstablishment) ? 'pe-none' : ''}" onclick="handleTableEvent(['openEtablissement', ${etablissement.id}])">${etablissement.name}</button>`
                    )
                    ?.join('<br />') || '-'

                // Liste des rôles
                const listRoles = result.roles
                    ?.map((role: any) =>
                        `<p class="m-0 p-0">${role.name}</p>`
                    )
                    ?.join('') || '-'

                // Liste des fonctions
                let fonctions = ''
                if (this.isInDetailEstablishment()) {
                    fonctions = result.working_establishments
                    ?.find((establishment: any) => establishment.id === Number(this.$route.params?.working_establishment_id || this.$props.establishment?.id))
                    ?.fonction || '-'
                } else {
                    fonctions = result.working_establishments
                        ?.map((working_establishment: any) => {
                            if (working_establishment?.fonction) {
                                return `<p class="m-0 p-0">${working_establishment.fonction}</p>`
                            }
                        })
                        ?.join('') || '-'
                }

                const status = [{ name: 'circle', class: `text-${result.email_verified ? 'success' : 'danger'}` }]
                const canEdit = (can(Ability.ADM_ESTABLISHMENT_MANAGE) || can(Ability.EST_INTERV_OWN_MANAGE)) && this.$store.getters['auth/authUser'].id !== result.id

                const line: any = []
                if (this.isInDetailEstablishment()) {
                    if (canEdit) {
                        line.push({ label: 'Éditer', item: result, type: 'action', typeAction: 'edit', class: 'commons_first_action_button', icon: 'pen', disabled: false })
                    } else {
                        line.push({ label: '', item: null, type: 'text', disabled: true })
                    }
                }

                line.push(
                    { label: '', item: result.name || '-', type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.first_name || '-', type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.email || '-', type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.telephone || '-', type: 'text', typeAction: null, class: '' },
                    { label: '', item: listRoles || '-', type: 'html', typeAction: null, class: '' },
                    { label: '', item: fonctions, type: 'html', typeAction: null, class: '' },
                    { label: '', item: status, type: 'icons', typeAction: 'editStatus', class: 'text-center' }
                )

                if (!this.isInDetailEstablishment()) {
                    line.push({ label: '', item: listEtablissements, type: 'html', typeAction: null, class: '' })
                } else {
                    line.push({
                        label: canEdit ? 'Supprimer' : '',
                        item: canEdit ? result : '',
                        type: canEdit ? 'action' : 'text',
                        typeAction: canEdit ? 'delete' : '',
                        class: 'text-secondary text-end',
                        icon: 'trash-alt',
                        disabled: !canEdit
                    })
                }

                this.dataForTab.push(line)
            }
        }
    }

    /**
     * @description Initialisation des filtres du tableau
     * @returns {void}
     */
    setFiltersForGenericTab(): void {
        this.filtres = [
            { libelle: 'Nom', defautOptionlibelle: 'Rechercher un', model: 'name', value: '', index: 'name', datas: null, loading: this.$store.getters['user/loading'], options: { type: 'form', fieldsKey: 'name' }},
            { libelle: 'Prénom', defautOptionlibelle: 'Rechercher un', model: 'first_name', value: '', index: 'first_name', datas: null, loading: this.$store.getters['user/loading'], options: { type: 'form', fieldsKey: 'first_name' }},
            { libelle: 'Courriel', defautOptionlibelle: 'Rechercher un', model: 'email', value: '', index: 'email', datas: null, loading: this.$store.getters['user/loading'], options: { type: 'form', fieldsKey: 'email' }}
        ]
    }

    /**
     * @description Gestion des événements du tableau
     * @param {any} paParams - Paramètres de l'événement
     * @returns {Promise<void>}
     */
    async handleTableEvent(paParams: any): Promise<void> {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'openEtablissement':
                    await this.$router.push(`/bourse_emploi/etablissements/${paParams[1]}`)
                    break

                case 'sortHandler':
                case 'filterHandler':
                    await this.filtreSortHandler(paParams[1])
                    break

                case 'onLoadPage':
                    await this.loadHandler(paParams[1])
                    break

                case 'delete':
                    this.delete(paParams[1])
                    break

                case 'edit':
                    this.showEditRole(paParams[1])
                    break
            }
        }
    }

    /**
     * @description Applique des filtres
     * @param {any} params - Paramètres de filtre
     * @returns {Promise<void>}
     */
    async filtreSortHandler(params: any): Promise<void> {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params

            if (this.isInDetailEstablishment()) {
                this.params['filter-working_establishment'] = this.$route.params.working_establishment_id
            }
        }
        if (this.filtreJustInitiated) {
            this.filtreJustInitiated = false
        } else {
            await this.$store.dispatch('user/getReportingUsers', this.params)
        }
    }

    /**
     * @description Complément des données sur un scroll
     * @param {any} params - Paramètres de chargement
     * @returns {Promise<void>}
     */
    async loadHandler(params: any): Promise<void> {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params

            if (this.isInDetailEstablishment()) {
                this.params['filter-working_establishment'] = this.$route.params.working_establishment_id
            }
        }
        await this.$store.dispatch('user/getMoreReportingUsers', this.params)
    }

    /**
     * @description Ouverture de la modale d'édition des rôles d'un utilisateur
     * @param {any} user - Utilisateur
     * @returns {void}
     */
    showEditRole(user: any): void {
        this.userToEdit = Object.assign({}, user)
        this.fonction = user.working_establishments
            ?.find((establishment: any) => establishment.id === Number(this.$route.params?.working_establishment_id || this.$props.establishment?.id))
            ?.fonction || '-'
        this.roles = this.$props.establishment?.workingEstablishmentType?.roles.map((role: any) => ({ title: role }))
        this.rolesSelected = user.roles.map((role: any) => JSON.parse(JSON.stringify({ title: role.name })))
        this.showModalEditRole = true
    }

    /**
     * @description Fermeture de la modale d'édition des rôles d'un utilisateur
     * @returns {void}
     */
    cancelEditRole(): void {
        this.showModalEditRole = false
        this.fonction = ''
        this.roles = []
        this.rolesSelected = []
        this.userToEdit = null
    }

    /**
     * @description Edition des rôles d'un utilisateur
     * @returns {void}
     */
    editRole(): void {
        if (this.processing || isEmpty(this.rolesSelected)) {
            return
        }
        this.processing = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours...', infosToaster)

        this.$store.dispatch('user/updateReportingUser', {
            user_id: this.userToEdit.id,
            working_establishment_id: Number(this.$route.params?.working_establishment_id),
            roles: this.rolesSelected.map((role: any) => role.title),
            fonction: this.fonction
        })
            .then(async () => {
                await this.$store.dispatch('user/getReportingUsers', this.params)
                this.cancelEditRole()
            })
            .finally(() => {
                this.processing = false
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * @description Avant le montage du composant
     * @returns {Promise<void>}
     */
    async beforeMount(): Promise<void> {
        (window as any).handleTableEvent = this.handleTableEvent
        this.params = {}
        this.params['page'] = 1
        this.params['sort'] = 'name'
        this.params['direction'] = 'asc'

        if (this.isInDetailEstablishment()) {
            this.params['filter-working_establishment'] = this.$route.params.working_establishment_id
        }

        this.buildGenericfields()
        this.setFiltersForGenericTab()
        await this.$store.dispatch('user/getReportingUsers', this.params)
    }

    /**
     * @description Montage du composant
     * @returns {Promise<void>}
     */
    async mounted(): Promise<void> {
        (window as any).handleTableEvent = this.handleTableEvent
        if (!this.$store.getters['auth/can'](Ability.ADM_ESTABLISHMENT_MANAGE) && isEmpty(this.$store.getters['user/reportingUserSelect'])) {
            await this.$store.dispatch('user/getReportingUser', { user_id: this.$store.getters['auth/authUser']?.id })
        }
    }

    /**
     * @description Avant destruction du composant
     * @returns {void}
     */
    beforeDestroy(): void {
        (window as any).handleTableEvent = null
    }

    /**
     * @description Vérifie si l'utilisateur consulte en détail un établissement
     * @returns {void}
     */
    isInDetailEstablishment(): boolean {
        return !!this.$route.params.working_establishment_id && this.$route.path === `/bourse_emploi/etablissements/${this.$route.params.working_establishment_id}`
    }
}
