











































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component, Watch }    from 'vue-property-decorator'
import { mapGetters, mapState }     from 'vuex'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'
import { Ability }                  from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { checkIcone, formatDate, formatDateSinTime, getFileNameFromHeader } from '@/utils/helpers'
import { MessageIndicationType } from '@/types/MessageIndicationType'
import MessageIndication from '@/components/MessageIndication.vue'
import Back from '@/components/Tools/Back.vue'
import PopupEditCandidat            from '@/components/Candidat/PopupEditCandidat.vue'
import {TypeMesure} from '@/types/Amenagement'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { isEmpty, orderBy } from 'lodash'
import { DecisionAmenagementInterface, EtatDecisionAmenagement } from "@/types/DecisionAmenagement";


@Component({
    methods: {
        isEmpty
    },
    computed: {
        EtatDecisionAmenagement() {
            return EtatDecisionAmenagement
        },
        TypeMesure() {
            return TypeMesure
        },
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapState('repartitioncandidats', ['liste_candidats', 'meta_candidats', 'loading_repartition', 'error_repartition', 'totalRows', 'lastPage']),
        ...mapGetters('repartitioncandidats', ['get_centres_satures'])
    },
    components: {
        ExaGenericTable,
        'font-awesome-icon': FontAwesomeIcon,
        MessageIndication,
        PopupEditCandidat,
        Back,
        ErrorDisplay
    }
})

export default class GestionRepartitions extends Vue
{
    formatDate        = formatDate
    formatDateSinTime = formatDateSinTime
    tableLoading      = false


    options_centres: Array<any> = []
    options_filieres: Array<any> = []
    options_villes: Array<any> = []
    options_etablissements: Array<any> = []
    options_academies: Array<object> = []
    options_zones: Array<any> = []
    centre_select_id: any = null
    filiere_select_id = null
    sp_select_id = null
    zone_select_id = null
    sds_select_id = null
    ville_select_id = null
    etablissement_select_text = null
    academie_select_text = null
    affectation_select_id = null
    affichage_amenagement_id = null
    classe_preparatoire = null
    legitimite_academique = null
    showDismissibleAlert = true
    centre_ville_deplace_id = null
    ville_select_souhait_principal = null

    Ability = Ability
    MessageIndicationType = MessageIndicationType

    genericfields = [
        { key: 'etatEdit', label: '', sortable: false,    class: '', type: 'action' },
        { key: 'code',  label: 'Code', sortable: true,  class: '', type: 'text' },
        { key: 'name',  label: 'Identité', sortable: true, class: '', type: 'text' },
        { key: 'bureau_distribution', label: 'Ville de résidence', sortable: true, class: '', type: 'text' },
        { key: 'etablissement.name', label: 'Etablissement', sortable: true, class: '', type: 'text' },
        { key: 'etablissement.academie', label: 'Académie', sortable: true, class: '', type: 'text' },
        { key: 'demande_amenagement', label: 'Aménagements', sortable: true, class: '', type: 'text' },
        { key: 'classe', label: 'Classe prépa.', sortable: true, class: 'text-center', type: 'text' },
        { key: 'concour.name', label: 'Filière', sortable: true, class: 'text-center', type: 'text' },
        { key: 'souhait1.name', label: 'Souhait principal', sortable: true, class: 'text-start', type: 'text' },
        { key: 'souhait2.name', label: 'Souhait de secours', sortable: true, class: 'text-start', type: 'text' },
        { key: 'legitimite_academique', label: 'Académie / Souhait de secours', sortable: false, class: 'text-center', type: 'text' },
        { key: 'centre.ville.name', label: 'Centre affecté', sortable: true, class: 'text-start', type: 'text' },
        { key: 'deplacement',  label: '', sortable: false,  class: '', type: 'text' }
    ]

    sortDirection   = 'asc'

    filtres:    any         = []
    dataForTab: Array<any>  = []
    allEpreuves             = []
    params: any = {}
    exportWorking = false
    params_search: any = {}
    is_search = false

    showModalEditCandidat = false
    showModalDeplaceCandidat = false
    showModalDeplaceSelectionCandidat = false
    selected_candidat: any = null
    centre_select: any = null
    selection_candidats: any = []
    loading_datas = false
    options_centre_villes: any = []
    loading_centre_ville = false
    old_liste_centres: Array<any> = []
    save_en_cours = false
    loading_selection_candidats = false
    no_filter = true

    @Watch('liste_candidats')
    getExaminateurs() {
        this.setDataForGenericTab(this.$store.state.repartitioncandidats.liste_candidats)
    }

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    @Watch('centre_select_id')
    reinitCentreSelect () {
        if (this.centre_select_id === null) {
            this.centre_select = null
        }
    }

    /**
     * getDecisionAmenagementsEtat
     * Récupération de l'état de la décision d'aménagement
     * @param {any} decisionAmenagements - Liste des décisions d'aménagement
     * @returns {EtatDecisionAmenagement | null} Etat de la décision d'aménagement
     */
    getDecisionAmenagementsEtat(decisionAmenagements: any): EtatDecisionAmenagement | null {
        if (!isEmpty(decisionAmenagements)) {
            return decisionAmenagements.find((d: DecisionAmenagementInterface) => d.type === TypeMesure.TYPE_ECRIT)?.etat
        }
        return null
    }

    // Création des lignes du tableau
    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            const can = this.$store.getters['auth/can'](Ability.ECR_PREPA_MANAGE)
            const icone = checkIcone(Ability.ECR_PREPA_MANAGE, can)
            for (const result of poData)
            {
                const identity = result.name && result.first_name ? result.name + ' ' +  result.first_name : ' - '
                const centre_affecte = result.centre ? result.centre.ville.name + ' - ' + result.centre.name : ''
                // const zone = result.centre && result.centre.ville && result.centre.ville.zone ? result.centre.ville.zone.name : ''
                const full_etablissement =  result.etablissement ? result.etablissement.name : ''

                let bg_souhaits = ''
                let bg_line = 'bg_light'
                let bg_centre_affecter = 'barre_tertiary'

                // Demande daménagement sur le candidat
                const iconDemandeAm: any[] = []
                let titleDemandeAmenagement = ''
                let decision: DecisionAmenagementInterface = {} as DecisionAmenagementInterface

                // Récupération de la décision d'aménagement
                if (!isEmpty(result.decisionAmenagements)) {
                    const index = result.decisionAmenagements.findIndex(
                        (d: DecisionAmenagementInterface) => d.type === TypeMesure.TYPE_ECRIT
                    )
                    decision = result.decisionAmenagements[index]
                }

                if (result.demande_amenagement) {
                    let state: {title: string; class: string} = { title: 'Non traitée', class: 'text-secondary' }

                    if (!isEmpty(decision) && decision.etat === EtatDecisionAmenagement.ETAT_VALIDE) {
                        state = { title: 'Traitée', class: 'text-success' }
                    }
                    iconDemandeAm.push({
                        name: 'check',
                        class: state.class,
                        title: state.title
                    })
                    titleDemandeAmenagement = state.title
                }

                // Identification d'un candidat déplacé
                const iconDeplacement: any[] = []
                if (result.repartition_manuelle === 1) {
                    iconDeplacement.push({ name:'exchange-alt', class:'text-info', title: 'Candidat déplacé manuellement' })
                    bg_souhaits = 'barre_soumis'
                    bg_line = 'barre_soumis'
                    bg_centre_affecter = 'barre_soumis'
                }

                // Coloration des villes selon saturation pour le souhait principal et secondaire
                let color_souhait_principal = 'text-danger'
                let color_souhait_secondaire = 'text-danger'

                if (result.concour && result.souhait1_ville_id && result.souhait2_ville_id) {
                    const liste_centre_for_candidat_p = this.$store.state.repartitioncandidats.liste_centres.filter((c: any) => c.ville_id === result.souhait1_ville_id)

                    if (liste_centre_for_candidat_p.length !== 0) {
                    // Recherche dans cette liste le concours du candidat pour connaître l'état de saturation
                    // Si un parmis tous les centres saturation à 0 -> Non saturée | Sinon ville saturée
                        for (const c in liste_centre_for_candidat_p) {
                            if (liste_centre_for_candidat_p[c].saturation[result.concour.id] && liste_centre_for_candidat_p[c].saturation[result.concour.id].saturation === 0) {
                                color_souhait_principal = 'text-success'
                            } else if (liste_centre_for_candidat_p[c].saturation[result.concour.id] && liste_centre_for_candidat_p[c].saturation[result.concour.id].saturation === 2) {
                                color_souhait_principal = 'text-secondary'
                            }
                        }
                    }

                    const liste_centre_for_candidat_s = this.$store.state.repartitioncandidats.liste_centres.filter((c: any) => c.ville_id === result.souhait2_ville_id)

                    if (liste_centre_for_candidat_s.length !== 0) {
                    // Recherche dans cette liste le concours du candidat pour connaître l'état de saturation
                    // Si un parmis tous les centres saturation à 0 -> Non saturée | Sinon ville saturée
                        for (const c in liste_centre_for_candidat_s) {
                            if (liste_centre_for_candidat_s[c].saturation[result.concour.id] && liste_centre_for_candidat_s[c].saturation[result.concour.id].saturation === 0) {
                                color_souhait_secondaire = 'text-success'
                            } else if (liste_centre_for_candidat_s[c].saturation[result.concour.id] && liste_centre_for_candidat_s[c].saturation[result.concour.id].saturation === 2) {
                                color_souhait_secondaire = 'text-secondary'
                            }
                        }
                    }
                }

                const iconLegitimiteAca: any[] = []
                iconLegitimiteAca.push({
                    name: 'circle',
                    class: result.legitimite_academique ? 'text-success' : 'text-danger',
                    title: result.legitimite_academique ? 'Académie correspondante' : 'Académie non correspondante'
                })

                const line: any = [
                    { label: icone.label,       item: result.id,    type: 'action',     typeAction: 'edit',      class: 'commons_first_action_button btn_action_ligne', icon:icone.icon, disabled: false },
                    { label: 'Fiche candidat',  item: result.id,    type: 'actionText', typeAction: 'editCandidat', class: 'text-info item_action ' + bg_line, text: result.code },
                    { label: 'Fiche candidat',  item: result.id,    type: 'actionText', typeAction: 'editCandidat', class: 'text-info item_action width_col_identity ' + bg_line, text: identity },
                    { label: '',                item: (result.code_postal && result.code_postal !== null && result.code_postal.toLowerCase().trim() !== 'null' ? result.code_postal.toLowerCase().trim() : '') + ' ' + (result.bureau_distribution && result.bureau_distribution !== null && result.bureau_distribution.toLowerCase().trim() !== 'null' ? result.bureau_distribution : '-'),      type: 'text',  typeAction: null,  class: bg_line },
                    { label: '',                item: full_etablissement,      type: 'text',  typeAction: null,  class: bg_line },
                    { label: '',                item: result.etablissement ? result.etablissement.academie : '',      type: 'text',  typeAction: null,  class: bg_line + ' text-uppercase' },
                    { label: '',                item: iconDemandeAm,  type: 'icons',   typeAction: null,  class: 'text-center ' + bg_line, title: titleDemandeAmenagement },
                    { label: '',                item: result.classe ? result.classe : '-',   type: 'text',  typeAction: null,  class: 'text-center ' + bg_line },
                    { label: '',                item: result.concour ? result.concour.name : '-',   type: 'text',  typeAction: null,  class: 'text-center ' + bg_line },
                    { label: '',                item: result.souhait1_ville_id ? result.souhait1.name : '',      type: 'text',  typeAction: null,  class: 'item_action_souhait text-start ' + bg_souhaits + ' ' + color_souhait_principal },
                    { label: '',                item: result.souhait2_ville_id ? result.souhait2.name : '',      type: 'text',  typeAction: null,  class: 'item_action_souhait text-start ' + bg_souhaits + ' ' + color_souhait_secondaire },
                    { label: '',                item: iconLegitimiteAca,      type: 'icons',  typeAction: null,  class: 'text-center width_col_legit ' + bg_souhaits },
                    { label: '',                item: centre_affecte,      type: 'text',  typeAction: null,  class: 'item_action_affectation text-secondary text-start ' + bg_centre_affecter },
                    { label: '',                item: iconDeplacement,  type: 'icons',   typeAction: null,  class: 'text-center ' + bg_centre_affecter, title: '' }
                ]

                this.dataForTab.push(line)
            }
        }
    }

    // Création des filtres pour le tableau
    setFiltersForGenericTab()
    {
        this.filtres =
        [
            { libelle: 'Code',  defautOptionlibelle: 'Rechercher un',   model: 'code',  value: '', index: 'code',   datas: null,  loading: this.$store.state.repartitioncandidats.loading_repartition, options: { type: 'form', fieldsKey: 'code' } },
            { libelle: 'Nom',   defautOptionlibelle: 'Rechercher un',   model: 'name',  value: '', index: 'name',   datas: null,  loading: this.$store.state.repartitioncandidats.loading_repartition, options: { type: 'form', fieldsKey: 'name' } },
            { libelle: 'Code postal',   defautOptionlibelle: 'Rechercher un',   model: 'code_postal',  value: '', index: 'code_postal',   datas: null,  loading: this.$store.state.repartitioncandidats.loading_repartition, options: { type: 'form', fieldsKey: 'bureau_distribution', strict: true } }
        ]
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        let selectedCandidat = null
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'sortHandler':
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'editCandidat':
                    selectedCandidat = this.$store.state.repartitioncandidats.liste_candidats.filter((r: any) => r.id === paParams[1])[0]
                    if (selectedCandidat)
                    {
                        this.editCandidat(selectedCandidat)
                    }
                    break
                case 'edit':
                    selectedCandidat = this.$store.state.repartitioncandidats.liste_candidats.filter((r: any) => r.id === paParams[1])[0]
                    if (selectedCandidat)
                    {
                        this.selected_candidat = selectedCandidat
                        this.ville_select_souhait_principal = selectedCandidat.souhait1_ville_id
                        this.deplace_candidat()
                    }
                    break
                default:
                    break
            }
        }
    }

    // Applique le chargement de la pagination
    loadHandler (params: any) {
        if (params.page <= this.$store.state.repartitioncandidats.lastPage) {
            Vue.set(params, 'filter-centre_id', this.centre_select_id)
            Vue.set(params, 'filter-concour_id', this.filiere_select_id)
            Vue.set(params, 'filter-souhait1_ville_id', this.sp_select_id)
            Vue.set(params, 'filter-souhait2_ville_id', this.sds_select_id)
            Vue.set(params, 'filter-centre.ville_id', this.ville_select_id)
            Vue.set(params, 'filter-centre.ville.zone_id', this.zone_select_id)
            Vue.set(params, 'filter-etablissement.name', this.etablissement_select_text && this.etablissement_select_text !== '' ? '%' + this.etablissement_select_text + '%' : null)
            Vue.set(params, 'filter-etablissement.academie', this.academie_select_text && this.academie_select_text !== '' ? '%' + this.academie_select_text + '%' : null)
            Vue.set(params, 'filter-repartition', this.affectation_select_id)
            Vue.set(params, 'filter-demande_amenagement', this.affichage_amenagement_id)

            Vue.set(params, 'excel', 0)
            this.$store.dispatch('repartitioncandidats/getMoreListeCandidats', params)
        }
    }

    // Applique les filtres
    filtreSortHandler(params: any) {
        Vue.set(params, 'filter-centre_id', this.centre_select_id)
        Vue.set(params, 'filter-concour_id', this.filiere_select_id)
        Vue.set(params, 'filter-souhait1_ville_id', this.sp_select_id)
        Vue.set(params, 'filter-souhait2_ville_id', this.sds_select_id)
        Vue.set(params, 'filter-centre.ville_id', this.ville_select_id)
        Vue.set(params, 'filter-centre.ville.zone_id', this.zone_select_id)
        Vue.set(params, 'filter-etablissement.name', this.etablissement_select_text && this.etablissement_select_text !== '' ? '%' + this.etablissement_select_text + '%' : null)
        Vue.set(params, 'filter-etablissement.academie', this.academie_select_text && this.academie_select_text !== '' ? '%' + this.academie_select_text + '%' : null)
        Vue.set(params, 'filter-repartition', this.affectation_select_id)
        Vue.set(params, 'filter-demande_amenagement', this.affichage_amenagement_id)
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.$store.dispatch('repartitioncandidats/getListeCandidats', params)
        }
    }

    // Export de la liste des candidats au format excel
    exporter_repartition () {
        this.$store.commit('repartitioncandidats/SET_ERROR', null)
        let fileName = ''
        this.exportWorking = true

        Vue.set(this.params, 'excel', 1)

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours...', infosToaster)

        this.$store.dispatch('repartitioncandidats/getExaminateursPresExcel', { params : this.params })
            .then(response => {
                const fileNameTemp = getFileNameFromHeader(response.headers)
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.exportWorking = false
            })
    }


    /**
     * Lance la requete de sélection
     */
    rechercher () {
        // Initialisation des filtres
        this.loading_datas = true
        this.no_filter = true
        this.$store.state.repartitioncandidats.error = null
        this.$store.commit('repartitioncandidats/SET_META', null)
        this.$store.commit('repartitioncandidats/SET_ERROR', null)
        Vue.set(this.params, 'filter-centre_id', this.centre_select_id)
        Vue.set(this.params, 'filter-concour_id', this.filiere_select_id)
        Vue.set(this.params, 'filter-souhait1_ville_id', this.sp_select_id)
        Vue.set(this.params, 'filter-souhait2_ville_id', this.sds_select_id)
        Vue.set(this.params, 'filter-centre.ville_id', this.ville_select_id)
        Vue.set(this.params, 'filter-centre.ville.zone_id', this.zone_select_id)
        Vue.set(this.params, 'filter-etablissement.name', this.etablissement_select_text && this.etablissement_select_text !== '' ? '%' + this.etablissement_select_text + '%' : null)
        Vue.set(this.params, 'filter-etablissement.academie', this.academie_select_text && this.academie_select_text !== '' ? '%' + this.academie_select_text + '%' : null)
        Vue.set(this.params, 'filter-repartition', this.affectation_select_id)
        Vue.set(this.params, 'filter-demande_amenagement', this.affichage_amenagement_id)
        Vue.set(this.params, 'filter-classe', this.classe_preparatoire && this.classe_preparatoire !== '' ? this.classe_preparatoire : null)
        Vue.set(this.params, 'filter-legitimite_academique', this.legitimite_academique)

        // Enleve la contrainte de non pagination
        Vue.set(this.params, 'perPage', null)

        if (this.centre_select_id !== null || this.filiere_select_id !== null || this.sp_select_id !== null || this.sds_select_id !== null ||
        this.ville_select_id !== null || this.zone_select_id !== null || this.etablissement_select_text !== null || this.affectation_select_id !== null || this.affichage_amenagement_id !== null) {
            // Au moins un filtre du requêteur est sélectionné, on active le bouton
            this.no_filter = false
        }

        // Init des infos du centre pour le bandeau de rappel
        // Compteur détaillé et compteur global
        this.centre_select = null
        if (this.centre_select_id && this.centre_select_id !== -1 && this.centre_select_id !== -2) {
            this.$store.dispatch('repartitioncandidats/getListeCentre', { centre_id: this.centre_select_id }).then(() => {
                this.centre_select = this.$store.state.repartitioncandidats.liste_centres.find((c: any) => c.id.toString() === this.centre_select_id.toString())
                if (this.centre_select) {
                    let capacite = '<div class="ligne_concours">'
                    const liste_concours = orderBy(this.$store.getters['concour/banques'], 'ordre', 'asc')
                    for (const c in liste_concours) {
                        const has_concours =  this.centre_select.repartition_concours.filter((co: any) => co.concour_id === liste_concours[c].id)

                        if (has_concours) {
                            let nb_capacite = 0
                            let nb_candidats = 0

                            for (const h in has_concours) {
                                nb_capacite += has_concours[h].capacite
                                nb_candidats += has_concours[h].nb_candidats
                            }

                            let class_color = 'text-secondary'

                            if (nb_candidats > nb_capacite) {
                                class_color = 'barre_text_rejet'
                                this.centre_select.class_libelle = 'barre_text_rejet'
                                this.centre_select.nb_exces = (nb_candidats - nb_capacite)
                            } else if (nb_candidats === nb_capacite) {
                                class_color = 'barre_text_soumis'
                            } else if (nb_candidats <= nb_capacite && nb_candidats !== 0) {
                                class_color = 'barre_text_valide'
                            }

                            if (nb_capacite !== 0) {
                                capacite += '<div class="celulle_concours ' + class_color + '"><strong>' + liste_concours[c].name + '</strong>  ' + nb_candidats + ' / ' + nb_capacite + '</div>'
                            } else {
                                capacite += '<div class="celulle_concours"></div>'
                            }
                        } else {
                            capacite += '<div class="celulle_concours"></div>'
                        }
                    }

                    capacite += '</div>'
                    this.centre_select.capacite_string = capacite
                }
            })
        }

        // Lance la recherche
        this.$store.dispatch('repartitioncandidats/getListeCandidats', this.params).then(() => {
            this.setDataForGenericTab(this.$store.state.repartitioncandidats.liste_candidats)
            this.loading_datas = false
        })
    }

    /**
     * Affichage de la modale du dossier candidat
     */
    editCandidat (item: any)
    {
        if (item && item.id !== undefined)
        {
            // On charge à partir de la BDD l'ensemble des informations du candidat sélectionné
            this.$store.commit('reclamation/SET_LOADING', true) // on lance le loading
            this.$store.dispatch('candidat/getCandidat', item).then(() => {
                const params =
                {
                    candidatId: item.id,
                    nomFiliere: item.filiere
                }
                this.$store.commit('candidat/SET_CANDIDAT_FILIERE', params)
                this.$store.commit('candidat/SET_SELECTED_CANDIDAT', item.id)
                this.showModalEditCandidat = true
                this.$store.commit('reclamation/SET_LOADING', false) // on lance le loading
            })
        }
    }

    /**
     * Fermeture de la modale du dossier candidat
     */
    reinitShowModalEditCandidat ()
    {
        this.showModalEditCandidat = false
    }

    // Déplace un candidat vers un autre centre
    deplace_candidat () {
        this.$store.state.repartitioncandidats.error_repartition = null
        if (this.ville_select_souhait_principal) {
            // Chargement des centres sur le souhait principal
            const e = {
                target : {
                    value : this.ville_select_souhait_principal
                }
            }
            this.loadCentreVilles(e)
        }
        this.showModalDeplaceCandidat = true
    }

    // Annule le déplacement d'un candidat vers un autre centre
    cancel_deplacement_candidat () {
        this.showModalDeplaceCandidat = false
        this.showModalDeplaceSelectionCandidat = false
        this.selected_candidat = null
        this.options_centre_villes = []
        this.centre_ville_deplace_id = null
        this.selection_candidats = []
    }

    // Enregistre le déplacement d'un candidat vers un autre centre
    save_deplacement_candidat () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Déplacement en cours...', infosToaster)

        this.save_en_cours = true
        this.$store.dispatch('repartitioncandidats/deplacerCandidat', {
            candidat_id: this.selected_candidat.id,
            centre_id: this.centre_ville_deplace_id,
            selected_centre_id: this.centre_select_id
        })
            .then(async () => {
                this.showModalDeplaceCandidat = false
                this.selected_candidat = null
                this.options_centre_villes = []
                this.centre_ville_deplace_id = null

                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Déplacement terminé.', succesToaster)

                // Refresh des centres et relance de la recherche
                await this.$store.dispatch('repartitioncandidats/getListeCentres')
                this.old_liste_centres = this.$store.state.repartitioncandidats.liste_centres
                this.rechercher()
            })
            .catch((error: any) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.save_en_cours = false
            })
    }

    // Enregistre le déplacement de la sélection de candidats vers un centre
    save_deplacement_selection_candidat () {
        this.save_en_cours = true
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Déplacement de la sélection en cours...', infosToaster)
        this.$store.dispatch('repartitioncandidats/deplacerCandidatMass', { candidat_ids: this.selection_candidats, centre_id: this.centre_ville_deplace_id })
            .then(async () => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Déplacement de la sélection terminé.', succesToaster)

                // Refresh des centres et relance de la recherche
                await this.$store.dispatch('repartitioncandidats/getListeCentres')
                this.selection_candidats = []
                this.options_centre_villes = []
                this.showModalDeplaceSelectionCandidat = false
                this.centre_ville_deplace_id = null

                this.old_liste_centres = this.$store.state.repartitioncandidats.liste_centres
                this.rechercher()
            })
            .catch((error: any) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.save_en_cours = false
            })
    }

    // Ouvre le popup de déplacement d'une sélection de candidat
    open_deplacement_selection () {
        this.$store.state.repartitioncandidats.error_repartition = null
        this.showModalDeplaceSelectionCandidat = true
        this.loading_selection_candidats = true

        this.$store.dispatch('repartitioncandidats/getListeCandidats', { ...this.params, perPage: -1 })
            .then(() => {
                for (const c in this.$store.state.repartitioncandidats.liste_candidats) {
                    this.selection_candidats.push(this.$store.state.repartitioncandidats.liste_candidats[c].id)
                }
                this.loading_selection_candidats = false
            })
    }

    // Sélection dans la sélection initiale des candidats à déplacer
    select_candidats (e: any) {
        if (e.target.id === 'check_all') {
            // Cas de la selection / déselection complète
            if (e.target.checked === true) {
                this.selection_candidats = []
                for (const c in this.$store.state.repartitioncandidats.liste_candidats) {
                    this.selection_candidats.push(this.$store.state.repartitioncandidats.liste_candidats[c].id.toString())
                }
            } else {
                this.selection_candidats = []
            }
        } else {
            // Ajout ou suppression au cas par cas
            if (e.target.checked === true) {
                this.selection_candidats.push(e.target.value)
            } else {
                const index = this.selection_candidats.findIndex((c: any) => c === e.target.value)
                this.selection_candidats.splice(index, 1)
            }
        }
    }

    // Chargement des centres selon la ville sélectionnée
    loadCentreVilles (e: any) {
        this.$store.state.repartitioncandidats.error_repartition = null
        this.loading_centre_ville = true
        this.options_centre_villes = []
        const ville_id = e.target.value
        const params: any = {}
        Vue.set(params, 'filter-ville_id', ville_id)
        this.$store.dispatch('repartitioncandidats/getListeCentres', params).then(() =>  {
            for (const c in this.$store.state.repartitioncandidats.liste_centres) {
                const centre_select = this.$store.state.repartitioncandidats.liste_centres[c]
                const has_concours_candidat = centre_select.concours.filter((c: any) => c.id === this.selected_candidat.concour.id)

                // Si le centre dispose de la filière du candidat, il est éligible au déplacement
                if (has_concours_candidat.length !== 0 && centre_select.submitted_at) {
                    let capacite = '<div class="ligne_concours">'
                    for (const c in this.$store.getters['concour/banques']) {
                        const has_concours = centre_select.repartition_concours.filter((co: any) => co.concour_id === this.$store.getters['concour/banques'][c].id)

                        if (has_concours) {
                            let nb_capacite = 0
                            let nb_candidats = 0

                            for (const h in has_concours) {
                                nb_capacite += has_concours[h].capacite
                                nb_candidats += has_concours[h].nb_candidats
                            }

                            let class_color = 'text-dark'

                            if (nb_candidats > nb_capacite) {
                                class_color = 'text-danger'
                            } else if (nb_candidats === nb_capacite) {
                                class_color = 'text-secondary'
                            } else if (nb_candidats <= nb_capacite) {
                                class_color = 'text-success'
                            }

                            if (nb_capacite !== 0) {
                                capacite += '<div class="celulle_concours ' + class_color + '"><strong>' + this.$store.getters['concour/banques'][c].name + '</strong>  ' + nb_candidats + ' / ' + nb_capacite + '</div>'
                            }
                        }
                    }

                    capacite += '</div>'

                    this.options_centre_villes.push({ id: centre_select.id, name: centre_select.name, capacite: capacite })
                }
            }
            this.loading_centre_ville = false
        }).catch((error: any) => {
            this.$store.state.repartitioncandidats.error_repartition = error
        })
    }

    // Chargement des centres selon la ville sélectionnée - mode sélection de candidats
    loadCentreVillesSelection (e: any) {
        this.$store.state.repartitioncandidats.error_repartition = null
        this.loading_centre_ville = true
        this.options_centre_villes = []
        const ville_id = e.target.value
        const params: any = {}
        Vue.set(params, 'filter-ville_id', ville_id)
        const presence_concours: any = []
        for (const c in this.$store.state.repartitioncandidats.liste_candidats) {
            if (!presence_concours.find((cc: any) => cc === this.$store.state.repartitioncandidats.liste_candidats[c].concour.id)) {
                presence_concours.push(this.$store.state.repartitioncandidats.liste_candidats[c].concour.id)
            }
        }

        this.$store.dispatch('repartitioncandidats/getListeCentres', params).then(() =>  {
            for (const c in this.$store.state.repartitioncandidats.liste_centres) {
                const centre_select = this.$store.state.repartitioncandidats.liste_centres[c]
                let has_concours = true

                for (const b in presence_concours) {
                    const check_presence_concours = centre_select.concours.filter((c: any) => c.id === presence_concours[b])
                    if (check_presence_concours.length === 0) {
                        // S'il manque un concours du tableau de présence le centre n'est pas sélectionnable
                        has_concours = false
                    }
                }

                // Si le centre dispose de la filière du candidat, il est éligible au déplacement
                if (has_concours && centre_select.submitted_at) {
                    let capacite = '<div class="ligne_concours">'
                    for (const c in this.$store.getters['concour/banques']) {
                        const has_concours = centre_select.repartition_concours.filter((co: any) => co.concour_id === this.$store.getters['concour/banques'][c].id)

                        if (has_concours) {
                            let nb_capacite = 0
                            let nb_candidats = 0

                            for (const h in has_concours) {
                                nb_capacite += has_concours[h].capacite
                                nb_candidats += has_concours[h].nb_candidats
                            }

                            let class_color = 'text-dark'

                            if (nb_candidats > nb_capacite) {
                                class_color = 'text-danger'
                            } else if (nb_candidats <= nb_capacite && nb_candidats !== 0) {
                                class_color = 'text-success'
                            }

                            if (nb_capacite !== 0) {
                                capacite += '<div class="celulle_concours ' + class_color + '"><strong>' + this.$store.getters['concour/banques'][c].name + '</strong>  ' + nb_candidats + ' / ' + nb_capacite + '</div>'
                            }
                        }
                    }

                    capacite += '</div>'

                    this.options_centre_villes.push({ id: centre_select.id, name: centre_select.name, capacite: capacite })
                }
            }
            this.loading_centre_ville = false
        }).catch((error: any) => {
            this.$store.state.repartitioncandidats.error_repartition = error
        })
    }

    // Retourne la capacite du centre
    getCapaciteCentre () {
        return this.$store.getters['repartitioncandidats/get_capacite_centre'](this.centre_select)
    }

    // Retourne le nombre de candidats affectés dans un centre
    getCandidatsAffectesCentre () {
        return this.$store.getters['repartitioncandidats/get_candidats_affectes_centre'](this.centre_select)
    }

    // Retourne les classes pour le bandeau du centre sélectionné
    getClassBandeauCentre () {
        if (this.getCandidatsAffectesCentre() > this.getCapaciteCentre()) {
            return { bg: 'barre_rejet', text: 'barre_text_rejet' }
        } else if (this.getCandidatsAffectesCentre() === this.getCapaciteCentre()) {
            return { bg: 'barre_soumis', text: 'barre_text_soumis' }
        } else if (this.getCandidatsAffectesCentre() !== 0) {
            return { bg: 'barre_valide', text: 'barre_text_valide' }
        } else {
            return { bg: 'barre_tertiary', text: 'barre_text_defaut' }
        }
    }

    // Retourne le nombre de centres saturés
    get_libelle_centre_satures () {
        let libelle = ''

        if (this.$store.getters['repartitioncandidats/get_centres_satures'] === 0) {
            libelle = 'Liste des centres'
        } else if (this.$store.getters['repartitioncandidats/get_centres_satures'] === 1) {
            libelle = this.$store.getters['repartitioncandidats/get_centres_satures'] + ' centre est saturé'
        } else if (this.$store.getters['repartitioncandidats/get_centres_satures'] > 1) {
            libelle = this.$store.getters['repartitioncandidats/get_centres_satures'] + ' centres sont saturés'
        }

        return libelle
    }

    // Retourne l'état de saturation des villes d'un candidat en particulier
    get_saturation_centreOld (ville_id: number, concour: any) {
        const centre_for_candidat = this.old_liste_centres.filter((c: any) => c.ville_id === ville_id)
        let text_class = 'text-danger'
        if (centre_for_candidat.length !== 0) {
            for (const c in centre_for_candidat) {
                if (centre_for_candidat[c].saturation[concour.id]) {
                    const centre_filiere_for_candidat = centre_for_candidat[c].saturation[concour.id]
                    if (centre_filiere_for_candidat.saturation === 0) {
                        text_class = 'text-success'
                    } else if (centre_filiere_for_candidat.saturation === 2) {
                        text_class = 'text-secondary'
                    }
                }
            }
        }
        return text_class
    }

    get_saturation_centre (ville_id: number, concour: any) {
        const centre_for_candidat = this.old_liste_centres.filter((c: any) => c.ville_id === ville_id)
        let nb_centre = 0
        let nb_centre_full = 0
        let nb_centre_sature = 0
        let nb_centre_non_sature = 0
        if (centre_for_candidat.length !== 0) {
            for (const c in centre_for_candidat) {
                nb_centre++
                // Centre saturé 1
                // Centre à l'équilibre 2
                // Centre non saturée 0
                if (centre_for_candidat[c].saturation[concour.id]) {
                    const centre_filiere_for_candidat = centre_for_candidat[c].saturation[concour.id]
                    if (centre_filiere_for_candidat.saturation === 0) {
                        nb_centre_non_sature++
                    } else if (centre_filiere_for_candidat.saturation === 2) {
                        nb_centre_full++
                    } else {
                        nb_centre_sature++
                    }
                }
            }
        }
        const test = '' // ' _nb_centre_' + nb_centre + '__nb_centre_full_' + nb_centre_full + '__nb_centre_non_sature_' + nb_centre_non_sature + '__nb_centre_sature_' + nb_centre_sature + '_'
        if (nb_centre > 0 && nb_centre_non_sature > 0) {
            return 'text-success' + test
        } else if (nb_centre > 0 && nb_centre === nb_centre_full) {
            return 'text-secondary' + test
        } else { // if (nb_centre === nb_centre_sature) {
            return 'text-danger' + test
        }
    }


    load () {
        this.$store.commit('repartitioncandidats/SET_META', null)
        this.$store.commit('repartitioncandidats/SET_TOTALROWS', 0)
        this.loading_datas = true
        // Met en attente le temps que tout soit chargé
        if (this.$route.query.centre_id && this.$route.query.centre_id !== null) {
            this.centre_select_id = this.$route.query.centre_id
        }

        const params: any = {
            page: 1,
            sort: 'name',
            direction: 'asc'
        }
        Vue.set(params, 'filter-centre_id', this.$route.query.centre_id)
        this.params = params

        // Load des filières
        this.$store.dispatch('concour/getConcoursActifs').then(() => {
            let filieres = this.$store.getters['concour/banques']
            filieres = orderBy(filieres, 'ordre', 'asc')
            this.options_filieres = []
            for (const f in filieres) {
                this.options_filieres.push({ id: filieres[f].id, name: filieres[f].name })
            }

            // Load des villes
            this.$store.dispatch('ville/getVilles').then(() => {
                this.$store.dispatch('ville/getZones').then(() => {
                    this.options_zones = this.$store.state.ville.zones

                    this.$store.dispatch('etablissement/getEtablissements').then(() => {
                        this.options_etablissements = this.$store.state.etablissement.etablissements

                        // Load des centres
                        this.$store.dispatch('repartitioncandidats/getListeCentres').then(() => {
                            this.old_liste_centres = this.$store.state.repartitioncandidats.liste_centres
                            const centres = this.$store.state.repartitioncandidats.liste_centres
                            for (const c in centres) {
                                this.options_centres.push({ id: centres[c].id, name: centres[c].ville.name + ' - ' + centres[c].name, flag_saturation: centres[c].flag_saturation })
                            }

                            // Prépare les options villes en cherchant si la ville est saturée ou non
                            this.options_villes = this.$store.state.ville.villes

                            for (const v in this.options_villes) {
                                this.options_villes[v].flag_saturation = 0
                                const centres_ville = centres.filter((c: any) => c.ville_id === this.options_villes[v].id)

                                for (const cv in centres_ville) {
                                    if (centres_ville[cv].flag_saturation === 1) {
                                        this.options_villes[v].flag_saturation = 1
                                    }
                                }
                            }

                            this.setFiltersForGenericTab()
                            this.rechercher()
                        })
                    })
                })
            })
        })
    }

    async mounted() {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            // Récupération des académies
            let array: any[] = []
            await this.$store.dispatch('etablissement/getEtablissements', { perPage: 0 })
            const etablissements = this.$store.getters['etablissement/etablissements']
            if (!isEmpty(etablissements)) {
                etablissements.forEach((etablissement: any) => {
                    if (etablissement.academie) {
                        array.push(etablissement.academie)
                    }
                })
                array = [ ...new Set(array) ]
                orderBy(array, ['academie'], ['asc'])

                // Formatage des données pour le select dans le tableau
                for (let i = 0; i < array.length; i++) {
                    array[i] = {
                        index: array[i],
                        name: array[i]
                    }
                }
                this.options_academies = array
            }

            this.load()
        }
    }
}
