export enum BarRuleType {
    BAR_RULE_TYPE_POINT = 'point',
    BAR_RULE_TYPE_GRADE = 'grade',
    BAR_RULE_TYPE_ABSENCE = 'absence',
    BAR_RULE_TYPE_POSITION = 'position'
}

export enum BarTypePass {
    BAR_TYPE_PASS = 'pass',
    BAR_TYPE_WILDCARD = 'wildcard',
    BAR_TYPE_ANONYMOUS = 'anonymous'
}
