















import { Vue, Component } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { VueEditor } from 'vue2-editor'
import MessageCandidatsCentre from '@/components/Centre/MessageCandidatsCentre.vue'

@Component({
    components: {
        VueEditor,
        MessageCandidatsCentre
    },
    computed: {
        ...mapGetters('centre', ['error', 'loading', 'centreSelect']),
        ...mapState('centre', ['error', 'centreSelect'])
    }
})
export default class MessageCandidats extends Vue {
    // DATAS

    // METHODS
    beforeMount () {
        if (!this.$store.state.centre.centreSelect.id) {
            this.$store.dispatch('centre/getCentre', { centre_id: this.$route.params.centre_id })
        }
    }
}
